import React, { useEffect, useMemo, useState } from "react";
import Loading from "../based/Loading";
import { Layout } from "../layout/Layout";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY, ARTWORK_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import OperatorServices from "../based/services/OperatorServices";
import { ConfirmDialog } from "../based/Dialog";
import Common from "../based/Common";
import BatchModal from "./BatchModal";
import ProductionServices from "../based/services/ProductionServices";
import Request from "../based/Request";

export default function PrintTagPet(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...Common.Paging });
    const [tags, setTags] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getDatas(paging);
    }, []);
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OperatorServices.PrintTagPetPaging(newPaging.pageNumber, newPaging.pageSize, newPaging.tagId, newPaging.status, "");
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg("");
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg("");
            Notify(NOTIFY.WARNING, "Chú ý", error.message ? error.message : "Có lỗi trong quá trình lấy thông tin");
        }
    }
    function renderTableFoot() {
        let totalRecord = paging.totalRecord,
            pageTotal = totalRecord % paging.pageSize === 0 ? totalRecord / paging.pageSize : (totalRecord - (totalRecord % paging.pageSize)) / paging.pageSize + 1;

        let from = (paging.pageNumber - 1) * paging.pageSize + 1;
        let to = paging.pageSize * paging.pageNumber;

        if (totalRecord == 0) {
            from = 0;
            to = 0;
        } else if (totalRecord < paging.pageSize * paging.pageNumber) {
            to = totalRecord;
        }
        return (
            <table className="table table-bordered m-b-0">
                <tfoot>
                    <tr>
                        <td>
                            <div className="form-inline">
                                <ul className="pagination pagination-split footable-pagination m-b-0">
                                    {paging.pageNumber > 1 &&
                                        (paging.pageNumber === 2 ? (
                                            <li onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                                <a className="cursor-pointer">1</a>
                                            </li>
                                        ) : (
                                            [
                                                <li onClick={() => getDatas({ ...paging, pageNumber: 1 })} title={1} key={0}>
                                                    <a className="cursor-pointer">«</a>
                                                </li>,
                                                <li onClick={() => getDatas({ ...paging, pageNumber: paging.pageNumber - 1 })} title={paging.pageNumber - 1} key={1}>
                                                    <a className="cursor-pointer">‹</a>
                                                </li>,
                                                <li onClick={() => getDatas({ ...paging, pageNumber: paging.pageNumber - 1 })} key={2}>
                                                    <a className="cursor-pointer">{paging.pageNumber - 1}</a>
                                                </li>,
                                            ]
                                        ))}
                                    <li className="active">
                                        <a className="cursor-pointer">{paging.pageNumber}</a>
                                    </li>
                                    {pageTotal > paging.pageNumber &&
                                        (pageTotal === paging.pageNumber + 1 ? (
                                            <li onClick={() => getDatas({ ...paging, pageNumber: paging.pageNumber + 1 })}>
                                                <a className="cursor-pointer">{paging.pageNumber + 1}</a>
                                            </li>
                                        ) : (
                                            [
                                                <li onClick={() => getDatas({ ...paging, pageNumber: paging.pageNumber + 1 })} key={0}>
                                                    <a className="cursor-pointer">{paging.pageNumber + 1}</a>
                                                </li>,
                                                <li onClick={() => getDatas({ ...paging, pageNumber: paging.pageNumber + 1 })} title={paging.pageNumber + 1} key={1}>
                                                    <a className="cursor-pointer">›</a>
                                                </li>,
                                                <li onClick={() => getDatas(pageTotal)} title={pageTotal} key={2}>
                                                    <a className="cursor-pointer">»</a>
                                                </li>,
                                            ]
                                        ))}
                                </ul>
                                <span className="table-record">
                                    Hiển thị từ {from} đến {to} của {totalRecord} bản ghi
                                </span>
                                <label className="form-inline ">
                                    Hiển thị&nbsp;
                                    <select className="form-control h-30px" value={paging.pageSize} onChange={(event) => getDatas({ ...paging, pageNumber: 1, pageSize: event.target.value })}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                    &nbsp;bản ghi
                                </label>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }
    function _handleMove(item) {
        let tagsUp = tags && tags.length ? [...tags] : [];
        let existed = false;
        if (tagsUp && tagsUp.length > 0) {
            for (let index = 0; index < tagsUp.length; index++) {
                existed = tagsUp[index].orderTagDetail && tagsUp[index].orderTagDetail.tagId == item.orderTagDetail.tagId;
                if (existed) break;
            }
        }
        if (!existed) {
            tagsUp.push(item);
            setTags(tagsUp);
        }
    }
    function _renderTable1() {
        return (
            <div className="content-table table-rep-plugin full-width" id="tbl_items">
                <table className="table table-bordered m-b-0 focus-on packing">
                    <thead>
                        <tr>
                            <th width="200" className="text-center">
                                Tag SX
                            </th>
                            <th width="200" className="text-center">
                                Mã đơn
                            </th>
                            <th width="100" className="text-center">
                                Mã mặt trước
                            </th>
                            <th width="100 " className="text-center">
                                Mã mặt sau
                            </th>
                            <th width="100 " className="text-center">
                                Mã Logo
                            </th>
                            <th width="250" className="text-center">
                                Tình trạng
                            </th>
                            <th className="text-center" width="200">
                                Batch SX
                            </th>
                            <th className="text-center" width="50"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas && datas.length > 0 ? (
                            datas.map((item, idx) => {
                                const { orderTagDetail, orderTagMockup } = item;
                                if (!orderTagDetail) return "";
                                const { artworkMockupModels, logoMockupModel } = orderTagMockup;
                                const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : null;
                                const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : null;

                                return (
                                    <tr key={idx}>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.tagId}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.orderId}</td>
                                        <td className="vertical-align-middle text-center">{front ? front.artworkId : ""} </td>
                                        <td className="vertical-align-middle text-center">{back ? back.artworkId : ""}</td>
                                        <td className="vertical-align-middle text-center">{logoMockupModel ? logoMockupModel.brandId : ""}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.inlineStatusName}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.batchName}</td>
                                        <td className="vertical-align-middle text-center">
                                            <i className="fa fa-arrow-right cursor-pointer" onClick={() => _handleMove(item)}></i>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="100" className="text-center">
                                    <span>Không có dữ liệu</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {renderTableFoot()}
            </div>
        );
    }
    function _handleRemove(item) {
        let tagsUp = [...tags];
        if (tagsUp && tagsUp.length > 0) {
            let idx = -1;
            for (let index = 0; index < tagsUp.length; index++) {
                let existed = tagsUp[index].orderTagDetail && tagsUp[index].orderTagDetail.tagId == item.orderTagDetail.tagId;
                if (existed) {
                    idx = index;
                    break;
                }
            }
            tagsUp.splice(idx, 1);
            setTags(tagsUp);
        }
    }
    function _handleClearAllTags() {
        ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn xóa hết các Tags đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setTags([]);
                resolve({ title: "Thành công", msg: "Thực hiện thành công" });
            });
        });
    }
    function _renderTableTagSelected() {
        return (
            <React.Fragment>
                <div className="form-group col-md-12">
                    <button className="btn btn-default  btn-sm m-r-5">Số lượng Tag: {tags.length}</button>
                    <button className="btn btn-danger  btn-sm m-r-5" onClick={() => _handleClearAllTags()}>
                        Xóa hết các Tag đã chọn
                    </button>
                </div>
                <div className="content-table table-rep-plugin full-width" id="tbl_items">
                    <table className="table table-bordered m-b-0 focus-on packing">
                        <thead>
                            <tr>
                                <th width="200" className="text-center">
                                    Tag SX
                                </th>
                                <th width="200" className="text-center">
                                    Mã đơn
                                </th>
                                <th width="100" className="text-center">
                                    Mã mặt trước
                                </th>
                                <th width="100 " className="text-center">
                                    Mã mặt sau
                                </th>
                                <th width="100 " className="text-center">
                                    Mã Logo
                                </th>
                                <th width="250" className="text-center">
                                    Tình trạng
                                </th>
                                <th className="text-center" width="200">
                                    Batch SX
                                </th>
                                <th className="text-center" width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tags && tags.length > 0 ? (
                                tags.map((item, idx) => {
                                    const { orderTagDetail, orderTagMockup } = item;
                                    if (!orderTagDetail) return "";
                                    const { artworkMockupModels, logoMockupModel } = orderTagMockup;
                                    const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : null;
                                    const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : null;

                                    return (
                                        <tr key={idx}>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.tagId}</td>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.orderId}</td>
                                            <td className="vertical-align-middle text-center">{front ? front.artworkId : ""} </td>
                                            <td className="vertical-align-middle text-center">{back ? back.artworkId : ""}</td>
                                            <td className="vertical-align-middle text-center">{logoMockupModel ? logoMockupModel.brandId : ""}</td>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.inlineStatusName}</td>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.batchName}</td>
                                            <td className="vertical-align-middle text-center">
                                                <i className="fa fa-trash-o fa-1x text-danger" onClick={() => _handleRemove(item)}></i>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="100" className="text-center">
                                        <span>Không có dữ liệu</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
    async function _handlePrintTags() {
        let ids = [...tags].map((item) => item.orderTagDetail.tagId);
        window.location.href = `${Request.API_URL}/api/Production/print-pet-tag/${JSON.stringify(ids)}`;
    }
    function _handleSave(batchs) {
        if (batchs && batchs.length > 0) {
            let tagsUp = tags && tags.length ? [...tags] : [];

            batchs.map((batch) => {
                let existed = false;
                if (tagsUp && tagsUp.length > 0) {
                    for (let index = 0; index < tagsUp.length; index++) {
                        existed = tagsUp[index].orderTagDetail && tagsUp[index].orderTagDetail.tagId == batch.orderTagDetail.tagId;
                        if (existed) break;
                    }
                }
                if (!existed) {
                    tagsUp.push(batch);
                }
            });
            setTags(tagsUp);
            setShow(false);
        }
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs currentPage="In Tag sản xuất (PET)" />
            {show && <BatchModal isShowModal={show} onClose={() => setShow(false)} onSave={(batchs) => _handleSave(batchs)} printType={2} />}
            <div className="col-sm-12 card-box">
                <div className="form-row m-t-20">
                    <div className="col-md-6">
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom  btn-sm m-r-5" onClick={() => setShow(true)}>
                                Chọn Batch
                            </button>
                            <button className="btn btn-custom  btn-sm m-r-5" onClick={() => _handlePrintTags()}>
                                In Tag SX
                            </button>
                        </div>
                        {_renderTable1()}
                    </div>
                    <div className="col-md-6">{_renderTableTagSelected()}</div>
                </div>
            </div>
        </React.Fragment>
    );
}
