import { createContext } from "react";

export const DesignContext = createContext({
  product: {
    color: null,
    image: null,
    listImage: [],
    svg: null
  },
  textActiveId: null,
  pictureActiveId: null,
  uploadActiveId: null,
  texts: [],
  pictures: [],
  uploads: [],
  presetContext: null,
  addDesign: () => { },
  updateDesign: () => { },
  removeDesign: () => { },
  activeDesign: () => { },
  inactiveAllDesign: () => { },
  setProductDesign: () => { },
  setPresetContext: () => { },
  setUploadActiveId: () => { },
  setUploads: () => { },
});
