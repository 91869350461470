import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../../based/Common";
import { NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import Request from "../../based/Request";
import TFUSelect from "../../based/inputs/TFUSelect";
import ReupServices from "../../based/services/ReupServices";
import useFormValidate from "../../hooks/useFormValidate";

const modelApp = {
    id: 0,
    folderId: 0,
    appName: "",
    thumbnail: "",
    x: 0,
    y: 0,
    platformId: 0,
};
const AddOrUpdateAppInFolder = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [dataFolder, setDataFolder] = useState([]);
    const [data, setData] = useState({});
    const [dataPlatform, setDataPlatform] = useState([]);

    useEffect(() => {
        getFolder();
        getDataDropDown();
    }, []);

    useEffect(() => {
        if (props.id > 0) {
            getData();
        } else {
            setData({});
        }
    }, [props.id]);

    async function getDataDropDown() {
        setIsBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ReupServices.GetApplicationDropDown();
        if (!err && data) {
            setDataPlatform(data);
            setIsBusy(false);
        } else {
            setDataPlatform([]);
            setIsBusy(false);
        }
    }

    const getData = async () => {
        setIsBusy(true);
        let [err, data] = await ReupServices.GetFolderById(props.id);
        if (!err && data) {
            setData(data);
            setIsBusy(false);
        } else {
            setData({});
            setIsBusy(false);
        }
    };

    const _handleSave = async () => {
        props.onSaveContinue();
    };

    const getFolder = async () => {
        setIsBusy(true);
        let [err, data] = await ReupServices.GetFolderDropDown();
        if (!err && data) {
            setDataFolder(data);
            setIsBusy(false);
        } else {
            setDataFolder([]);
            setIsBusy(false);
        }
    };

    async function handleDelete(id, idx) {
        if (id > 0) {
            let ids = [];
            ids.push(id);
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await ReupServices.DeleteApp(ids);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = { ...data };
            newData.apps.splice(idx, 1);
            setData(newData);
        }
    }

    function IsDisable(idx) {
        if (!data.apps[idx].appName || !data.apps[idx].platformId || data.apps[idx].x < 0 || data.apps[idx].y < 0) return true;
        return false;
    }

    async function handleCreateOrUpdate(idx) {
        setIsBusy(true);
        let dataItem = { ...data.apps[idx] };
        let [err, dt] = await ReupServices.CreateUpdateApp({ ...dataItem, folderId: props.id });
        if (!err && dt) {
            getData();
            setIsBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = { ...data };
                            dt.apps[index].thumbnail = res.data.imageUrl;
                            setData(dt);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <CommonModal show={props.isShowModal} size="lg" id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className="form-group">
                <label htmlFor="slotId" className="required">
                    Chọn slot:
                </label>
                <TFUSelect
                    className="select"
                    options={dataFolder}
                    placeholder="--Chọn--"
                    value={props.id}
                    onChanged={(value) => {
                        props.setSelectId(value);
                    }}
                />
                <Wapper>
                    <div className="w-60">
                        <label>Tên thiết bị</label>
                        <span>{data.deviceName}</span>
                    </div>
                    <div className="w-40">
                        <label>Vị trí page</label>
                        <span>{data.positionPage}</span>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Tên app</th>
                                    <th>Nền tảng</th>
                                    <th>Vị trí X</th>
                                    <th>Vị trí Y</th>
                                    <th>Thumbnail</th>
                                    <th>Action</th>
                                </tr>
                                {data &&
                                    data.apps &&
                                    data.apps.map((app, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: 200 }}>
                                                    {app.id <= 0 || app.isUpdate ? (
                                                        <input
                                                            className="input-in-table"
                                                            type="text"
                                                            placeholder="Tên app"
                                                            value={app.appName}
                                                            onChange={(e) => {
                                                                let dt = { ...data };
                                                                dt.apps[index].appName = e.target.value;
                                                                setData(dt);
                                                            }}
                                                        />
                                                    ) : (
                                                        app.appName
                                                    )}
                                                </td>
                                                <td style={{ width: 200 }}>
                                                    {app.id <= 0 || app.isUpdate ? (
                                                        <TFUSelect
                                                            className="select"
                                                            options={dataPlatform}
                                                            placeholder="--Chọn--"
                                                            value={app.platformId}
                                                            onChanged={(value) => {
                                                                let dt = { ...data };
                                                                dt.apps[index].platformId = value;
                                                                setData(dt);
                                                            }}
                                                        />
                                                    ) : (
                                                        app.platformName
                                                    )}
                                                </td>
                                                <td style={{ width: 100, textAlign: "center" }}>
                                                    {app.id <= 0 || app.isUpdate ? (
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            value={app.x}
                                                            onChange={(e) => {
                                                                let dt = { ...data };
                                                                dt.apps[index].x = e.target.value;
                                                                setData(dt);
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>X: {app.x}</span>
                                                    )}
                                                </td>
                                                <td style={{ width: 100, textAlign: "center" }}>
                                                    {app.id <= 0 || app.isUpdate ? (
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            value={app.y}
                                                            onChange={(e) => {
                                                                let dt = { ...data };
                                                                dt.apps[index].y = e.target.value;
                                                                setData(dt);
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>Y: {app.y}</span>
                                                    )}
                                                </td>
                                                <td style={{ width: 150, textAlign: "center" , position: 'relative'}}>
                                                    {app.id <= 0 || app.isUpdate ? (
                                                        <div>
                                                            {app.thumbnail ? (
                                                                <label for={`upload-${index}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                                    <img style={{ maxWidth: 250, maxHeight: 100 }} src={app.thumbnail} />
                                                                    <span style={{ position: 'absolute', top: 5 , right: 5 , fontSize: 30, lineHeight: 0.5}} onClick={()=>{
                                                                        let dt = {...data}
                                                                        dt.apps[index].thumbnail = ""
                                                                        setData(dt)
                                                                    }}>
                                                                        &times;
                                                                    </span>

                                                                
                                                                </label>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <input
                                                                style={{maxWidth: 90}}
                                                                accept="image/*"
                                                                hidden={app.thumbnail ? true : false}
                                                                type="file"
                                                                id={`upload-${index}`}
                                                                onChange={(e) => {
                                                                    handleUploadFile(e, index, data);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        app.thumbnail ? <img style={{maxWidth: 250, maxHeight: 100}} src={app.thumbnail} />  : ""
                                                    )}
                                                </td>
                                                <td style={{ width: 150, textAlign: "center" }}>
                                                    {app.isUpdate || app.id <= 0 ? (
                                                        <button
                                                            className={`btn btn-custom m-1 ${IsDisable(index) ? "disabled" : ""}`}
                                                            onClick={() => {
                                                                if (!IsDisable(index)) handleCreateOrUpdate(index);
                                                            }}
                                                        >
                                                            Xong
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-custom m-1"
                                                            onClick={() => {
                                                                let dt = { ...data };
                                                                dt.apps[index].isUpdate = true;
                                                                setData(dt);
                                                            }}
                                                        >
                                                            Sửa
                                                        </button>
                                                    )}
                                                    <button
                                                        className="btn btn-danger m-1"
                                                        onClick={() => {
                                                            handleDelete(app.id, index);
                                                        }}
                                                    >
                                                        Xóa
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {props.id > 0 && (
                                    <tr>
                                        <th
                                            colSpan={999}
                                            className="add-content"
                                            onClick={() => {
                                                setData({
                                                    ...data,
                                                    apps: [...data.apps, { ...modelApp }],
                                                });
                                            }}
                                        >
                                            Thêm +
                                        </th>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Wapper>
            </div>
        </CommonModal>
    );
};

export default AddOrUpdateAppInFolder;
const Wapper = styled.div`
    .w-60 {
        width: 60%;
        display: inline-block;

        label {
            display: block;
            margin-top: 10px;
            margin-bottom: 0;
        }
        span {
            display: block;
            padding: 5px;
            border: 1px solid #ccc;
        }
    }
    .w-40 {
        width: 40%;
        display: inline-block;

        label {
            display: block;
            margin-top: 10px;
            margin-bottom: 0;
        }
        span {
            display: block;
            padding: 5px;
            border: 1px solid #ccc;
        }
    }

    .add-content {
        color: #64c5b1;
        cursor: pointer;
    }

    table {
        width: 100%;
        margin-top: 15px;
        tr {
            td {
                position: relative;
                border: 1px solid #ccc;
                padding: 5px;

                .input-in-table {
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 40px;
                    padding: 0;
                    display: block;

                    ::placeholder {
                        opacity: 0.3;
                    }
                }

                .remove {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    cursor: pointer;
                }

                label {
                    width: 40px;
                    display: block;
                    background: #fff;
                    border-radius: 5px;
                    text-align: center;
                    margin: 0 auto;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
`;
AddOrUpdateAppInFolder.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
