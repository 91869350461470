import styled from 'styled-components';

export const NavbarLeftWrapper = styled.div`
width: ${({ toggle }) => toggle ? '240px' : '58.2px'};
position: fixed;
left: 0;
top: 0;
height: 100vh;
background: var(--white);
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
transition: all .6s ease;
box-shadow: 10px 0 4px -10px #888;
z-index: 1000;
.navbar__left__container{
    width: 100%;
    height: 100%;
    transition: all .5s ease;
    .nav__logo{
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 17px;
        .logo__text{
            font-size: 27px;
            letter-spacing: 5px;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            transition: all .5s ease;
            .sub__text{
                display: ${({ toggle }) => toggle ? 'block' : 'none'};
            }
            
        }
        .logo__full{
            width:50%;
            height: auto;
            object-fit: cover;
        }
        .logo__sub{
            width:50%;
            height: auto;
            object-fit: cover;
            display: ${({ toogle }) => toogle ? 'none' : 'block'}
        }
    }
}
.nav__content__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    overflow-y: scroll;
}

`
export const NavBarToggle = styled.div`
background: white;
top: 16px;
width: 25px;
height: 40px;
position: absolute;
right: -25px;
justify-content: center;
align-items: center;
cursor: pointer;
border-bottom-right-radius: 7px;
border-top-right-radius: 7px;
display: none;
${NavbarLeftWrapper}:hover &{
    display: flex;
}

`
export const NavItem = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin: 7px 0;
flex-direction: column;
transition: all .5s ease;
.main__nav__item{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 7px;
    .item__icon{
        flex-basis: ${({ toggle }) => toggle ? '15%' : '100%'};
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item__name{
        display: ${({ toggle }) => toggle ? 'block' : 'none'};
        flex-basis: 70%;
        align-items: center;
        font-weight: 500;

    }
    .toggle__icon{
        display: ${({ toggle }) => toggle ? 'block' : 'none'};
        flex-basis: 15%;
        cursor: pointer;
        align-items: center;
    }
}
.dropdown{
  display: none;
  width: 100%;
  ul{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;
      li{
          list-style: none;
          padding: 5px 10px;
          border-radius: 16px;
            text-align: left;
            background: white;
            width: 80%;
            a{
                color: #000;
                font-weight: 500;
            }
      }
      li.active{
        background: #e0e0e5;
        a{
            color: #000;
            font-weight:700;
        }
      }
  }
}
.dropdown.active{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
}

`
