import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/refactor/TFUSelect";
import GiftServices from "../based/services/GiftServices";
import useFormValidate from "../hooks/useFormValidate";

const GiftModel = {
    id: 0,
    url: "",
    giftTypeId: 0,
    quantity: 0,
    name: "",
};

const AddOrEditModalGift = ({ id, onSaveContinue, onClose, isShowModal }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [model, setModel] = useState(GiftModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const [giftTypeOptions, setGiftTypeOptions] = useState([]);

    useEffect(() => {
        if (id > 0) _getTag(id);
        _getGiftTypeOptions();
        return () => setModel({});
    }, [id]);

    const _getTag = async (id) => {
        setIsBusy(true);
        let [err, data] = await GiftServices.GetGiftById(id);
        if (!err && data) setModel({ ...data });
        else setModel(GiftModel);
        setIsBusy(false);
    };

    const _getGiftTypeOptions = async () => {
        let [err, data] = await GiftServices.GetGiftTypeByPaging({ pageNumber: 1, pageSize: 100 });
        if (!err && data && data.listObjects) {
            let options = data.listObjects.map((item) => {
                return { value: item.id, label: item.name };
            });
            setGiftTypeOptions(options);
        } else {
            setGiftTypeOptions([]);
        }
    };

    const _handleSave = async () => {
        setIsBusy(true);
        let [err, data] = await GiftServices.CreateUpdateGift(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSaveContinue();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
        setIsBusy(false);
    };
    return (
        <CommonModal show={isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()}>
            <div className="form-group">
                <label htmlFor="name" className="required">
                    Tên hiển thị:
                </label>
                <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setModel({ ...model, name: value })} value={model.name} />
            </div>
            <div className="form-group">
                <label htmlFor="">Icon</label>
                <input
                    type="file"
                    className="d-block"
                    onChange={(e) => {
                        let file = e.target.files[0];
                        if (file) {
                            let reader = new FileReader();
                            reader.onload = function (e) {
                                Request.UploadImage(file).then((res) => {
                                    if (res && res.data) {
                                        let { imageUrl, thumbnailUrl } = res.data;
                                        setModel({ ...model, url: imageUrl });
                                    }
                                });
                            };
                            reader.readAsDataURL(file);
                        }
                    }}
                />
                {model.url && <img src={model.url} alt="icon" style={{ width: "100px", height: "100px" }} />}
            </div>
            <div className="form-group">
                <label htmlFor="">Số lượng</label>
                <input
                    type="number"
                    value={model.quantity}
                    onChange={(e) => {
                        setModel({ ...model, quantity: +e.target.value });
                    }}
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <label htmlFor="">Loại quà tặng</label>
                <TFUSelect name="giftTypeId" value={model.giftTypeId} options={giftTypeOptions} onChanged={(value) => setModel({ ...model, giftTypeId: value })} />
            </div>
            <div className="form-group">
                <label htmlFor="">Mặc định tặng quà</label>
                <input
                    className="d-block"
                    type="checkbox"
                    checked={model.isDefault}
                    onChange={(e) => {
                        setModel({ ...model, isDefault: e.target.checked });
                    }}
                />
            </div>
        </CommonModal>
    );
};

export default AddOrEditModalGift;
