import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import { INITPAGING, NOTIFY } from "../based/Constants";
import PlatformServices from "../based/services/PlatformServices";
import { Notify } from "../based/Notify";

export const TemplateType = {
    ProductName: 0,
    ProductContent: 1,
};

const TemplateModel = {
    title: "",
    content: "",
    type: TemplateType.ProductName,
};

function PushTemplateModal({ show, onClose, templateType }) {
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [data, setData] = useState([]);
    const [model, setModel] = useState({ ...TemplateModel });

    useEffect(() => {
        getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
    }, [templateType]);

    const getData = async (paging) => {
        const [err, data] = await PlatformServices.GetTemplateDTOs({ ...paging, type: templateType });
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
        } else {
            setData([]);
            setPaging(paging);
        }
    };

    const removeTemplateDTO = async (id) => {
        const [err, data] = await PlatformServices.RemoveTemplateDTO(id);
        if (!err && data) {
            getData(paging);
        }
    };

    const createUpdateTemplate = async (model) => {
        const [err, data] = await PlatformServices.CreateUpdateTemplate(model);
        if (!err && data) {
            setModel({ ...TemplateModel });
            getData(paging);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thất bại");
        }
    };

    return (
        <CommonModal show={show} onClose={onClose} title={templateType == TemplateType.ProductName ? "Mẫu tên" : "Mẫu mô tả sản phẩm"} size="lg">
            <Wrapper>
                <div className="top-section">
                    <div
                        className="instruction"
                        onClick={(e) => {
                            navigator.clipboard.writeText("SET_TITLE");
                            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Đã copy cú pháp");
                        }}
                    >
                        Gõ SET_TITLE để replace tên thiết kế
                    </div>
                </div>
                <div className="input_section">
                    <div className="section-title">
                        <input
                            type="text"
                            placeholder="Nhập tên mẫu"
                            value={model.title}
                            onChange={(e) => {
                                let length = e.target.value.length;
                                if (length <= 50) {
                                    setModel({ ...model, title: e.target.value });
                                } else {
                                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Không được nhập quá 50 ký tự");
                                }
                            }}
                        />
                    </div>
                    <div className="section-content">
                        <textarea
                            placeholder="Cú pháp mẫu"
                            rows={4}
                            value={model.content}
                            onChange={(e) => {
                                let length = e.target.value.length;
                                if (length <= 2000) {
                                    setModel({ ...model, content: e.target.value });
                                } else {
                                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Không được nhập quá 2000 ký tự");
                                }
                            }}
                        />
                        <div className="list-icon">
                            <div className="icon-left">{model.content && model.content.length}/2000</div>
                            <div className="icon-right">
                                <div className="list-btn">
                                    <button
                                        className="btn btn-sm btn-custom"
                                        onClick={(e) => {
                                            createUpdateTemplate({ ...model, type: templateType });
                                        }}
                                    >
                                        Lưu
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) => {
                                            setModel({ ...TemplateModel });
                                        }}
                                    >
                                        Hủy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-item">
                    {data &&
                        data.map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className={`item-title ${item.isEdit ? "edit" : ""}`}>
                                        <input
                                            type="text"
                                            readOnly={!item.isEdit}
                                            value={item.title}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                let length = e.target.value.length;
                                                if (length <= 50) {
                                                    dt[index].title = e.target.value;
                                                    setData(dt);
                                                } else {
                                                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Không được nhập quá 50 ký tự");
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={`item-content ${item.isEdit ? "edit" : ""}`}>
                                        <textarea
                                            readOnly={!item.isEdit}
                                            value={item.content}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                let length = e.target.value.length;
                                                if (length <= 2000) {
                                                    dt[index].content = e.target.value;
                                                    setData(dt);
                                                } else {
                                                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Không được nhập quá 2000 ký tự");
                                                }
                                            }}
                                        />
                                        <div className="list-icon">
                                            <div className="icon-left">{item.content && item.content.length}/2000</div>
                                            <div className="icon-right">
                                                <div className="list-btn">
                                                    <a
                                                        className="text-custom"
                                                        onClick={(e) => {
                                                            if (item.isEdit) {
                                                                createUpdateTemplate({ ...item, type: templateType });
                                                            } else {
                                                                let dt = [...data];
                                                                dt[index].isEdit = true;
                                                                setData(dt);
                                                            }
                                                        }}
                                                    >
                                                        {item.isEdit ? "Lưu" : "Sửa"}
                                                    </a>
                                                    <a
                                                        className="text-danger"
                                                        onClick={(e) => {
                                                            removeTemplateDTO(item.id);
                                                        }}
                                                    >
                                                        Xóa
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default PushTemplateModal;

const Wrapper = styled.div`
    .item-title.edit {
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .item-content.edit {
        border: 1px solid #d9d9d9;
        background: #fff;
    }
    .section-title {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 6px 12px;
    }

    .section-title input {
        width: 100%;
        border: none;
    }

    .section-content textarea {
        width: 100%;
        border: none;
    }

    .input_section {
        margin: 16px 0;
    }

    .section-content {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 6px 12px;
    }

    .list-icon {
        display: flex;
        align-items: center;
    }

    .icon-right {
        margin-left: auto;
    }

    .list-btn {
        display: flex;
        align-items: center;
    }

    .list-btn button {
        margin: 0 8px;
        padding: 5px 24px !important;
    }

    .icon-left {
        color: #a4a4a4;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .item-title {
        padding: 6px 12px;
    }

    .item-title input[type="text"] {
        width: 100%;
        border: none;
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .item {
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .item-content {
        padding: 6px 12px;
    }

    .item-content textarea {
        width: 100%;
        border: none;
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .list-btn a {
        margin: 0 12px;
    }
`;
