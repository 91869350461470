import React, { useState, useEffect } from "react";
import CommonModal from "../based/Modal";
import Textbox from '../based/inputs/Textbox';
import { CONSTANTS, NOTIFY } from '../based/Constants'
import CommonTable from "../based/CommonTable";
import Common from '../based/Common';
import SetProductServices from '../based/services/SetProductServices';
import NumberFormat from 'react-currency-format';

export default function DetailSetProduct(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [msg, setMsg] = useState('Đang tải dữ liệu...')
    const [config, setConfig] = useState({})

    useEffect(() => {
        setConfig(props.config)
    }, [props.isShowModal])
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width='120' className='text-center'>Ảnh</th>
                    <th width='350'>Tên sản phẩm</th>
                    <th width='150' className='text-center'>Giá bán (đ)</th>
                    <th className='text-center'>Thông tin in</th>
                </tr>
            </thead>
        )
    }
    function _renderFrameConfig(item) {
        if (item.frameConfigs && item.frameConfigs.length > 0) {
            return (
                <table className='table table-bordered m-b-0 focus-on' >
                    <thead>
                        <tr className='text-center'>
                            {/* <th className="cursor-pointer">Tên</th> */}
                            <th className="cursor-pointer">Ngang áo (tay)</th>
                            <th className="cursor-pointer">Dài áo (trước)</th>
                            <th className="cursor-pointer">Khung ngang</th>
                            <th className="cursor-pointer">Khung dọc</th>
                            <th className="cursor-pointer">Vị trí 1</th>
                            <th className="cursor-pointer">Vị trí 2</th>
                            <th className="cursor-pointer">Vị trí 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            item.frameConfigs.map((config, i) => {
                                return (
                                    <tr key={i}>
                                        {/* <td>{config.attributeValueName}</td> */}
                                        <td>{config.widthMockup}</td>
                                        <td>{config.heightMockup}</td>
                                        <td>{config.widthFrame}</td>
                                        <td>{config.heightFrame}</td>
                                        <td>{config.optionTop1}</td>
                                        <td>{config.optionTop2}</td>
                                        <td>{config.optionTop3}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            )
        }

    }
    function _renderBody() {
        return (
            <tbody>
                {
                    config && config.productVariants && config.productVariants.length > 0 ?
                        config.productVariants.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td >
                                        <a className='iconImageDefault full-width'>
                                            <img src={item.avatar} className='variant-avatar m-r-5' alt={item.name} />
                                        </a>
                                    </td>
                                    <td className='text-left vertical-align-middle'>
                                        <strong> {item.name}</strong>
                                        {
                                            item.sellerSKU && <p className='margin-bottom-0 font-italic'>SKU: {item.sellerSKU ? item.sellerSKU : '----'}</p>
                                        }
                                        {
                                            item.attributeValuesDetail && item.attributeValuesDetail.length > 0 &&
                                            item.attributeValuesDetail.map((attribute, at) => {
                                                return (
                                                    <p key={at} className='margin-bottom-0 font-italic'>{attribute.name} : <span className='badge badge-custom'>{attribute.displayName}</span></p>
                                                )
                                            })
                                        }
                                    </td>
                                    <td className='text-center vertical-align-middle'>
                                        <NumberFormat
                                            thousandSeparator=','
                                            displayType={'text'}
                                            decimalSeparator='.'
                                            decimalScale={2}
                                            value={item.sellPrice}
                                        />
                                    </td>
                                    <td className='text-center vertical-align-middle'>
                                        {
                                            item.frameConfigs && item.frameConfigs.length > 0
                                                ? _renderFrameConfig(item)
                                                : <span>Chưa có thông tin</span>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan='5' className="vertical-align-middle text-center">Chưa có sản phẩm nào được chọn</td>
                        </tr>
                }
            </tbody>
        )
    }
    function _renderAction() {

    }
    return (
        <CommonModal
            title='Thông tin chi tiết'
            show={props.isShowModal}
            size='tfulg'
            isBusy={isLoading}
            busyMsg={msg}
            onClose={() => props.onClose()}
        >
            <div className='form-group'>
                <div className='form-row'>
                    <div className='col-md-6 vertical-align-middle' style={{ margin: 'auto' }}>
                        <label htmlFor="title" >Tên set sản phẩm:</label>
                        <Textbox name='title' className='form-control' onChanged={(name, value) => { }} value={config.title} readOnly />
                    </div>
                    <div className='col-md-6'>
                        <div className="ui-photo">
                            <ul className="ui-photo-grid clearfix">
                                <li className="ui-photo-item col-md-3">
                                    {
                                        config.setAvatar && config.setAvatar.length > 0 ?

                                            <div className="ui-photo-box">
                                                <img className="ui-photo-item__image" src={config.setAvatar} />
                                            </div>

                                            :
                                            <div className="file-upload position-relative"  >
                                                <div className="fileupload-text text-center">
                                                    <i className="fa fa-camera font-size-30px"></i>
                                                    <p className="mb-0 mt-2 text-secondary">Ảnh đại diện</p>
                                                </div>
                                            </div>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0"> <b>Danh sách sản phẩm của SET</b></h4>

                <div className="row">
                    <div className="col-md-12">
                        <div className='form-group col-md-12'>
                            <CommonTable
                                data={config.setProductVariants}
                                paging={Common.Paging}
                                renderHeader={_renderHeader}
                                renderBody={_renderBody}
                                renderAction={_renderAction}
                                freeSize='tbl_setproducts'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    )
}