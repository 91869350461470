import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { INITPAGING, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import TFUSelect from "../based/inputs/TFUSelect";
import AudioServices from "../based/services/AudioServices";
import VideoServices from "../based/services/VideoServices";
import { AfterEffectStatus, ConfigModel, LayerType, PresetModel, PresetVideoDetailType, PresetVideoLayerModel } from "./PresetModel";

function PresetConfig(props) {
    const id = props.match.params.id;
    const [isBusy, setIsBusy] = useState(false);
    const [afterEffectOptions, setAfterEffectOptions] = useState([]);
    const [configModel, setConfigModel] = useState(new ConfigModel());
    const [audioOptions, setAudioOptions] = useState([]); //lưu lại audio đã config

    console.log("configModel", configModel);

    useEffect(() => {
        if (id) {
            getConfigModel(id);
        } else {
            setConfigModel(new ConfigModel());
        }
    }, [id]);

    useEffect(() => {
        getAfterEffectPage({ ...INITPAGING, pageNumber: 1, pageSize: 1000 });
        getListAudio({ ...INITPAGING, pageNumber: 1, pageSize: 1000 });
    }, []);

    const handleSaveConfig = async () => {
        // setIsBusy(true);
        let [err, data] = await VideoServices.CreateUpdatePresetConfigModel(configModel);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
        // setIsBusy(false);
    };

    const getConfigModel = async (id) => {
        setIsBusy(true);
        let [err, data] = await VideoServices.GetPresetConfigModelById(id);
        if (!err && data) {
            setConfigModel(data);
        } else {
            setConfigModel(new ConfigModel());
        }
        setIsBusy(false);
    };

    const getAfterEffectPage = async (page) => {
        page.status = AfterEffectStatus.ConfigedLayerName; //đã config layer xong
        let [err, data] = await VideoServices.GetAfterEffectByPage(page);
        if (!err && data) {
            let datas = data.listObjects;
            let options = datas.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
            setAfterEffectOptions(options);
        }
    };

    const getListAudio = async (page) => {
        let [err, data] = await AudioServices.GetAudioByPage(page);
        if (!err && data) {
            let datas = data.listObjects;
            let options = datas.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
            setAudioOptions(options);
        } else setAudioOptions([]);
    };

    const getAfterEffectConfigModelById = async (id) => {
        setIsBusy(true);
        let [err, data] = await VideoServices.GetAfterEffectConfigModelById(id);
        if (!err && data) {
            //lấy ra layer audio ai trong data
            let { afterEffectModel, afterEffectLayers } = data;
            let audioLayer = afterEffectLayers.filter((x) => x.layerType === LayerType.AudioAI);
            if (audioLayer && audioLayer.length > 0) {
                //thêm video id vào
                let pt = configModel ? { ...configModel } : new ConfigModel();
                let preset = pt.preset ? { ...pt.preset } : new PresetModel();
                preset.videoId = id;

                //bổ sung layer
                let layers = pt.presetVideoLayers ? [...pt.presetVideoLayers] : [];
                let olds = layers.filter((x) => x.objectType == LayerType.AudioAI);
                if (olds && olds.length > 0) {
                    layers = layers.filter((x) => x.objectType != LayerType.AudioAI);
                }
                audioLayer.forEach((item) => {
                    let layer = {
                        objectType: LayerType.AudioAI,
                        objectData: "",
                        afterEffectLayerName: item.layerName,
                        afterEffectLayerId: item.id,
                    };
                    layers.push(layer);
                });
                pt.presetVideoLayers = layers;
                pt.preset = preset;
                setConfigModel(pt);
            }
        } else {
        }
        setIsBusy(false);
    };

    function convertSecondsToMinutes(inputSeconds) {
        const minutes = Math.floor(inputSeconds / 60);
        return minutes;
    }

    function convertSecondsToSeconds(inputSeconds) {
        const seconds = inputSeconds % 60;
        return seconds;
    }

    return (
        <Wrapper>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Tùy chọn Preset" />
            <div className="card-box">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label required">Tên Preset</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={configModel && configModel.preset && configModel.preset.name}
                                        onChange={(e) => {
                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let preset = pt.preset ? { ...pt.preset } : new PresetModel();
                                            preset.name = e.target.value;
                                            pt.preset = preset;
                                            setConfigModel(pt);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label required">Chọn Video quảng cáo</label>
                                    <TFUSelect
                                        options={afterEffectOptions}
                                        value={configModel && configModel.preset && configModel.preset.videoId}
                                        onChanged={(newValue) => {
                                            getAfterEffectConfigModelById(newValue);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="control-label required">Chọn ảnh đại diện</label>
                            <div className="image-preview">
                                <div className="text-center">
                                    {configModel.preset && configModel.preset.thumbnail && <img src={configModel.preset.thumbnail} alt="Ảnh đại diện" className="img-fluid" style={{ width: "100%", height: "100%", objectFit: "contain" }} />}
                                </div>
                                <input
                                    id="choose-file"
                                    type="file"
                                    className="d-none"
                                    onChange={(e) => {
                                        let file = e.target.files[0];
                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onloadend = () => {
                                            Request.UploadImage(file).then((res) => {
                                                if (res && res.data) {
                                                    let pt = configModel ? { ...configModel } : new ConfigModel();
                                                    let preset = pt.preset ? { ...pt.preset } : new PresetModel();
                                                    preset.thumbnail = res.data.imageUrl;
                                                    pt.preset = preset;
                                                    setConfigModel(pt);
                                                }
                                            });
                                        };
                                    }}
                                />
                            </div>
                            <div className="choose-file-area">
                                <label htmlFor="choose-file" className="choose-file">
                                    Chọn file
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <label className="required">Câu thoại AI</label>
                <div className="sub-header my-3">
                    - Số lượng câu thoại AI config trong Layer hiện tại là{" "}
                    {configModel && configModel.presetVideoLayers && configModel.presetVideoLayers.filter((x) => x.objectType == LayerType.AudioAI) && configModel.presetVideoLayers.filter((x) => x.objectType == LayerType.AudioAI).length} câu thoại.
                    <br />
                </div>
                {configModel &&
                    configModel.presetVideoLayers &&
                    configModel.presetVideoLayers.length > 0 &&
                    configModel.presetVideoLayers.map((item, index) => {
                        if (item.objectType == LayerType.AudioAI) {
                            return (
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label required">{item.afterEffectLayerName}</label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-none"
                                                value={item.objectData}
                                                onChange={(e) => {
                                                    let pt = configModel ? { ...configModel } : new ConfigModel();
                                                    let layers = pt.presetVideoLayers ? [...pt.presetVideoLayers] : [];
                                                    let layer = layers[index] ? { ...layers[index] } : new PresetVideoLayerModel();
                                                    layer.objectData = e.target.value;
                                                    layers[index] = layer;
                                                    pt.presetVideoLayers = layers;
                                                    setConfigModel(pt);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                <label className="required">Chọn nhạc nền mặc định</label>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUSelect
                                options={audioOptions}
                                value={configModel.presetVideoDetails && configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.AudioBack).objectData}
                                onChanged={(newValue) => {
                                    let pt = configModel ? { ...configModel } : new ConfigModel();
                                    let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                    let detail = details.find((item) => item.objectType == PresetVideoDetailType.AudioBack);
                                    detail.objectData = newValue;
                                    pt.presetVideoDetails = details;
                                    setConfigModel(pt);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <label className="required">Tùy chọn credit</label>
                <div className="row col-md-12">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label required">Credit</label>
                            <div className="credit-item">
                                <div className="from-time">
                                    <input
                                        type="number"
                                        className="form-control minute"
                                        placeholder="phút"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).fromTime &&
                                            convertSecondsToMinutes(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).fromTime)
                                        }
                                        maxLength={2}
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Credit);
                                            detail.fromTime = value * 60 + (detail.fromTime % 60);
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                    <input
                                        type="number"
                                        className="form-control second"
                                        placeholder="giây"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).fromTime &&
                                            convertSecondsToSeconds(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).fromTime)
                                        }
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Credit);
                                            detail.fromTime = convertSecondsToMinutes(+detail.fromTime) * 60 + value;
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                </div>
                                <span>Đến</span>
                                <div className="to-time">
                                    <input
                                        type="number"
                                        className="form-control minute"
                                        placeholder="phút"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).toTime &&
                                            convertSecondsToMinutes(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).toTime)
                                        }
                                        maxLength={2}
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Credit);
                                            detail.toTime = value * 60 + (detail.toTime % 60);
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                    <input
                                        type="number"
                                        className="form-control second"
                                        placeholder="giây"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).toTime &&
                                            convertSecondsToSeconds(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).toTime)
                                        }
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Credit);
                                            detail.toTime = convertSecondsToMinutes(+detail.toTime) * 60 + value;
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label required">Chọn vị trí xuất hiện</label>
                            <div className="input-xy">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="X"
                                    step={0.1}
                                    value={
                                        configModel.presetVideoDetails &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit) &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).x
                                    }
                                    onChange={(e) => {
                                        let pt = configModel ? { ...configModel } : new ConfigModel();
                                        let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                        let detail = details.find((item) => item.objectType == PresetVideoDetailType.Credit);
                                        detail.x = parseFloat(+e.target.value) || 0;
                                        pt.presetVideoDetails = details;
                                        setConfigModel(pt);
                                    }}
                                />
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Y"
                                    step={0.1}
                                    value={
                                        configModel.presetVideoDetails &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit) &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Credit).y
                                    }
                                    onChange={(e) => {
                                        let pt = configModel ? { ...configModel } : new ConfigModel();
                                        let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                        let detail = details.find((item) => item.objectType == PresetVideoDetailType.Credit);
                                        detail.y = parseFloat(+e.target.value) || 0;
                                        pt.presetVideoDetails = details;
                                        setConfigModel(pt);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <label className="required">Tùy chọn logo</label>
                <div className="row">
                    {/* item */}
                    <div className="col-md-4 border">
                        <div className="form-group">
                            <label className="control-label required">Logo</label>
                            <div className="credit-item">
                                <div className="from-time">
                                    <input
                                        type="number"
                                        className="form-control minute"
                                        placeholder="phút"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).fromTime &&
                                            convertSecondsToMinutes(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).fromTime)
                                        }
                                        maxLength={2}
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Logo);
                                            detail.fromTime = value * 60 + (detail.fromTime % 60);
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                    <input
                                        type="number"
                                        className="form-control second"
                                        placeholder="giây"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).fromTime &&
                                            convertSecondsToSeconds(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).fromTime)
                                        }
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Logo);
                                            detail.fromTime = convertSecondsToMinutes(+detail.fromTime) * 60 + value;
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                </div>
                                <span>Đến</span>
                                <div className="to-time">
                                    <input
                                        type="number"
                                        className="form-control minute"
                                        placeholder="phút"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).toTime &&
                                            convertSecondsToMinutes(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).toTime)
                                        }
                                        maxLength={2}
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Logo);
                                            detail.toTime = value * 60 + (detail.toTime % 60);
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                    <input
                                        type="number"
                                        className="form-control second"
                                        placeholder="giây"
                                        value={
                                            configModel.presetVideoDetails &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo) &&
                                            configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).toTime &&
                                            convertSecondsToSeconds(+configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).toTime)
                                        }
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value) || 0;
                                            if (value > 59) {
                                                return;
                                            }

                                            let pt = configModel ? { ...configModel } : new ConfigModel();
                                            let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                            let detail = details.find((item) => item.objectType == PresetVideoDetailType.Logo);
                                            detail.toTime = convertSecondsToMinutes(+detail.toTime) * 60 + value;
                                            pt.presetVideoDetails = details;
                                            setConfigModel(pt);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label required">Logo</label>
                            <div className="input-xy">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="X"
                                    step={0.1}
                                    value={
                                        configModel.presetVideoDetails &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo) &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).x
                                    }
                                    onChange={(e) => {
                                        let pt = configModel ? { ...configModel } : new ConfigModel();
                                        let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                        let detail = details.find((item) => item.objectType == PresetVideoDetailType.Logo);
                                        detail.x = parseFloat(e.target.value) || 0;
                                        pt.presetVideoDetails = details;
                                        setConfigModel(pt);
                                    }}
                                />
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Y"
                                    step={0.1}
                                    value={
                                        configModel.presetVideoDetails &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo) &&
                                        configModel.presetVideoDetails.find((item) => item.objectType == PresetVideoDetailType.Logo).y
                                    }
                                    onChange={(e) => {
                                        let pt = configModel ? { ...configModel } : new ConfigModel();
                                        let details = pt.presetVideoDetails ? [...pt.presetVideoDetails] : [];
                                        let detail = details.find((item) => item.objectType == PresetVideoDetailType.Logo);
                                        detail.y = parseFloat(e.target.value) || 0;
                                        pt.presetVideoDetails = details;
                                        setConfigModel(pt);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* create button to save */}
            <div className="col-md-12">
                <div className="form-group">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSaveConfig();
                        }}
                    >
                        Lưu
                    </button>
                </div>
            </div>
        </Wrapper>
    );
}

export default PresetConfig;

const Wrapper = styled.div`
    .card-box {
        padding: 20px;
    }
    .image-preview {
        height: 200px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #d9d9d9;
    }

    label.choose-file {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #ffffff;
        background: #36404c;
        padding: 5px 10px;
        margin-bottom: 0px;
    }

    .choose-file-area {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-center {
        height: 100%;
        width: 100%;
    }

    .credit-item {
        display: flex;
        margin: 10px 0;
        align-items: center;
    }

    .credit-item input[type="checkbox"] {
        margin: 0 5px;
    }

    .credit-item input.form-control {
        margin: 0 5px;
        border-radius: 0px;
    }

    .input-xy {
        display: flex;
        align-items: center;
    }

    .input-xy input.form-control {
        margin: 0 5px;
        border-radius: 0px;
    }

    .border-radius-none {
        border-radius: 0px;
    }

    .from-time {
        display: flex;
        width: 40%;
    }

    .to-time {
        display: flex;
        width: 40%;
    }
`;
