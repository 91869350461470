import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import VideoServices from "../based/services/VideoServices";
import useFormValidate from "../hooks/useFormValidate";

const AfterEffectModel = {
    id: 0,
    name: "",
    url: "",
    isDelete: false,
    createdBy: "",
    createdDate: null,
    modifiedBy: "",
    modifiedDate: null,
    videoType: 0
};

const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [model, setModel] = useState(AfterEffectModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const { id, onSaveContinue, onClose, isShowModal } = props;

    useEffect(() => {
        if (id > 0) _getTag(id);
        return () => setModel({});
    }, [id]);

    const _getTag = async (id) => {
        setIsBusy(true);
        let [err, data] = await VideoServices.GetAfterEffectById(id);
        if (!err && data) setModel({ ...data });
        else setModel(AfterEffectModel);
        setIsBusy(false);
    };

    const _handleSave = async () => {
        setIsBusy(true);
        let [err, data] = await VideoServices.CreateUpdateAfterEffect(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSaveContinue();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
        setIsBusy(false);
    };
    return (
        <CommonModal show={isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()}>
            <div className="form-group">
                <label htmlFor="name" className="required">
                    Tên hiển thị:
                </label>
                <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setModel({ ...model, name: value })} value={model.name} />
            </div>
            <div className="form-group">
                <label htmlFor="url" className="required">
                    Url:
                </label>
                <input
                    type={"file"}
                    onChange={(e) => {
                        let file = e.target.files[0];
                        if (file == null) return;
                        let extension = file.name.split(".").pop();
                        if (extension != "rar") {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Chỉ chấp nhận file .rar");
                            return;
                        }

                        if (file) {
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                                Request.UploadDocument(file).then((res) => {
                                    if (res && res.data) {
                                        setModel({ ...model, url: res.data.url });
                                        Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Upload thành công");
                                    }
                                });
                            };
                            reader.onerror = function (error) {
                                console.log("Error: ", error);
                            };
                        }
                    }}
                />
            </div>
            
        </CommonModal>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
