import { CommonState } from '../../models/CommonState';
import { AgencyModel } from '../../models/AgencyModel';
import { fetchBegin, fetchSuccess, fetchFail } from '../utils/fetch';
import { actionTypes } from './types';

// Initial Data
const commonState = new CommonState();
const INITIAL_STATE = { ...commonState, data: new AgencyModel() };
const INITIAL_ACTION = { type: '', payload: null };

// Reducer
const reducer = (state = INITIAL_STATE, action = INITIAL_ACTION) => {
  switch (action.type) {
    case actionTypes.CREATOR_INFO_AGENCY_FETCH_BEGIN:
      return fetchBegin(state);
    case actionTypes.CREATOR_INFO_AGENCY_FETCH_SUCCESS:
      return fetchSuccess(state, action.payload);
    case actionTypes.CREATOR_INFO_AGENCY_FETCH_FAIL:
      return fetchFail(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
