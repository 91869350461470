import vi from 'date-fns/locale/vi';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Common from '../based/Common';
import { NOTIFY, PRODUCT_STATUS_TYPE, STOCK_IN_STATUS } from '../based/Constants';
import CommonModal from '../based/Modal';
import { Notify } from '../based/Notify';
import TFUTooltip from '../based/TFUTooltip';
import TextArea from '../based/inputs/TextArea';
import Textbox from '../based/inputs/Textbox';
import AgencyServices from '../based/services/AgencyServices';
import StockOutServices from '../based/services/StockOutServices';
import WarehouseServices from '../based/services/WarehouseServices';
import { CustomerInfoModel, OrderModel, PlatformOrderPackageModel, StockOutContext, WarehouseInfoModel, WarehouseProductModel } from '../context/StockOutContext';
import useFormValidate from '../hooks/useFormValidate';
import { WarehouseOrdersModel } from '../models/WarehouseOrderModel';
import TransactionInfo from './TransactionInfo';
import CustomerInfo from './components/CustomerInfo';
import ScannedProductInfo from './components/ScannedProductInfo';
import StockOutModalItem from './components/StockOutModalItem';

const columns = [
  {
    displayName: 'Batch Code',
    name: 'batchCode',
    width: 180,
    toolTip: 'Batch Code là mã nhận dạng được gán cho một nhóm mặt hàng có cùng hạn sử dụng.',
  },
  {
    displayName: 'Barcode',
    name: 'barcode',
    width: 120,
  },
  {
    displayName: 'SKU',
    name: 'sellerSku',
    width: 100,
  },
  {
    displayName: 'Tên sản phẩm',
    name: 'productName',
    width: 150,
  },
  {
    displayName: 'Số lượng xuất',
    name: 'quantity',
    width: 80,
  },
  {
    displayName: 'Giá xuất',
    name: 'price',
    width: 120,
  },
  {
    displayName: 'Vị trí lưu kho',
    name: 'slotCode',
    width: 100,
    isRequired: true,
  },
  {
    displayName: 'Trạng thái',
    name: 'status',
    width: 100,
  },
  {
    displayName: '',
    width: 50,
  },
];

const key = ['orderId', 'batchCode', 'productVariantId', 'productStatus', 'warehouseId', 'slotCode'];

const MSG = {
  ValidQuantity: (quantity) => `Sản phẩm có số lượng lớn hơn số lượng ban đầu xác nhận (${quantity} cái). Xin vui lòng chọn lại.`,
  SUCCESS: 'Lưu thành công.',
  ERROR: 'Xin vui lòng kiểm tra và thử lại sau ít phút.',
  DuplicateValue: 'Hiện danh sách nhập kho đang bị trùng.',
  BarcodeScan: 'Hiện tại không có sản phẩm được quét trong đơn hàng.',
  MissingInvoice: 'Số hoá đơn không được để trống.',
  NullValue: 'Xin vui lòng không để trống các trường bắt buộc nhập.',
};

const NOTIFY_TITLE = { WARNING: 'Thông báo', SUCCESS: 'Thành công', ERROR: 'Lỗi', INVOICE: 'Hoá đơn' };

export default function StockOutModal(props) {
  const [isBusy, setIsBusy] = useState(false);
  const [msgModalLoading, setMsgLoading] = useState(true);
  const [warehouseInfo, setWarehouseInfo] = useState(new WarehouseInfoModel());
  const [customerInfo, setCustomerInfo] = useState(new CustomerInfoModel());
  const [products, setProducts] = useState([new WarehouseProductModel()]);
  const [isClearInput, setIsClearInput] = useState(false);
  const [orderBtnStatus, setOrderBtnStatus] = useState(props.orderBtnStatus);
  const [platformOrderPackages, setPlatformOrderPackages] = useState([new PlatformOrderPackageModel()]);
  const [transactionInfo, setTransactionInfo] = useState(new OrderModel());
  const [nextStatusButton, setNextStatusButton] = useState([]);

  const { addRef, displayError, isValid } = useFormValidate();

  var timer = null;

  useEffect(() => {
    if (props.orderId) _getOrderInfo(props.orderId);
  }, [props.orderId]);

  useEffect(() => {
    setOrderBtnStatus(props.orderBtnStatus);
    if (props.orderBtnStatus >= 0) _queryButton(props.orderBtnStatus);
  }, [props.orderBtnStatus]);

  async function _queryButton(btn) {
    let [err, data] = await WarehouseServices.QueryButtonDisplay(btn);
    if (!err) setNextStatusButton(data);
  }

  //lấy thông tin phiếu xuất
  async function _getOrderInfo(orderId) {
    setIsBusy(true);
    const [err, data] = await WarehouseServices.GetOrderDetail(orderId);
    if (!err) {
      setCustomerInfo(data.thirdParty);
      data.warehouseId = data.products && data.products.length > 0 ? data.products[0].warehouseId : 0;
      data.expectedTime = new Date(data.estimatedTime);
      setWarehouseInfo(data);
      var listProduct = data && data.products ? data.products : [];
      for (var i = 0; i < listProduct.length; i++) {
        let product = listProduct[i];
        product.hasExtendData = product.hasSerialNumber || product.hasImei || product.hasExpDate;
        product.isExpandExtendData = product.hasExtendData;
      }

      setProducts([...listProduct]);
      setIsBusy(false);
    }
  }

  //Key không được trùng và key phải khác null
  function _keyIsValid(updateProducts, key) {
    return new Promise((resolve, reject) => {
      let arrComparison = [];
      updateProducts.map((item, idx) => {
        let sub = [];
        sub = key.reduce((prev, ele, idx) => {
          let obj = item[ele];
          return [...prev, obj];
        }, []);
        return arrComparison.push(JSON.stringify(sub));
      });
      let filter = arrComparison.filter((item, index) => arrComparison.indexOf(item) != index);
      if (filter && filter.length > 0) return resolve({ success: false, message: MSG.DuplicateValue });
      let filterNull = arrComparison.filter((item, index) => {
        return item.includes('-1') || item.includes('null');
      });
      if (filterNull && filterNull.length > 0) return resolve({ success: false, message: MSG.NullValue });
      return resolve({ success: true });
    });
  }

  async function _handlePropertyChanged(index, name, value) {
    const currentProducts = [...products];
    const product = currentProducts[index];
    var tempValue = product[name];
    var tempExtendData = product.extendData;
    let result = await _handleKeyValidBeforeChanged(index, name, value);
    if (result) {
      switch (name) {
        case 'quantity':
          product[name] = +value;
          product.extendData.length = +value;
          if (warehouseInfo.statusId === STOCK_IN_STATUS.Approved) {
            // Initial quantity
            var validQuantity = 0;
            const [error, res] = await StockOutServices.GetApprovedQuantity(product.productId, product.productStatus, props.orderId, product.batchCode);
            if (!error && res) {
              validQuantity = res;
            }
            // Compare before and after quantity
            if (validQuantity < value) {
              product[name] = tempValue;
              product.extendData = tempExtendData;
              Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.ValidQuantity(validQuantity));
            }
          }
          break;
        case 'slotCode':
          product[name] = value;
          product.batchCode = '';
          break;
        case 'productStatus':
          product[name] = value;
          product.batchCode = '';
          break;
        default:
          product[name] = value;
          break;
      }
      setProducts([...products]);
    } else {
      Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.DuplicateValue);
    }
  }

  async function _handleDelivered() {
    products.forEach(function (i) {
      if (i.batchCode == '' || i.quantity == 0 || i.price == 0 || i.slotCode == '' || i.productStatus == null) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Chưa nhập đầy đủ thông tin!');
        return false;
      }
    });
    if (isValid()) {
      setMsgLoading('Đang lưu dữ liệu...');
      setIsBusy(true);
      //tiến hành chuyển trạng thái trên lazada
      let result = await _handleStatusToRTSFIFO();
      if (result) {
        //tiến hành lưu kho
        const [err, data] = await StockOutServices.DeliveredProducts({
          orderId: props.orderId,
          orderCode: warehouseInfo.orderCode,
          importTime: new Date(),
          products,
        });
        if (!err && data) {
          Notify(NOTIFY.SUCCESS, NOTIFY_TITLE.SUCCESS, MSG.SUCCESS);
          if (props.transactionId) props.onSaveAdvanced();
          else props.onSave();
        } else {
          if (err.errors) displayError(err.errors);
          else Notify(NOTIFY.ERROR, NOTIFY_TITLE.ERROR, MSG.ERROR);
        }
      }
      setIsBusy(false);
    }
  }

  function checkEmptyData(products) {
    products.forEach(function (i) {
      if (i.batchCode == '' || i.quantity == 0 || i.price == 0 || i.slotCode == '' || i.productStatus == null) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Chưa nhập đầy đủ thông tin!');
        return false;
      }
    });
    return true;
  }

  async function _handleUpdate() {
    if (!checkEmptyData(products)) {
      return false;
    }
    let result = await _keyIsValid(products, key);
    if (result && result.success) {
      let warehouse = { ...warehouseInfo };
      var model = new WarehouseOrdersModel();
      let refactProducts = products.map((item, idx) => {
        if (item.productStatus == PRODUCT_STATUS_TYPE.Defective) item.defectiveQuantity = item.quantity;
        if (item.productStatus == PRODUCT_STATUS_TYPE.Broken) item.brokenQuantity = item.quantity;
        return item;
      });
      model.id = props.orderId;
      model.expectedTimeStockOut = Common.formatDate(warehouseInfo.expectedTime, 'datetime');
      model.orderCode = warehouse.orderCode;
      model.remark = warehouseInfo.remark;
      model.thirdPartyId = customerInfo.id;
      model.products = refactProducts;
      model.statusId = warehouseInfo.statusId;
      let [err, data] = await StockOutServices.StockOut(model);
      setIsBusy(false);
      if (!err) {
        Notify(NOTIFY.SUCCESS, NOTIFY_TITLE.SUCCESS, MSG.SUCCESS);
        return true;
      } else {
        Notify(NOTIFY.ERROR, NOTIFY_TITLE.ERROR, MSG.ERROR);
        return false;
      }
    } else {
      Notify(NOTIFY.ERROR, NOTIFY_TITLE.ERROR, result && result.message ? result.message : MSG.ERROR);
    }
  }

  async function _handleKeyValidBeforeChanged(index, name, fValue) {
    return new Promise((resolve, reject) => {
      if (key.includes(name)) {
        let currentProducts = JSON.parse(JSON.stringify(products));
        let currentProduct = currentProducts[index];
        currentProduct[name] = +fValue || fValue;
        let newSet = new Set();

        currentProducts.map((item, idx) => {
          let comboKey = key.map((ite, index) => item[ite]);
          newSet.add(JSON.stringify(comboKey));
          return item;
        });

        if (currentProducts.length > newSet.size) return resolve(false);
        else return resolve(true);
      } else return resolve(true);
    });
  }

  function _handleBarcodeScanned(name, value) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      let updatedData = [...products];
      let filteredBarcode = updatedData.filter((item) => item.barcode === value);
      if (filteredBarcode && filteredBarcode.length > 0) {
        updatedData.map((item, index) => {
          if (item.barcode == value) item.isMatchingScan = true;
          return item;
        });
        setProducts(updatedData);
        setIsClearInput(true);
      } else {
        Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.BarcodeScan);
        setIsClearInput(true);
      }
    }, 1000);
  }

  function _handleAddProduct() {
    let subData = new WarehouseProductModel();
    subData.isAdded = true;
    subData.hasExpDate = products[0].hasExpDate;
    subData.hasImei = products[0].hasImei;
    subData.hasSerialNumber = products[0].hasSerialNumber;
    subData.extendData = [];
    subData.hasExtendData = products[0].hasExtendData;
    subData.isExpandExtendData = subData.hasExtendData;
    subData.warehouseId = warehouseInfo && warehouseInfo.warehouseId > 0 ? warehouseInfo.warehouseId : 0;
    let updateData = [...products, subData];
    setProducts(updateData);
  }

  function _handleRemoveProduct(index) {
    let updateData = [...products];
    updateData.splice(index, 1);
    setProducts(updateData);
  }

  async function _handleSelectProduct(value, idx) {
    let updateData = [...products];
    let product = updateData[idx];
    let selectedSKU = updateData.filter((item) => item.productId == value);
    // Nếu trong list sản phẩm đã có sản phẩm
    // Copy thông tin từ list ra
    // Nếu chưa có, call api để lấy thông tin sản phẩm
    if (selectedSKU && selectedSKU.length > 0) {
      let selectSku = selectedSKU[0];
      product.sellerSku = value;
      product.productName = selectSku.productName;
      product.price = selectSku.price;
      product.productVariantId = selectSku.productVariantId;
      product.isMatchingScan = selectSku.isMatchingScan;
      product.slotOptions = selectSku.slotOptions;
      product.warehouseId = selectSku.warehouseId;
      product.orderId = selectSku.orderId;
      product.barcode = selectSku.barcode;
      product.slotLength = selectSku.slotLength;
      product.batchCode = -1;
      setProducts(updateData);
    } else {
      let [err, data] = await WarehouseServices.GetProductDetails(value);
      if (!err) {
        product.sellerSku = data.sellerSku;
        product.barcode = data.barcode;
        product.productName = data.productName;
        product.batchCode = -1;
        setProducts(updateData);
      }
    }
  }

  async function _handleStatusToRTSFIFO() {
    if (platformOrderPackages && platformOrderPackages.length > 0) {
      let dataOrders = [...platformOrderPackages];
      let invoiceNumbers = dataOrders.map((value) => value.invoiceNumber);
      if (invoiceNumbers.includes(null) || invoiceNumbers.includes('')) {
        Notify(NOTIFY.WARNING, NOTIFY_TITLE.INVOICE, MSG.MissingInvoice);
        return false;
      } else {
        let platform = transactionInfo.platform;
        let shopAccount = transactionInfo.shopName;
        let request = platformOrderPackages;

        let [err, data] = await AgencyServices.HandleStatusToRTSFIFO(platform, shopAccount, request);
        if (!err) return true;
        else {
          Notify(NOTIFY.ERROR, NOTIFY_TITLE.ERROR, err.message);
          return false;
        }
      }
    } else return true;
  }

  function _renderCustomButton() {
    let content = null;
    content = (
      <React.Fragment>
        {nextStatusButton && nextStatusButton.length > 0
          ? nextStatusButton.map((item, idx) => {
              let clsBtn = item.buttonClass;
              return (
                <Button
                  key={idx}
                  className={`btn ${clsBtn} waves-effect w-md waves-light btn-sm`}
                  onClick={async () => {
                    // Nếu button có sự kiện nhận hàng sẽ bắt function nhận hàng
                    // Nếu không sẽ tiến hành update thông tin đơn hàng và chuyển trạng thái đơn hàng
                    if (item.value == STOCK_IN_STATUS.CompletelyReceived) _handleDelivered();
                    else {
                      let result = await _handleUpdate();
                      if (result) {
                        props.handleChangeStatus(props.orderId, item.value);
                        props.onClose(true);
                      }
                    }
                  }}
                >
                  {item.label}
                </Button>
              );
            })
          : null}
      </React.Fragment>
    );
    return content;
  }

  return (
    <CommonModal size="tfulg" show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msgModalLoading} title="Xuất kho" onClose={() => props.onClose()} customButton={_renderCustomButton()}>
      <StockOutContext.Provider
        value={{
          warehouseInfo,
          setWarehouseInfo,
          customerInfo,
          setCustomerInfo,
          products,
          setProducts,
          platformOrderPackages,
          setPlatformOrderPackages,
          transactionInfo,
          setTransactionInfo,
        }}
      >
        <div className="container-fluid p-0">
          <div className="form-row">
            <div className="col-md-12 m-b-10">
              <div className="card-box margin-bottom-10 padding-10 h-100">
                <CustomerInfo readOnly={true} />
              </div>
            </div>
            {props.transactionId != null ? (
              <div className=" col-md-6 m-b-10">
                <div className="card-box margin-bottom-10 padding-10 col-md-12 h-100">
                  <TransactionInfo platformOrderPackages={platformOrderPackages} transactionId={props.transactionId} />
                </div>
              </div>
            ) : null}
          </div>

          <div className="form-row">
            <div className="col-md-12">
              <div className="card-box margin-bottom-10 padding-10">
                <div className="col-md-12">
                  <div className="row">
                    <h4 className="header-title font-weight-bold" style={{ margin: '0 0 10px 0' }}>
                      Thông tin xuất kho
                    </h4>
                    <div className="full-width row">
                      <div className="form-group col-md-2">
                        <label className="col-form-label">Số chứng từ</label>
                        <div>
                          <Textbox name="orderCode" value={warehouseInfo.orderCode} onChanged={(name, value) => setWarehouseInfo({ ...warehouseInfo, orderCode: value })} readOnly />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label className="col-form-label">Ngày dự kiến xuất hàng</label>
                        <DatePicker
                          selected={warehouseInfo.expectedTime}
                          adjustDateOnChange
                          locale={vi}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          showTimeSelect
                          timeCaption="time"
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="dd/MM/yyyy HH:mm"
                          dropdownMode="select"
                          popperPlacement="auto"
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <ScannedProductInfo isClearInput={isClearInput} setClearInput={() => setIsClearInput(false)} onChanged={_handleBarcodeScanned} readOnly={props.readOnly} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-box margin-bottom-10">
            <div>
              <table className="table table-bordered m-b-0">
                <thead className="thead-light">
                  <tr>
                    {columns.map((item, idx) => {
                      let required = <span style={{ color: '#e62e04' }}> * </span>;
                      let tfTip = item.toolTip ? (
                        <TFUTooltip position="top" content={item.toolTip} target="batchCode">
                          <i className="fa fa-question-circle m-l-5" />
                        </TFUTooltip>
                      ) : (
                        ''
                      );
                      return (
                        <th key={idx} name={item.name} id={item.name} className="cursor-pointer center" width={item.width}>
                          {item.displayName}
                          {item.isRequired ? required : ''}
                          {tfTip}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {products && products.length > 0
                    ? products.map((product, index) => (
                        <StockOutModalItem
                          key={index}
                          ref={addRef}
                          name={`products[${index}]`}
                          index={index}
                          orderStatusId={warehouseInfo.statusId}
                          product={product}
                          products={products}
                          warehouseId={warehouseInfo.warehouseId}
                          orderId={props.orderId}
                          readOnly={props.readOnly}
                          handleSelectProduct={_handleSelectProduct}
                          handlePropertyChanged={_handlePropertyChanged}
                          handleRemoveProduct={_handleRemoveProduct}
                          orderBtnStatus={orderBtnStatus}
                        />
                      ))
                    : ''}
                  {orderBtnStatus == STOCK_IN_STATUS.PendingApproval || orderBtnStatus == STOCK_IN_STATUS.InboundingProcess ? (
                    <tr>
                      <td colSpan="12" className="text-right">
                        <button className="btn btn-custom btn-sm" onClick={_handleAddProduct}>
                          Thêm
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-group">
            <label className="col-form-label font-weight-bold">Ghi chú</label>
            <TextArea name="remark" className="form-control" value={warehouseInfo.remark} onChanged={(name, value) => setWarehouseInfo({ ...warehouseInfo, remark: value })} />
          </div>
        </div>
      </StockOutContext.Provider>
    </CommonModal>
  );
}
