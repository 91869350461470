import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import BaseBottomBarModal from "../based/BaseBottomBarModal";
import OrderServices from "../based/services/OrderServices";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";

function QRInput({ show, onClose }) {
    const [qrScan, setQrScan] = useState("");
    const history = useHistory();
    const inputRef = useRef(null);

    useEffect(() => {
        if (show) {
            inputRef.current.focus();
        }
    }, [show]);

    const maxHeight = useMemo(() => {
        return (80 / window.visualViewport.height) * 100;
    }, [window.visualViewport.height]);

    const handleQRScan = async (orderNumber) => {
        const [err, data] = await OrderServices.GetOrderTupleByOrderNumber(orderNumber);
        if (!err && data) {
            const status = data.item1;
            const msg = data.item2;

            //nếu status là fail thì hiển thị thông báo lỗi
            if (!status) {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
            } else {
                //nếu status là success thì chuyển hướng đến trang tạo đơn hàng
                window.location.href = `/logistic-create/${orderNumber}`; //chuyển hướng trang
            }
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, `Không tìm thấy đơn hàng ${orderNumber}`);
        }
    };

    return (
        <BaseBottomBarModal isShow={show} onClose={onClose} maxHeight={maxHeight}>
            <Wrapper>
                <div className="bottom-bar">
                    <div className="input-bar">
                        <input
                            type="text"
                            placeholder="Nhập hoặc quét mã"
                            value={qrScan}
                            onChange={(e) => {
                                let val = e.target.value;
                                setQrScan(val);
                                if (val.length === 6) {
                                    handleQRScan(val);
                                }
                            }}
                            ref={inputRef}
                        />
                        <div
                            className="enter-button"
                            onClick={(e) => {
                                handleQRScan(qrScan);
                            }}
                        >
                            Nhập
                        </div>
                    </div>
                    <div
                        className="close-btn"
                        onClick={(e) => {
                            onClose();
                        }}
                    >
                        <div className="icon">
                            <CloseIcon />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </BaseBottomBarModal>
    );
}

export default QRInput;

const Wrapper = styled.div`
    padding: 10px 0 10px 0;

    .bottom-bar {
        display: flex;
        align-items: center;
        height: 56px;
    }

    .close-btn {
        display: flex;
        margin: 0 10px 0 auto;
    }

    .icon {
        display: flex;
    }

    .input-bar {
        display: flex;
        align-items: center;
        border-radius: 12px;
        border: 1px solid #ededed;
        background: #fff;
        width: 100%;
    }

    .input-bar input[type="text"] {
        border: none;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        padding: 19px;
    }

    .enter-button {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 16px 38px;
        border-radius: 0px 12px 12px 0px;
        border: 1px solid #ededed;
        background: #28b259;
    }
`;

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
