export class UserModel {
    constructor() {
        // Basic Info
        this.id = 0;
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.birthday = new Date(1990, 1, 1);
        this.avatar = "";
        this.roleName = "";
        this.userName = "";

        // Extra Info
        this.phoneNumber = "";
        this.address = "";
        this.provinceCode = "";
        this.districtCode = "";
        this.wardCode = "";
    }
}
