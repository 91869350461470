export default class FlexiComboModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.displayTime = new Date();
        this.startTime = new Date();
        this.endTime = new Date();
        this.flexiComboType = 0;
        this.flexiComboValueType = 0;
        this.flexiComboProductApplies = [];
        this.flexiComboProductGifts = [];
        this.appliedOption = 1;
        this.idsOption = [];
        this.flexiComboTypeName = "";
        this.flexicomboFrame = "";
        this.isUseVoucherCode = false;
        this.voucherCode = "";
    }
}
