import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { SearchRounded } from '../content/ct.elm';
import Button from './Button';
const HeaderFilter = ({ handleBasicSearch, btnColor, btnWidth, color, width, label }) => {
    const basicSearchRef = useRef()
    const [keyword, setKeyword] = useState('')
    const handleBlur = () => {
        let target = basicSearchRef.current;
        if (target.value && target.value.length > 0) {
            setKeyword(target.value.trim())
        }
    }
    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            var key = basicSearchRef.current.value;
                key = key.trim();
            handleBasicSearch(key);
        }
    }
    return (
        <SearchRounded width={`${width ? width : '240px'}`}>
            <input type='text' ref={basicSearchRef}   onBlur={handleBlur} onKeyPress={handleKeyPress} />
            <Button btnType={5} label={label} onClick={() => handleBasicSearch ? handleBasicSearch(basicSearchRef.current.value) : {}} />
        </SearchRounded>
    );
};
HeaderFilter.propTypes = {
    handleBasicSearch: PropTypes.func,
    btnColor: PropTypes.string,
    btnWidth: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
};
export default HeaderFilter;
