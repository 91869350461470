import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../../based/Breadcrumbs";
import Common from "../../based/Common";
import CommonTable from "../../based/CommonTable";
import { NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import CheckBox from "../../based/inputs/CheckBox";
import AudioServices from "../../based/services/AudioServices";
import AddOrUpdateAudio from "./AddOrUpdateAudio";

export const initAudio = {
    id: 0,
    name: "",
    url: "",
    duration: 0,
    isDelete: false,
    createdBy: "",
    createdDate: new Date(),
    modifiedBy: "",
    modifiedDate: new Date(),
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

function AudioManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [selectId, setSelectId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await AudioServices.GetAudioByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };
    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await AudioServices.DeleteAudio(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    };
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox id="select_all" name="select_all" checked={data && data.length > 0 && data.filter((x) => x.selected).length === data.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th className="cursor-pointer">Name</th>
                    <th className="cursor-pointer">Url</th>
                    <th className="cursor-pointer">Thời lượng</th>
                    <th className="cursor-pointer">Thông tin upload</th>
                    <th className="cursor-pointer">Thông tin chỉnh sửa</th>
                    <th className="cursor-pointer">Hành động</th>
                </tr>
            </thead>
        );
    }
    function convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedTime = `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
        return formattedTime;
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    <strong>{item.name}</strong>
                                </td>
                                <td>
                                    <a href={item.url} target="_blank">
                                        {item.url}
                                    </a>
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>{convertSecondsToMinutes(item.duration)}</td>
                                <td style={{ width: 300, textAlign: "center" }}>
                                    <div>Người tạo: {item.createdBy}</div>
                                    <div>Ngày tạo: {Common.formatDate(new Date(item.createdDate), "fulldate")}</div>
                                </td>
                                <td style={{ width: 300, textAlign: "center" }}>
                                    {item.modifiedBy ? (
                                        <div>
                                            <div>Người sửa: {item.modifiedBy}</div>
                                            <div>Ngày sửa: {Common.formatDate(new Date(item.modifiedDate), "fulldate")}</div>
                                        </div>
                                    ) : (
                                        "Chưa chỉnh sửa"
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>

                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger ml-3" : "btn btn-danger ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý audio" />
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={data} paging={paging} ref={tableRef} headerCheckbox searchBasic hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getData} />
            {isShowModal && (
                <AddOrUpdateAudio
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </React.Fragment>
    );
}

const Wrapper = styled.div``;
AudioManagement.propTypes = {};

export default AudioManagement;
