import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Loading from "../based/Loading";
import SizeServices from "../based/services/SizeServices";
import SizeOptionItem from "./SizeOptionItem";
import SizeBoardItem from "./SizeBoardItem";
import SizeBoardDetailItem from "./SizeBoardDetailItem";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

function SizeOptionManagement() {
    const [sizeBoard, setSizeBoard] = useState([])
    const [pagingBoard, setPagingBoard] = useState({...initPage})
    const [sizeBoardDetail, setSizeBoardDetail] = useState([])
    const [pagingBoardDetail, setPagingBoardDetail] = useState({...initPage})
    const [isBusy, setBusy] = useState(true);
    const tableBoard = useRef(null);
    const tableBoardDetail = useRef(null);
    const [msg, setMsg] = useState("");
    const [dataDropDown, setDataDropDown] = useState([])
    const [dataSizeValue, setDataSizeValue] = useState([])
    

    
    useEffect(()=>{
        getDataDropDown()
        getDataSizeValue()
        getSizeBoard(pagingBoard)
        getSizeBoardDetail(pagingBoardDetail)
    },[])
    const getDataDropDown = async () =>{
        setBusy(true)
        let [err , data] = await SizeServices.GetSizeDropDown()
        if(!err && data){
            setDataDropDown(data)
            setBusy(false)
        }else{
            setDataDropDown([])
            setBusy(false)
        }
    } 
    const getDataSizeValue = async () => {
        setBusy(true)
        let [err , data] = await SizeServices.GetSizeValueDropDown()
        if(!err && data){
            setDataSizeValue(data)
            setBusy(false)
        }else{
            setDataSizeValue([])
            setBusy(false)
        }
    }
   

    const getSizeBoard = async (paging) =>{
        setBusy(true)
        let [err , data] = await SizeServices.GetSizeBoardByPage(paging)
        if(!err && data){
            setSizeBoard(data.listObjects)
            setPagingBoard(data.paging)
            setBusy(false)
        }else{
            setSizeBoard([])
            setPagingBoard({...initPage})
            setBusy(false)
        }
    }
   

    const getSizeBoardDetail = async (paging) =>{
        setBusy(true)
        let [err , data] = await SizeServices.GetSizeBoardDetailByPage(paging)
        if(!err && data){
            setSizeBoardDetail(data.listObjects)
            setPagingBoardDetail(data.paging)
            setBusy(false)
        }else{
            setSizeBoardDetail([])
            setPagingBoardDetail({...initPage})
            setBusy(false)
        }
    }
   
   
    

    return ( 
        <Wapper>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage={"Quản lý size option"}/>
            
             <div className="column-option">
                <div className="col-w100">
                    <div className="item">
                    <SizeBoardItem 
                        title={"Size Board"}
                        data={sizeBoard}
                        setBusy={setBusy} setData={setSizeBoard}
                        dataDropDown={dataDropDown}
                        deleteSize={SizeServices.DeleteSizeBoard} 
                        getData={getSizeBoard} paging={pagingBoard} 
                        tableRef={tableBoard}
                        dataSizeValue={dataSizeValue}
                        createOrUpdate={SizeServices.CreateUpdateSizeBoard}
                        getById={SizeServices.GetSizeBoardById} />
                    </div>
                </div>
                <div className="col-w100">
                    <div className="item">
                    <SizeBoardDetailItem
                        title={"Size Board Detail"}
                        data={sizeBoardDetail}
                        dataDropDown={dataDropDown}
                        setBusy={setBusy} setData={setSizeBoardDetail} 
                        deleteSize={SizeServices.DeleteSizeBoardDetail} 
                        getData={getSizeBoardDetail} paging={pagingBoardDetail} 
                        tableRef={tableBoardDetail}
                        dataSizeValue={dataSizeValue}
                        createOrUpdate={SizeServices.CreateUpdateSizeBoardDetail}
                        getById={SizeServices.GetSizeBoardDetailById} />
                    </div>
                </div>
             </div>
        </Wapper>
    );
}
const Wapper = styled.div`
    .column-option{
        display: flex;
        flex-wrap: wrap;
        .col-w100{
            width: 100%;
            min-width: 100%;
            max-width: 100%;


            .item{
                width: 100%;
                margin-bottom: 15px;

            }
        }
        .col-w50{
            width: 50%;
            min-width: 50%;
            max-width: 50%;


            .item{
                width: 100%;
                margin-bottom: 15px;

            }
        }
    }
`
export default SizeOptionManagement;