import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import { ShopCatDTO } from "./GeneralInformation";
import PlatformServices from "../based/services/PlatformServices";
import Request from "../based/Request";
import AddProductShopCatModal, { shopCatProductModel } from "./AddProductShopCatModal";
import Common from "../based/Common";
import CommonTablePagination from "../based/CommonTablePagination";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";

function EditShopCatModal({ show, onClose, shopCatId, onSave, shopId, platform }) {
    const [model, setModel] = useState(ShopCatDTO);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [listModel, setListModel] = useState([shopCatProductModel]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageSize: 20, shopCatId: shopCatId });

    useLayoutEffect(() => {
        setListModel([]);
    }, []);

    useEffect(() => {
        if (shopCatId > 0) {
            getModel(shopCatId);
            getListModel({ ...paging, shopCatId: shopCatId });
        }
    }, [show]);

    //lấy thông tin danh mục
    const getModel = async (shopCatId) => {
        const [err, data] = await PlatformServices.GetShopCatById(shopCatId);
        if (!err && data) {
            setModel(data);
        } else {
            setModel({});
        }
    };

    //lấy ds sản phẩm trong connection
    const getListModel = async (page) => {
        const [err, data] = await PlatformServices.GetShopCatProductModelsByShopCatId({ ...page, shopCatId: shopCatId });
        if (!err && data) {
            setListModel(data.listObjects);
            setPaging(data.paging);
        }
    };

    const updateShopCat = async () => {
        const [err, data] = await PlatformServices.CreateUpdateShopCat(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật danh mục thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra, vui lòng thử lại sau");
        }
    };

    //handle upload ảnh
    const handleImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            Request.UploadImage(file).then((res) => {
                if (res && res.data) {
                    setModel({ ...model, thumbnail: res.data.imageUrl });
                }
            });
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    //remove connection
    const removeShopCat = async (id, shopId, platform) => {
        const [err, data] = await PlatformServices.RemoveShopCatConnection(id, shopId, platform);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Xóa kết nối thành công");
            getListModel(paging);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra, vui lòng thử lại sau");
        }
    };

    return (
        <React.Fragment>
            <CommonModal
                title={"Chỉnh sửa danh mục"}
                show={show}
                onClose={onClose}
                onSave={() => {
                    updateShopCat();
                }}
                size="lg"
            >
                <Wrapper>
                    <div className="header_info">
                        <div className="header_info-left">
                            <div className="avatar">{model.thumbnail && <img src={model.thumbnail} />}</div>
                            <div className="name">
                                <label htmlFor="" className="required">
                                    Tên danh mục
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={model.name}
                                    onChange={(e) => {
                                        setModel({ ...model, name: e.target.value });
                                    }}
                                />
                                <div
                                    className="upload-btn"
                                    onClick={(e) => {
                                        document.getElementById("upload-image-input").click();
                                    }}
                                >
                                    <div className="icon">
                                        <UploadIcon />
                                    </div>
                                    <span>Tải ảnh lên</span>
                                </div>
                                <input
                                    type="file"
                                    className="d-none"
                                    onChange={(e) => {
                                        handleImage(e);
                                    }}
                                    id="upload-image-input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="section_product">
                        <div className="section_product-top">
                            <div className="title">Danh sách sản phẩm</div>
                            <div className="add-product">
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        setShowAddProduct(true);
                                    }}
                                >
                                    Thêm sản phẩm
                                </button>
                            </div>
                        </div>
                        <div className="section_product-content">
                            <div className="list-item">
                                {listModel && listModel.length > 0 ? (
                                    listModel.map((item, idx) => {
                                        return (
                                            <div className={`item ${item.isChecked ? "active" : ""}`} key={idx}>
                                                <div className="image">{item.avatar && <img src={item.avatar} alt={item.name} />}</div>
                                                <div className="name">{item.name}</div>
                                                <div className="price">
                                                    {Common.formatCurrency(item.minPrice)} - {Common.formatCurrency(item.maxPrice)}
                                                </div>

                                                <div
                                                    className="remove-icon"
                                                    onClick={(e) => {
                                                        removeShopCat(item.connectionId, shopId, platform);
                                                    }}
                                                >
                                                    <XIcon />
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="no-data">Không có dữ liệu</div>
                                )}
                            </div>
                            <div className="paging-bottom">
                                <CommonTablePagination
                                    paging={paging}
                                    onChangePage={(page) => {
                                        const newPaging = { ...paging, pageNumber: page };
                                        getListModel(newPaging);
                                    }}
                                    onChangePageSize={(pageSize) => {
                                        const newPaging = { ...paging, pageNumber: 1, pageSize: pageSize };
                                        getListModel(newPaging);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </CommonModal>
            {model && (
                <AddProductShopCatModal
                    show={showAddProduct}
                    onClose={() => setShowAddProduct(false)}
                    shopId={model.shopId}
                    platform={model.platform}
                    shopCatId={shopCatId}
                    platformShopCatId={model.platformShopCatId}
                    onSave={() => {
                        getModel(shopCatId);
                        getListModel(paging);
                        setShowAddProduct(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default EditShopCatModal;

const Wrapper = styled.div`
    .item {
        margin: 8px;
        width: 190px;
        display: flex;
        flex-direction: column;
    }

    .name {
        margin: 6px 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .price {
        margin-top: auto;
    }

    .header_info {
        display: flex;
    }

    .avatar {
        width: 130px;
        height: 130px;
    }

    .avatar img {
        width: 130px;
        height: 130px;
        object-fit: contain;
    }

    .header_info-left {
        display: flex;
    }

    .name {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
    }

    .upload-btn {
        display: flex;
        margin-top: auto;
        background: #f1f1f1;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
    }

    .upload-btn .icon {
        margin-right: 8px;
    }

    .upload-btn span {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .section_product-top {
        display: flex;
        align-items: center;
    }

    .add-product {
        margin-left: auto;
    }

    .section_product {
        margin-top: 18px;
    }

    .title {
        color: #444;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
    }

    .list-item {
        display: flex;
        flex-wrap: wrap;
    }

    .image {
        width: 172px;
        height: 172px;
        flex-shrink: 0;
    }

    .image {
    }

    .image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 8px;
        position: relative;
    }

    .item .name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 0;
    }

    .price {
        color: #f96a74;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .remove-icon {
        display: flex;
        position: absolute;
        background: #d70000;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        top: 5px;
        right: 5px;
    }

    .remove-icon .icon {
        display: flex;
    }
`;

const UploadIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5 15V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V15"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M17.5 8L12.5 3L7.5 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.5 3V15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const XIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
