import PubSub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY, RETURN_ORDER_STATUS, RETURN_ORDER_STATUS_NAME_ARR } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import Language from "../based/Language";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import ReturnRequestService from "../based/services/ReturnRequestService";

const TicketReturnOrder = (props) => {
    const columns = [
            { name: "title", displayName: "Yêu cầu" },
            { name: "description", displayName: "Lý do" },
            { name: "createdByName", displayName: "Khách hàng" },
            { name: "orderId", displayName: "Số đơn hàng" },
            { name: "commented", displayName: "Phản hồi mới nhất" },
            { name: "status", displayName: "Trạng thái" },
        ],
        initPage = { pageSize: 10, pageNumber: 1, keyword: "" },
        [paging, setPaging] = useState(initPage),
        [busy, setBusy] = useState({ isBusy: true, busyMsg: "" }),
        [datas, setDatas] = useState([]),
        tableRef = useRef(null),
        [tableGuid, setTableGuid] = useState(Common.generateGuid()),
        [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        setBusy({ isBusy: false, busyMsg: "" });
        getDatas(paging);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ReturnRequest });
    }, []);

    const getDatas = async (newPaging) => {
        setBusy({ isBusy: true, busyMsg: "Đang tải dữ liệu..." });
        const [err, data] = await ReturnRequestService.GetTickets(newPaging);
        if (!err && data) {
            setPaging(data.paging);
            setDatas(data.listObjects);
            setTableGuid(Common.generateGuid());
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };
    const renderTableAction = () => (
        <div className="row">
            <div className="col-sm-12">
                <div className="row flex-row p-y-10">
                    <div className="w-110px">
                        <div className="form-group">
                            <button className="btn btn-danger btn-sm" disabled={datas.filter((x) => x.checked && x.status !== RETURN_ORDER_STATUS.Closed).length !== 1} onClick={handleCloseTicket}>
                                <i className="fa fa-close m-r-5" />
                                Đóng yêu cầu
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleCloseTicket = async () => {
        var ticket = datas.filter((x) => x.checked)[0];
        setBusy({ isBusy: true, busyMsg: "Đang lưu dữ liệu..." });
        const [err, data] = await ReturnRequestService.CloseTicket(ticket.id);
        if (!err && data) {
            getDatas(paging);
            Notify(NOTIFY.SUCCESS, "Thành công", CONSTANTS.MSG_SUCCESS);
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox name="select_all" label="" checked={selectAll} onCheckedChange={(name, value) => handleCheckedAll(value)} />
                    </th>
                    {columns.map((column, i) => {
                        return (
                            <th key={i} className="cursor-pointer" width={column.width ? column.width : "auto"}>
                                <span>{column.displayName}</span>
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    };

    const handleCheckedAll = (value) => {
        setSelectAll(value);
        var newDatas = datas.map((item) => {
            item.checked = value;
            return item;
        });
        setDatas(newDatas);
    };

    const renderTableBody = () => {
        if (datas && datas.length > 0)
            return (
                <tbody>
                    {datas.map((item, i) => {
                        return (
                            <tr key={i} onClick={() => handleClickRow(i)}>
                                <td width={40} className={"center"}>
                                    <CheckBox id={item.id.toString()} name={i.toString()} label="" checked={!!item.checked} onCheckedChange={(name, value) => handleChecked(item, i, value)} />
                                </td>
                                {columns.map((column, j) => {
                                    switch (column.name) {
                                        case "title":
                                            return (
                                                <td className="center" key={j}>
                                                    <a href="javascript:void(0)" onClick={() => props.history.push("/ticket-return-order-detail/" + item.id)}>
                                                        {item[column.name]} <i className="mdi mdi-open-in-new" />
                                                    </a>
                                                </td>
                                            );
                                        case "status":
                                            return (
                                                <td className="center" key={j}>
                                                    {RETURN_ORDER_STATUS_NAME_ARR[item[column.name]]}
                                                </td>
                                            );
                                        default:
                                            return (
                                                <td className="center" key={j}>
                                                    {item[column.name]}
                                                </td>
                                            );
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            );
        let columnCount = columns ? columns.length + 1 : 10000000;
        return (
            <tbody>
                <tr>
                    <td colSpan={columnCount} className="center">
                        {Language.getValue("common.noRecords")}
                    </td>
                </tr>
            </tbody>
        );
    };

    const handleClickRow = (rowIndex) => {
        var newDatas = [...datas];
        newDatas[rowIndex].checked = !newDatas[rowIndex].checked;
        setDatas(newDatas);
    };

    const handleChecked = (item, idx, value) => {
        var newDatas = [...datas];
        newDatas[idx].checked = value;
        setDatas(newDatas);
        setSelectAll(newDatas.filter((x) => x.checked).length === newDatas.length);
    };

    return (
        <React.Fragment>
            <Loading show={busy.isBusy} msg={busy.busyMsg} />
            <Breadcrumbs currentPage="Quản lý đổi trả hàng" />
            <CommonTable data={datas} paging={paging} ref={tableRef} tableGuid={tableGuid} searchBasic onFilter={getDatas} renderAction={renderTableAction} renderHeader={renderTableHeader} renderBody={renderTableBody} />
        </React.Fragment>
    );
};

export default TicketReturnOrder;
