import React, { useState, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { CONSTANTS, NOTIFY, BANKACCOUNT_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import Textbox from "../based/inputs/Textbox";
import CheckBox from "../based/inputs/CheckBox";
import PhoneNumber from "../based/inputs/PhoneNumber";
import TextArea from "../based/inputs/TextArea";
import TFUSelectSubdivisions from "../based/TFUSelectSubdivisions";
import useFormValidate from "../hooks/useFormValidate";
import CreatorServices from "../based/services/CreatorServices";
import CommonServices from "../based/services/CommonServices";
import BankServices from "../based/services/BankServices";
import TFUSelect from "../based/refactor/TFUSelect";

const TABS = {
    PersonalInfo: 1,
    BankConfig: 2,
};
const initUser = {
    id: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    provinceCode: "",
    districtCode: "",
    wardCode: "",
    birthDate: new Date(),
};

export default function CustomerModal({ creator, isShowModal, onSaveSucceed, onClose }) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [currentUser, setCurrentUser] = useState(initUser);
    const [tabIndex, setTabIndex] = useState(TABS.PersonalInfo);
    const { addRef, isValid } = useFormValidate();
    const [bankList, setBankList] = useState([]);
    const [bankAccount, setBankAccount] = useState({});
    const [momoAccount, setMomoAccount] = useState({});

    useEffect(() => {
        if (creator) setCurrentUser(creator ? creator : initUser);
        setTabIndex(TABS.PersonalInfo);
    }, [isShowModal]);

    async function _handleSave() {
        if (isValid()) {
            let obj = { ...currentUser };
            if (obj != null) {
                setMsgModalLoading("Đang lưu dữ liệu...");
                const [err, data] = await CreatorServices.SaveCreator(obj);
                if (!err && data) {
                    Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                    onSaveSucceed();
                } else {
                    Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                }
            }
        }
    }
    function _handleClickTabBankConfig() {
        setTabIndex(TABS.BankConfig);
        if (!bankList || bankList.length == 0) getBanks();
        getCreatorBankAccounts();
    }
    const getBanks = async () => {
        setIsModalLoading(true);
        setMsgModalLoading("Đang tải dữ liệu...");
        const [err, data] = await CommonServices.GetBanks();
        if (!err && data) {
            var bankList = data.map((item) => {
                item.value = item.value;
                item.label = item.text;
                return item;
            });
            setBankList(bankList);
            setIsModalLoading(false);
            setMsgModalLoading(null);
        } else {
            setIsModalLoading(false);
            setMsgModalLoading(null);
        }
    };
    const getCreatorBankAccounts = async () => {
        const [err, data] = await BankServices.GetCreatorBankAccounts(currentUser.userName);
        if (!err && data) {
            for (var ba of data) {
                if (ba.bankAccountType === BANKACCOUNT_TYPE.Bank) {
                    setBankAccount(ba);
                }
                if (ba.bankAccountType === BANKACCOUNT_TYPE.Momo) {
                    setMomoAccount(ba);
                }
            }
        }
    };
    const TabHeader = useMemo(() => (
        <div className="p-b-10">
            <ul className="nav nav-tabs tabs-bordered nav-justified">
                <li className="nav-item">
                    <a className={tabIndex == TABS.PersonalInfo ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setTabIndex(TABS.PersonalInfo)}>
                        <span className="d-none d-sm-block">Thông tin cá nhân</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == TABS.BankConfig ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleClickTabBankConfig()}>
                        <span className="d-none d-sm-block">Thông tin chuyển khoản</span>
                    </a>
                </li>
            </ul>
        </div>
    ));
    return (
        <CommonModal
            size="lg"
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={creator && creator.id > 0 ? "Chỉnh sửa thông tin nhà sáng tạo" : "Thêm nhà sáng tạo"}
            onSave={() => _handleSave()}
            onClose={() => onClose()}
        >
            {TabHeader}
            {tabIndex == TABS.PersonalInfo ? (
                <div className="form-row">
                    <div className="col-6 row">
                        <div className="col-12 mt-2">
                            <label htmlFor="firstName">
                                Họ và tên: <span className="required-field-icon">*</span>
                            </label>
                            <Textbox
                                id="fullName"
                                name="fullName"
                                required
                                maxLength={255}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                className="form-control"
                                onChanged={(name, value) => {
                                    let creatorUpdate = { ...currentUser, fullName: value };
                                    setCurrentUser(creatorUpdate);
                                }}
                                value={currentUser.fullName}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="phoneNumber" className="required">
                                Điện thoại:
                            </label>
                            <PhoneNumber
                                id="phoneNumber"
                                name="phoneNumber"
                                addRef={addRef}
                                required
                                value={currentUser.phoneNumber}
                                onChanged={(name, value) => {
                                    let creatorUpdate = { ...currentUser, phoneNumber: value };
                                    setCurrentUser(creatorUpdate);
                                }}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="email">Email:</label>
                            <Textbox
                                id="email"
                                name="email"
                                className="form-control"
                                ref={addRef}
                                email
                                onChanged={(name, value) => {
                                    let creatorUpdate = { ...currentUser, email: value };
                                    setCurrentUser(creatorUpdate);
                                }}
                                value={currentUser.email}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="birthDate">Ngày sinh:</label>
                            <DatePicker
                                adjustDateOnChange
                                locale={vi}
                                showYearDropdown
                                showMonthDropdown
                                peekNextMonth
                                timeCaption="time"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                                className="form-control"
                                selected={new Date(currentUser.birthday)}
                                onChange={(value) => {
                                    var now = new Date();
                                    var y1900 = new Date("1/1/1900");
                                    if (value < y1900) value = y1900;
                                    if (value > now) value = now;
                                    let creatorUpdate = { ...currentUser, birthday: value };
                                    setCurrentUser(creatorUpdate);
                                }}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="agencyLevel">Cấp độ:</label>
                            <span className="ml-2">{currentUser.agencyLevel}</span>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="identityCardFront">Ảnh mặt trước CMT/CCCD:</label>
                            {currentUser.identityCardFront ? (
                                <a target="_blank" href={currentUser.identityCardFront} className="ml-2">
                                    tệp đính kèm <i className="mdi mdi-open-in-new" />
                                </a>
                            ) : (
                                <span className="ml-2">Không có</span>
                            )}
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="identityCardBack">Ảnh mặt sau CMT/CCCD:</label>
                            {currentUser.identityCardBack ? (
                                <a target="_blank" href={currentUser.identityCardBack} className="ml-2">
                                    tệp đính kèm <i className="mdi mdi-open-in-new" />
                                </a>
                            ) : (
                                <span className="ml-2">Không có</span>
                            )}
                        </div>
                        <div className="col-12 mt-2">
                            <CheckBox
                                checked={currentUser.verified}
                                name="verified"
                                id="verified"
                                label="Xác thực"
                                onCheckedChange={(name, value) => {
                                    let creatorUpdate = { ...currentUser, verified: value };
                                    setCurrentUser(creatorUpdate);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-12">
                            <TFUSelectSubdivisions
                                showProvince
                                showDistrict
                                showWard
                                provinceCode={currentUser.provinceCode}
                                districtCode={currentUser.districtCode}
                                wardCode={currentUser.wardCode}
                                onChanged={(obj) => setCurrentUser({ ...currentUser, ...obj })}
                                addRef={addRef}
                                childClassName="m-b-5 mt-2"
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="Note">Số nhà, tên đường</label>
                            <TextArea id="street" name="street" value={currentUser.address} onChanged={(name, value) => setCurrentUser({ ...currentUser, address: value })} />
                        </div>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="col-sm-12 card-box p-3">
                        <h4 className="header-title m-t-0">
                            <b>Tài khoản ngân hàng</b>
                        </h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className=" form-row">
                                    <div className="form-group col-md-12">
                                        <label>Ngân hàng:</label>
                                        <TFUSelect name="status" isDisabled value={bankAccount.bankCode} options={bankList} onChanged={(value) => {}} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="bankAccountName">Tên tài khoản:</label>
                                        <Textbox name="bankAccountName" readOnly value={bankAccount.bankAccountName} className="form-control" onChanged={(name, value) => {}} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="bankAccountNumber">Số tài khoản:</label>
                                        <Textbox readOnly name="bankAccountNumber" value={bankAccount.bankAccountNumber} className="form-control" onChanged={(name, value) => {}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 card-box p-3">
                        <h4 className="header-title m-t-0">
                            <b>Tài khoản ví Momo</b>
                        </h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="bankAccountName">Tên tài khoản:</label>
                                        <Textbox readOnly name="bankAccountName" value={momoAccount.bankAccountName} className="form-control" onChanged={(name, value) => {}} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="bankAccountNumber">Số điện thoại đăng ký trên Momo:</label>
                                        <Textbox readOnly name="bankAccountNumber" value={momoAccount.bankAccountNumber} className="form-control" onChanged={(name, value) => {}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </CommonModal>
    );
}
