import React from "react";
import { useState, useEffect } from "react";
import { NOTIFY, PRINT_TYPE, PRINT_TYPE_NAME } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import CheckBox from "../based/inputs/CheckBox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ProductServices from "../based/services/ProductServices";
import VoucherServices from "../based/services/VoucherServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import Request from "../based/Request";
import { INITPAGING, CONSTANTS } from "../based/Constants";
import styled from "styled-components";
import ReactSelect from "react-select";
import { ConfirmDialog } from "../based/Dialog";

const initVoucher = {
    voucherId: 0,
    colorCode: "",
    iconUrl: "",
    printType: 0,
    code: "",
    name: "",
    description: "",
    type: -1,
    value: 0,
    validPriceOrder: 0,
    quantity: 0,
    voucherType: 9,
    lotusMaximumDiscountValue: 0,
    validCollectTime: 0,
    voucherProducts: [],
};
const typeOption = [
    { value: 0, label: "Phần trăm" },
    { value: 1, label: "Giá cố định" },
];

const printTypeOption = [
    { value: 1, label: "DTG" },
    { value: 2, label: "PET" },
    { value: 4, label: "Silk" },
    { value: 8, label: "Decal" },
    { value: 16, label: "Heat" },
    { value: 32, label: "Embroider" },
    { value: 64, label: "UV" },
];

const intitialPaging = {
    pageNumber: 1,
    pageSize: 10,
    totalRecord: 0,
    productMaterialType: 2,
};
const AddOrUpdate = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [msg, setMsg] = useState("");
    const [voucherCus, setVoucherCus] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [listProductOption, setListProductOption] = useState([]);
    const [paging, setPaging] = useState({ ...intitialPaging });
    const [printType, setPrintType] = useState([]);
    console.log('printType :>> ', printType);
    useEffect(() => {
        if (props.id > 0) getVoucherCus(props.id);
        else {
            setVoucherCus({ ...initVoucher });
        }
    }, [props.id]);

    useEffect(() => {
        getListProduct();
    }, [paging.keyword]);

    const getListProduct = async () => {
        setIsFetching(true);
        const [err, data] = await ProductServices.GetProducts(paging);
        if (!err && data) {
            const options = data.listObjects.map((product) => ({
                value: product.id,
                label: product.name,
                ...product,
            }));
            setListProductOption(options);
        } else {
            setIsFetching(false);
        }
    };

    const selectProductHandler = (product) => {
        if (product) {
            const { id, name } = product;
            const updatedProducts = [...voucherCus.voucherProducts];
            var oldProduct = updatedProducts.find((x) => x.productId == id);
            if (oldProduct) {
                Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn đã thêm sản phẩm này rồi!");
            } else {
                updatedProducts.push({
                    voucherId: voucherCus.voucherId,
                    productId: id,
                    productName: name,
                });
                setVoucherCus({ ...voucherCus, voucherProducts: updatedProducts });
            }
        }
    };

    const getVoucherCus = async (id) => {
        setIsBusy(true);
        let [err, data] = await VoucherServices.GetVoucherCustomizeById(id);
        if (!err && data) {
            setIsBusy(false);
            setVoucherCus(data);
            getPrintType(data.printType);
        } else {
            setIsBusy(false);
            setVoucherCus({ ...initVoucher });
        }
    };

    const getPrintType = (number) => {
        const combinations = []
        if((number & printTypeOption[0].value) === printTypeOption[0].value) {
            combinations.push(printTypeOption[0].value);
        }
        if((number & printTypeOption[1].value) === printTypeOption[1].value) {
            combinations.push(printTypeOption[1].value);
        }
        if((number & printTypeOption[2].value) === printTypeOption[2].value) {
            combinations.push(printTypeOption[2].value);
        }
        if((number & printTypeOption[3].value) === printTypeOption[3].value) {
            combinations.push(printTypeOption[3].value);
        }
        if((number & printTypeOption[4].value) === printTypeOption[4].value) {
            combinations.push(printTypeOption[4].value);
        }
        if((number & printTypeOption[5].value) === printTypeOption[5].value) {
            combinations.push(printTypeOption[5].value);
        }
        if((number & printTypeOption[6].value) === printTypeOption[6].value) {
            combinations.push(printTypeOption[6].value);
        }
        setPrintType(combinations);
    };


    const _handleSave = async () => {
        if (isValid() && printType.length > 0) {
            setIsBusy(true);
            let [err, data] = await VoucherServices.CreateUpdateVoucherCustomize({ ...voucherCus, printTypes: printType });
            if (!err && data) {
                setIsBusy(false);
                setVoucherCus({});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            props.onSaveContinue();
        } else {
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Nhập đầy đủ thông tin");
        }
    };

    function deleteProductVoucher(id, vId) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await VoucherServices.DeleteVoucherProduct(vId, id);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getVoucherCus(props.id);
                } else {
                    setIsLoading(false);
                    getVoucherCus(props.id);
                    setMsg(null);
                    resolve({ title: "Thất bại", msg: "Xóa thất bại" });

                    // Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }

    async function handleUploadFile(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            setVoucherCus({ ...voucherCus, iconUrl: res.data.imageUrl });
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <CommonModal size="lg" show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <Wapper>
                <div className="row-modal">
                    <div className="col-modal one">
                        <div className="box-image">
                            {voucherCus.iconUrl ? <img src={voucherCus.iconUrl} /> : <img />}
                            {voucherCus.iconUrl && (
                                <span
                                    onClick={() => {
                                        setVoucherCus({ ...voucherCus, iconUrl: "" });
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13 1L1 13" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 13L1 0.999999" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            )}
                        </div>
                        <input
                            style={{ maxWidth: 90 }}
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                                handleUploadFile(e);
                            }}
                        />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="color" className="required">
                            Màu:
                        </label>
                        <div className="box-color">
                            <Textbox id="color" name="color" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, colorCode: value })} value={voucherCus.colorCode} />
                            <input type="color" onChange={(e) => setVoucherCus({ ...voucherCus, colorCode: e.target.value })} value={voucherCus.colorCode} />
                        </div>
                    </div>
                    <div className="col-modal">
                        <label htmlFor="quantity" className="required">
                            Số lượng dùng:
                        </label>

                        <TextNumber id="quantity" name="quantity" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, quantity: value })} value={voucherCus.quantity} />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="name" className="required">
                            Tên Voucher:
                        </label>
                        <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, name: value })} value={voucherCus.name} />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="code" className="required">
                            Mã Voucher:
                        </label>
                        <Textbox id="code" name="code" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, code: value })} value={voucherCus.code} />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="fromDate" className="required">
                            Ngày bắt đầu:
                        </label>
                        <TFUDatetime
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            value={voucherCus.fromDate ? new Date(voucherCus.fromDate) : new Date()}
                            name="fromDate"
                            placeholder="Từ ngày"
                            className="form-control"
                            onChanged={(value, name) => setVoucherCus({ ...voucherCus, fromDate: value })}
                        />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="toDate" className="required">
                            Ngày kết thúc:
                        </label>
                        <TFUDatetime
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            value={voucherCus.toDate ? new Date(voucherCus.toDate) : new Date()}
                            name="todate"
                            placeholder="Đến ngày"
                            className="form-control"
                            onChanged={(value, name) => setVoucherCus({ ...voucherCus, toDate: value })}
                        />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="time" className="required">
                            Thời gian dùng sau khi thu thập (giờ):
                        </label>
                        <TextNumber id="time" name="time" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, validCollectTime: value })} value={voucherCus.validCollectTime} />
                    </div>
                </div>

                <div className="row-modal">
                    <div className="col-modal">
                        <label htmlFor="type" className="required">
                            Loại khuyến mãi:
                        </label>
                        <TFUSelect
                            options={typeOption}
                            name={"type"}
                            id="type"
                            ref={addRef}
                            value={voucherCus.type}
                            onChanged={(value) => {
                                if (value == typeOption[1].value) {
                                    setVoucherCus({ ...voucherCus, type: value, lotusMaximumDiscountValue: 0 });
                                } else {
                                    setVoucherCus({ ...voucherCus, type: value });
                                }
                            }}
                        />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="min" className="required">
                            Đơn tối thiểu:
                        </label>
                        <TextNumber id="min" name="min" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, validPriceOrder: value })} value={voucherCus.validPriceOrder} />
                    </div>

                    <div className="col-modal">
                        <label htmlFor="value" className="required">
                            Giảm giá ({voucherCus.type == typeOption[1].value ? "vnd" : "%"}):
                        </label>
                        <TextNumber id="value" name="value" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, value: value })} value={voucherCus.value} />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="desc" className="required">
                            Mô tả Voucher:
                        </label>
                        <Textbox id="desc" name="desc" required ref={addRef} className="form-control" onChanged={(name, value) => setVoucherCus({ ...voucherCus, description: value })} value={voucherCus.description} />
                    </div>
                    <div className="col-modal">
                        <label htmlFor="lotusMaximumDiscountValue" className="required">
                            Giảm giá tối đa:
                        </label>
                        <TextNumber
                            readOnly={voucherCus.type == typeOption[1].value ? true : false}
                            id="lotusMaximumDiscountValue"
                            name="lotusMaximumDiscountValue"
                            required
                            ref={addRef}
                            className="form-control"
                            onChanged={(name, value) => setVoucherCus({ ...voucherCus, lotusMaximumDiscountValue: value })}
                            value={voucherCus.lotusMaximumDiscountValue}
                        />
                    </div>
                    <div className="col-modal"></div>
                </div>

                <div className="row-modal-flex">
                    <div className="w-40">
                        <label htmlFor="printType" className="required">
                            Kiểu in:
                        </label>
                        <div className="box-print-type">
                            {printTypeOption.map((item, idx) => {
                                return (
                                    <div className="item-print-type" key={idx}>
                                        <CheckBox
                                            id={"print-" + idx}
                                            name={"print-" + idx}
                                            checked={printType.findIndex((x) => x == item.value) != -1 ? true : false}
                                            onCheckedChange={(name, value) => {
                                                let dt = [...printType];
                                                if (value) {
                                                    dt.push(item.value);
                                                } else {
                                                    dt.splice(dt.indexOf(item.value), 1);
                                                }
                                                setPrintType(dt);
                                            }}
                                        />
                                        <label htmlFor={"print-" + idx}>{item.label}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="w-60">
                        <label htmlFor="keyword" className="required">
                            Sản phẩm áp dụng
                        </label>
                        <ReactSelect
                            id="keyword"
                            name="keyword"
                            isClearable={true}
                            className="react-select-up"
                            classNamePrefix="react-select"
                            placeholder="Nhập tên, mã sản phẩm ..."
                            onInputChange={(value) => setPaging({ ...paging, keyword: value })}
                            value={paging.keyword}
                            options={listProductOption}
                            onChange={(selected) => selectProductHandler(selected)}
                            noOptionsMessage={() => (isFetching ? "Đang tải dữ liệu" : "Không có dữ liệu")}
                        />
                        <div className="list-product mt-3">
                            {voucherCus &&
                                voucherCus.voucherProducts &&
                                voucherCus.voucherProducts.length > 0 &&
                                voucherCus.voucherProducts.map((item, idx) => {
                                    return (
                                        <div className="item-product" key={idx}>
                                            <div>{item.productName}</div>
                                            <span style={{cursor: 'pointer', fontSize: 18 }}
                                                onClick={() => {
                                                    deleteProductVoucher(item.productId, item.voucherId);
                                                }}
                                            >
                                                &times;
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </Wapper>
        </CommonModal>
    );
};

export default AddOrUpdate;
const Wapper = styled.div`
    .box-print-type {
        display: flex;
        flex-wrap: wrap;
        .item-print-type {
            width: 50%;
            margin-top: 7px;
            display: flex;
            align-items: center;

            label {
                margin-left: 10px;
                margin-bottom: 0;
            }
        }
    }

    .list-product {
        display: flex;
        flex-wrap: wrap;

        .item-product {
            display: flex;
            color: #fff;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-items: center;
            margin-bottom: 10px;
            margin-left: 10px;
            background: #64c5b1;
            padding: 12px;
            span {
                margin-left: 10px;
            }
        }
    }

    .box-image {
        height: 100%;
        position: relative;

        span {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        img {
            height: 100%;
            aspect-ratio: 1;
            object-fit: contain;
        }
    }

    .box-color {
        display: flex;
        input {
            width: 50%;
        }
    }

    .row-modal {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        padding: 10px;

        .col-modal {
            &.one {
                grid-row-start: 1;
                grid-row-end: 3;
                display: flex;
                justify-content: start;

                input {
                    margin-left: 10px;
                }
            }
        }
    }

    .row-modal-flex {
        display: flex;

        .w-40 {
            width: 40%;
        }
        .w-60 {
            width: 60%;
        }
    }
`;
AddOrUpdate.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
