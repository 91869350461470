import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import Request from "../based/Request";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";
import PushingServices from "../based/services/PushingServices";

function VideoModal({ show, onClose, videoId, onSave }) {
    const [model, setModel] = useState({});
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoId) {
            getFileById(videoId);
        }
    }, [videoId]);

    useEffect(() => {
        if (model.url && videoRef && videoRef.current) {
            videoRef.current.load();
        }
    }, [model && model.url]);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onloadend = (e) => {
            Request.UploadVideo(file).then((res) => {
                if (res && res.data) {
                    setModel({ ...model, url: res.data.fullUrl });
                } else {
                    Notify(NOTIFY.ERROR, "Upload video thất bại", "Upload video thất bại");
                }
            });
        };
        reader.readAsDataURL(file);
    };

    const getFileById = async (id) => {
        const [err, data] = await PushingServices.GetVideoById(id);
        if (!err && data) {
            setModel(data);
        } else {
            Notify(NOTIFY.ERROR, "Lấy dữ liệu thất bại", "Lấy dữ liệu thất bại");
        }
    };

    const handleSave = async () => {
        if (!model.url || !model.thumbnail) {
            Notify(NOTIFY.ERROR, "Vui lòng chọn video", "Vui lòng chọn video");
            return;
        }
        const [err, data] = await PushingServices.CreateUpdatePushingVideo(model);
        if (!err) {
            Notify(NOTIFY.SUCCESS, "Lưu thành công", "Lưu thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, "Lưu thất bại", "Lưu thất bại");
        }
    };

    return (
        <Wrapper>
            <CommonModal
                show={show}
                onClose={onClose}
                size="md"
                onSave={() => {
                    handleSave();
                }}
            >
                {/* url */}
                <div className="form-row my-2">
                    <label htmlFor="">Đường dẫn Video</label>
                    <div className="form-group col-md-12">
                        <input
                            type="file"
                            placeholder="Chọn file video"
                            onChange={(e) => {
                                handleFileChange(e);
                            }}
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <video ref={videoRef} width="100%" controls>
                            {model.url && <source src={model.url} type="video/mp4" />}
                        </video>
                    </div>
                </div>
                {/* thumbnail */}
                <div className="form-row my-2">
                    <label htmlFor="">Ảnh Thumbnail Video</label>
                    <div className="form-group col-md-12">
                        <input
                            type="file"
                            placeholder="Chọn file thumbnail"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (!file) return;
                                const reader = new FileReader();
                                reader.onloadend = (e) => {
                                    Request.UploadImage(file).then((res) => {
                                        if (res && res.data) {
                                            setModel({ ...model, thumbnail: res.data.imageUrl });
                                        } else {
                                            Notify(NOTIFY.ERROR, "Upload thumbnail thất bại", "Upload thumbnail thất bại");
                                        }
                                    });
                                };
                                reader.readAsDataURL(file);
                            }}
                        />
                    </div>
                    <div className="form-group col-md-12">{model.thumbnail && <img src={model.thumbnail} alt="thumbnail" width="100%" />}</div>
                </div>
                {/* IsDefault */}
                <div className="form-row my-2">
                    <label htmlFor="">Video Mặc Định</label>
                    <div className="form-group col-md-12">
                        <input
                            type="checkbox"
                            checked={model.isDefault}
                            onChange={(e) => {
                                setModel({ ...model, isDefault: e.target.checked });
                            }}
                        />
                        <label className="ml-3">Mặc định</label>
                    </div>
                </div>
            </CommonModal>
        </Wrapper>
    );
}

export default VideoModal;

const Wrapper = styled.div``;
