import BaseServices from "./BaseServices";

const TemSizeServices = {
    GetProductDropDown: async () => {
        return await BaseServices.Get("/api/SizeStamp/get-product-drop-down");
    },
    CreateProductStamp: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/create-update-product-stamp-size", model);
    },
    GetProductStampByPage: async (paging) => {
        return await BaseServices.Post(`/api/SizeStamp/get-product-stamp-size-by-page`, paging);
    },
    DeleteProductStampSize: async (productId) => {
        return await BaseServices.Get(`/api/SizeStamp/delete-product-stamp-size/${productId}`);
    },


    GetProductVarriantByProductId: async (productId) => {
        return await BaseServices.Get(`/api/SizeStamp/get-product-variant/${productId}`);
    },
    CreateUpdateStampSize: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/create-update-stamp-size", model);
    },
    GetStampSizeByProductId: async (productId) => {
        return await BaseServices.Post(`/api/SizeStamp/get-stamp-size-by-productId/${productId}`,);
    },
    DeleteStampSize: async (productvariantId) => {
        return await BaseServices.Get(`/api/SizeStamp/delete-stamp-size/${productvariantId}`);
    },




    
    CreateUpdateShipmentSizeStamp: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/create-update-shipment-stamp-size", model);
    },
    CreateUpdateShipmentProduct: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/create-update-shipment-product", model);
    },
    CreateUpdateShipment: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/create-update-shipment", model);
    },
    CreateUpdateSizeStampBarcodeColor: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/create-update-barcode-color", model);
    },
   
    DeleteShipmentSizeStamp: async (id) => {
        return await BaseServices.Get(`/api/SizeStamp/delete-shipment-stamp-size/${id}`);
    },
    
    DeleteShipmentProduct: async (id) => {
        return await BaseServices.Get(`/api/SizeStamp/delete-shipment-product/${id}`);
    },
    
    DeleteShipment: async (id) => {
        return await BaseServices.Get(`/api/SizeStamp/delete-shipment/${id}`);
    },


    GetShipmentByPage: async (model) => {
        return await BaseServices.Post("/api/SizeStamp/get-shipment-by-page", model);
    },
   
    GetStampBarcodeColor: async () => {
        return await BaseServices.Get("/api/SizeStamp/get-size-stamp-barcode-color");
    },
   
    GetShipmentById: async (id) => {
        return await BaseServices.Get(`/api/SizeStamp/get-shipment-byId/${id}`);
    },

    CheckName: async (id, name) => {
        return await BaseServices.Get(`/api/SizeStamp/check-name/${id}/${name}`);
    },


    GetShipmentSizeStampByShipmentProductId: async (id) => {
        return await BaseServices.Get(`/api/SizeStamp/get-shipment-size-stamp-by-shipmentproductId/${id}`);
    },



    




};

export default TemSizeServices;
