import React from 'react';

export default class CustomRange extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChanged(this.props.name, e.target.value);
  }

  render() {
    return (
      <div>
        <input type="range" className="custom-range w-85"  name="points" min={this.props.min} max={this.props.max} value={this.props.value} onChange={this.handleChange} />
        <label className="pl-2" htmlFor="customRange">{this.props.value}</label>
      </div>
    );
  }
}
