import BaseServices from "./BaseServices";

const CustomizeServices = {
    GetTagsPaging: async (paging) => {
        return BaseServices.Post("/api/customize/get-tags-paging", paging);
    },
    GetTag: async (tagId) => {
        return BaseServices.Get(`/api/customize/get-tag-byId/${tagId}`);
    },
    CreateTag: async (model) => {
        return BaseServices.Post(`/api/customize/create-tag`, model);
    },
    UpdateTag: async (model) => {
        return BaseServices.Post(`/api/customize/update-tag`, model);
    },
    RemoveTag: async (tagId) => {
        return BaseServices.Get(`/api/customize/remove-tag/${tagId}`);
    },
    GetStockPhotosPaging: async (paging) => {
        return BaseServices.Post("/api/customize/get-stock-photos-paging", paging);
    },
    GetStockPhotoById: async (id) => {
        return BaseServices.Get(`/api/customize/get-stock-photo-byId/${id}`);
    },
    CreateStockPhoto: async (model) => {
        return BaseServices.Post("/api/customize/create-stock-photo", model);
    },
    UpdateStockPhoto: async (model) => {
        return BaseServices.Post("/api/customize/update-stock-photo", model);
    },
    DeleteStockPhoto: async (id) => {
        return BaseServices.Get(`/api/customize/delete-stock-photo/${id}`);
    },
    GetTextsPaging: async (paging) => {
        return BaseServices.Post(`/api/customize/get-texts-paging`, paging);
    },
    GetLanguage: async () => {
        return BaseServices.Get(`/api/customize/get-language`);
    },
    GetText: async (textId) => {
        return BaseServices.Get(`/api/customize/get-text/${textId}`);
    },
    CreateText: async (model) => {
        return BaseServices.Post(`/api/customize/create-text`, model);
    },
    UpdateText: async (model) => {
        return BaseServices.Post(`/api/customize/update-text`, model);
    },
    RemoveText: async (textId) => {
        return BaseServices.Get(`/api/customize/remove-text/${textId}`);
    },
    RenderFontFace: async () => {
        return BaseServices.Get(`/api/customize/render-font-face`);
    },
    ClearFontFace: async () => {
        return BaseServices.Get(`/api/customize/clear-font-face`);
    },
    GetListCollectionPagings: async (paging) => {
        return BaseServices.Post(`/api/customize/get-collection-by-paging`, paging);
    },
    GetListCollectionContentPagings: async (paging) => {
        return BaseServices.Post(`/api/customize/get-collection-content-by-paging`, paging);
    },
    GetCollectionById: async (id) => {
        return BaseServices.Get(`/api/customize/get-collection-by-id/${id}`);
    },
    DeleteCollectionById: async (id) => {
        return BaseServices.Get(`/api/customize/delete-collection-by-id/${id}`);
    },
    CreateUpdateCollection: async (model) => {
        return BaseServices.Post(`/api/customize/create-update-collection`, model);
    },
    GetDemandByPaging: async (model) => {
        return BaseServices.Post(`/api/customize/get-demand-paging`, model);
    },
    GetAgeByPaging: async (model) => {
        return BaseServices.Post(`/api/customize/get-age-paging`, model);
    },

    GetStockImagePaging: async (paging) => {
        return BaseServices.Post(`/api/customize/get-stock-image-paging`, paging);
    },
    GetStockImageById: async (id) => {
        return BaseServices.Get(`/api/customize/get-stock-image-by-id/${id}`);
    },
    CreateUpdateStockImage: async (payload) => {
        return BaseServices.Post(`/api/customize/create-update-stock-image`, payload);
    },
    DeleteStockImage: async (id) => {
        return BaseServices.Get(`/api/customize/delete-stock-image/${id}`);
    },
    //collection type
    CreateUpdateCollectionType: async (model) => {
        return BaseServices.Post(`/api/customize/create-update-collection-type`, model);
    },
    GetCollectionTypeById: async (id) => {
        return BaseServices.Get(`/api/customize/get-collection-type/${id}`);
    },
    DeleteCollectionTypeById: async (id) => {
        return BaseServices.Get(`/api/customize/delete-collection-type/${id}`);
    },
    GetListCollectionTypePagings: async (model) => {
        return BaseServices.Post(`/api/customize/get-collection-type-page`, model);
    },
    CreateMultipleStockImage: async (models) => {
        return BaseServices.Post(`/api/customize/create-multiple-stock-image`, models);
    },
};

export default CustomizeServices;
