/**
 * the use form validate hooks
 * @param {Map<String, import("react").RefObject>} refSets The set of ref
 */
export default function useFormValidate() {
    const refSets = new Map();
    const regexSets = new Map();

    function isValid() {
        if (!refSets) return true;
        let errors = new Map();
        refSets.forEach((ref, name) => {
            if (ref.validateInput(ref.props.value).length != 0) {
                let errorMsg = ref.validateInput(ref.props.value);
                errors.set(`${name}`, errorMsg);
            } else {
                let pattern = regexSets.get(name);
                if (pattern && ref.props.value && !_validateRegex(ref.props.value, pattern.pattern)) errors.set(`${name}`, pattern.invalidMsg);
            }
        });
        displayError(errors);
        return errors.size == 0 ? true : false;
    }

    /**
     * Validate the input with the regex pattern.
     * @param {String} value the value of this input
     * @param {RegExp} regex The regex pattern
     */
    function _validateRegex(value, regex) {
        return regex.test(value);
    }

    /**
     * display error
     * @param {Map<String,String>} errors The dictionary error list
     */
    function displayError(errors) {
        if (errors.size == undefined && !Array.isArray(errors)) return;
        if (!refSets) return;
        let errorsMap = new Map();
        if (Array.isArray(errors)) errors.forEach((x) => errorsMap.set(`${x.key}`, x.value));
        else errorsMap = errors;
        refSets.forEach((ref, name) => {
            let error = errorsMap.get(name);
            if (error && ref.displayError) ref.displayError(error);
        });
        if (errorsMap.size > 0) {
            let firstErrElName = errorsMap.entries().next().value[0];
            if (document && document.querySelectorAll) {
                var scrollElements = document.querySelectorAll(`input[name="${firstErrElName}"]`);
                if (scrollElements && scrollElements.length > 0) scrollElements[0].scrollIntoView();
            }
        }
    }

    /**
     * Add reference with their configs.
     * @param {import("react").RefObject} ref The react component reference
     * @param {FormValidateConfig} config The form validate config
     */
    function addRef(ref, config) {
        if (ref) {
            if (!ref.props.name) throw new Error("props.name is required");
            refSets.set(`${ref.props.name}`, ref);
            if (config) regexSets.set(`${ref.props.name}`, config);
        }
    }

    /**
     * Get reference.
     * @param {String} name the key of ref
     */
    function getRef(name) {
        var ref = refSets.get(name);
        return ref;
    }

    return { addRef, isValid, displayError, getRef };
}

/**
 * The form validate config by regex pattern
 */
class FormValidateConfig {
    constructor() {
        this.pattern = "";
        this.invalidMsg = "";
    }
}
