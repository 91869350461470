export class ThirdPartyOperatorModel {
    constructor() {
        this.userId = 0;
        this.printType = 0;
        this.ironingFee = 0;
        this.packingFee = 0;
        this.userName = "";
        this.firstName = "";
        this.lastName = "";
        this.fullName = "";
        this.printTypeName = "",
        this.phoneNumber = ""
    }
}
