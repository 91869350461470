import React, { useEffect, useState } from 'react';
import { StockOutContext, WarehouseInfoModel, WarehouseProductModel, CustomerInfoModel } from '../context/StockOutContext';
import { Layout } from '../layout/Layout';
import StockOutWarehouseInfo from './StockOutWarehouseInfo';
import CustomerInfo from './components/CustomerInfo';
import CommonServices from '../based/services/CommonServices';
import ProductItemOut from './components/ProductItemOut';
import ProductServices from '../based/services/ProductServices';
import { WarehouseOrdersModel } from '../models/WarehouseOrderModel';
import StockOutServices from '../based/services/StockOutServices';
import Common from '../based/Common';
import Language from '../based/Language';
import { Notify } from '../based/Notify';
import { NOTIFY } from '../based/Constants';
import Breadcrumbs from '../based/Breadcrumbs';
import TFUTooltip from '../based/TFUTooltip';
import useFormValidate from '../hooks/useFormValidate';

export default function StockOut(props) {
  const [warehouseInfo, setWarehouseInfo] = useState(new WarehouseInfoModel());
  const [customerInfo, setCustomerInfo] = useState(new CustomerInfoModel());
  const [products, setProducts] = useState([new WarehouseProductModel()]);
  const [provinces, setProvinces] = useState([]);
  const [productList, setProductList] = useState([]);

  const { addRef, displayError, isValid, getRef } = useFormValidate();

  useEffect(() => {
    GetProvinces();
  }, []);

  useEffect(() => {
    let warehouseId = warehouseInfo.id;
    if (warehouseId > 0) {
      _getAvaiDropDownProducts(warehouseId);
      setProducts([new WarehouseProductModel()]);
    }
  }, [warehouseInfo.id]);

  async function _getAvaiDropDownProducts(warehouseId) {
    let [err, data] = await ProductServices.GetAvaiDropProductsByWhId(warehouseId);
    // Filter unique product
    let options = [...new Set(data.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
    const formatedOptions = options.map((x) => ({
      ...x,
      value: x.value.split('_')[1],
    }));
    if (!err) setProductList(formatedOptions);
  }

  async function GetProvinces() {
    const [err, data] = await CommonServices.GetProvinces();
    if (!err) {
      setProvinces(data);
    }
  }

  function _handleAddProduct() {
    var refWarehouseInfoId = getRef('warehouseInfoId');
    if (refWarehouseInfoId) {
      var isValidWarehouseInfoIdMsg = refWarehouseInfoId.validateInput(warehouseInfo.id);
      if (isValidWarehouseInfoIdMsg.length !== 0) {
        refWarehouseInfoId.displayError(isValidWarehouseInfoIdMsg);
      } else {
        products.push(new WarehouseProductModel());
        setProducts([...products]);
      }
    }
  }

  async function _handleSave() {
    let isLocalValid = _isLocalValid();
    if (isValid() && isLocalValid) {
      var model = new WarehouseOrdersModel();
      model.expectedWarehouseId = warehouseInfo.id;
      model.expectedTimeStockOut = Common.formatDate(warehouseInfo.expectedTime, 'datetime');
      model.thirdPartyId = customerInfo.id;
      model.products = products;
      if (!model.products || model.products.length == 0 || model.products[0].productId <= 0) {
        Notify(NOTIFY.WARNING, 'Xác nhận', 'Xin vui lòng lựa chọn sản phẩm trước khi lưu...');
      } else {
        let [err, data] = await StockOutServices.StockOut(model);
        if (!err && data) {
          props.history.push('/stock-out-history');
        } else {
          if (err.errors) displayError(err.errors);
          else Notify(NOTIFY.ERROR, 'Lỗi', 'Xin vui lòng kiểm tra và thử lại sau ít phút.');
        }
      }
    }
  }

  function _isLocalValid() {
    let updateProducts = [...products];
    if (updateProducts.length < 1) {
      Notify(NOTIFY.ERROR, 'Lỗi', 'Chưa có sản phẩm trong đơn');
      return false;
    }
    updateProducts.forEach(function (p) {
      if (p.productName === '') {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Bạn chưa chọn sản phẩm!');
        return false;
      }
      if (p.quantity === 0) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Số lượng sản phẩm nhập phải lớn hơn 0!');
        return false;
      }
      if (p.price === 0) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Bạn chưa nhập giá xuất!');
        return false;
      }
    });
    return true;
  }

  return (
    <React.Fragment>
      <Breadcrumbs currentPage=" Lệnh xuất hàng" parentPage="Xuất kho" hrefParent="/stock-out-history" />
      <StockOutContext.Provider value={{ warehouseInfo, setWarehouseInfo, customerInfo, setCustomerInfo, products, setProducts }}>
        <div className="container-fluid p-0">
          <div className="card-box margin-bottom-10 padding-10">
            <StockOutWarehouseInfo provinces={provinces} addRefParent={addRef} />
          </div>
          <div className="card-box margin-bottom-10 padding-10">
            <CustomerInfo provinces={provinces} addRefParent={addRef} />
          </div>
          <div className="card-box margin-bottom-10">
            <div>
              <table className="table table-bordered m-b-0">
                <thead className="thead-light">
                  <tr>
                    <th className="cursor-pointer center" width="400">
                      Mã sản phẩm
                    </th>
                    <th className="cursor-pointer center" width="200">
                      Tên sản phẩm
                    </th>
                    <th className="cursor-pointer center" id="slotCode" name="slotCode" width="200">
                      Vị trí xuất
                      <TFUTooltip position="top" content="Vị trí xuất kho: Số lượng (Trạng thái sản phẩm - Đợt nhập" target="slotCode">
                        <i className="fa fa-question-circle m-l-5" />
                      </TFUTooltip>
                    </th>
                    <th className="cursor-pointer center" width="200">
                      Trạng thái
                    </th>
                    <th className="cursor-pointer center" width="200">
                      Số lượng xuất
                    </th>
                    <th className="cursor-pointer center" width="200">
                      Giá xuất
                    </th>
                    <th className="cursor-pointer center"></th>
                  </tr>
                </thead>
                <tbody>
                  {products && products.length > 0 ? products.map((product, index) => <ProductItemOut key={index} index={index} addRefParent={addRef} productItem={product} productList={productList} warehouseId={warehouseInfo && warehouseInfo.id > 0 ? warehouseInfo.id : 0} />) : null}
                  <tr>
                    <td colSpan="7" className="text-right">
                      <button className="btn btn-custom btn-sm" onClick={_handleAddProduct}>
                        Thêm
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12">
              <div className="text-right margin-right-10">
                <button type="button" className="btn btn-custom waves-effect w-xs waves-light margin-right-10 btn-sm" onClick={_handleSave}>
                  {Language.getValue('common.btnSave')}
                </button>
                <button type="button" className="btn btn-inverse waves-effect w-xs waves-light btn-sm" onClick={() => window.history.back()}>
                  Hủy
                </button>
              </div>
            </div>
          </div>
        </div>
      </StockOutContext.Provider>
    </React.Fragment>
  );
}
