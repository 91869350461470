import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import { CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import ShippingServices from "../based/services/ShippingServices";

export default function GrabExpress2H(props) {
    const [isBusy, setBusy] = useState(false);
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        _isConnectToVNPost();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ConnectPlatform });
    }, []);

    async function _handleConnectToGrabExpress() {
        setBusy(true);
        const [err, data] = await ShippingServices.ConnectToGrab2H();
        if (!err && data) {
            setConnected(true);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Kết nối tài khoản Grab 2H thành công");
        } else {
            setConnected(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Kết nối tài khoản Grab 2H thất bại");
        }
        setBusy(false);
    }

    async function _isConnectToVNPost() {
        const [err, data] = await ShippingServices.IsConnectedGrab2H();
        if (!err && data) {
            setConnected(true);
        } else {
            setConnected(false);
        }
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <div className="row">
                <div className="col-sm-12 form-row card-box p-3">
                    <div className="col-12 col-lg-6">
                        <div className="lazada-channel--text">
                            <p className="mb-3 font-size-24px header-title">Kết nối Grab</p>
                            {connected ? (
                                <p className="sub-header text-success">
                                    <b>Kết nối tài khoản Grab thành công</b>
                                </p>
                            ) : (
                                <p className="sub-header text-danger">Kết nối tài khoản Grab</p>
                            )}
                        </div>
                        <div className="text-left">
                            <button className="btn btn-custom mt-3 mb-3" onClick={() => _handleConnectToGrabExpress()}>
                                <span>
                                    <i className="fa fa-share-alt"></i> Kết nối tài khoản Grab2H
                                </span>
                            </button>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="salechannel--image">
                            <img src={"/images/sale-chanel.jpg"} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
