import BaseServices from "./BaseServices";

const ChatUserService = {
    UpdateChatUserDetail: async (model) => {
        return await BaseServices.Post("/api/ChatUserDetail/update-user-detail", model);
    },
    GetChatUserDetailByPage: async (model) => {
        return await BaseServices.Post("/api/ChatUserDetail/get-chat-user-detail-by-page", model);
    },
    GetChatUserDetailById: async (id) => {
        return await BaseServices.Get(`/api/ChatUserDetail/get-chat-user-detail-byId/${id}`);
    },
    ConfirmUser: async (id, status) => {
        return await BaseServices.Get(`/api/ChatUserDetail/confirm-user/${id}/${status}`);
    },
};

export default ChatUserService;

