import React, { useState, useEffect } from "react";
import Request from "../../based/Request";
import { NOTIFY, CONSTANTS, PLATFORM } from "../../based/Constants";
import { Notify } from "../../based/Notify";
import CommonModal from "../../based/Modal";
import Textbox from "../../based/inputs/Textbox";
import TextArea from "../../based/inputs/TextArea";
import useFormValidate from "../../hooks/useFormValidate";
import Selection from "../../based/inputs/Selection";
import { Button } from 'react-bootstrap'
import WarehouseServices from "../../based/services/WarehouseServices";
import Language from '../../../src/based/Language'

export default function OPFWarehouseModal(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [msg, setMsg] = useState(null)
    const [warehouse, setWarehouse] = useState({});
    const [warehouseOptions, setWarehouseOptions] = useState([])
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setWarehouse(props.warehouse);
        _getWarWarehouses();
    }, [props.isShowModal]);

    async function _handleSave() {
        if (warehouse && isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            const [err, data] = await WarehouseServices.UpdateWarehouseOPF(warehouse);
            if (!err) {
                setIsLoading(false)
                setMsg('')
                Notify(NOTIFY.SUCCESS, "Thành công", 'Cập nhật thành công');
                props.onSuccess()
            } else {
                setIsLoading(false)
                setMsg('')
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", 'Cập nhật thất bại');
            }
        }
    }

    async function _getWarWarehouses() {
        let paging = { pageNumber: 1, pageSize: 10, platform: PLATFORM.LOTUS, createdBy: '' }
        let [err, data] = await WarehouseServices.GetWarehouses(paging)
        if (!err && data && data.listObjects && data.listObjects.length > 0) {
            let userStores = data.listObjects.map((item, idex) => { return { value: item.id, text: item.name, label: item.name } })
            setWarehouseOptions(userStores)
        } else setWarehouseOptions([])
    }

    function _renderCustomButton() {
        return <Button variant={warehouse.isVirtual ? "custom btn-sm disable" : "custom btn-sm"} onClick={() => _handleSave()}><i className="fa fa-save"></i> {Language.getValue('common.btnSave')}</Button>
    }

    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            title={warehouse.id == 0 ? "Thêm mới" : "Cập nhật"}
            onClose={() => props.onClose()}
            customButton={_renderCustomButton()}
        >
            <div className="form-group">
                <label htmlFor="warehouseName" className="required"> Tên kho: </label>
                <Textbox id="warehouseName" name="warehouseName" className="form-control"
                    ref={addRef} required maxLength={255} value={warehouse.warehouseName}
                    onChanged={(name, value) => setWarehouse({ ...warehouse, warehouseName: value })}
                />
            </div>
            {
                warehouse.platform == PLATFORM.Lazada &&
                <div className="form-group">
                    <label htmlFor="warehouseCode"> Mã kho: </label>
                    <Textbox name="warehouseCode" className="form-control"
                        value={warehouse.warehouseCode} readOnly
                        onChanged={(name, value) => { }}
                    />
                </div>
            }
            <div className="form-group">
                <label htmlFor="location" className='required'>Địa chỉ kho </label>
                <TextArea id="location" name='location' ref={addRef} required className="form-control"
                    value={warehouse.location}
                    onChanged={(name, value) => setWarehouse({ ...warehouse, location: value })}
                />
            </div>

            <div className='form-group'>
                <label className='col-form-label'>Tên kho hàng thực tế</label>
                <Selection
                    value={warehouse ? warehouse.warWarehouseId : 0}
                    className='form-control'
                    placeholder='--Chọn kho--'
                    onChanged={(value) => setWarehouse({ ...warehouse, warWarehouseId: value })}
                    options={warehouseOptions} />
            </div>
            <div className='form-row'>
                <div className='form-group col-md-12'>
                    <hr />
                </div>
            </div>
            {
                warehouse.platform == PLATFORM.Lazada &&
                <div className='form-row'>
                    <div className='form-group col-md-12 ont-italic'>
                        <label className='card-title'>Ghi chú</label>
                        <p className="card-text">- Đây là thông tin được đồng bộ trực tiếp từ <strong>SÀN LAZADA</strong></p>
                        <p className="card-text">- Trong trường hợp nhà bán hàng muốn thay đổi thông tin ở mục này, xin vui lòng truy cập Seller Center LAZADA để thay đổi thông tin tương ứng</p>
                    </div>
                </div>
            }
        </CommonModal>
    )
}