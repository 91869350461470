import React from "react";
import PropTypes from "prop-types";
import CommonModal from "../based/Modal";
import Common from "../based/Common";
import { OPTION_SIZE } from "./SizeBoardItem";
import styled from "styled-components";


const ModalAddOrUpdateSizeBoardDetail = (props) => {


    return (
        <CommonModal show={props.isShowModal} size="lg" id={Common.generateGuid()}  title={"Data"} onClose={() => props.onClose()}>
            <Wapper>
            { props.sizeBoard && props.sizeBoard.length > 0  ?  <table>
            <thead>
                <tr className="text-center">
                    <th className="cursor-pointer">Id</th>
                    <th className="cursor-pointer">SizeName</th>
                    <th className="cursor-pointer">Height</th>
                    <th className="cursor-pointer">MaxWeight</th>
                    <th className="cursor-pointer">MinWeight</th>
                    <th className="cursor-pointer">Size</th>
                    <th className="cursor-pointer">SizeValue</th>
                    <th className="cursor-pointer">Product</th>
                </tr>
            </thead>
                <tbody>
                {props.sizeBoard && props.sizeBoard.length > 0 ? (
                    props.sizeBoard.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 200, textAlign: "center" }}>
                                   {item.id}
                                </td>
                                <td>
                                   <strong>{item.sizeName}</strong>
                                </td>
                                <td>
                                   {item.height}
                                </td>
                                <td>
                                   {item.maxWeight}
                                </td>
                                <td>
                                   {item.minWeight}
                                </td>
                                <td>
                                   {OPTION_SIZE.map((itm)=>{
                                    if(itm.value === item.size)
                                        return itm.label
                                   })}
                                </td>
                                <td>
                                {props.dataSizeValue.map((itm)=>{
                                    if(itm.value === item.sizeValue)
                                        return itm.label
                                   })}
                                </td>
                                <td>
                                    {props.dataDropDown.map((itm)=>{
                                        if(itm.value === item.productId)
                                        return itm.label
                                   })}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
            </table> : ""}
            { props.sizeBoardDetail && props.sizeBoardDetail.length > 0  ? <table>
            <thead>
                <tr className="text-center">
                    <th className="cursor-pointer">Id</th>
                    <th className="cursor-pointer">Size</th>
                    <th className="cursor-pointer">Length</th>
                    <th className="cursor-pointer">Width</th>
                    <th className="cursor-pointer">Weight</th>
                    <th className="cursor-pointer">Height</th>
                    <th className="cursor-pointer">Product</th>
                </tr>
            </thead>
                <tbody>
                {props.sizeBoardDetail && props.sizeBoardDetail.length > 0 ? (
                    props.sizeBoardDetail.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 200, textAlign: "center" }}>
                                   {item.id}
                                </td>
                                <td>
                                   {item.size}
                                </td>
                                <td>
                                   {item['length']}
                                </td>
                                <td>
                                   {item.width}
                                </td>
                                <td>
                                   {item.weight}
                                </td>
                                <td>
                                  {item.height}
                                </td>
                                <td>
                                   {props.dataDropDown.map((itm)=>{
                                        if(itm.value === item.productId)
                                        return itm.label
                                   })}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
            </table> : ""}
            </Wapper>
        </CommonModal>
    );
};

export default ModalAddOrUpdateSizeBoardDetail;
const Wapper = styled.div`
     table{
        width: 100%;
        thead{
            tr{
                height: 40px;
                background-color: #ccc;
                th{
                    color: #fff;
                }
            }
        }
        tbody{
            tr{
                height: 40px;
                td{
                    border-radius: 1px solid #ccc;
                }
            }
        }
     }

`
ModalAddOrUpdateSizeBoardDetail.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
