import BaseServices from "./BaseServices";

const SocialAccountServices = {
    //SIZE
    CreateUpdateSize: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size", model);
    },
    DeleteSize: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size", ids);
    },
    GetSizeByPage: async (model) => {
        return await BaseServices.Post("/api/Size/get-size-by-page", model);
    },
    GetSizeDropDown: async () => {
        return await BaseServices.Post("/api/Size/get-size-drop-down");
    },
    GetSizeByProductId: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-by-productId/${id}`);
    },
    GetSizeValueDropDown: async () => {
        return await BaseServices.Get("/api/Size/get-size-value");
    },
    //SIZE BOARD
    CreateUpdateSizeBoard: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-board", model);
    },
    DeleteSizeBoard: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-board", ids);
    },
    GetSizeBoardByPage: async (model) => {
        return await BaseServices.Post("/api/Size/get-size-board-by-page", model);
    },
    GetSizeBoardById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-board-byId/${id}`);
    },

    //SIZE BOARD DETAIL
    CreateUpdateSizeBoardDetail: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-board-detail", model);
    },
    DeleteSizeBoardDetail: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-board-detail", ids);
    },
    GetSizeBoardDetailByPage: async (model) => {
        return await BaseServices.Post("/api/Size/get-size-board-detail-by-page", model);
    },
    GetSizeBoardDetailById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-board-detail-byId/${id}`);
    },
    //SIZE HANDDOOR
    CreateUpdateSizeHandDoor: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-hand-door", model);
    },
    DeleteSizeHandDoor: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-hand-door", ids);
    },
    GetSizeHandDoorByProductId: async (productId) => {
        return await BaseServices.Post(`/api/Size/get-size-hand-door-by-product/${productId}`);
    },
    GetSizeHandDoorById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-hand-door-byId/${id}`);
    },
    //SIZE Horizontal
    CreateUpdateSizeHorizontal: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-horizontal", model);
    },
    DeleteSizeHorizontal: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-horizontal", ids);
    },
    GetSizeHorizontalByProductId: async (productId) => {
        return await BaseServices.Post(`/api/Size/get-size-horizontal-by-product/${productId}`);
    },
    GetSizeHorizontalById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-horizontal-byId/${id}`);
    },
    //Size Long
    CreateUpdateSizeLong: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-long", model);
    },
    DeleteSizeLong: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-long", ids);
    },
    GetSizeLongByProductId: async (productId) => {
        return await BaseServices.Post(`/api/Size/get-size-long-by-product/${productId}`);
    },
    GetSizeLongById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-long-byId/${id}`);
    },
    //Size long sleeve
    CreateUpdateSizeLongSleeve: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-long-sleeve", model);
    },
    DeleteSizeLongSleeve: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-long-sleeve", ids);
    },
    GetSizeLongSleeveByProductId: async (productId) => {
        return await BaseServices.Post(`/api/Size/get-size-long-sleeve-by-product/${productId}`);
    },
    GetSizeLongSleeveById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-long-sleeve-byId/${id}`);
    },
    //Size neck
    CreateUpdateSizeNeck: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-neck", model);
    },
    DeleteSizeNeck: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-neck", ids);
    },
    GetSizeNeckByProductId: async (productId) => {
        return await BaseServices.Post(`/api/Size/get-size-neck-by-product/${productId}`);
    },
    GetSizeNeckById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-neck-byId/${id}`);
    },
    //Size Shoulder
    CreateUpdateSizeShoulder: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-shoulder", model);
    },
    DeleteSizeShoulder: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-shoulder", ids);
    },
    GetSizeShoulderByProductId: async (productId) => {
        return await BaseServices.Post(`/api/Size/get-size-shoulder-by-product/${productId}`);
    },
    GetSizeShoulderById: async (id) => {
        return await BaseServices.Get(`/api/Size/get-size-shoulder-byId/${id}`);
    },
    //Size connection
    CreateUpdateSizeConnection: async (model) => {
        return await BaseServices.Post("/api/Size/create-update-size-connection", model);
    },
    DeleteSizeConnection: async (ids) => {
        return await BaseServices.Post("/api/Size/delete-size-connection", ids);
    },

};

export default SocialAccountServices;
