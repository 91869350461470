import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { CONSTANTS, IMAGE_TYPE, NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import Textbox from "../based/inputs/Textbox";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import useFormValidate from "../hooks/useFormValidate";

const initFolder = {
    id: 0,
    isDelete: 0,
    isFolderTrending: 0,
    thumbnail: "",
    parentFolderId: 0,
    name: "",
};

const ModalAddOrUpdateFolder = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [folder, setFolder] = useState({ ...initFolder });
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if (props.id > 0) getFolder(props.id);
        else setFolder({});
        return () => setFolder({ ...initFolder });
    }, [props.id]);

    const getFolder = async (id) => {
        setIsBusy(true);
        let [err, data] = await FolderProductPrototypeServices.GetFolderAppProduct(id);
        if (!err && data) {
            setIsBusy(false);
            setFolder(data);
        } else {
            setIsBusy(false);
            setFolder({});
        }
    };
    const _handleSave = async () => {
        if (isValid() && folder.thumbnail) {
            setIsBusy(true);
            let [err, data] = await FolderProductPrototypeServices.CreateOrUpdateFolderProductBrandPrototype({ ...folder });
            if (!err && data == 1) {
                setIsBusy(false);
                setFolder({ ...initFolder });
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
        if (!folder.thumbnail) {
            Notify(NOTIFY.NOTIFY, NOTIFY.NOTIFY, "Chưa chọn ảnh");
        }
    };

    const handleChangeImg = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file, IMAGE_TYPE.Product)
                    .then((res) => {
                        if (res && res.data) {
                            setFolder({ ...folder, thumbnail: res.data.imageUrl });
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                          return `${prev}${item.value}<br/>`;
                                      }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                    })
                    .catch((err) => {});
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="name" className="required">
                        Tên folder:
                    </label>
                    <Textbox
                        id="name"
                        name="name"
                        required
                        ref={addRef}
                        className="form-control"
                        onChanged={(name, value) => {
                            setFolder({ ...folder, name: value });
                        }}
                        value={folder.name}
                    />
                    <label htmlFor="audio" className="required  mt-3">
                        Hình hiển thị:
                    </label>
                    <div>
                        <div>{folder.thumbnail ? <img src={folder.thumbnail} /> : <img />}</div>
                        <label htmlFor="img" className="custom-btn-choose">
                            Chọn hình
                        </label>
                        <input
                            id="img"
                            name="img"
                            hidden
                            required
                            type="file"
                            onChange={(e) => {
                                handleChangeImg(e);
                            }}
                        />
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
};

export default ModalAddOrUpdateFolder;

const Wrapper = styled.div`
    img {
        width: 100px;
        height: 100px;
        border: 1px solid #ccc;
        object-fit: contain;
    }
    .custom-btn-choose {
        background: #6bc5b1;
        color: #fff;
    }
    label.custom-btn-choose {
        padding: 2px 4px;
        margin: 10px;
    }
`;
