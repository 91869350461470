import React, { useState, useEffect } from 'react'
import Request from '../based/Request'; 
import Common from '../based/Common';
import CommonModal from '../based/Modal';
import CheckBox from '../based/inputs/CheckBox';

export default function AssignRole(props) {
    const [isModalLoading, setIsModalLoading] = useState(false)
    const [msgModalLoading, setMsgModalLoading] = useState(null)
    const [roles, setRoles] = useState([])
    useEffect(() => {
        if (props.user.id > 0 && props.isShowModal)
            getRoles(props.user.id)
    }, [props.isShowModal, props.user.id])

    function _handleSave() {
        setIsModalLoading(true)
        setMsgModalLoading('Đang lưu dữ liệu..')
        let rolesChecked = roles.filter(m => m.checked);
        let datas = [];
        rolesChecked.map((role) => {
            datas.push(role.name)
        })
        Request.Post('/api/users/assign-roles', {
            userId: props.user.id,
            roles: datas
        }).then(res => {
            setIsModalLoading(false)
            setMsgModalLoading(null)
            props.onSaveSucceed()
        })
            .catch(err => {
                setIsModalLoading(false)
                setMsgModalLoading(null)
            })
    }

    function getRoles(userId) {
        setIsModalLoading(true)
        setMsgModalLoading('Đang tải dữ liệu...')
        Request.Get(`/api/users/get-roles/${userId}`)
            .then(res => {
                if (res.success) {
                    setRoles(res.data)
                    setIsModalLoading(false)
                    setMsgModalLoading(null)
                }
                else {
                    setIsModalLoading(false)
                    setMsgModalLoading(null)
                }
            })
            .catch(err => {
                setIsModalLoading(false)
                setMsgModalLoading(null)
            })
    }
    function handleInputChange(key, value) {
        let rolesUpdate = [...roles];
        rolesUpdate.map((item) => {
            if (item.name == key)
                item.checked = value;
        })
        setRoles(rolesUpdate)
    }
    return (
        <CommonModal
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={'Phân quyền cho: ' + props.user.userName}
            onSave={() => _handleSave()}
            onClose={() => props.onClose()}
        >
            {
                roles && roles.length > 0 ?
                    roles.map((role, r) => {
                        return (<div key={r} className="form-group">
                            <CheckBox id={role.name} name={role.name} label={role.name}
                                className='form-control'
                                onCheckedChange={handleInputChange}
                                checked={role.checked} />
                        </div>)
                    })
                    :
                    null
            }

        </CommonModal>
    )
}