import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../../based/Common";
import { NOTIFY } from "../../based/Constants";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import AudioServices from "../../based/services/AudioServices";
import FolderAudioServices from "../../based/services/FolderAudioServices";
import useFormValidate from "../../hooks/useFormValidate";

var INITPAGING = {
    pageNumber: 1,
    pageSize: 10000,
    keyword: "",
};
const ModalAddAudioToFolder = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [checkConnect, setCheckConnect] = useState(false);
    const [dataAdd, setDataAdd] = useState([]);

    useEffect(() => {
        getData(paging);
    }, [checkConnect]);

    useEffect(() => {
        const arr = [];
        if (props.audios.length > 0) {
            props.audios.forEach((item) => {
                arr.push(item.id);
            });
        }
        setDataAdd(arr);
    }, []);

    const getData = async (paging) => {
        setIsBusy(true);
        setMsg("Đang tải dữ liệu...");
        if (!checkConnect) {
            let [err, data] = await AudioServices.GetAudioByPage(paging);
            if (!err && data) {
                setData([...data, ...data.listObjects]);
                setPaging(data.paging);
                setIsBusy(false);
            } else {
                setData([]);
                setIsBusy(false);
                setPaging({ ...INITPAGING });
            }
        } else {
            let [err, data] = await AudioServices.GetAudioByPageNotConnection(paging);
            if (!err && data) {
                setData([...data, ...data.listObjects]);
                setPaging(data.paging);
                setIsBusy(false);
            } else {
                setData([]);
                setIsBusy(false);
                setPaging({ ...INITPAGING });
            }
        }
    };

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let option = {
                folderId: props.folderId,
                objectType: 5,
                objectIds: dataAdd,
            };
            let [err, data] = await FolderAudioServices.CreateUpdateFolderAudioConnection(option);
            if (!err && data == 1) {
                setIsBusy(false);
                setDataAdd([]);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
    };

    const handleSearch = (e) => {
        if (e.charCode == 13) {
            getData({ ...paging, pageNumber: 1, keyword: e.target.value });
        }
    };

    function convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedTime = `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
        return formattedTime;
    }

    return (
        <CommonModal show={props.isShowModal} size="lg" id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Thêm nhạc vào folder"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className="form-group">
                <Wapper>
                    <div className="header_add_audio">
                        <div className="">
                            <strong>Đã chọn: {dataAdd.length}</strong>
                            <strong className="ml-50">Tên folder: {props.folderName}</strong>
                        </div>
                        <div>
                            <span
                                onClick={() => {
                                    setCheckConnect(!checkConnect);
                                }}
                            >
                                {checkConnect ? "Tất cả" : "Lọc audio chưa có folder"}
                            </span>
                            <input
                                type="text"
                                placeholder="Nhập tên nhạc"
                                onKeyPress={(e) => {
                                    handleSearch(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="list-audio">
                        {data &&
                            data.map((item, idx) => {
                                return (
                                    <div key={idx} className="col">
                                        <div
                                            className={`item ${dataAdd.some((itm) => itm == item.id) ? "active" : ""}`}
                                            onClick={() => {
                                                if (!dataAdd.some((itm) => itm == item.id)) setDataAdd([...dataAdd, item.id]);
                                                else {
                                                    const filter = dataAdd.filter((itm) => itm != item.id);
                                                    setDataAdd([...filter]);
                                                }
                                            }}
                                        >
                                            <div className="name">{item.name}</div>
                                            <div className="duration">{convertSecondsToMinutes(item.duration)} </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </Wapper>
            </div>
        </CommonModal>
    );
};

export default ModalAddAudioToFolder;

const Wapper = styled.div`
    .header_add_audio {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ml-50 {
            margin-left: 50px;
        }
        span {
            font-weight: bold;
            color: #64c5b1;
            cursor: pointer;
        }
        input {
            margin-left: 10px;
            height: 30px;
            width: 350px;
            border-radius: 10px;
            padding: 0 10px;
        }
    }

    .list-audio {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px 0;

        .col {
            width: 12.5%;
            min-width: 12.5%;
            max-width: 12.5%;
            padding: 0 5px;
            margin-bottom: 10px;

            .item {
                width: 100%;
                height: 100px;
                border-radius: 10px;
                border: 1px solid #ccc;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &.active {
                    border: 5px solid #64c5b1;
                }
                .name {
                    font-weight: 500;
                }

                .duration {
                }
            }
        }
    }
`;

ModalAddAudioToFolder.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
