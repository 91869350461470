import { INITPAGING } from "../../../based/Constants";

const openMockupImageModal = (state, pickedImageId) => {
  const updatedState = { ...state };
  const updatedMockupModal = { ...updatedState.mockupModal };
  const updatedMockupImageModal = { ...updatedState.mockupImageModal };
  const updatedData = { ...updatedMockupImageModal.data };

  updatedData.isShow = true;
  updatedData.pickedImageId = pickedImageId;
  updatedData.hasMore = true;
  updatedData.paging = { ...INITPAGING };
  updatedMockupModal.isShow = false;
  updatedMockupImageModal.data = updatedData;
  updatedState.mockupImageModal = updatedMockupImageModal;
  updatedState.mockupModal = updatedMockupModal;

  return updatedState;
};

const closeMockupImageModal = (state) => {
  const updatedState = { ...state };
  const updatedMockupModal = { ...updatedState.mockupModal };
  const updatedMockupImageModal = { ...updatedState.mockupImageModal };
  const updatedData = { ...updatedMockupImageModal.data };

  updatedData.isShow = false;
  updatedMockupModal.isShow = true;
  updatedMockupImageModal.data = updatedData;
  updatedState.mockupImageModal = updatedMockupImageModal;
  updatedState.mockupModal = updatedMockupModal;

  return updatedState;
};

// Fetch
const fetchImagesBegin = (state) => {
  const updatedState = { ...state };
  const updatedMockupImageModal = { ...updatedState.mockupImageModal };

  updatedMockupImageModal.isLoading = true;
  updatedState.mockupImageModal = updatedMockupImageModal;

  return updatedState;
};

const fetchImagesSuccess = (state, { paging, items, hasMore }) => {
  const updatedState = { ...state };
  const updatedMockupImageModal = { ...updatedState.mockupImageModal };
  const updatedData = { ...updatedMockupImageModal.data };

  updatedData.paging = paging;
  updatedData.hasMore = hasMore;
  updatedData.items = items;
  updatedMockupImageModal.isLoading = false;
  updatedMockupImageModal.data = updatedData;
  updatedState.mockupImageModal = updatedMockupImageModal;

  return updatedState;
};

const fetchImagesFail = (state, err) => {
  const updatedState = { ...state };
  const updatedMockupImageModal = { ...updatedState.mockupImageModal };

  updatedMockupImageModal.isLoading = false;
  updatedMockupImageModal.isError = true;
  updatedMockupImageModal.error = err;
  updatedState.mockupImageModal = updatedMockupImageModal;

  return updatedState;
};

const selectMockupImage = (state, { imgUrl, objectId }) => {
  const updatedState = { ...state };

  const updatedMockupImageModal = { ...updatedState.mockupImageModal };
  const updatedData = { ...updatedMockupImageModal.data };

  const updatedMockupModal = { ...updatedState.mockupModal };
  const updatedSubImages = [...updatedMockupModal.subImages];

  if (!updatedData.pickedImageId) {
    updatedMockupModal.mainImage.url = imgUrl;
    updatedMockupModal.mainImage.objectId = objectId;
  } else {
    const itemIdx = updatedSubImages.findIndex(
      (subImg) => subImg.id === updatedData.pickedImageId
    );
    if (itemIdx < 0) return updatedState;
    updatedSubImages[itemIdx].url = imgUrl;
    updatedSubImages[itemIdx].objectId = objectId;
  }

  updatedData.isShow = false;
  updatedMockupModal.isShow = true;
  updatedMockupModal.subImages = updatedSubImages;
  updatedMockupImageModal.data = updatedData;
  updatedState.mockupImageModal = updatedMockupImageModal;
  updatedState.mockupModal = updatedMockupModal;

  return updatedState;
};

const clearImages = (state) => {
  const updatedState = { ...state };

  const updatedMockupImageModal = { ...updatedState.mockupImageModal };
  const updatedData = { ...updatedMockupImageModal.data };

  updatedData.items = [];
  updatedMockupImageModal.data = updatedData;
  updatedState.mockupImageModal = updatedMockupImageModal;

  return updatedState;
};

export default {
  openMockupImageModal,
  closeMockupImageModal,
  selectMockupImage,
  fetchImagesBegin,
  fetchImagesSuccess,
  fetchImagesFail,
  clearImages,
};
