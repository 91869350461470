import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Textbox from "../based/inputs/Textbox";
import TemplateServices from "../based/services/TemplateServices";
import useFormValidate from "../hooks/useFormValidate";

function AddOrUpdateTagDesign(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [tags, setTags] = useState([]);
    const [design, setDesign] = useState({});
    const [similarTags, setSimilarTags] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if (props.id > 0) getTabCB(props.id);
        setDesign({
            id: props.id,
            title: props.title,
            description: props.description,
            designPoint: props.designPoint,
        });
    }, [props.id, props.title, props.description]);

    const getTabCB = async (id) => {
        setIsBusy(true);
        let [err, data] = await TemplateServices.GetTagByDesignId(id);
        if (!err && data) {
            setIsBusy(false);
            setTags(data);
        } else {
            setIsBusy(false);
            setTags([]);
        }
    };

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await TemplateServices.UpdateTemplateAndTag({
                ...design,
                tags: tags,
            });
            if (!err && data) {
                setIsBusy(false);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                props.onSaveContinue();
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
        }
    };

    const handleAddTag = (val) => {
        if (val != "" && tags.length < 100) {
            if (!tags.some((tag) => tag.tagName == val)) {
                let tag = {
                    id: 0,
                    tagName: val,
                };
                setTags([...tags, tag]);
            }
            setKeyword("");
        }
        if (tags.length == 100) Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Tối đa 100 tag");
    };

    const handleRemoveTag = async (id, idx) => {
        if (id > 0) {
            let [err, data] = await TemplateServices.DeleteTagConnection(id);
            if (!err && data) {
                setTags(tags.filter((t, i) => i != idx));
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
            }
        } else setTags(tags.filter((t, i) => i != idx));
    };

    const getSimilarTag = async (keyword) => {
        let [err, data] = await TemplateServices.GetDropDownTag(keyword);
        if (!err && data) {
            setSimilarTags(data);
        } else {
            setSimilarTags([]);
        }
    };

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className="form-group">
                <label htmlFor="title" className="required">
                    Tên hiển thị
                </label>
                <Textbox id="title" name="title" required ref={addRef} className="form-control" onChanged={(name, value) => setDesign({ ...design, title: value })} value={design.title} />
            </div>
            <div className="form-group">
                <label htmlFor="description">Mô tả</label>
                <textarea name="description" className="form-control" value={design.description} onChange={(e) => setDesign({ ...design, description: e.target.value })} />
            </div>
            <div className="form-group">
                <label htmlFor="name" className="required">
                    Tag name
                </label>
                <div className="sub-header">
                    Bạn có thể điền và nhấn enter để thêm tag mới
                    <br />
                    Hoặc bạn có thể chọn từ danh sách tag bên dưới sau khi điền
                </div>
                <span style={{ float: "right" }}>{tags.length}/100</span>
                <Wapper>
                    <div className="tab-list">
                        {tags &&
                            tags.map((tag, idx) => {
                                return (
                                    <div key={idx} className="tab-item">
                                        <span className="name-tab">#{tag.tagName}</span>
                                        <span
                                            className="icon"
                                            onClick={() => {
                                                handleRemoveTag(tag.id, idx);
                                            }}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                    <input
                        type="text"
                        placeholder="Nhập tag name và enter"
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value);
                            clearTimeout(timer);
                            setTimer(
                                setTimeout(() => {
                                    getSimilarTag(keyword);
                                }, 500)
                            );
                        }}
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() == "enter") {
                                let val = e.target.value;
                                handleAddTag(val);
                            }
                        }}
                    />

                    {similarTags && similarTags.length > 0 && (
                        <div className="tab-list" style={{ marginTop: "10px" }}>
                            {similarTags.map((tag, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="tab-item"
                                        onClick={() => {
                                            handleAddTag(tag.label);
                                        }}
                                    >
                                        <span className="name-tab">#{tag.label}</span>
                                        <span className="icon">+</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Wapper>
            </div>
            <div className="form-group">
                <label htmlFor="">Điểm đánh giá</label>
                <input
                    type={"number"}
                    value={design.designPoint}
                    onChange={(e) => {
                        setDesign({ ...design, designPoint: +e.target.value });
                    }}
                    min={0}
                    max={100}
                    className="form-control"
                />
            </div>
        </CommonModal>
    );
}

export default AddOrUpdateTagDesign;

const Wapper = styled.div`
    .tab-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .tab-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 5px;
            background-color: #64c5b1;
            height: 30px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            .name-tab {
                color: #ffffff;
            }
            .icon {
                margin-left: 5px;
                color: #ffffff;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
    input {
        margin-top: 10px;
        width: 100%;
        height: 30px;
        border: 1px solid #64c5b1;
        border-radius: 10px;
        padding: 0 10px;

        :placeholder {
            color: #f3f3f3;
        }
    }
`;

AddOrUpdateTagDesign.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
