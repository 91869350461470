import React, { useEffect, useMemo, useState } from "react";
import { NOTIFY } from "../based/Constants";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TFUDatetime from "../based/inputs/TFUDatetime";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import VoucherServices from "../based/services/VoucherServices";
import useFormValidate from "../hooks/useFormValidate";
import VoucherLightModel from "../models/Promotion/VoucherLightModel";

const TAB_SCREENS = {
    INFO: 0,
    PRODUCTS: 1,
};

export default function AddOrEditDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState(new VoucherLightModel());
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { addRef, displayError, isValid } = useFormValidate();
    const { voucherId } = props;

    useEffect(() => {
        if (voucherId > 0) _getVoucherById(voucherId);
        else setVoucher(new VoucherLightModel());
    }, [props.isShowModal]);

    /** handle save */
    async function _handleSave(isContinue) {
        if (isValid()) {
            let obj = { ...voucher };
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await VoucherServices.CreateUpdateVoucherLightBrand(obj);
            if (!err) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
            } else {
                if (err.errors) {
                    displayError(err.errors);
                    setCurrentTabIndex(TAB_SCREENS.INFO);
                } else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setCurrentTabIndex(TAB_SCREENS.INFO);
        }
    }

    /** handle thay đổi tab */
    function _handleChangedTab(tab) {
        setCurrentTabIndex(tab);
        if (tab == TAB_SCREENS.PRODUCTS)
            setTimeout(() => {
                if (document.getElementById("txtSearch")) document.getElementById("txtSearch").focus();
            }, 500);
    }

    /** handle thay đổi thông tin voucher trong voucher brand */
    function _handleOnChangedd(name, value) {
        let vc = { ...voucher };
        vc[name] = value;
        setVoucher(vc);
    }

    /** lấy thông tin voucher by id */
    async function _getVoucherById(voucherId) {
        let [err, data] = await VoucherServices.GetVoucherBrandDetailById(voucherId);
        if (!err && data) {
            setVoucher(data);
        } else {
            setVoucher(new VoucherLightModel());
        }
    }

    /**render */
    const TabHeader = useMemo(() => (
        <div className='p-b-10'>
            <ul className='nav nav-tabs tabs-bordered nav-justified'>
                <li className='nav-item'>
                    <a className={currentTabIndex == TAB_SCREENS.INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.INFO)}>
                        <span className='d-none d-sm-block'>Thông tin Voucher</span>
                    </a>
                </li>
            </ul>
        </div>
    ));
    function _renderInfo() {
        return (
            <div className='col-md-12 card-box p-3'>
                <div className='form-row'>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label htmlFor='code' className='required'>
                                Mã Voucher:
                            </label>
                            <Textbox
                                name='code'
                                required
                                ref={addRef}
                                maxLength={200}
                                className='form-control font-weight-bold'
                                onChanged={(name, value) => {
                                    _handleOnChangedd(name, value.trim());
                                }}
                                value={voucher.code}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label htmlFor='name' className='required'>
                                Tên Voucher:
                            </label>
                            <Textbox name='name' required ref={addRef} maxLength={200} className='form-control' onChanged={(name, value) => _handleOnChangedd(name, value)} value={voucher.name} />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label htmlFor='quantity'>Số lượng Voucher</label>
                            <TextNumber
                                required
                                ref={addRef}
                                className='form-control'
                                name='quantity'
                                value={voucher.quantity}
                                onChanged={(name, value) => {
                                    _handleOnChangedd(name, value);
                                }}
                                suffix='cái'
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label htmlFor='maxUsage'>Số lượt sử dụng trên mỗi người</label>
                            <TextNumber
                                required
                                ref={addRef}
                                className='form-control'
                                name='maxUsage'
                                value={voucher.maxUsage}
                                onChanged={(name, value) => {
                                    _handleOnChangedd(name, value);
                                }}
                                suffix='lượt'
                            />
                        </div>
                    </div>
                </div>

                <div className='form-row'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor='displayStartDate' className='required'>
                                Ngày hiển thị Voucher:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='displayStartDate'
                                minDate={new Date()}
                                value={new Date(voucher.displayStartDate)}
                                onChanged={(newValue) => _handleOnChangedd("displayStartDate", newValue)}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor='fromDate' className='required'>
                                Ngày bắt đầu sử dụng:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='fromDate'
                                minDate={new Date(voucher.displayStartDate)}
                                value={new Date(voucher.fromDate)}
                                onChanged={(newValue) => _handleOnChangedd("fromDate", newValue)}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor='toDate' className='required'>
                                Ngày kết thúc sử dụng:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='toDate'
                                minDate={new Date(voucher.fromDate)}
                                value={new Date(voucher.toDate)}
                                onChanged={(newValue) => _handleOnChangedd("toDate", newValue)}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='description'>Mô tả:</label>
                    <TextArea ref={addRef} name='description' value={voucher.description} required onChanged={(name, value) => _handleOnChangedd(name, value)} />
                </div>
            </div>
        );
    }
    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            size='lg'
            title={voucher.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave(false)}>
                        <i className='fa fa-save m-r-5' /> Lưu
                    </button>
                    {voucher.id > 0 ? null : (
                        <button className='btn btn-outline-primary btn-sm' onClick={() => _handleSave(true)}>
                            <i className='fa fa-save m-r-5' /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            {TabHeader}
            <div className={currentTabIndex == TAB_SCREENS.INFO ? "" : "hide"}>{_renderInfo()}</div>
        </CommonModal>
    );
}
