import React, { useState } from "react";
import Barcode from "react-barcode";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import ProductionServices from "../based/services/ProductionServices";
import { OrderTagPetModel } from "../models/OrderTagPetModel";

function ProcessPressurePET(props) {
    const [tagDetail, setTagDetail] = useState(new OrderTagPetModel());
    const [barcode, setBarcode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [barcodePet, setBarcodePet] = useState("");

    const _getTagDetail = async (tagId) => {
        let [err, data] = await ProductionServices.GetOrderTagPETModel(tagId);
        if (!err && data) {
            setTagDetail(data);
            setBarcode("");
        } else {
            setTagDetail(new OrderTagPetModel());
            setBarcode("");
        }
    };

    const _handleScanBarcode = (barcode) => {
        _getTagDetail(barcode);
    };

    const _handleScanBarcodePet = (barcodePet) => {
        let tag = { ...tagDetail };
        let files = tag.operatorFiles;
        files.map((item, idx) => {
            if (item.barcode == barcodePet) item.checked = true;
        });
        let everyTrue = files.every((x) => x.checked);
        if (everyTrue) {
            ConfirmDialog(
                "Xác nhận?",
                "Hiện tại tất cả tem PET đã được quét, bạn có muốn đánh dấu hoàn tất?",
                () => {
                    return new Promise(async (resolve, reject) => {
                        let [err, data] = await ProductionServices.UpdateProcessPressureById(tagDetail.orderTagDetail.tagId);
                        if (!err) {
                            resolve({ title: "Thành công", msg: "Cập nhập thành công" });
                            tag.orderTagDetail.isPressure = true;
                        } else {
                            reject({ title: "Thất bại", msg: "Cập nhập thất bại" });
                            let msg = CONSTANTS.MSG_ERROR;
                            if (err && err.errors) {
                                msg = err.errors.reduce((prev, item, idx) => (prev += `${item.value} <br/>`), "");
                            }
                            Notify(NOTIFY.WARNING, "Có lỗi", msg);
                        }
                    });
                },
                true
            );
        }
        setTagDetail(tag);
        setBarcodePet("");
    };

    return (
        <React.Fragment>
            <Loading show={isLoading} msg={"Đang tải dữ liệu..."} />
            <Breadcrumbs parentPage="Quản lý sản xuất" hrefParent="" currentPage="Xử lý ép PET DTG" />
            <div className="w-20 offset-md-2 m-l-0 m-b-20">
                <div className="form-row">
                    <div className="col-md-9">
                        <input
                            autoComplete="off"
                            id="barcode"
                            name="barcode"
                            className="form-control text-center font-weight-bold"
                            type="text"
                            placeholder="Nhập barcode Tag Sản xuất"
                            maxlength="200"
                            onChange={(e) => {
                                setBarcode(e.target.value);
                            }}
                            value={barcode}
                            onKeyPress={(e) => {
                                if (e.charCode === 13) _handleScanBarcode(barcode);
                            }}
                        ></input>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-custom btn-sm full-width" onClick={() => _handleScanBarcode(barcode)}>
                            Nhập
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-box col-md-8 offset-md-2">
                <div className="card-body">
                    <div className="form-group">
                        <button
                            className={`btn btn-outline-dark ml-3 active text-white rounded`}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={tagDetail.orderTagDetail.isPressure ? { background: "lightgreen" } : { background: "red" }}
                        >
                            {tagDetail.orderTagDetail.isPressure ? "Xong" : "Chưa xong"}
                        </button>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            {tagDetail.orderTagMockup.artworkMockupModels &&
                                tagDetail.orderTagMockup.artworkMockupModels.map((item, idx) => {
                                    return (
                                        <div className="col-md-6 center" key={idx}>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <img className="w-100 rounded" src={item.url} />
                                                    <p className="font-weight-bold">{item.artworkTypeName}</p>
                                                </div>
                                                <div className="form-group col-md-6 text-left d-flex flex-columns align-items-center">
                                                    <span>
                                                        <p>
                                                            <strong>Rộng:</strong> {Common.formatNumber(item.imageWidth, 2)}cm
                                                        </p>
                                                        <p>
                                                            <strong>Dài:</strong> {Common.formatNumber(item.imageHeight, 2)}cm
                                                        </p>
                                                        <p>
                                                            <strong>Top - Cổ:</strong> {Common.formatNumber(item.fromCollar, 2)}cm
                                                        </p>
                                                        <p>
                                                            <strong>Giữa - Trái:</strong> {Common.formatNumber(item.halfWidth, 2)}cm
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        {tagDetail.orderTagMockup.logoMockupModel && (
                            <div className="row">
                                <div className="form-group col-md-4 center">
                                    <img className="w-50 rounded" src={tagDetail.orderTagMockup.logoMockupModel.url} />
                                </div>
                                <div className="form-group col-md-4 d-flex flex-columns align-items-center text-left">
                                    <p>
                                        <strong>Vị trí Logo:</strong> {tagDetail.orderTagMockup.logoMockupModel.positionLevelName}
                                    </p>
                                </div>
                                <div className="form-group col-md-4">
                                    <img className="w-50 rounded" src={tagDetail.orderTagMockup.logoMockupModel.brandMockup} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-row m-t-20">
                                    <table className="table table-bordered m-t-20">
                                        <thead>
                                            <tr>
                                                <td className="vertical-align-middle center">Mặt in</td>
                                                <td className="vertical-align-middle center">Barcode</td>
                                                <td className="vertical-align-middle center">Check</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tagDetail.operatorFiles.some((item) => item.barcode) ? (
                                                tagDetail.operatorFiles.map((item, o) => {
                                                    return (
                                                        <tr key={o}>
                                                            <td className="vertical-align-middle center">{item.artworkTypeName}</td>
                                                            <td className="vertical-align-middle center">{item.barcode || ""}</td>
                                                            <td className="vertical-align-middle center">{item.checked ? <i className="fa fa-check-square-o fa-lg" /> : <i className="fa fa-square-o fa-lg" />}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={3} className="center">
                                                        Không có dữ liệu
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-9">
                                        <input
                                            autoComplete="off"
                                            id="barcodePet"
                                            name="barcodePet"
                                            className="form-control text-center font-weight-bold"
                                            type="text"
                                            placeholder="Nhập barcode PET"
                                            maxLength="200"
                                            onChange={(e) => {
                                                setBarcodePet(e.target.value);
                                            }}
                                            value={barcodePet}
                                            onKeyPress={(e) => {
                                                if (e.charCode === 13) _handleScanBarcodePet(barcodePet);
                                            }}
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-custom btn-sm full-width" onClick={() => _handleScanBarcodePet(barcodePet)}>
                                            Nhập
                                        </button>
                                    </div>
                                </div>
                                <div className="form-row m-t-10">
                                    <div className="col-md-12 text-center">
                                        <Barcode value={barcodePet ? barcodePet : "000000000"} height="50" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 border m-t-20">
                                {tagDetail.orderTagDetail && (
                                    <div className="m-t-10">
                                        <p>
                                            <strong>Mã đơn hàng:</strong> {tagDetail.orderTagDetail.orderId || ""}
                                        </p>
                                        <p>
                                            <strong>Mã sản phẩm:</strong> {tagDetail.orderTagDetail.sellerSKU || ""}
                                        </p>
                                        <p>
                                            <strong>Trạng thái:</strong> {tagDetail.orderTagDetail.isPressure ? "Đã ép" : "Chưa ép"}
                                        </p>
                                        <p>
                                            <strong>Loại áo:</strong> {tagDetail.orderTagDetail.productTypeName || ""}
                                        </p>
                                        {tagDetail.orderTagDetail.attributeValues.map((element, index) => {
                                            let isColor = Common.isHexColorValue(element.value);
                                            let valueBtn = isColor ? <span className="dot center mr-1" style={{ background: element.value }} /> : null;
                                            return (
                                                <p key={index}>
                                                    {isColor ? (
                                                        <span>
                                                            <strong>{element.name}</strong>: {valueBtn}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <strong>{element.name}</strong>: {element.value}
                                                        </span>
                                                    )}
                                                </p>
                                            );
                                        })}
                                        <p>
                                            <strong>Deadline:</strong> {Common.formatDate(new Date(tagDetail.orderTagDetail.deadline), "datetime")}
                                        </p>
                                        <p>
                                            <strong>Đã phủ:</strong> {tagDetail.orderTagDetail.totalPressureCount ? `${tagDetail.orderTagDetail.successfulPressureCount} / ${tagDetail.orderTagDetail.totalPressureCount}` : ""}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-4 ml-3 border m-t-20">
                                <table className="table table-bordered m-t-10">
                                    <thead>
                                        <tr>
                                            <td className="vertical-align-middle center">Tag SX</td>
                                            <td className="vertical-align-middle center">Đã ép</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tagDetail.orderTagItems.some((item) => item.tagId > 0) ? (
                                            tagDetail.orderTagItems.map((ele, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="vertical-align-middle center">{ele.tagId || ""}</td>
                                                        <td className="vertical-align-middle center">{ele.pressureCount || ""}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td className="center" colSpan={2}>
                                                    Không có dữ liệu
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-2"></div>
        </React.Fragment>
    );
}

ProcessPressurePET.propTypes = {};

export default ProcessPressurePET;
