import BaseServices from "./BaseServices";

const StockFolderServices = {
    CreateUpdateStockFolder: async (model) => {
        return await BaseServices.Post("/api/customize/create-update-stock-folder", model);
    },
    GetStockFolderById: async (id) => {
        return await BaseServices.Get(`/api/customize/get-stock-folder/${id}`);
    },
    DeleteStockFolderById: async (id) => {
        return await BaseServices.Get(`/api/customize/delete-stock-folder/${id}`);
    },
    GetListStockFolderPagings: async (paging) => {
        return await BaseServices.Post("/api/customize/get-list-stock-folder", paging);
    },
    GetListStockPhotoPagings: async (paging) => {
        return await BaseServices.Post("/api/customize/get-list-stock-photo", paging);
    },
    GetListStockTemplatePagings: async (paging) => {
        return await BaseServices.Post("/api/customize/get-list-stock-template", paging);
    },
    GetListStockImagePagings: async (paging) => {
        return await BaseServices.Post("/api/customize/get-list-stock-image", paging);
    },
    GetListStockFontPagings: async (paging) => {
        return await BaseServices.Post("/api/customize/get-list-stock-font", paging);
    },
    GetListGiftPagings: async (paging) => {
        return await BaseServices.Post("/api/customize/get-list-gift", paging);
    },
    AddStockImageConnection: async (payload) => {
        return await BaseServices.Post("/api/customize/add-stock-image-connection", payload);
    },
    UpdateStockFolderParent: async (folderId, parentFolderId) => {
        return await BaseServices.Get(`/api/customize/update-stock-image/${folderId}/${parentFolderId}`);
    },
    RemoveStockImageConnection: async (model) => {
        return await BaseServices.Post(`/api/customize/remove-stock-image-connection`, model);
    },
    GetDropdownFolderPagings: async (paging) => {
        return await BaseServices.Post(`/api/customize/get-dropdown-folder`, paging);
    },
    CreateUpdateStockSet: async (model) => {
        return await BaseServices.Post(`/api/customize/create-update-stock-set`, model);
    },
    GetStockSetById: async (id) => {
        return await BaseServices.Get(`/api/customize/get-stock-set/${id}`);
    },
    DeleteStockSetById: async (id) => {
        return await BaseServices.Get(`/api/customize/delete-stock-set/${id}`);
    },
    GetListStockSetPagings: async (paging) => {
        return await BaseServices.Post(`/api/customize/get-list-stock-set`, paging);
    },
    GetListStockSetImagePagings: async (paging) => {
        return await BaseServices.Post(`/api/customize/get-list-stock-set-image`, paging);
    },
    AddStockImageSetConnection: async (models) => {
        return await BaseServices.Post(`/api/customize/add-stock-image-set`, models);
    },
    RemoveStockImageSetConnection: async (model) => {
        return await BaseServices.Post(`/api/customize/remove-stock-image-set`, model);
    },
    CreateFolderTag: async (models) => {
        return await BaseServices.Post(`/api/customize/create-folder-tag`, models);
    },
};

export default StockFolderServices;
