import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import InstructServices from "../based/services/InstructServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import CheckBox from "../based/inputs/CheckBox";


const initLink = {
    id: 0,
    isDelete: 0,
    url: '',
    name:"",
    typeScreen: 1,
    isHidden: false
}
const types = [
    {value: 1 , label:"Desktop"},
    {value: 2, label:"Mobile"},
]

const ModalAddOrUpdateLink = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [link, setLink] = useState({...initLink});
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if (props.id > 0) getLink(props.id);
        else setLink({ 
            
        });
        return () => setLink({ ...initLink });
    }, [props.id]);
    

    const getLink = async (id) => {
        setIsBusy(true);
        let [err, data] = await InstructServices.GetLinkById(id);
        if (!err && data) {
            setIsBusy(false);
            setLink(data);
        } else {
            setIsBusy(false);
            setLink({});
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await InstructServices.CreateUpdateLink({...link});
            if (!err && data) {
                setIsBusy(false);
                setLink({...initLink});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
               
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                
            }
            props.onSaveContinue();
        }
        
    };


   
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                Tên hướng dẫn:
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => {
                    setLink({ ...link, name: value })
                }} value={link.name} />
                <label htmlFor='url' className='required'>
                Url (vd: "/orders"):
                </label>
                <Textbox id='url' name='url' required ref={addRef} className='form-control' onChanged={(name, value) => {
                    setLink({ ...link, url: value })
                }} value={link.url} />
                <label htmlFor='type' className='required'>
                Loại:
                </label>
                <TFUSelect
                    ref={addRef}
                    name="type"
                    placeholder='--Loại--'
                    required
                    options={types}
                    value={link.typeScreen}
                    onChanged={value => setLink({ ...link, typeScreen: value })}
                />
                <label htmlFor='isHidden' className='required'>
                Ẩn:
                </label>
                <CheckBox id="isHidden" name="isHidden" checked={link.isHidden} label=" " onCheckedChange={(name, value) => {
                    setLink({ ...link, isHidden: value })
                }} />
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdateLink;

ModalAddOrUpdateLink.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
