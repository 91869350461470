import React, { useContext } from "react";
import { ProductContext } from "../../context/ProductContext";
import TextNumber from "../../based/inputs/TextNumber";

export default function SectionShipping(props) {
    const { mainProduct, setMainProduct, addRef } = useContext(ProductContext);

    return (
        <div className="section-service__delivery">
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Thông tin vận chuyển</b>
                </h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="length" className="col-form-label">
                                    Chiều dài
                                </label>
                                <TextNumber id="length" name="length" price={false} suffix="cm" ref={addRef} min={1} max={999999} value={mainProduct.length} onChanged={(name, value) => setMainProduct({ ...mainProduct, length: value })} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="width" className="col-form-label">
                                    Chiều rộng
                                </label>
                                <TextNumber id="width" name="width" price={false} suffix="cm" ref={addRef} min={1} max={999999} value={mainProduct.width} onChanged={(name, value) => setMainProduct({ ...mainProduct, width: value })} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="height" className="col-form-label">
                                    Chiều cao
                                </label>
                                <TextNumber id="height" name="height" price={false} suffix="cm" ref={addRef} min={1} max={999999} value={mainProduct.height} onChanged={(name, value) => setMainProduct({ ...mainProduct, height: value })} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="shippingWeight" className="col-form-label">
                                    Khối lượng kiện hàng
                                </label>
                                <TextNumber
                                    id="shippingWeight"
                                    name="shippingWeight"
                                    price={false}
                                    suffix="gram"
                                    ref={addRef}
                                    min={1}
                                    max={999999}
                                    value={mainProduct.shippingWeight}
                                    onChanged={(name, value) => setMainProduct({ ...mainProduct, shippingWeight: value })}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="volume" className="col-form-label">
                                    Thể tích kiện hàng
                                </label>
                                <TextNumber id="volume" name="volume" price={false} suffix="ml" ref={addRef} min={1} max={999999} value={mainProduct.volume} onChanged={(name, value) => setMainProduct({ ...mainProduct, volume: value })} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
