import BaseServices from "./BaseServices";

const SetProductServices = {
    GetPaging: async (paging) => {
        return await BaseServices.Post(`/api/setProduct/get-paging`, paging);
    },
    GetPagingCreatorArtwork: async (paging) => {
        return await BaseServices.Post(`/api/setProduct/get-paging-creator-artwork`, paging);
    },
    SuggestSearch: async (keyword) => {
        return await BaseServices.Get(`/api/setProduct/suggets-search?keyword=${keyword}`);
    },
    SuggestProductVariants: async (productId) => {
        return await BaseServices.Get(`/api/setProduct/suggets-product-variants?productId=${productId}`);
    },
    DeleteVariant: async (variantId, setId) => {
        return await BaseServices.Post(`/api/setProduct/delete-variant?variantId=${variantId}&setId=${setId}`, null);
    },
    Save: async (model) => {
        return await BaseServices.Post(`/api/setProduct/save`, model);
    },
    DeleteSets: async (setIds) => {
        return await BaseServices.Post(`/api/setProduct/delete-sets`, setIds);
    },
    GetByIdForUpdate: async (setId) => {
        return await BaseServices.Get(`/api/setProduct/get-by-id-for-update?setId=${setId}`);
    },
    GetProductDetailCreateArtwork: async (setId) => {
        return await BaseServices.Get(`/api/setProduct/get-detail-id-for-update/${setId}`);
    },

    // Direct Sell
    GetDirectSellPaging: async (paging) => {
        return await BaseServices.Post(`/api/setProduct/get-direct-sell-paging`, paging);
    },
    DirectSell: async (model) => {
        return await BaseServices.Post(`/api/SetProduct/direct-sell`, model);
    },
};

export default SetProductServices;
