import BaseServices from "./BaseServices";

const ArtistServices = {
    CreateUpdateArtist: async (model) => {
        return await BaseServices.Post("/api/Artist/create-update-folder-artist", model);
    },
    CreateOrUpdateArkwork: async (model) => {
        return await BaseServices.Post("/api/Artist/create-update-arkwork", model);
    },
    CreateArtworkAndFolderConnection: async (model) => {
        return await BaseServices.Post("/api/Artist/create-artwork-and-folder-connection", model);
    },
    GetArtistByPage: async (model) => {
        return await BaseServices.Post("/api/Artist/get-artist-by-page", model);
    },
    DeteleArtist: async (ids) => {
        return await BaseServices.Post("/api/Artist/delete-artist", ids);
    },
    DeteleFolderArtist: async (ids) => {
        return await BaseServices.Post("/api/Artist/delete-folder-artist", ids);
    },
    DeteleFolderArtistCon: async (ids, folderId) => {
        return await BaseServices.Post(`/api/Artist/delete-folder-artist-con/${folderId}`, ids);
    },
    GetArtistById: async (id) => {
        return await BaseServices.Get(`/api/Artist/get-artist-by-id/${id}`);
    },
    GetArtworkByFolderId: async (folderId, paging) => {
        return await BaseServices.Post(`/api/Artist/get-artwork-by-folderId/${folderId}`,paging);
    },
    GetFolderArtistById: async (id) => {
        return await BaseServices.Get(`/api/Artist/get-folder-artist-byId/${id}`);
    },
    GetFolderArtistByUser: async (id) => {
        return await BaseServices.Get(`/api/Artist/get-folder-artist-by-user`);
    },
    GetFolderArtistAndArtworkByUser: async (id) => {
        return await BaseServices.Get(`/api/Artist/get-folder-artist-and-artwork-by-user`);
    },
    ConfirmAccount: async (userId, status) => {
        return await BaseServices.Get(`/api/Artist/confirm-account-artist/${userId}/${status}`);
    },
    UpdateInfoUserArtist: async (model) => {
        return await BaseServices.Post(`/api/Artist/update-info-user-artist`,model);
    },
    UpdateInfoUserArtistAdmin: async (model) => {
        return await BaseServices.Post(`/api/Artist/update-info-user-artist-admin`,model);
    },


    CreateOrUpdateLinkStream: async (model) => {
        return await BaseServices.Post("/api/Artist/create-update-live-stream", model);
    },
    DeleteLinkStream: async (ids) => {
        return await BaseServices.Post("/api/Artist/delete-live-stream", ids);
    },
    GetLiveStreamByUser: async (id) => {
        return await BaseServices.Get(`/api/Artist/get-live-stream-by-user`);
    },

    DeleteJobLiveStream: async (id) => {
        return await BaseServices.Get(`/api/Artist/delete-video-render/${id}`);
    },
};

export default ArtistServices;

