import React from "react";
import {useState, useEffect, useRef } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import MarketingServices from "../../based/services/MarketingServices";
import useFormValidate from "../../hooks/useFormValidate";
import Common from "../../based/Common";
import styled from "styled-components";


const TYPE_PRODUCT = {
    CREATOR: 1,
    DESIGN : 2
}
const modelPage = {
    pageSize: 20,
    pageNumber: 1
}
const ModalAddProduct = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [products, setProducts] = useState([]);
    const { addRef, displayError, isValid } = useFormValidate();
    const [typeProduct , setTypeProduct] = useState(TYPE_PRODUCT.CREATOR)
    const [paging, setPaging] = useState({
        ...modelPage
    })
    const observer = useRef(null);
    const [hasMore, setHasMore] = useState(true);


    const setLastCollectionHandler = (element) => {
        if (isBusy || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                let updatePaging = { ...paging };
                updatePaging.pageNumber += 1;
                if(typeProduct === TYPE_PRODUCT.DESIGN){
                    getMoreDesign(updatePaging);
                }
                if(typeProduct === TYPE_PRODUCT.CREATOR){
                    getMoreProductCreator(updatePaging)
                }
            }
        });
        if (element) observer.current.observe(element);
    };

    const getMoreDesign = async (paging) => {
        let [err, data] = await MarketingServices.GetDesignByKeyword(paging);
        if (!err && data && data.listObjects.length > 0) {
            setProducts([...products , ...data.listObjects]);
            setPaging(data.paging)
            if(data.listObjects.length > 0){
                setHasMore(true);
            }else setHasMore(false);
        } else {
            setPaging({...modelPage})
            setHasMore(false);
        }
    }

    const getMoreProductCreator = async (paging) => {
        setIsBusy(false)
        let [err, data] = await MarketingServices.GetProductCreatorByKeyword(paging);
        if (!err && data && data.listObjects.length > 0) {
            setProducts([...products , ...data.listObjects]);
            setPaging(data.paging)
            if(data.listObjects.length > 0){
                setHasMore(true);
            }else setHasMore(false);
            setIsBusy(false)
        } else {
            setPaging({...modelPage})
            setHasMore(false);
            setIsBusy(false)
        }
    }

    useEffect(()=>{
        setProducts([])
    },[typeProduct])


   const handleClickItem = (item , typeProduct) =>{
        if(!props.product.some(itm => itm.product.productId === item.productId && itm.product.productType === typeProduct)){
            props.setItemUpdate({...item , isAdd: true , productType: typeProduct})
            props.onSaveContinue()
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, 'Đã thêm sản phẩm này')
        }
        
   }
   
   
    const renderTable= ()=>{
        if(typeProduct === TYPE_PRODUCT.DESIGN){
            return products && products.length> 0 && products.map((item,idx)=>{
                return <tr key={idx} ref={setLastCollectionHandler}
                    onClick={()=>{
                        handleClickItem(item , typeProduct)
                    }}
                >
                    <td style={{width: 100}}>
                        {item.productImageUrl ? <img src={item.productImageUrl}/> : <img />}
                    </td>
                    <td>
                        {item.title}
                    </td>
                </tr>
            })
        }
        if(typeProduct === TYPE_PRODUCT.CREATOR){
            if(isBusy){
                return <div>Đang tải...</div>
            }else{
                return products && products.length> 0 && products.map((item,idx)=>{
                    return <tr key={idx} ref={setLastCollectionHandler}
                        onClick={()=>{
                            handleClickItem(item , typeProduct)
                        }}
                    >
                        <td style={{width: 100}}>
                            {item.productAvatar ? <img src={item.productAvatar}/> : <img />}
                        </td>
                        <td>
                            {item.nameProduct}
                        </td>
                    </tr>
                })
            }
        }
    }
    const handleChangeText = async(e)=>{
        if(e.charCode === 13){
            if(TYPE_PRODUCT.CREATOR === typeProduct){
                setIsBusy(true)
                let [err, data] = await MarketingServices.GetProductCreatorByKeyword({
                    ...modelPage,
                    keyword: e.target.value
                });
                if (!err && data) {
                    setProducts(data.listObjects);
                    setPaging(data.paging)
                    setHasMore(true)            
                    setIsBusy(false)
                } else {
                    setProducts([]);
                    setPaging({})
                    setHasMore(false);
                    setIsBusy(false)
                }
            }else{
                let [err, data] = await MarketingServices.GetDesignByKeyword({
                    ...modelPage,
                    keyword: e.target.value
                });
                if (!err && data) {
                    setProducts(data.listObjects);
                    setPaging(data.paging)
                    setHasMore(true);
                } else {
                    setProducts([]);
                    setPaging({})
                    setHasMore(false);
                }
            }
           
        }
    }

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Thêm sản phẩm"} onSave={()=>{}} onClose={() => props.onClose()}>
            <Wapper>
                <input type="text" placeholder="Nhập tên sản phẩm" onKeyPress={(e)=>{handleChangeText(e)}} />
                <div className="box-radio">
                    <span>Kiểu sản phẩm </span>
                    <input
                        type="radio"
                        checked={typeProduct === TYPE_PRODUCT.CREATOR}
                        onChange={()=>{
                            setTypeProduct(TYPE_PRODUCT.CREATOR)
                            setPaging({...modelPage})
                            setHasMore(true)
                        }}
                        name="radioOptions"
                    />
                    <span>Creator</span>

                    <input
                        type="radio"
                        checked={typeProduct === TYPE_PRODUCT.DESIGN}
                        onChange={()=>{
                            setTypeProduct(TYPE_PRODUCT.DESIGN)
                            setPaging({...modelPage})
                            setHasMore(true)
                        }}
                        name="radioOptions"
                    />
                    <span>Tự thiết kế</span>
                </div>
                <table>
                    <tbody>
                    {renderTable()}
                    </tbody>
                </table>
            </Wapper>
        </CommonModal>
    );
};

export default ModalAddProduct;
const Wapper = styled.div`
    img{
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }

    input{
        width: 100%;
        height: 30px;
        margin-right: 5px;
    }

    .box-radio{
        margin-top: 7px;
        display: flex;
        align-items: center;

        span{
            margin-right: 10px;
        }
        input{
            height: 20px;
            width: 20px;
            accent-color: green;
        }
    }
    table{
        margin-top: 10px;
        width: 100%;

        tbody{
            tr{
                width: 100%;
            }
            td{
                padding: 10px;
                border: 1px solid #ccc;
            }
        }
    }
    
`
ModalAddProduct.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
