import PropTypes from "prop-types";
import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import Common from "../based/Common";
import { IMAGEABLE_TYPE, NOTIFY } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CustomizeServices from "../based/services/CustomizeServices";
import useFormValidate from "../hooks/useFormValidate";
const PlatformType = {
    Undefined: 0,
    ShutterStock: 1,
    Adobe: 2,
};

const PlatformTypeOptions = [
    {
        value: PlatformType.ShutterStock,
        label: "ShutterStock",
    },
    {
        value: PlatformType.Adobe,
        label: "Adobe",
    },
];

const StockImagePlatformModel = {
    platformId: "",
    platformType: PlatformType.Undefined,
};

const StockImageModel = {
    id: 0,
    title: "",
    thumbUrl: "",
    url: "",
    width: 0,
    height: 0,
    tagConnections: [],
    stockImagePlatform: { ...StockImagePlatformModel },
};

/**
 * stock-#Cà Phê Hòn Chồng# vào #buổi chiều##Nha Trang# Việt Nam-521794075-transformed
 * @param {*} fileName
 */
export const HandleFileName = (fileName) => {
    let splitDash = fileName.split("-");

    //get shutterStockId
    let shutterStockId = "";
    shutterStockId = splitDash && splitDash.length >= 2 ? splitDash[splitDash.length - 2] : "";

    //get title
    let title = "";
    let titleWithTag = splitDash && splitDash.length >= 3 ? splitDash[splitDash.length - 3] : null;
    title = titleWithTag ? titleWithTag.replaceAll("#", "") : "";

    //get tag
    let splitTag = titleWithTag ? titleWithTag.split("#") : null;
    let tags = [];

    if (splitTag)
        for (let index = 0; index < splitTag.length; index++) {
            const element = splitTag[index];
            if (index % 2 != 0) tags.push(element);
        }

    return {
        title,
        shutterStockId,
        tags,
    };
};

export const HandleNewFileName = (fileName) => {
    let newFileName = Common.getStringWithoutExtension(fileName);
    let splitDash = newFileName.split("#");

    //platform data
    let platformData = splitDash && splitDash.length > 2 ? splitDash[2] : null;
    let platformId = "";
    let platformType = PlatformType.Undefined;
    if (platformData != null) {
        let splitPlatform = platformData.split("_");
        //get type
        platformType = splitPlatform.length > 2 ? getPlatformType(splitPlatform[2]) : platformType;

        //get platformId
        platformId = splitPlatform.length > 1 ? splitPlatform[1] : platformId;
    }

    //get title
    let title = splitDash && splitDash.length > 0 ? splitDash[0] : "";

    //get tag
    let tagString = splitDash && splitDash.length > 1 ? splitDash[1] : "";
    let tags = tagString ? tagString.split(",") : null;

    return {
        title,
        tags,
        platformId,
        platformType,
    };
};

const getPlatformType = (character) => {
    switch (character) {
        case "ad":
            return PlatformType.Adobe;
        case "st":
            return PlatformType.ShutterStock;

        default:
            break;
    }
};

const AddMultipleModal = ({ onSaveContinue, onClose, isShowModal }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [stockImages, setStockImages] = useState([StockImageModel]);
    const { addRef, displayError, isValid } = useFormValidate();
    const [editIndex, setEditIndex] = useState(-1);

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let model = [...stockImages];
            let [err, data] = await CustomizeServices.CreateMultipleStockImage(model);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                onSaveContinue();
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            setIsBusy(false);
        }
    };

    const _handleMultipleImage = (e) => {
        e.preventDefault();
        let files = e.target.files;
        let lstImage = [];
        if (files && files.length > 0) {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                let reader = new FileReader();
                reader.onloadend = () => {
                    Request.UploadStockPhoto(file, IMAGEABLE_TYPE.StockPhoto).then((res) => {
                        if (res && res.data) {
                            let { imageUrl, thumbnailUrl } = res.data;
                            let item = { url: imageUrl, thumbUrl: thumbnailUrl, width: file.width, fileName: file.name };
                            lstImage.push(item);
                            if (lstImage.length == files.length) {
                                let models = [];
                                for (const iterator of lstImage) {
                                    let handleName = HandleNewFileName(iterator.fileName);
                                    let object = { ...StockImageModel };
                                    object.url = iterator.url;
                                    object.thumbUrl = iterator.thumbUrl;

                                    if (handleName) {
                                        object.title = handleName.title;
                                        object.tagConnections = handleName.tags;
                                        object.stockImagePlatform = { ...StockImagePlatformModel, platformId: handleName.platformId, platformType: handleName.platformType };
                                    }

                                    models.push(object);
                                }
                                setStockImages(models);
                            }
                        }
                    });
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleItem = (name, value, index) => {
        let models = [...stockImages];
        let model = models[index];

        if (name == "platformType" || name == "platformId") {
            if (name == "platformType") model.stockImagePlatform.platformType = value;
            else model.stockImagePlatform.platformId = value;
        } else model[name] = value;
        setStockImages(models);
    };

    return (
        <Wrapper>
            <CommonModal show={isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={"Thêm mới"} onSave={_handleSave} onClose={() => onClose()} classModalBody={"modal-class-body"} size="lg">
                <div className="form-group">
                    <label htmlFor="image" className="required">
                        Danh sách ảnh:
                    </label>
                    <div className="sub-header">
                        Tên file:
                        <br />
                        Dành cho Shutter Stock: Doremon ngồi trước cửa#doremon đang ngồi,nobita#_Id_st
                        <br />
                        Dành cho Adobe: Doremon ngồi trước cửa#doremon đang ngồi,nobita#_Id_ad
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={(e) => {
                            _handleMultipleImage(e);
                        }}
                    />
                </div>

                <div className="table-responsive">
                    <table className="table table-centered mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Thumbnail</th>
                                <th>Title</th>
                                <th>Tags</th>
                                <th>Platform Id (Adobe/ShutterStock Id)</th>
                                <th>Platform Type (Adobe/ShutterStock)</th>
                                <th className="tabledit-toolbar-column"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockImages &&
                                stockImages.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <span className="tabledit-span tabledit-identifier">{idx + 1}</span>
                                            </td>
                                            <td>
                                                <img
                                                    src={item.thumbUrl}
                                                    alt={item.title}
                                                    style={{
                                                        width: `100px`,
                                                        height: `100px`,
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <span
                                                    className="tabledit-span tabledit-identifier"
                                                    style={{
                                                        display: editIndex == idx ? "none" : "unset",
                                                    }}
                                                >
                                                    {item.title}
                                                </span>
                                                <input
                                                    className="tabledit-input tabledit-identifier"
                                                    name="title"
                                                    value={item.title}
                                                    style={{
                                                        display: editIndex == idx ? "unset" : "none",
                                                    }}
                                                    onChange={(e) => handleItem("title", e.target.value, idx)}
                                                ></input>
                                            </td>
                                            <td>
                                                <span
                                                    className="tabledit-span tabledit-identifier"
                                                    style={{
                                                        display: editIndex == idx ? "none" : "unset",
                                                    }}
                                                >
                                                    {item.tagConnections &&
                                                        item.tagConnections.map((element, i) => {
                                                            return (
                                                                <span kery={i} className="badge badge-custom m-2">
                                                                    {element}
                                                                </span>
                                                            );
                                                        })}
                                                </span>
                                                {editIndex == idx && (
                                                    <TagsInput
                                                        addKeys={[13]}
                                                        addOnPaste
                                                        maxTags={10}
                                                        inputProps={{ placeholder: "" }}
                                                        value={item.tagConnections || []}
                                                        onChange={(tags) => {
                                                            let newTags = tags.reduce((prev, val, idx) => {
                                                                return prev.indexOf(val) == -1 ? [...prev, val] : [...prev];
                                                            }, []);
                                                            handleItem("tagConnections", newTags, idx);
                                                        }}
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <span
                                                    className="tabledit-span tabledit-identifier"
                                                    style={{
                                                        display: editIndex == idx ? "none" : "unset",
                                                    }}
                                                >
                                                    {item.stockImagePlatform.platformId}
                                                </span>
                                                <input
                                                    className="tabledit-input tabledit-identifier"
                                                    name="platformId"
                                                    value={item.stockImagePlatform.platformId}
                                                    onChange={(e) => handleItem("platformId", e.target.value, idx)}
                                                    style={{
                                                        display: editIndex == idx ? "unset" : "none",
                                                    }}
                                                ></input>
                                            </td>
                                            <td>
                                                <TFUSelect
                                                    name="platformType"
                                                    value={item.stockImagePlatform.platformType}
                                                    options={PlatformTypeOptions}
                                                    onChanged={(newValue) => {
                                                        let vl = !newValue ? PlatformType.Undefined : newValue;
                                                        handleItem("platformType", vl, idx);
                                                    }}
                                                    isDisabled={editIndex != idx}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className="tabledit-edit-button btn btn-primary"
                                                    onClick={(e) => {
                                                        if (editIndex == idx) setEditIndex(-1);
                                                        else setEditIndex(idx);
                                                    }}
                                                >
                                                    <span className="mdi mdi-pencil"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </CommonModal>
        </Wrapper>
    );
};

export default AddMultipleModal;

AddMultipleModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};

const Wrapper = styled.div`
    .modal-class-body {
        min-height: 50vh;
    }
`;
