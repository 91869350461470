import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import { Notify } from "../based/Notify";
import PosterServices from "../based/services/PosterServices";

const PosterManage = () => {
    const history = useHistory();
    const [paging, setPaging] = useState({ ...Common.Paging });
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    useEffect(() => {
        getDatas(paging);
    }, []);
    const getDatas = async (paging) => {
        let [err, data] = await PosterServices.GetListPoster(paging);
        if (!err && data && data.listObjects) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(Common.Paging);
        }
    };
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.isChecked = value));
        else newDatas[index].isChecked = value;
        setDatas(newDatas);
    };
    async function _handleDelete() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((x) => ids.push(x.id));
                    ids.map(async (set) => {
                        let [error, data] = await PosterServices.DeletePosterById(set);
                        if (error || !data) {
                            setIsLoading(false);
                            setMsg(null);
                            Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                            reject({ title: "Thất bại", msg: "Vui lòng thử lại sau." });
                        } else {
                            setIsLoading(false);
                            setMsg(null);
                            getDatas(paging);
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                        }
                    });
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn thông tin trước khi xóa");
    }
    async function _handleEdit() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length == 1) {
            history.push(`/PosterConfig/${dataChecked[0].posterGroupId}`);
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn một thông tin trước khi sửa");
    }
    const _renderHeader = () => {
        let icon = <i className="fa fa-sort fa-sort-thead"></i>;
        return (
            <thead>
                <tr>
                    <th width={40} className="text-center">
                        <CheckBox checked={datas && datas.length > 0 && datas.filter((x) => x.isChecked).length === datas.length} id="select_all" name="select_all" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th>Poster</th>
                    <th>Tiêu đề</th>
                    <th>Ngày tạo</th>
                    <th>Người tạo</th>
                    <th>Hạng mục</th>
                    <th>Thể loại</th>
                    <th>Chiều ngang</th>
                    <th>Chiều dọc</th>
                </tr>
            </thead>
        );
    };
    const _renderBody = () => {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td align="center">
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.isChecked ? item.isChecked : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td className="vertical-align-middle text-center">
                                    <img style={{ width: 50 }} src={item.bottomLayer} />
                                </td>
                                <td className="vertical-align-middle text-center">{item.name}</td>
                                <td className="vertical-align-middle text-center">{item.createdDate}</td>
                                <td className="vertical-align-middle text-center">{item.createdBy}</td>
                                <td className="vertical-align-middle text-center">{item.posterCategoryName}</td>
                                <td className="vertical-align-middle text-center">{item.templateTypeName}</td>
                                <td className="vertical-align-middle text-center">{item.width}</td>
                                <td className="vertical-align-middle text-center">{item.height}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={10} className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    };
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.isChecked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group full-width">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={() => history.push("/PosterConfig")}>
                        <i className="fa fa-plus m-r-5" />
                        Tạo mới
                    </button>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Poster" />
            <CommonTable placeholderSearch="Tên Poster" paging={paging} data={datas} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic />
        </React.Fragment>
    );
};

export default PosterManage;
