import React, { useContext } from "react";
import TextNumber from "../../based/inputs/TextNumber";
import { ProductContext } from "../../context/ProductContext";

export default function SectionPrices(props) {
    const { mainProduct, setMainProduct, addRef } = useContext(ProductContext);
    return (
        <React.Fragment>
            <div className="section-variant">
                <div className="col-sm-12 card-box p-3">
                    <h4 className="header-title m-t-0">
                        <b>Giá của sản phẩm</b>
                    </h4>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="importPrice" className="col-form-label">
                                        Giá nhập
                                    </label>
                                    <TextNumber onChanged={(name, value) => setMainProduct({ ...mainProduct, importPrice: value })} price={true} ref={addRef} name="importPrice" max={99999999999999} value={+mainProduct.importPrice} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="standardPrice" className="col-form-label">
                                        Giá sàn (Giá thấp nhất có thể bán)
                                    </label>
                                    <TextNumber onChanged={(name, value) => setMainProduct({ ...mainProduct, standardPrice: value })} price={true} ref={addRef} name="standardPrice" max={99999999999999} value={+mainProduct.standardPrice} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
