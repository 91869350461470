import React, { useEffect, useRef, useState } from "react";
import { CAMPAIGN_TYPE, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import BaseModal from "../based/refactor/BaseModal";
import CampaignServices from "../based/services/CampaignServices";
import BasicInfo from "./BasicInfo";
import DealHighLight from "./DealHighLight";

const TAB_INDEX = {
    BASIC_INFO: 1,
    DEAL_HIGHLIGHT: 2,
};

const initCampaignMegaDeal = {
    id: 0,
    index: 0,
    campaignId: 0,
    saleType: 0,
    saleValue: 0,
    minimumOrderValue: 0,
};

const initCampaign = {
    id: 0,
    title: null,
    shortLink: null,
    index: 1,
    showTime: new Date(),
    startTime: new Date(),
    endTime: new Date(new Date().setDate(new Date().getDate() + 1)),
    description: null,
    isActive: true,
    type: CAMPAIGN_TYPE.MEGA,
    campaignMegaDeals: [{ ...initCampaignMegaDeal }],
};

const Config = (props) => {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [tabIndex, setTabIndex] = useState(TAB_INDEX.BASIC_INFO);
    const [campaign, setCampaign] = useState(initCampaign);
    const refBasicInfo = useRef(null);
    const refDealHighLight = useRef(null);

    useEffect(() => {
        setTabIndex(TAB_INDEX.BASIC_INFO);
        if (props.campaignId > 0) getCampaign();
    }, [props.campaignId]);

    const getCampaign = async () => {
        setIsModalLoading(true);
        setMsgModalLoading("Đang tải dữ liệu...");
        const [err, data] = await CampaignServices.GetCampaignById(props.campaignId, CAMPAIGN_TYPE.MEGA);
        if (!err && data) {
            let camp = data;
            if (camp && !camp.campaignMegaDeals) {
                camp.campaignMegaDeals = [{ ...initCampaignMegaDeal }];
            }
            camp.showTime = new Date(camp.showTime);
            camp.startTime = new Date(camp.startTime);
            camp.endTime = new Date(camp.endTime);
            setCampaign(camp);
        } else {
            setCampaign(initCampaign);
        }
        setIsModalLoading(false);
        setMsgModalLoading(null);
    };

    const _handleSave = async () => {
        let obj = getCampaignConfig();
        const valid = checkValid(obj);
        if (valid) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu...");
            const [err, data] = await CampaignServices.AddOrUpdateCampaign(obj);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onClose();
                props.getCampaigns();
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
            }
            setIsModalLoading(false);
            setMsgModalLoading(null);
        }
    };

    const checkValid = (campaign) => {
        if (!campaign.title) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Tên chương trình khuyến mãi không được rỗng!");
            return false;
        }

        if (campaign.showTime > campaign.startTime) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Ngày hiển thị không được lớn hơn ngày bắt đầu!");
            return false;
        }
        if (campaign.startTime >= campaign.endTime) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc!");
            return false;
        }
        return true;
    };

    const checkValidInfo = (campaign) => {
        if (!campaign.title) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Tên chương trình khuyến mãi không được rỗng!");
            return false;
        }
        if (campaign.showTime > campaign.startTime) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Ngày hiển thị không được lớn hơn ngày bắt đầu!");
            return false;
        }
        if (campaign.startTime >= campaign.endTime) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc!");
            return false;
        }
        return true;
    };

    const _renderContent = () => {
        switch (tabIndex) {
            case TAB_INDEX.DEAL_HIGHLIGHT:
                return <DealHighLight dealHighLight={campaign.campaignMegaDeals} ref={refDealHighLight} />;
            default:
                return <BasicInfo basicInfo={campaign} ref={refBasicInfo} />;
        }
    };

    const getCampaignConfig = () => {
        let campaignUpdate = { ...campaign };
        // Basic info
        if (refBasicInfo.current != null) {
            let basicInfo = refBasicInfo.current.getBasicInfo();
            if (basicInfo) {
                campaignUpdate.id = basicInfo.id;
                campaignUpdate.title = basicInfo.title;
                campaignUpdate.shortLink = basicInfo.shortLink;
                campaignUpdate.showTime = basicInfo.showTime;
                campaignUpdate.startTime = basicInfo.startTime;
                campaignUpdate.endTime = basicInfo.endTime;
                campaignUpdate.duration = basicInfo.duration;
                campaignUpdate.index = basicInfo.index;
                campaignUpdate.description = basicInfo.description;
                campaignUpdate.isActive = basicInfo.isActive;
            }
        }
        // Campaign highlight
        if (refDealHighLight.current != null) {
            let dealHighLight = refDealHighLight.current.getCampaignHighLight();
            if (dealHighLight) {
                campaignUpdate.campaignMegaDeals = dealHighLight;
            } else {
                campaignUpdate.campaignMegaDeals = null;
            }
        }
        return campaignUpdate;
    };

    const _handleChangedTab = (tab) => {
        let obj = getCampaignConfig();
        switch (tab) {
            case TAB_INDEX.DEAL_HIGHLIGHT:
                const validd = checkValid(obj);
                if (validd) {
                    setTabIndex(TAB_INDEX.DEAL_HIGHLIGHT);
                }
                break;
            default:
                setTabIndex(TAB_INDEX.BASIC_INFO);
                break;
        }
        setCampaign(getCampaignConfig());
    };
    const _handleNextStep = (tab) => {
        let obj = getCampaignConfig();
        if (tab === TAB_INDEX.BASIC_INFO) {
            const valid = checkValidInfo(obj);
            if (valid) setTabIndex(tab + 1);
        } else if (tab === TAB_INDEX.BANNER) {
            const valida = checkValid(obj);
            if (valida) setTabIndex(tab + 1);
        } else {
            setTabIndex(tab + 1);
        }
        setCampaign(getCampaignConfig());
    };
    return (
        <BaseModal
            title="Thiết lập chiến dịch"
            isShow={props.isShowModal}
            onClose={() => {
                props.onClose();
                setCampaign(initCampaign);
            }}
            nextStep={tabIndex !== TAB_INDEX.DEAL_HIGHLIGHT ? () => _handleNextStep(tabIndex) : null}
            onSave={tabIndex === TAB_INDEX.DEAL_HIGHLIGHT ? () => _handleSave() : null}
        >
            <ul id="default-wizard-header" className="stepy-header">
                <li id="default-wizard-head-0" onClick={() => _handleChangedTab(TAB_INDEX.BASIC_INFO)} className={tabIndex == TAB_INDEX.BASIC_INFO ? "stepy-active" : ""}>
                    <div>1</div>
                    <span>Thiết lập chung</span>
                </li>
                <li id="default-wizard-head-2" onClick={() => _handleChangedTab(TAB_INDEX.DEAL_HIGHLIGHT)} className={tabIndex == TAB_INDEX.DEAL_HIGHLIGHT ? "stepy-active" : ""}>
                    <div>2</div>
                    <span>Deal giảm giá</span>
                </li>
            </ul>
            <div className="row w-100">
                <div className="col-md-12">{_renderContent()}</div>
            </div>
        </BaseModal>
    );
};

export default Config;
