import React, { useState, useEffect, useRef } from "react";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import ThirdPartyServices from "../based/services/ThirdPartyServices";
import CommonTable from "../based/CommonTable";
import NumberFormat from "react-currency-format";
export default function CustomerDetailsModal(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [customer, setCustomer] = useState({});
    const [paging, setPaging] = useState(Common.Paging);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (props.isShowModal) {
            getCustomerDetail(props.customer.id);
            getOrders(props.customer.id, Common.Paging);
        }
    }, [props.isShowModal]);

    async function getCustomerDetail(id) {
        setIsModalLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await ThirdPartyServices.GetCustomerById(id);
        if (!err && data) {
            setCustomer(data);
            setIsModalLoading(false);
            setMsg(null);
        } else {
            setCustomer({});
            setIsModalLoading(false);
            setMsg(null);
        }
    }
    async function getOrders(id, newPaging) {
        setIsModalLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await ThirdPartyServices.GetCustomerOrders(id, newPaging);
        if (!err && data) {
            setOrders(data.listObjects);
            setPaging(data.paging);
            setIsModalLoading(false);
            setMsg(null);
        } else {
            setOrders([]);
            setIsModalLoading(false);
            setMsg(null);
        }
    }
    function _renderAction() {
        return (
            <div className='p-y-10 box__actions'>
                
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width='50'>STT</th>
                    <th>Số đơn hàng</th>
                    <th>SL sản phẩm</th>
                    <th>Tổng giá trị (đ)</th>
                    <th>Giảm giá (đ)</th>
                    <th>Tiền ship (đ)</th>
                    <th width='250'>Thành tiền đơn hàng (đ)</th>
                    <th width='50' className='text-center'></th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {orders && orders.length > 0 ? (
                    orders.map((item, k) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;
                        return (
                            <tr key={k}>
                                <td className='text-center'>{no}</td>
                                <td className='text-left'>{item.orderNumber}</td>
                                <td className='text-right'>{item.itemCounts}</td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalPrice} />
                                </td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalDiscount} />
                                </td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.shippingFee} />
                                </td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.finallyPrice} />
                                </td>
                                <td className='text-center'>
                                    <a target='_blank' href={`/order-detail/${item.id}`}>
                                        <i title='Xem' className='mdi mdi-eye mdi-24px cursor-pointer' style={{ color: "#159a7f" }}></i>
                                    </a>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            <span>Không có đơn hàng nào</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    return (
        <CommonModal size='lg' show={props.isShowModal} id={Common.generateGuid()} isBusy={isModalLoading} busyMsg={msg} title='Thông tin khách hàng' onClose={() => props.onClose()}>
            <div className='form-row'>
                <div className='form-group col-md-4'>
                    <div className='customer-info-item'>
                        <h4 className='header-title m-t-0'>
                            <b>
                                <u>Thông tin cá nhân</u>
                            </b>
                        </h4>
                        <p>
                            <b>Họ và tên:</b>&nbsp;{customer.fullName}
                        </p>
                        <p>
                            <b>Giới tính:</b>&nbsp;{props.genderOptions.find((x) => x.value == customer.gender) ? props.genderOptions.find((x) => x.value == customer.gender).text : "---"}
                        </p>
                        <p>
                            <b>Ngày sinh:</b>&nbsp;{customer.birthDateStr ? customer.birthDateStr : "---"}
                        </p>
                    </div>
                </div>
                <div className='form-group col-md-4'>
                    <div className='customer-info-item'>
                        <h4 className='header-title m-t-0'>
                            <b>
                                <u>Thông tin liên hệ</u>
                            </b>
                        </h4>
                        <p>
                            <b>Điện thoại:</b>&nbsp;{customer.phoneNumber ? customer.phoneNumber : "---"}
                        </p>
                        <p>
                            <b>Email:</b>&nbsp;{customer.email ? customer.email : "---"}
                        </p>
                        <p>
                            <b>Địa chỉ:</b>&nbsp;{customer.address ? customer.address : "---"}
                        </p>
                    </div>
                </div>
                <div className='form-group col-md-4'>
                    <div className='customer-info-item'>
                        <h4 className='header-title m-t-0'>
                            <b>
                                <u>Lịch sử giao dịch</u>
                            </b>
                        </h4>
                        <p>
                            <b>Tổng số đơn hàng đã mua:</b>&nbsp;{customer.countPurchasedOfAllSellers}
                        </p>
                        <p>
                            <b>Tổng tiền đã mua:</b>&nbsp;
                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={customer.purchasedAmountOfAllSellers} />
                        </p>
                        <p>
                            <b>Ngày bắt đầu mua hàng:</b>&nbsp;{customer.firstTimePurchasedDateStr}
                        </p>
                        <p>
                            <b>Ngày mua cuối cùng:</b>&nbsp;{customer.lastTimePurchasedDateStr}
                        </p>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 card-box p-3'>
                <h4 className='header-title m-t-0'>
                    <b>DANH SÁCH ĐƠN HÀNG</b>
                </h4>
                <CommonTable freeSize='orders' searchBasic data={orders} paging={paging} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={(newPaging) => getOrders(props.customer.id, newPaging)} />
            </div>
        </CommonModal>
    );
}
