import React, { useEffect, useState } from "react";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import FinanceServices from "../based/services/FinanceServices";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import Radio from "../based/inputs/Radio";
import useFormValidate from "../hooks/useFormValidate";
import TextNumber from "../based/inputs/TextNumber";

const TransferMoneyGateway = {
    BankAccount: 1,
    MoMo: 2,
};

export default function PaymentModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const { addRef, displayError, isValid } = useFormValidate();

    const [paymentModel, setPaymentModel] = useState({});
    useEffect(() => {
        setPaymentModel({ ...props.operator, paymentType: TransferMoneyGateway.BankAccount });
    }, [props.isShowModal]);
    async function _handlePayment() {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await FinanceServices.TransferToOperator(paymentModel);
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSuccess();
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        }
    }
    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            title='Thanh toán chi phí sản xuất'
            customButton={
                <button className='btn btn-custom btn-sm' onClick={() => _handlePayment()}>
                    <i className='fa fa-paper-plane-o m-r-5' /> Thanh toán
                </button>
            }
            onClose={() => props.onClose()}
        >
            <div className='form-group row'>
                <label className='col-md-4 form-label'>Người nhận:</label>
                <div className='col-md-8'>
                    <label className='form-label'>{paymentModel.operatorName}</label>
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>Ngân hàng:</label>
                <div className='col-md-8'>
                    <label className='form-label'>{paymentModel.bankName ? paymentModel.bankName : "----"}</label>
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>Tên chủ STK:</label>
                <div className='col-md-8'>
                    <label className='form-label'>{paymentModel.bankAccountName ? paymentModel.bankAccountName : "----"}</label>
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>STK Ngân hàng:</label>
                <div className='col-md-8'>
                    <Textbox ref={addRef} value={paymentModel.bankAccountNumber} id='bankAccountNumber' name='bankAccountNumber' onChanged={(name, value) => setPaymentModel({ ...paymentModel, bankAccountNumber: value })} />
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>SĐT Momo:</label>
                <div className='col-md-8'>
                    <Textbox ref={addRef} value={paymentModel.phoneNumber} id='phoneNumber' name='phoneNumber' onChanged={(name, value) => setPaymentModel({ ...paymentModel, phoneNumber: value })} />
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>Chuyển khoản qua:</label>
                <div className='col-md-8'>
                    <Radio
                        name='paymentType'
                        isInline
                        checked={paymentModel.paymentType == TransferMoneyGateway.BankAccount}
                        onCheckedChange={(name, value) => setPaymentModel({ ...paymentModel, paymentType: TransferMoneyGateway.BankAccount })}
                        label='Tài khoản ngân hàng'
                    />
                    <Radio name='paymentType' isInline checked={paymentModel.paymentType == TransferMoneyGateway.MoMo} onCheckedChange={(name, value) => setPaymentModel({ ...paymentModel, paymentType: TransferMoneyGateway.MoMo })} label='MOMO' />
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>Số tiền:</label>
                <div className='col-md-8'>
                    <TextNumber readOnly name='totalAmount' suffix='đồng' className='form-control' value={paymentModel.totalAmount} onChanged={(name, value) => {}} />
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label required'>Mã giao dịch:</label>
                <div className='col-md-8'>
                    <Textbox required ref={addRef} value={paymentModel.transferReferenceCode} id='transferReferenceCode' name='transferReferenceCode' onChanged={(name, value) => setPaymentModel({ ...paymentModel, transferReferenceCode: value })} />
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label required'>Nội dung chuyển khoản:</label>
                <div className='col-md-8'>
                    <TextArea required ref={addRef} value={paymentModel.transferDescription} id='transferDescription' name='transferDescription' onChanged={(name, value) => setPaymentModel({ ...paymentModel, transferDescription: value })} />
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-md-4 form-label'>Ghi chú:</label>
                <div className='col-md-8'>
                    <TextArea value={paymentModel.note} id='note' name='note' onChanged={(name, value) => setPaymentModel({ ...paymentModel, note: value })} />
                </div>
            </div>
        </CommonModal>
    );
}
