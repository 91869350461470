import React, { useState, useEffect } from "react";
import { Layout } from "../layout/Layout";
import Loading from "../based/Loading";
import CommonTable from "../based/CommonTable";
import ThirdPartyServices from "../based/services/ThirdPartyServices";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import Textbox from "../based/inputs/Textbox";

export default function CustomerAddressHistories(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);

    useEffect(() => {
        getDatas(Common.Paging);
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await ThirdPartyServices.GetsCustomerAddressHistories(newPaging);
        if (!err && data) {
            let pagingUp = data.paging;
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setPaging(Common.Paging);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _renderAction() {
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='tel'>Số điện thoại: </label>
                            <Textbox className='form-control' name='tel' onChanged={(name, value) => setPaging({ ...paging, tel: value })} value={paging.tel} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='fullName'>Họ tên thật:</label>
                            <Textbox className='form-control' name='fullName' onChanged={(name, value) => setPaging({ ...paging, fullName: value })} value={paging.fullName} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='email'>Email:</label>
                            <Textbox className='form-control' name='email' onChanged={(name, value) => setPaging({ ...paging, email: value })} value={paging.email} />
                        </div> 
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() =>  getDatas({ ...paging, pageNumber: 1 })}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _renderColumnHeader() {
        if (paging && paging.maxAddressHistories > 0) {
            let content = [];
            for (let idx = 0; idx < paging.maxAddressHistories; idx++) {
                let name = `Billing ${idx + 1}`;
                if ((idx + 1) % 2 == 0) name = `Shipping ${idx + 1}`;
                content.push(<th style={{ minWidth: 200 }}>Họ tên - {name}</th>);
                content.push(<th style={{ minWidth: 150 }}>SĐT - {name}</th>);
                content.push(<th style={{ minWidth: 250 }}>Địa chỉ - {name}</th>);
            }
            return content.map((item, k) => {
                return <React.Fragment key={k}>{item}</React.Fragment>;
            });
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className='cursor-pointer text-center' style={{ minWidth: 100 }}></th>
                    <th className='cursor-pointer  text-center' style={{ minWidth: 130 }}>
                        User ID-Unique
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 100 }}>
                        Platform ID
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 120 }}>
                        Số điện thoại
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 150 }}>
                        SĐT Zalo
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 150 }}>
                        Like Zalo Page
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 130 }}>
                        Facebook ID
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 130 }}>
                        Instagram ID
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 250 }}>
                        Email
                    </th>
                    <th className='cursor-pointer text-center' style={{ minWidth: 200 }}>
                        Họ tên thật
                    </th>
                    {_renderColumnHeader()}
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        if (datas && datas.length > 0) return <tbody>{_renderBodyContent()}</tbody>;
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan='100000' className='text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    }
    function _renderBodyContent() {
        let content = [];
        if (datas && datas.length > 0) {
            datas.map((cus, k) => {
                let rowSpan = (cus.customerPlatforms && cus.customerPlatforms.length) > 0 ? cus.customerPlatforms.length : 1;
                let count = 0;
                if (cus.customerPlatforms && cus.customerPlatforms.length > 0) {
                    cus.customerPlatforms.map((platform, p) => {
                        count = count + 1;
                        let arr = [];
                        let mod = count % cus.customerPlatforms.length;
                        arr.push(
                            <td className='vertical-align-middle'>
                                <span>{platform.platformName}</span>
                            </td>
                        );
                        arr.push(
                            <td className='vertical-align-middle text-center'>
                                <span>{platform.userId > 0 ? platform.userId : ""}</span>
                            </td>
                        );
                        arr.push(
                            <td className='vertical-align-middle  text-center'>
                                <span>{platform.platformId > 0 ? platform.platformId : ""}</span>
                            </td>
                        );

                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <span>{cus.phoneNumber}</span>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <span>{cus.zaloPhone}</span>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    {cus.zaloFollowed ? (
                                        <span className='status__active'>
                                            <i className='fa fa-check m-r-5'></i>Đã like
                                        </span>
                                    ) : (
                                        <span className='status__unactive'>
                                            <i className='fa fa-exclamation m-r-5'></i>Chưa like
                                        </span>
                                    )}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <span>{cus.facebookId > 0 ? cus.facebookId : ""}</span>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <span>{cus.instagramId > 0 ? cus.instagramId : ""}</span>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <span>{cus.email}</span>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle'>
                                    <span>{cus.fullName}</span>
                                </td>
                            );
                        }

                        if (paging && paging.maxAddressHistories > 0) {
                            if (platform.addressHistories && platform.addressHistories.length > 0) {
                                platform.addressHistories.map((address) => {
                                    arr.push(
                                        <td className='vertical-align-middle'>
                                            <span>{address.fullName}</span>
                                        </td>
                                    );
                                    arr.push(
                                        <td className='vertical-align-middle'>
                                            <span>{address.phoneNumber}</span>
                                        </td>
                                    );
                                    arr.push(
                                        <td className='vertical-align-middle'>
                                            <p className='mb-0'>{address.street}</p>
                                            <p className='mb-0'>{address.wardName}</p>
                                            <p className='mb-0'>{address.districtName}</p>
                                            <p className='mb-0'>{address.provinceName}</p>
                                        </td>
                                    );
                                });
                                if (platform.addressHistories.length < paging.maxAddressHistories) {
                                    for (let idx = 0; idx < paging.maxAddressHistories - platform.addressHistories.length; idx++) {
                                        arr.push(<td className='vertical-align-middle'></td>);
                                        arr.push(<td className='vertical-align-middle'></td>);
                                        arr.push(<td className='vertical-align-middle'></td>);
                                    }
                                }
                            } else {
                                for (let idx = 0; idx < paging.maxAddressHistories; idx++) {
                                    arr.push(<td className='vertical-align-middle'></td>);
                                    arr.push(<td className='vertical-align-middle'></td>);
                                    arr.push(<td className='vertical-align-middle'></td>);
                                }
                            }
                        }
                        content.push(
                            <tr key={k + "_" + p} style={{ borderBottom: mod == 0 ? "3px solid #dedede" : "" }}>
                                {arr.map((ar, iiii) => {
                                    return <React.Fragment key={iiii}>{ar}</React.Fragment>;
                                })}
                            </tr>
                        );
                    });
                }
            });
        }
        return content;
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý thông tin History Address' />
            <Loading show={isLoading} msg={msg} />
            <CommonTable data={datas} paging={paging} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} className='scroll-x' />
        </React.Fragment>
    );
}
