export const HeightAndMaxMinWeight = [
    {
        value: 110,
        label: "110",
        minWeight: 19,
        maxWeight: 28,
        sizeName: "5"
    },
    {
        value: 111,
        label: "111",
        minWeight: 20,
        maxWeight: 29,
        sizeName: "5"
    },
    {
        value: 112,
        label: "112",
        minWeight: 20,
        maxWeight: 29,
        sizeName: "5"
    },
    {
        value: 113,
        label: "113",
        minWeight: 21,
        maxWeight: 30,
        sizeName: "5"
    },
    {
        value: 114,
        label: "114",
        minWeight: 21,
        maxWeight: 30,
        sizeName: "5"

    },
    {
        value: 115,
        label: "115",
        minWeight: 22,
        maxWeight: 31,
        sizeName: "5"

    },
    {
        value: 116,
        label: "116",
        minWeight: 22,
        maxWeight: 31,
        sizeName: "5"
    },
    {
        value: 117,
        label: "117",
        minWeight: 22,
        maxWeight: 32,
        sizeName: "6"
    },
    {
        value: 118,
        label: "118",
        minWeight: 23,
        maxWeight: 32,
        sizeName: "6"
    },
    {
        value: 119,
        label: "119",
        minWeight: 23,
        maxWeight: 33,
        sizeName: "6"
    },
    {
        value: 120,
        label: "120",
        minWeight: 23,
        maxWeight: 34,
        sizeName: "6"
    },
    {
        value: 121,
        label: "121",
        minWeight: 24,
        maxWeight: 34,
        sizeName: "6"
    },
    {
        value: 122,
        label: "122",
        minWeight: 24,
        maxWeight: 35,
        sizeName: "6"
    },
    {
        value: 123,
        label: "123",
        minWeight: 25,
        maxWeight: 35,
        sizeName: "6"

    },
    {
        value: 124,
        label: "124",
        minWeight: 25,
        maxWeight: 36,
        sizeName: "7"

    },
    {
        value: 125,
        label: "125",
        minWeight: 25,
        maxWeight: 36,
        sizeName: "7"
    },
    {
        value: 126,
        label: "126",
        minWeight: 26,
        maxWeight: 37,
        sizeName: "7"

    },
    {
        value: 127,
        label: "127",
        minWeight: 26,
        maxWeight: 38,
        sizeName: "7"
    },
    {
        value: 128,
        label: "128",
        minWeight: 27,
        maxWeight: 38,
        sizeName: "7"
    },
    {
        value: 129,
        label: "129",
        minWeight: 27,
        maxWeight: 39,
        sizeName: "7"
    },
    {
        value: 130,
        label: "130",
        minWeight: 27,
        maxWeight: 39,
        sizeName: "7"
    },
    {
        value: 131,
        label: "131",
        minWeight: 28,
        maxWeight: 40,
        sizeName: "8"
    },
    {
        value: 132,
        label: "132",
        minWeight: 28,
        maxWeight: 41,
        sizeName: "8"
    },
    {
        value: 133,
        label: "133",
        minWeight: 29,
        maxWeight: 41,
        sizeName: "8"
    },
    {
        value: 134,
        label: "134",
        minWeight: 29,
        maxWeight: 42,
        sizeName: "8"
    },
    {
        value: 135,
        label: "135",
        minWeight: 30,
        maxWeight: 42,
        sizeName: "8"
    },
    {
        value: 136,
        label: "136",
        minWeight: 30,
        maxWeight: 43,
        sizeName: "8"
    },
    {
        value: 137,
        label: "137",
        minWeight: 31,
        maxWeight: 44,
        sizeName: "8"
    },
    {
        value: 138,
        label: "138",
        minWeight: 31,
        maxWeight: 44,
        sizeName: "9"
    },
    {
        value: 139,
        label: "139",
        minWeight: 31,
        maxWeight: 45,
        sizeName: "9"
    },
    {
        value: 140,
        label: "140",
        minWeight: 32,
        maxWeight: 45,
        sizeName: "9"
    },
    {
        value: 141,
        label: "141",
        minWeight: 32,
        maxWeight: 46,
        sizeName: "9"
    },
    {
        value: 142,
        label: "142",
        minWeight: 33,
        maxWeight: 47,
        sizeName: "9"
    },
    {
        value: 143,
        label: "143",
        minWeight: 33,
        maxWeight: 47,
        sizeName: "9"
    },
    {
        value: 144,
        label: "144",
        minWeight: 34,
        maxWeight: 48,
        sizeName: "9"
    },
    {
        value: 146,
        label: "146",
        minWeight: 39,
        maxWeight: 56,
        sizeName: "S"
    },
    {
        value: 147,
        label: "147",
        minWeight: 40,
        maxWeight: 57,
        sizeName: "S"
    },
    {
        value: 148,
        label: "148",
        minWeight: 40,
        maxWeight: 58,
        sizeName: "S"
    },
    {
        value: 149,
        label: "149",
        minWeight: 41,
        maxWeight: 58,
        sizeName: "S"
    },
    {
        value: 150,
        label: "150",
        minWeight: 41,
        maxWeight: 59,
        sizeName: "S"
    },
    {
        value: 151,
        label: "151",
        minWeight: 41,
        maxWeight: 60,
        sizeName: "S"
    },
    {
        value: 152,
        label: "152",
        minWeight: 42,
        maxWeight: 61,
        sizeName: "S"
    },
    {
        value: 153,
        label: "153",
        minWeight: 43,
        maxWeight: 61,
        sizeName: "M"
    },
    {
        value: 154,
        label: "154",
        minWeight: 43,
        maxWeight: 62,
        sizeName: "M"
    },
    {
        value: 155,
        label: "155",
        minWeight: 44,
        maxWeight: 63,
        sizeName: "M"
    },
    {
        value: 156,
        label: "156",
        minWeight: 44,
        maxWeight: 64,
        sizeName: "M"
    },
    {
        value: 157,
        label: "157",
        minWeight: 45,
        maxWeight: 65,
        sizeName: "M"
    },
    {
        value: 158,
        label: "158",
        minWeight: 46,
        maxWeight: 66,
        sizeName: "M"
    },
    {
        value: 159,
        label: "159",
        minWeight: 46,
        maxWeight: 66,
        sizeName: "M"
    },
    {
        value: 160,
        label: "160",
        minWeight: 47,
        maxWeight: 67,
        sizeName: "L"
    },
    {
        value: 161,
        label: "161",
        minWeight: 47,
        maxWeight: 68,
        sizeName: "L"
    },
    {
        value: 162,
        label: "162",
        minWeight: 48,
        maxWeight: 69,
        sizeName: "L"
    },
    {
        value: 163,
        label: "163",
        minWeight: 49,
        maxWeight: 70,
        sizeName: "L"
    },
    {
        value: 164,
        label: "164",
        minWeight: 49,
        maxWeight: 71,
        sizeName: "L"
    },
    {
        value: 165,
        label: "165",
        minWeight: 50,
        maxWeight: 72,
        sizeName: "L"
    },
    {
        value: 166,
        label: "166",
        minWeight: 50,
        maxWeight: 72,
        sizeName: "L"
    },
    {
        value: 167,
        label: "167",
        minWeight: 51,
        maxWeight: 73,
        sizeName: "XL"
    },
    {
        value: 168,
        label: "168",
        minWeight: 51,
        maxWeight: 74,
        sizeName: "XL"
    },
    {
        value: 169,
        label: "169",
        minWeight: 52,
        maxWeight: 75,
        sizeName: "XL"
    },
    {
        value: 170,
        label: "170",
        minWeight: 53,
        maxWeight: 76,
        sizeName: "XL"
    },
    {
        value: 171,
        label: "171",
        minWeight: 53,
        maxWeight: 77,
        sizeName: "XL"
    },
    {
        value: 172,
        label: "172",
        minWeight: 54,
        maxWeight: 78,
        sizeName: "XL"
    },
    {
        value: 173,
        label: "173",
        minWeight: 55,
        maxWeight: 78,
        sizeName: "XL"
    },
    {
        value: 174,
        label: "174",
        minWeight: 55,
        maxWeight: 79,
        sizeName: "2XL"
    },
    {
        value: 175,
        label: "175",
        minWeight: 56,
        maxWeight: 80,
        sizeName: "2XL"
    },
    {
        value: 176,
        label: "176",
        minWeight: 56,
        maxWeight: 81,
        sizeName: "2XL"
    },
    {
        value: 177,
        label: "177",
        minWeight: 57,
        maxWeight: 82,
        sizeName: "2XL"
    },
    {
        value: 178,
        label: "178",
        minWeight: 58,
        maxWeight: 83,
        sizeName: "2XL"
    },
    {
        value: 179,
        label: "179",
        minWeight: 58,
        maxWeight: 84,
        sizeName: "2XL"
    },
    {
        value: 180,
        label: "180",
        minWeight: 59,
        maxWeight: 85,
        sizeName: "2XL"
    },
    {
        value: 181,
        label: "181",
        minWeight: 60,
        maxWeight: 86,
        sizeName: "3XL"
    },
    {
        value: 182,
        label: "182",
        minWeight: 60,
        maxWeight: 87,
        sizeName: "3XL"
    },
    {
        value: 183,
        label: "183",
        minWeight: 61,
        maxWeight: 88,
        sizeName: "3XL"
    },
    {
        value: 184,
        label: "184",
        minWeight: 62,
        maxWeight: 89,
        sizeName: "3XL"
    },
    {
        value: 185,
        label: "185",
        minWeight: 62,
        maxWeight: 90,
        sizeName: "3XL"
    },
    {
        value: 186,
        label: "186",
        minWeight: 63,
        maxWeight: 91,
        sizeName: "3XL"
    },
    {
        value: 187,
        label: "187",
        minWeight: 64,
        maxWeight: 92,
        sizeName: "3XL"
    }
]