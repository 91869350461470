import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import ProductServices from "../based/services/ProductServices";
import ProductTypeModel from "../models/ProductTypeModel";
import AddEditModal from "./AddEditModal";

const ManageProductType = () => {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([new ProductTypeModel()]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        getProductTypes(paging);
    }, []);

    const getProductTypes = async (paging) => {
        setBusy(true);
        const [err, data] = await ProductServices.GetProductTypes(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(INITPAGING);
        }
        setBusy(false);
    };

    const handleDelete = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                const [err, data] = await ProductServices.DeleteProductType(id);
                if (!err && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getProductTypes(paging);
                } else {
                    reject({ title: "Lỗi", msg: err && err.errors ? err.errors[0].value : "Có lỗi xảy ra. Vui lòng thử lại sau!" });
                }
                setBusy(false);
            });
        });
    };

    const _renderAction = () => {
        return (
            <button
                className="btn btn-custom mb-2"
                onClick={() => {
                    setSelectId(0);
                    setIsShowModal(true);
                }}
            >
                Thêm mới
            </button>
        );
    };

    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th className="center">Id</th>
                    <th className="center">Tên hiển thị</th>
                    <th className="center">Loại</th>
                    <th className="center">Hành động</th>
                </tr>
            </thead>
        );
    };

    const _renderBody = () => {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td className="center">{item.id}</td>
                                <td className="center">{item.name}</td>
                                <td className="center">{item.packageTypeName}</td>
                                <td className="center">
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                    <button className="btn btn-danger ml-3" onClick={() => handleDelete(item.id)}>
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    };

    return (
        <React.Fragment>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Quản lý dòng sản phẩm" />
            <CommonTable datas={datas} paging={paging} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            <AddEditModal
                isShowModal={isShowModal}
                onClose={() => {
                    setIsShowModal(false);
                }}
                onSaveContinue={() => {
                    setIsShowModal(false);
                    getProductTypes({ ...paging });
                }}
                id={selectId}
            />
        </React.Fragment>
    );
};

export default ManageProductType;
