import PubSub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Language from "../based/Language";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CheckBox from "../based/inputs/CheckBox";
import AddOrEditUser from "./AddOrEditUser";
import AssignRole from "./AssignRole";

const User = {
    id: 0,
    userName: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
};

const initPage = {
    pageSize: 100,
    pageNumber: 1,
    orderBy: "id",
    orderDirection: "asc",
};

export default function UserManagement(props) {
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowModalRole, setIsShowModalRole] = useState(false);
    const [user, setUser] = useState(User);
    const tableRef = useRef(null);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.UserManagement });
    }, []);

    function getDatas(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        Request.Post("/api/users/get-users", newPaging)
            .then((res) => {
                if (res.success) {
                    setDatas(res.data.listObjects);
                    setPaging(res.data.paging);
                } else setDatas([]);
            })
            .catch((err) => {
                setDatas([]);
                setPaging(initPage);
            });
        setBusy(false);
        setMsg(null);
    }

    function _handleAddUser() {
        setIsShowModal(true);
        setUser(User);
    }

    function _handleEditUser() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsShowModal(true);
            setUser(dataChecked[0]);
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một người dùng để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleDeleteUsers() {
        let userDelete = datas.filter((x) => x.checked);
        if (userDelete && userDelete.length > 0) {
            let ids = [];
            userDelete.map((user) => ids.push(user.id));
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise((resolve, reject) => {
                    setBusy(true);
                    Request.Post("/api/users/delete-users/", ids)
                        .then((res) => {
                            if (res.success && res.data) {
                                resolve({ title: "Thành công", msg: "Xóa thành công." });
                                setBusy(false);
                                getDatas(paging);
                            } else {
                                setBusy(false);
                                Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                            }
                        })
                        .catch((err) => {
                            setBusy(false);
                        });
                });
            });
        } else Notify(NOTIFY.INFO, "Thông báo", "Không có người dùng nào được chọn. Vui lòng kiểm tra lại!");
    }

    function _setSystemUser() {
        let userDelete = datas.filter((x) => x.checked);
        if (userDelete && userDelete.length > 0) {
            Request.Get("/api/users/set-system-user/" + userDelete[0].id).then((res) => {
                if (res.success) {
                    Notify(NOTIFY.SUCCESS, "Thông báo", "Thành công");
                    getDatas(paging);
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                }
            });
        } else {
            Notify(NOTIFY.INFO, "Thông báo", "Không có người dùng nào được chọn. Vui lòng kiểm tra lại!");
        }
    }

    function _handleActiveEmail() {
        let dataChecked = datas.filter((x) => x.checked && !x.emailConfirmed);
        if (dataChecked && dataChecked.length > 0) {
            let ids = [];
            dataChecked.map((user) => ids.push(user.id));
            ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn xác thực email cho tài khoản đã chọn?", () => {
                return new Promise((resolve, reject) => {
                    setBusy(true);
                    setMsg("Đang lưu dữ liệu...");
                    Request.Post("/api/users/confirm-email/", ids)
                        .then((res) => {
                            if (res.success && res.data) {
                                resolve({ title: "Thành công", msg: "Xác thực thành công" });
                                setBusy(false);
                                setMsg(null);
                                getDatas(paging);
                            } else {
                                setBusy(false);
                                setMsg(null);
                                Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                            }
                        })
                        .catch((err) => {
                            setBusy(false);
                            setMsg(null);
                        });
                });
            });
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn tài khoản để thực hiện xác thực. Vui lòng kiểm tra lại!");
    }

    function _handleSaveSucceed() {
        setIsShowModal(false);
        getDatas(paging);
    }

    function _handleAssignSucceed() {
        setIsShowModalRole(false);
        getDatas(paging);
    }

    function _handleAssignRole() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsShowModalRole(true);
            setUser(dataChecked[0]);
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một người dùng để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleCheckedChange(item, idx, value) {
        let datasUpdate = [...datas];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setDatas(datasUpdate);
        setSelectedAll(datasUpdate.filter((x) => x.checked).length == datas.length);
    }

    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabledEditPermission = false;
        let isEnabledEmailVerify = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) {
                isEnabledEditPermission = true;
                isEnabledEmailVerify = !dataChecked[0].emailConfirmed;
            }
        }
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAddUser}>
                        <i className="fa fa-plus m-r-5" />
                        {Language.getValue("tableAction.add")}
                    </button>
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabledEditPermission ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEditUser}>
                                <i className="fa fa-edit m-r-5" />
                                {Language.getValue("tableAction.edit")}
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDeleteUsers}>
                                <i className="fa fa-remove m-r-5" />
                                {Language.getValue("tableAction.delete")}
                            </button>
                            <button className={isEnabledEditPermission ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleAssignRole}>
                                <i className="fa fa-key m-r-5" /> Phân quyền
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleActiveEmail}>
                                <i className="fa fa-check-square-o m-r-5" /> Xác thực email
                            </button>
                            <button className={`btn btn-custom btn-sm m-r-5 ${dataChecked && dataChecked.length == 1 ? "" : "disable"}`} onClick={_setSystemUser}>
                                Set tài khoản hệ thống
                            </button>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={5} className="center">
                        <CheckBox checked={selectedAll} name="select_all" id="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th className="cursor-pointer" width={20}>
                        Tên đăng nhập
                    </th>
                    <th className="cursor-pointer" width={10}>
                        Họ
                    </th>
                    <th className="cursor-pointer" width={10}>
                        Tên
                    </th>
                    <th className="cursor-pointer" width={20}>
                        Email
                    </th>
                    <th className="cursor-pointer" width={20}>
                        Tài khoản hệ thống
                    </th>
                    <th className="cursor-pointer" width={10}>
                        Trạng thái xác thực Email
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return datas && datas.length > 0 ? (
            <tbody>
                {datas.map((item, i) => {
                    return (
                        <tr key={i} onClick={() => _handleClickRow(i)}>
                            <td className={"center"}>
                                <CheckBox checked={item.checked ? item.checked : false} name={`checkBox_${i}`} id={item.id.toString()} label=" " onCheckedChange={(name, value) => _handleCheckedChange(item, i, value)} />
                            </td>
                            <td>{item.userName}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.isSystem ? <span className="badge badge-custom m-2">Tài khoản hệ thống</span> : ""}</td>
                            <td className="text-center">
                                {item.emailConfirmed ? (
                                    <span className="status__active">
                                        <i className="fa fa-check m-r-5"></i>Đã xác thực
                                    </span>
                                ) : (
                                    <span className="status__unactive">
                                        <i className="fa fa-exclamation m-r-5"></i>Chưa xác thực
                                    </span>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colSpan="1000" className="center">
                        {Language.getValue("common.noRecords")}
                    </td>
                </tr>
            </tbody>
        );
    }

    function _handleClickRow(index) {
        let data = [...datas];
        data[index].checked = !data[index].checked;
        setDatas(data);
        setSelectedAll(data.filter((x) => x.checked).length == datas.length);
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage=" Quản lý tài khoản" />
            <AddOrEditUser isShowModal={isShowModal} user={user} onClose={() => setIsShowModal(false)} onSaveSucceed={_handleSaveSucceed} />
            <AssignRole isShowModal={isShowModalRole} user={user} onClose={() => setIsShowModalRole(false)} onSaveSucceed={_handleAssignSucceed} />
            <CommonTable data={datas} paging={paging} ref={tableRef} headerCheckbox searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
        </React.Fragment>
    );
}
