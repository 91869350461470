import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TextArea from "../based/inputs/TextArea";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import AudioServices from "../based/services/AudioServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import Request from "../based/Request";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import initEnhanceAccount from "./EnhanceAccountManagement"
import styled from "styled-components";
import EnhanceAccountServices from "../based/services/EnhanceAccountService";

const ModalAddOrUpdate = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [user, setUser] = useState({...initEnhanceAccount});
    const { addRef, displayError, isValid } = useFormValidate();


    useEffect(() => {
        if (props.id > 0) getUser(props.id);
        else setUser({ ...initEnhanceAccount });
        return () => setUser({ ...initEnhanceAccount });
    }, [props.id]);
    

    const getUser = async (id) => {
        setIsBusy(true);
        let [err, data] = await EnhanceAccountServices.GetEnhanceAccountById(id);
        if (!err && data) {
            setIsBusy(false);
            setUser(data);
        } else {
            setIsBusy(false);
            setUser({...initEnhanceAccount});
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await EnhanceAccountServices.CreateUpdateEnhanceAccount({ ...user });
            if (!err && data == 1) {
                setIsBusy(false);
                setUser({...initEnhanceAccount});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
        
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='username' className='required'>
                Username:
                </label>
                <Textbox id='username' name='username' required ref={addRef} className='form-control' onChanged={(name, value) => setUser({ ...user, username: value })} value={user.username} />
                <label htmlFor='pw' className='required'>
                    Password:
                </label>
                <Textbox id='pw' name='pw' required ref={addRef} className='form-control' onChanged={(name, value) => setUser({ ...user, password: value })} value={user.password} />
                <label htmlFor='credit' className='required'>
                Credit:
                </label>
                <TextNumber id='credit' name='credit' required ref={addRef} className='form-control' onChanged={(name, value) => setUser({ ...user, credit: value })} value={user.credit} />
               
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdate;
const Wapper = styled.div`

    .custom-btn-choose{
        display: inline-block;
        background: #eee;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        margin-right: 10px;
    }

    input[type="file"]{
        visibility: hidden;
    }
    
`
ModalAddOrUpdate.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
