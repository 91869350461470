import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import MarketingServices from "../based/services/MarketingServices";
import DragAndDropIndex from "../based/DragAndDropIndex";
import Request from "../based/Request";
import ModalConfigTime from "./modals/ModalConfigTimeReview";

const GIFT_TYPE_PARTNER_COLLAB = 3
const MarketingPartnerCollabGift = ({campaignId, setBusy}) => {
    const [data , setData] = useState([])
    const [dataCollab , setDataCollab] = useState([])
    const tableRef = useRef(null);
    const [paging , setPaging] = useState({})
    const [giftItem , setGiftItem] = useState({})
    const [isShowModalConfig , setIsShowModalConfig] = useState(false);
    const [collabId , setCollabId] = useState(0)
    

    useEffect(()=>{
        getDataCollab()
    },[])

    useEffect(()=>{
        if(collabId > 0){
            getData(collabId)
        }
    },[collabId])

    async function getDataCollab() {
        setBusy(true);
        let [err, data] = await MarketingServices.GetPartnerCollabByCampaign(campaignId);
        if (!err && data) {
            setDataCollab(data);
            if(data.length > 0){
                setCollabId(data[0].id)
                getData(data[0].id)
            }
            setBusy(false);
        } else {
            setDataCollab([]);
            setBusy(false);
        }
    }

    const getData = async(collabId) => {
        setBusy(true);
        let [err, data] = await MarketingServices.GetGiftCollabByCampaignId(campaignId,collabId);
        if (!err && data) {
            setData(data)
        } else {
            setData([]);
        }
        setBusy(false);
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>
                        STT
                    </th>
                    <th>Tên giải thưởng</th>
                    <th>Tổng phần quà</th>
                    <th>Số khung giờ đặc biệt</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }


    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{width: 100}}>{idx + 1}</td>
                                <td>
                                    {
                                        item.name
                                    }
                                </td>
                                <td style={{ width: 200 }}>{item.countTotalAllCampaign}</td>
                                <td style={{ width: 200 }}>{item.countSpecial}</td>
                                <td style={{ width: 200 }}>
                                    <button className={"btn btn-custom m-1"}
                                    onClick={()=>{
                                        setGiftItem(item)
                                        setIsShowModalConfig(true)
                                    }}
                                >Xem tỷ lệ</button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}

            </tbody>
        );
    }

    function _renderAction() {
       return <div className="title-product">
            Quà tặng của partner collab
            
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
            {dataCollab.length > 0 && dataCollab.map((item , idx)=>{
                return <li key={idx} className="nav-item">
                <a className={collabId == item.id ? "nav-link active" : "nav-link btn-light"} onClick={() => setCollabId(item.id)}>
                    <span>{item.firstName + " " + item.lastName}</span>
                </a>
            </li>
            })}
            
        </ul>

       </div>
    }


    return <div>
            {isShowModalConfig && <ModalConfigTime isShowModal={isShowModalConfig} onClose={()=>{
                setIsShowModalConfig(false)
                setGiftItem({})
            }} gift={giftItem} 
            campaignId={campaignId}
            />}
            {dataCollab.length > 0 && <CommonTable data={data} paging={paging} hideSearchBasicButton 
                renderAction={_renderAction} 
                renderHeader={_renderHeader} 
                renderBody={_renderBody} 
                ref={tableRef} 
             />}
            
    </div>
}

export default MarketingPartnerCollabGift