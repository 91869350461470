import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import MarketingServices from "../based/services/MarketingServices";
import MarketingGiftRanus from "./MarketingGiftRanus";
import MarketingPartnerGift from "./MarketingPartnerGift";
import MarketingPartnerCollabGift from "./MarketingPartnerCollabGift";
import MarketingSpinPrizeManegement from "./MarketingSpinPrizeManegement";
import ModalChooseImage from "./modals/ModalChooseImage";

const initPage = {
    pageSize: 50,
    pageNumber: 1,
};



function MarketingWheelManagement({campaignId}) {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
    

    return ( <MarketingWheelManagementWapper>
        <Loading show={isBusy} msg={msg} />
        <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
            <li className="nav-item">
                <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)}>
                    <span>Vòng quay</span>
                </a>
            </li>
            <li className="nav-item">
                <a className={tabIndex == 1 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(1)}>
                    <span>Danh sách trúng</span>
                </a>
            </li>
        </ul>
        {tabIndex === 0 && <WheelTab campaignId={campaignId} setBusy={setBusy} />}
        {tabIndex === 1 && <WinningTab campaignId={campaignId} setBusy={setBusy} />}
    </MarketingWheelManagementWapper> );
}

const initCampaign = {
    id: 0,
    marketingCampaignId: 0,
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    wheelImage: "",
    spinCount: 0

}
const WheelTab = ({campaignId, setBusy}) => {
    const [campaign , setCampaign] = useState({})
    const [isModal , setIsModal] = useState(false);
    const [image , setImage] = useState("");
    const WHEEL = 2


    useEffect(()=>{
        getCampaign()
        
    },[campaignId])


    const getCampaign = async () => {
        let [err, data] = await MarketingServices.GetCampaignById(campaignId);
        if(!err && data){
            setCampaign(data)
        }else{
            setCampaign({...initCampaign, marketingCampaignId: campaignId})
        }
    }

    const handleUpdateDate =  async (data) =>{
        if(data.pin.length > 2 && data.pin.length <7){
            let [err, dt] = await MarketingServices.CreateOrUpdateCampaign({
                ...data
            });
            if (!err && dt != 0) {
                if(dt == 1){
                    getCampaign()
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
                }
                if(dt == -7){
                    Notify(NOTIFY.WARNING, NOTIFY.WARNING,"Ngày trùng với chiến dịch khác")
                }
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
            }
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING , "Mã pin từ 3 - 5 ký tự")
            
        }
    
}

    
     async function handleUploadFile(image) {
        
        setIsModal(false)
        let [err, dt] = await MarketingServices.DrawImage({
            imageUrl: image , imageType: WHEEL, campaignId: campaign.id
        });
        if (!err && dt && dt.imageUrl) {
            handleUpdateDate({...campaign, wheelImage: dt.imageUrl})
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại xử lý ảnh")
        }
            
      
    }

    

    
    return <div className="body-page">
            {isModal && <ModalChooseImage  tabImage={WHEEL} onContinue={handleUploadFile} setImage={setImage} isShow={isModal} onClose={()=>{setIsModal(false)}} />}

            <div className="wheel-list">
                <div className="wheel-row">
                    <div className="wheel-col">
                        <span>Mã PIN</span>
                        <input type="text" value={campaign.pin} onChange={(e)=>{
                            setCampaign({...campaign , pin: e.target.value})
                        }}
                        onBlur={()=>{
                            handleUpdateDate(campaign)
                        }}
                        
                        />
                    </div>
                    <div className="wheel-col">
                        <span>Lượt quay/ngày</span>
                        <input type="number" value={campaign.spinCount} 
                        onChange={(e)=>{
                            setCampaign({...campaign , spinCount: e.target.value})
                        }} 
                        onBlur={()=>{
                            handleUpdateDate(campaign)
                        }}
                        />
                    </div>
                </div>
                <div className="wheel-row">
                    <div className="wheel-col w-100">
                        <span>Hình vòng quay (800x800px 72ppi)</span>
                        <div className="img-wheel">
                            {campaign.wheelImage ? <img src={campaign.wheelImage} /> : <img />}
                            {campaign.wheelImage && <i onClick={(e)=>{
                                
                                handleUpdateDate({...campaign , wheelImage: ""})
                            }}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 1L1 13" stroke="#D70000" stroke-width="1.5"/>
                                        <path d="M13 13L1 0.999999" stroke="#D70000" stroke-width="1.5"/>
                                    </svg>
                                </i>}
                        </div>
                        <button
                            className={"btn btn-custom mt-2"} 
                            onClick={()=>{
                                setIsModal(true)
                            }}
                        >Chọn ảnh</button>
                        
                    </div>
                </div>
            </div>
            {campaign.id > 0 && <div className="product-list">
                <MarketingSpinPrizeManegement campaignId={campaignId} setBusy={setBusy}/>
                <MarketingGiftRanus campaignId={campaignId} setBusy={setBusy}/>
                <MarketingPartnerGift campaignId={campaignId} setBusy={setBusy}/>
                <MarketingPartnerCollabGift campaignId={campaignId} setBusy={setBusy}/>
                </div>}
        </div>
        

   
}
const PRIZE_TYPE_OPTION = [
    {value: 0, label: "Quà tặng của cửa hàng"},
    {value: 1, label: "Quà ranus tại cửa hàng"},
    {value: 2, label: "Quà voucher"},
    {value: 3, label: "Thêm lượt"},
    {value: 4, label: "Không trúng thưởng"},
]

const WinningTab = ({campaignId , setBusy}) => {
    const [data , setData] = useState([])
    const tableRef = useRef(null);
    const [paging , setPaging] = useState({})
    const [date , setDate] = useState(new Date())

    useEffect(() =>{
        getUserWin(new Date(), "DESC")
    },[])

    const getUserWin = async(date , orderDirection) => {
        setBusy(true);
        let [err, data] = await MarketingServices.GetUserWin({
            campaignId: campaignId,
            date,
            orderDirection: orderDirection
        });
        if (!err && data) {
            setData(data)
        } else {
            setData([]);
        }
        setBusy(false);
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>
                        STT
                    </th>
                    <th>Tên tài khoản</th>
                    <th>Loại quà</th>
                    <th>Quà tặng</th>
                    <th>Thời gian</th>
                </tr>
            </thead>
        );
    }


    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr key={idx} className={`winning-table-body ${item.isPinVerified  ? "row-success" : item.isOutDate ? "row-error" : ""}`}>
                            <td>{idx + 1}</td>
                            <td style={{textAlign: 'left'}}>{item.fullName}</td>
                            <td> {item.prizeType == 0 ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.0794 8.23903V8.79903C23.0074 9.09503 22.9514 9.39903 22.8554 9.67903C22.6912 10.2117 22.3908 10.6922 21.9838 11.073C21.5769 11.4538 21.0775 11.7218 20.5352 11.8503C19.9928 11.9788 19.4263 11.9634 18.8918 11.8057C18.3572 11.648 17.8731 11.3534 17.4874 10.951C17.3834 10.847 17.2954 10.727 17.1914 10.607C16.9028 11.0294 16.5155 11.375 16.0631 11.6138C15.6107 11.8526 15.1069 11.9775 14.5954 11.9775C14.0838 11.9775 13.58 11.8526 13.1276 11.6138C12.6752 11.375 12.2879 11.0294 11.9994 10.607C11.7105 11.0278 11.3236 11.3719 10.872 11.6096C10.4204 11.8474 9.91772 11.9716 9.40736 11.9716C8.89701 11.9716 8.39433 11.8474 7.94273 11.6096C7.49114 11.3719 7.10421 11.0278 6.81536 10.607L6.75936 10.655L6.61536 10.823C6.18061 11.3602 5.57746 11.735 4.90336 11.887C4.18816 12.0418 3.44151 11.9467 2.78788 11.6178C2.13425 11.2888 1.61313 10.7457 1.31136 10.079C0.973763 9.46756 0.855215 8.7591 0.975363 8.07103C1.36736 6.41503 1.77536 4.75903 2.17536 3.10303C2.23339 2.76769 2.41157 2.46496 2.6766 2.25147C2.94163 2.03797 3.27536 1.92832 3.61536 1.94303H20.4154C20.7558 1.92658 21.0904 2.03558 21.3558 2.24938C21.6212 2.46318 21.799 2.76692 21.8554 3.10303C22.1354 4.23903 22.4074 5.37503 22.6554 6.51103L23.0794 8.23903Z" fill="#B3282D"/>
<path d="M14.7685 22.0397V16.3117H9.24048V22.0397H3.83248C3.62117 22.0569 3.40868 22.0267 3.21053 21.9513C3.01238 21.8759 2.83357 21.7572 2.68714 21.6039C2.54072 21.4506 2.43038 21.2665 2.36418 21.0651C2.29797 20.8637 2.27758 20.65 2.30448 20.4397V12.9997C2.51248 13.0717 2.70448 13.1517 2.89648 13.2077C3.52017 13.4155 4.18351 13.4759 4.83448 13.3843C5.48544 13.2927 6.10634 13.0515 6.64848 12.6797C6.69322 12.6453 6.74806 12.6267 6.80448 12.6267C6.8609 12.6267 6.91574 12.6453 6.96048 12.6797C7.68538 13.1553 8.53348 13.4087 9.40048 13.4087C10.2675 13.4087 11.1156 13.1553 11.8405 12.6797C11.8877 12.644 11.9453 12.6247 12.0045 12.6247C12.0637 12.6247 12.1213 12.644 12.1685 12.6797C12.8924 13.1501 13.7372 13.4004 14.6005 13.4004C15.4638 13.4004 16.3085 13.1501 17.0325 12.6797C17.1445 12.6157 17.2165 12.5837 17.3365 12.6797C17.9414 13.0959 18.6431 13.3498 19.3743 13.4171C20.1055 13.4843 20.8418 13.3626 21.5125 13.0637L21.6965 12.9997V20.4317C21.7227 20.6446 21.7007 20.8607 21.6321 21.064C21.5636 21.2673 21.4502 21.4525 21.3005 21.6061C21.1507 21.7597 20.9684 21.8777 20.7669 21.9514C20.5654 22.0251 20.35 22.0525 20.1365 22.0317H14.7685V22.0397Z" fill="#B3282D"/>
</svg>
 :
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="3" fill="black"/>
<path d="M19.4997 9.85845V8.77699C19.4997 7.64266 19.0256 6.55479 18.1817 5.7527C17.3378 4.95061 16.1933 4.5 14.9998 4.5H6V19.5H10.4999V6.66904H12.7563C13.3459 6.67215 13.9108 6.89448 14.3301 7.28843C14.7494 7.68239 14.9898 8.21669 14.9998 8.77699V9.85845C14.9998 10.4272 14.7621 10.9727 14.3389 11.3749C13.9158 11.7771 13.3419 12.0031 12.7434 12.0031H11.6377V14.1415H12.7627C13.3578 14.1464 13.9268 14.3745 14.3458 14.7761C14.7648 15.1778 14.9998 15.7205 14.9998 16.2862V19.5H19.4997V16.2862C19.5066 15.6771 19.3765 15.0737 19.1182 14.5163C18.8599 13.9588 18.4793 13.4603 18.0019 13.054C18.4744 12.6518 18.8522 12.1591 19.1103 11.6084C19.3684 11.0577 19.5011 10.4613 19.4997 9.85845Z" fill="white"/>
</svg>

                                            
                                }{"   "}
                                { item.prizeType === PRIZE_TYPE_OPTION[0].value && PRIZE_TYPE_OPTION[0].label || 
                            item.prizeType === PRIZE_TYPE_OPTION[1].value &&PRIZE_TYPE_OPTION[1].label || 
                            item.prizeType === PRIZE_TYPE_OPTION[2].value && PRIZE_TYPE_OPTION[2].label
                           }
                                </td>
                            <td style={{textAlign: 'left'}}>

                                <span>{item.nameGift}</span>    
                            </td>   
                            <td>{Common.formatDate(new Date(item.spinTime), 'fulldate')}</td>
                        </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}

            </tbody>
        );
    }

    function _renderAction() {
       return <div className="input-date-box mb-2">
            <input type="date" className="input-date"  onChange={(e)=>{
                setDate(e.target.value)
                getUserWin(e.target.value , "DESC")
            }} />
            <span>{Common.formatDate(new Date(date))}</span>
       </div>
    }


    return <div>
            <CommonTable data={data} paging={paging} hideSearchBasicButton 
                renderAction={_renderAction} 
                renderHeader={_renderHeader} 
                renderBody={_renderBody} 
                ref={tableRef} 
             />
    </div>
}
const MarketingWheelManagementWapper = styled.div`
    background-color: #fff;
    margin-bottom: 16px;
    position: relative;

    .cursor-move{
        cursor: move;
    }

    .title-product{
        font-size: 14px;
        font-weight: bold;
        color: #676767;
        margin-bottom: 24px;
    }


    .input-date-box{
        position: relative;
        .input-date{
            margin-top: 10px;
            border-radius: 10px;
            border: 1px solid #E1E1E1;
            background: #FFF;
            width: 200px;
            height: 38px;
            padding: 0 17px;
        }
    
        span{
            position: absolute;
            background: rgb(255, 255, 255);
            height: 32px;
            left: 17px;
            display: block;
            width: 100px;
            line-height: 38px;
            top: 11px;
        }
    }
    
    .body-page{
        display: flex;
        padding: 16px;

        .wheel-list{
            width: 300px;

            .wheel-row{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                

                .wheel-col{
                    width: calc(50% - 10px);
                    position: relative;


                    .absolutedate{
                        position: absolute;
                        background-color: #fff;
                        top: 30px;
                        left: 4px;
                        width: 90px;
                    }



                    &.w-100{
                        width: 100% !important;
                    } 

                    span{
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;
                        color: #666F7B;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                    input{
                        width: 100%;
                        border: 1.5px solid #D9D9D9;
                        background: #FFF;
                        outline: none;
                        height: 30px;
                    }
                    .img-wheel{
                        width: 100%;
                        position: relative;
                        padding: 8px;
                        i{
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            cursor: pointer;
                        }
                        img{
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }

                }

               
            }
           
        }
        .product-list{
            width: calc(100% - 300px);
            padding-left: 10px;

            .content-table{
                min-height: 250px;
            }

            table{
                tr{
                    td{
                        img{
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }
                }
            }

        }
    }
`
export default MarketingWheelManagement;