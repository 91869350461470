import React, { useState, useEffect, useRef } from "react";
import ReupServices from "../based/services/ReupServices";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import TFUSelect from "../based/inputs/TFUSelect";
import Request from "../based/Request";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

const modelFolder = {
    id: 0,
    deviceId: 0,
    isDelete: false,
    x: 0,
    y: 0,
    folderName: "",
    note: "",
    positionType: -1,
    positionPage: -1,
    thumbnail: "",
};
const DATA_POSITION = [
    {
        value: 0,
        label: "Màn hình chính",
    },
    {
        value: 1,
        label: "Màn hình bên trong",
    },
];
const DATA_POSITION_PAGE = [
    {
        value: 1,
        label: "1",
    },
    {
        value: 2,
        label: "2",
    },
    {
        value: 3,
        label: "3",
    },
    {
        value: 4,
        label: "4",
    },
    {
        value: 5,
        label: "5",
    },
    {
        value: 6,
        label: "6",
    },
    {
        value: 7,
        label: "7",
    },
    {
        value: 8,
        label: "8",
    },
    {
        value: 9,
        label: "9",
    },
    {
        value: 10,
        label: "10",
    },
];

const FolderManagement = () => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [dataDevice, setDataDevice] = useState([]);

    useEffect(() => {
        setBusy(false);
        getData(paging);
        getDataDropDown();
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ReupServices.GetFolderByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    async function getDataDropDown() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ReupServices.GetDeviceDropDown();
        if (!err && data) {
            setDataDevice(data);
            setBusy(false);
        } else {
            setDataDevice([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Tên Slot</th>
                    <th>Tên thiết bị</th>
                    <th>Vị trí</th>
                    <th>Vị trí page</th>
                    <th>Tọa độ</th>
                    <th>Note</th>
                    <th>Thumbnail</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...data];
                            dt[index].thumbnail = res.data.imageUrl;
                            setData(dt);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 200 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Tên slot"
                                            value={item.folderName}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].folderName = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.folderName
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <TFUSelect
                                            className="select"
                                            options={dataDevice}
                                            placeholder="--Chọn--"
                                            value={item.deviceId}
                                            onChanged={(value) => {
                                                let dt = [...data];
                                                dt[idx].deviceId = value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.deviceName
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <TFUSelect
                                            className="select"
                                            options={DATA_POSITION}
                                            placeholder="--Chọn--"
                                            value={item.positionType}
                                            onChanged={(value) => {
                                                let dt = [...data];
                                                dt[idx].positionType = value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : item.positionType === 1 ? (
                                        "Màn hình bên trong"
                                    ) : (
                                        "Màn hình chính"
                                    )}
                                </td>
                                <td style={{ width: 150 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <TFUSelect
                                            className="select"
                                            options={DATA_POSITION_PAGE}
                                            placeholder="--Chọn--"
                                            value={item.positionPage}
                                            onChanged={(value) => {
                                                let dt = [...data];
                                                dt[idx].positionPage = value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.positionPage
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <div className="box-coordinate">
                                            <div className="w-50">
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={item.x}
                                                    onChange={(e) => {
                                                        let dt = [...data];
                                                        dt[idx].x = e.target.value;
                                                        setData(dt);
                                                    }}
                                                />
                                                <span>X:</span>
                                            </div>
                                            <div className="w-50">
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={item.y}
                                                    onChange={(e) => {
                                                        let dt = [...data];
                                                        dt[idx].y = e.target.value;
                                                        setData(dt);
                                                    }}
                                                />
                                                <span>Y:</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="box-coordinate">
                                            <span className="w-50">X: {item.x}</span>
                                            <span className="w-50">Y: {item.y}</span>
                                        </div>
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Ghi chú"
                                            value={item.note}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].note = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.note
                                    )}
                                </td>
                                <td style={{ width: 250, textAlign: "center",position: 'relative' }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <div>
                                            {item.thumbnail ? (
                                                <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                    <img style={{ maxWidth: 250, maxHeight: 100 }} src={item.thumbnail} />
                                                    <span style={{ position: 'absolute', top: 10 , right: 10 , fontSize: 30 , lineHeight: 0.5}} onClick={()=>{
                                                        let dt = [...data]
                                                        dt[idx].thumbnail = ""
                                                        setData(dt)
                                                    }}>
                                                        &times;
                                                    </span>

                                                </label>
                                            ) : (
                                                ""
                                            )}
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                hidden={item.thumbnail ? true : false}
                                                type="file"
                                                id={`upload-${idx}`}
                                                onChange={(e) => {
                                                    handleUploadFile(e, idx, data);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <img style={{ maxWidth: 250, maxHeight: 100 }} src={item.thumbnail} />
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm tọa độ +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {}

    async function handleAddItem() {
        setData([{ ...modelFolder }, ...data]);
    }

    function IsDisable(idx) {
        if (!data[idx].deviceId || !data[idx].folderName || data[idx].x < 0 || data[idx].y < 0 || (data[idx].positionType <= -1) | (data[idx].positionPage <= 0)) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await ReupServices.CreateUpdateFolder(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            let ids = [];
            ids.push(id);
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await ReupServices.DeleteFolder(ids);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
        </StyleBody>
    );
};
const StyleBody = styled.div`
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    .box-coordinate {
        display: flex;
        width: 100%;
        align-items: center;

        .w-50 {
            width: 50%;
            display: block;
            min-width: 50%;
            max-width: 50%;
            position: relative;
            padding: 5px;

            input {
                width: 100%;
                border: none;
                outline: none;
                height: 40px;
                background: #ebebeb;
                padding-left: 20px;
            }
            span {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
            }
        }
    }
`;
export default FolderManagement;
