import { PRODUCT_STATUS } from "../based/Constants";
export class ProductVariantExtendsModel {
  constructor() {
    this.batchCode = "";
    this.productVariantId = 0;
    this.manufacturingDate = new Date();
    this.expiredDate = new Date();
    this.serialNumber = initSerialNumber;
    this.imei = [];
    this.orderId = 0;
    this.isDelete = false
  }
}

export class SerialNumberModel {
  constructor() {
    this.productType = "";
    this.serialNumber = [];
  }
}

const initSerialNumber = [
  { productType: PRODUCT_STATUS.Good, serialNumber: [] },
  { productType: PRODUCT_STATUS.Defective, serialNumber: [] },
  { productType: PRODUCT_STATUS.Broken, serialNumber: [] },
];
