import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY, OPERATOR_ORDER_INLINE_STATUS, PRINT_TYPE, PRINT_TYPE_NAME } from "../based/Constants";
import { Notify } from "../based/Notify";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import Loading from "../based/Loading";
import OperatorServices from "../based/services/OperatorServices";
import ProductionServices from "../based/services/ProductionServices";
import { OperatorOrderModel } from "../models/OperatorOrderModel";
import OperatorOrderDetail from "./components/OperatorOrderDetail";

const inlineStatusOptions = Object.keys(OPERATOR_ORDER_INLINE_STATUS).map((item) => {
    return {
        value: OPERATOR_ORDER_INLINE_STATUS[item],
        text: item,
        label: item,
    };
});

const printTypeOptions = [
    {
        value: PRINT_TYPE.DTG,
        label: PRINT_TYPE_NAME[PRINT_TYPE.DTG],
    },
    {
        value: PRINT_TYPE.PET,
        label: PRINT_TYPE_NAME[PRINT_TYPE.PET],
    },
];

function ManageOperatorOrder(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([new OperatorOrderModel()]);
    const [paging, setPaging] = useState({ ...INITPAGING, status: null, inlineStatus: null, printType: 0, handleBy: "", fromDate: null, toDate: null, orderDirection: "asc", orderBy: "TagId" });
    const [isShow, setIsShow] = useState(false);
    const [creatorOptions, setCreatorOptions] = useState([]);

    useEffect(() => {
        _getOperatorOrder({ ...paging });
        _getCreatorOptions();
    }, []);

    const _getOperatorOrder = async (paging) => {
        let [err, data] = await OperatorServices.GetOperatorOrdersTotal(paging);
        if (!err && data) {
            let listObjects = data.listObjects;
            let paging = data.paging;
            paging.fromDate = paging.fromDate ? new Date(paging.fromDate) : null;
            paging.toDate = paging.toDate ? new Date(paging.toDate) : null;
            setDatas(listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING, status: null, inlineStatus: null, printType: null, handleBy: null, fromDate: null, toDate: null });
        }
    };

    const _getCreatorOptions = async () => {
        let [err, data] = await OperatorServices.GetOperators({ ...INITPAGING, pageSize: 50 });
        if (!err && data) {
            let operator = data.listObjects.map((item) => {
                return { value: item.userName, text: item.fullName, label: item.fullName };
            });
            setCreatorOptions(operator);
        } else setCreatorOptions([]);
    };

    const handleComplete = async (id) => {
        let [err, data] = await ProductionServices.UpdateInlineStatus([id], 9);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Chuyển trạng thái thành công");
            _getOperatorOrder({ ...paging });
        }
    };

    const _renderFilter = useMemo(() => {
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group full-width">
                    <div className="box__search" style={{ minHeight: 163 }}>
                        <div className=" form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="createdBy">Người xử lý</label>
                                <TFUSelect name="createdBy" value={paging.handleBy} options={creatorOptions} placeholder="---Chọn người xử lý---" onChanged={(value) => setPaging({ ...paging, handleBy: value })} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inlineStatus">Trạng thái sản xuất:</label>
                                <TFUSelect name="inlineStatus" value={paging.inlineStatus} options={inlineStatusOptions} placeholder="---Tất cả---" onChanged={(value) => setPaging({ ...paging, inlineStatus: value })} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="printType">Kiểu in:</label>
                                <TFUSelect name="printType" value={paging.printType} options={printTypeOptions} placeholder="---Tất cả---" onChanged={(value) => setPaging({ ...paging, printType: +value })} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="time">Thời gian:</label>
                                <div className="input-group form-row">
                                    <div className="input-daterange input-group" id="date-range">
                                        <TFUDatetime
                                            dateFormat="dd/MM/yyyy"
                                            name="startDate"
                                            maxDate={paging.toDate}
                                            value={paging.fromDate}
                                            placeholder="Từ ngày"
                                            onChanged={(newValue) => setPaging({ ...paging, fromDate: newValue ? new Date(newValue) : null })}
                                        />
                                        <TFUDatetime
                                            dateFormat="dd/MM/yyyy"
                                            name="endDate"
                                            minDate={paging.fromDate}
                                            value={paging.toDate}
                                            placeholder="Đến ngày"
                                            onChanged={(newValue) => setPaging({ ...paging, toDate: newValue ? new Date(newValue) : null })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row ml-1">
                            <div className="form-group">
                                <button className="btn btn-custom btn-sm m-r-5" onClick={() => _getOperatorOrder({ ...paging })}>
                                    <i className="fa fa-search m-r-5" />
                                    Tìm kiếm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const _renderHeader = () => {
        return (
            <thead>
                <tr className="text-center">
                    <th>Tag Id</th>
                    <th>Mã sản phẩm đơn hàng</th>
                    <th>Trạng thái</th>
                    <th>Loại in</th>
                    <th>In logo</th>
                    <th>Xử lý In Artwork</th>
                    <th>Xử lý In Logo</th>
                    <th>Xử lý Ép Artwork</th>
                    <th>Xử lý Ép Logo</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    };

    const _renderBody = () => {
        if (datas && datas.length > 0)
            return (
                <tbody>
                    {datas.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="vertical-align-middle center">{item.id}</td>
                                <td className="vertical-align-middle center">{item.orderId}</td>
                                <td className="vertical-align-middle center">{item.operatorOrderInlineStatusName}</td>
                                <td className="vertical-align-middle center">{item.printTypeName}</td>
                                <td className="vertical-align-middle center">{item.isBrandStamp ? "Có" : "Không"}</td>
                                <td className="vertical-align-middle center">{item.printArtworkHandleName}</td>
                                <td className="vertical-align-middle center">{item.printLogoHandleName}</td>
                                <td className="vertical-align-middle center">{item.pressureArtworkHandleName}</td>
                                <td className="vertical-align-middle center">{item.pressureLogoHandleName}</td>
                                <td className="vertical-align-middle center">
                                    <button
                                        className="center btn btn-custom"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleComplete(item.id);
                                        }}
                                    >
                                        Chuyển trạng thái đã in
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            );
        else
            return (
                <tbody>
                    <tr>
                        <td className="vertical-align-middle text-center" colSpan={8}>
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    };

    const _renderAction = () => {
        // return (
        //     <div className='p-10 box__actions'>
        //         <div className='form-group full-width'>
        //             <button className='btn btn-custom btn-sm mr-2' onClick={() => setIsShow(true)}>
        //                 Chi tiết
        //             </button>
        //         </div>
        //     </div>
        // );
    };

    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs currentPage="Quản lý đơn sản xuất" />
            <div className="card-box p-3">
                <div className="form-row">
                    <div className="col-sm-6">{_renderFilter}</div>
                </div>
            </div>
            <CommonTable datas={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={_getOperatorOrder} />
            <OperatorOrderDetail
                isShowModal={isShow}
                onClose={() => {
                    setIsShow(false);
                }}
            />
        </React.Fragment>
    );
}

ManageOperatorOrder.propTypes = {};

export default ManageOperatorOrder;
