import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import DatePicker from "react-datepicker";
import { NOTIFY } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import { Notify } from "../based/Notify";

const BasicInfo = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getBasicInfo: () => {
            return basicInfo;
        },
    }));

    const [basicInfo, setBasicInfo] = useState(props.basicInfo);

    useEffect(() => {
        if (props.basicInfo) {
            setBasicInfo(props.basicInfo);
        }
    }, [props.basicInfo]);

    return (
        <React.Fragment>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="title" className="col-form-label">
                        Tên chương trình KM
                    </label>
                    <Textbox name="title" required value={basicInfo.title} onChanged={(name, value) => setBasicInfo({ ...basicInfo, title: value })} />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="title" className="col-form-label">
                        URL SEO
                    </label>
                    <Textbox name="shortLink" required value={basicInfo.shortLink} onChanged={(name, value) => setBasicInfo({ ...basicInfo, shortLink: value })} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="showTime" className="col-form-label">
                        Ngày hiển thị
                    </label>
                    <DatePicker
                        onChange={(value) => {
                            setBasicInfo({ ...basicInfo, showTime: value });
                        }}
                        selected={basicInfo.showTime}
                        adjustDateOnChange
                        minDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeCaption="time"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd/MM/yyyy HH:mm"
                        dropdownMode="select"
                        popperPlacement="auto"
                        className="form-control"
                    />{" "}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="startTime" className="col-form-label">
                        Ngày bắt đầu
                    </label>
                    <DatePicker
                        onChange={(value) => {
                            setBasicInfo({ ...basicInfo, startTime: value });
                        }}
                        selected={basicInfo.startTime}
                        adjustDateOnChange
                        minDate={basicInfo.showTime}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeCaption="time"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd/MM/yyyy HH:mm"
                        dropdownMode="select"
                        popperPlacement="auto"
                        className="form-control"
                    />{" "}
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="endTime" className="col-form-label">
                        Ngày kết thúc
                    </label>
                    <DatePicker
                        onChange={(value) => {
                            if (value > basicInfo.startTime) {
                                setBasicInfo({ ...basicInfo, endTime: value });
                            } else {
                                Notify(NOTIFY.WARNING, "Cảnh báo", "Ngày giờ kết thúc chiến dịch phải lớn hơn ngày giờ bắt đầu!");
                            }
                        }}
                        selected={basicInfo.endTime}
                        adjustDateOnChange
                        minDate={basicInfo.startTime}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeCaption="time"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd/MM/yyyy HH:mm"
                        dropdownMode="select"
                        popperPlacement="auto"
                        className="form-control"
                    />{" "}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="index" className="col-form-label">
                        Thứ tự hiển thị
                    </label>
                    <TextNumber min={1} name="index" required value={basicInfo.index} onChanged={(name, value) => setBasicInfo({ ...basicInfo, index: value })} />
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="description" className="col-form-label">
                        Mô tả
                    </label>
                    <textarea
                        className="form-control"
                        rows="3"
                        value={basicInfo.description ? basicInfo.description : ""}
                        onChange={(e) =>
                            setBasicInfo({
                                ...basicInfo,
                                description: e.target.value,
                            })
                        }
                    />
                </div>
            </div>

            <div className="form-group">
                <CheckBox id="isActive" name="isActive" checked={basicInfo.isActive} onCheckedChange={(name, value) => setBasicInfo({ ...basicInfo, isActive: value })} label="Kích hoạt chương trình" />
            </div>
        </React.Fragment>
    );
});

export default BasicInfo;
