import styled from "styled-components";

export const TopBarWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
    z-index: 100;
    background: #f1f1f1;
    border-bottom: 1px solid #cfcfcf;
    .price {
        margin-right: 15px;
    }
    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 10px;
        margin-right: 15px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }
`;
export const BtnRounded = styled.div`
    appearance: none;
    width: ${({ width }) => (width ? width : "auto")};
    background-color: transparent;
    border: 2px solid #1a1a1a;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3b3b3b;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    height: 32px;
    padding: 3px 7px;
    &:disabled {
        pointer-events: none;
    }

    &:hover {
        color: #fff;
        background-color: #1a1a1a;
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        transform: translateY(-2px);
    }

    &:active {
        box-shadow: none;
        transform: translateY(0);
    }
`;
