import BaseServices from "./BaseServices";

const ProductionServices = {
    GetOrderTagDTGModel: async (tagId) => {
        return await BaseServices.Get(`/api/production/get-order-tag/${tagId}`);
    },
    UpdateProcessCoverById: async (tagId) => {
        return await BaseServices.Get(`/api/production/update-process-cover/${tagId}`);
    },
    GetOrderTagPETModel: async (tagId) => {
        return await BaseServices.Get(`/api/production/get-order-pet-tag/${tagId}`);
    },
    UpdateProcessPressureById: async (tagId) => {
        return await BaseServices.Get(`/api/production/update-process-pressure/${tagId}`);
    },
    UpdateBeforeHandoverShipping: async (tagId) => {
        return await BaseServices.Get(`/api/production/update-before-handover-shipping/${tagId}`);
    },
    UpdateBeforeHandoverShipping: async (tagId) => {
        return await BaseServices.Get(`/api/production/update-before-handover-shipping/${tagId}`);
    },
    PrintPETTag: async (tagIds) => {
        return await BaseServices.Get(`/api/production/print-pet-tag/${tagIds}`);
    },
    UpdateInlineStatus: async (tagIds, inlineStatus) => {
        return await BaseServices.Post(`/api/production/update-inline-status/${inlineStatus}`, tagIds);
    },
    GetProductPickBatchesAsync: async (batch) => {
        return await BaseServices.Get(`/api/production/get-product-pick-batches-async?printingBatch=` + batch);
    },
};

export default ProductionServices;
