import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../../based/CommonTable";
import { NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import Request from "../../based/Request";
import CheckBox from "../../based/inputs/CheckBox";
import TFUSelect from "../../based/inputs/TFUSelect";
import MarketingServices from "../../based/services/MarketingServices";
import { Modal } from "react-bootstrap";
import Common from "../../based/Common";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};

const ModalChooseImage = ({tabImage, isShow , onClose , setImage , onContinue}) => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [selectId, setSelectId] = useState(0);
    const [choose , setChoose] = useState({id: 0})

    useEffect(() => {
        setBusy(false);
        getData(tabImage);
    }, []);

    

    async function getData(type) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetImageByType(type);
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

   
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>Ảnh</th>
                    <th>Tên</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 40, textAlign: "center" }}>
                                    {item.id > 0 && (
                                        <CheckBox
                                            checked={item.checked}
                                            name={`${item.id}`}
                                            label=" "
                                            onCheckedChange={(name, value) => {
                                                let dt = [...data];
                                                dt.map((x) => (x.checked = false));
                                                dt[idx].checked = value;
                                                setData(dt);
                                                if (value) {
                                                    setSelectId(item.id);
                                                } else {
                                                    setSelectId(0);
                                                }
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 100, textAlign: "center" }}>
                               
                                    <div>
                                       
                                            <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                <img style={{ maxWidth: 100, maxHeight: 100 }} src={item.imageUrl} />
                                                
                                            </label>
                                       
                                    </div>
                                
                                </td>
                                <td style={{ width: 250 }}>
                                    {
                                        item.name
                                    }
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
               
            </tbody>
        );
    }

   
    function _renderAction() {
        return <div className="mb-3 text-title">Hình ảnh</div>;
    }

   
    const [imageVariant, setImageVariant] = useState([]);
    useEffect(() => {
        if (selectId > 0) {
            getImageVariant();
        } else {
            setImageVariant([]);
        }
    }, [selectId]);

    async function getImageVariant() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetImageVariantByMarketingImageId(selectId);
        if (!err && data) {
            setImageVariant(data);
            setBusy(false);
        } else {
            setImageVariant([]);
            setBusy(false);
        }
    }
 



    return (
         <Modal show={isShow} onHide={onClose} size={'lg'} id={Common.generateGuid()} enforceFocus={false}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>
                    Quản lý hình ảnh
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor:'#f3f3f3'}}>
                <StyleBody>
                    <Loading show={isBusy} msg={msg} />
                    <div className="w-30">
                        <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
                    </div>

                    <div className="w-70">
                        <h5 className="text-title">Biến thể</h5>
                        <div className="list-item">
                            {imageVariant && imageVariant.length > 0 &&
                            imageVariant.map((item, idx)=>{
                                return <div key={idx} className={`item-image-variant ${choose.id == item.id ? "active" : ""}`}
                                    onClick={()=>{
                                        setChoose(item)
                                    }}
                                >
                                    <div className="w40per">
                                        {
                                        item.imageUrl ? <img src={item.imageUrl}/> : <img />}
                                        
                                    </div>
                                    <div className="w60per">
                                        <textarea value={item.name} readOnly />

                                       
                                    </div>
                                </div>
                            })}
                            
                           
                        </div>
                    </div>
                </StyleBody>
            </Modal.Body>
            <Modal.Footer>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <button className="btn btn-danger"
                    onClick={onClose}
                    >Đóng</button>
                    <button className="btn btn-custom ml-2"
                        onClick={async()=>{
                            if(choose && choose.imageUrl){
                                await setImage(choose.imageUrl)
                                onContinue(choose.imageUrl)
                            }
                        }}
                    >Chọn</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
const StyleBody = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;


    .list-item{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        .item-image-variant{
            width: calc(50% - 5px);
            min-width: calc(50% - 5px);
            max-width: calc(50% - 5px);
            margin-bottom: 10px;
            border: 1.5px solid #D9D9D9;
            background: #FFF;
            display: flex;

            &.active{
                border: 2px solid #64C5B1;
                background: #DFFFE0;
            }

            .w40per{
                width: 40%;
                position: relative;
                background: #D9D9D9;


                img{
                    width: 100%;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                }
                .remove{
                    cursor: pointer;
                    top: 5px;
                    right: 5px;
                    font-size: 30px;
                    position: absolute;
                    line-height: 1;
                    color: red;
                }
            }

            .w60per{
                width: 60%;
                text-align: end;
                textarea{
                    width: 100%;
                    height: calc(100% - 0px);
                }

            }
        }
    }
    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }
    .w-100 {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #fff;

        h4{
            color: #444;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
        }

        .type_color_list{
            display: flex;

            .type_color_item{
                margin-right: 40px;

                input{
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    height: 26px;
                    width: 100px;
                    &[type="number"]{
                        width: 50px;
                    }

                    ::placeholder {
                        opacity: 0.3;
                    }
                }

               
                .row{
                    margin-bottom: 10px;

                    input{
                        width: 150px;
                    }

                    span{
                        margin-left: 10px;
                        width: 120px;
                        display: block;
                    }
                }
            }
        }
    }


    .w-30 {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
    .w-70 {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
        padding: 15px;
        background-color: #fff;

    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    
`;
export default ModalChooseImage;
