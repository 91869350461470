import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { BANKACCOUNT_TYPE, CONSTANTS, NOTIFY } from "../based/Constants";
import { BtnRounded } from "../based/content/ct.elm";
import { Notify } from "../based/Notify";
import PhoneNumber from "../based/refactor/PhoneNumber";
import TextArea from "../based/refactor/TextArea";
import { default as TextBox, default as Textbox } from "../based/refactor/Textbox";
import TFUSelect from "../based/refactor/TFUSelect";
import ToolTip from "../based/refactor/ToolTip";
import Request from "../based/Request";
import CommonServices from "../based/services/CommonServices";
import TFUSelectSubdivisions from "../based/TFUSelectSubdivisions";
import { ApplicationContext } from "../context/ApplicationContext";
import useFormValidate from "../hooks/useFormValidate";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import { fetchAgencyUser, removeIdentificationImage, updateAgencyUser, uploadAvatarImage, uploadBannerImage } from "../redux/creator-info/actions";
import { selectAgency } from "../redux/creator-info/selector";

const IMAGE_TYPE = {
    BANNER: "BANNER",
    AVATAR: "AVATAR",
};
const menuItem = ["Trang chủ", "Thông tin cá nhân"];

const CreatorProfileRefactor = ({ agency, fetchAgencyUser, updateAgencyUser, uploadBannerImage, uploadAvatarImage, removeIdentificationImage, history }) => {
    const appCtx = useContext(ApplicationContext);
    const bannerRef = useRef();
    const avatarRef = useRef();
    const [agencyModel, setAgencyModel] = useState(agency);
    const { addRef, displayError, isValid } = useFormValidate();
    const [bankList, setBankList] = useState([]);
    const [showBank, setShowBank] = useState(false);
    const [showMomo, setShowMomo] = useState(false);

    useEffect(() => {
        getBanks();
        fetchAgencyUser();
    }, []);

    useEffect(() => {
        const updatedAgency = { ...agency };
        setAgencyModel(updatedAgency);
    }, [agency]);

    useEffect(() => {
        let bank = detectBankInfo(BANKACCOUNT_TYPE.Bank, "bankAccountName");
        let momo = detectBankInfo(BANKACCOUNT_TYPE.Momo, "bankAccountNumber");
        if (bank) setShowBank(true);
        if (momo) setShowMomo(true);
    }, [agencyModel]);

    const getBanks = async () => {
        const [err, data] = await CommonServices.GetBanks();
        if (!err && data) {
            var bankList = data.map((item) => {
                item.value = item.value;
                item.label = item.text;
                return item;
            });
            setBankList(bankList);
        } else {
            setBankList([]);
        }
    };
    const handleClearBank = (bankType) => {
        const saveData = { ...agencyModel };
        let bankAccounts = saveData.bankAccounts;
        switch (bankType) {
            case 1:
                let bank = bankAccounts.find((x) => x.bankAccountType == bankType);
                if (bank) {
                    bank.bankCode = 0;
                    bank.bankAccountName = "";
                    bank.bankAccountNumber = "";
                    setShowBank(false);
                }
                break;
            case 2:
                let momo = bankAccounts.find((x) => x.bankAccountType == bankType);
                if (momo) {
                    momo.bankAccountName = "";
                    momo.bankAccountNumber = "";
                    setShowMomo(false);
                }
                break;
            default:
                break;
        }
        setAgencyModel(saveData);
    };
    const inputUserChangeHandler = (name, value) => {
        const updatedUser = { ...agencyModel.user };
        updatedUser[name] = value;
        setAgencyModel({ ...agencyModel, user: updatedUser });
    };
    const detectBankInfo = (bankType, name) => {
        let currentValue = "";
        let bankAccounts = agencyModel && agencyModel.bankAccounts ? [...agencyModel.bankAccounts] : [];
        if (bankAccounts && bankAccounts.length > 0) {
            let bankInfo = bankAccounts.find((x) => x.bankAccountType == bankType);
            if (bankInfo != null) currentValue = bankInfo[name];
        }
        return currentValue;
    };
    const inputBankAccountChangeHandler = (name, value, bankType) => {
        const updatedBankAccounts = agencyModel && agencyModel.bankAccounts ? [...agencyModel.bankAccounts] : [];
        if (updatedBankAccounts && updatedBankAccounts.length > 0) {
            let currentBank = updatedBankAccounts.find((x) => x.bankAccountType == bankType);
            currentBank[name] = value;
            setAgencyModel({ ...agencyModel, bankAccounts: updatedBankAccounts });
        }
    };
    const inputAgencyChangeHandler = (name, value) => {
        if (name == "identificationFrontUrl" || name == "identificationBackUrl") {
            if (!value) removeIdentificationImage(name);
        }
        var re = /^[a-zA-Z0-9]+$/;
        if (name == "identificationNumber" && !re.test(value)) return;
        setAgencyModel({ ...agencyModel, [name]: value });
    };
    const selectSubdivisionsHandler = (obj) => {
        const { provinceCode, districtCode, wardCode } = obj;
        const updatedUser = { ...agencyModel.user };
        updatedUser.provinceCode = provinceCode;
        updatedUser.districtCode = districtCode;
        updatedUser.wardCode = wardCode;
        setAgencyModel({ ...agencyModel, user: updatedUser });
    };
    const pickImageHandler = (imageType) => {
        switch (imageType) {
            case IMAGE_TYPE.BANNER:
                return bannerRef.current.click();
            case IMAGE_TYPE.AVATAR:
                return avatarRef.current.click();
            default:
                return;
        }
    };
    const uploadImageHandler = (imgFile, imageType) => {
        switch (imageType) {
            case IMAGE_TYPE.BANNER:
                return uploadBannerImage(imgFile);
            case IMAGE_TYPE.AVATAR:
                return uploadAvatarImage(imgFile);
            default:
                return;
        }
    };
    const updateAgencyUserHandler = async () => {
        const saveData = { ...agencyModel };
        const { setApplicationInfo } = appCtx;
        const updatedAppInfo = { ...appCtx.applicationInfo };
        updatedAppInfo.userInfo = saveData.user;

        if (isValid()) {
            const res = await updateAgencyUser(saveData);
            if (res && !res.data && !res.errors) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật thông tin thành công!");
                setApplicationInfo(updatedAppInfo);
                history.push("/profile-info");
            } else {
                if (res.errors) displayError(res.errors);
                else Notify(NOTIFY.ERROR, "Thất bại", res.data ? res.data : "Cập nhật thông thất bại!");
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    function handleImageChange(e, name) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file, 0)
                    .then((res) => {
                        if (res && res.data) {
                            inputAgencyChangeHandler(name, res.data.imageUrl);
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                          return `${prev}${item.value}<br/>`;
                                      }, "")
                                    : "";
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                    })
                    .catch((error) => {});
            };

            reader.readAsDataURL(file);
        }
    }
    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title="Thông tin cá nhân" />
            <Wrapper>
                <WrapperUser boxShadow="none">
                    <div className="notify">Bạn vui lòng điền thông tin cá nhân để sử dụng đầy chức năng của Ranus Creator</div>
                    <div className="creator-profile mb-100">
                        <WrapperBlock>
                            <div className="creator-profile__basic-info">
                                {/* Body */}
                                <div className="form-row">
                                    {/* Avatar */}
                                    <div className="col-12 form-group cursor-pointer d-flex">
                                        <WrapperImage>{agencyModel && agencyModel.user && (agencyModel.user.avatar ? <img src={agencyModel.user.avatar} /> : agencyModel.user.fullName ? agencyModel.user.fullName.charAt(0) : "")}</WrapperImage>
                                        <div className="upload-image">
                                            <div className="title">150x150px</div>
                                            <div className="upload-image-button">
                                                <input
                                                    type={"file"}
                                                    className="d-none"
                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        if (!files || files.length <= 0) return;
                                                        uploadImageHandler(files[0], IMAGE_TYPE.AVATAR);
                                                    }}
                                                    id="upload-image-button"
                                                />
                                                <label htmlFor="upload-image-button" className="cursor-pointer">
                                                    Tải ảnh đại diện lên
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-title">Thông tin cá nhân</div>
                                    <div className="form-row col-12">
                                        <div className="form-row col-12">
                                            <div className="form-group col-md-3">
                                                <label htmlFor="displayName" className="required">
                                                    Nghệ danh
                                                    <ToolTip content="Tên hiển thị ở đầu trang Creator và là duy nhất" className="ml-2">
                                                        ?
                                                    </ToolTip>
                                                </label>
                                                <Textbox
                                                    className={"form-control"}
                                                    ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                                    required
                                                    maxLength={50}
                                                    id="displayName"
                                                    name="displayName"
                                                    value={agencyModel.displayName || ""}
                                                    onChanged={inputAgencyChangeHandler}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="firstName" className="required">
                                                    Họ
                                                </label>
                                                <Textbox className={"form-control"} required ref={addRef} id="firstName" name="firstName" value={agencyModel.user.firstName || ""} onChanged={inputUserChangeHandler} />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="lastName" className="required">
                                                    Tên
                                                </label>
                                                <Textbox
                                                    className={"form-control"}
                                                    ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                                    required
                                                    id="lastName"
                                                    name="lastName"
                                                    value={agencyModel.user.lastName || ""}
                                                    onChanged={inputUserChangeHandler}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="birthday" className="required">
                                                    Ngày sinh
                                                </label>
                                                <DatePicker
                                                    required
                                                    adjustDateOnChange
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    peekNextMonth
                                                    timeCaption="time"
                                                    timeIntervals={15}
                                                    dateFormat="dd/MM/yyyy"
                                                    dropdownMode="select"
                                                    className="form-control"
                                                    selected={new Date(agencyModel.user.birthday)}
                                                    onChange={(value) => {
                                                        var now = new Date();
                                                        var y1990 = new Date("1/1/1990");
                                                        if (value < y1990) value = y1990;
                                                        if (value > now) value = now;
                                                        const updatedUser = { ...agencyModel.user };
                                                        updatedUser.birthday = value;
                                                        setAgencyModel({ ...agencyModel, user: updatedUser });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row col-12">
                                            <div className="form-group col-md-3">
                                                <label htmlFor="email" className="required">
                                                    Email
                                                </label>
                                                <Textbox className={"form-control"} required ref={addRef} readOnly={true} id="email" name="email" value={agencyModel.user.email || ""} onChanged={inputUserChangeHandler} />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="phoneNumber" className="required">
                                                    Số điện thoại
                                                </label>
                                                <PhoneNumber required id="phoneNumber" name="phoneNumber" value={agencyModel.user.phoneNumber || ""} onChanged={inputUserChangeHandler} />
                                            </div>
                                        </div>
                                        <div className="form-row col-12">
                                            <div className="form-group col-md-3">
                                                <label htmlFor="address">Địa chỉ</label>
                                                <TextArea id="address" name="address" className="form-control" value={agencyModel.user.address || ""} ref={addRef} onChanged={inputUserChangeHandler} />
                                            </div>
                                            {/* Province, District, Ward */}
                                            <TFUSelectSubdivisions
                                                showProvince
                                                showDistrict
                                                showWard
                                                provinceCode={agencyModel.user.provinceCode || ""}
                                                districtCode={agencyModel.user.districtCode || ""}
                                                wardCode={agencyModel.user.wardCode || ""}
                                                onChanged={selectSubdivisionsHandler}
                                                parentClassName="form-row col-md-9"
                                                childClassName="form-group col-md-4"
                                            />
                                        </div>
                                        <div className="form-group col-md-12 mb-0">
                                            <label htmlFor="aboutMe">Mô tả về bạn</label>
                                            <TextArea id="aboutMe" name="aboutMe" className="form-control" value={agencyModel.aboutMe || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                        <div className="form-group col-md-12 my-2">
                                            <label htmlFor="aboutMe">Kết nối với Telegram</label>
                                            {agencyModel && agencyModel.telegramId > 0 ? (
                                                <div>{agencyModel.telegramId}</div>
                                            ) : (
                                                <button
                                                    className="btn btn-danger d-block"
                                                    onClick={(e) => {
                                                        window.open(`https://t.me/jarvis012022_bot?start=${agencyModel.telegramCode}`, "_blank");
                                                    }}
                                                >
                                                    Telegram Connect
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WrapperBlock>

                        <WrapperBlock>
                            {/* CCCD */}
                            <div className="creator-profile__about rounded">
                                <div className="main-title">Thông tin xác thực</div>
                                {/* Body */}
                                <div className="form-row">
                                    <div className="form-row col-12">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="identificationNumber" className="required">
                                                Số CMND/CCCD:
                                            </label>
                                            <Textbox className={"form-control"} required ref={addRef} id="identificationNumber" name="identificationNumber" value={agencyModel.identificationNumber || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="identificationCreatedTime" className="required">
                                                Ngày cấp:
                                            </label>
                                            <DatePicker
                                                required
                                                adjustDateOnChange
                                                showYearDropdown
                                                showMonthDropdown
                                                peekNextMonth
                                                timeCaption="time"
                                                timeIntervals={15}
                                                dateFormat="dd/MM/yyyy"
                                                dropdownMode="select"
                                                className="form-control"
                                                selected={new Date(agencyModel.identificationCreatedTime || new Date())}
                                                onChange={(value) => {
                                                    var now = new Date();
                                                    var y2000 = new Date("1/1/2000");
                                                    if (value < y2000) value = y2000;
                                                    if (value > now) value = now;
                                                    setAgencyModel({ ...agencyModel, identificationCreatedTime: value });
                                                }}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <TFUSelectSubdivisions
                                                showProvince
                                                provinceInputName="identificationProvinceCode"
                                                provinceCode={agencyModel.identificationProvinceCode || ""}
                                                onChanged={(obj) => {
                                                    setAgencyModel({ ...agencyModel, identificationProvinceCode: obj.provinceCode });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row col-12">
                                        <div className="form-group col-md-3">
                                            <div className="file-custom">
                                                {agencyModel.identificationFrontUrl ? (
                                                    <label className="file-label-image" htmlFor="identificationFrontUrl">
                                                        <div>
                                                            <img src={agencyModel.identificationFrontUrl} alt="mặt trước" />
                                                        </div>
                                                    </label>
                                                ) : (
                                                    <div className="label-custom">
                                                        <label className="identificationFrontUrl" htmlFor="identificationFrontUrl">
                                                            Tải ảnh mặt trước
                                                        </label>
                                                    </div>
                                                )}

                                                <input
                                                    type={"file"}
                                                    id="identificationFrontUrl"
                                                    className="d-none"
                                                    onChange={(e) => {
                                                        handleImageChange(e, "identificationFrontUrl");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <div className="file-custom">
                                                {agencyModel.identificationBackUrl ? (
                                                    <label className="file-label-image" htmlFor="identificationBackUrl">
                                                        <div>
                                                            <img src={agencyModel.identificationBackUrl} alt="mặt trước" />
                                                        </div>
                                                    </label>
                                                ) : (
                                                    <div className="label-custom">
                                                        <label className="identificationBackUrl" htmlFor="identificationBackUrl">
                                                            Tải ảnh mặt sau
                                                        </label>
                                                    </div>
                                                )}

                                                <input
                                                    type={"file"}
                                                    id="identificationBackUrl"
                                                    className="d-none"
                                                    onChange={(e) => {
                                                        handleImageChange(e, "identificationBackUrl");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WrapperBlock>
                        <WrapperBlock>
                            <div className="creator-profile__basic-info">
                                {/* Body */}
                                <div className="form-row">
                                    <div className="main-title">Thông tin các liên kết</div>
                                    <div className="form-row col-12">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">Website cá nhân</label>
                                            <Textbox id="websiteUrl" name="websiteUrl" className="form-control" value={agencyModel.websiteUrl || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstName">Facebook</label>
                                            <TextBox id="facebookUrl" name="facebookUrl" className="form-control" value={agencyModel.facebookUrl || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                    </div>
                                    <div className="form-row col-12">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstName">Youtube</label>
                                            <TextBox id="youtubeUrl" name="youtubeUrl" className="form-control" value={agencyModel.youtubeUrl || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstName">Instagram</label>
                                            <TextBox id="instagramUrl" name="instagramUrl" className="form-control" value={agencyModel.instagramUrl || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                    </div>

                                    <div className="form-row col-12">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstName">TikTok</label>
                                            <TextBox id="tikTokUrl" name="tikTokUrl" className="form-control" value={agencyModel.tikTokUrl || ""} onChanged={inputAgencyChangeHandler} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WrapperBlock>
                        <WrapperBlock>
                            <div className="creator-profile__basic-info">
                                {/* Body */}
                                <div className="form-row">
                                    <div className="main-title">Thông tin thanh toán</div>
                                    <div className="form-row col-12">
                                        <div className="bank-section">
                                            <div className="d-flex">
                                                <div className="bank-section-title">Tài khoản ngân hàng</div>
                                                {showBank && (
                                                    <div className="ml-auto" onClick={(e) => handleClearBank(1)}>
                                                        <i className="fa fa-times" />
                                                    </div>
                                                )}
                                            </div>
                                            {!showBank ? (
                                                <div className="bank-section-button">
                                                    <button onClick={(e) => setShowBank(true)}>Thêm tài khoản ngân hàng</button>
                                                </div>
                                            ) : (
                                                <div className="bank-section-input">
                                                    <div className="form-group">
                                                        <label className="required">Tên ngân hàng</label>
                                                        <TFUSelect
                                                            name="bankCode"
                                                            required
                                                            ref={addRef}
                                                            options={bankList}
                                                            placeholder="Chọn ngân hàng ..."
                                                            value={detectBankInfo(BANKACCOUNT_TYPE.Bank, "bankCode")}
                                                            onChanged={(value) => inputBankAccountChangeHandler("bankCode", value, BANKACCOUNT_TYPE.Bank)}
                                                        />
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label className="required">Tên tài khoản</label>
                                                            <TextBox
                                                                required
                                                                ref={addRef}
                                                                name="bankAccounts[0].BankAccountName"
                                                                className="form-control"
                                                                value={detectBankInfo(BANKACCOUNT_TYPE.Bank, "bankAccountName")}
                                                                onChanged={(name, value) => inputBankAccountChangeHandler("bankAccountName", value, BANKACCOUNT_TYPE.Bank)}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label className="required">Số tài khoản</label>
                                                            <TextBox
                                                                required
                                                                ref={addRef}
                                                                name="bankAccounts[0].BankAccountNumber"
                                                                className="form-control"
                                                                value={detectBankInfo(BANKACCOUNT_TYPE.Bank, "bankAccountNumber")}
                                                                onChanged={(name, value) => inputBankAccountChangeHandler("bankAccountNumber", value, BANKACCOUNT_TYPE.Bank)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="momo-section">
                                            <div className="d-flex">
                                                <div className="momo-section-title">Tài khoản Momo</div>
                                                {showMomo && (
                                                    <div className="ml-auto" onClick={(e) => handleClearBank(2)}>
                                                        <i className="fa fa-times" />
                                                    </div>
                                                )}
                                            </div>

                                            {!showMomo ? (
                                                <div className="momo-section-button">
                                                    <button onClick={(e) => setShowMomo(true)}>Thêm tài khoản Momo</button>
                                                </div>
                                            ) : (
                                                <div className="momo-section-input">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label className="required">Tên tài khoản</label>
                                                            <TextBox
                                                                required
                                                                ref={addRef}
                                                                name="bankAccounts[1].BankAccountName"
                                                                className="form-control"
                                                                value={detectBankInfo(BANKACCOUNT_TYPE.Momo, "bankAccountName")}
                                                                onChanged={(name, value) => inputBankAccountChangeHandler("bankAccountName", value, BANKACCOUNT_TYPE.Momo)}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label className="required">Số điện thoại</label>
                                                            <TextBox
                                                                required
                                                                ref={addRef}
                                                                name="bankAccounts[1].BankAccountNumber"
                                                                className="form-control"
                                                                value={detectBankInfo(BANKACCOUNT_TYPE.Momo, "bankAccountNumber")}
                                                                onChanged={(name, value) => inputBankAccountChangeHandler("bankAccountNumber", value, BANKACCOUNT_TYPE.Momo)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WrapperBlock>
                    </div>
                    <div className="ui-footer">
                        <div className="biz-plan-cover">
                            <div className="biz-plan-upgrade row">
                                <span className="col-auto use-free">
                                    Chỉ những tài khoản đã được admin <strong>xác thực thông tin (CCCD, Tài khoản Ngân hàng)</strong> thì mới hiển thị các chức năng và bắt đầu sử dụng. Trong thời gian chờ admin xác thực thông tin, bạn có thể tạo
                                    thương hiệu của cá nhân bạn
                                </span>
                                <span className="col-12 magic-col"></span>
                                <div className="col-auto text-center">
                                    <BtnRounded color="#34d3eb" onClick={(e) => updateAgencyUserHandler()}>
                                        &nbsp; Lưu thông tin &nbsp;
                                    </BtnRounded>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperUser>
            </Wrapper>
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    agency: selectAgency,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAgencyUser: () => {
        return dispatch(fetchAgencyUser());
    },
    updateAgencyUser: (agencyModel) => {
        return dispatch(updateAgencyUser(agencyModel));
    },
    uploadBannerImage: (imgFile) => {
        return dispatch(uploadBannerImage(imgFile));
    },
    uploadAvatarImage: (imgFile) => {
        return dispatch(uploadAvatarImage(imgFile));
    },
    removeIdentificationImage: (name) => {
        return dispatch(removeIdentificationImage(name));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorProfileRefactor);

const Wrapper = styled.div`
    width: ${({ width }) => (width ? width : "99%")};
    display: flex;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "flex-start")};
    flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : "column")};
    align-items: ${({ alignItem }) => (alignItem ? alignItem : "center")};
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
    position: relative;
    .box__header {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
    }
    .box__card {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px;
        display: block;
    }
    .box__content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: ${({ justifyElement }) => (justifyElement ? justifyElement : "flex-start")};
    }
`;

const WrapperUser = styled.div`
    width: ${({ width }) => (width ? width : "70%")};
    display: flex;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "flex-start")};
    flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : "column")};
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
    .box__header {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
    }
    .box__content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: ${({ justifyElement }) => (justifyElement ? justifyElement : "flex-start")};
    }
    .notify {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #28b259;
        margin: 20px 0;
    }
`;

const WrapperBlock = styled.div`
    flex-basis: 30%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    padding: 20px;
    background: white;
    border-radius: 10px;
    margin: 20px 0;
    .header {
        display: flex;
        justify-content: space-between;
        color: white;
        font-size: 14px;
        i {
            font-size: 25px;
        }
    }
    .content {
        min-height: 70px;
        color: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        .info {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    .main-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #444444;
        margin: 20px 0;
    }

    .upload-image {
        margin-left: 20px;
        .title {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #444444;
        }
        div {
            margin: 10px 0px;
        }
        .upload-image-button {
            label {
                background: #28b259;
                border-radius: 10px;
                border: none;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #ffffff;
                padding: 10px 20px;
            }
        }
    }
    .form-group {
        padding-right: 60px;
    }

    .file-custom {
        background: #ecf8f0;
        border-radius: 10px;
        height: 250px;
        text-align: center;
        overflow: hidden;
        .file-label-image {
            height: 100%;
            width: 100%;
            div {
                height: 100%;
                width: 100%;
                img {
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .label-custom {
            text-align: center;
            padding-top: 110px;
            label {
                background: #28b259;
                border-radius: 10px;
                padding: 10px 30px;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: white;
            }
        }
    }

    .bank-section {
        background: #ecf8f0;
        border-radius: 10px;
        height: 230px;
        padding: 10px;
        width: 50%;
        position: relative;
        .bank-section-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #444444;
        }
        .bank-section-button {
            position: absolute;
            top: 50%;
            left: calc(50% - 90px);
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #ffffff;
            button {
                color: white;
                padding: 10px 20px;
                background-color: #28b259;
                border: none;
                border-radius: 10px;
            }
        }
    }

    .momo-section {
        background: #ffddf1;
        border-radius: 10px;
        height: 230px;
        padding: 10px;
        width: calc(50% - 10px);
        position: relative;
        margin-left: 10px;
        .momo-section-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #444444;
        }
        .momo-section-button {
            position: absolute;
            top: 50%;
            left: calc(50% - 90px);
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #ffffff;
            button {
                color: white;
                padding: 10px 20px;
                background-color: #ae2070;
                border: none;
                border-radius: 10px;
            }
        }
    }
`;

const WrapperImage = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 50px;
    border-radius: 50%;
    color: #ffffff;
    background: #950000;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }
`;
