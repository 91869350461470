import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { IMAGEABLE_TYPE, NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import MessPhotoServices from "../based/services/MessPhotoServices";

const StockPhotoGroupModel = {
    groupId: 0,
    name: "",
    isDelete: false,
    meS_StockPhotos: [],
};

const AddOrEditStockModal = ({ id, onSaveContinue, onClose, isShowModal }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [stockPhoto, setStockPhoto] = useState(StockPhotoGroupModel);
    const [draggedIndex, setDraggedIndex] = useState(null);

    const handleDragStart = (e, index) => {
        setDraggedIndex(index);
        e.dataTransfer.setData("text/plain", "");
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        const draggedOverItem = stockPhoto.meS_StockPhotos[index];
        if (draggedOverItem === draggedIndex) {
            return;
        }

        let data = { ...stockPhoto };
        let items = data.meS_StockPhotos;

        const draggedItem = items[draggedIndex];
        items.splice(draggedIndex, 1);
        items.splice(index, 0, draggedItem);

        setDraggedIndex(index);
        setStockPhoto({ ...stockPhoto, meS_StockPhotos: items });
    };

    const handleDragEnd = (e, index) => {
        setDraggedIndex(null);
    };

    useEffect(() => {
        if (id > 0) _getStockPhoto(id);
        return () => setStockPhoto({});
    }, [id]);

    const _getStockPhoto = async (id) => {
        setIsBusy(true);
        let [err, data] = await MessPhotoServices.GetGroupDTOById(id);
        if (!err && data) {
            setStockPhoto({ ...data });
        } else setStockPhoto(StockPhotoGroupModel);
        setIsBusy(false);
    };

    const _handleSave = async () => {
        setIsBusy(true);
        let model = { ...stockPhoto };

        //them model
        let [err, data] = id > 0 ? await MessPhotoServices.UpdateGroupMess(model) : await MessPhotoServices.CreateGroupMess(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSaveContinue();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
        setIsBusy(false);
    };

    const _handleMultipleImage = (e) => {
        e.preventDefault();
        let files = e.target.files;
        let fileNameWithoutExtension = "";
        let fileName = files && files.length > 0 ? files[0].name : "";
        fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
        Request.UploadMultipleStockPhoto(files, IMAGEABLE_TYPE.StockPhoto).then((res) => {
            if (res && res.data) {
                let listImage = res.data.listImage;
                let lstImage =
                    listImage &&
                    listImage.map((item, idx) => ({
                        name: item.fileName,
                        url: item.imageUrl,
                        thumbUrl: item.thumbnailUrl,
                        index: idx,
                    }));
                setStockPhoto({ ...stockPhoto, name: fileNameWithoutExtension, meS_StockPhotos: lstImage });
            }
        });
    };

    return (
        <CommonModal show={isShowModal} size="lg" id={Common.generateGuid()} isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()} classModalBody={"modal-class-body"}>
            <Wrapper>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">
                            Chọn ảnh
                        </label>
                        <input
                            className="col-md-12"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                _handleMultipleImage(e);
                            }}
                            multiple
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">
                            Tên group
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            value={stockPhoto.name}
                            onChange={(e) => {
                                let value = e.target.value;
                                setStockPhoto({ ...stockPhoto, name: value });
                            }}
                        />
                    </div>
                </div>

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Icon</th>
                            <th width="200px">Image</th>
                            <th>FileName</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stockPhoto &&
                            stockPhoto.meS_StockPhotos &&
                            stockPhoto.meS_StockPhotos.map((item, idx) => {
                                return (
                                    <tr key={idx} draggable onDragStart={(e) => handleDragStart(e, idx)} onDragOver={(e) => handleDragOver(e, idx)} onDragEnd={(e) => handleDragEnd(e, idx)}>
                                        <td>
                                            <DragIcon />
                                        </td>
                                        <td>
                                            <img src={item.thumbUrl} alt="" />
                                        </td>
                                        <td>{item.name}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </Wrapper>
        </CommonModal>
    );
};

export default AddOrEditStockModal;

const Wrapper = styled.div`
    td img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const DragIcon = () => {
    return (
        <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="18" r="2" transform="rotate(-90 2 18)" fill="#444444"></circle>
            <circle cx="2" cy="10" r="2" transform="rotate(-90 2 10)" fill="#444444"></circle>
            <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#444444"></circle>
        </svg>
    );
};
