import { THIRD_PARTY_TYPE } from "../based/Constants";

export class ThirdPartyModel {
    constructor() {
        this.fullName = "";
        this.phoneNumber = "";
        this.email = "";
        this.provinceCode = "";
        this.districtCode = "";
        this.wardCode = "";
        this.street = "";
        this.birthDate = new Date();
        this.gender = 0;
        this.isActive = true;
        this.thirdPartyType = THIRD_PARTY_TYPE.Supplier;
    }
}
