import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { vi } from 'date-fns/locale';

import Request from '../based/Request';
import { CONSTANTS, NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import Common from '../based/Common';
import CommonModal from '../based/Modal';
import Textbox from '../based/inputs/Textbox';
import PhoneNumber from '../based/inputs/PhoneNumber';
import TextArea from '../based/inputs/TextArea';
import CheckBox from '../based/inputs/CheckBox';
import TFUSelectSubdivisions from '../based/TFUSelectSubdivisions';
import useFormValidate from '../hooks/useFormValidate';
import TFUSelect from '../based/inputs/TFUSelect';

const initUser = {
  id: 0,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  provinceCode: '',
  districtCode: '',
  wardCode: '',
  street: '',
  birthDate: new Date(),
  gender: 0,
  thirdPartyType: 1,
  isActive: true,
};

const genderOptions = [
  {
    label: 'Nam',
    text: 'Nam',
    value: '1',
  },
  {
    label: 'Nữ',
    text: 'Nữ',
    value: '2',
  },
  {
    label: 'Khác',
    text: 'Khác',
    value: '3',
  },
];

export default function CustomerModal(props) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [msgModalLoading, setMsgModalLoading] = useState(null);
  const [currentUser, setCurrentUser] = useState(initUser);

  const { addRef, displayError, isValid } = useFormValidate();

  useEffect(() => {
    if (props.customer) setCurrentUser(props.customer ? props.customer : initUser);
  }, [props.isShowModal]);

  function _handleSave() {
    if (isValid()) {
      let data = { ...currentUser };
      if (data != null) {
        setIsModalLoading(true);
        setMsgModalLoading('Đang lưu dữ liệu...');
        Request.Post('/api/thirdparty/save-customer', data)
          .then((res) => {
            if (res.success) {
              Notify(NOTIFY.SUCCESS, 'Thành công', 'Lưu thành công');
              setIsModalLoading(false);
              setMsgModalLoading(null);
              props.onSaveSucceed();
            } else {
              Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
              setIsModalLoading(false);
              setMsgModalLoading(null);
            }
          })
          .catch((err) => {
            if (err.errors) displayError(err.errors);
            else Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
            setIsModalLoading(false);
            setMsgModalLoading(null);
          });
      }
    }
  }

  return (
    <CommonModal size="lg" show={props.isShowModal} id={Common.generateGuid()} isBusy={isModalLoading} busyMsg={msgModalLoading} title={props.customer && props.customer.id > 0 ? 'Chỉnh sửa thông tin khách hàng' : 'Thêm khách hàng'} onSave={() => _handleSave()} onClose={() => props.onClose()}>
      <div className="form-row">
        <div className="form-group col-md-3">
          <label htmlFor="firstName">
            Họ: <span className="required-field-icon">*</span>
          </label>
          <Textbox
            id="firstName"
            name="firstName"
            required
            maxLength={255}
            ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
            className="form-control"
            onChanged={(name, value) => {
              let customerUpdate = { ...currentUser, firstName: value };
              setCurrentUser(customerUpdate);
            }}
            value={currentUser.firstName}
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="lastName">
            Tên: <span className="required-field-icon">*</span>
          </label>
          <Textbox
            id="lastName"
            name="lastName"
            className="form-control"
            required
            maxLength={255}
            ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
            onChanged={(name, value) => {
              let customerUpdate = { ...currentUser, lastName: value };
              setCurrentUser(customerUpdate);
            }}
            value={currentUser.lastName}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-3">
          <label htmlFor="phoneNumber" className="required">
            Điện thoại:
          </label>
          <PhoneNumber
            id="phoneNumber"
            name="phoneNumber"
            addRef={addRef}
            required
            value={currentUser.phoneNumber}
            onChanged={(name, value) => {
              let customerUpdate = { ...currentUser, phoneNumber: value };
              setCurrentUser(customerUpdate);
            }}
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="email">Email:</label>
          <Textbox
            id="email"
            name="email"
            className="form-control"
            ref={addRef}
            email
            onChanged={(name, value) => {
              let customerUpdate = { ...currentUser, email: value };
              setCurrentUser(customerUpdate);
            }}
            value={currentUser.email}
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="birthDate">Ngày sinh:</label>
          <DatePicker
            adjustDateOnChange
            locale={vi}
            showYearDropdown
            showMonthDropdown
            showTimeSelect
            peekNextMonth
            timeCaption="time"
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy"
            dropdownMode="select"
            className="form-control"
            selected={new Date(currentUser.birthDate)}
            onChange={(value) => {
              var now = new Date();
              var y1900 = new Date('1/1/1900');
              if (value < y1900) value = y1900;
              if (value > now) value = now;
              let customerUpdate = { ...currentUser, birthDate: value };
              setCurrentUser(customerUpdate);
            }}
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="gender">Giới tính:</label>
          <TFUSelect id="gender" name="gender" value={currentUser.gender} noOptionsMessage="Không có dữ liệu" options={genderOptions} onChanged={(value) => setCurrentUser({ ...currentUser, gender: value })} />
        </div>
      </div>
      <div className="form-row">
        <TFUSelectSubdivisions
          showProvince
          showDistrict
          showWard
          provinceCode={currentUser.provinceCode}
          districtCode={currentUser.districtCode}
          wardCode={currentUser.wardCode}
          onChanged={(obj) => setCurrentUser({ ...currentUser, ...obj })}
          addRef={addRef}
          parentClassName="form-group col-md-6"
          childClassName="m-b-5"
        />
        <div className="form-group col-md-6">
          <label htmlFor="Note">Số nhà, tên đường</label>
          <TextArea id="street" name="street" value={currentUser.street} onChanged={(name, value) => setCurrentUser({ ...currentUser, street: value })} />
        </div>
      </div>
      <div className="form-row">
        <CheckBox
          id="isActive"
          name="isActive"
          label="Kích hoạt"
          checked={currentUser.isActive}
          onCheckedChange={(name, value) => {
            let customerUpdate = { ...currentUser, isActive: value };
            setCurrentUser(customerUpdate);
          }}
        />
      </div>
    </CommonModal>
  );
}
