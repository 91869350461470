import React, { useState, useEffect,useRef } from "react";
import ReupServices from '../based/services/ReupServices';
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import Loading from "../based/Loading";
import {  NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import AddOrEditAccountCoordinateModal from "./comps/AddOrEditAccountCoordinateModal";
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 50
}
const AppAccountCoordinateManagement = () => {
    const [busy, setBusy] = useState(false);
    const [data, setData] = useState(false);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [showModalEditing, setShowModalEditing] = useState(false);
    const [editAccount, setEditAccountId] = useState({});
    
    useEffect(()=>{
        getData(paging)
    },[])

    const getData = async (newPaging) =>{
            setBusy(true);
            let [err, data] = await ReupServices.GetAppAccountIfXYEXIST(newPaging);
            if (!err && data) {
                setData(data.listObjects);
                setPaging(data.paging);
                setBusy(false);
            } else {
                setData([]);
                setBusy(false);
            }
        
    }
    async function handleDelete(item) {
            let ids = []
            ids.push(item.id)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await ReupServices.DeleteAppScriptByAppId(ids);
                    if (!error && data) {
                        
                        resolve({ title: "Thành công", msg: "Thành công" });



                        
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });


            let [err, dt] = await ReupServices.CreateUpdateAppAccount({...item , appId: 0, x: 0, y: 0});
            if (!err && dt) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thành công");
                getData(paging);
            } else {
                setBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
            }
            
        

    }

    const _renderAction = () => {
        return <div className='flex-row m-b-10'>
            <button className='btn btn-custom btn-sm' onClick={()=>{
                setEditAccountId({})
                setShowModalEditing(true);
            }}>
                Thêm mới
            </button>
        </div>
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th>STT</th>
                <th>Thiết bị</th>
                <th>Slot</th>
                <th>App</th>
                <th>Acc/Channel chính</th>
                <th>Acc/Page/Channel</th>
                <th>Link</th>
                <th>Hình mô tả</th>
                <th>Kịch bản lớn</th>
                <th>Action</th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        return <tbody>
                {" "}
                    {data && data.length > 0 ? (
                        data.map((item, idx) => {
                            return (
                                <tr className={idx}>
                                    <td>
                                        {idx + 1}
                                    </td>
                                    <td>
                                        <div>{item.deviceName}</div>
                                        <div>{item.deviceSerialNumber}</div>
                                    </td>
                                    <td>
                                        <div>{item.folderName}</div>
                                        <div>Page {item.pageNumber}</div>
                                        <div>X,Y: {item.xFolder},{item.yFolder}</div>                            
                                    </td>
                                    <td>
                                        <div>{item.appName}</div>
                                        <div>X,Y: {item.xApp},{item.yApp}</div>                            
                                    </td>
                                    <td>
                                        <div>{item.nameMainAccount}</div>
                                    </td>
                                    <td>
                                        <div>{item.accountName}</div>
                                        <div>X,Y: {item.x},{item.y}</div>                            
                                    </td>
                                    <td>
                                        <div>{item.profileLink}</div>
                                    </td>
                                    <td>
                                        {item.avatar &&  <img style={{maxWidth: 250, maxHeight: 100}} src={item.avatar} /> }
                                    </td>
                                    <td style={{ width: 150, textAlign: "center" }}>
                                        {item.scriptParents && item.scriptParents.map((script, index)=>{
                                            return <div key={index}>
                                                {script.scriptName}
                                            </div>
                                        })}
                                    </td>
                                    <td style={{ width: 150, textAlign: "center" }}>
                                        <button
                                            className="btn btn-custom"
                                            onClick={()=>{
                                                setEditAccountId(item);
                                                setShowModalEditing(true);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                        <button
                                            className="btn btn-danger ml-3"
                                            onClick={()=>{
                                                handleDelete(item)
                                            }}
                                        >
                                            Xóa
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={99}>Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
    }
    return (
        <div>
            <Loading show={busy} msg={""} />
            <CommonTable
                data={data}
                paging={paging}
                searchBasic
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                renderAction={_renderAction}
                onFilter={(paging) => getData(paging)}
            />
            {
                showModalEditing && 
                <AddOrEditAccountCoordinateModal 
                    show={showModalEditing}
                    onClose = {()=>{
                        setEditAccountId(0)
                        setShowModalEditing(false);
                    }}
                    account={editAccount}
                    onSave={()=>{
                        setShowModalEditing(false);
                        getData(paging)
                    }}
                />
            }
            
        </div>
    );
};

export default AppAccountCoordinateManagement;