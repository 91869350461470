import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import MarketingServices from "../based/services/MarketingServices";
import CommonTable from "../based/CommonTable";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import ModalAddUpdateImage from "./modals/ModalAddUpdateImage";


const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};

const IMAGE_POSITION = {
    BANNER: 0,
    POSTER: 1,
    WHEEL: 2
}


function MarketingManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const history = useHistory()
    const [result , setResult] = useState([])
    const [tabIndex, setTabIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [tabImage , setTabImage] = useState()


    useEffect(() => {
        if(tabIndex == 0){
            setBusy(false);
            getData(paging);
        }
    }, [tabIndex]);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetCreatorMarketingByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Ảnh</th>
                    <th>Tên đối tác</th>
                    <th>URL</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }



    const handleFindCreator = async (e)=> {
        if(e.target.value.trim() !== ''){
            let [err, data] = await MarketingServices.GetAllCreatorMarketing({
                pageNumber: 1,
                pageSize: 5000,
                keyword: e.target.value
            });
            if (!err && data) {
                setResult(data.listObjects);
            } else {
                setResult([]);
            }
        }else{
            setResult([])
        }
        
    }


    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 150,  textAlign: "center" }}>
                                    <img src={item.avatar} width={100} height={100} />
                                </td>
                                <td style={{ width: 200 }}>
                                    {item.isAdd ? (
                                        <div className="input-creator">
                                            <input
                                                className="input-in-table"
                                                type="text"
                                                placeholder="Nhập tên đối tác"
                                                onChange={(e) => {
                                                    handleFindCreator(e)
                                                }}
                                            />
                                            <span>{item.firstName && item.lastName ? "Đã chọn: " + item.firstName + " " + item.lastName : ""}</span>
                                            {result && result.length > 0 &&
                                                <div onClick={(e)=> e.stopPropagation()} className="result-search">
                                                    <div key={idx} className="result-item header">
                                                        <div style={{width: 40}}>Ảnh</div>
                                                        <span>Họ tên</span>
                                                        <span>Email</span>
                                                    </div>
                                                    {result.map((itm, i) => {
                                                        return <div key={i}
                                                            onClick={()=>{
                                                                if(!data.some(i=> i.creatorId === itm.userId)){
                                                                    let dt = [...data]
                                                                    dt[idx].creatorId = itm.userId
                                                                    dt[idx].avatar = itm.avatar
                                                                    dt[idx].firstName = itm.firstName
                                                                    dt[idx].lastName = itm.lastName
                                                                    setData(dt)
                                                                    setResult([])
                                                                }
                                                                else{
                                                                    Notify(NOTIFY.WARNING , NOTIFY.WARNING , "Đã có creator này")
                                                                }
                                                            }}
                                                        className="result-item">
                                                            <img src={itm.avatar} />
                                                            <span>{itm.firstName + " " + itm.lastName }</span>
                                                            <span>{itm.email}</span>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    ) : (
                                        item.firstName + ' ' + item.lastName
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.landingPages && item.landingPages.length > 0 && item.landingPages.map((itm , idx)=>{
                                        return <div>{itm.redirectUrl}</div>

                                    })}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isAdd ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                history.push(`/marketing-landing-page/${item.creatorId}`)
                                            }}
                                        >
                                            Chi tiết
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.creatorId, idx ,item.isAdd);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm đối tác +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {}

    async function handleAddItem() {
        if(!data.some(item => item.isAdd)){
            setData([ { 
                creatorId: 0,
                isAdd: true
            }, ...data]);
        }
       
    }

    function IsDisable(idx) {
        if ( !data[idx].creatorId ) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx]};
        let [err, dt] = await MarketingServices.CreateUpdateCreatorMarketing(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Trùng url");
        }
    }

    async function handleDelete(id, idx , isAdd) {
        if (!isAdd) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeleteCreatorMarketing(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    return (
        <MarketingWapper onClick={()=>{setResult([])}}>
            {isShowModal && <ModalAddUpdateImage tabImage={tabImage} isShow={isShowModal} onClose={()=>{
                setIsShowModal(false);
            }} />}
            <Breadcrumbs currentPage={"Quản lý partner"} />
            <Loading show={isBusy} msg={msg} />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
            <li className="nav-item">
                <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)}>
                    <span>Partner</span>
                </a>
            </li>
            <li className="nav-item">
                <a className={tabIndex == 1 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(1)}>
                    <span>Config tọa độ</span>
                </a>
            </li>
        </ul>
        {tabIndex === 0 && <CommonTable data={data} paging={paging} hideSearchBasicButton 
                renderAction={_renderAction} 
                renderHeader={_renderHeader} 
                renderBody={_renderBody} 
                ref={tableRef} 
             />}
        {tabIndex === 1 && <MarketingImageConfig setIsShowModal={setIsShowModal} setTabImage={setTabImage} />}
            
        </MarketingWapper>
    )
}
const MarketingImageConfig = ({setIsShowModal , setTabImage}) => {
    const [banner , setBanner] = useState({})
    const [poster , setPoster] = useState({})
    const [wheel , setWheel] = useState({})
    const [bannerPartnerCollab2 , setBannerPartnerCollab2] = useState({})
    const [posterPartnerCollab2 , setPosterPartnerCollab2] = useState({})
    const [wheelPartnerCollab2 , setWheelPartnerCollab2] = useState({})



    useEffect(()=>{
        getImagePositionBanner()
        getImagePositionPoster()
        getImagePositionWheel()
    },[])

    const getImagePositionPoster = async () => {
        let [err, data] = await MarketingServices.GetImagePositionByType(IMAGE_POSITION.POSTER);
        if (!err && data && data.length > 0) {
            setPoster(data[0]);
            if(data.length > 1){
                setPosterPartnerCollab2(data[1]);
            }else{
                setPosterPartnerCollab2({
                    id: 0,
                    x1: 0,
                    y1: 0,
                    width1: 0,
                    height1: 0,
                    x2: 0,
                    y2: 0,
                    width2: 0,
                    height2: 0,
                    x3: 0,
                    y3: 0,
                    width3: 0,
                    height3: 0,
                    rotate3: 0,
                    rotate1: 0,
                    rotate2: 0,
                    imagePositionType: IMAGE_POSITION.POSTER
                });
            }
        } else {
            setPoster({
                id: 0,
                x1: 0,
                y1: 0,
                width1: 0,
                height1: 0,
                x2: 0,
                y2: 0,
                width2: 0,
                height2: 0,
                rotate1: 0,
                rotate2: 0,
                x3: 0,
                y3: 0,
                width3: 0,
                height3: 0,
                rotate3: 0,
                imagePositionType: IMAGE_POSITION.POSTER
            });
            setPosterPartnerCollab2({
                id: 0,
                x1: 0,
                y1: 0,
                width1: 0,
                height1: 0,
                x2: 0,
                y2: 0,
                width2: 0,
                height2: 0,
                rotate1: 0,
                rotate2: 0,
                x3: 0,
                y3: 0,
                width3: 0,
                height3: 0,
                rotate3: 0,
                imagePositionType: IMAGE_POSITION.POSTER
            });
        }
    }
    const getImagePositionWheel = async () => {
        let [err, data] = await MarketingServices.GetImagePositionByType(IMAGE_POSITION.WHEEL);
        if (!err && data && data.length > 0) {
            setWheel(data[0]);
            if(data.length > 1){
                setWheelPartnerCollab2(data[1]);
            }else{
                setWheelPartnerCollab2({
                    id: 0,
                    x1: 0,
                    y1: 0,
                    width1: 0,
                    height1: 0,
                    x2: 0,
                    y2: 0,
                    width2: 0,
                    height2: 0,
                    rotate1: 0,
                    rotate2: 0,
                    x3: 0,
                    y3: 0,
                    width3: 0,
                    height3: 0,
                    rotate3: 0,
                    imagePositionType: IMAGE_POSITION.WHEEL
                });
            }
        } else {
            setWheel({
                id: 0,
                x1: 0,
                y1: 0,
                width1: 0,
                height1: 0,
                x2: 0,
                y2: 0,
                width2: 0,
                height2: 0,
                rotate1: 0,
                rotate2: 0,
                x3: 0,
                y3: 0,
                width3: 0,
                height3: 0,
                rotate3: 0,
                imagePositionType: IMAGE_POSITION.WHEEL
            });
            setWheelPartnerCollab2({
                id: 0,
                x1: 0,
                y1: 0,
                width1: 0,
                height1: 0,
                x2: 0,
                y2: 0,
                width2: 0,
                height2: 0,
                rotate1: 0,
                rotate2: 0,
                x3: 0,
                y3: 0,
                width3: 0,
                height3: 0,
                rotate3: 0,
                imagePositionType: IMAGE_POSITION.WHEEL
            });
        }
    }

    const getImagePositionBanner = async () => {
        let [err, data] = await MarketingServices.GetImagePositionByType(IMAGE_POSITION.BANNER);
        if (!err && data && data.length > 0) {
            setBanner(data[0]);
            if(data.length > 1){
                setBannerPartnerCollab2(data[1]);
            }else{
                setBannerPartnerCollab2({
                    id: 0,
                    x1: 0,
                    y1: 0,
                    width1: 0,
                    height1: 0,
                    x2: 0,
                    y2: 0,
                    width2: 0,
                    height2: 0,
                    rotate1: 0,
                    rotate2: 0,
                    x3: 0,
                    y3: 0,
                    width3: 0,
                    height3: 0,
                    rotate3: 0,
                    imagePositionType: IMAGE_POSITION.BANNER
                });
            }
        } else {
            setBanner({
                id: 0,
                x1: 0,
                y1: 0,
                width1: 0,
                height1: 0,
                x2: 0,
                y2: 0,
                width2: 0,
                height2: 0,
                rotate1: 0,
                rotate2: 0,
                x3: 0,
                y3: 0,
                width3: 0,
                height3: 0,
                rotate3: 0,
                imagePositionType: IMAGE_POSITION.BANNER
            });
            setBannerPartnerCollab2({
                id: 0,
                x1: 0,
                y1: 0,
                width1: 0,
                height1: 0,
                x2: 0,
                y2: 0,
                width2: 0,
                height2: 0,
                rotate1: 0,
                rotate2: 0,
                x3: 0,
                y3: 0,
                width3: 0,
                height3: 0,
                rotate3: 0,
                imagePositionType: IMAGE_POSITION.BANNER
            });
        }
    }

    const createUpdateImage = async (item) => {
        if((item.imagePositionType == IMAGE_POSITION.WHEEL && 
            (item.x2 < 0 || item.y2 < 0 || item.width2 < 0 || item.height2 < 0))
           ||
           ((item.imagePositionType == IMAGE_POSITION.BANNER || item.imagePositionType == IMAGE_POSITION.POSTER) && 
            (item.x2 < 0 || item.y2 < 0 || item.width2 < 0 || item.height2 < 0 ||
                item.x1 < 0 || item.y1 < 0 || item.width1 < 0 || item.height1 < 0)
            )
            ||
            (item.imagePositionType == IMAGE_POSITION.POSTER && 
                (item.x3 < 0 || item.y3 < 0 || item.width3 < 0 || item.height3 < 0))
            ) {
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Dữ liệu không hợp lệ")
        }else{
            let [err, data] = await MarketingServices.CreateUpdateImagePosition(item);
            if (!err && data) {
                getImagePositionWheel()
                getImagePositionBanner()
                getImagePositionPoster()
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thành công")

            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thất bại")
            }
        }
    }



    return <React.Fragment>
        <div className="container-config ">
        <div className="config-col-50 p-15">
            <h5 className="mb-3">Config banner</h5>
            <div className="container-config">
                <div className="config-col-50">
                    <h5>Vị trí Logo (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={banner.x1} onChange={(e)=>{
                                setBanner({...banner, x1: e.target.value})
                            }}/>
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={banner.width1} onChange={(e)=>{
                                setBanner({...banner, width1: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={banner.y1} onChange={(e)=>{
                                setBanner({...banner, y1: e.target.value})
                            }}/>
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={banner.height1} onChange={(e)=>{
                                setBanner({...banner, height1: e.target.value})
                            }}/>
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={banner.rotate1} onChange={(e)=>{
                                setBanner({...banner, rotate1: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
                <div className="config-col-50">
                    <h5>Vị trí Logo trên vòng quay (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number"value={banner.x2} onChange={(e)=>{
                                setBanner({...banner, x2: e.target.value})
                            }}/>
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={banner.width2} onChange={(e)=>{
                                setBanner({...banner, width2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={banner.y2} onChange={(e)=>{
                                setBanner({...banner, y2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={banner.height2} onChange={(e)=>{
                                setBanner({...banner, height2: e.target.value})
                            }}/>
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={banner.rotate2} onChange={(e)=>{
                                setBanner({...banner, rotate2: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="box-button">
                <button className="btn btn-custom"
                    onClick={()=>{
                        createUpdateImage(banner)
                    }}
                >Lưu</button>
                <button className="btn btn-custom ml-2"
                    onClick={()=>{
                        setTabImage(IMAGE_POSITION.BANNER)
                        setIsShowModal(true)
                    }}
                >Upload banner</button>
            </div>

        </div>
        <div className="config-col-50 p-15">
            <h5 className="mb-3">Config vòng quay</h5>
            <div className="container-config">
                <div className="config-col-50">
                    <h5>Vị trí Logo (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={wheel.x2} onChange={(e)=>{
                                setWheel({...wheel, x2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={wheel.width2} onChange={(e)=>{
                                setWheel({...wheel, width2: e.target.value})
                            }}  />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={wheel.y2} onChange={(e)=>{
                                setWheel({...wheel, y2: e.target.value})
                            }}  />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={wheel.height2} onChange={(e)=>{
                                setWheel({...wheel, height2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={wheel.rotate2} onChange={(e)=>{
                                setWheel({...wheel, rotate2: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="box-button">
                <button className="btn btn-custom"
                    onClick={()=>{
                        createUpdateImage(wheel)
                    }}
                >Lưu</button>
                <button className="btn btn-custom ml-2"
                    onClick={()=>{
                        setTabImage(IMAGE_POSITION.WHEEL)
                        setIsShowModal(true)
                    }}
                >Upload vòng quay</button>
            </div>
        </div>
        <div className="config-col-50 p-15 mt-3">
            <h5 className="mb-3">Config poster</h5>
            <div className="container-config">
                <div className="config-col-50">
                    <h5>Vị trí Logo (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={poster.x1} onChange={(e)=>{
                                setPoster({...poster, x1: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={poster.width1} onChange={(e)=>{
                                setPoster({...poster, width1: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={poster.y1} onChange={(e)=>{
                                setPoster({...poster, y1: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={poster.height1} onChange={(e)=>{
                                setPoster({...poster, height1: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={poster.rotate1} onChange={(e)=>{
                                setPoster({...poster, rotate1: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
                <div className="config-col-50">
                    <h5>Vị trí Logo trên vòng quay (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={poster.x2} onChange={(e)=>{
                                setPoster({...poster, x2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={poster.width2} onChange={(e)=>{
                                setPoster({...poster, width2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={poster.y2} onChange={(e)=>{
                                setPoster({...poster, y2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={poster.height2} onChange={(e)=>{
                                setPoster({...poster, height2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={poster.rotate2} onChange={(e)=>{
                                setPoster({...poster, rotate2: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
                <div className="config-col-50 mt-3">
                    <h5>Vị trí QRCode (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={poster.x3} onChange={(e)=>{
                                setPoster({...poster, x3: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={poster.width3} onChange={(e)=>{
                                setPoster({...poster, width3: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={poster.y3} onChange={(e)=>{
                                setPoster({...poster, y3: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={poster.height3} onChange={(e)=>{
                                setPoster({...poster, height3: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={poster.rotate3} onChange={(e)=>{
                                setPoster({...poster, rotate3: e.target.value})
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-button">
                <button className="btn btn-custom"
                onClick={()=>{
                    createUpdateImage(poster)
                }}
                >Lưu</button>
                <button className="btn btn-custom ml-2"
                        onClick={()=>{
                            setTabImage(IMAGE_POSITION.POSTER)
                            setIsShowModal(true)
                        }}    
                >Upload poster</button>
            </div>
        </div>
        

        </div>

        <div className="container-config mt-3">
        <div className="config-col-50 p-15">
            <h5 className="mb-3">Config banner partner collab (neu co)</h5>
            <div className="container-config">
                <div className="config-col-50">
                    <h5>Vị trí Logo (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={bannerPartnerCollab2.x1} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, x1: e.target.value})
                            }}/>
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={bannerPartnerCollab2.width1} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, width1: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={bannerPartnerCollab2.y1} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, y1: e.target.value})
                            }}/>
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={bannerPartnerCollab2.height1} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, height1: e.target.value})
                            }}/>
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={bannerPartnerCollab2.rotate1} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, rotate1: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
                <div className="config-col-50">
                    <h5>Vị trí Logo trên vòng quay (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number"value={bannerPartnerCollab2.x2} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, x2: e.target.value})
                            }}/>
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={bannerPartnerCollab2.width2} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, width2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={bannerPartnerCollab2.y2} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, y2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={bannerPartnerCollab2.height2} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, height2: e.target.value})
                            }}/>
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={bannerPartnerCollab2.rotate2} onChange={(e)=>{
                                setBannerPartnerCollab2({...bannerPartnerCollab2, rotate2: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="box-button">
                <button className="btn btn-custom"
                    onClick={()=>{
                        createUpdateImage(bannerPartnerCollab2)
                    }}
                >Lưu</button>
                <button className="btn btn-custom ml-2"
                    onClick={()=>{
                        setTabImage(IMAGE_POSITION.BANNER)
                        setIsShowModal(true)
                    }}
                >Upload banner</button>
            </div>

        </div>
        <div className="config-col-50 p-15">
            <h5 className="mb-3">Config vòng quay partner collab (neu co) </h5>
            <div className="container-config">
                <div className="config-col-50">
                    <h5>Vị trí Logo (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={wheelPartnerCollab2.x2} onChange={(e)=>{
                                setWheelPartnerCollab2({...wheelPartnerCollab2, x2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={wheelPartnerCollab2.width2} onChange={(e)=>{
                                setWheelPartnerCollab2({...wheelPartnerCollab2, width2: e.target.value})
                            }}  />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={wheelPartnerCollab2.y2} onChange={(e)=>{
                                setWheelPartnerCollab2({...wheelPartnerCollab2, y2: e.target.value})
                            }}  />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={wheelPartnerCollab2.height2} onChange={(e)=>{
                                setWheelPartnerCollab2({...wheelPartnerCollab2, height2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={wheelPartnerCollab2.rotate2} onChange={(e)=>{
                                setWheelPartnerCollab2({...wheelPartnerCollab2, rotate2: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="box-button">
                <button className="btn btn-custom"
                    onClick={()=>{
                        createUpdateImage(wheelPartnerCollab2)
                    }}
                >Lưu</button>
                <button className="btn btn-custom ml-2"
                    onClick={()=>{
                        setTabImage(IMAGE_POSITION.WHEEL)
                        setIsShowModal(true)
                    }}
                >Upload vòng quay</button>
            </div>
        </div>
        <div className="config-col-50 p-15 mt-3">
            <h5 className="mb-3">Config poster partner collab (neu co)</h5>
            <div className="container-config">
                <div className="config-col-50">
                    <h5>Vị trí Logo (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={posterPartnerCollab2.x1} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, x1: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={posterPartnerCollab2.width1} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, width1: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={posterPartnerCollab2.y1} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, y1: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={posterPartnerCollab2.height1} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, height1: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={posterPartnerCollab2.rotate1} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, rotate1: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
                <div className="config-col-50">
                    <h5>Vị trí Logo trên vòng quay (px)</h5>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ X</span>
                            <input type="number" value={posterPartnerCollab2.x2} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, x2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều rộng</span>
                            <input type="number" value={posterPartnerCollab2.width2} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, width2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Tọa độ Y</span>
                            <input type="number" value={posterPartnerCollab2.y2} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, y2: e.target.value})
                            }} />
                        </div>
                        <div className="config-col-50 flex-box">
                            <span>Chiều cao</span>
                            <input type="number" value={posterPartnerCollab2.height2} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, height2: e.target.value})
                            }} />
                        </div>
                    </div>
                    <div className="container-config">
                        <div className="config-col-50 flex-box">
                            <span>Góc nghiêng</span>
                            <input type="number" value={posterPartnerCollab2.rotate2} onChange={(e)=>{
                                setPosterPartnerCollab2({...posterPartnerCollab2, rotate2: e.target.value})
                            }}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="box-button">
                <button className="btn btn-custom"
                onClick={()=>{
                    createUpdateImage(posterPartnerCollab2)
                }}
                >Lưu</button>
                <button className="btn btn-custom ml-2"
                        onClick={()=>{
                            setTabImage(IMAGE_POSITION.POSTER)
                            setIsShowModal(true)
                        }}    
                >Upload poster</button>
            </div>
        </div>
        


    </div>
    </React.Fragment>
}


const MarketingWapper = styled.div`

    .container-config{
        display: flex;
        align-items: center;
        flex-wrap: wrap;



        .config-col-50{
            width: 50%;
            max-width: 50%;
            background-color: #fff;
            &.p-15{
                padding: 15px;
            }
            h5{
                color: #666F7B;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
            .box-button{
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: end;
            }

            &.flex-box{
                display: flex;
                align-items: center;
                margin-top: 10px;
                span{
                    display: block;
                    width: 80px;

                }
                input{
                    width: 77px;
                    height: 31px;
                    border: 1.5px solid #D9D9D9;
                    background: #FFF;
                    padding: 0 10px;
                }
            }


        }
    }


    
    .input-creator{
        width: 100%;
        position: relative;

        input{
            width: 100%;
            border: none;
            outline: none;
            height: 40px;
            padding: 0;
            ::placeholder{
                opacity: 0.5;
            }
        }
        span{
            display: block;
            width: 100%;
        }

        .result-search{
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 400px;
            overflow-y: auto;
            height: 200px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            padding: 5px;

           

            .result-item{
                width: 100%;
                display: flex;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                align-items: center;

                &.header{
                    font-weight: 600;
                    cursor: default;

                    :hover{
                        background-color: transparent;
                    }
                    
                }

                :hover{
                    background-color: rgba(0, 0, 0, 0.1);
                }

                img{
                    height: 40px;
                    width: 40px;
                    object-fit: contain;

                }
                
                span{
                    display: block;
                    width: 150px;
                    padding: 0 10px;
                }

            }
        }
    }

    .box-banner{
        display: flex;


        .box-banner-item{
            width: 50%;

        }
    }
`;
export default MarketingManagement;
