import BaseServices from "./BaseServices";

const AgencyServices = {
	GetNextAgencyStatus: async (obj) => {
		return await BaseServices.Get("/api/agencyorders/get-next-agency-status/" + obj);
	},
	HandleOrderByLazada: async (orderIds, obj) => {
		return await BaseServices.Post("/api/agencyOrders/handle-order-by-lazada/" + orderIds, obj);
	},
	HandleStatusToRTSFIFO: async (platform, shop, request) => {
		return await BaseServices.Post("/api/agencyorders/set-invoice-readytoship-fifo/" + platform + "/" + shop, request)
	}, 
	HandleStatusToRTS: async (platform, shop, request) => {
		return await BaseServices.Post(`/api/agencyorders/set-invoice-readytoship/${platform}/${shop}`, request)
	},
	GetAgencyProductsListing: async (paging) => {
		return await BaseServices.Post('/api/agencyProducts/get-agencyproducts', paging);
	},
	GetAgencyOrders: async (paging) => {
		return await BaseServices.Post('/api/agencyorders/get-agency-orders', paging)
	},
	GetAgencyOrderProducts: async (listAgencyOrderId) => {
		return await BaseServices.Post("/api/agencyorders/get-agency-order-products", listAgencyOrderId)
	},
	GetSyncHistory: async (paging) => {
		return await BaseServices.Post("/api/agencyorders/get-sync-history", paging)
	},
	GetAgencyOrderItems: async (orderId, paging) => {
		return await BaseServices.Post(`/api/agencyorders/get-agency-order-items/${orderId}`, paging)
	},
	CreateAgencyOrder: async (models) => {
		return await BaseServices.Post('/api/agencyorders/place-to-order/', models);
	},
	GetWarehousesBySeller: async () => {
		return await BaseServices.Get("/api/agencyProducts/get-warehouse-by-seller");
	},
	GetProductAvailableStock: async (productId, productVariantId, warehouseId) => {
		return await BaseServices.Get(`/api/agencyProducts/get-product-available-stock?productId=${productId}&productVariantId=${productVariantId}&warehouseId=${warehouseId}`);
	},
	GetOrders: async (thirdPartyId, pageNumber, pageSize) => {
		return await BaseServices.Post(`/api/agencyorders/get-orders?thirdPartyId=${thirdPartyId}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
	},
	SyncFifoProducts: async () => {
		return await BaseServices.Post(`/api/agencyProducts/sync-fifo-products`);
	},
	SaveProductPushToCreate: async (model) => {
		return await BaseServices.Post('/api/agencyProducts/save-product-push-opf', model)
	},
	PushProductToStore: async (model) => {
		return await BaseServices.Post('/api/agencyProducts/push-product-to-store', model)
	},
	GetTransactionByAgenOrderId: async (listAgencyOrderId) => {
		return await BaseServices.Post('/api/agencyorders/get-transaction-by-agencyorder-id', listAgencyOrderId)
	},
	GetAgencyProductsWarehouses: async (productId, productType) => {
		return await BaseServices.Get(`/api/agencyProducts/get-agency-product-warehouse/${productId}/${productType}`)
	}
};

export default AgencyServices;