import ComboSectionProductModel from "./ComboSectionProductModel";

export default class ComboSectionModel {
    constructor() {
        this.id = 0;
        this.comboId = 0;
        this.index = 0;
        this.quantity = 0;
        this.name = "";
        this.comboSectionProducts = [];
    }

    comboSectionProducts = [new ComboSectionProductModel()];
}
