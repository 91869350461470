export const actionTypes = {
  ///// Image
  // Add
  PROJECT_EDITOR_ADD_IMAGE_BEGIN: 'PROJECT_EDITOR_ADD_IMAGE_BEGIN',
  PROJECT_EDITOR_ADD_IMAGE_SUCCESS: 'PROJECT_EDITOR_ADD_IMAGE_SUCCESS',
  PROJECT_EDITOR_ADD_IMAGE_FAIL: 'PROJECT_EDITOR_ADD_IMAGE_FAIL',
  // Remove
  PROJECT_EDITOR_REMOVE_IMAGE_BEGIN: 'PROJECT_EDITOR_REMOVE_IMAGE_BEGIN',
  PROJECT_EDITOR_REMOVE_IMAGE_SUCCESS: 'PROJECT_EDITOR_REMOVE_IMAGE_SUCCESS',
  PROJECT_EDITOR_REMOVE_IMAGE_FAIL: 'PROJECT_EDITOR_REMOVE_IMAGE_FAIL',
  // Replace
  PROJECT_EDITOR_REPLACE_IMAGE_BEGIN: 'PROJECT_EDITOR_REPLACE_IMAGE_BEGIN',
  PROJECT_EDITOR_REPLACE_IMAGE_SUCCESS: 'PROJECT_EDITOR_REPLACE_IMAGE_SUCCESS',
  PROJECT_EDITOR_REPLACE_IMAGE_FAIL: 'PROJECT_EDITOR_REPLACE_IMAGE_FAIL',
  // Insert
  PROJECT_EDITOR_INSERT_IMAGE_BEGIN: 'PROJECT_EDITOR_INSERT_IMAGE_BEGIN',
  PROJECT_EDITOR_INSERT_IMAGE_SUCCESS: 'PROJECT_EDITOR_INSERT_IMAGE_SUCCESS',
  PROJECT_EDITOR_INSERT_IMAGE_FAIL: 'PROJECT_EDITOR_INSERT_IMAGE_FAIL',

  ///// Text
  PROJECT_EDITOR_ADD_TEXT: 'PROJECT_EDITOR_ADD_TEXT',
  PROJECT_EDITOR_INSERT_TEXT: 'PROJECT_EDITOR_INSERT_TEXT',
  PROJECT_EDITOR_REMOVE_TEXT: 'PROJECT_EDITOR_REMOVE_TEXT',
  PROJECT_EDITOR_SET_TEXT: 'PROJECT_EDITOR_SET_TEXT',

  ////// Embed
  // Item
  PROJECT_EDITOR_ADD_EMBED: 'PROJECT_EDITOR_ADD_EMBED',
  PROJECT_EDITOR_INSERT_EMBED: 'PROJECT_EDITOR_INSERT_EMBED',
  PROJECT_EDITOR_REMOVE_EMBED: 'PROJECT_EDITOR_REMOVE_EMBED',
  PROJECT_EDITOR_EDIT_EMBED: 'PROJECT_EDITOR_EDIT_EMBED',
  // Modal
  PROJECT_EDITOR_OPEN_EMBED_MODAL: 'PROJECT_EDITOR_OPEN_EMBED_MODAL',
  PROJECT_EDITOR_CLOSE_EMBED_MODAL: 'PROJECT_EDITOR_CLOSE_EMBED_MODAL',

  ///// Mockup
  // Item
  PROJECT_EDITOR_ADD_MOCKUP: 'PROJECT_EDITOR_ADD_MOCKUP',
  PROJECT_EDITOR_EDIT_MOCKUP: 'PROJECT_EDITOR_EDIT_MOCKUP',
  PROJECT_EDITOR_INSERT_MOCKUP: 'PROJECT_EDITOR_INSERT_MOCKUP',
  PROJECT_EDITOR_REMOVE_MOCKUP: 'PROJECT_EDITOR_REMOVE_MOCKUP',
  // Modal
  PROJECT_EDITOR_OPEN_MOCKUP_MODAL: 'PROJECT_EDITOR_OPEN_MOCKUP_MODAL',
  PROJECT_EDITOR_CLOSE_MOCKUP_MODAL: 'PROJECT_EDITOR_CLOSE_MOCKUP_MODAL',
  // Main Image
  PROJECT_EDITOR_UPLOAD_MAIN_IMAGE_MOCKUP_BEGIN: 'PROJECT_EDITOR_UPLOAD_MAIN_IMAGE_MOCKUP_BEGIN',
  PROJECT_EDITOR_UPLOAD_MAIN_IMAGE_MOCKUP_SUCCESS: 'PROJECT_EDITOR_UPLOAD_MAIN_IMAGE_MOCKUP_SUCCESS',
  PROJECT_EDITOR_UPLOAD_MAIN_IMAGE_MOCKUP_FAIL: 'PROJECT_EDITOR_UPLOAD_MAIN_IMAGE_MOCKUP_FAIL',
  // Sub Image
  PROJECT_EDITOR_ADD_SUB_IMAGE_MOCKUP: 'PROJECT_EDITOR_ADD_SUB_IMAGE_MOCKUP',
  PROJECT_EDITOR_UPLOAD_SUB_IMAGE_MOCKUP_BEGIN: 'PROJECT_EDITOR_UPLOAD_SUB_IMAGE_MOCKUP_BEGIN',
  PROJECT_EDITOR_UPLOAD_SUB_IMAGE_MOCKUP_SUCCESS: 'PROJECT_EDITOR_UPLOAD_SUB_IMAGE_MOCKUP_SUCCESS',
  PROJECT_EDITOR_UPLOAD_SUB_IMAGE_MOCKUP_FAIL: 'PROJECT_EDITOR_UPLOAD_SUB_IMAGE_MOCKUP_FAIL',
  // Image 
  PROJECT_EDITOR_SELECT_IMAGE_MOCKUP: 'PROJECT_EDITOR_SELECT_IMAGE_MOCKUP',
  PROJECT_EDITOR_CLEAR_IMAGES_MOCKUP: 'PROJECT_EDITOR_CLEAR_IMAGES_MOCKUP',
  // Mockup
  PROJECT_EDITOR_OPEN_IMAGE_MOCKUP_MODAL: 'PROJECT_EDITOR_OPEN_IMAGE_MOCKUP_MODAL',
  PROJECT_EDITOR_CLOSE_IMAGE_MOCKUP_MODAL: 'PROJECT_EDITOR_CLOSE_IMAGE_MOCKUP_MODAL',
  PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_BEGIN: 'PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_BEGIN',
  PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_SUCCESS: 'PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_SUCCESS',
  PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_FAIL: 'PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_FAIL',
  // Artwork
  PROJECT_EDITOR_FETCH_ARTWORKS_BEGIN: 'PROJECT_EDITOR_FETCH_ARTWORKS_BEGIN',
  PROJECT_EDITOR_FETCH_ARTWORKS_SUCCESS: 'PROJECT_EDITOR_FETCH_ARTWORKS_SUCCESS',
  PROJECT_EDITOR_FETCH_ARTWORKS_FAIL: 'PROJECT_EDITOR_FETCH_ARTWORKS_FAIL',

  ///// Project
  PROJECT_EDITOR_UPLOAD_COVER_IMAGE_BEGIN: 'PROJECT_EDITOR_UPLOAD_COVER_IMAGE_BEGIN',
  PROJECT_EDITOR_UPLOAD_COVER_IMAGE_SUCCESS: 'PROJECT_EDITOR_UPLOAD_COVER_IMAGE_SUCCESS',
  PROJECT_EDITOR_UPLOAD_COVER_IMAGE_FAIL: 'PROJECT_EDITOR_UPLOAD_COVER_IMAGE_FAIL',
  PROJECT_EDITOR_CREATE_BEGIN: 'PROJECT_EDITOR_CREATE_BEGIN',
  PROJECT_EDITOR_CREATE_SUCCESS: 'PROJECT_EDITOR_CREATE_SUCCESS',
  PROJECT_EDITOR_CREATE_FAIL: 'PROJECT_EDITOR_CREATE_FAIL',
  PROJECT_EDITOR_FETCH_BEGIN: 'PROJECT_EDITOR_FETCH_BEGIN',
  PROJECT_EDITOR_FETCH_SUCCESS: 'PROJECT_EDITOR_FETCH_SUCCESS',
  PROJECT_EDITOR_FETCH_FAIL: 'PROJECT_EDITOR_FETCH_FAIL',
  PROJECT_EDITOR_CLEAR: 'PROJECT_EDITOR_CLEAR',

  // Toggle Full Width Image
  PROJECT_EDITOR_TOGGLE_FULL_WIDTH: 'PROJECT_EDITOR_TOGGLE_FULL_WIDTH',
};
