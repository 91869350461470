import React, { useEffect, useState } from "react";
import FindingProductSelection from "../affiliate/config-commission/components/FindingProductSelection";
import CommonTable from "../based/CommonTable";
import BaseServices from "../based/services/BaseServices";
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 10,
    categoryId: 0,
    keyword: "",
};
const CategoryProducts = ({ onClose, categoryId }) => {
    const [products, setProducts] = useState([]);
    const [paging, setPaging] = useState(INIT_PAGING);

    useEffect(() => {
        if (categoryId > 0) {
            let page = { ...INIT_PAGING };
            page.categoryId = categoryId;
            setPaging(page);
            _fetProducts(page);
        }
    }, [categoryId]);

    const _fetProducts = async (page) => {
        if (!page) page = paging;
        let [err, data] = await BaseServices.Post("/api/category/get-display-products", page);
        if (!err && data) {
            setProducts(data);
            setPaging(page);
        }
    };

    async function _handleRemoveProduct(setId) {
        let model = {
            categoryId: categoryId,
            setIds: [setId],
        };
        let [err, data] = await BaseServices.Post("/api/category/delete-display-products", model);
        if (!err) {
            _fetProducts(INIT_PAGING);
        }
    }

    async function _handleAddProduct(product) {
        let model = {
            categoryId: categoryId,
            setIds: [product.id],
            isAppend: true,
        };
        let [err, data] = await BaseServices.Post("/api/category/add-products-to-display", model);
        if (!err) {
            _fetProducts({ ...INIT_PAGING, categoryId: categoryId });
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th>Ảnh</th>
                    <th>Tên SP</th>
                    <th></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {products &&
                    products.length > 0 &&
                    products.map((item, idx) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    <img style={{ width: 50, height: 50 }} src={item.productAvatar} alt={item.productAvatar} />
                                </td>
                                <td>{item.title}</td>
                                <td>
                                    <a href="" onClick={() => _handleRemoveProduct(item.id)}>
                                        Xóa
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        );
    }
    return (
        <div style={{ flexGrow: 2 }}>
            <div style={{ display: "flex", flexDirection: "row-reverse", cursor: "pointer" }} onClick={onClose}>
                <i className="fa fa-times fa-lg"></i>
            </div>
            <div>
                <label>Thêm sản phẩm</label>
                <FindingProductSelection isSetProduct={true} sm id="finding_set" name={"finding_set"} onSelectItem={_handleAddProduct} />
            </div>
            <CommonTable data={products} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} />
        </div>
    );
};

export default CategoryProducts;
