import PubSub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY, PLATFORM } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CheckBox from "../based/inputs/CheckBox";
import CategoryModal from "./CategoryModal";
import CategoryProducts from "./CategoryProducts";

const initCategory = {
    id: 0,
    parentId: 0,
    code: "",
    name: "",
    shortLink: "",
    icon: "",
    index: 1,
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    description: "",
    icon: "",
    image: "",
    isActive: true,
    isShowHomePage: true,
    categoryRewardPoints: [],
    opfCategories: [],
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    orderBy: "id",
    orderDirection: "asc",
    Platform: PLATFORM.Internal,
};

export default function Category(props) {
    const [isBusy, setBusy] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [category, setCategory] = useState(initCategory);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);
    const [categoryOptions, setCategoriesOptions] = useState([]);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [msg, setMessage] = useState("");
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [isClickCheckbox, setIsClickCheckbox] = useState(false);

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Category });
    }, []);

    function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        Request.Post("/api/category/get-page", newPaging)
            .then((res) => {
                setBusy(false);
                setMessage(null);
                if (res.success) {
                    setDatas(res.data.listObjects);
                    setPaging(res.data.paging);
                    setTableGuid(Common.generateGuid());
                } else {
                    setDatas([]);
                }
                setSelectedAll(false);
            })
            .catch((err) => {
                setDatas([]);
                setPaging(initPage);
                setBusy(false);
                setMessage(null);
            });
    }
    function GetCategoryOptions(id) {
        setBusy(true);
        Request.Get("/api/category/get-dropdown/" + id)
            .then((res) => {
                if (res.success) {
                    setCategoriesOptions(res.data);
                    setBusy(false);
                } else {
                    setCategoriesOptions([]);
                    setBusy(false);
                }
            })
            .catch((err) => {
                setCategoriesOptions([]);
                setBusy(false);
            });
    }
    function _handleDelete() {
        let dataChecked = _getCategoriesChecked();
        if (dataChecked && dataChecked.length > 0) {
            let ids = [];
            dataChecked.map((d) => ids.push(d.id));
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise((resolve, reject) => {
                    setBusy(true);
                    Request.Post("/api/category/deletes/" + PLATFORM.Internal, ids)
                        .then((res) => {
                            if (res.success) {
                                _handleCheckedAll(false);
                                resolve({ title: "Thành công", msg: "Xóa thành công." });
                                setBusy(false);
                                getDatas({ ...paging });
                                GetCategoryOptions(0);
                            } else {
                                setBusy(false);
                                Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                            }
                        })
                        .catch((err) => {
                            setBusy(false);
                            if (err.data) reject({ title: "Có lỗi", msg: err.data });
                            else reject({ title: "Có lỗi", msg: CONSTANTS.MSG_ERROR });
                        });
                });
            });
        }
    }
    function _handleEdit(category) {
        let dataChecked = _getCategoriesChecked();
        if (dataChecked && dataChecked.length == 1) {
            GetCategoryOptions(dataChecked[0].id);
            setBusy(true);
            setMessage("Đang tải dữ liệu...");
            Request.Get("/api/category/get-byid/" + dataChecked[0].id)
                .then((res) => {
                    if (res.success && res.data) {
                        setBusy(false);
                        setMessage(null);
                        setCategory(res.data);
                        setIsShowModal(true);
                    } else {
                        setBusy(false);
                        setMessage(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                    }
                })
                .catch((err) => {
                    setBusy(false);
                });
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một bản ghi để thực hiện. Vui lòng kiểm tra lại!");
    }
    function _handleAdd() {
        GetCategoryOptions(0);
        setCategory(initCategory);
        setIsShowModal(true);
    }
    function _handleSaveSucceed(isContinue) {
        getDatas({ ...paging, pageNumber: 1 });
        GetCategoryOptions(0);
        if (isContinue) setCategory(initCategory);
        else setIsShowModal(false);
    }
    function _getCategoriesChecked() {
        if (isClickCheckbox)
            if (datas && datas.length > 0) {
                let datasUp = [];
                datas.map((parent) => {
                    let categories = _recursiveGetChecked(parent);
                    if (categories && categories.length > 0) categories.map((x) => datasUp.push(x));
                });
                return datasUp;
            }
        return null;
    }
    function _recursiveGetChecked(parent) {
        let checkeds = [];
        if (parent && parent.checked) checkeds.push(parent);
        if (parent && parent.children && parent.children.length > 0) {
            parent.children.map((child) => {
                let results = _recursiveGetChecked(child);
                if (results && results.length > 0) results.map((x) => checkeds.push(x));
            });
        }
        return checkeds;
    }
    function _getTotalRecordFlat() {
        if (datas && datas.length > 0) {
            let totalRecord = 0;
            datas.map((parent) => {
                let categories = _recursiveCountRecord(parent);
                if (categories && categories.length > 0) totalRecord = totalRecord + categories.length;
            });
            return totalRecord;
        }
        return 0;
    }
    function _recursiveCountRecord(parent) {
        let arr = [];
        arr.push(parent);
        if (parent && parent.children && parent.children.length > 0) {
            parent.children.map((child) => {
                let results = _recursiveCountRecord(child);
                if (results && results.length > 0) results.map((x) => arr.push(x));
            });
        }
        return arr;
    }
    function _handleCheckedAll(value) {
        setSelectedAll(value);
        setIsClickCheckbox(true);
        let datasUp = [];
        datas.map((parent) => {
            let categories = _recursiveCheckedAllInPage(parent, value);
            datasUp.push(categories);
        });
        setDatas(datasUp);
    }
    function _recursiveCheckedAllInPage(parent, value) {
        parent.checked = value;
        if (parent && parent.children && parent.children.length > 0) {
            parent.children.map((child) => {
                _recursiveCheckedAllInPage(child, value);
            });
        }
        return parent;
    }
    function _handleCheckedChange(category, value) {
        setIsClickCheckbox(true);
        let datasUp = [];
        datas.map((parent) => {
            let categories = _recursiveSetStateCat(parent, category, "checked");
            datasUp.push(categories);
        });
        let dataChecked = _getCategoriesChecked();
        let totalRecord = _getTotalRecordFlat();
        setSelectedAll((dataChecked && dataChecked.length) == totalRecord);
        setDatas(datasUp);
    }
    function _recursiveSetStateCat(parent, category, name) {
        if (parent.id == category.id) parent[name] = !parent[name];
        if (parent.children && parent.children.length > 0) {
            parent.children.map((child) => {
                _recursiveSetStateCat(child, category, name);
            });
        }
        return parent;
    }
    function _handleCollapse(category) {
        setIsClickCheckbox(false);
        let datasUp = [];
        datas.map((parent) => {
            let categories = _recursiveSetStateCat(parent, category, "isUp");
            datasUp.push(categories);
        });
        setDatas(datasUp);
    }
    function _recursiveCategories(category, level) {
        let contents = [];
        if (category) {
            if (category.isUp === undefined) category.isUp = true;
            let icon = category.children && category.children.length > 0 ? <i onClick={() => _handleCollapse(category)} className={category.isUp ? "fa fa-angle-right collapse__cat" : "fa fa-angle-down collapse__cat"}></i> : "";
            let space = "";
            if (level > 1) {
                if (category.children && category.children.length > 0)
                    for (var i = 0; i < level; i++) {
                        space = space + `&nbsp;&nbsp;&nbsp;`;
                    }
                else
                    for (var i = 0; i < level - 1; i++) {
                        space = space + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
                    }
            }
            contents.push(
                <tr key={-1}>
                    <td width={40} className={"center"}>
                        <CheckBox checked={!!category.checked} name={category.id.toString()} id={category.id.toString()} label=" " onCheckedChange={(name, value) => _handleCheckedChange(category, value)} />
                    </td>
                    <td>
                        <span dangerouslySetInnerHTML={{ __html: space ? space : "" }} />
                        {icon}
                        {category.name}
                    </td>
                    <td>{category.index}</td>
                    <td className="center">
                        {category.isActive ? (
                            <span className="status__active">
                                <i className="fa fa-check m-r-5"></i>Hoạt động
                            </span>
                        ) : (
                            ""
                        )}
                    </td>
                    <td className="center">
                        {category.isStepConfig ? (
                            <span className="status__active">
                                <i className="fa fa-check m-r-5"></i>Cài đặt
                            </span>
                        ) : (
                            <span className="status__unactive">
                                {" "}
                                <i className="fa fa-exclamation m-r-5"></i>Không cài đặt
                            </span>
                        )}
                    </td>
                </tr>
            );
            if (!category.isUp && category.children && category.children.length > 0) {
                level++;
                category.children.map((child) => {
                    contents.push(_recursiveCategories(child, level));
                });
            }
        }
        return contents;
    }
    const _handleEditDisplayProduct = async () => {
        let dataChecked = _getCategoriesChecked();
        if (dataChecked && dataChecked.length == 1) {
            let categoryId = dataChecked[0].id;
            setSelectedCategoryId(categoryId);
        }
    };
    function _renderBody() {
        if (datas && datas.length > 0) {
            let contents = [];
            datas.map((item) => {
                let categories = _recursiveCategories(item, 1);
                contents.push(categories);
            });
            if (contents && contents.length > 0) {
                return (
                    <tbody>
                        {contents.map((item, i) => {
                            return <React.Fragment key={i}>{item}</React.Fragment>;
                        })}
                    </tbody>
                );
            }
        }
        return (
            <tbody>
                <tr>
                    <td colSpan={4} className="text-center">
                        Không có dữ liệu!
                    </td>
                </tr>
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = _getCategoriesChecked();
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" />
                        Thêm
                    </button>
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" />
                                Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                            <button className={isEnabled ? "btn btn-primary  btn-sm m-r-5" : "btn btn-primary  btn-sm m-r-5 disable"} onClick={_handleEditDisplayProduct}>
                                Sản phẩm hiển thị
                            </button>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox checked={!!selectedAll} name="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th className="cursor-pointer">Tên ngành hàng</th>
                    <th className="cursor-pointer" width="150">
                        Thứ tự hiển thị
                    </th>
                    <th className="cursor-pointer" width="150">
                        Trạng thái
                    </th>
                    <th className="cursor-pointer" width="150">
                        Bước nhảy chi phí in
                    </th>
                </tr>
            </thead>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Ngành hàng" />
            <Loading show={isBusy} msg={msg} />
            {isShowModal && <CategoryModal isShowModal={isShowModal} category={category} categoryOptions={categoryOptions} onClose={() => setIsShowModal(false)} onSaveSucceed={_handleSaveSucceed} />}
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 2 }}>
                    <CommonTable
                        data={datas}
                        paging={paging}
                        ref={tableRef}
                        tableGuid={tableGuid}
                        headerCheckbox
                        searchBasic
                        hideSearchBasicButton
                        renderAction={_renderAction}
                        renderHeader={_renderHeader}
                        renderBody={_renderBody}
                        onFilter={getDatas}
                    />
                </div>
                {selectedCategoryId > 0 && <CategoryProducts onClose={() => setSelectedCategoryId(0)} categoryId={selectedCategoryId} />}
            </div>
        </React.Fragment>
    );
}
