import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import PlatformServices from "../based/services/PlatformServices";
import { PLATFORM, STATUS_ACTIVE } from "../based/Constants";

function ListShopModal({ show, onClose, listShopId, setListShopId, onSave }) {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        _getStores();
    }, []);

    async function _getStores() {
        const [err, data] = await PlatformServices.PlatformStoresUser([PLATFORM.Shopee], STATUS_ACTIVE.All);
        if (!err) {
            setStores(data);
        }
    }

    return (
        <CommonModal show={show} onClose={() => onClose()} title="Thêm shop" onSave={onSave}>
            <Wrapper>
                <div className="body-page">
                    <div className="list-shop">
                        {stores &&
                            stores.map((item, idx) => {
                                return (
                                    <div className="shop-item" key={idx}>
                                        <input
                                            type="checkbox"
                                            checked={listShopId && listShopId.some((x) => x.shopId == item.shopId)}
                                            onChange={(e) => {
                                                let ls = [...listShopId];
                                                if (e.target.checked) {
                                                    ls.push(item);
                                                } else {
                                                    ls = ls.filter((x) => x.shopId != item.shopId);
                                                }
                                                setListShopId(ls);
                                            }}
                                        />
                                        <span>
                                            {item.shopName} ({item.shopId})
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default ListShopModal;

const Wrapper = styled.div`
    .shop-item {
        display: flex;
        align-items: center;
        margin: 12px 0;
    }

    .shop-item span {
        margin-left: 12px;
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
