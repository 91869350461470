import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import TextNumber from "../based/inputs/TextNumber";
import Loading from "../based/Loading";
import FinanceServices from "../based/services/FinanceServices";
import { Layout } from "../layout/Layout";
const WEEK_ARR = [0, 1, 2, 3, 4, 5];

export default function OperatorAmountPayment(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        getDatas(paging);
    }, []);
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await FinanceServices.GetOperatorsTotalAmount(newPaging);
        if (!error && result) {
            setPaging(result.paging);
            setDatas(result.listObjects);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width='50' className='text-center' rowSpan='2'>
                        STT
                    </th>
                    <th className='text-center' rowSpan='2'>
                        Tên Operator
                    </th>
                    {WEEK_ARR.map((i) => {
                        return (
                            <th key={i} className='text-center' colSpan='2'>
                                <strong>Tổng tiền tuần {i + 1}</strong>
                            </th>
                        );
                    })}
                </tr>
                <tr>
                    {WEEK_ARR.map((i) => {
                        return (
                            <React.Fragment key={i}>
                                <th className='text-center'>Đã thanh toán</th>
                                <th className='text-center'>Chưa thanh toán</th>
                            </React.Fragment>
                        );
                    })}
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='vertical-align-middle'>{item.operatorName}</td>
                                {WEEK_ARR.map((week, k) => {
                                    var value = item.creatorLoyaltyAmounts.filter((m) => m.weekIndex == week);
                                    let paidAmount = "--";
                                    let remaining = "--";
                                    if (value.length > 0) {
                                        paidAmount = value.filter((m) => m.isPaid).reduce((p, c) => p + c.totalAmount, 0);
                                        remaining = value.filter((m) => !m.isPaid).reduce((p, c) => p + c.totalAmount, 0);
                                        // render
                                        paidAmount = <TextNumber displayType='text' price value={paidAmount > 0 ? paidAmount : ""} />;
                                        remaining = <TextNumber displayType='text' price value={remaining > 0 ? remaining : ""} />;
                                    }
                                    return (
                                        <React.Fragment key={k}>
                                            <td className='text-right vertical-align-middle'>
                                                <span className='text-success'>{paidAmount}</span>
                                            </td>
                                            <td className='text-right vertical-align-middle'>
                                                <span className='text-danger'>{remaining}</span>
                                            </td>
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {}
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Chi phí sản xuất' />
            <Loading show={isLoading} msg={msg} />
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} />
        </React.Fragment>
    );
}
