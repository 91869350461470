import React, { useEffect, useState } from "react";
import { INITPAGING, NOTIFY } from "../../based/Constants";
import TFUSelect from "../../based/inputs/TFUSelect";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import TicketServices from "../../based/services/TicketServices";
import useFormValidate from "../../hooks/useFormValidate";
import { TICKET_STATUS } from "../Ticket";

const statusOptions = [
    {
        value: 2,
        label: "Đã đóng",
    },
    {
        value: 1,
        label: "Đang xử lý",
    },
    {
        value: 0,
        label: "Đang mở",
    },
];

const SettingModal = (props) => {
    const [settings, setSettings] = useState({ id: 0, status: TICKET_STATUS.OPEN });
    const { addRef, displayError, isValid } = useFormValidate();
    const [isLoading, setIsLoading] = useState(false);
    const [paging, setPaging] = useState({ ...INITPAGING });
    useEffect(() => {
        setSettings({ id: props.ticket.id, status: props.ticket.status });
    }, [props.ticket.id]);

    const _handleSave = async () => {
        if (isValid()) {
            let [err, data] = await TicketServices.ChangeTicketStatus(settings);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Thành công!.");
                props.onSuccess();
                props.onClose();
            } else {
                Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
            }
        }
    };

    return (
        <CommonModal show={props.isShowModal} onClose={props.onClose} onSave={_handleSave}>
            <div className="form-group">
                <label>Trạng thái:</label>
                <TFUSelect name="status" value={settings.status} options={statusOptions} placeholder="---Thiết lập---" onChanged={(value) => setSettings({ ...settings, status: value })} />
            </div>
        </CommonModal>
    );
};

export default SettingModal;
