import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { PRINT_TYPE } from "../based/Constants";
import Loading from "../based/Loading"; 
import { Layout } from "../layout/Layout"; 
import TFUSelect from "../based/inputs/TFUSelect";
import FinanceServices from "../based/services/FinanceServices";
import OperatorServices from "../based/services/OperatorServices";
import NumberFormat from "react-currency-format";

const PRINT_TYPE_OPTIONS = [
    { value: PRINT_TYPE.DTG, label: "DTG" },
    { value: PRINT_TYPE.PET, label: "PET" },
];

export default function FinancePrint(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [report, setReport] = useState({});
    const [userHandleOptions, setUserPackageHandle] = useState([]);

    useEffect(() => {
        getUserPackageHandle();
    }, []);
    async function getUserPackageHandle() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OperatorServices.GetUserPrintHandle();
        if (!error && data) {
            setUserPackageHandle(data);
            if (data && data.length > 0) {
                getDatas({ ...Common.Paging, printHandleBy: data[0].value });
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setUserPackageHandle([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await FinanceServices.FinancePrintGetPage(newPaging);
        if (!error && result) {
            setPaging(result.paging);
            let financePrints = result.financePrints;
            if (financePrints) {
                setDatas(financePrints.orders);
                setReport(financePrints.report);
            } else {
                setDatas([]);
                setReport({});
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setReport({});
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <span>STT</span>
                    </th>
                    <th width='200' className='text-center'>
                        Operator Order Id
                    </th>
                    <th width='200' className='text-center'>
                        Mã đơn hàng
                    </th>
                    <th>Sản phẩm</th>
                    <th width='200' className='text-center'>
                        Set sản phẩm
                    </th>
                    <th width='150' className='text-center'>
                        Loại in
                    </th>
                    <th width='100' className='text-center'>
                        In Logo
                    </th>
                    <th width='200' className='text-center'>
                        Chi phí in (đ)
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        if (datas && datas.length > 0) return <tbody>{_renderContentBody()}</tbody>;
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan='100' className='vertical-align-middle text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    }
    function _renderContentBody() {
        let content = [];
        if (datas && datas.length > 0) {
            datas.map((item, k) => {
                let rowSpan = (item.products && item.products.length) > 0 ? item.products.length : 1;
                let count = 0;
                if (item.products && item.products.length > 0) {
                    item.products.map((product, p) => {
                        count = count + 1;
                        let arr = [];
                        let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;

                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    {no}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle'>
                                    {item.id}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <a target='_blank' href={`/order-detail/${item.orderId}`}>
                                        <span className='m-r-5'>{item.orderNumber}</span>
                                        <i className='mdi mdi-open-in-new'></i>
                                    </a>
                                    <br></br>
                                    <small>{item.createdDateDisplay}</small>
                                </td>
                            );
                        }

                        arr.push(
                            <td className='vertical-align-middle'>
                                <strong> {product.name}</strong>
                                {product.sellerSKU && <p className='margin-bottom-0 font-italic'>SKU: {product.sellerSKU ? product.sellerSKU : "----"}</p>}
                                {product.attributeValuesDetail &&
                                    product.attributeValuesDetail.length > 0 &&
                                    product.attributeValuesDetail.map((attribute, at) => {
                                        return (
                                            <span key={at} className='margin-bottom-0 font-italic m-r-5'>
                                                {attribute.name} : <span className='badge badge-custom'>{attribute.displayName}</span>
                                            </span>
                                        );
                                    })}
                            </td>
                        );
                        arr.push(
                            <td className='vertical-align-middle text-center'>
                                <span> {product.setName}</span>
                            </td>
                        );

                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    {item.printTypeName}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    {item.isBrandStamp && (
                                        <span className='status__active'>
                                            <i className='fa fa-check m-r-5'></i>Có
                                        </span>
                                    )}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <strong>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.printCost} />
                                    </strong>
                                </td>
                            );
                        }

                        content.push(
                            <tr key={k + "_" + p}>
                                {arr.map((ar, iiii) => {
                                    return <React.Fragment key={iiii}>{ar}</React.Fragment>;
                                })}
                            </tr>
                        );
                    });
                }
            });
        }
        return content;
    }
    function _renderAction() {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-3'>
                            <label htmlFor='printHandleBy'>User in: </label>
                            <TFUSelect name='printHandleBy' isClearable={false} value={paging.printHandleBy} options={userHandleOptions} placeholder='---User in---' onChanged={(value) => setPaging({ ...paging, printHandleBy: value })} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='printType'>Loại in: </label>
                            <TFUSelect name='printType'  value={paging.printType} options={PRINT_TYPE_OPTIONS} placeholder='---Tất cả---' onChanged={(value) => setPaging({ ...paging, printType: value ? value : 0 })} />
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className='fa fa-search m-r-5' /> Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _renderReport() {
        if (report) {
            return (
                <div className='form-row'>
                    <div className='col-xl-3 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng số đơn hàng</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalOrders ? report.totalOrders : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-2 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng số sản phẩm</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalProducts ? report.totalProducts : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-2 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng chi phí In DTG</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span className='m-r-10'>đ</span>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalDTGCost ? report.totalDTGCost : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-2 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng chi phí In PET</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span className='m-r-10'>đ</span>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalPetCost ? report.totalPetCost : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng chi phí In</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span className='m-r-10'>đ</span>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalPrintCost ? report.totalPrintCost : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Đối soát xưởng in' />
            <Loading show={isLoading} msg={msg} />
            {_renderReport()}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
