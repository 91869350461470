import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import Request from "../based/Request";
import { Notify } from "../based/Notify";
import MarketingServices from "../based/services/MarketingServices";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import QRCode from 'react-qr-code';
import MarketingCampaign from "./MarketingCampaign";


const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};

const FIRST_DOMAIN = "https://ranus.vn/co/"
const FIRST_DOMAIN_COUNT = 20
function MarketingLandingPage(props) {
    const creatorId = props.match.params.creatorId
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const history = useHistory()
    const [tabLandingPage , setTabLandingPage] = useState({});
    const [qr , setQr] = useState("")



    useEffect(() => {
        setBusy(false);
        getData(creatorId);
    }, [creatorId]);

    async function getData(creatorId) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetLandingPageByPartnerId(creatorId);
        if (!err && data) {
            setData(data);
            if(data.length > 0){
                getDataById(data[data.length - 1].id)
            }
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }


    async function getDataById(id) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetLandingPageByPageById(id);
        if (!err && data) {
            setTabLandingPage({...data , redirectUrl: data.redirectUrl.slice(FIRST_DOMAIN_COUNT)})
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    

    async function handleDeleteLandingPage(id) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [error, data] = await MarketingServices.DeleteLandingPage(id);
                if (!error && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getData(creatorId);
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }

    const handleAddLandingPage = async (dataItem) => {
        setBusy(true);
        let [err, dt] = await MarketingServices.CreateUpdateLandingPage(dataItem);
        if (!err && dt) {
            getData(creatorId);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Trùng url");
        }

    }
    async function handleUploadFile(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            handleUpdateThumbnail(res.data.imageUrl)
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }
     const handleUpdateThumbnail =  async (url) =>{
        let [err, dt] = await MarketingServices.CreateUpdateLandingPage({
            ...tabLandingPage , redirectUrl: FIRST_DOMAIN + tabLandingPage.redirectUrl , thumbnail: url
        });
        if (!err && dt) {
            setTabLandingPage({...tabLandingPage , thumbnail: url})
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
        }
        
    }
    const handleUpdate =  async (e) =>{
        if (e.key === 'Enter'){
            let [err, dt] = await MarketingServices.CreateUpdateLandingPage({
                ...tabLandingPage , redirectUrl: FIRST_DOMAIN + tabLandingPage.redirectUrl
            });
            if (!err && dt) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
            }
        }
    }

    const handleDownload = () => {
        const svgElement = document.getElementById('qr-code')
        const serializer = new XMLSerializer();
        const svgXml = serializer.serializeToString(svgElement);
        const svgBlob = new Blob([svgXml], { type: 'image/svg+xml;charset=utf-8' });
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = svgUrl;
        downloadLink.download = 'qrcode.svg';
        downloadLink.click();
        URL.revokeObjectURL(svgUrl);
    };

    return (
        <MarketingWapper >
            <Breadcrumbs currentPage={"Quản lý landing page"} />
            <Loading show={isBusy} msg={msg} />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                {data && data.length > 0 && data.map((item , idx)=>{
                    return <li key={idx} className="nav-item">
                    <a className={tabLandingPage.id == item.id ? "nav-link active flex-item" : "flex-item nav-link btn-light"} onClick={() => {
                        getDataById(item.id)
                    }}>
                        <span>Cửa hàng {idx + 1}</span>
                        <span className="remove" onClick={()=>{{
                            handleDeleteLandingPage(item.id)
                        }}}>&times;</span>
                    </a>
                </li>
                }) } 
                <li className="nav-item">
                    <button className="btn-add" onClick={()=>{{
                        handleAddLandingPage({ 
                            id: 0,
                            brandName: "",
                            creatorId: creatorId,
                            name: "",
                            address: "",
                            redirectUrl: FIRST_DOMAIN + creatorId.toString() + "" + (data.length + 1).toString(),
                            domainCode: creatorId.toString() + "" + (data.length + 1).toString()
                        })
                    }}}>Thêm +</button>
                </li>
            </ul>
            {tabLandingPage && tabLandingPage.id > 0 && <div className="box-update">
                <div className="d-flex">
                    <div className="marketing-url">
                        <h4>Tên cửa hàng<i>(enter để thay đổi)</i></h4>
                        <input type="text" value={tabLandingPage.name} onKeyPress={handleUpdate}
                        onChange={(e)=>{setTabLandingPage({...tabLandingPage , name: e.target.value})}} />
                    </div>
                    <div className="marketing-url">
                        <h4>URL<i>(enter để thay đổi)</i></h4>
                        <div className="box-domain">
                            <input type="text" value={tabLandingPage.redirectUrl} 
                            onKeyPress={handleUpdate}
                            onChange={(e)=>{setTabLandingPage({...tabLandingPage , 
                                redirectUrl: e.target.value,
                                domainCode: e.target.value
                            })}}  />
                            <h6>{FIRST_DOMAIN}</h6>
                        </div>
                        <span
                            onClick={()=>{
                                setQr(FIRST_DOMAIN + tabLandingPage.redirectUrl)
                            }}
                        >Xuất mã QR</span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="marketing-url">
                        <h4>Địa chỉ<i>(enter để thay đổi)</i></h4>
                        <input type="text" value={tabLandingPage.address} onKeyPress={handleUpdate}
                        onChange={(e)=>{setTabLandingPage({...tabLandingPage , address: e.target.value})}} />
                    </div>
                    <div className="marketing-url">
                        <h4>Tên thương hiệu<i>(enter để thay đổi)</i></h4>
                        <input type="text" value={tabLandingPage.brandName} onKeyPress={handleUpdate}
                        onChange={(e)=>{setTabLandingPage({...tabLandingPage , brandName: e.target.value})}} />
                    </div>
                </div>
                {tabLandingPage && tabLandingPage.id > 0 && 
                <div className="box-logo">
                    <label>Avatar cửa hàng:</label>
                    {tabLandingPage.thumbnail ? <img src={tabLandingPage.thumbnail} /> : <img/>}
                    <input
                        style={{maxWidth: 90}}
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                            handleUploadFile(e);
                        }}
                    />
                </div> }
                
                {qr &&
                <div className="marketing-qr">
                    <div className="qr-code">
                        <QRCode
                        id="qr-code"
                        value={qr}
                        size={120}
                    />
                    </div>
                    <span onClick={handleDownload}>Tải về</span>
                </div>
            }
            </div>
            }
             {tabLandingPage && tabLandingPage.id > 0 && <MarketingCampaign  creatorId={creatorId} landingPageId={tabLandingPage.id} />}
        </MarketingWapper>
    )
}


const MarketingWapper = styled.div`

    .box-update{
        position: relative;
        padding: 10px;

        .marketing-qr{
            position: absolute;
            top: 15px;
            left: 850px;
            display: flex;
    
            .qr-code{
                width: 120px;
                height: 120px;
            }
    
            span{
                margin-left: 6px;
                color: #64C5B1;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                cursor: pointer;
            }
        }
    
        h4{
            color: #666F7B;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            margin-top: 0;
    
            i{
                margin-left: 4px;
                color: #444;
                font-size: 12px;
                font-weight: 400;
                line-height: 1;
            }
        }
        .box-logo{
            position: absolute;
            top: 15px;
            left: 710px;
            display: flex;
            flex-direction: column;
    
            img{
                width: 120px;
                height: 120px;
                object-fit: contain;
            }
    
            input{
                margin-top: 10px;
            }
    
        }
    

        .marketing-url{
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            margin-bottom: 20px;
            min-width: 300px;
    
            .box-domain{
                width: 350px;
                position: relative;
    
                input{
                    width: 100%;
                    padding-left: 118px;
                    height: 32px;
                }
    
                h6{
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    transform: translateY(-115%);
                    font-size: 13px;
                    font-weight: 400;
                }
            }
    
            input{
                width: 250px;
                height: 32px;
            }
            span{
                color: #64C5B1;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                margin-top: 10px;
                cursor: pointer;
            }
        }
    }


    .btn-add{
        background: #64c5b1;
        color: #fff;
        border: none;
        outline: none;
        height: 100%;
        padding: 10px;
    }
    .flex-item{
        display: flex;
        justify-content: space-between;
        align-item: center;

        .remove{
            cursor: pointer;
            font-size: 25px;
            margin-left: 10px;
            line-height: 1;
        }
    }

    .container-config{
        display: flex;
        align-items: center;
        flex-wrap: wrap;



        .config-col-50{
            width: 50%;
            max-width: 50%;
            background-color: #fff;
            &.p-15{
                padding: 15px;
            }
            h5{
                color: #666F7B;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
            .box-button{
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: end;
            }

            &.flex-box{
                display: flex;
                align-items: center;
                margin-top: 10px;
                span{
                    display: block;
                    width: 80px;

                }
                input{
                    width: 77px;
                    height: 31px;
                    border: 1.5px solid #D9D9D9;
                    background: #FFF;
                    padding: 0 10px;
                }
            }


        }
    }


    
    .input-creator{
        width: 100%;
        position: relative;

        input{
            width: 100%;
            border: none;
            outline: none;
            height: 40px;
            padding: 0;
            ::placeholder{
                opacity: 0.5;
            }
        }
        span{
            display: block;
            width: 100%;
        }

        .result-search{
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 400px;
            overflow-y: auto;
            height: 200px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            padding: 5px;

           

            .result-item{
                width: 100%;
                display: flex;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                align-items: center;

                &.header{
                    font-weight: 600;
                    cursor: default;

                    :hover{
                        background-color: transparent;
                    }
                    
                }

                :hover{
                    background-color: rgba(0, 0, 0, 0.1);
                }

                img{
                    height: 40px;
                    width: 40px;
                    object-fit: contain;

                }
                
                span{
                    display: block;
                    width: 150px;
                    padding: 0 10px;
                }

            }
        }
    }

    .box-banner{
        display: flex;


        .box-banner-item{
            width: 50%;

        }
    }
`;
export default MarketingLandingPage;
