import React from 'react'
import { useHistory } from "react-router-dom";

export default function NotfoundPage(props) {
    const ComingSoon = () => {
        const history = useHistory();
        const handleClick = () => {
            history.push("/");
        }
    return (
        <section className='bg-accpunt-pages'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="wrapper-page">
                            <div className="account-pages">
                                <div className="account-box">
                                    <div className="account-logo-box text-center">
                                        <h2 className="text-uppercase text-center">
                                            <a onClick={handleClick} className="text-success">
                                                <span>
                                                    <img src="/logoranus.png" alt="" height={34} />
                                                </span>
                                            </a>
                                        </h2>
                                    </div>
                                    <div className="account-content text-center" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                                        <h1 className="text-error">404</h1>
                                        <h3 className="text-danger mt-0" style={{ fontSize: '12px' }}>Không tìm thấy trang bạn yêu cầu
                                        </h3>
                                        <div className="form-group row mt-5">
                                            <div className="col-12">
                                                <a className="btn-lotus btn-error waves-effect waves-light" onClick={handleClick}>
                                                    Trở lại trang chủ
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
}