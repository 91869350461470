import React, { useState, useEffect, useRef } from "react";
import ReupServices from "../based/services/ReupServices";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import TFUSelect from "../based/inputs/TFUSelect";
import CheckBox from "../based/inputs/CheckBox";
import Request from "../based/Request";
import DragAndDropIndex from "../based/DragAndDropIndex";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

const modelScript = {
    id: 0,
    scriptName: "",
    isParent: false,
    isDelete: false,
};
const modelScriptStep = {
    id: 0,
    scriptId: 0,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    delay: 0,
    swipeTime: 0,
    stepName: "",
    text: "",
    index: 0,
    isDelete: false,
    actionType: -1,
};

export const DATA_ACTION = [
    {
        value: 0,
        label: "Nhấn",
    },
    {
        value: 1,
        label: "Nhấn giữ",
    },
    {
        value: 2,
        label: "Vuốt",
    },
    {
        value: 3,
        label: "Script Thiết bị",
    },
    {
        value: 4,
        label: "Vào App",
    },
    {
        value: 5,
        label: "Vào Tài khoản",
    },
    {
        value: 6,
        label: "Chọn Video",
    },
    {
        value: 7,
        label: "Input nội dung",
    },
    {
        value: 8,
        label: "Kiểm tra đối tượng",
    },
    {
        value: 9,
        label: "Gửi thông tin Clipboard sang Ding",
    },
];
export const DATA_ACTION_SHOW = ["Nhấn", "Nhấn giữ", "Vuốt", "Script Thiết bị", "Vào App", "Vào Tài khoản", "Chọn Video", "Input nội dung", "Kiểm tra đối tượng", "Gửi thông tin Clipboard sang Ding"];

const ISPARENT = false;

const SubScriptManagement = () => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [selectId, setSelectId] = useState(0);
    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ReupServices.GetScriptByPage(newPaging, ISPARENT);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>STT</th>
                    <th>Tên kịch bản con</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 40, textAlign: "center" }}>
                                    {item.id > 0 && (
                                        <CheckBox
                                            checked={item.checked}
                                            name={`${item.id}`}
                                            label=" "
                                            onCheckedChange={(name, value) => {
                                                let dt = [...data];
                                                dt.map((x) => (x.checked = false));
                                                dt[idx].checked = value;
                                                setData(dt);
                                                if (value) {
                                                    setSelectId(item.id);
                                                } else {
                                                    setSelectId(0);
                                                }
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Tên kịch bản"
                                            value={item.scriptName}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].scriptName = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.scriptName
                                    )}
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm kịch bản +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }
    function _renderAction() {
        return <div className="mb-3 text-title">Kịch bản con</div>;
    }
    async function handleAddItem() {
        setData([{ ...modelScript }, ...data]);
    }
    function IsDisable(idx) {
        if (!data[idx].scriptName) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await ReupServices.CreateUpdateScript(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    async function handleDelete(id, idx) {
        if (id > 0) {
            let ids = [];
            ids.push(id);
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await ReupServices.DeleteScript(ids);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    //Script Step
    const [scriptStep, setScriptStep] = useState([]);
    const [pagingScriptStep, setPagingScriptStep] = useState(initPage);
    const tableScriptStepRef = useRef(null);

    useEffect(() => {
        if (selectId > 0) {
            getScriptStep();
        } else {
            setScriptStep([]);
        }
    }, [selectId]);

    async function getScriptStep() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ReupServices.GetScriptStepByScriptId(selectId);
        if (!err && data) {
            setScriptStep(data);
            setBusy(false);
        } else {
            setScriptStep([]);
            setBusy(false);
        }
    }

    function _renderHeaderScriptStep() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>Bước</th>
                    <th>Tên bước</th>
                    <th>Hành động</th>
                    <th>Tọa độ X Y</th>
                    <th>Delay(s)</th>
                    <th>Thêm chữ</th>
                    <th>Thumbnail</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }

    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...scriptStep];
                            dt[index].thumbnail = res.data.imageUrl;
                            setScriptStep(dt);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    function renderRow(item, idx) {
        return (
            <React.Fragment>
                <td style={{ width: 40, textAlign: "center", verticalAlign: "middle" }}>
                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H12.5" stroke="#565656" />
                        <path d="M0 5.5H12.5" stroke="#565656" />
                        <path d="M0 10H12.5" stroke="#565656" />
                    </svg>
                </td>
                <td style={{ width: 50, textAlign: "center", verticalAlign: "middle" }}>{idx + 1}</td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {item.id <= 0 || item.isUpdate ? (
                        <input
                            className="input-in-table"
                            type="text"
                            placeholder="Tên bước"
                            value={item.stepName}
                            onChange={(e) => {
                                let dt = [...scriptStep];
                                dt[idx].stepName = e.target.value;
                                setScriptStep(dt);
                            }}
                        />
                    ) : (
                        item.stepName
                    )}
                </td>
                <td style={{ width: 200, textAlign: "center", verticalAlign: "middle" }}>
                    {item.id <= 0 || item.isUpdate ? (
                        <TFUSelect
                            className="select"
                            options={DATA_ACTION}
                            placeholder="--Chọn hành động--"
                            value={item.actionType}
                            onChanged={(value) => {
                                let dt = [...scriptStep];
                                dt[idx].actionType = value;
                                setScriptStep(dt);
                            }}
                        />
                    ) : (
                        DATA_ACTION_SHOW[item.actionType]
                    )}
                </td>
                <td style={{ width: 200, textAlign: "center", verticalAlign: "middle" }}>
                    {item.id <= 0 || item.isUpdate ? (
                        <div className="box-coordinate">
                            <div className="w-50">
                                <input
                                    type="number"
                                    min={0}
                                    value={item.x1}
                                    onChange={(e) => {
                                        let dt = [...scriptStep];
                                        dt[idx].x1 = e.target.value;
                                        setScriptStep(dt);
                                    }}
                                />
                                <span>X1:</span>
                            </div>
                            <div className="w-50">
                                <input
                                    type="number"
                                    min={0}
                                    value={item.y1}
                                    onChange={(e) => {
                                        let dt = [...scriptStep];
                                        dt[idx].y1 = e.target.value;
                                        setScriptStep(dt);
                                    }}
                                />
                                <span>Y1:</span>
                            </div>
                        </div>
                    ) : (
                        <div className="box-coordinate">
                            <span className="w-50">X1: {item.x1}</span>
                            <span className="w-50">Y1: {item.y1}</span>
                        </div>
                    )}

                    {item.actionType === 2 &&
                        (item.id <= 0 || item.isUpdate ? (
                            <div className="box-coordinate">
                                <div className="w-50">
                                    <input
                                        type="number"
                                        min={0}
                                        value={item.x2}
                                        onChange={(e) => {
                                            let dt = [...scriptStep];
                                            dt[idx].x2 = e.target.value;
                                            setScriptStep(dt);
                                        }}
                                    />
                                    <span>X2:</span>
                                </div>
                                <div className="w-50">
                                    <input
                                        type="number"
                                        min={0}
                                        value={item.y2}
                                        onChange={(e) => {
                                            let dt = [...scriptStep];
                                            dt[idx].y2 = e.target.value;
                                            setScriptStep(dt);
                                        }}
                                    />
                                    <span>Y2:</span>
                                </div>
                            </div>
                        ) : (
                            <div className="box-coordinate">
                                <span className="w-50">X2: {item.x2}</span>
                                <span className="w-50">Y2: {item.y2}</span>
                            </div>
                        ))}
                </td>
                <td style={{ width: 100, textAlign: "center", verticalAlign: "middle" }}>
                    {item.id <= 0 || item.isUpdate ? (
                        <input
                            type="number"
                            className="input-in-table"
                            min={0}
                            value={item.delay}
                            onChange={(e) => {
                                let dt = [...scriptStep];
                                dt[idx].delay = e.target.value;
                                setScriptStep(dt);
                            }}
                        />
                    ) : (
                        item.delay
                    )}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {item.id <= 0 || item.isUpdate ? (
                        <input
                            className="input-in-table"
                            type="text"
                            placeholder="Tên chữ"
                            value={item.text}
                            onChange={(e) => {
                                let dt = [...scriptStep];
                                dt[idx].text = e.target.value;
                                setScriptStep(dt);
                            }}
                        />
                    ) : (
                        item.text
                    )}
                </td>
                <td style={{ textAlign: "center", position: "relative", verticalAlign: "middle" }}>
                    {item.id <= 0 || item.isUpdate ? (
                        <div>
                            {item.thumbnail ? (
                                <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                    <img style={{ maxWidth: 100, maxHeight: 100 }} src={item.thumbnail} />
                                    <span
                                        style={{ position: "absolute", top: 10, right: 10, fontSize: 30, lineHeight: 0.5 }}
                                        onClick={() => {
                                            let dt = [...scriptStep];
                                            dt[idx].thumbnail = "";
                                            setScriptStep(dt);
                                        }}
                                    >
                                        &times;
                                    </span>
                                </label>
                            ) : (
                                ""
                            )}
                            <input
                                style={{ width: 90 }}
                                accept="image/*"
                                hidden={item.thumbnail ? true : false}
                                type="file"
                                id={`upload-${idx}`}
                                onChange={(e) => {
                                    handleUploadFile(e, idx, scriptStep);
                                }}
                            />
                        </div>
                    ) : (
                        <img style={{ maxWidth: 100, maxHeight: 100 }} src={item.thumbnail} />
                    )}
                </td>
                <td style={{ width: 200, textAlign: "center", verticalAlign: "middle" }}>
                    {item.isUpdate || item.id <= 0 ? (
                        <button
                            className={`btn btn-custom m-1  ${IsDisableScriptStep(idx) ? "disabled" : ""}`}
                            onClick={() => {
                                if (!IsDisableScriptStep(idx)) handleCreateOrUpdateScriptStep(idx);
                            }}
                        >
                            Xong
                        </button>
                    ) : (
                        <button
                            className="btn btn-custom m-1"
                            onClick={() => {
                                let dt = [...scriptStep];
                                dt[idx].isUpdate = true;
                                setScriptStep(dt);
                            }}
                        >
                            Sửa
                        </button>
                    )}
                    <button
                        className={"btn btn-danger m-1"}
                        onClick={() => {
                            handleDeleteScriptStep(item.id, idx);
                        }}
                    >
                        Xóa
                    </button>
                </td>
            </React.Fragment>
        );
    }

    function _renderBodyScriptStep() {
        return (
            <React.Fragment>
                <DragAndDropIndex data={scriptStep} renderRow={renderRow} updateIndexFolder={UpdateIndex} />
                <tbody>
                    <tr>
                        {selectId > 0 && (
                            <td colSpan={99}>
                                <div
                                    className="add-item"
                                    style={{
                                        color: "#64C5B1",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                    }}
                                    onClick={() => {
                                        handleAddItemScriptStep();
                                    }}
                                >
                                    Thêm bước +
                                </div>
                            </td>
                        )}
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    async function UpdateIndex(id, newIndex) {
        setBusy(true);
        let [err, data] = await ReupServices.UpdateIndexScriptStep({
            objectId: selectId,
            id,
            newIndex,
        });
        if (!err && data) {
            getScriptStep();
            setBusy(false);
        } else {
            setBusy(false);
        }
    }

    function _renderActionScriptStep() {
        return <div className="mb-3 text-title">Chi tiết kịch bản con</div>;
    }
    async function handleAddItemScriptStep() {
        setScriptStep([...scriptStep, { ...modelScriptStep, scriptId: selectId }]);
    }

    function IsDisableScriptStep(idx) {
        if (scriptStep[idx].actionType === 3) return false;
        if (scriptStep[idx].x1 < 0 || scriptStep[idx].y1 < 0 || !scriptStep[idx].stepName || scriptStep[idx].actionType < 0) return true;
        if (scriptStep[idx].actionType === 2 && (scriptStep[idx].x2 < 0 || !scriptStep[idx].y2 < 0)) return true;
        return false;
    }
    async function handleCreateOrUpdateScriptStep(idx) {
        setBusy(true);
        let dataItem = { ...scriptStep[idx] };
        let [err, dt] = await ReupServices.CreateUpdateScriptStep(dataItem);
        if (!err && dt) {
            getScriptStep();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    async function handleDeleteScriptStep(id, idx) {
        if (id > 0) {
            let ids = [];
            ids.push(id);
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await ReupServices.DeleteScriptStep(ids);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getScriptStep();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...scriptStep];
            newData.splice(idx, 1);
            setScriptStep(newData);
        }
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <div className="w-30">
                <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
            </div>
            <div className="w-70">
                <CommonTable data={scriptStep} paging={pagingScriptStep} hideSearchBasicButton renderAction={_renderActionScriptStep} renderHeader={_renderHeaderScriptStep} renderBody={_renderBodyScriptStep} ref={tableScriptStepRef} />
            </div>
        </StyleBody>
    );
};
const StyleBody = styled.div`
    display: flex;
    align-items: flex-start;

    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }

    .w-30 {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
    .w-70 {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    .box-coordinate {
        display: flex;
        width: 100%;
        align-items: center;

        .w-50 {
            width: 50%;
            display: block;
            min-width: 50%;
            max-width: 50%;
            position: relative;
            padding: 5px;

            input {
                width: 100%;
                border: none;
                outline: none;
                height: 40px;
                background: #ebebeb;
                padding-left: 30px;
            }
            span {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
            }
        }
    }
`;
export default SubScriptManagement;
