import BaseServices from "./BaseServices";

const TemplateServices = {
    GetTemplate: async (paging) => {
        return await BaseServices.Post("/api/DesignTemplate/get-template-by-page", paging);
    },
    UpdateTemplateAndTag: async (model) => {
        return await BaseServices.Post("/api/DesignTemplate/update-template", model);
    },
    DeleteTemplate: async (id) => {
        return await BaseServices.Get(`/api/DesignTemplate/delete-design/${id}`);
    },
    DeleteTagConnection: async (id) => {
        return await BaseServices.Get(`/api/DesignTemplate/delete-tag/${id}`);
    },
    GetTagByDesignId: async (id) => {
        return await BaseServices.Get(`/api/DesignTemplate/get-tag-by-designId/${id}`);
    },
    GetDropDownProduct: async () => {
        return await BaseServices.Get(`/api/DesignTemplate/get-product-drop-down`);
    },
    GetDropDownTag: async (keyword) => {
        return await BaseServices.Get(`/api/DesignTemplate/get-list-similar-tag/${keyword}`);
    },
};

export default TemplateServices;
