import React from "react";
import { ProductVariantExtendsModel } from "../models/ProductVariantExtendsModel";
import { PRODUCT_STATUS } from "../based/Constants";
export class WarehouseInfoModel {
  constructor() {
    this.id = 0;
    this.slotIndex = 0;
    this.address = "";
    this.expectedTime = new Date();
    this.orderCode = "";
    this.importTime = new Date();
    this.remark = "";
    this.warehouseName = "";
    this.slotCode = "";
  }
}

export class SupplierInfoModel {
  constructor() {
    this.id = 0;
    this.email = "";
    this.phoneNumber = "";
    this.address = "";
    this.fullName = "";
  }
}

export class WarehouseProductModel {
  constructor() {
    //key
    this.productId = 0;
    this.productType = 2;
    this.batchCode = "";
    this.orderId = 0;
    this.productStatus = 0;
    this.warehouseId = 0;
    this.slotCode = "";

    this.sellerSku = "";
    this.barcode = "";
    this.productName = "";
    this.quantity = 0;
    this.price = "";
    this.defectiveQuantity = 0;
    this.brokenQuantity = 0;

    //Bổ sung dùng để record thông tin serial number
    this.hasImei = false;
    this.hasSerialNumber = false;
    this.hasExpDate = false;
    this.extendData = [];
    this.serialNumber = [
      { productType: PRODUCT_STATUS.Good, serialNumber: [] },
      { productType: PRODUCT_STATUS.Defective, serialNumber: [] },
      { productType: PRODUCT_STATUS.Broken, serialNumber: [] },
    ];
    this.productVariantExtends = new ProductVariantExtendsModel();
  }
}

export class StockInContextModel {
  constructor() {
    this.warehouseInfo = new WarehouseInfoModel();
    this.setWarehouseInfo = setWarehouseInfo;
    this.supplierInfo = new SupplierInfoModel();
    this.setSupplierInfo = setSupplierInfo;
    this.products = [new WarehouseProductModel()];
    this.setProducts = setProducts;
  }
}
/**
 * set warehouse info model
 * @param {WarehouseInfoModel} warehouseInfo warehouse info model
 */
function setWarehouseInfo(warehouseInfo) { }

/**
 * set supplier info model
 * @param {SupplierInfoModel} supplierInfo supplier information model
 */
function setSupplierInfo(supplierInfo) { }

/**
 * set list products
 * @param {WarehouseProductModel[]} products list products
 */
function setProducts(products) { }

export const StockInContext = React.createContext(new StockInContextModel());
