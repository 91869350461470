import BaseServices from "../../based/services/BaseServices";

const PresetServices = {
    GetListPresetByPage: (page) => {
        return BaseServices.Post("/api/Preset/get-list-preset-by-page", page);
    },
    GetPresetById: (id) => {
        return BaseServices.Get("/api/Preset/get-preset-by-id/" + id);
    },
    CreatePreset: (model) => {
        return BaseServices.Post("/api/Preset/create-preset", model);
    },
    DeletePresetById: (id) => {
        return BaseServices.Get("/api/Preset/delete-preset-by-id/" + id);
    },
};

export default PresetServices;
