import Axios from 'axios';
import React, { useContext } from 'react';
import Request from '../../based/Request';
import { DesignContext } from '../../context/DesignContext';
import { ProductContext } from '../../context/ProductContext';

export default function NavigateButtons(props) {
    const designContext = useContext(DesignContext);
    const productContext = useContext(ProductContext)

     function _handleSave(){
        let imageUrl =  _processImage('svg-mockup-45')
    }

    const _processImage = () => {
        props.onSave()
    }

    return (
        <div className="form-group text-right product-navigate-buttons">
            <button
                type="button"
                className="btn btn-custom waves-effect w-xs waves-light margin-right-10 btn-sm"
                onClick={(e) => _handleSave()}
            >Lưu</button>
            <button
                type="button"
                className="btn btn-inverse waves-effect w-xs waves-light btn-sm"
                onClick={props.onCancel}
            >Hủy</button>
        </div>
    );
}
