import Pubsub from "pubsub-js";
import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY, VOUCHER_STATUS, VOUCHER_STATUS_NAME, VOUCHER_REGISTER_STATUS, VOUCHER_REGISTER_STATUS_NAME } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Textbox from "../based/inputs/Textbox";
import CheckBox from "../based/inputs/CheckBox";
import { Layout } from "../layout/Layout";
import VoucherServices from "../based/services/VoucherServices";
import TFUDatetime from "../based/inputs/TFUDatetime";
import AddOrEdit from "./AddOrEdit";
import { VoucherPromoteModel } from "../models/VoucherModel";
import VoucherDetail from "./VoucherDetail";
import VoucherProductsRegister from "./VoucherProductsRegister";

var moment = require("moment");

const TAB_SCREENS = {
    UNREGISTERED: 0,
    REGISTERED: 1,
};

export default function VoucherPromoteManagementAdmin(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [currentVoucher, setCurrentVoucher] = useState(null);

    const [currentTabIndex, setCurrentTabIndex] = useState(TAB_SCREENS.UNREGISTERED);
    const [showModalRegister, setShowModalRegister] = useState(false);

    useEffect(() => {
        Pubsub.publish(CONSTANTS.VOUCHER, { mainMenu: MAINMENU.VoucherPromote });
        document.getElementById("code").focus();
        let pagingNew = { ...Common.Paging };
        pagingNew.startDate = new Date();
        pagingNew.endDate = new Date(new Date().setDate(new Date().getDate() + 30));
        pagingNew.registerStartDate = new Date();
        pagingNew.registerEndDate = new Date(new Date().setDate(new Date().getDate() + 30));
        getDatas(pagingNew);
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = Common.IsAdmin() ? await VoucherServices.GetVoucherPromotePaging(newPaging) : await VoucherServices.GetVoucherPromotesPagingToRegister(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();

                if (pagingUp.registerStartDate) pagingUp.registerStartDate = moment(pagingUp.registerStartDate).toDate();
                if (pagingUp.registerEndDate) pagingUp.registerEndDate = moment(pagingUp.registerEndDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center' rowSpan='2'>
                        {Common.IsAdmin() ? (
                            <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                        ) : (
                            <span>STT</span>
                        )}
                    </th>
                    <th width='150' rowSpan='2' className='text-center'>
                        Mã Voucher
                    </th>
                    <th width='150' className='text-center' rowSpan='2'>
                        Tên Voucher
                    </th>
                    <th width='150' className='text-center' colSpan='2'>
                        Thời gian áp dụng
                    </th>
                    <th width='150' className='text-center' colSpan='2'>
                        Thời gian đăng ký
                    </th>
                    <th width='100' className='text-center' rowSpan='2'>
                        Thời gian hiển thị
                    </th>
                    <th width={Common.IsAdmin() ? "150" : "150"} className='text-center' rowSpan='2'>
                        {Common.IsAdmin() ? <span> Số lượng SP trong Voucher</span> : <span> Trạng thái</span>}
                    </th>
                    <th width='100' className='text-center' rowSpan='2'>
                        Hành động
                    </th>
                </tr>
                <tr>
                    <th width='100' className='text-center'>
                        Từ ngày
                    </th>
                    <th width='100' className='text-center'>
                        Đến ngày
                    </th>
                    <th width='100' className='text-center'>
                        Từ ngày
                    </th>
                    <th width='100' className='text-center'>
                        Đến ngày
                    </th>
                </tr>
            </thead>
        );
    }
    async function _handleDetail(item) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await VoucherServices.GetVoucherPromoteById(item.id);
        if (!err && data) {
            setIsLoading(false);
            setMsg(null);
            if (data.fromDate) data.fromDate = moment(data.fromDate).toDate();
            if (data.toDate) data.toDate = moment(data.toDate).toDate();
            if (data.registerStartDate) data.registerStartDate = moment(data.registerStartDate).toDate();
            if (data.registerEndDate) data.registerEndDate = moment(data.registerEndDate).toDate();

            if (data.displayStartDate) data.displayStartDate = moment(data.displayStartDate).toDate();
            setCurrentVoucher(data);
            setShowModalDetail(true);
        } else {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.INFO, "Thông báo", CONSTANTS.MSG_ERROR);
        }
    }
    async function _handleJoinVoucher(item) {
        setCurrentVoucher(item);
        setShowModalRegister(true);
    }
    async function _handleUnJoinedVoucher(item) {
        if (item && item.id > 0)
            ConfirmDialog("Xác nhận hủy?", "Xin vui lòng xác nhận để hủy tham gia voucher này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await VoucherServices.VoucherPromoteUnJoined(item.id);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Hủy thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.ERROR, "Có lỗi", error.data ? error.data : "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    }
    function _renderButton(item) {
        let btn = (
            <a className='font-weight-bold' style={{ color: "#64c5b1", cursor: "pointer" }} onClick={() => _handleDetail(item)}>
                Chi tiết
            </a>
        );
        if (!Common.IsAdmin()) {
            switch (item.registerStatus) {
                case VOUCHER_REGISTER_STATUS.FINISHED:
                case VOUCHER_REGISTER_STATUS.UPCOMING:
                    btn = (
                        <a className='font-weight-bold' style={{ color: "#64c5b1", cursor: "pointer" }} onClick={() => _handleJoinVoucher(item)}>
                            Chi tiết
                        </a>
                    );
                    break;
                default:
                    btn = (
                        <React.Fragment>
                            <a className='m-r-5 font-weight-bold' style={{ color: "#64c5b1", cursor: "pointer" }} onClick={() => _handleJoinVoucher(item)}>
                                {paging.registerType == 0 ? <span>Tham gia</span> : <span>Chi tiết</span>}
                            </a>
                            {paging.registerType == 1 && item.status == VOUCHER_STATUS.UPCOMING && (
                                <a className=' text-danger font-weight-bold' style={{ cursor: "pointer" }} onClick={() => _handleUnJoinedVoucher(item)}>
                                    <p>| Hủy tham gia</p>
                                </a>
                            )}
                        </React.Fragment>
                    );
                    break;
            } 
        }
        return btn;
    }
    function _renderVoucherRegisterStatus(item) {
        let cls = "";
        switch (item.registerStatus) {
            case VOUCHER_REGISTER_STATUS.GOING_ON:
                cls = "status__active";
            case VOUCHER_REGISTER_STATUS.FINISHED:
                cls = " status__unactive";
                break;
            default:
                cls += " text-warning font-weight-bold";
                break;
        }
        return <span className={cls}>{VOUCHER_REGISTER_STATUS_NAME[item.registerStatus]}</span>;
    }
    function _renderVoucherStatus(item) {
        let cls = "rounded text-white status";
        switch (item.status) {
            case VOUCHER_STATUS.GOING_ON:
                cls += " label-success";
                break;
            case VOUCHER_STATUS.FINISHED:
                cls += " label-danger";
                break;
            default:
                cls += " label-warning";
                break;
        }
        return (
            <React.Fragment>
                <div className={cls}>
                    <span>{VOUCHER_STATUS_NAME[item.status]} &nbsp;Voucher</span>
                </div>
                {_renderVoucherRegisterStatus(item)}
            </React.Fragment>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center vertical-align-middle'>
                                    {Common.IsAdmin() ? <CheckBox id={i.toString()} name={i.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(i, value)} /> : <span> {no}</span>}
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <strong>{item.code}</strong>
                                </td>
                                <td className='text-center vertical-align-middle'> {item.name}</td>
                                <td className='text-center vertical-align-middle'> {item.fromDateDisplay}</td>
                                <td className='text-center vertical-align-middle'> {item.toDateDisplay}</td>
                                <td className='text-center vertical-align-middle'> {item.registerStartDateDisplay}</td>
                                <td className='text-center vertical-align-middle'> {item.registerEndDateDisplay}</td>
                                <td className='text-center vertical-align-middle'> {item.displayStartDateDisplay}</td>
                                <td className='text-center vertical-align-middle'>{Common.IsAdmin() ? <span>{item.totalVariants}</span> : _renderVoucherStatus(item)}</td>
                                <td className='text-center vertical-align-middle'>{_renderButton(item)}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _handleAdd() {
        setCurrentVoucher(new VoucherPromoteModel());
        setShowModal(true);
    }
    async function _handleEdit() {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            if (selected != null) {
                setIsLoading(true);
                setMsg("Đang tải dữ liệu...");
                const [err, data] = await VoucherServices.GetVoucherPromoteById(selected.id);
                if (!err && data) {
                    setIsLoading(false);
                    setMsg(null);
                    if (data.fromDate) data.fromDate = moment(data.fromDate).toDate();
                    if (data.toDate) data.toDate = moment(data.toDate).toDate();

                    if (data.registerStartDate) data.registerStartDate = moment(data.registerStartDate).toDate();
                    if (data.registerEndDate) data.registerEndDate = moment(data.registerEndDate).toDate();

                    if (data.displayStartDate) data.displayStartDate = moment(data.displayStartDate).toDate();

                    setCurrentVoucher(data);
                    setShowModal(true);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.INFO, "Thông báo", CONSTANTS.MSG_ERROR);
                }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn voucher để thực hiện. Vui lòng kiểm tra lại!");
    }
    function _handleDelete() {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await VoucherServices.RemoveVoucherPromote(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    }
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-3'>
                            <label htmlFor='code'>Mã Voucher:</label>
                            <Textbox className='form-control' id='code' name='code' onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='name'>Tên Voucher:</label>
                            <Textbox className='form-control' name='name' onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='time'>Thời gian áp dụng:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='timeRegister'>Thời gian đăng ký:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='registerStartDate'
                                        maxDate={paging.registerEndDate}
                                        value={paging.registerStartDate}
                                        placeholder='Từ ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, registerStartDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='registerEndDate'
                                        minDate={paging.registerStartDate}
                                        value={paging.registerEndDate}
                                        placeholder='Đến ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, registerEndDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            {Common.IsAdmin() && (
                                <React.Fragment>
                                    <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleAdd()}>
                                        <i className='fa fa-plus m-r-5' /> Thêm mới
                                    </button>
                                    <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnabled ? _handleEdit() : {})}>
                                        <i className='fa fa-edit m-r-5' /> Sửa
                                    </button>
                                    <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger btn-sm m-r-5" : "btn btn-danger btn-sm m-r-5 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                                        <i className='fa fa-remove m-r-5' /> Xóa
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _handleChangedTab(tab) {
        setCurrentTabIndex(tab);
        getDatas({ ...paging, registerType: tab });
    }
    const _renderTab = useMemo(() => (
        <ul className='nav nav-tabs tabs-bordered'>
            <li className='nav-item'>
                <a className={currentTabIndex == TAB_SCREENS.UNREGISTERED ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.UNREGISTERED)}>
                    <span>Chưa đăng ký</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={currentTabIndex == TAB_SCREENS.REGISTERED ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.REGISTERED)}>
                    <span>Đã đăng ký</span>
                </a>
            </li>
        </ul>
    ));
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý Vourcher Promote' />
            <Loading show={isLoading} msg={msg} />
            {showModal && <AddOrEdit isShowModal={showModal} voucher={currentVoucher} onClose={() => setShowModal(false)} onSaveSucceed={() => getDatas(paging)} />}
            {showModalDetail && <VoucherDetail isShowModal={showModalDetail} voucher={currentVoucher} onClose={() => setShowModalDetail(false)} />}
            {!Common.IsAdmin() && showModalRegister && (
                <VoucherProductsRegister
                    isShowModal={showModalRegister}
                    voucherId={currentVoucher.id}
                    registerType={paging.registerType}
                    voucherStatus={currentVoucher.status}
                    registerStatus={currentVoucher.registerStatus}
                    onClose={() => setShowModalRegister(false)}
                    onSaveSucceed={() => {
                        getDatas(paging);
                        setShowModalRegister(false);
                    }}
                />
            )}
            {!Common.IsAdmin() && _renderTab}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
