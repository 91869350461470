import React, { useEffect, useState } from "react";
import { NOTIFY, CONSTANTS, SLOTOPERATOROBJECTTYPE, ADJUST_TYPE_STOCKIN } from "../../based/Constants";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import useFormValidate from "../../hooks/useFormValidate";
import TextNumber from "../../based/inputs/TextNumber";
import TFUSelect from "../../based/inputs/TFUSelect";
import Radio from "../../based/inputs/Radio";
import WarehouseServices from "../../based/services/WarehouseServices";

export default function StockInPrinterModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const { addRef, displayError, isValid } = useFormValidate();
    const [stockIn, setStockIn] = useState({ objectType: SLOTOPERATOROBJECTTYPE.MainImageLogo, adjustType: ADJUST_TYPE_STOCKIN.Increase });
    const [adjustQuantity, setAdjustQuantity] = useState(0);

    useEffect(() => {
        setStockIn({ ...props.stockIn, adjustType: ADJUST_TYPE_STOCKIN.Increase });
        setAdjustQuantity(0);
    }, [props.showModal.isShow]);

    async function _handleSave() {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu...");
            let [error, data] = props.showModal && props.showModal.isAdjust ? await WarehouseServices.AdjustStockInLogo(stockIn) : await WarehouseServices.SaveStockInLogo(stockIn);
            if (!error && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", data);
                props.onSaveSucceed();
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                if (error.errors) displayError(error.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", error.data ? error.data : CONSTANTS.MSG_ERROR);
            }
        }
    }
    function _handleChangeAdjustQuantity() {
        let dataUp = { ...stockIn };
        if (dataUp.adjustType == ADJUST_TYPE_STOCKIN.Increase) {
            //tăng
            dataUp.sellableQuantity = dataUp.sellableQuantity + adjustQuantity;
        } else {
            //giảm
            if (adjustQuantity > dataUp.sellableQuantity) {
                Notify(NOTIFY.WARNING, "Chú ý", "Số lượng giảm không được vượt quá số lượng có thể bán");
                return;
            } else {
                dataUp.sellableQuantity = dataUp.sellableQuantity - adjustQuantity;
            }
        }
        dataUp.adjustQuantity = adjustQuantity;
        setStockIn(dataUp);
    }
    function _handleChangeAdjustType(e) {
        let dataUp = { ...stockIn };
        dataUp.adjustType = e.target.value;
        if (dataUp.adjustType == ADJUST_TYPE_STOCKIN.Increase) {
            //tăng
            dataUp.sellableQuantity = dataUp.sellableQuantity + dataUp.adjustQuantity;
        } else {
            //giảm
            dataUp.sellableQuantity = dataUp.sellableQuantity - dataUp.adjustQuantity;
        }
        setStockIn(dataUp);
    }
    return (
        <CommonModal show={props.showModal.isShow} isBusy={isLoading} busyMsg={msg} title={props.showModal.isAdjust ? "Điều chỉnh tồn kho" : "Nhập kho"} onSave={() => _handleSave()} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='objectId' className='required'>
                    Thương hiệu
                </label>
                <TFUSelect
                    isDisabled={props.showModal && props.showModal.isAdjust}
                    ref={addRef}
                    required
                    options={props.brandOptions}
                    name='objectId'
                    value={stockIn.objectId}
                    placeholder='--Chọn thương hiệu---'
                    onChanged={(value) => setStockIn({ ...stockIn, objectId: value })}
                />
            </div>
            {props.showModal && props.showModal.isAdjust && (
                <div className='form-group'>
                    <label htmlFor='updateQuantity'>Số lượng điều chỉnh</label>
                    <div className='form-row'>
                        <div className='col-md-2'>
                            <select className='form-control text-center' name='adjustType' value={stockIn.adjustType} onChange={(e) => _handleChangeAdjustType(e)}>
                                <option value={ADJUST_TYPE_STOCKIN.Increase}>+</option>
                                <option value={ADJUST_TYPE_STOCKIN.Decrease}>-</option>
                            </select>
                        </div>
                        <div className='col-md-10'>
                            <TextNumber ref={addRef} required name='adjustQuantity' className='form-control' value={adjustQuantity} onChanged={(name, value) => setAdjustQuantity(value)} onBlur={(name, value) => _handleChangeAdjustQuantity()} />
                        </div>
                    </div>
                </div>
            )}
            <div className='form-group'>
                <label htmlFor='sellableQuantity' className='required'>
                    Số lượng có thể bán
                </label>
                <TextNumber
                    ref={addRef}
                    readOnly={props.showModal && props.showModal.isAdjust}
                    required
                    name='sellableQuantity'
                    className='form-control'
                    value={stockIn.sellableQuantity}
                    onChanged={(name, value) => setStockIn({ ...stockIn, sellableQuantity: value })}
                />
            </div>
            <div className='form-group'>
                <label htmlFor='objectType' className='required'>
                    Loại Logo
                </label>
                <div className='full-width'>
                    <Radio
                        isDisabled={props.showModal && props.showModal.isAdjust}
                        name='objectType'
                        isInline
                        checked={stockIn.objectType == SLOTOPERATOROBJECTTYPE.MainImageLogo}
                        onCheckedChange={(name, value) => setStockIn({ ...stockIn, objectType: SLOTOPERATOROBJECTTYPE.MainImageLogo })}
                        label='Logo main'
                    />
                    <Radio
                        isDisabled={props.showModal && props.showModal.isAdjust}
                        name='objectType'
                        isInline
                        checked={stockIn.objectType == SLOTOPERATOROBJECTTYPE.SubImageLogo}
                        onCheckedChange={(name, value) => setStockIn({ ...stockIn, objectType: SLOTOPERATOROBJECTTYPE.SubImageLogo })}
                        label='Logo sub'
                    />
                </div>
            </div>
        </CommonModal>
    );
}
