import axios from "axios";
import cookie from "react-cookies";
import Common from "./Common";
import { RequestDomain } from "./RequestDomain";

const apiUrl = RequestDomain.APIUrl();
const homeApiUrl = RequestDomain.HomeApiUrl();
const cdnUrl = RequestDomain.CDNUrl();
const homeUrl = RequestDomain.HomeUrl();
var token = cookie.load("token");

const instance = axios.create({ baseURL: apiUrl });
instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
instance.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";

//home api
const homeInstance = axios.create({ baseURL: homeApiUrl });
homeInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
homeInstance.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
const CancelToken = axios.CancelToken;
let cancel;
var Request = {
    API_URL: apiUrl,
    HOME_URL: homeUrl,
    HOME_API_URL: homeApiUrl,
    CdnURL: function () {
        return cdnUrl;
    },
    Get: function (url) {
        return new Promise((resolve, reject) => {
            instance
                .get(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response && error.response.status === 406) {
                        window.location.href = "/not-acceptable";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    GetHomeApi: function (url) {
        return new Promise((resolve, reject) => {
            homeInstance
                .get(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response && error.response.status === 406) {
                        window.location.href = "/not-acceptable";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    async GetAsync(url, data) {
        var result = await instance.get(url, data);
        return result;
    },
    Post: function (url, data) {
        return new Promise((resolve, reject) => {
            instance
                .post(url, data, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response && error.response.status === 406) {
                        window.location.href = "/not-acceptable";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    PostHomeApi: function (url, data) {
        return new Promise((resolve, reject) => {
            homeInstance
                .post(url, data, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response && error.response.status === 406) {
                        window.location.href = "/not-acceptable";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    async PostAsync(url, data) {
        var result = await instance.post(url, data);
        return result;
    },
    Put: function (url, data) {
        return new Promise((resolve, reject) => {
            instance
                .put(url, data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    async PutAsync(url, data) {
        var result = await instance.put(url, data);
        return result;
    },
    Delete: function (url) {
        return new Promise((resolve, reject) => {
            instance
                .delete(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    async DeleteAsync(url, data) {
        var result = await instance.delete(url, data);
        return result;
    },
    UploadImage: function (file, imageType = 0) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/upload-image/${imageType}`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            data.append("file", file);
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    UploadVideo: function (file) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/media/upload-video`);
            xhr.setRequestHeader("client", "ranus123");
            let tk = Common.GetToken();
            if (tk) xhr.setRequestHeader("Authorization", "Bearer " + tk);
            const data = new FormData();
            data.append("file", file);
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    UploadMultipleImage: function (files) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/upload-multiple-image`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            for (var file in files) {
                data.append("files", files[file]);
            }
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    UploadStockPhoto: function (file, imageType = 0) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/upload-customize-photo?type=${imageType}`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            data.append("file", file);
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    UploadMultipleStockPhoto: function (files, imageType = 0) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/upload-multiple-customize-photo?type=${imageType}`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            for (var i = 0; i < files.length; i++) {
                data.append("files", files[i]);
            }
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    UploadStockFont: function (files) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/upload-font`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            for (var file in files) {
                data.append("files", files[file]);
            }
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    DeleteImage: function (fileName) {
        instance.defaults.headers["client"] = "tfu_admin";
        if (token) instance.defaults.headers["Authorization"] = "Bearer " + token;
        return new Promise((resolve, reject) => {
            instance
                .post(`${cdnUrl}/api/images/delete-image/${fileName}`, fileName)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    UploadSlide: function (file) {
        if (file) {
            let type = file.type;
            if (type && (type == "application/vnd.openxmlformats-officedocument.presentationml.presentation" || type == "application/vnd.ms-powerpoint")) {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", `${cdnUrl}/api/media/upload-presentation`);
                    const data = new FormData();
                    data.append("file", file);
                    xhr.send(data);
                    xhr.addEventListener("load", () => {
                        const response = JSON.parse(xhr.responseText);
                        resolve(response);
                    });
                    xhr.addEventListener("error", () => {
                        const error = JSON.parse(xhr.responseText);
                        reject(error);
                    });
                });
            } else {
                return new Promise((resolve, reject) => {});
            }
        }
    },
    ImportExcel: function (file, importUrl) {
        if (file) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", apiUrl + importUrl);
                xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                xhr.setRequestHeader("client", "tfu_admin");
                const data = new FormData();
                data.append("file", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener("error", () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            });
        }
    },
    UploadDocument: function (file, documentType) {
        if (file) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${cdnUrl}/api/images/upload-document?type=${documentType}`);
                xhr.setRequestHeader("client", "tfu_admin");
                if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
                const data = new FormData();
                data.append("file", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener("error", () => {
                    const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                    reject(error);
                });
            });
        }
    },
    UploadPhotoshop: function (file) {
        if (file)
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${cdnUrl}/api/images/upload-photoshop`);
                xhr.setRequestHeader("client", "tfu_admin");
                xhr.setRequestHeader("Authorization", "Bearer " + Common.GetToken());
                const data = new FormData();
                data.append("file", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener("error", () => {
                    const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                    reject(error);
                });
            });
    },
    DeleteDocument: function (fileName) {
        instance.defaults.headers["client"] = "tfu_admin";
        if (token) instance.defaults.headers["Authorization"] = "Bearer " + token;
        return new Promise((resolve, reject) => {
            instance
                .post(`${cdnUrl}/api/images/delete-document?fileName=${fileName}`, null)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    PreviewDocument: function (fileName, createdBy) {
        instance.defaults.headers["client"] = "tfu_admin";
        if (token) instance.defaults.headers["Authorization"] = "Bearer " + token;
        return new Promise((resolve, reject) => {
            instance
                .get(`${cdnUrl}/api/images/preview-document?fileName=${fileName}&creatorName=${createdBy}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    DownloadTemplate: function (fileName) {
        if (fileName) {
            window.location.href = `${cdnUrl}/api/images/download-template/` + fileName;
        }
    },
    ExportExcel: function (obj) {
        if (obj) {
        }
    },
    DownloadFile: function (url, includedFileName) {
        let instanceDownload = axios.create({ baseURL: apiUrl });
        instanceDownload.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        instanceDownload.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
        instanceDownload.defaults.responseType = "blob";
        return new Promise((resolve, reject) => {
            instanceDownload
                .get(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    if (includedFileName) {
                        let fileName = "";
                        let disposition = res.headers["content-disposition"];
                        if (disposition && disposition.indexOf("attachment") !== -1) {
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) fileName = matches[1].replace(/['"]/g, "");
                        }
                        resolve([fileName, res.data]);
                    } else resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    CancelRequest: function () {
        if (cancel) cancel();
    },
    Convert: function (file) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/convert-embroi`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            data.append("file", file);
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },
    ImportGameExcel: function (file) {
        if (file) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${apiUrl}/api/game/import-excel`);
                xhr.setRequestHeader("client", "tfu_admin");
                if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
                const data = new FormData();
                data.append("file", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener("error", () => {
                    const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                    reject(error);
                });
            });
        }
    },
};
export default Request;
