import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import CommonTable from '../../based/CommonTable';
import { CONSTANTS, NOTIFY } from '../../based/Constants';
import { ConfirmDialog } from '../../based/Dialog';
import CheckBox from '../../based/inputs/CheckBox';
import Loading from '../../based/Loading';
import { Notify } from '../../based/Notify';
import Request from '../../based/Request';
import BaseServices from '../../based/services/BaseServices';
import ConfigCommissionModal from './components/ConfigCommissionModal';
import ConfigMaxCommissionModal from './components/ConfigMaxCommissionModal';
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 10
}
function ConfigCommission(props) {
    const [busy, setBusy] = useState(false)
    const [data, setData] = useState([])
    const [paging, setPage] = useState(INIT_PAGING)
    const [selectedItem, setSelectedItem] = useState(null)
    const [editId, setEditId] = useState(0);
    const [showModal, setShowModal] = useState(false)
    const [showConfigMaxCommissionModal, setShowConfigMaxCommissionModal] = useState(false)

    useEffect(() => {
        _fetchData(paging);
    }, [])

    async function _fetchData(pagingModel) {
        setBusy(true);
        const [err, resData] = await BaseServices.Post('/api/affiliate/get-commission-configs', pagingModel);
        setBusy(false);
        if (!err) {
            setData(resData.listObjects);
            setPage(resData.paging);
        } else {
        }
    }
    function _handleCloseModal() {
        setShowModal(false);
    }
    function _handleAdd() {
        setShowModal(true);
        setEditId(0);
    }
    function _handleEdit() {
        setEditId(selectedItem.id);
        setShowModal(true);
    }
    function _handleDelete() {
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
            return new Promise((resolve, reject) => {
                setBusy(true)
                Request.Post(`/api/affiliate/delete-commission-config/${selectedItem.id}`)
                    .then(res => {
                        if (res.success && res.data) {
                            resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                            setBusy(false)
                            _fetchData(paging);
                            setSelectedItem(null);
                        }
                        else {
                            setBusy(false)
                            Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                        }
                    })
                    .catch(err => {
                        setBusy(false)
                    });
            })
        });
    }
    function _handleSelectItem(name, checked) {
        if (checked) {
            setSelectedItem(data.filter(m => m.id == name)[0])
        }
        else {
            setSelectedItem(null)
        }
    }
    function _renderHeader() {
        return <thead>
            <tr>
                <th></th>
                <th>Ảnh</th>
                <th>Tên SP</th>
                <th>% hoa hồng từ đơn đầu tiên</th>
                <th>% hoa hồng từ đơn thứ 2</th>
                <th>% hoa hồng từ đơn thứ 3 trở lên</th>
                <th>Áp dụng từ ngày</th>
                <th>Áp dụng đến ngày</th>
                <th>Đối tượng áp dụng</th>
                <th>Đang hoạt động</th>
            </tr>
        </thead>
    }
    function _renderBody() {
        return <tbody>
            {data && data.length > 0 ? data.map((item, i) => {
                return <tr>
                    <td>
                        <CheckBox id={item.id} name={item.id} checked={item == selectedItem} onCheckedChange={_handleSelectItem} />
                    </td>
                    <td className='text-center' width={100}>
                        <img src={item.productImage} style={{ width: '100%' }} />
                    </td>
                    <td className='text-center'>{item.productName}</td>
                    <td className='text-center'>{item.commission1stOrder} %</td>
                    <td className='text-center'>{item.commission2ndOrders} %</td>
                    <td className='text-center'>{item.commissionMoreThan2Orders} %</td>
                    <td className='text-center'>{item.effectFromDateText}</td>
                    <td className='text-center'>{item.effectToDateText}</td>
                    <td className='text-center'>
                        {
                            item.planApplied == 1 ? 'Basic User' : 'Premium User'
                        }
                    </td>
                    <td className='text-center'>
                        <CheckBox checked={item.isActive} readOnly classDiv='center' />
                    </td>
                </tr>
            })
                :
                <tr>
                    <td colSpan={10}>Không có dữ liệu</td>
                </tr>
            }
        </tbody>
    }
    function _renderAction() {
        return <div className='flex-row'>
            <div className='form-group'>
                <button className='btn btn-custom btn-sm m-r-5' onClick={_handleAdd}>
                    <i className='fa fa-plus m-r-5' /> Thêm mới
                </button>
                <button className={selectedItem ? 'btn btn-custom btn-sm m-r-5' : 'btn btn-custom btn-sm m-r-5 disable'} onClick={_handleEdit}>
                    <i className='fa fa-edit m-r-5' />Sửa
                </button>
                <button className={selectedItem ? 'btn btn-danger  btn-sm m-r-5' : 'btn btn-danger  btn-sm m-r-5 disable'} onClick={_handleDelete}>
                    <i className='fa fa-remove m-r-5' />Xóa
                </button>
                <button className='btn btn-primary  btn-sm m-r-5' onClick={() => setShowConfigMaxCommissionModal(true)}>
                    Cài đặt mức hoa hồng tối đa
                </button>
            </div>
        </div>
    }
    function _handleSubmitForm(isSuccess) {
        if (isSuccess) {
            setShowModal(false);
            _fetchData(paging);
            setSelectedItem(null);
        }
    }
    function _handleSubmitFormMaxCommission(isSuccess) {
        if (isSuccess) {
            setShowConfigMaxCommissionModal(false);
        }
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Cấu hình % hoa hồng' />
            <Loading show={busy} msg={'Đang tải dữ liệu'} />
            {
                data && <CommonTable
                    data={data}
                    paging={paging}
                    renderHeader={_renderHeader}
                    renderBody={_renderBody}
                    renderAction={_renderAction}
                    onFilter={(paging) => _fetchData(paging)}
                />
            }
            <ConfigCommissionModal
                dataId={editId}
                isShow={showModal}
                onClose={_handleCloseModal}
                onSubmit={_handleSubmitForm}
            />
            <ConfigMaxCommissionModal
                isShow={showConfigMaxCommissionModal}
                onClose={() => setShowConfigMaxCommissionModal(false)}
                onSubmit={_handleSubmitFormMaxCommission}
            />
        </React.Fragment>
    );
}

export default ConfigCommission;