import React, { useEffect, useState } from "react";
import CommonModal from "../../based/Modal";
import WarehouseServices from "../../based/services/WarehouseServices";
import NumberFormat from "react-currency-format";
import { ADJUST_TYPE_STOCKIN } from "../../based/Constants";

export default function StockInHistories(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.stockIn) getDatas(props.stockIn.objectId, props.stockIn.objectType);
    }, [props.isShowModal]);
    async function getDatas(objectId, objectType) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await WarehouseServices.GetStockInLogoDetail(objectId, objectType);
        if (!error && result) {
            setData(result);
            setIsLoading(false);
            setMsg(null);
        } else {
            setData(null);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function setClassName(item) {
        if (item) {
            switch (item.adjustType) {
                case ADJUST_TYPE_STOCKIN.Increase:
                    return "text-success";
                case ADJUST_TYPE_STOCKIN.Decrease:
                    return "text-warning";
                case ADJUST_TYPE_STOCKIN.Deleted:
                    return "text-danger";
                default:
                    return "text-dark";
            }
        }
    }
    return (
        <CommonModal size='lg' show={props.isShowModal} isBusy={isLoading} busyMsg={msg} title='Lịch sử nhập kho' onClose={() => props.onClose()}>
            <div className='table-responsive m-b-10'>
                <table className='table table-bordered mb-0'>
                    <thead>
                        <tr style={{ background: "#e4e4e4" }}>
                            <th className='text-center vertical-align-middle' width='50' rowSpan='2'>
                                STT
                            </th>
                            <th className='text-center vertical-align-middle' rowSpan='2'>
                                SlotCode
                            </th>
                            <th className='text-center vertical-align-middle' rowSpan='2'>
                                Thương hiệu
                            </th>
                            <th className='text-center vertical-align-middle' width='150' rowSpan='2'>
                                Loại Logo
                            </th>
                            <th className='text-center vertical-align-middle' width='150' colSpan='4'>
                                Số lượng
                            </th>
                            <th className='text-center vertical-align-middle' width='150' rowSpan='2'>
                                Ghi chú
                            </th>
                            <th className='text-center vertical-align-middle' width='150' rowSpan='2'>
                                Ngày thay đổi
                            </th>
                        </tr>
                        <tr style={{ background: "#e4e4e4" }}>
                            <th width='100' className='text-center'>
                                Có thể bán
                            </th>
                            <th width='100' className='text-center'>
                                Đặt hàng
                            </th>
                            <th width='100' className='text-center'>
                                Thay đổi
                            </th>
                            <th width='100' className='text-center'>
                                Tổng
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.histories && data.histories.length > 0 ? (
                            <React.Fragment>
                                {data.histories.map((item, k) => {
                                    return (
                                        <tr key={k}>
                                            <td className='text-center vertical-align-middle'>{k + 1}</td>
                                            <td className='text-center vertical-align-middle'>{item.slotCode}</td>
                                            <td className='vertical-align-middle'>{item.brandName}</td>
                                            <td className='text-center vertical-align-middle'>{item.objectTypeName}</td>
                                            <td className='text-center vertical-align-middle'>
                                                <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.sellableQuantity} />
                                            </td>
                                            <td className='text-center vertical-align-middle'>
                                                <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.bookingQuantity} />
                                            </td>
                                            <td className='text-center vertical-align-middle'>
                                                <span className={setClassName(item)}>
                                                    {item.adjustType == ADJUST_TYPE_STOCKIN.Increase ? "+" : item.adjustType == ADJUST_TYPE_STOCKIN.Decrease ? "-" : item.adjustType == ADJUST_TYPE_STOCKIN.Deleted ? "x" : ""}
                                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.adjustQuantity} />
                                                </span>
                                            </td>
                                            <td className='text-center vertical-align-middle font-weight-bold'>
                                                <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalQuantity} />
                                            </td>
                                            <td className='vertical-align-middle'>{item.adjustTypeName}</td>
                                            <td className='text-center vertical-align-middle'>{item.createdDateDisplay}</td>
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        ) : (
                            <tr>
                                <td colSpan='100' className='text-center'>
                                    <span>Không có dữ liệu</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </CommonModal>
    );
}
