import React, { useState, useEffect, useRef } from 'react';
import { Layout } from '../layout/Layout';
import Loading from '../based/Loading';
import PubSub from 'pubsub-js'
import { CONSTANTS, MAINMENU } from '../based/Constants';
import Request from '../based/Request';
import { NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import { ConfirmDialog } from '../based/Dialog';
import Common from '../based/Common';
import Breadcrumbs from '../based/Breadcrumbs'
import CommonTable from '../based/CommonTable';
import AddOrEdit from './AddOrEdit'
import TFUTable from '../based/TFUTable';

const Action = {
    id: 0,
    name: '',
    actionCode: '',
    description: '',
    methodType: '',
    apiUrl: ''
}

const columns = [
    {
        name: 'name',
        displayName: 'Tên chức năng',
    },
    {
        name: 'actionCode',
        displayName: 'Code',
    },
    {
        name: 'description',
        displayName: 'Mô tả',
    }
]

export default function ManageAction(props) {
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState('Đang tải dữ liệu...')
    const [isShowModal, setIsShowModal] = useState(false);
    const [action, setAction] = useState(Action);

    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);

    useEffect(() => {
        setBusy(false)
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Settings });
    }, [])
    function handleAdd() {
        setAction(Action)
        setIsShowModal(true)
    }
    function handleEdit(item) {
        setAction(item)
        setIsShowModal(true)
    }

    function handleDelete(items) {
        let ids = []
        if (items.length != 0) {
            items.forEach(item => ids.push(item.id))
            ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
                return new Promise((resolve, reject) => {
                    setBusy(true)
                    setMsg('Đang xóa dữ liệu...')
                    Request.Post('/api/action/delete/', ids)
                        .then(res => {
                            if (res.success && res.data) {
                                resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                                setBusy(false)
                                setMsg(null)
                                setTableGuid(Common.generateGuid())
                            }
                            else {
                                setBusy(false)
                                setMsg(null)
                                Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                            }
                        })
                        .catch(err => {
                            setBusy(false)
                            setMsg(null)
                        });
                })
            });
        } else {
            Notify(NOTIFY.WARNING, 'Chú ý', 'Không có chức năng nào được chọn. Vui lòng chọn một chức năng để xóa!');
        }
    }
    function _handleSaveSucceed(isContinue) {
        setTableGuid(Common.generateGuid())
        if (isContinue) setAction(Action)
        else setIsShowModal(false)
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage=' Quản lý chức năng' parentPage='Cài đặt' hrefParent='/settings' />
            <AddOrEdit
                isShowModal={isShowModal}
                action={action}
                onClose={() => setIsShowModal(false)}
                onSaveSucceed={_handleSaveSucceed}
            />
            <Loading show={isBusy} msg={msg} />
            <TFUTable
                fetchDataUrl='/api/action/get-page'
                ref={tableRef}
                tableGuid={tableGuid}
                columns={columns}
                headerCheckbox
                search
                onAddItem={handleAdd}
                onEditItem={handleEdit}
                onDeleteItem={handleDelete}
            />
        </React.Fragment>
    )
}