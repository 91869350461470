import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../based/Breadcrumbs";
import CommonTable from "../../based/CommonTable";
import { INITPAGING, NOTIFY } from "../../based/Constants";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import { ConfirmDialog } from "../../based/Dialog";
import TemplateTypeServices from "../../based/services/TemplateTypeServices";
import AddOrEditModal from "./AddOrEditModal";
import CheckBox from "../../based/inputs/CheckBox";

const TemplateTypeModel = {
    id: 0,
    name: "",
};
function TemplateTypeManage(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [datas, setDatas] = useState([TemplateTypeModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await TemplateTypeServices.GetTemplateTypes(paging);
        if (!err && data) {
            setIsBusy(false);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
            setIsBusy(false);
        }
        setIsBusy(false);
    }
    const handleSortColumn = (columnName) => {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        _getData(newPaging);
    };
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className='btn btn-custom'
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>

                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger ml-3" : "btn btn-danger ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };
    function _renderHeader() {
        let icon = <i className='fa fa-sort fa-sort-thead'></i>;
        return (
            <thead>
                <tr>
                    <th className='center'>
                        <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th className='center' onClick={() => handleSortColumn("name")}>
                        Tên kiểu template {paging.orderBy === "name" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "name" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                    </th>
                    <th className='center'>Hành động</th>
                </tr>
            </thead>
        );
    }

    const _handleDelete = async (id) => {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    ids.map(async (set) => {
                        let [error, data] = await TemplateTypeServices.DeleteTemplateTypeById(set);
                        if (error || !data) {
                            setIsLoading(false);
                            setMsg(null);
                            Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                            reject({ title: "Thất bại", msg: "Vui lòng thử lại sau." });
                        } else {
                            setIsLoading(false);
                            setMsg(null);
                            _getData(paging);
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                        }
                    });
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có dữ liệu nào được chọn. Vui lòng kiểm tra lại!");
    };

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>{item.name}</td>
                                <td>
                                    <button
                                        className='btn btn-custom'
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg='Đang tải dữ liệu...' />
            <Breadcrumbs currentPage='Quản lý template type' />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            {isShowModal && (
                <AddOrEditModal
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        _getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </React.Fragment>
    );
}

export default TemplateTypeManage;
