import React from "react";
import PropTypes from "prop-types";
import { ButtonBlackRounded } from "../content/ct.elm";

function ButtonTab(props) {
    return (
        <ButtonBlackRounded
            className={props.className}
            onClick={(e) => {
                e.preventDefault();
                if (props.onClick) props.onClick();
            }}
        >
            <span>{props.title}</span>
        </ButtonBlackRounded>
    );
}

ButtonTab.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

export default ButtonTab;
