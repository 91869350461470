import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../../based/inputs/Textbox";
import TextArea from "../../based/inputs/TextArea";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import AudioServices from "../../based/services/AudioServices";
import useFormValidate from "../../hooks/useFormValidate";
import Common from "../../based/Common";
import Request from "../../based/Request";
import { INITPAGING , CONSTANTS} from "../../based/Constants";
import initAudio from "./AudioManagement"
import styled from "styled-components";

const AddOrUpdateAudio = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [audio, setAudio] = useState({...initAudio});
    const [duration, setDuration] = useState(0);
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(() => {
        if (props.id > 0) getAudio(props.id);
        else setAudio({ ...initAudio });
        return () => setAudio({ ...initAudio });
    }, [props.id]);
    

    const getAudio = async (id) => {
        setIsBusy(true);
        let [err, data] = await AudioServices.GetAudioById(id);
        if (!err && data) {
            setIsBusy(false);
            setAudio(data);
            setDuration(data.duration)
        } else {
            setIsBusy(false);
            setAudio({...initAudio});
        }
    };
    const _handleSave = async () => {
        if (isValid()&&audio.url && duration> 0) {
            setIsBusy(true);
            let [err, data] = await AudioServices.CreateUpdateAudio({ ...audio, duration: duration });
            if (!err && data == 1) {
                setIsBusy(false);
                setAudio({...initAudio});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
        if(!audio.url){
            Notify(NOTIFY.NOTIFY, NOTIFY.NOTIFY, "Chưa tải audio");
        }
        if(duration == 0){
            Notify(NOTIFY.NOTIFY, NOTIFY.NOTIFY, "Lấy thời lượng audio lỗi");
        }
    };


    const handleChangeDuration = (url)=>{
        var au = new Audio(url);
        au.addEventListener('canplaythrough', function() {
            setDuration(Math.floor(au.duration))
        })
    }
   
    const handleChangeAudio= (e)=>{
        e.preventDefault();
        let files = e.target.files;
        let file = files[0];
        if (file) {
            let reader = new FileReader();
            let documentType = 0;
            reader.onloadend = () => {
                Request.UploadDocument(file,documentType)
                    .then((res) => {
                        if (res && res.data) {
                            setAudio({ ...audio, url: res.data.url })
                            handleChangeDuration(res.data.url)
                            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Tải audio thành công");
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                            return `${prev}${item.value}`;
                                        }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                    })
                    .catch((err) => {
                    });
            };
            reader.readAsDataURL(file);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đọc file lỗi");
        }
    
    }
    function convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        return formattedTime;
    }
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Name:
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => setAudio({ ...audio, name: value })} value={audio.name} />
                <Wapper>
                    <label htmlFor='audio' className='required  mt-3'>
                        File Audio:
                    </label>
                    <div>
                        <label htmlFor='audio' className='custom-btn-choose'>
                            {audio.url ? "Thay đổi audio": "Chọn audio"}
                        </label>
                    </div>
                    <input id='audio' name='audio' required type="file"
                        accept={".mp3,.wav,.flac,.aiff,.aif,.ogg,.m4a"}
                        onChange={(e)=>{
                            handleChangeAudio(e)
                        }} 
                    />
                    <div>{audio.url ? <a href={audio.url}  target="_blank">{audio.url}</a> : "Chưa chọn audio"}</div>
                    <div>{duration ?   `Thời lượng: `+ convertSecondsToMinutes(duration) : ""}</div>
                </Wapper>
            </div>
        </CommonModal>
    );
};

export default AddOrUpdateAudio;
const Wapper = styled.div`

    .custom-btn-choose{
        display: inline-block;
        background: #eee;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        margin-right: 10px;
    }

    input[type="file"]{
        visibility: hidden;
    }
    
`
AddOrUpdateAudio.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
