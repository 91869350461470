import BaseServices from "./BaseServices";

const ArtworkServices = {
    CreateUpdateListArtwork: async (model) => {
        return BaseServices.Post("/api/artwork/create-update-list-artwork", model);
    },
    GetArtworkQuota: async () => {
        return BaseServices.Get("/api/artwork/get-artwork-quota");
    },
    GetSetPaging: async (paging, status, tabIndex) => {
        return BaseServices.Post(`/api/artwork/get-set-paging?status=${status}&tab=${tabIndex}`, paging);
    },
    DeleteSet: async (id) => {
        return await BaseServices.Post(`/api/artwork/delete-set?id=${id}`, null);
    },
    GetCensorshipPaging: async (paging) => {
        return BaseServices.Post(`/api/artwork/get-censorship-paging`, paging);
    },
    GetArtworkById: async (id) => {
        return BaseServices.Get(`/api/artwork/get-by-id?id=${id}`);
    },
    SaveCensorship: async (model) => {
        return BaseServices.Post(`/api/artwork/save-censorship`, model);
    },
    AdditionalInfo: async (model) => {
        return BaseServices.Post(`/api/artwork/save-additional-info`, model);
    },
    GetInfoToComplement: async (id) => {
        return BaseServices.Get(`/api/artwork/get-info-complement?id=${id}`);
    },
    GetPreAddArtwork: async (id) => {
        return BaseServices.Get(`/api/artwork/get-pre-add-artwork/${id}`);
    },
    SaveSetCensorship: async (model) => {
        return BaseServices.Post(`/api/artwork/save-set-censorship/`, model);
    },
    ConfigSetStatus: async (setId, status) => {
        return BaseServices.Get(`/api/artwork/config-set/${setId}/${status}`);
    },
    GetAppProductCodeModels: async (setId, ageSetId = 0) => {
        return BaseServices.Get(`/api/artwork/get-app-product-code/${setId}/${ageSetId}`);
    },
    GetTagTrending: async () => {
        return BaseServices.Get(`/api/artwork/get-tag-trending`);
    },
};

export default ArtworkServices;
