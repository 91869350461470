import React from "react";
import styled from "styled-components";

const PosterReview = (props) => {
    const { show, img, setShow } = props;
    return (
        <Wrapper show={show}>
            <div className="container">
                <img className="image" src={img && img} />
            </div>
            <span onClick={() => setShow(false)}>Đóng</span>
        </Wrapper>
    );
};

export default PosterReview;

const Wrapper = styled.div`
    display: ${({ show }) => (show ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    flex-direction: column;
    bottom: 0;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    .container {
        position: relative;
        margin-bottom: 20px;
        .image {
            max-height: 80vh;
            max-width: 100%;
            object-fit: cover;
        }
    }
    span {
        padding: 5px 25px;
        color: white;
        background: #00c853;
        cursor: pointer;
    }
`;
