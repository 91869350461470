import React, { useState, useEffect } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import CheckBox from "../based/inputs/CheckBox";
import { Notify } from "../based/Notify";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import BlogServices from "../based/services/BlogServices";
import SettingModal from "./components/SettingModal";
import { useHistory } from "react-router-dom";

export const BLOG_STATUS = {
    ACCEPTED: 1,
    PENDING: 0,
};
export const BLOG_FLAG = {
    DEFAULT: 0,
    HIGH_LIGHT: 1,
    SLIDER: 2,
    HOT: 3,
};
const BLOG_ACTIVE = {
    ACTIVE: 1,
    UN_ACTIVE: 0,
};
const flagStatistic = [
    {
        value: 0,
        label: "Mặc định",
        count: 0,
    },
    {
        value: 1,
        label: "Nổi bật",
        count: 0,
    },
];
const statusStatistic = [
    {
        value: 1,
        label: "Đã duyệt",
        count: 0,
    },
    {
        value: 0,
        label: "Đang chờ duyệt",
        count: 0,
    },
];
const reviewStatistic = [
    {
        value: 1,
        label: "Hiển thị",
        count: 0,
    },
    {
        value: 0,
        label: "Chưa hiển thị",
        count: 0,
    },
];
export const BlogModel = {
    id: 0,
    categoryId: 0,
    title: "",
    avatar: "",
    shortContent: "",
    content: "",
    isActive: 0,
    indexPos: 0,
    status: 0,
    flag: 0,
    isDelete: false,
    createdBy: "",
    createdDate: "",
    modifiedBy: "",
    modifiedDate: "",
    tags: "",
    seoUrl: "",
};

const BlogManagement = () => {
    const history = useHistory();

    //init blog model
    const [blog, setBlog] = useState({ ...BlogModel });
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    //init blog paging
    const [paging, setPaging] = useState({ ...Common.Paging });
    const [datas, setDatas] = useState([]);
    const [settingModal, setSettingModal] = useState(false);
    const [selectedAll, setSelectedAll] = useState(false);
    //blog Statistic
    const [statisticFlagHandle, setStatisticFlagHandle] = useState([]);
    const [statisticStatusHandle, setStatisticStatusHandle] = useState([]);
    const [statisticReviewHandle, setStatisticReviewHandle] = useState([]);
    const [statisticData, setStatisticData] = useState([]);
    const [isStatistic, setIsStatistic] = useState(false);
    const [statisticLoading, setStatisticLoading] = useState(false);

    useEffect(() => {
        getDatas({ ...paging });
    }, []);
    const handleRedirect = (url) => {
        return window.open(url, "_blank");
    };
    useEffect(() => {
        if (paging.totalRecord > 0) getAllData({ ...INITPAGING, pageSize: paging.totalRecord });
    }, [isStatistic]);
    useEffect(() => {
        if (statisticData.length > 0) handleStatisticData(statisticData);
    }, [isStatistic, statisticData]);

    //lay data blog
    async function getDatas(paging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await BlogServices.GetListBlog(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function _handleEdit() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length == 1) {
            history.push(`/blog/${dataChecked[0].id}`);
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn một thông tin trước khi sửa");
    }

    //thống kê dữ liệu

    const getAllData = async (paging) => {
        if (isStatistic) {
            setStatisticLoading(true);
            let [err, data] = await BlogServices.GetListBlog(paging);
            if (!err && data) {
                setStatisticLoading(false);
                setStatisticData(data.listObjects);
            } else {
                setStatisticLoading(false);
                setStatisticData([]);
            }
        }
    };
    const handleStatisticData = (statisticData) => {
        var flagStatisticHandle = [...flagStatistic];
        var statusStatisticHandle = [...statusStatistic];
        var reviewStatisticHandle = [...reviewStatistic];
        if (statisticData && statisticData.length > 0) {
            flagStatisticHandle.map((item) => {
                let count = statisticData.filter((data) => data.flag == item.value);
                item.count = count.length;
                return { ...item };
            });

            statusStatisticHandle.map((item) => {
                let count = statisticData.filter((data) => data.status == item.value);
                item.count = count.length;
                return { ...item };
            });
            reviewStatisticHandle.map((item) => {
                let count = statisticData.filter((data) => data.isActive == item.value);
                item.count = count.length;
                return { ...item };
            });
        }

        setStatisticFlagHandle(flagStatisticHandle);
        setStatisticStatusHandle(statusStatisticHandle);
        setStatisticReviewHandle(reviewStatisticHandle);
    };

    const renderHeaderStatisticStatus = () => {
        return (
            <thead>
                <tr key=''>
                    <th>Thuộc tính</th>
                    <th>Số lượng</th>
                </tr>
            </thead>
        );
    };
    const renderBodyStatisticStatus = () => {
        return (
            <tbody>
                {statisticStatusHandle && statisticStatusHandle.length > 0
                    ? statisticStatusHandle.map((item, idx) => (
                          <tr key={idx}>
                              <td>{item.label}</td>
                              <td>{item.count}</td>
                          </tr>
                      ))
                    : ""}
            </tbody>
        );
    };
    const renderBodyStatisticFlag = () => {
        return (
            <tbody>
                {statisticFlagHandle && statisticFlagHandle.length > 0
                    ? statisticFlagHandle.map((item, idx) => (
                          <tr key={idx}>
                              <td>{item.label}</td>
                              <td>{item.count}</td>
                          </tr>
                      ))
                    : ""}
            </tbody>
        );
    };

    const renderBodyStatisticReview = () => {
        return (
            <tbody>
                {statisticReviewHandle && statisticReviewHandle.length > 0
                    ? statisticReviewHandle.map((item, idx) => (
                          <tr key={idx}>
                              <td>{item.label}</td>
                              <td>{item.count}</td>
                          </tr>
                      ))
                    : ""}
            </tbody>
        );
    };

    //table blog
    function _renderHeader() {
        let icon = <i className='fa fa-sort fa-sort-thead'></i>;
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.isChecked).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width='120' className='text-center'>
                        Ảnh
                    </th>
                    <th onClick={() => handleSortColumn("title")}>
                        Tiêu đề blog{" "}
                        {paging.orderBy === "title" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "title" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                    </th>
                    <th className='text-center'>Nội dung ngắn</th>
                    <th width='80' className='text-center' onClick={() => handleSortColumn("indexPos")}>
                        Thứ tự hiển thị
                        {paging.orderBy === "indexPos" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "indexPos" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                    </th>
                    <th width='80' className="text-center">
                        Lượt xem
                    </th>
                    <th width='150' className='text-center' onClick={() => handleSortColumn("createdDate")}>
                        Người tạo - Ngày tạo
                        {paging.orderBy === "createdDate" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "createdDate" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th width='150' className='text-center'>
                        Ghi chú
                    </th>
                    <th width='80' className='text-center'>
                        Thiết lập
                    </th>
                    <th width='80' className='text-center' onClick={() => handleSortColumn("status")}>
                        Trạng thái
                        {paging.orderBy === "status" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "status" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                    </th>
                </tr>
            </thead>
        );
    }
    const renderFlag = (flag) => {
        switch (flag) {
            case BLOG_FLAG.DEFAULT:
                return "Mặc định";
                break;
            case BLOG_FLAG.HIGH_LIGHT:
                return "Nổi bật";
                break;
            case BLOG_FLAG.SLIDER:
                return "Trên Slide";
                break;
            case BLOG_FLAG.HOT:
                return "Chủ đề HOT";
                break;
            default:
                return "Mặc định";
        }
    };
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        let count = 0;
                        let countLink = item.content.match(/<a[^>]*>([^<]+)<\/a>/g);
                        if (countLink && countLink.length > 0) count = countLink.length;
                        return (
                            <tr key={idx}>
                                <td align='center'>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.isChecked ? item.isChecked : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td align='center'>{item.avatar ? <img src={item.avatar} width={200} alt={item.title} /> : "Chưa có hình đại diện"}</td>
                                <td width=''>
                                    <h5 className='text-ellipsis-4'>{item.title} </h5>
                                </td>
                                <td>
                                    {" "}
                                    <p className='text-ellipsis-4' style={{ maxWidth: "300px" }}>
                                        {" "}
                                        {item.shortContent}
                                    </p>
                                </td>
                                <td align='center'>{item.indexPos}</td>
                                <td align="center">{item.count?item.count:0}</td>
                                <td align='center'>
                                    {" "}
                                    <div>{item.createdBy ? item.createdBy : "Chưa rõ"}</div>
                                    <div className='mt-2'>Ngày tạo: {item.createdDate ? item.createdDate : "Chưa rõ"}</div>
                                </td>
                                <td align='center'>
                                    <p className={item.flag == 1 ? "text-danger" : "text-success"}>{renderFlag(item.flag)}</p>
                                    <br />
                                    {item.content ? <span>Số back link: {count}</span> : ""} <br />
                                    {item.isActive ? <p className='text-success'>Đã hiển thị</p> : <p className='text-muted'>Đang ẩn</p>}
                                    <br />
                                    <span className='cursor-pointer text-primary' onClick={() => handleRedirect(`/preview-blog/${item.id}`)}>
                                        Xem preview
                                    </span>
                                </td>
                                <td align='center'>
                                    <i
                                        className='fa fa-cog cursor-pointer'
                                        aria-hidden='true'
                                        style={{ color: "#159a7f" }}
                                        onClick={() => {
                                            setSettingModal(true);
                                            setBlog(item);
                                        }}
                                    ></i>
                                </td>

                                <td align='center'>
                                    {item.status == BLOG_STATUS.ACCEPTED ? <p className='text-success'>Đã duyệt</p> : item.status === BLOG_STATUS.PENDING ? <p className='text-warning'>Đang chờ duyệt</p> : <p className='text-danger'>Từ chối</p>}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='vertical-align-middle text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.isChecked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'>
                    <button className='btn btn-custom btn-sm m-r-5' onClick={() => history.push("/blog")}>
                        <i className='fa fa-plus m-r-5' />
                        Tạo mới
                    </button>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className='fa fa-remove m-r-5' />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    const handleSortColumn = (columnName) => {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        getDatas(newPaging);
    };
    async function _handleDelete() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((x) => ids.push(x.id));
                    let [err, data] = await BlogServices.DeleteBLogs(ids);
                    if (!err && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn thông tin trước khi xóa");
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.isChecked = value));
        else newDatas[index].isChecked = value;
        setDatas(newDatas);
    };
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Blog' />
            <Loading show={isLoading} msg={msg} />

            {settingModal && (
                <SettingModal
                    isShowModal={settingModal}
                    blog={blog}
                    onClose={() => {
                        setSettingModal(false);
                    }}
                    onSuccess={() => {
                        setSettingModal(false);
                        getDatas(paging);
                        setIsStatistic(false);
                    }}
                />
            )}
            <div className='w-100 mb-3'>
                <button className='btn btn-primary' onClick={() => setIsStatistic(!isStatistic)}>
                    Thống kê
                </button>
            </div>
            {statisticLoading && isStatistic ? (
                <div>Đang xử lý...</div>
            ) : (
                <div className={`w-100  ${isStatistic && !statisticLoading ? "d-flex" : "d-none"}`}>
                    {statisticStatusHandle && statisticStatusHandle.length > 0 ? <CommonTable renderHeader={renderHeaderStatisticStatus} renderBody={renderBodyStatisticStatus} classDivTable='table__fix__height' /> : "Đang xử lý"}
                    {statisticFlagHandle && statisticFlagHandle.length > 0 ? <CommonTable renderHeader={renderHeaderStatisticStatus} renderBody={renderBodyStatisticFlag} classDivTable='table__fix__height' /> : "Đang xử lý"}
                    {statisticReviewHandle && statisticReviewHandle.length > 0 ? <CommonTable renderHeader={renderHeaderStatisticStatus} renderBody={renderBodyStatisticReview} classDivTable='table__fix__height' /> : "Đang xử lý"}
                </div>
            )}

            <CommonTable data={datas} paging={paging} placeholderSearch='Tên blog' renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic />
        </React.Fragment>
    );
};

export default BlogManagement;
