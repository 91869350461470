import React, { useState, useEffect } from 'react'; 
import Loading from '../based/Loading';
import { CONSTANTS } from '../based/Constants';
import AddOrEditRole from './AddOrEditRole'
import { NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import { ConfirmDialog, ErrorDialog } from '../based/Dialog';
import Common from '../based/Common'; 
import Breadcrumbs from '../based/Breadcrumbs'
import RoleMenus from './RoleMenus'
import CommonTable from '../based/CommonTable';
import RoleServices from '../based/services/RoleServices';
import CheckBox from '../based/inputs/CheckBox';

const Role = {
    id: 0,
    name: '',
    isAdmin: true
}

export default function RoleManagement(props) {
    const [isBusy, setBusy] = useState(true)
    const [msg, setMsg] = useState(null)

    const [isShowModal, setIsShowModal] = useState(false);
    const [role, setRole] = useState(Role);
    const [claims, setClaims] = useState([])
    const [isShowModalRoleMenu, setIsShowModalRoleMenu] = useState(false);

    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);
    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        setBusy(false)
        getDatas(paging)
    }, [])

    async function handleDelete() {
        let rolesDelete = datas.filter(x => x.checked)
        if (rolesDelete && rolesDelete.length > 0) {
            let ids = []
            rolesDelete.map(role => ids.push(role.id))
            ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true)
                    setMsg('Đang xóa dữ liệu...')
                    const [err, data] = await RoleServices.DeleteRoles(ids); 
                    if (!err) {
                        resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                        if (data)
                            ErrorDialog('Thông báo', <span dangerouslySetInnerHTML={{ __html: data }} />)
                        setBusy(false)
                        setMsg(null)
                        getDatas(paging)
                    } else {
                        setBusy(false)
                        setMsg(null)
                        Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                    } 
                })
            });
        }
        else Notify(NOTIFY.INFO, 'Thông báo', 'Không có vài trò nào được chọn. Vui lòng kiểm tra lại!');
    }

    function _handleAdd() {
        setRole({
            id: 0,
            name: ''
        })
        getClaimsByRole(0)
    }

    function _handleEdit() {
        let dataChecked = datas.filter(x => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            let item = dataChecked[0]
            setRole(item)
            getClaimsByRole(item.id)
        }
        else Notify(NOTIFY.INFO, 'Thông báo', 'Yêu cầu chọn vai trò để thực hiện. Vui lòng kiểm tra lại!');
    }

    async function getClaimsByRole(roleId) {
        setBusy(true)
        setMsg('Đang tải dữ liệu...')
        const [err, data] = await RoleServices.GetClaimsByRole(roleId);
        if (!err) {
            setClaims(data)
            setIsShowModal(true)
            setBusy(false)
            setMsg(null)
        } else {
            setClaims([])
            setBusy(false)
            setMsg(null)
        }
    }

    async function getDatas(newPaging) {
        setBusy(true)
        setMsg('Đang tải dữ liệu...')
        const [err, data] = await RoleServices.GetRolesPaging(newPaging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false)
            setMsg(null)
        } else {
            setPaging(paging);
            setDatas([]);
            setBusy(false)
            setMsg(null)
        }
    }
    function _handleRoleMenus() {
        let dataChecked = datas.filter(x => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsShowModalRoleMenu(true);
            setRole(dataChecked[0]);
        }
        else Notify(NOTIFY.INFO, 'Thông báo', 'Yêu cầu chọn một vai trò để thực hiện. Vui lòng kiểm tra lại!');
    }
    function _renderAction() {
        let dataChecked = null;
        let isEdit = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter(x => x.checked);
            if (dataChecked && dataChecked.length == 1) {
                isEdit = true;
            }
            if (dataChecked && dataChecked.length > 0) {
                let rolesSystem = dataChecked.filter(x => x.isSystem);
                if (rolesSystem && rolesSystem.length == dataChecked.length) dataChecked = null;
            }
        }
        return (
            <div className='row flex-row p-10 box__actions'>
                <div className='form-group'>
                    <button className='btn btn-custom btn-sm m-r-5' onClick={_handleAdd}>
                        <i className='fa fa-plus m-r-5' /> Thêm mới
                    </button>
                    {
                        datas && datas.length > 0
                            ? <React.Fragment>
                                <button className={isEdit ? 'btn btn-custom btn-sm m-r-5' : 'btn btn-custom btn-sm m-r-5 disable'} onClick={_handleEdit}>
                                    <i className='fa fa-edit m-r-5' />Sửa
                                </button>
                                <button className={(dataChecked && dataChecked.length > 0) ? 'btn btn-danger  btn-sm m-r-5' : 'btn btn-danger  btn-sm m-r-5 disable'} onClick={handleDelete}>
                                    <i className='fa fa-remove m-r-5' />Xóa
                                </button>
                                <button className={isEdit ? 'btn btn-custom btn-sm m-r-5' : 'btn btn-custom btn-sm m-r-5 disable'} onClick={_handleRoleMenus}>
                                    <i className='fa fa-key m-r-5' /> Phân quyền menu
                                </button>
                            </React.Fragment>
                            : null
                    }
                </div>
            </div>
        )
    }
    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map(item => item.checked = value);
        setDatas(datasUpdate);
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='center'>
                        <CheckBox
                            checked={selectedAll}
                            name='select_all'
                            id='select_all'
                            label=' '
                            onCheckedChange={(name, value) => _handleCheckedAll(value)}
                        />
                    </th>
                    <th className='cursor-pointer'>Tên vai trò</th>
                </tr>
            </thead>
        )
    }
    function _renderBody() {
        return datas && datas.length > 0
            ? <tbody>
                {
                    datas.map((item, i) => {
                        return <tr key={i} onClick={() => _handleClickRow(i)} >
                            {
                                <td width={40} className={'center'}>
                                    <CheckBox checked={item.checked ? item.checked : false}
                                        name={`checkBox_${i}`} id={item.id.toString()}
                                        label=' ' onCheckedChange={(name, value) => _handleCheckedChange(item, i, value)}
                                    />
                                </td>
                            }
                            <td>{item.name}</td>
                        </tr>
                    })
                }
            </tbody>
            : <tbody><tr><td colSpan='2' className='center'>Không có dữ liệu</td></tr></tbody>
    }
    function _handleCheckedChange(item, idx, value) {
        let datasUpdate = [...datas];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setDatas(datasUpdate);
        setSelectedAll(datasUpdate.filter(x => x.checked).length == datas.length);
    }
    function _handleClickRow(index) {
        let data = [...datas];
        data[index].checked = !data[index].checked;
        setDatas(data);
        setSelectedAll(data.filter(x => x.checked).length == datas.length)
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage=' Quản lý vai trò' />
            <RoleMenus
                isShowModal={isShowModalRoleMenu}
                role={role}
                onClose={() => setIsShowModalRoleMenu(false)}
                onSaveSucceed={() => {
                    setIsShowModalRoleMenu(false);
                    getDatas(paging)
                }}
            />
            <AddOrEditRole
                isShowModal={isShowModal}
                role={role}
                claims={claims}
                onClose={() => setIsShowModal(false)}
                onSaveSucceed={() => {
                    setIsShowModal(false);
                    getDatas(paging)
                }}
            />
            <Loading show={isBusy} msg={'Đang tải dữ liệu...'} />
            <CommonTable
                data={datas}
                paging={paging}
                headerCheckbox
                searchBasic
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={getDatas}
            />
        </React.Fragment>
    );
}
