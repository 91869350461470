
import PubSub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, PLATFORM } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import Request from "../based/Request";
import styled from "styled-components";
import BaseServices from '../based/services/BaseServices';
import FindingProductSelection from '../affiliate/config-commission/components/FindingProductSelection';

const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 100000000,
    categoryId: 0,
    keyword: ''
}
const initCategory = {
    id: 0,
    parentId: 0,
    code: "",
    name: "",
    shortLink: "",
    icon: "",
    index: 1,
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    description: "",
    icon: "",
    image: "",
    isActive: true,
    isShowHomePage: true,
    categoryRewardPoints: [],
    opfCategories: [],
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    orderBy: "id",
    orderDirection: "asc",
    Platform: PLATFORM.Internal,
};

function ProductCategoryManagement() {
    const [isBusy, setBusy] = useState(true);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [msg, setMessage] = useState("");
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);

    //product
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (selectedCategoryId > 0) {
            let page = { ...INIT_PAGING };
            page.categoryId = selectedCategoryId;
            _fetProducts(page, false);
        }else{
            setProducts([]);
        }
    }, [selectedCategoryId])
    const _fetProducts = async (page, checkUpdate) => {
        let [err, data] = await BaseServices.Post('/api/category/get-display-products', page)
        if (!err && data) {
            setProducts(data);
            if(checkUpdate){
                let dt = [...datas]
                updateQuantityById(dt,page.categoryId , data.length ? data.length : 0)
                setDatas(dt)
            }
        }
    }

    async function _handleRemoveProduct(setId) {
        let model = {
            categoryId: selectedCategoryId,
            setIds: [setId]
        }
        let [err, data] = await BaseServices.Post('/api/category/delete-display-products', model)
        if (!err) {
            let page = { ...INIT_PAGING };
            page.categoryId = selectedCategoryId;
            _fetProducts(page, true);
        }
    }
    async function _handleAddProduct(product) {
        let model = {
            categoryId: selectedCategoryId,
            setIds: [product.id],
            isAppend: true
        }
        let [err, data] = await BaseServices.Post('/api/category/add-products-to-display', model)
        if (!err) {
            _fetProducts({ ...INIT_PAGING, categoryId: selectedCategoryId }, true);
        }
    }




    //ngành hàng
    useEffect(() => {
        setBusy(false);
        getDatas(paging);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Category });
    }, []);
    function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        Request.Post("/api/category/get-page", newPaging)
            .then((res) => {
                setBusy(false);
                setMessage(null);
                if (res.success) {
                    getQuantity(res.data.listObjects);
                    setPaging(res.data.paging);
                    setTableGuid(Common.generateGuid());
                } else {
                    setDatas([]);
                }
            })
            .catch((err) => {
                setDatas([]);
                setPaging(initPage);
                setBusy(false);
                setMessage(null);
            });
    }


    function getAllIds(arr) {
        let idArr = [];
        arr.forEach(item => {
          idArr.push(item.id);
          if (item.children && item.children.length > 0) {
            idArr = idArr.concat(getAllIds(item.children));
          }
        });
        return idArr;
      }


      const updateQuantityById = (array, id, quantity) => {
        for (let i = 0; i < array.length; i++) {
          if (array[i].id === id) {
            array[i].quantity = quantity;
            return true;
          }
          if (array[i].children && array[i].children.length > 0) {
            if (updateQuantityById(array[i].children, id, quantity)) {
              return true;
            }
          }
        }
        return false;
      };
      
      const addQuantityToChildArrays = (array, quantityMap) => {
        return array.map(item => {
            const newItem = {...item};
            if (newItem.children && newItem.children.length > 0) {
              newItem.children = addQuantityToChildArrays(newItem.children, quantityMap);
            }
            newItem.quantity = quantityMap[newItem.id];
            return newItem;
          });
      };
      

    const getQuantity = async(dataCat) => {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        const catIds = getAllIds(dataCat);
        Request.Post("/api/CategoryProduct/get-quantity-product", catIds)
            .then(async(res) => {
                setBusy(false);
                setMessage(null);
                if (res.success) {
                    let arrQuantity = [...res.data]
                    let quantityMap = [];
                    arrQuantity.forEach(item => {
                      quantityMap[item.idCategory] = item.quantity;
                    });
                    let data = await addQuantityToChildArrays(dataCat, quantityMap);
                    setDatas(data)
                } 
            })
            .catch((err) => {
                setBusy(false);
                setMessage(null);
            });
    }
  
    function _recursiveSetStateCat(parent, category, name) {
        if(name === "checked"){
            if (parent.id == category.id){ 
                if(parent[name]){
                    parent[name] = false
                    setSelectedCategoryId(0);
                }else{
                    parent[name] = true;
                    setSelectedCategoryId(category.id);
                }
            }
            else parent[name] = false;
        }else{
            if (parent.id == category.id) parent[name] = !parent[name];
        }
        
        if (parent.children && parent.children.length > 0) {
            parent.children.map((child) => {
                _recursiveSetStateCat(child, category, name);
            });
        }
        return parent;
    }
    function _handleCollapse(category) {
        let datasUp = [];
        datas.map((parent) => {
            let categories = _recursiveSetStateCat(parent, category, "isUp");
            datasUp.push(categories);
        });
        setDatas(datasUp);
    }
    function _handleCheckedChange(category, value) {
        let datasUp = [];
        datas.map((parent) => {
            let categories = _recursiveSetStateCat(parent, category, "checked");
            datasUp.push(categories);
        });
        setDatas(datasUp);
    }
    
   
    function _recursiveCategories(category, level, index) {
        let contents = [];
        if (category) {
            if (category.isUp === undefined) category.isUp = true;
            let icon = category.children && category.children.length > 0 ? <i onClick={() => _handleCollapse(category)} className={category.isUp ? "fa fa-angle-right collapse__cat" : "fa fa-angle-down collapse__cat"}></i> : "";
            let space = "";
            if (level > 1) {
                if (category.children && category.children.length > 0)
                    for (var i = 0; i < level; i++) {
                        space = space + `&nbsp;&nbsp;&nbsp;`;
                    }
                else
                    for (var i = 0; i < level - 1; i++) {
                        space = space + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
                    }
            }
            contents.push(
                <tr key={-1}>
                    <td width={40} className={"center"}>
                        <CheckBox 
                        checked={!!category.checked} 
                        name={category.id.toString()} 
                        id={category.id.toString()} 
                        label=" " 
                        onCheckedChange={ async (name, value) => {
                            _handleCheckedChange(category, value)
                        }} 
                        />
                    </td>
                    <td>
                        <span dangerouslySetInnerHTML={{ __html: space ? space : "" }} />
                        {icon}
                        {" "}
                        {category.icon && <img  src={category.icon} />}
                        {" "}
                        {category.name}
                    </td>
                    <td>{category.quantity}</td>
                    <td className="center">
                        {category.isShowHomePage ? (
                            <span className="status__active">
                                Đang hiển thị trên trang chủ
                            </span>
                        ) : (
                            <span className="status__unactive">
                                Đang ẩn
                            </span>
                        )}
                    </td>
                </tr>
            );
            if (!category.isUp && category.children && category.children.length > 0) {
                level++;
                category.children.map((child) => {
                    contents.push(_recursiveCategories(child, level));
                });
            }
        }
        return contents;
    }

    

    function _renderBody() {
        if (datas && datas.length > 0) {
            let contents = [];
            datas.map((item, index) => {
                let categories = _recursiveCategories(item, 1 , index);
                contents.push(categories);
            });
            if (contents && contents.length > 0) {
                return (
                    <tbody>
                        {contents.map((item, i) => {
                            return <React.Fragment key={i}>{item}</React.Fragment>;
                        })}
                    </tbody>
                );
            }
        }
        return (
            <tbody>
                <tr>
                    <td colSpan={4} className="text-center">
                        Không có dữ liệu!
                    </td>
                </tr>
            </tbody>
        );
    }
    function _renderAction() {
        
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                    </th>
                    <th className="cursor-pointer">Tên ngành hàng</th>
                    <th className="cursor-pointer" width="150">
                        Số sản phẩm
                    </th>
                    <th className="cursor-pointer" width="150">
                        Trạng thái
                    </th>
                </tr>
            </thead>
        );
    }


    return ( 
        <React.Fragment>            
            <Breadcrumbs currentPage="Quản lý sản phẩm ngành hàng" />
            <Loading show={isBusy} msg={msg} />
            <Wapper>
                <div className="w-40pc">
                    <CommonTable
                        data={datas}
                        paging={paging}
                        ref={tableRef}
                        tableGuid={tableGuid}
                        headerCheckbox
                        searchBasic
                        hideSearchBasicButton
                        renderAction={_renderAction}
                        renderHeader={_renderHeader}
                        renderBody={_renderBody}
                        onFilter={getDatas}
                    />
                </div>
                <div className="w-60pc">
                    <div className="table-category">
                        <div className="header-table">
                            {selectedCategoryId > 0 &&  <FindingProductSelection isSetProduct={true} sm id='finding_set' name={'finding_set'} onSelectItem={_handleAddProduct} />}
                        </div>
                        <div className="body-table">
                            {products && products.length > 0 ? products.map((item, idx)=>{
                                return (
                                    <div key={idx} className="col-table">
                                        <div className="item-table">
                                            <div className="remove"  onClick={() => _handleRemoveProduct(item.id)}>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.26256 1.5L0.779297 10" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M9.26367 10L0.780405 1.5" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                            <div className="box-img">
                                                <img src={item.productAvatar} alt={item.title} />
                                            </div>
                                            <div className="box-name">
                                                <img src={item.brandAvatar} alt={item.brandAvatar}/>
                                                <p>{item.brandName}</p>
                                            </div>
                                            <div className="description">{item.title}</div>
                                        </div>
                                    </div>
                                )
                            }): ""}
                            
                        </div>
                    </div>
                </div>
            </Wapper>
           

        </React.Fragment>
     );
}

const Wapper = styled.div`
    display: flex;
    align-items: stretch;
    .w-40pc{
        width: 40%;

    }
    .w-60pc{
        width: 60%;
        background-color: #fff;
        margin-bottom: 20px;

        .table-category{
            width:  calc(100% - 10px);
            margin-left: 10px;
            background-color: #fff;

            .header-table{
                margin-top: 55px;
                height: 50px;
                padding: 6px 12px;
                background: #D9D9D9;

                .tfu-autocomplete-input{

                }
                
            }
            .body-table{
                padding: 12px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -6px;
                overflow-y: auto;
                height: 500px;

                .col-table{
                    width: 16.6666%;
                    max-width: 16.6666%;
                    min-width: 16.6666%;
                    padding: 0 6px;

                    .item-table{
                        margin-bottom: 12px;
                        width: 100%;
                        padding: 6px;
                        border: 1px solid #D9D9D9;
                        position: relative;

                        .box-img{
                            width: 100%;
                            aspect-ratio: 1/1;

                            img{
                                width: 100%;
                                height: 100%;
                                min-height: 100%;
                                object-fit: contain;
                            }
                        }
                        
                        .remove{
                            position: absolute;
                            top: 6px;
                            right: 6px;
                            cursor: pointer;
                        }
                        .box-name{
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            img{
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                                border-radius: 50%;
                            }
                            p{
                                font-size: 12px;
                                font-weight: bold;
                                line-height: 14px;
                                margin-left: 6px;
                                color: #000000;
                            }
                        }
                        .description{
                            width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-size: 12px
                            line-height: 14px;
                            color: #676767;
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }

`

export default ProductCategoryManagement;