import React from 'react'
import {  ContentWrapper } from './ct.elm'

const Content = ({ toggle ,children}) => {
    return (
        <ContentWrapper toggle={toggle}>
            {children}
        </ContentWrapper>
    )
}

export default Content
