import React, { useEffect, useState, useRef } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CAMPAIGN_BOOSTING_STATUS, CAMPAIGN_BOOSTING_STATUS_NAME } from "../based/Constants";
import Loading from "../based/Loading";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import CampaignServices from "../based/services/CampaignServices";
import CommonServices from "../based/services/CommonServices";
import MarketerRegisterModal from "./MarketerRegisterModal";
import { Overlay, Popover } from "react-bootstrap";
var moment = require("moment");

export default function MarketerMasterCampaignBoosting(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: -1 });
    const [datas, setDatas] = useState([]);

    const [creatorOptions, setCreatorOptions] = useState([]);
    const [marketerOptions, setMarketerOptions] = useState([]);
    const [currentBoosting, setCurrentBoosting] = useState({});
    const [showModal, setShowModal] = useState(false);

    const [showOverlay, setShowOverlay] = useState(false);
    const ref = useRef(null);
    const [target, setTarget] = useState(null);
    const [overlayData, setOverlayData] = useState(null);

    useEffect(() => {
        let pagingNew = { ...Common.Paging, status: -1 };
        pagingNew.fromRunCampaingDate = new Date(new Date().setDate(new Date().getDate() - 15));
        pagingNew.toRunCampaingDate = new Date(new Date().setDate(new Date().getDate() + 15));
        getDatas(pagingNew);
        getCreators();
        getMarketers();
    }, []);

    async function getCreators() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setCreatorOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getMarketers() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetMarketerUsers();
        if (!error && data) {
            setMarketerOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setMarketerOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetMarketePerformanceBoostingPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.fromCreatedDate) pagingUp.fromCreatedDate = moment(pagingUp.fromCreatedDate).toDate();
                if (pagingUp.toCreatedDate) pagingUp.toCreatedDate = moment(pagingUp.toCreatedDate).toDate();

                if (pagingUp.fromRunCampaingDate) pagingUp.fromRunCampaingDate = moment(pagingUp.fromRunCampaingDate).toDate();
                if (pagingUp.toRunCampaingDate) pagingUp.toRunCampaingDate = moment(pagingUp.toRunCampaingDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function handleSortColumn(columnName) {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        getDatas(newPaging);
    }
    function _renderHeader() {
        let icon = <i className='fa fa-sort fa-sort-thead'></i>;
        return (
            <thead>
                <tr>
                    <th
                        className='text-center'
                        width='50'
                        rowSpan='2'
                        onClick={() => {
                            handleSortColumn("id");
                        }}
                    >
                        STT
                    </th>
                    <th className='text-center' rowSpan='2'>
                        Tên Set sản phẩm
                    </th>
                    <th className='text-center' width='150' colSpan='2'>
                        Hình mockup
                    </th>
                    <th className='text-center' width='100' rowSpan='2' onClick={() => handleSortColumn("loyaltyAmount")}>
                        Tiền bản quyền (đ) &nbsp;
                        {paging.orderBy === "loyaltyAmount" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "loyaltyAmount" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th className='text-center' width='150' rowSpan='2'>
                        Tên Creator
                    </th>
                    <th className='text-center' width='150' rowSpan='2'>
                        Tên Marketer
                    </th>
                    <th className='text-center' width='100' rowSpan='2' onClick={() => handleSortColumn("toltalTraffics")}>
                        Tổng lượng traffic &nbsp;
                        {paging.orderBy === "toltalTraffics" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "toltalTraffics" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th className='text-center' width='150' rowSpan='2' onClick={() => handleSortColumn("toltalTraffics30days")}>
                        Tổng traffic trong 30 ngày gần nhất&nbsp;
                        {paging.orderBy === "toltalTraffics30days" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "toltalTraffics30days" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th className='text-center' width='150' colSpan='2'>
                        Trong thời gian đăng ký chạy
                    </th>
                    <th className='text-center' width='130' rowSpan='2'>
                        Trạng thái
                    </th>
                    <th width='100' className='text-center' rowSpan='2'></th>
                </tr>
                <tr>
                    <th width='100' className='text-center'>
                        Mặt trước
                    </th>
                    <th width='100' className='text-center'>
                        Mặt sau
                    </th>
                    <th className='text-center' width='150' rowSpan='2' onClick={() => handleSortColumn("toltalTrafficsRangeRun")}>
                        Tổng traffics&nbsp;
                        {paging.orderBy === "toltalTrafficsRangeRun" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "toltalTrafficsRangeRun" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th className='text-center' width='150' rowSpan='2' onClick={() => handleSortColumn("totalOrders")}>
                        Tổng đơn hàng&nbsp;
                        {paging.orderBy === "totalOrders" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "totalOrders" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderStatus(item) {
        let cls = "rounded text-white status";
        switch (item.status) {
            case CAMPAIGN_BOOSTING_STATUS.APPROVED:
                cls += " label-info";
                break;
            case CAMPAIGN_BOOSTING_STATUS.RUNNING:
                cls += " label-success";
                break;
            case CAMPAIGN_BOOSTING_STATUS.FINISH:
                cls += " label-danger";
                break;
            default:
                cls += " label-secondary";
                break;
        }
        return (
            <div className={cls}>
                <span>{CAMPAIGN_BOOSTING_STATUS_NAME[item.status]}</span>
            </div>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    <React.Fragment>
                        {datas.map((item, i) => {
                            let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                            return (
                                <tr key={i}>
                                    <td className='text-center vertical-align-middle'>{no}</td>
                                    <td className='vertical-align-middle'>{item.setName}</td>
                                    <td className='vertical-align-middle'>
                                        <img src={item.mockupFront ? item.mockupFront : "/images/no-image.png"} alt={item.setName} className='variant-avatar' />
                                    </td>
                                    <td className='vertical-align-middle'>
                                        <img src={item.mockupBack ? item.mockupBack : "/images/no-image.png"} alt={item.setName} className='variant-avatar' />
                                    </td>
                                    <td className='text-right vertical-align-middle'>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                    </td>
                                    <td className='vertical-align-middle'>{item.createdBy}</td>
                                    <td className='vertical-align-middle'>{item.marketerUserName}</td>
                                    <td className='text-right vertical-align-middle'>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.toltalTraffics} />
                                    </td>
                                    <td className='text-right vertical-align-middle'>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.toltalTraffics30days} />
                                    </td>
                                    <td className='text-right vertical-align-middle'>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.toltalTrafficsRangeRun} />
                                        {item.toltalTrafficsRangeRun > 0 && (
                                            <React.Fragment>
                                                &nbsp;
                                                <i
                                                    className='fa fa-book cursor-poiter text-info'
                                                    title='Chi tiết'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setTarget(e.target);
                                                        setShowOverlay(true);
                                                        setOverlayData(item);
                                                    }}
                                                ></i>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className='text-right vertical-align-middle'>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalOrders} />
                                    </td>
                                    <td className='text-center vertical-align-middle'>{_renderStatus(item)}</td>
                                    <td className='text-center vertical-align-middle'>
                                        <span
                                            title='Chi tiết'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentBoosting(item);
                                                setShowModal(true);
                                            }}
                                            className='cursor-poiter text-info'
                                        >
                                            Chi tiết
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                        {showOverlay && <OverlayElement overlay={showOverlay} target={target} onClose={() => setShowOverlay(false)} container={ref.current} data={overlayData} />}
                    </React.Fragment>
                ) : (
                    <tr>
                        <td className='text-center' colSpan='100'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='creatorName'>Người tạo Campaign: </label>
                            <TFUSelect name='creatorName' value={paging.creatorName} options={creatorOptions} placeholder='---Tất cả---' onChanged={(value) => setPaging({ ...paging, creatorName: value })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='marketerName'>Người chạy Campaign: </label>
                            <TFUSelect name='marketerName' value={paging.marketerName} options={marketerOptions} placeholder='---Tất cả---' onChanged={(value) => setPaging({ ...paging, marketerName: value })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='status'>Trạng thái:</label>
                            <TFUSelect name='status' value={paging.status} options={props.statusOptions} placeholder='--Tất cả---' onChanged={(value) => setPaging({ ...paging, status: value != null ? value : -1 })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='time'>Thời gian tạo Campaign:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='fromCreatedDate'
                                        maxDate={paging.toCreatedDate}
                                        value={paging.fromCreatedDate}
                                        placeholder='Từ ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, fromCreatedDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='toCreatedDate'
                                        minDate={paging.fromCreatedDate}
                                        value={paging.toCreatedDate}
                                        placeholder='Đến ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, toCreatedDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='time'>Thời gian chạy Campaign:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='fromRunCampaingDate'
                                        maxDate={paging.toRunCampaingDate}
                                        value={paging.fromRunCampaingDate}
                                        placeholder='Từ ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, fromRunCampaingDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='toRunCampaingDate'
                                        minDate={paging.fromRunCampaingDate}
                                        value={paging.toRunCampaingDate}
                                        placeholder='Đến ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, toRunCampaingDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas(paging)}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            {showModal && (
                <MarketerRegisterModal
                    isShowModal={showModal}
                    currentBoosting={currentBoosting}
                    isPreview
                    onClose={() => setShowModal(false)}
                    onSaveSucceed={() => {
                        setShowModal(false);
                        getDatas(paging);
                    }}
                />
            )}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} />
        </React.Fragment>
    );
}

const OverlayElement = (props) => {
    function _renderBody() {
        if (props.data && props.data.traffics && props.data.traffics.length > 0) {
            return (
                <div className='content-table'>
                    <table className='table table-bordered m-b-0'>
                        <thead>
                            <tr>
                                <th className='vertical-align-middle text-center' width='50'>
                                    STT
                                </th>
                                <th className='vertical-align-middle text-center' width='200'>
                                    Nguồn Traffic
                                </th>
                                <th className='vertical-align-middle text-center' width='150'>
                                    Tổng Traffic
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.traffics.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td className='vertical-align-middle text-center'>{idx + 1}</td>
                                        <td className='vertical-align-middle'>{item.sourceTrafficName}</td>
                                        <td className='vertical-align-middle text-center'>
                                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.toltalTraffics} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
    }
    return (
        <Overlay rootClose={true} onHide={() => props.onClose()} show={props.overlay} target={props.target} placement='top' container={props.container} containerPadding={20}>
            <Popover id='popover-stock'>
                <Popover.Content>{_renderBody()}</Popover.Content>
            </Popover>
        </Overlay>
    );
};
