import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import { CARD_COLOR, CONSTANTS, NOTIFY, REGEX } from "../based/Constants";
import { Box, BtnRounded, Table } from "../based/content/ct.elm";
import TableFooter from "../based/content/TableFooter";
import { Notify } from "../based/Notify";
import BaseModal from "../based/refactor/BaseModal";
import HeaderFilter from "../based/refactor/HeaderFilter";
import TextArea from "../based/refactor/TextArea";
import Textbox from "../based/refactor/Textbox";
import TextNumber from "../based/refactor/TextNumber";
import CampaignServices from "../based/services/CampaignServices";
import PrintServices from "../based/services/PrintServices";
import useFormValidate from "../hooks/useFormValidate";
const SetupModal = ({ isModal, isView, handleModalBoosting, onReloadDashboard, currentBoosting, onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const { addRef, displayError, isValid } = useFormValidate();
    const [config, setConfig] = useState({});
    const [readRateCard, setReadRateCard] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);
    const [products, setProducts] = useState([]);
    const hashtagsRef = useRef();
    const [dashboard, setDashboard] = useState([]);

    useEffect(() => {
        getDashboard();
    }, []);
    useEffect(() => {
        getReadRateCard();
        if (currentBoosting && currentBoosting.id > 0) getBoosting(currentBoosting.id);
        else getProducts(Common.Paging);
    }, [isModal]);
    const getReadRateCard = async () => {
        const [err, data] = await PrintServices.ReadRateCard();
        if (!err && data) {
            setReadRateCard(data);
        } else {
            setReadRateCard([]);
        }
    };
    const getDashboard = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetDashboardCampaignBoosting();
        if (!error && data) {
            setDashboard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDashboard({});
            setIsLoading(false);
            setMsg(null);
        }
    };

    const handleHashTags = (e) => {
        var hashtag = hashtagsRef.current.value;
        if (e.charCode === 13 && hashtag !== "") {
            let hashTagList = config.hashTag || [];
            if (hashTagList.length > 4) Notify(NOTIFY.WARNING, "Chú ý", "Chỉ được phép tối đa 5 Hashtag", 5);
            else if (hashtag.indexOf(" ") >= 0) Notify(NOTIFY.WARNING, "Chú ý", "Hashtag không chứa khoảng trắng.", 5);
            else if (hashtag.length > 50) Notify(NOTIFY.WARNING, "Chú ý", "Hashtag tối đa 50 ký tự .", 5);
            else if (hashtag[0] != "#") Notify(NOTIFY.WARNING, "Chú ý", "Hashtag phải bắt đầu bằng # .", 5);
            else {
                let hashTags = [...hashTagList, hashtag];
                setConfig({ ...config, hashTag: hashTags });
                hashtagsRef.current.value = "";
            }
        }
    };
    const handleRemoveHashtag = (item) => {
        let cf = { ...config };
        let hashTags = cf.hashTag;
        var has = hashTags.filter((value) => value !== item);
        setConfig({ ...config, hashTag: has });
    };

    const getBoosting = async (id) => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, result] = await CampaignServices.GetCampaignBoostingById(id);
        if (!err && result) {
            let pds = [];
            pds.push(result.app_Set);
            setProducts(pds);
            setConfig(result);
            setIsLoading(false);
            setMsg("");
        } else {
            setProducts([]);
            setConfig({});
            setIsLoading(false);
            setMsg("");
        }
    };
    const getProducts = async (newPaging) => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, result] = await CampaignServices.GetAppSetToBoostingPaging(newPaging);
        if (!err && result) {
            setProducts(result.listObjects);
            setPaging(result.paging);
            setIsLoading(false);
            setMsg("");
        } else {
            setProducts([]);
            setIsLoading(false);
            setMsg("");
        }
    };
    const _handleChoose = (product) => {
        let configUp = { ...config };
        configUp.setName = product.title;
        configUp.setId = product.id;
        setConfig(configUp);
    };

    //lưu dữ liệu
    const _handleSave = async () => {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, result] = await CampaignServices.SaveCampaignBoosting(config);
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", result ? result : "Lưu thành công");
                onSuccess();
                if (config.id == 0 || !config.id) {
                    onReloadDashboard();
                }
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", err.data ? err.data : CONSTANTS.MSG_ERROR);
            }
        }
    };
    const handleBasicSearch = (keyword) => {
        let newPaging = {
            ...paging,
            pageNumber: 1,
            keyword: keyword,
        };
        getProducts(newPaging);
    };

    const renderConfig = () => {
        return (
            <div className='form__element'>
                <div className='form__group'>
                    <label>Set sản phẩm</label>
                    <Textbox readOnly name='setName' className='form-control font-weight-bold' onChanged={(name, value) => {}} value={config.setName} />
                </div>
                <div className='form__group'>
                    <label>
                        Tiền bản quyền
                        <span className='sub__label'>
                            Tiền bản quyền thấp nhất là 0đ, cao nhất là&nbsp;
                            <strong>
                                <NumberFormat thousandSeparator=',' suffix='đ' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.campaignBoostingLoyalty ? dashboard.campaignBoostingLoyalty : 0} />
                            </strong>
                        </span>
                    </label>
                    <TextNumber
                        ref={addRef}
                        max={dashboard.campaignBoostingLoyalty}
                        required
                        readOnly={config.id > 0}
                        name='loyaltyAmount'
                        suffix='đồng'
                        className='form-control flex-basic-content'
                        value={config.loyaltyAmount ? config.loyaltyAmount : 0}
                        onChanged={(name, value) => setConfig({ ...config, loyaltyAmount: value })}
                    />
                </div>
                <div className='form__group'>
                    <label>Link tham khảo</label>
                    <Textbox
                        readOnly={isView}
                        name='referenceLink'
                        required
                        ref={(ref) => addRef(ref, { pattern: REGEX.URL, invalidMsg: "Link không hợp lệ. VD: ranus.vn, google.com/tin-tuc-tong-hop" })}
                        className='form-control'
                        onChanged={(name, value) => setConfig({ ...config, referenceLink: value })}
                        value={config.referenceLink}
                    />
                    <span className='sub__label'>Link tham khảo của chủ đề, giúp cho hệ thống cập nhập được chủ đề</span>
                </div>
                <div className='form__group'>
                    <label>Hashtag</label>
                    <div className='input__extend' style={{ width: "100%" }}>
                        <div className='tag-container' style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            {config &&
                                config.hashTag &&
                                config.hashTag.map((item, ind) => (
                                    <div className='hashtag__item' style={{ marginRight: "3px" }} key={ind}>
                                        {`${item}`}
                                        <span>
                                            <i className='fa fa-times-circle' aria-hidden='true' onClick={() => handleRemoveHashtag(item)}></i>
                                        </span>
                                    </div>
                                ))}
                        </div>
                        <input type='text' className='input__item w-100' ref={hashtagsRef} onKeyPress={handleHashTags} id='hashtags' autoComplete='off' />
                    </div>
                    {!isView && (
                        <React.Fragment>
                            <span className='sub__label'>Enter để thêm HashTag mới tối đa 50 ký tự, tối đa 5 hashTag</span>
                            <span className='sub__label'>Hashtag là một từ hoặc một chuỗi các kí tự liên tiếp nhau được đặt sau dấu thăng #</span>
                            <span className='sub__label'>Không cho phép có khoảng trắng, ví dụ: #lotusfashion, #lotus_fashion</span>
                        </React.Fragment>
                    )}
                </div>
                <div className='form__group'>
                    <label>Lý do có thể bán được hàng:</label>
                    <TextArea readOnly={isView} name='reason' ref={addRef} maxLength={500} className='' onChanged={(name, value) => setConfig({ ...config, reason: value })} value={config.reason} />
                </div>
            </div>
        );
    };
    const renderFooterModal = () => {
        return (
            <div className='row w-100  d-flex justify-content-end m-r-15'>
                {config && config.setId > 0 ? (
                    <BtnRounded width='130px' color={CARD_COLOR.Green} onClick={() => _handleSave()}>
                        Lưu
                    </BtnRounded>
                ) : (
                    ""
                )}
                <BtnRounded width='130px' color={CARD_COLOR.Red} onClick={() => handleModalBoosting()}>
                    Hủy
                </BtnRounded>
            </div>
        );
    };
    return (
        <BaseModal title='Cài đặt Boosting' isShow={isModal} onSave={() => _handleSave()} onClose={() => handleModalBoosting()}>
            <div className='flex__row'>
                <Box>
                    <div className='box__header flex__space-between'>
                        {config.id > 0 ? "Thông tin sản phẩm" : "Danh sách sản phẩm"}
                        <HeaderFilter handleBasicSearch={(keyword) => handleBasicSearch(keyword)} />
                    </div>
                    <div className='fixTableHead '>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "20px" }} className='fixed'>
                                        STT
                                    </th>
                                    <th style={{ width: "40px" }}>Hình ảnh</th>
                                    <th style={{ width: "50px" }}>Set sản phẩm</th>
                                    <th style={{ width: "50px" }}>Tiền bản quyền</th>
                                    <th style={{ width: "20px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {products && products.length > 0 ? (
                                    products.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: "10%" }}>{index + 1}</td>
                                            <td style={{ width: "20%" }}> {item.setAvatar ? <img src={item.setAvatar} className='variant-avatar' /> : <img src='/images/logo.png' alt='logo' />}</td>
                                            <td style={{ width: "30%" }}>
                                                <span className='product__name'>{item.title}</span> <br /> <span>Số lượng:{item.totalVariants}</span>
                                            </td>
                                            <td>
                                                <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                            </td>
                                            {config.id == 0 || !config.id ? (
                                                <td style={{ width: "10%" }}>
                                                    <div className='flex__center'>
                                                        <BtnRounded color='#4caf50' onClick={() => _handleChoose(item)}>
                                                            Chọn
                                                        </BtnRounded>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5}>Không có dữ liệu</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <TableFooter itemToDisplay={2} onFilter={(paging) => getProducts(paging)} paging={paging} />
                </Box>
                <Box>
                    <div className='box__header'>Cấu hình thông tin.</div>
                    <Box boxShadow='none'>{renderConfig()}</Box>
                </Box>
            </div>
            <Box>
                <div className='box__header'>Mức tiền bản quyền &amp; Ranus Charge</div>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: "30%" }}>Mức Charge</th>
                            <th style={{ width: "30%" }}>Mức Charge </th>
                            <th style={{ width: "30%" }}>Ranus Charge(&#37;)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {readRateCard && readRateCard.length > 0 ? (
                            readRateCard.map((item, k) => {
                                return (
                                    <tr key={k}>
                                        <td className='text-center'>{k + 1}</td>
                                        <td className='text-center'>
                                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                        </td>
                                        <td className='text-center'>{item.lotusChargePercent}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan='3' className='text-center'>
                                    <span>Chưa có cấu hình</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Box>
        </BaseModal>
    );
};

export default SetupModal;
