// state = {
//   isLoading: boolean,
//   isError: boolean,
//   error: any,
//   data: any,
// };

export const fetchBegin = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSuccess = (state, updatedData) => ({
  ...state,
  isLoading: false,
  data: updatedData,
});

export const fetchFail = (state, err) => ({
  ...state,
  isLoading: false,
  isError: true,
  error: err,
});
