import React from "react";
import PropTypes from "prop-types";

import Textbox from "./Textbox";
import { CONSTANTS } from "../Constants";

const PhoneNumber = (props) => (
    <div className='input-group'>
        <div className='input-group-prepend'>
            <span className='input-group-text'>+84</span>
        </div>
        <Textbox
            id={props.id}
            name={props.name}
            className='form-control input-phone'
            number
            ref={props.addRef}
            required={props.required ? props.required : false}
            minLength={9}
            maxLength={11}
            value={props.value}
            onChanged={(name, value) => {
                if (value === "" || (value && value.length <= 11 && CONSTANTS.PHONE_REGEX.test(value))) props.onChanged(name, value);
            }}
            readOnly={props.readOnly || false}
            placeholder={props.placeholder ? props.placeholder : null}
        />
    </div>
);

PhoneNumber.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    addRef: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChanged: PropTypes.func.isRequired,
};

export default PhoneNumber;
