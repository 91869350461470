import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ChatUserService from "../based/services/ChatUserService";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import Request from "../based/Request";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import styled from "styled-components";


function ModalAddOrUpdate(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [user, setUser] = useState({})
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(()=>{
        setUser({...props.user})
    },[])

    
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await ChatUserService.UpdateChatUserDetail({ ...user});
            if (!err && data == 1) {
                setIsBusy(false);
                setUser({});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
       
    };

    return ( 
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    FirstName
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control'
                 onChanged={(name, value) => setUser({ ...user, firstName: value })} 
                 value={user.firstName} />
                 <label htmlFor='name' className='required'>
                    LastName
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control'
                 onChanged={(name, value) => setUser({ ...user, lastName: value })} 
                 value={user.lastName} />
            </div>
        </CommonModal>
    );
}

export default ModalAddOrUpdate;
ModalAddOrUpdate.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};