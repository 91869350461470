import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ColorPicker from "../based/ColorPicker";
import Common from "../based/Common";
import { ATTRIBUTE_INPUT_TYPE, CONSTANTS, INITPAGING, NOTIFY, PLATFORM, PLATFORM_NAME, PRODUCT_COLOR_TYPE } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import TFUTooltip from "../based/TFUTooltip";
import CheckBox from "../based/inputs/CheckBox";
import Selection from "../based/inputs/Selection";
import TFUSelect from "../based/inputs/TFUSelect";
import TextNumber from "../based/inputs/TextNumber";
import Textbox from "../based/inputs/Textbox";
import AttributeServices from "../based/services/AttributeServices";
import { ConfigAttributeModalContext, SubPrintBrandStampModel } from "../context/ConfigAttributeModalContext";
import useFormValidate from "../hooks/useFormValidate";
import PrintFrameConfig from "./components/PrintFrameConfig";

const PLATFORM_OPTIONS = [
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Sendo, label: PLATFORM_NAME.Sendo },
];

const CODE_OPTION_ID = {
    Color: 1,
    Size: 2,
    Sex: 3,
    Style: 4,
};

const CODE_OPTION_NAME = {
    Color: "Color",
    Size: "Size",
    Sex: "Sex",
    Style: "Style",
};

const NAME_OPTIONS = [
    { value: CODE_OPTION_ID.Color, label: "Color (Màu)", code: CODE_OPTION_NAME.Color },
    { value: CODE_OPTION_ID.Size, label: "Size (Kích thước)", code: CODE_OPTION_NAME.Size },
    { value: CODE_OPTION_ID.Sex, label: "Sex (Giới tính)", code: CODE_OPTION_NAME.Sex },
    { value: CODE_OPTION_ID.Style, label: "Style (Kiểu dáng)", code: CODE_OPTION_NAME.Style },
];

const PRODUCT_COLOR_TYPE_OPTIONS = Object.keys(PRODUCT_COLOR_TYPE).map((item) => {
    return {
        value: PRODUCT_COLOR_TYPE[item],
        text: item,
        label: item,
    };
});

export default function ConfigAttributeModal({ isShowModal, onSaveSucceed, onClose, ...props }) {
    const history = useHistory();
    const { addRef, displayError, isValid } = useFormValidate();
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [attribute, setAttribute] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryPaging, setCategoryPaging] = useState({ ...INITPAGING });
    const [attributesOptions, setAttributesOptions] = useState([]);
    const [printFrame, setPrintFrame] = useState([]);

    const nameTFUSelect = useMemo(() => {
        if (attribute) {
            let item = NAME_OPTIONS.find((x) => x.code == attribute.code);
            return item ? item.value : null;
        }
        return null;
    }, [attribute]);

    useEffect(() => {
        if (isShowModal) _getCategories({ ...categoryPaging });
        if (props.attribute && props.attribute.id > 0) {
            getById(props.attribute.id);
        } else {
            setAttribute(props.attribute);
        }
    }, [isShowModal]);

    /** lấy danh sách ngành hàng theo paging */
    function _getCategories(paging) {
        Request.Post("/api/category/get-child-dropdown", paging)
            .then((res) => {
                if (res.success) {
                    setCategoryOptions(res.data.listObjects);
                    setCategoryPaging(res.data.paging);
                } else {
                    setCategoryOptions([]);
                    setCategoryPaging(paging);
                }
            })
            .catch((err) => {
                setCategoryOptions([]);
                setCategoryPaging(paging);
            });
    }

    /** lấy thông tin thuộc tính theo id */
    async function getById(id) {
        setIsModalLoading(true);
        setMsgModalLoading("Đang tải dữ liệu...");
        const [err, att] = await AttributeServices.GetAttributesById(id);
        if (!err) {
            _handleChangedCategory(att.categoryId, att);
            _getCategories({ ...categoryPaging, keyword: att.categoryName });
            setIsModalLoading(false);
            setMsgModalLoading("");
            setPrintFrame(att.printFrameConfigs);
        } else {
            let attributeUp = {};
            setAttribute(attributeUp);
            setIsModalLoading(false);
            setMsgModalLoading("");
            Notify(NOTIFY.ERROR, "Lỗi", err.message ? err.message : "Đã có lỗi xảy ra trong quá trình xử lý dữ liệu");
            setPrintFrame([]);
        }
    }

    /** validate thông tin trước khi save */
    const ensureData = (att) => {
        if (att.isPrintFrameConfig) {
            att.printFrameConfigs = [...printFrame];
            return att;
        }
        return att;
    };

    /** handle save data */
    function _handleSave(isContinue) {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu...");
            let att = ensureData({ ...attribute });
            Request.Post("/api/attribute/save", { ...att })
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                        onSaveSucceed(isContinue);
                        if (isContinue) setAttribute(props.attribute);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                    }
                })
                .catch((err) => {
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                    if (err.errors) {
                        displayError(err.errors);
                    }
                });
        }
    }

    /** handle thay đổi thông tin code */
    function _handleChangedCode(value) {
        //config code
        if (value) {
            let attributeOld = { ...attribute };
            if (!Common.isValidCode(value)) value = attributeOld.code;
        }
        //config thông tin thuộc tính
        if (value == "Color") {
            _handleChangedDataType(ATTRIBUTE_INPUT_TYPE.COLOR_PICKER, value);
        } else {
            _handleChangedDataType(ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT, value);
        }
    }

    /** handle thay đổi thông tin thuộc tính */
    function _handleChangedInput(index, value, name) {
        let currentModel = { ...attribute };
        if (currentModel.attributeValues && currentModel.attributeValues.length > 0) {
            currentModel.attributeValues[index][name] = value;
        }
        setAttribute(currentModel);
    }

    /** handle thêm màu */
    function _handleAddColor() {
        let currentModel = { ...attribute };
        if (!currentModel.attributeValues) currentModel.attributeValues = [];
        currentModel.attributeValues.push({
            id: 0,
            name: "#000000",
            displayName: "Màu đen",
        });
        setAttribute(currentModel);
    }

    /** handle bỏ màu */
    function _handleRemoveColor(index) {
        let currentModel = { ...attribute };
        if (currentModel.attributeValues && currentModel.attributeValues.length > 0) currentModel.attributeValues.splice(index, 1);
        setAttribute(currentModel);
    }

    /** handle thêm size */
    function _handleAddRow() {
        let randomInt = Common.generateGuid();

        //add thuộc tính
        let currentModel = { ...attribute };
        if (!currentModel.attributeValues) currentModel.attributeValues = [];
        currentModel.attributeValues.push({
            id: 0,
            name: "",
            displayName: "",
            compareId: randomInt,
        });
        setAttribute(currentModel);

        //add thông tin khung in
        let frame = [...printFrame];
        let newModel = new SubPrintBrandStampModel();
        newModel.id = 0;
        newModel.attributeValueName = "";
        newModel.compareId = randomInt;
        frame.push(newModel);
        setPrintFrame(frame);
    }

    /** handle bỏ size */
    function _handleRemoveRow(index) {
        //remove thuộc tính
        let currentModel = { ...attribute };
        let randomInt = "";
        if (currentModel.attributeValues && currentModel.attributeValues.length > 0) {
            let item = currentModel.attributeValues[index];
            randomInt = item.compareId;
            currentModel.attributeValues.splice(index, 1);
        }
        setAttribute(currentModel);

        //remove frame config
        let frame = [...printFrame];
        if (frame && frame.length > 0) {
            let item = frame.find((x) => x.compareId == randomInt);
            if (item) {
                frame.splice(frame.indexOf(item), 1);
            }
        }
        setPrintFrame(frame);
    }

    /** render thông tin thuộc tính */
    function _renderAttributeValue() {
        const currentModel = { ...attribute };
        switch (currentModel.dataType) {
            case ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT:
                return (
                    <React.Fragment>
                        <div className="d-flex">
                            <label className="col-md-4 text-left required">Tên thuộc tính</label>
                            <label className="col-md-6 text-left required">Tên hiển thị thuộc tính</label>
                            <div className="col-md-2 text-center">
                                <button
                                    type="button"
                                    className="btn btn-xs btn-custom"
                                    onClick={() => {
                                        _handleAddRow();
                                    }}
                                >
                                    <i className="mdi mdi-plus"></i>
                                </button>
                            </div>
                        </div>
                        {currentModel && currentModel.attributeValues && currentModel.attributeValues.length > 0
                            ? currentModel.attributeValues.map((item, index) => {
                                  return (
                                      <div className="d-flex" key={index}>
                                          <div className="col-md-4">
                                              <Textbox
                                                  name="name"
                                                  value={item.name}
                                                  onChanged={(name, value) => {
                                                      // update thuộc tính
                                                      _handleChangedInput(index, value, "name");

                                                      // update value name trong frame config
                                                      let frame = [...printFrame];
                                                      if (frame && frame.length > 0) {
                                                          let findItem = frame.find((x) => x.compareId == item.compareId);
                                                          if (findItem) {
                                                              findItem.attributeValueName = value;
                                                          }
                                                      }
                                                      setPrintFrame(frame);
                                                  }}
                                              />
                                          </div>
                                          <div className="col-md-6">
                                              <Textbox
                                                  name="displayName"
                                                  value={item.displayName}
                                                  onChanged={(name, value) => {
                                                      _handleChangedInput(index, value, "displayName");
                                                  }}
                                              />
                                          </div>
                                          <div className="form-group col-md-2 text-center">
                                              <i
                                                  className="mdi mdi-24px mdi-delete text-danger cursor-pointer"
                                                  onClick={() => {
                                                      _handleRemoveRow(index);
                                                  }}
                                              ></i>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </React.Fragment>
                );
            case ATTRIBUTE_INPUT_TYPE.COLOR_PICKER:
                return (
                    <React.Fragment>
                        <div className="d-flex">
                            <label className="col-md-3 text-left required">Mã màu</label>
                            <label className="col-md-4 text-left required">Tên màu</label>
                            <label className="col-md-4 text-left required">Loại màu</label>
                            <div className="col-md-1 text-center">
                                <button type="button" className="btn btn-xs btn-custom" onClick={() => _handleAddColor()}>
                                    <i className="mdi mdi-plus"></i>
                                </button>
                            </div>
                        </div>
                        {currentModel && currentModel.attributeValues && currentModel.attributeValues.length > 0
                            ? currentModel.attributeValues.map((item, index) => {
                                  return (
                                      <div className="d-flex" key={index}>
                                          <div className="col-md-3 ">
                                              <ColorPicker id="colorPicker" name="colorPicker" value={item.name} onChanged={(value) => _handleChangedInput(index, value, "name")} />
                                          </div>
                                          <div className="col-md-4 ">
                                              <Textbox name="displayName" value={item.displayName} onChanged={(name, value) => _handleChangedInput(index, value, "displayName")} />
                                          </div>
                                          <div className="col-md-4 ">
                                              <Selection
                                                  options={PRODUCT_COLOR_TYPE_OPTIONS}
                                                  onChanged={(newValue) => _handleChangedInput(index, +newValue, "productColorType")}
                                                  name="productColorType"
                                                  value={item.productColorType}
                                                  placeholder="--Chọn mã màu--"
                                              />
                                          </div>
                                          <div className="form-group col-md-1 text-center">
                                              <i className="mdi mdi-24px mdi-delete text-danger cursor-pointer" onClick={() => _handleRemoveColor(index)}></i>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </React.Fragment>
                );
            default:
                return null;
        }
    }

    /** thay đổi thông tin thuộc tính input */
    function _handleChangedDataType(value, codeValue) {
        let currentModel = { ...attribute };
        currentModel.dataType = value;
        if (codeValue) currentModel.code = codeValue;
        if (value == ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT)
            //SingleSelect
            currentModel.attributeValues = [];
        if (codeValue == "Size") currentModel.isPrintFrameConfig = true;
        else if (value == ATTRIBUTE_INPUT_TYPE.COLOR_PICKER) {
            //ColorPicker
            let attributeValuesUpdate = [];
            attributeValuesUpdate.push({
                id: 0,
                name: "#000000",
                displayName: "Màu đen",
            });
            currentModel.attributeValues = attributeValuesUpdate;
        }
        setAttribute(currentModel);
    }

    /** handle thay đổi ngành hàng */
    async function _handleChangedCategory(categoryId, attributeUpdate) {
        setIsModalLoading(true);
        setMsgModalLoading("Đang tải dữ liệu...");
        const [err, datas] = await AttributeServices.GetOpfAttributesByLotusCategory(categoryId);
        if (!err) {
            let attributeUp = attributeUpdate ? attributeUpdate : { ...attribute };
            attributeUp.categoryId = categoryId;
            setAttributesOptions(datas);
            setAttribute(attributeUp);
            setIsModalLoading(false);
            setMsgModalLoading("");
        } else {
            let attributeUp = attributeUpdate ? attributeUpdate : { ...attribute };
            attributeUp.categoryId = categoryId;
            setAttributesOptions([]);
            setAttribute(attributeUp);
            setIsModalLoading(false);
            setMsgModalLoading("");
            Notify(NOTIFY.ERROR, "Lỗi", err.message ? err.message : "Đã có lỗi xảy ra trong quá trình xử lý dữ liệu");
        }
    }

    /** handle thay đổi thuộc tính ngành hàng */
    function _handleChangedOptionAttribute(value, platform) {
        if (attributesOptions && attributesOptions.length > 0) {
            let attributeUp = { ...attribute };
            if (!attributeUp.opfAttributes || attributeUp.opfAttributes.length == 0) attributeUp.opfAttributes = [];
            let curOPF = attributeUp.opfAttributes.filter((x) => x.platform == platform);
            if (curOPF && curOPF.length > 0) {
                curOPF[0].id = value;
                curOPF[0].lotusAttributeId = attributeUp.id;
            } else {
                let obj = {
                    id: value,
                    platform: platform,
                    lotusAttributeId: attributeUp.id,
                };
                attributeUp.opfAttributes.push(obj);
            }

            setAttribute(attributeUp);
        }
    }

    return attribute ? (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            size="tfulg"
            busyMsg={msgModalLoading}
            title={attribute.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave(false)}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                    {attribute.id > 0 ? null : (
                        <button className="btn btn-outline-primary btn-sm" onClick={() => _handleSave(true)}>
                            <i className="fa fa-save m-r-5" /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <div className="form-row">
                <div className="col-md-6">
                    <div className="card card-body" style={{ paddingTop: 5 }}>
                        <h5 className="card-title">THÔNG TIN THUỘC TÍNH</h5>
                        <div className="form-group">
                            <label htmlFor="categoryId required">Ngành hàng:</label>
                            <TFUSelect
                                name="categoryId"
                                options={categoryOptions}
                                value={attribute.categoryId}
                                placeholder="-- Chọn ngành hàng --"
                                onChanged={(value) => _handleChangedCategory(value)}
                                handleInputChange={(e) => {
                                    _getCategories({ ...categoryPaging, keyword: e });
                                }}
                            />
                        </div>
                        <div className="form-group  ">
                            <label htmlFor="code" className="required">
                                Code:
                            </label>
                            <TFUTooltip
                                content=" 
                            - Code phải là duy nhất<br/>
                            - Bao gồm các chữ số a-zA-Z0-9 và dấu _"
                                target="quesCode"
                            >
                                <i className="fa fa-question-circle" id="quesCode"></i>
                            </TFUTooltip>
                            <TFUSelect
                                id="code"
                                name="code"
                                required
                                value={nameTFUSelect}
                                ref={addRef}
                                options={NAME_OPTIONS}
                                isDisabled={attribute.id > 0}
                                onChanged={(newVal) => {
                                    let option = NAME_OPTIONS.find((x) => x.value == newVal);
                                    _handleChangedCode(option.code);
                                }}
                            />
                        </div>
                        <div className="form-group ">
                            <label htmlFor="name" className="required">
                                Tên thuộc tính:
                            </label>
                            <Textbox name="name" required ref={addRef} maxLength={255} className="form-control" onChanged={(name, value) => setAttribute({ ...attribute, name: value })} value={attribute.name} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dataType" className="required">
                                Kiểu dữ liệu:
                            </label>
                            <TFUSelect
                                id="dataType"
                                name="dataType"
                                value={attribute.dataType}
                                onChanged={(newVal) => _handleChangedDataType(newVal)}
                                isDisabled={true}
                                options={[
                                    { value: 2, label: "SingleSelect" },
                                    { value: 10, label: "ColorPicker" },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="index">Thứ tự hiển thị:</label>
                            <TextNumber id="index" className="form-control" onChanged={(name, value) => setAttribute({ ...attribute, index: value })} value={attribute.index} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Mô tả:</label>
                            <textarea className="form-control" rows="2" value={attribute.description} onChange={(e) => setAttribute({ ...attribute, description: e.target.value })} />
                        </div>
                        <div className="form-group">
                            <CheckBox
                                className="form-control"
                                name="isPrintFrameConfig"
                                checked={attribute.isPrintFrameConfig}
                                onCheckedChange={(name, value) => {
                                    setAttribute({ ...attribute, isPrintFrameConfig: value });
                                }}
                                label="Thiết lập in ấn"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card card-body" style={{ paddingTop: 5 }}>
                        <h5 className="card-title">GIÁ TRỊ THUỘC TÍNH</h5>
                        <div className="form-group">{_renderAttributeValue()}</div>
                    </div>
                    <div className="card card-body" style={{ paddingTop: 5, marginTop: 10 }}>
                        <h5 className="card-title">THUỘC TÍNH TRÊN SÀN TMĐT</h5>
                        <div className="form-group">
                            {attribute.categoryId == 0 ? (
                                <React.Fragment>
                                    <strong className="text-pink m-r-5">Chưa được mapping với ngành hàng của sàn TMĐT</strong>
                                    <a
                                        className="text-info"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push("/category");
                                        }}
                                    >
                                        <i className="fa fa-external-link"></i>
                                    </a>
                                </React.Fragment>
                            ) : (
                                PLATFORM_OPTIONS.map((item, k) => {
                                    let options = [];
                                    let opfAttributeId = 0;
                                    if (attributesOptions && attributesOptions.length > 0) {
                                        let currentOptions = attributesOptions.filter((x) => x.platform == item.value);
                                        if (currentOptions && currentOptions.length > 0) currentOptions.map((op) => options.push({ value: op.id, label: op.name, lotusAttributeId: op.lotusAttributeId }));
                                    }
                                    if (attribute.opfAttributes && attribute.opfAttributes.length > 0) {
                                        let current = attribute.opfAttributes.find((x) => x.platform == item.value);
                                        if (current) opfAttributeId = current.id;
                                    }
                                    return (
                                        <div className="form-group" key={k}>
                                            <label htmlFor="opfAttributeId">Thuộc tính trên {item.label}:</label>
                                            {options && options.length > 0 ? (
                                                <TFUSelect name="opfAttributeId" options={options} value={opfAttributeId} placeholder="-- Chọn thuộc tính --" onChanged={(value) => _handleChangedOptionAttribute(value, item.value)} />
                                            ) : (
                                                <p>
                                                    <strong className="text-pink m-r-5">Chưa được mapping với ngành hàng TMĐT hoặc chưa đồng bộ thuộc tính</strong>
                                                    <a
                                                        className="text-info"
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            history.push("/category");
                                                        }}
                                                    >
                                                        <i className="fa fa-external-link"></i>
                                                    </a>
                                                </p>
                                            )}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${attribute.isPrintFrameConfig ? "" : "d-none"}`}>
                    <ConfigAttributeModalContext.Provider value={{ attributeId: attribute.id, printFrame, setPrintFrame }}>
                        {attribute.dataType === ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT && attribute.isPrintFrameConfig && (
                            <div className="col-md-12 mt-3">
                                <div className="card card-body">
                                    <h5 className="card-title">
                                        IN ẤN - VỊ TRÍ ARTWORK <small>(mm)</small>
                                    </h5>
                                    <p className="sub-header">
                                        Notes:
                                        <br />
                                        - Config vị trí đặt artwork theo thông số với thuộc tính kích thước
                                        <br />- Cài đặt kích thước tiêu chuẩn với <b>tỉ lệ 1</b>
                                        <br />
                                        - Kích thước tiêu chuẩn là kích thước dùng để làm chuẩn để tiến hành tạo mockup
                                        <br />
                                    </p>
                                    <div className="form-group">
                                        <PrintFrameConfig addRef={addRef} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </ConfigAttributeModalContext.Provider>
                </div>
            </div>
        </CommonModal>
    ) : null;
}
