import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-currency-format";
import TagsInput from "react-tagsinput";
import Autocomplete from "../based/AutoComplete";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import TextEditor from "../based/inputs/TextEditor";
import TextNumber from "../based/inputs/TextNumber";
import TFUSelect from "../based/inputs/TFUSelect";
import { Notify } from "../based/Notify";
import BaseModal from "../based/refactor/BaseModal";
import Button, { ButtonType } from "../based/refactor/Button";
import CommonTable from "../based/refactor/CommonTable";
import ArtworkServices from "../based/services/ArtworkServices";
import BrandServices from "../based/services/BrandServices";
import PrintServices from "../based/services/PrintServices";
import TopicServices from "../based/services/TopicServices";
import useFormValidate from "../hooks/useFormValidate";
import { DesignType } from "../manage-agency-product/modal/preadd-components/PreAddArtworkInfo";

export default function UpdateArtworkInfo(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [artwork, setArtwork] = useState({});

    async function handleSave() {
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");
        let [error, data] = await ArtworkServices.AdditionalInfo(artwork);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            props.onSaveSucceed();
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật thông tin thành công");
        } else {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.ERROR, "Lỗi", error.data);
        }
    }
    return (
        <BaseModal
            title='Cập nhật thiết kế'
            isShow={props.isModal}
            onClose={() => {
                props.onClose();
            }}
            hideClose
        >
            <UpdateArtworkInfoInside isShow={props.isShow} artworkId={props.artwork.id} appSetId={props.artwork.appSetId} setArtwork={(values) => setArtwork(values)} />
            <div className='col-md-12 d-flex justify-content-end mt-10 pt-5 mb-10 '>
                <Button
                    btnType={ButtonType.Rounded}
                    title='Hoàn tất'
                    color='#28B259'
                    className='mr-3'
                    onClick={() => {
                        ConfirmDialog(
                            "Thông tin",
                            'Sau khi bạn tiến hành lưu/cập nhập thông tin. Nội dung thiết kế sẽ được kiểm tra bởi hệ thống. Thiết kế sẽ ở trạng thái "CHỜ DUYỆT TỔNG THỂ"',
                            () => {
                                return new Promise((resolve, reject) => {
                                    handleSave();
                                    resolve({ title: "Thành công", msg: "Lưu thành công." });
                                });
                            },
                            true
                        );
                    }}
                />
                <Button btnType={ButtonType.Rounded} color='#F95D55' onClick={() => props.onClose()} fontSize='14px' title='&ensp;Hủy&ensp;' />
            </div>
        </BaseModal>
    );
}

export function UpdateArtworkInfoInside(props) {
    const [artwork, setArtwork] = useState({});
    const [readRateCard, setReadRateCard] = useState([]);
    const [artworkBrands, setArtworkBrands] = useState([]);
    const [topics, setTopics] = useState([]);
    const [topicPaging, setTopicPaging] = useState({ ...INITPAGING });
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [productColorCode, setProductColorCode] = useState([]);
    const [topTopics, setTopTopics] = useState([]);
    const [designType, setDesignType] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const { readOnly } = props;
    const autoSuggestRef = useRef();
    useEffect(() => {
        let artworkId = props.artworkId;
        if (artworkId) getDatas(artworkId);
        getReadRateCard();
        if(!artworkId) getBrand();
        getTopTopic();
    }, [props.artworkId]);

    useEffect(() => {
        if (props.appSetId) getAppProductColorCode(props.appSetId, props.artworkId);
    }, [props.appSetId]);

    useEffect(() => {
        if (props.setArtwork) props.setArtwork({ ...artwork });
    }, [artwork]);

    async function getAppProductColorCode(setId, ageSetId) {
        let [err, data] = await ArtworkServices.GetAppProductCodeModels(setId, ageSetId);
        if (!err && data) {
            setProductColorCode(data);
        } else setProductColorCode([]);
    }
    async function getTopTopic() {
        setIsLoading(true);
        let [err, data] = await TopicServices.GetTop10Topic();
        if (!err && data && data.length > 0) setTopTopics(data);
        else setTopTopics([]);
        setIsLoading(false);
    }
    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
  
    async function getDatas(id) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetInfoToComplement(id);
        if (!error && data) {
            setArtwork({ ...data, totalLoyaltyPrice: data.loyaltyAmount - data.lotusChargeAmount });
            setIsLoading(false);
            console.log(data);
            if(props.artworkId) getBrand(data);
            setMsg(null);
        } else {
            setArtwork({});
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function getBrand(artwork=null) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await BrandServices.GetDropdownApprovedBrand({ ...INITPAGING });
        if (!err && data) {
            if(artwork){
                data.map((item)=>{
                    if(item.value!=artwork.brandId){
                        data.push({text:artwork.brandName,value:artwork.brandId,label:artwork.brandName})
                    }
                })   
            }
            setArtworkBrands(data); console.log("brand",data)
        }
        else setArtworkBrands([]);
        setIsLoading(false);
        setMsg(null);
    }

    async function _getTopic(paging) {
        let [err, data] = await TopicServices.GetDropdownTopic(paging);
        if (!err && data) setTopics(data.listObjects);
        else setTopics([]);
    }

    const renderHeader = () => {
        return (
            <tr>
                <th>Mức charge</th>
                <th>Mức tiền bản quyền (đồng)</th>
                <th>Ranus Operation Fee (%)</th>
            </tr>
        );
    };

    const renderBody = () => {
        if (readRateCard.length > 0) {
            return readRateCard.map((item, k) => {
                return (
                    <tr key={k}>
                        <td className='text-center'>{k + 1}</td>
                        <td className='text-center'>
                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                        </td>
                        <td className='text-center'>{item.lotusChargePercent}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan='3'>
                        <span>Chưa có cấu hình</span>
                    </td>
                </tr>
            );
        }
    };

    function setAmount(amount) {
        if (amount > 0 && readRateCard && readRateCard.length > 0) {
            let total = 0;
            let tmp = 0;
            for (let index = 0; index < readRateCard.length; index++) {
                if (amount >= readRateCard[index].loyaltyAmount) {
                    total += (readRateCard[index].loyaltyAmount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    tmp = readRateCard[index].loyaltyAmount;
                } else {
                    total += (amount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    break;
                }
            }
            setArtwork({ ...artwork, totalLoyaltyPrice: total, lotusChargeAmount: amount - total, loyaltyAmount: amount });
        } else {
            setArtwork({ ...artwork, totalLoyaltyPrice: 0, lotusChargeAmount: 0, loyaltyAmount: 0 });
        }
    }
    return (
        <div className='form-row col-md-12'>
            <div className='col-md-8 row'>
                <div className='form-group col-md-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label className='col-form-label required' htmlFor='title'>
                                Tên set thiết kế (Tiếng Việt)
                            </label>
                            <Textbox maxLength={255} name='title' readOnly={readOnly} value={artwork.title} required ref={addRef} placeholder='--Tên thiết kế--' onChanged={(name, value) => setArtwork({ ...artwork, title: value })} />
                        </div>
                        <div className='col-md-4'>
                            <label className='col-form-label required' htmlFor='titleEN'>
                                Tên set thiết kế (Tiếng Anh)
                            </label>
                            <Textbox maxLength={255} name='titleEN' readOnly={readOnly} value={artwork.titleEN} required ref={addRef} placeholder='--Tên thiết kế (ENG)--' onChanged={(name, value) => setArtwork({ ...artwork, titleEN: value })} />
                        </div>
                        <div className='col-md-4'>
                            <label className='col-form-label required' htmlFor='brandId'>
                                Thương hiệu
                            </label>
                            <TFUSelect name='brandId' isDisabled={readOnly} value={artwork.brandId} required ref={addRef} options={artworkBrands} placeholder='---Thương hiệu---' onChanged={(value) => setArtwork({ ...artwork, brandId: value })} />
                        </div>
                    </div>
                </div>
                <div className='form-group col-md-4'>
                    <label className='col-form-label required' htmlFor='advantages'>
                        Màu sắc hình ảnh hiển thị
                    </label>
                    <div className='form-group'>
                        {productColorCode && productColorCode.length > 0
                            ? productColorCode.map((item, idx) => {
                                  return (
                                      <React.Fragment key={idx}>
                                          <label>{item.productName}</label>
                                          <div className='colors'>
                                              {item.appProductCodes.map((element, index) => {
                                                  return (
                                                      <div
                                                          className={`color-item pull-left ${element.appProductVariantId == artwork.appProductVariantId ? "active" : ""}`}
                                                          onClick={(e) => {
                                                              setArtwork({ ...artwork, appProductVariantId: element.appProductVariantId });
                                                          }}
                                                          key={index}
                                                      >
                                                          <div style={{ backgroundColor: element.colorCode }}></div>
                                                      </div>
                                                  );
                                              })}
                                          </div>
                                      </React.Fragment>
                                  );
                              })
                            : null}
                    </div>
                </div>
                <div className='form-group col-md-8' ref={autoSuggestRef}>
                    <label className='col-form-label'>Phong cách thiết kế</label>
                    <div className='form-row '>
                        {topTopics.map((item, idx) => {
                            return (
                                <CheckBox
                                    key={idx}
                                    classDiv='col-md-3 my-2'
                                    name={"topicName"}
                                    label={item.topicName}
                                    checked={artwork.topic && artwork.topic.includes(item.topicName)}
                                    onCheckedChange={(name, value) => {
                                        let artworkModel = { ...artwork };
                                        let topic = artworkModel.topic || [];
                                        //nếu true, add vào topic
                                        //tối đa được 3 tag
                                        if (value) {
                                            let lengthTopic = topic.length;
                                            if (lengthTopic >= 3) Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 3 phong cách thiết kế");
                                            else {
                                                topic.push(item.topicName);
                                                setArtwork({ ...artworkModel, topic : topic });
                                            }
                                        }
                                        //nếu false, remove ra khỏi topic
                                        else {
                                            let indexOf = topic.indexOf(item.topicName);
                                            if (indexOf >= 0) topic.splice(indexOf, 1);
                                            setArtwork({ ...artworkModel });
                                        }
                                    }}
                                />
                            );
                        })}

                        <span className='col-md-4 my-2 text-success cursor-pointer' onClick={(e) => setDesignType(true)}>
                            Xem thêm
                        </span>
                    </div>
                    <div className=' d-flex pt-1'>
                        {artwork.topic && artwork.topic.length > 0
                            ? artwork.topic.map((item, idx) => (
                                  <div key={idx} className='d-flex justify-content-between align-items-center p-1  m-2'>
                                      <span className='d-inline-block text-truncate mr-2 pt-1' style={{ maxWidth: "120px" }}>
                                          {item}
                                      </span>
                                      <i
                                          className='fa fa-times-circle cursor-pointer text-danger pb-2'
                                          aria-hidden='true'
                                          onClick={() => {
                                              let artworkModel = { ...artwork };
                                              let topic = artworkModel.topic;
                                              let indexOf = topic.indexOf(item);
                                              if (indexOf >= 0) topic.splice(indexOf, 1);
                                              setArtwork(artworkModel);
                                          }}
                                      ></i>
                                  </div>
                              ))
                            : ""}
                    </div>
                    {/* <Autocomplete
                        currRef={autoSuggestRef}
                        readOnly={readOnly}
                        suggestion={topics}
                        tags={artwork.topic || []}
                        onChange={(tag) => {
                            setArtwork({ ...artwork, topic: tag });
                        }}
                        resetSuggestion={() => {
                            setTopics([]);
                        }}
                        onFetch={(value) => {
                            let paging = { ...topicPaging };
                            paging.keyword = value;
                            _getTopic(paging);
                        }}
                    /> */}
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label required' htmlFor='content'>
                        Câu chuyện thiết kế (Tiếng Việt)
                    </label>
                    <TextEditor readOnly={readOnly} required ref={addRef} name='content' className='form-control full-width' html={artwork.content ? artwork.content : null} onChanged={(name, value) => setArtwork({ ...artwork, content: value })} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label required' htmlFor='contentEN'>
                        Câu chuyện thiết kế (Tiếng Anh)
                    </label>
                    <TextEditor readOnly={readOnly} className='form-control full-width' required ref={addRef} name='contentEN' html={artwork.contentEN} onChanged={(name, value) => setArtwork({ ...artwork, contentEN: value })} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label'>Tag</label>
                    <TagsInput
                        disabled={readOnly}
                        addKeys={[13]}
                        addOnPaste
                        inputProps={{ placeholder: "" }}
                        value={artwork.tag || []}
                        onChange={(tags) => {
                            let newTags = tags.reduce((prev, val, idx) => (prev.indexOf(val) == -1 ? [...prev, val] : [...prev]), []);
                            setArtwork({ ...artwork, tag: newTags });
                        }}
                    />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label'>SEO Meta Title</label>
                    <Textbox readOnly={readOnly} name='seoMetaTitle' value={artwork.seoMetaTitle} onChanged={(name, value) => setArtwork({ ...artwork, seoMetaTitle: value })} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label'>SEO Meta Description</label>
                    <TextArea readOnly={readOnly} name='seoMetaDescription' value={artwork.seoMetaDescription} onChanged={(name, value) => setArtwork({ ...artwork, seoMetaDescription: value })} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label'>SEO Meta Keywords</label>
                    <Textbox readOnly={readOnly} name='seoMetaKeywords' value={artwork.seoMetaKeywords} onChanged={(name, value) => setArtwork({ ...artwork, seoMetaKeywords: value })} />
                </div>
            </div>
            <div className='col-md-4'>
                <div className='form-group col-md-12'>
                    <label className='col-form-label' htmlFor='config'>
                        Thông tin cấu hình mức Charge tiền bản quyền
                    </label>
                    <CommonTable renderHeader={renderHeader} renderBody={renderBody} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label required' htmlFor='loyaltyAmount'>
                        Tiền bản quyền
                    </label>
                    <TextNumber readOnly={readOnly} required ref={addRef} name='loyaltyAmount' className='form-control' suffix=' đ' value={Number(artwork.loyaltyAmount) || 0} min={0} max={999999999} onChanged={(name, value) => setAmount(value)} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label' htmlFor='lotusChargeAmount'>
                        Phí hoạt động Ranus
                    </label>
                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} suffix=' đ' value={artwork.lotusChargeAmount} className='form-control' style={{ background: "#e9ecef" }} />
                </div>
                <div className='form-group col-md-12'>
                    <label className='col-form-label' htmlFor='totalLoyaltyPrice'>
                        Tiền bản quyền thực lĩnh
                    </label>
                    <NumberFormat
                        thousandSeparator=','
                        displayType={"text"}
                        decimalSeparator='.'
                        decimalScale={2}
                        suffix=' đ'
                        value={artwork.totalLoyaltyPrice > 0 ? artwork.totalLoyaltyPrice : 0}
                        className='form-control'
                        style={{ background: "#e9ecef" }}
                    />
                </div>
            </div>
            <DesignType
                isModal={designType}
                onClose={() => setDesignType(false)}
                topics={artwork.topic}
                setTopics={(topics) => {
                    let artworkModel = { ...artwork };
                    artworkModel.topic = topics;
                    setArtwork(artworkModel);
                }}
            />
        </div>
    );
}

UpdateArtworkInfoInside.propTypes = {};
