import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../based/Breadcrumbs";
import { CONSTANTS, NOTIFY } from "../../../based/Constants";
import Loading from "../../../based/Loading";
import { Notify } from "../../../based/Notify";
import TFUImage from "../../../based/inputs/Image";
import TFUSelect from "../../../based/inputs/TFUSelect";
import TextNumber from "../../../based/inputs/TextNumber";
import Textbox from "../../../based/inputs/Textbox";
import BaseServices from "../../../based/services/BaseServices";
import useFormValidate from "../../../hooks/useFormValidate";
import PrintingTableSlotsEditor from "./PrintingTableSlotsEditor";

const INIT_PRINTING_TABLE = {
    id: 0,
    printDeviceId: 0,
    name: "",
    printingTableImage: "",
    slots: [],
};
const UVPrintingTableDetail = ({ match }) => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState("");
    const [printers, setPrinters] = useState([]);
    const [printingTableDetail, setPrintingTableDetail] = useState(INIT_PRINTING_TABLE);
    const { addRef, isValid, displayError } = useFormValidate();
    const detailId = match.params.detailId;
    const history = useHistory();
    useEffect(() => {
        _fetchData();
    }, []);
    const _fetchData = async () => {
        setBusy(true);
        setBusyMsg("Loading...");
        if (detailId) {
            if (detailId == "new") {
                setPrintingTableDetail(INIT_PRINTING_TABLE);
                _fetchPrinters();
            } else {
                const [err, data] = await BaseServices.Get("/api/uvprint/get-printing-table/" + detailId);
                if (!err && data) {
                    if (data.slots && data.slots.length > 0) {
                        data.slots.forEach((element) => {
                            if (element.products) element.products = element.products.filter((m) => m.selected);
                        });
                    }
                    setPrintingTableDetail(data);
                } else {
                    setPrintingTableDetail(INIT_PRINTING_TABLE);
                }
                _fetchPrinters();
            }
        }
        setBusy(false);
        setBusyMsg("");
    };
    const _fetchPrinters = async () => {
        let paging = {
            pageNumber: 1,
            pageSize: 50,
        };
        const [err, data] = await BaseServices.Post("/api/uvprint/get-print-devices", paging);
        if (!err && data) {
            let printers = data.listObjects.map((m) => ({ value: m.id, label: m.name }));
            setPrinters(printers);
        } else {
            setPrinters([]);
        }
    };

    const _handleSave = async () => {
        if (isValid()) {
            setBusy(true);
            setBusyMsg("Đang lưu...");
            const [err, data] = await BaseServices.Post("/api/uvprint/save-printing-table", printingTableDetail);
            setBusy(false);
            setBusyMsg(null);
            if (err) {
                Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
            } else {
                history.push("/uv-printing-table-management");
            }
        }
    };

    return (
        <React.Fragment>
            <Breadcrumbs currentPage={detailId == "new" ? "Thêm mới bàn gá" : "Chỉnh sửa bàn gá"} parentPage="Ranus" hrefParent="/" />
            <Loading show={busy} msg={busyMsg} />
            <div className="card-box padding-10">
                <div className="row">
                    <div className="col-2">
                        <label className="required">Tên bàn gá</label>
                        <Textbox name="name" ref={addRef} required value={printingTableDetail.name} onChanged={(name, value) => setPrintingTableDetail({ ...printingTableDetail, name: value })} />
                    </div>
                    <div className="col-2">
                        <label className="required">Máy in UV</label>
                        <TFUSelect name="printDeviceId" value={printingTableDetail.printDeviceId} required ref={addRef} options={printers} onChanged={(value) => setPrintingTableDetail({ ...printingTableDetail, printDeviceId: value })} />
                    </div>
                    <div className="col-2">
                        <label className="required">CalX (Đơn vị: 1mm)</label>
                        <TextNumber name="calX" ref={addRef} value={printingTableDetail.calX} onChanged={(name, value) => setPrintingTableDetail({ ...printingTableDetail, calX: value })} decimal min={Number.MIN_SAFE_INTEGER} />
                    </div>
                    <div className="col-2">
                        <label className="required">CalY (Đơn vị: 1mm)</label>
                        <TextNumber name="calY" ref={addRef} value={printingTableDetail.calY} onChanged={(name, value) => setPrintingTableDetail({ ...printingTableDetail, calY: value })} decimal min={Number.MIN_SAFE_INTEGER} />
                    </div>
                    <div className="col-4">
                        <label className="required">Hình ảnh mô phỏng</label>
                        <TFUImage image={printingTableDetail.printingTableImage} onChanged={(name, value) => setPrintingTableDetail({ ...printingTableDetail, printingTableImage: value })} />
                    </div>
                    <div className="col-2">
                        <label className="required">Varnish Opacity (Đơn vị: %, từ 1 đến 100)</label>
                        <TextNumber name="varnishOpacity" ref={addRef} value={printingTableDetail.varnishOpacity} onChanged={(name, value) => setPrintingTableDetail({ ...printingTableDetail, varnishOpacity: value })} decimal min={1} max={100} />
                    </div>
                </div>
                <div className="row">
                    <h5 className="col-12">Vị trí của sản phẩm trên bàn</h5>
                    <div className="col-12">
                        <PrintingTableSlotsEditor slots={printingTableDetail.slots} onChanged={(slots) => setPrintingTableDetail({ ...printingTableDetail, slots: [...slots] })} />
                    </div>
                </div>
                <div className="row text-right m-t-10">
                    <div className="col-12">
                        <button className="btn btn-custom" onClick={_handleSave}>
                            Hoàn thành
                        </button>
                        <button className="btn btn-dark m-l-10" onClick={() => history.push("/uv-printing-table-management")}>
                            Trở về danh sách
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UVPrintingTableDetail;
