import Pubsub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CommonServices from "../based/services/CommonServices";
import ThirdPartyServices from "../based/services/ThirdPartyServices";
import { Layout } from "../layout/Layout";
import { ThirdPartyModel } from "../models/ThirdPartyModel";
import SupplierModal from "./SupplierModal";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

export default function Supplier() {
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [selectedAll, setSelectedAll] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const tableRef = useRef(null);
    const [provinces, setProvinces] = useState([]);
    const [supplier, setSupplier] = useState(new ThirdPartyModel());

    useEffect(() => {
        setBusy(false);
        Pubsub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Supplier });
        GetProvinces();
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        setSelectedAll(false);
        const [err, data] = await ThirdPartyServices.GetsSuppliers(newPaging);
        if (!err && data) {
            setBusy(false);
            setMsg(null);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(initPage);
            setBusy(false);
            setMsg(null);
        }
    }

    async function GetProvinces() {
        const [err, data] = await CommonServices.GetProvinces();
        if (!err) setProvinces(data);
    }

    function _handleAdd() {
        setSupplier(new ThirdPartyModel());
        setIsShowModal(true);
    }

    function _handleEdit() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            let sp = dataChecked[0];
            if (sp != null) {
                if (sp.birthDate) sp.birthDate = new Date(sp.birthDate);
                setSupplier(sp);
                setIsShowModal(true);
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một bản ghi để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleSaveSucceed() {
        getDatas(paging);
        setIsShowModal(false);
    }

    function _handleDelete() {
        let suppliersDelete = datas.filter((x) => x.checked);
        if (suppliersDelete && suppliersDelete.length > 0) {
            let ids = [];
            suppliersDelete.map((item) => ids.push(item.id));
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    const [err, data] = await ThirdPartyServices.DeleteSuppliers(ids);
                    if (!err && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        setBusy(false);
                        getDatas(paging);
                    } else {
                        setBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                        setMsg(null);
                    }
                });
            });
        } else Notify(NOTIFY.INFO, "Thông báo", "Không có nhà cung cấp nào được chọn. Vui lòng kiểm tra lại!");
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th width={40} className='center'>
                        <CheckBox id='select_all' checked={selectedAll} name='select_all' label=' ' onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th width='50'>#</th>
                    <th>Tên nhà cung cấp</th>
                    <th>Địa chỉ</th>
                    <th width='200'>Email</th>
                    <th width='200'>Số điện thoại</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, k) => (
                        <tr key={k} className='text-center' onClick={() => _handleClickRow(k)}>
                            <td width={40} className='vertical-align-middle'>
                                <CheckBox id={Common.generateGuid()} checked={item.checked ? true : false} name={item.id.toString()} label=' ' onCheckedChange={(name, value) => _handleCheckdChange(item, k, value)} />
                            </td>
                            <td className='text-center'>{item.id}</td>
                            <td className='text-left vertical-align-middle'>
                                <span dangerouslySetInnerHTML={{ __html: item.fullName ? item.fullName : "" }} />
                            </td>
                            <td className='text-left vertical-align-middle'>
                                <span dangerouslySetInnerHTML={{ __html: item.address ? item.address : "" }} />
                            </td>
                            <td className='text-left vertical-align-middle'>
                                <span dangerouslySetInnerHTML={{ __html: item.email ? item.email : "" }} />
                            </td>
                            <td className='text-left vertical-align-middle'>
                                <span dangerouslySetInnerHTML={{ __html: item.phoneNumber ? item.phoneNumber : "" }} />
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan='6' className='text-center'>
                            Không có dữ liệu{" "}
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }

    function _handleClickRow(index) {
        let data = [...datas];
        data[index].checked = !data[index].checked;
        setDatas(data);
        setSelectedAll(data.filter((x) => x.checked).length == datas.length);
    }

    function _handleCheckdChange(item, idx, value) {
        let datasUpdate = [...datas];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setDatas(datasUpdate);
        setSelectedAll(datasUpdate.filter((x) => x.checked).length == datas.length);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='form-row p-y-10'>
                <div className='form-group'>
                    <button className='btn btn-custom btn-sm m-r-5' onClick={_handleAdd}>
                        <i className='fa fa-plus m-r-5' /> Thêm mới
                    </button>
                </div>
                {datas && datas.length > 0 ? (
                    <React.Fragment>
                        <div className='form-group'>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                        </div>
                        <div className='form-group'>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className='fa fa-remove m-r-5' /> Xóa
                            </button>
                        </div>
                    </React.Fragment>
                ) : (
                    ""
                )}
            </div>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage=' Nhà cung cấp' />
            <CommonTable data={datas} paging={paging} ref={tableRef} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} searchBasic onFilter={getDatas} />
            <SupplierModal
                supplier={supplier}
                provinces={provinces}
                isShowModal={isShowModal}
                onClose={() => {
                    setIsShowModal(false);
                }}
                onSave={(data) => {
                    setIsShowModal(data);
                }}
                onSaveSucceed={_handleSaveSucceed}
            />
        </React.Fragment>
    );
}
