import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../based/Breadcrumbs';
import { INITPAGING } from '../based/Constants';
import TextNumber from '../based/inputs/TextNumber';
import Loading from '../based/Loading';
import BaseServices from '../based/services/BaseServices';
import { Layout } from '../layout/Layout';
const WEEK_ARR = [0, 1, 2, 3, 4, 5];
function CreatorLoyalty(props) {
    const [isBusy, setIsBusy] = useState(false)
    const [paging, setPaging] = useState(INITPAGING)
    const [data, setData] = useState([])

    useEffect(() => {
        _fetchData(paging);
    }, [])

    const _fetchData = async (paging) => {
        setIsBusy(true);
        let [err, data] = await BaseServices.Post('/api/finance/get-last-6weeks-loyalty-amount', paging);
        setIsBusy(false);
        if (!err) {
            setData(data.listObjects);
            setPaging(data.paging);
        }
    }
    const handleChangePage = (pageNumber) => {
        paging.pageNumber = pageNumber;
        _fetchData(paging);
    }

    const handleChangePageSize = (pageSize) => {
        paging.pageSize = pageSize;
        _fetchData(paging);
    }
    const _renderPaging = () => {
        let totalRecord = paging.totalRecord,
            pageTotal = totalRecord % paging.pageSize === 0 ? totalRecord / paging.pageSize : (totalRecord - (totalRecord % paging.pageSize)) / paging.pageSize + 1;
        let from = (paging.pageNumber - 1) * paging.pageSize + 1;
        let to = paging.pageSize * paging.pageNumber;

        if (totalRecord == 0) {
            from = 0;
            to = 0;
        } else if (totalRecord < paging.pageSize * paging.pageNumber) {
            to = totalRecord;
        }
        return totalRecord > 0 ? (
            <table className="table table-bordered m-b-0">
                <tfoot>
                    <tr>
                        <td>
                            <div className="form-inline">
                                <ul className="pagination pagination-split footable-pagination m-b-0">
                                    {paging.pageNumber > 1 &&
                                        (paging.pageNumber === 2 ? (
                                            <li onClick={() => handleChangePage(1)}>
                                                <a className="cursor-pointer">1</a>
                                            </li>
                                        ) : (
                                            [
                                                <li onClick={() => handleChangePage(1)} title={1} key={0}>
                                                    <a className="cursor-pointer">«</a>
                                                </li>,
                                                <li onClick={() => handleChangePage(paging.pageNumber - 1)} title={paging.pageNumber - 1} key={1}>
                                                    <a className="cursor-pointer">‹</a>
                                                </li>,
                                                <li onClick={() => handleChangePage(paging.pageNumber - 1)} key={2}>
                                                    <a className="cursor-pointer">{paging.pageNumber - 1}</a>
                                                </li>,
                                            ]
                                        ))}
                                    <li className="active">
                                        <a className="cursor-pointer">{paging.pageNumber}</a>
                                    </li>
                                    {pageTotal > paging.pageNumber &&
                                        (pageTotal === paging.pageNumber + 1 ? (
                                            <li onClick={() => handleChangePage(paging.pageNumber + 1)}>
                                                <a className="cursor-pointer">{paging.pageNumber + 1}</a>
                                            </li>
                                        ) : (
                                            [
                                                <li onClick={() => handleChangePage(paging.pageNumber + 1)} key={0}>
                                                    <a className="cursor-pointer">{paging.pageNumber + 1}</a>
                                                </li>,
                                                <li onClick={() => handleChangePage(paging.pageNumber + 1)} title={paging.pageNumber + 1} key={1}>
                                                    <a className="cursor-pointer">›</a>
                                                </li>,
                                                <li onClick={() => handleChangePage(pageTotal)} title={pageTotal} key={2}>
                                                    <a className="cursor-pointer">»</a>
                                                </li>,
                                            ]
                                        ))}
                                </ul>
                                <span className="table-record">
                                    Hiển thị từ {from} đến {to} của {totalRecord} bản ghi
                                </span>
                                <label className="form-inline">
                                    Hiển thị&nbsp;
                                    <select className="form-control h-30px" value={paging.pageSize} onChange={(event) => handleChangePageSize(event.target.value)}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                    &nbsp;bản ghi
                                </label>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        ) : null;
    }

    const _renderTableHeader = () => {
        return WEEK_ARR.map(i => {
            return (
                <React.Fragment>
                    <th className='text-center' colSpan='2'><strong>Tổng tiền tuần {i + 1}</strong></th>
                </React.Fragment>
            )
        })
    }

    const _renderSpanRow = () => {
        return WEEK_ARR.map(i => {
            return (
                <React.Fragment>
                    <td>Đã thanh toán</td>
                    <td>Chưa thanh toán</td>
                </React.Fragment>
            )
        })
    }

    const _renderTableBody = () => {
        if (!data)
            return <tr>
                <td colSpan='13'>Không có dữ liệu</td>
            </tr>
        return data.map((creator, i) => {
            return <tr key={i}>
                <td>{creator.creatorName}</td>
                {
                    WEEK_ARR.map((week) => {
                        var value = creator.creatorLoyaltyAmounts.filter(m => m.weekIndex == week);
                        let paidAmount = '--';
                        let remaining = '--';
                        if (value.length > 0) {
                            paidAmount = value.filter(m => m.isPaid).reduce((p, c) => p + c.totalLoyaltyAmount, 0);
                            remaining = value.filter(m => !m.isPaid).reduce((p, c) => p + c.totalLoyaltyAmount, 0);
                            // render
                            paidAmount = <TextNumber displayType='text' price value={paidAmount} />
                            remaining = <TextNumber displayType='text' price value={remaining} />
                        }
                        return <React.Fragment>
                            <td className='text-right'><span className='text-success'>{paidAmount}</span></td>
                            <td className='text-right'><span className="text-danger">{remaining}</span></td>
                        </React.Fragment>
                    })
                }
            </tr >
        })
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Tiền bản quyền' parentPage='Tài chính' hrefParent='/finance' />
            <Loading show={isBusy} msg='Đang tải dữ liệu' />
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    {_renderTableHeader()}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    {_renderSpanRow()}
                                </tr>
                                {
                                    _renderTableBody()
                                }
                            </tbody>
                        </table>
                    </div>
                    {_renderPaging()}
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreatorLoyalty;