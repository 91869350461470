import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CheckBox from '../../../based/inputs/CheckBox';
import TextNumber from '../../../based/inputs/TextNumber';
import TFUDatetime from '../../../based/inputs/TFUDatetime';
import TFUSelect from '../../../based/inputs/TFUSelect';
import Language from '../../../based/Language';
import Loading from '../../../based/Loading';
import BaseServices from '../../../based/services/BaseServices';
import useFormValidate from '../../../hooks/useFormValidate';
import moment from 'moment';
import FindingProductSelection from './FindingProductSelection';
const USER_PLANS = [
    {
        value: 1,
        label: 'Tài khoản Basic'
    },
    {
        value: 2,
        label: 'Tài khoản Premium'
    }
]
function ConfigCommissionModal(props) {
    const [isBusy, setBusy] = useState(false);
    const [data, setData] = useState({});
    const [products, setProducts] = useState(new Map());
    const { addRef, isValid, displayError } = useFormValidate();
    useEffect(() => {
        if (props.isShow && props.dataId > 0) {
            _fetchData();
        } else {
            setData({});
            setProducts(new Map());
        }
    }, [props.isShow])
    async function _handleSubmit() {
        if (isValid() && products.size > 0) {
            setBusy(true);
            data.appProductIds = Array.from(products.keys());
            const [err, resData] = await BaseServices.Post(`/api/affiliate/submit-commission-config`, data);
            setBusy(false);
            if (!err) {
                props.onSubmit(true);
            } else {
                props.onSubmit(false);
            }
        }
    }
    async function _fetchData() {
        setBusy(true);
        const [err, resData] = await BaseServices.Post(`/api/affiliate/get-commission-config/${props.dataId}`);
        setBusy(false);
        if (!err) {
            let data = resData;
            data.effectFromDate = moment(data.effectFromDateText, 'DD/MM/YYYY').toDate();
            data.effectToDate = moment(data.effectToDateText, 'DD/MM/YYYY').toDate();
            let products = new Map();
            products.set(data.appProductId, { id: data.appProductId, name: data.productName, productAvatar: data.productImage });
            setProducts(products);
            setData(resData);
        } else {
        }
    }

    function _handleInputChanged(name, value) {
        let model = { ...data };
        model[name] = value;
        setData(model);
    }

    function _handleSelectProduct(productItem) {
        if (products.size < 1 && !products.has(productItem.id)) {
            let newSet = new Map(products.entries());
            newSet.set(productItem.id, productItem);
            setProducts(newSet);
        }
    }

    function _handleRemoveProduct(item) {
        let newSet = new Map(products.entries());
        newSet.delete(item.id);
        setProducts(newSet);
    }

    function _renderProducts() {
        let array = Array.from(products.values());
        return array.map((item, index) => (
            <tr key={index} style={{ height: 70, borderBottom: '1px solid #ccc' }}>
                <td width={100}>
                    <img src={item.productAvatar} style={{ width: '100%' }} />
                </td>
                <td>{item.name}</td>
                <td width={50}>
                    <button className='btn btn-danger btn-sm' onClick={() => _handleRemoveProduct(item)}>Xóa</button>
                </td>
            </tr>
        ))
    }
    function _renderBody() {
        return <div>
            <div className='form-group'>
                <label className='required'>Dòng sản phẩm</label>
                <FindingProductSelection onSelectItem={_handleSelectProduct} />
                {
                    products && products.size > 0 && <div>
                        <table className='table table-bordered' style={{ width: '100%' }}>
                            <tbody>
                                {
                                    _renderProducts()
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    (!products || products.size == 0) && <label className={'text-danger'}>
                        Dữ liệu không được để trống.
                    </label>
                }
            </div>
            <div className='form-group'>
                <label className='required'>% Hoa hồng cho đơn đầu tiên</label>
                <TextNumber ref={addRef} name='commission1stOrder' id='commission1stOrder' value={data.commission1stOrder} onChanged={_handleInputChanged} />
            </div>
            <div className='form-group'>
                <label className='required'>% Hoa hồng cho đơn thứ 2</label>
                <TextNumber ref={addRef} name='commission2ndOrders' id='commission2ndOrders' value={data.commission2ndOrders} onChanged={_handleInputChanged} />
            </div>
            <div className='form-group'>
                <label className='required'>% Hoa hồng cho đơn thứ 3 trở lên</label>
                <TextNumber ref={addRef} name='commissionMoreThan2Orders' id='commissionMoreThan2Orders' value={data.commissionMoreThan2Orders} onChanged={_handleInputChanged} />
            </div>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <label className='required'>Thời gian áp dụng từ ngày</label>
                        <TFUDatetime dateFormat='dd/MM/yyyy' name='effectFromDate' ref={addRef} value={data.effectFromDate}
                            onChanged={(value, name) => _handleInputChanged(name, value)} />
                    </div>
                    <div className='col-sm-6'>
                        <label className='required'>Đến ngày</label>
                        <TFUDatetime dateFormat='dd/MM/yyyy' name='effectToDate' ref={addRef} value={data.effectToDate}
                            onChanged={(value, name) => _handleInputChanged(name, value)} />
                    </div>
                </div>
            </div>
            <div className='form-group'>
                <label className='required'>Áp dụng cho loại tài khoản:</label>
                <TFUSelect options={USER_PLANS} ref={addRef} name='planApplied' id='planApplied' value={data.planApplied}
                    onChanged={(value) => _handleInputChanged('planApplied', value)} />
            </div>
            <div className='form-group'>
                <CheckBox label='Kích hoạt' checked={data.isActive} name='isActive' onCheckedChange={_handleInputChanged} />
            </div>
        </div>
    }
    return (
        <Modal show={props.isShow} size={'lg'} enforceFocus={false}>
            <Loading show={isBusy} msg={'Đang lưu...'} />
            <Modal.Header>
                <button type='button' className='close' onClick={props.onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Cấu hình % hoa hồng</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                {_renderBody()}
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={_handleSubmit}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={props.onClose}>
                    {Language.getValue("common.btnClose")}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfigCommissionModal;