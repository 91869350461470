import React, { useState, useEffect } from "react";
import { vi } from "date-fns/locale";
import { NOTIFY, CONSTANTS } from "../based/Constants";
import { Notify } from "../based/Notify";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import Textbox from "../based/inputs/Textbox";
import CheckBox from "../based/inputs/CheckBox";
import TextArea from "../based/inputs/TextArea";
import DatePicker from "react-datepicker";
import TFUSelect from "../based/inputs/TFUSelect";
import TFUSelectSubdivisions from "../based/TFUSelectSubdivisions";
import useFormValidate from "../hooks/useFormValidate";
import PhoneNumber from "../based/inputs/PhoneNumber";
import ThirdPartyServices from "../based/services/ThirdPartyServices";
import { ThirdPartyModel } from "../models/ThirdPartyModel";

const genderOptions = [
    {
        label: "Nam",
        text: "Nam",
        value: "1",
    },
    {
        label: "Nữ",
        text: "Nữ",
        value: "2",
    },
    {
        label: "Khác",
        text: "Khác",
        value: "3",
    },
];

export default function SupplierModal(props) {
    const [isBusy, setBusy] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [currentUser, setCurrentUser] = useState(new ThirdPartyModel());

    const { addRef, isValid, displayError } = useFormValidate();

    useEffect(() => {
        if (props.isShowModal) setCurrentUser(props.supplier ? props.supplier : new ThirdPartyModel());
    }, [props.isShowModal]);

    async function _handleSave() {
        if (isValid()) {
            let obj = { ...currentUser };
            if (obj != null) {
                setBusy(true);
                setMsgModalLoading("Đang lưu dữ liệu...");
                const [err, data] = await ThirdPartyServices.SaveSupplier(obj);
                if (!err && data) {
                    Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                    setBusy(false);
                    setMsgModalLoading(null);
                    props.onSaveSucceed();
                } else {
                    if (err.errors) displayError(err.errors);
                    else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                    setBusy(false);
                    setMsgModalLoading(null);
                }
            }
        }
    }

    return (
        <CommonModal size='lg' show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msgModalLoading} title='Thêm nhà cung cấp' onSave={() => _handleSave()} onClose={() => props.onClose()}>
            <div className='form-row'>
                <div className='form-group col-md-3'>
                    <label htmlFor='fullName' className='required'>
                        Họ và tên:
                    </label>
                    <Textbox
                        id='fullName'
                        name='fullName'
                        required
                        maxLength={255}
                        ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                        className='form-control'
                        onChanged={(name, value) => {
                            let customerUpdate = { ...currentUser, fullName: value };
                            setCurrentUser(customerUpdate);
                        }}
                        value={currentUser.fullName}
                    />
                </div>
                <div className='form-group col-md-3'>
                    <label htmlFor='phoneNumber' className='required'>
                        Điện thoại:
                    </label>
                    <PhoneNumber
                        id='phoneNumber'
                        name='phoneNumber'
                        addRef={addRef}
                        required
                        value={currentUser.phoneNumber}
                        onChanged={(name, value) => {
                            let customerUpdate = { ...currentUser, phoneNumber: value };
                            setCurrentUser(customerUpdate);
                        }}
                    />
                </div>
                <div className='form-group col-md-6'>
                    <label htmlFor='email'>Email:</label>
                    <Textbox
                        id='email'
                        name='email'
                        className='form-control'
                        ref={addRef}
                        email
                        onChanged={(name, value) => {
                            let customerUpdate = { ...currentUser, email: value };
                            setCurrentUser(customerUpdate);
                        }}
                        value={currentUser.email}
                    />
                </div>
            </div>
            <div className='form-row'>
                <div className='form-group col-md-3'>
                    <label htmlFor='birthDate'>Ngày sinh:</label>
                    <DatePicker
                        adjustDateOnChange
                        locale={vi}
                        showYearDropdown
                        showMonthDropdown
                        showTimeSelect
                        peekNextMonth
                        timeCaption='time'
                        timeFormat='HH:mm'
                        timeIntervals={15}
                        dateFormat='dd/MM/yyyy'
                        dropdownMode='select'
                        className='form-control'
                        selected={new Date(currentUser.birthDate)}
                        onChange={(value) => {
                            var now = new Date();
                            var y1900 = new Date("1/1/1900");
                            if (value < y1900) value = y1900;
                            if (value > now) value = now;
                            let customerUpdate = { ...currentUser, birthDate: value };
                            setCurrentUser(customerUpdate);
                        }}
                    />
                </div>
                <div className='form-group col-md-3'>
                    <label htmlFor='gender'>Giới tính:</label>
                    <TFUSelect id='gender' name='gender' value={currentUser.gender} noOptionsMessage='Không có dữ liệu' options={genderOptions} onChanged={(value) => setCurrentUser({ ...currentUser, gender: value })} />
                </div>
            </div>
            <div className='form-row'>
                <TFUSelectSubdivisions
                    showProvince
                    showDistrict
                    showWard
                    provinceCode={currentUser.provinceCode}
                    districtCode={currentUser.districtCode}
                    wardCode={currentUser.wardCode}
                    onChanged={(obj) => setCurrentUser({ ...currentUser, ...obj })}
                    addRef={addRef}
                    parentClassName='form-group col-md-6'
                    childClassName='m-b-5'
                />
                <div className='form-group col-md-6'>
                    <label htmlFor='street'>Số nhà, tên đường</label>
                    <TextArea id='street' name='street' value={currentUser.street} onChanged={(name, value) => setCurrentUser({ ...currentUser, street: value })} />
                </div>
            </div>
            <div className='form-row'>
                <CheckBox
                    id='isActive'
                    name='isActive'
                    label='Kích hoạt'
                    checked={currentUser.isActive}
                    onCheckedChange={(name, value) => {
                        let customerUpdate = { ...currentUser, isActive: value };
                        setCurrentUser(customerUpdate);
                    }}
                />
            </div>
        </CommonModal>
    );
}
