import BaseServices from "./BaseServices";

const GiftServices = {
    CreateUpdateGiftType: async (data) => {
        return BaseServices.Post(`/api/gift/create-update-gift-type`, data);
    },
    DeleteGiftType: async (id) => {
        return BaseServices.Get(`/api/gift/delete-gift-type/${id}`);
    },
    GetGiftTypeById: async (id) => {
        return BaseServices.Get(`/api/gift/get-gift-type-by-id/${id}`);
    },
    GetGiftTypeByPaging: async (paging) => {
        return BaseServices.Post(`/api/gift/get-gift-type-by-paging`, paging);
    },
    CreateUpdateGift: async (data) => {
        return BaseServices.Post(`/api/gift/create-update-gift`, data);
    },
    DeleteGift: async (id) => {
        return BaseServices.Get(`/api/gift/delete-gift/${id}`);
    },
    GetGiftById: async (id) => {
        return BaseServices.Get(`/api/gift/get-gift-by-id/${id}`);
    },
    GetGiftByPaging: async (paging) => {
        return BaseServices.Post(`/api/gift/get-gift-by-paging`, paging);
    },
    GetGiftByFolderId: async (folderId) => {
        return BaseServices.Get(`/api/gift/get-gift-by-folder-id/${folderId}`);
    },
};

export default GiftServices;
