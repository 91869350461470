import BaseServices from "./BaseServices";

const CusCollectionTypeServices = {
    //collection type
    CreateUpdateCUSCollectionType: async (model) => {
        return BaseServices.Post(`/api/CUSCollectionType/create-update-cus-collection-type`, model);
    },
    DeleteCUSCollectionType: async (id) => {
        return BaseServices.Get(`/api/CUSCollectionType/delete-cus-collection-type/${id}`);
    },
    GetCUSCollectionTypeByPage: async (model) => {
        return BaseServices.Post(`/api/CUSCollectionType/get-cus-collection-type-by-page`, model);
    },
};

export default CusCollectionTypeServices;
