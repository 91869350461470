import React, { useEffect, useState } from "react";
import Select from "react-select";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import useFormValidate from "../hooks/useFormValidate";
import TextNumber from "../based/inputs/TextNumber";

export default function AddOrEdit(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [menu, setMenu] = useState(props.menu);
    const [actions, setActions] = useState([]);
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setMenu(props.menu);
        let actionsUp = props.actions;
        if (actionsUp && actionsUp.length > 0) {
            actionsUp.map((action) => {
                if (props.menu.actions && props.menu.actions.length > 0) {
                    let exists = props.menu.actions.filter((x) => x.id == action.value);
                    if (exists && exists.length > 0) action.checked = true;
                } else action.checked = false;
            });
        }
        setActions(actionsUp);
    }, [props.isShowModal]);

    function _handleSave(isContinue) {
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang lưu dữ liệu..");
            let actionPost = [];
            let actionsChecked = actions.filter((m) => m.checked);
            actionsChecked.map((action) => {
                actionPost.push({
                    id: action.value,
                    name: action.label,
                    actionCode: action.value,
                });
            });
            menu.actions = actionPost;
            Request.Post("/api/menu/save", menu)
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                        setIsBusy(false);
                        setMsg(null);
                        props.onSaveSucceed(isContinue);
                        if (isContinue) setMenu(props.menu);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }
    function handleInputChange(name, value) {
        let actionsUp = [...actions];
        actionsUp.map((item) => {
            if (item.value == name) item.checked = value;
        });
        setActions(actionsUp);
    }

    function handleFileChange(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            setMenu({ ...menu, icon: res.data.imageUrl });
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                          return `${prev}${item.value}<br/>`;
                                      }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                    })
                    .catch((err) => {});
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <CommonModal show={props.isShowModal} size="lg" id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={menu.id == 0 ? "Thêm mới" : "Cập nhật"} onSave={() => _handleSave(false)} onClose={() => props.onClose()}>
            <div className="form-row">
                <div className="col-md-6">
                    <div className="card card-body" style={{ paddingTop: 5 }}>
                        <h5 className="card-title">THÔNG TIN MENU</h5>
                        <div className="form-group">
                            <label htmlFor="functionCode" className="required">
                                Code:
                            </label>
                            <Textbox
                                id="functionCode"
                                name="functionCode"
                                required
                                readOnly={menu.id ? true : false}
                                ref={addRef}
                                className="form-control"
                                onChanged={(name, value) => setMenu({ ...menu, functionCode: value })}
                                value={menu.functionCode}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="parentId">Menu cha:</label>
                            <Select
                                value={props.dataDropDown ? props.dataDropDown.filter((x) => x.value == menu.parentId) : null}
                                onChange={(selected) => setMenu({ ...menu, parentId: selected ? selected.value : 0 })}
                                options={props.dataDropDown}
                                noOptionsMessage={() => "Không có dữ liệu"}
                                isClearable={true}
                                placeholder={"--Chọn menu cha--"}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="required">
                                Tên:
                            </label>
                            <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setMenu({ ...menu, name: value })} value={menu.name} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="navigateLink">Link:</label>
                            <Textbox id="navigateLink" className="form-control" onChanged={(name, value) => setMenu({ ...menu, navigateLink: value })} value={menu.navigateLink} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="icon">Icon:</label>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="icon" onChange={handleFileChange} />
                                <label className="custom-file-label" htmlFor="icon">
                                    {menu.icon ? <img src={menu.icon} /> : "Chọn file"}
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="orderIndex">Thứ tự:</label>
                            <TextNumber id="orderIndex" className="form-control" onChanged={(name, value) => setMenu({ ...menu, index: value })} value={menu.index} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card card-body" style={{ paddingTop: 5 }}>
                        <h5 className="card-title">CHỨC NĂNG</h5>
                        <div className="form-group form-row" style={{ maxHeight: 300, overflow: "auto" }}>
                            {actions && actions.length > 0
                                ? actions.map((action, i) => {
                                      return (
                                          <div key={i} className="col-md-6">
                                              <CheckBox id={action.value} name={action.value} label={action.label} className="form-control" onCheckedChange={handleInputChange} checked={action.checked} />
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
