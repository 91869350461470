import React, {  useEffect, useState } from "react";
import styled from "styled-components";
import MarketingServices from "../../based/services/MarketingServices";
import Common from "../../based/Common";
import { Modal } from "react-bootstrap";

const ModalChangeSpecialDay = ({isShow , onClose , giftId , dataApply , date}) => {
    const [specialDay , setSpecialDay] = useState([])
    const [data, setData] = useState(dataApply)

    useEffect(() => {
        setData(dataApply)
    },[dataApply])
    
    useEffect(()=>{
        if(giftId > 0)
        getSpecialDayByGiftId(giftId)
    },[giftId, isShow])
    
    const getSpecialDayByGiftId = async (giftId) => {
        let [err , data ] = await MarketingServices.GetSpecialDayByGiftId(giftId);
        if(!err && data){
            setSpecialDay(data)
        }else{  
            setSpecialDay([])
        }
    }

    const handleDeleteSpecialApply = async (date , id, isDelete) =>{
        let [err , data ] = await MarketingServices.CreateOrDeleteSpecialDayApply({
            id: 0,
            specialDayId: id,
            applyDate: date
        });
        if(!err && data){
            if(isDelete){
                setData({})
            }else{
                setData({
                    id: 0,
                    specialDayId: id,
                    applyDate: date
                })
            }
        }else{

        }
    }

    return (
        <Modal show={isShow} onHide={onClose} size={'md'} id={Common.generateGuid()} enforceFocus={false}
        scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
             <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Chọn ngày đặc biệt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalChangeSpecialDayStyle 
                    
                    className={`partner_modal `} >
                   

                    <div className="partner_modal_body">
                        {specialDay.length > 0 && specialDay.map((item , idx)=>{
                            return <div className="item-day-special">
                                <input type="radio" id={`radio-${idx}`} checked={data && item.id == data.specialDayId}
                                    onClick={()=>{
                                        if(data && data.specialDayId)
                                            handleDeleteSpecialApply(data.applyDate,data.specialDayId, true)
                                        handleDeleteSpecialApply(date,item.id, false)

                                    }}
                                />
                                <label for={`radio-${idx}`}>{specialDay.length - idx} - {item.name}</label>
                            </div>
                        })}
                    </div>
                    
                </ModalChangeSpecialDayStyle>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <div className="box-button" style={{ display: "flex", justifyContent: 'center' }}>
                        <button className="btn-danger" onClick={()=>{onClose()}}>Đóng</button>
                        {data && data.specialDayId &&
                            <button className="ml-3 btn-custom"
                            onClick={()=>{
                                handleDeleteSpecialApply(data.applyDate,data.specialDayId , true)
                            }}
                            >Bỏ chọn</button> }
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const ModalChangeSpecialDayStyle = styled.div`
    width: 100%;
    bottom: 0;
    height: 270px;
    border-radius: 16px 16px 0 0 !important;
    position: relative;
    background-color: #fff !important;
    font-family: 'Lexend';

    @media (min-width: 768px) {
        max-width: 350px;
        border-radius: 16px !important;
        overflow: hidden;

        
    }



    .partner_modal_body{
        padding-bottom: 80px;
        margin-top: 10px;

        .item-day-special{
            display: flex;
            align-items: center;
            margin-top: 10px;
            input{
                width: 24px;
                height: 24px;
                accent-color: #4d9b65;
            }
            label{
                color: #505050;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 1;
                margin: 0 0 0 20px;
            }
        }

    }

    .box-footer-modal{
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;
        height: 60px;

    
        .partner_modal_body-comfirm-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            padding: 0 16px;
            z-index: 1;
            background-color: #fff;
    
            button{
                border: none;
                outline: none;
                border-radius: 10px;
                width: 125px;
                height: 38px;
                color: #FFF;
                font-size: 14px;
                font-weight: 600;
                line-height: 38px;
                text-align: center;
                border: 2px solid #f4f4f4;
                color: #B3282D;
                background-color: #fff;
                line-height: 1;
                margin: 0 5px;
               
    
                &.accept{
                    background: #B3282D;
                    color: #fff;
                }
            }
    
        }
    }
    
    


    

    
`

export default ModalChangeSpecialDay

