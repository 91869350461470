import React, { useState, useRef, useEffect, useContext } from "react";
import Loading from "../../based/Loading";
import Request from "../../based/Request";
import { Notify } from "../../based/Notify";
import Textbox from "../../based/refactor/Textbox";
import cookie from "react-cookies";
import { NOTIFY, CONSTANTS } from "../../based/Constants";
import useFormValidate from "../../hooks/useFormValidate";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import LayoutLight from "../../layout/refactor/Layout/Layout";
import { Box, BoxUser, BtnRounded } from "../../based/content/ct.elm";
import { ApplicationContext } from "../../context/ApplicationContext";
import { AuthContext } from "../../context/AuthContext";
const menuItem = ["Trang chủ", "Đổi mật khẩu"];
const ChangePassModel = {
    password: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
    userId: ""

};
export default function ChangePasswordRefactor(props) {
    const appCtx = useContext(ApplicationContext);

    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [validMsg, setValidMsg] = useState()
    const { addRef, displayError, isValid } = useFormValidate();

    const [model, setModel] = useState({
        password: "",
        newPassword: "",
        confirmPassword: "",
        token: "",
        token: "",
        userId: appCtx.applicationInfo.userInfo.id
    });
    useEffect(() => {
        setModel({
            password: "",
            newPassword: "",
            confirmPassword: "",
            token: "",
            token: "",
            userId: 0
        });
        setValidMsg(null)
    }, []);

    function _handleSave() {
        if (isValid()) {
            setBusy(true);
            setMsg("Đang lưu dữ liệu...");
            Request.Post("/api/accounts/change-password", { ...model, userId: appCtx.applicationInfo.userInfo.id })
                .then((res) => {
                    if (res.success) {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.SUCCESS, "Thành công", "Thay đổi mật khẩu thành công");
                        setTimeout(() => {
                            window.location.href = "/login";
                        }, 500);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setBusy(false);
                    setMsg(null);
                    if (err.errors) setValidMsg(err.errors[0].value)
                    Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                });
        }
    }
    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title='Đổi mật khẩu' />
            <Loading show={isBusy} msg={msg} />
            <Box style={{ 'height': '85%' }}>
                <BoxUser>
                    <div className='col-12 col-lg-8 offset-md-0 pt-3' >
                        <div className='form-group col-12'>
                            <label htmlFor='passwordOld' className='required'>
                                Mật khẩu cũ
                            </label>
                            <Textbox
                                id='password'
                                value={model.password}
                                name='password'
                                minLength={6}
                                maxLength={30}
                                required
                                password
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                className='form-control'
                                onChanged={(name, value) => setModel({ ...model, password: value })}
                            />
                        </div>
                        <div className='form-group col-12'>
                            <label htmlFor='password' className='required'>
                                Mật khẩu mới
                            </label>
                            <Textbox
                                id='newPassword'
                                value={model.newPassword}
                                name='newPassword'
                                required
                                password
                                minLength={6}
                                maxLength={30}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                className='form-control'
                                onChanged={(name, value) => setModel({ ...model, newPassword: value })}
                            />
                        </div>
                        <div className='form-group col-12'>
                            <label htmlFor='confirmPassword' className='required'>
                                Nhập lại mật khẩu mới
                            </label>
                            <Textbox
                                id='confirmPassword'
                                value={model.confirmPassword}
                                name='confirmPassword'
                                required
                                password
                                minLength={6}
                                maxLength={30}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                className='form-control'
                                onChanged={(name, value) => setModel({ ...model, confirmPassword: value })}
                            />
                        </div>
                        <div>
                            {validMsg ? <span className="text-danger mt-3 mb-3">{validMsg}</span> : ''}
                        </div>
                        <div className='form-group col-12'>
                            <BtnRounded color='#34d3eb' onClick={() => _handleSave()}>
                                &nbsp; Lưu &nbsp;
                            </BtnRounded>
                        </div>
                    </div>
                </BoxUser>
            </Box>
        </React.Fragment>
    );
}
