import React, { Component } from "react";
import Common from "../based/Common";
import Content from "../based/content/Content";
import LeftSideMenu from "./LeftSideMenu";
import TopBar from "./TopBar";

var token = Common.GetToken();
let verfiryEmail = false;
if (token) {
    const jwtDecode = require("jwt-decode");
    let decode = jwtDecode(token);
    verfiryEmail = !!decode.VerifyEmail;
}

export class Layout extends Component {
    displayName = Layout.name;
    constructor(props) {
        super(props);
    }
    _renderContent() {
        if (token) {
            if (!verfiryEmail) window.location.href = "/confirm-email";
            else
                return (
                    <div id="wrapper">
                        <TopBar />
                        <LeftSideMenu />
                        <div className="content-page">
                            <div className="content">{this.props.children}</div>
                        </div>
                    </div>
                );
        } else window.location.href = "/login?redirect=" + window.location.href;
    }
    render() {
        return this._renderContent();
    }
}
