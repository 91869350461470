import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY, StockFolderType } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import StockFolderServices from "../based/services/StockFolderServices";
import AddEditModal from "./AddEditModal";
import MoveModal from "./MoveModal";
import SelectImageModal from "./SelectImageModal";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import DragAndDropIndex from "../based/DragAndDropIndex";
const initPage = {
    pageSize: 10,
    pageNumber: 1,
    folderType: StockFolderType.StockImage,
    orderBy: "id",
    orderDirection: "asc",
};

class StockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.parentFolderId = 0;
        this.thumbnail = "";
        this.countStock = 0;
        this.child = [];
        this.isDown = false;
        this.isChecked = false;
        this.folderType = StockFolderType.StockImage;
    }
}

export default function StockImage(props) {
    const [isBusy, setBusy] = useState(true);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);
    const [datas, setDatas] = useState([new StockFolderModel()]);
    const [paging, setPaging] = useState(initPage);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMessage] = useState("");
    const [currentParentId, setCurrentParentId] = useState(0);
    const [activeId, setActiveId] = useState({ id: 0, parentId: 0 });
    const [stockImages, setStockImages] = useState([]);
    const [pagingStockImage, setPagingStockImage] = useState({ ...INITPAGING });
    const [showSelectModal, setShowSelectModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await StockFolderServices.GetListStockFolderPagings(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(newPaging);
        }
        setBusy(false);
        setMessage(null);
    }

    async function getStockImage(newPaging) {
        let [err, data] = await StockFolderServices.GetListStockImagePagings(newPaging);
        if (!err && data) {
            setStockImages(data.listObjects);
            setPagingStockImage(data.paging);
        } else {
            setStockImages([]);
            setPagingStockImage(newPaging);
        }
    }

    async function handleRemove(id, parentId) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await StockFolderServices.DeleteStockFolderById(id);
        if (!err && data) {
            getDatas({ ...paging });
            setStockImages([]);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
            setActiveId({ id: 0, parentId: 0 });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
        setBusy(false);
        setMessage(null);
    }

    async function getChildDatas(newPaging) {
        let [err, data] = await StockFolderServices.GetListStockFolderPagings(newPaging);
        return [err, data];
    }

    async function getRefreshChildDatas(newPaging) {
        let [err, data] = await StockFolderServices.GetListStockFolderPagings(newPaging);
        if (!err && data && data.listObjects) {
            let dt = [...datas];
            let ele = dt.find((x) => x.id == newPaging.stockFolderId);
            ele.child = data.listObjects;
            setDatas(dt);
        }
    }

    async function addStockImage(payload) {
        let [err, data] = await StockFolderServices.AddStockImageConnection(payload);
        if (!err && data) {
            setShowSelectModal(false);
            getStockImage({ ...pagingStockImage, folderId: activeId.id });
        }
    }

    async function removeStockImage(payload) {
        let [err, data] = await StockFolderServices.RemoveStockImageConnection(payload);
        if (!err && data) {
            getStockImage({ ...pagingStockImage, folderId: activeId.id });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");

            //trừ đi số lượng
            let parent = [...datas];
            let currentParent = parent.find((x) => x.id == activeId.parentId);
            currentParent.countStock -= 1;

            let children = currentParent.child.find((x) => x.id == activeId.id);
            children.countStock -= 1;
            setDatas(parent);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    }

    async function updateIndexStockFolder(id, index){
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.UpdateIndexStockFolder({
            id: id,
            parentFolderId: 0,
            folderType: StockFolderType.StockImage,
            index: index
        });
        if (!err && data) {        
            setBusy(false);
            getDatas(paging);
        } else {
            setBusy(false);
        }
    }
    function renderRow(item, i){
        return (
            <React.Fragment>
                <td>
                    <CheckBox
                        checked={item.id == activeId.id}
                        name={item.id}
                        onCheckedChange={(name, value) => {
                            getStockImage({ ...INITPAGING, pageNumber: 1, pageSize: 200, folderId: item.id });
                            if (item.id == activeId.id) setActiveId({ id: 0, parentId: 0 });
                            else setActiveId({ id: item.id, parentId: 0 });
                        }}
                    />
                </td>
                <td className="d-flex align-items-center">
                    <i
                        className="fa fa-angle-right mr-3 cursor-pointer"
                        onClick={async (e) => {
                            let dt = [...datas];
                            let ele = dt.find((x) => x.id == item.id);
                            ele.isDown = !ele.isDown;
                            let [err, data] = await getChildDatas({ ...INITPAGING, pageNumber: 1, pageSize: 1000, stockFolderId: item.id });
                            if (!err && data && data.listObjects) ele.child = data.listObjects;
                            setDatas(dt);
                        }}
                    />
                    <span
                        className="d-flex align-items-center cursor-pointer"
                        onClick={(e) => {
                            if (item.id == activeId.id) setActiveId({ id: 0, parentId: 0 });
                            else setActiveId({ id: item.id, parentId: 0 });
                        }}
                    >
                        <img src={item.thumbnail} alt={item.name} className="mr-3 square" />
                        {item.name}
                    </span>
                </td>
                <td>{item.countStock}</td>
        </React.Fragment>
        )
    }

    function renderIsDown(item , i) {
        return (
            item.isDown && (
                <React.Fragment>
                    {item.child &&
                        item.child.map((c, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        <CheckBox
                                            checked={c.id == activeId.id}
                                            name={`child-${c.id}`}
                                            onCheckedChange={(name, value) => {
                                                getStockImage({ ...INITPAGING, pageNumber: 1, pageSize: 200, folderId: c.id });
                                                if (c.id == activeId.id) setActiveId({ id: 0, parentId: 0 });
                                                else setActiveId({ id: c.id, parentId: item.id });
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <span className="ml-4">{c.name}</span>
                                    </td>
                                    <td>{c.countStock}</td>
                                </tr>
                            );
                        })}

                    <tr>
                        <td></td>
                        <td
                            className="add-more cursor-pointer"
                            onClick={(e) => {
                                setCurrentParentId(item.id);
                                setShowModal(true);
                                setActiveId({ id: 0, parentId: 0 });
                            }}
                        >
                            <span className="ml-4">Thêm folder con +</span>
                        </td>
                        <td></td>
                    </tr>
                </React.Fragment>
            )
        )
    }

    function _renderBody() {
        return (
            <DragAndDropIndex data={datas} renderRow={renderRow} renderIsDown={renderIsDown} updateIndexFolder={updateIndexStockFolder} />
        );
    }

   
    function _renderAction() {
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group">
                    <button
                        className="btn btn-custom btn-sm m-1"
                        onClick={(e) => {
                            setCurrentParentId(0);
                            setShowModal(true);
                        }}
                    >
                        Thêm folder cha
                    </button>
                    <button
                        className={`btn btn-custom btn-sm m-1 ${activeId && activeId.id > 0 ? "" : "disabled"}`}
                        onClick={(e) => {
                            setCurrentParentId(0);
                            setShowModal(true);
                        }}
                    >
                        Sửa tên folder
                    </button>
                    <button
                        className="btn btn-custom btn-sm m-1"
                        onClick={(e) => {
                            setShowMoveModal(true);
                        }}
                    >
                        Di chuyển folder
                    </button>
                    <button
                        className={`btn btn-danger btn-sm m-1 ${activeId && activeId.id > 0 ? "" : "disabled"} `}
                        onClick={(e) => {
                            let item = activeId;
                            handleRemove(item.id, item.parentId);
                        }}
                    >
                        Xóa
                    </button>
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox name="select_all" label=" " onCheckedChange={(name, value) => {}} />
                    </th>
                    <th className="cursor-pointer">Tên Folder</th>
                    <th className="cursor-pointer" width="150">
                        Số họa tiết
                    </th>
                </tr>
            </thead>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <Wrapper>
                <div style={{ display: "flex" }}>
                    <div style={{ flexBasis: "40%" }}>
                        <CommonTable
                            data={datas}
                            paging={paging}
                            ref={tableRef}
                            tableGuid={tableGuid}
                            searchBasic
                            hideSearchBasicButton
                            hideSearch
                            renderAction={_renderAction}
                            renderHeader={_renderHeader}
                            renderBody={_renderBody}
                            onFilter={getDatas}
                        />
                    </div>

                    <div className="stock-box">
                        <div className="box-header">
                            <div className="header-left">
                                <div className="form-group">
                                    <label>Họa tiết</label>
                                </div>
                            </div>
                            <div className="header-right">
                                <button className={`btn btn-custom btn-sm m-1 cursor-pointer ${activeId && activeId.id > 0 ? "" : "disabled"}`} onClick={(e) => setShowSelectModal(true)}>
                                    Thêm họa tiết
                                </button>
                            </div>
                        </div>
                        <div className="body-list">
                            {stockImages &&
                                stockImages.map((item, idx) => {
                                    return (
                                        <div className="body-item" key={idx}>
                                            <img src={item.thumbUrl} />
                                            <i
                                                className="fa fa-times remove-icon cursor-pointer"
                                                onClick={(e) => {
                                                    removeStockImage({ folderId: activeId.id, objectId: item.id, objectType: StockFolderType.StockImage });
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </Wrapper>
            {showModal && (
                <AddEditModal
                    isShowModal={showModal}
                    onClose={(e) => setShowModal(false)}
                    onSave={() => {
                        setShowModal(false);
                        if (currentParentId == 0) getDatas({ ...paging });
                        if (currentParentId > 0) {
                            getRefreshChildDatas({ ...INITPAGING, pageNumber: 1, pageSize: 100, stockFolderId: currentParentId });
                            setCurrentParentId(0);
                        }
                    }}
                    currentParentId={currentParentId}
                    currentId={activeId.id}
                />
            )}
            {showSelectModal && (
                <SelectImageModal
                    isShowModal={showSelectModal}
                    onClose={(e) => setShowSelectModal(false)}
                    onSave={(ids) => {
                        if (ids && ids.length > 0) {
                            let folderId = activeId.id;
                            let payload = ids.map((item) => ({
                                folderId: folderId,
                                objectId: item,
                                objectType: StockFolderType.StockImage,
                            }));
                            addStockImage(payload);
                        }
                    }}
                />
            )}
            {showMoveModal && (
                <MoveModal
                    isShowModal={showMoveModal}
                    onClose={() => setShowMoveModal(false)}
                    currentId={activeId.id}
                    onSave={() => {
                        getDatas({ ...paging });
                        setShowMoveModal(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}

const Wrapper = styled.div`
    td.add-more {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #64c5b1;
    }
    .square {
        height: 20px;
        width: 20px;
        object-fit: contain;
    }

    //stock-box
    .stock-box {
        flex: 1;
        background: #ffffff;
        margin-left: 10px;
    }

    .box-header {
        background: #d9d9d9;
        padding: 10px 10px 0px 10px;
        display: flex;
    }

    .header-left {
        display: flex;
    }

    .header-right {
        margin-left: auto;
    }

    .header-left .form-group {
        display: flex;
        align-items: baseline;
    }

    .header-left .form-group .btn-search {
        margin-left: 10px;
    }

    /* body */
    .body-list {
        display: flex;
        flex-wrap: wrap;
    }

    .body-item {
        height: 100px;
        width: 100px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        margin: 10px;
        padding: 5px;
        position: relative;
    }

    .body-item img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    i.fa.fa-times.remove-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;
