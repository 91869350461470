import React, { useEffect, useState } from "react";
import Loading from "../based/Loading"; 
import Breadcrumbs from "../based/Breadcrumbs";
import LevelConfigService from "../based/services/LevelConfigService";
import TextNumber from "../based/inputs/TextNumber";
import Textbox from "../based/inputs/Textbox";
import Sticky from "react-stickynode";
import CheckBox from "../based/inputs/CheckBox";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";

export default function LevelConfig(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        getDatas();
    }, []);

    async function getDatas() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await LevelConfigService.GetAllLevelConfig();
        if (!error && data) {
            setDatas(data);
            setIsLoading(false);
            setMsg("");
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg("");
        }
    }
    function _handleChange(name, value, item) {
        let datasUp = [...datas];
        datasUp.map((config) => {
            if (config.id == item.id) config[name] = value;
        });
        setDatas(datasUp);
    }
    function _renderConfig(item) {
        if (item) {
            return (
                <div className='card-box p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>Level {item.agencyLevel}</b>
                    </h4>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <label htmlFor='tierName'>Tier Name:</label>
                            <Textbox className='form-control' name='tierName' onChanged={(name, value) => _handleChange(name, value, item)} value={item.tierName} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='totalArtworkUnder25'>Số lượng Artwork &lt; 25MB:</label>
                            <TextNumber id='totalArtworkUnder25' name='totalArtworkUnder25' className='form-control' max={999999} value={item.totalArtworkUnder25} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                    </div>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <label htmlFor='totalArtworkUnder50'>Số lượng Artwork &lt; 50MB:</label>
                            <TextNumber id='totalArtworkUnder50' name='totalArtworkUnder50' className='form-control' max={999999} value={item.totalArtworkUnder50} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='totalArtworkUnder100'>Số lượng Artwork &lt; 100MB:</label>
                            <TextNumber id='totalArtworkUnder100' name='totalArtworkUnder100' className='form-control' max={999999} value={item.totalArtworkUnder100} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                    </div>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <label htmlFor='maximumCreatedBrand'>Số lượng thương hiệu được tạo:</label>
                            <TextNumber id='maximumCreatedBrand' name='maximumCreatedBrand' className='form-control' max={999999} value={item.maximumCreatedBrand} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='totalProductsSell'>Số lượng sản phẩm cần bán được:</label>
                            <TextNumber id='totalProductsSell' name='totalProductsSell' className='form-control' max={999999} value={item.totalProductsSell} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                    </div>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <label htmlFor='totalVouchersSellOver10'>Số lượng SP trong Voucher khi bán &gt; 10 SP:</label>
                            <TextNumber id='totalVouchersSellOver10' name='totalVouchersSellOver10' className='form-control' max={999999} value={item.totalVouchersSellOver10} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='totalVouchersSellOver50'>Số lượng SP trong Voucher khi bán &gt; 50 SP:</label>
                            <TextNumber id='totalVouchersSellOver50' name='totalVouchersSellOver50' className='form-control' max={999999} value={item.totalVouchersSellOver50} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                    </div>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <label htmlFor='totalVouchersSellOver100'>Số lượng SP trong Voucher khi bán &gt; 100 SP:</label>
                            <TextNumber id='totalVouchersSellOver100' name='totalVouchersSellOver100' className='form-control' max={999999} value={item.totalVouchersSellOver100} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='totalVouchersSellOver200'>Số lượng SP trong Voucher khi bán &gt; 200 SP:</label>
                            <TextNumber id='totalVouchersSellOver200' name='totalVouchersSellOver200' className='form-control' max={999999} value={item.totalVouchersSellOver200} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div>
                    </div>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <label htmlFor='totalSetBoosting'>Số lượng SP đăng ký Campaign Boosting:</label>
                            <TextNumber id='totalSetBoosting' name='totalSetBoosting' className='form-control' max={999999} value={item.totalSetBoosting} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div> 
                        <div className='col-md-6'>
                            <label htmlFor='milestoneRegisteredProductLimit'>Số lượng SP đăng ký Campaign Boosting:</label>
                            <TextNumber id='milestoneRegisteredProductLimit' name='milestoneRegisteredProductLimit' className='form-control' max={999999} value={item.milestoneRegisteredProductLimit} onChanged={(name, value) => _handleChange(name, value, item)} />
                        </div> 
                    </div>
                    <div className='form-group form-row'>
                        <div className='col-md-12'>
                            <CheckBox id='isCreatedBrandLogo' name='isCreatedBrandLogo' checked={item.isCreatedBrandLogo} onCheckedChange={(name, value) => _handleChange(name, value, item)} label='Được phép tạo Logo thương hiệu' />
                        </div>
                    </div>
                </div>
            );
        }
    }
    async function _handleSave() {
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");
        let [error, data] = await LevelConfigService.SaveLevelConfig(datas);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.SUCCESS, "Thành công", "Lưu cấu hình thành công");
        } else {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.ERROR, "Lỗi", error.data ? error.data : "Có lỗi xảy ra. Vui lòng thử lại sau.");
        }
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs currentPage='Cấu hình Level' />
            <Sticky enabled={true} top={70} bottomBoundary={4000} innerZ={2}>
                <div className='form-group text-right product-navigate-buttons'>
                    <button type='button' className='btn btn-custom waves-effect w-xs waves-light btn-sm' onClick={() => _handleSave()}>
                        <i className='fa fa-save m-r-5'></i> Lưu cấu hình
                    </button>
                </div>
            </Sticky>
            {datas && datas.length > 0 ? (
                <div className='form-row'>
                    {datas.map((item, k) => {
                        return (
                            <div key={k} className='col-sm-6 pr-1'>
                                {_renderConfig(item)}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className='form-row'>
                    <div className='col-sm-12 pr-1'>
                        <div className='card-box p-3'>
                            <div className='full-width text-center' style={{ background: "#e9ecef", opacity: 1, padding: 100, fontSize: 20 }}>
                                <strong>Chưa có thông tin cấu hình Level</strong>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
