import React, { useEffect, useMemo, useState } from "react";
import Barcode from "react-barcode";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LazyLoad from "react-lazyload";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY, ROLES } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/inputs/TFUSelect";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import AccountServices from "../based/services/AccountServices";
import CommonServices from "../based/services/CommonServices";
import OperatorServices from "../based/services/OperatorServices";
import { Layout } from "../layout/Layout";
import UploadOperatorFile from "./UploadOperatorFile";
const { ClipboardItem } = window;

const TAB_SCREEN = {
    ALL: 0,
    PROCESSING: 1,
    QC_FAILED: 2,
    HAVING_ORDER: 3,
    CART: 4,
    MOST_VIEW: 5,
};

export default function ProcessOperatorFile(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);
    const [roles, setRoles] = useState(null);
    const [creatorOptions, setCreatorOptions] = useState([]);
    const [tabIndex, setTabindex] = useState(TAB_SCREEN.ALL);
    const [showUpload, setShowUpload] = useState(false);
    const [current, setCurrent] = useState({});
    useEffect(() => {
        getDatas(paging, TAB_SCREEN.ALL);
        showScreen();
    }, []);
    async function showScreen() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await AccountServices.GetRolesByUser();
        if (!error && data) {
            setRoles(data);
            setIsLoading(false);
            setMsg(null);
            if (!data.find((x) => x.toUpperCase() == ROLES.DESIGNER)) getCreator();
        } else {
            setRoles(null);
            setIsLoading(false);
            setMsg(null);
            window.location.href = "/access-denied";
        }
    }
    async function getCreator() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setCreatorOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging, tab) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OperatorServices.ProcessOperatorFilesPaging(newPaging, tab);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className='text-center' width='150'>
                        File Name
                    </th>
                    <th className='text-center' width='150'>
                        Mock-up
                    </th>
                    <th className='text-center'>95%</th>
                    <th className='text-center'>90%</th>
                    <th className='text-center'>85%</th>
                    <th className='text-center'>80%</th>
                </tr>
            </thead>
        );
    }
    async function _handleDownloadArtwork(item) {
        setIsLoading(true);
        setMsg("Đang tải file thiết kế...");
        let fileName = item.url;
        let fileArr = fileName.split("/");
        if (fileArr && fileArr.length > 0) fileName = fileArr[fileArr.length - 1];
        let [error, data] = await OperatorServices.DownloadArtwork(item.id, item.url, fileName);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.SUCCESS, "Thành công", "Tải file thiết kế thành công");
        } else {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra trong quá trình tải file.");
        }
    }
    function _handleCopy(copyType) {
        var messageSuccess = "";
        switch (copyType) {
            case "setName":
                messageSuccess = "Sao chép tên file thành công.";
                break;
            case "barcode":
                messageSuccess = "Sao chép Barcode thành công.";
                break;
            default:
                break;
        }
        Notify(NOTIFY.SUCCESS, "Thành công", messageSuccess);
    }
    async function _handleShowUpload(file) {
        if (roles && roles.length > 0) {
            if (!roles.find((x) => x.toUpperCase() == ROLES.DESIGNER)) {
                setShowUpload(true);
                setCurrent(file);
            } else if (roles.find((x) => x.toUpperCase() == ROLES.DESIGNER) && !file.qcCheck) {
                setShowUpload(true);
                setCurrent(file);
            }
        } else Notify(NOTIFY.WARNING, "Chú ý", "Bạn không có quyền thực hiện chức năng này");
    }

    function _renderFileItem(item) {
        let files = [];
        if (item.operatorFiles && item.operatorFiles.length > 0) {
            let scales = [0.95, 0.9, 0.85, 0.8];
            scales.map((scale, s) => {
                let currentFile = item.operatorFiles && item.operatorFiles.length > 0 ? item.operatorFiles.find((x) => x.scaleValue == scale) : null;
                if (currentFile) {
                    files.push(
                        <td key={s} className='text-center vertical-align-middle' style={{ width: 200 }}>
                            <div id={currentFile.barcode}>
                                <Barcode value={currentFile.barcode ? currentFile.barcode : "000000000"} height={30} />
                            </div>
                            <button
                                type='button'
                                className={`btn btn-secondary waves-effect btn-sm ${isDisabled ? "disabled" : "not"}`}
                                disabled={isDisabled}
                                onClick={(e) => {
                                    drawCanvas(currentFile.barcode);
                                }}
                            >
                                Copy Barcode
                            </button>
                            <div className='d-flex mt-1 mb-1'>
                                <button
                                    type='button'
                                    className={
                                        currentFile.qcCheck && roles && roles.find((x) => x.toUpperCase() == ROLES.DESIGNER) ? "btn btn-secondary waves-effect btn-sm mr-1 flex-70 disabled" : "btn btn-secondary waves-effect btn-sm mr-1 flex-70"
                                    }
                                    onClick={() => _handleShowUpload(currentFile)}
                                >
                                    Upload file
                                </button>
                                <div>
                                    <button
                                        type='button'
                                        className={
                                            currentFile.qcCheck && roles && roles.find((x) => x.toUpperCase() == ROLES.DESIGNER)
                                                ? "btn btn-outline-primary waves-effect waves-light btn-sm full-width mb-1 disabled"
                                                : "btn btn-outline-primary waves-effect waves-light btn-sm full-width mb-1"
                                        }
                                        onClick={() => _handleShowUpload(currentFile)}
                                    >
                                        Nhập tọa độ
                                    </button>
                                    <button
                                        type='button'
                                        className={currentFile.fileUrl && currentFile.fileUrl.length > 0 ? "btn btn-success waves-effect waves-light btn-sm  full-width" : "btn btn-warning waves-effect waves-light btn-sm  full-width"}
                                    >
                                        <span>{currentFile.fileUrl && currentFile.fileUrl.length > 0 ? "Đã upload" : "Chưa upload"}</span>
                                    </button>
                                </div>
                            </div>
                            {currentFile.fileUrl && currentFile.fileUrl.length > 0 && (
                                <button type='button' className={currentFile.qcCheck ? "btn btn-success waves-effect waves-light btn-sm" : "btn btn-danger waves-effect waves-light btn-sm"}>
                                    <span>{currentFile.qcCheck ? "Passed" : "Failed"}</span>
                                </button>
                            )}
                        </td>
                    );
                } else {
                    files.push(
                        <td key={s} className='text-center vertical-align-middle'>
                            <img src='/images/istockphoto.png' />
                        </td>
                    );
                }
            });
        } else {
            files.push(
                <td className='text-center vertical-align-middle'>
                    <img src='/images/istockphoto.png' />
                </td>
            );
            files.push(
                <td className='text-center vertical-align-middle'>
                    <img src='/images/istockphoto.png' />
                </td>
            );
            files.push(
                <td className='text-center vertical-align-middle'>
                    <img src='/images/istockphoto.png' />
                </td>
            );
            files.push(
                <td className='text-center vertical-align-middle'>
                    <img src='/images/istockphoto.png' />
                </td>
            );
        }
        return files;
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className='text-center vertical-align-middle position-relative'>
                                    <div className='d-flex' style={{ width: 200 }}>
                                        <div className='btn-group mb-2 position-absolute' style={{ top: 0, left: 0, margin: 5 }}>
                                            <button type='button' className='btn btn-custom waves-effect btn-sm' onClick={() => _handleDownloadArtwork(item)}>
                                                Download
                                            </button>
                                            <CopyToClipboard text={Common.GetFileName(item.url)} onCopy={() => _handleCopy("setName")}>
                                                <button type='button' className='btn btn-outline-primary waves-effect  waves-light  btn-sm'>
                                                    Copy tên file
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                        <p className='mb-2' id='setName' name='setName'>
                                            {item.setName}
                                        </p>
                                        <span className='btn btn-outline-primary waves-effect waves-light btn-sm position-absolute' style={{ bottom: 0, left: 0, whiteSpace: "break-spaces", wordBreak: "break-all", margin: 5 }}>
                                            Creator: {item.createdBy}
                                        </span>
                                    </div>
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    {/* <img src={"/images/no-image.png"} className='border' style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} /> */}
                                    <LazyLoad once={true} height={10} placeholder={<img src={item.thumbnail ? item.thumbnail : "/images/no-image.png"} className='border' style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} />}>
                                        <img src={item.thumbnail ? item.thumbnail : "/images/no-image.png"} className='border' style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} />
                                    </LazyLoad>
                                </td>
                                {_renderFileItem(item)}
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            roles &&
            roles.length > 0 && (
                <React.Fragment>
                    <div className='box__actions' style={{ borderBottom: "1px solid #f1f1f1" }}>
                        <div className='form-group full-width'>
                            <div className='form-row'>
                                {!roles.find((x) => x.toUpperCase() == ROLES.DESIGNER) && (
                                    <div className='form-group col-md-4'>
                                        <TFUSelect name='createdBy' value={paging.createdBy} options={creatorOptions} placeholder='---Chọn người tạo---' onChanged={(value) => setPaging({ ...paging, createdBy: value })} />
                                    </div>
                                )}

                                <div className='form-group col-md-4'>
                                    <Textbox className='form-control' placeholder='Tên thiết kế' id='artworkName' name='artworkName' onChanged={(name, value) => setPaging({ ...paging, artworkName: value })} value={paging.artworkName} />
                                </div>

                                <div className='form-group col-md-3'>
                                    <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas({ ...paging, pageNumber: 1 }, tabIndex)}>
                                        <i className='fa fa-search m-r-5' />
                                        Tìm kiếm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {_renderTab}
                </React.Fragment>
            )
        );
    }
    function _handleChangedTab(tab) {
        if (tab != tabIndex) {
            setTabindex(tab);
            getDatas(paging, tab);
        }
    }
    const _renderTab = useMemo(() => (
        <ul className='nav nav-tabs tabs-bordered'>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.ALL ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.ALL)}>
                    <span>Tất cả</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.HAVING_ORDER ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.HAVING_ORDER)}>
                    <span>Đang có Order</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.CART ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.CART)}>
                    <span>Đang thêm vào giỏ hàng</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.MOST_VIEW ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.MOST_VIEW)}>
                    <span>Nhiều lượt View</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.PROCESSING ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.PROCESSING)}>
                    <span>Đang xử lý</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.QC_FAILED ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.QC_FAILED)}>
                    <span>QC Failed</span>
                </a>
            </li>
        </ul>
    ));

    const drawCanvas = async (key) => {
        setIsDisabled(true);
        getBarcode(key)
            .then(async (res) => {
                if (res.success && res.data) {
                    let url = res.data.imageUrl;
                    let blob = await fetch(url).then((r) => r.blob());
                    await navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Coppy Barcode thành công.");
                }
                setIsDisabled(false);
            })
            .catch((err) => {
                setIsDisabled(false);
            });
    };

    const getBarcode = (barcode) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${Request.CdnURL()}/api/images/get-barcode/${barcode}`);
            xhr.setRequestHeader("client", "tfu_admin");
            let token = Common.GetToken();
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    };

    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Xử lý file sản xuất' />
            <Loading show={isLoading} msg={msg} />
            {showUpload && (
                <UploadOperatorFile
                    artwork={current}
                    isShowModal={showUpload}
                    onClose={() => setShowUpload(false)}
                    onSuccess={() => {
                        setShowUpload(false);
                        getDatas(paging, tabIndex);
                    }}
                />
            )}
            <div id='png-container'></div>
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging, tabIndex)} />
        </React.Fragment>
    );
}
