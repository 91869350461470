import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import CustomizeServices from "../based/services/CustomizeServices";
import useFormValidate from "../hooks/useFormValidate";

const TagModel = {
    id: 0,
    name: "",
};

const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [tag, setTag] = useState(TagModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const { id, onSaveContinue, onClose, isShowModal } = props;

    useEffect(() => {
        if (id > 0) _getTag(id);
        return () => setTag({});
    }, [id]);

    const _getTag = async (id) => {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetTag(id);
        if (!err && data) setTag({ ...data });
        else setTag(TagModel);
        setIsBusy(false);
    };

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = id > 0 ? await CustomizeServices.UpdateTag({ ...tag }) : await CustomizeServices.CreateTag({ ...tag });
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                onSaveContinue();
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            setIsBusy(false);
        }
    };
    return (
        <CommonModal show={isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()}>
            <div className="form-group">
                <label htmlFor="name" className="required">
                    Tên hiển thị:
                </label>
                <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setTag({ ...tag, name: value })} value={tag.name} />
            </div>
        </CommonModal>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
