import { ProductVariantModel } from "./BaseProductModel";

export class SetModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.totalVariants = 0;
        this.setAvatar = "";
        this.productVariants = [new ProductVariantModel()];
        this.status = 0;
        this.brandId = -2;
        this.topic = [];
        this.advantages = "";
        this.content = "";
        this.tag = [];
        this.loyaltyAmount = 0;
        this.lotusChargeAmount = 0;
        this.seoMetaTitle = ""
        this.seoMetaKeywords = ""
        this.seoMetaDescription = ""
        this.manualReviewStatus = 0
        this.remark = ""
        this.artworks = []

    }
}
