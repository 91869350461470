import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../based/Breadcrumbs';
import CommonTable from '../based/CommonTable';
import { INITPAGING } from '../based/Constants';
import CheckBox from '../based/inputs/CheckBox';
import Selection from '../based/inputs/Selection';
import Loading from '../based/Loading';
import BaseServices from '../based/services/BaseServices';

const filterOptions = [
    {
        value: '',
        label: 'ALL'
    },
    {
        value: 'INDEXING_STATE_UNSPECIFIED',
        label: 'INDEXING_STATE_UNSPECIFIED'
    },
    {
        value: 'INDEXING_ALLOWED',
        label: 'INDEXING_ALLOWED'
    },
    {
        value: 'BLOCKED_BY_META_TAG',
        label: 'BLOCKED_BY_META_TAG'
    },
    {
        value: 'BLOCKED_BY_HTTP_HEADER',
        label: 'BLOCKED_BY_HTTP_HEADER'
    },
    {
        value: 'BLOCKED_BY_ROBOTS_TXT',
        label: 'BLOCKED_BY_ROBOTS_TXT'
    }
]
const URLIndexing = props => {
    const [isBusy, setBusy] = useState(false);
    const [urls, setData] = useState([]);
    const [paging, setPaging] = useState(INITPAGING);
    const [selectedAll, setSelectedAll] = useState(false);
    useEffect(() => {
        _fetchData(paging);
    }, []);

    const _fetchData = async (paging) => {
        setBusy(true);
        const [err, data] = await BaseServices.Post('/api/sitemap/get-url-indexing', paging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
        }
        setBusy(false);
    }
    const _handleCheckedAll = (value) => {
        setSelectedAll(value);
        let datasUpdate = [...urls];
        datasUpdate.map(item => item.checked = value);
        setData(datasUpdate);
    }
    const _handleCheckedChange = (item, idx, value) => {
        let datasUpdate = [...urls];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setData(datasUpdate);
        setSelectedAll(datasUpdate.filter(x => x.checked).length == urls.length);
    }
    const _handleReSubmit = async () => {
        let dataChecked = urls.filter(x => x.checked);
        if (dataChecked && dataChecked.length > 0) {
            let ids = dataChecked.map(m => m.id);
            setBusy(true);
            const [err, data] = await BaseServices.Post('/api/sitemap/resubmit-url', { ids: ids });
            if (!err && data) {
                _fetchData(paging);
            }
            setBusy(false);
        }
    }
    const _handleFilterByIndexStatus = (value) => {
        let newPaging = { ...paging, indexStatus: value };
        setPaging(newPaging)
        _fetchData(newPaging);
    }
    const _renderAction = () => {
        let dataChecked = null;
        let isEdit = false;
        if (urls && urls.length > 0) {
            dataChecked = urls.filter(x => x.checked);
            if (dataChecked && dataChecked.length > 0) {
                isEdit = true;
            }
        }
        return (
            <div className='row flex-row p-10 box__actions'>
                <div className='form-group'>
                    <button className={isEdit ? 'btn btn-custom btn-sm m-r-5' : 'btn btn-custom btn-sm m-r-5 disable'} onClick={_handleReSubmit}>
                        <i className='fa fa-edit m-r-5' />Yêu cầu Index lại
                    </button>
                </div>
            </div>
        )
    }
    const _renderSearchAdvanced = () => {
        return <div>
            <Selection
                placeholder='Index Status'
                id='filter_index_status'
                name='filter_index_status'
                value={paging.indexStatus}
                onChanged={_handleFilterByIndexStatus}
                options={filterOptions}
            />
        </div>
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th width={40} className='center'>
                    <CheckBox
                        checked={selectedAll}
                        name='select_all'
                        id='select_all'
                        label=' '
                        onCheckedChange={(name, value) => _handleCheckedAll(value)}
                    />
                </th>
                <th>Url</th>
                <th>Ngày cuối update</th>
                <th>Trạng thái Submit</th>
                <th>Trạng thái Index</th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        return <tbody>
            {
                urls && urls.length > 0 && urls.map((item, idx) => {
                    return <tr key={item.id}>
                        <td width={40} className={'center'}>
                            <CheckBox checked={item.checked ? item.checked : false}
                                name={`checkBox_${idx}`} id={item.id.toString()}
                                label=' ' onCheckedChange={(name, value) => _handleCheckedChange(item, idx, value)}
                            />
                        </td>
                        <td>
                            <a href={item.url}>{item.url}</a>
                        </td>
                        <td width={150}>{item.updatedDateText}</td>
                        <td width={50}>{item.isSubmitted ? 'True' : 'False'}</td>
                        <td width={300}>{item.indexStatus}</td>
                    </tr>
                })
            }
        </tbody>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý URL Index (Google)' />
            <Loading show={isBusy} msg={'Đang tải dữ liệu...'} />
            <CommonTable data={urls}
                paging={paging} searchBasic
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={_fetchData}
                searchAdvanced={_renderSearchAdvanced}
            />
        </React.Fragment>
    );
};

export default URLIndexing;