import React, { useState, useEffect, useRef } from 'react';
import { Layout } from '../layout/Layout';
import Loading from '../based/Loading';
import PubSub from 'pubsub-js';
import { CONSTANTS, MAINMENU } from '../based/Constants';
import { NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import { ConfirmDialog } from '../based/Dialog';
import Common from '../based/Common';
import CommonTable from '../based/CommonTable';
import Breadcrumbs from '../based/Breadcrumbs';
import CheckBox from '../based/inputs/CheckBox';
import Language from '../based/Language';
import AddOrEditBankAccount from './AddOrEditBankAccount';
import { BankAccountConfigModel } from '../models/BankAccountConfigModel';
import CommonServices from '../based/services/CommonServices';
import BankServices from '../based/services/BankServices';

const columns = [
  {
    name: 'bankAccountName',
    displayName: 'Tên tài khoản',
  },
  {
    name: 'bankAccountNumber',
    displayName: 'Số tài khoản/Số điện thoại',
  },
  {
    name: 'bankName',
    alternative: 'Momo',
    displayName: 'Tên ngân hàng',
  },
  {
    name: 'isActive',
    displayName: 'Hoạt động',
    type: 'bool',
  },
  {
    name: 'verified',
    displayName: 'Xác nhận',
    type: 'bool',
  },
];

const initPage = {
  pageSize: 10,
  pageNumber: 1,
  orderBy: 'id',
  orderDirection: 'asc',
};

const BankAccountManagement = (props) => {
  const tableRef = useRef(null);
  const [isBusy, setBusy] = useState(true);
  const [msg, setMsg] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [tableGuid, setTableGuid] = useState(Common.generateGuid());
  
  const [banks, setBanks] = useState([]);
  const [datas, setDatas] = useState([]);
  const [paging, setPaging] = useState(initPage);
  const [bankAccount, setBankAccount] = useState(new BankAccountConfigModel());

  useEffect(() => {
    setBusy(false);
    getDatas(paging);
    getBanks();
    PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.SystemBankAccounts });
  }, []);

  const getDatas = async (paging) => {
    setBusy(true);
    setMsg('Đang tải dữ liệu...');
    const [err, data] = await BankServices.GetBankAccounts(paging);
    if (!err && data) {
      setBusy(false);
      setMsg(null);
      setDatas(data.listObjects);
      setPaging(data.paging);
      setTableGuid(Common.generateGuid());
    }
    else {
      setDatas([]);
      setPaging(initPage);
      setBusy(false);
      setMsg(null);
    }
  }

  const getBanks = async () => {
    const [err, data] = await CommonServices.GetBanks();
    if (!err) setBanks(data);
  }

  const _handleAddBankAccount = () => {
    setIsShowModal(true);
    setBankAccount(new BankAccountConfigModel());
  }

  const _handleDeleteBankAccounts = () => {
    var datasDelete = datas.filter((x) => x.checked);
    if (datasDelete && datasDelete.length > 0) {
      ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
        return new Promise(async (resolve, reject) => {
          var ids = datasDelete.map((x) => x.id);
          setBusy({ isBusy: true, busyMsg: 'Đang xóa dữ liệu...' });
          const [err, data] = await BankServices.DeleteBankAccounts(ids);
          if (!err && data) {
            setBusy({ isBusy: false, busyMsg: '' });
            resolve({ title: 'Thành công', msg: 'Xóa thành công' });
            getDatas(paging);
          }
          else {
            Notify(NOTIFY.ERROR, 'Có lỗi xảy ra. Vui lòng thử lại sau ít phút!', CONSTANTS.MSG_ERROR);
            setBusy({ isBusy: false, busyMsg: '' });
          }
        });
      });
    } else Notify(NOTIFY.INFO, 'Thông báo', 'Không có bản ghi nào được chọn. Vui lòng kiểm tra lại!');
  }

  const _handleEditBankAccount = () => {
    let dataChecked = datas.filter((x) => x.checked);
    if (dataChecked && dataChecked.length == 1) {
      setIsShowModal(true);
      setBankAccount(dataChecked[0]);
    } else Notify(NOTIFY.INFO, 'Thông báo', 'Yêu cầu chọn một người dùng để thực hiện. Vui lòng kiểm tra lại!');
  }

  const _handleSaveSucceed = () => {
    setIsShowModal(false);
    setTableGuid(Common.generateGuid());
    getDatas(paging);
  }

  const _handleCheckedChange = (item, idx, value) => {
    let datasUpdate = [...datas];
    let itemUpdate = datasUpdate[idx];
    itemUpdate.checked = value;
    setDatas(datasUpdate);
    setSelectedAll(datasUpdate.filter((x) => x.checked).length == datas.length);
  }

  const _handleCheckedAll = (value) => {
    setSelectedAll(value);
    let datasUpdate = [...datas];
    datasUpdate.map((item) => (item.checked = value));
    setDatas(datasUpdate);
  }

  const _renderAction = () => {
    let isEnableEdit = false;
    if (datas && datas.length > 0) {
      let dataChecked = datas.filter((x) => x.checked);
      if (dataChecked && dataChecked.length == 1) isEnableEdit = true;
    }
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="row flex-row p-y-10">
            <div className="w-110px">
              <div className="form-group">
                <button className="btn btn-custom btn-sm w-100px" onClick={_handleAddBankAccount}>
                  <i className="fa fa-plus m-r-5" />
                  {Language.getValue('tableAction.add')}
                </button>
              </div>
            </div>
            <div className="w-110px">
              <div className="form-group">
                <button className={'btn btn-custom btn-sm w-100px' + (isEnableEdit ? '' : ' disable')} onClick={_handleEditBankAccount}>
                  <i className="fa fa-edit m-r-5" />
                  {Language.getValue('tableAction.edit')}
                </button>
              </div>
            </div>
            <div className="w-110px">
              <div className="form-group">
                <button className={'btn btn-danger btn-sm w-100px'} disabled={!datas.some((x) => x.checked)} onClick={_handleDeleteBankAccounts}>
                  <i className="fa fa-remove m-r-5" />
                  {Language.getValue('tableAction.delete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const _renderHeader = () => {
    return (
      <thead>
        <tr className="text-center">
          <th width={40} className="center">
            <CheckBox checked={selectedAll} name="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
          </th>
          {columns.map((column, i) => {
            let cls = 'cursor-pointer';
            let width = column.width ? column.width : 'auto';
            let icon = <i className="fa fa-sort fa-sort-thead"></i>;
            if (column.name && column.name.toLowerCase() == paging.orderBy.toLowerCase()) {
              if (paging.orderDirection.toUpperCase() === 'DESC') icon = <i className="fa fa-sort-desc fa-sort-thead"></i>;
              else icon = <i className="fa fa-sort-asc fa-sort-thead"></i>;
            }
            return (
              <th width={width} className={cls} key={i} onClick={() => handleSortColumn(column)}>
                {column.isSort ? icon : null}
                <span>{column.displayName}</span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  const _handleChangeActive = (item, idx, value) => {
    ConfirmDialog('Xác nhận?', 'Bạn chắc chắn muốn thay đổi trạng thái kích hoạt của tài khoản đã chọn? ', () => {
      return new Promise(async (resolve, reject) => {
        setBusy(true);
        setMsg('Đang lưu dữ liệu....');
        const [err, data] = await BankServices.ChangeBankAccountStatus(item.id);
        if (!err && data) {
          setBusy(false);
          setMsg(null);
          resolve({ title: 'Thành công', msg: 'Thay đổi thành công.' });
          let datasUpdate = [...datas];
          let itemUpdate = datasUpdate[idx];
          itemUpdate.isActive = value;
          setDatas(datasUpdate);
        }
        else {
          Notify(NOTIFY.WARNING, 'Có lỗi xảy ra. Vui lòng thử lại sau ít phút!', CONSTANTS.MSG_ERROR);
          setBusy(false);
          setMsg(null);
        }
      });
    });
  }

  const _handleChangeVerify = (item, idx, value) => {
    ConfirmDialog('Xác nhận?', 'Bạn chắc chắn muốn thay đổi trạng thái xác thực của tài khoản đã chọn? ', () => {
      return new Promise(async (resolve, reject) => {
        setBusy(true);
        setMsg('Đang lưu dữ liệu....');
        const [err, data] = await BankServices.VerifyBankAccount(item.id);
        if (!err && data) {
          setBusy(false);
          setMsg(null);
          resolve({ title: 'Thành công', msg: 'Thay đổi thành công.' });
          let datasUpdate = [...datas];
          let itemUpdate = datasUpdate[idx];
          itemUpdate.verified = value;
          setDatas(datasUpdate);
        }
        else {
          Notify(NOTIFY.WARNING, 'Có lỗi xảy ra. Vui lòng thử lại sau ít phút!', CONSTANTS.MSG_ERROR);
          setBusy(false);
          setMsg(null);
        }
      });
    });
  }

  const _renderBody = () => {
    if (datas && datas.length > 0)
      return (
        <tbody>
          {datas.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {
                  <tr key={i} onClick={() => _handleClickRow(i)}>
                    {
                      <td width={40} className={'center'}>
                        <CheckBox checked={item.checked} name={i} id={item.id.toString()} label=" " onCheckedChange={(name, value) => _handleCheckedChange(item, i, value)} />
                      </td>
                    }
                    {columns.map((column, j) => {
                      let align = column.align ? column.align : '';
                      let columnVal =
                        column.type != 'bool' ? (
                          <td className={align} key={j}>
                            <span dangerouslySetInnerHTML={{ __html: item[column.name] ? item[column.name] : column.alternative ? column.alternative : ''}} />
                          </td>
                        ) : (
                          <td className="vertical-align-middle">
                            <label className="switch small">
                              <input
                                type="checkbox"
                                checked={item[column.name]}
                                onChange={(e) => {
                                  column.name === 'isActive'
                                  ? _handleChangeActive(item, i, e.target.checked)
                                  : _handleChangeVerify(item, i, e.target.checked)
                                }} 
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        );
                      return columnVal;
                    })}
                  </tr>
                }
              </React.Fragment>
            );
          })}
        </tbody>
      );
    let columnCount = props.columns ? props.columns.length + (props.headerCheckbox ? 1 : 0) : 10000000;
    return (
      <tbody>
        <tr>
          <td colSpan={columnCount} className="center">
            {Language.getValue('common.noRecords')}
          </td>
        </tr>
      </tbody>
    );
  }

  const handleSortColumn = (column) => {
    let dir = 'asc';
    if (column.name == paging.orderBy) dir = 'desc';
    let newPaging = {
      ...paging,
      orderBy: column.name,
      orderDirection: dir,
    };
    getDatas(newPaging);
  }

  const _handleClickRow = (index) => {
    let data = [...datas];
    data[index].checked = !data[index].checked;
    setDatas(data);
  }

  return (
    <React.Fragment>
      <Breadcrumbs currentPage=" Quản lý tài khoản ngân hàng/Momo" parentPage="Cài đặt" hrefParent="/settings" />
      <AddOrEditBankAccount isShowModal={isShowModal} bankAccount={bankAccount} onClose={() => setIsShowModal(false)} onSaveSucceed={_handleSaveSucceed} banks={banks} />
      <Loading show={isBusy} msg={msg} />
      <CommonTable data={datas} paging={paging} ref={tableRef} tableGuid={tableGuid} headerCheckbox searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
    </React.Fragment>
  );
}

export default BankAccountManagement;
