import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY, PLATFORM, PLATFORM_NAME } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import TFUCategories from "../based/TFUCategories";
import TFUTooltip from "../based/TFUTooltip";
import CheckBox from "../based/inputs/CheckBox";
import TFUImage from "../based/inputs/Image";
import TFUSelect from "../based/inputs/TFUSelect";
import TextArea from "../based/inputs/TextArea";
import TextNumber from "../based/inputs/TextNumber";
import Textbox from "../based/inputs/Textbox";
import PlatformServices from "../based/services/PlatformServices";
import PrintServices from "../based/services/PrintServices";
import useFormValidate from "../hooks/useFormValidate";
import { PrintStepConfigModel } from "../models/PrintStepConfigModel";
import styled from "styled-components";

const categoryRewardPoints = [
    {
        discountLevel: 1,
        point: 0,
        discount: 0,
    },
    {
        discountLevel: 2,
        point: 0,
        discount: 0,
    },
    {
        discountLevel: 3,
        point: 0,
        discount: 0,
    },
];
const PLATFORM_OPTIONS = [
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
    { value: PLATFORM.Tiktok, label: PLATFORM_NAME.Tiktok },
];

export default function CategoryModal(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [category, setCategory] = useState(props.category);
    const { addRef, displayError, isValid } = useFormValidate();
    const [categoryOpf, setCategoryOpf] = useState({});
    const [printStepConfig, setPrintStepConfig] = useState({});
    console.log("categoryOpf", categoryOpf);
    useEffect(() => {
        setCategory(props.category);

        //print step
        let categoryId = props.category ? props.category.id : 0;
        if (categoryId > 0) getConfig(categoryId);
        else {
            let data = new PrintStepConfigModel();
            setPrintStepConfig(data);
        }
    }, [props.isShowModal]);

    const getConfig = async (id) => {
        let [err, data] = await PrintServices.ReadFirstStepConfig(id);
        if (!err) setPrintStepConfig(data);
        else {
            let data = new PrintStepConfigModel();
            data.categoryId = id;
            setPrintStepConfig(data);
        }
    };

    function _handleSave(isContinue) {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu...");
            let cat = { ...category };
            cat.printStepConfig = { ...printStepConfig };
            cat.opfCategories = cat.opfCategories ? cat.opfCategories.filter((x) => x.breadcrumbs != "") : [];
            Request.Post("/api/category/save", cat)
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                        props.onSaveSucceed(isContinue);
                        if (isContinue) setCategory(props.category);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                    }
                })
                .catch((err) => {
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                    if (err.errors) {
                        displayError(err.errors);
                    }
                });
        }
    }

    function _handleChangedCode(value) {
        if (value && !Common.isValidCode(value)) {
            value = category.code;
        }
        setCategory({ ...category, code: value });
    }

    function _handleSelectedCategory(breadcrumbs, opfCategory) {
        let categoryUp = { ...category };
        if (!categoryUp.opfCategories || categoryUp.opfCategories.length == 0) categoryUp.opfCategories = [];

        //lấy ra cat hiện tại
        let curOPF = categoryUp.opfCategories.find((x) => x.id == categoryOpf.id);
        curOPF.id = opfCategory.id;
        curOPF.breadcrumbs = breadcrumbs;
        curOPF.platform = categoryOpf.platform;
        curOPF.lotusCategoryId = categoryUp.id;
        curOPF.name = opfCategory.name;
        curOPF.parentId = opfCategory.parentId;
        curOPF.hasChildren = opfCategory.hasChildren;

        setCategory(categoryUp);
    }

    function _removeCategoryOpf(item) {
        let categoryUp = { ...category };
        let curOPF = categoryUp.opfCategories.find((x) => x.id == item.id);
        if (curOPF) {
            let index = categoryUp.opfCategories.indexOf(curOPF);
            categoryUp.opfCategories.splice(index, 1);
        }

        setCategory(categoryUp);
    }

    async function _syncAttributeByCat(catId, platform) {
        let [err, data] = await PlatformServices.SyncAttributes({
            categoryId: catId,
            platform: platform,
        });
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Đồng bộ thành công");
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Đồng bộ thất bại");
        }
    }

    return (
        <CommonModal
            size="lg"
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={category.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave(false)}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                    {category.id > 0 ? null : (
                        <button className="btn btn-outline-primary btn-sm" onClick={() => _handleSave(true)}>
                            <i className="fa fa-save m-r-5" /> Lưu &amp; tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            {categoryOpf && categoryOpf.isShow && (
                <TFUCategories
                    title={`Ngành hàng ${categoryOpf ? categoryOpf.title : ""}`}
                    isShowModal={categoryOpf ? categoryOpf.isShow : false}
                    onClose={() => setCategoryOpf({ ...categoryOpf, isShow: false })}
                    platform={categoryOpf.platform}
                    onSelected={_handleSelectedCategory}
                />
            )}

            <Wrapper>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="card card-body" style={{ paddingTop: 5 }}>
                            <h5 className="card-title">THÔNG TIN NGÀNH HÀNG</h5>
                            <div className="form-group">
                                <label htmlFor="parentId">Ngành hàng cha:</label>
                                <TFUSelect
                                    name="parentId"
                                    ref={addRef}
                                    options={props.categoryOptions}
                                    value={category.parentId}
                                    placeholder="-- Ngành hàng cha --"
                                    onChangedObject={(selected) => {
                                        if (selected) {
                                            var newCategory = {
                                                ...category,
                                                parentId: selected.value ? selected.value : 0,
                                                categoryRewardPoints: selected.level === 0 ? categoryRewardPoints : [],
                                            };
                                            setCategory(newCategory);
                                        } else {
                                            setCategory({
                                                ...category,
                                                parentId: 0,
                                                categoryRewardPoints: [],
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-7">
                                    <label htmlFor="name" className="required">
                                        Tên ngành hàng:
                                    </label>
                                    <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setCategory({ ...category, name: value })} value={category.name} />
                                </div>
                                <div className="form-group col-md-5">
                                    <label htmlFor="code" className="required">
                                        Mã ngành hàng:
                                    </label>
                                    <TFUTooltip target="quescode" content="Mã gồm các chữ số a-zA-Z0-9 và dấu _">
                                        <i className="fa fa-question-circle" id="quescode" style={{ marginTop: 0, position: "absolute" }} />
                                    </TFUTooltip>
                                    <Textbox id="code" name="code" required ref={addRef} readOnly={category.id > 0 ? true : false} className="form-control" onChanged={(name, value) => _handleChangedCode(value)} value={category.code} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-7">
                                    <label htmlFor="shortLink">Link trên Website:</label>
                                    <Textbox name="shortLink" className="form-control" onChanged={(name, value) => setCategory({ ...category, shortLink: value })} value={category.shortLink} />
                                </div>
                                <div className="form-group col-md-5">
                                    <label htmlFor="index">Thứ tự hiển thị:</label>
                                    <TextNumber
                                        name="index"
                                        ref={addRef}
                                        className="form-control"
                                        onChanged={(name, value) => setCategory({ ...category, index: value })}
                                        onBlur={(name, value) => setCategory({ ...category, index: value ? value : 1 })}
                                        value={category.index}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Mô tả:</label>
                                <TextArea className="form-control" rows="2" value={category.description} onChanged={(name, value) => setCategory({ ...category, description: value })} />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-7">
                                    <label htmlFor="icon" className="col-form-label">
                                        Icon
                                    </label>
                                    <TFUImage image={category.icon} onChanged={(name, value) => setCategory({ ...category, icon: value })} name="icon" />
                                </div>
                                <div className="form-group col-md-5">
                                    <label htmlFor="image" className="col-form-label">
                                        Image
                                    </label>
                                    <TFUImage image={category.image} onChanged={(name, value) => setCategory({ ...category, image: value })} name="image" />
                                </div>
                            </div>
                            <div className="form-group">
                                <CheckBox id="isActive" name="isActive" checked={category.isActive} isInline onCheckedChange={(name, value) => setCategory({ ...category, isActive: value })} label="Kích hoạt" />
                                <CheckBox id="isShowHomePage" name="isShowHomePage" checked={category.isShowHomePage} isInline onCheckedChange={(name, value) => setCategory({ ...category, isShowHomePage: value })} label="Hiển thị trang chủ" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-body" style={{ paddingTop: 5 }}>
                            <h5 className="card-title">NGÀNH HÀNG TRÊN SÀN TMĐT</h5>
                            {PLATFORM_OPTIONS.map((item, k) => {
                                var breadcrumbs = "";
                                var catId = 0;
                                if (category && category.opfCategories && category.opfCategories.length > 0) {
                                    var current = category.opfCategories.find((x) => x.platform == item.value);
                                    if (current) {
                                        breadcrumbs = current.breadcrumbs;
                                        catId = current.id;
                                    }
                                }
                                var listCat = category && category.opfCategories && category.opfCategories.length > 0 ? category.opfCategories.filter((x) => x.platform == item.value) : null;
                                if (!listCat)
                                    return (
                                        <div className="form-group group-platform" key={k} style={{ position: "relative" }}>
                                            <label htmlFor="OPFCategoryId" className="full-width">
                                                {item.label}
                                                <div
                                                    className="badge badge-primary"
                                                    onClick={(e) => {
                                                        let cat = { ...category };
                                                        let opfCat = cat.opfCategories;
                                                        if (!opfCat) opfCat = [];
                                                        opfCat.push({ id: Common.generateGuid(), platform: item.value, breadcrumbs: "", lotusCategoryId: 0, name: "", parentId: 0, hasChildren: false });
                                                        cat.opfCategories = opfCat;
                                                        setCategory(cat);
                                                    }}
                                                >
                                                    Thêm dòng
                                                </div>
                                            </label>
                                        </div>
                                    );
                                else
                                    return (
                                        <div className="form-group group-platform" key={k} style={{ position: "relative" }}>
                                            <label htmlFor="OPFCategoryId" className="full-width">
                                                {item.label}
                                                <div
                                                    className="badge badge-primary"
                                                    onClick={(e) => {
                                                        let cat = { ...category };
                                                        let opfCat = cat.opfCategories;
                                                        if (!opfCat) opfCat = [];
                                                        opfCat.push({ id: Common.generateGuid(), platform: item.value, breadcrumbs: "", lotusCategoryId: 0, name: "", parentId: 0, hasChildren: false });
                                                        setCategory(cat);
                                                    }}
                                                >
                                                    Thêm dòng
                                                </div>
                                            </label>
                                            {listCat.map((x, i) => {
                                                return (
                                                    <div className="row-cat-platform" key={i}>
                                                        <Textbox id="OPFCategoryId" name="OPFCategoryId" value={x.breadcrumbs} readOnly={true} />
                                                        <div>
                                                            <button className="btn btn-custom btn-sm" onClick={() => setCategoryOpf({ ...categoryOpf, isShow: true, platform: item.value, title: item.label, id: x.id })}>
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            <button className="btn btn-danger btn-sm" onClick={() => _removeCategoryOpf(x)}>
                                                                <i className="fa fa-remove" style={{ color: "#fff" }}></i>
                                                            </button>
                                                            {x.id > 0 && (
                                                                <button className="btn btn-custom btn-sm" onClick={() => _syncAttributeByCat(x.id, item.value)}>
                                                                    <i className="fa fa-refresh" style={{ color: "#fff" }}></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                            })}
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="card card-body" style={{ paddingTop: 5 }}>
                            <h5 className="card-title">IN ẤN - BƯỚC GIÁ</h5>
                            <PrintStep
                                addRef={addRef}
                                printStepConfig={printStepConfig}
                                handleInputChange={(name, value) => {
                                    let config = { ...printStepConfig };
                                    config[name] = value;
                                    setPrintStepConfig(config);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

function PrintStep({ printStepConfig = PropTypes.object, addRef = PropTypes.object, handleInputChange = PropTypes.func }) {
    return (
        <div className="form-group row">
            <div className="col-md-2">
                <label htmlFor="costLevel1">Level 1:</label>
                <TextNumber ref={addRef} id="costLevel1" suffix="đồng" name="costLevel1" className="form-control" value={printStepConfig.costLevel1} onChanged={handleInputChange} />
            </div>
            <div className="col-md-2">
                <label htmlFor="costLevel2">Level 2:</label>
                <TextNumber ref={addRef} id="costLevel2" suffix="đồng" name="costLevel2" className="form-control" value={printStepConfig.costLevel2} onChanged={handleInputChange} />
            </div>
            <div className="col-md-2">
                <label htmlFor="costLevel3">Level 3:</label>
                <TextNumber ref={addRef} id="costLevel3" suffix="đồng" name="costLevel3" className="form-control" value={printStepConfig.costLevel3} onChanged={handleInputChange} />
            </div>
            <div className="col-md-2">
                <label htmlFor="costLevel4">Level 4:</label>
                <TextNumber ref={addRef} id="costLevel4" suffix="đồng" name="costLevel4" className="form-control" value={printStepConfig.costLevel4} onChanged={handleInputChange} />
            </div>
            <div className="col-md-2">
                <label htmlFor="costLevel5">Level 5:</label>
                <TextNumber ref={addRef} id="costLevel5" suffix="đồng" name="costLevel5" className="form-control" value={printStepConfig.costLevel5} onChanged={handleInputChange} />
            </div>
        </div>
    );
}

const Wrapper = styled.div`
    .row-cat-platform {
        display: flex;
        width: 100%;
        margin: 6px 0;
    }
    .badge.badge-primary {
        margin-left: auto;
    }

    .form-group label.full-width {
        display: flex;
    }

    .form-group.group-platform {
        display: flex;
        flex-wrap: wrap;
    }

    .form-group.group-platform input#OPFCategoryId {
        flex: 1;
        border-radius: 0;
    }

    .form-group.group-platform button {
        height: 100%;
    }
`;
