import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TFUSelect from "../../based/inputs/TFUSelect";
import { SwichToogle } from "../../based/inputs/SwitchToogle";
import CustomRange from "../../based/inputs/CustomRange";
import TextNumber from "../../based/inputs/TextNumber";
import GTXPrintModel from "../../models/GTXPrintModel";
import CommonModal from "../../based/Modal";
import Common from "../../based/Common";
import PrintServices from "../../based/services/PrintServices";
import { Notify } from "../../based/Notify";
import { CONSTANTS, NOTIFY } from "../../based/Constants";
import ProductServices from "../../based/services/ProductServices";
import ReactSelect from "react-select";
import Textbox from "../../based/inputs/Textbox";

const initShowComponent = {
    isColor: false,
    isColorWhite: false,
    isWhiteColorWhite: false,
    isColorColorWhite: false,
    isTransColor: false,
    is2LayerWhite: false,
    isWhiteColorPause: false,
};

const colorInkOptions = {
    Color: 0,
    ColorWhite: 2,
};

const printMode = {
    Normal: false,
    Eco: true,
};

const options = (optionValue) => {
    if (typeof optionValue === "object")
        return Object.keys(optionValue).map((item) => {
            return { value: optionValue[item], label: item };
        });
    return null;
};

const intitialPaging = {
    pageNumber: 1,
    pageSize: 10,
    totalRecord: 0,
};

const defaultSettingsId = [1, 2];

function GTXPrinterModal(props) {
    const [showComponent, setShowComponent] = useState(initShowComponent);
    const [model, setModel] = useState(new GTXPrintModel());
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [paging, setPaging] = useState({ ...intitialPaging });
    const [isFetching, setIsFetching] = useState(false);
    const [listProductOption, setListProductOption] = useState([]);
    const readOnly = defaultSettingsId.includes(props.id);

    useEffect(() => {
        let id = props.id;
        if (id > 0) {
            getData(id);
        } else {
            let current = new GTXPrintModel();
            setModel(current);

            //set preset trong list uploads
            _renderComponentAs(current);
        }
        return () => {
            setModel(new GTXPrintModel());
        };
    }, [props.id]);

    useEffect(() => {
        getListProduct();
    }, [paging.keyword]);

    const getData = async (id) => {
        setIsBusy(true);
        let [err, data] = await PrintServices.GetGTXConfig(id);
        if (!err) {
            setModel(data);
            _renderComponentAs(data);
        } else setModel(new GTXPrintModel());
        setIsBusy(false);
    };

    const getListProduct = async () => {
        setIsFetching(true);
        const [err, data] = await ProductServices.GetProducts(paging);
        if (!err && data) {
            const options = data.listObjects.map((product) => ({
                value: product.id,
                label: product.name,
                ...product,
            }));
            setListProductOption(options);
        } else {
            setIsFetching(false);
        }
    };

    const _handleSave = async (model) => {
        let [err, data] = await PrintServices.SaveGTXConfig(model);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            props.saveSucceed();
        } else {
            let msg = err && err.message ? err.message : CONSTANTS.MSG_ERROR;
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
    };

    const onChanged = (name, value) => {
        let current = { ...model };
        current[name] = value;
        setModel(current);

        //set preset trong list uploads
        _renderComponentAs(current);
    };

    const _renderComponentAs = (model) => {
        let currentShow = { ...initShowComponent };

        switch (model.byInk) {
            case colorInkOptions.Color:
                currentShow.isColor = true;
                currentShow.isColorColorWhite = true;
                break;
            case colorInkOptions.ColorWhite:
                currentShow.isColorWhite = true;
                currentShow.isWhiteColorWhite = true;
                currentShow.isColorColorWhite = true;
                break;

            case colorInkOptions.White:
                currentShow.isWhiteColorWhite = true;
            default:
                break;
        }

        if (model.bTransColor) currentShow.isTransColor = true;
        if (model.bDivide) currentShow.is2LayerWhite = true;
        if (model.bPause) currentShow.isWhiteColorPause = true;
        setShowComponent(currentShow);
    };

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id == 0 ? "Thêm mới" : "Cập nhật"} onSave={() => _handleSave({ ...model })} onClose={() => props.onClose()} size='lg'>
            <div className={`picture-design-selector `}>
                <div className='d-flex flex-column px-3 w-100'>
                    <h6>Sản phẩm phôi</h6>
                    <div className='d-flex flex-row px-3 py-2'>
                        <div className='w-50'>
                            <ReactSelect
                                id='keyword'
                                name='keyword'
                                isClearable={true}
                                className='react-select-up w-50'
                                classNamePrefix='react-select'
                                placeholder='Nhập tên, mã sản phẩm ...'
                                onInputChange={(value) => setPaging({ ...paging, keyword: value })}
                                value={paging.keyword}
                                options={listProductOption}
                                onChange={(selected) => {
                                    let current = { ...model };
                                    current["productId"] = selected.id;
                                    current["productName"] = selected.name;
                                    setModel(current);
                                }}
                                noOptionsMessage={() => (isFetching ? "Đang tải dữ liệu" : "Không có dữ liệu")}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className='w-50'>
                            <Textbox value={readOnly ? "Mặc định" : model.productName} readOnly />
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column px-3 w-100'>
                    <h6>Main Settings</h6>
                    <SelectItem title='Select Ink' name='byInk' value={model.byInk} onChanged={(name, value) => onChanged(name, +value)} type='selection' options={options(colorInkOptions)} readOnly={readOnly} />
                </div>

                <div className='d-flex flex-column px-3 w-100'>
                    <h6>Color Processing</h6>
                    <SelectItem title='Saturation' name='bySaturation' value={model.bySaturation} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={40} />
                    <SelectItem title='Brightness' name='byBrightness' value={model.byBrightness} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={40} />
                    <SelectItem title='Constrast' name='byContrast' value={model.byContrast} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={40} />

                    {/* Valid only when [Color Ink Only] is specified for [Ink Combination] */}
                    {showComponent.isColor && (
                        <React.Fragment>
                            <h6>Color Ink Setttings</h6>
                            <SelectItem title='Ink Volume' name='byInkVolume' value={model.byInkVolume} onChanged={(name, value) => onChanged(name, value)} type='range' min={1} max={10} />
                            <SelectItem title='Double Print' name='byDoublePrint' value={model.byDoublePrint} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={3} />
                        </React.Fragment>
                    )}

                    {/* Valid only when [Color+White Ink] is specified for [Ink Combination] */}
                    {showComponent.isColorWhite && (
                        <React.Fragment>
                            <h6>Color + White Setttings</h6>
                            <SelectItem title='Mode' name='bEcoMode' value={model.bEcoMode} onChanged={(name, value) => onChanged(name, value)} type='selection' options={options(printMode)} />
                            <SelectItem title='Use background black color' name='bMaterialBlack' value={model.bMaterialBlack} onChanged={(name, value) => onChanged(name, value)} type='boolean' />
                            <SelectItem title='Minimum Whiteness' name='byMinWhite' value={model.byMinWhite} onChanged={(name, value) => onChanged(name, value)} type='range' min={1} max={6} />
                            <SelectItem title='Choke Width' name='byChoke' value={model.byChoke} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={10} />
                            <SelectItem title='White Color Pause' name='bPause' value={model.bPause} onChanged={(name, value) => onChanged(name, value)} type='boolean' />
                        </React.Fragment>
                    )}

                    {/* Valid only when [White Ink Only] or [Color+White Ink] is specified for [Ink Combination] */}
                    {showComponent.isWhiteColorWhite && (
                        <React.Fragment>
                            <h6>White/Color + White Settings</h6>
                            <SelectItem title='Highlight' name='byHighlight' value={model.byHighlight} onChanged={(name, value) => onChanged(name, value)} type='range' min={1} max={9} />
                            <SelectItem title='Mask' name='byMask' value={model.byMask} onChanged={(name, value) => onChanged(name, value)} type='range' min={1} max={5} />
                            <SelectItem title='Transparent Color' name='bTransColor' value={model.bTransColor} onChanged={(name, value) => onChanged(name, value)} type='boolean' />
                            <SelectItem title='Two Layer White' name='bDivide' value={model.bDivide} onChanged={(name, value) => onChanged(name, value)} type='boolean' />
                        </React.Fragment>
                    )}

                    {/* Valid only when [Color Ink Only] or [Color+White Ink] is specified for [Ink Combination] */}
                    {showComponent.isColorColorWhite && (
                        <React.Fragment>
                            <h6>Color Balance</h6>
                            <SelectItem title='Color Multiple Pass Printing' name='bMultiple' value={model.bMultiple} onChanged={(name, value) => onChanged(name, value)} type='boolean' />

                            <SelectItem title='Cyan' name='iCyanBalance' value={model.iCyanBalance} onChanged={(name, value) => onChanged(name, value)} type='range' min={-5} max={5} />
                            <SelectItem title='Magenta' name='iMagentaBalance' value={model.iMagentaBalance} onChanged={(name, value) => onChanged(name, value)} type='range' min={-5} max={5} />
                            <SelectItem title='Yellow' name='iYellowBalance' value={model.iYellowBalance} onChanged={(name, value) => onChanged(name, value)} type='range' min={-5} max={5} />
                            <SelectItem title='Black' name='iBlackBalance' value={model.iBlackBalance} onChanged={(name, value) => onChanged(name, value)} type='range' min={-5} max={5} />
                        </React.Fragment>
                    )}

                    {/* Valid only when true is specified for [Transparent Color] */}
                    {showComponent.isTransColor && (
                        <React.Fragment>
                            <h6>Transparent Settings</h6>
                            <SelectItem title='Color Trans' name='colorTrans' value={model.colorTrans} onChanged={(name, value) => onChanged(name, value)} type='number' />
                            <SelectItem title='Tolerance' name='byTolerance' value={model.byTolerance} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={50} />
                        </React.Fragment>
                    )}

                    {/* Valid only when true is specified for [2 Layer White] */}
                    {showComponent.is2LayerWhite && (
                        <React.Fragment>
                            <h6>2 Layer White Settings</h6>
                            <SelectItem title='Divide Span' name='byDivideSpan' value={model.byDivideSpan} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={50} />
                        </React.Fragment>
                    )}

                    {/* Valid only when true is specified for [White/Color Pause] */}
                    {showComponent.isWhiteColorPause && (
                        <React.Fragment>
                            <h6>White/Color Pause Settings</h6>
                            <SelectItem title='Pause Span' name='byPauseSpan' value={model.byPauseSpan} onChanged={(name, value) => onChanged(name, value)} type='range' min={0} max={50} />
                            <SelectItem title='Uni-Directional Printing' name='bUniPrint' value={model.bUniPrint} onChanged={(name, value) => onChanged(name, value)} type='boolean' />
                        </React.Fragment>
                    )}

                    <hr />
                    <h5>Art-Work có nhiều màu đỏ</h5>
                    <SelectItem title='Red Highlight' name='redHighlight' value={model.redHighlight} onChanged={(name, value) => onChanged(name, value)} type='range' min={1} max={9} />
                    <SelectItem title='Red Mask' name='redMask' value={model.redMask} onChanged={(name, value) => onChanged(name, value)} type='range' min={1} max={5} />
                    <SelectItem title='Red Magenta' name='redMagenta' value={model.redMagenta} onChanged={(name, value) => onChanged(name, value)} type='range' min={-5} max={5} />
                    <SelectItem title='Red Yellow' name='redYellow' value={model.redYellow} onChanged={(name, value) => onChanged(name, value)} type='range' min={-5} max={5} />
                </div>
            </div>
        </CommonModal>
    );
}

GTXPrinterModal.propTypes = {
    id: PropTypes.number,
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    saveSucceed: PropTypes.func,
};

export default GTXPrinterModal;

const SelectItem = ({ title, name, value, onChanged, type, options = [], min = 0, max = 0, readOnly = false, suffix = null }) => {
    let item;
    switch (type) {
        case "selection":
            item = <TFUSelect isDisabled={readOnly} name={name} value={value} options={options || []} onChanged={(value) => onChanged(name, value)} />;
            break;
        case "boolean":
            item = (
                <SwichToogle
                    name={name}
                    value={value}
                    onCheckChanged={(nme, val) => {
                        onChanged(nme, val);
                    }}
                />
            );
            break;
        case "range":
            item = <CustomRange name={name} value={value} min={min} max={max} onChanged={(name, value) => onChanged(name, value)} />;
            break;
        case "number":
            item = <TextNumber name={name} value={value} onChanged={(name, value) => onChanged(name, value)} readOnly={readOnly} suffix={suffix} />;
            break;
        default:
            break;
    }
    return (
        <div className='d-flex flex-row px-3 py-2'>
            <label className='w-50'>{title}</label>
            <div className='w-50'>{item}</div>
        </div>
    );
};
