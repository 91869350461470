import React, { useContext, useEffect, useRef, useState } from "react";
import Autocomplete from "../../based/AutoComplete";
import ColorPicker from "../../based/ColorPicker";
import Common from "../../based/Common";
import { ATTRIBUTE_INPUT_TYPE } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import TShirtSVG from "../../based/TShirtSVG";
import CheckBox from "../../based/inputs/CheckBox";
import TFUOverlay from "../../based/inputs/TFUOverlay";
import TFUSelect from "../../based/inputs/TFUSelect";
import TextNumber from "../../based/inputs/TextNumber";
import Textbox from "../../based/inputs/Textbox";
import AttributeServices from "../../based/services/AttributeServices";
import ProductServices from "../../based/services/ProductServices";
import { ProductContext } from "../../context/ProductContext";

export default function SectionVariants(props) {
    const { mainProduct, setMainProduct, variants, setVariants, attributes, setAttributes, addRef } = useContext(ProductContext);
    const isEditing = mainProduct.id > 0;
    const [stateOverlay, setStateOverlay] = useState({ target: null, isShow: false, contentBackground: "#000000" });
    const [attributesValue, setAttributesValue] = useState(new Map());
    const attRef = useRef();

    useEffect(() => {
        if (mainProduct.categoryId > 0) _addVariant();
    }, [mainProduct.categoryId]);

    function _addVariant() {
        let newVariants = new Map(attributes.entries());
        newVariants.set("", []);
        setAttributes(newVariants);
    }

    function _setProductVariants(attributes) {
        mainProduct.attributes = Array.from(attributes.keys());
        let array = []; //array of map
        attributes.forEach((value, key) => {
            var attribute = props.attributeOptions.find((m) => m.value == key);
            if (attribute && attribute.text)
                array.push(
                    value.map((v, i) => ({
                        id: key,
                        value: v,
                        name: attribute.text,
                        code: attribute.code,
                        isPrintFrameConfig: attribute.isPrintFrameConfig,
                        attributeDataType: attribute.attributeDataType,
                    }))
                );
        });
        let result = [];
        if (array.length >= 2) {
            result = __reduce(array[0], array[1]);
            for (let i = 2; i < array.length; i++) {
                result = __reduce(result, array[i]);
            }
        } else result = array[0];
        let _variants = [];
        if (result && result.length && result.length > 0)
            for (let i = 0; i < result.length; i++) {
                let attributes = Array.isArray(result[i]) ? result[i] : [result[i]];
                let variant = {
                    attributes: attributes,
                    sellPrice: 0,
                    voucherPrice: 0,
                    sellerSKU: "",
                    barcode: "",
                    isActive: true,
                    listImage: [],
                    isShowUploadImage: false,
                };
                _variants.push(variant);
            }
        setVariants(_variants);
    }

    function __reduce(array1, array2) {
        let result = [];
        for (var i = 0; i < array1.length; i++) {
            let arr = Array.isArray(array1[i]) ? Array.from(array1[i]) : [array1[i]];
            for (var j = 0; j < array2.length; j++) {
                let ar = arr.concat(array2[j]);
                result.push(ar);
            }
        }
        return result;
    }

    function _changeAttribute(oldKey, newKey) {
        let newVariants = new Map(attributes.entries());
        newVariants.delete(oldKey);
        let variant = newVariants.has(newKey);
        if (!variant) newVariants.set(newKey, []);
        setAttributes(newVariants);
        _setProductVariants(newVariants);
        _getAttributeValuesOptions(oldKey, newKey);
    }

    async function _getAttributeValuesOptions(oldKey, newKey) {
        let [err, data] = await AttributeServices.GetAttributesValue(newKey);
        let newVariants = new Map(attributesValue.entries());
        newVariants.delete(oldKey);
        let variant = newVariants.has(newKey);
        if (!variant && data) newVariants.set(newKey, data);
        setAttributesValue(newVariants);
    }

    function _changeAttributeValue(key, newValue) {
        let newVariants = new Map(attributes.entries());
        newVariants.set(key, [...new Set(newValue)]);
        setAttributes(newVariants);
        _setProductVariants(newVariants);
    }

    function _renderAttributes() {
        let content = [];
        if (attributes.size > 0) {
            attributes.forEach((value, key) => {
                let currentAttribute = attributesValue ? attributesValue.get(key) : [];
                content.push(
                    <div className="form-row m-t-10" key={key}>
                        <div className="col-sm-6">
                            <TFUSelect options={props.attributeOptions} value={key} noOptionsMessage="Không có dữ liệu" onChanged={(v) => _changeAttribute(key, v)} isDisabled={!Common.IsAdmin()} name="attributeOptions" ref={addRef} />
                        </div>
                        <div className="col-sm-6">
                            {key != null ? (
                                <Autocomplete
                                    suggestion={currentAttribute || []}
                                    tags={value}
                                    onChange={(tag) => {
                                        _changeAttributeValue(key, tag);
                                    }}
                                    onFetch={(value) => {}}
                                    disabled={false}
                                    resetSuggestion={() => {}}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            });
        }
        return (
            <div>
                {content.length > 0 && content.map((m) => m)}
                <div className="my-2">
                    <button type="button" className="btn btn-custom waves-effect w-xs waves-light btn-sm" onClick={_addVariant}>
                        <i className="fa fa-plus" /> Thêm thuộc tính
                    </button>
                    {attributes.size > 0 ? (
                        <span style={{ fontStyle: "italic", float: "right" }}>
                            (<strong>Enter</strong> để thêm thuộc tính với tối đa 50 ký tự)
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }

    function _handleDeleteVariant(id, idx) {
        ConfirmDialog("Xác nhận xóa?", "Sản phẩm đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa sản phẩm đã chọn? ", () => {
            return new Promise(async (resolve, reject) => {
                const [err, data] = await ProductServices.DeleteVariant(id);
                if (!err) {
                    mainProduct.variants.splice(idx, 1);
                    setMainProduct({ ...mainProduct });
                    props.onSetIsEditNoneVariant();
                    resolve({
                        title: "Thành công",
                        msg: "Xóa thành công.",
                    });
                } else {
                    if (err.data) reject({ title: "Có lỗi", msg: err.data });
                    else reject(err);
                }
            });
        });
    }

    function _handleChangeVariant(idx, name, value, attId) {
        let variant = isEditing && !props.isEditNoneVariant ? mainProduct.variants[idx] : variants[idx];
        if (attId >= 0 && variant.attributes[attId]) variant.attributes[attId].value = value;
        else variant[name] = value;
        if (isEditing && !props.isEditNoneVariant) {
            setMainProduct({ ...mainProduct, isRunServices: true });
        } else setVariants([...variants]);
    }

    function _renderAddingVariants() {
        if (!mainProduct.multiVariant || attributes.size == 0) return;
        let columns = [];
        attributes.forEach((v, k) => {
            let attribute = props.attributeOptions.find((m) => m.value == k);
            if (attribute) columns.push(attribute.label);
        });
        columns = columns.concat(["Giá", "Giá Voucher", "SKU", "Barcode"]);
        return (
            <div>
                <table className="table table-add-variants">
                    <thead className="thead-light">
                        <tr className="center">
                            {columns.map((column, i) => (
                                <th key={i}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {variants.map((variant, idx) => {
                            return variant.attributes.length <= 0 ? (
                                <tr />
                            ) : (
                                <React.Fragment key={idx}>
                                    <tr className="center">
                                        {variant.attributes &&
                                            variant.attributes.map((att, i) => (
                                                <td key={i}>
                                                    {att.attributeDataType == ATTRIBUTE_INPUT_TYPE.COLOR_PICKER ? (
                                                        <React.Fragment>
                                                            <span
                                                                className="dot center"
                                                                onMouseEnter={(e) => setStateOverlay({ ...stateOverlay, target: e.target, isShow: true, contentBackground: att.value })}
                                                                onMouseLeave={(e) => setStateOverlay({ ...stateOverlay, isShow: false })}
                                                                ref={attRef}
                                                                style={{ backgroundColor: `${att.value}` }}
                                                            ></span>
                                                        </React.Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span className="center ml-1">{att.value}</span>
                                                </td>
                                            ))}
                                        {__renderVariant(variant, idx)}
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
                <TFUOverlay title="Ảnh sản phẩm" content={<TShirtSVG backgroundColor={stateOverlay.contentBackground} />} show={stateOverlay.isShow} target={stateOverlay.target || false} container={attRef.current} placement={"left"} />
            </div>
        );
    }

    function __renderVariant(variant, idx) {
        return (
            <React.Fragment>
                {!Common.IsAdmin() && (
                    <td>
                        <TextNumber
                            id={`variants[${idx}].ImportPrice`}
                            name={`variants[${idx}].ImportPrice`}
                            ref={addRef}
                            price={true}
                            value={+variant.importVariantPrice + (variant.importInkPrice || 0) || 0}
                            readOnly={true}
                            onChanged={(name, value) => {}}
                        />
                    </td>
                )}
                <td>
                    <TextNumber
                        id={`variants[${idx}].SellPrice`}
                        name={`variants[${idx}].SellPrice`}
                        ref={addRef}
                        price={true}
                        value={+variant.sellPrice || 0}
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, "sellPrice", value);
                        }}
                    />
                </td>
                <td>
                    <TextNumber
                        id={`variants[${idx}].VoucherPrice`}
                        name={`variants[${idx}].VoucherPrice`}
                        ref={addRef}
                        price={true}
                        value={+variant.voucherPrice || 0}
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, "voucherPrice", value);
                        }}
                    />
                </td>
                <td>
                    <Textbox
                        id={`variants[${idx}].SellerSKU`}
                        name={`variants[${idx}].SellerSKU`}
                        ref={addRef}
                        value={variant.sellerSKU}
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, "sellerSKU", value);
                        }}
                    />
                </td>
                <td>
                    <Textbox
                        id={`variants[${idx}].Barcode`}
                        name={`variants[${idx}].Barcode`}
                        ref={addRef}
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, "barcode", value);
                        }}
                        value={variant.barcode}
                    />
                </td>
            </React.Fragment>
        );
    }

    function _renderEditingVariants() {
        let keys = mainProduct.variants && mainProduct.variants[0].attributes && mainProduct.variants[0].attributes.map((att) => att.name);
        let columns = [...keys, "Giá", "Giá Voucher", "SKU", "Barcode"];

        return (
            <table className="table table-edit-variants">
                <thead className="thead-light">
                    <tr>
                        {columns.map((c, i) => (
                            <th key={i}>{c}</th>
                        ))}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {mainProduct.variants.map((variant, idx) => {
                        return (
                            <tr key={idx}>
                                {variant.attributes &&
                                    variant.attributes.map((att, i) => (
                                        <td key={i}>
                                            {Common.isHexColorValue(att.value) ? (
                                                <ColorPicker
                                                    id="colorPicker"
                                                    name="colorPicker"
                                                    value={att.value}
                                                    onChanged={(value) => {
                                                        _handleChangeVariant(idx, `attributes[${att.id}]`, value, i);
                                                    }}
                                                    readOnly
                                                />
                                            ) : (
                                                <Textbox
                                                    onChanged={(name, value) => {
                                                        _handleChangeVariant(idx, name, value, i);
                                                    }}
                                                    price={true}
                                                    name={`attributes[${att.id}]`}
                                                    value={att.value}
                                                    readOnly
                                                />
                                            )}
                                        </td>
                                    ))}
                                {__renderVariant(variant, idx)}
                                <td width="100">
                                    <div className="button-group">
                                        <button className="btn btn-sm btn-danger" onClick={() => _handleDeleteVariant(variant.id, idx)}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                        <button className="btn btn-sm btn-primary m-l-10" onClick={() => props.history.push(`/product-variants-editor/${Common.DetectPlatformName(props.platform)}/${mainProduct.id}/edit/${variant.id}`)}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    function _renderProductVariants() {
        if (isEditing && mainProduct.variants && mainProduct.variants[0]) return _renderEditingVariants();
        return _renderAddingVariants();
    }

    return (
        <div className="section-variant">
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Thuộc tính của sản phẩm</b>
                </h4>
                <div className="row">
                    <div className="col-sm-12">
                        {
                            <CheckBox
                                id="multiVariant"
                                name="multiVariant"
                                checked={mainProduct.multiVariant}
                                onCheckedChange={(name, value) => {
                                    setMainProduct({
                                        ...mainProduct,
                                        multiVariant: value,
                                    });
                                    if (!value) {
                                        setAttributes(new Map());
                                        setVariants([]);
                                    }
                                }}
                                label="Sản phẩm có nhiều thuộc tính. Ví dụ khác nhau về màu sắc, kích thước..."
                            />
                        }
                        {isEditing ? (
                            mainProduct.variants && mainProduct.variants.length > 0 ? (
                                <a
                                    href="#"
                                    className="btn btn-custom waves-effect w-xs waves-light btn-sm mb-2 mr-2"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.history.push(`/product-variants-editor/${Common.DetectPlatformName(props.platform)}/${mainProduct.id}/add`);
                                    }}
                                >
                                    <i className="fa fa-plus" /> Thêm thuộc tính
                                </a>
                            ) : (
                                _renderAttributes()
                            )
                        ) : null}
                        {!isEditing ? _renderAttributes() : ""}
                        {_renderProductVariants()}
                    </div>
                </div>
            </div>
        </div>
    );
}
