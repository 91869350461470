import BaseServices from "./BaseServices";

const BannerServices = {  
    DeleteBanner: async (id) => {
        return await BaseServices.Post(`/api/Banner/delete-banners/${id}`, null);
    },

    GetBannersSetting: async () => {
        return await BaseServices.Get("/api/Banner/get-banners-setting");
    },

    GetCreatorBanners: async () => {
        return await BaseServices.Get("/api/Banner/get-creator-banners");
    },

    SaveBannersSetting: async (banner) => {
        return await BaseServices.Post("/api/Banner/save-banners-setting", banner);
    },
};

export default BannerServices;
