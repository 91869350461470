import React, { useEffect, useState, useRef, forwardRef } from "react";
import CommonModal from "../based/Modal";
import Request from "../based/Request";
import Common from "../based/Common";

const TFUCategories = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [categories, setCategories] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState("");
    const [categorySelected, setCategorySelected] = useState(null);

    useEffect(() => {
        setCategories([]);
        if (props.isShowModal) {
            getCategories(0, 0, true);
        }
    }, [props.isShowModal]);

    function getCategories(id, level, hasChildren) {
        let requestModel = {
            Platform: props.platform,
            ObjectId: id,
        };
        let url = "/api/category/get-opfcategories-by-parent";
        if (hasChildren) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
        }
        Request.Post(url, requestModel)
            .then((res) => {
                if (res.success) {
                    let cat = {
                        id: Common.generateGuid(),
                        level: level + 1,
                        datas: res.data,
                    };
                    let categoriesUp = [...categories];
                    if (categoriesUp.length >= level + 1) {
                        categoriesUp.splice(level, categoriesUp.length - 1);
                    }
                    categoriesUp.push(cat);
                    if (id > 0 && categoriesUp && categoriesUp.length > 0) {
                        categoriesUp.map((item) => {
                            if (item.datas && item.datas.length > 0) {
                                item.datas.map((data) => {
                                    if (item.level == level)
                                        if (data.id == id) data.selected = true;
                                        else data.selected = false;
                                });
                            }
                        });
                    }
                    setCategories(categoriesUp);
                    getCategoryName(categoriesUp);
                    setIsLoading(false);
                    setMsg(null);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setMsg(null);
            });
    }
    function _handleClickCategory(cat, level) {
        getCategories(cat.id, level, cat.hasChildren);
        if (!cat.hasChildren) setCategorySelected(cat);
        else setCategorySelected(null);
    }
    function getCategoryName(categories) {
        if (categories && categories.length > 0) {
            let name = null;
            let arrName = [];
            let categoriesSelected = [];
            categories.map((category) => {
                if (category.datas && category.datas.length > 0) {
                    let catSelected = category.datas.filter((x) => x.selected);
                    if (catSelected && catSelected.length > 0) {
                        catSelected.map((item) => categoriesSelected.push(item));
                        arrName.push(catSelected[0].name);
                    }
                }
            });
            if (arrName && arrName.length > 0) name = arrName.join(" / ");
            setBreadcrumbs(name);
        }
    }
    function _handleSelected() {
        if (props.onSelected) props.onSelected(breadcrumbs, categorySelected);
        props.onClose();
    }

    return (
        <CommonModal
            size="lg"
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            title={props.title ? props.title : "Ngành hàng"}
            customButton={
                <button className={categorySelected ? "btn btn-custom btn-sm" : "btn btn-custom btn-sm disable"} onClick={() => (categorySelected ? _handleSelected() : {})}>
                    <i className="fa fa-save" /> Chọn
                </button>
            }
            onClose={() => props.onClose()}
        >
            {categories && categories.length > 0 ? (
                <div className="form-row wrap-data-cat">
                    {categories.map((cat, c) => {
                        return cat.datas && cat.datas.length ? (
                            <div key={c} className="category-item pd-x-5 col-md-3">
                                <ul className="nav nav-aside row-select-category pd-y-15 ">
                                    {cat.datas.map((item, i) => {
                                        return (
                                            <li key={c + "_" + i} className={item.selected ? "nav-item mb-5 pd-x-15 active" : "nav-item mb-5 pd-x-15"} onClick={() => _handleClickCategory(item, c + 1)}>
                                                <a className="nav-link d-flex">
                                                    <span className="text-wrap">{item.name}</span>
                                                    {item.hasChildren ? <i className="fa fa-angle-right mg-l-auto"></i> : null}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : null;
                    })}
                </div>
            ) : null}
            <div className="form-row">
                <label className="m-r-5">Đã chọn: </label>
                {breadcrumbs && breadcrumbs.length > 0 ? <strong> {breadcrumbs}</strong> : <span> Chưa chọn ngành hàng</span>}
            </div>
        </CommonModal>
    );
});
export default TFUCategories;
