import React, { useState, useEffect } from "react";
import Common from "../../based/Common";
import CommonTable from "../../based/CommonTable";
import Loading from "../../based/Loading";
import StockInPrinterModal from "./StockInPrinterModal";
import StockInHistories from "./StockInHistories";
import BrandServices from "../../based/services/BrandServices";
import TFUDatetime from "../../based/inputs/TFUDatetime";
import WarehouseServices from "../../based/services/WarehouseServices";
import TFUSelect from "../../based/inputs/TFUSelect";
import NumberFormat from "react-currency-format";
import { SLOTOPERATOROBJECTTYPE, NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import { Notify } from "../../based/Notify";

var moment = require("moment");

export default function StockInPrinter(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: -1 });
    const [datas, setDatas] = useState([]);
    const [showModal, setShowModal] = useState({ isShow: false, isAdjust: false });
    const [brandOptions, setBrandOptions] = useState([]);
    const [stockIn, setStockIn] = useState({ objectType: SLOTOPERATOROBJECTTYPE.MainImageLogo });
    const [showHistories, setShowHistories] = useState(false);

    useEffect(() => {
        getDropdownsAllBrands();
        getDatas(paging);
    }, []);
    async function getDropdownsAllBrands() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await BrandServices.GetDropdownsAllBrands();
        if (!error && data) {
            setBrandOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setBrandOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await WarehouseServices.GetStockInLogoPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className='text-center' width='50'>
                        STT
                    </th>
                    <th className='text-center'>Thương hiệu</th>
                    <th className='text-center' width='150'>
                        Loại Logo
                    </th>
                    <th className='text-center' width='150'>
                        Số lượng
                    </th>
                    <th className='text-center' width='200'>
                        Ngày nhập kho cuối cùng
                    </th>
                    <th className='text-center' width='100'></th>
                </tr>
            </thead>
        );
    }
    async function _handleAdjust(e, item) {
        e.preventDefault();
        setStockIn(item);
        setShowModal({ isShow: true, isAdjust: true });
    }
    async function _handleHistories(e, item) {
        e.preventDefault();
        setStockIn(item);
        setShowHistories(true);
    }
    async function _handleDelete(item) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa thông tin tồn kho", () => {
            return new Promise(async (resolve, reject) => {
                let ids = [];
                ids.push(item.id);
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await WarehouseServices.DeleteStockInLogo(item.objectId, item.objectType);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: data });
                    getDatas(paging);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.ERROR, "Có lỗi", error.data ? error.data : "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, k) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;
                        return (
                            <tr key={k}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='vertical-align-middle'>{item.brandName}</td>
                                <td className='vertical-align-middle text-center '>{item.objectTypeName}</td>
                                <td className='text-right vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalQuantity} />
                                </td>
                                <td className='vertical-align-middle text-center '>{item.lastTimeStockInDisplay}</td>
                                <td className='text-center vertical-align-middle text-center '>
                                    <i className='fa fa-eye cursor-poiter text-info' title='Chi tiết' onClick={(e) => _handleHistories(e, item)}></i>
                                    &nbsp;
                                    <i className='fa fa-edit cursor-poiter text-info' title='Điều chỉnh' onClick={(e) => _handleAdjust(e, item)}></i>
                                    &nbsp;
                                    <i className='fa fa-trash cursor-poiter text-danger' title='Xóa' onClick={() => _handleDelete(item)}></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _handleAdd() {
        setShowModal({ isShow: true, isAdjust: false });
        setStockIn({ objectType: SLOTOPERATOROBJECTTYPE.MainImageLogo });
    }
    function _renderAction() {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='brandId'>Thương hiệu: </label>
                            <TFUSelect name='brandId' value={paging.brandId} options={brandOptions} placeholder='---Tất cả---' onChanged={(value) => setPaging({ ...paging, brandId: value != null ? value : 0 })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='time'>Thời gian nhập kho</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas(paging)}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            <button type='button' className='btn btn-custom btn-sm m-r-5' onClick={() => _handleAdd()}>
                                <i className='fa fa-plus m-r-5' /> Thêm mới
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            {showModal.isShow && (
                <StockInPrinterModal
                    showModal={showModal}
                    stockIn={stockIn}
                    onClose={() => setShowModal({ isShow: false, isAdjust: false })}
                    brandOptions={brandOptions}
                    onSaveSucceed={() => {
                        setShowModal({ isShow: false, isAdjust: false });
                        getDatas(paging);
                    }}
                />
            )}
            {showHistories && (
                <StockInHistories
                    stockIn={stockIn}
                    isShowModal={showHistories}
                    onClose={() => {
                        setShowHistories(false);
                        setStockIn({});
                    }}
                />
            )}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} />
        </React.Fragment>
    );
}
