import React, { useEffect, useMemo, useState } from "react";
import Common from "../based/Common";
import { NOTIFY, PRINT_TYPE, PRODUCT_STATUS_APPROVED } from "../based/Constants";
import Radio from "../based/inputs/Radio";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import TextEditor from "../based/inputs/TextEditor";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ProductServices from "../based/services/ProductServices";
import useFormValidate from "../hooks/useFormValidate";

const PRODUCT_DETAIL_TAB = {
    PRODUCT_INFO: "PRODUCT_INFO",
    PRODUCT_IMAGE: "PRODUCT_IMAGE",
    PRODUCT_APPROVED: "PRODUCT_APPROVED",
};

export default function ProductDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [product, setProduct] = useState({});
    const [tabIndex, setTabIndex] = useState();
    const [dataSubmit, setDataSubmit] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(() => {
        if (props.option && props.option.isShow) {
            getDatas(props.option.product.id);
            setTabIndex(PRODUCT_DETAIL_TAB.PRODUCT_INFO);
        }
    }, [props.option]);

    async function getDatas(productId) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ProductServices.GetAgencyProductDetail(productId);
        if (!error && data) {
            setProduct(data);
            let hasImage =
                (data.listImage && data.listImage.length > 0) ||
                (data.variants && data.variants.length > 0 && data.variants.filter((x) => x.listImage && x.listImage.length > 0) && data.variants.filter((x) => x.listImage && x.listImage.length > 0).length > 0);
            if (hasImage) setDataSubmit({ id: data.id, status: PRODUCT_STATUS_APPROVED.APPROVED });
            else setDataSubmit({ id: data.id, status: PRODUCT_STATUS_APPROVED.REJECTED });
            setIsLoading(false);
            setMsg(null);
        } else {
            setProduct({});
            setIsLoading(false);
            setMsg(null);
        }
    }
    const TabHeader = useMemo(() => (
        <div className='p-b-10'>
            <ul className='nav nav-tabs tabs-bordered nav-justified'>
                <li className='nav-item'>
                    <a className={tabIndex == PRODUCT_DETAIL_TAB.PRODUCT_INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setTabIndex(PRODUCT_DETAIL_TAB.PRODUCT_INFO)}>
                        <span className='d-none d-sm-block'>Thông tin sản phẩm</span>
                    </a>
                </li>
                <li className='nav-item'>
                    <a className={tabIndex == PRODUCT_DETAIL_TAB.PRODUCT_IMAGE ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setTabIndex(PRODUCT_DETAIL_TAB.PRODUCT_IMAGE)}>
                        <span className='d-none d-sm-block'>Hình ảnh sản phẩm</span>
                    </a>
                </li>
                {props.option && !props.option.isView && (
                    <li className='nav-item'>
                        <a className={tabIndex == PRODUCT_DETAIL_TAB.PRODUCT_APPROVED ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setTabIndex(PRODUCT_DETAIL_TAB.PRODUCT_APPROVED)}>
                            <span className='d-none d-sm-block'>Duyệt sản phẩm</span>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    ));
    function _renderProduct() {
        return (
            <React.Fragment>
                <div className='col-sm-12 card-box p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>Thông tin cơ bản</b>
                    </h4>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className=' form-row'>
                                <div className='form-group col-md-12'>
                                    <label className='col-form-label' htmlFor='name'>
                                        Tên sản phẩm
                                    </label>
                                    <Textbox name='name' value={product.name} readOnly onChanged={(name, value) => {}} />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-6'>
                                    <label className='col-form-label' htmlFor='brandName'>
                                        Thương hiệu
                                    </label>
                                    <Textbox name='brandName' value={product.brandName} readOnly onChanged={(name, value) => {}} />
                                </div>
                                <div className='form-group col-md-6'>
                                    <label className='col-form-label' htmlFor='categoryName'>
                                        Ngành hàng
                                    </label>
                                    <Textbox name='categoryName' value={product.categoryName} readOnly onChanged={(name, value) => {}} />
                                </div>
                            </div>
                            <div className=' form-row'>
                                <div className='form-group col-md-12'>
                                    <label className='col-form-label' htmlFor='content'>
                                        Mô tả sản phẩm
                                    </label>
                                    <TextEditor name='product.content' readOnly html={product.content ? product.content : null} value={product.content} onChanged={(name, value) => {}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {_renderVariants()}
            </React.Fragment>
        );
    }
    function _renderAttributeItem(attr) {
        let isHex = Common.isHexColorValue(attr.value);
        return (
            <div className='position-relative'>
                <Textbox name={attr.name} value={attr.value} readOnly onChanged={(name, value) => {}} />
                {isHex && <div className='ml-1 position-absolute' style={{ background: attr.value, width: "2rem", height: "1.2rem", top: 5, right: 5 }}></div>}
            </div>
        );
    }
    function _renderVariants() {
        if (product.variants && product.variants.length > 0) {
            let columns = product.variants[0].attributes;
            return (
                <div className='col-sm-12 card-box p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>Thông tin biến thể</b>
                    </h4>
                    <div className='row'>
                        <div className='col-md-12' style={{ maxHeight: 500, overflowY: "auto" }}>
                            <table className='table table-add-variants'>
                                <thead className='thead-light' style={{ position: "sticky", top: "0", zIndex: 1 }}>
                                    <tr>
                                        <th className='center vertical-align-middle' width='100'></th>
                                        <th className='vertical-align-middle' width='50'>
                                            Tên biến thể
                                        </th>
                                        <th className='vertical-align-middle' width='50'>
                                            SKU
                                        </th>
                                        {columns &&
                                            columns.length > 0 &&
                                            columns.map((column, i) => (
                                                <th key={i} width='70'>
                                                    {column.name}
                                                </th>
                                            ))}
                                        <th className='center vertical-align-middle' width='50'>
                                            Loại in
                                        </th>
                                        <th className='center vertical-align-middle' width='100'>
                                            Giá áo
                                        </th>
                                        <th className='center vertical-align-middle' width='100'>
                                            Chi phí in
                                        </th>
                                        <th className='center vertical-align-middle' width='100'>
                                            Tiền bản quyền
                                        </th>
                                        <th className='center vertical-align-middle' width='100'>
                                            Giá bán
                                        </th>
                                        <th className='text-left vertical-align-middle' width='250'>
                                            Cấu trúc chi phí in (Mặt trước)
                                        </th>
                                        <th className='text-left vertical-align-middle' width='250'>
                                            Cấu trúc chi phí in (Mặt sau)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {product.variants.map((variant, idx) => {
                                        return (
                                            variant.attributes &&
                                            variant.attributes.length > 0 && (
                                                <tr key={idx}>
                                                    <td className='vertical-align-middle'>
                                                        <img
                                                            src={variant.productAvatar && variant.productAvatar.length > 0 ? variant.productAvatar : "https://localhost:6001/images_system/no-image.png"}
                                                            className='variant-avatar'
                                                            id='tfutooltip_123456'
                                                        />
                                                    </td>
                                                    <td className='vertical-align-middle'>{variant.name}</td>
                                                    <td className='vertical-align-middle'>{variant.sellerSKU}</td>
                                                    {variant.attributes.map((attr, i) => (
                                                        <td key={i} className='vertical-align-middle'>
                                                            {_renderAttributeItem(attr)}
                                                        </td>
                                                    ))}
                                                    <td className='center vertical-align-middle'>
                                                        {variant.printTypeString}
                                                        <br />
                                                        {variant.printType == PRINT_TYPE.DTG ? (
                                                            <React.Fragment>
                                                                <a
                                                                    onClick={(e) => {
                                                                        window.open(variant.dtgProfile, "_blank");
                                                                    }}
                                                                    className='text-primary d-block'
                                                                >
                                                                    XML Profile (Mặt trước)
                                                                </a>
                                                                <a
                                                                    onClick={(e) => {
                                                                        window.open(variant.dtgProfileBack, "_blank");
                                                                    }}
                                                                    className='text-primary d-block'
                                                                >
                                                                    XML Profile (Mặt sau)
                                                                </a>
                                                            </React.Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                    <td className='center vertical-align-middle'>
                                                        <TextNumber name='productCost' value={variant.productCost} readOnly onChanged={(name, value) => {}} />
                                                    </td>
                                                    <td className='center vertical-align-middle'>
                                                        <TextNumber name='printCost' value={variant.printCost} readOnly onChanged={(name, value) => {}} />
                                                    </td>
                                                    <td className='center vertical-align-middle'>
                                                        <TextNumber name='loyaltyAmount' value={variant.loyaltyAmount} readOnly onChanged={(name, value) => {}} />
                                                    </td>
                                                    <td className='center vertical-align-middle'>
                                                        <TextNumber name='quantity' value={variant.sellPrice} readOnly onChanged={(name, value) => {}} />
                                                    </td>
                                                    <td className='text-left vertical-align-middle'>
                                                        {variant.printType == PRINT_TYPE.DTG
                                                            ? variant.dtgCostStructure &&
                                                              variant.dtgCostStructure.reduce((prev, item, index) => {
                                                                  return (prev += `Loại: ${item.printCostType}, Đơn vị: ${item.unit}, Tổng: ${Common.formatCurrency(item.totalCost)}\n`);
                                                              }, "")
                                                            : variant.petCostStructure &&
                                                              variant.petCostStructure.reduce((prev, item, index) => {
                                                                  return (prev += `Loại: ${item.printCostType}, Đơn vị: ${item.unit}, Tổng: ${Common.formatCurrency(item.totalCost)}\n`);
                                                              }, "")}
                                                    </td>
                                                    <td className='text-left vertical-align-middle'>
                                                        {variant.printType == PRINT_TYPE.DTG
                                                            ? variant.dtgCostStructureBack &&
                                                              variant.dtgCostStructureBack.reduce((prev, item, index) => {
                                                                  return (prev += `Loại: ${item.printCostType}, Đơn vị: ${item.unit}, Tổng: ${Common.formatCurrency(item.totalCost)}\n`);
                                                              }, "")
                                                            : variant.petCostStructureBack &&
                                                              variant.petCostStructureBack.reduce((prev, item, index) => {
                                                                  return (prev += `Loại: ${item.printCostType}, Đơn vị: ${item.unit}, Tổng: ${Common.formatCurrency(item.totalCost)}\n`);
                                                              }, "")}
                                                    </td>
                                                </tr>
                                            )
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }
    function _renderImages() {
        var hasImage =
            (product.listImage && product.listImage.length > 0) ||
            (product.variants && product.variants.length > 0 && product.variants.filter((x) => x.listImage && x.listImage.length > 0) && product.variants.filter((x) => x.listImage && x.listImage.length > 0).length > 0);
        if (hasImage)
            return (
                <React.Fragment>
                    {product.listImage && product.listImage.length > 0 && (
                        <div className='col-sm-12 card-box p-3'>
                            <h4 className='header-title m-t-0'>
                                <b>Ảnh sản phẩm</b>
                            </h4>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-row'>
                                        <div className='ui-product-photo'>
                                            <ul className='ui-product-photo-grid clearfix'>
                                                {product.listImage.map((image, idx) => {
                                                    return (
                                                        <li key={idx} className='ui-product-photo-item col-md-2'>
                                                            <div className='file-upload position-relative'>
                                                                <div className='fileupload-text text-center'>
                                                                    <img src={image.url} style={{ height: "100%", display: "block", objectFit: "contain" }} className='full-width' />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {product.variants &&
                        product.variants.length > 0 &&
                        product.variants.map((variant, idx) => {
                            return (
                                variant.listImage &&
                                variant.listImage.length > 0 && (
                                    <div key={idx} className='col-sm-12 card-box p-3'>
                                        <h4 className='header-title m-t-0'>
                                            {" "}
                                            <b>biến thể: {variant.sellerSKU}</b>
                                        </h4>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='form-row'>
                                                    <div className='ui-product-photo'>
                                                        <ul className='ui-product-photo-grid clearfix'>
                                                            {variant.listImage.map((image, idx) => {
                                                                return (
                                                                    <li key={idx} className='ui-product-photo-item col-md-2'>
                                                                        <div className='file-upload position-relative'>
                                                                            <div className='fileupload-text text-center'>
                                                                                <img src={image.url} style={{ height: "100%", display: "block", objectFit: "contain" }} className='full-width' />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            );
                        })}
                </React.Fragment>
            );
        else
            return (
                <div className='full-width text-center' style={{ background: "#e9ecef", opacity: 1, padding: 100, fontSize: 20 }}>
                    <strong>Sản phẩm chưa có ảnh</strong>
                </div>
            );
    }
    function _renderApprovedContent() {
        return (
            <div className='col-sm-12 card-box p-3'>
                <h4 className='header-title m-t-0'>
                    {" "}
                    <b>Duyệt sản phẩm</b>
                </h4>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className=' form-row'>
                            <div className='col-md-3 form-group'>
                                <Radio
                                    name='status'
                                    isInline
                                    checked={dataSubmit.status == PRODUCT_STATUS_APPROVED.APPROVED}
                                    value={PRODUCT_STATUS_APPROVED.APPROVED}
                                    onCheckedChange={(name, value) => setDataSubmit({ ...dataSubmit, status: value })}
                                    label='Duyệt sản phẩm'
                                />
                            </div>
                            <div className='col-md-3 form-group'>
                                <Radio
                                    name='status'
                                    isInline
                                    checked={dataSubmit.status == PRODUCT_STATUS_APPROVED.REJECTED}
                                    value={PRODUCT_STATUS_APPROVED.REJECTED}
                                    onCheckedChange={(name, value) => setDataSubmit({ ...dataSubmit, status: value })}
                                    label='Từ chối sản phẩm'
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col-md-12'>
                                <label className='col-form-label' htmlFor='notes'>
                                    {dataSubmit.status == PRODUCT_STATUS_APPROVED.APPROVED ? "Ghi chú" : "Lý do từ chối"}
                                </label>
                                <TextArea
                                    name='notes'
                                    className='form-control'
                                    ref={addRef}
                                    required={dataSubmit.status == PRODUCT_STATUS_APPROVED.REJECTED}
                                    value={dataSubmit.notes}
                                    onChanged={(name, value) => setDataSubmit({ ...dataSubmit, notes: value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    async function _handleSave() {
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");
        let [error, data] = await ProductServices.ApprovedProductDesign(dataSubmit);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.SUCCESS, "Thành công", dataSubmit.status == PRODUCT_STATUS_APPROVED.APPROVED ? "Duyệt sản phẩm thành công" : "Từ chối thành công");
            props.onSaveSucceed();
            props.onClose();
        } else {
            setIsLoading(false);
            setMsg(null);
            if (error.errors) displayError(error.errors);
            else Notify(NOTIFY.ERROR, "Lỗi", error.data);
        }
    }
    return (
        <CommonModal
            title='Thông tin sản phẩm'
            show={props.option ? props.option.isShow : false}
            size='tfulg'
            isBusy={isLoading}
            busyMsg={msg}
            onClose={() => props.onClose()}
            customButton={
                props.option &&
                !props.option.isView &&
                tabIndex == PRODUCT_DETAIL_TAB.PRODUCT_APPROVED && (
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave()}>
                        <i className='fa fa-save m-r-5' /> {dataSubmit.status == PRODUCT_STATUS_APPROVED.APPROVED ? "Duyệt sản phẩm" : "Từ chối sản phẩm"}
                    </button>
                )
            }
        >
            {TabHeader}
            {tabIndex == PRODUCT_DETAIL_TAB.PRODUCT_INFO ? _renderProduct() : tabIndex == PRODUCT_DETAIL_TAB.PRODUCT_IMAGE ? _renderImages() : _renderApprovedContent()}
        </CommonModal>
    );
}
