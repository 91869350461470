import React, { useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import Loading from "../based/Loading";
import { RequestDomain } from "../based/RequestDomain";
import GameServices from "./GameServices";

function CuttingModal({ show, onClose }) {
    const [cuttingNumber, setCuttingNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async (cuttingNumber) => {
        setIsLoading(true);
        // Call API
        const [err, data] = await GameServices.SimulatorGame(cuttingNumber);
        if (!err && data > 0) {
            onClose();
            let url = `${RequestDomain.APIUrl()}/api/game/report-export?gameId=${data}&benchPlayer=${0}&benchBanker=${0}`;
            window.open(url, "_blank");
        } else {
            onClose();
        }
        setIsLoading(false);
    };

    return (
        <Wrapper>
            <Loading show={isLoading} msg="Đang xử lý..." />
            <CommonModal
                show={show}
                onClose={() => onClose()}
                onConfirm={() => {
                    handleConfirm(cuttingNumber);
                }}
            >
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Số lá cutting
                    </label>
                    <input
                        type="number"
                        onChange={(e) => {
                            setCuttingNumber(+e.target.value);
                        }}
                        className="form-control"
                        value={cuttingNumber}
                    />
                </div>
            </CommonModal>
        </Wrapper>
    );
}

export default CuttingModal;

const Wrapper = styled.div``;
