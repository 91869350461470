import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import TFUErrorBoundaries from "../based/TFUErrorBoundaries";

export const Home = (props) => {
    const history = useHistory();

    return (
        <Wrapper>
            <TFUErrorBoundaries>
                <div className="container-fluid" style={{ marginBottom: "60px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title float-left">Dashboard</h4>
                                <ol className="breadcrumb float-right">
                                    <li className="breadcrumb-item">
                                        <a>Ranus</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <a>Dashboard</a>
                                    </li>
                                </ol>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                    <div className="check-list">
                        <div className="sub-header">
                            Checklist tạo sản phẩm:
                            <p>1.0 Tạo ngành hàng sản phẩm (Ngành hàng cha/Tên ngành hàng/Bước giá in ấn: Dùng để round up chi phí in ấn)</p>
                            <p>2.0 Tạo thuộc tính. Bắt buộc phải thuộc tính:</p>
                            <p>- Màu (Color): Dùng để hiển thị màu sắc của sản phẩm</p>
                            <p>- Kích thước (Size): Dùng để thiết lập thông tin in ấn của sản phẩm (Khung in ngang/dọc/Khung cách cổ min và maximum/Thông tin của Logo)</p>
                            <p>3.0 Tạo sản phẩm phôi</p>
                            <p>- Giá nhập: dùng cho việc nhập kho</p>
                            <p>- Giá thuộc tính (Giá biến thể): Dùng cho việc bán ra cho Creator, Giá khách hàng mua sẽ trừ đi Voucher Brand để ra được giá này</p>
                            <p>4.0 Tạo thông tin biến thể sản phẩm phôi</p>
                            <p>- Config màu sắc sản phẩm cho các biến thể, và thông số vị trí của ảnh khi render ra trên biến thể đó</p>
                            <p>5.0 Nhập kho</p>
                            <p>6.0 Config thông tin set sản phẩm (Dùng cho việc config khả năng in ấn của sản phẩm (DTG/PET) và các thông số khác)</p>
                        </div>
                    </div>
                    <hr />
                    <div className="button-box col-md-2 row">
                        <label className="col-md-12">Đẩy sản phẩm</label>
                        <button
                            className="btn btn-primary col-md-12 m-1"
                            onClick={() => {
                                history.push("/manage-product-design");
                            }}
                        >
                            Đẩy sản phẩm (Cửa hàng/Thương hiệu)
                        </button>
                        <button
                            className="btn btn-primary col-md-12 m-1"
                            onClick={() => {
                                history.push("/template-management");
                            }}
                        >
                            Đẩy sản phẩm (Tự thiết kế)
                        </button>
                    </div>
                </div>
                <footer className="footer text-right">{new Date().getFullYear()} - Ranus ©</footer>
            </TFUErrorBoundaries>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .button-box {
        padding: 10px;
        border: 1px solid;
    }
`;
