import React, { useState } from "react";
import { useEffect } from "react";
import { NOTIFY } from "../../based/Constants";
import TFUImage from "../../based/inputs/Image";
import Textbox from "../../based/inputs/Textbox";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import MTOServices from "../../based/services/MTOServices";
import useFormValidate from "../../hooks/useFormValidate";

const initCategory = {
    id: 0,
    name: "",
    avatar: "",
};
const AddOrUpdateModal = (props) => {
    const [category, setCategory] = useState({
        ...initCategory,
    });
    const [isLoading, setIsLoading] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    //display msg required cho phần avatar
    const [msgErr, setMsgErr] = useState("");
    useEffect(() => {
        setMsgErr("");
    }, [category.avatar]);
    useEffect(() => {
        setCategory(props.category);
    }, [props.category]);

    function _handleInputChange(key, value, thumbnail) {
        if (value) {
            setCategory({
                ...category,
                avatar: thumbnail,
            });
            document.getElementById("uploadImages").value = null;
        }
    }
    const _handleSave = async () => {
        if (isValid() && category.avatar != "") {
            props.handleSave(category);
            props.onClose();
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", "Có lỗi xảy ra ");
        }
        if (category.avatar === "") return setMsgErr("Thêm ảnh đại diện");
    };
    return (
        <CommonModal show={props.isShow} title={category.id ? "Cập nhật" : "Thêm mới"} onClose={props.onClose} onSave={() => (isValid() && msgErr == "" ? _handleSave() : undefined)}>
            <div className='d-flex justify-content-center'>
                <div className='box-left d-flex flex-column '>
                    <div className='form-group'>
                        <label>Tên bộ sưu tập</label>
                        <Textbox
                            required
                            name='name'
                            ref={addRef}
                            maxLength={500}
                            className='form-control'
                            onChanged={(name, value) =>
                                setCategory({
                                    ...category,
                                    name: value,
                                })
                            }
                            value={category.name}
                        />
                    </div>
                    <div className='form-group'>
                        <label>Hình đại diện:</label>
                        {msgErr ? <label className='text-danger full-width'>{msgErr}</label> : ""}
                        <TFUImage className='hide' id='uploadImages' imageType='mtoDesign' maximumSize={5} name='uploadImages' images={category.avatar} onChanged={_handleInputChange} />
                        <ul className='ui-photo-grid clearfix' style={{listStyleType:'none'}}>
                            <li className='ui-photo-item col-md-3'>
                                {category.avatar ? (
                                    <div
                                        className='ui-photo-box position-relative'
                                        style={{
                                            width: "200px",
                                        }}
                                    >
                                        <img
                                            className='ui-photo-item__image'
                                            src={category.avatar}
                                            style={{
                                                width: "200px",
                                            }}
                                        />
                                        <div className='photo-hover-overlay'>
                                            <ul
                                                className='photo-overlay-actions position-absolute'
                                                style={{
                                                    top: 0,
                                                    right: "10px",
                                                }}
                                            >
                                                <li>
                                                    <button
                                                        className='btn btn-link photo-overlay-actions__link'
                                                        onClick={() =>
                                                            setCategory({
                                                                ...category,
                                                                avatar: "",
                                                            })
                                                        }
                                                    >
                                                        <i
                                                            className='fa fa-trash-o'
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        ></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className='file-upload position-relative'
                                        style={{
                                            width: "140px",
                                        }}
                                        onClick={() => {
                                            document.getElementById("uploadImages").click();
                                        }}
                                    >
                                        <div
                                            className='fileupload-text text-center'
                                            style={{
                                                width: "140px",
                                            }}
                                        >
                                            <i className='fa fa-camera font-size-30px'></i>
                                            <p className='mb-0 mt-2 text-secondary'>Ảnh đại diện</p>
                                        </div>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default AddOrUpdateModal;
