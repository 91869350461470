import BaseServices from "./BaseServices";

const ReturnRequestService = {
    GetTickets: async (paging) => {
        return await BaseServices.Post("/api/Ticket/get-page-ticket-return-order", paging);
    },
    GetTicketReturnOrderDetail: async (ticketId) => {
        return await BaseServices.Get("/api/Ticket/get-ticket-return-order-detail/" + ticketId);
    },
    AddTicketComment: async (comment) => {
        return await BaseServices.Post("/api/Ticket/add-ticket-comment", comment);
    },
    CloseTicket: async (ticketId) => {
        return await BaseServices.Get(`/api/Ticket/close-ticket/${ticketId}`);
    },
    ChangeTicketStatus: async (ticketId, nextStatus, shippingCompanyId) => {
        return await BaseServices.Post(`/api/Ticket/change-ticket-status/${ticketId}?nextStatus=${nextStatus}&shippingCompanyId=${shippingCompanyId}`);
    },
    CalculateShippingFeeByTicketId: async (ticketId) => {
        return await BaseServices.Post(`/api/Ticket/calculate-shipping-fee-by-ticketid?ticketId=${ticketId}`);
    },
    UpdateTicketHistoryStatus: async (ticketId, nextStatus) => {
        return await BaseServices.Get(`/api/Ticket/update-ticket-history-status/${ticketId}/${nextStatus}`);
    },
};
export default ReturnRequestService;
