import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Common from './Common';
import Language from './Language';
import { Notify } from './Notify';
import { NOTIFY } from './Constants';
import Request from './Request';
import CheckBox from './inputs/CheckBox';
import Loading from './Loading';
import NumberFormat from 'react-currency-format';

const initPaging = {
  pageNumber: 1,
  pageSize: 10,
  orderBy: 'id',
  orderDirection: 'asc',
  keyword: '',
};
export class TFUTableProps {
  constructor() {
    this.tableGuid = Common.generateGuid();
    this.search = false;
    this.headerCheckbox = false;
    this.fetchDataUrl = '';
    this.columns = [];
    this.onAddItem = function () {};
    this.onEditItem = function (selectedItem) {};
    this.onSeeDetail = function (selectedItem) {};
    this.onDeleteItem = function (items) {};
    this.onClickRow = function () {};
    this.data = [];
  }
}
/**
 * Common table to fetching data, simple actions (add, edit, delete) and filter, sort function.
 * @param {TFUTableProps} props The table's properties.
 */
const TFUTable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getSelectedItems: () => {
      return data.filter((m) => m.selected);
    },
    getPaging: () => {
      return paging;
    },
    getDatas: () => {
      return data;
    },
  }));
  const [isBusy, setBusy] = useState(true);
  const [paging, setPaging] = useState(props.paging ? Object.assign(initPaging, props.paging) : initPaging);
  const [data, setData] = useState([]);
  const tableId = Common.generateGuid();

  useEffect(() => {
    var newPaging = props.paging ? Object.assign(initPaging, props.paging) : initPaging;
    newPaging.pageSize = paging.pageSize;
    fetchData(newPaging);
  }, [props.tableGuid, props.data]);

  function fetchData(pagingModel) {
    if (props.fetchDataUrl) {
      setBusy(true);
      Request.Post(props.fetchDataUrl, pagingModel)
        .then((res) => {
          setBusy(false);
          if (res.success) {
            setData(res.data.listObjects);
            setPaging(res.data.paging);
          } else {
            setData([]);
          }
        })
        .catch((err) => {
          setData([]);
          setPaging(initPaging);
          setBusy(false);
        });
    } else {
      setData(props.data);
      setPaging(initPaging);
      setBusy(false);
    }
  }

  function getSelectedRows() {
    if (data) return data.filter((m) => m.selected);
    return [];
  }

  function handleAddItem() {
    props.onAddItem();
  }
  function handleEditItem() {
    let selectedRows = getSelectedRows();
    if (selectedRows.length == 0) Notify(NOTIFY.WARNING, 'Chú ý', 'Không có dòng nào được chọn');
    else if (selectedRows.length > 1) Notify(NOTIFY.WARNING, 'Chú ý', 'Chỉ được sửa một dòng duy nhất');
    else if (props.onEditItem) {
      props.onEditItem(selectedRows[0]);
    }
  }
  function handleSeeDetail() {
    let selectedRows = getSelectedRows();
    if (selectedRows.length == 0) Notify(NOTIFY.WARNING, 'Chú ý', 'Không có dòng nào được chọn');
    else if (selectedRows.length > 1) Notify(NOTIFY.WARNING, 'Chú ý', 'Chỉ được sửa một dòng duy nhất');
    else if (props.onSeeDetail) {
      props.onSeeDetail(selectedRows[0]);
    }
  }
  function handleDeleteItem() {
    let selectedRows = getSelectedRows();
    if (selectedRows.length == 0) {
      Notify(NOTIFY.WARNING, 'Chú ý', 'Không có dòng nào được chọn');
    } else if (props.onDeleteItem) {
      props.onDeleteItem(selectedRows);
    }
  }
  function _handleClickRow(item, e) {
    e.preventDefault();
    let idx = data.indexOf(item);
    let newData = [...data];
    newData[idx].selected = !newData[idx].selected;
    setData(newData);
    if (props.onClickRow) props.onClickRow(item);
  }

  function handleSortColumn(column) {
    let dir = 'asc';
    if (column.name == paging.orderBy) dir = paging.orderDirection == 'desc' ? 'asc' : 'desc';
    let newPaging = {
      ...paging,
      orderBy: column.name,
      orderDirection: dir,
    };
    fetchData(newPaging);
  }

  function handleChangePage(page) {
    let newPaging = {
      ...paging,
      pageNumber: page,
    };
    fetchData(newPaging);
  }

  function handleChangePageSize(pageSize) {
    let newPaging = {
      ...paging,
      pageSize: pageSize,
    };
    fetchData(newPaging);
  }

  function handleSearch(keyword) {
    let newPaging = {
      ...paging,
      keyword: keyword,
    };
    fetchData(newPaging);
  }
  function renderAction() {
    if (props.onAddItem || props.onEditItem || props.onSeeDetail || props.onDeleteItem || props.customButtons || props.search)
      return (
        <div className="row">
          <div className="col-sm-12">
            <div className="row flex-row p-10">
              {props.onAddItem ? (
                <div className="m-r-5">
                  <div className="form-group">
                    <button className="btn btn-custom btn-sm " onClick={handleAddItem}>
                      <i className="fa fa-plus m-r-5" />
                      {Language.getValue('tableAction.add')}
                    </button>
                  </div>
                </div>
              ) : null}
              {props.onEditItem ? (
                <div className="m-r-5">
                  <div className="form-group">
                    <button className="btn btn-custom btn-sm " onClick={handleEditItem}>
                      <i className="fa fa-edit m-r-5" />
                      {Language.getValue('tableAction.edit')}
                    </button>
                  </div>
                </div>
              ) : null}
              {props.onSeeDetail ? (
                <div className="m-r-5">
                  <div className="form-group">
                    <button className="btn btn-custom btn-sm " onClick={handleSeeDetail}>
                      <i className="fa fa-info m-r-5" />
                      {Language.getValue('tableAction.detail')}
                    </button>
                  </div>
                </div>
              ) : null}
              {props.onDeleteItem ? (
                <div className="m-r-5">
                  <div className="form-group">
                    <button className="btn btn-danger btn-sm " onClick={handleDeleteItem}>
                      <i className="fa fa-remove m-r-5" style={{ color: '#fff' }} />
                      {Language.getValue('tableAction.delete')}
                    </button>
                  </div>
                </div>
              ) : null}
              {props.customButtons}
              {props.search && <CommonTableFilter keyword={paging.keyword} onSearch={(keyword) => handleSearch(keyword)} />}
            </div>
          </div>
        </div>
      );
    else return null;
  }

  function renderTableHeader() {
    let selectedRows = getSelectedRows();
    return (
      <thead>
        <tr>
          {props.headerCheckbox && (
            <th width={40} className={'center'}>
              <CheckBox checked={data && selectedRows.length > 0 && selectedRows.length == data.length ? true : false} name="select_all" id="select_all" label=" " onCheckedChange={(name, value) => _handleChecked(-1, value)} />
            </th>
          )}
          {props.columns.map((column, i) => {
            let cls = 'cursor-pointer';
            let width = column.width ? column.width : 'auto';
            if (column.align) cls += ` text-${column.align}`;
            let icon = <i className="fa fa-sort fa-sort-thead"></i>;
            if (column.name.toLowerCase() == paging.orderBy.toLowerCase()) {
              if (paging.orderDirection.toUpperCase() === 'DESC') icon = <i className="fa fa-sort-desc fa-sort-thead"></i>;
              else icon = <i className="fa fa-sort-asc fa-sort-thead"></i>;
            }
            return (
              <th width={width} className={cls} key={i} onClick={() => handleSortColumn(column)}>
                {column.isSort == false ? null : icon}
                <span className="ml-3">{column.displayName}</span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
  function _handleChecked(index, value) {
    let newData = [...data];
    if (index == -1) {
      //select all
      newData.map((m) => (m.selected = value));
    } else {
      newData[index].selected = value;
    }
    setData(newData);
  }
  function renderTableBody() {
    if (data && data.length > 0)
      return (
        <tbody>
          {data.map((item, i) => {
            return (
              <tr
                key={i}
                onClick={(e) => {
                  _handleClickRow(item, e);
                }}
              >
                {props.headerCheckbox && (
                  <td width={40} className={'center'}>
                    <CheckBox checked={item.selected ? item.selected : false} name={i.toString()} id={i.toString()} label=" " onCheckedChange={(name, value) => _handleChecked(i, value)} />
                  </td>
                )}
                {props.columns.map((column, j) => {
                  let align = column.align ? column.align : '';
                  let columnVal =
                    column.startTime && column.endTime ? (
                      <td className="center" key={j}>
                       {new Date(item[column.startTime]) < new Date() && new Date() < new Date(item[column.endTime])
                        ? <span className='text-primary'><b>{column.labelHappening}</b></span>
                        : new Date(item[column.startTime]) > new Date()
                          ? <span className='text-secondary'><b>{column.labelNotStartedYet}</b></span>
                          : <span className='text-danger'><b>{column.labelEnded}</b></span>
                      }
                      </td>
                    ) :
                    column.type === 'bool' ? (
                      <td className="center" key={j}>
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id={'checkBox_' + tableId + item[column.name]} checked={item[column.name]} onChange={() => {}} />
                          <label className="custom-control-label" htmlFor={'checkBox_' + tableId + item[column.name]}>
                            {item[column.name] ? column.labelActive : column.labelInActive}
                            &nbsp;
                          </label>
                        </div>
                      </td>
                    ) : column.type === 'price' ? (
                      <td className={'center ' + align} key={j}>
                        {' '}
                        {item[column.name] != null ? <NumberFormat thousandSeparator="," displayType={'text'} decimalSeparator="." decimalScale={2} suffix="đ" value={item[column.name]} /> : null}
                      </td>
                    ) : column.type === 'button' && props.rowButton ? (
                      <td className="center" key={j}>
                        {props.rowButton}
                      </td>
                    ) : column.type === 'date' ? (
                      <td className="center" key={j}>
                        {Common.formatDate(new Date(item[column.name]), 'datetime')}
                      </td>
                    ) : column.type === 'image' ? (
                      <td className={'center ' + align} key={j}>
                        {' '}
                        {item[column.name] != null ? (
                          <img
                            src={item[column.name]}
                            style={{
                              width: 50,
                              height: 50,
                            }}
                          />
                        ) : null}
                      </td>
                    ) : (
                      <td className='center' key={j}>
                        <span>{item[column.name] || ''}</span>
                      </td>
                    );
                  return columnVal;
                })}
              </tr>
            );
          })}
        </tbody>
      );
    let columnCount = props.columns.length + (props.headerCheckbox ? 1 : 0);
    return (
      <tbody>
        <tr>
          <td colSpan={columnCount} className="center">
            {Language.getValue('common.noRecords')}
          </td>
        </tr>
      </tbody>
    );
  }

  function renderTableFoot() {
    let totalRecord = paging.totalRecord,
      pageTotal = totalRecord % paging.pageSize === 0 ? totalRecord / paging.pageSize : (totalRecord - (totalRecord % paging.pageSize)) / paging.pageSize + 1;
    let from = (paging.pageNumber - 1) * paging.pageSize + 1;
    let to = paging.pageSize * paging.pageNumber;

    if (totalRecord == 0) {
      from = 0;
      to = 0;
    } else if (totalRecord < paging.pageSize * paging.pageNumber) {
      to = totalRecord;
    }
    return totalRecord > 0 ? (
      <table className="table table-bordered m-b-0">
        <tfoot>
          <tr>
            <td>
              <div className="form-inline">
                <ul className="pagination pagination-split footable-pagination m-b-0">
                  {paging.pageNumber > 1 &&
                    (paging.pageNumber === 2 ? (
                      <li onClick={() => handleChangePage(1)}>
                        <a className="cursor-pointer">1</a>
                      </li>
                    ) : (
                      [
                        <li onClick={() => handleChangePage(1)} title={1} key={0}>
                          <a className="cursor-pointer">«</a>
                        </li>,
                        <li onClick={() => handleChangePage(paging.pageNumber - 1)} title={paging.pageNumber - 1} key={1}>
                          <a className="cursor-pointer">‹</a>
                        </li>,
                        <li onClick={() => handleChangePage(paging.pageNumber - 1)} key={2}>
                          <a className="cursor-pointer">{paging.pageNumber - 1}</a>
                        </li>,
                      ]
                    ))}
                  <li className="active">
                    <a className="cursor-pointer">{paging.pageNumber}</a>
                  </li>
                  {pageTotal > paging.pageNumber &&
                    (pageTotal === paging.pageNumber + 1 ? (
                      <li onClick={() => handleChangePage(paging.pageNumber + 1)}>
                        <a className="cursor-pointer">{paging.pageNumber + 1}</a>
                      </li>
                    ) : (
                      [
                        <li onClick={() => handleChangePage(paging.pageNumber + 1)} key={0}>
                          <a className="cursor-pointer">{paging.pageNumber + 1}</a>
                        </li>,
                        <li onClick={() => handleChangePage(paging.pageNumber + 1)} title={paging.pageNumber + 1} key={1}>
                          <a className="cursor-pointer">›</a>
                        </li>,
                        <li onClick={() => handleChangePage(pageTotal)} title={pageTotal} key={2}>
                          <a className="cursor-pointer">»</a>
                        </li>,
                      ]
                    ))}
                </ul>
                <span className="table-record">
                  Hiển thị từ {from} đến {to} của {totalRecord} bản ghi
                </span>
                <label className="form-inline">
                  Hiển thị&nbsp;
                  <select className="form-control h-30px" value={paging.pageSize} onChange={(event) => handleChangePageSize(event.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  &nbsp;bản ghi
                </label>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    ) : null;
  }

  return (
    <React.Fragment>
      <div id={tableId}>
        {renderAction()}
        <Loading show={isBusy} msg="Đang tải dữ liệu..." />
        <div className="col-sm-12 card-box p-3">
          <div>
            <div className={'content-table table-rep-plugin scroll '}>
              <table className="table table-bordered table-hover m-b-0 focus-on">
                {renderTableHeader()}
                {renderTableBody()}
              </table>
            </div>
            {renderTableFoot()}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
export default TFUTable;
class CommonTableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: this.props.keyword,
    };
  }
  handleBlur(event) {
    let _this = this;
    let target = event.target;
    if (target.value && target.value.length > 0) {
      _this.setState({ keyword: target.value.trim() });
    }
  }
  handleChangedKeyword(event) {
    let target = event.target;
    this.setState({ keyword: target.value });
  }
  handleKeyPress(event) {
    if (event.charCode === 13) {
      let keyword = this.state.keyword;
      if (keyword && keyword.length > 0) {
        keyword = keyword.trim();
        this.setState({ keyword: keyword });
      }
      this.props.onSearch(keyword);
    }
  }
  render() {
    return (
      <div className="card-box m-b-0 p-10 float-right ml-auto">
        <div className="form-inline">
          <div className="form-group">
            <input type="text" placeholder="Từ khóa" className="form-control" value={this.state.keyword} onChange={this.handleChangedKeyword.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} onBlur={this.handleBlur.bind(this)} />
            <button className="btn btn-default btn-sm ml-3" onClick={() => this.props.onSearch(this.state.keyword)}>
              <i className="fa fa-search m-r-5" /> {Language.getValue('common.btnSearch')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
