import React, { useState } from 'react';
import Radio from '../../based/inputs/Radio';
import TextArea from '../../based/inputs/TextArea';
import Textbox from '../../based/inputs/Textbox';
import CommonModal from '../../based/Modal';
import BaseServices from '../../based/services/BaseServices';
const TransferMoneyGateway =
{
    BankAccount: 1,
    MoMo: 2
}
function PaymentModal(props) {
    const [paymentModel, setPaymentModel] = useState({ paymentType: TransferMoneyGateway.BankAccount })
    const [isBusy, setBusy] = useState(false)
    const _handlePayment = async () => {
        setBusy(true);
        paymentModel.ticketId = props.data.ticketId;
        paymentModel.bankAccountNumber = props.data.bankAccountNumber;
        paymentModel.phoneNumber = props.data.phoneNumber;
        const [err, data] = await BaseServices.Post('/api/finance/transfer-to', paymentModel);
        setBusy(false);
        if (!err) {
            props.onSaveSucceeded();
        } else {
        }
    }
    return (
        <CommonModal
            isBusy={isBusy}
            busyMsg='Đang xử lý...'
            show={props.show}
            size='md'
            title={'Thanh toán cho ' + props.data.creator}
            onSave={_handlePayment}
            onClose={props.onClose}>
            <div className="row">
                <div className="col-12">
                    <form className="form-horizontal" role="form" method="post" onSubmit={_handlePayment}>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Người nhận:</label>
                            <div className='col-md-8'>
                                <label className="form-label">{props.data.creator}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Ngân hàng:</label>
                            <div className='col-md-8'>
                                <label className="form-label">{props.data.bankName}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">STK Ngân hàng:</label>
                            <div className='col-md-8'>
                                <label className="form-label">{props.data.bankAccountName}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Tên chủ STK:</label>
                            <div className='col-md-8'>
                                <label className="form-label">{props.data.bankAccountNumber}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">SĐT Momo:</label>
                            <div className='col-md-8'>
                                <label className="form-label">{props.data.phoneNumber}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Chuyển khoản qua:</label>
                            <div className='col-md-8'>
                                <Radio name='paymentType' isInline
                                    checked={paymentModel.paymentType == TransferMoneyGateway.BankAccount}
                                    onCheckedChange={(name, value) => setPaymentModel({ ...paymentModel, paymentType: TransferMoneyGateway.BankAccount })}
                                    label='Tài khoản ngân hàng'
                                />
                                <Radio name='paymentType' isInline
                                    checked={paymentModel.paymentType == TransferMoneyGateway.MoMo}
                                    onCheckedChange={(name, value) => setPaymentModel({ ...paymentModel, paymentType: TransferMoneyGateway.MoMo })}
                                    label='MOMO'
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Nội dung chuyển khoản:</label>
                            <div className='col-md-8'>
                                <Textbox value={paymentModel.transferDescription} id='transferDescription' name='transferDescription'
                                    onChanged={(name, value) => setPaymentModel({ ...paymentModel, transferDescription: value })} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Mã giao dịch:</label>
                            <div className='col-md-8'>
                                <Textbox value={paymentModel.transferReferenceCode} id='transferReferenceCode' name='transferReferenceCode'
                                    onChanged={(name, value) => setPaymentModel({ ...paymentModel, transferReferenceCode: value })} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 form-label">Ghi chú:</label>
                            <div className='col-md-8'>
                                <TextArea value={paymentModel.note} id='note' name='note'
                                    onChanged={(name, value) => setPaymentModel({ ...paymentModel, note: value })} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </CommonModal>
    );
}

export default PaymentModal;