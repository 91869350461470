import { PLATFORM } from '../based/Constants';

export class WarehousesModel {
  constructor() {
    this.id = 0;
    this.name = '';
    this.location = '';
    this.totalSlots = 0;
    this.totalQuantity = 0;
    this.isActive = false;
    this.totalArea = 0;
    this.totalUsage = 0;
    this.formatAddress = '';
    this.warehouseRegions = [
      {
        regionCode: '',
        slots: [new WareslotsModel()],
      },
    ];
  }
}

export class WareslotsModel {
  constructor() {
    this.index = 0;
    this.code = '';
    this.displayName = '';
    this.warehouseId = 0;
    this.totalProduct = 0;
    this.length = 0;
    this.width = 0;
    this.height = 0;
    this.categories = [];
    this.usageRate = 0;
    this.isActive = true;
  }
}
