import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { INITPAGING, NOTIFY, StockFolderType } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import CustomizeServices from "../based/services/CustomizeServices";
import StockFolderServices from "../based/services/StockFolderServices";

class StockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.parentFolderId = 0;
        this.thumbnail = "";
        this.folderType = StockFolderType.Font;
        this.textId = 0;
    }
}

function AddEditModal({ isShowModal, onClose, onSave, currentParentId, currentId }) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [folder, setFolder] = useState(new StockFolderModel());
    const [fontOptions, setFontOptions] = useState([]);

    useEffect(() => {
        _getFont();
    }, []);

    useEffect(() => {
        setFolder({ ...folder, parentFolderId: currentParentId });
    }, [currentParentId]);

    useEffect(() => {
        if (currentId > 0) _getData(currentId);
    }, [currentId]);

    const _handleSave = async () => {
        let [err, data] = await StockFolderServices.CreateUpdateStockFolder(folder);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const _getData = async (id) => {
        let [err, data] = await StockFolderServices.GetStockFolderById(id);
        if (!err && data) setFolder(data);
        else setFolder(new StockFolderModel());
    };

    const _getFont = async () => {
        let [err, data] = await CustomizeServices.GetTextsPaging({ ...INITPAGING, pageNumber: 1, pageSize: 500 });
        if (!err && data && data.listObjects) {
            let options = data.listObjects.map((item) => ({ value: item.id, label: item.fontFamily }));
            setFontOptions(options);
        } else setFontOptions([]);
    };

    return (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={folder.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <Wrapper>
                <form>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="name" className="d-block my-2 required">
                                {currentId > 0 ? "Tên folder" : currentParentId == 0 ? "Tên folder cha" : "Tên folder con"}
                            </label>
                            <input type="text" value={folder.name} id="name" required className="form-control" onChange={(e) => setFolder({ ...folder, name: e.target.value })} />
                        </div>
                    </div>
                    {currentParentId == 0 && (
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="name" className="d-block my-2 required">
                                    Font chữ
                                </label>
                                <TFUSelect
                                    options={fontOptions}
                                    value={folder.textId}
                                    name="textId"
                                    onChanged={(value) => {
                                        setFolder({ ...folder, textId: +value });
                                    }}
                                    placeholder="--Chọn Font--"
                                />
                            </div>
                        </div>
                    )}
                </form>
            </Wrapper>
        </CommonModal>
    );
}

AddEditModal.propTypes = {};

export default AddEditModal;

const Wrapper = styled.div`
    .preview-thumbnail {
        height: 100px;
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;
