import { v4 as uuid } from 'uuid';
import { PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE } from '../../../based/Constants';

const fetchImageBegin = (state) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };

  updatedItems.isLoading = true;
  updatedState.items = updatedItems;

  return updatedState;
};

const fetchImageFail = (state, err) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };

  updatedItems.isLoading = false;
  updatedItems.isError = true;
  updatedItems.error = err;
  updatedState.items = updatedItems;

  return updatedState;
};

const addImageSuccess = (state, imgUrl) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const newItem = {
    id: uuid(),
    type: ITEM_TYPE.IMAGE,
    data: {
      url: imgUrl,
      isFullWidth: true,
    },
  };

  updatedData.push(newItem);
  updatedItems.isLoading = false;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const removeImageSuccess = (state, itemId) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];
  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  updatedData.splice(itemIdx, 1);
  updatedItems.isLoading = false;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const replaceImageSuccess = (state, { newUrl, oldItemId }) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === oldItemId);
  if (itemIdx < 0) return updatedState;

  const updatedItem = {
    ...updatedData[itemIdx],
    data: {
      ...updatedData[itemIdx].data,
      url: newUrl,
    },
  };

  updatedData[itemIdx] = updatedItem;
  updatedItems.isLoading = false;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const insertImageSuccess = (state, { insertImgUrl, itemId }) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  const insertItem = {
    id: uuid(),
    type: ITEM_TYPE.IMAGE,
    data: {
      url: insertImgUrl,
      isFullWidth: true,
    },
  };

  updatedData.splice(itemIdx, 0, insertItem);
  updatedItems.isLoading = false;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

export default {
  fetchImageBegin,
  fetchImageFail,
  addImageSuccess,
  removeImageSuccess,
  replaceImageSuccess,
  insertImageSuccess,
};
