import React, { useState, useEffect } from 'react'

export class PrintStepConfigModel {
    constructor() {
        this.categoryId = 0;
        this.costLevel1 = 0;
        this.costLevel2 = 0;
        this.costLevel3 = 0;
        this.costLevel4 = 0;
        this.costLevel5 = 0;
    }
}
