import React, { useState, useEffect } from "react";
import CommonModal from "../based/Modal";
import PrintServices from "../based/services/PrintServices";
import NumberFormat from "react-currency-format";

export default function RateCardConfigModal(props) {
    const [readRateCard, setReadRateCard] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    useEffect(() => {
        if (props.readRateCard && props.readRateCard.length > 0) setReadRateCard(props.readRateCard);
        else getReadRateCard();
    }, [props.showRateCard]);
    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    return (
        <CommonModal show={props.showRateCard} isBusy={isLoading} busyMsg={msg} hiddenFooter onClose={() => props.onClose()} backdropClassName={"backdrop__manual"}>
            <div className='col-md-12 card-box p-3'>
                <h4 className='header-title m-t-0'>
                    <b>Thông tin cấu hình</b>
                </h4>
                <div className='table-responsive m-b-10'>
                    <table className='table table-bordered mb-0'>
                        <thead>
                            <tr style={{ background: "#e4e4e4" }}>
                                <th className='text-center' width='50'>
                                    Mức Charge
                                </th>
                                <th className='text-center vertical-align-middle'>Mức tiền bản quyền</th>
                                <th className='text-center vertical-align-middle'>Ranus Operation Fee (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {readRateCard && readRateCard.length > 0 ? (
                                readRateCard.map((item, k) => {
                                    return (
                                        <tr key={k}>
                                            <td className='text-center'>{k + 1}</td>
                                            <td className='text-center'>
                                                <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                            </td>
                                            <td className='text-center'>{item.lotusChargePercent}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan='3'>
                                        <span>Chưa có cấu hình</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </CommonModal>
    );
}
