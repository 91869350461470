import React, { useEffect, useMemo, useRef, useState } from "react";
import { NOTIFY } from "../based/Constants";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TFUDatetime from "../based/inputs/TFUDatetime";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import CampaignServices from "../based/services/CampaignServices";
import useFormValidate from "../hooks/useFormValidate";
import CampaignModel from "../models/Campaign/CampaignModel";

const TAB_SCREENS = {
    INFO: 0,
    PRODUCTS: 1,
};

export default function AddOrEdit(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [campaign, setCampaign] = useState(new CampaignModel());
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { addRef, displayError, isValid } = useFormValidate();
    const { campaignId } = props;

    useEffect(() => {
        if (campaignId > 0) _getCampaignById(campaignId);
        else {
            let cp = new CampaignModel();
            setCampaign(cp);
        }
    }, [props.isShowModal, campaignId]);

    /** handle save */
    async function _handleSave(isContinue) {
        if (isValid()) {
            let obj = { ...campaign };
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await CampaignServices.CreateUpdateCampaignFreeShip(obj);
            if (!err) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setCurrentTabIndex(TAB_SCREENS.INFO);
        }
    }

    /** handle thay đổi tab */
    function _handleChangedTab(tab) {
        setCurrentTabIndex(tab);
        if (tab == TAB_SCREENS.PRODUCTS)
            setTimeout(() => {
                if (document.getElementById("txtSearch")) document.getElementById("txtSearch").focus();
            }, 500);
    }

    /** handle thay đổi thông tin voucher trong voucher brand */
    function _handleOnChanged(name, value) {
        let campaignModel = { ...campaign };
        campaignModel[name] = value;
        setCampaign(campaignModel);
    }

    /** lấy thông tin campaign by id */
    async function _getCampaignById(campaignId) {
        let [err, data] = await CampaignServices.GetCampaignFreeShipById(campaignId);
        if (!err && data) {
            setCampaign(data);
        } else {
            let cp = new CampaignModel();
            setCampaign(cp);
        }
    }

    /**render */
    const TabHeader = useMemo(() => (
        <div className="p-b-10">
            <ul className="nav nav-tabs tabs-bordered nav-justified">
                <li className="nav-item">
                    <a className={currentTabIndex == TAB_SCREENS.INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.INFO)}>
                        <span className="d-none d-sm-block">Thông tin Campaign</span>
                    </a>
                </li>
            </ul>
        </div>
    ));
    function _renderInfo() {
        return (
            <div className="col-md-12 card-box p-3">
                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="title" className="required">
                                Tên Campaign:
                            </label>
                            <Textbox name="title" required ref={addRef} maxLength={200} className="form-control" onChanged={(name, value) => _handleOnChanged(name, value)} value={campaign.title} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="minimumOrderValue" className="required">
                                Giá trị đơn hàng tối thiểu:
                            </label>
                            <TextNumber
                                name="minimumOrderValue"
                                required
                                ref={addRef}
                                maxLength={200}
                                className="form-control"
                                onChanged={(name, value) => {
                                    let cp = { ...campaign };
                                    let campaignBuyMore = cp.campaignFreeShip;
                                    campaignBuyMore.minimumOrderValue = value;
                                    setCampaign(cp);
                                }}
                                value={+campaign.campaignFreeShip.minimumOrderValue}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="maximumDiscount" className="required">
                                Giảm giá tối đa:
                            </label>
                            <TextNumber
                                name="maximumDiscount"
                                required
                                ref={addRef}
                                maxLength={200}
                                className="form-control"
                                onChanged={(name, value) => {
                                    let cp = { ...campaign };
                                    let campaignBuyMore = cp.campaignFreeShip;
                                    campaignBuyMore.maximumDiscount = value;
                                    setCampaign(cp);
                                }}
                                value={+campaign.campaignFreeShip.maximumDiscount}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="showTime" className="required">
                                Ngày hiển thị:
                            </label>
                            <TFUDatetime dateFormat="dd/MM/yyyy HH:mm" showTimeSelect required ref={addRef} name="showTime" minDate={new Date()} value={new Date(campaign.showTime)} onChanged={(newValue) => _handleOnChanged("showTime", newValue)} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="startTime" className="required">
                                Ngày bắt đầu sử dụng:
                            </label>
                            <TFUDatetime
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                required
                                ref={addRef}
                                name="startTime"
                                minDate={campaign.showTime}
                                value={new Date(campaign.startTime)}
                                onChanged={(newValue) => _handleOnChanged("startTime", newValue)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="endTime" className="required">
                                Ngày kết thúc sử dụng:
                            </label>
                            <TFUDatetime
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                required
                                ref={addRef}
                                name="endTime"
                                minDate={campaign.startTime}
                                value={new Date(campaign.endTime)}
                                onChanged={(newValue) => _handleOnChanged("endTime", newValue)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Mô tả:</label>
                    <TextArea ref={addRef} name="description" value={campaign.description} onChanged={(name, value) => _handleOnChanged(name, value)} />
                </div>
            </div>
        );
    }
    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            size="lg"
            title={campaign.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave(false)}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                    {campaign.id > 0 ? null : (
                        <button className="btn btn-outline-primary btn-sm" onClick={() => _handleSave(true)}>
                            <i className="fa fa-save m-r-5" /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            {TabHeader}
            <div className={currentTabIndex == TAB_SCREENS.INFO ? "" : "hide"}>{_renderInfo()}</div>
        </CommonModal>
    );
}
