import BaseServices from "./BaseServices";

const LevelConfigService = {
    GetAllLevelConfig: async () => {
        return await BaseServices.Get("/api/sysLevel/get-all-level-config");
    }, 
    SaveLevelConfig: async (datas) => {
        return await BaseServices.Post("/api/sysLevel/save-config", datas);
    },
    GetRegisteredProductLimit: async () => {
        return await BaseServices.Get('/api/SysLevel/get-registered-product-limit');
    },
};
export default LevelConfigService;
