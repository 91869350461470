import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CheckBox from "../based/inputs/CheckBox";
import TFUSelect from "../based/inputs/TFUSelect";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TemSizeServices from "../based/services/TemSizeServices";
import Common from "../based/Common";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

const modelShipmentSize = {
    id: 0,
    shipmentProductId: 0,
    productVariantId: 0,
    quantity: 0,
    isAdd: true,
};

const ShipmentProductManagement = ({shipmentProductId, productId}) => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [dataDropDown, setDataDropDown] = useState([]);
    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

   

    
    async function getDataDropDown(dataConflix) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetStampSizeByProductId(productId);
        if (!err && data) {
            let newdata = data.filter((item) => !dataConflix.some(i=> i.productVariantId == item.productVariantId))
            const option = newdata.map((item , idx)=>{
                return {
                    value: item.productVariantId,
                    label: item.sizeName + ' - ' + item.colorName + ' - ' + item.sellerSKU
                }
            })
            setDataDropDown(option);
            setBusy(false);
        } else {
            setDataDropDown([]);
            setBusy(false);
        }
    }


    async function getData() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetShipmentSizeStampByShipmentProductId(shipmentProductId);
        if (!err && data) {
            setData(data);
            getDataDropDown(data)

            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>Màu</th>
                    <th>Size</th>
                    <th>Số lượng</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: '100%' , minHeight: '56px', display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                                    <div>{item.colorName}</div>
                                    <div style={{backgroundColor: item.colorCode, width: 100, height: 30}}></div>
                                </td>
                                <td style={{ minWidth: 300 }}> 
                                    {item.isAdd ?
                                        <TFUSelect
                                        className="select"
                                        options={dataDropDown}
                                        placeholder="--Chọn--"
                                        value={item.productVariantId}
                                        onChanged={(value) => {
                                            let dt = [...data];
                                            dt[idx].productVariantId = value;
                                            setData(dt);
                                        }}
                                /> : item.sizeName}
                                </td>
                                <td style={{ width: 100 }}>
                                {item.isUpdate || item.isAdd ? <input type="number"
                                            className="input-in-table"
                                            placeholder="Nhập số lượng"
                                            value={item.quantity}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].quantity = e.target.value;
                                                setData(dt);
                                            }}
                                     /> : item.quantity}
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    {item.isUpdate || item.isAdd ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : <button
                                            className={`btn btn-custom m-1`}
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                   
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                                opacity: dataDropDown.length > 0 ? 1 : 0.3,
                            }}
                            onClick={() => {
                                if(dataDropDown.length > 0){
                                    handleAddItem();
                                }
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {
        return <span></span>;
    }

    async function handleAddItem() {
        setData([ ...data, { ...modelShipmentSize , shipmentProductId: shipmentProductId }]);
    }

    function IsDisable(idx) {
        if (!data[idx].quantity || !data[idx].productVariantId) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await TemSizeServices.CreateUpdateShipmentSizeStamp(dataItem);
        if (!err && dt) {
            getData();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {

            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await TemSizeServices.DeleteShipmentSizeStamp(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }


   

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />

            
        </StyleBody>
    );
};
const StyleBody = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;

    .card-box{
        width: 100%;

        .content-table{
            min-height: 250px;
        }
    }
    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }

    .w-30 {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
    }
    .w-70 {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    
`;
export default ShipmentProductManagement;
