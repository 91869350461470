import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import VoucherServices from "../based/services/VoucherServices";
import { VoucherFreeShipModel } from "../models/VoucherModel";
import AddOrEdit from "./AddOrEdit";
var moment = require("moment");

export default function VoucherFreeship(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentVoucher, setCurrentVoucher] = useState(null);

    useEffect(() => {
        document.getElementById("code").focus();
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await VoucherServices.GetVoucherFreeShipPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center' rowSpan='2'>
                        <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width='150' rowSpan='2' className='text-center'>
                        Mã Voucher
                    </th>
                    <th width='150' className='text-center' rowSpan='2'>
                        Tên Voucher
                    </th>
                    <th width='150' className='text-center' colSpan='2'>
                        Thời gian áp dụng
                    </th>
                    <th width='200' rowSpan='2' className='text-center'>
                        Giá trị đơn hàng tối thiểu: (vnđ)
                    </th>
                    <th width='200' rowSpan='2' className='text-center'>
                        Giảm giá tối đa (vnđ)
                    </th>
                </tr>
                <tr>
                    <th width='150' className='text-center'>
                        Từ ngày
                    </th>
                    <th width='150' className='text-center'>
                        Đến ngày
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center'>
                                    <CheckBox id={i.toString()} name={i.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(i, value)} />
                                </td>
                                <td className='text-center'>
                                    <strong>{item.code}</strong>
                                </td>
                                <td className='text-center'> {item.name}</td>
                                <td className='text-center'> {item.fromDateDisplay}</td>
                                <td className='text-center'> {item.toDateDisplay}</td>
                                <td className='text-center'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.minimumOrderValue} />
                                </td>
                                <td className='text-center'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.maximumDiscount} />
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _handleAdd() {
        setCurrentVoucher(new VoucherFreeShipModel());
        setShowModal(true);
    }
    async function _handleEdit() {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            if (selected != null) {
                setIsLoading(true);
                setMsg("Đang tải dữ liệu...");
                const [err, data] = await VoucherServices.GetVoucherFreeShipById(selected.id);
                if (!err && data) {
                    setIsLoading(false);
                    setMsg(null);
                    setCurrentVoucher(data);
                    setShowModal(true);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.INFO, "Thông báo", CONSTANTS.MSG_ERROR);
                }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn voucher để thực hiện. Vui lòng kiểm tra lại!");
    }
    function _handleDelete() {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await VoucherServices.RemoveVoucherFreeShip(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    }
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='code'>Mã Voucher:</label>
                            <Textbox className='form-control' id='code' name='code' onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='name'>Tên Voucher:</label>
                            <Textbox className='form-control' name='name' onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='time'>Thời gian áp dụng:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleAdd()}>
                                <i className='fa fa-plus m-r-5' /> Thêm mới
                            </button>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnabled ? _handleEdit() : {})}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger btn-sm m-r-5" : "btn btn-danger btn-sm m-r-5 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                                <i className='fa fa-remove m-r-5' /> Xóa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý Vourcher Freeship' />
            <Loading show={isLoading} msg={msg} />
            {showModal && <AddOrEdit isShowModal={showModal} voucher={currentVoucher} onClose={() => setShowModal(false)} onSaveSucceed={() => getDatas(paging)} />}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
