import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { CARD_COLOR } from "../Constants";
import { BtnRounded } from "../content/ct.elm";

const BaseModal = ({ isShow, title, onClose, onSave, customButton, onConfirm, hideClose, nextStep, className, children, width, height }) => {
    useEffect(() => {
        const escFunction = (e) => {
            if (e.keyCode === 27 && onClose) {
                onClose();
            }
        };
        window.addEventListener("keydown", escFunction);
        return () => {
            window.removeEventListener("keydown", escFunction);
            document.body.className = "scroll";
        };
    }, []);

    return (
        <React.Fragment>
            {isShow && <GlobalStyle />}
            <div className={` ${isShow ? "modal__extent__layout active" : "modal__extent__layout"}`}>
                <div className={` ${className} modal__container`} style={{ width: `${width ? width : "1200px"}`, height: `${height ? height : "auto"}` }}>
                    <div className="modal__header">
                        <h4>{title ? title : ""}</h4>
                        <i className="fa fa-times" aria-hidden="true" onClick={onClose}></i>
                    </div>
                    <div className="modal__body">{children}</div>
                    <div className="modal__footer">
                        {customButton ? customButton : null}
                        {onSave ? (
                            <BtnRounded className="mr-3" width="130px" color={CARD_COLOR.Green} onClick={onSave}>
                                Lưu
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                        {onConfirm ? (
                            <BtnRounded className="mr-3" width="130px" color={CARD_COLOR.Green} onClick={onConfirm}>
                                Xác nhận
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                        {nextStep ? (
                            <BtnRounded className="mr-3" width="130px" color={CARD_COLOR.Green} onClick={nextStep}>
                                Tiếp theo
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                        {onClose && !hideClose ? (
                            <BtnRounded width="130px" color={CARD_COLOR.Red} onClick={onClose}>
                                Đóng
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BaseModal;

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden !important;
    touch-action: none;
  }
`;
