import BaseServices from "./BaseServices";
const FlexiComboServices = {
    GetSuggestAppliedProduct: async (paging) => {
        return await BaseServices.Post("/api/flexi/get-suggest-applied-product", paging);
    },
    GetSuggestGiftedProduct: async (paging) => {
        return await BaseServices.Post("/api/flexi/get-suggest-gifted-product", paging);
    },
    CreateUpdateFlexiCombo: async (paging) => {
        return await BaseServices.Post("/api/flexi/create-update-flexi-combo", paging);
    },
    GetPageFlexiCombo: async (paging) => {
        return await BaseServices.Post("/api/flexi/get-page-flexi-combo", paging);
    },
    GetPageById: async (id) => {
        return await BaseServices.Get(`/api/flexi/get-page-by-id/${id}`);
    },
    DeleteById: async (id) => {
        return await BaseServices.Get(`/api/flexi/delete-by-id/${id}`);
    },
};

export default FlexiComboServices;
