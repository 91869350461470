import React, { useState, useEffect } from "react";
import Request from "../based/Request";
import { NOTIFY, CONSTANTS } from "../based/Constants";
import { Notify } from "../based/Notify";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import Textbox from "../based/inputs/Textbox";
import CheckBox from "../based/inputs/CheckBox";
import useFormValidate from "../hooks/useFormValidate";

const ROLE_NAME_REGEX = /^[A-Za-z0-9\s@%+\\\/'!#$^?:.(){}\[\]~\-_]*$/;
const INVALID_ROLE_NAME_MSG = "Tên vai trò chỉ được chứa ký tự chữ cái, chữ số và chỉ được chứa các ký tự đặc biệt sau: @, %, +, , /, ', !, #, $, ^, ?, :, ., (, ), {, }, [, ], ~, -, _";

export default function AddOrEditRole(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [role, setRole] = useState(props.role);
    const [claims, setClaims] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setRole(props.role);
        setClaims(props.claims);
        setSelectedAll(props.claims.filter((x) => x.checked).length == props.claims.length);
    }, [props.isShowModal]);

    function _handleSave() {
        if (role && isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu..");
            let datas = [];
            if (claims && claims.length > 0) {
                claims.map((claim) => {
                    if (claim.permissions && claim.permissions.length > 0) {
                        let pers = claim.permissions.filter((x) => x.checked);
                        if (pers && pers.length > 0) {
                            pers.map((per) => datas.push(per.code));
                        }
                    }
                });
            }
            Request.Post("/api/role/save", { ...role, claims: datas })
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu vai trò thành công");
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                        props.onSaveSucceed();
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                    }
                })
                .catch((err) => {
                    if (err.errors) displayError(err.errors);
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                });
        } else {
            document.querySelector('label[for="name"]').scrollIntoView();
        }
    }
    function handleCheckedFunction(name, value, idx) {
        let claimsUpdate = [...claims];
        if (claimsUpdate && claimsUpdate.length > 0) {
            claimsUpdate[idx].checked = value;
            let permissionUpdate = claimsUpdate[idx].permissions;
            if (permissionUpdate && permissionUpdate.length > 0) {
                permissionUpdate.map((per) => (per.checked = value));
            }
            setClaims(claimsUpdate);
            setSelectedAll(claimsUpdate.filter((x) => x.checked).length == claims.length);
        }
    }
    function handlePermissionChanged(name, value, idxFunc, idxPer) {
        let claimsUpdate = [...claims];
        if (claimsUpdate && claimsUpdate.length > 0) {
            if (claimsUpdate[idxFunc] && claimsUpdate[idxFunc].permissions && claimsUpdate[idxFunc].permissions.length > 0) {
                claimsUpdate[idxFunc].permissions[idxPer].checked = value;
                claimsUpdate[idxFunc].checked = claimsUpdate[idxFunc].permissions.filter((x) => x.checked).length == claimsUpdate[idxFunc].permissions.length;
                setClaims(claimsUpdate);
                setSelectedAll(claimsUpdate.filter((x) => x.checked).length == claims.length);
            }
        }
    }
    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let claimsUpdate = claims.map((item) => {
            item.checked = value;
            if (item.permissions && item.permissions.length > 0) item.permissions.map((per) => (per.checked = value));
            return item;
        });
        setClaims(claimsUpdate);
    }
    function renderTreePermission() {
        return claims && claims.length > 0
            ? claims.map((claim, c) => (
                  <div key={c} className='form-group col-md-3 col-sm-3 col-xs-12 permission-item'>
                      <CheckBox
                          key={claim.functionCode}
                          id={claim.functionCode}
                          name={claim.functionCode}
                          label={claim.functionName}
                          className='form-control'
                          onCheckedChange={(name, value) => handleCheckedFunction(name, value, c)}
                          checked={claim.checked}
                      />
                      {claim.permissions
                          ? claim.permissions.map((per, p) => (
                                <div className='child-group' key={p}>
                                    <CheckBox key={per.code} id={per.code} name={per.code} label={per.displayName} className='form-control' onCheckedChange={(name, value) => handlePermissionChanged(name, value, c, p)} checked={per.checked} />
                                </div>
                            ))
                          : null}
                  </div>
              ))
            : null;
    }
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} size='lg' isBusy={isModalLoading} busyMsg={msgModalLoading} title={role.id == 0 ? "Thêm mới" : "Cập nhật"} onSave={() => _handleSave()} onClose={() => props.onClose()}>
            <div className='form-row'>
                <div className='form-group col-8'>
                    <label htmlFor='name' className='required'>
                        Tên vai trò:
                    </label>
                    <Textbox
                        required
                        id='name'
                        name='name'
                        ref={(ref) => addRef(ref, { pattern: ROLE_NAME_REGEX, invalidMsg: INVALID_ROLE_NAME_MSG })}
                        maxLength={255}
                        className='form-control'
                        onChanged={(name, value) => setRole({ ...role, name: value })}
                        value={role.name}
                    />
                </div>
                <div className='form-group col-4' style={{ top: 35 }}>
                    <CheckBox name='isAdmin' label='Quyền quản trị' className='form-control' onCheckedChange={(name, value) => setRole({ ...role, isAdmin: value })} checked={role.isAdmin} />
                </div>
            </div>
            <div className='form-group'>
                {claims && claims.length > 0 ? (
                    <React.Fragment>
                        <label htmlFor='roles' style={{ background: "#e4e4e4 " }}>
                            Quyền truy cập:
                        </label>
                        <div className='pull-right' style={{ fontWeight: "bold" }}>
                            <CheckBox checked={selectedAll} name='select_all' id='select_all' label='Chọn tất cả' onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                        </div>
                        <div className='form-row'>{renderTreePermission()}</div>
                    </React.Fragment>
                ) : null}
            </div>
        </CommonModal>
    );
}
