export class App_TargetGroupModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.description = "";
        this.spinnerLink = "";
        this.spinnerImageUrl = "";
        this.app_TargetGroups = [];
        this.app_TargetGroupIndices = [];
    }
}

export class App_TargetGroupVoucherModel {
    constructor() {
        this.id = 0;
        this.groupId = 0;
        this.startTime = new Date();
        this.endTime = new Date();
        this.voucherId = 0;
        this.quantity = 1;
        this.maximumReleaseSameIP = 1;
        this.percentageRelease = 0;
        this.timeFrameIndex = 1;
    }
}

export class App_TargetGroupIndexModel {
    constructor() {
        this.groupId = 0;
        this.voucherId = 0;
        this.applyDate = new Date();
        this.index = 1;
        this.percentageRelease = 0;
    }
}
