import React, { useContext } from "react";
import TextNumber from "../based/inputs/TextNumber";
import { ProductVariantContext } from "../context/ProductVariantContext";

export default function SectionShipping(props) {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);

    return (
        <div className="section-productVariants-shipping col-md-12 card-box p-3">
            <h4 className="header-title m-t-0">
                <b>Thông tin vận chuyển</b>
            </h4>
            <div className="row">
                <div className="col-md-4">
                    <label htmlFor="variantLength" className="col-form-label">
                        Chiều dài (cm)
                    </label>
                    <TextNumber
                        key={`variantLength[${editingVariant.id}]`}
                        id="variantLength"
                        name="variantLength"
                        price={false}
                        ref={addRef}
                        min={1}
                        max={999999}
                        suffix="cm"
                        value={editingVariant.length}
                        onChanged={(name, value) => setEditingVariant({ ...editingVariant, length: value, isModified: true })}
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="variantWidth" className="col-form-label">
                        Chiều rộng (cm)
                    </label>
                    <TextNumber
                        key={`variantWidth[${editingVariant.id}]`}
                        id="variantWidth"
                        name="variantWidth"
                        price={false}
                        ref={addRef}
                        min={1}
                        max={999999}
                        suffix="cm"
                        value={editingVariant.width}
                        onChanged={(name, value) => setEditingVariant({ ...editingVariant, width: value, isModified: true })}
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="variantHeight" className="col-form-label">
                        Chiều cao (cm)
                    </label>
                    <TextNumber
                        key={`variantHeight[${editingVariant.id}]`}
                        id="variantHeight"
                        name="variantHeight"
                        price={false}
                        ref={addRef}
                        min={1}
                        max={999999}
                        suffix="cm"
                        value={editingVariant.height}
                        onChanged={(name, value) => setEditingVariant({ ...editingVariant, height: value, isModified: true })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-4">
                    <label htmlFor="variantShippingWeight" className="col-form-label">
                        Khối lượng kiện hàng (gram)
                    </label>
                    <TextNumber
                        key={`variantShippingWeight[${editingVariant.id}]`}
                        id="variantShippingWeight"
                        name="variantShippingWeight"
                        price={false}
                        ref={addRef}
                        min={1}
                        max={999999}
                        suffix="gram"
                        value={editingVariant.shippingWeight}
                        onChanged={(name, value) => setEditingVariant({ ...editingVariant, shippingWeight: value, isModified: true })}
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="packageVolume" className="col-form-label">
                        Thể tích kiện hàng (ml)
                    </label>
                    <TextNumber
                        key={`packageVolume[${editingVariant.id}]`}
                        id="packageVolume"
                        name="packageVolume"
                        price={false}
                        ref={addRef}
                        min={1}
                        max={999999}
                        suffix="ml"
                        value={editingVariant.volume}
                        onChanged={(name, value) => setEditingVariant({ ...editingVariant, volume: value, isModified: true })}
                    />
                </div>
            </div>
        </div>
    );
}
