import React, { useState } from 'react'
import Content from '../../../based/content/Content'
import NavbarLeft from '../NavbarLeft/NavbarLeft'
import NotificationRight from '../NotificationRight/NotificationRight'
import TopBar from '../TopBar/TopBar'

const LayoutLight = ({children}) => {
    const [isToggleNav, setIsToggleNav] = useState(true)
    const handleToggle = () => {
        setIsToggleNav(!isToggleNav)
    }
    return (
        <div id='wrapper' style={{background:'#f7f7ff!important'}}>
            <TopBar />
            <NavbarLeft toggle={handleToggle} />
            <Content toggle={isToggleNav} >
                {children}
            </Content>
            {/* <NotificationRight /> */}
        </div>
    )
}

export default LayoutLight
