import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

export default function ColorPicker(props) {
    const [color, setColor] = useState(props.value);
    const id = uuid();
    var colorWell;
    var defaultColor = "#000000";

    useEffect(() => {
        let value = props.value;
        if (!value) value = defaultColor;
        startUp(value);
    }, []);

    useEffect(() => {
        setColor(props.value);
    }, [props.value]);

    function startUp(value) {
        colorWell = document.getElementById(id);
        colorWell.value = value;
        colorWell.addEventListener("input", onInput, false); //change each times
        colorWell.addEventListener("change", onChange, false); //change when dismiss color picker
    }

    function onInput(e) {
        setColor(e.target.value);
    }

    function onChange(e) {
        props.onChanged(e.target.value);
    }

    return (
        <Container className={`form-group w-100 ${props.readOnly ? "disabled" : ""}`}>
            <input id={id} name="colorPicker" type="color" value={color} onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e) : null)} onChange={(e) => {}} />
            <input htmlFor="colorPicker" type="text" value={props.value} onChange={(e) => {}} />
        </Container>
    );
}

const Container = styled.span`
    display: inline-flex;
    align-items: center;
    width: 150px;
    max-width: 100%;
    padding: 4px 12px;
    border: 1px solid #bfc9d9;
    border-radius: 4px;

    input[type="color"] {
        margin-right: 8px;
        -webkit-appearance: none;
        border: none;
        width: auto;
        height: auto;
        cursor: pointer;
        background: none;
        &::-webkit-color-swatch-wrapper {
            padding: 0;
            width: 14px;
            height: 14px;
        }
        &::-webkit-color-swatch {
            border: 1px solid #bfc9d9;
            border-radius: 4px;
            padding: 0;
        }
    }

    input[type="text"] {
        border: none;
        width: 100%;
        font-size: 14px;
    }
`;
