import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from 'react';

import TextNumber from '../../based/inputs/TextNumber';
import TFUDatetime from '../../based/inputs/TFUDatetime';
import { PRODUCT_STATUS_TYPE, STOCK_IN_STATUS, PRODUCT_TYPE } from '../../based/Constants';
import TFUSelect from '../../based/inputs/TFUSelect';
import Textbox from '../../based/inputs/Textbox';
import TFUTooltip from '../../based/TFUTooltip';
import { StockInContext } from '../../context/StockInContext';
import WarehouseServices from '../../based/services/WarehouseServices';

const StockInModalItem = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({ displayError, validateInput, props }));

  const [product, setProduct] = useState(props.product);
  const [productStatusOptions, setProductStatusOptions] = useState([]);
  const [stockValidateObject, setStockValidateObject] = useState({});
  const [valid, setValid] = useState({ isValid: true, msgInvalid: '' });

  useEffect(() => {
    _renderProductStatusOptions();
  }, []);

  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  useEffect(() => {
    if (props.stockValidation) _renderStockValidateObject();
  }, [props.stockValidation]);

  const displayError = (msg) => setValid({ isValid: false, msgInvalid: msg });

  const validateInput = () => {
    if (product.hasImei || product.hasSerialNumber) {
      if (product.extendData.length !== product.quantity) {
        return 'Cần bổ sung thông tin sản phẩm.';
      }
    } else if (product.hasExpDate) {
      if (product.quantity > 0 && product.extendData.length === 0) {
        return 'Cần bổ sung thông tin sản phẩm.';
      }
    }
    return '';
  };

  function _renderProductStatusOptions() {
    let productStatus = { ...PRODUCT_STATUS_TYPE };
    let options = [];
    for (const [key, value] of Object.entries(productStatus)) {
      let sub = { value: value, text: key, label: key };
      options.push(sub);
    }
    setProductStatusOptions(options);
  }

  function _renderStockValidateObject() {
    let stockValidateFilter = props.stockValidation && props.stockValidation.length > 0 ? props.stockValidation.filter((item) => item.productVariantId == product.productVariantId) : [];
    let stockValidateObject = stockValidateFilter && stockValidateFilter.length > 0 ? stockValidateFilter[0].stockObject : null;

    setStockValidateObject(stockValidateObject);
  }

  const renderComplementExtendData = () => {
    if (product.isExpandExtendData && product.hasExtendData && product.quantity > 0 && props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess) {
      var complementExtendDataElement = [];
      complementExtendDataElement.push(
        <tr key="headerinfo">
          <th className="center" width="60">
            STT
          </th>
          {product.hasExpDate ? (
            <React.Fragment>
              <th className="center">Ngày sản xuất</th>
              <th className="center">Hạn sử dụng</th>
            </React.Fragment>
          ) : (
            ''
          )}
          {product.hasImei ? <th className="center">Số imei</th> : ''}
          {product.hasSerialNumber ? <th className="center">Số serial</th> : ''}
        </tr>
      );
      if (product.hasImei || product.hasSerialNumber) {
        for (var i = 0; i < product.quantity; i++) {
          complementExtendDataElement.push(
            <tr key={i}>
              <td className="center">{i + 1}</td>
              {product.hasExpDate && i === 0 ? (
                <React.Fragment>
                  <td rowSpan={product.quantity}>
                    <TFUDatetime
                      ref={props.addRefParent}
                      required
                      name={`products[${props.index}].ExtendData[${i}].ManufacturingDate`}
                      placeholder="ngày sản xuất"
                      dateFormat="dd/MM/yyyy"
                      value={product.extendData && product.extendData[i] && product.extendData[i].manufacturingDate ? product.extendData[i].manufacturingDate : null}
                      onChanged={(value) => {
                        var newExtendData = product.extendData;
                        for (var j = 0; j < product.quantity; j++) {
                          if (!!newExtendData && !!newExtendData[j]) newExtendData[j].manufacturingDate = value;
                          else newExtendData[j] = { imei: '', serialNumber: '', manufacturingDate: value, expiredDate: null };
                        }
                        setValid({ isValid: true, msgInvalid: '' });
                        props.handlePropertyChanged(props.index, 'extendData', newExtendData);
                      }}
                    />
                  </td>
                  <td rowSpan={product.quantity}>
                    <TFUDatetime
                      ref={props.addRefParent}
                      required
                      name={`products[${props.index}].ExtendData[${i}].ExpiredDate`}
                      placeholder="hạn sử dụng"
                      dateFormat="dd/MM/yyyy"
                      value={product.extendData && product.extendData[i] && product.extendData[i].expiredDate ? product.extendData[i].expiredDate : null}
                      onChanged={(value) => {
                        var newExtendData = product.extendData;
                        for (var j = 0; j < product.quantity; j++) {
                          if (!!newExtendData && !!newExtendData[j]) newExtendData[j].expiredDate = value;
                          else newExtendData[j] = { imei: '', serialNumber: '', manufacturingDate: null, expiredDate: value };
                        }
                        setValid({ isValid: true, msgInvalid: '' });
                        props.handlePropertyChanged(props.index, 'extendData', newExtendData);
                      }}
                    />
                  </td>
                </React.Fragment>
              ) : (
                ''
              )}
              {product.hasImei ? (
                <td>
                  <Textbox
                    ref={props.addRefParent}
                    required
                    name={`products[${props.index}].ExtendData[${i}].Imei`}
                    placeholder="mã imei"
                    value={product.extendData && product.extendData[i] && product.extendData[i].imei ? product.extendData[i].imei : ''}
                    onChanged={(name, value) => {
                      var extendDataIndex = +name.split('ExtendData[')[1].split(']')[0];
                      var newExtendData = product.extendData;
                      if (!!newExtendData && !!newExtendData[extendDataIndex]) {
                        newExtendData[extendDataIndex].imei = value;
                      } else {
                        for (var j = 0; j <= extendDataIndex; j++) {
                          if (!!newExtendData && !!newExtendData[j]) {
                          } else {
                            newExtendData[j] = { imei: '', serialNumber: '', manufacturingDate: null, expiredDate: null };
                          }
                        }
                        newExtendData[extendDataIndex].imei = value;
                      }
                      setValid({ isValid: true, msgInvalid: '' });
                      props.handlePropertyChanged(props.index, 'extendData', newExtendData);
                    }}
                  />
                </td>
              ) : (
                ''
              )}
              {product.hasSerialNumber ? (
                <td>
                  <Textbox
                    ref={props.addRefParent}
                    required
                    name={`products[${props.index}].ExtendData[${i}].SerialNumber`}
                    placeholder="số serial"
                    value={product.extendData && product.extendData[i] && product.extendData[i].serialNumber ? product.extendData[i].serialNumber : ''}
                    onChanged={(name, value) => {
                      var extendDataIndex = +name.split('ExtendData[')[1].split(']')[0];
                      var newExtendData = product.extendData;
                      if (!!newExtendData && !!newExtendData[extendDataIndex]) {
                        newExtendData[extendDataIndex].serialNumber = value;
                      } else {
                        for (var j = 0; j <= extendDataIndex; j++) {
                          if (!!newExtendData && !!newExtendData[j]) {
                          } else {
                            newExtendData[j] = { imei: '', serialNumber: '', manufacturingDate: null, expiredDate: null };
                          }
                        }
                        newExtendData[extendDataIndex].serialNumber = value;
                      }
                      setValid({ isValid: true, msgInvalid: '' });
                      props.handlePropertyChanged(props.index, 'extendData', newExtendData);
                    }}
                  />
                </td>
              ) : (
                ''
              )}
            </tr>
          );
        }
      } else {
        complementExtendDataElement.push(
          <tr key="all">
            <td className="center">Tất cả</td>
            <td>
              <TFUDatetime
                ref={props.addRefParent}
                required
                name={`products[${props.index}].ExtendData[0].ManufacturingDate`}
                placeholder="ngày sản xuất"
                dateFormat="dd/MM/yyyy"
                value={product.extendData && product.extendData[0] && product.extendData[0].manufacturingDate ? product.extendData[0].manufacturingDate : null}
                onChanged={(value, name) => {
                  var newExtendData = product.extendData;
                  if (!!newExtendData && !!newExtendData[0]) {
                    newExtendData[0].manufacturingDate = value;
                  } else {
                    newExtendData[0] = { imei: '', serialNumber: '', manufacturingDate: value, expiredDate: null };
                  }
                  setValid({ isValid: true, msgInvalid: '' });
                  props.handlePropertyChanged(props.index, 'extendData', newExtendData);
                }}
              />
            </td>
            <td>
              <TFUDatetime
                ref={props.addRefParent}
                required
                name={`products[${props.index}].ExtendData[0].ExpiredDate`}
                placeholder="hạn sử dụng"
                dateFormat="dd/MM/yyyy"
                value={product.extendData && product.extendData[0] && product.extendData[0].expiredDate ? product.extendData[0].expiredDate : null}
                onChanged={(value, name) => {
                  var newExtendData = product.extendData;
                  if (!!newExtendData && !!newExtendData[0]) {
                    newExtendData[0].expiredDate = value;
                  } else {
                    newExtendData[0] = { imei: '', serialNumber: '', manufacturingDate: null, expiredDate: value };
                  }
                  setValid({ isValid: true, msgInvalid: '' });
                  props.handlePropertyChanged(props.index, 'extendData', newExtendData);
                }}
              />
            </td>
          </tr>
        );
      }

      return (
        <tr>
          <td colSpan="7">
            <div className="scroll-mh-200px">
              <table className="w-100">{complementExtendDataElement}</table>
            </div>
          </td>
        </tr>
      );
    } else return '';
  };

  const { products, setProducts } = useContext(StockInContext);

  async function _handleSelectSlot(value) {
    let index = props.index;
    let product = products[index];
    if (value) {
      if (product) {
        product.slotCode = value;
        product.warehouseId = props.warehouseId;
      }
    }
    setProducts([...products]);
  }

  async function _handleSelectProduct(value) {
    let index = props.index;
    let product = products[index];
    if (value) {
      if (product) {
        let [err, data] = await WarehouseServices.GetProductDetails(value);
        if (!err && data) {
          product.productId = data.productId;
          product.productName = data.productName;
          product.sellerSku = data.sellerSku;
          product.hasSerialNumber = data.hasSerialNumber;
          product.hasExpDate = data.hasExpDate;
          product.hasImei = data.hasImei;
          product.price = data.price;
          product.barcode = data.barcode;
          product.productType = PRODUCT_TYPE.PRODUCT_VARIANT;
          product.hasExtendData = data.hasSerialNumber || data.hasImei || data.hasExpDate;
          product.isExpandExtendData = product.hasExtendData;
        } else {
          product.productId = 0;
          product.productName = '';
          product.sellerSku = '';
          product.hasSerialNumber = false;
          product.hasExpDate = false;
          product.hasImei = false;
          product.price = 0;
          product.barcode = '';
          product.productType = null;
        }
      }
    } else {
      product.productId = 0;
      product.productName = '';
      product.sellerSku = '';
      product.hasSerialNumber = false;
      product.hasExpDate = false;
      product.hasImei = false;
      product.price = 0;
      product.barcode = '';
      product.productType = null;
    }
    setProducts([...products]);
  }

  return (
    <React.Fragment>
      <tr id={`products[${props.index}]`} className={valid.isValid ? '' : 'alert-danger'}>
        {props.excludeColumns.includes('batchCode') ? (
          ''
        ) : (
          <td rowSpan={product.hasExtendData && product.quantity > 0 && props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess && product.isExpandExtendData ? 2 : 1}>
            <div className="form-row">
              <span className="status__active col-md-2">{product.isBatchMatching ? <i className="fa fa-check fa-lg" /> : null}</span>
              <Textbox id={`batchCode_${props.index}`} name={`batchCode_${props.index}`} value={product && product.batchCode ? product.batchCode : ''} readOnly={true} />
            </div>
          </td>
        )}
        <td>
          <div className="form-row">
            <span className="status__active col-md-2">{product.isBarcodeMatching ? <i className="fa fa-lg fa-check" /> : null}</span>
            <span className="col-md-10">{product.barcode}</span>
          </div>
        </td>
        {product.isAdded ? (
          <td>
            <TFUSelect id={`products[${props.index}].ProductId`} name={`products[${props.index}].ProductId`} options={props.productList} value={product.productId} placeholder="--Chọn sản phẩm--" onChanged={_handleSelectProduct} ref={props.addRef} />
          </td>
        ) : (
          <td className="text-center">{product.sellerSku}</td>
        )}
        <td>{product.productName}</td>
        <td className="text-right">
          <TextNumber
            ref={props.addRefParent}
            required
            id={`products[${props.index}].Quantity`}
            name={`products[${props.index}].Quantity`}
            value={product.quantity || 0}
            displayType={props.readOnly ? 'text' : 'input'}
            onChanged={(name, value) => props.handlePropertyChanged(props.index, 'quantity', value)}
            max={stockValidateObject && stockValidateObject.Good > 0 ? stockValidateObject.Good : 999999999}
            readOnly={props.readOnly || !product.productId}
          />
        </td>
        {props.excludeColumns.includes('price') ? (
          ''
        ) : (
          <td>
            <TextNumber
              ref={props.addRefParent}
              id={`products[${props.index}].Price`}
              name={`products[${props.index}].Price`}
              price
              name="price"
              value={+product.price || 0}
              displayType={props.readOnly ? 'text' : 'input'}
              onChanged={(name, value) => props.handlePropertyChanged(props.index, 'price', value)}
              readOnly={props.readOnly || !product.productId}
            />
          </td>
        )}
        {props.excludeColumns.includes('productStatus') ? (
          ''
        ) : (
          <td>
            <TFUSelect
              ref={props.addRefParent}
              id={`products[${props.index}].ProductStatus`}
              name={`products[${props.index}].ProductStatus`}
              value={product.productStatus}
              placeholder="--Trạng thái--"
              onChanged={(value) => {
                let newProduct = { ...product };
                newProduct.productStatus = value;
                props.handlePropertyChanged(props.index, 'productStatus', value);
              }}
              options={productStatusOptions}
              isDisabled={props.readOnly || !product.productId}
            />
          </td>
        )}
        <td>
          <TFUSelect
            ref={props.addRefParent}
            id={`products[${props.index}].SlotCode`}
            name={`products[${props.index}].SlotCode`}
            value={product.slotCode != '' ? product.slotCode : -1}
            placeholder="--Chọn vị trí--"
            options={props.slotList}
            onChanged={_handleSelectSlot}
            isDisabled={props.readOnly || !product.productId}
            ref={props.addRef}
          />
          {/* <TFUSelect id={`products[${props.index}].SlotCode`} name={`products[${props.index}].SlotCode`} value={product.slotCode != '' ? product.slotCode : -1} placeholder="--Chọn vị trí--" options={props.slotList} onChanged={_handleSelectSlot} isDisabled={props.readOnly} ref={props.addRef} /> */}
        </td>
        <td className="text-center">
          <button className={props.readOnly ? 'btn btn-sm mx-1 btn-danger disable' : 'btn btn-sm mx-1 btn-danger'} onClick={() => props.handleRemoveProduct(props.index)}>
            <i className="fa fa-trash-o" />
          </button>
          {product.hasExtendData && props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess ? (
            <button type="button" className="btn btn-sm mx-1 btn-info" onClick={() => props.handlePropertyChanged(props.index, 'isExpandExtendData', !product.isExpandExtendData)}>
              <i className={product.isExpandExtendData ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'} />
            </button>
          ) : (
            ''
          )}
          {valid.isValid ? (
            ''
          ) : (
            <TFUTooltip position="top" content={valid.msgInvalid} target={`products[${props.index}]`}>
              <i className="fa fa-info-circle text-danger m-l-5" />
            </TFUTooltip>
          )}
        </td>
      </tr>
      {renderComplementExtendData()}
    </React.Fragment>
  );
});

export default StockInModalItem;
