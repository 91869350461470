export class OrderTagDetailModel {
    constructor() {
        this.orderId = 0;
        this.sellerSKU = "";
        this.inlineStatus = 0;
        this.inlineStatusName = "";
        this.printedCount = 0;
        this.productTypeName = "";
        this.attributeValues = [];
        this.attributeValuesJson = "";
        this.deadline = new Date();
        this.successfulPrintCount = 0;
        this.totalPrintCount = 0;
        this.successfulPreProcessCount = 0;
        this.totalPreProcessCount = 0;
        this.dTGCoverColor = "";
        this.isPressure = false;
        this.successfulPressureCount = 0;
        this.totalPressureCount = 0;
        this.tagId = 0
    }
}
