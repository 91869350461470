import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Loading from '../../../based/Loading';
import BaseServices from '../../../based/services/BaseServices';
import useFormValidate from '../../../hooks/useFormValidate';
import Textbox from '../../../based/inputs/Textbox';
import CheckBox from '../../../based/inputs/CheckBox';

const INIT_PRINTER = {
    id: 0,
    name: '',
    flatPrint: false,
    roundPrint: false,
    width: 0,
    height: 0,
    length: 0
}

const AddOrEditPrinterModal = ({ printerId, show, onSave, onClose }) => {
    const [isBusy, setBusy] = useState(false);
    const { addRef, isValid, } = useFormValidate();
    const [dataModel, setDataModel] = useState(INIT_PRINTER);
    useEffect(() => {
        if (show && printerId > 0) {
            _fetchData();
        }
    }, [show])
    const _fetchData = async () => {
        setBusy(true);
        const [err, data] = await BaseServices.Get('/api/uvprint/get-print-device/' + printerId);
        if (!err) {
            setDataModel(data)
        }
        setBusy(false);
    }
    function _handleInputChanged(name, value) {
        let model = { ...dataModel };
        model[name] = value;
        setDataModel(model);
    }
    const _handleSave = async () => {
        if (isValid()) {
            setBusy(true);
            const [err, data] = await BaseServices.Post('/api/uvprint/save-print-device', dataModel)
            if (!err) {
                onSave(true);
            }
            setBusy(false);
        }
    }

    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Loading show={isBusy} msg={'Đang lưu...'} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>{dataModel.id > 0 ? 'Cập nhật thông tin máy in UV' : 'Thêm mới máy in UV'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <div className='form-group'>
                    <label className='required'>Tên Máy In:</label>
                    <Textbox ref={addRef} required name='name' id='name' value={dataModel.name} onChanged={_handleInputChanged} />
                </div>
                <div className='form-group row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <CheckBox checked={dataModel.flatPrint} name="flatPrint" label='In phẳng' onCheckedChange={_handleInputChanged} />
                            <div className='form-group'>
                                <label className='required'>Ngang</label>
                                <Textbox name='width' id='width' value={dataModel.width} onChanged={_handleInputChanged} />
                            </div>
                            <div className='form-group'>
                                <label className='required'>Dài</label>
                                <Textbox name='height' id='height' value={dataModel.height} onChanged={_handleInputChanged} />
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <CheckBox checked={dataModel.roundPrint} name="roundPrint" label='In xoay' onCheckedChange={_handleInputChanged} />
                            <div className='form-group'>
                                <label className='required'>Dài</label>
                                <Textbox name='length' id='length' value={dataModel.length} onChanged={_handleInputChanged} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={_handleSave}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddOrEditPrinterModal;