import React, { useEffect, useState, useMemo } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY, CAMPAIGN_BOOSTING_STATUS, CAMPAIGN_BOOSTING_STATUS_NAME } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import CampaignServices from "../based/services/CampaignServices";
import CommonServices from "../based/services/CommonServices";
import MarketerRegisterModal from "./MarketerRegisterModal";

var moment = require("moment");

const TAB_SCREENS = {
    UNREGISTERED: 0,
    REGISTERED: 1,
};

export default function MarketerCampaignBoosting(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: -1 });
    const [datas, setDatas] = useState([]);

    const [currentTabIndex, setCurrentTabIndex] = useState(TAB_SCREENS.UNREGISTERED);
    const [currentBoosting, setCurrentBoosting] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [creatorOptions, setCreatorOptions] = useState([]);

    useEffect(() => {
        getDatas({ ...paging, isRegister: false });
        getCreators();
    }, []);
    async function getCreators() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setCreatorOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetCampaignBoostingPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _handleChangedTab(tab) {
        if (tab != currentTabIndex) {
            setCurrentTabIndex(tab);
            getDatas({ ...paging, isRegister: tab == TAB_SCREENS.REGISTERED });
        }
    }
    const _renderTab = useMemo(() => (
        <ul className='nav nav-tabs tabs-bordered'>
            <li className='nav-item'>
                <a className={currentTabIndex == TAB_SCREENS.UNREGISTERED ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.UNREGISTERED)}>
                    <span>Chưa đăng ký</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={currentTabIndex == TAB_SCREENS.REGISTERED ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.REGISTERED)}>
                    <span>Đã đăng ký</span>
                </a>
            </li>
        </ul>
    ));
    function handleSortColumn(columnName) {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        getDatas(newPaging);
    }
    function _renderHeader() {
        let icon = <i className='fa fa-sort fa-sort-thead'></i>;
        return (
            <thead>
                <tr>
                    <th
                        className='text-center'
                        width='50'
                        rowSpan='2'
                        onClick={() => {
                            handleSortColumn("id");
                        }}
                    >
                        STT
                    </th>
                    <th className='text-center' rowSpan='2'>
                        Tên Set sản phẩm
                    </th>
                    <th className='text-center' width='150' colSpan='2'>
                        Hình mockup
                    </th>
                    <th className='text-center' width='170' rowSpan='2' onClick={() => handleSortColumn("loyaltyAmount")}>
                        Tiền bản quyền (đ) &nbsp;
                        {paging.orderBy === "loyaltyAmount" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "loyaltyAmount" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th className='text-center' width='150' rowSpan='2'>
                        Tên Creator
                    </th>
                    <th className='text-center' width='170' rowSpan='2' onClick={() => handleSortColumn("toltalTraffics")}>
                        Tổng lượng traffic &nbsp;
                        {paging.orderBy === "toltalTraffics" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "toltalTraffics" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    <th className='text-center' width='200' rowSpan='2' onClick={() => handleSortColumn("toltalTraffics30days")}>
                        Tổng traffic trong 30 ngày gần nhất&nbsp;
                        {paging.orderBy === "toltalTraffics30days" && paging.orderDirection.toUpperCase() === "DESC" ? (
                            <i className='fa fa-sort-desc fa-sort-thead'></i>
                        ) : paging.orderBy === "toltalTraffics30days" ? (
                            <i className='fa fa-sort-asc fa-sort-thead'></i>
                        ) : (
                            icon
                        )}
                    </th>
                    {paging.isRegister ? (
                        <React.Fragment>
                            <th className='text-center' width='150' colSpan='2'>
                                Thời gian chạy
                            </th>
                            <th className='text-center' width='150' rowSpan='2' onClick={() => handleSortColumn("budge")}>
                                Ngân sách (đ)&nbsp;
                                {paging.orderBy === "budge" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "budge" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                            </th>
                        </React.Fragment>
                    ) : (
                        <th className='text-center' width='150' rowSpan='2'>
                            Trạng thái
                        </th>
                    )}
                    <th className='text-center' width='50' rowSpan='2'></th>
                </tr>
                <tr>
                    <th width='100' className='text-center'>
                        Mặt trước
                    </th>
                    <th width='100' className='text-center'>
                        Mặt sau
                    </th>
                    {paging.isRegister && (
                        <React.Fragment>
                            <th width='100' className='text-center'>
                                Từ ngày
                            </th>
                            <th width='100' className='text-center'>
                                Đến ngày
                            </th>
                        </React.Fragment>
                    )}
                </tr>
            </thead>
        );
    }
    function _renderStatus(item) {
        let cls = "rounded text-white status";
        switch (item.status) {
            case CAMPAIGN_BOOSTING_STATUS.APPROVED:
                cls += " label-info";
                break;
            case CAMPAIGN_BOOSTING_STATUS.RUNNING:
                cls += " label-success";
                break;
            case CAMPAIGN_BOOSTING_STATUS.FINISH:
                cls += " label-danger";
                break;
            default:
                cls += " label-secondary";
                break;
        }
        return (
            <div className={cls}>
                <span>{CAMPAIGN_BOOSTING_STATUS_NAME[item.status]}</span>
            </div>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='vertical-align-middle'>{item.setName}</td>
                                <td className='vertical-align-middle'>
                                    <img src={item.mockupFront ? item.mockupFront : "/images/no-image.png"} alt={item.setName} className='variant-avatar' />
                                </td>
                                <td className='vertical-align-middle'>
                                    <img src={item.mockupBack ? item.mockupBack : "/images/no-image.png"} alt={item.setName} className='variant-avatar' />
                                </td>
                                <td className='text-right vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                </td>
                                <td className='vertical-align-middle'>{item.createdBy}</td>
                                <td className='text-right vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.toltalTraffics} />
                                </td>
                                <td className='text-right vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.toltalTraffics30days} />
                                </td>
                                {paging.isRegister ? (
                                    <React.Fragment>
                                        <td className='text-center vertical-align-middle'>{item.fromDateDisplay}</td>
                                        <td className='text-center vertical-align-middle'>{item.toDateDisplay}</td>
                                        <td className='text-right vertical-align-middle'>
                                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.budge} />
                                        </td>
                                    </React.Fragment>
                                ) : (
                                    <td className='text-center vertical-align-middle'>{_renderStatus(item)}</td>
                                )}

                                <td className='text-center vertical-align-middle'>
                                    <i
                                        className='fa fa-eye cursor-poiter text-info'
                                        title='Chi tiết'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentBoosting(item);
                                            setShowModal(true);
                                        }}
                                    ></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td className='text-center' colSpan='100'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='createdBy'>Người tạo: </label>
                            <TFUSelect name='createdBy' value={paging.createdBy} options={creatorOptions} placeholder='---Tất cả---' onChanged={(value) => setPaging({ ...paging, createdBy: value })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='status'>Trạng thái:</label>
                            <TFUSelect name='status' value={paging.status} options={props.statusOptions} placeholder='--Tất cả---' onChanged={(value) => setPaging({ ...paging, status: value != null ? value : -1 })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='time'>{paging.isRegister ? "Thời gian chạy Campaign:" : "Thời gian tạo:"}</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas(paging)}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            {showModal && (
                <MarketerRegisterModal
                    isShowModal={showModal}
                    currentBoosting={currentBoosting}
                    isRegister={paging.isRegister}
                    onClose={() => setShowModal(false)}
                    onSaveSucceed={() => {
                        setShowModal(false);
                        getDatas(paging);
                    }}
                />
            )}
            {_renderTab}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} />
        </React.Fragment>
    );
}
