import React from "react";
import { INITPAGING } from "../based/Constants";

export class ApplicationContextModel {
    constructor() {
        this.applicationInfo = new ApplicationInfoModel();
        this.setApplicationInfo = setApplicationInfo;
    }
}

export class ApplicationInfoModel {
    constructor() {
        this.menus = [];
        this.userInfo = new UserInfoModel();
        this.productListingPaging = { ...INITPAGING };
    }
}
export class UserInfoModel {
    constructor() {
        this.id = 0;
        this.avatar = "";
        this.fullName = "";
        this.balance = 0;
        this.username = "";
    }
}

/**
 * set application info model
 * @param {ApplicationInfoModel} applicationInfo application info model
 */
function setApplicationInfo(applicationInfo) {}

/**
 * set user info model
 * @param {UserInfoModel} userInfo user info model
 */
function setUserInfo(userInfo) {}

export const ApplicationContext = React.createContext(new ApplicationContextModel());
