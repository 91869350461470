import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";

import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, PLATFORM } from "../based/Constants";
import Language from "../based/Language";
import Loading from "../based/Loading";
import TFUAutoComplete from "../based/TFUAutoComplete";
import CheckBox from "../based/inputs/CheckBox";
import Radio from "../based/inputs/Radio";
import Textbox from "../based/inputs/Textbox";
import InventoryServices from "../based/services/InventoryServices";
import { ProductVariantModel } from "../models/ProductVariantModel";
import InventoryWarehouse from "./inventory-components/InventoryWarehouse";

const parentColumns = [
    {
        displayName: "",
    },
    {
        displayName: "Tên sản phẩm",
        name: "name",
    },
    {
        displayName: "SKU",
        name: "sellerSKU",
    },
    {
        displayName: "Số lượng đã bán",
    },
    {
        displayName: "Số lượng sản phẩm (Tốt)",
    },
    {
        displayName: "Số lượng sản phẩm (Lỗi)",
    },
    {
        displayName: "Số lượng sản phẩm (Bị hỏng)",
    },
    {
        displayName: "Đang chờ nhập hàng",
    },
];

const initPaging = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: "id",
    orderDirection: "asc",
    keyword: "",
    warehouseId: 0,
    categoryId: 0,
    brandId: 0,
};

export default function InventoryManagement() {
    const [isBusy, setBusy] = useState(false);
    const [msgBusy, setMsgBusy] = useState("");
    const [datas, setDatas] = useState([new ProductVariantModel()]);
    const [paging, setPaging] = useState(initPaging);
    const [isOneWarehouse, setIsOneWarehouse] = useState(false);
    const [categoryKeyword, setCategoryKeyword] = useState("");
    const [brandKeyword, setBrandKeyword] = useState("");
    const [warehouseKeyword, setWarehouseKeyword] = useState("");

    useEffect(() => {
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Inventory });
        let newPaging = paging;
        var url_string = window.location.href;
        var url = new URL(url_string);
        var id = url.searchParams.get("id");
        if (id) {
            newPaging.warehouseId = id;
            setIsOneWarehouse(true);
        }
        _fetchingData(newPaging);
    }, []);

    async function _fetchingData(paging) {
        setBusy(true);
        setMsgBusy("Đang tải dữ liệu...");
        const [err, data] = await InventoryServices.GetInventory(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setMsgBusy(null);
            setBusy(false);
        } else {
            setMsgBusy(null);
            setBusy(false);
        }
    }

    function _handleSortColumn(column) {}
    function _handleCollapse(idx) {
        let updateDatas = [...datas];
        let currentCollapse = updateDatas[idx].isCollapse;
        updateDatas.map((item) => {
            item.isCollapse = false;
            return item;
        });
        updateDatas[idx].isCollapse = !currentCollapse;
        setDatas(updateDatas);
    }

    function handleBasicSearch(keyword) {
        let newPaging = {
            ...paging,
            pageNumber: 1,
            keyword: keyword,
        };
        _fetchingData(newPaging);
    }
    function _renderAction() {
        return (
            <div className="row flex-row p-x-10 col-sm-12">
                <div className="form-group">
                    <CommonTableFilter keyword={paging.keyword} onSearch={(keyword) => handleBasicSearch(keyword)} />
                </div>
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    {parentColumns.map((column, index) => {
                        let cls = "cursor-pointer";
                        let width = column.width ? column.width : "auto";
                        let icon = <i className="fa fa-sort-desc fa-sort-thead"></i>;
                        if (column.name && column.name.toLowerCase() == paging.orderBy.toLowerCase()) {
                            if (paging.orderDirection.toUpperCase() === "DESC") icon = <i className="fa fa-sort-desc fa-sort-thead"></i>;
                            else icon = <i className="fa fa-sort-asc fa-sort-thead"></i>;
                        }

                        return (
                            <th className={cls} key={index} width={width} onClick={() => _handleSortColumn(column)}>
                                {column.isSort ? icon : null}
                                <span>{column.displayName}</span>
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }

    function _getColorSize(attributes) {
        if (!attributes) return "";
        var color = attributes.find((x) => x.code.toLowerCase() == "color");
        var size = attributes.find((x) => x.code.toLowerCase() == "size");

        var returnStr = "";
        if (color) returnStr += "Màu: " + color.value + "<br/>";
        if (size) returnStr += "Size: " + size.value;

        return returnStr;
    }

    function _renderBody() {
        let contents = [];
        let cls = "cursor-pointer vertical-align-middle text-center";
        let collapseTable = datas && datas.length > 0 && datas.filter((x) => x.isCollapse).length > 0 ? true : false;

        datas && datas.length > 0
            ? datas.map((item, idx) => {
                  return contents.push(
                      <React.Fragment key={idx}>
                          <tr className={cls + `${!item.isCollapse && collapseTable ? "filter-blur" : ""}`} key={idx} onClick={() => _handleCollapse(idx)}>
                              <td>
                                  <CheckBox checked={!!item.isCollapse} name="isCollapse" onCheckedChange={(name, value) => _handleCollapse(idx)} />
                              </td>
                              <td>
                                  {item.name}
                                  <br />
                                  <span dangerouslySetInnerHTML={{ __html: _getColorSize(item.attributes) }}></span>
                              </td>
                              <td>{item.sku}</td>
                              <td>{item.soldQuantity}</td>
                              <td>{item.goodQuantity}</td>
                              <td>{item.defectiveQuantity}</td>
                              <td>{item.brokenQuantity}</td>
                              <td>{item.pendingQuantity}</td>
                          </tr>
                          {item.isCollapse && <InventoryWarehouse datas={item.detailList} index={idx} />}
                      </React.Fragment>
                  );
              })
            : contents.push(
                  <tr>
                      <td colSpan={8} className="text-center">
                          Không có dữ liệu
                      </td>
                  </tr>
              );

        return (
            <React.Fragment>
                <tbody>{contents && contents.length > 0 ? contents.map((m) => m) : null}</tbody>
            </React.Fragment>
        );
    }
    function _renderActionInventory() {
        return (
            <div className="p-x-10 box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="brandId">Thương hiệu:</label>
                            <TFUAutoComplete
                                id="brandId"
                                name="brandId"
                                urlFetchData={`/api/brand/search-dropdown-brand?platform=${PLATFORM.LOTUS}&brandId=${paging.brandId}&keyword=${brandKeyword}`}
                                onSelectedItemChanged={(item) => {
                                    setBrandKeyword(item.text);
                                    if (!item.isTyping || paging.brandId) setPaging({ ...paging, brandId: item.value });
                                }}
                                placeholder="--Thương hiệu--"
                                value={paging.brandId}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="categoryId">Ngành hàng:</label>
                            <TFUAutoComplete
                                id="categoryId"
                                name="categoryId"
                                urlFetchData={`/api/categories/search-dropdown-category?platform=${PLATFORM.LOTUS}&categoryId=${paging.categoryId}&keyword=${categoryKeyword}`}
                                onSelectedItemChanged={(item) => {
                                    setCategoryKeyword(item.text);
                                    if (!item.isTyping || paging.categoryId) setPaging({ ...paging, categoryId: item.value });
                                }}
                                placeholder="--Ngành hàng--"
                                value={paging.categoryId}
                            />
                        </div>
                        {isOneWarehouse ? null : (
                            <div className="form-group col-md-3">
                                <label htmlFor="warehouseId">Kho:</label>
                                <TFUAutoComplete
                                    id="warehouseId"
                                    name="warehouseId"
                                    urlFetchData={`/api/warehouseManagement/search-dropdown-opfwarehouse?isWarehouseOPF=${true}&warehouseId=${paging.warehouseId}&keyword=${warehouseKeyword}`}
                                    onSelectedItemChanged={(item) => {
                                        setWarehouseKeyword(item.text);
                                        if (!item.isTyping || paging.warehouseId) setPaging({ ...paging, warehouseId: item.value });
                                    }}
                                    placeholder="--Kho--"
                                    value={paging.warehouseId}
                                />
                            </div>
                        )}
                        <div className={isOneWarehouse ? "form-group col-md-6" : "form-group col-md-3"}>
                            <label htmlFor="keyword">Từ khóa:</label>
                            <Textbox className="form-control" onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} />
                        </div>
                        <div className="form-group col-md-12">
                            <Radio isInline label="Tất cả" name="statusWarehouse" value={0} onCheckedChange={(name, value) => setPaging({ ...paging, statusWarehouse: value })} checked={paging.statusWarehouse == 0 ? true : false} />
                            <Radio isInline label="Còn hàng" name="statusWarehouse" value={1} onCheckedChange={(name, value) => setPaging({ ...paging, statusWarehouse: value })} checked={paging.statusWarehouse == 1 ? true : false} />
                            <Radio isInline label="Hết hàng" name="statusWarehouse" value={2} onCheckedChange={(name, value) => setPaging({ ...paging, statusWarehouse: value })} checked={paging.statusWarehouse == 2 ? true : false} />
                        </div>
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => _fetchingData(paging)}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _renderHeaderInventory() {
        return (
            <thead>
                <tr className="text-center">
                    <th width="100" className="cursor-pointer center">
                        <span className="ml-3">Ảnh</span>
                    </th>
                    <th width="300" className="cursor-pointer center">
                        <span className="ml-3">SKU</span>
                    </th>
                    <th width="auto" className="cursor-pointer center">
                        <span className="ml-3">Tên sản phẩm</span>
                    </th>
                    <th width="150" className="cursor-pointer center">
                        <span className="ml-3">Giá (vnđ)</span>
                    </th>
                    {isOneWarehouse ? null : (
                        <th width="200" className="cursor-pointer center">
                            <span className="ml-3">Kho</span>
                        </th>
                    )}
                    <th width="150" className="cursor-pointer center">
                        <span className="ml-3">SL trong kho</span>
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderBodyInventories() {
        if (datas && datas.length > 0)
            return (
                <tbody>
                    {datas.map((item, i) => {
                        if (item) return <React.Fragment key={i}>{_renderInventoryItem(item, i, item.inventories ? item.inventories.length : 1)}</React.Fragment>;
                    })}
                </tbody>
            );
        return (
            <tbody>
                <tr>
                    <td colSpan={7} className="center">
                        Không có dữ liệu
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderInventoryItem(item, idx, rowSpan) {
        var content = [];
        if (item.inventories && item.inventories.length > 0)
            item.inventories.map((a, index) => {
                content.push(
                    <tr key={index}>
                        {index > 0 ? null : (
                            <td rowSpan={rowSpan} className="vertical-align-middle">
                                <img src={item.avatar} style={{ width: 50, height: 50 }} />
                            </td>
                        )}
                        {index > 0 ? null : (
                            <td rowSpan={rowSpan} className="vertical-align-middle">
                                <span dangerouslySetInnerHTML={{ __html: item.sellerSKU ? item.sellerSKU : "" }} />
                            </td>
                        )}
                        {index > 0 ? null : (
                            <td rowSpan={rowSpan} className="vertical-align-middle text-left">
                                {item.name}
                            </td>
                        )}
                        {index > 0 ? null : (
                            <td rowSpan={rowSpan} className="vertical-align-middle text-right">
                                <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.sellPrice} />
                            </td>
                        )}
                        {isOneWarehouse ? null : <td className="vertical-align-middle text-left">{a.warehouseName}</td>}
                        <td className="vertical-align-middle text-right">{a.quantity}</td>
                    </tr>
                );
            });
        return content;
    }

    async function _handleFilterInventories(newPaging) {
        await _fetchingData(newPaging);
    }

    function _renderInventoriesSeller() {
        return <CommonTable renderAction={_renderActionInventory} renderHeader={_renderHeaderInventory} renderBody={_renderBodyInventories} paging={paging} data={datas} searchBasic={false} onFilter={_handleFilterInventories} />;
    }
    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msgBusy} />
            {isOneWarehouse ? <Breadcrumbs currentPage="Quản lý tồn kho" hrefParent="warehouse" parentPage="Quản lý kho" /> : <Breadcrumbs currentPage="Quản lý tồn kho" />}
            {!Common.IsAdmin() ? _renderInventoriesSeller() : <CommonTable renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} paging={paging} data={datas} onFilter={_handleFilterInventories} />}
        </React.Fragment>
    );
}

class CommonTableFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: this.props.keyword,
        };
    }
    handleBlur(event) {
        let _this = this;
        let target = event.target;
        if (target.value && target.value.length > 0) {
            _this.setState({ keyword: target.value.trim() });
        }
    }
    handleChangedKeyword(event) {
        let target = event.target;
        this.setState({ keyword: target.value });
    }
    handleKeyPress(event) {
        if (event.charCode === 13) {
            let keyword = this.state.keyword;
            if (keyword && keyword.length > 0) {
                keyword = keyword.trim();
                this.setState({ keyword: keyword });
            }
            this.props.onSearch(keyword);
        }
    }
    render() {
        return (
            <div className="card-box m-b-5 p-10 float-right">
                <div className="form-inline">
                    <div className="form-group">
                        <input type="text" placeholder="Từ khóa" className="form-control" value={this.state.keyword} onChange={this.handleChangedKeyword.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} onBlur={this.handleBlur.bind(this)} />
                        <button className="btn btn-default btn-sm ml-3" onClick={() => this.props.onSearch(this.state.keyword)}>
                            <i className="fa fa-search m-r-5" /> {Language.getValue("common.btnSearch")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
