export class ArtworkMockupModel {
    constructor() {
        this.url = "/images/t-shirt/front-scale.png";
        this.artworkType = 0;
        this.imageWidth = 0;
        this.imageHeight = 0;
        this.fromTop = 0;
        this.fromMid = 0;
        this.fromCollar = 0;
        this.halfWidth = 0;
        this.artworkTypeName = "Mặt trước";
    }
}
