export default class ComboSectionProductModel {
    constructor() {
        this.sectionId = 0;
        this.agencyProductId = 0;
        this.productName = "";
        this.productAvatar = "";
        this.productAvailable = "";
        this.checked = false;
    }
}
