import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Rating from 'react-rating';
import BaseServices from '../../../based/services/BaseServices';

const ProductReviewModal = ({ show, onClose, sharingId, thirdPartyId }) => {
    const [productReviews, setProductReviews] = useState([]);
    useEffect(() => {
        if (show) {
            _fetchData();
        }
    }, [show]);
    const _fetchData = async () => {
        let postData = {
            sharingId: sharingId,
            pageNumber: 1,
            pageSize: 50,
            thirdPartyId: thirdPartyId
        }
        const [err, data] = await BaseServices.Post('/api/affiliate/get-product-reviews', postData);
        if (!err && data) {
            console.log(data);
            setProductReviews(data.listObjects);
        }
    }
    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Đánh giá sản phẩm</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                {
                    productReviews && productReviews.map((review, id) => {
                        return <div key={id} className='review-item card-box p-10'>
                            <div><strong>{review.userName}</strong></div>
                            <Rating
                                readonly={true}
                                initialRating={review.rateStar}
                                emptySymbol="fa fa-star-o"
                                fullSymbol="fa fa-star"
                            />
                            <p>{review.content}</p>
                            <div className='d-flex' style={{ gap: 10 }}>
                                {
                                    review.images && review.images.map((img, idx) => {
                                        return <img style={{ height: 100, width: 'auto' }} src={img} key={idx} />
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-inverse waves-light' onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProductReviewModal;