import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { FLEXI_COMBO_TYPE, INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Textbox from "../based/inputs/Textbox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import FlexiComboServices from "../based/services/FlexiComboServices";
import FlexiComboModel from "../models/FlexiComboModel";
import FlexiComboModal from "./components/FlexiComboModal";

export default function FlexiComboManagement(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [datas, setDatas] = useState([new FlexiComboModel()]);
    const [currentFlexi, setCurrentFlexi] = useState(null);
    const [idFlexiCombo, setIdFlexiCombo] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);

    useEffect(() => {
        getDatas(paging);
    }, []);

    /** Lấy dữ liệu theo paging */
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");

        let [err, data] = await FlexiComboServices.GetPageFlexiCombo(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas(null);
            setPaging({ ...INITPAGING });
        }

        setIsLoading(false);
        setMsg("");
    }

    /** Bật modal add của Flexi Combo */
    function _handleAdd() {
        setCurrentFlexi(new FlexiComboModel());
        setShowModal(true);
        setIdFlexiCombo(0);
    }

    /** Edit modal */
    async function _handleEdit(id) {
        setIdFlexiCombo(id);
        setShowModal(true);
    }

    /** Delete modal */
    function _handleDelete(id) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa Flexi Combo này", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await FlexiComboServices.DeleteById(id);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getDatas(paging);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }

    /** Filter modal */
    function _handleFilter() {
        getDatas({ ...paging });
    }

    /** Render table */
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <span>Id</span>
                    </th>
                    <th width='150' className='text-center'>
                        Tên FlexiCombo
                    </th>
                    <th width='150' className='text-center'>
                        Thời gian hiển thị
                    </th>
                    <th width='150' className='text-center'>
                        Thời gian bắt đầu
                    </th>
                    <th width='150' className='text-center'>
                        Thời gian kết thúc
                    </th>
                    <th width='100' className='text-center'>
                        Loại FlexiCombo
                    </th>
                    <th width={"150"} className='text-center'>
                        Giá trị FlexiCombo tối thiểu
                    </th>
                    <th width='100' className='text-center'>
                        Phương thức chọn sản phẩm FlexiCombo
                    </th>
                    <th width='100' className='text-center'>
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderButton(item) {
        // let isDisabledEdit = new Date() > new Date(item.displayTime);
        let btn = (
            <React.Fragment>
                <a
                    // className={`font-weight-bold mx-2 ${isDisabledEdit ? "disabled" : ""}`}
                    className={`font-weight-bold mx-2 `}
                    style={{ color: "#64c5b1", cursor: "pointer" }}
                    onClick={() => {
                        // if (!isDisabledEdit)
                        _handleEdit(item.id);
                        // else Notify(NOTIFY.INFO,'Thông tin','Hiện chiến dịch đã bắt đầu, bạn không thể chỉnh sửa.')
                    }}
                >
                    Chỉnh sửa
                </a>
                <a className='font-weight-bold mx-2' style={{ color: "darkred", cursor: "pointer" }} onClick={() => _handleDelete(item.id)}>
                    Xóa
                </a>
            </React.Fragment>
        );
        return btn;
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center vertical-align-middle'>
                                    <span>{item.id}</span>
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <strong>{item.name}</strong>
                                </td>
                                <td className='text-center vertical-align-middle'> {Common.formatDate(new Date(item.displayTime))}</td>
                                <td className='text-center vertical-align-middle'> {Common.formatDate(new Date(item.startTime))}</td>
                                <td className='text-center vertical-align-middle'> {Common.formatDate(new Date(item.endTime))}</td>
                                <td className='text-center vertical-align-middle'> {item.flexiComboTypeName}</td>
                                <td className='text-center vertical-align-middle'> {item.flexiComboType == FLEXI_COMBO_TYPE.MinMoney ? Common.formatCurrency(item.flexiComboValueType) : item.flexiComboValueType}</td>
                                <td className='text-center vertical-align-middle'> {item.appliedOptionName}</td>
                                <td className='text-center vertical-align-middle'>{_renderButton(item)}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='9' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-3'>
                            <label htmlFor='name'>Tên FlexiCombo:</label>
                            <Textbox className='form-control' name='name' onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} />
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleAdd()}>
                                <i className='fa fa-plus m-r-5' /> Thêm mới
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý Flexi Combo' />
            <Loading show={isLoading} msg={msg} />
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
            <FlexiComboModal
                isShowModal={showModal}
                onClose={() => setShowModal(false)}
                onSave={() => {
                    getDatas({ ...paging });
                    setShowModal(false);
                    setIdFlexiCombo(0);
                }}
                idFlexi={idFlexiCombo}
            />
        </React.Fragment>
    );
}
