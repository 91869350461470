import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import SocialAccountServices from "../based/services/SocialAccountServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import Request from "../based/Request";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import {initSocialAccount, TYPE_SOCIAL_DROPDOWN , TYPE_SOCIAL} from "./SocialAccountManagement"
import styled from "styled-components";

const AddOrUpdateAccountModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [socialAccount, setSocialAccount] = useState({...initSocialAccount});
    const [selectedSocialAccount, setSelectedSocialAccount] = useState({})
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(() => {
        if (props.id > 0) getSocialAccount(props.id);
        else setSocialAccount({ ...initSocialAccount });
        return () => setSocialAccount({ ...initSocialAccount });
    }, [props.id]);
    

    const getSocialAccount = async (id) => {
        setIsBusy(true);
        let [err, data] = await SocialAccountServices.GetSocialAccountById(id);
        if (!err && data) {
            setIsBusy(false);
            setSocialAccount(data);
            setSelectedSocialAccount({
                value: data.social,
                label: TYPE_SOCIAL[data.social]
            })
        } else {
            setIsBusy(false);
            setSocialAccount({...initSocialAccount});
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await SocialAccountServices.CreateUpdateSocialAccount({ ...socialAccount});
            if (!err && data == 1) {
                setIsBusy(false);
                setSocialAccount({...initSocialAccount});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
     
    };


   
   
   
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='social' className='required'>
                    Loại mạng xã hội:
                </label>
                <TFUSelect
                    name='social'
                    required
                    ref={addRef}
                    options={TYPE_SOCIAL_DROPDOWN}
                    text={selectedSocialAccount.label}
                    value={selectedSocialAccount.value}
                    placeholder='-- Loại mạng xã hội --'
                    onChangedObject={(selected) => {
                        if (selected) {
                            setSocialAccount({ ...socialAccount, social: selected.value })
                            setSelectedSocialAccount(selected)
                        }
                    }}
                />
                <label htmlFor='nameAcc' className='required'>
                    Tên tài khoản MXH:
                </label>
                <Textbox id='nameAcc' name='nameAcc' required ref={addRef} className='form-control' onChanged={(name, value) => setSocialAccount({ ...socialAccount, nameAccount: value })} value={socialAccount.nameAccount} />
                <label htmlFor='namePage' className='required'>
                    Tên trang/kênh:
                </label>
                <Textbox id='namePage' name='namePage' required ref={addRef} className='form-control' onChanged={(name, value) => setSocialAccount({ ...socialAccount, namePage: value })} value={socialAccount.namePage} />
                <label htmlFor='Username' className='required'>
                    Username:
                </label>
                <Textbox id='Username' name='Username' required ref={addRef} className='form-control' onChanged={(name, value) => setSocialAccount({ ...socialAccount, username: value })} value={socialAccount.username} />
                <label htmlFor='password' className='required'>
                    Password:
                </label>
                <Textbox id='password' name='password' required ref={addRef} className='form-control' onChanged={(name, value) => setSocialAccount({ ...socialAccount, password: value })} value={socialAccount.password} />
                <label htmlFor='accountUrl' className='required'>
                    Link:
                </label>
                <Textbox id='accountUrl' name='accountUrl' required ref={addRef} className='form-control' onChanged={(name, value) => setSocialAccount({ ...socialAccount, accountUrl: value })} value={socialAccount.accountUrl} />
                
            
            
            </div>
        </CommonModal>
    );
};

export default AddOrUpdateAccountModal;
const Wapper = styled.div`

    .custom-btn-choose{
        display: inline-block;
        background: #eee;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        margin-right: 10px;
    }

    input[type="file"]{
        visibility: hidden;
    }
    
`
AddOrUpdateAccountModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
