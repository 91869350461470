import { combineReducers } from 'redux';
import creatorInfoReducer from './creator-info/reducer';
import projectEditorReducer from './project-editor/reducer';

const rootReducer = combineReducers({
  creatorInfo: creatorInfoReducer,
  projectEditor: projectEditorReducer,
});

export default rootReducer;
