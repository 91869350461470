import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../../../based/Common";
import { INITPAGING } from "../../../based/Constants";
import Loading from "../../../based/Loading";
import AnswerContentService from "../../../based/services/Help/AnswerContentService";
import { AnswerContentModel } from "../AnswerContentManagement";

const AnswerContentPreview = (props) => {
    const id = props.match.params.id || 0;
    const [answerContent, setAnswerContent] = useState(AnswerContentModel);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getAnswerContent(props.match.params.id);
    }, [props.match.params.id]);
   
    const getAnswerContent = async (id) => {
        setIsLoading(true);
        let [err, data] = await AnswerContentService.GetAnswerContentById(id);
        if (!err && data) {
            setIsLoading(false);
            setAnswerContent(data);
        } else {
            setAnswerContent(AnswerContentModel);
            setIsLoading(false);
        }
    };

    return (
        <Wrapper>
            <Loading show={isLoading} msg='Đang tải' />
            <div className='preview-container' id='preview'>
                {answerContent && answerContent.id > 0 ? (
                    <React.Fragment>
                        <div className='main' style={{ borderTop: 0, paddingTop: 0 }}>
                            <div className='blog-content-container'>
                                <div className='blog-container w-100 row d-flex justify-content-between'>
                                    <div className='blog-content col-md-12 col-lg-8  p-4'>
                                        <h1 className='blog-title' style={{ fontSize: 40 }}>
                                            {answerContent.title}
                                        </h1>
                                        <span>{Common.formatDate(new Date(answerContent.createdDate))}</span>
                                        <section id='paragraph' className='blog-paragraph' dangerouslySetInnerHTML={{ __html: answerContent.content }} />
                                    </div>
                                    <div className='right-section col-md-10 col-lg-4 pl-5 pt-7'>
                                        <div className='product-section' id='product-section'>
                                            <h2>Sản phẩm nổi bật</h2>
                                            <div className='w-100 d-flex flex-wrap' id='product-script'></div>
                                        </div>
                                        <h2 className='mt-4'>Bài viết mới</h2>
                                        <div className='d-flex flex-column' id='new-blog-script'>
                                            <span>Chưa có bài viết</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4 p-4'>
                                    <h2 style={{ fontSize: 25, fontWeight: 500 }}>
                                        <b>Bài viết liên quan</b>
                                    </h2>
                                    <div className='mt-4 d-flex flex-wrap' id='ref-blog-script'>
                                        <span> Chưa có bài viết</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <span>Bài viết không tồn tại!</span>
                )}
            </div>
            <hr />
            {/* <div className='seo-information '>
                <div className='w-50'>
                    <CommonTable renderHeader={renderHeader} renderBody={renderBody} classDivTable='table__fix__height' />
                </div>

                <div className='w-50'>
                    <CommonTable renderHeader={renderHeaderKeyword} renderBody={renderBodyKeyword} classDivTable='table__fix__height ' />
                </div>
            </div> */}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    mark {
        background-color: #04aa6d;
        color: #fff;
    }
    .preview-container {
        width: 90%;
        background: #fff;
        padding: 15px 30px;
        .blog-title {
            font-size: 40px;
            color: #000;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 500;
            color: #000;
        }

        h1 {
            font-size: 40px !important;
            font-weight: 600;
            color: #000;
        }

        ul li {
            list-style-type: none;
        }

        a {
            text-decoration: none;
            color: unset;
        }

        p {
            font-family: "Lexend", sans-serif;
        }

        body {
            margin: 0;
        }

        .container-fluid {
            max-width: 1800px;
        }

        a:not([href]):not([tabindex]),
        a:not([href]):not([tabindex]):focus,
        a:not([href]):not([tabindex]):hover {
            color: inherit;
            text-decoration: none;
        }

        a:hover {
            text-decoration: none;
            color: #4c4c4c;
        }

        .container {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }

        .zalo-share-button-custom {
            background: #03a5fa;
            color: #fff;
            border-radius: 4px;
            font-size: 12px;
            width: 77px;
            height: 28px;
            overflow: hidden;
            font-weight: 700;
            cursor: pointer;
        }

        @media screen and (min-width: 576px) {
            .container {
                max-width: 540px;
            }
        }

        @media screen and (min-width: 768px) {
            .container {
                max-width: 720px;
            }
        }

        @media screen and (min-width: 992px) {
            .container {
                max-width: 960px;
            }
        }

        @media screen and (min-width: 1200px) {
            .container {
                max-width: 1140px;
            }
        }

        /* header */
        /* 33. Header Bar */
        .header-bar {
            padding: 1rem;
            /* position: fixed; */
            top: 0;
            left: 0;
            right: 0;
            z-index: 1050;
            background-color: white;
        }

        .header-bar.scroll {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        }

        .header-bar.position-fixed {
            transition: opacity 1s ease-in-out;
        }

        .header-bar__history i {
            font-size: 2rem;
        }

        .header-bar__icon-language img {
            width: 3rem;
        }

        .header-bar__icon-cart {
            margin-right: 3rem !important;
        }

        .header-bar__icon-cart span {
            display: inline-block;
            background-color: #e23e1d;
            color: white;
            position: absolute;
            top: 1px;
            right: -7px;
            height: 1.5rem;
            width: 1.5rem;
            font-size: 1rem;
        }

        .info__menu:hover + .info__dropdown,
        .info__dropdown:hover {
            opacity: 1;
            transform: translateX(0%);
            padding-top: 2rem;
            z-index: 99999;
        }

        .info__dropdown {
            position: absolute;
            top: 5vh;
            right: 0;
            opacity: 0;
            transform: translateX(500%);
            transition: all 0.4s ease;
        }

        .info__dropdown ul {
            list-style: none;
            font-size: 1.5rem;
            padding: 0;
            background-color: white;
            border-radius: 0.5rem;
            box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
        }

        .info__dropdown ul li {
            color: black;
            width: 100%;
            padding: 1rem;
            padding-top: 0.5rem;
            white-space: nowrap;
        }

        .info__dropdown ul li:last-child {
            margin-bottom: 0;
        }

        .info__dropdown ul li a {
            text-decoration: none;
            color: black;
            display: block;
        }

        .search-temp {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 100vh;
            background-color: white;
            z-index: 2000;
            opacity: 0;
            transform: translateX(300%);
            transition: all 0.4s ease;
        }

        .search-temp.active {
            opacity: 1;
            transform: translateX(0);
        }

        .search-temp__bar i {
            font-size: 2.3rem;
        }

        .search-temp__bar input {
            padding: 1rem;
            border-radius: 1rem;
            border: 0.5px solid !important;
            border-color: gray !important;
            height: 35px;
        }

        .search-temp__bar div i {
            top: 7px;
            right: 10px;
            opacity: 0.5;
        }

        .search-temp__history div span {
            display: inline-block;
            /* background-color: #eff0f5;
            border: 1px solid #eee;
            border-radius: 0.5rem; */
            margin-right: 15px;
        }

        /*----------------------------------------*/
        /* 6. Search  */
        .search-temp #search-result-autocomplete {
            position: absolute;
            top: 49px;
            background: white;
            width: 100%;
        }

        .search-temp #search-result-autocomplete .suggest_search .product-item {
            border-bottom: 1px solid #eeeeee;
            padding: 10px 5px;
            margin: 0px 10px;
        }

        @media all and (max-width: 375px) {
            .search__filter .filter__left .btn {
                padding: 5px 15px;
            }
        }

        @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
            .search__filter .filter__left .btn {
                padding: 5px 5px;
            }
        }

        @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
            .search__filter .filter__left .btn {
                padding: 5px 5px;
            }
        }

        ul {
            list-style: none;
        }

        .header-search .txt-search {
            border-radius: 20px;
            padding: 0 20px;
        }

        .header-search .btn-search {
            cursor: pointer;
            border: 1px solid #000;
            padding: 1px 13px;
            border-radius: 20px;
            background: #000;
            color: white;
            position: absolute;
            right: 3px;
            top: 4px;
        }

        .header-bar .box__logo {
            flex-basis: 20%;
        }

        .header-bar .box__search {
            flex-basis: 60%;
        }

        .header-bar .box__search .header-search {
            margin: 0px 70px;
        }

        .header-bar .box__account {
            flex-basis: 20%;
            text-align: right;
            position: absolute;
            right: 0;
        }

        .header-bar .search-temp__history {
            flex-basis: 60%;
            margin-left: calc(20% + 70px);
        }

        .header-search-popup {
            padding: 20px;
            background: white;
            position: absolute;
            z-index: 100;
            width: 100%;
            top: calc(100% + 5px);
            left: 0;
            right: 0;
            border-radius: 10px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s ease;
        }

        .header-search-popup.active {
            visibility: visible;
            opacity: 1;
        }

        .header-search-popup ul li {
            padding: 5px 0px;
        }

        .header-search-popup ul li span {
            max-width: 440px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .icon__cart .badge_number {
            position: absolute;
            top: -5px;
            right: -5px;
            background-repeat: no-repeat;
            background-size: cover;
            width: 17px;
            height: 17px;
            flex-shrink: 0;
            color: #fff;
            text-align: center;
            font-weight: 700;
            font-size: 1.2rem;
            background: red;
            border-radius: 50%;
        }

        .header-mobile {
            display: none;
        }

        @media screen and (min-width: 768px) {
            .header-bar .box__search {
                flex-basis: 45%;
            }

            .header-bar .box__search .header-search {
                margin: 0px 0px;
            }
        }

        @media screen and (max-width: 768px) {
            .header-bar.scroll {
                display: none;
            }

            .product-section {
                display: none !important;
            }

            .header-mobile {
                display: block;
            }

            h1 {
                font-size: 22px;
            }
        }

        /*  3.5 Register Login CSS
        /*----------------------------------------*/
        .register-login a {
            font-size: 14px;
            color: #4c4c4c;
            text-decoration: none;
        }

        .register-login a:hover {
            color: #e23e1d;
        }

        .register-login.style-2 a {
            font-size: 12px;
            line-height: 40px;
        }

        .register-login span {
            display: inline-block;
            margin: 0 3px;
        }

        /*blog*/
        .blog-container {
            margin: 0 auto;
        }

        .blog-content-container {
            max-width: 1400px;
            display: flex;
            flex-direction: column;
            margin: 0px auto;
        }

        .blog {
            margin: 10px 20px;
            width: 356px;
            height: 450px;
        }

        @media screen and (max-width: 820px) {
            .blog {
                width: 42%;
            }
        }

        @media screen and (max-width: 768px) {
            .blog {
                width: 39%;
            }
        }

        @media screen and (max-width: 400px) {
            .blog {
                width: 90%;
            }
        }

        .right-section {
            margin: 0 auto;
        }

        .right-section h2 {
            font-size: 20px;
            font-weight: bold;
        }

        /*product*/
        .product,
        .product-gift {
            border-radius: 1rem;
            overflow: hidden;
            width: calc(45% - 10px);
            margin: 0 5px 10px;
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
        }

        .product__header {
            position: absolute;
            z-index: 10;
            width: 100%;
        }

        .product__brand {
            position: relative;
            width: auto;
            max-height: 20px;
            flex-shrink: 0;
        }

        .product__badge {
            position: absolute;
            top: 0;
            right: 10px;
            background-repeat: no-repeat;
            background-size: cover;
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            font-size: 6px;
            color: white;
            text-align: center;
        }

        .product__badge b {
            font-size: 8px;
        }

        .product__flip-card {
            padding: 5px 0px;
            perspective: 1500px;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            flex-shrink: 1;
            transition: all 0.4s ease-in;
        }

        .products {
            padding: 1rem;
            margin-left: -1rem;
            height: 325px;
        }

        @media screen and (min-width: 1024px) {
            .products {
                padding: 1.5rem;
                height: 400px;
            }

            .products__for-you {
                padding: 30px 10px;
            }
        }

        .product__flip-card-inner {
            width: 100%;
            height: 100%;
            position: relative;
            perspective: 1500px;
            transform-style: preserve-3d;
            transition: transform 0.6s;
        }

        .product__image-back,
        .product__image-front {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            backface-visibility: hidden;
            flex-shrink: 0;
            padding: 0 5px;
            text-align: center;
        }

        .product__image-back {
            transform: rotateY(-180deg);
        }

        .product__flip-card img {
            width: auto;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }

        .product__flip-card-inner.active {
            transform: rotateY(180deg);
        }

        .product__print {
            padding: 1px 2px;
            background-color: black;
            color: white;
            border-radius: 1rem;
            font-size: 0.7rem;
            font-weight: 500;
            cursor: pointer;
        }

        @media screen and (min-width: 1024px) {
            .product__print {
                padding: 2px 5px;
                font-size: 0.8rem;
            }
        }

        .product__printbnt {
            padding: 6px 14px;
            background-color: #000;
            color: #fff;
            border-radius: 50px;
            font-size: 1rem;
            font-weight: 500;
        }

        @media screen and (max-width: 1200px) {
            .gallery .ribbon img {
                width: 355px;
            }
        }

        @media screen and (max-width: 995px) {
            .gallery .ribbon img {
                width: 355px;
            }
        }

        @media screen and (max-width: 990px) {
            .gallery .ribbon img {
                width: 200px;
            }
        }

        @media screen and (min-width: 765px) {
            .product__name span {
                font-size: 1rem;
            }
        }

        .product__footer {
            display: flex;
            flex: 1;
            flex-direction: column;
            background-color: #efeeee;
        }

        .product__footer.selected {
            background-color: lightyellow;
        }

        .product__footer-brand {
            display: flex;
            justify-content: space-between;
        }

        .product__footer-brand span {
            padding: 1.5px 2px;
            color: #fff;
            background-color: #f7991c;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
            font-size: 0.8rem;
            font-weight: 400;
        }

        .product__name {
            font-weight: 600;
            font-size: 1rem;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 3rem;
        }

        .product__name span {
            padding: 1.5px 2px;
            color: white;
            background-color: #f7991c;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
            font-size: 0.6rem;
            font-weight: 400;
        }

        @media screen and (min-width: 768px) {
            .product__flip-card {
                height: 170px;
            }
        }

        @media screen and (min-width: 1024px) {
            .product__name span {
                font-size: 1rem;
            }

            .product__flip-card {
                height: 260px;
            }
        }

        .product__rate {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .product__bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: auto;
            align-items: flex-end !important;
        }

        .product__price {
            margin: 5px 5px 0 0;
            display: flex;
            flex-direction: column;
            height: 3rem;
        }

        .product__price-normal {
            font-size: 1rem;
            height: 0.9rem;
        }

        .product__price-special {
            font-size: 1em;
            font-weight: 600;
            color: #ce0000;
        }

        .product__buy-now {
            text-align: center;
            padding: 5px 5px;
            background-color: #000559;
            border-radius: 5px;
            color: white;
            margin-bottom: 5px;
            margin-right: 5px;
            font-size: 1em;
            font-weight: 500;
        }

        .product__add-to-cart {
            margin-bottom: 5px;
            margin-right: 5px;
        }

        .product {
            border-radius: 1rem;
            overflow: hidden;
            width: 47%;
            margin: 0 5px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            height: 350px;
            flex-shrink: 0;
            position: relative;
        }

        .product:hover {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .product-gift {
            border-radius: 1rem;
            overflow: hidden;
            width: calc(45% - 10px);
            margin: 0 5px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            height: 200px;
            flex-shrink: 0;
        }

        .blog-avatar {
            margin-bottom: 20px;
        }

        /* small blog item */

        .small-blog-item {
            cursor: pointer;
        }

        .blog-avatar-small {
            width: 100px;
            height: 100px;
            position: relative;
            overflow: hidden;
        }

        .blog-avatar-small img {
            max-width: 100%;
        }

        a.blog-refrence {
            padding: 0px 5px 5px;
            margin: 10px 5px 0px 0px;
        }

        .img_blog_item {
            width: 340px;
            height: 250px;
        }

        .img_blog_item,
        .img_blog_slide {
            border-radius: 10px;
            object-fit: cover;
        }

        h3.blog_title {
            font-size: 20px;
            font-weight: 500;
        }

        .blog_short_content,
        .blog_title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            padding: 0;
            -webkit-line-clamp: 3;
        }

        /*footer*/
        .footer-ranus {
            color: rgba(0, 0, 0, 0.54);
            padding-top: 15em;
            position: relative;
            width: 100%;
            font-size: 80%;
        }

        .footer-ranus .footer-first {
            color: rgba(0, 0, 0, 0.54);
            padding-top: 15em;
            position: relative;
            width: 100%;
        }

        .footer-ranus .footer-first h4 {
            font-size: 20px;
        }

        .footer-ranus .footer-second {
            color: rgba(0, 0, 0, 0.54);
            background-color: rgb(245, 245, 245);
            padding-top: 3em;
            position: relative;
            bottom: 0px;
            width: 100%;
            padding-bottom: 3em;
        }

        .logo {
            width: 160px;
            fill: #fff;
            padding-right: 0;
            display: inline-block;
            vertical-align: middle;
        }

        img {
            max-width: 100%;
        }

        .card-list-footer {
            width: auto;
            height: 3.8125rem;
            max-width: 330px;
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
            overflow: hidden;
            align-items: baseline;
            justify-content: flex-start;
            display: flex;
        }

        .card-list-footer li {
            display: list-item;
            text-align: -webkit-match-parent;
            width: 35px;
            display: block;
            margin-right: 13px;
        }

        .card-list-footer img {
            max-width: 60px;
            width: 100%;
            object-fit: cover;
        }

        .footer-second .regulation {
            width: 100%;
            margin: 0px auto;
            display: flex;
            flex-direction: row;
            -webkit-box-pack: center;
            justify-content: center;
        }

        .footer-second .regulation .regulation-item {
            text-align: center;
            padding: 0px 1.5625rem;
            border-right: 1px solid rgba(0, 0, 0, 0.09);
        }

        .company-footer-name {
            text-align: center;
            padding: 0px 2rem;
        }

        .company-footer {
            text-align: center;
            padding: 0px 1rem;
        }

        .blog-paragraph {
            width: 86%;
            float: right;
            line-height: 25px !important;
            color: unset;

            ul{
                list-style: unset !important;
            }
            ol{
                list-style: unset !important;
            }
        }

        .blog-paragraph article,
        .blog-paragraph img {
            max-width: 100%;
        }

        .mce-toc {
            background: #f1f1f1;
            border-radius: 12px;
            padding: 10px;
        }

        .mce-toc h2 {
            color: #000;
        }

        .mce-toc ul,
        .mce-toc li {
            list-style-type: none;
            padding-bottom: 5px;
        }

        .mce-toc li a {
            color: #4287f6;
        }

        .mce-toc img {
            border-radius: 10px !important;
        }

        b,
        strong {
            font-weight: 600;
        }

        @media screen and (min-width: 820px) {
            .product__name span {
                font-size: 1rem;
            }
        }

        @media screen and (max-width: 768px) {
            .footer-second .regulation {
                flex-direction: column;
                /* flex-wrap: wrap; */
            }

            .footer-ranus {
                padding-top: 0;
            }

            .social-section {
                width: 50% !important;
                flex-direction: row !important;
                justify-content: space-around;
            }

            .blog-paragraph {
                width: 100%;
            }

            h1 {
                font-size: 22px !important;
            }
        }
    }
`;

export default AnswerContentPreview;
