import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Textbox from "../../../based/inputs/Textbox";
import TextNumber from "../../../based/inputs/TextNumber";
import useFormValidate from "../../../hooks/useFormValidate";
import Common from "../../../based/Common";
import CommonModal from "../../../based/Modal";
import ImageUpload from "./ImageUpload"
import { NOTIFY } from "../../../based/Constants";
import { Notify } from "../../../based/Notify";
import TypeQuestionService from "../../../based/services/Help/TypeQuestionService";
import HelpTypeQuestionModel from "../../../models/HelpTypeQuestionModel";
import TFUSelect from "../../../based/inputs/TFUSelect";


const AddOrUpdateModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState(null);
    const [typeQuestion, setTypeQuestion] = useState(props.typeQuestion);
    const { addRef, displayError, isValid } = useFormValidate();
    const [msgIcon, setMsgIcon] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);


    useEffect(() => {
        //category
        setTypeQuestion(props.typeQuestion);
    }, [props.isShowModal]);
    
    // useEffect(() => {
    //     if (props.id > 0) getTypeQuestion(props.id);
    //     else setTypeQuestion({ ...HelpTypeQuestionModel });
    //     return () => setTypeQuestion({ ...HelpTypeQuestionModel });
    // }, [props.id]);

    const getParentTypeQuestion = async (id) => {
        setIsBusy(true);
        let [err, data] = await TypeQuestionService.GetTypeQuestionById(id);
        if (!err && data) {
            setIsBusy(false);
            setTypeQuestion({ ...typeQuestion , 
                icon : data.icon,
                colorIcon : data.colorIcon,
                parentId : data.id
            
            });
        } else {
            setIsBusy(false);
            setTypeQuestion(new HelpTypeQuestionModel());
        }
    };
    const _handleSave = async () => {
        if (isValid()&&typeQuestion.icon) {
            setIsBusy(true);
            let [err, data] = await TypeQuestionService.CreateUpdateTypeQuestion({ ...typeQuestion });
            if (!err && data == 1) {
                setIsBusy(false);
                setTypeQuestion(new HelpTypeQuestionModel());
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Loại câu hỏi đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
        if (!typeQuestion.icon) {
            setMsgIcon(true);
        }
      
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Tên loại câu hỏi
                </label>
                <Textbox id='name' name='name'  required ref={addRef} className='form-control' onChanged={(name, value) => setTypeQuestion({ ...typeQuestion, name: value })} value={typeQuestion.name} />
                
                <label htmlFor='parentId'>Câu hỏi cha:</label>
                <TFUSelect
                    name='parentId'
                    ref={addRef}
                    options={props.typeQuestionOptions}
                    text={typeQuestion.name}
                    value={typeQuestion.parentId}
                    placeholder='-- Câu hỏi cha --'
                    onChangedObject={(selected) => {
                        if (selected) {
                            // var newQuestion = {
                            //     ...typeQuestion,
                            //     parentId: selected.value ? selected.value : 0,
                            // };
                            // setTypeQuestion(newQuestion);
                            getParentTypeQuestion(selected.value)
                        } else {
                            setTypeQuestion({
                                ...typeQuestion,
                                parentId: 0,
                            });
                        }
                    }}
                />



                <label htmlFor='indexPos' className='required'>
                    Thứ tự hiển thị
                </label>
                <TextNumber id='indexPos' name='indexPos'  required ref={addRef} className='form-control' onChanged={(name, value) => setTypeQuestion({ ...typeQuestion, indexPos: value })} value={typeQuestion.indexPos} />


                <label htmlFor='icon' className='required mt-3'>
                    Icon
                </label>
                <ImageUpload
                    id='icon'
                    name='icon'
                    image={typeQuestion.icon}
                    isRequired={msgIcon}
                    onChanged={(name, value) => {
                        setTypeQuestion({ ...typeQuestion, icon: value });
                    }}
                    maximumSize={5}
                />
                
                

                <label htmlFor='color' className='required'>
                    Mã màu icon (hex) (vd: #000000)
                </label>
                <Textbox id='color' name='color'  required ref={addRef} className='form-control' onChanged={(name, value) => setTypeQuestion({ ...typeQuestion, colorIcon: value })} value={typeQuestion.colorIcon} />
                
            </div>
        </CommonModal>
    );
};

export default AddOrUpdateModal;

AddOrUpdateModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
