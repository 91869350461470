import React, { forwardRef, useImperativeHandle, useState } from "react";
import { TFUDatetimeProps } from "../PropsDefined";
import { CONSTANTS } from "../Constants";
import DatePicker from "react-datepicker";
import vi from "date-fns/locale/vi";
import subDays from "date-fns/subDays";

/**
 * Tfu datetime
 * @param {TFUDatetimeProps} props the tfu datetime props
 * @param {Ref} ref reference
 */
const InputDatePicker = (props, ref) => {
	useImperativeHandle(ref, () => ({ displayError, validateInput, props }));

	const [valid, setValid] = useState({ isValid: true, msgInvalid: "" });

	const displayError = (msg) => {
		setValid({ isValid: false, msgInvalid: msg });
	};
	const validateInput = (value) => {
		if (!value && props.required) return CONSTANTS.MSG_REQUIRED;
		return "";
	};
	const handleChange = (date) => {
		var newValue = date ? date : null;
		if (props.required && !newValue)
			setValid({
				isValid: false,
				msgInvalid: props.requiredMsg
					? props.requiredMsg
					: CONSTANTS.MSG_REQUIRED,
			});
		else setValid({ isValid: true, msgInvalid: "" });
		props.onChanged(newValue, props.name);
	};

	return (
		<React.Fragment>
			<DatePicker
				autoComplete="off"
				name={props.name}
				placeholderText={props.placeholder}
				disabled={props.disabled}
				adjustDateOnChange
				selected={props.value}
				onChange={handleChange}
				locale={vi}
				// minDate={subDays(new Date(), 0)}
				minDate={props.minDate && props.minDate}
				peekNextMonth
				showMonthDropdown
				showYearDropdown
				showTimeSelect={
					props.showTimeSelect ? props.showTimeSelect : false
				}
				timeCaption="time"
				timeFormat="HH:mm"
				timeIntervals={props.timeIntervals ? props.timeIntervals : 15}
				dateFormat={
					props.dateFormat ? props.dateFormat : "dd/MM/yyyy HH:mm"
				}
				dropdownMode="select"
				className={
					props.className
						? `${props.className} input__item ${valid.isValid ? "" : " alert-danger"
						}`
						: `input__item${valid.isValid ? "" : " alert-danger"}`
				}
				readOnly={(props.readOnly == false || props.readOnly == undefined) ? false : true}
				maxDate={props.maxDate ? props.maxDate : null}
			/>
			{valid.isValid ? (
				""
			) : (
				<label className="text-danger full-width" htmlFor={props.id}>
					{valid.msgInvalid}
				</label>
			)}
		</React.Fragment>
	);
};
export default forwardRef(InputDatePicker);
