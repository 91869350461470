import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { ARTWORK_TYPE, GENDER, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import TFUSelect from "../based/refactor/TFUSelect";
import Request from "../based/Request";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";

const ARTWORK_TYPE_OPTIONS = [
    {
        value: ARTWORK_TYPE.Front,
        label: "Mặt trước",
    },
    {
        value: ARTWORK_TYPE.Back,
        label: "Mặt sau",
    },
];

const GENDER_OPTIONS = [
    {
        value: GENDER.MAN,
        label: "Nam",
    },
    {
        value: GENDER.WOMAN,
        label: "Nữ",
    },
];

const appProductPrototypeFrameDTO = {
    id: 0, // Assuming long translates to number
    colorValue: "",
    sizeValue: "",
    bottomLayer: "",
    topLayer: "",
    artworkType: null, // Assuming enum translates to null
    axisX: 0.0, // Assuming decimal translates to number
    collarY: 0.0, // Assuming decimal translates to number
    frameMinY: 0.0, // Assuming decimal translates to number
    digitalScale: 0, // Assuming int translates to number
    height: 0, // Assuming int translates to number
    weight: 0, // Assuming int translates to number
    appId: 0, // Assuming AppProductId translates to appId (long to number)
    widthFrame: 0.0, // Assuming decimal translates to number
    heightFrame: 0.0, // Assuming decimal translates to number
    frontMin: 0.0, // Assuming decimal translates to number
    frontMax: 0.0, // Assuming decimal translates to number
    backMin: 0.0, // Assuming decimal translates to number
    backMax: 0.0, // Assuming decimal translates to number
    gender: GENDER.MAN,
};

const appProductPrototypeImageDTO = {
    id: 0, // Assuming long translates to number
    url: "",
    productId: 0, // Assuming IdProduct translates to productId (long to number)
};

const cusAppProductSeoDTO = {
    id: 0, // Assuming long translates to number
    appId: 0, // Assuming AppProductId translates to appId (long to number)
    colorCode: "",
    seoImage: "",
};

const prototypeColorModel = {
    name: "",
    displayName: "",
    productSeo: { ...cusAppProductSeoDTO }, // Assuming reference type translates to null
    productPrototypeFrames: [{ ...appProductPrototypeFrameDTO }],
};

const printFrameConfigModel = {
    variantId: 0, // Assuming long translates to number
    attributeValueId: 0, // Assuming long translates to number

    // Thông số (Dimensions)
    widthFrame: 0.0, // Assuming decimal translates to number
    heightFrame: 0.0, // Assuming decimal translates to number
    widthMockup: 0.0, // Assuming decimal translates to number
    heightMockup: 0.0, // Assuming decimal translates to number

    // Thông số creator flexible config (Flexible Config Dimensions)
    frontMin: 0.0, // Assuming decimal translates to number
    frontMax: 0.0, // Assuming decimal translates to number
    backMin: 0.0, // Assuming decimal translates to number
    backMax: 0.0, // Assuming decimal translates to number

    // thông số logo (Logo Dimensions)
    logoHeightCollar: 0.0, // Assuming decimal translates to number
    logoWidthCollar: 0.0, // Assuming decimal translates to number
    logoHeightBackCollar: 0.0, // Assuming decimal translates to number

    // Display Field
    attributeValueName: "",
    appId: 0, // Assuming long translates to number (AppProductId)
    compareId: "",

    // Mặc định (Defaults)
    scale: 1.0, // Assuming default scale is 1
    isDefault: false,
};

const prototypeCustomizeModel = {
    id: 0, // Assuming long translates to number
    name: "",
    productAvatar: "",
    displayName: "",
    fromPrice: 0.0, // Assuming decimal translates to number
    colorModels: [{ ...prototypeColorModel }],
    frameConfigModels: [{ ...printFrameConfigModel }],
    productPrototypeImages: [{ ...appProductPrototypeImageDTO }],
};

function PageAddProduct(props) {
    const [paging, setPaging] = useState();
    const [model, setModel] = useState(prototypeCustomizeModel);
    const { appProductId } = props.match.params;
    const [sizeConfig, setSizeConfig] = useState([{ ...printFrameConfigModel }]);

    useEffect(() => {
        getModel(appProductId);
    }, [appProductId]);

    const checkedProductSeo = useMemo(() => {
        let md = { ...model };
        let checkedColor = md.colorModels.find((x) => x.checked);
        let productSeo = { ...cusAppProductSeoDTO };
        if (checkedColor) {
            productSeo = checkedColor.productSeo;
        } else {
            productSeo = {};
        }
        return productSeo;
    }, [model]);

    const checkedImageFrame = useMemo(() => {
        let md = { ...model };
        let checkedColor = md.colorModels.find((x) => x.checked);
        let imgFrame = checkedColor?.productPrototypeFrames;
        return imgFrame;
    }, [model]);

    const checkedRealImage = useMemo(() => {
        let md = { ...model };
        let img = md?.productPrototypeImages;
        return img;
    }, [model]);

    const getModel = async (id) => {
        let [err, data] = await FolderProductPrototypeServices.GetPrototypeCustomizeModelById(id);
        if (!err && data) {
            let dt = { ...data };
            dt.colorModels = dt.colorModels.map((item, index) => {
                return {
                    ...item,
                    checked: index === 0,
                };
            });
            setModel(dt);

            // Set size config
            let sizeConfig = dt.frameConfigModels;
            setSizeConfig(sizeConfig);
        } else setModel({});
    };

    const handleSave = async (model) => {
        let [err, data] = await FolderProductPrototypeServices.CreateUpdatePrototype(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật sản phẩm thành công");
        } else {
            let errMsg =
                err && err.errors && err.errors.length > 0
                    ? err.errors.reduce((prev, item, idx) => {
                          return `${prev}${item.value}<br/>`;
                      }, "")
                    : "";
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
        }
    };

    const handleCheckImage = async (dto, index) => {
        let [err, data] = await FolderProductPrototypeServices.HandleCheckImage(dto);
        if (!err && data) {
            let md = { ...model };
            let color = md.colorModels.find((x) => x.checked);
            let frames = color.productPrototypeFrames;
            let frame = frames[index];
            frame.mockupImage = data;
            setModel(md);
        } else {
            let errMsg =
                err && err.errors && err.errors.length > 0
                    ? err.errors.reduce((prev, item, idx) => {
                          return `${prev}${item.value}<br/>`;
                      }, "")
                    : "";
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
        }
    };

    const renderHeader = () => {
        return (
            <tr
                style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "10",
                }}
            >
                <th>Hình</th>
                <th>Lớp phủ (nếu có)</th>
                <th
                    style={{
                        width: "200px",
                    }}
                >
                    Size áo
                </th>
                <th
                    style={{
                        width: "200px",
                    }}
                >
                    Mặt áo
                </th>
                <th
                    style={{
                        width: "200px",
                    }}
                >
                    Giới tính
                </th>
                <th>Kích thước ngang thực tế</th>
                <th>Kích thước dọc thực tế</th>
                <th>Cách cổ Min (Khoảng cách từ áo tới khung)</th>
                <th>Tọa độ X (Vị trí cổ áo - Điểm số 1)</th>
                <th>Tọa độ Y1 (Vị trí cổ áo - Điểm số 1)</th>
                <th>Tọa độ Y2 (Vị trí cổ áo - Điểm số 2)</th>
                <th>Khoảng cách từ điểm số 1 đến điểm số 2</th>
                <th>Thông số mẫu</th>
                <th>Check hình mô phỏng</th>
                <th></th>
            </tr>
        );
    };

    const renderBody = () => {
        return (
            <React.Fragment>
                {checkedImageFrame &&
                    checkedImageFrame.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div
                                        onClick={(e) => {
                                            document.getElementById("file-thumbnail-" + index).click();
                                        }}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    >
                                        {item.bottomLayer ? (
                                            <img
                                                src={item.bottomLayer}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        ) : (
                                            <div
                                                className="blank-image"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "1px solid #ccc",
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="d-none"
                                        id={"file-thumbnail-" + index}
                                        onChange={(e) => {
                                            handleBottomlayer(e, index);
                                        }}
                                    />
                                </td>
                                <td>
                                    <div
                                        onClick={(e) => {
                                            document.getElementById("file-sub-thumbnail-" + index).click();
                                        }}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    >
                                        {item.topLayer ? (
                                            <img
                                                src={item.topLayer}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        ) : (
                                            <div
                                                className="blank-image"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "1px solid #ccc",
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="d-none"
                                        id={"file-sub-thumbnail-" + index}
                                        onChange={(e) => {
                                            handleToplayer(e, index);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TFUSelect
                                        options={
                                            sizeConfig && sizeConfig.length > 0
                                                ? sizeConfig.map((item) => {
                                                      return {
                                                          value: item.attributeValueName,
                                                          label: item.attributeValueName,
                                                      };
                                                  })
                                                : []
                                        }
                                        value={item.sizeValue}
                                        name={"sizeValue-" + index}
                                        onChanged={(newVal) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            let selectedSizeConfig = sizeConfig.find((x) => x.attributeValueName == newVal);
                                            frame.sizeValue = selectedSizeConfig.attributeValueName;
                                            frame.widthFrame = selectedSizeConfig.widthFrame;
                                            frame.heightFrame = selectedSizeConfig.heightFrame;
                                            frame.frontMin = selectedSizeConfig.frontMin;
                                            frame.frontMax = selectedSizeConfig.frontMax;
                                            frame.backMin = selectedSizeConfig.backMin;
                                            frame.backMax = selectedSizeConfig.backMax;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TFUSelect
                                        options={ARTWORK_TYPE_OPTIONS}
                                        value={item.artworkType}
                                        name={"artworkType-" + index}
                                        onChanged={(newVal) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            frame.artworkType = +newVal;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TFUSelect
                                        options={GENDER_OPTIONS}
                                        value={item.gender}
                                        name={"itemGender-" + index}
                                        onChanged={(newVal) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            frame.gender = newVal;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input type="number" className="form-control" value={item.widthFrame} readOnly />
                                </td>
                                <td>
                                    <input type="number" className="form-control" value={item.heightFrame} readOnly />
                                </td>
                                <td>
                                    <input type="number" className="form-control" value={item.frontMin} readOnly />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={item.axisX}
                                        onChange={(e) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            frame.axisX = e.target.value;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={item.collarY}
                                        onChange={(e) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            frame.collarY = e.target.value;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={item.frameMinY}
                                        onChange={(e) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            frame.frameMinY = e.target.value;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={item.digitalScale}
                                        onChange={(e) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            frame.digitalScale = e.target.value;
                                            setModel(md);
                                        }}
                                    />
                                </td>
                                <td>
                                    <div className="list-item">
                                        <div className="item">
                                            <div className="suf-item">Cao</div>
                                            <input
                                                value={item.height}
                                                type="number"
                                                onChange={(e) => {
                                                    let md = { ...model };
                                                    let color = md.colorModels.find((x) => x.checked);
                                                    let frames = color.productPrototypeFrames;
                                                    let frame = frames[index];
                                                    frame.height = e.target.value;
                                                    setModel(md);
                                                }}
                                            />
                                        </div>
                                        <div className="item">
                                            <div className="suf-item">Nặng</div>
                                            <input
                                                value={item.weight}
                                                type="number"
                                                onChange={(e) => {
                                                    let md = { ...model };
                                                    let color = md.colorModels.find((x) => x.checked);
                                                    let frames = color.productPrototypeFrames;
                                                    let frame = frames[index];
                                                    frame.weight = e.target.value;
                                                    setModel(md);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <img
                                        src={item.mockupImage}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "contain",
                                        }}
                                        onClick={(e) => {
                                            window.open(item.mockupImage, "_blank");
                                        }}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="btn btn-danger m-2"
                                        onClick={(e) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            frames.splice(index, 1);
                                            setModel(md);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                    <button
                                        className="btn btn-primary m-2"
                                        onClick={(e) => {
                                            let md = { ...model };
                                            let color = md.colorModels.find((x) => x.checked);
                                            let frames = color.productPrototypeFrames;
                                            let frame = frames[index];
                                            handleCheckImage(frame, index);
                                        }}
                                    >
                                        Kiểm tra
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
            </React.Fragment>
        );
    };

    const handleSeoFileChange = (e) => {
        let file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader();
        reader.onloadend = () => {
            Request.UploadImage(file, 0).then((res) => {
                if (res && res.data) {
                    let imageUrl = res.data.imageUrl;
                    let md = { ...model };
                    let color = md.colorModels.find((x) => x.checked);
                    if (color.productSeo) color.productSeo.seoImage = imageUrl;
                    else {
                        color.productSeo = { ...cusAppProductSeoDTO, appId: appProductId, colorCode: color.name, seoImage: imageUrl };
                    }
                    setModel(md);
                } else {
                    let errMsg =
                        res.errors && res.errors.length > 0
                            ? res.errors.reduce((prev, item, idx) => {
                                  return `${prev}${item.value}<br/>`;
                              }, "")
                            : "";
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                }
            });
        };
        reader.readAsDataURL(file);
    };

    const handleUploadImages = (e) => {
        let files = e.target.files;
        if (!files || files.length === 0) return;

        Request.UploadMultipleImage(files).then((res) => {
            if (res && res.data && !res.errors) {
                let { listImageUrl } = res.data;
                let md = { ...model };
                let realImages = md.productPrototypeImages;
                listImageUrl.forEach((item) => {
                    realImages.push({ ...appProductPrototypeImageDTO, productId: appProductId, url: item });
                });
                setModel(md);
            } else {
                let errMsg =
                    res.errors && res.errors.length > 0
                        ? res.errors.reduce((prev, item, idx) => {
                              return `${prev}${item.value}<br/>`;
                          }, "")
                        : "";
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
            }
        });
    };

    const handleBottomlayer = (e, index) => {
        let file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader();
        reader.onloadend = () => {
            Request.UploadImage(file, 0).then((res) => {
                if (res && res.data) {
                    let imageUrl = res.data.imageUrl;
                    let md = { ...model };
                    let color = md.colorModels.find((x) => x.checked);
                    let frames = color.productPrototypeFrames;
                    let frame = frames[index];
                    frame.bottomLayer = imageUrl;

                    //kiểm tra file name và input thông tin
                    var fileName = file.name;
                    var splitFileName = fileName.split("_");
                    if (splitFileName && splitFileName.length > 0) {
                        var positionX = splitFileName.length > 1 ? +splitFileName[1] : 0;
                        var positionY1 = splitFileName.length > 2 ? +splitFileName[2] : 0;
                        var positionY2 = splitFileName.length > 3 ? +splitFileName[3] : 0;
                        var range = splitFileName.length > 4 ? +splitFileName[4] : 0;
                        var height = splitFileName.length > 5 ? +splitFileName[5] : 0;
                        var weight = splitFileName.length > 6 ? +splitFileName[6] : 0;

                        frame.axisX = positionX;
                        frame.collarY = positionY1;
                        frame.frameMinY = positionY2;
                        frame.digitalScale = range;
                        frame.height = height;
                        frame.weight = weight;
                    }

                    setModel(md);
                } else {
                    let errMsg =
                        res.errors && res.errors.length > 0
                            ? res.errors.reduce((prev, item, idx) => {
                                  return `${prev}${item.value}<br/>`;
                              }, "")
                            : "";
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                }
            });
        };
        reader.readAsDataURL(file);
    };

    const handleToplayer = (e, index) => {
        let file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader();
        reader.onloadend = () => {
            Request.UploadImage(file, 0).then((res) => {
                if (res && res.data) {
                    let imageUrl = res.data.imageUrl;
                    let md = { ...model };
                    let color = md.colorModels.find((x) => x.checked);
                    let frames = color.productPrototypeFrames;
                    let frame = frames[index];
                    frame.topLayer = imageUrl;
                    setModel(md);
                } else {
                    let errMsg =
                        res.errors && res.errors.length > 0
                            ? res.errors.reduce((prev, item, idx) => {
                                  return `${prev}${item.value}<br/>`;
                              }, "")
                            : "";
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                }
            });
        };
        reader.readAsDataURL(file);
    };

    if (!model || model.id === 0) return <div>Loading...</div>;

    return (
        <Wrapper>
            <Breadcrumbs currentPage={"Chỉnh sửa thông tin sản phẩm phôi tự thiết kế"} />
            <div className="section-info">
                <div className="info-image">
                    <img src={model.productAvatar} />
                </div>
                <div className="info-detail">
                    <div className="detail-name">{model.name}</div>
                    <div className="row my-3">
                        <div className="form-group col-md-2">
                            <label>Tên hiển thị</label>
                            <input
                                type="text"
                                className="form-control"
                                value={model.displayName}
                                onChange={(e) => {
                                    setModel({
                                        ...model,
                                        displayName: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Giá</label>
                            <input
                                type="number"
                                className="form-control"
                                value={model.fromPrice}
                                onChange={(e) => {
                                    setModel({
                                        ...model,
                                        fromPrice: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-main">
                <div className="main-header">
                    <ul className="nav nav-tabs tabs-bordered">
                        {model.colorModels &&
                            model.colorModels.map((item, index) => {
                                return (
                                    <li
                                        className={`nav-item ${item.checked ? "active" : ""}`}
                                        key={index}
                                        onClick={(e) => {
                                            setModel({
                                                ...model,
                                                colorModels: model.colorModels.map((i, idx) => {
                                                    return {
                                                        ...i,
                                                        checked: idx === index,
                                                    };
                                                }),
                                            });
                                        }}
                                    >
                                        <a className={`nav-link btn-light ${item.checked ? "active" : ""}`}>
                                            <span className="Black">{item.displayName}</span>
                                        </a>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                <div className="main-body">
                    <div className="body-thumbnail form-group">
                        <label htmlFor="">Hình chờ render thumbnail</label>
                        <div
                            onClick={(e) => {
                                document.getElementById("file-thumbnail").click();
                            }}
                            className="thumbnail-image"
                        >
                            {checkedProductSeo ? <img src={checkedProductSeo.seoImage} /> : <div className="blank-image"></div>}
                        </div>

                        <input
                            type="file"
                            className="d-none"
                            id="file-thumbnail"
                            onChange={(e) => {
                                handleSeoFileChange(e);
                            }}
                        />
                    </div>
                    <div className="body-table">
                        <label htmlFor="">Cấu hình Mockup</label>
                        <CommonTable paging={paging} renderHeader={renderHeader} renderBody={renderBody} />
                        <div className="body-button">
                            <div className="button-custom">
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        let md = { ...model };
                                        let color = md.colorModels.find((x) => x.checked);
                                        color.productPrototypeFrames.push({ ...appProductPrototypeFrameDTO });
                                        setModel(md);
                                    }}
                                >
                                    Thêm cấu hình +
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-real-image">
                <label htmlFor="">Hình thực tế </label>
                <input
                    type="file"
                    placeholder="Chọn nhiều hình ảnh"
                    className="form-control"
                    onChange={(e) => {
                        handleUploadImages(e);
                    }}
                    multiple
                />
                <div className="list-real-image">
                    {checkedRealImage &&
                        checkedRealImage.map((item, index) => {
                            return (
                                <div className="real-item" key={index}>
                                    <div className="real-image">
                                        <img src={item.url} />
                                    </div>
                                    <div
                                        className="remove-icon cursor-pointer"
                                        onClick={(e) => {
                                            let md = { ...model };
                                            let images = md.productPrototypeImages;
                                            images.splice(index, 1);
                                            setModel(md);
                                        }}
                                    >
                                        Xóa
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>

            <div className="button-bottom">
                <button
                    className="btn btn-primary"
                    onClick={(e) => {
                        handleSave({ ...model });
                    }}
                >
                    Hoàn thành
                </button>
            </div>
        </Wrapper>
    );
}

export default PageAddProduct;

const Wrapper = styled.div`
    .remove-icon {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        color: red;
    }

    .real-item {
        position: relative;
    }
    .tbl__scroll {
        min-height: 300px !important;
    }
    .list-item {
        display: flex;
        flex-direction: column;
    }

    .item {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 9px 12px;
        margin: 5px 0;
    }

    .item input[type="number"] {
        border: none;
    }
    .section-info {
        background: #ffffff;
        display: flex;
        padding: 14px 20px;
    }

    .info-image {
        flex-basis: 10%;
    }

    .info-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .info-detail {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 1;
    }

    .detail-name {
        color: #676767;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .section-main {
        background: #fff;
        margin: 20px 0;
        padding: 14px 20px;
    }

    .main-body {
        padding: 22px 17px;
    }

    .body-thumbnail label {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .thumbnail-image {
        width: 10%;
        height: 100px;
        border: 1px solid #ccc;
    }

    .body-thumbnail.form-group {
    }

    .thumbnail-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .body-table label {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    td img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .section-real-image {
        background: #fff;
        margin: 20px 0;
        padding: 14px 20px;
    }

    .section-real-image label {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .list-real-image {
        display: flex;
        flex-wrap: wrap;
    }

    .real-item {
        width: 200px;
        flex-shrink: 0;
        margin: 10px;
    }

    .real-image {
        width: 100%;
        height: 100%;
    }

    .real-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
