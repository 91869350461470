import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import TextNumber from '../../../based/inputs/TextNumber';
import Language from '../../../based/Language';
import Loading from '../../../based/Loading';
import BaseServices from '../../../based/services/BaseServices';
import useFormValidate from '../../../hooks/useFormValidate';

function ConfigMaxCommissionModal(props) {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState('');
    const [commission, setCommission] = useState(0);
    const { addRef, isValid, displayError } = useFormValidate();
    useEffect(() => {
        if (props.isShow) {
            _fetchData();
        }
    }, [props.isShow])
    async function _fetchData() {
        setBusy(true);
        setMsg('Đang lấy dữ liệu...')
        const [err, resData] = await BaseServices.Get(`/api/affiliate/get-maximum-commission`);
        setBusy(false);
        if (!err) {
            setCommission(resData)
        } else {
        }
    }
    async function _handleSubmit() {
        if (isValid()) {
            setBusy(true);
            setMsg('Đang lưu dữ liệu...')
            const [err, resData] = await BaseServices.Post(`/api/affiliate/save-config-commission-per-order`, { commission: commission });
            setBusy(false);
            if (!err) {
                props.onSubmit(true);
            } else {
                props.onSubmit(false);
            }
        }
    }

    function _handleInputChanged(name, value) {
        setCommission(value);
    }
    return (
        <Modal show={props.isShow} size={'md'} enforceFocus={false}>
            <Loading show={isBusy} msg={msg} />
            <Modal.Header>
                <button type='button' className='close' onClick={props.onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Cấu hình hoa hồng tối đa cho mỗi đơn hàng</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <div className='form-group'>
                    <label className='required'>Hoa hồng tối đa</label>
                    <TextNumber ref={addRef} name='commission' id='commission' value={commission} onChanged={_handleInputChanged} price decimal />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={_handleSubmit}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={props.onClose}>
                    {Language.getValue("common.btnClose")}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfigMaxCommissionModal;