import BaseServices from "./BaseServices";

const CampaignServices = {
    GetListCampaign: async (paging) => {
        return await BaseServices.Post("/api/Campaign/get-list-campaign", paging);
    },
    GetListShortLink: async () => {
        return await BaseServices.Get("/api/Campaign/get-list-campaign-short-link");
    },
    GetCampaignById: async (id, type) => {
        return await BaseServices.Get(`/api/Campaign/get-campaign-by-id/${id}/${type}`);
    },
    AddOrUpdateCampaign: async (obj) => {
        return await BaseServices.Post("/api/Campaign/add-or-update-campaign/", obj);
    },
    DeleteCampaigns: async (ids) => {
        return await BaseServices.Post("/api/Campaign/delete-campaigns", ids);
    },
    GetProductMilestoneSetId: async (setId, campaignId) => {
        return await BaseServices.Get(`/api/Campaign/get-product-milestone-setId/${setId}/${campaignId}`);
    },

    //#region campaign milestone
    GetDashboardCampaignMilestone: async () => {
        return await BaseServices.Get("/api/Campaign/get-campaign-milestone-dashboard");
    },
    GetCampaignProductMilestonePaging: async (paging) => {
        return await BaseServices.Post("/api/Campaign/get-campaign-milestones-paging", paging);
    },
    DeleteCampaignMilestone: async (id) => {
        return await BaseServices.Post("/api/Campaign/delete-campaign-milestone?id=" + id, null);
    },
    //#endregion

    //#region Campaign Boosting
    ShowCampaignBoostingByRole: async () => {
        return await BaseServices.Get("/api/Campaign/show-campaign-boosting-byrole");
    },
    GetDashboardCampaignBoosting: async () => {
        return await BaseServices.Get("/api/Campaign/get-campaign-boosting-dashboard");
    },
    GetCampaignBoostingPaging: async (model) => {
        return await BaseServices.Post(`/api/Campaign/get-campaign-boosting-paging`, model);
    },
    GetAppSetToBoostingPaging: async (paging) => {
        return await BaseServices.Post(`/api/Campaign/get-appset-to-boosting-paging`, paging);
    },
    SaveCampaignBoosting: async (model) => {
        return await BaseServices.Post(`/api/Campaign/save-campaign-boosting`, model);
    },
    GetCampaignBoostingById: async (id) => {
        return await BaseServices.Get(`/api/Campaign/get-campaign-boosting-byid/${id}`);
    },
    DeleteCampaignBoostings: async (ids) => {
        return await BaseServices.Post("/api/Campaign/delete-campaign-boostings", ids);
    },
    MarketerRegisterBoosting: async (model) => {
        return await BaseServices.Post("/api/Campaign/marketer-register-boosting", model);
    },
    MarketerUnRegisterBoosting: async (id) => {
        return await BaseServices.Post("/api/Campaign/marketer-unregister-boosting?id=" + id, null);
    },
    GetMarketePerformanceBoostingPaging: async (model) => {
        return await BaseServices.Post(`/api/Campaign/get-marketer-performance-boosting-paging`, model);
    },
    //#endregion

    //#region Campaign Buymore
    CreateUpdateCampaignBuyMore: async (model) => {
        return await BaseServices.Post(`/api/Campaign/create-update-buy-more`, model);
    },
    RemoveCampaignBuyMore: async (campaignId) => {
        return await BaseServices.Get(`/api/Campaign/remove-buy-more-by-id/${campaignId}`);
    },
    GetCampaignBuyMoreById: async (campaignId) => {
        return await BaseServices.Get(`/api/Campaign/get-buy-more-by-id/${campaignId}`);
    },
    GetCampaignBuyMoreByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Campaign/get-buy-more-by-paging`, paging);
    },
    RemoveCampaignBuyMoreByIds: async (ids) => {
        return await BaseServices.Post(`/api/Campaign/remove-buy-more-by-ids`, ids);
    },
    //#endregion

    //#region Campaign FreeShip
    GetCampaignFreeShipByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Campaign/get-free-ship-by-paging`, paging);
    },
    CreateUpdateCampaignFreeShip: async (model) => {
        return await BaseServices.Post(`/api/Campaign/create-update-free-ship`, model);
    },
    GetCampaignFreeShipById: async (campaignId) => {
        return await BaseServices.Get(`/api/Campaign/get-free-ship-by-id/${campaignId}`);
    },
    RemoveCampaignFreeShipByIds: async (ids) => {
        return await BaseServices.Post(`/api/Campaign/remove-free-ship-by-ids`, ids);
    },
    //#endregion
};

export default CampaignServices;
