import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import ManyShareServices from "../based/services/ManyShareServices";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import Request from "../based/Request";


const BANNER_TYPE = {
    HeaderTopBanner : 0,
    HeaderBottomBanner : 1,
    FooterBanner : 2,
}

const initBanner = {
    id: 0,
    bannerType: 0,
    bannerUrl: 0,
    bannerPosition: 0
}

const ManyShareBanner = (props) => {
    const [banner1 , setBanner1] = useState([])
    const [banner3 , setBanner3] = useState({})


    useEffect(()=>{
        getBanner1()
        getBanner3()
    },[])
    
    const getBanner1 = async () => {
        let [err , data] = await ManyShareServices.GetBannerManageByPosition(BANNER_TYPE.HeaderTopBanner)
        if(!err && data) {
            setBanner1(data)
        }else{
            setBanner1([])
        }
    }

    const getBanner3 = async () => {
        let [err , data] = await ManyShareServices.GetBannerManageByPosition(BANNER_TYPE.FooterBanner)
        if(!err &&  data) {
            setBanner3(data)
        }else{
            setBanner3({})
        }
    }

    const handleAddBanner = async (model) => {
        let [err , data] = await ManyShareServices.CreateUpdateBannerManage(model)
        if(!err &&  data) {
            getBanner1()
            getBanner3()
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR , "Thêm thất bại")
        }
    }

    const handleUpdateUrl = async (id,url) => {
        let [err , data] = await ManyShareServices.UpdateBannerUrl({
            id: id, bannerUrl: url
        })
        if(!err &&  data) {
            getBanner1()
            getBanner3()
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR , "Cập nhật thất bại")
        }
    }

    const handleDeleteBanner = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [error, data] = await ManyShareServices.DeleteBannerManage(id);
                if (!error && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getBanner1()
                    getBanner3()
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }


    async function handleUploadFile(e, id) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            handleUpdateUrl(id, res.data.imageUrl)
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }


    return <ManyShareBannerWapper>
        <div className="page-container">
            <div className="w-80">
                <div className="box-image">
                    <h4>Banner 1</h4>
                    {banner1 && banner1.length > 0 && 
                    banner1.map((item , idx)=>{
                        return <div key={idx} className="image-col">
                        <div className="image-item">
                            {item.banners.map((itm , i)=>{
                                if(itm.bannerType == 1)
                                return <div key={i} className="image-item-child">
                                        
                                        <div className="box-image-input">
                                        {itm.bannerUrl ? 
                                            <img src={itm.bannerUrl } />
                                            : 
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => {
                                                    handleUploadFile(e, itm.id);
                                                }}
                                            />
                                        }

                                        </div>
                                        <i>Desktop</i>
                                        {itm.bannerUrl && <span onClick={()=>{
                                            handleUpdateUrl(itm.id,"")
                                        }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 1L1 9" stroke="#D70000"/>
                                                <path d="M9 9L1 1" stroke="#D70000"/>
                                            </svg>
                                        </span>}
                                    </div>
                                else
                                return <div key={i} className="image-item-child">
                                       <div className="box-image-input">
                                        {itm.bannerUrl ? 
                                            <img src={itm.bannerUrl } />
                                            : 
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => {
                                                    handleUploadFile(e, itm.id);
                                                }}
                                            />
                                        }

                                        </div>
                                        <i>Mobile</i>
                                        {itm.bannerUrl && <span onClick={()=>{
                                            handleUpdateUrl(itm.id,"")
                                        }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 1L1 9" stroke="#D70000"/>
                                                <path d="M9 9L1 1" stroke="#D70000"/>
                                            </svg>
                                        </span>}
                                    </div>
                            })}
                            
                            
                            <div className="remove"
                                onClick={()=>{
                                    handleDeleteBanner(item.id);
                                }}
                            >Xóa</div>
                        </div>
                    </div>
                    })}
                    
                    <div className="image-col more" onClick={()=>{
                        handleAddBanner({
                            id: 0,
                            bannerPosition: BANNER_TYPE.HeaderTopBanner
                        })
                    }}>
                        Thêm +
                    </div>
                </div>

                <div className="box-image">
                    <h4>Banner 2</h4>
                    {banner3 && banner3.length > 0 && 
                    banner3.map((item , idx)=>{
                        return <div key={idx} className="image-col">
                        <div className="image-item">
                        {item.banners.map((itm , i)=>{
                                if(itm.bannerType == 1)
                                return <div  key={i} className="image-item-child">
                                       <div className="box-image-input">
                                        {itm.bannerUrl ? 
                                            <img src={itm.bannerUrl } />
                                            : 
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => {
                                                    handleUploadFile(e, itm.id);
                                                }}
                                            />
                                        }

                                        </div>
                                        <i>Desktop</i>
                                        {itm.bannerUrl && <span onClick={()=>{
                                            handleUpdateUrl(itm.id,"")
                                        }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 1L1 9" stroke="#D70000"/>
                                                <path d="M9 9L1 1" stroke="#D70000"/>
                                            </svg>
                                        </span>}
                                    </div>
                                else
                                return <div key={i} className="image-item-child">
                                        <div className="box-image-input">
                                        {itm.bannerUrl ? 
                                            <img src={itm.bannerUrl } />
                                            : 
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => {
                                                    handleUploadFile(e, itm.id);
                                                }}
                                            />
                                        }

                                        </div>
                                        <i>Mobile</i>
                                        {itm.bannerUrl && <span onClick={()=>{
                                            handleUpdateUrl(itm.id,"")
                                        }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 1L1 9" stroke="#D70000"/>
                                                <path d="M9 9L1 1" stroke="#D70000"/>
                                            </svg>
                                        </span>}
                                    </div>
                            })}
                            <div className="remove" 
                                onClick={()=>{
                                    handleDeleteBanner(item.id);
                                }}
                            >Xóa</div>
                        </div>
                    </div>
                    })}
                    {banner3 && banner3.length < 1 && 
                    <div className="image-col more" onClick={()=>{
                        handleAddBanner({
                            id: 0,
                            bannerPosition: BANNER_TYPE.FooterBanner
                        })
                    }}>
                        Thêm +
                    </div>}
                    
                </div>
            </div>
            <div className="w-20">
                <img src="https://cdn.ranus.vn/images/1/46e2e1a7-882a-4363-9d06-ec6eb91bdbe8.jpg" />
            </div>
        </div>
       
    </ManyShareBannerWapper>
}

const ManyShareBannerWapper = styled.div`

    .page-container{
        display: flex;
        align-items: flex-start;
        
        .w-80{
            width: 75%;


            


        }


        .box-image{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;

            
            h4{
                width: 100%;
                margin: 10px 0 0 10px;
            }

            .image-col{
                width: 33.33333%;
                padding: 0 8px;

                &.more{
                    color: #64C5B1;
                    text-align: left;
                    padding-top: 10px;
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    cursor: pointer;

                }

                .image-item{
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    background-color: white;
                    padding: 10px 10px 20px 10px;
                    border-radius: 10px;
                    position: relative;

                    .remove{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        cursor: pointer;
                        line-height: 1;
                        color: #F96A74;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                    }

                    .image-item-child{
                        width: calc(50% - 5px);
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;


                        i{

                        }

                       

                        span{
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            cursor: pointer;
                            line-height: 1;
                        }

                        .box-image-input{
                            width: 100%;
                            aspect-ratio: 1/1;
                            display: grid;
                            place-items: center;

                            img{
                                width: 100%;
                                aspect-ratio: 1/1;
                                object-fit: contain;
                            }

                            input{
                                max-width: 100px;
                            }
                        }
                    }
                }
            }
        }


        .w-20{
            width: 25%;
            min-width:25%;
            padding-left: 10px;

            img{
                width: calc(100% - 10px);
                height: auto;
                object-fit: contain;
            }
        }

    }

    


    
`;


export default ManyShareBanner;
