import React, { useState, useEffect, useRef } from "react";
import Common from "../based/Common";
import CommonTable, { } from "../based/CommonTable";
import Language from "../based/Language";
import Loading from "../based/Loading";
import NumberFormat from "react-currency-format";
import { Layout } from "../layout/Layout";
import Breadcrumbs from "../based/Breadcrumbs";
import PaidLoyaltyTable from "./components/PaidLoyaltyTable";
import SoldProducts from "../dashboard/components/SoldProducts";

export default function FinanceManagement(props) {
    const [isBusy, setIsBusy] = useState(false)
    const [datas, setDatas] = useState([])
    const [tabIndex, setTabindex] = useState(0)

    useEffect(() => {
        setDatas(props.datas)
    }, [props.datas])

    return <React.Fragment>
        <Loading show={isBusy} msg='Đang tải dữ liệu' />
        <Breadcrumbs currentPage='Thống kê tài chính theo kỳ' parentPage='Tài chính' hrefParent='/finance' />
        <div className="row">
            <div className="col-md-12">
                <div className="detail-tab px-0 text-center">
                    <div className="nav nav-tabs">
                        <div className="nav-item">
                            <a href="javascript:void(0)" className={tabIndex == 0 ? "nav-link active" : 'nav-link'} onClick={() => setTabindex(0)}>
                                <span className="d-none d-sm-block">Sao kê tài khoản</span>
                            </a>
                        </div>
                        <div className="nav-item">
                            <a href="javascript:void(0)" className={tabIndex == 1 ? "nav-link active" : 'nav-link'} onClick={() => setTabindex(1)}>
                                <span className="d-none d-sm-block">Sản phẩm đã bán</span>
                            </a>
                        </div>
                    </div>
                </div>
                {
                    tabIndex == 0 && <PaidLoyaltyTable creator={true} />
                }
                {
                    tabIndex == 1 && <SoldProducts />
                }
            </div>
        </div>
    </React.Fragment>
}