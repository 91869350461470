import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { ARTWORK_STATUS, ARTWORK_STATUS_OPTIONS } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import Loading from "../based/Loading";
import Request from "../based/Request";
import ArtworkServices from "../based/services/ArtworkServices";
import CommonServices from "../based/services/CommonServices";
import SetCensorship from "./SetCensorship";

const Tab_Initializing = 999;
const TAB_SCREEN = [
    {
        value: ARTWORK_STATUS.All,
        label: "Tất cả thiết kế",
    },
    {
        value: Tab_Initializing,
        label: "Thiết kế đang tạo",
    },
    {
        value: ARTWORK_STATUS.Offline,
        label: "Thiết kế ẩn",
    },
    {
        value: ARTWORK_STATUS.Online,
        label: "Thiết kế online",
    },
];

const REVIEW_STATUS = [
    {
        value: 0,
        label: "Chưa duyệt",
    },
    {
        value: 1,
        label: "Đã duyệt",
    },
    {
        value: 2,
        label: "Duyệt thất bại",
    },
];
const MANUAL_RATE = [
    {
        value: 1,
        label: "Rất xấu",
    },
    {
        value: 2,
        label: "Xấu",
    },
    {
        value: 3,
        label: "Bình thường",
    },
    {
        value: 4,
        label: "Đẹp",
    },
    {
        value: 5,
        label: "Rất đẹp",
    },
];
export default function ArtworksSetCensorship(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, artworkStatus: ARTWORK_STATUS.All, orderBy: "id", orderDirection: "desc" });
    const [datas, setDatas] = useState([]);
    const [tabIndex, setTabIndex] = useState(ARTWORK_STATUS.All);
    const [setId, setSetId] = useState(0);
    const [creatorOptions, setCreatorOptions] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);

    useEffect(() => {
        getDatas(paging, tabIndex);
        getCreator();
    }, []);

    async function getCreator() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setCreatorOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function getDatas(newPaging, tab) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetSetPaging(newPaging, newPaging.artworkStatus, tab);
        if (!error && data) {
            let pagingUp = { ...data.paging, artworkStatus: newPaging.artworkStatus };
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = new Date(pagingUp.startDate);
                if (pagingUp.endDate) pagingUp.endDate = new Date(pagingUp.endDate);
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function _handleChangeTab(tab) {
        if (tabIndex != tab) {
            let pagingUp = { ...paging, artworkStatus: tab == Tab_Initializing ? ARTWORK_STATUS.All : tab };
            setTabIndex(tab);
            getDatas(pagingUp, tab);
        }
    }
    const _renderTab = useMemo(() => (
        <ul className="nav nav-tabs tabs-bordered">
            {TAB_SCREEN.map((item, index) => {
                return (
                    <li key={index} className="nav-item">
                        <a className={tabIndex == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(item.value)}>
                            <span>{item.label}</span>
                        </a>
                    </li>
                );
            })}
        </ul>
    ));
    function handleSortColumn(columnName) {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        getDatas(newPaging, tabIndex);
    }
    function _renderHeader() {
        switch (tabIndex) {
            case Tab_Initializing:
                return (
                    <thead>
                        <tr>
                            <th width="50" className="text-center cursor-pointer" onClick={() => handleSortColumn("id")}>
                                STT &nbsp;
                                {paging.orderBy === "id" && paging.orderDirection.toUpperCase() === "DESC" ? <i className="fa fa-sort-desc fa-sort-thead"></i> : paging.orderBy === "id" ? <i className="fa fa-sort-asc fa-sort-thead"></i> : icon}
                            </th>
                            <th width="300">Tên set thiết kế</th>
                            <th width="100" className="text-center">
                                Trạng thái
                            </th>
                            <th width="200" className="text-center">
                                Loại sản phẩm
                            </th>
                            <th width="200" className="text-center">
                                Thumbnail Thiết kế
                            </th>
                            <th width="230">Thông tin thiết kế</th>
                            <th width="200">Ghi chú</th>
                            <th width="100">Trạng thái kiểm duyệt</th>
                            <th width="100" className="text-center"></th>
                        </tr>
                    </thead>
                );
            default:
                let icon = <i className="fa fa-sort fa-sort-thead"></i>;
                return (
                    <thead>
                        <tr>
                            <th width="50" className="text-center cursor-pointer" onClick={() => handleSortColumn("id")}>
                                STT &nbsp;
                                {paging.orderBy === "id" && paging.orderDirection.toUpperCase() === "DESC" ? <i className="fa fa-sort-desc fa-sort-thead"></i> : paging.orderBy === "id" ? <i className="fa fa-sort-asc fa-sort-thead"></i> : icon}
                            </th>
                            <th width="250">Tên set thiết kế</th>
                            <th width="100" className="text-center">
                                Creator
                            </th>
                            <th width="100" className="text-center">
                                Trạng thái
                            </th>
                            <th width="200" className="text-center">
                                Thumbnail Thiết kế
                            </th>
                            <th width="230">Thông tin thiết kế</th>
                            <th width="100" className="text-center cursor-pointer" onClick={() => handleSortColumn("loyaltyAmount")}>
                                Tiền bản quyền &nbsp;
                                {paging.orderBy === "loyaltyAmount" && paging.orderDirection.toUpperCase() === "DESC" ? (
                                    <i className="fa fa-sort-desc fa-sort-thead"></i>
                                ) : paging.orderBy === "loyaltyAmount" ? (
                                    <i className="fa fa-sort-asc fa-sort-thead"></i>
                                ) : (
                                    icon
                                )}
                            </th>
                            <th width="100" className="text-center cursor-pointer" onClick={() => handleSortColumn("totalSell")}>
                                Số lượng đã bán&nbsp;
                                {paging.orderBy === "totalSell" && paging.orderDirection.toUpperCase() === "DESC" ? (
                                    <i className="fa fa-sort-desc fa-sort-thead"></i>
                                ) : paging.orderBy === "totalSell" ? (
                                    <i className="fa fa-sort-asc fa-sort-thead"></i>
                                ) : (
                                    icon
                                )}
                            </th>
                            <th width="100">Trạng thái kiểm duyệt</th>
                            <th width="100" className="text-center"></th>
                        </tr>
                    </thead>
                );
        }
    }
    function _renderVariant(variants) {
        if (variants && variants.length > 0) {
            return variants.map((variant, k) => {
                return (
                    <React.Fragment key={k}>
                        <p className="mb-0">
                            <span>-&nbsp;{variant.name}&nbsp;</span>
                            {variant.attributeValuesDetail &&
                                variant.attributeValuesDetail.length > 0 &&
                                variant.attributeValuesDetail.map((attribute, at) => {
                                    return (
                                        <span key={at} className="margin-bottom-0 font-italic">
                                            -&nbsp;{!attribute.value.includes("#") && `${attribute.name} :`} <span className="badge badge-custom">{attribute.displayName ? attribute.displayName : attribute.value}</span>
                                        </span>
                                    );
                                })}
                        </p>
                    </React.Fragment>
                );
            });
        }
    }
    function handleOpenPdp(e, item) {
        e.preventDefault();
        window.open(`${Request.HOME_URL}/product-detail-page/${item.ageSetId}/${item.ageVariantId}`, "_blank");
    }
    function _renderBody() {
        if (datas && datas.length > 0) return <tbody>{_renderContentBody()}</tbody>;
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan="100" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    }
    function _renderContentBody() {
        let content = [];
        if (datas && datas.length > 0) {
            datas.map((item, k) => {
                let rowSpan = (item.artworks && item.artworks.length) > 0 ? item.artworks.length : 1;
                let count = 0;
                if (item.artworks && item.artworks.length > 0) {
                    item.artworks.map((art, a) => {
                        count = count + 1;
                        let arr = [];
                        let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;
                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                    {no}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle">
                                    {item.title}
                                </td>
                            );
                            if (tabIndex != Tab_Initializing) {
                                arr.push(
                                    <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                        {item.createdBy}
                                    </td>
                                );
                            }

                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                    {item.statusName}
                                </td>
                            );
                            if (tabIndex == Tab_Initializing) {
                                arr.push(
                                    <td rowSpan={rowSpan} className="vertical-align-middle">
                                        {_renderVariant(item.productVariants)}
                                    </td>
                                );
                            }
                        }
                        arr.push(
                            <td className="vertical-align-middle text-center" style={{ backgroundImage: "url(/images/transparent.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                {art.thumbnail ? (
                                    <a className="iconImageDefault full-width" href="#" onClick={(e) => handleOpenPdp(e, art)}>
                                        <img src={art.thumbnail} alt="Thumbnail Thiết kế" className="variant-avatar" />
                                    </a>
                                ) : (
                                    <strong>Thiết kế đang được xử lý...</strong>
                                )}
                            </td>
                        );
                        arr.push(
                            <td className="vertical-align-middle">
                                <p className="mb-0">
                                    <span className="m-r-5">Kích thước in:</span>{" "}
                                    <strong>
                                        {art.widthCm.toFixed(2)}cm x {art.heightCm.toFixed(2)}cm
                                    </strong>
                                </p>
                                <p className="mb-0">
                                    <span className="m-r-5">Kích thước ảnh:</span>{" "}
                                    <strong>
                                        {art.width} x {art.height} pixel
                                    </strong>
                                </p>
                                <p className="mb-0">
                                    <span className="m-r-5">
                                        <strong>{art.dpi} DPi</strong>
                                    </span>
                                </p>
                                <p className="mb-0">
                                    <span className="m-r-5">Dung lượng:</span> <strong>{art.size}MB</strong>
                                </p>
                            </td>
                        );
                        if (tabIndex == Tab_Initializing) {
                            arr.push(<td className="vertical-align-middle">{art.remark}</td>);
                        }
                        if (tabIndex != Tab_Initializing && count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle text-right">
                                    <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={item.loyaltyAmount} />
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle text-right">
                                    <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.totalSell} />
                                </td>
                            );
                        }
                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                    <div className={`rounded text-white status ${item.manualReviewStatus == 1 ? "label-success" : "label-warning"} `}>
                                        {item.manualReviewStatusName}
                                        <br />
                                        {item.manualRateName}
                                    </div>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                    <i
                                        title="Kiểm duyệt"
                                        className="mdi mdi-grease-pencil mdi-24px cursor-pointer"
                                        style={{ color: "#159a7f" }}
                                        onClick={() => {
                                            setSetId(item.id);
                                            setIsShowModal(true);
                                        }}
                                    ></i>
                                </td>
                            );
                        }
                        content.push(
                            <tr key={k + "_" + a}>
                                {arr.map((ar, iiii) => {
                                    return <React.Fragment key={iiii}>{ar}</React.Fragment>;
                                })}
                            </tr>
                        );
                    });
                } else {
                    let arr = [];
                    let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;
                    let rowSpan = 1;
                    arr.push(
                        <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                            {no}
                        </td>
                    );
                    arr.push(
                        <td rowSpan={rowSpan} className="vertical-align-middle">
                            {item.title}
                        </td>
                    );
                    if (tabIndex != Tab_Initializing) {
                        arr.push(
                            <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                {item.createdBy}
                            </td>
                        );
                    }
                    arr.push(
                        <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                            {item.statusName}
                        </td>
                    );
                    arr.push(<td></td>);
                    arr.push(<td></td>);
                    if (tabIndex == Tab_Initializing) {
                        arr.push(<td className="vertical-align-middle"></td>);
                        arr.push(<td className="vertical-align-middle"></td>);
                        arr.push(<td className="vertical-align-middle"></td>);
                    }
                    if (tabIndex != Tab_Initializing) {
                        arr.push(
                            <td rowSpan={rowSpan} className="vertical-align-middle text-right">
                                <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={item.loyaltyAmount} />
                            </td>
                        );
                        arr.push(
                            <td rowSpan={rowSpan} className="vertical-align-middle text-right">
                                <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.totalSell} />
                            </td>
                        );
                        arr.push(
                            <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                                <div class={`rounded text-white status ${item.manualReviewStatus == 1 ? "label-success" : "label-warning"} `}>
                                    {item.manualReviewStatusName}
                                    <br />
                                    {item.manualRateName}
                                </div>
                            </td>
                        );
                    }
                    arr.push(
                        <td rowSpan={rowSpan} className="vertical-align-middle text-center">
                            <i
                                title="Kiểm duyệt"
                                className="mdi mdi-grease-pencil mdi-24px cursor-pointer"
                                style={{ color: "#159a7f" }}
                                onClick={() => {
                                    setSetId(item.id);
                                    setIsShowModal(true);
                                }}
                            ></i>
                        </td>
                    );
                    content.push(
                        <tr key={k}>
                            {arr.map((ar, iiii) => {
                                return <React.Fragment key={iiii}>{ar}</React.Fragment>;
                            })}
                        </tr>
                    );
                }
            });
        }
        return content;
    }
    const _renderFilter = useMemo(() => (
        <div className="p-y-10 box__actions">
            <div className="form-group full-width">
                <div className="box__search" style={{ minHeight: 163 }}>
                    <div className=" form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="createdBy">Người tạo</label>
                            <TFUSelect name="createdBy" value={paging.createdBy} options={creatorOptions} placeholder="---Chọn người tạo---" onChanged={(value) => setPaging({ ...paging, createdBy: value })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="setName">Tên set thiết kế:</label>
                            <Textbox className="form-control" name="setName" onChanged={(name, value) => setPaging({ ...paging, setName: value })} value={paging.setName} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="artworkStatus">Trạng thái thiết kế:</label>
                            <TFUSelect
                                name="artworkStatus"
                                value={paging.artworkStatus}
                                options={ARTWORK_STATUS_OPTIONS}
                                placeholder="---Tất cả---"
                                onChanged={(value) => {
                                    setPaging({ ...paging, artworkStatus: value != null && value >= -1 ? value : -2 });
                                }}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="reviewStatus">Trạng thái kiểm duyệt:</label>
                            <TFUSelect name="reviewStatus" value={paging.reviewStatus} options={REVIEW_STATUS} placeholder="---Tất cả---" onChanged={(value) => setPaging({ ...paging, reviewStatus: value != null ? value : -1 })} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="reviewStatus">Điểm số kiểm duyệt:</label>
                            <TFUSelect name="manualRate" value={paging.manualRate} options={MANUAL_RATE} placeholder="---Tất cả---" onChanged={(value) => setPaging({ ...paging, manualRate: value != null ? value : -1 })} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="time">Thời gian:</label>
                            <div className="input-group form-row">
                                <div className="input-daterange input-group" id="date-range">
                                    <TFUDatetime dateFormat="dd/MM/yyyy" name="startDate" maxDate={paging.endDate} value={paging.startDate} placeholder="Từ ngày" onChanged={(newValue) => setPaging({ ...paging, startDate: new Date(newValue) })} />
                                    <TFUDatetime dateFormat="dd/MM/yyyy" name="endDate" minDate={paging.startDate} value={paging.endDate} placeholder="Đến ngày" onChanged={(newValue) => setPaging({ ...paging, endDate: new Date(newValue) })} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row ml-1">
                        <div className="form-group">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => getDatas(paging, tabIndex)}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));
    function _renderAction() {}
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Kiểm duyệt sản phẩm" />
            <Loading show={isLoading} msg={msg} />
            {_renderTab}
            <div className="col-sm-12 card-box p-3">
                <div className="form-row">
                    <div className="col-sm-12">{_renderFilter}</div>
                </div>
                <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging, tabIndex)} searchBasic={false} />
            </div>
            {isShowModal && <SetCensorship isShow={isShowModal} setId={setId} onClose={() => setIsShowModal(false)} onSaveSucceed={() => getDatas(paging, tabIndex)} />}
        </React.Fragment>
    );
}
