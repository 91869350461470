import React, { useState } from 'react'
import Breadcum from '../../layout/refactor/Breadcum/Breadcum'
import { Block, Box } from './adm.elm'
import LayoutLight from '../../layout/refactor/Layout/Layout'
import LoadingR from '../../based/LoadingR'

const AdminDash = () => {
    var menuItem = ['Trang chủ', 'Dashboard']
    const [isLoading, setIsLoading] = useState(false)
    return (
        <LayoutLight>
            <Breadcum menu={menuItem} title='Dashboard' />
            <LoadingR isShow={isLoading} />
            <Box>
                <div className='box__header'>Số sản phẩm đang chờ thanh toán</div>
                <div className='box__content'>
                    <Block bgColor='#00c853'>
                        <div className='header'>
                            <span>Sản phẩm đã mua chưa hoàn thành đơn hàng</span>
                            <span><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                <span>10 sản phẩm</span>
                                <span className='price'>1.200.000 đ</span>
                            </div>
                        </div>
                    </Block>
                    <Block bgColor='#ff9100'>
                        <div className='header'>
                            <span>Sản phẩm đã hoàn thành đơn.</span>
                            <span><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                <span>10 sản phẩm</span>
                                <span className='price'>1.200.000 đ</span>
                            </div>
                        </div>
                    </Block>
                </div>
            </Box>
            <Box>
                <div className='box__header'>Doanh thu 6 tuần gần đây</div>
                <div className='box__content'>
                </div>
            </Box>
        </LayoutLight>
    )
}

export default AdminDash
