import React, { useState, useEffect, useContext } from "react";

import Loading from "../based/Loading";
import Request from "../based/Request";
import { NOTIFY, CONSTANTS } from "../based/Constants";
import { Notify } from "../based/Notify";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import TFUImage from "../based/inputs/Image";
import useFormValidate from "../hooks/useFormValidate";
import TFUSelectSubdivisions from "../based/TFUSelectSubdivisions";
import { ApplicationContext } from "../context/ApplicationContext";
import PhoneNumber from "../based/inputs/PhoneNumber";
import Breadcrumbs from "../based/Breadcrumbs";
import { Layout } from "../layout/Layout";

export default function PersonalInfo(props) {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const { addRef, displayError, isValid } = useFormValidate();
    const [user, setUser] = useState([]);
    const { applicationInfo, setApplicationInfo } = useContext(ApplicationContext);

    useEffect(() => {
        getPersonalInfo();
    }, []);

    function getPersonalInfo() {
        Request.Get("/api/accounts/get-personal-info")
            .then((res) => {
                let data = res.data;
                setUser(data);
            })
            .catch((err) => {});
    }
    function _handleChangedPhone(value) {
        let regex = /^\d+$/;
        let userOld = { ...user };
        if (value) {
            let isValid = regex.test(value);
            if (!isValid) {
                value = userOld.phoneNumber;
            }
        }
        setUser({ ...user, phoneNumber: value });
    }
    function _handleSave() {
        if (isValid()) {
            setBusy(true);
            setMsg("Đang lưu dữ liệu...");

            Request.Post("/api/accounts/save-personal-info", user)
                .then((res) => {
                    if (res.success) {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.SUCCESS, "Thành công", "Thay đổi thông tin thành công");
                        setApplicationInfo({
                            ...applicationInfo,
                            userInfo: {
                                ...applicationInfo.userInfo,
                                avatar: user.avatar,
                                fullName: user.firstName + " " + user.lastName,
                            },
                        });
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }
    function _handleInputChange(key, value) {
        if (value) {
            setUser({ ...user, avatar: value });
            document.getElementById("uploadImages").value = null;
        }
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage="Thông tin cá nhân" />
            <div className="card-box col-12 col-lg-8 offset-md-2 p-3">
                <div className="form-row">
                    <div className="form-group col-md-9">
                        <div className="form-group">
                            <label htmlFor="userName">Tên đăng nhập</label>
                            <Textbox id="userName" value={user.userName} className="form-control" readOnly />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="firstName" className="required">
                                    Họ
                                </label>
                                <Textbox id="firstName" value={user.firstName} name="firstName" required ref={addRef} className="form-control" onChanged={(name, value) => setUser({ ...user, firstName: value })} />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="lastName" className="required">
                                    Tên
                                </label>
                                <Textbox id="lastName" value={user.lastName} name="lastName" required ref={addRef} className="form-control" onChanged={(name, value) => setUser({ ...user, lastName: value })} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="email" className="required">
                                    Email
                                </label>
                                <Textbox id="email" value={user.email} name="email" required ref={addRef} className="form-control" onChanged={(name, value) => setUser({ ...user, email: value })} />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="phoneNumber" className="required">
                                    Số điện thoại
                                </label>
                                <PhoneNumber id="phoneNumber" name="phoneNumber" addRef={addRef} required value={user.phoneNumber} onChanged={(name, value) => _handleChangedPhone(value)} />
                            </div>
                        </div>
                        <TFUSelectSubdivisions
                            showProvince
                            showDistrict
                            showWard
                            provinceCode={user.provinceCode}
                            districtCode={user.districtCode}
                            wardCode={user.wardCode}
                            onChanged={(obj) => setUser({ ...user, ...obj })}
                            addRef={addRef}
                            parentClassName="form-row"
                            childClassName="form-group col-md-12"
                        />
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="address">Địa chỉ:</label>
                                <TextArea id="address" name="address" className="form-control" onChanged={(name, value) => setUser({ ...user, address: value })} value={user.address} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <div className="ui-photo">
                            <TFUImage className="hide" id="uploadImages" name="uploadImages" images={user ? user.avatar : ""} onChanged={_handleInputChange} />
                            <ul className="ui-photo-grid clearfix">
                                <li className="ui-photo-item col-md-12">
                                    {user.avatar && user.avatar.length > 0 ? (
                                        <div className="ui-photo-box">
                                            <img className="ui-photo-item__image" src={user.avatar} />
                                            <div className="photo-hover-overlay">
                                                <ul className="photo-overlay-actions">
                                                    <li>
                                                        <button className="btn btn-link photo-overlay-actions__link" onClick={() => setUser({ ...user, avatar: "" })}>
                                                            <i className="fa fa-trash-o" style={{ color: "red" }}></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="file-upload position-relative"
                                            onClick={() => {
                                                document.getElementById("uploadImages").click();
                                            }}
                                        >
                                            <div className="fileupload-text text-center">
                                                <i className="fa fa-camera font-size-30px"></i>
                                                <p className="mb-0 mt-2 text-secondary">Ảnh đại diện</p>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                            <i className="fa fa-save m-r-5"></i> Lưu
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
