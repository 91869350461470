import React, { useEffect } from 'react';
import { useState } from 'react';
import TagsInput from 'react-tagsinput';
import Common from '../../../based/Common';
import CheckBox from '../../../based/inputs/CheckBox';
import Textbox from '../../../based/inputs/Textbox';
import BaseServices from '../../../based/services/BaseServices';
import PickupProductModal from './PickupProductModal';
import TableVariantsComp from './TableVariantsComp';

const AddEditMarketingProduct = ({ product, setProduct, onSave, onCancel }) => {
    const [isShowModalPickup, setShowModalPickup] = useState(false);
    const [selectedAllVariant, setSelectedAllVariant] = useState(false);

    useEffect(() => {
        if (!product || !product.campaigns) {
            _fetchCampaigns();
        }
    }, [])
    const _fetchCampaigns = async () => {
        const [err, data] = await BaseServices.Post(`/api/campaign/get-list-campaign`, { pageNumber: 1, pageSize: 1000, type: 6 });
        if (!err && data) {
            let campaigns = data.listObjects;
            setProduct({ ...product, campaigns: campaigns })
        }
    }
    const _pickupProduct = async () => {
        setShowModalPickup(true);
    }
    const _handlePickupProduct = async (p) => {
        //fetch variants:
        const [err, variants] = await BaseServices.Get(`/api/agencyProduct/get-agency-variant-by-setid/${p.id}`);
        if (!err && variants) {
            let url = (process.env.NODE_ENV === "production" ? "https://ranus.vn/" : "https://localhost:5000/") + p.friendlyUrl;
            let convertVariants = variants.map(m => ({
                thumbnail: m.productAvatar,
                variantId: m.id,
                attributes: m.attributeJsonValues ? JSON.parse(m.attributeJsonValues) : [],
                selected: false,
                appProductId: m.appProductId,
                productName: m.productName
            }));
            setProduct({
                ...product,
                setId: p.id,
                title: p.title,
                image: p.productAvatar,
                ranusUrl: url,
                variants: convertVariants
            });
            setShowModalPickup(false);
        }
        else {
            console.log('Error');
        }
    }
    const _handleInputChanged = (name, value) => {
        setProduct({ ...product, [name]: value })
    }
    function _handleTagsChanged(tags) {
        setProduct({ ...product, otherRequirements: tags })
    }
    function _handlePickVariant(variant, value) {
        let variants = [...product.variants];
        if (variant == null) {
            setSelectedAllVariant(value);
            variants.forEach(v => v.selected = value);
        } else {
            const { color, size } = variant;
            let v = variants.filter(m => m.color == color && m.size == size);
            debugger;
            if (v.length > 0) {
                for (let i = 0; i < v.length; i++) {
                    v[i].selected = true;
                }
            }
        }
        setProduct({ ...product, variants: variants });
    }

    function _handlePickCampaign(idx) {
        let groups = [...product.campaigns];
        groups[idx].selected = !groups[idx].selected
        setProduct({ ...product, campaigns: groups });
    }
    return (
        <div className='card-box padding-10'>
            <div className='row'>
                <div className='col-4'>
                    <label>Sản phẩm</label>
                    <div className='row'>
                        <div className='col-6' style={{ border: '1px solid #cdcdcd', minHeight: 200 }}>
                            {product.image && <img src={product.image} style={{ width: '100%' }} />}
                        </div>
                        <div className='col-6'>
                            {product.title}
                        </div>
                    </div>
                    <button className='btn btn-custom m-t-10' onClick={_pickupProduct}>Chọn sản phẩm</button>
                </div>
                <div className='col-4'>
                    <label>Link mua sản phẩm</label>
                    <div className='m-b-10'>
                        <Textbox
                            classNameInputText='w-100px'
                            prependText='Ranus'
                            id='ranusUrl'
                            name='ranusUrl'
                            value={product.ranusUrl}
                            readOnly
                        />
                    </div>
                    <div className='m-b-10'>
                        <Textbox
                            classNameInputText='w-100px'
                            prependText='Shopee'
                            id='shopeeUrl'
                            name='shopeeUrl'
                            onChanged={_handleInputChanged}
                            value={product.shopeeUrl}
                        />
                    </div>
                    <div className='m-b-10'>
                        <Textbox
                            classNameInputText='w-100px'
                            prependText='Lazada'
                            id='lazadaUrl'
                            name='lazadaUrl'
                            onChanged={_handleInputChanged}
                            value={product.lazadaUrl}
                        />
                    </div>
                    <div className='m-b-10'>
                        <Textbox
                            classNameInputText='w-100px'
                            prependText='Tiktok'
                            id='tiktokShopUrl'
                            name='tiktokShopUrl'
                            onChanged={_handleInputChanged}
                            value={product.tiktokShopUrl}
                        />
                    </div>
                </div>
                <div className='col-4'>
                    <label>Yêu cầu cho hình ảnh/video</label>
                    <TagsInput
                        value={product.otherRequirements ? product.otherRequirements : []}
                        onChange={_handleTagsChanged}
                        focusedClassName={"react-tagsinput--focused-custom"}
                        inputProps={{ className: "react-tagsinput-input-custom", placeholder: "Thêm yêu cầu" }}
                        tagProps={{ className: "react-tagsinput-tag-custom" }}
                    />
                    <span style={{ fontStyle: "italic" }}>
                        (<strong>Enter</strong> để thêm yêu cầu mới)
                    </span>
                </div>
            </div>
            <hr />
            <h4>Áp dụng cho các chiến dịch</h4>
            {
                product.campaigns && product.campaigns.map((g, i) => {
                    return <div key={i}>
                        <CheckBox name={'checkbox_' + g.id} checked={g.selected} onCheckedChange={() => _handlePickCampaign(i)}
                            label={`${g.title} (${Common.formatDate(new Date(g.startTime))} - ${Common.formatDate(new Date(g.endTime))})`} />
                    </div>
                })
            }
            <hr />
            <h4>Các biến thể</h4>
            {
                <TableVariantsComp product={product} onPickVariant={_handlePickVariant} selectedAllVariant={selectedAllVariant} editable={true} />
            }
            <hr />
            <div className='button-group text-right'>
                <button className='btn btn-dark m-b-10 m-r-5' onClick={onCancel}>Trở lại</button>
                <button className='btn btn-custom m-b-10' onClick={onSave}>Hoàn thành</button>
            </div>
            {
                isShowModalPickup && <PickupProductModal
                    onClose={() => setShowModalPickup(false)}
                    show={isShowModalPickup}
                    onSelectProduct={_handlePickupProduct}
                />
            }
        </div>
    );
};

export default AddEditMarketingProduct;