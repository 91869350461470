import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import Common from "../../based/Common";
import { NOTIFY } from "../../based/Constants";
import TFUImage from "../../based/inputs/Image";
import TextArea from "../../based/inputs/TextArea";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import TagTrendingServices from "../../based/services/TagTrendingServices";
import TextNumber from "../../based/inputs/TextNumber";
import useFormValidate from "../../hooks/useFormValidate";

const tagTrendingModel = {
    id: 0,
    tagTrending: [],
    thumbnail: "",
    description: "",
    index: 0,
};
const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState(null);
    const [templateType, setTemplateType] = useState(tagTrendingModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const [check, setCheck] = useState(true);

    useEffect(() => {
        if (props.id > 0) getTemplateType(props.id);
        else setTemplateType({ ...tagTrendingModel });
        return () => setTemplateType({ ...tagTrendingModel });
    }, [props.id]);

    const getTemplateType = async (id) => {
        setIsBusy(true);
        let [err, data] = await TagTrendingServices.GetTagTrendingById(id);
        if (!err && data) {
            setIsBusy(false);
            setTemplateType({ ...data });
        } else {
            setIsBusy(false);
            setTemplateType(tagTrendingModel);
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await TagTrendingServices.CreateUpdateTagTrending({ ...templateType });
            if (!err && data == 1) {
                setIsBusy(false);
                setTemplateType(tagTrendingModel);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Kiểu tempalte đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
    };

    const handleChangedImage = (name, url) => {
        let model = { ...templateType };
        model[name] = url;
        setTemplateType(model);
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className="form-group">
                <label htmlFor="name" className="required">
                    Tag Trending:
                </label>
                <TagsInput
                    addKeys={[13]}
                    addOnPaste
                    maxTags={10}
                    inputProps={{ placeholder: "" }}
                    value={templateType.tagTrending || []}
                    onChange={(tags) => {
                        let newTags = tags.reduce((prev, val, idx) => {
                            let value = Common.removeVietnameseFromString(val);
                            value = `#${value.replace(/ /g, "")}`;
                            return prev.indexOf(value) == -1 ? [...prev, value] : [...prev];
                        }, []);
                        setTemplateType({ ...templateType, tagTrending: newTags });
                    }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="ogImage">Description:</label>
                <div className="bg-white">
                    <TextArea id="description" name="description" className="form-control" onChanged={(name, value) => setTemplateType({ ...templateType, description: value })} value={templateType.description} />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="ogImage">Index:</label>
                <div className="bg-white">
                    <TextNumber id="index" name="index" className="form-control" onChanged={(name, value) => setTemplateType({ ...templateType, index: value })} value={templateType.index} />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="ogImage">Thumbnail:</label>
                <div className="bg-white">
                    <TFUImage name="thumbnail" image={templateType.thumbnail} onChanged={handleChangedImage} />
                </div>
            </div>
        </CommonModal>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
