import Common from "./Common";

export class TFUAutoCompleteProps {
    constructor() {
        this.urlFetchData = "";
        this.items = [new DropdownModel()];
        this.value = "";
        this.placeholder = "";
        this.name = "";
        this.params = "";
        this.onSelectedItemChanged = function (item = new DropdownModel()) {};
        this.required = false;
        this.paging = {};
    }
}

export class DropdownModel {
    constructor() {
        this.text = "";
        this.label = "";
        this.value = "";
    }
}
export class CommonPagingModel {
    constructor() {
        this.pageNumber = 1;
        this.pageSize = 10;
        this.totalRecord = 0;
        this.keyword = "";
    }
}
export class CommonTableProps {
    constructor() {
        this.tableGuid = Common.generateGuid();
        this.paging = new CommonPagingModel();
        this.searchBasic = true;
        this.hideSearchBasicButton = false;
        this.hideSearch = false;
        this.searchAdvanced = false;
        this.onFilter = (paging = new CommonPagingModel()) => {};
        this.searchAdvanced = () => {};
        this.renderAction = () => {};
        this.renderHeader = () => {};
        this.renderBody = () => {};
        this.datas = [];
        this.hideFooter = false;
    }
}

export class WarehouseInfoProps {
    constructor() {
        this.readOnly = false;
        this.onAddWarehouse = function () {};
    }
}
export class SelectionProps {
    constructor() {
        this.value = "";
        this.placeholder = "";
        this.onChanged = (value) => {};
        this.options = [];
    }
}

export class TFUSelectProps {
    constructor() {
        this.value = "";
        this.name = "";
        this.options = [new DropdownModel()];
        this.noOptionsMessage = "";
        this.isDisabled = false;
        this.placeholder = "";
        this.onChanged = (value) => {};
        this.onChangedObject = (selected) => {};
        this.required = false;
        this.requiredMsg = "";
        this.isDisabled = false;
        this.styles = {};
    }
}

export class ProductItemProps {
    constructor() {
        this.index = 0;
        this.productList = [new DropdownModel()];
    }
}

export class TFUSubdivisionProps {
    constructor() {
        this.provinceCode = "";
        this.districtCode = "";
        this.wardCode = "";
        this.isRequiredProvince = false;
        this.isRequiredDistrict = false;
        this.isRequiredWard = false;
        this.showProvince = true;
        this.showDistrict = true;
        this.showWard = true;
        this.returnSubdivisionsName = false;
        this.addRef = (ref) => {};
    }
    /**
     * on changed event
     * @param {TFUSubdivisionValue} obj The sub division object.
     */
    onChanged(obj) {}
}
export class TFUSubdivisionValue {
    constructor(provinceCode, districtCode, wardCode, provinceName, districtName, wardName) {
        this.provinceCode = provinceCode;
        this.districtCode = districtCode;
        this.wardCode = wardCode;
        this.provinceName = provinceName;
        this.districtName = districtName;
        this.wardName = wardName;
    }
}

export class TFUTooltipProps {
    constructor() {
        this.content = "";
        this.children = "";
    }
}

export class LotusUploadProps {
    constructor() {
        this.isShowUpload = true;
        this.name = "";
        this.buttonName = "Nhấn vào để thêm tài liệu";
        this.description = "";
        this.accept = "";
        this.fileLimit = 5;
        this.sizeLimit = 3;
        this.documentType = 0;
        this.multiple = false;
        this.isAction = true;
        this.isDelete = true;
        this.isPreviewFile = true;
        this.isShowFileName = false;
        this.documents = null;
        this.onChanged = (value) => {};
        this.createdBy = "";
    }
}
export class MultiLevelSelectionProps {
    constructor() {
        this.fetchOptions = () => {};
        this.multiple = false;
        this.onChanged = () => {};
    }
}
