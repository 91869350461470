import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { OrderTagDTGModel } from "../models/OrderTagDTGModel";
import ProductionServices from "../based/services/ProductionServices";
import Barcode from "react-barcode";
import { Layout } from "../layout/Layout";
import Loading from "../based/Loading";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { OrderTagDetailModel } from "../models/OrderTagDetailModel";
import { OrderTagItemModel } from "../models/OrderTagItemModel";
import { OrderTagMockupModel } from "../models/OrderTagMockupModel";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";

function ProcessCover(props) {
    const [tagDetail, setTagDetail] = useState(new OrderTagDTGModel());
    const [barcode, setBarcode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const _getTagDetail = async (tagId) => {
        let [err, data] = await ProductionServices.GetOrderTagDTGModel(tagId);
        if (!err && data) {
            //Xử lý status cover phủ
            let items = data.orderTagItems;
            let alreadyProcessCover = items.some((item) => item.preProcessCount > 0);
            if (alreadyProcessCover) {
                ConfirmDialog("Xác nhận", "Tag hiện tại đã được phủ, bạn có muốn tiếp tục phủ lại?", () => {
                    return new Promise((resolve, reject) => {
                        setIsLoading(true);
                        _updateInlinseStatus(tagId);
                        resolve({ title: "Thành công", msg: "Cập nhập thành công." });
                        setIsLoading(false);

                        //tiến hành update số
                        let tagItems = data.orderTagItems;
                        let tagItem = tagItems.find((x) => x.tagId == tagId);
                        if (tagItem) tagItem.preProcessCount += 1;
                        setTagDetail(data);
                    });
                });
            } else {
                _updateInlinseStatus(tagId);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thành công");

                //tiến hành update số
                let tagItems = data.orderTagItems;
                let tagItem = tagItems.find((x) => x.tagId == tagId);
                if (tagItem) tagItem.preProcessCount += 1;
                setTagDetail(data);
            }
        } else {
            let error = err.errors;
            let msg = CONSTANTS.MSG_ERROR;
            if (error && error.length > 0)
                msg = error.reduce((prev, item, index) => {
                    return (prev += `${item.value}<br/>`);
                }, "");
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
            setTagDetail(new OrderTagDTGModel());
        }
    };

    const _updateInlinseStatus = async (tagId) => {
        let [err, data] = await ProductionServices.UpdateProcessCoverById(tagId);
        if (!err) {
            let tag = { ...tagDetail };
        } else {
            let error = err.errors;
            let msg = CONSTANTS.MSG_ERROR;
            if (error && error.length > 0)
                msg = error.reduce((prev, item, index) => {
                    return (prev += `${item.value}<br/>`);
                }, "");
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
    };

    const _handleScanBarcode = (barcode) => {
        _getTagDetail(barcode);
    };

    return (
        <React.Fragment>
            <Loading show={isLoading} msg={"Đang tải dữ liệu..."} />
            <Breadcrumbs parentPage='Quản lý sản xuất' hrefParent='' currentPage='Xử lý nước phủ in DTG' />
            <div className='card-box col-md-8 offset-md-2'>
                <div className='card-body'>
                    <div className='form-group'>
                        <label className='col-form-label'>
                            <strong>Loại nước phủ:</strong>
                        </label>
                        <button
                            className={`btn btn-outline-dark waves-effect width-md waves-light ml-3 active}`}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <span className='dot center mr-1' style={{ background: tagDetail.orderTagDetail && tagDetail.orderTagDetail.dTGCoverColor }} />
                        </button>
                    </div>
                    <div className='form-group'>
                        <div className='row'>
                            {tagDetail.orderTagMockup.artworkMockupModels &&
                                tagDetail.orderTagMockup.artworkMockupModels.map((item, idx) => {
                                    return (
                                        <div className='col-md-6 center' key={idx}>
                                            <div className='form-group'>
                                                <img className='w-50 rounded' src={item.url} />
                                                <p className='font-weight-bold'>{item.artworkTypeName}</p>
                                            </div>
                                            <div className='form-group'>
                                                <p><strong>Rộng:</strong> {Common.formatNumber(item.imageWidth, 2)}cm</p>
                                                <p><strong>Dài:</strong> {Common.formatNumber(item.imageHeight, 2)}cm</p>
                                                <p><strong>Top - Cổ:</strong> {Common.formatNumber(item.fromCollar, 2)}cm</p>
                                                <p><strong>Giữa - Trái:</strong> {Common.formatNumber(item.halfWidth, 2)}cm</p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='form-row m-t-20'>
                                    <div className='col-md-9'>
                                        <input
                                            autocomplete='off'
                                            id='barcode'
                                            name='barcode'
                                            className='form-control text-center font-weight-bold'
                                            type='text'
                                            placeholder='Nhập barcode'
                                            maxlength='200'
                                            onChange={(e) => {
                                                setBarcode(e.target.value);
                                            }}
                                            value={barcode}
                                            onKeyPress={(e) => {
                                                if (e.charCode === 13) _handleScanBarcode(barcode);
                                            }}
                                        ></input>
                                    </div>
                                    <div className='col-md-3'>
                                        <button className='btn btn-custom btn-sm full-width' onClick={() => _handleScanBarcode(barcode)}>
                                            Nhập
                                        </button>
                                    </div>
                                </div>
                                <div className='form-row m-t-10'>
                                    <div className='col-md-12 text-center'>
                                        <Barcode value={barcode ? barcode : "000000000"} height='50' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 border'>
                                {tagDetail.orderTagDetail && (
                                    <div className='m-t-20'>
                                        <p>
                                            <strong>Mã đơn hàng:</strong> {tagDetail.orderTagDetail.orderId || 0}
                                        </p>
                                        <p>
                                            <strong>Mã sản phẩm:</strong> {tagDetail.orderTagDetail.sellerSKU}
                                        </p>
                                        <p>
                                            <strong>Loại áo:</strong> {tagDetail.orderTagDetail.productTypeName}
                                        </p>
                                        {tagDetail.orderTagDetail.attributeValues.map((element, index) => {
                                            return (
                                                <p key={index}>
                                                    {element.name}: {element.value}
                                                </p>
                                            );
                                        })}
                                        <p>
                                            <strong>Deadline:</strong> {Common.formatDate(new Date(tagDetail.orderTagDetail.deadline), "datetime")}
                                        </p>
                                        <p>
                                            <strong>Đã phủ:</strong> {tagDetail.orderTagDetail.totalPrintCount > 0 ? tagDetail.orderTagDetail.successfulPreProcessCount / tagDetail.orderTagDetail.totalPrintCount : ""}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-4 ml-3 border'>
                                <table className='table table-bordered m-t-20'>
                                    <thead>
                                        <tr>
                                            <td className='vertical-align-middle center'>Tag SX</td>
                                            <td className='vertical-align-middle center'>Đã phủ</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tagDetail.orderTagItems.every((item) => item.tagId) ? (
                                            tagDetail.orderTagItems.map((ele, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className='vertical-align-middle center'>{ele.tagId}</td>
                                                        <td className='vertical-align-middle center'>{ele.preProcessCount}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td className='center' colSpan={2}>
                                                    Không có dữ liệu
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-2'></div>
        </React.Fragment>
    );
}

ProcessCover.propTypes = {};

export default ProcessCover;
