import React, { Component } from "react";
import InputValidation from './InputValidation';

export default class ValidationBar extends InputValidation {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isValid ? null : (
                    <label className={"text-danger " + this.props.invalidBoxClassName} htmlFor={this.props.id}>
                        {this.state.msgInvalid}
                    </label>
                )}
            </React.Fragment>
        );
    }
}
