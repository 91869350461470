import { v4 as uuid } from 'uuid';
import {
  PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE,
  PROJECT_EDITOR_MODAL_TYPE as MODAL_TYPE,
} from '../../../based/Constants';

const openAddEmbedModal = (state) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };

  updatedEmbedModal.isShow = true;
  updatedEmbedModal.type = MODAL_TYPE.ADD;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};

const openEditEmbedModal = (state, itemId) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  updatedEmbedModal.content = updatedData[itemIdx].data.content;
  updatedEmbedModal.type = MODAL_TYPE.EDIT;
  updatedEmbedModal.isShow = true;
  updatedEmbedModal.embedId = itemId;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};

const openInsertEmbedModal = (state, itemId) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };

  updatedEmbedModal.content = '';
  updatedEmbedModal.type = MODAL_TYPE.INSERT;
  updatedEmbedModal.isShow = true;
  updatedEmbedModal.embedId = itemId;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};

const openErrorEmbedModal = (state, itemId) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  updatedData.splice(itemIdx, 1);

  updatedEmbedModal.isError = false;
  updatedEmbedModal.isShow = true;
  updatedEmbedModal.type = MODAL_TYPE.ERROR;
  updatedItems.data = updatedData;
  updatedState.embedModal = updatedEmbedModal;
  updatedState.items = updatedItems;

  return updatedState;
};

const openEmbedModal = (state, { itemId, type }) => {
  switch (type) {
    case MODAL_TYPE.ADD:
      return openAddEmbedModal(state);
    case MODAL_TYPE.EDIT:
      return openEditEmbedModal(state, itemId);
    case MODAL_TYPE.INSERT:
      return openInsertEmbedModal(state, itemId);
    case MODAL_TYPE.ERROR:
      return openErrorEmbedModal(state, itemId);
    default:
      return;
  }
};

const closeEmbedModal = (state) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };

  updatedEmbedModal.isShow = false;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};


// Embed Item
const addEmbed = (state, content) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const newItem = {
    id: uuid(),
    type: ITEM_TYPE.EMBED,
    data: { content: content, isFullWidth: true },
  };

  updatedData.push(newItem);
  updatedItems.data = updatedData;
  updatedEmbedModal.isShow = false;
  updatedState.items = updatedItems;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};

const removeEmbed = (state, itemId) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  updatedData.splice(itemIdx, 1);
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const editEmbed = (state, newContent) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex(
    (item) => item.id === updatedEmbedModal.embedId
  );
  if (itemIdx < 0) return updatedState;

  updatedData[itemIdx].data.content = newContent;
  updatedEmbedModal.isShow = false;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};

const insertEmbed = (state, newContent) => {
  const updatedState = { ...state };
  const updatedEmbedModal = { ...updatedState.embedModal };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex(
    (item) => item.id === updatedEmbedModal.embedId
  );
  if (itemIdx < 0) return updatedState;

  const newItem = {
    id: uuid(),
    type: ITEM_TYPE.EMBED,
    data: { content: newContent, isFullWidth: true },
  };

  updatedData.splice(itemIdx, 0, newItem);
  updatedEmbedModal.isShow = false;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;
  updatedState.embedModal = updatedEmbedModal;

  return updatedState;
};

export default {
  openEmbedModal,
  closeEmbedModal,
  addEmbed,
  removeEmbed,
  editEmbed,
  insertEmbed,
};
