import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../../based/Breadcrumbs";
import { INITPAGING, NOTIFY } from "../../based/Constants";
import TFUImage from "../../based/inputs/Image";
import Textbox from "../../based/inputs/Textbox";
import TFUSelect from "../../based/inputs/TFUSelect";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import PosterServices from "../../based/services/PosterServices";
import TemplateTypeServices from "../../based/services/TemplateTypeServices";
import useFormValidate from "../../hooks/useFormValidate";
import ModalConfig from "./ModalConfig";
import PosterReview from "./PosterReview";
import VariantModal from "./VariantModal";
import MainColorTypeServices from "../../based/services/MainColorServices";
import CustomizeServices from "../../based/services/CustomizeServices";

const DataModel = {
    name: "",
    templateTypeId: 0,
    templateTypeName: "",
    bottomLayer: "",
    topLayer: "",
    posterCategory: 0,
    mainColor: "",
    isDefault: true,
    width: 0,
    height: 0,
    coL_PosterArtworkConfigs: [],
    coL_PosterContentConfigs: [],
    coL_PosterAppProductVariants: [],
};
const CONFIG_TAB = {
    AVATAR: 0,
    COLLECTION: 1,
    TOPEFFECT: 2,
};
const CONFIG_STEP = {
    LIST_OPTIONS: 0,
    CONFIG_TITLE: 1,
    CONFIG_CONTENT: 2,
    CONFIG_ARTWORK: 3,
    CONFIG_PRODUCT: 4,
};
const DELETE_OPTIONS = {
    TOP_LAYER: 0, // Xóa ảnh lớp trên cùng
    BOTTOM_LAYER: 1, // Xóa ảnh lớp dưới cùng
    CONTENT: 2, //Xóa thông số content
    TITLE: 3, //Xóa thông số title
    ARWORK: 4, //Xóa thông số artwork
    VAR_PRODUCT: 5, //Xóa thông số sản phẩm phôi
};
const PosterConfig = (props) => {
    const { addRef, displayError, isValid } = useFormValidate();
    const [dataConfig, setDataConfig] = useState([DataModel]);
    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);
    const [show, setShow] = useState(false);
    const [step, setStep] = useState(0);
    const [index, setIndex] = useState(CONFIG_STEP.LIST_OPTIONS);
    const [update, setUpdate] = useState(false);
    const [tab, setTab] = useState(CONFIG_TAB.AVATAR);
    const [validAvatar, setValidAvatar] = useState(false);
    const [validTopEffect, setValidTopEffect] = useState(false);
    const [paging, setPaging] = useState({...INITPAGING, isActive: true, pageSize: 500});
    const [posterVariant, setPosterVariant] = useState([]);
    const [templateType, setTemplateType] = useState([]);
    const [fontOptions, setFontOptions] = useState([]);
    const [mainColorOptions, setMainColorOptions] = useState([]);
    const [category, setCategory] = useState([]);
    const [showVariant, setShowVariant] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const [imageReview, setImageReview] = useState("");
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteOptions, setDeleteOptions] = useState();
    const [indexDelete, setIndexDelete] = useState();
    const history = useHistory();
    var imageRef = useRef();

    useEffect(() => {
        getPosterVariant();
        getCategory();
        getTemplateType();
        getFontOptions(paging);
        getMainColor();
        return () => {
            setPosterVariant([]);
            setTemplateType([]);
            setFontOptions([]);
            setMainColorOptions([]);
            setCategory([]);
            setDataConfig([DataModel]);
            imageRef = null;
        };
    }, []);
    useEffect(() => {
        if (props.match.params.id > 0) getDataConfig(props.match.params.id);
        else setDataConfig([DataModel]);
    }, [props.match.params.id]);

    useEffect(() => {
        handleValid();
    }, [dataConfig]);

    const handleImageLoaded = () => {
        if (props.match.params.id > 0 && imageRef && imageRef.current && imageRef.current.width > 0 && imageRef.current.height > 0) {
            let data = [...dataConfig];
            let currentImageRef = imageRef.current;
            let perX = currentImageRef.width / dataConfig[0].width;
            let perY = currentImageRef.height / dataConfig[0].height;
            data.map((set) => {
                set.coL_PosterArtworkConfigs.map((item) => {
                    item.x = parseInt(item.x * perX);
                    item.y = parseInt(item.y * perY);
                });
                set.coL_PosterContentConfigs.map((item) => {
                    item.x = parseInt(item.x * perX);
                    item.y = parseInt(item.y * perY);
                });
                set.coL_PosterAppProductVariants.map((item) => {
                    item.x = parseInt(item.x * perX);
                    item.y = parseInt(item.y * perY);
                });
            });
            setDataConfig(data);
        }
    };

    const getDataConfig = async (id) => {
        setLoading(true);
        let [err, data] = await PosterServices.GetPosterByGroupId(id);
        if (!err && data && data.length > 0) {
            setDataConfig(data);
            setLoading(false);
            if (data[0].coL_PosterArtworkConfigs.length > 0 || data[0].coL_PosterContentConfigs.length > 0) setValidAvatar(true);
        } else {
            setDataConfig([DataModel]);
            setLoading(false);
        }
    };
    const handleValid = () => {
        if (dataConfig[0].coL_PosterArtworkConfigs.length > 0 || dataConfig[0].coL_PosterContentConfigs.length > 0) setValidAvatar(true);
        else setValidAvatar(false);

        if (dataConfig[0].topLayer) setValidTopEffect(true);
        else setValidTopEffect(false);
    };
    const getMainColor = async () => {
        let [err, data] = await MainColorTypeServices.GetMainColorByPage({ ...paging, pageSize: 20 });
        if (!err && data && data.listObjects) {
            let datas = [];
            data.listObjects.map((set) => {
                let modelTemp = {
                    text: set.colorDisplayName,
                    value: set.code,
                    label: set.colorDisplayName,
                };
                datas.push(modelTemp);
            });
            setMainColorOptions(datas);
            setLoading(false);
        }
    };
    const getCategory = async () => {
        let [err, data] = await PosterServices.GetCategory();
        if ((!err, data)) {
            setCategory(data);
        }
    };
    const getPosterVariant = async () => {
        let [err, data] = await PosterServices.GetListPosterVariant({ ...paging, pageSize: 100 });
        if (!err && data && data.listObjects) {
            let datas = [];
            data.listObjects.map((set) => {
                let productVariantModel = {
                    productId: set.productId,
                    name: set.name,
                    colors: [],
                    sizes: [],
                    variants: [],
                };
                if (datas == [] || !datas.find((item) => item.productId == set.productId)) {
                    datas.push(productVariantModel);
                }
            });
            datas.map((set, idx) => {
                data.listObjects.map((item) => {
                    if (item.productId == set.productId) {
                        let color = item.attributeValueLightModels.find((item) => item.code == "Color").value;
                        let size = item.attributeValueLightModels.find((item) => item.code == "Size").value;
                        let variantModel = {
                            id: item.id,
                            color: color,
                            size: size,
                            colorDisplayName: item.colorDisplayName,
                            name: item.name,
                        };
                        if (datas[idx].colors == [] || !datas[idx].colors.find((item) => item == color)) {
                            datas[idx].colors.push(color);
                        }
                        if (datas[idx].sizes == [] || !datas[idx].sizes.find((item) => item == size)) {
                            datas[idx].sizes.push(size);
                        }
                        if (datas[idx].variants == [] || !datas[idx].variants.find((item) => item.color == color && item.size == size)) {
                            datas[idx].variants.push(variantModel);
                        }
                    }
                });
            });
            setPosterVariant(datas);
        }
    };
    const getTemplateType = async () => {
        let [err, data] = await TemplateTypeServices.GetTemplateTypes(paging);
        if (!err && data && data.listObjects) {
            let datas = [];
            data.listObjects.map((set) => {
                let modelTemp = {
                    text: set.name,
                    label: set.name,
                    value: set.id,
                };
                datas.push(modelTemp);
            });
            setTemplateType(datas);
        }
    };
    const getFontOptions = async (paging) => {
        let [err, data] = await CustomizeServices.GetTextsPaging(paging);
        if (!err && data && data.listObjects) {
            let datas = [];
            data.listObjects.map((set) => {
                let modelTemp = {
                    text: set.text,
                    value: set.id,
                    label: set.text,
                };
                datas.push(modelTemp);
            });
            setFontOptions(datas);
        }
    };
    const handleClick = (e) => {
        let imageRect = imageRef.current.getBoundingClientRect();
        var x = e.clientX - imageRect.left;
        var y = e.clientY - imageRect.top;
        setX(x);
        setY(y);
        setStep(CONFIG_STEP.LIST_OPTIONS);
        setUpdate(false);
        setShow(true);
    };
    const updateTitleConfig = (idx) => {
        setIndex(idx);
        setUpdate(true);
        setShow(true);
        setStep(CONFIG_STEP.CONFIG_TITLE);
        setX(dataConfig[0].coL_PosterContentConfigs[idx].x);
        setY(dataConfig[0].coL_PosterContentConfigs[idx].y);
    };
    const updateContentConfig = (idx) => {
        setIndex(idx);
        setUpdate(true);
        setShow(true);
        setStep(CONFIG_STEP.CONFIG_CONTENT);
        setX(dataConfig[0].coL_PosterContentConfigs[idx].x);
        setY(dataConfig[0].coL_PosterContentConfigs[idx].y);
    };
    const updateArtworkConfig = (idx) => {
        setIndex(idx);
        setUpdate(true);
        setShow(true);
        setStep(CONFIG_STEP.CONFIG_ARTWORK);
        setX(dataConfig[0].coL_PosterArtworkConfigs[idx].x);
        setY(dataConfig[0].coL_PosterArtworkConfigs[idx].y);
    };
    const updateProductConfig = (idx) => {
        setIndex(idx);
        setUpdate(true);
        setShow(true);
        setStep(CONFIG_STEP.CONFIG_PRODUCT);
        setX(dataConfig[0].coL_PosterAppProductVariants[idx].x);
        setY(dataConfig[0].coL_PosterAppProductVariants[idx].y);
    };
    const deleteConfig = (idx, deleteOptions) => {
        setShowConfirmModal(true);
        setDeleteOptions(deleteOptions);
        setIndexDelete(idx);
    };
    const renderContent = (data) => {
        let datas = [];
        if (data[0].coL_PosterContentConfigs.length > 0) {
            data[0].coL_PosterContentConfigs.map((set, idx) => {
                if (set.contentType == 1) {
                    datas.push(
                        <Done key={idx} X={set.x} Y={set.y}>
                            <div className="square"></div>
                            <div className="content-container">
                                <p className="title-header" onClick={() => updateTitleConfig(idx)}>
                                    {set.content}
                                </p>
                                <img className="cancel" src="/images/Cancel_line_black.svg" onClick={() => deleteConfig(idx, DELETE_OPTIONS.CONTENT)} />
                            </div>
                        </Done>
                    );
                }
            });
        }
        if (data[0].coL_PosterContentConfigs.length > 0) {
            data[0].coL_PosterContentConfigs.map((set, idx) => {
                if (set.contentType == 2) {
                    datas.push(
                        <Done key={idx} X={set.x} Y={set.y}>
                            <div className="square"></div>
                            <div className="content-container">
                                <p className="title-header" onClick={() => updateContentConfig(idx)}>
                                    Thông số content
                                </p>
                                <img className="cancel" src="/images/Cancel_line_black.svg" onClick={() => deleteConfig(idx, DELETE_OPTIONS.CONTENT)} />
                            </div>
                        </Done>
                    );
                }
            });
        }
        if (data[0].coL_PosterArtworkConfigs.length > 0) {
            data[0].coL_PosterArtworkConfigs.map((set, idx) => {
                datas.push(
                    <Done key={idx} X={set.x} Y={set.y}>
                        <div className="square"></div>
                        <div className="content-container">
                            <p className="title-header" onClick={() => updateArtworkConfig(idx)}>
                                Thông số artwork
                            </p>
                            <img className="cancel" src="/images/Cancel_line_black.svg" onClick={() => deleteConfig(idx, DELETE_OPTIONS.ARWORK)} />
                        </div>
                    </Done>
                );
            });
        }
        if (data[0].coL_PosterAppProductVariants.length > 0) {
            data[0].coL_PosterAppProductVariants.map((set, idx) => {
                datas.push(
                    <Done key={idx} X={set.x} Y={set.y}>
                        <div className="square"></div>
                        <div className="content-container">
                            <p className="title-header" onClick={() => updateProductConfig(idx)}>
                                Thông số sản phẩm
                            </p>
                            <img className="cancel" src="/images/Cancel_line_black.svg" onClick={() => deleteConfig(idx, DELETE_OPTIONS.VAR_PRODUCT)} />
                        </div>
                    </Done>
                );
            });
        }
        return datas.map((x) => x);
    };
    const handleSetBottomImage = (value) => {
        let dataTemp = [...dataConfig];
        dataTemp[0].bottomLayer = value;
        setDataConfig(dataTemp);
    };
    const handleSetTopLayer = (value) => {
        let dataTemp = [...dataConfig];
        dataTemp[0].topLayer = value;
        setDataConfig(dataTemp);
    };
    const createOrUpdate = async () => {
        if (validAvatar && isValid()) {
            let currentImageRef = imageRef.current;
            let percentTempX = currentImageRef.naturalWidth / currentImageRef.width;
            let percentTempY = currentImageRef.naturalHeight / currentImageRef.height;
            let dataTemp = [...dataConfig];
            dataTemp.map((set) => {
                set.coL_PosterArtworkConfigs.map((item) => {
                    item.x = parseInt(item.x * percentTempX);
                    item.y = parseInt(item.y * percentTempY);
                });
                set.coL_PosterContentConfigs.map((item) => {
                    item.x = parseInt(item.x * percentTempX);
                    item.y = parseInt(item.y * percentTempY);
                });
                set.coL_PosterAppProductVariants.map((item) => {
                    item.x = parseInt(item.x * percentTempX);
                    item.y = parseInt(item.y * percentTempY);
                });
            });
            let [err, data] = await PosterServices.CreateUpdatePoster(dataTemp);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                history.push("/poster");
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng thử lại sau");
            }
        } else {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn cần config ảnh đại diện");
        }
    };
    const previewMockup = async () => {
        let dataTemp = { ...dataConfig[0] };
        dataTemp.coL_PosterArtworkConfigs.map((set) => {
            set.artworkUrl = "https://cdn.ranus.vn/images/system/layer2B.png";
        });
        let [err, data] = await PosterServices.GetPreviewMockup(dataTemp);
        if (!err && data) {
            setImageReview(data);
            setShowReview(true);
        } else {
            Notify(NOTIFY.ERROR, "Có Lỗi", "Vui lòng thử lại sau");
        }
    };
    const handleDeleteBottomImage = () => {
        setShowConfirmModal(true);
        setDeleteOptions(DELETE_OPTIONS.BOTTOM_LAYER);
    };
    const handleDeleteTopImage = () => {
        setShowConfirmModal(true);
        setDeleteOptions(DELETE_OPTIONS.TOP_LAYER);
    };

    const renderRightContent = (tab) => {
        return (
            <React.Fragment>
                <div className={`right-section ${tab == CONFIG_TAB.AVATAR ? "" : "d-none"}`}>
                    <p>Tạo thông số cho ảnh đại diện</p>
                    <div className="image-container" style={{ background: `${dataConfig[0].bottomLayer ? "none" : "#f7f7f7"}` }}>
                        <div className="section-container">
                            {dataConfig[0].bottomLayer && (
                                <React.Fragment>
                                    <img id="imgBottomLayer" src={dataConfig[0].bottomLayer} onClick={(e) => handleClick(e)} ref={imageRef} onLoad={(e) => handleImageLoaded()} />
                                    <img src="/images/Cancel_line_black.svg" className="cancel-img" onClick={() => handleDeleteBottomImage()} />
                                </React.Fragment>
                            )}
                            <ModalConfig
                                X={X}
                                Y={Y}
                                show={show}
                                step={step}
                                posterVariant={posterVariant}
                                fontOptions={fontOptions}
                                setStep={setStep}
                                onClose={() => setShow(false)}
                                data={dataConfig}
                                index={index}
                                update={update}
                                setData={setDataConfig}
                            />
                        </div>
                        {dataConfig && renderContent(dataConfig)}
                        {dataConfig[0].bottomLayer ? (
                            ""
                        ) : (
                            <TFUImage maximumSize={10} isHidePreview={true} image={dataConfig[0].bottomLayer} ref={addRef} imageType="avatar" onChanged={(name, value) => handleSetBottomImage(value)} name="image" id="image" />
                        )}
                    </div>
                </div>
                <div className={`right-section ${tab == CONFIG_TAB.TOPEFFECT ? "" : "d-none"}`}>
                    <p>Lớp hiệu ứng trên cùng</p>
                    <div className="image-container" style={{ background: `${dataConfig[0].topLayer ? "none" : "#f7f7f7"}` }}>
                        <div className="section-container">
                            {dataConfig[0].topLayer && (
                                <React.Fragment>
                                    <img src={dataConfig[0].topLayer} />
                                    <img src="/images/Cancel_line_black.svg" className="cancel-img" onClick={() => handleDeleteTopImage()} />
                                </React.Fragment>
                            )}
                        </div>
                        {dataConfig[0].topLayer ? (
                            ""
                        ) : (
                            <TFUImage maximumSize={10} isHidePreview={true} image={dataConfig[0].topLayer} ref={addRef} imageType="topEffect" onChanged={(name, value) => handleSetTopLayer(value)} name="image" id={"image"} />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const _renderTable = () => {
        return (
            <table className="table">
                <thead>
                    <tr className="tr-head">
                        <th width="25%" className="text-center">
                            Biến thể
                        </th>
                        <th width="45%" className="text-center">
                            Màu
                        </th>
                        <th width="30%" className="text-center">
                            Tông màu
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataConfig.length > 1 ? (
                        dataConfig.slice(1).map((set, idx) => {
                            if (posterVariant.length > 0) {
                                return (
                                    <tr className="tr-body" key={idx}>
                                        <td className="vertical-align-middle text-center">{`${idx + 1}`}</td>
                                        <td className="vertical-align-middle text-center">
                                            <div className="d-flex">
                                                {set.coL_PosterArtworkConfigs.map((item, index) => {
                                                    return <div key={index} className="color" style={{ background: `${posterVariant.find((x) => x.productId == item.productId).variants.find((x) => x.id == item.appProductVariantId).color}` }}></div>;
                                                })}
                                            </div>
                                        </td>
                                        <td className="vertical-align-middle text-center">
                                            <div key={idx} className="color" style={{ background: `${set.mainColor}` }}></div>
                                        </td>
                                    </tr>
                                );
                            }
                        })
                    ) : (
                        <tr>
                            <td colSpan={10} className="vertical-align-middle text-center">
                                Không có dữ liệu
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    };

    const handleSetName = (value) => {
        let dataTemp = [...dataConfig];
        for (let i = 0; i < dataTemp.length; i++) {
            dataTemp[i].name = value;
        }
        setDataConfig(dataTemp);
    };
    const handleSetTemplateType = (value) => {
        let dataTemp = [...dataConfig];
        let text = templateType.find((item) => item.value == value).text;
        for (let i = 0; i < dataTemp.length; i++) {
            dataTemp[i].templateTypeName = text;
            dataTemp[i].templateTypeId = value;
        }
        setDataConfig(dataTemp);
    };
    const handleSetCategory = (value) => {
        let dataTemp = [...dataConfig];
        for (let i = 0; i < dataTemp.length; i++) {
            dataTemp[i].posterCategory = parseInt(value);
        }
        setDataConfig(dataTemp);
    };
    const handleSetMainColor = (value) => {
        let dataTemp = [...dataConfig];
        for (let i = 0; i < dataTemp.length; i++) {
            dataTemp[i].mainColor = value;
        }
        setDataConfig(dataTemp);
    };
    const handleShowVariant = () => {
        if (validAvatar && isValid()) {
            setShowVariant(true);
        } else {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn cần config ảnh đại diện");
        }
    };
    const handleDelete = () => {
        switch (deleteOptions) {
            case DELETE_OPTIONS.BOTTOM_LAYER: {
                let dataTemp = [...dataConfig];
                dataTemp.map((set) => {
                    set.bottomLayer = "";
                });
                setDataConfig(dataTemp);
                setShowConfirmModal(false);
                break;
            }
            case DELETE_OPTIONS.TOP_LAYER: {
                let dataTemp = [...dataConfig];
                dataTemp.map((set) => {
                    set.topLayer = "";
                });
                setDataConfig(dataTemp);
                setShowConfirmModal(false);
                break;
            }
            case DELETE_OPTIONS.VAR_PRODUCT: {
                let datas = [...dataConfig];
                datas.map((set) => {
                    set.coL_PosterAppProductVariants.splice(indexDelete, 1);
                });
                setDataConfig(datas);
                setShowConfirmModal(false);
                break;
            }
            case DELETE_OPTIONS.ARWORK: {
                let datas = [...dataConfig];
                datas.map((set) => {
                    set.coL_PosterArtworkConfigs.splice(indexDelete, 1);
                });
                setDataConfig(datas);
                setShowConfirmModal(false);
                break;
            }
            case DELETE_OPTIONS.CONTENT: {
                let datas = [...dataConfig];
                datas.map((set) => {
                    set.coL_PosterContentConfigs.splice(indexDelete, 1);
                });
                setDataConfig(datas);
                setShowConfirmModal(false);
                break;
            }
        }
    };
    return (
        <Wrapper>
            <PosterReview show={showReview} setShow={setShowReview} img={imageReview} />
            <ConfirmModal showConfirmModal={showConfirmModal}>
                <div className="confirm-dialog-container">
                    <div className="confirm-header">
                        <img src="./images/Cancel_line_black.svg" className="cancelImg" onClick={() => setShowConfirmModal(false)} />
                    </div>
                    <div className="confirm-body">
                        <h4>Bạn xác nhận muốn xóa?</h4>
                        <div className="btn-confirm-container">
                            <span className="mr-3" onClick={() => handleDelete()}>
                                Xác nhận
                            </span>
                            <span onClick={() => setShowConfirmModal(false)}>Hủy</span>
                        </div>
                    </div>
                </div>
            </ConfirmModal>
            <Breadcrumbs currentPage="Tạo poster mới" />
            <Loading show={loading} msg="Đang tải dữ liệu" />
            <div className="dialog-container">
                <div className="left-container">
                    <div className="left-section">
                        <p>
                            Tên Template<span style={{ color: "red" }}>*</span>
                        </p>
                        <Textbox className="text-box" id="templateName" name="templateName" required ref={addRef} onChanged={(name, value) => handleSetName(value)} value={dataConfig[0].name} />
                        <p>
                            Phong cách Template<span style={{ color: "red" }}>*</span>
                        </p>
                        <TFUSelect options={templateType} ref={addRef} required name="templateType" id="templateType" value={dataConfig[0].templateTypeId} onChanged={(value) => handleSetTemplateType(value)} />
                        <div className={tab == CONFIG_TAB.AVATAR ? "btn-container active" : "btn-container"}>
                            <p>
                                Tạo thông số cho ảnh đại diện<span style={{ color: "red" }}>*</span>
                            </p>
                            <div className={validAvatar ? "item active" : "item"} onClick={() => setTab(CONFIG_TAB.AVATAR)}>
                                {validAvatar ? "Đã tải lên" : "Tải ảnh lên"}
                            </div>
                        </div>
                        <div className={tab == CONFIG_TAB.TOPEFFECT ? "btn-container active" : "btn-container"}>
                            <p>
                                Lớp hiệu ứng trên cùng<span style={{ color: "red" }}>*</span>
                            </p>
                            <div className={validTopEffect ? "item active" : "item"} onClick={() => setTab(CONFIG_TAB.TOPEFFECT)}>
                                {validTopEffect ? "Đã tải lên" : "Tải ảnh lên"}
                            </div>
                        </div>
                        <div className="tones-category">
                            <div className="category">
                                <p>
                                    Hạng mục<span style={{ color: "red" }}>*</span>
                                </p>
                                <TFUSelect options={category} ref={addRef} required name="category" id="category" value={dataConfig[0].posterCategory} onChanged={(value) => handleSetCategory(value)} />
                            </div>
                            <div className="tones">
                                <p>
                                    Tông màu<span style={{ color: "red" }}>*</span>
                                </p>
                                <div className="text-color-container">
                                    <TFUSelect className="select" options={mainColorOptions} ref={addRef} required name="mainColor" id="mainColor" value={dataConfig[0].mainColor} onChanged={(value) => handleSetMainColor(value)} />
                                    <div className="color-picker" style={{ background: `${dataConfig[0].mainColor}` }}></div>
                                </div>
                            </div>
                        </div>
                        <p className="mt-3">
                            Tạo biến thể<span style={{ color: "red" }}>*</span>
                            <span className="ml-2" style={{ color: "#00c853" }}>{`Đã tạo ${dataConfig.length - 1} biến thể`}</span>
                        </p>
                        {_renderTable()}
                        <span
                            style={{ color: "#00c853" }}
                            onClick={() => {
                                handleShowVariant();
                            }}
                            className="cursor-pointer"
                        >
                            Thêm biến thể +
                        </span>
                        <VariantModal datas={dataConfig} mainColorOptions={mainColorOptions} setDatas={setDataConfig} showVariant={showVariant} setShowVariant={setShowVariant} />
                        <div className="d-flex justify-content-center">
                            <div className="btn-save mr-3" onClick={() => previewMockup()}>
                                Review
                            </div>
                            <div className="btn-save ml-3" onClick={() => createOrUpdate()}>
                                Hoàn thành
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-container">{renderRightContent(tab)}</div>
            </div>
        </Wrapper>
    );
};

export default PosterConfig;

const Wrapper = styled.div`
    .dialog-container {
        width: 100%;
        height: calc(100vh - 100px);
        display: flex;
        overflow: hidden;
        .left-container {
            flex: 1;
            padding: 10px;
            height: 100%;
            overflow: scroll;
            .left-section {
                border-radius: 10px;
                padding: 30px;
                width: 100%;
                height: 100%;
                background: white;
                .table {
                    width: 100%;
                    border: 1px solid #dee2e6;
                    max-height: 200px;
                    background-color: transparent;
                    border-collapse: collapse;
                    .tr-head {
                        background: #e4e4e4;
                        color: black;
                    }
                    .tr-body {
                        .color {
                            height: 20px;
                            width: 20px;
                            border: 1px solid;
                            margin: auto;
                        }
                    }
                }
                p {
                    padding: 0;
                    color: black;
                    margin: 0;
                }
                .tones-category {
                    display: flex;
                    justify-content: space-between;
                    .tones {
                        flex-basis: 45%;
                        .text-color-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .select {
                                width: 80%;
                            }
                            .color-picker {
                                height: 20px;
                                width: 20px;
                                border: 1px solid black;
                            }
                        }
                    }
                    .category {
                        flex-basis: 45%;
                    }
                }
                .btn-container {
                    background: white;
                    margin-top: 20px;
                    height: 80px;
                    .item {
                        margin: 0px 20px;
                        border: 1px solid black;
                        height: 50px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .active {
                        border: 1px solid #00c853;
                        color: #00c853;
                    }
                }
                .active {
                    background: #f7f7f7;
                }
                .text-box {
                    width: 100%;
                    border-radius: 0px;
                    margin-bottom: 20px;
                    border: 1px solid hsl(0, 0%, 80%);
                }

                .btn-save {
                    @media (max-width: 1400px) {
                        padding: 5px 5px;
                    }
                    margin-top: 20px;
                    padding: 5px 20px;
                    color: white;
                    cursor: pointer;
                    background: #00c853;
                }
            }
        }
        .right-container {
            flex: 3;
            padding: 10px;
            height: 100%;
            overflow: hidden;
            .right-section {
                border-radius: 10px;
                padding: 30px;
                width: 100%;
                height: 100%;
                background: white;
                overflow-y: auto;
                .image-container {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .section-container {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        img {
                            width: auto;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            border: 1px solid black;
                        }
                        .cancel-img {
                            position: absolute;
                            height: 30px;
                            width: 30px;
                            right: 10px;
                            top: 10px;
                            cursor: pointer;
                            box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
                        }
                    }
                    .previewComponent {
                        z-index: 1;
                    }
                }
            }
        }
    }
`;
const Done = styled.div`
    .square {
        position: absolute;
        left: ${({ X }) => (X ? X + "px" : "0")};
        top: ${({ Y }) => (Y ? Y + "px" : "0")};
        box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
        height: 40px;
        width: 40px;
        border: 1px solid white;
        transform: translate(-20px, -20px);
    }
    .content-container {
        position: absolute;
        left: ${({ X }) => (X && X - 120 > 0 ? X - 120 + "px" : "0")};
        top: ${({ Y }) => (Y ? Y + 50 + "px" : "0")};
        box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
        width: 280px;
        border: 1px solid white;
        background: white;
        z-index: 1050;
        padding: 10px;
        .title-header {
            font-weight: 700;
            color: black;
            margin-left: 20px;
            cursor: pointer;
        }
        .cancel {
            height: 15px !important;
            width: 15px !important;
            border: none !important;
            position: absolute;
            right: 10px;
            top: 14px !important;
            cursor: pointer;
        }
    }
`;
const ConfirmModal = styled.div`
    position: fixed;
    display: ${({ showConfirmModal }) => (showConfirmModal ? "flex" : "none")};
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    z-index: 1500;
    .confirm-dialog-container {
        width: 400px;
        height: 400px;
        .confirm-header {
            height: 50px;
            width: 100%;
            background: #00c853;
            color: white;
            position: relative;
            .cancelImg {
                height: 20px;
                width: 20px;
                right: 15px;
                top: 15px;
                cursor: pointer;
                position: absolute;
            }
        }
        .confirm-body {
            height: 100px;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            background: white;
            h4 {
                margin-top: 15px;
            }
            .btn-confirm-container {
                position: absolute;
                bottom: 15px;
                right: 15px;
                span {
                    color: white;
                    background: #00c853;
                    padding: 5px 20px;
                    cursor: pointer;
                }
            }
        }
    }
`;
