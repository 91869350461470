import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components"
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import { Modal } from "react-bootstrap";
import Loading from "../based/Loading";
import Request from "../based/Request";
import BaseServices from "../based/services/BaseServices";


const INIT_DATA = {
    images: [],
    note: "",
    id: 0,
}
const PetErrorModal = ({ onClose, operatorOrderId }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [data, setData] = useState({...INIT_DATA})
   

    useEffect(()=>{
        getProducts()
    },[operatorOrderId])


    const getProducts = async () => {
        setIsBusy(true)
        const [err, data] = await BaseServices.Get(`/api/PetPressed/get-defective-product/${operatorOrderId}`);
        if (!err && data) {
            setData(data)
        } else {
            setData({...INIT_DATA})
        }
        setIsBusy(false)
    }


    const handleSave = async () => {
        if(data.note){
            setIsBusy(true)
            const [err, dt] = await BaseServices.Post(`/api/PetPressed/create-update-defective-product`,{
                id: data.id,
                operatorOrderId: operatorOrderId,
                note: data.note
            });
            if (!err && dt) {
                Notify(NOTIFY.SUCCESS , NOTIFY.SUCCESS,"Thành công");
                onClose()
            } else {
                Notify(NOTIFY.ERROR , NOTIFY.ERROR,"Thất bại");
            }
            setIsBusy(false)
        }
        else{
            Notify(NOTIFY.WARNING , NOTIFY.WARNING,"Nhập chi tiết lỗi");
        }
    }


    const handleUploadImage = async (url) => {
        setIsBusy(true)
        const [err, data] = await BaseServices.Post(`/api/PetPressed/create-image-defective`,{
            id: 0,
            operatorOrderId: operatorOrderId,
            imageUrl: url
        });
        if (!err && data) {
            getProducts()
            Notify(NOTIFY.SUCCESS , NOTIFY.SUCCESS,"Thành công");
        } else {
            Notify(NOTIFY.ERROR , NOTIFY.ERROR,"Thất bại");
        }
        setIsBusy(false)
    }

    const handleDeleteImage = async (id) => {
        setIsBusy(true)
        const [err, data] = await BaseServices.Get(`/api/PetPressed/delete-image-defective/${id}`);
        if (!err && data) {
            getProducts()
            Notify(NOTIFY.SUCCESS , NOTIFY.SUCCESS,"Thành công");
        } else {
            Notify(NOTIFY.ERROR , NOTIFY.ERROR,"Thất bại");
        }
        setIsBusy(false)
    }

    async function handleUploadFile(e) {
        if(data.images.length < 5){
            let reader = new FileReader();
            let file = e.target.files[0];
            if (file) {
                reader.onloadend = () => {
                    Request.UploadImage(file)
                        .then((res) => {
                            if (res && res.data) {
                                handleUploadImage(res.data.imageUrl)


                            } else {
                                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                            }
                        })
                        .catch((err) => {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        });
                };

                reader.readAsDataURL(file);
            }
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Tối đa 5 ảnh");

        }
        
    }


    return (
        <Modal show={true} onHide={onClose} size={'md'} id={Common.generateGuid()} enforceFocus={false}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Chi tiết lỗi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StylePetErrorModal>
                    <h4>Điền chi tiết lỗi</h4>
                    <textarea value={data.note} onChange={(e)=>{setData({...data, note: e.target.value})}}>   
                    </textarea>
                    <div>
                        <span>Hình chụp ({data.images.length}/5)</span>
                        <input
                            style={{maxWidth: 90}}
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                                handleUploadFile(e);
                            }}
                        />
                    </div>
                    <div className="box-image">
                        {data.images.length > 0 && data.images.map((itm , idx) => {
                            return <div className="image-item" key={idx}>
                                <img src={itm.imageUrl} />
                                <div className="remove-image" 
                                    onClick={()=>{
                                        handleDeleteImage(itm.id)
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13 1L1 13" stroke="#D70000" strokeWidth="1.5"/>
                                        <path d="M13 13L1 0.999999" stroke="#D70000" strokeWidth="1.5"/>
                                    </svg>
                                </div>
                            </div>
                        })}
                    </div>
                </StylePetErrorModal>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <div className="box-button" style={{ display: "flex", justifyContent: 'center' }}>
                        <button className="btn-danger btn" 
                            onClick={()=>{
                                handleSave()
                            }}
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default PetErrorModal;
const StylePetErrorModal = styled.div`
    h4{
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; 
    }
    textarea {
        width: 100%;
        height: 200px;
        border: 1px solid #D9D9D9;
    }

    .box-image{
        display: flex;
        flex-wrap: wrap;

        .image-item{
            position: relative;
            margin-right: 10px;
            width: 100px;
            height: 100px;

            img{
                width: 100px;
                height: 100px;
                object-fit: contain;
            }

            .remove-image{
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
            }
        }
    }
`

PetErrorModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
