import BaseServices from "./BaseServices";

const ManyShareServices = {
    CreateUpdateBannerManage: async (model) => {
        return await BaseServices.Post(`/api/ManyShare/create-update-banner-share`, model);
    },
    DeleteBannerManage: async (id) => {
        return await BaseServices.Get(`/api/ManyShare/delete-banner-share/${id}`);
    },
    UpdateBannerUrl: async (model) => {
        return await BaseServices.Post(`/api/ManyShare/update-banner-url`, model);
    },

    DeleteManyShare: async (id) => {
        return await BaseServices.Get(`/api/ManyShare/delete-many-share/${id}`);
    },
    GetBannerManageByPosition: async (type) => {
        return await BaseServices.Get(`/api/ManyShare/get-banner-share-by-position/${type}`);
    },
    
    GetManyShareByPage: async (paging) => {
        return await BaseServices.Post(`/api/ManyShare/get-many-share-by-page`, paging);
    },
    
};

export default ManyShareServices;

