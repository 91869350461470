import Request from "../Request";
import BaseServices from "./BaseServices";

const AttributeServices = {
    GetPageAttributes: async (paging) => {
        try {
            const res = await Request.Post('/api/attribute/get-page', paging);
            if (res.success)
                return [null, res.data];
            return [res, null]
        } catch (error) {
            return [error, null]
        }
    },

    GetDropdownAttributes: async (categoryId = 0) => {
        try {
            const res = await Request.Get('/api/attribute/get-dropdown?categoryId=' + categoryId);
            if (res.success)
                return [null, res.data];
            return [res, null]
        } catch (error) {
            return [error, null]
        }
    },
    GetAttributesOPF: async (requestModel) => {
        try {
            const res = await Request.Post('/api/agencyProducts/sync-category-attributes', requestModel);
            if (res.success)
                return [null, res.data];
            return [res, null]
        } catch (error) {
            return [error, null]
        }
    },
    GetAttributesValue: async (id) => {
        return await BaseServices.Get(`/api/attribute/get-attributes-value/${id}`)
    },
    AddUpdateListAttributeValueExtend: async (list) => {
        return await BaseServices.Post(`/api/attribute/add-or-update-attribute-extend`, list)
    },
    DeleteListAttributeValueExtend: async (ids) => {
        return await BaseServices.Post(`/api/attribute/delete`, ids)
    },
    GetAttributeValueExtends: async (variantId) => {
        return await BaseServices.Get(`/api/attribute/get-attribute-value/${variantId}`)
    },
    GetOpfAttributesByLotusCategory: async (categoryId) => {
        return await BaseServices.Get(`/api/attribute/get-opfattributes-by-lotuscategory?categoryId=${categoryId}`)
    },
    GetAttributesById: async (categoryId) => {
        return await BaseServices.Get(`/api/attribute/get-byid/${categoryId}`)
    }
}

export default AttributeServices;