import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY, StockFolderType } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import StockFolderServices from "../based/services/StockFolderServices";
import AddEditModal from "./AddEditModal";
import MoveModal from "./MoveModal";
import SelectFontModal from "./SelectFontModal";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import DragAndDropIndex from "../based/DragAndDropIndex";
const initPage = {
    pageSize: 10,
    pageNumber: 1,
    folderType: StockFolderType.Font,
    orderBy: "id",
    orderDirection: "asc",
};

class StockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.parentFolderId = 0;
        this.thumbnail = "";
        this.countStock = 0;
        this.child = [];
        this.isDown = false;
        this.isChecked = false;
        this.folderType = StockFolderType.Font;
    }
}

export default function StockFont(props) {
    const [isBusy, setBusy] = useState(true);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);
    const [datas, setDatas] = useState([new StockFolderModel()]);
    const [paging, setPaging] = useState(initPage);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMessage] = useState("");
    const [currentParentId, setCurrentParentId] = useState(0);
    const [activeId, setActiveId] = useState({ id: 0, parentId: 0 });
    const [stockFonts, setStockFonts] = useState([]);
    const [showSelectModal, setShowSelectModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [pagingStockImage, setPagingStockImage] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
    useEffect(() => {
        setBusy(false);
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await StockFolderServices.GetListStockFolderPagings(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(newPaging);
        }
        setBusy(false);
        setMessage(null);
    }

    async function handleRemove(id, parentId) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await StockFolderServices.DeleteStockFolderById(id);
        if (!err && data) {
            getDatas({ ...paging });
            setStockFonts([]);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
            setActiveId({ id: 0, parentId: 0 });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
        setBusy(false);
        setMessage(null);
    }

    async function addStockImage(payload) {
        let [err, data] = await StockFolderServices.AddStockImageConnection(payload);
        if (!err && data) {
            setShowSelectModal(false);
            getStockFont({ ...pagingStockImage, folderId: activeId.id });
        }
    }

    async function getStockFont(paging) {
        let [err, data] = await StockFolderServices.GetListStockFontPagings(paging);
        if (!err && data) {
            setStockFonts(data.listObjects);
        } else setStockFonts([]);
    }

    async function removeStockImage(payload) {
        let [err, data] = await StockFolderServices.RemoveStockImageConnection(payload);
        if (!err && data) {
            getStockFont({ ...pagingStockImage, folderId: activeId.id });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    }

    async function updateIndexStockFolder(id, index){
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.UpdateIndexStockFolder({
            id: id,
            parentFolderId: 0,
            folderType: StockFolderType.Font,
            index: index
        });
        if (!err && data) {        
            setBusy(false);
            getDatas(paging);
        } else {
            setBusy(false);
        }
    }
   
    function renderRow(item, i){
        return (
            <React.Fragment>
                <td>
                <CheckBox
                    checked={item.id == activeId.id}
                    name={item.id}
                    onCheckedChange={(name, value) => {
                        getStockFont({ ...INITPAGING, pageNumber: 1, pageSize: 200, folderId: item.id });
                        if (item.id == activeId.id) setActiveId({ id: 0, parentId: 0 });
                        else setActiveId({ id: item.id, parentId: 0 });
                    }}
                />
            </td>
            <td className="d-flex align-items-center">
                <span
                    className="d-flex align-items-center cursor-pointer"
                    onClick={(e) => {
                        if (item.id == activeId.id) setActiveId({ id: 0, parentId: 0 });
                        else setActiveId({ id: item.id, parentId: 0 });
                    }}
                >
                    {item.name}
                </span>
            </td>
            <td>{item.countStock}</td>
        </React.Fragment>
        )
    }

    

    function _renderBody() {
        return (
            <DragAndDropIndex data={datas} renderRow={renderRow} updateIndexFolder={updateIndexStockFolder} />
        );
    }

    
    function _renderAction() {
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group">
                    <button
                        className="btn btn-custom btn-sm m-1"
                        onClick={(e) => {
                            setCurrentParentId(0);
                            setShowModal(true);
                            setActiveId({ id: 0, parentId: 0 });
                        }}
                    >
                        Thêm folder cha
                    </button>
                    <button
                        className={`btn btn-custom btn-sm m-1 ${activeId && activeId.id > 0 ? "" : "disabled"}`}
                        onClick={(e) => {
                            setCurrentParentId(0);
                            setShowModal(true);
                        }}
                    >
                        Sửa tên folder
                    </button>
                    <button
                        className="btn btn-custom btn-sm m-1"
                        onClick={(e) => {
                            setShowMoveModal(true);
                        }}
                    >
                        Di chuyển folder
                    </button>
                    <button
                        className={`btn btn-danger btn-sm m-1 ${activeId && activeId.id > 0 ? "" : "disabled"} `}
                        onClick={(e) => {
                            let item = activeId;
                            handleRemove(item.id, item.parentId);
                        }}
                    >
                        Xóa
                    </button>
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox name="select_all" label=" " onCheckedChange={(name, value) => {}} />
                    </th>
                    <th className="cursor-pointer">Tên Folder</th>
                    <th className="cursor-pointer" width="150">
                        Số font chữ
                    </th>
                </tr>
            </thead>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <Wrapper>
                <div style={{ display: "flex" }}>
                    <div style={{ flexBasis: "40%" }}>
                        <CommonTable
                            data={datas}
                            paging={paging}
                            ref={tableRef}
                            tableGuid={tableGuid}
                            searchBasic
                            hideSearchBasicButton
                            hideSearch
                            renderAction={_renderAction}
                            renderHeader={_renderHeader}
                            renderBody={_renderBody}
                            onFilter={getDatas}
                        />
                    </div>
                    <div className="stock-box">
                        <div className="box-header">
                            <div className="header-left">
                                <div className="form-group">
                                    <label>Font chữ</label>
                                </div>
                            </div>
                            <div className="header-right">
                                <button className={`btn btn-custom btn-sm m-1 cursor-pointer ${activeId && activeId.id > 0 ? "" : "disabled"}`} onClick={(e) => setShowSelectModal(true)}>
                                    Thêm Font chữ
                                </button>
                            </div>
                        </div>
                        <div className="body-list">
                            {stockFonts &&
                                stockFonts.map((item, idx) => {
                                    return (
                                        <div className="body-item" key={idx}>
                                            <i
                                                className="fa fa-times remove-icon cursor-pointer"
                                                onClick={(e) => {
                                                    removeStockImage({ folderId: activeId.id, objectId: item.id, objectType: 2 });
                                                }}
                                            />
                                            <span
                                                className="body-item-text"
                                                style={{
                                                    fontFamily: item.fontFamily,
                                                }}
                                            >
                                                {item.fontFamily}
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </Wrapper>
            {showModal && (
                <AddEditModal
                    isShowModal={showModal}
                    onClose={(e) => setShowModal(false)}
                    onSave={() => {
                        setShowModal(false);
                        if (currentParentId == 0) getDatas({ ...paging });
                    }}
                    currentParentId={currentParentId}
                    currentId={activeId.id}
                />
            )}
            {showSelectModal && (
                <SelectFontModal
                    isShowModal={showSelectModal}
                    onClose={(e) => setShowSelectModal(false)}
                    onSave={(ids) => {
                        if (ids && ids.length > 0) {
                            let folderId = activeId.id;
                            let payload = ids.map((item) => ({
                                folderId: folderId,
                                objectId: item,
                                objectType: 2,
                            }));
                            addStockImage(payload);
                        }
                    }}
                />
            )}
            {showMoveModal && (
                <MoveModal
                    isShowModal={showMoveModal}
                    onClose={() => setShowMoveModal(false)}
                    currentId={activeId.id}
                    onSave={() => {
                        getDatas({ ...paging });
                        setShowMoveModal(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}

const Wrapper = styled.div`
    .body-item-text {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
    }
    td.add-more {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #64c5b1;
    }
    .square {
        height: 20px;
        width: 20px;
        object-fit: contain;
    }

    //stock-box
    .stock-box {
        flex: 1;
        background: #ffffff;
        margin-left: 10px;
    }

    .box-header {
        background: #d9d9d9;
        padding: 10px 10px 0px 10px;
        display: flex;
    }

    .header-left {
        display: flex;
    }

    .header-right {
        margin-left: auto;
    }

    .header-left .form-group {
        display: flex;
        align-items: baseline;
    }

    .header-left .form-group .btn-search {
        margin-left: 10px;
    }

    /* body */
    .body-list {
        display: flex;
        flex-wrap: wrap;
    }

    .body-item {
        height: 100px;
        width: 100px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        margin: 10px;
        padding: 5px;
        position: relative;
    }

    .body-item img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    i.fa.fa-times.remove-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;
