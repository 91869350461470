import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Image from "../based/inputs/Image";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import VoucherServices from "../based/services/VoucherServices";
import TFUTooltip from "../based/TFUTooltip";
import useFormValidate from "../hooks/useFormValidate";
import { App_TargetGroupIndexModel, App_TargetGroupModel, App_TargetGroupVoucherModel } from "../models/TargetGroupModel";

export default function AddOrEdit(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const { addRef, displayError, isValid } = useFormValidate();
    const [codeValid, setCodeValid] = useState({ status: 0, message: "err" });
    const [group, setGroup] = useState(new App_TargetGroupModel());
    const [showResult, setShowResult] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [isFetching, setFetching] = useState(false);
    const [vouchers, setVouchers] = useState([]);

    //target group Index
    const [fellowVouchers, setFellowVouchers] = useState([]);
    const [showResultIndex, setShowResultIndex] = useState(false);

    useEffect(() => {
        let group = props.voucher;
        if (group) {
            setGroup(props.voucher);

            //lấy voucher vào danh sách fellow voucher
            let targetGroup = group.app_TargetGroups;
            let vouchers =
                targetGroup && targetGroup.length > 0
                    ? targetGroup.reduce((prev, item, idx) => {
                          return [...prev].some((x) => x.id == item.voucherId)
                              ? [...prev]
                              : [
                                    ...prev,
                                    {
                                        id: item.voucherId,
                                        name: item.name,
                                        description: item.description,
                                        fromDate: item.fromDate,
                                        toDate: item.toDate,
                                        validCollectTime: item.validCollectTime,
                                        quantity: item.quantityVoucher,
                                        salePrice: item.salePrice,
                                        isPrintedProduct: item.isPrintedProduct,
                                    },
                                ];
                      }, [])
                    : [];
            setFellowVouchers(vouchers);
        }

        setCodeValid({ status: 1, message: "" });
    }, [props.isShowModal]);

    //save và update tất cả thông tin
    async function _handleSave(isContinue) {
        if (isValid() && codeValid.status === 1) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            console.log("group", group);
            let [err, data] = await VoucherServices.CreateUpdateTargetGroup(group);
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
                if (isContinue) setGroup(props.voucher);
                else props.onClose();
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        }
    }

    //handle target group voucher
    function _handleClickOutside() {
        setTimeout(function () {
            setShowResult(false);
        }, 200);
    }

    function _handleFocusInputSearch() {
        if (vouchers && vouchers.length > 0) setShowResult(true);
        else setShowResult(false);
    }

    const debounce = useCallback(
        _.debounce((_searchVal) => {
            _fetchingSearch(_searchVal);
        }, 1000),
        []
    );

    function _handleChangeInputSearch(e) {
        var text = e.target.value;
        setFetching(true);
        setShowResult(true);
        debounce(text);
    }

    async function _fetchingSearch(text) {
        if (text && text.length > 0) {
            setFetching(true);
            let paging = { ...INITPAGING };
            paging.pageSize = 20;
            paging.keyword = text;
            const [err, data] = await VoucherServices.GetVoucherSuggestDirectByPaging(paging);
            if (!err && data) {
                setVouchers(data.listObjects);
                setFetching(false);
                setShowResult(true);
            } else {
                setShowResult(true);
                setFetching(false);
                setVouchers([]);
            }
        } else {
            setVouchers([]);
            setShowResult(false);
        }
    }

    async function _handleSelectItem(item) {
        if (item && item.id > 0) {
            //bổ sung vào group
            let element = new App_TargetGroupVoucherModel();
            element.voucherId = item.id;
            element.name = item.name;
            element.fromDate = item.fromDate;
            element.toDate = item.toDate;
            element.validCollectTime = item.validCollectTime;
            element.quantityVoucher = item.quantity;
            element.salePrice = item.salePrice;
            element.isPrintedProduct = item.isPrintedProduct;

            let grou = { ...group };
            let targetGroup = grou.app_TargetGroups;
            targetGroup.push(element);
            setGroup(grou);

            //bổ sung vào danh sách voucher của index
            let fVoucher = [...fellowVouchers];
            let some = fVoucher.some((x) => x.id == item.id);
            if (!some) {
                fVoucher.push(item);
                setFellowVouchers(fVoucher);
            }
        }
    }

    async function _handleRemoveTargetVoucher(i) {
        if (i) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    let grou = { ...group };
                    let targetGroup = grou.app_TargetGroups;
                    targetGroup.splice(i, 1);
                    setGroup(grou);

                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn sản phẩm trước khi xóa!");
    }

    async function _handleTargetGroupVoucher(name, value, index) {
        let grou = { ...group };
        let targetGroup = grou.app_TargetGroups;
        let item = targetGroup[index];
        item[name] = value;
        setGroup(grou);
    }

    function _renderSearchAutoComplete() {
        return (
            <div id='search-autocomplete' className='p-1 bg-light rounded rounded-pill shadow-sm mb-4' onBlur={_handleClickOutside}>
                <div className='input-group'>
                    <div className='input-group-prepend'>
                        <button type='submit' className='btn btn-link text-warning'>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                    <input
                        type='search'
                        placeholder='Nhập tên sản phẩm...'
                        className='form-control border-0 bg-light'
                        onKeyUp={(e) => _handleChangeInputSearch(e)}
                        onChange={(e) => setTextSearch(e.target.value)}
                        onFocus={() => _handleFocusInputSearch()}
                        value={textSearch}
                        autoComplete='off'
                    />
                </div>
                {showResult && _renderResult()}
            </div>
        );
    }

    function _renderResult() {
        return (
            <div id='search-result'>
                <ul className='suggest_search'>
                    {vouchers && vouchers.length > 0 ? (
                        vouchers.map((item, index) => {
                            return (
                                <div className='product-item' key={index} onClick={() => _handleSelectItem(item)}>
                                    <li className='product_suggest'>
                                        <div className='item-info'>
                                            <h3>{item.name} </h3>
                                            <p className='item-txt-sku'>Mô tả: {item.description}</p>
                                            <p className='item-txt-sku'>Thời gian hiệu lực voucher: {item.validCollectTime}</p>
                                            <p className='item-txt-sku'>Số lượng Voucher Pool: {item.quantity}</p>
                                            <p className='item-txt-sku'>Mua với giá: {Common.formatCurrency(item.salePrice)}</p>
                                            <p className='item-txt-sku'>Áp dụng: {item.isPrintedProduct ? "Sản phẩm in" : "Sản phẩm phôi"}</p>
                                        </div>
                                    </li>
                                </div>
                            );
                        })
                    ) : (
                        <div className='product-item'>
                            <li className='product_suggest'>
                                {isFetching ? (
                                    <span>
                                        <i className='fa fa-spinner m-r-5'></i>Đang tải dữ liệu...
                                    </span>
                                ) : (
                                    <span>Không có dữ liệu</span>
                                )}
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width='120'>Tên mã giảm giá</th>
                    <th width='120'>Thời gian hiệu lực sau khi thu thập Voucher</th>
                    <th width='120'>Số lượng</th>
                    <th width='120'>Mua với giá</th>
                    <th width='120'>Áp dụng cho sản phẩm</th>
                    <th width='120' id='timeFrameHeader'>
                        <TFUTooltip target='timeFrameHeader' position='left' content='Các khung quay, nếu chung khung thời gian thì input 1 số'>
                            Nhóm khung quay
                        </TFUTooltip>
                    </th>
                    <th width='120'>Thời gian nhả mã (Bắt đầu)</th>
                    <th width='120'>Thời gian nhả mã (Kết thúc)</th>
                    <th width='120'>Số lượng mã nhã trong khung thời gian</th>
                    <th width='120'>Số lượng nhã Maximum trong cùng IP</th>
                    <th width='50'></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {group && group.app_TargetGroups && group.app_TargetGroups.length > 0 ? (
                    group.app_TargetGroups.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className='text-left vertical-align-middle'>
                                    <strong>{item.name}</strong>
                                </td>
                                <td className='text-left vertical-align-middle'>{item.validCollectTime}</td>
                                <td className='text-left vertical-align-middle'>{item.quantityVoucher}</td>
                                <td className='text-left vertical-align-middle'>{Common.formatCurrency(item.salePrice)}</td>
                                <td className='text-left vertical-align-middle'>{item.isPrintedProduct ? "Sản phẩm in" : "Sản phẩm phôi"}</td>
                                <td className='text-left vertical-align-middle'>
                                    <TextNumber required ref={addRef} name={`timeFrameIndex`} className='form-control' value={+item.timeFrameIndex} min={0} max={999999999} onChanged={(name, value) => _handleTargetGroupVoucher(name, +value, i)} />
                                </td>
                                <td className='text-left vertical-align-middle'>
                                    <TFUDatetime
                                        minDate={new Date()}
                                        value={new Date(item.startTime)}
                                        showTimeSelectOnly={true}
                                        name='startTime'
                                        placeholder='Từ ngày'
                                        className='form-control'
                                        showTimeSelect
                                        onChanged={(value, name) => {
                                            _handleTargetGroupVoucher(name, value, i);
                                        }}
                                    />
                                </td>
                                <td className='text-left vertical-align-middle'>
                                    <TFUDatetime
                                        minDate={new Date()}
                                        value={new Date(item.endTime)}
                                        showTimeSelectOnly={true}
                                        name='endTime'
                                        placeholder='Từ ngày'
                                        className='form-control'
                                        showTimeSelect
                                        onChanged={(value, name) => {
                                            _handleTargetGroupVoucher(name, value, i);
                                        }}
                                    />
                                </td>
                                <td className='text-left vertical-align-middle'>
                                    <TextNumber required ref={addRef} name={`quantity`} className='form-control' suffix='mã' value={+item.quantity} min={0} max={999999999} onChanged={(name, value) => _handleTargetGroupVoucher(name, +value, i)} />
                                </td>
                                <td className='text-left vertical-align-middle'>
                                    <TextNumber
                                        required
                                        ref={addRef}
                                        name={`maximumReleaseSameIP`}
                                        className='form-control'
                                        suffix='mã'
                                        value={+item.maximumReleaseSameIP}
                                        min={0}
                                        max={999999999}
                                        onChanged={(name, value) => _handleTargetGroupVoucher(name, +value, i)}
                                    />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <i className='mdi mdi-24px mdi-delete text-danger cursor-pointer' onClick={() => _handleRemoveTargetVoucher(i)}></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='99' className='vertical-align-middle text-center'>
                            Chưa có sản phẩm nào được chọn
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {}

    //thiết lập target group index
    function _handleClickOutsideIndex() {
        setTimeout(function () {
            setShowResultIndex(false);
        }, 200);
    }

    function _handleFocusInputSearchIndex() {
        if (fellowVouchers && fellowVouchers.length > 0) setShowResultIndex(true);
        else setShowResultIndex(false);
    }

    async function _handleSelectItemIndex(item) {
        if (item && item.id > 0) {
            //bổ sung vào group
            let element = new App_TargetGroupIndexModel();
            element.voucherId = item.id;
            element.name = item.name;
            element.fromDate = item.fromDate;
            element.toDate = item.toDate;
            element.validCollectTime = item.validCollectTime;
            element.quantityVoucher = item.quantity;
            element.salePrice = item.salePrice;
            element.isPrintedProduct = item.isPrintedProduct;

            let grou = { ...group };
            let targetGroup = grou.app_TargetGroupIndices;
            targetGroup.push(element);
            console.log("targetGroup", targetGroup);
            setGroup(grou);
        }
    }

    async function _handleTargetGroupVoucherIndex(name, value, index) {
        let grou = { ...group };
        let targetGroup = grou.app_TargetGroupIndices;
        let item = targetGroup[index];
        item[name] = value;
        setGroup(grou);
    }

    async function _handleRemoveTargetVoucherIndex(i) {
        if (i) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    let grou = { ...group };
                    let targetGroup = grou.app_TargetGroupIndices;
                    targetGroup.splice(i, 1);
                    setGroup(grou);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn sản phẩm trước khi xóa!");
    }

    function _renderSearchAutoCompleteIndex() {
        return (
            <div id='search-autocomplete-index' className='p-1 bg-light rounded rounded-pill shadow-sm mb-4' onBlur={_handleClickOutsideIndex}>
                <div className='input-group'>
                    <div className='input-group-prepend'>
                        <button type='submit' className='btn btn-link text-warning'>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                    <input type='search' placeholder='Nhập tên sản phẩm...' className='form-control border-0 bg-light' onKeyUp={(e) => {}} onChange={(e) => {}} onFocus={() => _handleFocusInputSearchIndex()} autoComplete='off' />
                </div>
                {showResultIndex && _renderResultIndex()}
            </div>
        );
    }

    function _renderResultIndex() {
        return (
            <div id='search-result-index'>
                <ul className='suggest_search'>
                    {fellowVouchers && fellowVouchers.length > 0 ? (
                        fellowVouchers.map((item, index) => {
                            return (
                                <div className='product-item' key={index} onClick={() => _handleSelectItemIndex(item)}>
                                    <li className='product_suggest'>
                                        <div className='item-info'>
                                            <h3>{item.name} </h3>
                                            <p className='item-txt-sku'>Mô tả: {item.description}</p>
                                            <p className='item-txt-sku'>Thời gian hiệu lực sau khi Voucher collect: {item.validCollectTime}</p>
                                            <p className='item-txt-sku'>Số lượng Voucher Pool: {item.quantity}</p>
                                            <p className='item-txt-sku'>Mua với giá: {Common.formatCurrency(item.salePrice)}</p>
                                            <p className='item-txt-sku'>Áp dụng: {item.isPrintedProduct ? "Sản phẩm in" : "Sản phẩm phôi"}</p>
                                        </div>
                                    </li>
                                </div>
                            );
                        })
                    ) : (
                        <div className='product-item'>
                            <li className='product_suggest'>
                                {isFetching ? (
                                    <span>
                                        <i className='fa fa-spinner m-r-5'></i>Đang tải dữ liệu...
                                    </span>
                                ) : (
                                    <span>Không có dữ liệu</span>
                                )}
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        );
    }

    function _renderHeaderIndex() {
        return (
            <thead>
                <tr>
                    <th width='120'>Tên mã giảm giá</th>
                    <th width='120'>Thời gian hiệu lực sau khi collect voucher</th>
                    <th width='120'>Số lượng</th>
                    <th width='120'>Mua với giá</th>
                    <th width='120'>Áp dụng cho sản phẩm</th>
                    <th width='120'>Ngày áp dụng</th>
                    <th width='120'>Phần trăm mã được release</th>
                    <th width='120'>
                        Thứ tự trong vòng quay
                        <p className='sub-header'>Vòng quay có 10 vị trí</p>
                    </th>
                    <th width='50'></th>
                </tr>
            </thead>
        );
    }

    function _renderBodyIndex() {
        return (
            <tbody>
                {group && group.app_TargetGroupIndices && group.app_TargetGroupIndices.length > 0 ? (
                    group.app_TargetGroupIndices.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className='text-left vertical-align-middle'>
                                    <strong>{item.name}</strong>
                                </td>
                                <td className='text-left vertical-align-middle'>{item.validCollectTime}</td>
                                <td className='text-left vertical-align-middle'>{item.quantityVoucher}</td>
                                <td className='text-left vertical-align-middle'>{Common.formatCurrency(item.salePrice)}</td>
                                <td className='text-left vertical-align-middle'>{item.isPrintedProduct ? "Sản phẩm in" : "Sản phẩm phôi"}</td>
                                <td className='text-left vertical-align-middle'>
                                    <TFUDatetime
                                        minDate={new Date()}
                                        value={new Date(item.applyDate)}
                                        name='applyDate'
                                        ref={addRef}
                                        placeholder='Ngày áp dụng'
                                        className='form-control'
                                        showDateOnly
                                        onChanged={(value, name) => {
                                            _handleTargetGroupVoucherIndex(name, value, i);
                                        }}
                                    />
                                </td>
                                <td className='text-left vertical-align-middle'>
                                    <TextNumber
                                        required
                                        ref={addRef}
                                        name={`percentageRelease`}
                                        className='form-control'
                                        suffix='%'
                                        value={+item.percentageRelease}
                                        min={0}
                                        max={100}
                                        onChanged={(name, value) => _handleTargetGroupVoucherIndex(name, +value, i)}
                                    />
                                </td>

                                <td className='text-left vertical-align-middle'>
                                    <TextNumber required ref={addRef} name={`index`} className='form-control' min={1} max={10} suffix='mã' value={+item.index} onChanged={(name, value) => _handleTargetGroupVoucherIndex(name, +value, i)} />
                                </td>

                                <td className='text-center vertical-align-middle'>
                                    <i className='mdi mdi-24px mdi-delete text-danger cursor-pointer' onClick={() => _handleRemoveTargetVoucherIndex(i)}></i>   
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='99' className='vertical-align-middle text-center'>
                            Chưa có sản phẩm nào được chọn
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderActionIndex() {}

    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            size='tfulg'
            title={group.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave(false)}>
                        <i className='fa fa-save m-r-5' /> Lưu
                    </button>
                    {group.id > 0 ? null : (
                        <button className='btn btn-outline-primary btn-sm' onClick={() => _handleSave(true)}>
                            <i className='fa fa-save m-r-5' /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Tên nhóm:
                </label>
                <Textbox name='name' required ref={addRef} maxLength={200} className='form-control' onChanged={(name, value) => setGroup({ ...group, name: value })} value={group.name} />
            </div>
            <div className='form-group'>
                <label htmlFor='description'>Mô tả:</label>
                <TextArea ref={addRef} name='description' value={group.description} onChanged={(name, value) => setGroup({ ...group, description: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='spinnerImageUrl' className='required'>
                    Image vòng quay:
                </label>
                <p className='sub-header'>Ảnh vòng quay, .png</p>
                <div className="max-w-600px">
                    <Image
                    className
                        name='spinnerImageUrl'
                        image={group.spinnerImageUrl}
                        onChanged={(name, value) => {
                            setGroup({ ...group, spinnerImageUrl: value });
                        }}
                    />
                </div>
            </div>

            {/* Thiết lập cấu hình Target Group Voucher */}
            <div className='col-sm-12 card-box p-3'>
                <h4 className='header-title m-t-0'>
                    <b>Thiết lập cấu hình nhóm Voucher Release theo nhóm</b>
                </h4>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group col-md-12'>
                            {_renderSearchAutoComplete()}
                            <CommonTable noBoxOuter data={group.app_TargetGroups} paging={Common.Paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} freeSize='tbl_setproducts' />
                        </div>
                    </div>
                </div>
            </div>

            {/* Thiết lập cấu hình Target Group VoucherIndex */}
            <div className='col-sm-12 card-box p-3'>
                <h4 className='header-title m-t-0'>
                    <b>Thiết lập cấu hình nhóm Voucher phần trăm theo ngày</b>
                </h4>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group col-md-12'>
                            {_renderSearchAutoCompleteIndex()}
                            <CommonTable noBoxOuter data={group.app_TargetGroupIndices} paging={Common.Paging} renderHeader={_renderHeaderIndex} renderBody={_renderBodyIndex} renderAction={_renderActionIndex} freeSize='tbl_setproducts' />
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
