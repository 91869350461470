import React, {  useEffect, useState } from "react";
import styled from "styled-components";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";

function SizeOptionItem({data,getData, deleteSize , createOrUpdate, productId}) {
    const [size , setSize] = useState([])

    useEffect(()=>{
        setSize(data)
    },[data])
    
    async function handleAddItem() {
        setSize([ ...size, { id: 0, sizeValue: 0, productId: productId, isDelete: false, isUpdate: true }]);
    }


    function IsDisable(idx) {
        if (size[idx].sizeValue <= 0) return true;
        return false;
    }

    async function handleCreateOrUpdate(idx) {
        let dataItem = { ...size[idx] };
        let [err, dt] = await createOrUpdate(dataItem);
        if (!err && dt) {
            getData();
        } else {
           
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            var ids = []
            ids.push(id)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await deleteSize(ids);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            })
        } else {
            let newData = [...size];
            newData.splice(idx, 1);
            setSize(newData);
        }
    }



    return ( 
        <Wapper>
            <table>
                <tbody>
                    {size && size.length > 0 ? (
                        size.map((item, idx) => {
                            return (
                                <tr className={idx}>
                                    <td>
                                        {item.isUpdate  || item.id <= 0 ? (
                                            <input type="number"
                                                className="input-in-table"
                                                placeholder="value"
                                                value={item.sizeValue}
                                                maxLength={1}
                                                onChange={(e) => {
                                                    let dt = [...size];
                                                    dt[idx].sizeValue = e.target.value;
                                                    setSize(dt);
                                                }}
                                        />
                                        ) : (
                                            item.sizeValue
                                        )}
                                    </td>
                                    <td>
                                    {item.isUpdate || item.isAdd ? (
                                        <span
                                            className={`m-1 blue ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </span>
                                    ) : <span
                                            className={`blue m-1`}
                                            onClick={() => {
                                                let dt = [...size];
                                                dt[idx].isUpdate = true;
                                                setSize(dt);
                                            }}
                                        >
                                            Sửa
                                        </span>}
                                    <span
                                        className={"red m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </span>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={999}>Chưa có dữ liệu</td>
                        </tr>
                    )}
                    <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "12px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                 handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </Wapper>
    );
}
const Wapper = styled.div`
    width: 100%;

    .blue{
        color: #64C5B1;
        cursor: pointer;

    }

    .red{
        color: red;
        cursor: pointer;
    }
    table{
        border: none;
        width: 100%;

        th, td{
            border: none;
        }
    }
`
export default SizeOptionItem;