import React, { useState } from "react";
import { useEffect } from "react";
import Common from "../../based/Common";
import CommonTable from "../../based/CommonTable";
import { NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import CheckBox from "../../based/inputs/CheckBox";
import TFUImage from "../../based/inputs/Image";
import Textbox from "../../based/inputs/Textbox";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import MTOServices from "../../based/services/MTOServices";
import useFormValidate from "../../hooks/useFormValidate";
const initDesign = {
    id: 0,
    categoryId: 0,
    name: "",
    url: "",
    thumbnail: "",
};
const AddDesignModal = (props) => {
    const [design, setDesign] = useState({ ...initDesign });
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);
    const [isLoading, setIsLoading] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();

    //display msg required cho phần avatar
    const [msgErr, setMsgErr] = useState("");
    useEffect(() => {
        setMsgErr("");
    }, [design.url]);

    useEffect(() => {
        getDesigns(paging, props.categoryId);
    }, [props.categoryId]);

    //lấy danh sách thiết kế theo id
    const getDesigns = async (paging, id) => {
        setIsLoading(true);
        let [err, data] = await MTOServices.GetDesignByCategoryId(paging, id);
        if (!err && data && data.listObjects) {
            setIsLoading(false);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setDatas([]);
            setPaging(Common.Paging);
        }
    };
    const _handleSaveDesign = async () => {
        if (isValid() && design.url !== "") {
            setMsgErr("");
            let [err, data] = await MTOServices.CreateUpdateDesign({ ...design, categoryId: props.categoryId });
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", design.id !== 0 ? "Cập nhật thành công" : "Thêm thiết kế thành công");
                setDesign(initDesign);
                getDesigns(paging, props.categoryId);
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra ");
            }
        }
        if (design.url === "") return setMsgErr("Thêm ảnh đại diện");
    };
    const handleValidate = () => {};
    function _handleInputChange(key, value, thumbnail) {
        if (value) {
            setDesign({ ...design, url: value, thumbnail: thumbnail });
            document.getElementById("uploadImages").value = null;
        }
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };
    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <CheckBox checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} name='select_all' id='select_all' label=' ' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width='400' className='text-center'>
                        Ảnh
                    </th>
                    <th width='200' className='text-center'>
                        Tên thiết kế
                    </th>
                    <th className='text-center'>Trạng thái</th>
                </tr>
            </thead>
        );
    };
    const _renderBody = () => {
        return (
            <tbody>
                {datas && datas.length ? (
                    datas.map((item, idx) => (
                        <tr key={idx}>
                            <td align='center'>
                                <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                            </td>
                            <td align='center'>
                                <img style={{ width: 120, height: 120 }} src={item.thumbnail} alt='img' />
                            </td>
                            <td align='center'>
                                <h4 className='font-weight-bold'>{item.name}</h4>
                            </td>
                            <td align='center'>Kích hoạt</td>
                        </tr>
                    ))
                ) : (
                    <tr key=''>
                        <td className='text-center' colSpan={4}>
                            Chưa có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    };
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => setDesign({ ...initDesign })}>
                                <i className='fa fa-plus m-r-5' />
                                Tạo mới
                            </button>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className='fa fa-remove m-r-5' />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    const _handleFilter = async (paging) => {
        setIsLoading(true);
        let [err, data] = await MTOServices.FilterDesignByKeyword(paging, props.categoryId);
        if (!err && data) {
            setIsLoading(false);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setDatas([]);
            setPaging(Common.Paging);
        }
    };
    const _handleEdit = async () => {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            setDesign({ ...selected });
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn thiết kế để thực hiện. Vui lòng kiểm tra lại!");
    };
    const _handleDelete = async () => {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa thiết kế này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    // setMsg("Đang xóa dữ liệu...");
                    let [err, data] = await MTOServices.DeleteDesign(ids);
                    if (!err && data) {
                        setIsLoading(false);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        // Notify(NOTIFY.SUCCESS, "Thành công", "Xóa thành công");
                        getDesigns(paging, props.categoryId);
                    } else {
                        setIsLoading(false);
                        // setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có thiết kế nào được chọn. Vui lòng kiểm tra lại!");
    };
    return (
        <CommonModal show={props.isShow} title='Danh sách thiết kế' onClose={props.onClose} size='lg'>
            <div className='box__search d-flex justify-content-start flex-column align-items-center' style={{ minHeight: 455 }}>
                <h3 className='title w-100 text-left'>{design.id !== 0 ? "Cập nhật thiết kế" : "Thêm thiết kế mới"}</h3>
                <div className='form-row d-flex justify-content-around w-100' style={{ minHeight: 320 }}>
                    <div className='form-group w-30'>
                        <label>Tên thiết kế</label>
                        <Textbox required name='name' ref={addRef} maxLength={500} className='form-control' onChanged={(name, value) => setDesign({ ...design, name: value })} value={design.name} />
                    </div>
                    <div className='form-group w-50'>
                        <label>Hình đại diện:</label>
                        {msgErr ? <label className='text-danger full-width'>{msgErr}</label> : ""}
                        <TFUImage className='hide' id='uploadImages' imageType='mtoDesign' maximumSize={5} name='uploadImages' images={design.url} onChanged={_handleInputChange} />
                        <ul className='ui-photo-grid clearfix' style={{ listStyleType: "none" }}>
                            <li className='ui-photo-item col-md-3'>
                                {design.url ? (
                                    <div className='ui-photo-box position-relative' style={{ width: "200px" }}>
                                        <img className='ui-photo-item__image' src={design.url} style={{ width: "200px" }} />
                                        <div className='photo-hover-overlay'>
                                            <ul className='photo-overlay-actions position-absolute' style={{ top: 0, right: "10px" }}>
                                                <li>
                                                    <button className='btn btn-link photo-overlay-actions__link' onClick={() => setDesign({ ...design, url: "" })}>
                                                        <i className='fa fa-trash-o' style={{ color: "red" }}></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className='file-upload position-relative'
                                        style={{ width: "140px" }}
                                        onClick={() => {
                                            document.getElementById("uploadImages").click();
                                        }}
                                    >
                                        <div className='fileupload-text text-center' style={{ width: "140px" }}>
                                            <i className='fa fa-camera font-size-30px'></i>
                                            <p className='mb-0 mt-2 text-secondary'>Ảnh đại diện</p>
                                        </div>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='mt-4'>
                    {" "}
                    <button className='btn btn-success' onClick={_handleSaveDesign}>
                        Lưu
                    </button>
                </div>
            </div>
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={_handleFilter} searchBasic />
        </CommonModal>
    );
};

export default AddDesignModal;
