import BaseServices from "../based/services/BaseServices";

const GameServices = {
    CreateUpdateGame: async (game) => {
        return BaseServices.Post(`/api/game/create-update-game`, game);
    },
    GetGameById: async (gameId) => {
        return BaseServices.Get(`/api/game/get-game-by-id/${gameId}`);
    },
    GetGames: async (paging) => {
        return BaseServices.Post(`/api/game/get-games`, paging);
    },
    CreateUpdateGameRound: async (gameRound) => {
        return BaseServices.Post(`/api/game/create-update-game-round`, gameRound);
    },
    GetGameRoundByGameId: async (gameId) => {
        return BaseServices.Get(`/api/game/get-game-round-by-id/${gameId}`);
    },
    RemoveGameRoundById: async (gameRoundId) => {
        return BaseServices.Delete(`/api/game/remove-game-round-by-id/${gameRoundId}`);
    },
    TakeNote: async (model) => {
        return BaseServices.Post(`/api/game/take-note`, model);
    },
    BurnCard: async (model) => {
        return BaseServices.Post(`/api/game/burn-card`, model);
    },
    DeleteGame: async (gameId) => {
        return BaseServices.Get(`/api/game/delete-game/${gameId}`);
    },
    SimulatorGame: async (cuttingNumber) => {
        return BaseServices.Get(`/api/game/simulator-game/` + cuttingNumber);
    },
};

export default GameServices;
