import BaseServices from './BaseServices';

const CategoryServices = {
  GetPage: async (paging) => {
    return await BaseServices.Post('/api/category/get-page', paging);
  },
  GetCategoryById: async(id) =>{ 
    return await BaseServices.Get(`/api/category/get-category-by-id/${id}`)
  }
};

export default CategoryServices;