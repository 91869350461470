import { PRINT_TYPE_NAME, StockFolderType } from "../../based/Constants";
import { PrintOrderProductModel } from "./PrintOrderModel";

export class PrintProductModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.productAvatar = "";
        this.printProductColors = [];
        this.widthArtwork = 0;
        this.heightArtwork = 0;
        this.printTypeList = 0;
        this.listPrintType = [];
        this.packageType = 1;
    }
    printProductColors = [new PrintProductColorModel()];
}

export class PrintProductColorModel {
    constructor() {
        this.code = "";
        this.attributeName = "";
        this.value = "";
        this.displayName = "";
        this.appProductId = 0;
        this.isDisabled = false;
        this.imageModels = [];
    }
    imageModels = [new PrintProductImageModel()];
}

export class PrintProductImageModel {
    constructor() {
        this.imageId = 0;
        this.url = "";
        this.imageType = 0;
        this.appProductId = 0;
        this.colorValue = "";
        this.variantFrame = new VariantFrameModel();
    }
}

export class PrintStockPhotoModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.description = "";
        this.thumbUrl = "";
        this.width = 0;
        this.height = 0;
        this.tagConnections = [];
        this.color = "";
        this.cusImageId = 0;
        this.stockVariantThumbs = [];
    }
    tagConnections = [new TagConnectionModel()];
    stockVariantThumbs = [new StockVariantThumbModel()];
}

export class PrintStockImageModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.description = "";
        this.thumbUrl = "";
        this.width = 0;
        this.height = 0;
        this.tagConnections = [];
        this.color = "";
        this.cusImageId = 0;
    }
    tagConnections = [new TagConnectionModel()];
}

export class TagConnectionModel {
    constructor() {
        this.id = 0;
        this.taggableId = 0;
        this.taggableType = TaggableType.Design;
        this.tagId = 0;
    }
}

export class UploadImageModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.uploaderId = 0;
        this.url = "";
        this.thumbUrl = "";
        this.cusImageId = 0;
        this.aiLabType = 0;
        this.aiLabTypeName = "";
        this.aiLabTypes = [];
        this.width = 0;
        this.height = 0;
    }
}

export const TaggableType = {
    Design: 1,
    Stockphoto: 2,
};

export class DesignModel {
    constructor() {
        this.id = 0;
        this.creatorId = 0;
        this.folderId = 0;
        this.title = "Untitle";
        this.description = "";
        this.public = false;
        this.width = 0;
        this.height = 0;
        this.color = "";
        this.printOrderProducts = [];
        this.designExtends = [];
        this.printType = PRINT_TYPE_NAME.DTG;
        this.isDraft = 1;
        this.tempId = "";
    }
    printOrderProducts = [new PrintOrderProductModel()];
    designExtends = [new DesignExtend()];
}

export class DesignExtend {
    constructor() {
        this.designId = 0;
        this.imageId = 0;
        this.imageType = 0;
        this.artworkPosition = 0;
        this.elements = [];
        this.url = "";
        this.variantFrame = new VariantFrameModel();
        this.variantWarningZones = [];
        this.variantImages = [];
        this.checked = false;
        this.boundary = new BoundaryModel();
        this.boundaryX = 0;
        this.boundaryY = 0;
        this.boundaryHeight = 0;
        this.boundaryWidth = 0;
        this.printCost = 0;
        this.groupElements = [];
    }
    elements = [new ElementModel()];
    groupElements = [new GroupElementModel()];
    variantWarningZones = [new VariantWarningZoneModel()];
    variantImages = [new VariantImageModel()];
}

export class HistoryModel {
    constructor() {
        this.id = "";
        this.historyType = HistoryType.Editor;
        this.objectData = {};
        this.checked = false;
    }
}

export class ElementModel {
    constructor() {
        this.id = 0;
        this.imageId = 0;
        this.designId = 0;
        this.elementableId = 0;
        this.elementableType = ElementableType.Image;
        this.zIndex = 1;
        this.thumbUrl = "";
        this.thumbName = "";
        this.checked = false;
        this.groupId = "";
        this.tempGroupId = "";
        this.originGroupId = "";
        this.stockVariantThumbs = [];
        //frame
        this.posX = 0;
        this.posY = 0;
        this.posWidth = 0;
        this.posHeight = 0;
        this.posDegree = 0;
        this.posOpacity = 1;
        //object
        this.objectX = 0;
        this.objectY = 0;
        this.objectWidth = 0;
        this.objectHeight = 0;
        //text
        this.fontFamily = "";
        this.fontWeight = 0;
        this.fontSize = 0;
        this.fontColor = "";
        this.fontText = "";
        this.fontStyle = FontStyle.Normal;
        this.textDecoration = TextDecoration.Normal;
        this.letterSpacing = 0;
        this.lineSpacing = 1.2;
        this.isUpperCase = false;
        this.textAlign = TextAlignType.Left;
        this.scale = 1;
        //custom
        this.flipType = FlipType.Normal;
        this.listFlipType = [];
        //lock
        this.lock = false;
        //align
        this.showAlignBoundary = false;
        //curve
        this.listCurveModel = [new CurveModel()];
        this.lineBottomH = 0;
        this.isCurve = false;
        this.listObjectCurve = [new ObjectPositionModel()];
        this.curveNumber = 0;
        //svg
        this.svgContent = "";
        this.isSvg = "";
        this.isDistort = false;
    }
}

export class GroupElementModel {
    constructor() {
        this.id = "";
        this.imageId = 0;
        this.designId = 0;
        this.posX = 0;
        this.posY = 0;
        this.posWidth = 0;
        this.posHeight = 0;
        this.posDegree = 0;
        this.title = "";
        this.checked = false;
        this.lock = false;
        this.zIndex = 0;
        this.centerX = 0;
        this.centerY = 0;
        this.isCollapse = false;
        this.isTempGroup = false;
    }
}

export class FontModel {
    constructor() {
        this.id = 0;
        this.fontFamily = "";
        this.fontWeight = 0;
        this.fontSize = 0;
        this.fontColor = "";
        this.fontText = "";
        this.cusImageId = 0;
        this.textInfos = [];
        this.isFollowing = false;
    }
    textInfos = [new TextInfoModel()];
}

export class TextInfoModel {
    constructor() {
        this.id = 0;
        this.fontFamily = "";
        this.name = "";
        this.url = "";
        this.textId = 0;
    }
}

export class VariantFrameModel {
    constructor() {
        this.imageId = 0;
        this.axisX = 0;
        this.collarY = 0;
        this.frameMinY = 0;
        this.digitalScale = 0;
        this.width = 0;
        this.height = 0;
        this.positionMin = 0;
        this.positionMax = 0;
    }
}

export const ElementableType = {
    Image: 1,
    Text: 2,
};

export const ImageableType = {
    StockPhoto: 1,
    UploadImage: 2,
};

export const HistoryType = {
    Editor: 1,
};

export class BoundaryModel {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.dimensionWidth = 0;
        this.dimensionHeight = 0;
        this.pixelPerMm = 0;
        this.radius = 0;
    }
}

export class DesignOrderModel {
    constructor() {
        this.id = 0;
        this.designId = 0;
        this.appProductVariantId = 0;
        this.productCost = 0;
        this.printCost = 0;
        this.totalCost = 0;
        this.printType = 0;
        this.quantity = 0;
        this.appProductId = 0;
        this.designVariantAttributes = [];
    }
    designVariantAttributes = [new DesignVariantAttributeModel()];
}

export class DesignVariantAttributeModel {
    constructor() {
        this.designVariantAttributeType = 0;
        this.designVariantAttributeValue = 0;
    }
}

export const DesignVariantAttributeType = {
    Size: 1,
    Color: 2,
};

export const FlipType = {
    Normal: 0,
    Vertical: 1,
    Horizontal: 2,
};

export class AlignItem {
    constructor() {
        this.id = 0;
        this.a = new PositionModel();
        this.b = new PositionModel();
        this.c = new PositionModel();
        this.d = new PositionModel();
        this.top = new PositionModel();
        this.right = new PositionModel();
        this.bottom = new PositionModel();
        this.left = new PositionModel();
        this.center = new PositionModel();
    }
}

export class AlignLine {
    constructor() {
        this.a = new PositionModel();
        this.dimension = new DimensionModel();
        this.type = AlignType.Horizontal;
    }
}
export class DistortObjectModel {
    constructor() {
        this.m = new PositionModel();
        this.ratioTop = 0;
        this.ratioLeft = 0;
    }
}
export class DistortModel {
    constructor() {
        this.frame = new ObjectPositionModel();
        this.listM = [];
    }
    listM = [new DistortObjectModel()];
}

export class TopLeftLineModel {
    constructor() {
        this.firstLine = {};
        this.secondLine = {};
        this.connectLine = {};
    }
    firstLine = new LineModel();
    secondLine = new LineModel();
    connectLine = new LineModel();
}

export class LineModel {
    constructor() {
        this.a = new PositionModel();
        this.b = new PositionModel();
        this.width = 0;
    }
}

export const AlignType = {
    Horizontal: 1,
    Vertical: 2,
};

export const FontStyle = {
    Normal: 0,
    Italic: 1,
};

export const TextDecoration = {
    Normal: 0,
    Underline: 1,
};

export const TextAlignType = {
    Left: 1,
    Center: 2,
    Right: 3,
    Justify: 4,
};

export class ElementTextCurveModel {
    constructor() {
        this.id = 0;
        this.elementId = 0;
        this.absoluteDeg = 0;
        this.absoluteX = 0;
        this.absoluteY = 0;
        this.absoluteHeight = 0;
        this.absoluteWidth = 0;
        this.posDeg = 0;
        this.posHeight = 0;
        this.posWidth = 0;
        this.posX = 0;
        this.posY = 0;
        this.character = "";
        this.absoluteCenterCurveX = 0;
        this.absoluteCenterCurveY = 0;
    }
}

export class StockRecentlyModel {
    constructor(objId, objType) {
        this.objectId = objId;
        this.objectType = objType;
    }
}

export class RecentlyModel {
    constructor() {
        this.id = 0;
        this.objectId = 0;
        this.objectType = StockFolderType.StockPhoto;
        this.name = "";
        this.textInfos = [];
    }
    textInfos = [new TextInfoModel()];
}

export class FolderFontModel {
    constructor() {
        this.id = 0;
        this.fontId = 0;
        this.countStock = 0;
        this.fontFamilyName = "";
        this.name = "";
        this.parentFolderId = 0;
        this.textInfos = [];
    }
    textInfos = [new TextInfoModel()];
}

export class TextTemplateModel {
    constructor() {
        this.id = 0;
        this.name = 0;
        this.boundaryX = 0;
        this.boundaryY = 0;
        this.boundaryWidth = 0;
        this.boundaryHeight = 0;
        this.templateItems = [];
        this.width = 0;
        this.height = 0;
        this.color = "";
    }
    templateItems = [new TemplateItemModel()];
}

export class TemplateItemModel {
    constructor() {
        this.id = 0;
        this.fontText = "";
        this.fontFamily = "";
        this.fontColor = "";
        this.curveNumber = 0;
        this.posX = 0;
        this.posY = 0;
        this.posWidth = 0;
        this.posHeight = 0;
        this.posDegree = 0;
        this.posOpacity = 0;
        this.fontSize = 0;
        this.fontStyle = FontStyle.Normal;
        this.fontWeight = 0;
        this.letterSpacing = 0;
        this.lineSpacing = 0;
        this.templateId = 0;
        this.textAlign = TextAlignType.Left;
        this.textDecoration = TextDecoration.Normal;
        this.templateItemCurves = [];
    }
    templateItemCurves = [new TemplateItemCurveModel()];
}

export class TemplateItemCurveModel {
    constructor() {
        this.id = 0;
        this.absoluteCenterCurveX = 0;
        this.absoluteCenterCurveY = 0;
        this.absoluteDeg = 0;
        this.absoluteHeight = 0;
        this.absoluteWidth = 0;
        this.absoluteX = 0;
        this.absoluteY = 0;
        this.character = "";
        this.posDeg = 0;
        this.posHeight = 0;
        this.posWidth = 0;
        this.posX = 0;
        this.posY = 0;
        this.templateItemId = 0;
    }
}

export class VariantWarningZoneModel {
    constructor() {
        this.id = 0;
        this.imageId = 0;
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.radius = 0;
        this.productVariantName = "";
        this.url = "";
    }
}

export class VariantImageModel {
    constructor() {
        this.id = 0;
        this.imageId = 0;
        this.imageVariantUrl = "";
        this.index = 0;
    }
}

export class StockVariantThumbModel {
    constructor() {
        this.id = 0;
        this.stockId = 0;
        this.stockFolderType = 0;
        this.url = "";
        this.width = 0;
        this.height = 0;
    }
}

export class LastScrollSection {
    constructor() {
        this.bottomBarId = 0;
        this.lastScroll = 0;
    }
}

export class StockAIModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.thumbUrl = "";
        this.url = "";
        this.width = 0;
        this.height = 0;
        this.idSite = "";
        this.aiSite = AISite.MidJourney;
    }
}

export const AISite = {
    MidJourney: 1,
    Vecteezy: 2,
    ShutterStock: 3,
    Freepik: 4,
    Lab: 5,
};

export class PositionModel {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

export class DimensionModel {
    constructor(width, height) {
        this.width = width;
        this.height = height;
    }
}

export class ObjectPositionModel {
    constructor() {
        this.a = new PositionModel();
        this.b = new PositionModel();
        this.c = new PositionModel();
        this.d = new PositionModel();
        this.center = new PositionModel();
        this.dimension = new DimensionModel();
        this.deg = 0;
        this.aResult = new PositionModel();
    }
}

export class FrameObjectResultModel {
    constructor() {
        this.frame = new ObjectPositionModel();
        this.object = new ObjectPositionModel();
    }
}

export class CropPositionModel {
    constructor(cropTop = 0, cropBottom = 0, cropLeft = 0, cropRight = 0) {
        this.cropTop = cropTop;
        this.cropBottom = cropBottom;
        this.cropLeft = cropLeft;
        this.cropRight = cropRight;
    }
}

export class GroupPositionModel {
    constructor() {
        this.a = new PositionModel();
        this.b = new PositionModel();
        this.c = new PositionModel();
        this.d = new PositionModel();
        this.center = new PositionModel();
        this.dimension = new DimensionModel();
        this.deg = 0;
    }
}

export class TwoTouchModel {
    constructor() {
        this.firstx = 0;
        this.firsty = 0;
        this.secondx = 0;
        this.secondy = 0;
    }
}

export class ScaleFingerModel {
    constructor() {
        this.firstLength = 0; //AC
        this.secondLength = 0; //FC
        this.thirdLength = 0; //AF
        this.degCalculate = 0;
        this.diagonalAfter = 0;
        this.scaleNumber = 0;
        this.widthResult = 0;
    }
}

export class CropFingerModel {
    constructor() {
        this.firstLength = 0; //AC
        this.secondLength = 0; //FC
        this.thirdLength = 0; //AF
        this.degCalculate = 0;
        this.cropDistance = 0;
    }
}

export class CropModel {
    constructor() {
        this.top = 0;
        this.right = 0;
        this.bottom = 0;
        this.left = 0;
    }
}

export class CurveModel {
    constructor() {
        this.dimension = new DimensionModel();
        this.hBottomH = 0;
        this.accumulateW = 0;
        this.isMidLetter = false;
        this.angleMiddle = 0;
        this.angleMiddleText = 0;
        this.character = "";
        this.leftRightType = 0;
    }
}
export const GetListCurveModel = (checkedElement = new ElementModel()) => {
    //xử lý font canvas
    var { fontText, fontSize, fontFamily, lineSpacing, letterSpacing, fontWeight, scale, fontStyle } = checkedElement;

    var listCurveModel = [];
    for (var i = 0; i < fontText.length; i++) {
        var curve = new CurveModel();
        var character = fontText.charAt(i);
        var characterWidth = measureTextWidth(character, fontFamily, fontSize, fontWeight, letterSpacing, scale, fontStyle);

        var width = characterWidth + letterSpacing;
        var height = fontSize * lineSpacing;
        curve.dimension = new DimensionModel(width, height);
        curve.character = fontText.charAt(i);
        listCurveModel.push(curve);
    }

    return listCurveModel;
};

const measureTextWidth = (text, fontFamily, fontSize, fontWeight = 500, letterSpacing = 0, scale = 1, fontStyle = FontStyle.Normal) => {
    const p = document.createElement("p");
    p.innerHTML = text;
    p.style.fontFamily = `"${fontFamily}"`;
    p.style.fontSize = `${fontSize}px`;
    p.style.fontWeight = +fontWeight;
    p.style.transform = `scale(${scale}, ${scale})`;
    p.style.fontStyle = fontStyle == FontStyle.Normal ? "normal" : "italic";
    p.style.letterSpacing = `${letterSpacing}px`;
    p.style.margin = 0;
    p.style.transformOrigin = "top left";
    p.style.visibility = "hidden";
    p.style.position = "absolute";
    p.style.whiteSpace = "pre";
    document.body.appendChild(p);
    let bounding = p.getBoundingClientRect();
    const width = Math.ceil(bounding.width);
    document.body.removeChild(p);
    return width;
};
