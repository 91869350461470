import React, { useState, useEffect } from "react";

import TFUSelect from "../../../based/inputs/TFUSelect";
import CheckBox from "../../../based/inputs/CheckBox";
import CommonModal from "../../../based/Modal";
import { BLOG_FLAG } from "../AnswerContentManagement";
import useFormValidate from "../../../hooks/useFormValidate";
import AnswerContentService from "../../../based/services/Help/AnswerContentService";
import { Notify } from "../../../based/Notify";
import { INITPAGING, NOTIFY } from "../../../based/Constants";
import TextNumber from "../../../based/inputs/TextNumber";
import CommonTable from "../../../based/CommonTable";
import Loading from "../../../based/Loading";

const statusOptions = [
    {
        value: 1,
        label: "Duyệt",
    },
    {
        value: 0,
        label: "Chờ duyệt",
    },
];
export const flagOptions = [
    {
        value: 0,
        label: "Mặc định",
    },
    {
        value: 1,
        label: "Nổi bật",
    },
];
const IS_ACTIVE = {
    ACTIVE: 1,
    HIDE: 0,
};
const DefaultSetting = {
    id: 0,
    status: 0,
    indexPos: 0,
    flag: 0,
    isActive: 0,
};
const SettingModal = (props) => {
    const [settings, setSettings] = useState({ ...DefaultSetting });
    const { addRef, displayError, isValid } = useFormValidate();
    const [isLoading, setIsLoading] = useState(false);
    // const [listBackLink, setListBackLink] = useState([]);
    // const [paging, setPaging] = useState({ ...INITPAGING });
    // const [keywords, setKeywords] = useState([]);

    useEffect(() => {
        setSettings({ id: props.answerContent.id, status: props.answerContent.status, indexPos: props.answerContent.indexPos, flag: props.answerContent.flag, isActive: props.answerContent.isActive });
        //handleCountATag(props.answerContent.content);
        //getKeywords(paging);
    }, [props.answerContent.id]);

    const getKeywords = async (paging) => {
        // setIsLoading(true);
        // let [err, data] = await An.GetanswerContentKeywords(paging);
        // if (!err && data) {
        //     setIsLoading(false);
        //     setKeywords(data.listObjects);
        //     setPaging(data.paging);
        // } else {
        //     setIsLoading(false);
        //     setKeywords([]);
        //     setPaging({ ...INITPAGING });
        // }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsLoading(true);
            let [err, data] = await AnswerContentService.ChangeAnswerContentStatus(settings);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Thành công!.");
                setIsLoading(false);
                props.onSuccess();
                props.onClose();
            } else {
                setIsLoading(false);
                Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
            }
        }
    };
   // const handleTargetAttributes = (target) => {
        // switch (target) {
        //     case "_blank":
        //         return "New tab";
        //     case "_parent":
        //         return "Current tab";
        //     case "_top":
        //         return "Mở trong toàn bộ phần nội dung của cửa sổ";
        //     default:
        //         return "Current tab";
        // }
    //};
    //const handleCountATag = (content) => {
        // var parser = new DOMParser().parseFromString(content, "text/html");
        // const ancho = parser.querySelectorAll("a");
        // if (ancho && ancho.length > 0) setListBackLink([...ancho]);
    //};
    //function decodeEntity(inputStr) {
        // var textarea = document.createElement("textarea");
        // textarea.innerHTML = inputStr;
        // return textarea.value;
    //}

   // function findLocationCharacter(substring, string) {
        // var a = [],
        //     i = -1;
        // while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
        // return a;
    //}
    //const handleCountKeywords = (keywords) => {
        // var result = [];
        // if (keywords && keywords.length > 0) {
        //     keywords.map((item) => {
        //         let temp = findLocationCharacter(item.name, decodeEntity(props.blog.content));
        //         if (temp && temp.length) {
        //             result.push({ keyword: item.name, count: temp.length, level: item.level });
        //         }
        //     });
        // }
        // return result;
    //};
    //const renderHeader = () => {
        // return (
        //     <thead>
        //         <tr>
        //             <th width={40} className='text-center'>
        //                 STT
        //             </th>
        //             <th width='' className='text-center'>
        //                 Tiêu đề
        //             </th>
        //             <th>Back Link </th>
        //             <th className='text-center'>Target _blank</th>
        //         </tr>
        //     </thead>
        // );
    //};
    //const handleRedirect = (url) => {
       // return window.open(url, "_blank");
    //};
    //const renderBody = () => {
        // return (
        //     <tbody>
        //         {listBackLink && listBackLink.length > 0 ? (
        //             listBackLink.map((item, idx) => (
        //                 <tr key={idx}>
        //                     <td>{idx + 1}</td>
        //                     <td>{item.innerText || "_"}</td>
        //                     <td onClick={() => (item.attributes.href ? handleRedirect(item.attributes.href.nodeValue || "") : {})} className='cursor-pointer'>
        //                         {item.attributes.href ? item.attributes.href.nodeValue : "Chưa xác định"}
        //                     </td>
        //                     <td>{handleTargetAttributes(item.target) || "Chưa xác định"}</td>
        //                 </tr>
        //             ))
        //         ) : (
        //             <tr key=''>
        //                 <td colSpan={4}> Không có Back link</td>
        //             </tr>
        //         )}
        //     </tbody>
        // );
   // };
    //const renderHeaderKeyword = () => {
        // return (
        //     <thead>
        //         <tr>
        //             <th width={40} className='text-center'>
        //                 STT
        //             </th>
        //             <th width='' className='text-center'>
        //                 Từ khóa
        //             </th>
        //             <th>Số lượng</th>
        //             <th>Level</th>
        //         </tr>
        //     </thead>
        // );
    //};
    //const renderBodyKeyword = () => {
        // return (
        //     <tbody>
        //         {handleCountKeywords(keywords).length > 0 ? (
        //             handleCountKeywords(keywords).map((item, idx) => (
        //                 <tr key={idx}>
        //                     <td>{idx + 1}</td>
        //                     <td>{item.keyword}</td>
        //                     <td>{item.count}</td>
        //                     <td>F{item.level}</td>
        //                 </tr>
        //             ))
        //         ) : (
        //             <tr key=''>
        //                 <td colSpan={4}> Không có keyword</td>
        //             </tr>
        //         )}
        //     </tbody>
        // );
    //};
    return (
        <CommonModal show={props.isShowModal} onClose={props.onClose} onSave={_handleSave} isBusy={isLoading}>
            <div className='form-group'>
                <label>Trạng thái:</label>
                <TFUSelect name='status' value={settings.status} options={statusOptions} placeholder='---Thiết lập---' onChanged={(value) => setSettings({ ...settings, status: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='indexPos' required className='required mt-3'>
                    Thứ tự hiển thị:
                </label>
                <TextNumber id='indexPos' name='indexPos' min={0} required ref={addRef} className='form-control' onChanged={(name, value) => setSettings({ ...settings, indexPos: value })} value={settings.indexPos} />
            </div>
            <div className='form-group'>
                <label>Flag:</label>
                <TFUSelect name='flag' value={settings.flag} options={flagOptions} placeholder='---Thiết lập---' onChanged={(value) => setSettings({ ...settings, flag: value })} />
            </div>
            {/* <div className='form-group d-flex'>
                <label className=' mr-3'>Kích hoạt:</label>
                <CheckBox name='isActive' id='isActive' checked={settings.isActive == IS_ACTIVE.ACTIVE ? true : false} label='' onCheckedChange={(name, value) => setSettings({ ...settings, isActive: value ? IS_ACTIVE.ACTIVE : IS_ACTIVE.HIDE })} />
            </div>
            {props.blog && props.blog.id > 0 ? (
                <React.Fragment>
                    <hr />
                    <CommonTable renderHeader={renderHeader} renderBody={renderBody} classDivTable='table__fix__height' />
                    <hr />
                    <CommonTable renderHeader={renderHeaderKeyword} renderBody={renderBodyKeyword} classDivTable='table__fix__height' />
                </React.Fragment>
            ) : (
                ""
            )} */}
        </CommonModal>
    );
};

export default SettingModal;
