import React from 'react';
import { Modal } from 'react-bootstrap';

const ResourceModal = ({ show, resources, onClose }) => {
    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Hình ảnh/Video tải lên</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <div className='row'>
                    {
                        resources && resources.length > 0 && resources.map((resource, idx) => {
                            return <div key={idx} className='col-3'>
                                <a href={resource.resourceUrl}>
                                    <img src={resource.thumbnail} style={{ width: '100%' }} />
                                </a>
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-inverse waves-light btn-sm' onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResourceModal;