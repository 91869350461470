import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { PAYMENT_METHOD } from "../based/Constants";
import OrderServices from "../based/services/OrderServices";

function LogisticMultiDetailPage(props) {
    const history = useHistory();
    const [deliveryModel, setDeliveryModel] = useState({});
    const { sortingCode } = props.match.params;

    console.log("sortingCode", sortingCode);
    console.log("deliveryModel", deliveryModel);

    useEffect(() => {
        fetchData(sortingCode);
    }, [sortingCode]);

    const fetchData = async (sortingCode) => {
        const [err, data] = await OrderServices.GetMultiOrderGrabDeliveryInfo(sortingCode);
        if (!err && data) {
            setDeliveryModel(data);
        } else {
            setDeliveryModel({});
        }
    };

    const totalShippingFee = useMemo(() => {
        if (!deliveryModel || !deliveryModel.transactionDeliveries) return 0;
        return deliveryModel.transactionDeliveries.reduce((total, item) => (item.order ? total + item.order.shippingServicesFee : total), 0);
    }, [deliveryModel]);

    if (!deliveryModel) return "";
    return (
        <React.Fragment>
            <Wrapper>
                <div className="section-shipper">
                    <div className="order-detail">
                        <div className="order-row">
                            <div className="detail-left">Trạng thái</div>
                            <div className="detail-right text-green">{deliveryModel.shipperModel && deliveryModel.shipperModel.shipperName ? "Đã nhận" : "Chờ Shipper"}</div>
                        </div>
                        {/* <div className="order-row">
                            <div className="detail-left">Mã đơn Grab</div>
                            <div className="detail-right">
                                123NJKDFNJKWN234
                                <div className="icon">
                                    <CopyIcon />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {deliveryModel.shipperModel && deliveryModel.shipperModel.shipperName && (
                        <div className="shipper-detail">
                            <div className="detail-status">
                                <div className="status-left">Giao nhiều điểm</div>
                                <div className="status-right">{Common.formatCurrency(totalShippingFee)}</div>
                            </div>
                            <div className="shipper">
                                <div className="shipper-name">{deliveryModel.shipperModel.shipperName} - </div>
                                <div className="shipper-phone">
                                    <a href="tel:0365339300">{deliveryModel.shipperModel.phoneNumber}</a>
                                    <div className="icon">
                                        <PhoneIcon />
                                    </div>
                                </div>
                            </div>

                            <div className="shipper-bike">
                                <span>{deliveryModel.shipperModel.extra}</span>
                            </div>
                        </div>
                    )}
                </div>
                {deliveryModel.transactionDeliveries && deliveryModel.transactionDeliveries.length > 0
                    ? deliveryModel.transactionDeliveries.map((item, index) => {
                          const ship = item.shippingAddress;
                          const totalWeight = item.products.reduce((total, item) => total + item.shippingWeight, 0) / 1000;
                          let redundantPrice = item.order.finallyPrice - (item.order.transferPartial || 0);
                          if (item.order.paymentMethod == PAYMENT_METHOD.COD || item.order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) {
                          } else {
                              redundantPrice = 0;
                          }

                          const totalQty = item.products.reduce((total, item) => total + item.quantity, 0);
                          return (
                              <div className="receive-position" key={index}>
                                  <div className="receiver">
                                      <div className="receiver-header">
                                          <div className="receiver-header-left">Điểm giao hàng {index + 1}</div>
                                          <div className="receiver-header-right">
                                              <div className="right-detail"></div>
                                              <div className="icon">
                                                  <GoRightIcon />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="receiver-body">
                                          <p>
                                              {ship.userName} - {ship.phoneNumber}
                                          </p>
                                          <p>{ship.fullAddress}</p>
                                      </div>
                                  </div>
                                  <div className="booking-type">
                                      <div className="booking-left">
                                          <div className="booking-type-header">COD</div>
                                          <div className="booking-type-body">{Common.formatCurrency(redundantPrice)}</div>
                                      </div>
                                      <div className="booking-middle">
                                          <div className="booking-type-header">Size</div>
                                          <div className="booking-type-body">S</div>
                                      </div>
                                      <div className="booking-right">
                                          <div className="booking-type-header">Cân nặng</div>
                                          <div className="booking-type-body">{totalWeight} Kg</div>
                                      </div>
                                  </div>
                                  <div className="booking-package">
                                      <span>
                                          {totalQty} sản phẩm: {Common.formatCurrency(item.order.finallyPrice)}
                                      </span>
                                  </div>
                              </div>
                          );
                      })
                    : ""}

                <div className="bottom-bar">
                    <div
                        className="close-button"
                        onClick={(e) => {
                            history.push("/logistic");
                        }}
                    >
                        <span>Đóng</span>
                    </div>
                    <div className="copy-button">
                        <div className="icon">
                            <CopyLinkIcon />
                        </div>
                        <span>Copy link</span>
                    </div>
                    <div className="share-button">
                        <div className="icon">
                            <ShareLinkIcon />
                        </div>
                        <span>Share Zalo</span>
                    </div>
                </div>
            </Wrapper>
        </React.Fragment>
    );
}

export default LogisticMultiDetailPage;

const Wrapper = styled.div`
    overflow: auto;
    height: 100vh;
    position: relative;

    .section-shipper {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
    }

    .section-shipper {
        background: white;
        padding: 15px;
        border-radius: 0 0 12px 12px;
    }

    .order-row {
        display: flex;
        align-items: center;
    }

    .detail-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .detail-right {
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    .text-green {
        /* 128.571% */
        color: #28b259;
    }

    .icon {
        display: flex;
        margin-left: 4px;
    }

    .detail-status {
        display: flex;
    }

    .status-left {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .status-right {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        margin-left: auto;
    }

    .shipper-detail {
        margin-top: 12px;
    }

    .shipper {
        display: flex;
    }

    .shipper-phone {
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    .shipper-bike {
        margin-top: 4px;
    }

    .copy-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        margin: 0px 5px;
    }

    .share-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        margin: 0 5px;
    }

    .close-button {
        border-radius: 12px;
        background: #f96a74;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin: 0 5px;
        max-width: 40vw;
    }

    .copy-button span {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .share-button span {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .receive-position {
        background: white;
        margin: 12px 16px;
        border-radius: 12px;
    }

    .booking-package {
        padding: 8px 12px;
        border-top: 1px solid #d9d9d9;
    }

    .bottom-bar {
        display: flex;
    }

    .create-button {
        width: 40%;
    }

    .overview-grab {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .overview-top {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .overview-bottom {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .header {
        display: flex;
    }

    .header-right {
        margin-left: auto;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .section-info {
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 12px 0;
    }

    .sender-header {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
    }

    .sender-header-right {
        display: flex;
        margin-left: auto;
    }

    .sender-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    p {
        margin: 0 !important;
    }

    .sender-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .sender {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header {
        display: flex;
        align-items: center;
    }

    .receiver-header-right {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .receiver {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header-right .icon {
        margin-left: 8px;
    }

    .right-detail {
        color: #28b259;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .receiver-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .receiver-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .booking-type {
        display: flex;
    }

    .booking-left {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        background: white;
    }

    .booking-middle {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border-left: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        background: #f6f6f6;
    }

    .booking-right {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        background: #f6f6f6;
    }

    .booking-left-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        display: flex;
    }

    .booking-left-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        display: flex;
    }

    .booking-middle-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-middle-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-right-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-right-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-type-header {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .booking-type-body {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .booking-left .booking-type-body {
        color: #28b259;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .order-product-overview {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 8px;
    }

    thead {
        border-radius: 10px 0px 0px 0px;
        border: 1px solid #ededed;
        background: #878787;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
    }

    td span.main {
        display: block;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    td span.sub {
        display: block;
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    tr td {
        vertical-align: middle !important;
        text-align: center;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    table.table.table-border {
        background: white;
    }

    .bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    }

    .create-button {
        border-radius: 12px;
        background: #28b259;
        text-align: center;
        padding: 14px;
    }

    .create-button span {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .booking-main {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .method-type {
        display: flex;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .method-left {
        width: 33.33%;
        text-align: center;
        padding: 12px;
        border-radius: 12px 0 0 12px;
    }

    .active {
        background: #28b259;
    }

    .method-middle {
        width: 33.33%;
        text-align: center;
        padding: 12px;
        border: 1px solid #d9d9d9;
    }

    .method-right {
        width: 33.33%;
        text-align: center;
        padding: 12px;
        border-radius: 0 12px 12px 0;
    }

    .method-type-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .method-type-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-top: 4px;
    }

    .method-type .active div {
        color: white !important;
    }

    .order-product {
        margin-top: 24px;
    }
`;

const GoRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
            <path d="M1 1L5 5L1 9" stroke="black" strokeWidth="1.5" />
        </svg>
    );
};

const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
                d="M19.5758 9.80078H11.4758C10.4817 9.80078 9.67578 10.6067 9.67578 11.6008V19.7008C9.67578 20.6949 10.4817 21.5008 11.4758 21.5008H19.5758C20.5699 21.5008 21.3758 20.6949 21.3758 19.7008V11.6008C21.3758 10.6067 20.5699 9.80078 19.5758 9.80078Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.07598 15.2H5.17598C4.69859 15.2 4.24075 15.0104 3.90318 14.6728C3.56562 14.3352 3.37598 13.8774 3.37598 13.4V5.3C3.37598 4.82261 3.56562 4.36477 3.90318 4.02721C4.24075 3.68964 4.69859 3.5 5.17598 3.5H13.276C13.7534 3.5 14.2112 3.68964 14.5488 4.02721C14.8863 4.36477 15.076 4.82261 15.076 5.3V6.2"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const PhoneIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <g clip-path="url(#clip0_5796_2249)">
                <path
                    d="M15.5429 11.7807V13.7807C15.5437 13.9664 15.5056 14.1502 15.4313 14.3203C15.3569 14.4904 15.2478 14.6431 15.111 14.7686C14.9742 14.8941 14.8126 14.9897 14.6368 15.0492C14.4609 15.1087 14.2745 15.1308 14.0896 15.114C12.0381 14.8911 10.0676 14.1901 8.33626 13.0674C6.72548 12.0438 5.35982 10.6782 4.33626 9.06738C3.20958 7.32819 2.50842 5.34805 2.28959 3.28738C2.27293 3.10303 2.29484 2.91722 2.35392 2.7418C2.41301 2.56637 2.50797 2.40518 2.63277 2.26846C2.75756 2.13175 2.90946 2.02252 3.07878 1.94773C3.24811 1.87294 3.43115 1.83422 3.61626 1.83405H5.61626C5.9398 1.83086 6.25345 1.94543 6.49877 2.1564C6.74408 2.36737 6.90431 2.66035 6.94959 2.98072C7.03401 3.62076 7.19056 4.2492 7.41626 4.85405C7.50595 5.09266 7.52537 5.35199 7.4722 5.6013C7.41902 5.85061 7.2955 6.07946 7.11626 6.26072L6.26959 7.10738C7.21863 8.77641 8.60056 10.1583 10.2696 11.1074L11.1163 10.2607C11.2975 10.0815 11.5264 9.95795 11.7757 9.90478C12.025 9.85161 12.2843 9.87102 12.5229 9.96072C13.1278 10.1864 13.7562 10.343 14.3963 10.4274C14.7201 10.4731 15.0159 10.6362 15.2273 10.8857C15.4387 11.1352 15.551 11.4538 15.5429 11.7807Z"
                    stroke="#13AAFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5796_2249">
                    <rect width="16" height="16" fill="white" transform="translate(0.875977 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const CopyLinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.0002 10.9992C13.5707 10.4251 13.0228 9.95007 12.3936 9.60631C11.7645 9.26255 11.0687 9.05813 10.3535 9.00691C9.63841 8.9557 8.92061 9.05888 8.24885 9.30947C7.5771 9.56005 6.96709 9.95218 6.4602 10.4592L3.4602 13.4592C2.54941 14.4023 2.04544 15.6653 2.05683 16.9763C2.06822 18.2872 2.59407 19.5413 3.52111 20.4683C4.44815 21.3954 5.70221 21.9212 7.01319 21.9326C8.32418 21.944 9.58719 21.44 10.5302 20.5292L12.2402 18.8192"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const ShareLinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M15.0615 16.5851L21.2219 10.8068C21.6432 10.4116 21.643 9.74254 21.2214 9.3476L15.061 3.57708C14.4222 2.97874 13.3774 3.43167 13.3774 4.30691V7.01099C13.2878 7.02198 13.2094 7.03297 13.131 7.03297C11.7088 7.13187 10.3539 7.47253 9.05487 8.05494C4.70997 10.022 2 14.1648 2 18.8571V19.0558C2 19.9367 3.23342 20.1958 3.64614 19.4176C5.30347 16.2857 7.92385 14.3187 11.4065 13.4725C12.0448 13.3077 12.6943 13.2527 13.3774 13.1429V15.8558C13.3774 16.7313 14.4229 17.1841 15.0615 16.5851Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
