import React from "react";
import {useState, useEffect, useRef } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import MarketingServices from "../../based/services/MarketingServices";
import Common from "../../based/Common";
import { INITPAGING , CONSTANTS} from "../../based/Constants";
import styled from "styled-components";
import { ConfirmDialog } from "../../based/Dialog";
import { Modal } from "react-bootstrap";
import Loading from "../../based/Loading";
import ModalChangeSpecialDay from "./ModalChangeSpecialDay";
import TFUSelect from "../../based/inputs/TFUSelect";


const TAB_CONFIG = {
    OPTION: 0,
    DATE: 1
}
const initGift = {
    name: '',
    id: 0,
    marketingCampaignId: 0,
    giftType: 2
}

const initConfigGift = {
    startTime: '00:00',
    endTime: '00:00',
    rateWin: 0,
    quantity: 0,
    minuteExpiry: 30,
    timeStartUsing: null,
    timeEndUsing: null,
    id: 0,
    giftId: 0,
}
const initConfigSpecialDayGift = {
    startTime: '00:00',
    endTime: '00:00',
    rateWin: 0,
    quantity: 0,
    minuteExpiry: 30,
    timeStartUsing: null,
    timeEndUsing: null,
    id: 0,
    specialDayId: 0,
}
const initSpecialDay = {
    id: 0,
    giftId: 0,
    name: "",
    configTimes: [],
    isUpdate: true,
}

const EFFECT_TIME = [
    {value: 1 , label: '1 ngày'},
    {value: 3 , label: '3 ngày'},
    {value: 7 , label: '7 ngày'},
    {value: -1 , label: 'Cả chiến dịch'},
]

const ModalConfigTime = ({isShowModal, gift, onClose, campaignId}) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [tab , setTab] = useState(TAB_CONFIG.OPTION)
    const [isModal , setIsModal] = useState({
        isShow: false,
        date: null,
        specialApply: {},
    })
    const [configDefault , setConfigDefault] = useState([])
    const [specialDay , setSpecialDay] = useState([])
    const [giftId , setGiftId] = useState([])
    const [giftItem , setGiftItem] = useState({})

    useEffect(()=>{
        if(gift && gift.id > 0){
            getGiftConfigByGiftId(gift.id)
            setGiftItem(gift)
            getSpecialDayByGiftId(gift.id)
        }else{
            setConfigDefault([])
            setSpecialDay([])
        }
    },[gift])

  
    
    const getGiftConfigByGiftId = async (giftId) => {
        let [err , data ] = await MarketingServices.GetGiftConfigByGiftId(giftId);
        if(!err && data){
            setConfigDefault(data)
        }else{
            setConfigDefault([])
        }
    }

    const getSpecialDayByGiftId = async (giftId) => {
        let [err , data ] = await MarketingServices.GetSpecialDayByGiftId(giftId);
        if(!err && data){
            setSpecialDay(data)
        }else{
            setSpecialDay([])
        }
    }


    //default config
    const handleAddConfig = async () => {
        let [err , data ] = await MarketingServices.CreateUpdateMarketingGiftConfig(
            {...initConfigGift,giftId: gift.id});
        if(!err && data){
            getGiftConfigByGiftId(gift.id)
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

        }
    }
    const handleAddConfigLast = async (startTime , endTime) => {
        let [err , data ] = await MarketingServices.CreateUpdateMarketingGiftConfig({...initConfigGift,
            startTime, endTime , giftId: gift.id
        });
        if(!err && data){
            getGiftConfigByGiftId(gift.id)
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

        }

    }
    const updateItemConfig = async (item) => {
        if(item.startTime.split(":")[0] <= 23 && item.startTime.split(":")[0] >= 0
            && item.startTime.split(":")[1] <= 59 && item.startTime.split(":")[1] >=0
            && item.endTime.split(":")[0] <= 23 && item.endTime.split(":")[0] >= 0
            && item.endTime.split(":")[1] <= 59 && item.endTime.split(":")[1] >=0
        ){
            let [err , data ] = await MarketingServices.CreateUpdateMarketingGiftConfig(item);
            if(!err && data){
                
            }else{
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
        }
        else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Giờ từ 0 đến 23 ,phút từ 0 đến 59");
        }

    }

    const handleDeleteConfig = async (id)  => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [err , dt ] = await MarketingServices.DeleteGiftConfigById(id);
                if(!err && dt != 0){
                    if(dt == 1){
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getGiftConfigByGiftId(gift.id)
                    }
                    if(dt == -7)
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đang diễn ra chiến dịch , không thể xóa");

                }else{
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

                }
            });
    });
    }



    //special day
    const handleAddSpecialDay = async () => {
        setSpecialDay([{...initSpecialDay , giftId: gift.id},...specialDay]);
    }

    const handleCreateUpdateSpecialDay = async (item , i) => {
        let data = [...specialDay]
        if(data[i].isUpdate){
            let [err , dt ] = await MarketingServices.CreateUpdateSpecialDay(item);
            if(!err && dt){
                getSpecialDayByGiftId(gift.id)
            }else{
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

            }
        }
        data[i].isUpdate = !data[i].isUpdate
        setSpecialDay(data)
    }


    const handleAddConfigSpecialDay = async (start , end ,specialDayId) => {
        let [err , data ] = await MarketingServices.CreateUpdateMarketingGiftSpecialDayConfig(
            {...initConfigSpecialDayGift,startTime: start, endTime: end,specialDayId: specialDayId});
        if(!err && data){
            getSpecialDayByGiftId(gift.id)
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

        }
    }

    const handleAddConfigSpecialDayFirst = async (specialDayId) => {
        let [err , data ] = await MarketingServices.CreateUpdateMarketingGiftSpecialDayConfig(
            {...initConfigSpecialDayGift,specialDayId: specialDayId});
        if(!err && data){
            getSpecialDayByGiftId(gift.id)
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

        }
    }


    const handleDeleteSpecialDay = async (id, idx)  => {
        if(id > 0){
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [err , dt ] = await MarketingServices.DeleteSpecialDayById(id);
                    if(!err && dt != 0){
                        if(dt == 1){
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                            getSpecialDayByGiftId(gift.id)
                        }
                        if(dt == -7)
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đang diễn ra chiến dịch , không thể xóa");

                    }else{
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

                    }
                });
             });
            
        }else{
            let data = specialDay.filter((item , i) => i !== idx)
            setSpecialDay(data)
        }

    }

    const handleDeleteSpecialDayConfig = async (id)  => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [err , dt ] = await MarketingServices.DeleteSpecialDayGifyConfigById(id);
                if(!err && dt != 0){
                    if(dt == 1){
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getSpecialDayByGiftId(gift.id)
                    }
                        
                    if(dt == -7)
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đang diễn ra chiến dịch , không thể xóa");
                }else{
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");

                }
            });
    });
    }


    const updateItemSpecialDayConfig = async (item) => {
        if(item.startTime.split(":")[0] <= 23 && item.startTime.split(":")[0] >= 0
            && item.startTime.split(":")[1] <= 59 && item.startTime.split(":")[1] >=0
            && item.endTime.split(":")[0] <= 23 && item.endTime.split(":")[0] >= 0
            && item.endTime.split(":")[1] <= 59 && item.endTime.split(":")[1] >=0
        ){
            let [err , data ] = await MarketingServices.CreateUpdateMarketingGiftSpecialDayConfig(item);
            if(!err && data){
                
            }else{
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");

            }
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Giờ từ 0 đến 23 ,phút từ 0 đến 59");
        }

    }


    const updateGift = async (item) => {
        let [err , data ] = await MarketingServices.CreateUpdateGift(item);
        if(!err && data){
            setGiftItem(data);
        }else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }


    return (
        <Modal show={isShowModal} onHide={onClose} size={'lg'} id={Common.generateGuid()} enforceFocus={false}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>
                    {tab === TAB_CONFIG.DATE && "Ngày áp dụng"}
                    {tab === TAB_CONFIG.OPTION && "Chi tiết giải thưởng"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor:'#f3f3f3'}}>
                <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                    <li className="nav-item">
                        <a className={tab === TAB_CONFIG.OPTION ? "nav-link active" : "nav-link btn-light"} onClick={() => {
                            setTab(TAB_CONFIG.OPTION)
                        }}>
                            <span>Giải thưởng</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={tab === TAB_CONFIG.DATE ? "nav-link active" : "nav-link btn-light"} onClick={() =>{
                            setGiftId(gift.id)
                            setTab(TAB_CONFIG.DATE)
                        }}>
                            <span>Ngày áp dụng</span>
                        </a>
                    </li>
                </ul>
                <ModalCreateUpdateGiftStyle 
                    className={`partner_modal `} >
                    
                    {tab === TAB_CONFIG.OPTION &&
                    <div className="partner_modal_body">
                        <div className="partner_modal_body_gift">
                        <span>
                            <label>Số lượng quà tối đa 1 user nhận</label>
                            <input type="number" min={0} max={59}  placeholder="00" value={giftItem.maxReceive} 
                                onChange={(e) =>{
                                    setGiftItem({...giftItem, maxReceive: e.target.value})
                                }}
                                onFocus={(e)=>{
                                    e.target.select()
                                }}
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={()=>{
                                    updateGift(giftItem)
                                }}
                            />
                        </span>
                       <span>
                        <label>Trong khoảng thời gian</label>
                        <TFUSelect isClearable={false} value={giftItem.effectiveTime} options={EFFECT_TIME} onChanged={(value)=>{
                            updateGift({...giftItem, effectiveTime: value})
                        }} />
                       </span>
                        </div>


                        <div className="partner_modal_body-config">
                            <h5>Ngày mặc định ({configDefault.length})</h5>
                            <div className="partner_modal_body-config-list">
                                {
                                    configDefault && configDefault.length > 0 ? configDefault.map((item, idx)=>{
                                        return  <GiftConfigItem 
                                            idx={idx} 
                                            item={item}
                                            configDefault={configDefault}
                                            setConfigDefault={setConfigDefault}
                                            handleDeleteConfig={()=>{
                                                handleDeleteConfig(item.id)
                                            }}
                                            updateItem={()=>{
                                                updateItemConfig(item)
                                            }}
                                            handleAddConfigLast={handleAddConfigLast}
                                            isLastItem={idx === configDefault.length - 1}
                                        />
                                    }) : 
                                    <div className="add-config"
                                        onClick={()=>{
                                            handleAddConfig()
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="12" fill="#DFFFE0"/>
                                        <path d="M12 7.5V16.5" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                                        <path d="M16.5 12L7.5 12" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                                        </svg>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="partner_modal_body-config">
                            <div className="d-flex" style={{alignItems: 'center'}}>
                                <h5>Ngày đặc biệt ({specialDay.length})</h5>
                                <div className="modal-gift-config-add"
                                    onClick={()=>{
                                        handleAddSpecialDay()
                                    }}
                                >
                                    Thêm ngày đặc biệt +
                                </div>
                            </div>
                            {specialDay.length > 0 && specialDay.map((itm , i)=>{
                                return <div key={i} className="partner_modal_body-config-special">
                                    <div className="partner_modal_body-config-special-title">
                                        <div className="partner_modal_body-config-special-count">
                                            {specialDay.length - i}
                                        </div>
                                        <div className="partner_modal_body-config-special-name">
                                            {itm.isUpdate ?
                                            <input type="text" value={itm.name} placeholder="Nhập tên" 
                                                maxLength={40}
                                            onChange={(e)=>{
                                                let data = [...specialDay]
                                                data[i].name = e.target.value
                                                setSpecialDay(data)
                                            }} />
                                            :
                                            itm.name 
                                        } ({itm.configTimes.length})
                                        </div>
                                        <div className="partner_modal_body-config-special-icon"
                                            onClick={()=>{
                                                handleCreateUpdateSpecialDay(itm , i)
                                               
                                            }}
                                        
                                        >   {itm.isUpdate ? "Lưu" :
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.1767 5.79974L7.02325 14.9648L1 17L3.03259 10.9691L12.2064 1.82435C12.4669 1.56303 12.7763 1.35571 13.117 1.21426C13.4576 1.07281 13.8228 1 14.1915 1C14.5603 1 14.9254 1.07281 15.2661 1.21426C15.6067 1.35571 15.9162 1.56303 16.1767 1.82435C16.4377 2.08522 16.6447 2.39505 16.786 2.73612C16.9273 3.07719 17 3.44281 17 3.81205C17 4.18129 16.9273 4.5469 16.786 4.88797C16.6447 5.22904 16.4377 5.53887 16.1767 5.79974V5.79974Z" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M10.4131 3.59375L14.1269 7.52139" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>}
                                        </div>
                                        <div className="partner_modal_body-config-special-icon"
                                             onClick={()=>{
                                                handleDeleteSpecialDay(itm.id, i)
                                            }}
                                        >
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 4H3.33333H14" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="partner_modal_body-config-list">
                                        {
                                            itm.configTimes.length > 0 ? itm.configTimes.map((item, idx)=>{
                                                return  <ConfigTimeSpecialDayItem 
                                                    idx={idx} 
                                                    item={item}
                                                    indexSpec={i}
                                                    specialDay={specialDay}
                                                    setSpecialDay={setSpecialDay}
                                                    handleDeleteConfig={()=>{
                                                        handleDeleteSpecialDayConfig(item.id)
                                                    }}
                                                    updateItem={()=>{
                                                        updateItemSpecialDayConfig(item)
                                                    }}
                                                    handleAddConfigLast={handleAddConfigSpecialDay}
                                                    isLastItem={idx === itm.configTimes.length - 1}
                                                />
                                            }) : 
                                            <div className="add-config"
                                                onClick={()=>{
                                                    handleAddConfigSpecialDayFirst(itm.id)
                                                }}
                                            
                                            >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#DFFFE0"/>
                                                <path d="M12 7.5V16.5" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                                                <path d="M16.5 12L7.5 12" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                                                </svg>

                                            </div>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>}
                    {tab === TAB_CONFIG.DATE &&
                        <DateConfig campaignId={campaignId} isModal={isModal} specialDay={specialDay} giftId={giftId} />
                    }
                </ModalCreateUpdateGiftStyle>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <div className="box-button" style={{ display: "flex", justifyContent: 'center' }}>
                        <button className="btn btn-danger" onClick={()=>{onClose()}}>Đóng</button>
                        <button className="btn btn-custom"
                            onClick={()=>{
                                if(tab === TAB_CONFIG.OPTION){
                                    setGiftId(gift.id)
                                    setTab(TAB_CONFIG.DATE)
                                    
                                }
                                if(tab === TAB_CONFIG.DATE){
                                    onClose()
                                }
                            }}
                        >{tab === TAB_CONFIG.DATE ? "Xong" : "Tiếp theo"}</button> 
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
       

    );
};

export default ModalConfigTime;
const DateConfig = ({giftId , isModal , specialDay, campaignId}) => {
    const [day, setDay] = useState([])
    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [year, setYear] = useState(new Date().getFullYear())
    const [campaign, setCampaign] = useState({})
    const [isModalApply, setIsModalApply] = useState({
        isShow: false,
        date: null,
        specialApply: {},
    })

    useEffect(()=>{
        if(giftId > 0)
            getMonthAndSpecialDayByGiftId(month , year)
    },[])

    useEffect(()=>{
        if(!isModal.isShow){
            getMonthAndSpecialDayByGiftId(month , year)
        }
    },[isModal])
    
    useEffect(()=>{
        getCampaign()
    },[])

    const getCampaign = async () => {
        let [err, data] = await MarketingServices.GetCampaignById(campaignId)
        if(!err && data){
            setCampaign(data)
        }else{
            setCampaign({})
        }
    }


    const getMonthAndSpecialDayByGiftId = async (month , year)  => {
        let [err , dt ] = await MarketingServices.GetMonthAndSpecialDayByGiftId(month , year , giftId);
        if(!err && dt){
            setDay(dt)
        }else{
            setDay([])
        }
        
    }
   

    const compareDate = async (day , month , year) => {
        let date = new Date(year+"-"+month+"-"+day);
        let startDate = new Date(campaign.startDate)
        let endDate = new Date(campaign.endDate)
        return startDate <= date && date <= endDate
          
    }

    return <div className="partner_modal_body">
            {isModalApply && <ModalChangeSpecialDay
                giftId={giftId}
                dataApply={isModalApply.specialApply}
                date={isModalApply.date}
                isShow={isModalApply.isShow} 
                onClose={()=>{
                    setIsModalApply({
                        isShow: false,
                        date: null,
                        specialApply: {},
                    });
                    getMonthAndSpecialDayByGiftId(month , year)
                }}
            />}
        <div className="box-month-year">
            <div className="month-show">
                <span onClick={()=>{
                    let m = month - 1 > 0 ? month - 1 : 12
                    let y = month - 1 > 0 ? year : year - 1
                    setMonth(m)
                    setYear(y)
                    getMonthAndSpecialDayByGiftId(m, y)
                }}>
                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 2L2 10L9 18" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
                <span>{month}/{year}</span>
                <span onClick={()=>{
                    let m = month + 1 <= 12 ? month + 1 : 1
                    let y = month + 1 <= 12 ? year : year + 1
                    setMonth(m)
                    setYear(y)
                    getMonthAndSpecialDayByGiftId(m,y)
                }}>
                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 18L9 10L2 2" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
        </div>
        <div className="table-date">
            <div className="row-date-header">
                <div className="item-date-header">T2</div>
                <div className="item-date-header">T3</div>
                <div className="item-date-header">T4</div>
                <div className="item-date-header">T5</div>
                <div className="item-date-header">T6</div>
                <div className="item-date-header">T7</div>
                <div className="item-date-header">CN</div>
            </div>
            <div className="row-date-body">
                {day && day.length > 0 && day.map((item , idx)=>{
                    return <div key={idx}
                     className={`item-date ${item.month != month ? "faded" :  
                     new Date(campaign.startDate) <= new Date(item.year+"-"+item.month+"-"+item.day) && new Date(item.year+"-"+item.month+"-"+item.day) <= new Date(campaign.endDate) ? "active" : ""}`}
                        onClick={async()=>{
                            if(item.month === month && await compareDate(item.day , item.month , item.year))
                            {
                                setIsModalApply({
                                    isShow: true,
                                    date: new Date(item.year+"-"+item.month+"-"+item.day),
                                    specialApply: item.specialApply
                                });
                            }
                        }}
                    >
                        <span>{item.day}</span>
                        {item.specialApply && <strong>{specialDay.length - specialDay.findIndex(x => x.id == item.specialApply.specialDayId)}</strong>}
                    </div>
                })}
            </div> 
        </div>
    </div>
}

const ConfigTimeSpecialDayItem = ({idx , item , indexSpec,
    handleAddConfigLast , 
    handleDeleteConfig , 
    specialDay,
    setSpecialDay,
    updateItem,
    isLastItem}) => {
        const input1 = useRef(null)
        const input2 = useRef(null)
        const input3 = useRef(null)
        const input4 = useRef(null)

    return <div key={idx} className="partner_modal_body-config-col">
        <div className="partner_modal_body-config-item">
            <div className="partner_modal_body-config-item-title">
                <h6>Khung giờ {idx + 1}</h6>
                <span
                    onClick={()=>{
                        handleDeleteConfig()
                    }}
                
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4H3.33333H14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
            <div className="partner_modal_body-config-item-container">
                <div className="partner_modal_body-config-item-box time">
                    <div className="time-item">
                        <input type="number" ref={input1} min={0} max={23} placeholder="00" value={item.startTime.split(":")[0]} 
                                onChange={(e) =>{
                                    let data = [...specialDay]
                                    data[indexSpec].configTimes[idx].startTime = e.target.value + ":" + item.startTime.split(":")[1]
                                    setSpecialDay(data)
                                    if(e.target.value > 9 && e.target.value < 24){
                                        input2.current.focus()
                                    }
                                }} 
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onFocus={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={updateItem}
                                />
                        <span>:</span>
                        <input type="number" min={0} max={59} ref={input2} placeholder="00" value={item.startTime.split(":")[1]} 
                            onChange={(e) =>{
                                let data = [...specialDay]
                                data[indexSpec].configTimes[idx].startTime = item.startTime.split(":")[0] + ":" + e.target.value
                                setSpecialDay(data)
                                if(e.target.value > 9 && e.target.value < 60){
                                    input3.current.focus()
                                }
                            }}
                            onClick={(e)=>{
                                e.target.select()
                            }}
                            onFocus={(e)=>{
                                e.target.select()
                            }}
                            onBlur={updateItem}
                        />
                    </div>
                    <span>-</span>
                    <div className="time-item">
                        <input  type="number" min={0} max={23} ref={input3} placeholder="00" value={item.endTime.split(":")[0]}
                        onChange={(e) =>{
                            let data = [...specialDay]
                            data[indexSpec].configTimes[idx].endTime = e.target.value + ":" + item.endTime.split(":")[1]
                            setSpecialDay(data)
                            if(e.target.value > 9 && e.target.value < 24){
                                input4.current.focus()
                            }
                        }} 
                        onFocus={(e)=>{
                            e.target.select()
                        }}
                        
                        onClick={(e)=>{
                            e.target.select()
                        }}
                        onBlur={updateItem}
                        
                        />
                        <span>:</span>
                        <input  type="number" min={0} max={59} ref={input4} placeholder="00" value={item.endTime.split(":")[1]} 
                            onChange={(e) =>{
                                let data = [...specialDay]
                                data[indexSpec].configTimes[idx].endTime = item.endTime.split(":")[0] + ":" + e.target.value
                                setSpecialDay(data)
                            }}
                            onClick={(e)=>{
                                e.target.select()
                            }}
                            onFocus={(e)=>{
                                e.target.select()
                            }}
                            onBlur={updateItem}
                        />
                    </div>
                </div>
                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Tỷ lệ trúng</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} max={100} placeholder="00" value={item.rateWin} 
                                onChange={(e) =>{
                                    let data = [...specialDay]
                                    data[indexSpec].configTimes[idx].rateWin = e.target.value
                                    setSpecialDay(data)
                                }}
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={updateItem}
                                />
                            <span>%</span>
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Lượng quà</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} placeholder="00" value={item.quantity} 
                                onChange={(e) =>{
                                    let data = [...specialDay]
                                    data[indexSpec].configTimes[idx].quantity = e.target.value
                                    setSpecialDay(data)
                                    
                                }}
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={updateItem}
                                />
                            <span>cái</span>
                        </div>
                    </div>
                </div>  

                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Hạn sử dụng</span>
                    </div>
                    <div className="partner_modal_body-config-item-box-item"
                        
                    >
                        <span>
                            <i></i>
                            <svg 
                                onClick={()=>{
                                    if(item.minuteExpiry != null){
                                        let data = [...specialDay]
                                        data[indexSpec].configTimes[idx].timeStartUsing = 0
                                        data[indexSpec].configTimes[idx].timeEndUsing = 0
                                        data[indexSpec].configTimes[idx].minuteExpiry = null
                                        setSpecialDay(data)
                                    }else{
                                        let data = [...specialDay]
                                        data[indexSpec].configTimes[idx].timeStartUsing = null
                                        data[indexSpec].configTimes[idx].timeEndUsing = null
                                        data[indexSpec].configTimes[idx].minuteExpiry = 0
                                        setSpecialDay(data)
                                    }
                                    
                                }}
                            
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M11.1816 1L13.7276 3.54592L11.1816 6.09183" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.27148 7.36184V6.08889C2.27148 5.41367 2.53971 4.7661 3.01717 4.28865C3.49462 3.8112 4.14218 3.54297 4.8174 3.54297H13.7281" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.8174 15.002L2.27148 12.4561L4.8174 9.91016" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.7281 8.63867V9.91163C13.7281 10.5868 13.4599 11.2344 12.9824 11.7119C12.505 12.1893 11.8574 12.4575 11.1822 12.4575H2.27148" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                </div>  
                <div className="partner_modal_body-config-item-box">
                    {item.minuteExpiry != null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Hạn dùng</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.minuteExpiry} 
                                    onChange={(e) =>{
                                        let data = [...specialDay]
                                        data[indexSpec].configTimes[idx].minuteExpiry = e.target.value
                                        setSpecialDay(data)
                                    }} 
                                    onClick={(e)=>{
                                        e.target.select()
                                    }}
                                    onBlur={updateItem}
                                    
                                    />
                                <span>Phút</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Dùng sau</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0}  placeholder="0" value={item.timeStartUsing} 
                                    onChange={(e) =>{
                                        let data = [...specialDay]
                                       data[indexSpec].configTimes[idx].timeStartUsing = e.target.value
                                        setSpecialDay(data)
                                    }} 
                                    onClick={(e)=>{
                                        e.target.select()
                                    }}
                                    onBlur={updateItem}
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>
                                <div>Hạn dùng</div>
                                <div className="modal-button-detail">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_8397_2569)">
                                        <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_8397_2569">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.timeEndUsing} 
                                    onChange={(e) =>{
                                        let data = [...specialDay]
                                        data[indexSpec].configTimes[idx].timeEndUsing = e.target.value
                                        setSpecialDay(data)
                                    }}
                                    onClick={(e)=>{
                                        e.target.select()
                                    }}
                                    onBlur={updateItem}
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>
                    }
                </div>  
            </div>
            <div className="add-first"
                onClick={()=>{
                    if(idx > 0)
                        handleAddConfigLast(specialDay[indexSpec].configTimes[idx - 1].endTime,item.startTime, specialDay[indexSpec].id)
                    else
                        handleAddConfigLast("00:00",item.startTime, specialDay[indexSpec].id)
                }}
                    
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#DFFFE0"/>
                <path d="M12 7.5V16.5" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                <path d="M16.5 12L7.5 12" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                </svg>

            </div>
            {isLastItem && <div className="add-last"
                onClick={()=>{
                    handleAddConfigLast(item.endTime,"23:59", specialDay[indexSpec].id)
                }}
            >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#DFFFE0"/>
                <path d="M12 7.5V16.5" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                <path d="M16.5 12L7.5 12" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                </svg>

            </div>}
        </div>
        
    </div>
}


const GiftConfigItem = ({idx , item , 
    handleAddConfigLast , 
    handleDeleteConfig , 
    configDefault,
    setConfigDefault,
    updateItem,
    isLastItem}) => {
    
    const input1 = useRef(null)
    const input2 = useRef(null)
    const input3 = useRef(null)
    const input4 = useRef(null)
    return <div key={idx} className="partner_modal_body-config-col">
        <div className="partner_modal_body-config-item">
            <div className="partner_modal_body-config-item-title">
                <h6>Khung giờ {idx + 1}</h6>
                <span
                    onClick={()=>{
                        handleDeleteConfig()
                    }}
                
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4H3.33333H14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
            <div className="partner_modal_body-config-item-container">
                <div className="partner_modal_body-config-item-box time">
                    <div className="time-item">
                        <input type="number" min={0} ref={input1} max={23} placeholder="00" value={item.startTime.split(":")[0]} 
                                onChange={(e) =>{
                                    let data = [...configDefault]
                                    data[idx].startTime = e.target.value + ":" + item.startTime.split(":")[1]
                                    setConfigDefault(data)
                                    if(e.target.value > 9 && e.target.value < 24){
                                        input2.current.focus()
                                    }
                                }} 
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onFocus={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={updateItem}
                                />
                        <span>:</span>
                        <input type="number" min={0} max={59} ref={input2} placeholder="00" value={item.startTime.split(":")[1]} 
                            onChange={(e) =>{
                                let data = [...configDefault]
                                data[idx].startTime = item.startTime.split(":")[0] + ":" + e.target.value
                                setConfigDefault(data)
                                if(e.target.value > 9 && e.target.value < 60){
                                    input3.current.focus()
                                }
                            }}
                            onFocus={(e)=>{
                                e.target.select()
                            }}
                            onClick={(e)=>{
                                e.target.select()
                            }}
                            onBlur={updateItem}
                        />
                    </div>
                    <span>-</span>
                    <div className="time-item">
                        <input  type="number" min={0} ref={input3} max={23} placeholder="00" value={item.endTime.split(":")[0]}
                        onChange={(e) =>{
                            let data = [...configDefault]
                            data[idx].endTime = e.target.value + ":" + item.endTime.split(":")[1]
                            setConfigDefault(data)
                            if(e.target.value > 9 && e.target.value < 24){
                                input4.current.focus()
                            }
                        }}  onClick={(e)=>{
                            e.target.select()
                        }}
                        onFocus={(e)=>{
                            e.target.select()
                        }}
                        onBlur={updateItem}
                        
                        />
                        <span>:</span>
                        <input  type="number" min={0} max={59} ref={input4} placeholder="00" value={item.endTime.split(":")[1]} 
                            onChange={(e) =>{
                                let data = [...configDefault]
                                data[idx].endTime = item.endTime.split(":")[0] + ":" + e.target.value
                                setConfigDefault(data)

                            }}
                            onFocus={(e)=>{
                                e.target.select()
                            }}
                            onClick={(e)=>{
                                e.target.select()
                            }}
                            onBlur={updateItem}
                        />
                    </div>
                </div>
                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Tỷ lệ trúng</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} max={100} placeholder="00" value={item.rateWin} 
                                onChange={(e) =>{
                                    let data = [...configDefault]
                                    data[idx].rateWin = e.target.value
                                    setConfigDefault(data)
                                }}
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={updateItem}
                                />
                            <span>%</span>
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Lượng quà</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} placeholder="00" value={item.quantity} 
                                onChange={(e) =>{
                                    let data = [...configDefault]
                                    data[idx].quantity = e.target.value
                                    setConfigDefault(data)
                                    
                                }}
                                onClick={(e)=>{
                                    e.target.select()
                                }}
                                onBlur={updateItem}
                                />
                            <span>cái</span>
                        </div>
                    </div>
                </div>  

                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Hạn sử dụng</span>
                    </div>
                    <div className="partner_modal_body-config-item-box-item"
                        
                    >
                        <span>
                            <i></i>
                            <svg 
                                onClick={()=>{
                                    if(item.minuteExpiry != null){
                                        let data = [...configDefault]
                                        data[idx].timeStartUsing = 0
                                        data[idx].timeEndUsing = 0
                                        data[idx].minuteExpiry = null
                                        setConfigDefault(data)
                                    }else{
                                        let data = [...configDefault]
                                        data[idx].timeStartUsing = null
                                        data[idx].timeEndUsing = null
                                        data[idx].minuteExpiry = 0
                                        setConfigDefault(data)
                                    }
                                    
                                }}
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M11.1816 1L13.7276 3.54592L11.1816 6.09183" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.27148 7.36184V6.08889C2.27148 5.41367 2.53971 4.7661 3.01717 4.28865C3.49462 3.8112 4.14218 3.54297 4.8174 3.54297H13.7281" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.8174 15.002L2.27148 12.4561L4.8174 9.91016" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.7281 8.63867V9.91163C13.7281 10.5868 13.4599 11.2344 12.9824 11.7119C12.505 12.1893 11.8574 12.4575 11.1822 12.4575H2.27148" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                            
                        </span>
                    </div>
                </div>  
                <div className="partner_modal_body-config-item-box">
                    {item.minuteExpiry != null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Hạn dùng</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.minuteExpiry} 
                                    onChange={(e) =>{
                                        let data = [...configDefault]
                                        data[idx].minuteExpiry = e.target.value
                                        setConfigDefault(data)
                                    }} 
                                    onClick={(e)=>{
                                        e.target.select()
                                    }}
                                    onBlur={updateItem}
                                    
                                    />
                                <span>Phút</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Dùng sau</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0}  placeholder="0" value={item.timeStartUsing} 
                                    onChange={(e) =>{
                                        let data = [...configDefault]
                                        data[idx].timeStartUsing = e.target.value
                                        setConfigDefault(data)
                                    }} 
                                    onClick={(e)=>{
                                        e.target.select()
                                    }}
                                    onBlur={updateItem}
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>
                                <div>Hạn dùng</div>
                                <div className="modal-button-detail">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_8397_2569)">
                                        <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_8397_2569">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.timeEndUsing} 
                                    onChange={(e) =>{
                                        let data = [...configDefault]
                                        data[idx].timeEndUsing = e.target.value
                                        setConfigDefault(data)
                                    }}
                                    onClick={(e)=>{
                                        e.target.select()
                                    }}
                                    onBlur={updateItem}
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>
                    }
                </div>  
            </div>
            <div className="add-first"
                onClick={()=>{
                    if(idx > 0)
                        handleAddConfigLast(configDefault[idx - 1].endTime,item.startTime)
                    else
                        handleAddConfigLast("00:00",item.startTime)
                }}
                    
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#DFFFE0"/>
                <path d="M12 7.5V16.5" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                <path d="M16.5 12L7.5 12" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                </svg>

            </div>
            {isLastItem && <div className="add-last"
                onClick={()=>{
                    handleAddConfigLast(item.endTime,"23:59")
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#DFFFE0"/>
                <path d="M12 7.5V16.5" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                <path d="M16.5 12L7.5 12" stroke="#64C5B1" stroke-width="3" stroke-linecap="round"/>
                </svg>

            </div>}
        </div>
        
    </div>
}

const ModalCreateUpdateGiftStyle = styled.div`
    width: 100%;
    bottom: 0;
    border-radius: 16px 16px 0 0 !important;
    position: relative;
    background-color: #f3f3f3 !important;
    font-family: 'Lexend';


    .modal-gift-config-add{
        color: #64C5B1;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 1; /* 142.857% */
        margin-left: 20px;
        cursor: pointer;
    }

    .partner_modal_body{
        

        .box-month-year{
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            .month-show{
                display: flex;
                width: 160px;
                margin-left: 10px;
                padding: 20px;
                align-items: center;
                justify-content: space-between;
                height: 38px;
                border-radius: 10px;
                background: #fff;
    
                span{
                    color: #444;
                    font-family: Lexend;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    cursor: pointer;
                    line-height: normal;
                }
            }
        }

        .table-date{
            margin-top: 15px;
            border-radius: 10px;
            overflow: hidden;

            .row-date-header{
                display: flex;
                
                .item-date-header{
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    height: 45px;
                    color: #FFF;
                    text-align: center;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 45px;
                    background:  #7A7A7A;
                    
                }
            }

            .row-date-body{
                display: flex;
                flex-wrap: wrap;

                .item-date{
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    cursor: pointer;
                    height: 80px;
                    padding: 12px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #EDEDED;
                    background: #FFF;

                    &.active{
                        border: 1px solid #EDEDED;
                        background: #C2F2D3;
                    }

                    &.faded{
                        span{
                            color: #D8D8D8;
                        }
                        strong{
                            display: none;
                        }
                    }
                    span{
                        color: #666;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    strong{
                        display: block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: #64C5B1;
                        color: #FFF;
                        text-align: center;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }
        }


        .partner_modal_body_gift{
            display: flex;
            margin-top: 20px;

            span{
                width: 30%;
                label{
                    display: block;
                }

                input[type="number"]{
                    width: calc(100% - 20px);
                    border-radius: 10px;
                    border: 1px solid #FFF;
                    height: 38px;
                    padding: 8px;

                    ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
        }




        .partner_modal_body-config{
            margin-top: 12px;


            .partner_modal_body-config-special{
                margin-top: 20px;

                .partner_modal_body-config-special-title{
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    
                    .partner_modal_body-config-special-count{
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #64C5B1;
                        color: #FFF;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 15px;
                    }
                    .partner_modal_body-config-special-name{
                        margin: 0 10px;
                        color: #64C5B1;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                        height: 32px;


                        input{
                            border-radius: 5px;
                            background: #FFF;
                            height: 32px;
                            border: none;
                            outline: none;
                            width: 250px;
                            padding: 0 5px;

                            ::placeholder{
                                color: #505050;
                                font-family: Lexend;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                    .partner_modal_body-config-special-icon{
                        color: #64C5B1;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                        margin-right: 10px;
                    }
                }
            }

            h5{
                color: #505050;
                font-family: Lexend;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 12px;
            }


            .partner_modal_body-config-list{
                display: flex;
                flex-wrap: wrap;

                .add-config{
                    margin: 50px 0;
                }

                .partner_modal_body-config-col{
                    width: 205px;
                    min-width: 205px;
                    max-width: 205px;
                    text-align: center;
                    display: flex;
                    position: relative;
                    justify-content: end;
                    margin-bottom: 10px;


                    input[type="number"]{
                        ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                  

                    .partner_modal_body-config-item{
                        width: 180px;
                        position: relative;

                        .add-first{
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translate(-100%, -50%);
                            cursor: pointer;
                        }
                        .add-last{
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(100%, -50%);
                            cursor: pointer;
                        }
                        
                        
                        
                        .partner_modal_body-config-item-title{
                            height: 38px;
                            border-radius: 10px 10px 0px 0px;
                            background: #7A7A7A;
                            padding: 0 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            h6{
                                color: #FFF;
                                font-family: Lexend;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1;
                                margin: 0;
                            }
    
                        }

                        .partner_modal_body-config-item-container{
                            background: #fff;
                            border-radius: 0 0 10px 10px;
                            padding: 8px;

                            .partner_modal_body-config-item-box{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 10px;

                                input{
                                    ::placeholder{
                                        opacity: 0.2;
                                    }
                                }

                                &.time{
                                    border-radius: 10px;
                                    border: 1px solid #FFF;
                                    background: #EDEDED;
                                    height: 38px;
                                    padding: 8px;

                                    span{
                                        color: #BDBDBD;
                                        font-size: 20px;
                                    }

                                    .time-item{
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 56px;

                                        
                                        input{
                                            width: 48%;
                                            border: none;
                                            outline: none;
                                            background-color: #EDEDED;
                                            text-align: center;
                                            

                                            ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                                                -webkit-appearance: none;
                                                margin: 0;
                                            }
                                        }
                                    }

                                }


                                .partner_modal_body-config-item-box-item{
                                    width: calc(50% - 5px);

                                    span{
                                        display: flex;
                                        justify-content: space-between;
                                        color: #505050;
                                        font-family: Lexend;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 1;
                                        margin-bottom: 5px;
                                    }
                                    .config-item-box-item-input{
                                        width: 100%;
                                        position: relative;

                                        input{
                                            width: 100%;
                                            height: 32px;
                                            border-radius: 10px;
                                            border: 1px solid #FFF;
                                            background: #EDEDED;
                                            color: #505050;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 1;
                                            padding: 0 37px 0 8px;
                                            border: none;
                                            outline: none;
                                        }
                                        span{
                                            position: absolute;
                                            top: 50%;
                                            right: 6px;
                                            transform: translateY(-50%);
                                        }
                                    }
                                }
                            }

                        }

                        
                    }
                }

                

            }
        }

        .modal-button-detail{
            position: relative;

            .customise-tooltip{
                width: 232px;
                text-align: left;
                .customise-tooltip-content{
                    line-height: 1.2;
                }
            }

            :hover{
                .customise-tooltip{
                    display: block;
                }
            }
        }
        .partner_modal_body-box{
            margin-top: 12px;
            border-radius: 10px;
            background: #FFF;
            padding: 12px;

            .partner_modal_body-box-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    color: #505050;
                    font-family: Lexend;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1;
                }

                h5{
                    color: #505050;
                    font-size: 12px;
                    font-style: normal;
                    line-height: normal;
                }
    
            }

           
            .input-name-gift{
                width: 100%;
                border-radius: 10px;
                border: 1px solid #E1E1E1;
                background: #FFF;
                color: #505050;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                padding: 0 12px;
                height: 42px;

            }

        }
    }

    .box-footer-modal{
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;
        height: 100px;

        

        .partner_modal_tab{
            height: 40px;
            display: flex;


            .partner_modal_tab-item{
                height: 40px;
                width: 50%;
                background: #F8F8F8;
                color: #7A7A7A;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                text-align: center;
                position: relative;

                :first-child{
                    border-radius: 0 0 10px 0;
                }
                :last-child{
                    border-radius: 0 0 0 10px;
                }

                .partner_modal_tab-item-line{
                    display: none;
                }

                &.active{
                    background-color: #fff;
                    color: #64C5B1;

                    .partner_modal_tab-item-line{
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 4px;
                        background: #64C5B1;
                        left: 0;
                        z-index: 5;
                    }
                    
                }
            }
        }

        .partner_modal_body-comfirm-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            padding: 0 16px;
            z-index: 1;
            background-color: #fff;
    
            button{
                border: none;
                outline: none;
                border-radius: 10px;
                width: 125px;
                height: 38px;
                color: #FFF;
                font-size: 14px;
                font-weight: 600;
                line-height: 38px;
                text-align: center;
                border: 2px solid #f4f4f4;
                color: #64C5B1;
                background-color: #fff;
                line-height: 1;
                margin: 0 5px;
               
    
                &.accept{
                    background: #64C5B1;
                    color: #fff;
                }
            }
    
        }
    }
    
    


    

    
`
ModalConfigTime.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
