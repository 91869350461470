import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Loading from "../based/Loading";
import CommonServices from "../based/services/CommonServices";
import { Layout } from "../layout/Layout";
import HandleLogo from "./HandleLogo";

function ProcessLogoFile(props) {
    const [creatorOptions, setCreatorOptions] = useState([]);
    const [isBusy, setBusy] = useState(false);
    const [msgBusy, setMsgBusy] = useState("");

    useEffect(() => {
        getCreators();
    }, []);

    async function getCreators() {
        setBusy(true);
        setMsgBusy("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setBusy(false);
            setMsgBusy(null);
        } else {
            setCreatorOptions([]);
            setBusy(false);
            setMsgBusy(null);
        }
    }
    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msgBusy} />
            <Breadcrumbs currentPage=' Xử lý file Logo' />
            <HandleLogo creatorOptions={creatorOptions} />;
        </React.Fragment>
    );
}

ProcessLogoFile.propTypes = {};

export default ProcessLogoFile;
