import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Loading from "../based/Loading";
import ManyShareBanner from "./ManyShareBanner";
import ManyShareLink from "./ManyShareLink";




function ManyShareManage() {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
   
    return (
        <MarketingWapper>
            <Breadcrumbs currentPage={"Quản lý partner"} />
            <Loading show={isBusy} msg={msg} />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
            <li className="nav-item">
                <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)}>
                    <span>Quản lý link chia sẽ</span>
                </a>
            </li>
            <li className="nav-item">
                <a className={tabIndex == 1 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(1)}>
                    <span>Quản lý banner</span>
                </a>
            </li>
        </ul>
        {tabIndex === 0 && <ManyShareLink />}
        {tabIndex === 1 && <ManyShareBanner  />}
            
        </MarketingWapper>
    )
}



const MarketingWapper = styled.div`

    
`;
export default ManyShareManage;
