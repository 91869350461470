import React, { useEffect, useState } from "react";
import { NOTIFY, PLATFORM, PLATFORM_NAME, STATUS_ACTIVE } from "../based/Constants";
import Radio from "../based/inputs/Radio";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import PlatformServices from "../based/services/PlatformServices";
import ProductServices from "../based/services/ProductServices";

const PLATFORM_PUSH = [
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
];

function PushListProductModal(props) {
    const { isShow, onClose, ids } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [shopOptions, setShopOptions] = useState([]);
    const [data, setData] = useState({ shopInfo: { platform: PLATFORM.Lazada, shopId: "" } });

    useEffect(() => {
        getShops([PLATFORM.Lazada, PLATFORM.Shopee]);
    }, []);

    async function getShops(platforms) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu..");
        const [err, stores] = await PlatformServices.PlatformStoresUser(platforms, STATUS_ACTIVE.All);
        if (!err) {
            let currentShopOptions = [];
            if (stores && stores.length > 0) currentShopOptions = stores.filter((x) => x.platform == PLATFORM.Lazada);
            else Notify(NOTIFY.WARNING, "Thông báo", "Không tìm thấy gian hàng hoặc chưa được kết nối đến sàn.");
            setShopOptions(stores);
            setIsLoading(false);
            setMsg("");
        } else {
            setShopOptions([]);
            setIsLoading(false);
            setMsg("");
        }
        console.log("stores", stores);
    }

    async function setShopInfo(name, value) {
        let dataUp = { ...data };
        dataUp.shopInfo[name] = value;
        if (name == "platform") {
            let currentShopOptions = [];
            let catId = 0;
            if (shopOptions && shopOptions.length > 0) currentShopOptions = shopOptions.filter((x) => x.platform == value);
            if (currentShopOptions.length > 0) {
                dataUp.shopInfo.shopId = currentShopOptions[0].shopId;
                catId = currentShopOptions[0].opfCategoryId;
            }
        }
        setData(dataUp);
    }

    async function _handleSave() {
        let { shopInfo } = data;
        if (!shopInfo) {
            Notify(NOTIFY.INFO, NOTIFY.INFO, "Xin vui lòng chọn cửa hàng");
            return;
        }
        let model = {
            pushShopInfo: { platform: shopInfo.platform, shopid: shopInfo.shopId },
            ids: [...ids],
        };
        let [err, datas] = await ProductServices.AddListProductToQueuePush(model);
        if (!err && datas) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thêm vào hàng chờ thành công");
            onClose();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thêm vào hàng chờ thất bại");
        }
    }

    function _renderBoxContent() {
        let currentShopOptions = [];
        if (shopOptions && shopOptions.length > 0) currentShopOptions = shopOptions.filter((x) => x.platform == data.shopInfo.platform);
        return (
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Thông tin sàn</b>
                </h4>
                <p className="sub-header">Thiết lập thông tin sàn thương mại điện tử để đẩy sản phẩm lên</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group ">
                            <label htmlFor="platform" className="col-form-label required">
                                Đẩy lên sàn
                            </label>
                            <div className="form-row">
                                {PLATFORM_PUSH.map((item, k) => {
                                    return (
                                        <div className="col-md-3" key={k}>
                                            <Radio id={`${k}`} name="platform" isInline checked={data.shopInfo.platform == item.value} value={item.value} onCheckedChange={(name, value) => setShopInfo(name, value)} label={item.label} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {currentShopOptions && currentShopOptions.length > 0 ? (
                            <div className="form-group ">
                                <label htmlFor="shopId" className="col-form-label required">
                                    Đẩy lên gian hàng
                                </label>
                                <div className="form-row">
                                    {currentShopOptions.map((shop, s) => {
                                        return (
                                            <div className="col-md-4" key={s}>
                                                <Radio name="shopId" id={`${s}`} isInline checked={data.shopInfo.shopId == shop.shopId} value={shop.shopId} onCheckedChange={(name, value) => setShopInfo(name, value)} label={shop.shopName} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <CommonModal title="Thông tin cửa hàng" show={isShow} size="tfumd" isBusy={isLoading} busyMsg={msg} onClose={() => onClose()} onSave={() => [_handleSave()]}>
            {_renderBoxContent()}
        </CommonModal>
    );
}

PushListProductModal.propTypes = {};

export default PushListProductModal;
