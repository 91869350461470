import React, { Component } from "react";
import { Link } from "react-router-dom";
import PubSub from "pubsub-js";
import { CONSTANTS } from "../based/Constants";
import { ApplicationContext } from "../context/ApplicationContext";

export default class LeftSideMenu extends Component {
    static contextType = ApplicationContext;
    constructor(props) {
        super(props);
        this.state = {
            mainMenu: "",
            subMenu: "",
            token: "",
            isActiveMnu: false,
        };
    }

    handleReceivedMsg = (msg, data) => {
        this.setState(data);
        var curentMenu = this.context.applicationInfo.menus.find((x) => x.functionCode === data.mainMenu);
        if (curentMenu && curentMenu.id) {
            localStorage.setItem("menuID", curentMenu.id);
        }
    };
    componentDidMount() {
        let token = PubSub.subscribe(CONSTANTS.SIDEBAR_MENU_TOPIC, this.handleReceivedMsg);
        this.setState({ token: token });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.state.token);
    }
    componentDidUpdate() {
        // let pathname = window.location.pathname;
        // if (!this.state.isActiveMnu && pathname) {
        //     var curentMenu = this.context.applicationInfo.menus.find((x) => x.navigateLink === pathname);
        //     if (curentMenu && document.getElementById(`mnu-${curentMenu.id}`)) {

        //         var topPos = document.getElementById(`mnu-${curentMenu.id}`).offsetTop;
        //         document.getElementById("remcove-scroll").scrollTop = topPos - 50;
        //         this.setState({ isActiveMnu: true });
        //     }
        // }
    }
    _renderMenu(appInfo) {
        let menus = appInfo.menus;
        if (menus && menus.length > 0) {
            let arrMnu = [];
            let localMenuID = localStorage.getItem("menuID");
            let pathname = window.location.pathname;
            let parents = menus.filter((x) => x.parentId == 0);
            if (parents && parents.length > 0) {
                parents.map((parent, p) => {
                    arrMnu.push(
                        <li className='menu-title' key={p}>
                            {parent.name}
                        </li>
                    );
                    let childs = menus.filter((x) => x.parentId == parent.id);
                    if (childs && childs.length > 0) {
                        childs.map((mnu, m) => {
                            arrMnu.push(
                                <li id={`mnu-${mnu.id}`} key={m} className='' onClick={() => localStorage.setItem("menuID", mnu.id)}>
                                    <Link to={mnu.navigateLink} aria-expanded='false' className={pathname && pathname == mnu.navigateLink && window.location.pathname != "/" ? "active" : ""}>
                                        <i className={mnu.icon ? mnu.icon : "fa fa-list"} />
                                        <span>{mnu.name}</span>
                                    </Link>
                                </li>
                            );
                        });
                    }
                });
            }
            return arrMnu.map((mnu, m) => <React.Fragment key={m}> {mnu} </React.Fragment>);
        }
    }

    render() {
        return (
            <div className='left side-menu'>
                <div className='slimscroll-menu scroll-100vh' id='remcove-scroll'>
                    <div id='sidebar-menu'>
                        <ul className='metismenu in' id='side-menu'>
                            {this._renderMenu(this.context.applicationInfo)}
                        </ul>
                    </div>
                    <div className='clearfix' />
                </div>
            </div>
        );
    }
}
