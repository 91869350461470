import React, { useState , useEffect} from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import StampManagement from "./StampManagement";
import ShipmentManagement from "./ShipmentManagement";


const SizeStampManagement = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [isBusy, setBusy] = useState(true);



   

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý tem size"  />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                <li className="nav-item">
                    <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)}>
                        <span>Quản lý tem</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 1 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(1)}>
                        <span>Tạo yêu cầu sản xuất </span>
                    </a>
                </li>
            </ul>
            {tabIndex == 0 && <StampManagement />}
            {tabIndex == 1 && <ShipmentManagement />}



        </React.Fragment>
    );
};

export default SizeStampManagement;
