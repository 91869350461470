import BaseServices from "./BaseServices";

const MainColorTypeServices = {
    CreateUpdateMainColor: async (model) => {
        return BaseServices.Post(`/api/MainColor/create-update-main-color`, model);
    },
    DeleteMainColor: async (id) => {
        return BaseServices.Get(`/api/MainColor/delete-main-color/${id}`);
    },
    GetMainColorByPage: async (model) => {
        return BaseServices.Post(`/api/MainColor/get-main-color-by-page`, model);
    },
};

export default MainColorTypeServices;
