import Request from "../Request";
import BaseServices from "./BaseServices";

const ProductServices = {
    GetProductsListing: async (paging) => {
        try {
            const res = await Request.Post("/api/Product/get-list-product", paging);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    GetProductsDropdown: async () => {
        try {
            const res = await Request.Get("/api/Product/get-dropdown-products");
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    GetProductDetails: async (id, platform) => {
        try {
            const res = await Request.Get("/api/Product/get-product-by-product-id/" + id);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    GetProductStatusDropdown: async () => {
        try {
            const res = await Request.Get("/api/Product/get-product-status-dropdown");
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    DeleteVariant: async (variantId) => {
        try {
            const res = await Request.Post("/api/Product/delete-variant", [variantId]);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    DeleteProducts: async (ids, platform) => {
        try {
            const res = await Request.Post(`/api/Product/delete/${platform}`, ids);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    DeletePlatformProducts: async (model, platform) => {
        try {
            const res = await Request.Post(`/api/agencyProducts/delete/${platform}`, model);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    GetAvaiDropProductsByWhId: async (warehouseId) => {
        try {
            const res = await Request.Get("/api/Product/get-avai-dropdown-products-by-warehouseid/" + warehouseId);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (error) {
            return [error, null];
        }
    },
    GetProductVariants: async (productId) => {
        return await BaseServices.Get("/api/Product/get-product-variants/" + productId);
    },
    GetAdvanceProductVariants: async (productId, platform) => {
        return await BaseServices.Get("/api/Product/get-advanced-product-variants/" + productId + "/" + platform);
    },
    GetProductVariantInfo: async (variantId) => {
        return await BaseServices.Get("/api/Product/get-product-variant-info/" + variantId);
    },
    GetProductAttributes: async (productId) => {
        return await BaseServices.Get("/api/Product/get-product-attributes/" + productId);
    },
    SearchProductsForFacebook: async (paging) => {
        try {
            const res = await Request.Post("/api/agencyProducts/search-products-forFacebook", paging);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    SaveChooseProductsFacebook: async (models) => {
        try {
            const res = await Request.Post("/api/agencyProducts/save-products-forFacebook", models);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    ProductsFacebookListingPage: async (paging) => {
        try {
            const res = await Request.Post("/api/agencyProducts/get-list-facebookproducts", paging);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    DeleteAgencyProductMapping: async (id) => {
        try {
            const res = await Request.Post(`/api/agencyProducts/delete-agency_productmapping/${id}`);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    //lấy danh sách sản phẩm của từng store trên sàn
    GetPlatformProducts: async (paging) => {
        try {
            const res = await Request.Post("/api/agencyProducts/get-products-store", paging);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    //xóa sản phẩm được đồng bộ về từ sàn
    DeletePlatformProduct: async (model) => {
        try {
            const res = await Request.Post("/api/agencyproducts/delete-productOPF/", model);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    GetPlatformAccounts: async () => {
        return await BaseServices.Get("/api/agencyproducts/get-platform-accounts");
    },
    SaveProductMappingConfig: async (model) => {
        return await BaseServices.Post("/api/product/save-product-mapping-config", model);
    },
    GetAgencyProductsMappings: async (ageproductId, type) => {
        return await BaseServices.Get(`/api/product/get-agency-products-mapping/${ageproductId}/${type}`);
    },
    GetDropdownVariantByProductId: async (productId, platform) => {
        return await BaseServices.Get(`/api/product/get-list-variant-by-product-id/${productId}/${platform}`);
    },
    GetAgencyProductInfo: async (productId, productType) => {
        return await BaseServices.Get(`/api/product/get-agency-product-info/${productId}/${productType}`);
    },
    GetProducts: async (paging) => {
        return await BaseServices.Post("/api/product/get-list", paging);
    },
    GetProductsDesignPage: async (paging) => {
        return await BaseServices.Post("/api/productDessign/get-products-design-page", paging);
    },
    GetProductsPushPage: async (paging) => {
        return await BaseServices.Post("/api/productDessign/get-products-push-page", paging);
    },
    GetAgencyProductDetail: async (id) => {
        return await BaseServices.Get(`/api/productDessign/get-ageproduct-detail?id=${id}`);
    },
    GetAgencyProductToPush: async (id, platform, shopId, categoryId) => {
        return await BaseServices.Get(`/api/productDessign/get-ageproduct-topush?productId=${id}&platform=${platform}&shopId=${shopId}&categoryId=${categoryId}`);
    },
    PushProductToStore: async (model) => {
        return await BaseServices.Post(`/api/productDessign/push-product-tostore`, model);
    },
    AddListProductToQueuePush: async (model) => {
        return await BaseServices.Post(`/api/productDessign/add-list-product-to-queue`, model);
    },
    GetImagesFromProductId: async (objectId) => {
        return await BaseServices.Get(`/api/product/get-image-from-productId/${objectId}`);
    },
    GetProductExtends: async (productId) => {
        return await BaseServices.Get(`/api/product/get-product-extends/${productId}`);
    },
    // Product Variant
    GetListProductVariant: async (paging) => {
        return await BaseServices.Post("/api/AgencyProduct/get-list-product-variant/", paging);
    },
    // lấy danh sách ảnh design sản phẩm
    GetImagesProductsDesign: async (id) => {
        return await BaseServices.Get(`/api/productDessign/get-images-product-design/${id}`);
    },
    // duyệt sản phẩm design
    ApprovedProductDesign: async (model) => {
        return await BaseServices.Post(`/api/productDessign/approved-product-design`, model);
    },
    // Product Types
    GetProductTypes: async (paging) => {
        return await BaseServices.Post("/api/Product/get-product-types", paging);
    },
    GetProductTypeById: async (id) => {
        return await BaseServices.Get(`/api/Product/get-product-type-by-id/${id}`);
    },
    AddOrUpdateProductType: async (model) => {
        return await BaseServices.Post("/api/Product/add-or-update-product-type", model);
    },
    DeleteProductType: async (id) => {
        return await BaseServices.Delete(`/api/Product/delete-product-type/${id}`);
    },
    GetAgencyVariantBySet: async (setId) => {
        return await BaseServices.Get(`/api/agencyProduct/get-agency-variant-by-setid/${setId}`);
    },
    GetListProductByKeyword: async (paging) => {
        return await BaseServices.Post(`/api/agencyProduct/get-list-product-keyword`, paging);
    },
    ValidateImageMockup: async (image) => {
        return await BaseServices.Post(`/api/product/validate-image-mockup`, image);
    },
    RenderMockupUpload: async (model) => {
        return await BaseServices.Post(`/api/product/render-mockup-upload`, model);
    },
    RenderLogoMockupUpload: async (model) => {
        return await BaseServices.Post(`/api/product/render-logo-mockup-upload`, model);
    },
    //tìm sản phẩm  bằng từ khóa SEO
    GetProductByKeyWord: async (paging) => {
        return await BaseServices.PostHomeApi(`/api/Product/search-products`, paging);
    },
    //lấy dropdown sản phẩm
    GetListProduct: async (paging) => {
        return await BaseServices.Post(`/api/Product/get-dropdown-product-name`, paging);
    },
    PushProductMetaCatalog: async (agencyProductId, templateId) => {
        return await BaseServices.Get(`/api/productDessign/push-product-meta-catalog/${agencyProductId}/${templateId}`);
    },
};

export default ProductServices;
