import BaseServices from "../BaseServices";

const AnswerContentServices = {
    CreateUpdateAnswerContent: async (model) => {
        return await BaseServices.Post("/api/AnswerContent/create-update-answer-content", model);
    },
    DeleteAnswerContent: async (ids) => {
        return await BaseServices.Post("/api/AnswerContent/delete-answer-content", ids);
    },
    GetAnswerContentByPage: async (model) => {
        return await BaseServices.Post("/api/AnswerContent/get-answer-contents", model);
    },
    GetAnswerContentById: async (id) => {
        return await BaseServices.Get(`/api/AnswerContent/get-answer-content-by-id/${id}`);
    },
    ChangeAnswerContentStatus: async (model) => {
        return await BaseServices.Post("/api/AnswerContent/change-answer-content-status", model);
    },
};

export default AnswerContentServices;
