import React, { useEffect, useState, useContext } from 'react';

import Textbox from '../../based/inputs/Textbox';
import CustomerModal from '../../third-party/CustomerModal';
import { StockOutContext, CustomerInfoModel } from '../../context/StockOutContext';
import Selection from '../../based/inputs/Selection';
import ThirdPartyServices from '../../based/services/ThirdPartyServices';

export default function CustomerInfo(props) {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isShowCustomerModal, setIsShowCustomerModal] = useState(false);
  const { customerInfo, setCustomerInfo } = useContext(StockOutContext);

  useEffect(() => {
    _getCustomers();
  }, []);

  async function _getCustomers() {
    const [err, data] = await ThirdPartyServices.GetCustomersDropdown();
    if (!err) {
      setCustomerOptions(data);
    }
  }

  async function _handleChangeCustomer(id) {
    if (id > 0) {
      const [err, data] = await ThirdPartyServices.GetCustomerDetail(id);
      if (!err) setCustomerInfo(data);
      else setCustomerInfo(new CustomerInfoModel());
    } else setCustomerInfo(new CustomerInfoModel());
  }

  function _handleAddCustomerModal() {
    setIsShowCustomerModal(true);
  }

  function _handleSaveSucceed() {
    _getCustomers();
  }

  function _renderBody() {
    return (
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="name" className="col-form-label required">
            Tên khách hàng
          </label>
          <div className="row">
            <div className="col-md-10">
              <Selection ref={props.addRefParent ? props.addRefParent : null} id="customerInfoId" name="customerInfoId" required value={customerInfo.id} placeholder="---Chọn khách hàng---" onChanged={_handleChangeCustomer} options={customerOptions} />
            </div>
            <div className="col-md-2">
              <button className="btn btn-custom btn-sm" onClick={_handleAddCustomerModal} title="Thêm khách hàng">
                <i className="fa fa-lg fa-plus" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="email" className="col-form-label">
                Email
              </label>
              <Textbox className="form-control" id="email" name="email" value={customerInfo.email} readOnly />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phoneNumber" className="col-form-label">
                Số điện thoại
              </label>
              <Textbox className="form-control" id="phoneNumber" name="phoneNumber" value={customerInfo.phoneNumber} readOnly />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="address" className="col-form-label">
                Địa chỉ nhà khách hàng
              </label>
              <Textbox className="form-control" id="address" name="address" value={customerInfo.address} readOnly />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function _renderBodyReadOnly() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <label className="col-md-5 label-normal">Tên khách hàng:</label>
            <strong className="col-md-7">{customerInfo.fullName}</strong>
          </div>
          <div className="row">
            <label className="col-md-5 label-normal">Email:</label>
            <strong className="col-md-7">{customerInfo.email}</strong>
          </div>
          <div className="row">
            <label className="col-md-5 label-normal">Số điện thoại:</label>
            <strong className="col-md-7">{customerInfo.phoneNumber}</strong>
          </div>
          <div className="row">
            <label className="col-md-5 label-normal">Địa chỉ:</label>
            <strong className="col-md-7">{customerInfo.address}</strong>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="header-title text-left font-weight-bold" style={{ margin: '0 0 10px 0' }}>
          Thông tin khách hàng
        </h4>
        {props.readOnly ? _renderBodyReadOnly() : _renderBody()}
      </div>
      {!props.readOnly && (
        <CustomerModal
          isShowModal={isShowCustomerModal}
          provinces={props.provinces}
          onClose={() => setIsShowCustomerModal(false)}
          onSaveSucceed={(data) => {
            setIsShowCustomerModal(false);
            _handleSaveSucceed(data);
          }}
        />
      )}
    </div>
  );
}
