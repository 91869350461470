import React, { useState } from "react";
import Common from "../../based/Common";
import { CONSTANTS, PRESSURE_TYPE, PRINT_TYPE, SYSTEM_ROLE_CONSTANTS } from "../../based/Constants";
import PhoneNumber from "../../based/inputs/PhoneNumber";
import TextArea from "../../based/inputs/TextArea";
import Textbox from "../../based/inputs/Textbox";
import TFUSelect from "../../based/inputs/TFUSelect";
import CommonModal from "../../based/Modal";
import CheckBox from "../../based/inputs/CheckBox";
import BaseServices from "../../based/services/BaseServices";
import OperatorServices from "../../based/services/OperatorServices";
import TFUSelectSubdivisions from "../../based/TFUSelectSubdivisions";
import useFormValidate from "../../hooks/useFormValidate";
import { ThirdPartyOperatorModel } from "../../models/ThirdPartyOperatorModel";

const printTypeOptions = Object.keys({ ...PRINT_TYPE }).map((item) => {
    return {
        value: PRINT_TYPE[item],
        text: item,
        label: item,
    };
});

const pressureTypeOptions = Object.keys({ ...PRESSURE_TYPE }).map((item) => {
    return {
        value: PRESSURE_TYPE[item],
        text: item,
        label: item,
    };
});

function AddOrEditProducerModal(props) {
    const [isBusy, setBusy] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [producer, setProducer] = useState(props.producer);
    const { addRef, displayError, isValid } = useFormValidate();

    const _handleInputChanged = (name, value) => {
        let p = { ...producer };
        p[name] = value;
        setProducer(p);
    };
    const _handleSave = async () => {
        setBusy(true);
        let [err, data] = await OperatorServices.SaveOperator({ ...producer });
        setBusy(false);
        if (!err) {
            props.onSaveSucceeded();
        } else {
            displayError(err.errors);
        }
    };
    return (
        <CommonModal
            size='md'
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isBusy}
            busyMsg={msgModalLoading}
            title={producer.id > 0 ? "Chỉnh sửa thông tin NSX" : "Thêm nhà sản xuất"}
            onSave={() => _handleSave()}
            onClose={() => props.onClose()}
        >
            <div className='form-group'>
                <label className='label-control required' htmlFor='fullName'>
                    Họ và tên
                </label>
                <Textbox
                    id='fullName'
                    name='fullName'
                    required
                    maxLength={255}
                    ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                    className='form-control'
                    onChanged={_handleInputChanged}
                    value={producer.fullName}
                    readOnly
                />
            </div>
            {producer.roles && producer.roles.length > 0 && producer.roles.includes(SYSTEM_ROLE_CONSTANTS.PRINTER_MANAGER) && (
                <div className='form-group'>
                    <label htmlFor='Note'>Năng lực sản xuất</label>
                    <div className='d-flex flex-row'>
                        {printTypeOptions.map((item, idx) => {
                            return (
                                <CheckBox
                                    classDiv='mr-5'
                                    name='printType'
                                    label={item.label}
                                    checked={(producer.printType & item.value) > 0}
                                    onCheckedChange={(name, value) => {
                                        let pro = { ...producer };
                                        let pt = value ? pro.printType | item.value : pro.printType - item.value;
                                        setProducer({ ...producer, printType: pt });
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
            {producer.roles && producer.roles.length > 0 && producer.roles.includes(SYSTEM_ROLE_CONSTANTS.PRESSURE_MANAGER) && (
                <div className='form-group'>
                    <label htmlFor='Note'>Năng lực ép</label>
                    <div className='d-flex flex-row'>
                        {pressureTypeOptions.map((item, idx) => {
                            return (
                                <CheckBox
                                    classDiv='mr-5'
                                    name='printType'
                                    label={item.label}
                                    checked={(producer.pressureType & item.value) > 0}
                                    onCheckedChange={(name, value) => {
                                        let pro = { ...producer };
                                        let pt = value ? pro.pressureType | item.value : pro.pressureType - item.value;
                                        setProducer({ ...producer, pressureType: pt });
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </CommonModal>
    );
}

export default AddOrEditProducerModal;
