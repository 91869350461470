import { CAMPAIGN_TYPE } from "../../based/Constants";
import CampaignBuyMoreModel from "./CampaignBuyMoreModel";
import CampaignBuyMoreProductModel from "./CampaignBuyMoreProductModel";
import CampaignFreeShipModel from "./CampaignFreeShipModel";

export default class CampaignModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.description = "";
        this.showTime = new Date();
        this.startTime = new Date();
        this.endTime = new Date();
        this.shortLink = "";
        this.isActive = 1;
        this.index = 0;
        this.type = CAMPAIGN_TYPE.NORMAL;
        this.campaignBuyMoreModel = new CampaignBuyMoreModel();
        this.campaignBuyMoreProducts = [new CampaignBuyMoreProductModel()];
        this.campaignFreeShip = new CampaignFreeShipModel();

        //?buymore
        this.minimumQuantity = 0;
        this.discountValue = 0;
    }
}
