import BaseServices from "./BaseServices";

const FinanceServices = {
    GetByPaging: async (paging) => {
        return await BaseServices.Post("/api/finance/get-by-paging", paging);
    },
    GetOPUserStores: async (platform) => {
        return await BaseServices.Get(`/api/finance/get-user-store/${platform}`);
    },
    GetPlatformPayout: async (model) => {
        return await BaseServices.Post(`/api/finance/get-platform-payout`, model);
    },
    GetPlatformTransactionDetail: async (model) => {
        return await BaseServices.Post("/api/finance/get-platform-transaction-detail", model);
    },
    GetShippingServicesDropdown: async () => {
        return await BaseServices.Get("/api/finance/get-shipping-services");
    },
    GetStoreGHN: async (shippingServiceId) => {
        return await BaseServices.Get(`/api/finance/get-store-ghn/${shippingServiceId}`);
    },
    GetFinanceFIFO: async () => {
        return await BaseServices.Get("/api/finance/get-finance-fifo");
    },
    FinanceMomoGetPage: async (paging) => {
        return await BaseServices.Post("/api/finance/finance-momo-get-page", paging);
    },
    FinanceMomoTranscripts: async (transcriptIds) => {
        return await BaseServices.Get(`/api/finance/finance-momo-transcripts?transcriptIds=${transcriptIds}`);
    },
    SaveFinanceMomoTranscripts: async (transcriptIds) => {
        return await BaseServices.Post(`/api/finance/save-finance-momo-transcripts?transcriptIds=${transcriptIds}`, null);
    },
    FinancePrintGetPage: async (paging) => {
        return await BaseServices.Post("/api/finance/finance-print-get-page", paging);
    },
    FinanceOperatorGetPage: async (paid, paging) => {
        return await BaseServices.Post(`/api/finance/finance-operator-get-page/${paid}`, paging);
    },
    TransferToOperator: async (model) => {
        return await BaseServices.Post(`/api/finance/transfer-to-operator`, model);
    },
    GetOperatorsTotalAmount: async (paging) => {
        return await BaseServices.Post(`/api/finance/get-last-6weeks-operators-total-amount`, paging);
    },
    GetLoyaltyManagement: async (paging, artworkStatus) => {
        return await BaseServices.Post(`/api/finance/get-loyalty-management/${artworkStatus}`, paging);
    },
    GetFinanceProductAnalyst: async (paging, status) => {
        return await BaseServices.Post(`/api/finance/get-data-finance-analysis/${status}`, paging);
    },
    GetCreatorTicket: async (paging) => {
        return await BaseServices.Post(`/api/finance/get-creator-ticket`, paging);
    },
    GetCreatorTicketDetail: async (creatorTicketId, isSold, paging) => {
        return await BaseServices.Post(`/api/finance/get-creator-ticket-detail/${creatorTicketId}/${isSold}`, paging);
    },
    FinanceShippingGetPage: async (paging) => {
        return await BaseServices.Post(`/api/finance/finance-shipping-get-page`, paging);
    },
    MarkAsReceived: async (orderId) => {
        return await BaseServices.Get(`/api/finance/mark-as-received/${orderId}`);
    },
    GetTotalPaidToSenderByOrderId: async (orderIds) => {
        return await BaseServices.Post(`/api/finance/get-total-paid-to-sender`, orderIds);
    },
};

export default FinanceServices;
