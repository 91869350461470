import React from "react";
import VoucherBrandDetail from "./VoucherBrandDetail";
import VoucherBrandProduct from "./VoucherBrandProduct";

function VoucherBrandTotal(props) {
    return (
        <React.Fragment>
            <div style={{ height: "40vh" }}>
                <VoucherBrandDetail />
            </div>
            <div className="mt-10" style={{ height: "40vh" }}>
                <VoucherBrandProduct />
            </div>
        </React.Fragment>
    );
}

VoucherBrandTotal.propTypes = {};

export default VoucherBrandTotal;
