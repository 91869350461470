import React, { useEffect, useState } from "react";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import useFormValidate from "../hooks/useFormValidate";

export default function RefreshPassword(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [user, setUser] = useState({
        password: "",
        confirmPassword: "",
    });

    useEffect(() => {
        if (window.location.search) {
            setIsBusy(true);
            Request.Get("/api/accounts/refresh-password" + window.location.search, null)
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                    } else {
                        setIsBusy(false);
                        props.history.push(res.url);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                });
        } else props.history.push("/login");
    }, []);
    function _handleForgetPass(e) {
        e.preventDefault();
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang lưu dữ liệu...");

            const params = props.location.search.split("&");
            const token = params[0].split("=")[1];
            const userId = params[1].split("=")[1];

            Request.Post("/api/accounts/save-refresh-password" + props.location.search, {
                password: user.password,
                confirmPassword: user.confirmPassword,
                token: token,
                userId: userId,
            })
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.SUCCESS, "Thành công", "Đặt lại mật khẩu thành công");
                        setTimeout(function () {
                            window.location.href = "/login";
                        }, 200);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                    if (err.message) Notify(NOTIFY.ERROR, "Lỗi", err.message);
                });
        }
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <section className="bg-account">
                <div className="page-header mt-5 ml-5">
                    <img alt="Lotus" src="/images/logo-lotus.png" />
                </div>
                <div className="account-content">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="col-left">
                            <img atl="lotus" src="/images/background-image.png" style={{ height: 650 }} />
                        </div>
                        <div className="col-right">
                            <div className="main-account">
                                <h2 className="text-uppercase text-center">
                                    <a href="/" className="text-success">
                                        <span>
                                            <img src="/logo.png" alt="" height="100" />
                                        </span>
                                    </a>
                                </h2>
                                <h4 className="font-weight-bold text-center text-dark mb-20">Đặt lại mật khẩu</h4>
                                <div className="account-content">
                                    <div className="form-group  row">
                                        <div className="col-12">
                                            <label htmlFor="password" className="required">
                                                Mật khẩu mới
                                            </label>
                                            <Textbox
                                                id="password"
                                                name="password"
                                                value={user.password}
                                                required
                                                password
                                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                                maxLength={100}
                                                minLength={6}
                                                className="form-control"
                                                onChanged={(name, value) => setUser({ ...user, password: value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row ">
                                        <div className="col-12">
                                            <label htmlFor="confirmPassword" className="required">
                                                Nhập lại mật khẩu
                                            </label>
                                            <Textbox
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                value={user.confirmPassword}
                                                required
                                                password
                                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                                maxLength={100}
                                                minLength={6}
                                                className="form-control"
                                                onChanged={(name, value) => setUser({ ...user, confirmPassword: value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mt-4">
                                        <div className="col-12">
                                            <a className="btn-lotus btn-md btn-block btn-dark ml-0 waves-effect waves-light" href="#" onClick={(e) => _handleForgetPass(e)}>
                                                Đặt lại mật khẩu
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row pt-2">
                                        <div className="col-sm-12 text-center">
                                            <p className="text-muted mb-0">
                                                Bạn đã có tài khoản?{" "}
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        props.history.push("/login");
                                                    }}
                                                    className="text-dark ml-1"
                                                >
                                                    <b>Đăng nhập</b>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
