import BaseServices from "./BaseServices";

const FolderAudioServices = {
    CreateUpdateFolderAudio: async (model) => {
        return await BaseServices.Post("/api/FolderAudio/create-update-folder-audio", model);
    },
    DeleteFolderAudio: async (ids) => {
        return await BaseServices.Post("/api/FolderAudio/delete-folder-audio", ids);
    },
    GetFolderAudioByPage: async (model) => {
        return await BaseServices.Post("/api/FolderAudio/get-folder-audio-by-page", model);
    },
    GetFolderAudioById: async (id) => {
        return await BaseServices.Get(`/api/FolderAudio/get-folder-audio-byId/${id}`);
    },
    GetAudioByFolderId: async (model, folderId) => {
        return await BaseServices.Post(`api/FolderAudio/get-audio-by-folderId/${folderId}`,model);
    },
    CreateUpdateFolderAudioConnection: async (model) => {
        return await BaseServices.Post("/api/FolderAudio/create-update-folder-audio-connection", model);
    },
    
};

export default FolderAudioServices;
