import React, { useState, useEffect, useRef } from "react";
import PubSub from "pubsub-js";
import { Layout } from "../layout/Layout";
import StoreDiv from "./components/StoreDiv";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import { WarehousesModel } from "../models/WarehousesModel";
import WarehouseServices from "../based/services/WarehouseServices";
import WarehouseModal from "./WarehouseModal";
import { Notify } from "../based/Notify";
import { NOTIFY, CONSTANTS, MAINMENU, PLATFORM } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import OPFWarehouseModal from "./seller/OPFWarehouseModal";
import StoreItem from "./seller/StoreItem";
import Breadcrumbs from "../based/Breadcrumbs";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    orderBy: "id",
    orderDirection: "asc",
    shopId: "",
    platform: PLATFORM.Undefined,
};

export default function WarehouseManagement(props) {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [datas, setDatas] = useState(null);
    const [paging, setPaging] = useState(initPage);
    const tableRef = useRef(null);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const [provinces, setProvinces] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [warehouse, setWarehouse] = useState(new WarehousesModel());
    const [stores, setStores] = useState([]);

    useEffect(() => {
        _handleChangeTab();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, {
            mainMenu: MAINMENU.Warehouse,
        });
    }, []);

    async function _getDatas(paging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await WarehouseServices.GetWarehouses(paging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
            setMsg("");
        } else {
            setBusy(false);
            setMsg("");
        }
    }

    async function _handleFilter(newPaging) {
        await _getDatas(newPaging);
    }

    function _handleAdd() {
        if (!Common.IsAdmin()) {
            var newWarehouse = new WarehousesModel();
            newWarehouse.warehouseRegions = null;
            newWarehouse.platform = PLATFORM.Facebook;
            newWarehouse.isActive = true;
            setWarehouse(newWarehouse);
        } else setWarehouse(new WarehousesModel());
        setIsShowModal(true);
    }

    async function _handleEdit(idx) {
        if (datas[idx]) {
            setBusy(true);
            setMsg("Đang tải dữ liệu...");
            const [err, data] = paging.platform == PLATFORM.LOTUS ? await WarehouseServices.GetWarehouseNoSlotsById(datas[idx].id) : await WarehouseServices.GetWarehouseById(datas[idx].id);
            if (!err) {
                setWarehouse(data);
                setIsShowModal(true);
                setBusy(false);
                setMsg("");
            } else {
                setBusy(false);
                setMsg("");
            }
        }
    }

    function _handleClick(idx) {
        let warehouse = datas[idx];
        if (warehouse) {
            localStorage.setItem(CONSTANTS.CURRENT_WAREHOUSE, warehouse.name);
            // window.location.href = "/wareslot/" + warehouse.id;
            props.history.push("/wareslot/" + warehouse.id);
        }
    }

    async function _handleDelete(idx) {
        let warehouse = datas[idx];
        if (warehouse.totalQuantity > 0) {
            Notify(NOTIFY.INFO, "Thông tin", "Không thể xoá kho đang có sản phẩm");
        } else {
            let ids = [];
            ids.push(warehouse.id);
            ConfirmDialog("Xác nhận xoá?", "Bạn chắc chắn muốn xoá dữ liệu được chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMsg("Đang xoá dữ liệu...");
                    let [err, data] = await WarehouseServices.DeleteWarehouse(ids);
                    setBusy(false);
                    setMsg("");
                    if (!err) {
                        _getDatas(paging);
                        resolve({
                            title: "Thành công",
                            msg: "Xoá thành công",
                        });
                    }
                });
            });
        }
    }

    async function _handleChangeTab(curPlatform) {
        let currentPage = { ...paging, platform: 0, shopId: "" };
        _getDatas(currentPage);
    }

    async function _handleChangeStoreTab(e, value) {
        e.preventDefault();
        _getDatas({ ...paging, shopId: value });
    }

    function _renderAction() {
        return (
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='row flex-row p-y-10'>
                        <div className='m-r-5'>
                            <div className='form-group'>
                                <button className='btn btn-custom btn-sm' onClick={() => _handleAdd()}>
                                    <i className='fa fa-plus m-r-5' />
                                    Thêm mới
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _renderBody() {
        let isMulPlatform = false;
        if (datas && datas.length > 0 && datas.filter((x) => x.platformName != "FIFO")) isMulPlatform = true;
        return (
            <tbody className='tbody-warehouse'>
                <tr>
                    <td>
                        <div className='padding-10'>
                            <div className='row'>
                                {datas && datas.length > 0 ? (
                                    datas.map((item, i) => {
                                        return !Common.IsAdmin() ? (
                                            <StoreItem
                                                data={item}
                                                isMulPlatform={isMulPlatform}
                                                onEdit={() => _handleEdit(i)}
                                                onDelete={() => _handleDelete(i)}
                                                onClick={() => _handleClick(i)}
                                                readOnly={paging.platform == PLATFORM.LOTUS ? false : true}
                                            />
                                        ) : (
                                            <StoreDiv data={item} onEdit={() => _handleEdit(i)} onDelete={() => _handleDelete(i)} onClick={() => _handleClick(i)} />
                                        );
                                    })
                                ) : (
                                    <span className='p-y-10'>Hiện tại không có dữ liệu kho</span>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }
    function _renderHeader() {}

    function _renderTab() {
        return !Common.IsAdmin() ? (
            <React.Fragment>
                <div className='d-flex agencyproducts'>
                    <div className='detail-tab px-0 text-center'>
                        <div className='d-flex border-bottom box-shadow-none'>
                            <button
                                type='button'
                                className={paging.platform == PLATFORM.Lazada ? "btn btn-primary" : "btn btn-light"}
                                onClick={() => {
                                    _handleChangeTab(PLATFORM.Lazada);
                                }}
                            >
                                Lazada
                            </button>
                            <button
                                type='button'
                                className={paging.platform == PLATFORM.Shopee ? "btn btn-primary" : "btn btn-light"}
                                onClick={() => {
                                    _handleChangeTab(PLATFORM.Shopee);
                                }}
                            >
                                Shopee
                            </button>
                            <button
                                type='button'
                                className={paging.platform == PLATFORM.LOTUS ? "btn btn-primary" : "btn btn-light"}
                                onClick={() => {
                                    _handleChangeTab(PLATFORM.LOTUS);
                                }}
                            >
                                Seller
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        ) : (
            ""
        );
    }

    function _renderStoreTab() {
        return stores && stores.length > 0 ? (
            <div className='row col-md-12' style={{ height: "40px" }}>
                <ul className='nav nav-tabs tabs-bordered'>
                    {stores.map((item, idx) => {
                        return (
                            <li key={idx} className='nav-item'>
                                <a href='#' className={paging.shopId == item.value ? "nav-link active" : "nav-link"} onClick={(e) => _handleChangeStoreTab(e, item.value)}>
                                    <span className='d-none d-sm-block'>{item.text}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        ) : null;
    }

    function _handleSaveSucceed() {
        setIsShowModal(false);
        setTableGuid(Common.generateGuid());
        _getDatas(paging);
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý kho' />
            <Loading show={isBusy} msg={msg} />
            {_renderTab()}
            {_renderStoreTab()}
            <CommonTable data={datas} paging={paging} ref={tableRef} tableGuid={tableGuid} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} searchBasic onFilter={_handleFilter} freeSize />
            {!Common.IsAdmin() ? <WarWarehouseCommonTable /> : ""}
            {!Common.IsAdmin() ? (
                <OPFWarehouseModal
                    provinces={provinces}
                    isShowModal={isShowModal}
                    warehouse={warehouse}
                    onClose={() => setIsShowModal(false)}
                    onSave={(data) => {
                        setIsShowModal(data), setTableGuid(Common.generateGuid());
                    }}
                    onSaveSucceed={_handleSaveSucceed}
                    readOnly={paging.platform == PLATFORM.LOTUS ? false : true}
                />
            ) : (
                <WarehouseModal
                    provinces={provinces}
                    isShowModal={isShowModal}
                    warehouse={warehouse}
                    onClose={() => setIsShowModal(false)}
                    onSave={(data) => {
                        setIsShowModal(data), setTableGuid(Common.generateGuid());
                    }}
                    onSaveSucceed={_handleSaveSucceed}
                />
            )}
        </React.Fragment>
    );
}

const WarWarehouseCommonTable = () => {
    let initPage = {
        pageSize: 10,
        pageNumber: 1,
        orderBy: "id",
        orderDirection: "asc",
        platform: PLATFORM.LOTUS,
    };

    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());

    useEffect(() => {
        _fetchAdminWarehouse({ ...paging, createdBy: "admin" });
    }, []);

    async function _fetchAdminWarehouse(paging) {
        let [err, res] = await WarehouseServices.GetWarehouses(paging);
        if (!err) {
            setDatas(res.listObjects);
            setPaging(res.paging);
        }
    }

    function _renderTab() {
        return (
            <React.Fragment>
                <div className='d-flex agencyproducts'>
                    <div className='detail-tab px-0 text-center'>
                        <div className='d-flex border-bottom box-shadow-none'>
                            <button type='button' className={"btn btn-primary"}>
                                FIFO
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function _renderAction() {}
    function _renderHeader() {}
    function _handleEdit() {}
    function _handleDelete() {}
    function _renderBody() {
        let isMulPlatform = false;
        if (datas && datas.length > 0 && datas.filter((x) => x.platformName != "FIFO")) isMulPlatform = true;

        return (
            <tbody className='tbody-warehouse'>
                <tr>
                    <td>
                        <div className='padding-10'>
                            <div className='row'>
                                {datas && datas.length > 0 ? (
                                    datas.map((item, i) => {
                                        return <StoreItem data={item} isMulPlatform={isMulPlatform} onEdit={() => _handleEdit(i)} onDelete={() => _handleDelete(i)} readOnly={true} />;
                                    })
                                ) : (
                                    <span className='p-y-10'>Hiện tại không có dữ liệu kho</span>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <React.Fragment>
            {_renderTab()}
            <CommonTable
                data={datas}
                paging={paging}
                tableGuid={tableGuid}
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                // searchBasic
                onFilter={() => {
                    _fetchAdminWarehouse({ ...paging });
                }}
                freeSize
            />
        </React.Fragment>
    );
};
