import React, {useState, useEffect, useContext} from "react";
import {PlatformOrderPackageModel} from "../../models/PlatformOrderPackageModel";
import {NOTIFY, PLATFORM} from "../../based/Constants";
import {Notify} from "../../based/Notify";
import Textbox from "../../based/inputs/Textbox";
import AgencyServices from "../../based/services/AgencyServices";
import {StockOutContext} from "../../context/StockOutContext";

export default function LazadaPackageInfo(props) {
  const {platformOrderPackages, setPlatformOrderPackages} = useContext(StockOutContext);

  useEffect(() => {
    _handleOrderByLazada();
  }, [props.platformOrderId]);

  async function _handleOrderByLazada() {
    if (props.platformOrderId && props.platformOrderId > 0 && props.request) {
      let orderIds = [];
      orderIds.push(props.platformOrderId);
      let [err, data] = await AgencyServices.HandleOrderByLazada(JSON.stringify(orderIds), props.request);
      if (!err) {
        let updateData = [...data];
        if (updateData && updateData.length > 0)
          updateData.map((item) => {
            if (!item.invoiceNumber) item.invoiceNumber = item.trackingNumber;
            return item;
          });
        setPlatformOrderPackages(updateData);
      }else{
        setPlatformOrderPackages([])
      }
    }
  }

  function _handleUpdateProduct(orders, name) {
    let dataOrders = [...platformOrderPackages];
    if (dataOrders && dataOrders.length > 0) {
      dataOrders.map((value, index) => {
        if (value.orderId == orders.orderId) {
          value[name] = orders[name];
        }
      });
    }
    setPlatformOrderPackages(dataOrders);
  }

  return (
    <div className="row">
      <div className="col-sm-12 p-3">
        <table className="table table-bordered m-b-0">
          <thead className="thead-light">
            <tr>
              <th className="cursor-pointer center">Số đơn hàng</th>
              <th className="cursor-pointer center">Sản phẩm</th>
              <th className="cursor-pointer center">Thông tin vận chuyển</th>
              <th className="cursor-pointer center">Mã vận đơn</th>
              <th className="cursor-pointer center">Số hóa đơn</th>
            </tr>
          </thead>
          <tbody>
            {platformOrderPackages && platformOrderPackages.length > 0 ? (
              platformOrderPackages.map((item, index) => {
                return (
                  <tr>
                    <td className="text-center vertical-align-middle">{item.orderId}</td>
                    <td className="text-center vertical-align-middle">{item.totalItems}</td>
                    <td className="text-center vertical-align-middle">{item.shipmentProviderName}</td>
                    <td className="text-center vertical-align-middle">{item.trackingNumber}</td>
                    <td className="text-center vertical-align-middle required">
                      <Textbox
                        required
                        id="invoiceNumber"
                        name="invoiceNumber"
                        value={item.invoiceNumber}
                        onChanged={(name, value) => {
                          let orderUpdate = {
                            ...item,
                            invoiceNumber: value,
                          };
                          _handleUpdateProduct(orderUpdate, "invoiceNumber");
                        }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan="5">
                  Không có dữ liệu
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
