import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import OperatorServices from "../based/services/OperatorServices";
import { Layout } from "../layout/Layout";
import { ProducerModel } from "../models/ProducerModel";
import { ThirdPartyOperatorModel } from "../models/ThirdPartyOperatorModel";
import AddOrEditProducerModal from "./components/AddOrEditProducerModal";

function Producer(props) {
    const [isShowModal, setShowModal] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [data, setData] = useState([new ThirdPartyOperatorModel()]);
    const [currentProducer, setCurrentProducer] = useState({});
    const [paging, setPaging] = useState({ ...INITPAGING });

    useEffect(() => {
        _fetchData({ ...INITPAGING });
    }, []);

    const _fetchData = async (paging) => {
        setBusy(true);
        let [err, data] = await OperatorServices.GetOperators(paging);
        setBusy(false);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
        } else {
            setData([]);
            setPaging(INITPAGING);
        }
    };

    const __handleEdit = async (index) => {
        let [err, data] = await OperatorServices.GetOperator(index);
        if (!err) {
            setCurrentProducer(data);
            setShowModal(true);
        }
    };

    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th className='center'>Tên đơn vị</th>
                    <th className='center'>Role</th>
                    <th className='center'>Số điện thoại</th>
                    <th className='center'>Năng lực In</th>
                    <th className='center'>Năng lực Ép</th>
                    <th className='center'>Hành động</th>
                </tr>
            </thead>
        );
    };

    const _renderBody = () => {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, i) => {
                        let roleName = item.roles && item.roles.length > 0 ? item.roles.reduce((prev, item, idx) => (prev += idx == 0 ? `${item} ` : `- ${item}`), "") : "";
                        return (
                            <tr key={i}>
                                <td className='center'>{item.fullName}</td>
                                <td className='center'>{roleName}</td>
                                <td className='center'>{item.phoneNumber}</td>
                                <td className='center'>{item.printType > 0 ? item.printTypeName : ""}</td>
                                <td className='center'>{item.pressureType > 0 ? item.pressureTypeName : ""}</td>
                                <td className='center'>
                                    <button className='btn btn-sm btn-primary' onClick={() => __handleEdit(item.userId)}>
                                        Sửa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='6' className='center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    };

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={"Đang tải dữ liệu"} />
            <Breadcrumbs currentPage='Nhà Sản Xuất' />
            <CommonTable data={data} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={() => {}} searchBasic onFilter={() => _fetchData({ ...paging })} />
            {isShowModal && (
                <AddOrEditProducerModal
                    producer={currentProducer}
                    isShowModal={isShowModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                    onSave={(data) => {
                        setShowModal(false);
                    }}
                    onSaveSucceeded={() => {
                        setShowModal(false);
                        _fetchData({ ...paging });
                        Notify(NOTIFY.SUCCESS, "", "Lưu thành công");
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default Producer;
