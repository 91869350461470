import BaseServices from "./BaseServices"

const ImageServices = {
    UploadImage: async (data) => {
        return await BaseServices.Post("/api/Image/upload-image", data)
    },
    GetArtWorkByUserName: async () => {
        return await BaseServices.Get("/api/Image/get-art-work")
    },
    RemoveArtwork: async (id) => {
        return await BaseServices.Get(`/api/Image/remove-art-work/${id}`)
    },
    DeleteImage: async (fileName) => {
        return await BaseServices.Get(`/api/Image/remove-image/${fileName}`)
    }
}

export default ImageServices