import React from "react";

function CommonTablePagination(props) {
    function handleChangePage(page) {
        if (props.onChangePage) {
            props.onChangePage(page);
        }
    }
    function handleChangePageSize(pageSize) {
        if (props.onChangePageSize) {
            props.onChangePageSize(pageSize);
        }
    }
    let totalRecord = props.paging.totalRecord,
        pageTotal = totalRecord % props.paging.pageSize === 0 ? totalRecord / props.paging.pageSize : (totalRecord - (totalRecord % props.paging.pageSize)) / props.paging.pageSize + 1;

    let from = (props.paging.pageNumber - 1) * props.paging.pageSize + 1;
    let to = props.paging.pageSize * props.paging.pageNumber;

    if (totalRecord == 0) {
        from = 0;
        to = 0;
    } else if (totalRecord < props.paging.pageSize * props.paging.pageNumber) {
        to = totalRecord;
    }

    return (
        <table className="table table-bordered m-b-0">
            <tfoot>
                <tr>
                    <td>
                        <div className="form-inline">
                            <ul className="pagination pagination-split footable-pagination m-b-0">
                                {props.paging.pageNumber > 1 &&
                                    (props.paging.pageNumber === 2 ? (
                                        <li onClick={() => handleChangePage(1)}>
                                            <a className="cursor-pointer">1</a>
                                        </li>
                                    ) : (
                                        [
                                            <li onClick={() => handleChangePage(1)} title={1} key={0}>
                                                <a className="cursor-pointer">«</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(props.paging.pageNumber - 1)} title={props.paging.pageNumber - 1} key={1}>
                                                <a className="cursor-pointer">‹</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(props.paging.pageNumber - 1)} key={2}>
                                                <a className="cursor-pointer">{props.paging.pageNumber - 1}</a>
                                            </li>,
                                        ]
                                    ))}
                                <li className="active">
                                    <a className="cursor-pointer">{props.paging.pageNumber}</a>
                                </li>
                                {pageTotal > props.paging.pageNumber &&
                                    (pageTotal === props.paging.pageNumber + 1 ? (
                                        <li onClick={() => handleChangePage(props.paging.pageNumber + 1)}>
                                            <a className="cursor-pointer">{props.paging.pageNumber + 1}</a>
                                        </li>
                                    ) : (
                                        [
                                            <li onClick={() => handleChangePage(props.paging.pageNumber + 1)} key={0}>
                                                <a className="cursor-pointer">{props.paging.pageNumber + 1}</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(props.paging.pageNumber + 1)} title={props.paging.pageNumber + 1} key={1}>
                                                <a className="cursor-pointer">›</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(pageTotal)} title={pageTotal} key={2}>
                                                <a className="cursor-pointer">»</a>
                                            </li>,
                                        ]
                                    ))}
                            </ul>
                            <span className="table-record">
                                Hiển thị từ {from} đến {to} của {totalRecord} bản ghi
                            </span>
                            <label className={"form-inline " + props.footerClassName}>
                                Hiển thị&nbsp;
                                <select className={props.footerClassName ? props.footerClassName : "form-control h-30px"} value={props.paging.pageSize} onChange={(event) => handleChangePageSize(event.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                                &nbsp;bản ghi
                            </label>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}

export default CommonTablePagination;
