import React from "react";
import Language from "../../based/Language"; 
function StoreDiv(props) {
  return (
    <div className="text-left-wrap col-md-3">
      <div className="row border m-l-5 m-r-5 m-b-10 h-100">
        <div className="text-center col border-bottom" style={{ whiteSpace: "normal" }}>
          <div className="row">
            <a onClick={() => props.onClick()} className="col-12"  >
              <i className="fa fa-database"></i> {props.data.name}
              {
                props.data.phoneNumber && props.data.phoneNumber.length > 0 ?
                  <span> (  {props.data.phoneNumber} )</span>
                  : null
              }
              <p><i className="fa fa-map-marker"></i> {props.data.formatAddress}</p>
            </a>
          </div>
        </div>
        <div className="w-100"></div>
        <div className="col m-b-5">
          <div className="row">
            <div className="col-12">
              <span>  Số khu vực: <b>{props.data.totalRegions}</b> </span>
            </div>
            <div className="col-12">
              <span>  Số vị trí: <b>{props.data.totalSlots}</b> </span>
            </div>
            <div className="col-12">
              <span>  Tổng số sản phẩm trong kho: <b>{props.data.totalQuantity}</b> </span>
            </div>
            <div className="col-12">
              <span>   Tỉ lệ sử dụng kho: <b>{props.data.totalUsage}</b>  </span>
            </div>
            <div className="col-12 m-b-5">
              <button className="btn btn-xs btn-danger float-right m-l-5" onClick={() => { props.onDelete(); }}  >
                <i className="fa fa-trash m-r-5" />
                {Language.getValue("tableAction.delete")}
              </button>
              <button className="btn btn-xs btn-custom float-right" onClick={() => { props.onEdit(); }}  >
                <i className="fa fa-edit m-r-5" />
                {Language.getValue("tableAction.edit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StoreDiv;
