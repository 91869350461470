import BaseServices from "./BaseServices";

const InstructServices = {  
    CreateUpdateLink: async (model) => {
        return await BaseServices.Post("/api/Instruct/create-update-link", model);
    },
    CreateUpdateStep: async (model) => {
        return await BaseServices.Post("/api/Instruct/create-update-step", model);
    },
    CreateUpdateContent: async (model) => {
        return await BaseServices.Post("/api/Instruct/create-update-content", model);
    },
    DeleteLink: async (ids) => {
        return await BaseServices.Post("/api/Instruct/delete-link", ids);
    },
    DeleteStep: async (ids) => {
        return await BaseServices.Post("/api/Instruct/delete-step", ids);
    },
    DeleteContent: async (ids) => {
        return await BaseServices.Post("/api/Instruct/delete-content", ids);
    },
    GetLinkByPage: async (model) => {
        return await BaseServices.Post("/api/Instruct/get-link-by-page", model);
    },
    GetLinkById: async (id) => {
        return await BaseServices.Get(`/api/Instruct/get-link-byId/${id}`);
    },
    GetStepById: async (id) => {
        return await BaseServices.Get(`/api/Instruct/get-step-byId/${id}`);
    },
    GetContentById: async (id) => {
        return await BaseServices.Get(`/api/Instruct/get-content-byId/${id}`);
    },
    UpdateIndexContentInStep: async (model) => {
        return await BaseServices.Post("/api/Instruct/update-index-content-in-step", model);
    },
    GetStepByLinkId: async (linkId) => {
        return await BaseServices.Get(`/api/Instruct/get-step-by-linkId/${linkId}`);
    },
    GetContentByStepId: async (stepId) => {
        return await BaseServices.Get(`/api/Instruct/get-content-by-stepId/${stepId}`);
    },
    UpdateIndexStepInLink: async (model) => {
        return await BaseServices.Post("/api/Instruct/update-index-step-in-link", model);
    },
};

export default InstructServices;
