export const RequestDomain = {
    APIUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://api.ranus.vn" : "https://localhost:6002";
    },
    CDNUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://cdn.ranus.vn" : "https://localhost:6001";
    },
    HomeApiUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://home-api.ranus.vn" : "https://localhost:6003";
    },
    HomeUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://ranus.vn" : "https://localhost:5000";
    },
    CreatorUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://creator.ranus.vn" : "https://localhost:7000";
    },
    AdminUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://admin.ranus.vn" : "https://localhost:7000";
    },
};
