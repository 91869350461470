import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY, StockFolderType } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import MessPhotoServices from "../based/services/MessPhotoServices";
import AddEditModal from "./AddEditModal";
import SelectFontModal from "./SelectFontModal";
const initPage = {
    pageSize: 10,
    pageNumber: 1,
    folderType: StockFolderType.Font,
    orderBy: "id",
    orderDirection: "asc",
};

class MessStockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.startDate = new Date();
        this.endDate = new Date();
        this.isDefault = false;
        this.limit = 0;
    }
}

export default function StockMessPhoto(props) {
    const [isBusy, setBusy] = useState(true);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);
    const [datas, setDatas] = useState([new MessStockFolderModel()]);
    const [paging, setPaging] = useState(initPage);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMessage] = useState("");
    const [activeId, setActiveId] = useState(0);
    const [stockFonts, setStockFonts] = useState([]);
    const [showSelectModal, setShowSelectModal] = useState(false);

    useEffect(() => {
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await MessPhotoServices.GetListFolderMess(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(newPaging);
        }
        setBusy(false);
        setMessage(null);
    }

    async function handleRemove(id) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await MessPhotoServices.RemoveFolderMess(id);
        if (!err && data) {
            getDatas({ ...paging });
            setStockFonts([]);
            setActiveId(0);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
        setBusy(false);
        setMessage(null);
    }

    async function getStockFont(folderId) {
        let [err, data] = await MessPhotoServices.GetListPhotoGroupByFolderId(folderId);
        if (!err && data) setStockFonts(data);
        else setStockFonts([]);
    }

    async function addStockImage(payload) {
        let [err, data] = await MessPhotoServices.AddRangeFolderConnection(payload);
        if (!err && data) {
            setShowSelectModal(false);
            getStockFont(activeId);
        }
    }

    async function removeStockImage(payload) {
        let [err, data] = await MessPhotoServices.DeleteRangeFolderConnection(payload);
        if (!err && data) {
            getStockFont(activeId);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    }

    function _renderAction() {
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group">
                    <button
                        className="btn btn-custom btn-sm m-1"
                        onClick={(e) => {
                            setShowModal(true);
                            setActiveId(0);
                        }}
                    >
                        Thêm folder
                    </button>
                    <button
                        className={`btn btn-custom btn-sm m-1 ${activeId > 0 ? "" : "disabled"}`}
                        onClick={(e) => {
                            setShowModal(true);
                        }}
                    >
                        Sửa tên folder
                    </button>

                    <button
                        className={`btn btn-danger btn-sm m-1 ${activeId > 0 ? "" : "disabled"} `}
                        onClick={(e) => {
                            handleRemove(activeId);
                        }}
                    >
                        Xóa
                    </button>
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox name="select_all" label=" " onCheckedChange={(name, value) => {}} />
                    </th>
                    <th className="cursor-pointer">Tên Folder</th>
                    <th className="cursor-pointer" width="150">
                        Loại Folder
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        if (!datas)
            return (
                <tbody>
                    <tr>
                        <td colSpan={999} className="text-center">
                            Không có dữ liệu!
                        </td>
                    </tr>
                </tbody>
            );
        return (
            <tbody>
                {datas.map((item, i) => {
                    let content = "";
                    if (item.isDefault) {
                        content = "Mặc định";
                    } else {
                        let limitContent = item.limit == 0 ? "Không giới hạn" : item.limit;
                        content = `Bắt đầu: ${Common.formatDate(new Date(item.startDate))} - Kết thúc: ${Common.formatDate(new Date(item.endDate))}\nGiới hạn gửi: ${limitContent}`;
                    }
                    return (
                        <React.Fragment>
                            <tr
                                key={i}
                                onClick={(e) => {
                                    if (item.id == activeId) {
                                        setActiveId(0);
                                        setStockFonts([]);
                                    } else {
                                        getStockFont(item.id);
                                        setActiveId(item.id);
                                    }
                                }}
                            >
                                <td>
                                    <CheckBox checked={item.id == activeId} name={item.id} />
                                </td>
                                <td>
                                    <span className="d-flex align-items-center cursor-pointer">{item.name}</span>
                                </td>
                                <td>{content}</td>
                            </tr>
                        </React.Fragment>
                    );
                })}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <Wrapper>
                <div style={{ display: "flex" }}>
                    <div style={{ flexBasis: "40%" }}>
                        <CommonTable
                            data={datas}
                            paging={paging}
                            ref={tableRef}
                            tableGuid={tableGuid}
                            searchBasic
                            hideSearchBasicButton
                            hideSearch
                            renderAction={_renderAction}
                            renderHeader={_renderHeader}
                            renderBody={_renderBody}
                            onFilter={getDatas}
                        />
                    </div>
                    <div className="stock-box">
                        <div className="box-header">
                            <div className="header-left">
                                <div className="form-group">
                                    <label>Group Ảnh</label>
                                </div>
                            </div>
                            <div className="header-right">
                                <button className={`btn btn-custom btn-sm m-1 cursor-pointer ${activeId > 0 ? "" : "disabled"}`} onClick={(e) => setShowSelectModal(true)}>
                                    Thêm Group Ảnh
                                </button>
                            </div>
                        </div>
                        <div className="body-list">
                            {stockFonts &&
                                stockFonts.map((item, idx) => {
                                    return (
                                        <div className="body-item" key={idx}>
                                            <i
                                                className="fa fa-times remove-icon cursor-pointer"
                                                onClick={(e) => {
                                                    removeStockImage([{ folderId: activeId, groupId: item.groupId }]);
                                                }}
                                            />
                                            <span className="body-item-text">{item.name}</span>
                                            {item.avatar && <img src={item.avatar} />}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </Wrapper>
            {showModal && (
                <AddEditModal
                    isShowModal={showModal}
                    onClose={(e) => setShowModal(false)}
                    onSave={() => {
                        setShowModal(false);
                        getDatas({ ...paging });
                    }}
                    currentId={activeId}
                />
            )}
            {showSelectModal && (
                <SelectFontModal
                    isShowModal={showSelectModal}
                    onClose={(e) => setShowSelectModal(false)}
                    onSave={(ids) => {
                        if (ids && ids.length > 0) {
                            let payload = ids.map((item) => ({
                                folderId: activeId,
                                groupId: item,
                            }));
                            addStockImage(payload);
                        }
                    }}
                    folderId={activeId}
                />
            )}
        </React.Fragment>
    );
}

const Wrapper = styled.div`
    .body-item-text {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        z-index: 2;
        position: relative;
        font-weight: bold;
    }
    td.add-more {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #64c5b1;
    }
    .square {
        height: 20px;
        width: 20px;
        object-fit: contain;
    }

    //stock-box
    .stock-box {
        flex: 1;
        background: #ffffff;
        margin-left: 10px;
    }

    .box-header {
        background: #d9d9d9;
        padding: 10px 10px 0px 10px;
        display: flex;
    }

    .header-left {
        display: flex;
    }

    .header-right {
        margin-left: auto;
    }

    .header-left .form-group {
        display: flex;
        align-items: baseline;
    }

    .header-left .form-group .btn-search {
        margin-left: 10px;
    }

    /* body */
    .body-list {
        display: flex;
        flex-wrap: wrap;
    }

    .body-item {
        height: 100px;
        width: 100px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        margin: 10px;
        padding: 5px;
        position: relative;
    }

    .body-item img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    i.fa.fa-times.remove-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 3;
    }
`;
