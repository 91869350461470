import React, { useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import { StockFolderType, StockFolderTypeName } from "../based/Constants";
import StockFont from "../stock-font/StockFont";
import GiftFolder from "../stock-gift/GiftFolder";
import StockImage from "../stock-image/StockImage";
import StockMessPhoto from "../stock-mess-photo/StockMessPhoto";
import PhotoTemplateFolder from "../stock-template/PhotoTemplateFolder";
import StockFolder from "./StockFolder";

const FOLDER_TAB = [
    { value: StockFolderType.StockPhoto, label: StockFolderTypeName.StockPhoto },
    { value: StockFolderType.Font, label: StockFolderTypeName.Font },
    { value: StockFolderType.StockImage, label: StockFolderTypeName.StockImage },
    { value: StockFolderType.PhotoTemplate, label: StockFolderTypeName.PhotoTemplate },
    { value: StockFolderType.MessengerPhoto, label: StockFolderTypeName.MessengerPhoto },
    { value: StockFolderType.Gift, label: StockFolderTypeName.Gift },
];

function StockFolderTotal(props) {
    const [activeFolder, setActiveFolder] = useState(StockFolderType.StockPhoto);

    function _renderFolderTab() {
        return FOLDER_TAB.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a className={activeFolder == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => setActiveFolder(item.value)}>
                        <span>{item.label}</span>
                    </a>
                </li>
            );
        });
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý Folder Họa Tiết" />
            <ul className="nav nav-tabs tabs-bordered">{_renderFolderTab()}</ul>
            {activeFolder == StockFolderType.StockPhoto && <StockFolder />}
            {activeFolder == StockFolderType.Font && <StockFont />}
            {activeFolder == StockFolderType.StockImage && <StockImage />}
            {activeFolder == StockFolderType.PhotoTemplate && <PhotoTemplateFolder />}
            {activeFolder == StockFolderType.MessengerPhoto && <StockMessPhoto />}
            {activeFolder == StockFolderType.Gift && <GiftFolder />}
        </React.Fragment>
    );
}

export default StockFolderTotal;
