import Pubsub from "pubsub-js";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import { useHistory } from "react-router-dom";
import Common from "../../based/Common";
import { CONSTANTS, MAINMENU, NOTIFY, PLATFORM } from "../../based/Constants";
import { Block, CheckBox } from "../../based/content/ct.elm";
import { ConfirmDialog } from "../../based/Dialog";
import Language from "../../based/Language";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import Button, { ButtonType } from "../../based/refactor/Button";
import ButtonTab from "../../based/refactor/ButtonTab";
import CommonTable from "../../based/refactor/CommonTable";
import TableWidget from "../../based/refactor/TableWidget";
import Textbox from "../../based/refactor/Textbox";
import TFUSelect from "../../based/refactor/TFUSelect";
// import Request from "../../based/Request";
import BrandServices from "../../based/services/BrandServices";
import CommonServices from "../../based/services/CommonServices";
import SupportControl from "../../based/SupportControl";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
// import LayoutLight from "../../layout/refactor/Layout/Layout";
import AddOrEdit from "../AddOrEdit";
import BrandApproved from "../BrandApproved";

const initBrand = {
    id: 0,
    parentId: 0,
    code: "",
    name: "",
    shortLink: "",
    icon: "",
    index: 1,
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    description: "",
    image: "",
    isActive: true,
    platform: PLATFORM.Internal,
    opfBrands: [],
};
const initPaging = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: "id",
    orderDirection: "asc",
    keyword: "",
};
const columns = [
    {
        displayName: "Logo",
        name: "image",
        type: "image",
        width: "15%",
    },
    {
        displayName: "Tên thương hiệu",
        name: "name",
        width: "30%",
    },
    {
        displayName: "Thứ tự hiển thị",
        width: "15%",
        name: "index",
    },
    {
        displayName: "Kích hoạt",
        name: "isActive",
        type: "bool",
        width: "15%",
    },
];
const TAB_SCREEN = {
    LOTUS: 1,
    CREATOR: 2,
    PROCESS_BRAND: 3,
};
const STATUS_OPTIONS = [
    {
        value: 0,
        label: "Chờ duyệt",
    },
    {
        value: 1,
        label: "Đã duyệt",
    },
    {
        value: 2,
        label: "Từ chối duyệt",
    },
];
const menuItem = ["Trang chủ", "Quản lý thương hiệu"];

export default function BrandManage(props) {
    const [isBusy, setBusy] = useState(false);
    const [msgBusy, setMsgBusy] = useState("");
    const [paging, setPaging] = useState({ ...initPaging });
    const [datas, setDatas] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [brand, setBrand] = useState(initBrand);
    const [tabIndex, setTabIndex] = useState(TAB_SCREEN.LOTUS);
    const [creatorOptions, setCreatorOptions] = useState([]);
    const [showApproved, setShowApproved] = useState(false);
    const isAdmin = Common.IsAdmin();
    const history = useHistory();
    const [supportControl, setSupportControl] = useState(false);
    useEffect(() => {
        Pubsub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.BrandManage });
        getDatas(paging, isAdmin ? TAB_SCREEN.LOTUS : TAB_SCREEN.CREATOR);
        setTabIndex(isAdmin ? TAB_SCREEN.LOTUS : TAB_SCREEN.CREATOR);
        if (isAdmin) {
            getCreators();
        }
    }, []);

    /** lấy danh sách creator */
    async function getCreators() {
        setBusy(true);
        setMsgBusy("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setBusy(false);
            setMsgBusy(null);
        } else {
            setCreatorOptions([]);
            setBusy(false);
            setMsgBusy(null);
        }
    }

    /** lấy danh sách nhãn hiệu */
    const getDatas = async (newPaging, index) => {
        setBusy(true);
        setMsgBusy("Đang tải dữ liệu...");
        let [err, data] = await BrandServices.GetListBrand(newPaging, index ? index : tabIndex, newPaging.status);
        if (!err) {
            setDatas(data ? data.listObjects : []);
            setPaging({ ...data.paging, status: newPaging.status });
        } else {
            setDatas([]);
            setPaging(initPaging);
        }
        setBusy(false);
        setMsgBusy(null);
    };

    /** lưu thành công */
    const handleSaveSucceed = (isContinue) => {
        if (isContinue) setBrand(initBrand);
        else setIsShowModal(false);
        getDatas(paging);
    };

    /** thêm mới */
    const _handleAdd = () => {
        if (isAdmin) {
            setBrand(initBrand);
            setIsShowModal(true);
        } else {
            history.push("/create-brand");
        }
    };

    /** chỉnh sửa */
    async function _handleEdit() {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            if (selected != null) {
                history.push(`/create-brand/${selected.id}`);
                // setBusy(true);
                // setMsgBusy("Đang xóa dữ liệu...");
                // const [err, data] = await BrandServices.GetById(selected.id);
                // if (!err && data) {
                //     setBusy(false);
                //     setMsgBusy(null);
                //     setBrand(data);
                //     setIsShowModal(true);
                // } else {
                //     setBusy(false);
                //     setMsgBusy(null);
                //     Notify(NOTIFY.INFO, "Thông báo", CONSTANTS.MSG_ERROR);
                // }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một thương hiệu để thực hiện. Vui lòng kiểm tra lại!");
    }

    /** xoá */
    const _handleDelete = () => {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMsgBusy("Đang xóa dữ liệu...");
                    const [err, data] = await BrandServices.Deletes(ids);
                    if (!err && data) {
                        setBusy(false);
                        setMsgBusy(null);
                        resolve({ title: "Thành công", msg: data ? data : "Xóa thành công" });
                        getDatas(paging);
                    } else {
                        setBusy(false);
                        setMsgBusy(null);
                        Notify(NOTIFY.INFO, "Thông báo", err.data ? err.data : CONSTANTS.MSG_ERROR);
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có thương hiệu nào được chọn. Vui lòng kiểm tra lại!");
    };

    /** thêm mới hoặc chỉnh sửa */
    const addEditAction = ({ dataChecked, isEnabled }) => {
        return (
            <div className="box__header">
                <Button btnType={ButtonType.Add} onClick={_handleAdd} label="Thêm" className="mx-1">
                    {Language.getValue("tableAction.add")}
                </Button>
                <Button btnType={ButtonType.Edit} onClick={() => (isEnabled ? _handleEdit() : {})} className={`mx-1 ${isEnabled ? "" : "disabled"}`}>
                    {Language.getValue("tableAction.edit")}
                </Button>
                <Button btnType={ButtonType.Delete} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})} className={`mx-1 ${dataChecked && dataChecked.length > 0 ? "" : "disabled"}`}>
                    {Language.getValue("tableAction.delete")}
                </Button>
            </div>
        );
    };

    /** duyệt thương hiệu */
    const approveAction = ({ dataChecked, isEnabled }) => {
        return (
            <div className="box__content mb-20">
                <Block flexBasis="100%" style={{ marginTop: "20px" }}>
                    <div className="flex__space-between mb-10 form-row">
                        <div className="flex__column flex__basis-20 col-4">
                            <label>Người tạo:</label>
                            <TFUSelect name="createdBy" className="item__input__select" options={creatorOptions} value={paging.createdBy} placeholder="--Chọn người tạo---" onChanged={(value) => setPaging({ ...paging, createdBy: value })} />
                        </div>
                        <div className="flex__column flex__basis-30 col-4">
                            <label>Tên thương hiệu:</label>
                            <Textbox className="form-control" name="keyword" onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} handleEnter={() => getDatas({ ...paging })} />
                        </div>
                        <div className="flex__column flex__basis-30 col-4">
                            <label>Trạng thái duyệt:</label>
                            <TFUSelect className="item__input__select" name="status" value={paging.status} options={STATUS_OPTIONS} placeholder="---Tất cả---" onChanged={(value) => setPaging({ ...paging, status: value != null ? value : -1 })} />
                        </div>
                    </div>
                    <div className="flex__row__start mt-10 w-30">
                        <div className="form-row">
                            <Button btnType={ButtonType.Search} label="Tìm kiếm" onClick={() => getDatas({ ...paging, pageNumber: 1 })} className="m-1" />
                            <Button className={isEnabled ? "m-1" : "disable m-1"} onClick={() => (isEnabled ? _handleShowApproved() : {})} bgColor={"#357a38"} color={"#fff"}>
                                <i className="fa fa-pencil m-r-5" />
                                Kiểm duyệt
                            </Button>
                        </div>
                    </div>
                </Block>
            </div>
        );
    };

    /** show duyệt thương hiệu */
    function _handleShowApproved() {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            if (selected != null) {
                setBrand(selecteds[0]);
                setShowApproved(true);
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một thương hiệu để thực hiện. Vui lòng kiểm tra lại!");
    }

    /** handle checkbox thương hiệu */
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };

    /** handle sort thương hiệu */
    const handleSortColumn = (column) => {
        let dir = "asc";
        if (column.name === paging.orderBy) dir = paging.orderDirection === "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: column.name,
            orderDirection: dir,
        };
        getDatas(newPaging);
    };

    /** handle checkbox dòng dữ liệu */
    const _handleClickRow = (indexItem, event) => {
        let newData = [...datas];
        newData[indexItem].selected = !newData[indexItem].selected;
        setDatas(newData);
    };

    /** handle detail dữ liệu */
    function _handleClickDetail(e, item) {}

    /** handle thay đổi tab */
    function _handleChangeTab(idx) {
        if (idx != tabIndex) {
            setTabIndex(idx);
            getDatas({ ...initPaging, status: -1 }, idx);
        }
    }

    /** render action */
    const _renderAction = () => {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }

        if (isAdmin) {
            switch (tabIndex) {
                case TAB_SCREEN.CREATOR:
                    return approveAction({ dataChecked, isEnabled });
                case TAB_SCREEN.LOTUS:
                    return addEditAction({ dataChecked, isEnabled });
                default:
                    break;
            }
        } else {
            switch (tabIndex) {
                case TAB_SCREEN.CREATOR:
                    return addEditAction({ dataChecked, isEnabled });
                case TAB_SCREEN.LOTUS:
                    break;
                default:
                    break;
            }
        }
    };

    /** render header */
    const _renderHeader = () => (
        <tr className="text-center">
            <th width={"10%"} className="center">
                <CheckBox>
                    <input
                        type="checkbox"
                        className="checkbox-round"
                        id="select_all"
                        name="select_all"
                        checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length}
                        label=""
                        onChange={(e) => _handleChecked(-1, e.target.checked)}
                    />
                </CheckBox>
            </th>
            {isAdmin && tabIndex == TAB_SCREEN.CREATOR && <th width="200">Người tạo</th>}
            {columns &&
                columns.length > 0 &&
                columns.map((column, i) => {
                    let icon = <i className="fa fa-sort-thead fa-sort" />;
                    if (column.name.toLowerCase() == paging.orderBy.toLowerCase()) icon = paging.orderDirection.toUpperCase() === "DESC" ? <i className="fa fa-sort-thead fa-sort-desc" /> : <i className="fa fa-sort-thead fa-sort-asc" />;

                    return (
                        <th key={i} width={column.width ? column.width : "auto"} className={`cursor-pointer${column.align ? ` text-${column.align}` : ""}`} onClick={column.isSort ? handleSortColumn(column) : () => {}}>
                            {column.isSort ? icon : ""}
                            <span>{column.displayName}</span>
                        </th>
                    );
                })}
            {(!isAdmin || tabIndex == TAB_SCREEN.CREATOR) && <th width="15%">Trạng thái duyệt</th>}
        </tr>
    );

    /** render body */
    const _renderBody = () => {
        if (datas && datas.length > 0)
            return datas.map((item, i) => {
                let status = STATUS_OPTIONS.find((x) => x.value == item.status);
                let statusName = "Chờ duyệt";
                if (status) {
                    statusName = status.label;
                }
                return (
                    <tr key={i} onClick={_handleClickRow.bind(this, i)}>
                        <td className="center">
                            <CheckBox>
                                <input type="checkbox" className="checkbox-round" id="select_all" name="select_all" checked={item.selected ? item.selected : false} label="" onChange={(e) => _handleChecked(i, e.target.checked)} />
                            </CheckBox>
                        </td>
                        {isAdmin && tabIndex == TAB_SCREEN.CREATOR && (
                            <td>
                                <div>
                                    <strong>{item.createdBy}</strong>
                                    <i
                                        className="fa fa-info-circle cursor-pointer"
                                        style={{ color: "#64c5b1" }}
                                        onClick={(e) => {
                                            _handleClickDetail(e, item);
                                        }}
                                    ></i>
                                </div>
                                <small>{item.createdDateDisplay}</small>
                            </td>
                        )}

                        {columns.map((column, j) => {
                            let align = column.align ? column.align : "";
                            return column.type === "bool" ? (
                                <td className="center" key={j}>
                                    {item[column.name] ? <span className="status active">Hoạt động</span> : <span className="status danger">Không hoạt động</span>}
                                </td>
                            ) : column.type === "price" ? (
                                <td key={j}>{item[column.name] ? <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix="đ" value={item[column.name]} /> : ""}</td>
                            ) : column.type === "image" ? (
                                <td key={j}>{item[column.name] ? <img src={item[column.name]} style={{ width: 50, height: 50 }} /> : ""}</td>
                            ) : (
                                <td className={align} key={j}>
                                    <span dangerouslySetInnerHTML={{ __html: item[column.name] ? item[column.name] : "" }} />
                                </td>
                            );
                        })}
                        {(!isAdmin || tabIndex == TAB_SCREEN.CREATOR) && <td>{<RenderStatus i={item.status} />}</td>}
                    </tr>
                );
            });
        return (
            <tr>
                <td colSpan="100" className="center">
                    {Language.getValue("common.noRecords")}
                </td>
            </tr>
        );
    };

    /** render tab hiển thị */
    const _renderTab = () => {
        return (
            <React.Fragment>
                {isAdmin ? (
                    <React.Fragment>
                        <ButtonTab className={`${tabIndex == TAB_SCREEN.LOTUS ? "active" : ""} mx-1`} title="Thương hiệu Ranus" onClick={() => _handleChangeTab(TAB_SCREEN.LOTUS)} />
                        <ButtonTab className={`${tabIndex == TAB_SCREEN.CREATOR ? "active" : ""} mx-1`} title="Thương hiệu Creator" onClick={() => _handleChangeTab(TAB_SCREEN.CREATOR)} />
                    </React.Fragment>
                ) : (
                    <ButtonTab className={`${tabIndex == TAB_SCREEN.CREATOR ? "active" : ""} mx-1`} title="Danh sách thương hiệu" onClick={() => _handleChangeTab(TAB_SCREEN.CREATOR)} />
                )}
            </React.Fragment>
        );
    };

    /** main return */
    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title="Quản lý thương hiệu" />
            <Loading show={isBusy} msg={msgBusy} />
            {isShowModal && <AddOrEdit isShowModal={isShowModal} brand={brand} onClose={() => setIsShowModal(false)} onSaveSucceed={handleSaveSucceed} history={props.history} />}
            {/* /**nếu require thêm require*/}
            {supportControl ? <SupportControl /> : ""}
            {showApproved && (
                <BrandApproved
                    isShow={showApproved}
                    brand={brand}
                    statusOptions={STATUS_OPTIONS}
                    onClose={() => setShowApproved(false)}
                    onSaveSucceed={() => {
                        setShowApproved(false);
                        getDatas(paging);
                    }}
                />
            )}
            {!isAdmin && <BrandLevelDashboard />}
            <CommonTable renderTab={_renderTab} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} paging={paging} onFilter={(paging) => getDatas(paging)} />
        </React.Fragment>
    );
}

const RenderStatus = ({ i }) => {
    const CAMPAIGN_BOOSTING_STATUS_NAME = {
        0: "Chờ duyệt",
        1: "Đã duyệt",
        2: "Đang chạy",
        3: "Kết thúc",
    };

    var cls = "";
    switch (i) {
        case 1:
            cls += "#4caf50";
            break;
        case 0:
            cls += "#ff8f00";
            break;
        case -1:
            cls += "#4caf50";
        default:
            cls += "#ff1744";
            break;
    }
    return (
        <div className="flex-center">
            <Button bgColor={cls} color="#fff" title={CAMPAIGN_BOOSTING_STATUS_NAME[i]}></Button>
        </div>
    );
};

const BrandLevelDashboard = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [msgBusy, setMsgBusy] = useState("");
    const [dashboard, setDashboard] = useState({});

    useEffect(() => {
        getDashboard("");
    }, []);

    async function getDashboard(userName) {
        setBusy(true);
        setMsgBusy("Đang tải dữ liệu...");
        let [error, data] = await BrandServices.GetBrandConfigByTier(userName);
        if (!error && data) {
            setDashboard(data);
            setBusy(false);
            setMsgBusy(null);
        } else {
            setDashboard({});
            setBusy(false);
            setMsgBusy(null);
        }
    }
    const renderHeader = () => {
        return (
            <tr>
                <th style={{ width: "30%" }}>Tier</th>
                <th style={{ width: "30%" }}>Số lượng thương hiệu được tạo</th>
                <th style={{ width: "30%" }}>Số lượng thương hiệu đã tạo</th>
            </tr>
        );
    };

    const renderBody = () => {
        return (
            <tr>
                <td>
                    <span>{dashboard ? dashboard.tierName : ""}</span>
                </td>
                <td className="text-center">
                    <span>{dashboard ? dashboard.configNumber : 0}</span>
                </td>
                <td className="text-center">
                    <span>{dashboard ? dashboard.currentValidNumber : 0}</span>
                </td>
            </tr>
        );
    };

    return <TableWidget title="Thông tin Tier hiện tại" renderHeader={renderHeader} renderBody={renderBody} />;
};
