import React, { useEffect, useState } from "react";
import { NOTIFY, CONSTANTS, VOUCHER_STATUS, VOUCHER_REGISTER_STATUS } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import VoucherServices from "../based/services/VoucherServices";
import Textbox from "../based/inputs/Textbox";
import Common from "../based/Common";
import NumberFormat from "react-currency-format";
import CommonTable from "../based/CommonTable";
import CheckBox from "../based/inputs/CheckBox";

export default function VoucherProductsRegister(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);
    const [productsSelected, setProductsSelected] = useState([]);

    useEffect(() => {
        getProductIds(props.registerType, true);
    }, [props.isShowModal]);

    async function getProductIds(type, isGetDatas) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await VoucherServices.GetIdsVoucherPromoteProductsToRegister(props.voucherId, type);
        if (!error && data) {
            setProductsSelected(data);
            if (isGetDatas) {
                getDatas({ ...Common.Paging, registerType: props.registerType, voucherId: props.voucherId }, data);
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setProductsSelected([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function getDatas(newPaging, productIds) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await VoucherServices.GetVoucherPromoteProductsPagingToRegister(newPaging);
        if (!error && result) {
            let products = result.listObjects;
            if (products && products.length > 0) {
                products.map((product) => {
                    let productsSelectedUp = productIds && productIds.length > 0 ? productIds : [...productsSelected];
                    if (productsSelectedUp && productsSelectedUp.length > 0) {
                        let existed = productsSelectedUp.find((x) => x == product.ageVariantId);
                        product.isSelected = existed != null;
                    }
                });
            }
            setDatas(products);
            setPaging(result.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function _handleSave() {
        if (productsSelected && productsSelected.length > 0) {
            let model = {
                voucherId: props.voucherId,
                ageVariantIds: productsSelected,
                isSelecedAll: productsSelected.length == paging.totalRecord,
            };
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu...");
            let [error, data] = await VoucherServices.SaveJoinVoucherPromote(model);
            if (!error && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed();
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.ERROR, "Lỗi", error.data ? error.data : CONSTANTS.MSG_ERROR);
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn Sản phẩm tham gia Voucher");
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) {
            newDatas.forEach((m) => (m.isSelected = value));
            if (value) getProductIds(0);
            else setProductsSelected([]);
        } else {
            newDatas[index].isSelected = value;
            let productsSelectedUp = productsSelected && productsSelected.length > 0 ? [...productsSelected] : [];
            if (productsSelectedUp.includes(newDatas[index].ageVariantId)) {
                let idx = productsSelectedUp.indexOf(newDatas[index].ageVariantId);
                productsSelectedUp.splice(idx, 1);
            } else {
                productsSelectedUp.push(newDatas[index].ageVariantId);
            }
            setProductsSelected(productsSelectedUp);
        }
        setDatas(newDatas);
    };
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <CheckBox
                            disabled={props.registerStatus != VOUCHER_REGISTER_STATUS.GOING_ON}
                            id='select_all'
                            name='select_all'
                            checked={productsSelected.length == paging.totalRecord}
                            label=''
                            onCheckedChange={(name, value) => _handleChecked(-1, value)}
                        />
                    </th>
                    <th width='150' className='text-center'>
                        Hình ảnh
                    </th>
                    <th className='text-center'>Tên sản phẩm</th>
                    <th width='100' className='text-center'>
                        Loại in
                    </th>
                    <th width='150' className='text-center'>
                        Giá sản phẩm (đ)
                    </th>
                    <th width='150' className='text-center'>
                        Giá Loyalty (đ)
                    </th>
                    <th width='150' className='text-center'>
                        Giá Buyer mua (đ)
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderAttributes(item) {
        if (item.attributeValuesDetail && item.attributeValuesDetail.length > 0) {
            let arr = [];
            item.attributeValuesDetail.map((attribute, idx) => {
                arr.push(
                    <span key={idx} className='margin-bottom-0 font-italic'>
                        {attribute.name} : <span className='badge badge-custom'>{attribute.displayName}</span>
                    </span>
                );
                if (idx < item.attributeValuesDetail.length - 1) arr.push(<span className='m-r-5'>,</span>);
            });
            return arr;
        }
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center vertical-align-middle'>
                                    <CheckBox
                                        disabled={props.registerStatus != VOUCHER_REGISTER_STATUS.GOING_ON}
                                        id={i.toString()}
                                        name={i.toString()}
                                        checked={item.isSelected ? item.isSelected : false}
                                        label=' '
                                        onCheckedChange={(name, value) => _handleChecked(i, value)}
                                    />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <a className='iconImageDefault full-width' href='#'>
                                        <img src={item.avatar} alt={item.productName} className='variant-avatar' />
                                    </a>
                                </td>
                                <td className='vertical-align-middle'>
                                    <strong> {item.productName}</strong>
                                    {item.sellerSKU && <p className='margin-bottom-0 font-italic'>SKU: {item.sellerSKU ? item.sellerSKU : "----"}</p>}
                                    {_renderAttributes(item)}
                                    {/* {item.attributeValuesDetail &&
                                        item.attributeValuesDetail.length > 0 &&
                                        item.attributeValuesDetail.map((attribute, at) => {
                                            return (
                                                <p key={at} className='margin-bottom-0 font-italic'>
                                                    {attribute.name} : <span className='badge badge-custom'>{attribute.displayName}</span>
                                                </p>
                                            );
                                        })} */}
                                </td>
                                <td className='text-center vertical-align-middle'>{item.printTypeName}</td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.voucherProductPrice} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.voucherLoyaltyAmount} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.voucherSellPrice} />
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <Textbox placeholder='SellerSKU' className='form-control' name='sellerSKU' onChanged={(name, value) => setPaging({ ...paging, sellerSKU: value })} value={paging.sellerSKU} />
                        </div>
                        <div className='form-group col-md-4'>
                            <Textbox placeholder='Tên sản phẩm' className='form-control' name='name' onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                        </div>
                        <div className='form-group col-md-4'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className='fa fa-search m-r-5' /> Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _renderButtonSave() {
        return (
            <React.Fragment>
                <span className='badge badge-custom m-r-5' style={{ fontSize: 15 }}>
                    Số sản phẩm đang chọn: <strong style={{ color: "red" }}>{productsSelected.length}</strong>
                </span>
                {(props.registerStatus == VOUCHER_REGISTER_STATUS.GOING_ON || props.voucherStatus == VOUCHER_STATUS.GOING_ON) && (
                    <button className={productsSelected.length > 0 ? "btn btn-custom btn-sm" : "btn btn-custom btn-sm disable"} onClick={() => (productsSelected.length > 0 ? _handleSave() : {})}>
                        <i className='fa fa-save m-r-5' /> Lưu
                    </button>
                )}
            </React.Fragment>
        );
    }
    return (
        <CommonModal show={props.isShowModal} isBusy={isLoading} busyMsg={msg} size='lg' title='Chọn sản phẩm' customButton={_renderButtonSave()} onClose={() => props.onClose()}>
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </CommonModal>
    );
}
