import BaseServices from "./BaseServices";

const MessPhotoServices = {
    GetGroupDTO: async (paging) => {
        return BaseServices.Post(`/api/messPhoto/get-group-dto`, paging);
    },
    SetIsDeleteGroupDTO: async (id) => {
        return BaseServices.Get(`/api/messPhoto/set-is-delete-group-dto/${id}`);
    },
    GetGroupDTOById: async (id) => {
        return BaseServices.Get(`/api/messPhoto/get-group-dto-by-id/${id}`);
    },
    CreateGroupMess: async (model) => {
        return BaseServices.Post(`/api/messPhoto/create-group-mess`, model);
    },
    UpdateGroupMess: async (model) => {
        return BaseServices.Post(`/api/messPhoto/update-group-mess`, model);
    },
    CreateFolderMess: async (model) => {
        return BaseServices.Post(`/api/messPhoto/create-folder-mess`, model);
    },
    UpdateFolderMess: async (model) => {
        return BaseServices.Post(`/api/messPhoto/update-folder-mess`, model);
    },
    GetFolderMessById: async (id) => {
        return BaseServices.Get(`/api/messPhoto/get-folder-mess-by-id/${id}`);
    },
    GetListFolderMess: async (paging) => {
        return BaseServices.Post(`/api/messPhoto/get-list-folder-mess`, paging);
    },
    AddRangeFolderConnection: async (model) => {
        return BaseServices.Post(`/api/messPhoto/add-range-folder-connection`, model);
    },
    DeleteRangeFolderConnection: async (model) => {
        return BaseServices.Post(`/api/messPhoto/delete-range-folder-connection`, model);
    },
    RemoveFolderMess: async (id) => {
        return BaseServices.Get(`/api/messPhoto/remove-folder-mess/${id}`);
    },
    GetListPhotoGroupByFolderId: async (folderId) => {
        return BaseServices.Get(`/api/messPhoto/get-list-photo-group-by-folder-id/${folderId}`);
    },
    CreateMultipleGroupMess: async (models) => {
        return BaseServices.Post(`/api/messPhoto/create-multiple-group-mess`, models);
    },
};

export default MessPhotoServices;
