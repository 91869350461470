const THIRD_PARTY_PLANS = [
    {
        id: 1,
        name: 'Basic'
    },
    {
        id: 2,
        name: 'Premium'
    }
]

export default THIRD_PARTY_PLANS;