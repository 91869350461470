import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Pubsub from "pubsub-js";
import { Layout } from "../layout/Layout";
import { CONSTANTS, ORDER_TYPE, MAINMENU, STOCK_IN_STATUS, STOCK_IN_STATUS_NAME, ROLES } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import WarehouseServices from "../based/services/WarehouseServices";
import CommonTable from "../based/CommonTable";
import Loading from "../based/Loading";
import StockInModal from "./StockInModal";
import AccountServices from "../based/services/AccountServices";
import StockInPrinter from "./printer/StockInPrinter";

const initPaging = {
    pageSize: 10,
    pageNumber: 1,
    orderTypes: ORDER_TYPE.StockIn,
};

//Dùng để exclude các status không muốn show trên screen
const excludeStatus = [STOCK_IN_STATUS.RejectApproval];
const TAB_INDEX = { ...STOCK_IN_STATUS, ALL: 0 };
const TAB_INDEX_NAME = { ...STOCK_IN_STATUS_NAME, ALL: "Tất cả" };
const selectedTabIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export default function StockInHistory(props) {
    const [isBusy, setIsBusy] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const [readOnlyDetail, setReadOnlyDetail] = useState(true);
    const [orderId, setOrderId] = useState(0);
    const [paging, setPaging] = useState(initPaging);
    const [orders, setOrders] = useState([]);
    const [tabIndex, setTabIndex] = useState(TAB_INDEX.ALL);
    //Status của button
    const [orderBtnStatus, setOrderBtnStatus] = useState(0);
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        // _fetchData(paging);
        showScreen();
        Pubsub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.StockInHistory });
        setIsBusy(false);
    }, []);

    async function showScreen() {
        setIsBusy(true);
        let [error, data] = await AccountServices.GetRolesByUser();
        if (!error && data) {
            setRoles(data);
            setIsBusy(false);
            if (!data.find((x) => x.toUpperCase() == ROLES.PRINTERMANAGER)) _fetchData(paging);
        } else {
            setRoles(null);
            setIsBusy(false);
            window.location.href = "/access-denied";
        }
    }

    function _renderScreen() {
        if (roles && roles.length > 0) {
            return (
                <React.Fragment>
                    <div className='row'>
                        <div className='col-12 h-65px'>
                            <div className='page-title-box p-x-25'>
                                <h4 className='page-title float-left'>Nhập kho</h4>
                                <ol className='breadcrumb float-right'>
                                    <li className='breadcrumb-item'>
                                        <a>Kho</a>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        <a>Nhập kho</a>
                                    </li>
                                </ol>
                                <div className='clearfix' />
                            </div>
                        </div>
                    </div>
                    {roles.find((x) => x.toUpperCase() == ROLES.PRINTERMANAGER) ? (
                        <StockInPrinter />
                    ) : (
                        <React.Fragment>
                            <ul className='nav nav-tabs tabs-bordered'>{_renderTabIndex()}</ul>
                            <CommonTable onFilter={_fetchData} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} />
                            {orderId > 0 && showDetail && (
                                <StockInModal
                                    isShowModal={showDetail}
                                    readOnly={readOnlyDetail}
                                    orderId={orderId}
                                    onClose={(saveSuccess) => {
                                        if (saveSuccess) _fetchData(paging);
                                        setShowDetail(false);
                                    }}
                                    orderBtnStatus={orderBtnStatus}
                                    handleChangeStatus={(orderId, status) => _handleServicesChangeStatus(orderId, status)}
                                />
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        }
    }

    // ADD NEW
    function _renderTabIndex() {
        let resultTabIndex = Object.entries(TAB_INDEX).reduce((prev, val) => (selectedTabIndex.indexOf(val[1]) >= 0 ? [...prev, [val[1], val[0]]] : prev), []);
        let newMap = new Map(resultTabIndex);
        let tempIndex = selectedTabIndex.map((item) => {
            return [item, newMap.get(item)];
        });

        return tempIndex.map((item, index) => {
            return (
                <li key={index} className='nav-item'>
                    <a className={tabIndex == item[0] ? "nav-link active" : "nav-link"} onClick={() => _handleChangeTab(item[0])}>
                        <span className='d-none d-sm-block'>{TAB_INDEX_NAME[item[1]]}</span>
                    </a>
                </li>
            );
        });
    }

    function _handleChangeTab(index) {
        setTabIndex(index);
        let datas = { ...paging, status: index };
        _fetchData(datas);
        setPaging(datas);
    }

    async function _fetchData(pagingModel) {
        const [err, data] = await WarehouseServices.GetOrderHistory(pagingModel);
        if (!err) {
            setOrders(data.listObjects);
            setPaging(data.paging);
        } else {
            setOrders([]);
        }
    }

    function _handleDeleteOrder(orderId) {
        ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn muốn xóa dữ liệu đã chọn", () => {
            return new Promise(async (resolve, reject) => {
                const [err, data] = await WarehouseServices.DeleteOrder(orderId);
                if (!err) {
                    await _fetchData(initPaging);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    reject({ title: "Có lỗi", msg: "Xóa gặp lỗi." });
                }
            });
        });
    }

    //Status Button:
    //Duyệt: Show Modal, Readonly, Show 2 Button (Duyệt - Từ chối)
    //Xử lý yêu cầu: Show Modal, Được quyền chỉnh sửa, Show Button (Nhận hàng)
    //Đang chờ xác nhận: Show Modal, Được quyền chỉnh sửa, Show Button (Đang chờ xác nhận)
    function _handleChangeStatus(orderId, nextStatus) {
        switch (parseInt(nextStatus)) {
            case STOCK_IN_STATUS.Approved:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(false);
                break;
            case STOCK_IN_STATUS.InboundingProcess:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(false);
                break;
            case STOCK_IN_STATUS.PendingApproval:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(false);
                break;
            case STOCK_IN_STATUS.Completed:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(true);
                break;
            case STOCK_IN_STATUS.Cancelled:
                ConfirmDialog("Xác nhận", "Bạn có chắc chắn muốn hủy đơn đã chọn?", () => {
                    return new Promise((resolve, reject) => {
                        _handleServicesChangeStatus(orderId, nextStatus);
                        resolve({ title: "Thành công", msg: "Hủy thành công." });
                    });
                });
                break;
            default:
                _handleServicesChangeStatus(orderId, nextStatus);
                break;
        }
    }

    async function _handleServicesChangeStatus(orderId, nextStatus) {
        setIsBusy(true);
        const [err, data] = await WarehouseServices.ChangeStatus(orderId, nextStatus);
        setIsBusy(false);
        if (!err) {
            let updateOrders = [...orders];
            let order = updateOrders.filter((m) => m.id == orderId)[0];
            order.status = data.status;
            order.statusId = data.statusId;
            order.statusColor = data.statusColor;
            order.nextStatus = data.nextStatus;
            setOrders(updateOrders);
            setOrderBtnStatus(0);
            _fetchData(paging);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className={"center"}>Số chứng từ</th>
                    <th className={"center"}>Trạng thái lưu kho</th>
                    <th className={"center"}>Ngày tạo đơn</th>
                    <th className={"center"}>Thời gian dự kiến nhập</th>
                    <th className={"center"}>Đơn vị cung cấp</th>
                    <th className={"center"}>Mã sản phẩm</th>
                    <th className={"center"}>Số lượng SP</th>
                    <th className={"center"}>Số lượng SP lỗi</th>
                    <th className={"center"}></th>
                </tr>
            </thead>
        );
    }

    function __renderActionButtons(order) {
        if (order.nextStatus && order.nextStatus.length > 0) {
            return (
                <DropdownButton
                    id='dropdown-basic-button'
                    title={
                        <span>
                            <i className='fa fa-cog m-r-5' />
                            Thao tác
                        </span>
                    }
                    variant='custom'
                    size='sm'
                >
                    {order.nextStatus.reduce(
                        (prev, status, index) =>
                            !excludeStatus.includes(parseInt(status.value))
                                ? status.value == STOCK_IN_STATUS.PendingApproval
                                    ? [
                                          ...prev,
                                          <Dropdown.Item key={index} onClick={() => _handleChangeStatus(order.id, status.value)}>
                                              Xác nhận
                                          </Dropdown.Item>,
                                      ]
                                    : [
                                          ...prev,
                                          <Dropdown.Item key={index} onClick={() => _handleChangeStatus(order.id, status.value)}>
                                              {status.label}
                                          </Dropdown.Item>,
                                      ]
                                : prev,
                        []
                    )}
                    {order.statusId == STOCK_IN_STATUS.PendingApproval ? (
                        <Dropdown.Item key={-1} onClick={() => _handleDeleteOrder(order.id)}>
                            Xóa
                        </Dropdown.Item>
                    ) : null}
                    {(order.statusId == STOCK_IN_STATUS.PartiallyReceived || order.statusId == STOCK_IN_STATUS.CompletelyReceived || order.statusId == STOCK_IN_STATUS.PendingApproval) && (
                        <Dropdown.Item
                            key={-2}
                            onClick={() => {
                                setOrderId(order.id);
                                setOrderBtnStatus(-1);
                                setShowDetail(true);
                                setReadOnlyDetail(true);
                            }}
                        >
                            Chi tiết
                        </Dropdown.Item>
                    )}
                </DropdownButton>
            );
        }
        // HIỂN THỊ KHI TRẠNG THÁI LÀ "CANCELLED"
        return (
            <DropdownButton
                id='dropdown-basic-button'
                title={
                    <React.Fragment>
                        <i className='fa fa-cog m-r-5' />
                        Thao tác
                    </React.Fragment>
                }
                variant='custom '
                size='sm'
            >
                <Dropdown.Item
                    key={-2}
                    onClick={() => {
                        setOrderId(order.id);
                        setOrderBtnStatus(-1);
                        setShowDetail(true);
                        setReadOnlyDetail(true);
                    }}
                >
                    Chi tiết
                </Dropdown.Item>
            </DropdownButton>
        );
    }

    function _renderBody() {
        var body =
            orders && orders.length > 0 ? (
                orders.map((order, idx) => (
                    <tr key={idx}>
                        <td>{order.orderCode}</td>
                        <td style={{ color: order.statusColor }}>{order.status}</td>
                        <td>{order.createdDateString}</td>
                        <td>{order.estimatedTimeString}</td>
                        <td>{order.thirdPartyName}</td>
                        <td>{order.productCodes}</td>
                        <td>{order.totalGoodProducts + order.totalBadProducts}</td>
                        <td>{order.totalBadProducts}</td>
                        <td>{__renderActionButtons(order)}</td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan='9'>Không có dữ liệu</td>
                </tr>
            );

        return <tbody>{body}</tbody>;
    }

    function _renderAction() {
        return (
            <React.Fragment>
                <button
                    type='button'
                    onClick={(e) => {
                        e.preventDefault();
                        let history = props.history;
                        history.push("/stock-in");
                    }}
                    className='btn btn-custom btn-sm m-r-5 mt-2'
                >
                    <i className='fa fa-cog m-r-5' /> Tạo lệnh nhập hàng
                </button>
            </React.Fragment>
        );
    }
    return <React.Fragment>{isBusy ? <Loading show={true} msg={"Đang tải dữ liệu..."} fullScreen /> : _renderScreen()}</React.Fragment>;
}
