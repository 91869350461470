import React, { useState, useRef, useEffect } from 'react';
import { Layout } from '../layout/Layout';
import Loading from '../based/Loading';
import { NOTIFY, CONSTANTS } from '../based/Constants';
import { Notify } from '../based/Notify';
import Common from '../based/Common';
import Breadcrumbs from '../based/Breadcrumbs';
import CommonTable from '../based/CommonTable';
import CheckBox from '../based/inputs/CheckBox';
import Language from '../based/Language';
import { WareslotsModel, WarehousesModel } from '../models/WarehousesModel';
import WarehouseServices from '../based/services/WarehouseServices';
import CommonServices from '../based/services/CommonServices';
import WarehouseModal from './WarehouseModal';

const initPage = {
  pageSize: 10,
  pageNumber: 1,
  orderBy: 'id',
  orderDirection: 'asc',
};

export default function Wareslot(props) {
  const [isBusy, setBusy] = useState(false);
  const [msg, setMsg] = useState(null);
  const [datas, setDatas] = useState([new WareslotsModel()]);
  const [paging, setPaging] = useState(initPage);
  const tableRef = useRef(null);
  const [tableGuid, setTableGuid] = useState(Common.generateGuid);
  const [warehouseId, setWarehouseId] = useState(0);
  const [provinces, setProvinces] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [warehouse, setWarehouse] = useState(new WarehousesModel());

  useEffect(() => {
    let paramId = props.match.params.warehouseId;
    if (paramId > 0) {
      setWarehouseId(paramId);
      GetProvinces();
    }
  }, []);

  useEffect(() => {
    getDatas(paging);
  }, [warehouseId]);

  async function getDatas(newPaging) {
    setBusy(true);
    setMsg('Đang tải dữ liệu...');
    if (warehouseId > 0 && newPaging) {
      let [err, data] = await WarehouseServices.GetWareslot(warehouseId, newPaging);
      if (!err) {
        setDatas(data.listObjects);
        setPaging(data.paging);
      } else {
        Notify(NOTIFY.WARNING, 'Lỗi', 'Xin vui lòng thử lại sau.');
      }
      setBusy(false);
      setMsg('');
    }
  }

  async function GetProvinces() {
    const [err, data] = await CommonServices.GetProvinces();
    if (!err) setProvinces(data);
  }

  function _renderHeader() {
    return (
      <thead>
        <tr className="text-center">
          <th width={40} className="center"></th>
          <th className="cursor-pointer text-center">Mã vị trí</th>
          <th className="cursor-pointer">Tổng sản phẩm</th>
          <th className="cursor-pointer" width={200}>
            Tỉ lệ chiếm chỗ
          </th>
          <th className="cursor-pointer" width={200}>
            Tên loại hàng đang chứa
          </th>
          <th className="cursor-pointer text-center" width={150}>
            Trạng thái
          </th>
        </tr>
      </thead>
    );
  }

  function _renderAction() {
    let dataChecked = null;
    let isEnableEdit = false;
    if (datas && datas.length > 0) {
      dataChecked = datas.filter((x) => x.checked);
      if (dataChecked && dataChecked.length == 1) isEnableEdit = true;
    }
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="row flex-row p-y-10">
            <div className="m-r-5">
              <div className="form-group">
                <button className={'btn btn-custom btn-sm' + (isEnableEdit ? '' : ' disable')} onClick={_handleEdit}>
                  <i className="fa fa-edit m-r-5" />
                  {Language.getValue('tableAction.edit')}
                </button>
              </div>
            </div>
            <div className="m-r-5">
              <div className="form-group">
                <button className={'btn btn-danger btn-sm' + (dataChecked && dataChecked.length > 0 ? '' : ' disable')} onClick={_handleDelete}>
                  <i className="fa fa-remove m-r-5" />
                  {Language.getValue('tableAction.delete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function _renderBody() {
    if (datas && datas.length > 0) {
      let contents = [];
      let groupItems = Common.GroupArray(datas, 'regionName');
      groupItems.forEach((value, key, map) => {
        contents.push(
          <React.Fragment key={key}>
            {key && (
              <tr onClick={() => _handleCollapse(key, 0)} className="tr__group">
                <td colSpan={6}>
                  <strong>Khu vực: {key}</strong>
                </td>
              </tr>
            )}
            {value.map((item, idx) => {
              return (
                <tr key={idx} className="center" onClick={() => _handleClickRow(item)}>
                  <td className="center vertical-align-middle">
                    <CheckBox id={`${item.warehouseId.toString()}_${item.code.toString()}`} checked={item.checked} name={`${item.warehouseId.toString()}_${item.code.toString()}`} label=" " onCheckedChange={(name, value) => _handleClickRow(item)} />
                  </td>
                  <td>
                    <span>{item._SlotCode}</span>
                  </td>
                  <td>
                    <span>{item.totalProduct}</span>
                  </td>
                  <td>
                    <span>{item.usageRate}</span>
                  </td>
                  <td>
                    <span>{item.categoriesName}</span>
                  </td>
                  <td width={60} className="center vertical-align-middle">
                    {item.isActive ? <i className="fa fa-check fa-lg color-green" /> : <i className="fa fa-minus-circle fa-lg color-red" />}
                  </td>
                </tr>
              );
            })}
          </React.Fragment>
        );
      });
      return <tbody>{contents.map((m) => m)}</tbody>;
    }
    return (
      <tbody>
        <tr>
          <td colSpan={6} className="center">
            Không có dữ liệu
          </td>
        </tr>
      </tbody>
    );
  }

  async function _handleEdit() {
    if (warehouseId > 0) {
      let [err, data] = await WarehouseServices.GetWarehouseById(warehouseId);
      if (!err) {
        setWarehouse(data);
        setIsShowModal(true);
      }
    }
  }

  async function _handleDelete() {
    let updatedData = [...datas];
    let filterData = updatedData.filter((item) => {
      return item.checked;
    });
    let item = filterData[0];

    if (item) {
      if (item.totalProduct > 0) {
        Notify(NOTIFY.WARNING, 'Lỗi', `Vị trí hiện tại đang có ${item.totalProduct} sản phẩm. Xin vui lòng chuyển sản phẩm sang vị trí khác trước khi xoá.`);
      } else {
        let [err, data] = await WarehouseServices.DeleteSlot(item.code, warehouseId);
        if (!err) {
          getDatas(paging);
          Notify(NOTIFY.SUCCESS, 'Thành công', `Xoá thành công.`);
        } else {
          Notify(NOTIFY.WARNING, 'Lỗi', `Xin vui lòng thử lại sau ít phút.`);
        }
      }
    } else {
      Notify(NOTIFY.INFO, 'Thông tin', 'Xin vui lòng lựa chọn đơn hàng trước khi xoá');
    }
  }
  function _handleClickRow(slot) {
    let datasUpdate = [...datas];
    datasUpdate.map((item) => {
      if (item.code == slot.code) {
        item.checked = !item.checked;
      } else {
        item.checked = false;
      }
    });
    setDatas(datasUpdate);
  }

  function _handleSaveSucceed() {
    setIsShowModal(false);
    setTableGuid(Common.generateGuid());
    getDatas(paging);
  }

  function _handleCollapse() {}

  return (
    <Layout>
      <Loading show={isBusy} msg={msg} />
      <Breadcrumbs currentPage={localStorage.getItem(CONSTANTS.CURRENT_WAREHOUSE)} parentPage="Quản lý kho" hrefParent="/warehouse" />
      <CommonTable data={datas} paging={paging} ref={tableRef} tableGuid={tableGuid} searchBasic onFilter={getDatas} renderHeader={_renderHeader} renderAction={_renderAction} renderBody={_renderBody} />
      <WarehouseModal
        provinces={provinces}
        isShowModal={isShowModal}
        warehouse={warehouse}
        onClose={() => setIsShowModal(false)}
        onSave={(data) => {
          setIsShowModal(data), setTableGuid(Common.generateGuid());
        }}
        onSaveSucceed={_handleSaveSucceed}
      />
    </Layout>
  );
}
