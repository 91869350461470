import BaseServices from './BaseServices';

const StockOutServices = {
  GetOrderHistory: async (model) => {
    return await BaseServices.Post('/api/StockOut/get-orders', model);
  },
  ChangeStatus: async (orderId, status) => {
    let obj = { orderId, status };
    return await BaseServices.Post('/api/StockOut/change-status', obj);
  },
  DeleteOrder: async (orderId) => {
    return await BaseServices.Post('/api/StockOut/remove-order/' + orderId);
  },
  GetBatchCodeList: async (obj) => {
    return await BaseServices.Post('/api/StockOut/get-batchcode-list/', obj);
  },
  GetExtendDataOptions: async (batchCode) => {
    return await BaseServices.Get(`/api/StockOut/get-extend-data-options/${batchCode}`);
  },
  StockOut: async (model) => {
    return await BaseServices.Post('/api/StockOut/stock-out', model);
  },
  DeliveredProducts: async (obj) => {
    return await BaseServices.Post('/api/StockOut/delivered-products', obj);
  },
  GetApprovedQuantity: async (productId, productStatus, orderId, batchCode) => {
    return await BaseServices.Get(`/api/StockOut/get-approved-quantity/${productId}/${productStatus}/${orderId}/${batchCode}`);
  },
};

export default StockOutServices;
