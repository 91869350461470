import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import EnhanceAccountService from "../based/services/EnhanceAccountService"
import CheckBox from "../based/inputs/CheckBox";
import ModalAddOrUpdate from "./ModalAddOrUpdate";
import ModalImportExcel from "./ModalImportExcel"

export const initEnhanceAccount = {
    id: 0,
    username: "",
    password: "",
    credit: 0,
    isDelete: false,
    createdBy: "",
    createdDate: new Date(),
    modifiedBy: "",
    modifiedDate: new Date(),
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};
function EnhanceAccountManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowModalImport, setIsShowModalImport] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const [selectId, setSelectId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const tableRef = useRef(null);


    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await EnhanceAccountService.GetEnhanceAccountByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await EnhanceAccountService.DeteleEnhanceAccount(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có account nào được chọn. Vui lòng kiểm tra lại!");
    };


    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th width={40} className="center">
                        <CheckBox id="select_all" name="select_all" checked={data && data.length > 0 && data.filter((x) => x.selected).length === data.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Credit</th>
                    <th>IsDelete</th>
                    <th>Thông tin tạo</th>
                    <th>Thông tin chỉnh sửa</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };
    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    <strong>{item.username}</strong>
                                </td>
                                <td>
                                   {item.password}
                                </td>
                                <td>
                                   {item.credit}
                                </td>
                                <td>
                                   {item.isDelete ? "True": "False"}
                                </td>
                                <td style={{ width: 300, textAlign: "center" }}>
                                    <div>Người tạo: {item.createdBy}</div>
                                    <div>Ngày tạo: {Common.formatDate(new Date(item.createdDate), "fulldate")}</div>
                                </td>
                                <td style={{ width: 300, textAlign: "center" }}>
                                    {
                                        item.modifiedBy && item.modifiedDate ?
                                        <div>
                                        <div>Người sửa: {item.modifiedBy}</div>
                                        <div>Ngày sửa: {Common.formatDate(new Date(item.modifiedDate), "fulldate")}</div>
                                        </div>
                                        : "Chưa chỉnh sửa"
                                    }
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }



    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn mb-3 btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>
                <button className={dataChecked && dataChecked.length > 0 ? "btn mb-3 btn-danger ml-3" : "btn mb-3 btn-danger ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
                <button for="upload-excel" className="btn mb-3 ml-3 btn-custom" onClick={() => {
                            setIsShowModalImport(true)

                }}>
                    <i className="fa fa-upload m-r-5" /> Import Excel
                </button>
            </React.Fragment>
        );
    }
 


    return ( 
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý tài khoản enhance' />
            <Loading show={isBusy} msg={msg} />
            <CommonTable 
                placeholderSearch="Tên tài khoản"
                data={data} 
                paging={paging}
                searchBasic
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                ref={tableRef}
                onFilter={(paging)=>getData(paging)}
                />
               
                 {isShowModal && (
                    <ModalAddOrUpdate
                        isShowModal={isShowModal}
                        onClose={() => {
                            setIsShowModal(false);
                            setSelectId(0);
                        }}
                        onSaveContinue={() => {
                            setIsShowModal(false);
                            getData({ ...paging });
                        }}
                        id={selectId}
                    />
                )}
                {isShowModalImport && (
                    <ModalImportExcel
                        isShowModal={isShowModalImport}
                        onClose={() => {
                            setIsShowModalImport(false);
                        }}
                        onSaveContinue={() => {
                            setIsShowModalImport(false);
                            getData({ ...paging });
                        }}
                    />
                )}
        </React.Fragment>
    );
}

export default EnhanceAccountManagement;