import { OrderTagDetailModel } from "./OrderTagDetailModel";
import { OrderTagItemModel } from "./OrderTagItemModel";
import { OrderTagMockupModel } from "./OrderTagMockupModel";
import { OrderTagPetItemModel } from "./OrderTagPetItemModel";

export class OrderTagPetModel{
    constructor(){
        this.orderTagDetail = new OrderTagDetailModel();
        this.orderTagItems = [new OrderTagItemModel()];
        this.orderTagMockup = new OrderTagMockupModel();
        this.operatorFiles = [new  OrderTagPetItemModel()]
    }
}