import React, { useState, useEffect, useContext } from 'react';
import { StockOutContext, WarehouseProductModel } from '../../context/StockOutContext';
import TFUSelect from '../../based/inputs/TFUSelect';
import WarehouseServices from '../../based/services/WarehouseServices';
import TextNumber from '../../based/inputs/TextNumber';
import { NOTIFY, PRODUCT_STATUS, PRODUCT_STATUS_TYPE, PRODUCT_TYPE } from '../../based/Constants';
import { Notify } from '../../based/Notify';

const statusOptions = Object.entries(PRODUCT_STATUS).map(([key, values]) => {
  return {
    value: PRODUCT_STATUS_TYPE[key],
    label: values,
    text: values,
  };
});

export default function ProductItemOut(props) {
  const { index, addRefParent, productList, warehouseId } = props;

  const { products, setProducts } = useContext(StockOutContext);
  const [wareslots, setWareslots] = useState([]);
  const [currentSlotProduct, setCurrentSlotProduct] = useState(new WarehouseProductModel());
  const [sellableQuantity, setSellableQuantity] = useState(0);
  const [hasQuantity, setHasQuantity] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    let product = products[index];
    if (product.productId > 0) {
      _getSlots(warehouseId, product.productId);
      setSellableQuantity(0);
    }
  }, [products[index].productId]);

  // Lấy danh sách slot chứa sản phẩm
  async function _getSlots(warehouseId, productId) {
    const [err, data] = await WarehouseServices.GetSlotsContainingProduct(warehouseId, productId);
    if (!err && data && data.length > 0) {
      setCurrentSlotProduct(data);
      // Set danh sách slot chứa sản phẩm hiện tại vào dropdown
      let slotOptions = data.map((s) => ({ value: s.slotCode, text: s.slotCode, label: s.slotCode }));
      let uniqueOptions = [...new Set(slotOptions.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
      setWareslots(uniqueOptions);
    } else {
      setWareslots([]);
    }
  }

  // Chọn sản phẩm
  async function _handleSelectProduct(value) {
    let product = products[index];
    // Clear previous status and sellable quantity
    setReadOnly(true);
    setHasQuantity(true);
    setSellableQuantity(0);
    product.slotCode = '';
    product.productStatus = 0;
    if (value && product) {
      let [err, data] = await WarehouseServices.GetProductDetails(value);
      if (!err) {
        product.productType = PRODUCT_TYPE.PRODUCT_VARIANT;
        product.productId = data.productId;
        product.sellerSku = data.sellerSku;
        product.productName = data.productName;
      } else product = new WarehouseProductModel();
      setProducts([...products]);
    } else {
      product = new WarehouseProductModel();
      setProducts([...products]);
    }
  }

  // Chọn slot
  async function _handleSelectSlot(slotSelected) {
    let product = products[index];
    // Clear previous status and sellable quantity
    setReadOnly(true);
    setHasQuantity(true);
    setSellableQuantity(0);
    product.productStatus = 0;
    if (slotSelected && products[index]) {
      var slotCode = slotSelected ? slotSelected.value : null;
      if (product) {
        product.slotCode = slotCode;
        setProducts([...products]);
      } else {
        products[index] = new WarehouseProductModel();
        setProducts([...products]);
      }
    } else {
      products[index] = new WarehouseProductModel();
      setProducts([...products]);
    }
  }

  // Chọn trạng thái
  async function _handleSelectStatus(statusSelected) {
    let product = products[index];
    if (statusSelected && product) {
      let checkProduct = products.filter((item) => item.productId == product.productId && item.slotCode == product.slotCode && item.productStatus == statusSelected);
      if (checkProduct && checkProduct.length > 0) {
        Notify(NOTIFY.WARNING, 'Xác nhận', 'Sản phẩm với cùng vị trí kho và trạng thái đã được lựa chọn, xin lui lòng chọn sản phẩm với trạng thái khác...');
      } else {
        product.productStatus = statusSelected;
        var slots = currentSlotProduct.filter((s) => s.slotCode === product.slotCode && s.productStatus === statusSelected);
        var sellable = 0;
        slots.forEach(function (i) {
          sellable += i.sellable;
        });
        if (slots && sellable > 0) {
          setReadOnly(false);
          setHasQuantity(true);
          setSellableQuantity(sellable);
        } else {
          setReadOnly(true);
          setHasQuantity(false);
          setSellableQuantity(0);
        }
        setProducts([...products]);
      }
    }
  }

  function _handleChangeProduct(name, value) {
    let product = products[index];
    if (product) product[name] = value;
    if (product.productStatus == PRODUCT_STATUS_TYPE.Defective) {
      product.defectiveQuantity = product.quantity;
      product.brokenQuantity = 0;
    }
    if (product.productStatus == PRODUCT_STATUS_TYPE.Broken) {
      product.brokenQuantity = product.quantity;
      product.defectiveQuantity = 0;
    }
    setProducts([...products]);
  }

  function _handleRemoveProduct() {
    products.splice(props.index, 1);
    setProducts([...products]);
  }

  let product = products[index];

  return (
    <tr>
      <td className="text-center vertical-align-middle">
        <TFUSelect ref={addRefParent} required name={`products[${index}].ProductId`} options={productList} value={product.productId} placeholder="--Chọn sản phẩm--" onChanged={_handleSelectProduct} />
      </td>
      <td className="text-center vertical-align-middle">{product.productName}</td>
      <td className="text-center vertical-align-middle">
        <TFUSelect ref={addRefParent} required id={`products[${index}].SlotCode`} name={`products[${index}].SlotCode`} isClearable={true} options={wareslots} value={product.slotCode} placeholder="--Chọn vị trí xuất--" onChangedObject={_handleSelectSlot} isDisabled={!product.productId} />
      </td>
      <td className="text-center vertical-align-middle">
        <TFUSelect
          ref={addRefParent}
          required
          id={`products[${index}].ProductStatus`}
          name={`products[${index}].ProductStatus`}
          options={statusOptions}
          value={product.productStatus ? +product.productStatus : 0}
          placeholder="--Chọn trạng thái--"
          onChanged={_handleSelectStatus}
          isDisabled={!product.slotCode}
        />
        {sellableQuantity > 0 ? <em>(số lượng tồn kho là {sellableQuantity})</em> : !hasQuantity ? <em>không có sản phẩm</em> : ''}
      </td>
      <td className="text-center vertical-align-middle">
        <TextNumber
          ref={addRefParent}
          id={`products[${index}].Quantity`}
          name={`products[${index}].Quantity`}
          required
          onChanged={(name, value) => _handleChangeProduct('quantity', value)}
          value={product.quantity ? +product.quantity : 0}
          min={sellableQuantity ? 1 : 0}
          max={sellableQuantity}
          readOnly={readOnly}
        />
      </td>
      <td className="text-center vertical-align-middle">
        <TextNumber ref={addRefParent} id={`products[${index}].Price`} name={`products[${index}].Price`} price required onChanged={(name, value) => _handleChangeProduct('price', value)} value={product.price ? +product.price : 0} readOnly={readOnly} />
      </td>
      <td className="text-center vertical-align-middle">
        <button className="btn btn-danger" onClick={_handleRemoveProduct}>
          <i className="fa fa-trash-o"></i>
        </button>
      </td>
    </tr>
  );
}
