import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CheckBox from '../../../based/inputs/CheckBox';
import TextArea from '../../../based/inputs/TextArea';
import Textbox from '../../../based/inputs/Textbox';
import Loading from '../../../based/Loading';
import InputDatePicker from '../../../based/refactor/InputDatePicker';
import BaseServices from '../../../based/services/BaseServices';
import useFormValidate from '../../../hooks/useFormValidate';

const AddOrEditVIPCampaign = ({ campaign, show, onClose, onSave }) => {
    const [isBusy, setBusy] = useState(false);
    const { addRef, isValid, } = useFormValidate();
    const [dataModel, setDataModel] = useState(campaign);
    const [groupUsers, setGroups] = useState([]);
    const [marketingProducts, setMarketingProducts] = useState([]);
    useEffect(() => {
        if (show) {
            _fetchCampaignDetail();
        }
    }, [show])
    const _fetchCampaignDetail = async () => {
        setBusy(true);
        let groups = [];
        const [err, data] = await BaseServices.Post('/api/affiliate/get-group-affiliate', { pageNumber: 1, pageSize: 100 })
        if (!err) {
            groups = data.listObjects;
        }
        let products = []
        const [err2, data2] = await BaseServices.Post('/api/affiliate/get-marketing-products', { pageNumber: 1, pageSize: 100 })
        if (!err2) {
            products = data2.listObjects;
        }
        const [err3, data3] = await BaseServices.Get(`/api/campaign/get-campaign-by-id/${campaign.id}/${6}`)
        if (!err3) {
            // console.log(data);
            if (data3.groupAffiliates && groups) {
                groups.forEach((gr) => {
                    gr.selected = data3.groupAffiliates.filter(m => m.id == gr.id).length > 0
                })
            }
            if (data3.marketingProducts && products) {
                products.forEach((pr) => {
                    pr.selected = data3.marketingProducts.filter(m => m.setId == pr.setId).length > 0
                })
            }
            setDataModel({
                ...data3,
                startTime: new Date(data3.startTime),
                endTime: new Date(data3.endTime)
            })
        }
        setMarketingProducts(products);
        setGroups(groups);
        setBusy(false);
    }
    function _handleInputChanged(name, value) {
        let model = { ...dataModel };
        model[name] = value;
        setDataModel(model);
    }
    const _handleSave = async () => {
        if (isValid()) {
            setBusy(true);
            let postObject = {
                ...dataModel,
                groupAffiliates: groupUsers.filter(m => m.selected),
                marketingProducts: marketingProducts.filter(m => m.selected)
            };
            postObject.type = 6;
            const [err, data] = await BaseServices.Post('/api/campaign/add-or-update-campaign', postObject)
            if (!err) {
                onSave(true);
            }
            setBusy(false);
        }
    }
    function _handlePickGroup(idx) {
        let groups = [...groupUsers];
        groups[idx].selected = !groups[idx].selected
        setGroups(groups);
    }
    function _handlePickProduct(idx) {
        let products = [...marketingProducts];
        products[idx].selected = !products[idx].selected
        setMarketingProducts(products);
    }
    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Loading show={isBusy} msg={'Đang lưu...'} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>{dataModel.id > 0 ? 'Cập nhật thông tin chiến dịch' : 'Thêm mới chiến dịch'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <div className='form-group'>
                    <label className='required'>Tên Chiến dịch:</label>
                    <Textbox ref={addRef} name='title' id='title' value={dataModel.title} onChanged={_handleInputChanged} />
                </div>
                <div className='form-group'>
                    <label className='required'>Mô tả:</label>
                    <TextArea ref={addRef} name='description' id='description' value={dataModel.description} onChanged={_handleInputChanged} />
                </div>
                <div className='form-group row'>
                    <label htmlFor='startTime' className='col-3'>
                        Ngày bắt đầu
                    </label>
                    <InputDatePicker
                        name='startTime'
                        onChanged={(value) => {
                            _handleInputChanged('startTime', value);
                        }}
                        value={dataModel.startTime}
                        dateFormat='dd/MM/yyyy'
                        dropdownMode='select'
                        popperPlacement='auto'
                        className='form-control col-3'
                    />
                    <label htmlFor='endTime' className='col-3'>
                        Ngày kết thúc
                    </label>
                    <InputDatePicker
                        name='endTime'
                        onChanged={(value) => {
                            _handleInputChanged('endTime', value);
                        }}
                        value={dataModel.endTime}
                        minDate={dataModel.startTime}
                        dateFormat='dd/MM/yyyy'
                        dropdownMode='select'
                        popperPlacement='auto'
                        className='form-control col-3'
                    />
                </div>
                <div className='form-group'>
                    <label className='required'>Nhóm TTLK được áp dụng:</label>
                    {
                        groupUsers && groupUsers.map((group, i) => {
                            return <CheckBox
                                label={group.name}
                                key={i}
                                name={'group_' + group.id}
                                id={'group_' + group.id}
                                checked={group.selected}
                                onCheckedChange={() => _handlePickGroup(i)}
                            />
                        })
                    }
                </div>
                <div className='form-group'>
                    <label className='required'>Áp dụng cho các sản phẩm:</label>
                    <div className='table-responsive' style={{ maxHeight: 200 }}>
                        <table className='table table-bordered'>
                            <tbody>
                                {
                                    marketingProducts && marketingProducts.map((product, i) => {
                                        return <tr key={i}>
                                            <td>
                                                <CheckBox
                                                    label={product.name}
                                                    name={'group_' + product.id}
                                                    id={'group_' + product.id}
                                                    checked={product.selected}
                                                    onCheckedChange={() => _handlePickProduct(i)}
                                                />
                                            </td>
                                            <td>
                                                <img src={product.image} style={{ width: 50 }} />
                                            </td>
                                            <td>{product.title}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>


                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={_handleSave}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddOrEditVIPCampaign;