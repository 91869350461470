import styled from 'styled-components'
export const Wrapper = styled.div`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: #64c5b1;
.container{
    font-family: Montserrat,sans-serif;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background: white;
    border-radius: 7px;
    text-align: left;
    width: 35%;
    height: 60vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 26px 10px;
    .header{
        width: 80%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        img{
            width:50px;
            height: 50px;
        }
    }  
    .text-error{
        font-size: 47px;
        font-size: 47px;
    } 
    .text-danger{
        font-weight: 600;
    }
    .text-muted {
    color: #98a6ad !important;
    height: 25%;
    width: 80%;
    text-align: center;
    padding-top: 23px;
}
.btn.btn-md.btn-block{
    width: 60%;
}
}
`