import React, { Component } from "react";
import Request from "../../Request";
import { Editor } from "@tinymce/tinymce-react";
import { CONSTANTS } from "../../Constants";
import style from "./editor.css";

class BlogEditor extends Component {
    constructor(props) {
        super(props);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.state = {
            isValid: true,
            inValidMsg: "",
            html: "",
        };
    }

    handleEditorChange(content) {
        if (this.props.onChanged) {
            this.props.onChanged(this.props.name, content);
        }
    }
    getContent() {
        return this.props.html;
    }

    validateInput(value) {
        if (!this.props.html && this.props.required) return CONSTANTS.MSG_REQUIRED;
        this.setState({
            isValid: true,
            msgInvalid: "",
        });
        return "";
    }

    displayError(msg) {
        this.setState({ isValid: false, inValidMsg: msg });
    }

    render() {
        return (
            <React.Fragment>
                <Editor
                    id={this.props.id}
                    value={this.props.html}
                    disabled={this.props.readOnly}
                    onEditorChange={this.handleEditorChange}
                    init={{
                        selector: "textarea#full-featured-non-premium",
                        content_css: "./editor.css",
                        importcss_append: true,
                        plugins:
                            "print preview fullpage paste importcss searchreplace autolink autosave save directionality code visualblocks   fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount spellchecker imagetools textpattern noneditable help charmap quickbars emoticons",
                        imagetools_cors_hosts: ["picsum.photos"],
                        menubar: "file edit view insert format tools table help",
                        toolbar:
                            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                        toolbar_sticky: true,
                        image_advtab: true,
                        link_list: [{ title: "Trang chủ Ranus", value: "https://ranus.vn" }],
                        image_list: [
                            { title: "Logo Ranus Black", value: "https://ranus.vn/img/Logo-01.png" },
                            { title: "My page 2", value: "http://www.moxiecode.com" },
                        ],
                        image_class_list: [
                            { title: "Hình border-radius 10px", value: "img-br-10" },
                            { title: "Some class", value: "class-name" },
                        ],
                        content_style: "body { font-family: 'Lexend'; }",
                        fontsize_formats: "8pt 10pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 25px 26pt 27pt 28pt 29pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt 38pt 40pt 42pt 44pt 50pt",
                        font_formats:
                            "Lexend; Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; SVN-Helvetica=SVN-Helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                        formats: {
                            // Changes the default format for the bold button to produce a span with style with font-width: bold
                            bold: { inline: "span", styles: { "font-weight": "bold" } },
                        },
                        importcss_append: true,
                        height: 400,
                        images_upload_handler: function (blobInfo, success, failure) {
                            Request.UploadImage(blobInfo.blob())
                                .then((res) => {
                                    success(res.data.imageUrl);
                                })
                                .catch((err) => failure(err));
                        },
                        templates: [
                            {
                                title: "Heading h2 Bold",
                                description: "Tạo thẻ h2 với font-weigh bold",
                                content: "<h2><b>Tiêu đề văn bản...</b></h2>",
                            },
                            {
                                title: "Heading h3 Bold",
                                description: "Tạo thẻ h3 với font-weigh bold",
                                content: "<h3><b>Tiêu đề văn bản...</b></h3>",
                            },
                        ],
                        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                        image_caption: true,
                        quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                        noneditable_noneditable_class: "mceNonEditable",
                        toolbar_drawer: "sliding",

                        contextmenu: "link image imagetools table",

                        height: 800,
                        toolbar_sticky: true,
                    }}
                />
                {!this.state.isValid && <label className='text-danger full-width'>{this.state.inValidMsg}</label>}
            </React.Fragment>
        );
    }
}

export default BlogEditor;
