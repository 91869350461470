  
import BaseServices from "./BaseServices";

const RoleServices = { 
    GetRolesPaging: async (paging) => { 
        return await BaseServices.Post('/api/role/get-roles', paging);
    },
    GetMenusByRole: async (roleId) => { 
        return await BaseServices.Get(`/api/role/get-role-menus/${roleId}`);
    },
    SaveRoleMenus: async (model) => { 
        return await BaseServices.Post('/api/role/save-role-menus', model);
    },
    GetClaimsByRole: async (roleId) => { 
        return await BaseServices.Get(`/api/role/get-claims/${roleId}`);
    },
    DeleteRoles: async (ids) => { 
        return await BaseServices.Post('/api/role/delete-list-role', ids);
    },
    
}

export default RoleServices;