// admin only
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import { Layout } from '../../layout/Layout';
import BaseServices from '../../based/services/BaseServices';
import CommonTable from '../../based/CommonTable';
import Checkbox from '../../based/inputs/CheckBox';
import { ConfirmDialog } from '../../based/Dialog';
import Loading from '../../based/Loading';
import { CONSTANTS, NOTIFY } from '../../based/Constants';
import Common from '../../based/Common';
import { Notify } from '../../based/Notify';
import AffiliatorReviewForm from './components/AffiliatorReviewForm';
import Request from '../../based/Request';
import { Box } from '../../based/content/ct.elm';
import MarketingProducts from './components/MarketingProducts';
import BlackListUser from './components/BlackListUser';
import TFUSelect from '../../based/refactor/TFUSelect';
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 100,
    vipStatus: 0
}
const VIP_STATUS = [
    {
        label: 'Tất cả',
        value: '0'
    },
    {
        label: 'Đã gửi yêu cầu',
        value: '1'
    },
    {
        label: 'Đã duyệt',
        value: '2'
    },
    {
        label: 'Bị từ chối',
        value: '3'
    },
]
function AffiliateVerification(props) {
    const [busy, setBusy] = useState(false)
    const [busyMsg, setBusyMsg] = useState('')
    const [data, setData] = useState([])
    const [paging, setPage] = useState(INIT_PAGING)
    const [selectedItem, setSelectedItem] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [vipStatus, setVipStatus] = useState(VIP_STATUS[0].value)
    useEffect(() => {
        _fetchData(paging);
    }, [])
    async function _fetchData(paging) {
        const [err, resData] = await BaseServices.Post('/api/affiliate/get-open-tickets', paging);
        if (!err) {
            setData(resData.listObjects);
            setPage(resData.paging);
        } else {
        }
    }
    function _renderAction() {

    }
    function _renderHeader() {
        return <thead>
            <tr>
                <th width='20'>STT</th>
                <th width='300'>Email</th>
                <th width='100'>SĐT</th>
                <th className='text-center'>
                    Đã xác nhận email
                </th>
                <th className='text-center'>
                    Loại tài khoản1
                </th>
                <th className='text-center' width='200'>
                    Đã cung cấp thông tin cá nhân/doanh nghiệp
                </th>
                <th className='text-center'>
                    Đã cung cấp thông tin ngân hàng
                </th>
                <th className='text-center'>
                    Đã gửi yêu cầu VIP
                </th>
                <th className='text-center'>
                    Ngày gửi yêu cầu VIP
                </th>
                <th className='text-center'>
                    Xác thực link
                </th>
                <th className='text-center'>
                    Đã đăng ký với Ranus
                </th>
                <th width='100' className='text-center'>
                    Trạng thái
                </th>
                <th className='text-center'>
                    Quota
                </th>
                <th width='150' className='text-center'>
                    Duyệt
                </th>
            </tr>
        </thead>
    }
    function _handleDelete(id) {
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
            return new Promise((resolve, reject) => {
                setBusy(true)
                setBusyMsg('Đang xóa dữ liệu...')
                Request.Post('/api/affiliate/delete-ticket/', id)
                    .then(res => {
                        if (res.success && res.data) {
                            resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                            setBusy(false)
                            setBusyMsg(null)
                        }
                        else {
                            setBusy(false)
                            setBusyMsg(null)
                            Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                        }
                    })
                    .catch(err => {
                        setBusy(false)
                        setBusyMsg(null)
                    });
            })
        });
    }

    async function _handleShowDetail(id) {
        setSelectedItem(data[id]);
    }
    function _handleCloseModal(shouldRefresh) {
        setSelectedItem(null);
        if (shouldRefresh)
            _fetchData(paging);
    }
    function _renderBody() {
        return <tbody>
            {
                paging.totalRecord == 0 ? <tr><td colSpan={12}>Không có dữ liệu</td></tr>
                    :
                    data.map((item, idx) => {
                        return <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.email}</td>
                            <td>{item.phoneNumber}</td>
                            <td className='text-center'>
                                <Checkbox checked={item.isVerifiedEmail} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>{item.accountType == 0 ? "Cá Nhân" : "Doanh Nghiệp"}</td>
                            <td className='text-center'>
                                <Checkbox checked={item.isBasicInfoSubmitted} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>
                                <Checkbox checked={item.isBankInfoSubmitted} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>
                                <Checkbox checked={item.isVIPRequested} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>
                                {item.isVIPRequested ? item.timeRequestVipStr : ''}
                            </td>
                            <td className='text-center'>
                                <Checkbox checked={item.isTrafficSourceVerified} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>
                                <Checkbox checked={item.isRegisteredWithRanus} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>
                                {
                                    item.status == 1 ? <span className='text-primary'>Chờ duyệt</span> : item.status == 2 ?
                                        <span className='text-custom'>Đã duyệt</span> : <span className='text-danger'>Đã từ chối</span>
                                }
                            </td>
                            <td className='text-center'>
                                {item.quota ? item.quota : 0}
                            </td>
                            <td className='text-center'>
                                <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleShowDetail(idx)}>Xem</button>
                                <button className='btn btn-danger btn-sm m-r-5' onClick={() => _handleDelete(item.thirdPartyId)}>Xóa</button>
                            </td>
                        </tr>
                    })
            }
        </tbody>

    }
    const _handleChangedFilter = async (name, value) => {
        let filter = { ...paging };
        filter[name] = value;
        await _fetchData(filter)
    }
    const _renderSearchAdvanced = () => {
        return <div className='d-flex' style={{ alignItems: 'baseline', gap: '5px' }}>
            <label>Trạng thái đăng ký VIP</label>
            <div style={{ width: '200px' }} >
                <TFUSelect options={VIP_STATUS} name='vipStatus' id='vipStatus' value={paging.vipStatus}
                    onChanged={(value) => _handleChangedFilter('vipStatus', value)} />
            </div>
        </div>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Duyệt nhà quảng cáo' parentPage='Lotus Fashion' hrefParent='/' />
            <Loading show={busy} msg={busyMsg} />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                <li className="nav-item">
                    <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)} >
                        <span>Danh sách nhà quảng cáo</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 1 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(1)}>
                        <span>Sản phẩm áp dụng</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 2 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(2)}>
                        <span>Black list</span>
                    </a>
                </li>
            </ul>
            {
                tabIndex == 0 && data && <CommonTable
                    data={data}
                    paging={paging}
                    renderHeader={_renderHeader}
                    renderBody={_renderBody}
                    renderAction={_renderAction}
                    searchBasic
                    searchAdvanced={_renderSearchAdvanced}
                    placeholderSearch='Nhập tên hoặc email'
                    onFilter={(paging) => _fetchData(paging)}
                />
            }
            {
                tabIndex == 1 && <MarketingProducts />
            }
            {
                tabIndex == 2 && <BlackListUser />
            }
            <AffiliatorReviewForm
                data={selectedItem}
                isShow={selectedItem != null}
                onClose={_handleCloseModal}
                model={selectedItem}
            />
        </React.Fragment>
    );
}

export default AffiliateVerification;