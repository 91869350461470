import React, { useContext } from "react";
import { IMAGE_TYPE, PRODUCT_IMAGE_TYPE } from "../../based/Constants";
import TextEditor from "../../based/inputs/TextEditor";
import { ProductContext } from "../../context/ProductContext";
import MultipleImages from "../SupportiveComponent/MultipleImages";
let imageTypeOptions = Object.entries(PRODUCT_IMAGE_TYPE).map((item) => {
    return { value: item[1], text: item[0], label: item[0] };
});

export default function SectionDescription({ isShowUploadImage = true }) {
    const { mainProduct, setMainProduct, addRef } = useContext(ProductContext);

    function _handleSwap(index1, index2) {
        let imagesUp = [...mainProduct.images];
        let img1 = imagesUp[index1];
        let img2 = imagesUp[index2];
        imagesUp[index2] = img1;
        imagesUp[index1] = img2;
        setMainProduct({ ...mainProduct, images: imagesUp });
    }

    return (
        <div className="section-description">
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Mô tả sản phẩm</b>
                </h4>
                <div className="row">
                    <div className="col-md-12">
                        {isShowUploadImage && (
                            <div className="form-row">
                                <label htmlFor="images" className="col-form-label full-width">
                                    Hình ảnh sản phẩm
                                </label>
                                <MultipleImages
                                    className="hide"
                                    id="uploadImages"
                                    name="uploadImages"
                                    images={mainProduct ? mainProduct.listImage : []}
                                    isPreview
                                    limit={5}
                                    size={12}
                                    classNamePreview="ui-product-photo-item col-md-2"
                                    onSwap={_handleSwap}
                                    onChanged={(name, newImages) => setMainProduct({ ...mainProduct, listImage: newImages })}
                                    imageTypeOptions={imageTypeOptions}
                                    imageType={IMAGE_TYPE.Product}
                                />
                                <div className="ui-product-photo" style={{ paddingTop: "50px" }}>
                                    <ul className="ui-product-photo-grid clearfix">
                                        <li className="ui-product-photo-item col-md-2">
                                            <div
                                                className="file-upload position-relative"
                                                onClick={() => {
                                                    document.getElementById("uploadImages").click();
                                                }}
                                            >
                                                <div className="fileupload-text text-center">
                                                    <i className="fa fa-camera font-size-30px"></i>
                                                    <p className="mb-0 mt-2 text-secondary">
                                                        Thêm hình ảnh <span style={{ fontStyle: "italic" }}>(tối đa 5 ảnh)</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className="form-row">
                            <label htmlFor="advantages" className="col-form-label full-width">
                                Đặc điểm nổi bật
                            </label>
                            <TextEditor
                                id="advantages"
                                name="advantages"
                                className="form-control full-width"
                                ref={addRef}
                                html={mainProduct ? mainProduct.advantages : null}
                                onChanged={(name, value) => setMainProduct({ ...mainProduct, advantages: value })}
                            />
                        </div>
                        <div className="form-row">
                            <label htmlFor="content" className="col-form-label full-width">
                                Chi tiết về sản phẩm
                            </label>
                            <TextEditor id="content" name="content" className="form-control full-width" ref={addRef} html={mainProduct ? mainProduct.content : null} onChanged={(name, value) => setMainProduct({ ...mainProduct, content: value })} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
