import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import NumberFormat from "react-currency-format";
import TagsInput from "react-tagsinput";
import { INITPAGING, NOTIFY } from "../../../based/Constants";
import CheckBox from "../../../based/inputs/CheckBox";
import TextArea from "../../../based/inputs/TextArea";
import Textbox from "../../../based/inputs/Textbox";
import TextEditor from "../../../based/inputs/TextEditor";
import TextNumber from "../../../based/inputs/TextNumber";
import TFUSelect from "../../../based/inputs/TFUSelect";
import { Notify } from "../../../based/Notify";
import BaseModal from "../../../based/refactor/BaseModal";
import CommonTable from "../../../based/refactor/CommonTable";
import ArtworkServices from "../../../based/services/ArtworkServices";
import BrandServices from "../../../based/services/BrandServices";
import PrintServices from "../../../based/services/PrintServices";
import TopicServices from "../../../based/services/TopicServices";
import { SetProductContext } from "../../../context/SetProductContext";

PreAddArtworkInfo.propTypes = {
    artworkId: PropTypes.number,
};

export function PreAddArtworkInfo(props) {
    const { ageSetModel, setAgeSetModel, addRef, setModel } = useContext(SetProductContext);
    const [readRateCard, setReadRateCard] = useState([]);
    const [artworkBrands, setArtworkBrands] = useState([]);
    const [topics, setTopics] = useState([]);
    const [topicPaging, setTopicPaging] = useState({ ...INITPAGING });
    const [isLoading, setIsLoading] = useState(false);
    const [productColorCode, setProductColorCode] = useState([]);
    const [topTopics, setTopTopics] = useState([]);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const autoSuggestRef = useRef();
    const [designType, setDesignType] = useState(false);
    var timer = null;
    useEffect(() => {
        getReadRateCard();
        getBrand();
        getTopTopic();
    }, [props.isShow]);

    useEffect(() => {
        if (setModel.id > 0) getAppProductColorCode(setModel.id, 0);
    }, [setModel.id]);

    async function getAppProductColorCode(setId, ageSetId) {
        let [err, data] = await ArtworkServices.GetAppProductCodeModels(setId, ageSetId);
        if (!err && data) {
            setProductColorCode(data);
            console.log("data :>> ", data);
        } else setProductColorCode([]);
    }

    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function getBrand() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await BrandServices.GetDropdownApprovedBrand({ ...INITPAGING });
        if (!err && data) setArtworkBrands(data);
        else setArtworkBrands([]);
        setIsLoading(false);
        setMsg(null);
    }

    async function getTopTopic() {
        setIsLoading(true);
        let [err, data] = await TopicServices.GetTop10Topic();
        if (!err && data && data.length > 0) setTopTopics(data);
        else setTopTopics([]);
        console.log("top Topic", data);
        setIsLoading(false);
    }

    async function _getTopic(paging) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            _fetchTopic(paging);
        }, 400);
    }

    async function _fetchTopic(paging) {
        let [err, data] = await TopicServices.GetDropdownTopic(paging);
        if (!err && data) setTopics(data.listObjects);
        else setTopics([]);
    }

    function setAmount(amount) {
        if (amount > 0 && readRateCard && readRateCard.length > 0) {
            let total = 0;
            let tmp = 0;
            for (let index = 0; index < readRateCard.length; index++) {
                if (amount >= readRateCard[index].loyaltyAmount) {
                    total += (readRateCard[index].loyaltyAmount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    tmp = readRateCard[index].loyaltyAmount;
                } else {
                    total += (amount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    break;
                }
            }
            setAgeSetModel({ ...ageSetModel, totalLoyaltyPrice: total, lotusChargeAmount: amount - total, loyaltyAmount: amount });
        } else {
            setAgeSetModel({ ...ageSetModel, totalLoyaltyPrice: 0, lotusChargeAmount: 0, loyaltyAmount: 0 });
        }
    }
    const renderHeader = () => {
        return (
            <tr>
                <th>Mức charge</th>
                <th>Mức tiền bản quyền(đồng)</th>
                <th>Ranus Operation Fee (%)</th>
            </tr>
        );
    };
    const renderBody = () => {
        if (readRateCard.length > 0) {
            return readRateCard.map((item, k) => {
                return (
                    <tr key={k}>
                        <td className='text-center'>{k + 1}</td>
                        <td className='text-center'>
                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                        </td>
                        <td className='text-center'>{item.lotusChargePercent}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan='3'>
                        <span>Chưa có cấu hình</span>
                    </td>
                </tr>
            );
        }
    };
    console.log(ageSetModel);
    return (
        <React.Fragment>
            <div className='form-row col-md-12'>
                <div className='col-md-8 row'>
                    <div className='form-group col-md-12'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <label className='col-form-label required' htmlFor='brandId'>
                                    Tên set thiết kế (Tiếng Việt)
                                </label>
                                <Textbox maxLength={255} name='title' value={ageSetModel.title} required ref={addRef} placeholder='--Tên thiết kế (VI)--' onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, title: value })} />
                            </div>
                            <div className='col-md-4'>
                                <label className='col-form-label required' htmlFor='brandId'>
                                    Tên set thiết kế (Tiếng Anh)
                                </label>
                                <Textbox maxLength={255} name='titleEN' value={ageSetModel.titleEN} required ref={addRef} placeholder='--Tên thiết kế (ENG)--' onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, titleEN: value })} />
                            </div>
                            <div className='col-md-4'>
                                <label className='col-form-label required' htmlFor='brandId'>
                                    Thương hiệu
                                </label>
                                <TFUSelect required name='brandId' ref={addRef} value={ageSetModel.brandId} options={artworkBrands} placeholder='---Thương hiệu---' onChanged={(value) => setAgeSetModel({ ...ageSetModel, brandId: +value })} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group col-md-4'>
                        <label className='col-form-label required' htmlFor='advantages'>
                            Màu sắc hình ảnh hiển thị
                        </label>
                        <div className='form-group'>
                            {productColorCode && productColorCode.length > 0
                                ? productColorCode.map((item, idx) => {
                                      return (
                                          <React.Fragment key={idx}>
                                              <label>{item.productName}</label>
                                              <div className='colors'>
                                                  {item.appProductCodes.map((element, index) => {
                                                      return (
                                                          <div
                                                              key={index}
                                                              className={`color-item pull-left ${element.appProductVariantId == ageSetModel.appProductVariantId ? "active" : ""}`}
                                                              onClick={(e) => {
                                                                  setAgeSetModel({ ...ageSetModel, appProductVariantId: element.appProductVariantId });
                                                              }}
                                                          >
                                                              <div style={{ backgroundColor: element.colorCode }}></div>
                                                          </div>
                                                      );
                                                  })}
                                              </div>
                                          </React.Fragment>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                    <div className='form-group col-md-8'>
                        <label className='col-form-label required'>Phong cách thiết kế (Tối đa 3)</label>
                        <div className='form-row '>
                            {topTopics.map((item, idx) => {
                                return (
                                    <CheckBox
                                        key={idx}
                                        classDiv='col-md-3 my-2'
                                        label={item.topicName}
                                        checked={ageSetModel && ageSetModel.topic.includes(item.topicName)}
                                        onCheckedChange={(name, value) => {
                                            let setModel = { ...ageSetModel };
                                            let topic = setModel.topic;
                                            //nếu true, add vào topic
                                            //tối đa được 3 tag
                                            if (value) {
                                                let lengthTopic = topic.length;
                                                if (lengthTopic >= 3) Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 3 phong cách thiết kế");
                                                else {
                                                    topic.push(item.topicName);
                                                    setAgeSetModel(setModel);
                                                }
                                            }
                                            //nếu false, remove ra khỏi topic
                                            else {
                                                let indexOf = topic.indexOf(item.topicName);
                                                if (indexOf >= 0) topic.splice(indexOf, 1);
                                                setAgeSetModel(setModel);
                                            }
                                        }}
                                    />
                                );
                            })}

                            <span className='col-md-4 my-2 text-success cursor-pointer' onClick={(e) => setDesignType(true)}>
                                Xem thêm
                            </span>
                        </div>
                        <div className=' d-flex pt-1'>
                            {ageSetModel && ageSetModel.topic.length > 0
                                ? ageSetModel.topic.map((item, idx) => (
                                      <div key={idx} className='d-flex justify-content-between align-items-center p-1  m-2'>
                                          <span className='d-inline-block text-truncate mr-2 pt-1' style={{ maxWidth: "120px" }}>
                                              {item}
                                          </span>{" "}
                                          <i
                                              class='fa fa-times-circle cursor-pointer text-danger pb-2'
                                              aria-hidden='true'
                                              onClick={() => {
                                                  let setModel = { ...ageSetModel };
                                                  let topic = setModel.topic;
                                                  let indexOf = topic.indexOf(item);
                                                  if (indexOf >= 0) topic.splice(indexOf, 1);
                                                  setAgeSetModel(setModel);
                                              }}
                                          ></i>
                                      </div>
                                  ))
                                : ""}
                        </div>
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label required' htmlFor='content'>
                            Câu chuyện thiết kế (Tiếng Việt)
                        </label>
                        <TextEditor className='form-control full-width' required ref={addRef} name='content' html={ageSetModel.content} onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, content: value })} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label required' htmlFor='contentEN'>
                            Câu chuyện thiết kế (Tiếng Anh)
                        </label>
                        <TextEditor className='form-control full-width' required ref={addRef} name='contentEN' html={ageSetModel.contentEN} onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, contentEN: value })} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label'>Tag</label>
                        <TagsInput
                            addKeys={[13]}
                            addOnPaste
                            maxTags={10}
                            inputProps={{ placeholder: "" }}
                            value={ageSetModel.tag || []}
                            onChange={(tags) => {
                                let newTags = tags.reduce((prev, val, idx) => (prev.indexOf(val) == -1 ? [...prev, val] : [...prev]), []);
                                setAgeSetModel({ ...ageSetModel, tag: newTags });
                            }}
                        />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label'>SEO Meta Title</label>
                        <Textbox name='seoMetaTitle' value={ageSetModel.seoMetaTitle} onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, seoMetaTitle: value })} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label'>SEO Meta Description</label>
                        <TextArea name='seoMetaDescription' value={ageSetModel.seoMetaDescription} onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, seoMetaDescription: value })} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label'>SEO Meta Keywords</label>
                        <Textbox name='seoMetaKeywords' value={ageSetModel.seoMetaKeywords} onChanged={(name, value) => setAgeSetModel({ ...ageSetModel, seoMetaKeywords: value })} />
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label' htmlFor='config'>
                            Thông tin cấu hình mức Charge tiền bản quyền
                        </label>
                        <CommonTable renderHeader={renderHeader} renderBody={renderBody} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label required' htmlFor='loyaltyAmount'>
                            Tiền bản quyền
                        </label>
                        <TextNumber required ref={addRef} name='loyaltyAmount' className='form-control' suffix=' đ' value={ageSetModel.loyaltyAmount} min={0} max={999999999} onChanged={(name, value) => setAmount(value)} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label' htmlFor='lotusChargeAmount'>
                            Phí hoạt động Ranus
                        </label>
                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} suffix=' đ' value={ageSetModel.lotusChargeAmount} className='form-control' style={{ background: "#e9ecef" }} />
                    </div>
                    <div className='form-group col-md-12'>
                        <label className='col-form-label' htmlFor='totalLoyaltyPrice'>
                            Tiền bản quyền thực lĩnh
                        </label>
                        <NumberFormat
                            thousandSeparator=','
                            displayType={"text"}
                            decimalSeparator='.'
                            decimalScale={2}
                            suffix=' đ'
                            value={ageSetModel.totalLoyaltyPrice > 0 ? ageSetModel.totalLoyaltyPrice : 0}
                            className='form-control'
                            style={{ background: "#e9ecef" }}
                        />
                    </div>
                </div>
            </div>
            <DesignType
                isModal={designType}
                onClose={() => setDesignType(false)}
                topics={ageSetModel.topic}
                setTopics={(topics) => {
                    let setModel = { ...ageSetModel };
                    setModel.topic = topics;
                    setAgeSetModel(setModel);
                }}
            />
        </React.Fragment>
    );
}

export function DesignType(props) {
    const [popular, setPopular] = useState([]);
    const [listData, setListData] = useState([]);
    const [pagingData, setPagingData] = useState({ ...INITPAGING });
    const [isLoading, setIsLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const { topics, setTopics } = props;
    let timer = null;

    useEffect(() => {
        getTopTopic();
        getAllTopic({ ...INITPAGING, pageSize: 20 });
    }, []);

    async function getTopTopic() {
        setIsLoading(true);
        let [err, data] = await TopicServices.GetTop10Topic();
        if (!err && data && data.length > 0) setPopular(data);
        else setPopular([]);
        setIsLoading(false);
    }

    async function getAllTopic(paging) {
        setIsLoading(true);
        let [err, data] = await TopicServices.GetTopics(paging);
        if (!err && data && data.listObjects) setListData(data.listObjects);
        else setListData([]);
        setIsLoading(false);
    }

    async function _fetchKeyword(keyword) {
        setKeyword(keyword);
        clearTimeout(timer);
        timer = setTimeout(() => {
            let paging = { ...INITPAGING, pageNumber: 1, pageSize: 30, keyword };
            getAllTopic(paging);
        }, 500);
    }

    async function _onClickTopicName(name, value) {
        let topic = topics;
        //nếu true, add vào topic
        //tối đa được 3 tag
        if (value) {
            let lengthTopic = topic.length;
            if (lengthTopic >= 3) Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 3 phong cách thiết kế");
            else {
                topic.push(name);
                setTopics(topic);
            }
        }
        //nếu false, remove ra khỏi topic
        else {
            let indexOf = topic.indexOf(name);
            if (indexOf >= 0) topic.splice(indexOf, 1);
            setTopics(topic);
        }
    }

    return (
        <BaseModal isShow={props.isModal} title='Chọn phong cách thiết kế (Tối đa 3)' onClose={props.onClose} width={"700px"}>
            <div className='form-row col-md-12'>
                <div className='col-md-6'>
                    <label className='col-form-label artwork-info-popular-label font-weight-bold'>Phổ biến</label>
                    <div className='artwork-info-popular-topic'>
                        {popular.map((item, idx) => {
                            let isActive = topics ? topics.includes(item.topicName) : false;
                            return (
                                <span
                                    key={idx}
                                    className={`cursor-pointer topic-item my-1 ${isActive ? "active" : ""}`}
                                    onClick={(e) => {
                                        _onClickTopicName(item.topicName, !isActive);
                                    }}
                                >
                                    {item.topicName}
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div className='col-md-6 position-relative all-topic'>
                    <div className='d-flex'>
                        <label className='col-form-label artwork-info-all-label w-50 font-weight-bold'>Tất cả</label>
                        <Textbox name='keyword' value={keyword} onChanged={(name, value) => _fetchKeyword(value)} placeholder={"Tìm kiếm"} className='form-control w-50' />
                    </div>
                    <div className='artwork-info-all-topic'>
                        {listData.map((item, idx) => {
                            let isActive = topics ? topics.includes(item.topicName) : false;
                            return (
                                <span
                                    className={`cursor-pointer topic-item my-1 ${isActive ? "active" : ""}`}
                                    key={idx}
                                    onClick={(e) => {
                                        _onClickTopicName(item.topicName, !isActive);
                                    }}
                                >
                                    {item.topicName}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}
