import PubSub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { ATTRIBUTE_INPUT_TYPE, ATTRIBUTE_TYPE, CONSTANTS, MAINMENU, NOTIFY, PLATFORM, SUBMENU } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import AttributeServices from "../based/services/AttributeServices";
import ConfigAttributeModal from "./ConfigAttributeModal";

const InitAttribute = {
    id: 0,
    groupId: 0,
    parentId: 0,
    code: "",
    name: "",
    categoryId: 0,
    dataType: ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT,
    dataTypeName: "",
    unit: "",
    index: 1,
    description: "",
    isRequired: false,
    searchable: true,
    attributeType: ATTRIBUTE_TYPE.NORMAL,
};

const initPage = {
    pageSize: 50,
    pageNumber: 1,
    orderBy: "id",
    orderDirection: "asc",
    Platform: PLATFORM.Internal,
};

export default function Attribute(props) {
    const [isBusy, setBusy] = useState(true);
    const [msg, setMessage] = useState("");
    const [isShowModal, setIsShowModal] = useState(false);
    const [attribute, setAttribute] = useState(InitAttribute);
    const tableRef = useRef(null);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [selectedAll, setSelectedAll] = useState(false);
    const [isShowFrameModal, setIsShowFrameModal] = useState(false);

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.AttributeManagement, subMenu: SUBMENU.Attributes });
    }, []);

    async function getDatas(newPaging) {
        setSelectedAll(false);
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        const [error, data] = await AttributeServices.GetPageAttributes(newPaging);
        if (!error) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
            setMessage(null);
        } else {
            setDatas([]);
            setPaging(initPage);
            setBusy(false);
            setMessage(null);
        }
    }

    function _handleDelete() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length > 0) {
            let ids = [];
            dataChecked.map((item) => ids.push(item.id));
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise((resolve, reject) => {
                    setBusy(true);
                    Request.Post("/api/attribute/delete/", ids)
                        .then((res) => {
                            if (res.success) {
                                setBusy(false);
                                resolve({ title: "Thành công", msg: "Xóa thành công." });
                                getDatas(paging);
                            } else {
                                setBusy(false);
                                Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                            }
                        })
                        .catch((err) => {
                            setBusy(false);
                            if (err.data) reject({ title: "Có lỗi", msg: err.data });
                            else reject({ title: "Có lỗi", msg: CONSTANTS.MSG_ERROR });
                        });
                });
            });
        }
    }

    function _handleEdit() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsShowModal(true);
            setAttribute(dataChecked[0]);
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một bản ghi để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleAdd() {
        setAttribute(InitAttribute);
        setIsShowModal(true);
    }

    function _handleSaveSucceed(isContinue) {
        if (isContinue) setAttribute({ ...InitAttribute, categoryId: attribute.categoryId });
        else setIsShowModal(false);
        getDatas({ ...paging, pageNumber: 1 });
    }

    function _handleCheckedChange(item) {
        let datasUpdate = [...datas];
        datasUpdate.map((m) => {
            if (m.id == item.id) m.checked = !m.checked;
        });
        setDatas(datasUpdate);
        setSelectedAll(datasUpdate.filter((x) => x.checked).length == datas.length);
    }

    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) {
                isEnabled = true;
                let item = dataChecked[0];
            }
        }
        return (
            <div className="p-10 box__actions">
                <div className="form-group full-width">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" />
                        Thêm
                    </button>
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" />
                                Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox checked={selectedAll} name="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th className="cursor-pointer text-center" width={100}>
                        ID
                    </th>
                    <th className="cursor-pointer">Mã thuộc tính</th>
                    <th className="cursor-pointer">Tên thuộc tính</th>
                    <th className="cursor-pointer">Ngành hàng</th>
                    <th className="cursor-pointer" width={200}>
                        Thiết lập khung in
                    </th>
                    <th className="cursor-pointer text-center" width={200}>
                        Thứ tự hiển thị
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((attr, idx) => {
                        return (
                            <tr key={idx} onClick={() => _handleCheckedChange(attr)}>
                                <td width={40} className={"center"}>
                                    <CheckBox checked={attr.checked} label=" " id={attr.id.toString()} name={idx.toString()} onCheckedChange={() => _handleCheckedChange(attr)} />
                                </td>
                                <td>{attr.id}</td>
                                <td>{attr.code}</td>
                                <td>{attr.name}</td>
                                <td>{attr.categoryName}</td>
                                <td className="center">
                                    {attr.isPrintFrameConfig ? (
                                        <span className="status__active">
                                            <i className="fa fa-check m-r-5"></i>
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </td>
                                <td className="center">{attr.index}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="8" className="center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Thuộc tính" />
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={datas} paging={paging} ref={tableRef} headerCheckbox searchBasic={true} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
            {isShowModal && <ConfigAttributeModal history={props.history} isShowModal={isShowModal} attribute={attribute} onClose={() => setIsShowModal(false)} onSaveSucceed={_handleSaveSucceed} />}
        </React.Fragment>
    );
}
