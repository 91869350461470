import React, { useState, useEffect } from 'react'

export default class TShirtSVG extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return<div >
            <img style={{ backgroundColor: this.props.backgroundColor, maxHeight:'80px',border:'0px' }} src="/images/t-shirt.png" alt="t-shirt" />
        </div> 
    }
}