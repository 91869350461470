import React from "react";
import PropTypes from "prop-types";
import Common from "../Common";

class CheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        };
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
    }

    static defaultProps = {
        required: false,
    };

    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        onCheckedChange: PropTypes.func,
        checked: PropTypes.bool,
    };

    handleCheckedChange(e) {
        e.stopPropagation();
        if (!this.props.readOnly && this.props.onCheckedChange) this.props.onCheckedChange(this.props.name, e.target.checked);
    }

    validateInput() {
        return true;
    }

    render() {
        let guid = Common.generateGuid();
        var classDiv = this.props.isInline ? "checkbox checkbox-success form-check-inline" : "checkbox checkbox-success";
        if (this.props.classDiv) classDiv += " " + this.props.classDiv;
        return (
            <div className={classDiv}>
                <input
                    id={guid}
                    name={this.props.name}
                    type="checkbox"
                    onChange={this.handleCheckedChange}
                    onClick={(e) => e.stopPropagation()}
                    disabled={this.props.disabled ? true : false}
                    checked={this.props.checked ? true : false}
                    readOnly={this.props.readOnly}
                    className="checkbox-round"
                />
                <label htmlFor={guid} className="cursor-pointer mb-0" onChange={this.handleCheckedChange} onClick={(e) => e.stopPropagation()}>
                    {this.props.label}
                </label>
            </div>
        );
    }
}

export default CheckBox;
