import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY, PLATFORM, STATUS_ACTIVE } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import { Notify } from "../based/Notify";
import PlatformServices from "../based/services/PlatformServices";
import PushingServices from "../based/services/PushingServices";
import VideoModal from "./VideoModal";
import VideoPushModal from "./VideoPushModal";

const agePushingVideo = {
    id: 0, // long
    url: "", // string
    thumbnail: "", // string
    isDefault: false, // bool
    isDelete: false, // bool
};

const PlatformOptions = [
    {
        value: PLATFORM.Lazada,
        label: "Lazada",
    },
    {
        value: PLATFORM.Shopee,
        label: "Shopee",
    },
    {
        value: PLATFORM.Tiktok,
        label: "Tiktok",
    },
];

function VideoManagement(props) {
    const [datas, setDatas] = useState([agePushingVideo]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 50 });
    const [isShow, setIsShow] = useState(false);
    const [videoId, setVideoId] = useState(0);
    const [isShowVideoPush, setIsShowVideoPush] = useState(false);
    const [stores, setStores] = useState([]);

    useEffect(() => {
        _getData(paging);
    }, []);

    async function _getStores(platform) {
        const [err, data] = await PlatformServices.PlatformStoresUser([platform], STATUS_ACTIVE.All);
        if (!err) {
            let storeOptions = data.map((x) => {
                return {
                    value: x.shopId,
                    label: x.shopName,
                };
            });
            setStores(storeOptions);
        } else {
            setStores([]);
        }
    }

    async function _getData(page) {
        let [err, data] = await PushingServices.GetVideoByPaging({ ...page });
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(page);
        }
    }

    async function deleteVideo(id) {
        let [err, data] = await PushingServices.SetIsDeleteVideo(id);
        if (!err) {
            _getData(paging);
        } else {
            Notify(NOTIFY.ERROR, "Xóa thất bại", "Xóa thất bại");
        }
    }

    async function uploadVideoPlatform(videoId, listModel) {
        const [err, data] = await PushingServices.UpdatePushingVideoPlatforms(videoId, listModel);
        if (!err) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Thành công");
        } else {
            Notify(NOTIFY.ERROR, "Thất bại", "Thất bại");
        }
    }

    function _renderAction() {
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={(e) => {
                        setIsShow(true);
                        setVideoId(0);
                    }}
                >
                    Thêm mới
                </button>
            </React.Fragment>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">STT</th>
                    <th className="center">Thumbnail</th>
                    <th
                        className="center"
                        style={{
                            width: "100px",
                        }}
                    >
                        Url
                    </th>
                    <th className="center">Mặc định</th>
                    <th className="center">Video ID</th>
                    <th className="center"></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="center">{idx + 1}</td>
                                <td className="center-image">
                                    <img src={item.thumbnail} />
                                </td>
                                <td className="center">
                                    <a
                                        className="text text-custom"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(item.url, "_blank");
                                        }}
                                    >
                                        {item.url}
                                    </a>
                                </td>
                                <td className="center">{item.isDefault ? "Mặc định" : ""}</td>
                                <td className="center">
                                    {item.pushingVideoPlatforms &&
                                        item.pushingVideoPlatforms.map((x, i) => {
                                            return (
                                                <React.Fragment>
                                                    <div key={i} className="platform-video-item">
                                                        <TFUSelect
                                                            options={PlatformOptions}
                                                            value={x.platform}
                                                            placeholder="Chọn Platform"
                                                            onChanged={(newVal) => {
                                                                //cập nhập thông tin hiện tại
                                                                let dt = [...datas];
                                                                dt[idx].pushingVideoPlatforms[i].platform = newVal;
                                                                dt[idx].pushingVideoPlatforms[i].shopId = "";
                                                                setDatas(dt);

                                                                //call api lấy thông tin shop
                                                                _getStores(newVal);
                                                            }}
                                                        />
                                                        <TFUSelect
                                                            options={stores}
                                                            value={x.shopId}
                                                            placeholder="Chọn Shop"
                                                            onChanged={(newVal) => {
                                                                //cập nhập thông tin hiện tại
                                                                let dt = [...datas];
                                                                dt[idx].pushingVideoPlatforms[i].shopId = newVal;
                                                                setDatas(dt);
                                                            }}
                                                        />
                                                        <div>
                                                            <input
                                                                type="text"
                                                                placeholder="Video ID"
                                                                onChange={(e) => {
                                                                    let dt = [...datas];
                                                                    dt[idx].pushingVideoPlatforms[i].platformVideoId = e.target.value;
                                                                    setDatas(dt);
                                                                }}
                                                                value={x.platformVideoId}
                                                            />
                                                        </div>
                                                        <div
                                                            className="btn btn-danger"
                                                            onClick={(e) => {
                                                                let dt = [...datas];
                                                                dt[idx].pushingVideoPlatforms.splice(i, 1);
                                                                setDatas(dt);
                                                            }}
                                                        >
                                                            Xóa
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    <div className="list-action">
                                        <button
                                            onClick={(e) => {
                                                let dt = [...datas];
                                                let current = dt[idx];
                                                if (!current.pushingVideoPlatforms) current.pushingVideoPlatforms = [];
                                                else current.pushingVideoPlatforms.push({});
                                                setDatas(dt);
                                            }}
                                            className="btn btn-custom btn-sm m-1"
                                        >
                                            Thêm
                                        </button>
                                        <button
                                            className="btn btn-custom btn-sm m-1"
                                            onClick={(e) => {
                                                let lst = item.pushingVideoPlatforms;
                                                if (lst) {
                                                    for (var element of lst) {
                                                        element.videoId = item.id;
                                                        element.id = 0;
                                                    }
                                                }
                                                uploadVideoPlatform(item.id, lst);
                                            }}
                                        >
                                            Cập nhập
                                        </button>
                                    </div>
                                </td>
                                <td className="center">
                                    <button
                                        className="btn btn-danger m-1"
                                        onClick={(e) => {
                                            deleteVideo(item.id);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={(e) => {
                                            setIsShow(true);
                                            setVideoId(item.id);
                                        }}
                                    >
                                        Chỉnh sửa
                                    </button>
                                    {/* <button
                                        className="btn btn-custom m-1"
                                        onClick={(e) => {
                                            setVideoId(item.id);
                                            setIsShowVideoPush(true);
                                        }}
                                    >
                                        Upload Video tới Shopee
                                    </button> */}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý Video Mặc định" />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            <VideoModal
                show={isShow}
                onClose={() => setIsShow(false)}
                videoId={videoId}
                onSave={() => {
                    _getData(paging);
                    setIsShow(false);
                }}
            />
            <VideoPushModal
                show={isShowVideoPush}
                onClose={() => setIsShowVideoPush(false)}
                videoId={videoId}
                onSave={() => {
                    _getData(paging);
                    setIsShowVideoPush(false);
                }}
            />
        </Wrapper>
    );
}

export default VideoManagement;

const Wrapper = styled.div`
    .platform-video-item {
        display: flex;
    }

    .platform-video-item > div {
        width: calc(25% - 10px);
        margin: 5px;

        input {
            width: 100%;
        }
    }

    .list-action {
        display: flex;
        justify-content: flex-end;
    }

    td.center-image {
        width: 200px;
        height: 200px;
    }

    .center-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
