import { CollectionModel } from '../../../models/CollectionModel';

const fetchBegin = (state) => {
  const updatedState = { ...state };
  const updatedProject = { ...updatedState.project };

  updatedProject.isLoading = true;
  updatedState.project = updatedProject;

  return updatedState;
};

const fetchFail = (state, err) => {
  const updatedState = { ...state };
  const updatedProject = { ...updatedState.project };

  updatedProject.isError = true;
  updatedProject.isLoading = false;
  updatedProject.error = err;
  updatedState.project = updatedProject;

  return updatedState;
};

const uploadCoverImageSuccess = (state, imgUrl) => {
  const updatedState = { ...state };
  const updatedProject = { ...updatedState.project };
  const updatedData = { ...updatedProject.data };

  updatedData.coverUrl = imgUrl;
  updatedProject.isLoading = false;
  updatedProject.data = updatedData;
  updatedState.project = updatedProject;

  return updatedState;
};

const fetchProjectSuccess = (_, updatedState) => {
  return updatedState;
};

const clearProject = (state) => {
  var updatedState = { ...state };
  var updatedProject = { ...updatedState.project };
  var updatedData = { ...updatedProject.data,coverUrl:'',name:'' };
  var items = updatedProject.items
  updatedData.project = new CollectionModel();
  updatedProject.data = updatedData;
  updatedState.project = updatedProject;
  updatedState.items = { ...items, data: [] }
  return updatedState;
};

export default {
  fetchBegin,
  fetchFail,
  uploadCoverImageSuccess,
  fetchProjectSuccess,
  clearProject,
};
