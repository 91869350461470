import React, { useContext, useEffect, useMemo, useState } from "react";
import Common from "../../based/Common";
import { INITPAGING, PLATEN_SIZE, PRINT_TYPE, PRINT_TYPE_NAME, PRODUCT_FLAG, PRODUCT_FLAG_NAME, PRODUCT_MATERIAL_TYPE_OPTION } from "../../based/Constants";
import TFUAutoComplete from "../../based/TFUAutoComplete";
import CheckBox from "../../based/inputs/CheckBox";
import Selection from "../../based/inputs/Selection";
import TextNumber from "../../based/inputs/TextNumber";
import Textbox from "../../based/inputs/Textbox";
import ProductServices from "../../based/services/ProductServices";
import { ProductContext } from "../../context/ProductContext";

const PlatenSizeOptions = Object.keys(PLATEN_SIZE).map((item) => {
    return {
        value: PLATEN_SIZE[item],
        text: item,
        label: item,
    };
});
import styled from "styled-components";
export default function BasicInfo(props) {
    const { mainProduct, setMainProduct, addRef } = useContext(ProductContext);
    const [categoryKeyword, setCategoryKeyword] = useState("");
    const [productTypeOptions, setProductTypeOptions] = useState([]);

    useEffect(() => {
        if (mainProduct.categoryName) setCategoryKeyword(mainProduct.categoryName);
    }, [mainProduct.brandName, mainProduct.categoryName]);

    useEffect(() => {
        _getProductTypes();
    }, []);

    const _getProductTypes = async () => {
        let [err, data] = await ProductServices.GetProductTypes({ ...INITPAGING, pageNumber: 1, pageSize: 20 });
        if (!err && data) {
            let options = data.listObjects.map((x) => ({ value: x.id, text: x.name, label: x.name }));
            setProductTypeOptions(options);
        } else setProductTypeOptions([]);
    };

    const TFUSelectCat = useMemo(
        () => (
            <TFUAutoComplete
                id="categoryId"
                name="categoryId"
                urlFetchData={{
                    url: "/api/Category/get-list-children-category",
                    params: {
                        ...INITPAGING,
                        keyword: categoryKeyword || mainProduct.categoryName,
                    },
                }}
                onSelectedItemChanged={(item) => {
                    setCategoryKeyword(item.text);
                    if (!item.isTyping || mainProduct.categoryId) {
                        setMainProduct({
                            ...mainProduct,
                            categoryId: item.value,
                        });
                    }
                }}
                placeholder={mainProduct.categoryId > 0 ? "" : "--Ngành hàng--"}
                ref={addRef}
                value={mainProduct.categoryId}
                disabled={false}
            />
        ),
        [mainProduct.categoryId, mainProduct.brandId, categoryKeyword, mainProduct.name]
    );
    return (
        <Wrapper>
            <div className="section-basicinfo">
                <div className="col-sm-12 card-box p-3">
                    <h4 className="header-title m-t-0">
                        <b>Thông tin cơ bản</b>
                    </h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="name" className="col-form-label required">
                                    Tên sản phẩm
                                </label>
                                <Textbox
                                    required
                                    name="name"
                                    value={mainProduct.name}
                                    ref={addRef}
                                    maxLength={350}
                                    onChanged={(name, value) =>
                                        setMainProduct({
                                            ...mainProduct,
                                            name: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-row">
                                <React.Fragment>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="categoryId" className="col-form-label">
                                            Ngành hàng
                                        </label>
                                        {TFUSelectCat}
                                    </div>

                                    <React.Fragment>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="productMaterialType" className="col-form-label">
                                                Loại sản phẩm
                                            </label>
                                            <div className="form-group">
                                                <Selection
                                                    id="productMaterialType"
                                                    name="productMaterialType"
                                                    value={mainProduct.productMaterialType}
                                                    required
                                                    placeholder="---Chọn loại sản phẩm ---"
                                                    onChanged={(val) => setMainProduct({ ...mainProduct, productMaterialType: val })}
                                                    options={PRODUCT_MATERIAL_TYPE_OPTION}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor={"productTypeId"} className="col-form-label">
                                                Dòng sản phẩm
                                            </label>
                                            <div className="form-group">
                                                <Selection
                                                    id="productTypeId"
                                                    name="productTypeId"
                                                    value={mainProduct.productTypeId}
                                                    options={productTypeOptions}
                                                    required
                                                    placeholder="---Chọn dòng sản phẩm---"
                                                    onChanged={(value) => setMainProduct({ ...mainProduct, productTypeId: +value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor={"platenSize"} className="col-form-label">
                                                Khung in DTG
                                            </label>
                                            <div className="form-group">
                                                <Selection
                                                    id="platenSize"
                                                    name="platenSize"
                                                    value={mainProduct.platenSize}
                                                    options={PlatenSizeOptions}
                                                    required
                                                    placeholder="---Chọn kích thước khung in DTG---"
                                                    onChanged={(value) => setMainProduct({ ...mainProduct, platenSize: +value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor={"material"} className="col-form-label">
                                                Chất liệu
                                            </label>
                                            <Textbox name="material" value={mainProduct.material} onChanged={(name, value) => setMainProduct({ ...mainProduct, material: value })} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor={"weightMaterial"} className="col-form-label">
                                                Trọng lượng
                                            </label>
                                            <Textbox name="weightMaterial" value={mainProduct.weightMaterial} onChanged={(name, value) => setMainProduct({ ...mainProduct, weightMaterial: value })} />
                                        </div>
                                    </React.Fragment>
                                </React.Fragment>
                            </div>

                            <div className="form-row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor={"productFlag"} className="col-form-label">
                                        Phân loại sản phẩm
                                    </label>
                                    {Object.keys(PRODUCT_FLAG).map((key) => {
                                        let val = PRODUCT_FLAG[key];
                                        return (
                                            <CheckBox
                                                name={`productFlag-${key}`}
                                                isInline
                                                checked={Common.hasFlag(mainProduct.productFlag, val)}
                                                onCheckedChange={(name, value) => {
                                                    if (Common.hasFlag(mainProduct.productFlag, val)) {
                                                        setMainProduct({
                                                            ...mainProduct,
                                                            productFlag: (mainProduct.productFlag || 0) - val,
                                                        });
                                                    } else {
                                                        setMainProduct({
                                                            ...mainProduct,
                                                            productFlag: (mainProduct.productFlag || 0) + val,
                                                        });
                                                    }
                                                }}
                                                label={PRODUCT_FLAG_NAME[key]}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="form-group col-md-6 lst-print-type">
                                    <label htmlFor={"productType"} className="col-form-label">
                                        Kiểu in khả dụng
                                    </label>
                                    {Object.keys(PRINT_TYPE).map((key) => {
                                        let val = PRINT_TYPE[key];
                                        return (
                                            <div className="print-type">
                                                <CheckBox
                                                    name={`printType-${key}`}
                                                    isInline
                                                    checked={Common.hasFlag(mainProduct.printType, val)}
                                                    onCheckedChange={(name, value) => {
                                                        if (Common.hasFlag(mainProduct.printType, val)) {
                                                            setMainProduct({
                                                                ...mainProduct,
                                                                printType: (mainProduct.printType || 0) - val,
                                                            });
                                                        } else {
                                                            setMainProduct({
                                                                ...mainProduct,
                                                                printType: (mainProduct.printType || 0) + val,
                                                            });
                                                        }
                                                    }}
                                                    label={PRINT_TYPE_NAME[val]}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="form-row mt-2">
                                <div className="form-group col-md-3">
                                    <CheckBox
                                        name="isHot"
                                        isInline
                                        checked={mainProduct.isHot}
                                        onCheckedChange={(name, value) =>
                                            setMainProduct({
                                                ...mainProduct,
                                                isHot: value,
                                            })
                                        }
                                        label="Sản phẩm Hot"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <CheckBox
                                        name="isNewArrival"
                                        isInline
                                        checked={mainProduct.isNewArrival}
                                        onCheckedChange={(name, value) =>
                                            setMainProduct({
                                                ...mainProduct,
                                                isNewArrival: value,
                                            })
                                        }
                                        label="Sản phẩm mới"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <CheckBox
                                        name="isLowCost"
                                        isInline
                                        checked={mainProduct.isLowCost}
                                        onCheckedChange={(name, value) =>
                                            setMainProduct({
                                                ...mainProduct,
                                                isLowCost: value,
                                            })
                                        }
                                        label="Sản phẩm giá rẻ"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .lst-print-type {
        display: flex;
        flex-wrap: wrap;
    }

    .lst-print-type label.col-form-label {
        width: 100%;
        height: auto;
    }
`;
