import BaseServices from "./BaseServices";

const MidjourneyService = {

    CreateUpdateMidjourneyPass: async (model) => {
        return await BaseServices.Post("/api/MidjourneyAccount/create-update-midjourney-pass", model);
    },
    CreateUpdateTimeReset: async (model) => {
        return await BaseServices.Post("/api/MidjourneyAccount/create-update-time-reset", model);
    },
    GetMidjourneyPass: async () => {
        return await BaseServices.Get("/api/MidjourneyAccount/get-midjourney-pass");
    },
    GetTimeReset: async () => {
        return await BaseServices.Get("/api/MidjourneyAccount/get-time-reset");
    },



    CreateUpdateUserSender: async (model) => {
        return await BaseServices.Post("/api/MidjourneyAccount/create-update-user-sender", model);
    },
    DeleteUserSender: async (id) => {
        return await BaseServices.Get(`/api/MidjourneyAccount/delete-user-sender/${id}`);
    },
    GetUserSender: async (paging) => {
        return await BaseServices.Post("/api/MidjourneyAccount/get-user-sender", paging);
    },
};

export default MidjourneyService;
