import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CommonServices from "../based/services/CommonServices";
import FinanceServices from "../based/services/FinanceServices";

export default function CodGHN(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, shippingCompanyId: 1 });
    const [datas, setDatas] = useState([]);
    const [shippingServices, setShippingServices] = useState([]);

    useEffect(() => {
        document.getElementById("orderId").focus();
        getDatas(paging);
        getShippingServices();
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await FinanceServices.FinanceShippingGetPage(newPaging);
        if (!error && result) {
            setDatas(result.listObjects);
            console.log("result.listObjects", result.listObjects);
            setPaging(result.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function getShippingServices() {
        let [err, data] = await CommonServices.GetShippingServices();
        if (!err && data) setShippingServices(data);
        else setShippingServices([]);
    }

    async function markAsCompleted(id) {
        let [err, data] = await FinanceServices.MarkAsReceived(id);
        if (!err && data) {
            getDatas({ ...paging });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thành công");
        } else Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thất bại");
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className="text-center">
                        <span>STT</span>
                    </th>
                    <th width="200" className="text-center">
                        Mã đơn hàng
                    </th>
                    <th>Mã vận chuyển</th>
                    <th width="150" className="text-center">
                        Tên khách hàng
                    </th>
                    <th width="200" className="text-center">
                        Tổng số tiền của đơn hàng (đ)
                    </th>
                    <th width="200" className="text-center">
                        Tổng số tiền COD (đ)
                    </th>
                    <th width="200" className="text-center">
                        Chi phí vận chuyển (đ)
                    </th>
                    <th width="200" className="text-center">
                        ĐVVC thanh toán cho Ranus (đ)
                    </th>
                    <th width="200" className="text-center">
                        Ranus xác nhận số tiền nhận được từ ĐVVC (đ)
                    </th>
                    <th width="150" className="text-center">
                        Trạng thái
                    </th>
                    <th width="150" className="text-center">
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((order, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td width={40} className="text-center vertical-align-middle">
                                    {no}
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <a target="_blank" href={`/order-detail/${order.id}`}>
                                        <span className="m-r-5">{order.id}</span>
                                        <i className="mdi mdi-open-in-new"></i>
                                    </a>
                                </td>
                                <td className="vertical-align-middle">
                                    <strong>{order.orderNumber}</strong>
                                </td>
                                <td className="text-center vertical-align-middle"> {order.thirdPartyName}</td>
                                <td className="text-center vertical-align-middle">
                                    <strong>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={order.finallyPrice} />
                                    </strong>
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <strong>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={order.codAmount} />
                                    </strong>
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <strong>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={order.shippingServicesFee} />
                                    </strong>
                                </td>

                                <td className="text-center vertical-align-middle">
                                    <strong>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={order.paidToSender} />
                                    </strong>
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <strong>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={order.senderReceived} />
                                    </strong>
                                </td>
                                <td className="vertical-align-middle text-center">{order.shippingServicesStatusName}</td>
                                <td className="vertical-align-middle text-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            markAsCompleted(order.id);
                                        }}
                                    >
                                        Xác nhận số tiền thanh toán chính xác
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="100" className="text-center">
                            <span>Không có đơn hàng</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className="box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="orderId">Mã đơn hàng:</label>
                            <Textbox className="form-control" id="orderId" name="orderId" onChanged={(name, value) => setPaging({ ...paging, orderId: value })} value={paging.orderId} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="shippingOrderNumber">Mã vận chuyển:</label>
                            <Textbox className="form-control" id="shippingOrderNumber" name="shippingOrderNumber" onChanged={(name, value) => setPaging({ ...paging, shippingOrderNumber: value })} value={paging.shippingOrderNumber} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="time">Thời gian giao dịch:</label>
                            <div className="input-group form-row">
                                <div className="input-daterange input-group" id="date-range">
                                    <TFUDatetime dateFormat="dd/MM/yyyy" name="startDate" maxDate={paging.endDate} value={paging.startDate} placeholder="Từ ngày" onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat="dd/MM/yyyy" name="endDate" minDate={paging.startDate} value={paging.endDate} placeholder="Đến ngày" onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className="fa fa-search m-r-5" /> Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _renderPlatformTab() {
        return shippingServices.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a className={paging.shippingCompanyId == item.id ? "nav-link active" : "nav-link btn-light"} onClick={() => getDatas({ ...paging, shippingCompanyId: item.id, pageNumber: 1, pageSize: 10 })}>
                        <span>{item.name}</span>
                    </a>
                </li>
            );
        });
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Đối soát GHN/VNPOST" />
            <Loading show={isLoading} msg={msg} />
            <ul className="nav nav-tabs tabs-bordered">{_renderPlatformTab()}</ul>
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
