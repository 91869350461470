import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import BaseServices from "../based/services/BaseServices";
import FindingCollectionSelection from "./FindingCollectionSelection";
import ModalUploadBanner from "./ModalUploadBanner";

function CollectionManagement() {
    const [isBusy, setBusy] = useState(false);
    const [datas, setDatas] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [itemCol, setItemCol] = useState({});

    useEffect(() => {
        if (!isModal) {
            getDatas();
        }
    }, [isModal]);

    function getDatas() {
        setBusy(true);
        Request.Get("/api/CreatorCollectionShow/get-all-col-show")
            .then((res) => {
                setBusy(false);
                if (res.success) {
                    setDatas(res.data);
                    setBusy(false);
                } else {
                    setDatas([]);
                    setBusy(false);
                }
            })
            .catch((err) => {
                setDatas([]);
                setBusy(false);
            });
    }

    async function _handleRemoveProduct(id, showType) {
        let ids = [];
        ids.push(id);
        let [err, data] = await BaseServices.Post(`/api/CreatorCollectionShow/delete-col-show/${showType}`, ids);
        if (!err) {
            getDatas();
        }
    }
    async function _handleAddProduct(item) {
        let model = {
            id: 0,
            idCol: item.id,
            showType: item.showType,
        };
        let [err, data] = await BaseServices.Post("/api/CreatorCollectionShow/create-col-show", model);
        if (!err) {
            if (data) getDatas();
            else {
                Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Đã hiển thị");
            }
        }
    }

    return (
        <Wapper>
            {isModal && (
                <ModalUploadBanner
                    isShowModal={isModal}
                    onClose={() => {
                        setIsModal(false);
                        setItemCol({});
                    }}
                    item={itemCol}
                />
            )}
            <Breadcrumbs currentPage="Quản lý bộ sưu tập hiển thị" />
            <Loading show={isBusy} />
            <div className="w-100pc">
                <div className="table-category">
                    <div className="header-table">
                        <FindingCollectionSelection isSetCol={true} onSelectItem={_handleAddProduct} sm id="finding_set" name={"finding_set"} />
                    </div>
                    <div className="body-table">
                        {datas && datas.length > 0
                            ? datas.map((item, idx) => {
                                  return (
                                      <div key={idx} className="col-table">
                                          <div className="item-table">
                                              <div className="box-img">{item.thumbnail ? <img src={item.thumbnail} alt={item.name} /> : null}</div>
                                              <div className="description">{item.name}</div>
                                              <div className="flex-bottom">
                                                  <div
                                                      className="btn-banner"
                                                      onClick={() => {
                                                          setItemCol(item);
                                                          setIsModal(true);
                                                      }}
                                                  >
                                                      Sửa banner
                                                  </div>
                                                  <div
                                                      className="remove"
                                                      onClick={() => {
                                                          _handleRemoveProduct(item.id, item.showType);
                                                      }}
                                                  >
                                                      Xóa
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                </div>
            </div>
        </Wapper>
    );
}
const Wapper = styled.div`
    .w-100pc {
        width: 100%;
        background-color: #fff;

        .table-category {
            width: calc(100% - 10px);
            margin-left: 10px;
            background-color: #fff;

            .header-table {
                height: 50px;
                padding: 6px 12px;
                background: #d9d9d9;

                .tfu-autocomplete-input {
                }
            }
            .body-table {
                padding: 12px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -6px;
                overflow-y: auto;
                height: 500px;

                .col-table {
                    width: 16.6666%;
                    max-width: 16.6666%;
                    min-width: 16.6666%;
                    padding: 0 6px;

                    .item-table {
                        margin-bottom: 12px;
                        width: 100%;
                        padding: 6px;
                        border: 1px solid #d9d9d9;
                        position: relative;

                        .box-img {
                            width: 100%;
                            aspect-ratio: 1/1;

                            img {
                                width: 100%;
                                height: 100%;
                                min-height: 100%;
                                object-fit: contain;
                            }
                        }
                        .flex-bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .btn-banner {
                                cursor: pointer;
                                color: green;
                            }
                            .remove {
                                color: red;
                                cursor: pointer;
                            }
                        }

                        .box-name {
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            img {
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                                border-radius: 50%;
                            }
                            p {
                                font-size: 12px;
                                font-weight: bold;
                                line-height: 14px;
                                margin-left: 6px;
                                color: #000000;
                            }
                        }
                        .description {
                            width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-size: 14px;
                            line-height: 14px;
                            color: #676767;
                            margin-top: 6px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            height: 28px;
                        }
                    }
                }
            }
        }
    }
`;
export default CollectionManagement;
