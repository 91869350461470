import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTablePagination from "../based/CommonTablePagination";
import { INITPAGING, PLATFORM } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import PushingServices from "../based/services/PushingServices";
import { ProductPushingStatus } from "../platform-shop-management/ProductInformation";
import Lazada from "./assets/Lazada.png";
import Shopee from "./assets/Shopee.png";
import Tiktok from "./assets/Tiktok.png";

const pushingProductModel = {
    productId: 0,
    productType: 0,
    name: "",
    appProductId: 0,
    appProductName: "",
    colorName: "",
    productPushingStatus: 0,
    productPushingStatusName: "",
    thumbnail: "",
    printCostDefault: "",
    isTopPick: false,
    productPushingId: false,
    platformProductId: "",
};

const ListTabOption = [
    {
        value: ProductPushingStatus.Undefined,
        label: "Tất cả",
    },
    {
        value: ProductPushingStatus.Pending,
        label: "Đang chờ",
    },
    {
        value: ProductPushingStatus.Processing,
        label: "Đang xử lý",
    },
    {
        value: ProductPushingStatus.Failed,
        label: "Thất bại",
    },
    {
        value: ProductPushingStatus.Successed,
        label: "Thành công",
    },
];

function PushingManageTotal(props) {
    return (
        <Wrapper>
            <Breadcrumbs currentPage="Đẩy sản phẩm" />
            <TableTotal />
        </Wrapper>
    );
}

export default PushingManageTotal;

function TableTotal() {
    const [datas, setDatas] = useState([pushingProductModel]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 100, status: -1 });

    useEffect(() => {
        getData(paging);
    }, []);

    async function getData(page) {
        let [err, data] = await PushingServices.GetPushingProductTotalModels(page);
        if (!err && data) {
            let { listObjects, paging } = data;
            setDatas(listObjects);
            setPaging(paging);
        } else {
            if (page.pageNumber == 1) {
                setDatas([]);
                setPaging({ ...INITPAGING });
            }
        }
    }

    function handleChangePage(page) {
        let newPaging = {
            ...paging,
            pageNumber: page,
        };
        getData(newPaging);
    }

    function handleChangePageSize(pageSize) {
        let newPaging = {
            ...paging,
            pageNumber: 1,
            pageSize: pageSize,
        };
        getData(newPaging);
    }

    var PushingStatus = {
        Undefined: -1,
        Pending: 0,
        Processing: 1,
        Failed: 2,
        Successed: 3,
    };

    function renderIconPushStatus(item) {
        if (item.pushingStatus == PushingStatus.Successed) {
            switch (item.platform) {
                case PLATFORM.Shopee:
                    return (
                        <div
                            className="image-icon"
                            onClick={(e) => {
                                if (item.platformProductId) {
                                    window.open(`https://shopee.vn/product/${item.shopId}/${item.platformProductId}/`, "_blank");
                                }
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <img src={Shopee} alt="Shopee" />
                            <span>{item.shopName}</span>
                        </div>
                    );
                case PLATFORM.Tiktok:
                    return (
                        <div
                            className="image-icon"
                            onClick={(e) => {
                                if (item.platformProductId) {
                                    window.open(`https://shop.tiktok.com/view/product/${item.platformProductId}`, "_blank");
                                }
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <img src={Tiktok} alt="Tiktok" />
                            <span>{item.shopName}</span>
                        </div>
                    );
                case PLATFORM.Lazada:
                    return (
                        <div
                            className="image-icon"
                            onClick={(e) => {
                                if (item.platformProductId) {
                                    window.open(`https://www.lazada.vn/products/i${item.platformProductId}.html`, "_blank");
                                }
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <img src={Lazada} alt="Lazada" />
                            <span>{item.shopName}</span>
                        </div>
                    );
                default:
                    break;
            }
        } else {
            return (
                <div>
                    <span className="other">
                        {item.pushingStatusName} ({item.platformName}) -
                    </span>
                    <span>{item.shopName}</span>
                </div>
            );
        }
    }

    return (
        <TableTotalWrapper>
            <div className="table-total">
                <div className="table-action row">
                    <div className="col-md-6 form-row">
                        <div className="col-md-8 my-3">
                            <label htmlFor="search" className="col-md-12">
                                Tìm kiếm
                            </label>
                            <div className="col-md-12 search-area">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="search"
                                    id="search"
                                    placeholder="Tìm kiếm"
                                    value={paging.keyword}
                                    onChange={(e) => {
                                        setPaging({ ...paging, keyword: e.target.value });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            getData({ ...paging, pageNumber: 1 });
                                        }
                                    }}
                                />
                                <button
                                    className="btn-search"
                                    onClick={(e) => {
                                        getData({ ...paging, pageNumber: 1 });
                                    }}
                                >
                                    Tìm kiếm
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="list-action">
                                <div className="form-group">
                                    <label htmlFor="">Trạng thái</label>
                                    <TFUSelect
                                        options={ListTabOption}
                                        value={paging.status}
                                        onChanged={(value) => {
                                            getData({ ...paging, status: value });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-body">
                    {datas && datas.length > 0 ? (
                        datas.map((item, idx) => {
                            return (
                                <div className="table-item" key={idx}>
                                    <div className="table-image">
                                        {<img src={item.thumbnail} alt={item.name} />}
                                        <div className="push-product-type">
                                            <div className="badge badge-danger">Tự thiết kế</div>
                                        </div>
                                        {item.artworkCount > 1 && <div className="print-side badge badge-custom">{item.artworkCount} mặt</div>}
                                    </div>
                                    <div className="table-info">
                                        <div className="table-name">{item.name}</div>
                                        <div className="table-price">
                                            <span className="sale-price">{item.salePrice > 0 ? Common.formatCurrency(item.salePrice) : ""}</span>
                                            <span className="sell-price">{item.sellPrice > 0 ? Common.formatCurrency(item.sellPrice) : ""}</span>
                                        </div>
                                        <div className="table-status">
                                            {item.productPushModels &&
                                                item.productPushModels.map((pushItem, pushIdx) => {
                                                    return (
                                                        <div className="push-item" key={pushIdx}>
                                                            {renderIconPushStatus(pushItem)}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className="table-action">
                                            <button
                                                className={`btn btn-custom ${item.salePrice > 0 ? "" : "disabled"}`}
                                                onClick={(e) => {
                                                    window.open(`/pushing-multi-detail/${item.designId}`, "_blank");
                                                }}
                                            >
                                                Đẩy sản phẩm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-item"></div>
                    )}
                </div>
                <div className="table-bottom">
                    <CommonTablePagination paging={paging} onChangePage={handleChangePage} onChangePageSize={handleChangePageSize} />
                </div>
            </div>
        </TableTotalWrapper>
    );
}

const Wrapper = styled.div`
    .push-product-type {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .table-image {
        position: relative;
    }

    .print-side.badge.badge-custom {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .col-md-12.search-area {
        display: flex;
        align-items: center;
    }

    button.btn-search {
        display: flex;
        border: 0;
        background: #64c5b1;
        padding: 8px 37px;
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    input#search {
        flex: 1;
        flex-shrink: 0;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 0;
        height: 100%;
    }

    button.btn-search {
    }

    td.center-image {
        width: 120px;
        height: 120px;
    }

    .center-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const TableTotalWrapper = styled.div`
    span.other {
        display: inline-block !important;
    }

    .image-icon {
        display: flex;
        align-items: center;
    }

    .image-icon span {
        margin-left: 6px;
        display: flex;
        color: black !important;
    }

    .table-status {
        display: flex;
        flex-wrap: wrap;
    }

    .push-item span {
        color: #febd6a;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .push-item {
        margin: 5px;
    }
    .table-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .table-item {
        width: 200px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        padding: 8px 12px;
        background: white;
        border: 1px solid #d9d9d9;
    }

    .table-image {
        width: 100%;
        height: auto;
    }

    .table-image img {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    .table-info {
        background: white;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .table-name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        /* 142.857% */
        margin: 3px 0;
        height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    span.sale-price {
        color: #f96a74;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    span.sell-price {
        color: #a4a4a4;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-decoration: line-through;
        margin-left: 5px;
    }

    .table-action {
        margin-top: auto;
    }
`;
