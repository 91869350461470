import Pubsub from "pubsub-js";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CampaignServices from "../based/services/CampaignServices";
import CampaignModel from "../models/Campaign/CampaignModel";
import AddOrEdit from "./AddOrEdit";

export default function CampaignFreeShip(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([new CampaignModel()]);
    const [showModal, setShowModal] = useState(false);
    const [campaignId, setCampaignId] = useState(0);

    useEffect(() => {
        Pubsub.publish(CONSTANTS.VOUCHER, { mainMenu: MAINMENU.CampaignFreeShip });
        let pagingNew = { ...Common.Paging };
        getDatas(pagingNew);
    }, []);

    /** lấy thông tin campaign buy more */
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await CampaignServices.GetCampaignFreeShipByPaging(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(newPaging);
        }
        setIsLoading(false);
        setMsg(null);
    }

    /** handle check box */
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };

    /** handle add */
    function _handleAdd() {
        setCampaignId(0);
        setShowModal(true);
    }

    /** handle delete */
    function _handleDelete() {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa campaign này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [err, data] = await CampaignServices.RemoveCampaignFreeShipByIds(ids);
                    if (!err && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas({ ...paging });
                    } else {
                        let msg = err && err.data ? err.data : "Thất bại";
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
                    }
                    setIsLoading(false);
                    setMsg(null);
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có campaign nào được chọn. Vui lòng kiểm tra lại!");
    }

    /** handle filter */
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
    }

    /** render */
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className="text-center">
                        <CheckBox id="select_all" name="select_all" checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width="150" className="text-center">
                        Tên Campaign
                    </th>
                    <th width="150" className="text-center">
                        Thời gian hiển thị
                    </th>
                    <th width="150" className="text-center">
                        Thời gian bắt đầu
                    </th>
                    <th width="100" className="text-center">
                        Thời gian kết thúc
                    </th>
                    <th width="100" className="text-center">
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderButton(item) {
        let btn = (
            <a
                className="font-weight-bold"
                style={{ color: "#64c5b1", cursor: "pointer" }}
                onClick={() => {
                    setCampaignId(item.id);
                    setShowModal(true);
                }}
            >
                Chỉnh sửa
            </a>
        );
        return btn;
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td width={40} className="text-center vertical-align-middle">
                                    {Common.IsAdmin() ? <CheckBox id={i.toString()} name={i.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(i, value)} /> : <span> {no}</span>}
                                </td>
                                <td className="text-center vertical-align-middle"> {item.title}</td>
                                <td className="text-center vertical-align-middle"> {Common.formatDate(new Date(item.showTime), "datetime")}</td>
                                <td className="text-center vertical-align-middle"> {Common.formatDate(new Date(item.startTime), "datetime")}</td>
                                <td className="text-center vertical-align-middle"> {Common.formatDate(new Date(item.endTime), "datetime")}</td>
                                <td className="text-center vertical-align-middle">{_renderButton(item)}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="20" className="text-center">
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="name">Tên Campaign:</label>
                            <Textbox className="form-control" name="title" onChanged={(name, value) => setPaging({ ...paging, title: value })} value={paging.title} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="time">Thời gian áp dụng:</label>
                            <div className="input-group form-row">
                                <div className="input-daterange input-group" id="date-range">
                                    <TFUDatetime
                                        dateFormat="dd/MM/yyyy"
                                        name="startDate"
                                        maxDate={paging.endDate}
                                        value={paging.startDate ? new Date(paging.startDate) : null}
                                        placeholder="Từ ngày"
                                        onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat="dd/MM/yyyy"
                                        name="endDate"
                                        minDate={paging.startDate}
                                        value={paging.endDate ? new Date(paging.endDate) : null}
                                        placeholder="Đến ngày"
                                        onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleFilter()}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                            {Common.IsAdmin() && (
                                <React.Fragment>
                                    <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleAdd()}>
                                        <i className="fa fa-plus m-r-5" /> Thêm mới
                                    </button>
                                    <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger btn-sm m-r-5" : "btn btn-danger btn-sm m-r-5 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                                        <i className="fa fa-remove m-r-5" /> Xóa
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Campaign Buy More" />
            <Loading show={isLoading} msg={msg} />
            {showModal && (
                <AddOrEdit
                    isShowModal={showModal}
                    campaignId={campaignId}
                    onClose={() => setShowModal(false)}
                    onSaveSucceed={(value) => {
                        setShowModal(false);
                        getDatas(paging);
                    }}
                />
            )}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
