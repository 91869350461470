import { OrderTagDetailModel } from "./OrderTagDetailModel";
import { OrderTagItemModel } from "./OrderTagItemModel";
import { OrderTagMockupModel } from "./OrderTagMockupModel";

export class OrderTagDTGModel {
    constructor() {
        this.orderTagDetail = new OrderTagDetailModel();
        this.orderTagItems = [new OrderTagItemModel()];
        this.orderTagMockup = new OrderTagMockupModel();
    }
}
