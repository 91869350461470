import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PlatformServices from "../based/services/PlatformServices";
import TFUSelect from "../based/inputs/TFUSelect";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";

const ProductLowCostDiscountDTO = {
    shopId: "", // string
    platform: 0, // Platform enum/object or other structure as needed
    productId: 0, // number (long in C#)
    lowCostDiscount: 0.0, // number (decimal in C#)
    productName: "",
    productAvatar: "",
};

function DiscountPlatform({ shopid, platform }) {
    const [listModel, setListModel] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getModel();
        getOptions();
    }, []);

    const getModel = async () => {
        const [err, data] = await PlatformServices.GetProductLowCostDiscounts({ shopId: shopid, platform: platform });
        if (!err && data) setListModel(data);
        else setListModel([]);
    };

    const getOptions = async () => {
        const [err, data] = await PlatformServices.GetProductOptions();
        if (!err && data) {
            let lst = data.map((item, idx) => {
                return {
                    value: item.productId,
                    label: item.productName,
                    avatar: item.productAvatar,
                };
            });
            setOptions(lst);
        } else setOptions([]);
    };

    const handleSave = async () => {
        const [err, data] = await PlatformServices.CreateUpdateProductLowCostDiscount(listModel);
        if (!err && data) {
            getModel();
            Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Lưu thất bại");
        }
    };

    return (
        <Wrapper>
            <div className="header-action">
                <div className="header-action-left">
                    <button
                        className="btn btn-custom btn-sm"
                        onClick={(e) => {
                            let dto = { ...ProductLowCostDiscountDTO };
                            dto.shopId = shopid;
                            dto.platform = platform;
                            setListModel([...listModel, dto]);
                        }}
                    >
                        Thêm loại sản phẩm
                    </button>
                </div>
            </div>
            <div className="body-data col-md-6">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Tên sản phẩm</th>
                            <th>Hình ảnh</th>
                            <th>Giá giảm</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listModel.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <TFUSelect
                                        name={`product-${index}`}
                                        id={`product-${index}`}
                                        options={options}
                                        value={item.productId}
                                        onChanged={(newValue) => {
                                            console.log(newValue);
                                            let option = options.find((x) => x.value == newValue);
                                            let lm = [...listModel];
                                            let item = lm[index];
                                            item.productId = option.value;
                                            item.productName = option.label;
                                            item.productAvatar = option.avatar;
                                            setListModel([...lm]);
                                        }}
                                    />
                                </td>
                                <td>
                                    <img className="product-avatar" src={item.productAvatar} alt="" />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="lowcost-input form-control"
                                        value={item.lowCostDiscount}
                                        onChange={(e) => {
                                            let lm = [...listModel];
                                            let item = lm[index];
                                            item.lowCostDiscount = e.target.value;
                                            setListModel([...lm]);
                                        }}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                            let lm = [...listModel];
                                            lm.splice(index, 1);
                                            setListModel([...lm]);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="list-button-bottom">
                    <button
                        className="btn btn-custom btn-sm"
                        onClick={(e) => {
                            handleSave();
                        }}
                    >
                        Lưu
                    </button>
                </div>
            </div>
        </Wrapper>
    );
}

export default DiscountPlatform;

const Wrapper = styled.div`
    .header-action {
        margin: 12px 0;
    }

    img.product-avatar {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
`;
