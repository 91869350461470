import React, { useState, useEffect } from "react";
import PubSub from "pubsub-js";
import { CONSTANTS, MAINMENU, NOTIFY, IMAGE_SITE, IMAGE_TYPE, LAYOUT_BANNER } from "../based/Constants";
// import { Layout } from "../layout/Layout";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CampaignServices from "../based/services/CampaignServices";
import BannerServices from "../based/services/BannerServices";
import Breadcrumbs from "../based/Breadcrumbs";
import MultipleImages from "../based/inputs/MultipleImages";
import Request from "../based/Request";

const BANNERS = [
    {
        title: "Banner Homepage",
        showPanel: true,
        layout: LAYOUT_BANNER.HOMEPAGE,
        imageType: IMAGE_TYPE.Slider,
    },
    {
        title: "Banner Voucher",
        showPanel: true,
        layout: LAYOUT_BANNER.VOUCHER,
        imageType: IMAGE_TYPE.Slider,
    },
    {
        title: "Banner Creator Profile",
        showPanel: true,
        layout: LAYOUT_BANNER.CREATOR_PROFILE,
        imageType: IMAGE_TYPE.BannerCreatorProfile,
    },
    {
        title: "Section Sản phẩm mới",
        showPanel: true,
        layout: LAYOUT_BANNER.HOMEPAGE,
        imageType: IMAGE_TYPE.SectionProductNew,
    },
    {
        title: "Section Mua ngay giá tốt",
        showPanel: true,
        layout: LAYOUT_BANNER.HOMEPAGE,
        imageType: IMAGE_TYPE.SectionProductMilestone,
    },
    {
        title: "Section Sản phẩm hot",
        showPanel: true,
        layout: LAYOUT_BANNER.HOMEPAGE,
        imageType: IMAGE_TYPE.SectionProductHot,
    },
    {
        title: "Section Sản phẩm cho bạn",
        showPanel: true,
        layout: LAYOUT_BANNER.HOMEPAGE,
        imageType: IMAGE_TYPE.SectionProductCardForYou,
    },
];
const BannerSetting = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [listUrlRedirect, setListUrlRedirect] = useState([]);

    const [banners, setBanners] = useState(BANNERS);

    useEffect(() => {
        getBanners();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Settings });
    }, []);

    const getBanners = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu....");
        const [err, data] = await BannerServices.GetBannersSetting(); 
        if (!err && data) {
            let banners = BANNERS;
            banners.map((banner) => {
                data.map((item) => {
                    if (banner.imageType == item.imageType && banner.layout == item.layout) {
                        banner.bannersDesktop = item.bannersDesktop;
                        banner.bannersMobile = item.bannersMobile;
                    }
                });
            });
            setBanners(banners);
            setIsLoading(false);
            setMsg("");
            getUrlRedirect();
        } else {
            setBanners(BANNERS);
            setIsLoading(false);
            setMsg("");
            getUrlRedirect();
        }
    };

    const getUrlRedirect = async () => {
        setIsLoading(true);
        const [err, data] = await CampaignServices.GetListShortLink();
        if (!err && data) {
            setListUrlRedirect(data);
            setIsLoading(false);
        } else {
            setListUrlRedirect([]);
            setIsLoading(false);
        }
    };

    function _handlShowPannel(item, idx) {
        let bannersUp = [...banners];
        bannersUp[idx].showPanel = !bannersUp[idx].showPanel;
        setBanners(bannersUp);
    }
    function _handleImageChange(key, images, banner, idx) {
        let bannersUp = [...banners];
        let currentBanner = bannersUp[idx];
        currentBanner[key] = currentBanner[key] && currentBanner[key].length > 0 ? currentBanner[key] : [];
        if (images && images.length > 0) {
            images.map((img) => {
                var existed = currentBanner[key].find((x) => x.url == img);
                if (!existed) {
                    var item = {
                        url: img,
                        layout: banner.layout,
                        imageSite: key == "bannersDesktop" ? IMAGE_SITE.Desktop : IMAGE_SITE.Mobile,
                        imageType: banner.imageType,
                        urlRedirect: "",
                    };
                    currentBanner[key].push(item);
                }
            });
        }
        setBanners(bannersUp);
    }
    async function _handleRemoveImageBanner(image, imageIdx, key, bannerIdx) {
        let bannersUp = [...banners];
        let currentBanner = bannersUp[bannerIdx];
        if (currentBanner[key] && currentBanner[key].length > 0) {
            let img = image.url;
            img = img.split("/")[img.split("/").length - 1];
            Request.DeleteImage(img)
                .then((res) => {
                    currentBanner[key].splice(imageIdx, 1);
                    setBanners(bannersUp);
                    if (image.id > 0) BannerServices.DeleteBanner(image.id);
                })
                .catch((err) => {});
        }
    }
    const _renderBannerUrlRedirect = (image, key, idx) => {
        return (
            <select className='form-control' style={{ marginTop: 10 }} value={image.urlRedirect} onChange={(e) => handleChangeSelectUrl(key, image, e.target.value, idx)}>
                <option value=''>--Chọn đường dẫn điều hướng--</option>
                {listUrlRedirect && listUrlRedirect.length > 0
                    ? listUrlRedirect.map((url, k) => {
                          return (
                              <option key={k} value={url}>
                                  {url}
                              </option>
                          );
                      })
                    : null}
            </select>
        );
    };
    function handleChangeSelectUrl(key, image, urlRedirect, bannerIdx) {
        let bannersUp = [...banners];
        let currentBanner = bannersUp[bannerIdx];
        if (currentBanner[key] && currentBanner[key].length > 0) {
            currentBanner[key].map((banner) => {
                if (banner.url == image.url) banner.urlRedirect = urlRedirect;
            });
            setBanners(bannersUp);
        }
    }
    async function handleSaveBanners(banner) { 
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");
        if (!banner) Notify(NOTIFY.WARNING, "Chú ý", "Yêu cầu upload banner trước khi lưu");
        const [err, data] = await BannerServices.SaveBannersSetting(banner);
        if (!err && data) {
            setIsLoading(false);
            setMsg("");
            Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công.");
        } else {
            setIsLoading(false);
            setMsg("");
            Notify(NOTIFY.ERROR, "Lỗi", err.data ? err.data : err.message);
        }
    }   
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý Banner' />
            <Loading show={isLoading} msg={msg} />
            {banners.map((banner, idx) => {
                let idDesktop = `desktop${banner.imageType}${banner.layout}`;
                let idMobile = `mobile${banner.imageType}${banner.layout}`;
                return (
                    <div key={idx} className={idx == 0 ? "card bg-transparent" : "card bg-transparent mt-3"}>
                        <div className='card-header position-relative' onClick={() => _handlShowPannel(banner, idx)}>
                            <h6 className='m-0'>{banner.title}</h6>
                            <i className={banner.showPanel ? "fa fa-angle-double-up" : "fa fa-angle-double-down"} style={{ position: "absolute", top: 15, left: 5 }}></i>
                        </div>
                        {banner.showPanel && (
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-6 card-box' style={{ padding: 20 }}>
                                        <h4 className='header-title mb-4 px-2'>
                                            Banner desktop
                                            <button
                                                className='btn btn-success btn-xs float-right'
                                                onClick={() => {
                                                    document.getElementById(`${idDesktop}`).click();
                                                }}
                                            >
                                                <span className='fa fa-plus'></span>
                                            </button>
                                        </h4>
                                        <div className='bg-white'>
                                            <MultipleImages className='hide' id={idDesktop} name={idDesktop} images={[]} onChanged={(key, value) => _handleImageChange("bannersDesktop", value, banner, idx)} />
                                            <div className='box-images'>
                                                {banner.bannersDesktop && banner.bannersDesktop.length > 0 && (
                                                    <ul className='resCmtImg form-row' style={{ margin: 0 }}>
                                                        {banner.bannersDesktop.map((image, i) => {
                                                            return (
                                                                <li key={i} className='cmt-image-item col-md-4' style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.url} className='full-width' style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className='fbDelImg' style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image, i, "bannersDesktop", idx)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    {_renderBannerUrlRedirect(image, "bannersDesktop", idx)}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 card-box' style={{ padding: 20 }}>
                                        <h4 className='header-title mb-4 px-2'>
                                            Banner mobile
                                            <button
                                                className='btn btn-success btn-xs float-right'
                                                onClick={() => {
                                                    document.getElementById(`${idMobile}`).click();
                                                }}
                                            >
                                                <span className='fa fa-plus'></span>
                                            </button>
                                        </h4>
                                        <div className='bg-white'>
                                            <MultipleImages className='hide' id={idMobile} name={idMobile} images={[]} onChanged={(key, value) => _handleImageChange("bannersMobile", value, banner, idx)} />
                                            {banner.bannersMobile && banner.bannersMobile.length > 0 && (
                                                <ul className='resCmtImg form-row' style={{ margin: 0 }}>
                                                    {banner.bannersMobile.map((image, i) => {
                                                        return (
                                                            <li key={i} className='cmt-image-item col-md-4' style={{ marginRight: 0, marginBottom: 20 }}>
                                                                <div>
                                                                    <img src={image.url} className='full-width' style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                    <i className='fbDelImg' style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image, i, "bannersMobile", idx)}>
                                                                        x
                                                                    </i>
                                                                </div>
                                                                {_renderBannerUrlRedirect(image, "bannersMobile", idx)}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={(banner.bannersDesktop && banner.bannersDesktop.length > 0) || (banner.bannersMobile && banner.bannersMobile.length > 0) ? "btn btn-custom btn-sm" : "btn btn-custom btn-sm disable"}
                                    onClick={() => handleSaveBanners(banner)}
                                >
                                    <i className='fa fa-save m-r-5'></i>Lưu {banner.title}
                                </button>
                            </div>
                        )}
                    </div>
                );
            })} 
        </React.Fragment>
    );
};

export default BannerSetting;
