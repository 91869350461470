import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import SocialAccountServices from "../based/services/SocialAccountServices";
import AddOrUpdateAccountModal from "./AddOrUpdateAccountModal";

export const initSocialAccount = {
    id: 0,
    nameAccount: "",
    namePage: "",
    username: "",
    password: "",
    accountUrl: "",
    social: 0,
    isDelete: false,
    updatedAt: new Date(),
   
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

export const TYPE_SOCIAL = [
    "NONE",
    "Facebook",
    "Instagram",
    "Zalo",
    "Telegram",
    "Twitter",
    "Tiktok",
    "Youtube",
    "WeChat"
]


export const TYPE_SOCIAL_DROPDOWN = [
    {
        value: 1, label: "Facebook"
    },
    {
        value: 2, label: "Instagram"
    },
    {
        value: 3, label: "Zalo"
    },
    {
        value: 4, label: "Telegram"
    },
    {
        value: 5, label: "Twitter"
    },
    {
        value: 6, label: "Tiktok"
    },
    {
        value: 7, label: "Youtube"
    },
    {
        value: 8, label: "WeChat"
    },

]

function SocialAccountManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [selectId,setSelectId] = useState(-1)
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(paging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await SocialAccountServices.GetSocialAccountByPage(paging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };
    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắc muốn xóa các tài khoản đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await SocialAccountServices.DeleteSocialAccount(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có tài khoản nào được chọn. Vui lòng kiểm tra lại!");
    };
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox id="select_all" name="select_all" checked={data && data.length > 0 && data.filter((x) => x.selected).length === data.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th className="cursor-pointer">Loại MXH</th>
                    <th className="cursor-pointer">Tên MXH</th>
                    <th className="cursor-pointer">Tên trang/kênh</th>
                    <th className="cursor-pointer">Username</th>
                    <th className="cursor-pointer">Password</th>
                    <th className="cursor-pointer">Link profile</th>
                    <th className="cursor-pointer">Ngày cập nhật</th>
                    <th className="">Hành động</th>
                </tr>
            </thead>
        );
    }
   

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>
                                    {TYPE_SOCIAL[item.social]}
                                </td>
                                <td>
                                    {item.nameAccount}
                                </td>
                                <td>{item.namePage}</td>
                                <td>
                                   {item.username}
                                </td>
                                <td>
                                    {item.password}
                                </td>
                                <td>
                                    {item.accountUrl ? 
                                    <a href={item.accountUrl} target="_blank">{item.accountUrl}</a>
                                    :""    
                                }
                                </td>
                                <td>
                                    {Common.formatDate(new Date(item.updatedAt), "datetime")}
                                </td>
                                <td>
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);

                    }}
                >
                    Thêm mới
                </button>

                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger ml-3" : "btn btn-danger ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý tài khoản mạng xã hội" />
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={data} paging={paging} ref={tableRef} headerCheckbox searchBasic hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getData} />
            {isShowModal && (
                <AddOrUpdateAccountModal
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </React.Fragment>
    );
}

const Wrapper = styled.div``;
SocialAccountManagement.propTypes = {};

export default SocialAccountManagement;
