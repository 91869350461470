import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import MarketingServices from "../based/services/MarketingServices";
import DragAndDropIndex from "../based/DragAndDropIndex";
import Request from "../based/Request";
import ModalConfigTime from "./modals/ModalConfigTime";
import TFUSelect from "../based/inputs/TFUSelect";

const initPage = {
    pageSize: 50,
    pageNumber: 1,
};

const initModel = {
    id: 0,
    name: "",
    voucherId: 0

}

const TYPE_GIFT = {
    RANUS: 0,
    VOUCHER: 2
}
const MarketingGiftRanus = ({campaignId, setBusy}) => {
    const [data , setData] = useState([])
    const tableRef = useRef(null);
    const [paging , setPaging] = useState({})
    const [giftItem , setGiftItem] = useState({})
    const [isShowModalConfig , setIsShowModalConfig] = useState(false);
    const [type , setType] = useState(TYPE_GIFT.RANUS)
    const [voucherOptions , setVoucherOptions] = useState([])

    useEffect(()=>{
        getVoucher()
    },[])

    useEffect(() =>{
        getData()
    },[campaignId , type])

    const getData = async() => {
        setBusy(true);
        let [err, data] = await MarketingServices.GetGiftByCampaignId(campaignId, type);
        if (!err && data) {
            setData(data)
        } else {
            setData([]);
        }
        setBusy(false);
    }
    const getVoucher = async() => {
        setBusy(true);
        let [err, data] = await MarketingServices.GetVoucherDropDown();
        if (!err && data) {
            setVoucherOptions(data)
        } else {
            setVoucherOptions([]);
        }
        setBusy(false);
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>
                        STT
                    </th>
                    <th>Tên giải thưởng</th>
                    <th>Tổng phần quà</th>
                    <th>Số khung giờ đặc biệt</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }
    async function _handleDelete(id, idx) {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeleteGift(id);
                    if (!error && data) {
                        if(data === 1){
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                            getData();
                        }
                        if(data === -7){
                            Notify(NOTIFY.WARNING,"Lưu ý", "Chiến dịch đã chạy , không thể xóa");
                        }
                        

                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{width: 100}}>{idx + 1}</td>
                                <td>
                                    {item.id <= 0 || item.isUpdate ? (
                                        (type == TYPE_GIFT.RANUS && <input style={{
                                            width: '100%',
                                            height: '40px',
                                            border: 'none',
                                            outline: 'none',
                                            padding: '0 10px'
                                        }}
                                        className="input-in-table"
                                        type="text"
                                        placeholder="Tên giải thưởng"
                                        value={item.name}
                                        onChange={(e) => {
                                            let dt = [...data];
                                            dt[idx].name = e.target.value;
                                            setData(dt);
                                        }}
                                    />)
                                        ||
                                        (type == TYPE_GIFT.VOUCHER && <TFUSelect name='voucher' id='voucher' 
                                        value={item.voucherId} options={voucherOptions} 
                                        onChanged={(value) => {
                                            let dt = [...data];
                                            dt[idx].voucherId = value;
                                            setData(dt);
                                        }} />)
            
                                    ) : (

                                        (type == TYPE_GIFT.VOUCHER && item.voucherName) ||
                                        (type == TYPE_GIFT.RANUS && item.name)
                                        
                                    )}
                                </td>
                                <td style={{ width: 200 }}>{item.countTotalAllCampaign}</td>
                                <td style={{ width: 200 }}>{item.countSpecial}</td>
                                <td style={{ width: 300, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) {
                                                    handleCreateOrUpdate(idx);
                                                }
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    {item.id > 0 && <button className={"btn btn-custom m-1"}
                                        onClick={()=>{
                                            setGiftItem(item)
                                            setIsShowModalConfig(true)
                                        }}
                                    >Config tỷ lệ</button>}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                          _handleDelete(item.id , idx)
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    async function handleAddItem() {
        setData([...data, { ...initModel,giftType: type, marketingCampaignId: campaignId }]);
    }
    function _renderAction() {
       return <ul className="nav nav-tabs tabs-bordered mb-3" style={{ alignSelf: "flex-start" }}>
       <li className="nav-item">
           <a className={type == TYPE_GIFT.RANUS ? "nav-link active" : "nav-link btn-light"} onClick={() => setType(TYPE_GIFT.RANUS)}>
               <span>Quà Ranus</span>
           </a>
       </li>
       <li className="nav-item">
           <a className={type == TYPE_GIFT.VOUCHER ? "nav-link active" : "nav-link btn-light"} onClick={() => setType(TYPE_GIFT.VOUCHER)}>
               <span>Ranus Voucher</span>
           </a>
       </li>
   </ul>
    }


    function IsDisable(idx) {
        if ((data[idx].giftType == TYPE_GIFT.RANUS && !data[idx].name) ||
        (data[idx].giftType == TYPE_GIFT.VOUCHER && data[idx].voucherId <= 0)) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx],marketingCampaignId: campaignId , giftType: type };
        let [err, dt] = await MarketingServices.CreateUpdateGift(dataItem);
        if (!err && dt) {
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            getData()
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    return <div>
            {isShowModalConfig && <ModalConfigTime isShowModal={isShowModalConfig} onClose={()=>{
                setIsShowModalConfig(false)
                setGiftItem({})
                getData()
            }} gift={giftItem} 
            campaignId={campaignId}
            />}
            <CommonTable data={data} paging={paging} hideSearchBasicButton 
                renderAction={_renderAction} 
                renderHeader={_renderHeader} 
                renderBody={_renderBody} 
                ref={tableRef} 
             />
    </div>
}

export default MarketingGiftRanus