import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Common from '../../../based/Common';
import { CONSTANTS, NOTIFY } from '../../../based/Constants';
import { ConfirmDialog } from '../../../based/Dialog';
import TextArea from '../../../based/inputs/TextArea';
import Language from '../../../based/Language';
import Loading from '../../../based/Loading';
import { Notify } from '../../../based/Notify';
import TFUSelect from '../../../based/refactor/TFUSelect';
import Request from '../../../based/Request';
import BaseServices from '../../../based/services/BaseServices';
const INIT_DECLINE_MODEL = {
    email: '',
    isApproved: false,
    declinedReason: ''
}
export default function AffiliatorReviewForm(props) {
    const [model, setModel] = useState(null);
    const [showPreviewImage, setShowPreviewImage] = useState(false)
    const [previewImg, setPreviewImg] = useState('');
    const [showDeclineForm, setShowDeclineForm] = useState(false)
    const [declineReason, setDeclineReason] = useState(INIT_DECLINE_MODEL);
    const [isBusy, setBusy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [errors, setErrors] = useState(new Map());
    useEffect(() => {
        if (props.isShow) {
            //call api get detail
            _fetDetail();
        }
    }, [props.isShow])

    async function _fetDetail() {
        setBusy(true);
        const [err, resData] = await BaseServices.Get(`/api/affiliate/get-third-party-info?id=${props.data.thirdPartyId}`);
        setBusy(false);
        if (!err && resData) {
            setModel(resData);
            if (resData.personalInformation && resData.personalInformation.affiliateVIPStatus == 1) {
                const [err2, data2] = await BaseServices.Get('/api/affiliate/get-affiliate-groups');
                if (!err2 && data2) {
                    setGroups(data2);
                }
            }
        } else {

        }
    }

    function _renderPersonalInfo() {
        let info = model.personalInformation;
        return <div className='card card-body p-10 m-b-10'>
            <h4 className="header-title m-t-0"><b>Thông tin cá nhân</b></h4>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Email:</label>
                        <div className='col-md-8'>
                            <strong>{model.emailVerification && model.emailVerification.email}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Họ tên:</label>
                        <div className='col-md-8'>
                            <strong>{info.fullName}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Ngày sinh:</label>
                        <div className='col-md-8'>
                            <strong>{info.birthdayText}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Giới tính:</label>
                        <div className='col-md-8'>
                            <strong>{info.gender == 1 ? "Nam" : info.gender == 2 ? "Nữ" : "Khác"}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Địa chỉ:</label>
                        <div className='col-md-8'>
                            <strong>{Common.formatAddress(info.street, info.wardName, info.districtName, info.provinceName)}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Số CMT/CCCD/Passpost:</label>
                        <div className='col-md-8'>
                            <strong>{info.identificationNumber}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Nơi cấp:</label>
                        <div className='col-md-8'>
                            <strong>{info.identificationProvinceCode}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Ngày cấp:</label>
                        <div className='col-md-8'>
                            <strong>{info.identificationCreatedTimeText}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>CMT/CCCD/Passpost mặt trước:</label>
                        <div className='col-md-8'>
                            <div className='img img-small img-box' style={{ width: 100, height: 100 }}>
                                <img src={info.identificationFrontUrl} alt='CMT/CCCD/Passpost mặt trước' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>CMT/CCCD/Passpost mặt sau:</label>
                        <div className='col-md-8'>
                            <div className='img img-small img-box' style={{ width: 100, height: 100 }}>
                                <img src={info.identificationBackUrl} alt='CMT/CCCD/Passpost mặt sau' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    function _renderBusinessInfo() {
        let info = model.businessInformation;
        return <div className='card card-body p-10 m-b-10'>
            <h4 className="header-title m-t-0"><b>Thông tin doanh nghiệp</b></h4>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Tên Doanh Nghiệp:</label>
                        <div className='col-md-8'>
                            <strong>{info.businessName}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Số điện thoại:</label>
                        <div className='col-md-8'>
                            <strong>{info.phoneNumber}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Địa chỉ:</label>
                        <div className='col-md-8'>
                            <strong>{info.businessAddress}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Số ĐKKD:</label>
                        <div className='col-md-8'>
                            <strong>{info.registrationNumber}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Tên DN trên giấy ĐKKD:</label>
                        <div className='col-md-8'>
                            <strong>{info.registrationName}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-md-4'>Ngày cấp:</label>
                        <div className='col-md-8'>
                            <strong>{info.incorporationDateText}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <label className='col-md-4'>Ảnh chụp giấy ĐKKD:</label>
                        <div className='col-md-8'>
                            <div className='img img-small img-box' style={{ width: 300, height: 300, cursor: 'pointer' }}>
                                <img src={info.registrationDocument} alt='CMT/CCCD/Passpost mặt sau' onClick={() => _showPreviewImage(info.registrationDocument)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    function _renderTrafficSources() {
        let info = model.personalInformation;
        return <div className='card card-body p-10 m-b-10'>
            <h4 className="header-title m-t-0"><b>Các nguồn quảng cáo</b></h4>
            <div className='row'>
                <label className='col-md-3'>Website:</label>
                <div className='col-md-9'>
                    {info.websiteUrl}
                </div>
            </div>
            <div className='row'>
                <label className='col-md-3'>Facebook:</label>
                <div className='col-md-9'>
                    {info.facebookUrl}
                </div>
            </div>
            <div className='row'>
                <label className='col-md-3'>Instagram:</label>
                <div className='col-md-9'>
                    {info.instagramUrl}
                </div>
            </div>
            <div className='row'>
                <label className='col-md-3'>Tiktok:</label>
                <div className='col-md-9'>
                    {info.tikTokUrl}
                </div>
            </div>
            <div className='row'>
                <label className='col-md-3'>Youtube:</label>
                <div className='col-md-9'>
                    {info.youtubeUrl}
                </div>
            </div>
        </div>
    }

    function _renderBankInfo() {
        return <div className='card card-body p-10 m-b-10'>
            <h4 className="header-title m-t-0"><b>Thông tin tài khoản ngân hàng</b></h4>
            <div className='row'>
                <label className='col-md-3'>Tên người thụ hưởng:</label>
                <div className='col-md-9'>
                    {model.bankAccountInformation.bankAccountName}
                </div>
            </div>
            <div className='row'>
                <label className='col-md-3'>Số tài khoản:</label>
                <div className='col-md-9'>
                    {model.bankAccountInformation.bankAccountNumber}
                </div>
            </div>
            <div className='row'>
                <label className='col-md-3'>Tên ngân hàng:</label>
                <div className='col-md-9'>
                    {model.bankAccountInformation.bankName}
                </div>
            </div>
        </div>
    }
    function _showPreviewImage(url) {
        setShowPreviewImage(true);
        setPreviewImg(url);
    }
    function _closePreviewImage() {
        setShowPreviewImage(false);
        setPreviewImg(null);
    }
    function _handleApprove() {
        if (model.personalInformation && model.personalInformation.affiliateVIPStatus == 1 && selectedGroup <= 0) {
            let err = new Map();
            err.set('affiliateGroup', 'Vui lòng chọn nhóm TTLK')
            setErrors(err)
        } else {
            ConfirmDialog("Duyệt Affiliator", "Bạn có chắc những thông tin đã nhập là chính xác?", () => {
                return new Promise((resolve, reject) => {
                    let obj = {
                        email: model.emailVerification.email,
                        isApproved: true,
                        affiliateGroup: selectedGroup
                    }
                    if (model.personalInformation && model.personalInformation.affiliateVIPStatus == 1 && selectedGroup == 0) {
                        let err = new Map();
                        err.set('affiliateGroup', 'Vui lòng chọn nhóm TTLK')
                        setErrors(err)
                    } else {
                        Request.Post('/api/affiliate/verify-affiliator', obj)
                            .then(res => {
                                if (res.success && res.data) {
                                    resolve({ title: 'Thành công', msg: 'Đã duyệt thành công.' });
                                    props.onClose(true);
                                }
                                else {
                                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                                }
                            })
                            .catch(err => {
                                reject({
                                    title: 'Có lỗi', msg: CONSTANTS.MSG_ERROR
                                })
                            });
                    }
                })
            })
        }
    }

    function _handleDecline() {
        setShowDeclineForm(true);
    }

    function _handleSubmitDecline() {
        declineReason.email = model.emailVerification.email;
        Request.Post('/api/affiliate/verify-affiliator', declineReason)
            .then(res => {
                if (res.success) {
                    setShowDeclineForm(false);
                    props.onClose(true);
                }
                else {
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
            })
            .catch(err => {
            });
    }
    function _handleBlock() {
        //call api block
        Request.Post('/api/affiliate/block-affiliator/' + props.data.thirdPartyId)
            .then(res => {
                if (res.success) {
                    props.onClose(true);
                }
                else {
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
            })
            .catch(err => {
            });
    }
    return (
        <React.Fragment>
            <Modal show={props.isShow} size={'lg'} enforceFocus={false}>
                {
                    isBusy && <Loading show msg='Loading...' />
                }
                <Modal.Header>
                    <button type='button' className='close' onClick={props.onClose}>
                        <span aria-hidden='true'>×</span>
                        <span className='sr-only'>Close</span>
                    </button>
                    <Modal.Title>Duyệt người dùng Affiliate</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-20'>
                    {
                        model && <div>
                            {
                                model.personalInformation && _renderPersonalInfo()

                            }
                            {
                                model.businessInformation && _renderBusinessInfo()
                            }
                            {model.status && model.status.isTrafficSourceSubmitted && _renderTrafficSources()}
                            {model.status && model.status.isBankInfoSubmitted && _renderBankInfo()}
                            {
                                model.personalInformation && model.personalInformation.affiliateVIPStatus == 1 && <div className='card card-body p-10 m-b-10'>
                                    <h4 className="header-title m-t-0"><b>Nhóm TTLK</b></h4>
                                    <div className='row'>
                                        <label className='col-md-3'>Chọn nhóm TTLK:</label>
                                        <div className='col-md-9'>
                                            <div>
                                                <TFUSelect options={groups} value={selectedGroup} onChanged={(value) => setSelectedGroup(value)} />
                                            </div>
                                            <div>
                                                {
                                                    errors && errors.has('affiliateGroup') && <span className='error text-danger'>{errors.get('affiliateGroup')}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <label className='col-md-3'>SĐT Đăng ký VIP:</label>
                                        <label className='col-md-9'>{model.personalInformation.affiliateVIPRequestMessage}</label>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        model && model.status && model.status.isBasicInfoSubmitted &&
                        <button className='btn btn-custom waves-light btn-sm' onClick={_handleApprove}>
                            Đồng ý duyệt
                        </button>
                    }
                    <button className='btn btn-danger waves-light btn-sm' onClick={_handleDecline}>
                        Yêu cầu điền lại
                    </button>
                    <button className='btn btn-danger waves-light btn-sm' onClick={_handleBlock}>
                        Block
                    </button>
                    <button className='btn btn-inverse waves-light btn-sm' onClick={props.onClose}>
                        {Language.getValue("common.btnClose")}
                    </button>
                </Modal.Footer>
            </Modal>
            {/* Modal preview image */}
            <Modal show={showPreviewImage} size={'lg'} enforceFocus={false}>
                <Modal.Header>
                    <button type='button' className='close' onClick={_closePreviewImage}>
                        <span aria-hidden='true'>×</span>
                        <span className='sr-only'>Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body className='p-20'>
                    <img src={previewImg} style={{ width: '100%' }} />
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-inverse waves-light btn-sm' onClick={_closePreviewImage}>
                        {Language.getValue("common.btnClose")}
                    </button>
                </Modal.Footer>
            </Modal>
            {/* Modal decline */}
            <Modal show={showDeclineForm} size={'lg'} enforceFocus={false}>
                <Modal.Header>
                    <button type='button' className='close' onClick={() => setShowDeclineForm(false)}>
                        <span aria-hidden='true'>×</span>
                        <span className='sr-only'>Close</span>
                    </button>
                    <Modal.Title>Từ chối duyệt, yêu cầu cung cấp lại thông tin</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-20'>
                    <div className='form-group'>
                        <label className='color_gray'>Lý do từ chối:</label>
                        <TextArea name='declinedReason' required value={declineReason.declinedReason} minLength={6} className='form-control'
                            onChanged={(name, value) => setDeclineReason({ ...declineReason, declinedReason: value })} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-custom waves-light' onClick={_handleSubmitDecline}>
                        Lưu
                    </button>
                    <button className='btn btn-inverse waves-light' onClick={() => setShowDeclineForm(false)}>
                        {Language.getValue("common.btnClose")}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}