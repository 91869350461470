import React from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import Loading from '../../based/Loading';
import CommonTable from '../../based/CommonTable';
import BaseServices from '../../based/services/BaseServices';
import { ConfirmDialog } from '../../based/Dialog';
import { Notify } from '../../based/Notify';
import { CONSTANTS, NOTIFY } from '../../based/Constants';
import AddOrEditPrinterModal from './components/AddOrEditPrinterModal';
import { useState } from 'react';
import { useEffect } from 'react';
const INIT_PAGING = {
    pageSize: 50,
    pageNumber: 1
}
const UVPrinterManagement = () => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState('');
    const [devices, setDevices] = useState([]);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [editingPrinterId, setEditingPrinterId] = useState(0);
    const [showModalEditing, setShowModalEditing] = useState(false);
    useEffect(() => {
        _fetchData();
    }, [])
    const _fetchData = async () => {
        setBusy(true);
        setBusyMsg("Loading...")
        const [err, data] = await BaseServices.Post('/api/uvprint/get-print-devices', paging);
        if (!err) {
            setDevices(data.listObjects);
            setPaging(data.paging);
        } else {
            setDevices([]);
        }
        setBusy(false);
        setBusyMsg('')
    }
    const _handleAdd = async () => {
        setShowModalEditing(true);
        setEditingPrinterId(0)
    }
    const _handleEdit = (gr) => {
        setShowModalEditing(true);
        setEditingPrinterId(gr.id);
    }
    const _handleDelete = async (id) => {
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true)
                setBusyMsg('Đang xóa dữ liệu...')
                const [err, data] = await BaseServices.Post('/api/uvprint/delete-print-device/' + id);
                if (err) {
                    setBusy(false)
                    setBusyMsg(null)
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
                else {
                    resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                    setBusy(false)
                    setBusyMsg(null)
                    _fetchData();
                }
            })
        });
    }
    const _handleCloseModal = () => {
        setEditingPrinterId(0);
        setShowModalEditing(false);
    }
    const _handleSave = (success) => {
        if (success) {
            setShowModalEditing(false);
            setEditingPrinterId(0);
            _fetchData();
        }
    }
    const _renderAction = () => {
        return <div className='flex-row m-b-10'>
            <button className='btn btn-custom btn-sm' onClick={_handleAdd}>
                Thêm mới
            </button>
        </div>
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th>Id</th>
                <th>Tên máy in</th>
                <th>In phẳng</th>
                <th>In xoay</th>
                <th></th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        return <tbody>
            {
                devices && devices.length > 0 ?
                    devices.map((g, i) => {
                        return <tr key={i}>
                            <td width={50}>{g.id}</td>
                            <td>{g.name}</td>
                            <td>{g.flatPrint ? `${g.width}mm x ${g.height}mm` : ""}</td>
                            <td>{g.roundPrint ? `${g.length}mm` : ""}</td>
                            <td width={130}>
                                <button className='btn btn-sm btn-custom m-r-10' onClick={() => _handleEdit(g)}>Sửa</button>
                                <button className='btn btn-sm btn-danger' onClick={() => _handleDelete(g.id)}>Xóa</button>
                            </td>
                        </tr>
                    })
                    :
                    <tr><td colSpan={5}>Không có dữ liệu</td></tr>
            }
        </tbody>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý máy in UV' parentPage='Ranus' hrefParent='/' />
            <Loading show={busy} msg={busyMsg} />
            {
                devices && <CommonTable
                    data={devices}
                    paging={paging}
                    renderHeader={_renderHeader}
                    renderBody={_renderBody}
                    renderAction={_renderAction}
                    searchBasic
                    onFilter={(paging) => _fetchData(paging)}
                />
            }
            {
                showModalEditing && <AddOrEditPrinterModal
                    printerId={editingPrinterId}
                    onClose={_handleCloseModal}
                    onSave={_handleSave}
                    show={showModalEditing}
                />
            }
        </React.Fragment>
    );
};

export default UVPrinterManagement;