import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import ArtistServices from "../based/services/ArtistServices";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

function ArtistManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(false);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const history = useHistory()

    useEffect(() => {
        getData(paging);
    }, []);


    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ArtistServices.GetArtistByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    function _renderBody(){
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{idx + 1}</td>
                                <td>
                                    <div>{item.id}</div>
                                    <div>{item.userName}</div>
                                </td>
                                <td>{item.firstName + " " + item.lastName}</td>
                                <td>{item.nickName ? item.nickName : 'Chưa có nickname'}</td>
                                <td>{Common.formatDate(new Date(item.createdDate), 'fulldate')}</td>
                                <td>{item.phoneNumber ? item.phoneNumber : 'Chưa cập nhật'}</td>
                                <td style={{ width: 300}}>{item.folders && item.folders.length >0 ?
                                    
                                        item.folders.map((item, index)=>{
                                            return (
                                                <div key={index}>
                                                    <span style={{textAlign:'left', minWidth: 100, display: 'inline-block'}}>{item.name}</span>:
                                                    <span>{" "}{item.quantityArtwork ? item.quantityArtwork : 0}</span></div>
                                            )
                                        })
                                
                                :'Chưa có folder nào'}</td> 
                                <td style={{textAlign:'center'}}>
                                {!item.isActive ? <button
                                        className='btn btn-custom mr-3'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            _handleConfirm(item.id,true, "duyệt")
                                        }}
                                    >
                                        Duyệt
                                    </button>: ''}
                                {item.isActive ? <button
                                        className='btn btn-danger mr-3'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            _handleConfirm(item.id,false,"từ chối")
                                        }}
                                    >
                                        Từ chối
                                    </button>: ''}
                                </td>                               
                                <td style={{ width: 200, textAlign:'center'}}>
                                    
                                     <button
                                        className='btn btn-custom mr-3'
                                        onClick={() => {
                                            history.push(`/artist-detail/${item.id}`)
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                    <button
                                        className='btn btn-danger'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            _handleDelete(item.id)
                                        }}
                                    >
                                        Xóa
                                    </button>
                                    
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }
    const _handleDelete = async (id) => {
        let ids = []
        ids.push(id);
        ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn muốn xóa tài khoản này khỏi tài khoản artist?", () => {
            return new Promise(async (resolve, reject) => {
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await ArtistServices.DeteleArtist(ids);
                if (!error && data) {
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getData(paging);
                } else {
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
       
           
    }

    const _handleConfirm = async (id,status,message) => {
        ConfirmDialog("Xác nhận?", `Bạn có chắc chắn muốn ${message} tài khoản này?`, () => {
            return new Promise(async (resolve, reject) => {
                setMsg("Đang duyệt...");
                let [error, data] = await ArtistServices.ConfirmAccount(id,status);
                if (!error && data) {
                    setMsg(null);
                    resolve({ title: "Thành công", msg: `${message} thành công.` });
                    getData(paging);
                } else {
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
       
           
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th>STT</th>
                    <th>UserName</th>
                    <th>Họ và tên</th>
                    <th>Nickname</th>
                    <th>Ngày đăng ký</th>
                    <th>Số điện thoại</th>
                    <th>Số artwork</th>
                    <th>Duyệt</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        
    }

    return (
        <React.Fragment>
        <Breadcrumbs currentPage='Quản lý artist' />
        <Loading show={isBusy} msg={msg} />
        <CommonTable 
            data={data} 
            paging={paging}
            ref={tableRef}
            hideSearchBasicButton
            renderAction={_renderAction}
            renderHeader={_renderHeader}
            renderBody={_renderBody}
            onFilter={getData}
            />
    </React.Fragment>
    )
}

export default ArtistManagement;