import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING, PLATFORM } from "../based/Constants";
import Loading from "../based/Loading";
import Request from "../based/Request";
import TFUAutoComplete from "../based/TFUAutoComplete";
import CheckBox from "../based/inputs/CheckBox";
import TFUSelect from "../based/inputs/TFUSelect";
import Textbox from "../based/inputs/Textbox";
import CommonServices from "../based/services/CommonServices";
import ProductServices from "../based/services/ProductServices";
import ProductDetail from "./ProductDetail";
import PushListProductModal from "./PushListProductModal";
import { Notify } from "../based/Notify";

export default function ProductDesign(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [categoryKeyword, setCategoryKeyword] = useState("");
    const [brandKeyword, setBrandKeyword] = useState("");
    const [creatorOptions, setCreatorOptions] = useState([]);
    const [showProductDetail, setProductDetail] = useState({ isShow: false, product: {} });
    const [productNameOptions, setProductNameOptions] = useState([]);
    const [showPushListModal, setShowPushListModal] = useState(false);
    const [idsPushListModal, setIdsPushListModal] = useState([]);

    useEffect(() => {
        getCreator();
        getProductNameOptions({ ...INITPAGING, pageSize: 20 });
        getDatas(Common.Paging);
    }, []);

    async function getCreator() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CommonServices.GetDropdownsCreator();
        if (!error && data) {
            setCreatorOptions(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setCreatorOptions([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function getProductNameOptions(paging) {
        setIsLoading(true);
        let [error, data] = await ProductServices.GetListProduct(paging);
        console.log("data", data);
        if (!error && data && data.listObjects) {
            setProductNameOptions(data.listObjects);
        } else {
            setProductNameOptions([]);
        }
        setIsLoading(false);
    }

    async function pushProductMetaCatalog(agencyProductId) {
        setIsLoading(true);
        let [error, data] = await ProductServices.PushProductMetaCatalog(agencyProductId, 0);
        if (!error && data) {
            console.log("data", data);
            Notify("success", "Thông báo", "Đẩy sản phẩm thành công");
        } else {
            console.log("error", error);
            Notify("error", "Thông báo", "Đẩy sản phẩm thất bại");
        }
        setIsLoading(false);
    }

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ProductServices.GetProductsDesignPage(newPaging);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width="5%" className="text-center"></th>
                    <th width="25%">Ảnh</th>
                    <th width="15%">Tên sản phẩm</th>
                    <th width="10%">Tên loại sản phẩm</th>
                    <th width="5%">Người tạo</th>
                    <th width="5%">Cập nhật gần nhất</th>
                    <th width="10%">Trạng thái Lazada</th>
                    <th width="10%">Trạng thái Shopee</th>
                    <th width="10%">Trạng thái Tiktok</th>
                    <th width="10%">Hàng đợi</th>
                    <th width="5%"></th>
                </tr>
            </thead>
        );
    }

    function _handleCheckdChange(item, idx, value) {
        let datasUpdate = [...datas];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setDatas(datasUpdate);
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let avatar = item.productAvatar ? item.productAvatar : Request.CdnURL() + "/images_system/no-image.png";
                        let rankPrice = "";
                        if (item.minPrice == item.maxPrice) rankPrice = <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.minPrice} />;
                        else
                            rankPrice = (
                                <React.Fragment>
                                    <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.minPrice} /> -
                                    <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.maxPrice} />
                                </React.Fragment>
                            );
                        let lazadaPushProduct = item.pushProducts && item.pushProducts.length > 0 ? item.pushProducts.filter((x) => x.platform == PLATFORM.Lazada) : [];
                        let shopeePushProduct = item.pushProducts && item.pushProducts.length > 0 ? item.pushProducts.filter((x) => x.platform == PLATFORM.Shopee) : [];
                        let tiktokPushProduct = item.pushProducts && item.pushProducts.length > 0 ? item.pushProducts.filter((x) => x.platform == PLATFORM.Tiktok) : [];

                        return (
                            <tr key={i}>
                                <td width={40} className="vertical-align-middle">
                                    <CheckBox checked={!!item.checked} name={item.id.toString()} label=" " onCheckedChange={(name, value) => _handleCheckdChange(item, i, value)} />
                                </td>
                                <td className="text-center vertical-align-middle">
                                    {item.listImage && item.listImage.length > 0 ? (
                                        item.listImage.map((element, idx) => {
                                            return (
                                                <a className="iconImageDefault full-width" key={idx}>
                                                    <img src={element.url} className="variant-avatar m-r-5 img-product-design" />
                                                </a>
                                            );
                                        })
                                    ) : (
                                        <a className="iconImageDefault full-width">
                                            <img src={avatar} className="variant-avatar m-r-5 img-product-design" />
                                        </a>
                                    )}
                                </td>
                                <td className="text-left vertical-align-middle">
                                    {item.name}
                                    {item.totalVariant > 0 ? (
                                        <React.Fragment>
                                            <br />
                                            <strong>({item.totalVariant} biến thể)</strong>
                                        </React.Fragment>
                                    ) : null}
                                </td>
                                <td className="text-left vertical-align-middle">{item.productName}</td>
                                <td className="text-left vertical-align-middle"> {item.createdBy}</td>
                                <td className="vertical-align-middle">{item.modifiedDateDisplay}</td>
                                <td className="vertical-align-middle">
                                    {lazadaPushProduct &&
                                        lazadaPushProduct.reduce((prev, item, idx) => {
                                            return (prev += `${item.shopId}: ${item.pushingStatusName}(${item.platformProductId})\n`);
                                        }, "")}
                                </td>
                                <td className="vertical-align-middle">
                                    {shopeePushProduct &&
                                        shopeePushProduct.reduce((prev, item, idx) => {
                                            return (prev += `${item.shopId}: ${item.pushingStatusName}(${item.platformProductId})\n`);
                                        }, "")}
                                </td>
                                <td className="vertical-align-middle">
                                    {tiktokPushProduct &&
                                        tiktokPushProduct.reduce((prev, item, idx) => {
                                            return (prev += `${item.shopId}: ${item.pushingStatusName}(${item.platformProductId})\n`);
                                        }, "")}
                                </td>
                                <td className="vertical-align-middle">
                                    {item.syncPlatforms && item.syncPlatforms.length > 0
                                        ? item.syncPlatforms.reduce((prev, element, idx) => {
                                              return (prev += `${element.platformName} - ${element.shopId}: ${element.jobStatusName}\n`);
                                          }, "")
                                        : ""}
                                </td>
                                <td className="vertical-align-middle">
                                    <DropdownButton
                                        id="dropdown-basic-button"
                                        title={
                                            <span>
                                                <i className="fa fa-cog m-r-5" /> Thao tác
                                            </span>
                                        }
                                        variant="custom"
                                        size="sm"
                                        className="m-1"
                                    >
                                        <Dropdown.Item onClick={() => setProductDetail({ isShow: true, product: item, isView: true })}>
                                            <i className="fa fa-eye m-r-5"></i>Xem chi tiết
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                window.open(`/push-product-design/${item.id}/${item.opfCategoryId}`, "_blank");
                                            }}
                                        >
                                            <i className="fa fa-upload m-r-5"></i>Đẩy sản phẩm (Lazada/Shopee/Tiktok/...)
                                        </Dropdown.Item>
                                    </DropdownButton>

                                    <div
                                        className="btn btn-custom custom m-1"
                                        onClick={(e) => {
                                            pushProductMetaCatalog(item.id);
                                        }}
                                    >
                                        Đẩy sản phẩm Meta Catalog
                                    </div>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="11" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }

        let isEnabledPush = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length > 0) isEnabledPush = true;
        }
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="keyword">Từ khóa:</label>
                            <Textbox className="form-control" onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="createdBy">Người tạo: </label>
                            <TFUSelect name="createdBy" value={paging.createdBy} options={creatorOptions} placeholder="---Chọn người tạo---" onChanged={(value) => setPaging({ ...paging, createdBy: value })} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="productId">Loại sản phẩm: </label>
                            <TFUSelect name="productId" value={paging.productId} options={productNameOptions} placeholder="---Chọn loại sản phẩm---" onChanged={(value) => setPaging({ ...paging, productId: value })} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="brandId">Thương hiệu:</label>
                            <TFUAutoComplete
                                name="brandId"
                                urlFetchData={{
                                    url: "/api/brand/get-list-brand-creator",
                                    params: { pageNumber: 1, pageSize: 10, keyword: brandKeyword },
                                }}
                                onSelectedItemChanged={(item) => {
                                    setBrandKeyword(item.text);
                                    setPaging({ ...paging, brandId: +item.value });
                                }}
                                placeholder="--Thương hiệu--"
                                value={paging.brandId}
                            />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                        </div>
                        <div className="form-group">
                            <button
                                className={`btn btn-custom btn-sm m-r-5 ${isEnabledPush ? "" : "disabled"}`}
                                onClick={() => {
                                    let ids = [...datas].reduce((prev, item, idx) => {
                                        return item.checked ? [...prev, item.id] : [...prev];
                                    }, []);
                                    console.log("ids", ids);
                                    setIdsPushListModal(ids);
                                    setShowPushListModal(true);
                                }}
                            >
                                <i className="fa fa-search m-r-5" />
                                Đẩy sản phẩm hàng loạt
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Sản phẩm Design" />
            <Loading show={isLoading} msg={msg} />
            {showProductDetail && showProductDetail.isShow && <ProductDetail option={showProductDetail} onClose={() => setProductDetail({ ...showProductDetail, isShow: false })} onSaveSucceed={() => getDatas(paging)} />}
            {showPushListModal && <PushListProductModal isShow={showPushListModal} ids={idsPushListModal} onClose={() => setShowPushListModal(false)} />}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={getDatas} searchBasic={false} />
        </React.Fragment>
    );
}
