import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { shopCatProductModel } from "./AddProductShopCatModal";
import { INITPAGING } from "../based/Constants";
import PlatformServices from "../based/services/PlatformServices";
import CommonTablePagination from "../based/CommonTablePagination";
import Common from "../based/Common";
import { useHistory, useLocation } from "react-router-dom";
export const ProductPushingStatus = {
    Undefined: -1,
    Pending: 0,
    Processing: 1,
    Failed: 2,
    Successed: 3,
};

const ListTab = [
    {
        value: ProductPushingStatus.Undefined,
        label: "Tất cả",
    },
    {
        value: ProductPushingStatus.Pending,
        label: "Đang chờ",
    },
    {
        value: ProductPushingStatus.Processing,
        label: "Đang xử lý",
    },
    {
        value: ProductPushingStatus.Failed,
        label: "Thất bại",
    },
    {
        value: ProductPushingStatus.Successed,
        label: "Thành công",
    },
];

function ProductInformation({ shopId, platform }) {
    const [tabIndex, setTabIndex] = useState(ProductPushingStatus.Successed);
    const [listModel, setListModel] = useState([shopCatProductModel]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageSize: 20, shopId: shopId, platform: platform });
    const history = useHistory();

    useLayoutEffect(() => {
        setListModel([]);
    }, []);

    useEffect(() => {
        getModel({ ...paging });
    }, [tabIndex]);

    const getModel = async (page) => {
        const [err, data] = await PlatformServices.GetShopCatProductModels({ ...page, shopId: shopId, platform: platform, productPushingStatus: tabIndex });
        if (!err && data) {
            setListModel(data.listObjects);
            setPaging(data.paging);
        }
    };

    function TabNavigation() {
        return (
            <ul className="nav nav-tabs tabs-bordered">
                {ListTab.map((item, index) => {
                    return (
                        <li key={index} className="nav-item">
                            <a className={tabIndex == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(item.value)}>
                                <span>{item.label}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <Wrapper>
            <TabNavigation />
            <div className="section_product">
                <div className="section_product-top">
                    <div className="title">Danh sách sản phẩm</div>
                    <div className="add-product"></div>
                </div>
                <div className="section_product-content">
                    <div className="list-item">
                        {listModel && listModel.length > 0 ? (
                            listModel.map((item, idx) => {
                                return (
                                    <div
                                        className={`item ${item.isChecked ? "active" : ""}`}
                                        key={idx}
                                        onClick={(e) => {
                                            let list = [...listModel];
                                            list[idx].isChecked = !list[idx].isChecked;
                                            setListModel(list);
                                        }}
                                    >
                                        <div className="image">{item.avatar && <img src={item.avatar} alt={item.name} />}</div>
                                        <div className="name">{item.name}</div>
                                        <div className="price">
                                            {Common.formatCurrency(item.minPrice)} - {Common.formatCurrency(item.maxPrice)}
                                        </div>
                                        <div
                                            className="open-icon"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                history.push(`/pushing-multi-detail/${item.productId}`);
                                            }}
                                        >
                                            <OpenIcon />
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data">Không có dữ liệu</div>
                        )}
                    </div>
                    <div className="paging-bottom">
                        <CommonTablePagination
                            paging={paging}
                            onChangePage={(page) => {
                                const newPaging = { ...paging, pageNumber: page };
                                getModel(newPaging);
                            }}
                            onChangePageSize={(pageSize) => {
                                const newPaging = { ...paging, pageNumber: 1, pageSize: pageSize };
                                getModel(newPaging);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="section-bottom"></div>
        </Wrapper>
    );
}

export default ProductInformation;

const OpenIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3333 9.88873V15.2221C14.3333 15.6936 14.146 16.1457 13.8126 16.4791C13.4792 16.8125 13.0271 16.9998 12.5556 16.9998H2.77778C2.30628 16.9998 1.8541 16.8125 1.5207 16.4791C1.1873 16.1457 1 15.6936 1 15.2221V5.44428C1 4.97279 1.1873 4.5206 1.5207 4.1872C1.8541 3.8538 2.30628 3.6665 2.77778 3.6665H8.11111"
                stroke="#64C5B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M11.6667 1H17.0001V6.33333" stroke="#64C5B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.22217 10.7778L16.9999 1" stroke="#64C5B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const Wrapper = styled.div`
    .item.active {
        border: 1px solid #64c5b1;
        background: #e6fffa;
    }
    .item {
        margin: 8px;
        width: 190px;
        display: flex;
        flex-direction: column;
    }

    .name {
        margin: 6px 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .price {
        margin-top: auto;
    }
    .header_info {
        display: flex;
    }

    .avatar {
        width: 130px;
        height: 130px;
    }

    .avatar img {
        width: 130px;
        height: 130px;
        object-fit: contain;
    }

    .header_info-left {
        display: flex;
    }

    .name {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
    }

    .upload-btn {
        display: flex;
        margin-top: auto;
        background: #f1f1f1;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
    }

    .upload-btn .icon {
        margin-right: 8px;
    }

    .upload-btn span {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .section_product-top {
        display: flex;
        align-items: center;
    }

    .add-product {
        margin-left: auto;
    }

    .section_product {
        margin-top: 18px;
    }

    .title {
        color: #444;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
    }

    .list-item {
        display: flex;
        flex-wrap: wrap;
    }

    .image {
        width: 172px;
        height: 172px;
        flex-shrink: 0;
    }

    .image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 8px;
        position: relative;
    }

    .item .name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 0;
    }

    .price {
        color: #f96a74;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .remove-icon {
        display: flex;
        position: absolute;
        background: #d70000;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        top: 5px;
        right: 5px;
    }

    .remove-icon .icon {
        display: flex;
    }

    .open-icon {
        display: flex;
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;
