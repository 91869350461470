import React, { forwardRef, useEffect, useState } from "react";
import Common from "./Common";
import CommonTablePagination from "./CommonTablePagination";
import Language from "./Language";
import Loading from "./Loading";
import { CommonTableProps } from "./PropsDefined";

/**
 * Common table
 * @param {CommonTableProps} props common table props
 * @param {Ref} ref ref
 */
const CommonTable = (props, ref) => {
    const [isBusy, setBusy] = useState(true);
    const tableId = Common.generateGuid();

    useEffect(() => {
        setBusy(false);
    }, [props.tableGuid]);

    function handleChangePage(page) {
        let newPaging = {
            ...props.paging,
            pageNumber: page,
        };
        props.onFilter(newPaging);
    }

    function handleChangePageSize(pageSize) {
        let newPaging = {
            ...props.paging,
            pageNumber: 1,
            pageSize: pageSize,
        };
        props.onFilter(newPaging);
    }

    function handleBasicSearch(keyword) {
        let newPaging = {
            ...props.paging,
            pageNumber: 1,
            keyword: keyword,
        };
        props.onFilter(newPaging);
    }

    function renderTableFoot() {
        return <CommonTablePagination paging={props.paging} onChangePage={handleChangePage} onChangePageSize={handleChangePageSize} />;
    }

    return (
        <React.Fragment>
            <div id={tableId} className={props.noBoxOuter ? "" : "card-box padding-10"}>
                {props.title ? (
                    <div className="row">
                        <h4 className="col-md-12 header-title mx-0"> {props.title}</h4>
                    </div>
                ) : (
                    ""
                )}
                {props.searchBasic && <CommonTableFilter keyword={props.paging.keyword} hideButon={props.hideSearchBasicButton} hideSearch={props.hideSearch} placeholder={props.placeholderSearch} onSearch={(keyword) => handleBasicSearch(keyword)} />}
                {props.searchAdvanced ? <div className="margin-bottom-0">{props.searchAdvanced()}</div> : null}
                {props.renderAction && props.renderAction()}
                <Loading show={isBusy} msg="Đang tải dữ liệu..." />
                <div className="p-0">
                    <div className={`content-table table-rep-plugin${props.freeSize ? "" : " tbl__scroll full-width"} ${props.classDivTable ? props.classDivTable : ""}`}>
                        <table className={props.className ? "table table-bordered m-b-0 focus-on " + props.className : "table table-bordered m-b-0 focus-on"}>
                            {props.renderHeader()}
                            {props.renderBody()}
                        </table>
                    </div>
                    {!props.hideFooter && props.paging && props.paging.totalRecord > 0 ? renderTableFoot() : null}
                </div>
            </div>
        </React.Fragment>
    );
};

export default forwardRef(CommonTable);

class CommonTableFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: this.props.keyword ? this.props.keyword : "",
        };
    }
    handleBlur(event) {
        let _this = this;
        let target = event.target;
        if (target.value && target.value.length > 0) {
            _this.setState({ keyword: target.value.trim() });
        }
    }
    handleChangedKeyword(event) {
        let target = event.target;
        this.setState({ keyword: target.value });
    }
    handleKeyPress(event) {
        if (event.charCode === 13) {
            let keyword = this.state.keyword;
            if (keyword && keyword.length > 0) {
                keyword = keyword.trim();
                this.setState({ keyword: keyword });
            }
            this.props.onSearch(keyword);
        }
    }
    render() {
        return (
            <div className="card-box float-right">
                <div className="form-inline">
                    <div className="form-group">
                        {!this.props.hideSearch && (
                            <input
                                type="text"
                                placeholder={this.props.placeholder ? this.props.placeholder : "Từ khóa"}
                                className="form-control"
                                value={this.state.keyword}
                                onChange={this.handleChangedKeyword.bind(this)}
                                onKeyPress={this.handleKeyPress.bind(this)}
                                onBlur={this.handleBlur.bind(this)}
                            />
                        )}
                        {this.props.hideButon ? (
                            ""
                        ) : (
                            <button className="btn btn-default btn-sm ml-3" onClick={() => this.props.onSearch(this.state.keyword)}>
                                <i className="fa fa-search m-r-5" /> {Language.getValue("common.btnSearch")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
