import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import styled from "styled-components";

export const SubModeModal = {
    Close: 0,
    High: 1,
    Mid: 2,
};

const AnimationGift = {
    Walk: 1,
    Transformation: 2,
    Jump: 3,
    Talk: 4,
};
const AnimationType = {
    RightToLeft: 1,
    LeftToRight: 2,
};

const CurrentGiftState = {
    animation: AnimationGift.Walk,
    animationType: AnimationType.RightToLeft,
};

const TransitionStatus = {
    Default: 0,
    NotShow: 1,
    Closing: 2,
    Moving: 3,
    Unset: 4,
};

function BaseBottomBarModal(
    {
        isShow,
        onClose,
        title,
        maxHeight,
        children,
        zIndex,
        backdropOpacity = 0,
        noBackdrop = false,
        isDragMode = false,
        headerId = "",
        bodyId = "",
        bottomBarStyle = {},
        setTransformY,
        initMode = SubModeModal.High,
        setMode,
        isShowHeartGift = false,
        heartPosition = null,
        heartAction = null,
        isCloseImmediately = false,
        className = "",
        switchMode,
    },
    ref
) {
    const modHeight = maxHeight > 0 ? maxHeight / 100 : 0.8;
    const midModHeight = 0.55;
    const [startModal, setStartModal] = useState({ clientX: 0, clientY: 0 });
    const [translateY, setTranslateY] = useState();
    const [startTranslateY, setStartTranslateY] = useState(0);
    const [startTarget, setStartTarget] = useState();
    const [currentGiftState, setCurrentGiftState] = useState({ ...CurrentGiftState, animation: AnimationGift.Transformation });
    const [bottomHeight, setBottomHeight] = useState(0);
    const [midHeight, setMidHeight] = useState(0);
    const heartRef = useRef();
    const headerRef = useRef();
    const [transitionStyle, setTransitionStyle] = useState(TransitionStatus.NotShow);
    const [movingModal, setMovingModal] = useState(false);
    const [endingModal, setEndingModal] = useState(false);
    const [closingModal, setClosingModal] = useState(false);

    useEffect(() => {
        //nếu keyboard được mở thì set height lại bằng 80% màn hình khi trước
        let innerHeight = window.innerHeight;
        let bottomHt = innerHeight * modHeight;
        let midHt = bottomHt - innerHeight * midModHeight;
        setBottomHeight(bottomHt);
        setMidHeight(midHt);
        setTranslateY(0);
    }, []);

    useEffect(() => {
        if (isShow) {
            if (initMode == SubModeModal.Mid) {
                setTranslateY(midHeight);
            } else setTranslateY(0);
        }
    }, [isShow]);

    useEffect(() => {
        if (!isShow) {
            setTransitionStyle(TransitionStatus.NotShow);
        } else {
            setTimeout(() => {
                setTransitionStyle(TransitionStatus.Default);
            }, 500);
        }
    }, [isShow]);

    useEffect(() => {
        if (isShow) {
            if (movingModal) setTransitionStyle(TransitionStatus.Unset);
            else {
                if (endingModal) setTransitionStyle(TransitionStatus.Moving);
                else if (closingModal) setTransitionStyle(TransitionStatus.Closing);
                else setTransitionStyle(TransitionStatus.Default);
            }
        }
    }, [movingModal, endingModal, closingModal]);

    useImperativeHandle(ref, () => ({
        getHeaderRef: () => {
            return headerRef;
        },
    }));

    const _modalStart = (e) => {
        if (!e.touches || e.touches.length == 0) return;
        setStartModal({ clientX: e.touches[0].clientX, clientY: e.touches[0].clientY });
        setStartTranslateY(translateY);
        setStartTarget(e.target);
        setMovingModal(true);
    };

    const _modalMove = (e) => {
        if (!startModal) return;
        var { clientY: startY } = startModal;
        var { clientY: currentY } = e.touches[0];
        let header = document.getElementById(headerId);
        let body = document.getElementById(bodyId);
        let clickedHeader = header && header.contains(startTarget);
        let clickedBody = body && body.contains(startTarget);

        //kiểm tra trạng thái
        let moving = false;
        if (clickedHeader) moving = true;
        if (clickedBody) {
            let isTop = body.scrollTop <= 0;
            if (isTop) {
                if (startTranslateY != midHeight) moving = true;
                else {
                    let goUp = currentY - startY < 0;
                    if (goUp) moving = false;
                    else moving = true;
                }
            } else moving = false;
        }

        //moving modal
        if (moving) {
            let length = currentY - startY;
            var calculateHeight = startTranslateY + length;
            if (calculateHeight <= 0) calculateHeight = 0;
            setTranslateY(calculateHeight);
        }
    };

    const _modalEnd = (e) => {
        setMovingModal(false);
        setEndingModal(true);
        let ty = 0;
        var mode = SubModeModal.Close;

        if (!isCloseImmediately) {
            if (translateY > 0 && translateY < midHeight) {
                if (startTranslateY == 0) {
                    let percentChange = translateY / bottomHeight;
                    if (percentChange > 0.15) {
                        ty = midHeight;
                        mode = SubModeModal.Mid;
                    } else {
                        ty = 0;
                        mode = SubModeModal.High;
                    }
                } else {
                    let percentChange = Math.abs(translateY - midHeight) / bottomHeight;
                    if (percentChange > 0.15) {
                        ty = 0;
                        mode = SubModeModal.High;
                    } else {
                        ty = midHeight;
                        mode = SubModeModal.Mid;
                    }
                }
                setTranslateY(ty);
                if (setMode) setMode(mode);
            }
            if (translateY >= midHeight && translateY <= bottomHeight) {
                let percentChange = Math.abs(translateY - midHeight) / bottomHeight;
                if (percentChange > 0.15) handleClose();
                else {
                    ty = midHeight;
                    setTranslateY(ty);
                    if (setMode) setMode(SubModeModal.Mid);
                }
            }
            if (setTransformY) {
                setTransformY(ty);
            }
        } else {
            let percentChange = translateY / bottomHeight;
            if (percentChange < 0.25) {
                setTranslateY(0);
                if (setMode) setMode(SubModeModal.High);
            } else {
                setTranslateY(bottomHeight);
                if (setMode) setMode(SubModeModal.Close);
            }
        }
        setEndingModal(false);
        setStartModal(null);
        setStartTranslateY(0);
        setStartTarget(null);
    };

    function handleClose() {
        setClosingModal(true);
        setTimeout(() => {
            setTranslateY(bottomHeight);
            onClose();
        }, 0);

        setTimeout(() => {
            setClosingModal(false);
        }, 1000);
    }

    const _transitionStyle = useMemo(() => {
        switch (transitionStyle) {
            case TransitionStatus.NotShow:
                return switchMode ? "transform 0s cubic-bezier(0,0,.2,1) 0s, opacity 0.3s ease-in-out 0s" : "transform 0.3s cubic-bezier(0,0,.2,1) 0s, opacity 0.3s ease-in-out 0s";
            case TransitionStatus.Closing:
                return "transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s";
            case TransitionStatus.Moving:
                return "transform 0.3s ease-in-out 0s";
            case TransitionStatus.Default:
                return "transform 0s cubic-bezier(1,1,0.2,0) 0s, opacity 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s";
            case TransitionStatus.Unset:
                return "unset";
        }
    }, [transitionStyle, switchMode]);

    const [isHeartAction, setIsHeartAction] = useState(false);

    function _renderBody() {
        return (
            <div
                className={`bottom-bar-modal ${isShow ? "active" : "inactive"}`}
                style={{
                    height: `${bottomHeight}px`,
                    transform: `translateY(${!isShow ? "150%" : `${translateY}px`})`,
                    transition: _transitionStyle,
                    ...bottomBarStyle,
                }}
                id={isShow ? "bottom-bar-id" : ""}
            >
                {isShowHeartGift && isShow && (
                    <div
                        className="heart-gift"
                        ref={heartRef}
                        style={{
                            transform: `translate(0px,0px)`,
                            transition: "all 0.3s ease 0s",
                            right: `${heartPosition ? heartPosition.x : 0}px`,
                            top: `${heartPosition ? heartPosition.y : 0}px`,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (heartAction) {
                                let isClick = !isHeartAction;
                                heartAction(isClick);
                                setIsHeartAction(isClick);
                            }
                        }}
                    ></div>
                )}

                {children}
            </div>
        );
    }

    return (
        <Wrapper
            maxHeight={maxHeight}
            zIndex={zIndex}
            onTouchStart={(e) => {
                if (isDragMode) _modalStart(e);
            }}
            onTouchMove={(e) => {
                if (isDragMode) _modalMove(e);
            }}
            onTouchEnd={(e) => {
                if (isDragMode) _modalEnd(e);
            }}
            ref={ref}
            data-height={bottomHeight}
            data-translatey={translateY}
            className={className}
        >
            {_renderBody()}
        </Wrapper>
    );
}

export default forwardRef(BaseBottomBarModal);

const Wrapper = styled.div`
    font-size: 10px;
    .heart-gift {
        position: absolute;
        height: 50px;
        width: 50px;

        z-index: 2;
        user-select: "none";
        touch-action: "none";
        pointer-events: "none";
    }

    .heart-gift img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .bottom-bar-modal {
        max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}vh` : " 80vh")};
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        box-shadow: 0 -5px 7px -4px #333;
        background-color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        z-index: ${(props) => (props.zIndex > 0 ? props.zIndex : 2)};
        overflow-y: auto;
    }
    .bottom-bar-modal.active {
        opacity: 1;
    }
    .bottom-bar-modal.inactive {
        opacity: 0;
    }
    .bottom-bar-modal-header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        position: sticky;
        top: 0;
        .bottom-bar-modal-header-title {
            color: black;
            font-size: 2rem;
            font-weight: 600;
        }
        .btn-close {
            padding: 5px;
            img {
                width: 15px;
                height: 15px;
                object-fit: cover;
            }
        }
    }

    .custom-upload-image {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .number-img {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #979797;
        margin-left: 10px;
    }
    .custom-thumb {
        padding: 0;
        aspect-ratio: 1/1;

        img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
            height: auto;
        }
    }

    .bottom-bar-modal-body {
        .custom-link-review {
            padding-bottom: 5px;
            border-bottom: 1px solid #e5e5e5;

            .custom-link {
                max-width: 90%;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .custom-img {
                width: 10%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                img {
                    width: 16.51px;
                    height: 19.6px;
                }
            }
        }

        .body-post {
            display: flex;

            .left-custom {
                width: 25%;
                min-width: 25%;
                max-width: 25%;
                padding: 5px 5px 5px 0;
                img {
                    width: 100%;
                }
            }
            .right-custom {
                width: 75%;
                min-width: 75%;
                max-width: 75%;
                padding: 5px 0 5px 0;

                .name-post {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #444444;
                    overflow: hidden;
                }

                .custom-date {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #bababa;
                }
            }
        }

        .custom-rate-full {
            background-color: #fff;
            border-radius: 10px;

            .custom-rate-pd {
                background-color: #fff;
                border-radius: 10px;

                .custom-rate-image {
                    width: 30%;
                    min-width: 30%;
                    max-width: 30%;
                    padding: 15px;
                    img {
                        width: 100%;
                    }
                }

                .custom-rate-desc {
                    width: 70%;
                    min-width: 70%;
                    max-width: 70%;
                    padding-top: 15px;
                    padding-right: 15px;

                    .box-logo {
                        display: flex;
                        align-items: center;

                        .logo-aff {
                            width: 50px;
                            img {
                                width: 100%;
                            }
                        }

                        .date-rate {
                            margin-left: 20px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #bababa;
                        }
                    }

                    .name-product-rate {
                        font-size: 12px;
                        line-height: 15px;
                        color: #bababa;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }

                    .name-product-rate {
                        span {
                            font-size: 12px;
                            line-height: 15px;
                            color: #bababa;
                        }
                    }
                }
            }

            .review-item {
                padding-top: 0px;

                .rating {
                    margin: 0;
                }
                .custom-title {
                    margin-top: 0px;
                }
                .start-image {
                    img {
                        width: 15px;
                        height: 15px;
                    }
                }
                .text-start {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    color: #f8993f;
                }

                .custom-content {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #444444;
                    margin-top: 18px;
                    margin-bottom: 18px;
                }

                .img-review {
                    display: flex;
                    margin: 0 -3px;
                    .c-img {
                        width: 20%;
                        min-width: 20%;
                        max-width: 20%;
                        padding: 0 3px;
                        img {
                            border-radius: 10px;
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
`;
