import styled from 'styled-components';

export const ContentWrapper = styled.div`
position: absolute;
top: 70px;
right: 0;
width: ${({ toggle }) => toggle ? 'calc(100% - 240px)' : 'calc(100% - 59px)'} ;
height: calc(100vh - 70px);
transition: width .6s ease;
display: flex;
justify-content: flex-start;
 align-items: center;
flex-direction: column;
font-family: 'Roboto',sans-serif;
.flex__row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
.flex__space-between{
display: flex;
flex-direction: row;
justify-content: space-between !important;
align-items: center;
}
.flex__row__start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}
.flex__row__end{
    margin-top: 15px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end !important;
    align-items: center;
}
.flex__row__start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
}
.flex__column{
    display: flex;
    flex-direction:column !important;
}
.vh-80{
     height: 80vh;
 }
.flex__basis-50{
    flex-basis:50%;
}
.flex__basis-40{
    flex-basis:40% !important;
}
.flex__basis-20{
    flex-basis:20%;
}
.flex__basis-25{
    flex-basis:25%;
}
.flex__basis-30{
    flex-basis:30%;
}


.input__item{
    flex-basis: 90%;
    border-radius: .5rem;
    padding: 3px 10px;
    width: 100%;
    border: 1px solid #aaa;
}
.input-40{
    width: 40%;
}

/**form */
.sub__label{
    font-size: 10px;
    color: red;
}
.input__extent{
            width: 100%;
            position: relative;
            .sub__input{
                position: absolute;
                right: 5px;
                top: 1px;
            }
            .tag-container{
                display: flex;
                flex-direction: row !important;
                justify-content: flex-start;
                width: 100%;
                .hashtag__item{
                    padding: 4px;
                    margin-right: 5px;
                    
                }
            }
        }
.form__element{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .form__group{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        padding: 5px 0;
        label{
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            width: 100%;
            align-items: center;
        }
        
        input,textarea{
            width: 100%;
            border-radius: 11px;
            padding-left: 13px;
            border: 1px solid #666f7b;
            margin-left: 5px;
            margin-bottom: 4px;
        }
        textarea {
            resize: none;
        }
    }
    
}

/** table */
.div_before_table {
    overflow:hidden;
    overflow-y: scroll;
    height: 500px;
    width: 100%;
    position: relative;
}
  .fixed{
    top: 0;
    z-index: 2;
    position: sticky;
 }
 
 .fixTableHead {
      overflow-y: auto;
      height: 500px;
      width: 100%;
      border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    }
    .fixTableHead thead th {
      position: sticky;
      top: 0;
    }
    .fixTableHead table{
        width: 100%;
        
        padding: 5px 7px;
        thead tr th{
            background: #f1f1f1;
            text-align: center;
            padding: 8px 0px;
            z-index: 99;
        }
        tbody tr td{
        text-align: center;
        border-right: 1.5px solid #f1f1f1;
        border-left: 1.5px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 7px 7px;
        vertical-align: middle;
        max-height: 125px;
    }
    tbody tr td img{
        max-width: 50px;
    }
    }
`
export const Box = styled.div`
width: ${({ width }) => width ? width : '99%'};
margin-top: 15px;
background: ${({ bgColor }) => bgColor ? bgColor : 'white'};
border-radius: 6px;
box-shadow: ${({ boxShadow }) => boxShadow ? boxShadow : 'rgba(149, 157, 165, 0.2) 0px 8px 24px'};
display: flex;
justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'flex-start'};
flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
align-items: ${({ alignItem }) => alignItem ? alignItem : 'center'};
padding: 10px 15px;
min-height:${({ minHeight }) => minHeight ? minHeight : 'auto'};
position: relative;
.box__header{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding: 10px ;
    display: flex;
    justify-content: flex-start;
}
.box__card{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding: 10px ;
    display: block;

}
.box__content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${({ justifyElement }) => justifyElement ? justifyElement : 'flex-start'};
}
`
export const BoxUser = styled.div`
width: ${({ width }) => width ? width : '70%'};
margin-top: 15px;
background: ${({ bgColor }) => bgColor ? bgColor : 'white'};
border-radius: 6px;
box-shadow: ${({ boxShadow }) => boxShadow ? boxShadow : 'rgba(149, 157, 165, 0.2) 0px 8px 24px'};
display: flex;
justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'flex-start'};
flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
align-items: ${({ alignItem }) => alignItem ? alignItem : 'center'};
padding: 10px 15px;
min-height:${({ minHeight }) => minHeight ? minHeight : 'auto'};
.box__header{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding: 10px ;
    display: flex;
    justify-content: flex-start;
}
.box__content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${({ justifyElement }) => justifyElement ? justifyElement : 'flex-start'};
}
`
export const Block = styled.div`
padding:7px 10px;
background: ${({ bgColor }) => bgColor ? bgColor : 'white'};
flex-basis: ${({ flexBasis }) => flexBasis ? flexBasis : '30%;'};
border-radius: 7px;
box-shadow: ${({ boxShadow }) => boxShadow ? boxShadow : 'rgba(149, 157, 165, 0.2) 0px 8px 24px'};
margin-right: 15px;
height: ${({ height }) => height ? height : 'fit-content'};

.header{
    display: flex;
    justify-content: space-between;
    color: ${({ headerColor }) => headerColor ? headerColor : '#000'};
    font-size: 14px;
    i{
        font-size: 25px;
    }
}
.content{
    min-height: 70px;
    color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    .info{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}
`
export const Table = styled.table`
    width: 100%;
    color: #000;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    padding: 5px 7px;
    overflow: hidden;
    position: relative;
    thead tr th{
        background: #f1f1f1;
        text-align: center;
        padding: 8px 0px;
    }
    div.table__body{
        height: ${({ height }) => height ? height : 'auto'};
        overflow-y: auto;
        width: 100%;
    }
    tbody{
        overflow: auto;
        width: 100%;
    }
    tbody tr td{
        text-align: center;
        border-right: 1.5px solid #f1f1f1;
        border-left: 1.5px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 7px 7px;
        vertical-align: middle;
        max-height: 125px;
    }
    td .product__name{
        width: 100%;
        font-weight: 500;
    }
    td img{
        width: 80%;
        margin: auto;
        max-width: 100px;
    }
    td.text-uppercase{
        text-transform: uppercase;
    }
    span.status.active{
        color: #4caf50;
    }
    span.status.danger{
        color: #ff1744;
    }
    td.status.active{
        color: #4caf50;
    }
    td.status.danger{
        color: #ff1744;
    }
    td.status.pending{
        color: #ff8f00;
    }
    td .flex-center{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
export const ButtonBlackRounded = styled.div`
width: ${({ width }) => width ? width : '254px'};
height: ${({ height }) => height ? height : '43px'};
background: transparent;
border-radius: 34px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
color: #000;
margin-right: 5px;
transition: background .4s ease;
&.active{
    background: #000;
    color: #fff;
}
&:hover{
    background: #000;
    color: #fff;
}
`
export const ButtonColor = styled.div`
padding: 5px 10px;
background: transparent;
border-radius: 13px;
display: flex;
justify-content: center;
font-size: ${({ fontSize }) => fontSize ? fontSize : '12px'};
align-items: center;
cursor: pointer;
background: ${({ bgColor }) => bgColor ? bgColor : 'transparent'};
color: ${({ color }) => color ? color : '#000'};
span{
    margin-left: 6px;
    width: max-content;
}
`
export const CheckBox = styled.div`
    input[type="checkbox"]{
        margin-right: 7px;
        line-height: 12px;
    }
    label{
        width: auto;
		color: #333;
            span {
            display:inline-block;
            width: 13px;
            height: 12px;
            margin: -2px 5px 0 0;
            vertical-align: middle;
        }
    }


    

`
export const Modal = styled.div`
position: fixed;
width: 100%;
height: 100vh;
background: rgba(255,255,255,0.5);
.modal__container{
    min-width: 40%;
}
`
export const SearchRounded = styled.div`
width: ${({ width }) => width ? width : 'auto'};
display: flex;
flex-direction: row;
justify-content: space-between;
border: 1px solid #bdbdbd;
border-radius: 25px;
    overflow: hidden;
    position: relative;
input{
     width: 117px;;
    border: none;
    outline: none;
    padding-left: 20px;
}
div{
    flex-basis:24%;
}

`
export const BtnRounded = styled.div`
  appearance: none;
  width: ${({ width }) => width ? width : 'auto'};
  background-color: transparent;
  border: 2px solid ${({ color }) => color ? color : '#1A1A1A'};
  border-radius: 15px;
  box-sizing: border-box;
  color: ${({ color }) => color ? color : '#3B3B3B'};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  height: 32px;
padding: 3px 7px;
&:disabled {
  pointer-events: none;
}

&:hover {
  color: #fff;
  background-color: ${({ color }) => color ? color : '#1A1A1A'};
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

&:active {
  box-shadow: none;
  transform: translateY(0);

} 
&.disable{
    opacity: 0.5;
}
`
export const OptButton = styled.div`
border: 1px solid #000;
cursor: pointer;
padding: 1px 1px;
border-radius:17px;
display: flex;
margin: 3px 5px;
align-items: center;
justify-content:center;
background: ${({ active, color }) => active && color ? '#000' : `#fff`};
.container{
    color:${({ active, color }) => active && color ? '#fff' : `#000`};
    border-radius:10px;
    padding: 2px 5px;
}

`
export const CardButton = styled.div`
`
