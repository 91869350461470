import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../../based/inputs/Textbox";
import TextNumber from "../../based/inputs/TextNumber";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import FolderProductPrototypeServices from "../../based/services/FolderProductPrototypeServices";
import useFormValidate from "../../hooks/useFormValidate";
import Common from "../../based/Common";
import { INITPAGING , CONSTANTS} from "../../based/Constants";
import styled from "styled-components";
import MultipleImages from "../../based/inputs/MultipleImages";
import MarketingServices from "../../based/services/MarketingServices";
import Request from "../../based/Request";

const TYPE_PRODUCT = {
    CREATOR: 1,
    DESIGN : 2
}

const ModalUpdateProduct = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [products, setProducts] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(()=>{
        
        if(props.item && props.item.isAdd){
            getProducts(props.item)
        }else if(props.item && !props.item.isAdd){
            if(props.item.productType === TYPE_PRODUCT.CREATOR){
                setProducts({
                    ...props.item,
                    imageOriginal: props.item.productAvatar
                })
            }
            if(props.item.productType === TYPE_PRODUCT.DESIGN){
                setProducts({
                    ...props.item,
                    imageOriginal : props.item.productImageUrl
                })
            }
        }
        
    },[props.item])

    const getProducts = async (item) => {
        if(item.productType === TYPE_PRODUCT.CREATOR){
            let [err, data] = await MarketingServices.GetProductCreatorById(item.productId, props.creatorId);
            if (!err && data) {
                setProducts({
                    ...data,
                    imageOriginal: data.productAvatar
                })
            } else {
                setProducts({
                    name: props.item.nameProduct,
                    price: props.item.sellPrice,
                    isShowThumbnail: false,
                    thumbnail: "",
                    productId: props.item.productId,
                    productType: TYPE_PRODUCT.CREATOR,
                    imageOriginal: props.item.productAvatar
                })
            }
        }        
        if(item.productType === TYPE_PRODUCT.DESIGN){
            let [err, data] = await MarketingServices.GetProductDesignById(item.productId, props.creatorId);
            if (!err && data) {
                setProducts({
                   ...data,
                   price: data.totalPrice,
                    imageOriginal : data.productImageUrl
                })
            } else {
                setProducts({
                    name: props.item.title,
                    price: props.item.totalPrice,
                    isShowThumbnail: false,
                    thumbnail: "",
                    productId: props.item.productId,
                    productType: TYPE_PRODUCT.DESIGN,
                    imageOriginal : props.item.productImageUrl
                })
            }
        }
        
    }

   
    const _handleSave = async () => {
        if (isValid() && products.name.length < 50) {
            setIsBusy(true);
            let [err, data] = await MarketingServices.CreateFolderConnectionMarketingProduct({
                ...products
            } , props.folderId , products.productType);
            if (!err && data) {
                setIsBusy(false);
                setProducts({});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            props.onSaveContinue();
        }
        if(products.name.length >= 50){
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Tên không được vượt quá 50 ký tự");
        }
        
    };
   
    async function handleUploadFile(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            setProducts({...products, thumbnail: res.data.imageUrl})     
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }
    
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Cập nhật sản phẩm"} onSave={_handleSave} onClose={() => props.onClose()}>
            <Wapper>
                <div className="box-input">
                    <div className="w50">
                        <input type="radio" checked={!products.isShowThumbnail} onChange={()=>{setProducts({...products, isShowThumbnail: false})}} />
                        <label htmlFor="name">Ảnh gốc</label>
                        <div className="box-image">
                            <img style={{ maxWidth: 100, maxHeight: 100 }} src={products.imageOriginal} />
                        </div>
                    </div>
                    <div className="w50 pl-2">
                        <input type="radio" checked={products.isShowThumbnail} onChange={()=>{setProducts({...products, isShowThumbnail: true})}} />
                        <label htmlFor="price">Ảnh hiển thị</label>
                        {products.thumbnail ? 
                        <div className="box-image">
                            <img style={{ maxWidth: 100, maxHeight: 100 }} src={products.thumbnail} />
                            <span onClick={()=>{setProducts({...products, thumbnail: ""})}}>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 1L1 12" stroke="#F96A74" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M12 12L1 0.999999" stroke="#F96A74" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </span>
                        </div>
                        
                        :
                        <div className="box-image">
                            <input
                                style={{maxWidth: 90}}
                                accept="image/*"
                                type="file"
                                onChange={(e) => {
                                    handleUploadFile(e);
                                }}
                            />
                        </div>
                        }
                        
                    </div>
                </div>
                <div className="box-input">
                    <div className="w50">
                        <label htmlFor="name">Tên hiển thị</label>
                        <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => {
                            setProducts({...products, name: value})
                        }} value={products.name} />
                    </div>
                    <div className="w50 pl-2">
                        <label htmlFor="price">Giá</label>
                        <TextNumber id='price' name='price' required ref={addRef} className='form-control' onChanged={(name, value) => {
                            setProducts({...products, price: value})

                        }} value={products.price} />
                    </div>
                </div>
                
            </Wapper>
        </CommonModal>
    );
};

export default ModalUpdateProduct;
const Wapper = styled.div`
    .hide{
        display: none;
    }
    .box-image{
        position: relative;
        width: 100%;
        aspect-ratio: 1/1;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        span{
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            line-height: 1;
        }
    }
    .box-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .text-add{
            color: #64C5B1;
        }

    }

    .box-input{
        display: flex;

        .w50{
            width: 50%;

            
        }

        input[type='radio']{
            width: 20px;
            height: 20px;
            accent-color: green;
        }
    }

    .group-img{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 -3px;
        .col-img{
            width: 20%;
            max-width: 20%;
            margin: 0 3px;
            position: relative;

            img{
                width: 100%;
                aspect-ratio: 1/1;
                margin-bottom: 6px;
            }
            .remove{
                position: absolute;
                right: 8px;
                top: 8px;
            }
        }
    }
    
`
ModalUpdateProduct.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
