export const PresetVideoDetailType = {
    Undefined: 0,
    Logo: 1,
    Credit: 2,
    AudioBack: 3,
};

export const AfterEffectStatus = {
    Undefined: 0,
    Uploaded: 1,
    ProcessedLayerName: 2,
    ConfigedLayerName: 3,
};
export const AfterEffectModel = {
    id: 0,
    name: "",
    url: "",
    isDelete: false,
    createdBy: "",
    createdDate: null,
    modifiedBy: "",
    modifiedDate: null,
    status: AfterEffectStatus.Undefined,
    statusName: "",
};
export const LayerType = {
    Undefined: 0,
    Artwork: 1,
    ProductImage: 2,
    ProductPrice: 3,
    ProductName: 4,
    AudioAI: 5,
    AffiliateCode: 6,
};

export const LayerTypeName = {
    0: "Không xác định",
    1: "Thiết kế",
    2: "Ảnh sản phẩm",
    3: "Giá sản phẩm",
    4: "Tên sản phẩm",
    5: "Voice AI",
    6: "Mã giảm giá",
};

export const LayerProductSide = {
    Undefined: 0,
    Front: 1,
    Back: 2,
};

export const LayerProductSideName = {
    0: "Không xác định",
    1: "Mặt trước",
    2: "Mặt sau",
};

export class ConfigModel {
    constructor() {
        this.preset = {};
        this.presetVideoLayers = [];

        //default value
        let defaultArray = [];
        defaultArray.push({
            objectType: PresetVideoDetailType.Credit,
            objectData: "",
            fromTime: 0,
            toTime: 0,
        });
        defaultArray.push({
            objectType: PresetVideoDetailType.AudioBack,
            objectData: "",
            fromTime: 0,
            toTime: 0,
        });
        defaultArray.push({
            objectType: PresetVideoDetailType.Logo,
            objectData: "",
            fromTime: 0,
            toTime: 0,
        });
        this.presetVideoDetails = defaultArray;
    }
    preset = new PresetModel();
    presetVideoLayers = [new PresetVideoLayerModel()];
    presetVideoDetails = [new PresetVideoDetailModel()];
}

export class PresetModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.thumbnail = "";
        this.videoId = 0;
    }
}

export class PresetVideoLayerModel {
    constructor() {
        this.presetVideoId = 0;
        this.afterEffectLayerId = 0;
        this.afterEffectLayerName = "";
        this.objectType = LayerType.Undefined;
        this.objectData = "";
    }
}

export class PresetVideoDetailModel {
    constructor() {
        this.id = 0;
        this.presetId = 0;
        this.objectType = PresetVideoDetailType.Undefined;
        this.objectData = "";
        this.fromTime = 0;
        this.toTime = 0;
        this.x = 0;
        this.y = 0;
    }
}
export const AfterEffectVideoType = {
    Reup: 0,
    LiveStream: 1
};


export const JobVideoSex = {
    Undefined: 0,
    Man: 1,
    Woman: 2
};