import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { NOTIFY, SHIPPING_SERVICES_CODE } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Radio from "../based/inputs/Radio";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonServices from "../based/services/CommonServices";
import OrderServices from "../based/services/OrderServices";
const ShippingCompany = {
    RANUS: 3,
};
export default function OrderBookingModal({ isShowModal, onClose, orderId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [shippingCompanyId, setShippingCompanyId] = useState(0);
    const [shippingCompanyCode, setShippingCompanyCode] = useState("");
    const [shippingServices, setShippingServices] = useState([]);
    const [label, setLabel] = useState({});
    const [shippingModel, setShippingModel] = useState({});

    useEffect(() => {
        _getShippingServices();
    }, [isShowModal]);

    useEffect(() => {
        if (orderId > 0) getPrintInfo(orderId);
    }, [orderId]);

    useEffect(() => {
        var shipping = shippingServices.find((x) => x.id == shippingCompanyId);
        if (shipping) {
            setShippingCompanyCode(shipping.code);

            if (label && label.order) {
                let lb = { ...label };
                let order = lb.order;
                order.shippingCompanyCode = shipping.code;
                setLabel(lb);
            }

            if (shipping.code == SHIPPING_SERVICES_CODE.VNPOST) {
                setShippingModel({
                    provinceCode: label.shippingAddress.vnPostProvince,
                    districtCode: label.shippingAddress.vnPostDistrict,
                    wardCode: label.shippingAddress.vnPostWard,
                });
            }
            if (shipping.code == SHIPPING_SERVICES_CODE.SHOPEEXPRESS) {
                setShippingModel({
                    provinceCode: label.shippingAddress.provinceName,
                    districtCode: label.shippingAddress.districtName,
                    wardCode: label.shippingAddress.wardName,
                });
            }
        }
    }, [shippingCompanyId, shippingServices]);

    const getPrintInfo = async (orderId) => {
        setIsLoading(true);
        let [err, data] = await OrderServices.GetRanusShippingLabel(orderId);
        if (!err && data) {
            let dt = { ...data };
            if (dt.shippingOrderNumber) dt.url = dt.shippingOrderNumber;
            setLabel(dt);

            //address model
            if (data.order) {
                let address = data.shippingAddress;
                if (data.order.shippingCompanyCode == SHIPPING_SERVICES_CODE.VNPOST) {
                    setShippingModel({
                        provinceCode: address.vnPostProvince,
                        districtCode: address.vnPostDistrict,
                        wardCode: address.vnPostWard,
                    });
                }
                if (data.order.shippingCompanyCode == SHIPPING_SERVICES_CODE.SHOPEEXPRESS) {
                    setShippingModel({
                        provinceCode: address.provinceName,
                        districtCode: address.districtName,
                        wardCode: address.wardName,
                    });
                }
            }
        } else {
            setLabel({});
        }
        setIsLoading(false);
    };

    async function _getShippingServices() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await CommonServices.GetShippingServices();
        if (!err && data) {
            setShippingServices(data);
            var ranus = data.find((x) => x.code == SHIPPING_SERVICES_CODE.RANUS);
            if (ranus) setShippingCompanyId(ranus.id);
        } else {
            setShippingCompanyId(0);
        }
        setMsg("");
        setIsLoading(false);
    }

    // in tem vận chuyển
    async function _handlePrint() {
        if (shippingCompanyId > 0) {
            let currentShipping = shippingServices.find((x) => x.id == shippingCompanyId);
            let shippingCode = "";
            if (currentShipping) shippingCode = currentShipping.code;
            if (orderId > 0) {
                let order = label.order;
                let shippingOrderNumber = order.shippingOrderNumber;
                switch (shippingCode) {
                    case SHIPPING_SERVICES_CODE.RANUS:
                        await _handlePrintRanus();
                        break;
                    case SHIPPING_SERVICES_CODE.VNPOST:
                        if (!shippingOrderNumber || shippingCompanyId != order.shippingCompanyId) {
                            if (!shippingModel || !shippingModel.districtCode || !shippingModel.provinceCode || !shippingModel.wardCode || shippingModel.districtCode == -2 || shippingCode.provinceCode == -2 || shippingCode.wardCode == -2) {
                                Notify(NOTIFY.ERROR, "Lỗi", "Xin vui lòng bổ sung đầy đủ thông tin địa chỉ");
                                return;
                            }
                            await _handlePrintVNPOST();
                        }
                        break;
                    case SHIPPING_SERVICES_CODE.SHOPEEXPRESS:
                        if (!shippingOrderNumber || shippingCompanyId != order.shippingCompanyId) {
                            await _handlePrintShopeeXpress();
                        }
                        break;
                    default:
                        break;
                }
                onClose();
                _handleReadyToShip(orderId);
                window.open(`/shipping-label/${orderId}`, "_blank");
            }
        } else Notify(NOTIFY.WARNING, "Chú ý", "Yêu cầu chọn đơn vị vận chuyển trước khi in nhãn ship");
    }

    async function _handleReadyToShip(orderId) {
        let [err, data] = await OrderServices.SetStatusToReadyToShip(orderId);
    }

    async function _handlePrintRanus() {
        let [error, data] = await OrderServices.PrintShippingLabelRanus(orderId, shippingCompanyId);
    }

    async function _handlePrintVNPOST() {
        let [error, data] = await OrderServices.PrintShippingLabel(orderId, shippingCompanyId, shippingModel);
        if (!error && data) {
            let lb = { ...label };
            let order = lb.order;
            order.shippingOrderNumber = data.url;
            order.sortingCode = data.sortingCode;
            order.shippingCompanyCode = SHIPPING_SERVICES_CODE.VNPOST;
            setLabel(lb);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
        }
    }

    async function _handlePrintShopeeXpress() {
        let [error, data] = await OrderServices.PrintShippingLabel(orderId, shippingCompanyId, shippingModel);
        if (!error && data) {
            let lb = { ...label };
            let order = lb.order;
            order.shippingOrderNumber = data.url;
            order.sortingCode = data.sortingCode;
            order.shippingCompanyCode = SHIPPING_SERVICES_CODE.SHOPEEXPRESS;
            setLabel(lb);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
        }
    }

    return (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isLoading}
            busyMsg={msg}
            title="Chọn đơn vị vận chuyển"
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm m-2" onClick={() => _handlePrint()}>
                        <i className="fa fa-print m-r-5" />
                        Chọn đơn vị phụ trách vận chuyển
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
            size={"lg"}
        >
            <div className={`d-flex flex-column align-items-center justify-content-start`} style={{ overflowY: "auto" }}>
                <div className="p-20 w-100" style={{ borderBottom: "1px solid black" }}>
                    <h5>Đơn vị phụ trách Vận Chuyển</h5>
                    <div className="">
                        {shippingServices &&
                            shippingServices.length > 0 &&
                            shippingServices.map((item, idx) => {
                                let isHandle = label && label.order && (item.id == label.order.shippingCompanyId ? "(Đang Handle Đơn Hàng)" : "");
                                return (
                                    <Radio
                                        classDiv="mr-3"
                                        key={idx}
                                        id={`${item.id}`}
                                        name="shippingCompanyId"
                                        checked={shippingCompanyId == item.id}
                                        value={item.id}
                                        onCheckedChange={(name, value) => setShippingCompanyId(item.id)}
                                        label={`${item.name} ${isHandle || ""}`}
                                    />
                                );
                            })}
                    </div>
                    <div className="sub-header" style={{ flex: 100 }}>
                        Đối với Ranus tự giao, tem vận chuyển của Ranus tự thiết kế <br />
                        Đối với các đơn vị vận chuyển khác, tem vận chuyển của đơn vị vận chuyển, thông tin bên dưới dùng để tham khảo lại với thông tin tem vận chuyển của ĐVVC
                    </div>
                </div>
                {shippingCompanyId == ShippingCompany.RANUS ? (
                    ""
                ) : (
                    <React.Fragment>
                        {/* Thông tin vận chuyển được mapping với đơn vị vận chuyển */}
                        {shippingCompanyCode == SHIPPING_SERVICES_CODE.VNPOST && (!shippingModel.provinceCode || !shippingModel.districtCode || !shippingModel.wardCode) && (
                            <div className="w-100" style={{ borderBottom: "1px solid black" }}>
                                <h5>Thông tin quận huyện trên Hệ Thống ĐVVC</h5>
                                <VNPostComponent model={shippingModel} setModel={(model) => setShippingModel(model)} />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        </CommonModal>
    );
}

function VNPostComponent({ model, setModel }) {
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);

    useEffect(() => {
        getProvinces();
    }, []);

    useEffect(() => {
        getDistricts(model.provinceCode);
        setWards([]);
    }, [model.provinceCode]);

    useEffect(() => {
        getWards(model.districtCode);
    }, [model.districtCode]);

    const getProvinces = async () => {
        let [err, data] = await CommonServices.GetListVNPostProvince();
        if (!err && data) setProvinces(data);
        else setProvinces([]);
    };

    const getDistricts = async (provinceCode) => {
        let [err, data] = await CommonServices.GetListVNPostDistrict(provinceCode);
        if (!err && data) setDistricts(data);
        else setDistricts([]);
    };

    const getWards = async (districtCode) => {
        let [err, data] = await CommonServices.GetListVNPostWard(districtCode);
        if (!err && data) setWards(data);
        else setWards([]);
    };

    return (
        <div className="form-group col-md-12">
            <label className="col-form-label required">Thông tin vị trí của đơn vị vận chuyển</label>
            <div className="form-group col-md-12">
                <label className="text_addAddress color_text required">Tỉnh/Thành phố</label>
                <TFUSelect
                    name="province"
                    value={model.provinceCode}
                    options={provinces}
                    required
                    placeholder="--Chọn tỉnh thành--"
                    className="pb-3"
                    onChanged={(value) => {
                        setModel({ ...model, provinceCode: value });
                    }}
                />
            </div>
            <div className="form-group col-md-12">
                <label className="text_addAddress color_text required">Quận/Huyện</label>
                <TFUSelect
                    name="province"
                    value={model.districtCode}
                    options={districts}
                    required
                    placeholder="--Chọn tỉnh thành--"
                    className="address-selection"
                    onChanged={(value) => {
                        setModel({ ...model, districtCode: value });
                    }}
                />
            </div>
            <div className="form-group col-md-12">
                <label className="text_addAddress color_text required">Phường/Xã</label>
                <TFUSelect
                    name="province"
                    value={model.wardCode}
                    options={wards}
                    required
                    placeholder="--Chọn tỉnh thành--"
                    className="address-selection"
                    onChanged={(value) => {
                        setModel({ ...model, wardCode: value });
                    }}
                />
            </div>
        </div>
    );
}
