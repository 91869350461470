
import cookie from 'react-cookies'
var process = function (key, data) {
    let arr = key.split('.')
    let result = ''
    for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        data = data[element]
        result = data
    }
    return result ? result : `${key} Undefined`
}

var Language = {
    getValue: function (key) {
        let data = {} 
        let langCode = cookie.load('lang')
        if (langCode) {
            data = require('../resources/' + langCode + '.json')
        } else {
            data = require('../resources/vi-VN.json')
        }
        return process(key, data)
    }
}

export default Language