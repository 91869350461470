import BaseServices from './BaseServices';

const ThirdPartyServices = {
  // Customers
  GetsCustomers: async (paging) => {
    return await BaseServices.Post('/api/Thirdparty/gets-customer', paging);
  },
  GetCustomersDropdown: async () => {
    return await BaseServices.Get('/api/ThirdParty/get-customers-dropdown');
  },
  GetCustomerDetail: async (id) => {
    return await BaseServices.Get('/api/ThirdParty/get-customer/' + id);
  },
  GetCustomersDropdownByPhone: async (phoneNumber) => {
    return await BaseServices.Get('/api/ThirdParty/get-customers-by-phone/' + phoneNumber);
  },
  GetCustomerByPhoneOrEmail: async (phoneNumber, email) => {
    return await BaseServices.Get(`/api/ThirdParty/get-customer-by-phone-or-email?phoneNumber=${phoneNumber}&email=${email}`);
  },

  // Suppliers
  GetsSuppliers: async (paging) => {
    return await BaseServices.Post('/api/Thirdparty/gets-supplier', paging);
  },
  GetSupplierDetail: async (id) => {
    return await BaseServices.Get('/api/ThirdParty/get-supplier/' + id);
  },
  GetSuppliersDropdown: async () => {
    return await BaseServices.Get('/api/ThirdParty/get-suppliers-dropdown');
  },
  SaveSupplier: async (obj) => {
    return await BaseServices.Post('/api/ThirdParty/save-supplier', obj);
  },
  DeleteSuppliers: async (ids) => {
    return await BaseServices.Post('/api/Thirdparty/delete-suppliers', ids);
  },
  GetCustomerById: async (customerId) => {
    return await BaseServices.Get(`/api/thirdParty/gets-customer-detail?id=${customerId}`);
  },
  GetCustomerOrders: async (customerId, paging) => {
    return await BaseServices.Post(`/api/thirdParty/gets-customer-orders?id=${customerId}`, paging);
  },
  GetsCustomerAddressHistories: async (paging) => {
    return await BaseServices.Post(`/api/thirdParty/get-customer-address-histories`, paging);
  },
};

export default ThirdPartyServices;
