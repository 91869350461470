import React from "react";
import CommonTable from "../../../based/CommonTable";
import { useState } from "react";
import AddOrEditSlotModal from "./AddOrEditSlotModal";
const INIT_SLOT = {
    id: 0,
    printingTableId: 0,
    positionIndex: "",
    direction: "",
    x: "",
    y: "",
    index: -1,
};
const PrintingTableSlotsEditor = ({ slots, onChanged }) => {
    const [showModal, setShowModal] = useState(false);
    const [editingSlot, setEditingSlot] = useState(INIT_SLOT);
    const _handleAdd = () => {
        setShowModal(true);
        setEditingSlot(INIT_SLOT);
    };
    const _handleEdit = (index) => {
        let slot = { ...slots[index] };
        slot.index = index;
        slot.positionIndex += 1;
        setEditingSlot(slot);
        setShowModal(true);
    };

    const _handleDelete = (index) => {
        slots.splice(index, 1);
        onChanged(slots);
    };
    const _handleSave = (slot) => {
        let exist = slots.findIndex(m => m.positionIndex == slot.positionIndex);
        if (exist >= 0) {
            slots[exist] = slot;
        }
        else if (slot.index >= 0) {
            slots[slot.index] = slot;
        } else {
            slots.push(slot);
        }
        onChanged(slots);
        setShowModal(false);
    };
    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th>Thứ tự trên bàn in</th>
                    <th>Loại SP có thể in</th>
                    <th>Hiệu chỉnh</th>
                    <th>Hướng quay</th>
                    <th></th>
                </tr>
            </thead>
        );
    };
    const _renderBody = () => {
        return (
            <tbody>
                {slots && slots.length > 0 ? (
                    slots.map((g, i) => {
                        return (
                            <tr key={i}>
                                <td>{g.positionIndex + 1}</td>
                                <td>
                                    <div className="d-flex flex-wrap">
                                        {g.products.map((m) => (
                                            <div className="p-item flex-50">{m.productName}</div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <span>X: {g.x}</span>
                                    <br />
                                    <span>Y: {g.y}</span>
                                </td>
                                <td>{"Hướng " + g.direction}</td>
                                <td width={130}>
                                    <button className="btn btn-sm btn-custom m-r-10" onClick={() => _handleEdit(i)}>
                                        Sửa
                                    </button>
                                    <button className="btn btn-sm btn-danger" onClick={() => _handleDelete(i)}>
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={8}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    };
    return (
        <div>
            <CommonTable data={slots} renderHeader={_renderHeader} renderBody={_renderBody} hideFooter />
            <div>
                <strong className="text-custom pointer" onClick={_handleAdd}>
                    Thêm vị trí +
                </strong>
            </div>
            <AddOrEditSlotModal show={showModal} onClose={() => setShowModal(false)} onSave={_handleSave} slot={editingSlot} slots={slots} />
        </div>
    );
};

export default PrintingTableSlotsEditor;
