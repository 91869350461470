import React from 'react';
import { StockInContext, WarehouseInfoModel, SupplierInfoModel, WarehouseProductModel } from '../context/StockInContext';
import SupplierInfo from './SupplierInfo';
import WarehouseServices from '../based/services/WarehouseServices';
import { useState, useEffect } from 'react';
import CommonModal from '../based/Modal';
import Common from '../based/Common';
import { CONSTANTS, NOTIFY, STOCK_IN_STATUS } from '../based/Constants';
import { Notify } from '../based/Notify';
import TextArea from '../based/inputs/TextArea';
import { Button } from 'react-bootstrap';
import { WarehouseOrdersModel } from '../models/WarehouseOrderModel';
import StockInModalItem from './components/StockInModalItem';
import TFUTooltip from '../based/TFUTooltip';
import ScannedProductInfo from './components/ScannedProductInfo';
import ProductServices from '../based/services/ProductServices';
import Textbox from '../based/inputs/Textbox';
import DatePicker from 'react-datepicker';
import useFormValidate from '../hooks/useFormValidate';

const columns = [
  {
    displayName: 'Batch Code',
    name: 'batchCode',
    width: 180,
    toolTip: 'Batch Code là mã nhận dạng được gán cho một nhóm mặt hàng có cùng hạn sử dụng.',
  },
  {
    displayName: 'Barcode',
    name: 'barcode',
    width: 120,
  },
  {
    displayName: 'SKU',
    name: 'sellerSku',
    width: 100,
  },
  {
    displayName: 'Tên sản phẩm',
    name: 'productName',
    width: 150,
  },
  {
    displayName: 'Số lượng nhập',
    name: 'quantity',
    width: 80,
    isRequired: true,
  },
  {
    displayName: 'Giá nhập',
    name: 'price',
    width: 120,
    isRequired: true,
  },
  {
    displayName: 'Trạng thái',
    name: 'productStatus',
    width: 120,
  },
  {
    displayName: 'Vị trí lưu kho',
    name: 'slotCode',
    width: 80,
    isRequired: true,
  },
  {
    displayName: '',
    width: 50,
  },
];
const key = ['orderId', 'batchCode', 'productVariantId', 'productStatus', 'warehouseId', 'slotCode'];

const MSG = {
  ValidQuantity: (quantity) => {
    return `Sản phẩm có số lượng lớn hơn số lượng ban đầu xác nhận (${quantity} cái). Xin vui lòng chọn lại.`;
  },
  DuplicateSerialNumber: (string) => {
    return `Số Serial Number (${string.join(', ')}) hiện tại đang bị trùng. Xin vui lòng kiểm tra lại.`;
  },
  SUCCESS: 'Lưu thành công.',
  ERROR: 'Xin vui lòng kiểm tra và thử lại sau ít phút.',
  DuplicateValue: 'Hiện danh sách nhập kho đang bị trùng.',
  NullValue: 'Xin vui lòng không để trống các trường bắt buộc nhập.',
  BarcodeScan: 'Hiện tại không có sản phẩm được quét trong đơn hàng.',
  Unselected: 'Bạn chưa chọn sản phẩm.',
};

const NOTIFY_TITLE = {
  WARNING: 'Thông báo',
  SUCCESS: 'Thành công',
  ERROR: 'Lỗi',
};

export default function StockInModal(props) {
  const [isBusy, setIsBusy] = useState(true);
  const [msgModalLoading, setMsgLoading] = useState(true);
  const [warehouseInfo, setWarehouseInfo] = useState(new WarehouseInfoModel());
  const [supplierInfo, setSupplierInfo] = useState(new SupplierInfoModel());
  const [products, setProducts] = useState([new WarehouseProductModel()]);
  const [stockValidation, setStockValidation] = useState([]);
  const [nextStatusButton, setNextStatusButton] = useState([]);
  const [isShowAddEdit, setIsShowAddEdit] = useState(false);
  const [isCommonModal, setIsCommonModal] = useState(props.isShowModal);
  const [isClearScanInput, setIsClearScanInput] = useState(false);
  const [excludeColumns, setExcludeColumns] = useState([]);
  const [productList, setProductList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [warehouseId, setWarehouseId] = useState(0);

  const { addRef, displayError, isValid } = useFormValidate();

  var timer = null;

  useEffect(() => {
    if (props.orderId > 0) _getOrderInfo(props.orderId);
  }, [props.orderId]);

  useEffect(() => {
    if (props.orderBtnStatus >= 0) {
      _queryButton(props.orderBtnStatus);
      _queryColumns(props.orderBtnStatus);
    }
  }, [props.orderBtnStatus]);

  useEffect(() => {
    setIsCommonModal(props.isShowModal);
  }, [props.isShowModal]);

  // Get available products in WAR_SlotProducts
  async function _getDropdownProducts(productsInOrder, status) {
    let [err, data] = await ProductServices.GetProductsDropdown();
    if (!err) {
      {
        // Nếu đơn đã xác nhận thì dropdown lúc này chỉ gồm những sản phẩm lúc xác nhận
        if (status == STOCK_IN_STATUS.Approved) {
          let productIds = productsInOrder.map((i) => i.productId);
          let productList = data.filter((i) => productIds.includes(+i.value.split('_')[1]));
          const formatedOptions = productList.map((x) => ({
            ...x,
            value: x.value.split('_')[1],
          }));
          setProductList(formatedOptions);
        } else {
          const formatedOptions = data.map((x) => ({
            ...x,
            value: x.value.split('_')[1],
          }));
          setProductList(formatedOptions);
        }
      }
    }
  }

  async function _queryButton(btn) {
    let [err, data] = await WarehouseServices.QueryButtonDisplay(btn);
    if (!err) setNextStatusButton(data);
  }

  function _queryColumns(btn) {
    if (btn == STOCK_IN_STATUS.Approved || btn == STOCK_IN_STATUS.PendingApproval) {
      let excludesCln = ['batchCode', 'productStatus'];
      return setExcludeColumns(excludesCln);
    }
    if (btn == STOCK_IN_STATUS.InboundingProcess) {
      let excludeCln = ['price'];
      setExcludeColumns(excludeCln);
    } else setExcludeColumns([]);
  }

  // Get order information
  async function _getOrderInfo(orderId) {
    setIsBusy(true);
    const [err, data] = await WarehouseServices.GetOrderDetail(orderId);
    if (!err) {
      var listProduct = data.products;
      for (var i = 0; i < listProduct.length; i++) {
        var product = listProduct[i];
        var pId = product.productId;
        if (product.extendData.length) {
          for (var j = 0; j < product.extendData.length; j++) {
            product.extendData[j].productVariantId = pId;
            if (product.extendData[j].manufacturingDate) {
              product.extendData[j].manufacturingDate = new Date(product.extendData[j].manufacturingDate);
            }
            if (product.extendData[j].expiredDate) {
              product.extendData[j].expiredDate = new Date(product.extendData[j].expiredDate);
            }
          }
        }
        listProduct[i].slotOptions = await _getSlots(listProduct[i].warehouseId);
        listProduct[i].hasExtendData = listProduct[i].hasSerialNumber || listProduct[i].hasImei || listProduct[i].hasExpDate;
        listProduct[i].isExpandExtendData = listProduct[i].hasExtendData;
        if (data.statusId == STOCK_IN_STATUS.Approved && listProduct[i].batchCode == CONSTANTS.DEFAULT_BATCHCODE) listProduct[i].batchCode = `BC_${data.id}_${i + 1}`;
      }
      _getDropdownProducts(listProduct, data.statusId);
      setProducts(listProduct);
      setWarehouseInfo({
        ...warehouseInfo,
        id: data.id,
        orderCode: data.orderCode,
        remark: data.remark,
        statusId: data.statusId,
        warehouseName: data.warehouseName,
      });
      setSupplierInfo(data.thirdParty);
      //Nếu đơn hàng được set có thể viết và đã được approval
      //Tiến hành validate để người dùng nhập không vượt qua số quy định
      if (!props.readOnly && data.statusId == STOCK_IN_STATUS.Approved) _handleStockValidatation(listProduct);
    }
    setIsBusy(false);
  }

  // Get slot list in warehouse
  async function _getSlots(warehouseId) {
    if (+warehouseId > 0) {
      const [err, data] = await WarehouseServices.GetWarehouseById(warehouseId);
      if (!err && data.wareslot) {
        var slotOptions = data.wareslot.map((slot) => ({ text: slot.code, value: slot.code, label: slot.code }));
        setWarehouseId(warehouseId);
        setSlotList(slotOptions);
        return slotOptions;
      }
    }
    return [];
  }

  async function _handleKeyValidBeforeChanged(index, name, fValue) {
    return new Promise((resolve, reject) => {
      if (key.includes(name)) {
        let currentProducts = JSON.parse(JSON.stringify(products));
        let currentProduct = currentProducts[index];
        currentProduct[name] = +fValue || fValue;
        let newSet = new Set();

        currentProducts.map((item, idx) => {
          let comboKey = key.map((ite, index) => {
            return item[ite];
          });
          newSet.add(JSON.stringify(comboKey));
          return item;
        });

        if (currentProducts.length > newSet.size) return resolve(false);
        else return resolve(true);
      } else return resolve(true);
    });
  }

  async function _handlePropertyChanged(index, name, fValue) {
    const currentProducts = [...products];
    const product = products[index];
    if (!product.productId) {
      Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.Unselected);
      return;
    }

    const value = +fValue || fValue;
    // Backup values
    var tempValue = product[name];
    var tempExtendData = product.extendData;

    var opositeValue = 0;
    let result = await _handleKeyValidBeforeChanged(index, name, fValue);
    if (result) {
      switch (name) {
        case 'quantity':
          product[name] = value;
          if (product.hasExtendData) {
            if (product.extendData.length > value) {
              product.extendData.length = value;
            } else {
              while (product.extendData.length < value - 1) {
                product.extendData.push({ id: 0, imei: '', serialNumber: '', manufacturingDate: null, expiredDate: null });
              }
            }
          }
          if (warehouseInfo.statusId === STOCK_IN_STATUS.Approved) {
            // Initial quantity
            var validQuantity = 0;
            const [error, res] = await WarehouseServices.GetApprovedQuantity(product.productId, props.orderId);
            if (!error && res) {
              validQuantity = res;
            }

            // Quantity after
            var currentQuantity = 0;
            currentProducts.forEach(function (i) {
              if (i.productId == product.productId) {
                currentQuantity += i.quantity;
              }
            });

            // Compare before and after quantity
            if (validQuantity < currentQuantity) {
              product[name] = tempValue;
              product.extendData = tempExtendData;
              Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.ValidQuantity(validQuantity));
            }
          }
          break;
        case 'defectiveQuantity':
        case 'brokenQuantity':
          if (name == 'defectiveQuantity') opositeValue = product.brokenQuantity;
          else opositeValue = product.defectiveQuantity;
          product[name] = value;
          if (stockValidation && stockValidation.length > 0) {
            if (product.quantity < value + opositeValue) {
              product[name] = tempValue;
              Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.ValidQuantity(product.quantity));
            }
          }
          break;
        default:
          product[name] = value;
          break;
      }
      setProducts([...products]);
    } else {
      Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.DuplicateValue);
    }
  }

  async function _handleReceived() {
    products.forEach(function (i) {
      if (i.productId == 0 || i.quantity == 0 || i.slotCode == '') {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Chưa nhập đầy đủ thông tin!');
        return false;
      }
    });
    if (isValid()) {
      let warehouse = { ...warehouseInfo };
      setMsgLoading('Đang lưu dữ liệu...');
      setIsBusy(true);
      let obj = {
        orderId: props.orderId,
        orderCode: warehouse.orderCode,
        importTime: new Date(),
        products,
        remark: warehouse.remark,
      };
      const [error, res] = await WarehouseServices.ReceivedProducts(obj);
      if (!error && res) {
        Notify(NOTIFY.SUCCESS, NOTIFY_TITLE.SUCCESS, MSG.SUCCESS);
        props.onClose(true);
      } else {
        if (error.errors) {
          Notify(NOTIFY.ERROR, NOTIFY_TITLE.ERROR, MSG.ERROR);
          displayError(error.errors);
        } else Notify(NOTIFY.ERROR, NOTIFY_TITLE.ERROR, MSG.ERROR);
      }
      setIsBusy(false);
    }
  }

  async function _handleUpdate() {
    let isLocalValid = _isLocalValid();
    if (isLocalValid) {
      let warehouse = { ...warehouseInfo };
      setIsBusy(true);
      var model = new WarehouseOrdersModel();
      model.id = props.orderId;
      model.expectedTimeStockIn = Common.formatDate(warehouseInfo.expectedTime, 'datetime');
      model.orderCode = warehouse.orderCode;
      model.remark = warehouseInfo.remark;
      model.thirdPartyId = supplierInfo.id;
      model.products = products;
      model.statusId = warehouseInfo.statusId;
      let [err, data] = await WarehouseServices.StockIn(model);
      setIsBusy(false);
      if (!err) {
        Notify(NOTIFY.SUCCESS, NOTIFY_TITLE.SUCCESS, MSG.SUCCESS);
        return true;
      }
    }
  }

  function _isLocalValid() {
    let updateProducts = [...products];
    let defaultProduct = updateProducts.find((x) => x.batchCode == CONSTANTS.DEFAULT_BATCHCODE);
    if (defaultProduct) {
      for (var i = 0; i < updateProducts.length; i++) {
        updateProducts[i].batchCode = CONSTANTS.DEFAULT_BATCHCODE;
      }
    }
    if (updateProducts.length < 1) {
      Notify(NOTIFY.ERROR, 'Lỗi', 'Chưa có sản phẩm trong đơn');
      return false;
    }
    updateProducts.forEach(function (p) {
      if (p.productName === '') {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Bạn chưa chọn sản phẩm');
        return false;
      }
      if (p.quantity === 0) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Số lượng sản phẩm nhập phải lớn hơn 0');
        return false;
      }
      if (p.productStatus === null) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Bạn chưa chọn trạng thái');
        return false;
      }
      if (p.slotCode === '') {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Bạn chưa chọn vị trí lưu');
        return false;
      }
    });
    let filterData = updateProducts.map((item, idx) => {
      return JSON.stringify({ id: item.productId, status: item.productStatus, slot: item.slotCode, batchCode: item.batchCode });
    });
    let isNotValid = filterData.length !== new Set(filterData).size;
    if (isNotValid) {
      Notify(NOTIFY.ERROR, 'Lỗi', 'Sản phẩm và kho trùng, xin kiểm tra lại');
      return false;
    } else return true;
  }

  function _handleStockValidatation(listProduct) {
    let productsDetail = [...listProduct];
    let groupBy = Common.GroupArray(productsDetail, 'productId');
    let lstStockValidate = [];
    groupBy.forEach((value, key) => {
      let sub = {};
      sub.Good = value.reduce((prev, ele) => {
        return (prev += ele.goodQuantity);
      }, 0);
      sub.Broken = value.reduce((prev, ele) => {
        return (prev += ele.brokenQuantity);
      }, 0);
      sub.Defective = value.reduce((prev, ele) => {
        return (prev += ele.defectiveQuantity);
      }, 0);
      return lstStockValidate.push({ productId: key, stockObject: sub });
    });
    setStockValidation(lstStockValidate);
  }

  function _handleAddProduct() {
    let subData = new WarehouseProductModel();
    subData.isAdded = true;
    subData.productStatus = 1;
    subData.batchCode = `BC_${warehouseInfo.id}_${products ? products.length + 1 : 1}`;
    let updateData = [...products, subData];
    setProducts(updateData);
  }

  function _handleRemoveProduct(index) {
    let updateData = [...products];
    updateData.splice(index, 1);
    let updateBatchCode = updateData.map((e, i, a) => ({ ...e, batchCode: `BC_${warehouseInfo.id}_${i + 1}` }));
    setProducts(updateBatchCode);
  }

  function _handleScanned(name, value) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      let updateData = [...products];
      let filterData = updateData.filter((item) => item[name] === value);
      if (filterData && filterData.length > 0) {
        updateData.map((item, idx) => {
          if (item[name] === value && name == 'batchCode') item.isBatchMatching = true;
          if (item[name] === value && name == 'barcode') item.isBarcodeMatching = true;
          return item;
        });
        setProducts(updateData);
        setIsClearScanInput(true);
      } else {
        Notify(NOTIFY.WARNING, NOTIFY_TITLE.WARNING, MSG.BarcodeScan);
        setIsClearScanInput(true);
      }
    }, 1000);
  }

  function _renderCustomButton() {
    let content = (
      <React.Fragment>
        {nextStatusButton && nextStatusButton.length > 0
          ? nextStatusButton.map((item, index) => {
              let clsBtn = item.buttonClass;
              return (
                <Button
                  id={index}
                  key={index}
                  className={`btn ${clsBtn} waves-effect w-md waves-light btn-sm`}
                  onClick={async () => {
                    //Nếu button có sự kiện nhận hàng sẽ bắt function nhận hàng
                    //Nếu không sẽ tiến hành update thông tin đơn hàng và chuyển trạng thái đơn hàng
                    if (item.value == STOCK_IN_STATUS.CompletelyReceived) {
                      _handleReceived();
                    } else {
                      let result = await _handleUpdate();
                      if (result) {
                        props.handleChangeStatus(props.orderId, item.value);
                        props.onClose(true);
                      }
                    }
                  }}
                >
                  {item.label}
                </Button>
              );
            })
          : null}
        {props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess ? (
          <Button
            id="saveDraft"
            key="saveDraft"
            className={`btn btn-warning waves-effect w-md waves-light btn-sm`}
            onClick={async () => {
              let result = await _handleUpdate();
              if (result) props.onClose();
            }}
          >
            Lưu bản nháp
          </Button>
        ) : null}
      </React.Fragment>
    );
    return content;
  }

  return (
    <React.Fragment>
      <CommonModal size="tfulg" show={isCommonModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msgModalLoading} title="Nhập kho" error={props.readOnly} customButton={_renderCustomButton()} onClose={() => props.onClose()}>
        <StockInContext.Provider
          value={{
            warehouseInfo,
            setWarehouseInfo,
            supplierInfo,
            setSupplierInfo,
            products,
            setProducts,
          }}
        >
          <div className="container-fluid p-0">
            <div className="card-box margin-bottom-10 padding-10">
              <SupplierInfo readOnly={true} />
            </div>
            <div className="card-box margin-bottom-10 padding-10">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="header-title font-weight-bold" style={{ margin: '0 0 10px 0' }}>
                    Thông tin nhập kho
                  </h4>
                  <div className="row">
                    <div className="form-group col-md-2">
                      <label htmlFor="warehouseName" className="col-form-label">
                        Tên kho
                      </label>
                      <Textbox name="warehouseName" value={warehouseInfo.warehouseName} readOnly={true} />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="orderCode" className="col-form-label">
                        Số chứng từ
                      </label>
                      <Textbox name="orderCode" value={warehouseInfo.id.toString()} readOnly={true} />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="orderCode" className="col-form-label">
                        Ngày nhập
                      </label>
                      <DatePicker selected={new Date()} dateFormat="dd/MM/yyyy HH:mm" className="form-control" readOnly />
                    </div>
                    <div className="form-group col-md-2">
                      <ScannedProductInfo
                        isClearInput={isClearScanInput}
                        setClearInput={() => setIsClearScanInput(false)}
                        onChanged={(name, value) => {
                          _handleScanned(name, value);
                        }}
                        readOnly={props.readOnly}
                      />
                    </div>
                    {/* <div className="form-group col-md-2">
                      <ScannedBatchCodeInfo
                        isClearInput={isClearScanInput}
                        setClearInput={() => setIsClearScanInput(false)}
                        onChanged={(name, value) => _handleScanned(name, value)}
                        readOnly={props.readOnly}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-box margin-bottom-10">
              <div className="">
                <table className="table table-bordered m-b-0">
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column, index) => {
                        let required = <span style={{ color: '#e62e04' }}> * </span>;
                        let tlTip = column.toolTip ? (
                          <TFUTooltip position="top" content={column.toolTip} target="batchCode">
                            <i className="fa fa-question-circle m-l-5" />
                          </TFUTooltip>
                        ) : (
                          ''
                        );
                        if (!excludeColumns.includes(column.name))
                          return (
                            <th key={index} className={`cursor-pointer center`} width={column.width} name={column.name} id={column.name}>
                              {column.displayName}
                              {column.isRequired ? required : ''}
                              {tlTip}
                            </th>
                          );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.length > 0 &&
                      products.map((product, index) => {
                        return (
                          <StockInModalItem
                            key={index}
                            ref={addRef}
                            name={`products[${index}]`}
                            product={product}
                            products={products}
                            productList={productList}
                            slotList={slotList}
                            warehouseId={warehouseId}
                            index={index}
                            handlePropertyChanged={(idx, name, value) => _handlePropertyChanged(idx, name, value)}
                            handleRemoveProduct={(index) => _handleRemoveProduct(index)}
                            readOnly={props.readOnly || warehouseInfo.statusId === STOCK_IN_STATUS.PendingApproval}
                            stockValidation={stockValidation}
                            orderBtnStatus={props.orderBtnStatus}
                            isShowAddEdit={isShowAddEdit}
                            excludeColumns={excludeColumns}
                            addRefParent={addRef}
                          />
                        );
                      })}
                    {props.orderBtnStatus == STOCK_IN_STATUS.PendingApproval || props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess ? (
                      <tr>
                        <td colSpan={13} className="text-right">
                          <button className="btn btn-primary btn-sm" onClick={_handleAddProduct}>
                            Thêm
                          </button>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <label className="col-form-label font-weight-bold">Ghi chú</label>
              <TextArea
                name="remark"
                className="form-control"
                value={warehouseInfo.remark}
                onChanged={(name, value) => {
                  setWarehouseInfo({ ...warehouseInfo, remark: value });
                }}
                readOnly={props.readOnly}
              />
            </div>
          </div>
        </StockInContext.Provider>
      </CommonModal>
    </React.Fragment>
  );
}
