import React, { useEffect, useState } from 'react';
import CommonTable from '../../../based/CommonTable';
import { CONSTANTS, INITPAGING, NOTIFY } from '../../../based/Constants';
import { ConfirmDialog } from '../../../based/Dialog';
import CheckBox from '../../../based/inputs/CheckBox';
import { Notify } from '../../../based/Notify';
import BaseServices from '../../../based/services/BaseServices';

const BlackListUser = () => {
    const [users, setUsers] = useState([]);
    const [paging, setPaging] = useState(INITPAGING);
    useEffect(() => {
        _fetchData()
    }, [])
    const _fetchData = async () => {
        const [err, data] = await BaseServices.Post('/api/affiliate/get-black-list', paging);
        if (!err) {
            setUsers(data.listObjects);
            setPaging(data.paging)
        }
    }
    const _handleUnBlock = async (id) => {
        ConfirmDialog('Xác nhận bỏ block?', 'Bạn chắc chắn muốn bỏ block với user này?', () => {
            return new Promise(async (resolve, reject) => {
                const [err, data] = await BaseServices.Post('/api/affiliate/unblock/' + id);
                if (err) {
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
                else {
                    resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                    await _fetchData();
                }
            })
        });
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <td>STT</td>
                <td>Email</td>
                <td>Đã xác nhận email</td>
                <td>Loại tài khoản</td>
                <td>Trạng thái</td>
                <td></td>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        if (users && users.length > 0) {
            return <tbody>
                {
                    users.map((item, idx) => {
                        return <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                                {item.email}
                            </td>
                            <td>
                                <CheckBox checked={item.verificationStatus == 1} classDiv='center' readOnly />
                            </td>
                            <td className='text-center'>{item.accountType == 0 ? "Cá Nhân" : "Doanh Nghiệp"}</td>
                            <td>
                                <strong className='text-danger'>Blocked</strong>
                            </td>
                            <td>
                                <button className='btn btn-custom btn-sm' onClick={() => _handleUnBlock(item.id)}>Gỡ bỏ</button>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        }
        else
            return <tbody>
                <tr>
                    <td colSpan={6}>Không có dữ liệu</td>
                </tr>
            </tbody>
    }
    return (
        <div>
            <CommonTable
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                paging={paging}
            />
        </div>
    );
};

export default BlackListUser;