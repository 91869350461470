import React, { useState, useEffect } from "react";
import { Notify } from "../../based/Notify";
import { NOTIFY, BANKACCOUNT_TYPE } from "../../based/Constants";
import TextBox from "../../based/refactor/Textbox";
import TFUSelect from "../../based/refactor/TFUSelect";
import CommonServices from "../../based/services/CommonServices";
import BankServices from "../../based/services/BankServices";
import useFormValidate from "../../hooks/useFormValidate";
import Loading from "../../based/Loading";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import LayoutLight from "../../layout/refactor/Layout/Layout";
import { Box ,BoxUser, BtnRounded } from "../../based/content/ct.elm";

const menuItem = ["Trang chủ", "Thông tin chuyển khoản"];
const initBankAccount = {
    id: 0,
    bankAccountName: "",
    bankAccountNumber: "",
    bankCode: 0,
    isActive: true,
    bankAccountType: BANKACCOUNT_TYPE.Bank,
    verified: false,
    remark: "",
};

const BankAccountsRefactor = () => {
    const [bankAccount, setBankAccount] = useState({ ...initBankAccount });
    const [momoAccount, setMomoAccount] = useState({ ...initBankAccount, bankAccountType: BANKACCOUNT_TYPE.Momo });

    const [bankList, setBankList] = useState([]);

    const { addRef, isValid } = useFormValidate();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    useEffect(() => {
        getBanks();
        getCreatorBankAccounts();
    }, []);

    const getBanks = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await CommonServices.GetBanks();
        if (!err && data) {
            var bankList = data.map((item) => {
                item.value = item.value;
                item.label = item.text;
                return item;
            });
            setBankList(bankList);
            setIsLoading(false);
            setMsg(null);
        } else {
            setIsLoading(false);
            setMsg(null);
        }
    };

    const getCreatorBankAccounts = async () => {
        const [err, data] = await BankServices.GetCreatorBankAccounts('');
        if (!err && data) {
            for (var ba of data) {
                if (ba.bankAccountType === BANKACCOUNT_TYPE.Bank) {
                    setBankAccount(ba);
                }
                if (ba.bankAccountType === BANKACCOUNT_TYPE.Momo) {
                    setMomoAccount(ba);
                }
            }
        }
    };

    const updateBankAccountHandler = async () => {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu...");
            var bankAccounts = [];
            if (bankAccount.bankCode && bankAccount.bankAccountName && bankAccount.bankAccountNumber) bankAccounts.push(bankAccount);
            if (momoAccount.bankAccountName && momoAccount.bankAccountNumber) bankAccounts.push(momoAccount);
            const [err, data] = await BankServices.UpdateCreatorBankAccounts(bankAccounts);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật thông tin thành công!");
                setIsLoading(false);
                setMsg(null);
            } else {
                Notify(NOTIFY.ERROR, "Thất bại", "Vui lòng kiểm tra lại đầy đủ thông tin và thử lại sau ít phút!");
                setIsLoading(false);
                setMsg(null);
            }
        }
    };

    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title='Thông tin chuyển khoản' />
            <Loading show={isLoading} msg={msg} />
            <Box style={{'height':'85%'}}>
                <BoxUser>
                        <div className='creator-bank-accounts'>
                            {/* Bank Account */}
                            <div className='rounded mb-3'>
                                <div className='mb-3 border-bottom'>
                                    <div className='d-flex align-items-center pb-3'>
                                        <h4 className='my-0'>Tài khoản ngân hàng</h4>
                                        <span className={`ml-2 mt-2 font-weight-bold ${bankAccount.verified ? "text-success" : "text-danger"}`}>({bankAccount.verified ? "Đã được xác thực" : "Chưa được xác thực"})</span>
                                    </div>
                                    <p className='text-danger'>* Vui lòng kiểm tra kỹ thông tin đã nhập là chính xác để Lotus có thể tiến hành kiểm tra và xác nhận trong thời gian sớm nhất!</p>
                                </div>
                                <div className='form-row'>
                                    <div className='form-group col-12'>
                                        <div className='form-row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='firstName' className='required'>
                                                    Ngân hàng:
                                                </label>
                                                <TFUSelect name='status' ref={addRef} value={bankAccount.bankCode} options={bankList} placeholder='Chọn ngân hàng ...' onChanged={(value) => setBankAccount({ ...bankAccount, bankCode: value })} />
                                            </div>
                                        </div>
                                        <div className='form-row'>
                                            <div className='form-group col-md-6'>
                                                <label htmlFor='bankAccountName' className='required'>
                                                    Tên tài khoản:
                                                </label>
                                                <TextBox required ref={addRef} name='bankAccountName' value={bankAccount.bankAccountName} className='form-control' onChanged={(name, value) => setBankAccount({ ...bankAccount, [name]: value })} />
                                            </div>
                                            <div className='form-group col-md-6'>
                                                <label htmlFor='bankAccountNumber' className='required'>
                                                    Số tài khoản:
                                                </label>
                                                <TextBox required ref={addRef} name='bankAccountNumber' value={bankAccount.bankAccountNumber} className='form-control' onChanged={(name, value) => setBankAccount({ ...bankAccount, [name]: value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Momo Account */}
                            <div className='rounded mb-3'>
                                <div className='mb-3 border-bottom'>
                                    <div className='d-flex align-items-center pb-3'>
                                        <h4 className='my-0'>Tài khoản ví Momo</h4>
                                        <span className={`ml-2 mt-2 font-weight-bold ${momoAccount.verified ? "text-success" : "text-danger"}`}>({momoAccount.verified ? "Đã được xác thực" : "Chưa được xác thực"})</span>
                                    </div>
                                    <p className='text-danger'>* Vui lòng kiểm tra kỹ thông tin đã nhập là chính xác để Lotus có thể tiến hành kiểm tra và xác nhận trong thời gian sớm nhất!</p>
                                </div>
                                <div className='form-row'>
                                    <div className='form-group col-12'>
                                        <div className='form-row'>
                                            <div className='form-group col-md-6'>
                                                <label htmlFor='bankAccountName' className='required'>
                                                    Tên tài khoản:
                                                </label>
                                                <TextBox ref={addRef} name='bankAccountName' value={momoAccount.bankAccountName} className='form-control' onChanged={(name, value) => setMomoAccount({ ...momoAccount, [name]: value })} />
                                            </div>
                                            <div className='form-group col-md-6'>
                                                <label htmlFor='bankAccountNumber' className='required'>
                                                    Số điện thoại đăng ký trên Momo:
                                                </label>
                                                <TextBox ref={addRef} name='bankAccountNumber' value={momoAccount.bankAccountNumber} className='form-control' onChanged={(name, value) => setMomoAccount({ ...momoAccount, [name]: value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className='rounded mb-3'>
                                <div className='form-row'>
                                    <div className='form-group col-12 mb-0'>
                                        <div className='d-flex align-items-start justify-content-end'>
                                            <BtnRounded color='#34d3eb' onClick={updateBankAccountHandler}>
                                               &nbsp; Lưu &nbsp;
                                            </BtnRounded>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </BoxUser>
            </Box>
            
        </React.Fragment>
    );
};

export default BankAccountsRefactor;
