import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import CommonTable from '../../based/CommonTable';
import { INITPAGING } from '../../based/Constants';
import Loading from '../../based/Loading';
import BaseServices from '../../based/services/BaseServices';
import DetailAffiliator from './DetailAffiliator';
import { Link } from "react-router-dom";

const SharingInteraction = (props) => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState('');
    const [users, setUsers] = useState([]);
    const [paging, setPaging] = useState(INITPAGING);
    const { thirdPartyId } = props.match.params;
    useEffect(() => {
        _fetchData(paging);
    }, [])
    const _fetchData = async (paging) => {
        if (!thirdPartyId) {
            setBusy(true);
            setBusyMsg('Đang tải dữ liệu...')
            const [err, data] = await BaseServices.Post('/api/affiliate/get-vip-affiliators', paging);
            if (!err && data) {
                setUsers(data.listObjects)
                setPaging(data.paging)
            }
            setBusy(false);
        }
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <td>STT</td>
                <td>Email</td>
                <td>Tên Affiliator</td>
                <td>Ngày tham gia VIP</td>
                <td>Quota</td>
                <td></td>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        if (users && users.length > 0) {
            return <tbody>
                {
                    users.map((item, idx) => {
                        return <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                                {item.email}
                            </td>
                            <td>{item.fullName}</td>
                            <td>
                                {item.joinedTime}
                            </td>
                            <td>
                                {item.quota}
                            </td>
                            <td>
                                <Link to={'/affiliate-vip/' + item.thirdPartyId} className='btn btn-custom btn-sm m-r-5' target='_blank'>Chi tiết</Link>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        }
        else
            return <tbody>
                <tr>
                    <td colSpan={6}>Không có affiliator</td>
                </tr>
            </tbody>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Thông kê lượng tương tác' parentPage='Ranus' hrefParent='/' />
            <Loading show={busy} msg={busyMsg} />
            {
                thirdPartyId > 0 ? <DetailAffiliator thirdPartyId={thirdPartyId} />
                    :
                    <CommonTable
                        renderHeader={_renderHeader}
                        renderBody={_renderBody}
                        paging={paging}
                        searchBasic
                        onFilter={_fetchData}
                    />
            }
        </React.Fragment>
    );
};
export default SharingInteraction;