import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import TFUImage from "../../based/inputs/Image";
import { ProductVariantContext } from "../../context/ProductVariantContext";
import { VariantImageModel } from "../../models/BaseProductModel";

const VariantImageConfig = (props) => {
    const { editingVariant, setEditingVariant, addRef, listWarningZone, setListWarningZone, listVariantImage, setListVariantImage } = useContext(ProductVariantContext);

    useEffect(() => {
        let variant = { ...editingVariant };
        let listImage = variant ? variant.listImage : [];
        let listZone = [];
        for (const image of listImage) {
            if (image.listVariantImage && image.listVariantImage.length > 0) listZone = [...listZone, ...image.listVariantImage];
        }
        if (listZone && listZone.length > 0) setListVariantImage(listZone);
        else setListVariantImage([]);
    }, [editingVariant]);

    const handleItem = (value, i) => {
        //list hiện tại
        let lst = [...listVariantImage];
        lst[i] = value;
        setListVariantImage(lst);
    };

    return (
        <React.Fragment>
            <div className="col-md-12 card-box p-3">
                <h5 className="header-title m-t-0">
                    <b>Tự thiết kế - Config Biến Thể Ảnh</b> <small>(pixel)</small>
                </h5>
                <p className="sub-header">
                    Note:
                    <br />
                    - Config các biến thể ảnh background
                    <br />- Thích hợp cho các sản phẩm như: Bình nước
                </p>
            </div>
            <div className="card-box margin-bottom-10">
                <div>
                    <table className="table table-bordered m-b-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="cursor-pointer center" width="200">
                                    Background hình ảnh chính<span className="required-field-icon">&nbsp;*</span>
                                </th>
                                <th className="cursor-pointer center" width="200">
                                    Lớp hình ảnh sản phẩm<span className="required-field-icon">&nbsp;*</span>
                                </th>
                                <th className="cursor-pointer center" width="200">
                                    Lớp cover sản phẩm<span className="required-field-icon">&nbsp;*</span>
                                </th>
                                <th className="cursor-pointer center" width="200">
                                    Index vị trí theo thứ tự<span className="required-field-icon">&nbsp;*</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listVariantImage &&
                                listVariantImage.map((item, i) => {
                                    return (
                                        <VariantImageItem
                                            item={item}
                                            setItem={(value) => {
                                                handleItem(value, i);
                                            }}
                                            index={i}
                                        />
                                    );
                                })}
                            <tr>
                                <td colSpan="999" className="text-right">
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            let newItem = new VariantImageModel();
                                            let lst = [...listVariantImage];
                                            lst.push(newItem);
                                            setListVariantImage(lst);
                                        }}
                                    >
                                        Thêm cấu hình
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default VariantImageConfig;

const VariantImageItem = ({ item = new VariantImageModel(), setItem = () => {}, index }) => {
    const { editingVariant, setEditingVariant, addRef, listVariantImage, setListVariantImage } = useContext(ProductVariantContext);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getOptions();
    }, [editingVariant]);

    const getOptions = () => {
        let listImage =
            editingVariant && editingVariant.listImage
                ? editingVariant.listImage.map((item) => ({
                      value: item.url,
                      label: item.url,
                      image: item.url,
                      imageTypeName: item.imageType == 27 ? "Mặt trước" : item.imageType == 28 ? "Mặt sau" : "",
                  }))
                : [];
        setOptions(listImage);
    };

    return (
        <Wrapper>
            <td className="text-center vertical-align-middle">
                <ReactSelect
                    value={item.url}
                    options={options}
                    formatOptionLabel={(country) => (
                        <div className="country-option">
                            <img src={country.image} alt="country-image" />
                            <span>{country.imageTypeName}</span>
                        </div>
                    )}
                    onChange={(selected) => {
                        setItem({ ...item, url: selected.value });
                    }}
                />
                {item.url && <img src={item.url} className="thumbnail-image" />}
            </td>
            <td className="text-center vertical-align-middle">
                <label className="btn btn-rounded btn-primary" htmlFor={`imageVariantUrl-${index}`}>
                    Tải ảnh biến thể lên
                </label>
                <TFUImage image={item.imageVariantUrl} ref={addRef} onChanged={(name, value) => setItem({ ...item, imageVariantUrl: value })} name={`imageVariantUrl-${index}`} className={"custom-file-input d-none"} id={`imageVariantUrl-${index}`} />
            </td>
            <td className="text-center vertical-align-middle">
                <label className="btn btn-rounded btn-primary" htmlFor={`coverUrl-${index}`}>
                    Tải ảnh cover lên
                </label>
                <TFUImage image={item.coverUrl} ref={addRef} onChanged={(name, value) => setItem({ ...item, coverUrl: value })} name={`coverUrl-${index}`} className={"custom-file-input d-none"} id={`coverUrl-${index}`} />
            </td>
            <td className="text-center vertical-align-middle">
                <input value={item.index} className="form-control" onChange={(e) => setItem({ ...item, index: e.target.value })} type="number" />
            </td>
        </Wrapper>
    );
};

const Wrapper = styled.tr`
    .image-preview {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }

    .image-preview img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .country-option {
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }

    .thumbnail-image {
        width: 60%;
        height: auto;
        object-fit: contain;
    }
`;
