import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components"
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import SortServices from "../based/services/SortServices";
import { Modal } from "react-bootstrap";
import Loading from "../based/Loading";
import ChangeSlotPositionModal from "./ChangeSlotPositionModal";



const ModalDetail = ({ isShowModal, onClose, slot, onSaveContinue }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [products, setProducts] = useState([])
    const [showChangeSlotModal, setShowChangeSlotModal] = useState(false);
    useEffect(() => {
        if (isShowModal && slot.order) {
            fetchSlotDetail(slot.order.orderId);
        }
    }, [slot]);

    const fetchSlotDetail = async (orderId) => {
        const [err, data] = await SortServices.GetOrderProducts(orderId);
        if (!err && data)
            setProducts(data);
    }

    const _handleSave = () => {

    }

    const handleCancelSlot = async () => {
        const [err, data] = await SortServices.CancelSlot(slot.id);
        if (!err && data)
            onSaveContinue()
        else{
            Notify(NOTIFY.ERROR, NOTIFY.ERROR , "Hủy khay lỗi")
        }
    }

    const _handleShowModalChangeSlot = async () => {
        setShowChangeSlotModal(true);
    }
    return (
        <Modal show={isShowModal} onHide={onClose} size={'md'} id={Common.generateGuid()} enforceFocus={false}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Chi tiết khay</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StyleModalDetail>
                    <div className="box-info">
                        <div className="item w-33">
                            <div className="desc">Mã ĐH</div>
                        </div>
                        <div className="item w-66">
                            <div className="code">{slot.order ? slot.order.orderId : ""}</div>
                        </div>
                        <div className="item center w-33">
                            <div className="desc">Khu</div>
                            <div className="count">{slot.areaName}</div>
                        </div>
                        <div className="item center w-33">
                            <div className="desc">Tầng</div>
                            <div className="count">{slot.row}</div>
                        </div>
                        <div className="item center w-33">
                            <div className="desc">Khay</div>
                            <div className="count">{slot.column}</div>
                        </div>
                        {
                            slot.order && <React.Fragment>
                                <div className={`item center w-50 ${slot.order.printedCount == slot.order.totalProductCount ? "enough" : "not_enough"}`}>
                                    <div className="desc">Đã sản xuất</div>
                                    <div className={`count ${slot.order.printedCount == slot.order.totalProductCount ? "enough" : "not_enough"}`}>{slot.order.printedCount}/{slot.order.totalProductCount}</div>
                                </div>
                                <div className={`item center w-50 ${slot.order.productInSlotCount == slot.order.totalProductCount ? "enough" : "not_enough"}`}>
                                    <div className="desc">Đã cho vào khay</div>
                                    <div className={`count ${slot.order.productInSlotCount == slot.order.totalProductCount ? "enough" : "not_enough"}`}>{slot.order.productInSlotCount}/{slot.order.totalProductCount}</div>
                                </div>
                                <div className="item center w-50">
                                    <div className="desc">Đặt in</div>
                                    <div className="">{new Date(slot.order.orderDate).toLocaleDateString()}</div>
                                </div>
                            </React.Fragment>
                        }
                        {/* <div className="item center w-50">
                        <div className="desc">Sản xuất</div>
                        <div className="">12:52 23/12/2023</div>
                    </div> */}
                    </div>
                    <div className="box-table">
                        <div className="title">Sản phẩm trong đơn</div>
                        <table>
                            <tr>
                                <th>Tên</th>
                                <th style={{ width: 150 }}>Mã sản xuất</th>
                                <th style={{ width: 150 }}>Ngày sản xuất</th>
                            </tr>
                            {
                                products && products.map((p, i) => {
                                    return <tr>
                                        <td>{p.productName}</td>
                                        <td>{p.barcode}</td>
                                        <td>{new Date(p.printingDate).toLocaleDateString()}</td>
                                    </tr>
                                })
                            }
                        </table>
                    </div>
                </StyleModalDetail>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <div className="box-button" style={{ display: "flex", justifyContent: 'space-between' }}>
                        <button className="btn-danger" onClick={()=>{
                            handleCancelSlot()
                        }}>Hủy khay</button>
                        <div>
                            <button className="btn-danger" onClick={onClose}>Đóng</button>
                            <button className="btn-custom ml-3" onClick={_handleShowModalChangeSlot}>Đổi vị trí</button>
                        </div>
                    </div>
                </div>
                <ChangeSlotPositionModal
                    isShow={showChangeSlotModal}
                    onClose={()=> setShowChangeSlotModal(false)}
                    slotDetail={slot}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDetail;
const StyleModalDetail = styled.div`
    .box-button{
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .box-table{
        margin-top: 15px;

        .title{
            color: #444;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        table{
            width: 100%;
            margin-top: 15px;

            th{
                text-align: center;
                border: 1px solid #EDEDED;
                background: #878787;
                color: #FFF;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                padding: 7px;
            }
            td{
                padding: 7px;
                color: #000;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border: 1px solid #EDEDED;
                background: #FFF;
            }
        }
    }

    .box-info{

        display: flex;
        flex-wrap: wrap;

        .item{
            border: 1px solid #D9D9D9;
            padding: 5px;
            &.center{
                text-align: center;
            }

            .desc{
                color: #676767;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;

            }
            .code{
                color: #28B259;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
            }
            
            .count{
                font-size: 50px;
                font-weight: 700;
                line-height: 1.3;
                color: #676767;

            }
            &.not_enough{
                background-color: #FFDFDF;
                .count{
                    color: #D70000;
                }
            }
            &.enough{
                background-color: #DFFFE0;
                .count{
                    color: #28B259;
                }
            }
        }

        .w-33{
            width: 33.3333333%;
            min-width: 33.3333333%;
        }
        .w-66{
            width: 66.6666666%;
            min-width: 66.6666666%;
        }
        .w-50{
            width: 50%;
            min-width: 50%;
        }
    }
`

ModalDetail.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
