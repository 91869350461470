import axios from "axios";
import cookie from "react-cookies";
import Common from "./Common";

const Request = {
    APIUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://api.ranus.vn" : "https://localhost:6004";
    },
    CDNUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://cdn.ranus.vn" : "https://localhost:6001";
    },
    HomeUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://ranus.vn" : "https://localhost:5000";
    },
    CreatorUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://creator.ranus.vn" : "https://localhost:7000";
    },
    AdminUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://admin.ranus.vn" : "https://localhost:7000";
    },
};

const apiUrl = Request.APIUrl();
const cdnUrl = Request.CDNUrl();
const homeUrl = Request.HomeUrl();
var token = cookie.load("token");
const instance = axios.create({ baseURL: apiUrl });
instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
instance.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
const CancelToken = axios.CancelToken;
let cancel;
var RequestMTO = {
    API_URL: apiUrl,
    HOME_URL: homeUrl,
    CdnURL: function () {
        return cdnUrl;
    },
    Get: function (url) {
        return new Promise((resolve, reject) => {
            instance
                .get(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response && error.response.status === 406) {
                        window.location.href = "/not-acceptable";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },

    async GetAsync(url, data) {
        var result = await instance.get(url, data);
        return result;
    },

    Post: function (url, data) {
        return new Promise((resolve, reject) => {
            instance
                .post(url, data, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response && error.response.status === 406) {
                        window.location.href = "/not-acceptable";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },

    async PostAsync(url, data) {
        var result = await instance.post(url, data);
        return result;
    },

    Put: function (url, data) {
        return new Promise((resolve, reject) => {
            instance
                .put(url, data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },

    async PutAsync(url, data) {
        var result = await instance.put(url, data);
        return result;
    },

    Delete: function (url) {
        return new Promise((resolve, reject) => {
            instance
                .delete(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },

    async DeleteAsync(url, data) {
        var result = await instance.delete(url, data);
        return result;
    },

    UploadImage: function (file, imageType = 0) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/upload-image/${imageType}`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            data.append("file", file);
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },

    CompressImage: function (filePath) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${cdnUrl}/api/images/compress-image`);
            xhr.setRequestHeader("client", "tfu_admin");
            if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
            const data = new FormData();
            data.append("filePath", filePath);
            xhr.send(data);
            xhr.addEventListener("load", () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener("error", () => {
                const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                reject(error);
            });
        });
    },

    DeleteImage: function (fileName) {
        instance.defaults.headers["client"] = "tfu_admin";
        if (token) instance.defaults.headers["Authorization"] = "Bearer " + token;
        return new Promise((resolve, reject) => {
            instance
                .post(`${cdnUrl}/api/images/delete-image/${fileName}`, fileName)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    UploadSlide: function (file) {
        if (file) {
            let type = file.type;
            if (type && (type == "application/vnd.openxmlformats-officedocument.presentationml.presentation" || type == "application/vnd.ms-powerpoint")) {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", `${cdnUrl}/api/media/upload-presentation`);
                    const data = new FormData();
                    data.append("file", file);
                    xhr.send(data);
                    xhr.addEventListener("load", () => {
                        const response = JSON.parse(xhr.responseText);
                        resolve(response);
                    });
                    xhr.addEventListener("error", () => {
                        const error = JSON.parse(xhr.responseText);
                        reject(error);
                    });
                });
            } else {
                return new Promise((resolve, reject) => {});
            }
        }
    },

    ImportExcel: function (file, importUrl) {
        if (file) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", apiUrl + importUrl);
                xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                xhr.setRequestHeader("client", "tfu_admin");
                const data = new FormData();
                data.append("file", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener("error", () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            });
        }
    },
    UploadDocument: function (file, documentType) {
        if (file) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${cdnUrl}/api/images/upload-document?type=${documentType}`);
                xhr.setRequestHeader("client", "tfu_admin");
                if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
                const data = new FormData();
                data.append("file", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener("error", () => {
                    const error = xhr.responseText && xhr.responseText.length > 0 ? JSON.parse(xhr.responseText) : null;
                    reject(error);
                });
            });
        }
    },
    DeleteDocument: function (fileName) {
        instance.defaults.headers["client"] = "tfu_admin";
        if (token) instance.defaults.headers["Authorization"] = "Bearer " + token;
        return new Promise((resolve, reject) => {
            instance
                .post(`${cdnUrl}/api/images/delete-document?fileName=${fileName}`, null)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    PreviewDocument: function (fileName, createdBy) {
        instance.defaults.headers["client"] = "tfu_admin";
        if (token) instance.defaults.headers["Authorization"] = "Bearer " + token;
        return new Promise((resolve, reject) => {
            instance
                .get(`${cdnUrl}/api/images/preview-document?fileName=${fileName}&creatorName=${createdBy}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    DownloadTemplate: function (fileName) {
        if (fileName) {
            window.location.href = `${cdnUrl}/api/images/download-template/` + fileName;
        }
    },
    ExportExcel: function (obj) {
        if (obj) {
        }
    },
    DownloadFile: function (url) {
        let instanceDownload = axios.create({ baseURL: apiUrl });
        instanceDownload.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        instanceDownload.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
        instanceDownload.defaults.responseType = "blob";
        return new Promise((resolve, reject) => {
            instanceDownload
                .get(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        Common.RemoveToken();
                        window.location.href = "/login?redirect=" + window.location.href;
                    } else if (error.response && error.response.status === 302) {
                        window.location.href = "/confirm-email";
                    } else if (error.response && error.response.status === 403) {
                        window.location.href = "/access-denied";
                    } else if (error.response) {
                        reject(error.response.data);
                    }
                });
        });
    },
    CancelRequest: function () {
        if (cancel) cancel();
    },
};
export default RequestMTO;
