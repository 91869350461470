import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import SortServices from "../based/services/SortServices";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};
const model = {
    id: 0,
    nameArea: "",
    columnCount: 0,
    floorCount: 0,
}
const AreaManagement = () => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);

    const [dataBackup , setDataBackup] = useState({});

    useEffect(() => {
        setBusy(false);
        getData();
    }, []);

    async function getData() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await SortServices.GetArea();
        if (!err && data) {
            setData([...data]);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Khu</th>
                    <th>Tầng</th>
                    <th>Cột</th>
                    <th>Số đơn hàng trong khay</th>
                    <th>Khay trống</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }


    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Tên khu"
                                            value={item.nameArea}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].nameArea = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.nameArea
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="number"
                                            placeholder="Số tầng"
                                            min={0}
                                            value={item.floorCount}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].floorCount = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.floorCount
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="number"
                                            placeholder="Số cột"
                                            value={item.columnCount}
                                            min={0}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].columnCount = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.columnCount
                                    )}
                                </td>
                                <td style={{ width: 250 }}>
                                    {
                                       0
                                    }
                                </td>
                                <td style={{ width: 250 }}>
                                   {
                                       0
                                    }
                                </td>
                                <td style={{ width: 250, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                for (let i = 0; i < dt.length; i++) {
                                                    if(dt[i].isUpdate) {
                                                        dt[i].nameArea = dataBackup.nameArea;
                                                        dt[i].floorCount = dataBackup.floorCount;
                                                        dt[i].columnCount = dataBackup.columnCount;
                                                    }
                                                    if(i === idx) {
                                                        dt[i].isUpdate = true;
                                                    }else{
                                                        dt[i].isUpdate = false;
                                                    }
                                                }
                                                setData(dt);
                                                setDataBackup(Object.assign({}, item))
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm khu +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {}

    async function handleAddItem() {
        setData([ ...data,{ ...model }]);
    }   

    function IsDisable(idx) {
        if (!data[idx].nameArea || !data[idx].floorCount|| !data[idx].columnCount) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await SortServices.CreateUpdate({
            id: dataItem.id,
            nameArea: dataItem.nameArea,
            shelfs:[
                {
                    id: 0,
                    areaId: dataItem.id,
                    name: "",
                    rowCount: dataItem.floorCount,
                    columnCount: dataItem.columnCount
                }
            ]
        });
        if (!err ) {
            if(dt == 1){
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            }
            if(dt == -2){
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Trùng tên khu");
            }
            if(dt == -8){
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Không thể sửa do còn tồn tại sản phẩm tronng slot");
            }
            getData();
            setBusy(false);

        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            getData();
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            let ids = [];
            ids.push(id);
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await SortServices.DeleteArea(ids);
                    if (!error && data) {
                        if(data === 1)
                        {
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                        }
                        if(data === -8)
                        {
                            Notify(NOTIFY.WARNING, "Lưu ý", "Không thể xóa do còn tồn tại sản phẩm tronng slot");
                        }
                        getData();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                        getData();
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={data} paging={paging} 
            hideSearchBasicButton renderAction={_renderAction} 
            renderHeader={_renderHeader} renderBody={_renderBody} 
            ref={tableRef}/>
        </StyleBody>
    );
};
const StyleBody = styled.div`
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }
`;
export default AreaManagement;
