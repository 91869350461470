import React, { useEffect, useMemo, useState } from "react";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import styled, { createGlobalStyle } from "styled-components";
import Common from "../based/Common";
import { PAYMENT_METHOD, SHIPPING_SERVICES_CODE } from "../based/Constants";
import OrderServices from "../based/services/OrderServices";
import ShopeeXpressSVG from "./assets/shopee_express.svg";

export const ShippingLabel = (props) => {
    const { orderId } = props.match.params;
    const [content, setContent] = useState({});
    const [printInfo, setPrintInfo] = useState({ barcode: "0" });
    const [shippingServiceCode, setShippingServiceCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPrintInfo(orderId);
    }, [orderId]);

    const getPrintInfo = async (orderId) => {
        setIsLoading(true);
        let [err, data] = await OrderServices.GetRanusShippingLabel(orderId);
        if (!err && data) {
            let ct = { ...data };
            if (ct.shippingOrderNumber) ct.url = ct.shippingOrderNumber;
            if (ct.order && ct.order.shippingCompanyCode == SHIPPING_SERVICES_CODE.SHOPEEXPRESS) {
                let sortCode = ct.order.sortingCode;
                if (sortCode) {
                    let split = sortCode.split(",");
                    if (split && split.length > 2) {
                        ct.order.deliveryCode = split[0];
                        ct.order.areaDeliveryCode = split[1];
                        ct.order.returnCode = split[2];
                    }
                }
            }

            setContent(ct);

            if (ct.order) {
                setPrintInfo({ ...printInfo, barcode: String(ct.order.id) });
                setShippingServiceCode(ct.order.shippingCompanyCode);
            }
        } else {
            setContent({});
        }
        setIsLoading(false);
    };

    const countTotalItem = (products) => {
        let count = 0;
        if (products && products.length > 0) {
            products.map((item) => {
                count += item.quantity;
            });
        }
        return count;
    };

    const codPrice = useMemo(() => {
        let price = content.order && content.order.finallyPrice && content.order.paymentMethod == PAYMENT_METHOD.COD ? content.order.finallyPrice : 0;
        if (content.order && content.order.transferPartial > 0 && (content.order.paymentMethod == PAYMENT_METHOD.COD || content.order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER)) {
            price = content.order.finallyPrice <= content.order.transferPartial ? 0 : content.order.finallyPrice - content.order.transferPartial;
        }
        return price;
    }, [content]);

    function BarcodeSection() {
        switch (shippingServiceCode) {
            case SHIPPING_SERVICES_CODE.SHOPEEXPRESS:
                return (
                    <React.Fragment>
                        <div className="header-right-top">
                            <Barcode value={content.order ? String(content.order.shippingOrderNumber) : "000000000"} height={100} fontSize={30} width={4} format="CODE128" />
                        </div>
                        <div className="header-right-top">
                            <Barcode value={content.order ? String(content.order.trackingNumber) : "000000000"} height={100} fontSize={30} width={4} format="CODE128" />
                        </div>
                    </React.Fragment>
                );
            case SHIPPING_SERVICES_CODE.VNPOST:
                return (
                    <React.Fragment>
                        <div
                            className="header-right-top"
                            style={{
                                height: "33.33%",
                            }}
                        >
                            <Barcode value={content.order ? String(content.order.shippingOrderNumber) : "000000000"} height={100} fontSize={30} width={4} format="CODE128" />
                        </div>
                        <div
                            className="header-right-top"
                            style={{
                                height: "33.33%",
                            }}
                        >
                            <Barcode value={content.order ? String(content.order.trackingNumber) : "000000000"} height={100} fontSize={30} width={4} format="CODE128" />
                        </div>
                        <div
                            className="header-right-bottom"
                            style={{
                                height: "33.33%",
                            }}
                        >
                            <Barcode value={content.order ? String(content.order.sortingCode) : "000000000"} height={100} fontSize={30} width={4} format="CODE128" />
                        </div>
                    </React.Fragment>
                );

            default:
                return (
                    <React.Fragment>
                        <div className="header-right-top">
                            <Barcode value={content.order ? String(content.order.shippingOrderNumber) : "000000000"} height={100} fontSize={30} width={4} format="CODE128" />
                        </div>
                    </React.Fragment>
                );
        }
    }

    return (
        <>
            <GlobalStyle />
            <ShippingWrapper id="section-to-print">
                <div className="border-box">
                    <div className="header-logo">
                        <div className="header-left">
                            <div className="header-left-top">
                                <img src="./images/Logo-01.png" alt="Ranus" />
                            </div>
                            {shippingServiceCode == SHIPPING_SERVICES_CODE.SHOPEEXPRESS && (
                                <div className="header-left-bottom">
                                    <img src={ShopeeXpressSVG} />
                                </div>
                            )}
                        </div>
                        <div className="header-right">{BarcodeSection()}</div>
                        <div className="header-order-id">{content.order && content.order.orderNumber ? content.order.orderNumber : ""}</div>
                    </div>
                    <hr />
                    {shippingServiceCode == SHIPPING_SERVICES_CODE.SHOPEEXPRESS && (
                        <>
                            <div className="header-info">
                                <div className="header-info-detail">ĐƠN NGOÀI SÀN</div>
                            </div>
                            <hr />
                        </>
                    )}
                    {content.order && content.order.deliveryCode && (
                        <>
                            <div className="departure-sortcode">
                                {content.order && content.order.deliveryCode && <div className="departure-sortcode-left">{content.order.deliveryCode}</div>}
                                {content.order && content.order.areaDeliveryCode && <div className="departure-sortcode-right">{content.order.areaDeliveryCode}</div>}
                            </div>
                            <hr />
                        </>
                    )}
                    <div className="address-body">
                        <div className="address-body-left">
                            <div className="address-body-top">Từ: Ranus.vn</div>
                            <div className="address-body-middle">75 đường số 2, KDC Cityland Parkhills, P10, Gò Vấp, HCM</div>
                            <div className="address-body-bottom">Hotline: 0898 000 004</div>
                        </div>
                        <div className="address-body-right">
                            <div className="address-body-top">Đến: {content.shippingAddress && content.shippingAddress.userName ? content.shippingAddress.userName : ""}</div>
                            <div className="address-body-middle">{content.shippingAddress && content.shippingAddress.fullAddress ? content.shippingAddress.fullAddress : ""}</div>
                            <div className="address-body-bottom">SĐT: {content.shippingAddress && content.shippingAddress.phoneNumber ? content.shippingAddress.phoneNumber : ""}</div>
                        </div>
                    </div>
                    <hr />
                    <div className="price-body">
                        <div className="price-body-left">
                            <div className="price-body-top">Tiền thu người nhận:</div>
                            <div className="price-body-middle">{codPrice > 0 ? Common.formatCurrency(codPrice) : 0}</div>
                            <div className="price-body-bottom">*Kiểm tra hàng trước khi nhận</div>
                            {content.order && content.order.packageCost > 0 && <div className="gift-box">Hộp quà tặng</div>}
                        </div>
                        <div className="price-body-right">
                            <div className="price-body-first">
                                <QRCode value={content.order ? String(content.order.trackingNumber) : "000000000"} />
                            </div>
                            {content.order && content.order.returnCode && <div className="price-body-second">{content.order.returnCode}</div>}
                        </div>
                    </div>
                    <hr />

                    <div className="order-content">
                        {content.gifts && content.gifts.length > 0 && (
                            <React.Fragment>
                                <div>
                                    Quà tặng Sticker ({content.gifts.length}):{" "}
                                    {content.gifts.map((item, idx) => {
                                        return (
                                            <span key={idx}>
                                                {item.giftName} x{item.quantity}
                                            </span>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        )}
                        <div className="order-gift-sticker"></div>
                        <div className="order-content-top">Nội dung đơn hàng (Tổng số lượng {countTotalItem(content.products)})</div>
                        <div className="order-content-detail">
                            {content.products &&
                                content.products.length > 0 &&
                                content.products.map((item, idx) => {
                                    return (
                                        <p key={idx}>
                                            {idx + 1}. {Common.ProductAttributeDisplay(item.name, item.attributeValueLightModels, item.colorDisplayName)} - SL:{item.quantity}
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </ShippingWrapper>
        </>
    );
};

const GlobalStyle = createGlobalStyle`
@media print {
  body {
    visibility: hidden;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  @page{
      size: 4.13in 6in;
    }
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}

div#section-to-print {
        width: 600px;
        height: 852px;
        margin: 0 auto 30px;
        padding: 12px;
        background: white;
    }
    #section-to-print hr {
        border: 1px dashed;
        margin: 0;
    }
`;

const ShippingWrapper = styled.div`
    font-family: "Lexend";

    .border-box {
        width: 100%;
        height: 100%;
        border: 1px solid;
        border-bottom: none;
    }

    .header-logo {
        height: 140px;
        display: flex;
        margin-bottom: 10px;
    }

    .header-left {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .header-right {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
    }

    .header-left-top img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-left-bottom img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .header-right-bottom svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-right-bottom {
        height: 50%;
    }
    .header-left-top {
        height: 50%;
    }

    .header-left-bottom {
        height: 50%;
    }

    .header-right-top {
        height: 50%;
    }

    .header-right-top svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-info-detail {
        color: #000;
        font-family: Lexend;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }

    .header-info {
        text-align: center;
        padding: 3px 0;
    }

    .departure-sortcode {
        display: flex;
        width: 100%;
    }

    .departure-sortcode-left {
        width: 50%;
        color: #000;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding: 0 0 0 8px;
        border-right: 1px dashed;
    }

    .departure-sortcode-right {
        width: 50%;
        color: #000;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding: 0 0 0 8px;
    }

    .address-body {
        display: flex;
    }

    .address-body-left {
        width: 50%;
        padding: 5px 0 5px 8px;
        border-right: 1px dashed;
    }

    .address-body-right {
        width: 50%;
        padding: 5px 8px 5px 8px;
    }

    .address-body-top {
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .address-body-middle {
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .address-body-bottom {
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .price-body {
        display: flex;
    }

    .price-body-left {
        width: 50%;
        border-right: 1px dashed;
        padding: 5px 0 5px 8px;
    }

    .price-body-right {
        width: 50%;
    }

    .price-body-first {
        border-right: 1px dashed;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price-body-first svg {
        height: 134px;
        width: 134px;
        object-fit: cover;
        padding: 12px;
    }

    .price-body-top {
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .price-body-middle {
        color: #000;
        font-family: Lexend;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }

    .price-body-bottom {
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .order-content-top {
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .order-content {
        padding: 10px 0 10px 8px;
        height: auto;
    }

    .order-content-detail {
        margin-top: 12px;
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .order-content-detail p {
        margin-bottom: 12px;
    }
    .header-order-id {
        display: flex;
        background: #000;
        color: #fff;
        font-family: Lexend;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        right: 0;
        top: 0;
        padding: 3px 6px;
    }

    .header-logo {
        position: relative;
    }

    .border-box {
        width: auto !important;
    }

    .price-body-left {
        position: relative;
    }

    .gift-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        color: #fff;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 3px 3px 3px 6px !important;
    }
`;
