import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import InstructServices from "../based/services/InstructServices";
import ModalAddOrUpdateLink from "./ModalAddOrUpdateLink"
import ModalAddOrUpdateStep from "./ModalAddOrUpdateStep"
import ModalAddOrUpdateContent from "./ModalAddOrUpdateContent"
import DragAndDropIndex from "../based/DragAndDropIndex";
const initPage = {
    pageSize: 10,
    pageNumber: 1,
};


function FolderProductPrototype() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModalLink, setIsShowModalLink] = useState(false);
    const [isShowModalStep, setIsShowModalStep] = useState(false);
    const [isShowModalContent, setIsShowModalContent] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [linkId , setLinkId] = useState()

    const [step, setStep] = useState([]);
    const tableStepRef = useRef(null);

    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await InstructServices.GetLinkByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                    </th>
                    <th className="cursor-pointer">Tên hướng dẫn</th>
                    <th className="cursor-pointer">Đường dẫn</th>
                    <th className="">Loại</th>
                    <th className="">Trạng thái ẩn</th>
                </tr>
            </thead>
        );
    }
    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await InstructServices.DeleteLink(ids);
                    if (!error && data) {
                        setBusy(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Chưa chọn hướng dẫn");
    };
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom mb-3"
                    onClick={() => {
                        setLinkId(0)
                        setIsShowModalLink(true);
                    }}
                >
                    Thêm mới
                </button>
                <button onClick={()=>{
                    setIsShowModalLink(true);
                }} className={dataChecked && dataChecked.length > 0 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom ml-3 mb-3 disable"}>
                    Sửa
                </button>
                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger mb-3 ml-3" : "btn btn-danger ml-3 mb-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }
    function _renderBody() {
        return (
            data && data.length > 0 ?
            <tbody>
                {data.map((item, idx) => {
                    return (
                        <tr key={idx}>
                            <td>
                                {" "}
                                <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => {
                                    let dt = [...data];
                                    dt.map((x) => (x.selected = false));
                                    dt[idx].selected = value;
                                    setData(dt);
                                    if(value){
                                        setLinkId(item.id)
                                    }else{
                                        setLinkId(0);
                                    }
                                }} />
                            </td>
                            <td style={{ width: 200, textAlign: "center" }}>
                                <strong>{item.name}</strong>
                            </td>
                            <td style={{ width: 200, textAlign: "center" }}>
                                <strong>{item.url}</strong>
                            </td>
                            <td style={{ width: 200, textAlign: "center" }}>
                                <strong>{item.typeScreen === 1 ? "Desktop" : "Mobile"}</strong>
                            </td>
                            <td style={{ width: 50, textAlign: "center" }}>
                                <strong>{item.isHidden ? "Đang ẩn" : "Hiển thị"}</strong>
                            </td>
                        </tr>
                    )
                })}
                
            </tbody>
            : 
            <tbody>
                <tr>
                    <td colSpan={999} className="text-center">
                        Không có dữ liệu!
                    </td>
                </tr>
            </tbody>
        );
    }








    //Step Instruct
    const [stepId , setStepId] = useState()

    useEffect(() => {
        getStep()
    },[linkId])

    const getStep = async() =>{
        setBusy(true);
        let [err, data] = await InstructServices.GetStepByLinkId(linkId);
        if (!err && data) {
            setStep(data);
            setBusy(false);
        } else {
            setStep([]);
            setBusy(false);
        }
    }
    
    function _renderHeaderStep() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>Bước</th>
                    <th>Tên bước</th>
                    <th>Hành động click</th>
                    <th>Hiển thị nếu element này xuất hiện</th>
                    <th>Selector</th>
                    <th>Nội dung</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }
   
    function _renderActionStep() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className={`btn btn-custom mb-3 ${dataChecked && dataChecked.length > 0 ? "" : "disabled"}`}
                    onClick={ async() => {
                        await setStepId(0)
                        setIsShowModalStep(true);
                    }}
                >
                    Thêm bước +
                </button>
               
            </React.Fragment>
        );
    }
    const handleRemoveStep = async (id) =>{
        let ids= []
        ids.push(id)


        ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn xóa bước này ", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                let [err, data] = await InstructServices.DeleteStep(ids);
                if (!err && data) {
                    getStep()
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    setBusy(false);
                } else {
                    setBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa lỗi");
                }  
            });
        });


        
    }

    function renderRow(item, idx){
        return (
            <React.Fragment>
                <td style={{width: 40,textAlign: 'center' , cursor: 'move', verticalAlign:'middle'}}>
                                <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1H12.5" stroke="#565656"/>
                                    <path d="M0 5.5H12.5" stroke="#565656"/>
                                    <path d="M0 10H12.5" stroke="#565656"/>
                                </svg>
                            </td>
                            <td style={{ width: 50, textAlign: "center" }}>
                               {idx + 1}
                            </td>
                            <td style={{ width: 150, textAlign: "center" }}>
                               {item.title}
                            </td>
                            <td style={{ width: 50, textAlign: "center" }}>
                               {item.isAction ? "true" : "false"}
                            </td>
                            <td style={{ width: 150, textAlign: "center" }}>
                               {item.showIfExistElement}
                            </td>
                            <td style={{ width: 150, textAlign: "center" }}>
                               {item.xPath}
                            </td>
                            <td style={{ width: 300, textAlign: "center" , background: "#767676",color:"#fff" }}>
                               {
                                item.contents && item.contents.length > 0 ? 
                           
                                    item.contents.map((x, i) => {
                                        return (
                                            <div style={{display: 'flex', marginBottom: 5}} key={i}>
                                                {x.icon ? <img style={{width: 20 , display:'inline-block', aspectRatio: 1/1 , objectFit: "contain"}} src={x.icon}/> : ""}
                                                <span style={{width: 'calc(100% - 20px)' , display:'inline-block', textAlign: 'left', paddingLeft: 5}}>{x.content}</span>
                                            </div>
                                        )
                                    })
                              

                                : "Chưa có nội dung"
                               } 
                            </td>
                            <td style={{ width: 150, textAlign: "center" }}>
                                <div>
                                    <button className="btn btn-custom"
                                onClick={async()=>{
                                        await setStepId(item.id)
                                        setIsShowModalStep(true);
                                    }}>Sửa</button>
                                <button className="btn ml-3 btn-danger"
                                onClick={()=>{
                                    handleRemoveStep(item.id)
                                }}
                                >Xóa</button>
                                </div>
                                <button className="btn mt-2 btn-custom"
                                    onClick={async()=>{
                                        await setStepId(item.id)
                                        setIsShowModalContent(true);
                                }}>Sửa nội dung</button>
                            </td>
        </React.Fragment>
        )
    }

    async function UpdateIndexStepInLink(id, newIndex) {
        setBusy(true);
        let [err, data] = await InstructServices.UpdateIndexStepInLink({
            instructLinkId: linkId,
            id, 
            newIndex
        });
        if (!err && data) {
            getStep()
            setBusy(false);
        } else {
            setBusy(false);
        }
    }

    function _renderBodyStep() {
        return (
            <DragAndDropIndex data={step} renderRow={renderRow} updateIndexFolder={UpdateIndexStepInLink} />

        );
    }
   




    return (
        <Wapper>
            <Breadcrumbs currentPage="Quản lý hướng dẫn" />
            <Loading show={isBusy} msg={msg} />
            <div className="body-page">
                <div className="folder-list">
                    <CommonTable data={data}
                    paging={paging} ref={tableRef}  
                    hideSearchBasicButton 
                    renderAction={_renderAction} 
                    renderHeader={_renderHeader} 
                    renderBody={_renderBody}
                    onFilter={(paging)=>getData(paging)}
                    />
                </div>
                <div className="product-list">
                    <CommonTable data={step} 
                    ref={tableStepRef}  hideSearchBasicButton 
                    renderAction={_renderActionStep} 
                    renderHeader={_renderHeaderStep} 
                    renderBody={_renderBodyStep} />

                </div>
            </div>
            {isShowModalLink && <ModalAddOrUpdateLink 
                isShowModal={isShowModalLink}
                onClose={() => {
                    setIsShowModalLink(false);
                }}
                onSaveContinue={() => {
                    setIsShowModalLink(false);
                    getData(initPage)
                }}
                id={linkId} 
            />}
            {isShowModalStep && <ModalAddOrUpdateStep 
                isShowModal={isShowModalStep}
                onClose={() => {
                    setIsShowModalStep(false);
                }}
                onSaveContinue={() => {
                    setIsShowModalStep(false);
                    getStep()
                }}
                id={stepId} 
                idLink={linkId}
            />}
            {isShowModalContent && <ModalAddOrUpdateContent 
                isShowModal={isShowModalContent}
                onClose={() => {
                    setIsShowModalContent(false);
                    getStep()
                }}
                onSaveContinue={() => {
                    setIsShowModalContent(false);
                    getStep()
                }}
                stepId={stepId} 
            />}
        </Wapper>
        
    )
}
const Wapper = styled.div`
    .cursor-move{
        cursor: move;
    }
    .body-page{
        display: flex;
        .folder-list{
            width: 30%;

           
        }
        .product-list{
            width: 70%;
            padding-left: 10px;

            table{
                tr{
                    td{
                        img{
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }
                }
            }

        }
    }
`
export default FolderProductPrototype;