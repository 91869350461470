import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY, SHIPPING_SERVICES } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ReturnRequestService from "../based/services/ReturnRequestService";
import useFormValidate from "../hooks/useFormValidate";

const TicketReturnOrderChangeStatusModal = (props) => {
    const { isShowModal, returnOrderTicket, nextStatus, onClose, onDone } = props,
        [modalLoading, setModalLoading] = useState({ isLoading: false, msgLoading: "" }),
        [shippingServiceFee, setShippingServiceFee] = useState([]),
        [shippingCompanyId, setShippingCompanyId] = useState(null),
        { addRef, isValid } = useFormValidate();

    useEffect(() => {
        calculateShippingFeeByTicketId();
    }, []);

    const calculateShippingFeeByTicketId = async () => {
        setModalLoading({ isLoading: true, msgLoading: "Đang lưu dữ liệu..." });
        const [err, data] = await ReturnRequestService.CalculateShippingFeeByTicketId(returnOrderTicket.id);
        if (!err && data && data.length > 0) {
            var newShippingServiceFee = data.map((item) => {
                item.value = SHIPPING_SERVICES[item.shippingCompanyCode];
                item.label = `${item.shippingCompanyCode} - ${Common.formatCurrency(item.shippingFee)}`;
                return item;
            });
            setShippingServiceFee(newShippingServiceFee);
        } else Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        setModalLoading({ isLoading: false, msgLoading: "" });
    };

    const handleSave = async () => {
        if (isValid()) {
            setModalLoading({ isLoading: true, msgLoading: "Đang lưu dữ liệu..." });
            const [err, data] = await ReturnRequestService.ChangeTicketStatus(returnOrderTicket.id, nextStatus, shippingCompanyId);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", CONSTANTS.MSG_SUCCESS);
                onDone();
            } else Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
            setModalLoading({ isLoading: false, msgLoading: "" });
        }
    };

    return (
        <CommonModal
            size="xl"
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={modalLoading.isLoading}
            busyMsg={modalLoading.msgLoading}
            title="Chọn đơn vị vận chuyển"
            classModalBody="return-order-change-status-modal"
            onClose={() => onClose()}
            customButton={
                <button className="btn btn-custom btn-sm" onClick={handleSave}>
                    <i className="fa fa-save m-r-5" /> Lưu
                </button>
            }
        >
            <div className="form-group">
                <label htmlFor="externalShippingServiceId" className="required">
                    Loại hình vận chuyển:
                </label>
                <TFUSelect ref={addRef} required id="shippingCompanyId" name="shippingCompanyId" placeholder="Chọn Loại hình vận chuyển" options={shippingServiceFee} value={shippingCompanyId} onChanged={(value) => setShippingCompanyId(value)} />
            </div>
        </CommonModal>
    );
};

TicketReturnOrderChangeStatusModal.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    returnOrderTicket: PropTypes.object.isRequired,
    nextStatus: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
};

export default TicketReturnOrderChangeStatusModal;
