import { POSITION_LEVEL } from "../based/Constants";
import { PositionModel } from "../models/PositionModel";
import { ArtworkPositionModel } from "./ArtworkPositionModel";

export class OperatorOrderTicket {
    constructor() {
        this.brandName = "";
        this.creatorName = "";
        this.productName = "";
        this.attributeValues = [];
        this.logoPosition = new PositionModel();
        this.artworkPosition = new ArtworkPositionModel();
        this.customerName = "";
        this.printingDate = new Date();
        this.printingBatch = 0;
        this.orderQuantity = 0;
        this.orderId = 0;
        this.logoPositionLevel = POSITION_LEVEL.A
    }
}
