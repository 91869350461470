import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import AccountServices from "../based/services/AccountServices";

var token = Common.GetToken();

const STATUS_CONFIRM = {
    FALED: 0,
    SUCCESSFULLY: 1,
    CONFIRM: 2,
};
export default function ConfirmEmail(props) {
    const [statusConfirm, setStatusConfirm] = useState(STATUS_CONFIRM.CONFIRM);
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");

    const [email, setEmail] = useState("");

    useEffect(() => {
        if (window.location.search) confirm();
        else if (!token) props.history.push("/login");
    }, []);
    async function confirm() {
        setIsBusy(true);
        setMsg("Đang xác thực....");
        let [error, result] = await AccountServices.ConfirmEmail(window.location.search);
        if (!error) {
            setIsBusy(false);
            setMsg("Xác thực thành công. Vui lòng đăng nhập để sử dụng và trải nghiệm hệ thống quản trị");
            setStatusConfirm(STATUS_CONFIRM.SUCCESSFULLY);
        } else {
            setIsBusy(false);
            setMsg("Hiện tại link xác thực đã hết giá trị sử dụng. Xin vui lòng đăng ký lại");
            setStatusConfirm(STATUS_CONFIRM.FALED);
        }
    }
    function _handleReSendConfirm() {
        if (email) {
            setIsBusy(true);
            setMsg("Đang gửi xác thực....");
            Request.Post("/api/accounts/re-sendemail-confirm/" + email, null)
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                        setMsg("Gửi thông tin xác thực thành công. Vui lòng kiểm tra email và làm theo hướng dẫn");
                        setStatusConfirm(STATUS_CONFIRM.SUCCESSFULLY);
                    } else {
                        setIsBusy(false);
                        setMsg("Có lỗi xảy ra trong quá trình gửi xác thực. Vui lòng thử lại sau ít phút");
                        setStatusConfirm(STATUS_CONFIRM.FALED);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    Notify(NOTIFY.INFO, "Thông báo", err.message);
                });
        } else Notify(NOTIFY.INFO, "Thông báo", "Vui lòng nhập địa chỉ email đã đăng ký");
    }
    function _renderContent() {
        switch (statusConfirm) {
            case STATUS_CONFIRM.FALED:
                return <p style={{ color: "red" }}>{msg} </p>;
            case STATUS_CONFIRM.CONFIRM:
                return (
                    <React.Fragment>
                        <p>Tài khoản của bạn chưa được xác thực. Vui lòng kiểm tra email và làm theo hướng dẫn</p>
                        <p>
                            <b>
                                <i>Nếu không nhận được email. Vui lòng nhập lại email đã đăng ký để thực hiện lại việc xác thực.</i>
                            </b>
                        </p>
                        <Textbox id='email' name='email' value={email} placeholder='Email đã đăng ký' className='form-control' onChanged={(name, value) => setEmail(value)} />
                    </React.Fragment>
                );
            case STATUS_CONFIRM.SUCCESSFULLY:
                return <p className='text-dark'> {msg} </p>;
            default:
                return <p >{msg} </p>;
        }
    }
    function _renderButton() {
        switch (statusConfirm) {
            case STATUS_CONFIRM.FALED:
                return (
                    <a
                        href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/");
                        }}
                        className='btn btn-md btn-block btn-primary waves-effect waves-light'
                    >
                        Trang chủ
                    </a>
                );
            case STATUS_CONFIRM.CONFIRM:
                return (
                    <React.Fragment>
                        <a className="btn-lotus btn-md btn-block btn-dark ml-0 waves-effect waves-light" href='#' onClick={(e) => {
                            e.preventDefault();
                            _handleReSendConfirm();
                        }}> Gửi email xác thực</a>

                        <div className='row mt-4 pt-2'>
                            <div className='col-sm-12 text-center'>
                                <p className='text-muted mb-0'>
                                    Đăng nhập với tài khoản khác?{" "}
                                    <a
                                        href='#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.history.push("/login");
                                        }}
                                        className='text-dark ml-1'
                                    >
                                        <b>Đăng nhập</b>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </React.Fragment>
                );
            case STATUS_CONFIRM.SUCCESSFULLY:
                return (
                    <a
                        href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/login");
                        }}
                        className='btn btn-md btn-block btn-primary waves-effect waves-light'
                    >
                        Đăng nhập
                    </a>
                );
        }
    }
    return (
        <React.Fragment>
            <section className='bg-account'>
                <div className='page-header mt-5 ml-5'>
                    <img alt='Lotus' src='/images/logo-lotus.png' />
                </div>
                <div className='account-content'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='col-left'>
                            <img atl='lotus' src='/images/background-image.png' style={{ height: 650 }} />
                        </div>
                        <div className='col-right'>
                            <div className='main-account'>
                                <h4 className='font-weight-bold text-center text-dark mb-20'>Xác thực Email</h4>
                                <div className='account-content'>
                                    {_renderContent()}
                                    <div className='form-group row text-center m-t-10'>
                                        <div className='col-12'>{_renderButton()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
