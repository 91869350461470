import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING } from "../based/Constants";
import Loading from "../based/Loading";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Textbox from "../based/inputs/Textbox";
import VoucherServices from "../based/services/VoucherServices";

export default function VoucherOverallManagement(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [datas, setDatas] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getDatas({ ...INITPAGING });
    }, []);

    /** lấy thông tin voucher */
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await VoucherServices.GetListVoucher(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...newPaging, totalRecord: 0 });
        }
        setIsLoading(false);
        setMsg(null);
    }

    async function handleDelete(id) {
        setIsLoading(true);
        setMsg("Đang xóa dữ liệu...");
        let [err, data] = await VoucherServices.RemoveVoucher([id]);
        if (!err && data) {
            getDatas(paging);
        }
        setIsLoading(false);
        setMsg(null);
    }

    /** handle filter */
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
    }

    /** render */
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="code">Mã Voucher:</label>
                            <Textbox className="form-control" id="code" name="code" onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="name">Tên Voucher:</label>
                            <Textbox className="form-control" name="name" onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="time">Thời gian áp dụng:</label>
                            <div className="input-group form-row">
                                <div className="input-daterange input-group" id="date-range">
                                    <TFUDatetime
                                        dateFormat="dd/MM/yyyy"
                                        name="startDate"
                                        maxDate={paging.endDate}
                                        value={new Date(paging.startDate || new Date())}
                                        placeholder="Từ ngày"
                                        onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat="dd/MM/yyyy"
                                        name="endDate"
                                        minDate={paging.startDate}
                                        value={new Date(paging.endDate || new Date())}
                                        placeholder="Đến ngày"
                                        onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleFilter()}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                            <button
                                className="btn btn-custom btn-sm m-r-5"
                                onClick={() => {
                                    history.push("/voucher-overall-editor");
                                }}
                            >
                                <i className="fa fa-plus m-r-5" /> Thêm mới
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width="38" className="text-center">
                        STT
                    </th>
                    <th width="330" className="text-center">
                        Tên voucher
                    </th>
                    <th width="120" className="text-center">
                        Mã voucher
                    </th>
                    <th width="255" className="text-center">
                        Loại voucher
                    </th>
                    <th width="350" className="text-center">
                        Mô tả
                    </th>
                    <th width="130" className="text-center">
                        Ngày bắt đầu
                    </th>
                    <th width="130" className="text-center">
                        Ngày kết thúc
                    </th>
                    <th width="100" className="text-center">
                        Số lượng mã
                    </th>
                    <th width="140" className="text-center"></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td width={40} className="text-center vertical-align-middle">
                                    {no}
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <strong>{item.name}</strong>
                                </td>
                                <td className="text-center vertical-align-middle">{item.code}</td>
                                <td className="text-center vertical-align-middle">{item.voucherTypeName}</td>
                                <td className="text-center vertical-align-middle">{item.description}</td>
                                <td className="text-center vertical-align-middle">{Common.formatDate(new Date(item.fromDate))}</td>
                                <td className="text-center vertical-align-middle">{Common.formatDate(new Date(item.toDate))}</td>
                                <td className="text-center vertical-align-middle">{item.quantity}</td>
                                <td className="text-center vertical-align-middle">
                                    <a
                                        className="font-weight-bold text-custom"
                                        style={{ cursor: "pointer", margin: "5px" }}
                                        onClick={() => {
                                            history.push(`/voucher-overall-editor/${item.id}`);
                                        }}
                                    >
                                        Chi tiết
                                    </a>
                                    <a
                                        className="font-weight-bold text-danger"
                                        style={{ cursor: "pointer", margin: "5px" }}
                                        onClick={() => {
                                            handleDelete(item.id);
                                        }}
                                    >
                                        Xóa
                                    </a>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="200" className="text-center">
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý Vourcher" />
            <Loading show={isLoading} msg={msg} />
            <CommonTable classDivTable="mh-100vh" data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
