import BaseServices from "./BaseServices";

const TagComboServices = {
    CreateOrUpdateTagCombo: async (model) => {
        return await BaseServices.Post("/api/Tag/create-update-tagcombo", model);
    },
    DeleteTagCombo: async (ids) => {
        return await BaseServices.Post("/api/Tag/delete-tagcombo", ids);
    },
    GetTagComboByPage: async (paging) => {
        return await BaseServices.Post(`/api/Tag/get-tagcombo_bypage`, paging);
    },
    GetTagComboById: async (id) => {
        return await BaseServices.Get(`/api/Tag/get-tagcombo-by-id/${id}`);
    },
};

export default TagComboServices;
