import PropTypes from "prop-types";
import React from "react";
import CheckBox from "../../based/inputs/CheckBox";
import TFUImage from "../../based/inputs/Image";
import TextNumber from "../../based/inputs/TextNumber";
import TFUSelect from "../../based/inputs/TFUSelect";
import { IMAGE_TYPE } from "../../based/Constants";

function VariantFrameConfigItem(props) {
    const { url, index, imageTypeOptions, imageType, addRef, width, height, allowRenderPDP, allowRenderUpload, handleChange, handleRemove, handleValidate, mockup, onFileName, lengthFrameMin, axisX, collarY, frameMinY, digitalScale } = props;

    return (
        <tr>
            <td className="text-center vertical-align-middle">
                <TFUImage
                    className="max-w-150px my-2"
                    name="url"
                    id={`url[${index}]`}
                    image={url}
                    onChanged={(name, value) => {
                        handleChange(name, value);
                    }}
                    onFileName={(fileName) => {
                        onFileName(fileName);
                    }}
                    imageType={IMAGE_TYPE.Product}
                />
            </td>
            <td className="text-center vertical-align-middle">
                <TFUSelect
                    name={`imageType[${index}]`}
                    options={imageTypeOptions}
                    value={imageType}
                    placeholder="--Chọn sản phẩm--"
                    onChanged={(newVal) => {
                        handleChange("imageType", newVal);
                    }}
                    ref={addRef}
                />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`width`} required ref={addRef} value={width} onChanged={(name, value) => handleChange(name, value)} decimal readOnly />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`height`} required ref={addRef} value={height} onChanged={(name, value) => handleChange(name, value)} decimal readOnly />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`lengthFrameMin`} required ref={addRef} value={lengthFrameMin} onChanged={(name, value) => handleChange(name, value)} decimal readOnly />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`axisX`} required ref={addRef} value={axisX} onChanged={(name, value) => handleChange(name, value)} decimal />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`collarY`} required ref={addRef} value={collarY} onChanged={(name, value) => handleChange(name, value)} decimal />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`frameMinY`} required ref={addRef} value={frameMinY} onChanged={(name, value) => handleChange(name, value)} decimal />
            </td>
            <td className="text-center vertical-align-middle">
                <TextNumber name={`digitalScale`} required ref={addRef} value={digitalScale} onChanged={(name, value) => handleChange(name, value)} decimal />
            </td>
            <td className="text-center vertical-align-middle">
                {mockup &&
                    mockup.length > 0 &&
                    mockup.map((item, idx) => {
                        return <img key={idx} src={item} alt="image" style={{ maxWidth: "180px" }} />;
                    })}
            </td>
            <td className="text-center vertical-align-middle">
                <CheckBox name={"allowRenderPDP"} checked={allowRenderPDP} onCheckedChange={(name, value) => handleChange(name, value)} />
            </td>
            <td className="text-center vertical-align-middle">
                <CheckBox name={"allowRenderUpload"} checked={allowRenderUpload} onCheckedChange={(name, value) => handleChange(name, value)} />
            </td>
            <td className="text-center vertical-align-middle">
                <button
                    className="btn btn-danger m-1"
                    onClick={() => {
                        handleRemove();
                    }}
                >
                    Xoá
                </button>
                <button
                    className="btn btn-primary m-1"
                    onClick={() => {
                        handleValidate();
                    }}
                >
                    Kiểm tra
                </button>
            </td>
        </tr>
    );
}

VariantFrameConfigItem.propTypes = {
    url: PropTypes.string,
    index: PropTypes.number,
    imageTypeOptions: PropTypes.array,
    imageType: PropTypes.number,
    addRef: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    positionAX: PropTypes.number,
    positionAY1: PropTypes.number,
    positionAY2: PropTypes.number,
    positionBY1: PropTypes.number,
    positionCY1: PropTypes.number,
    handleChange: PropTypes.func,
    handleRemove: PropTypes.func,
    handleValidate: PropTypes.func,
    mockup: PropTypes.string,
};

export default VariantFrameConfigItem;
