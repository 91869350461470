import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import NumberFormat from "react-currency-format";
import CommonTable from "../based/CommonTable";
import SetProductServices from "../based/services/SetProductServices";
import TextNumber from "../based/inputs/TextNumber";
import _ from "lodash";

const PromoteHighlight = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getPromoteHighlight: () => {
            return productHighlight;
        },
    }));

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");

    const [products, setProducts] = useState([]);
    const [textSearch, setTextSearch] = useState("");
    const [isFetching, setFetching] = useState(false);

    const [showResult, setShowResult] = useState(false);
    const [quickSetup, setQuickSetup] = useState({});
    const [productHighlight, setProductHighlight] = useState([]);

    useEffect(() => {
        setProductHighlight(props.voucherPromoteHighlights);
    }, [props.voucherPromoteHighlights]);

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th>Tên sản phẩm</th>
                    <th width='200' className='text-center'>
                        Giá thường ngày (đ)
                    </th>
                    <th width='200' className='text-center'>
                        Giá Promote Brand
                    </th>
                    <th width='200' className='text-center'>
                        Giá Loyalty
                    </th>
                    <th width='150' className='text-center'>
                        Ranus Operation Fee (đ)
                    </th>
                    <th width='50'></th>
                </tr>
            </thead>
        );
    }
    function _handleRemoveVariant(item) {
        if (item) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn loại bỏ sản phẩm đã chọn?", () => {
                return new Promise((resolve, reject) => {
                    let productHighlightUp = [...productHighlight];
                    if (productHighlightUp && productHighlightUp.length > 0) {
                        let idx = -1;
                        productHighlightUp.map((variant, v) => {
                            if (variant.appProductVariantId == item.appProductVariantId) idx = v;
                        });
                        if (idx > -1) productHighlightUp.splice(idx, 1);
                        setProductHighlight(productHighlightUp);
                    }
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                });
            });
        }
    }
    function _handleChangedHighLight(name, value, idx) {
        if (name) {
            let productHighlightUp = [...productHighlight];
            if (productHighlightUp && productHighlightUp.length > 0) {
                productHighlightUp[idx][name] = value;
                if (name === "mustLoyaltyAmount") {
                    let lotusChargeAmount = Common.SetLotusChargeAmount(value, props.readRateCard);
                    productHighlightUp[idx]["mustLotusCharge"] = lotusChargeAmount;
                }
                setProductHighlight(productHighlightUp);
            }
        }
    }
    function _renderBody() {
        return (
            <tbody>
                {productHighlight && productHighlight.length > 0 ? (
                    productHighlight.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    <strong> {item.productName}</strong> 
                                    {item.sellerSKU && <p className='margin-bottom-0 font-italic'>SKU: {item.sellerSKU ? item.sellerSKU : "----"}</p>}
                                    {item.attributeValuesDetail &&
                                        item.attributeValuesDetail.length > 0 &&
                                        item.attributeValuesDetail.map((attribute, at) => {
                                            return (
                                                <p key={at} className='margin-bottom-0 font-italic'>
                                                    {attribute.name} : <span className='badge badge-custom'>{attribute.displayName}</span>
                                                </p>
                                            );
                                        })}
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.sellPrice} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <TextNumber
                                        required
                                        ref={props.addRef}
                                        name={`voucherPromoteHighlight[${i}].VoucherProductPrice`}
                                        suffix='đồng'
                                        className='form-control'
                                        value={item.voucherProductPrice}
                                        onChanged={(name, value) => _handleChangedHighLight("voucherProductPrice", value, i)}
                                    />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <TextNumber
                                        required
                                        ref={props.addRef}
                                        name={`voucherPromoteHighlight[${i}].MustLoyaltyAmount`}
                                        suffix='đồng'
                                        className='form-control'
                                        value={item.mustLoyaltyAmount}
                                        onChanged={(name, value) => _handleChangedHighLight("mustLoyaltyAmount", value, i)}
                                    />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.mustLotusCharge} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <i className='mdi mdi-24px mdi-delete text-danger cursor-pointer' onClick={() => _handleRemoveVariant(item)}></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Chưa có sản phẩm nào được chọn</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {}
    function _handleApplyAll() { 
        if (quickSetup && (quickSetup.promotePrice || quickSetup.loyaltyPrice)) {
            let productHighlightUp = [...productHighlight];
            if (productHighlightUp && productHighlightUp.length > 0) {
                productHighlightUp.map((item) => {
                    if (quickSetup.promotePrice) item.voucherProductPrice = quickSetup.promotePrice;
                    if (quickSetup.loyaltyPrice) {
                        item.mustLoyaltyAmount = quickSetup.loyaltyPrice;
                        let lotusChargeAmount = Common.SetLotusChargeAmount(item.mustLoyaltyAmount, props.readRateCard);
                        item.mustLotusCharge = lotusChargeAmount;
                    }
                });
                setProductHighlight(productHighlightUp);
            }
        } else Notify(NOTIFY.NOTIFY, "Chú ý", "Nhập thông tin thiết lập nhanh");
    }
    function _handleClickOutside() {
        setTimeout(function () {
            setShowResult(false);
        }, 200);
    }
    function _renderSearchAutoComplete() {
        return (
            <div id='search-autocomplete' className='p-1 bg-search-autocomplete rounded rounded-pill shadow-sm mb-4' onBlur={_handleClickOutside}>
                <div className='input-group'>
                    <div className='input-group-prepend'>
                        <button type='submit' className='btn btn-link text-warning'>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                    <input
                        id='txtSearch'
                        type='search'
                        placeholder='Nhập tên set sản phẩm...'
                        className='form-control border-0 bg-search-autocomplete'
                        onKeyUp={(e) => _handleChangeInputSearch(e)}
                        onChange={(e) => setTextSearch(e.target.value)}
                        onFocus={() => _handleFocusInputSearch()}
                        value={textSearch}
                        autoComplete='off'
                    />
                </div>
                {showResult && _renderResult()}
            </div>
        );
    }
    function _handleChangeInputSearch(e) {
        var text = e.target.value;
        setFetching(true);
        setShowResult(true);
        debounce(text);
    }
    function _handleFocusInputSearch() {
        if (products && products.length > 0) setShowResult(true);
        else setShowResult(false);
    }
    function _renderResult() {
        return (
            <div id='search-result'>
                <ul className='suggest_search'>
                    {products && products.length > 0 ? (
                        products.map((item, index) => {
                            return (
                                <div className='product-item' key={index} onClick={() => _handleSelectItem(item)}>
                                    <li className='product_suggest'>
                                        <div className='item-img'>
                                            <img src={item.setAvatar} alt={item.title} />
                                        </div>
                                        <div className='item-info'>
                                            <h3>{item.title} </h3>
                                            <p className='item-txt-sku'>Bao gồm: {item.totalVariants} sản phẩm</p>
                                        </div>
                                    </li>
                                </div>
                            );
                        })
                    ) : (
                        <div className='product-item'>
                            <li className='product_suggest'>
                                {isFetching ? (
                                    <span>
                                        <i className='fa fa-spinner m-r-5'></i>Đang tải dữ liệu...
                                    </span>
                                ) : (
                                    <span>Không có dữ liệu</span>
                                )}
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        );
    }
    async function _handleSelectItem(item) {
        if (item && item.id > 0) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu..");
            let [err, data] = await SetProductServices.GetByIdForUpdate(item.id);
            if (!err && data && data.productVariants && data.productVariants.length > 0) {
                setIsLoading(false);
                setMsg(null);
                let productVariants = data.productVariants;
                let productHighlightUp = productHighlight && productHighlight.length > 0 ? [...productHighlight] : [];
                productVariants.map((variant) => {
                    let oldVariant = null;
                    if (productHighlightUp && productHighlightUp.length > 0) oldVariant = productHighlightUp.find((x) => x.appProductVariantId == variant.variantId);
                    if (!oldVariant)
                        productHighlightUp.push({
                            avatar: variant.avatar,
                            appProductVariantId: variant.variantId,
                            productName: variant.productName,
                            sellerSKU: variant.sellerSKU,
                            sellPrice: variant.sellPrice,
                            voucherProductPrice: variant.sellPrice,
                            mustLoyaltyAmount: 0,
                            mustLotusCharge: 0,
                            attributeValuesDetail: variant.attributeValuesDetail,
                        });
                });
                setProductHighlight(productHighlightUp);
                setShowResult(false);
            } else {
                setIsLoading(false);
                setMsg(null);
            }
        }
    }
    const debounce = useCallback(
        _.debounce((_searchVal) => {
            _fetchingSearch(_searchVal);
        }, 1000),
        []
    );
    async function _fetchingSearch(text) {
        if (text && text.length > 0) {
            setFetching(true);
            var modelSearch = { ...Common.Paging, keyword: text };
            const [err, data] = await SetProductServices.GetPaging(modelSearch);
            if (!err && data && data.listObjects && data.listObjects.length > 0) {
                setProducts(data.listObjects);
                setFetching(false);
                setShowResult(true);
            } else {
                setShowResult(true);
                setFetching(false);
                setProducts([]);
            }
        } else {
            setProducts([]);
            setShowResult(false);
        }
    }
    function _renderQuickSetup() {
        let isEnabled = false;
        if (productHighlight && productHighlight.length > 0 && quickSetup && (quickSetup.promotePrice || quickSetup.loyaltyPrice)) {
            isEnabled = true;
        }
        return (
            <div className='card-box p-3'>
                <h4 className='header-title m-t-0'>
                    <b>Thiết lập nhanh</b>
                </h4>
                <div className='form-row'>
                    <div className='col-md-6 form-group'>
                        <label>Giá Promote Brand</label>
                        <TextNumber name='promotePrice' suffix='đồng' className='form-control' value={quickSetup.promotePrice} onChanged={(name, value) => setQuickSetup({ ...quickSetup, promotePrice: value })} />
                    </div>
                    <div className='col-md-6 form-group'>
                        <label>Giá Loyalty</label>
                        <TextNumber name='loyaltyPrice' suffix='đồng' className='form-control' value={quickSetup.loyaltyPrice} onChanged={(name, value) => setQuickSetup({ ...quickSetup, loyaltyPrice: value })} />
                    </div> 
                </div>
                <div className='form-row'>
                    <div className='col-md-12'>
                        <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm disable m-r-5"} onClick={() => (isEnabled ? _handleApplyAll() : {})}>
                            Áp dụng cho tất cả sản phẩm
                        </button>
                        <button className='btn btn-custom btn-sm m-r-5' onClick={() => props.onShowRateCard()}>
                            <i className='fa fa-book m-r-5'></i>Cấu hình tiền bản quyền
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className='col-md-12 card-box p-3'>
            {_renderSearchAutoComplete()}
            {_renderQuickSetup()}
            <div style={{ maxHeight: 500, overflow: "auto" }}>
                <CommonTable title='Danh sách sản phẩm' data={productHighlight} paging={Common.Paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} freeSize='tbl_setproducts' />
            </div>
        </div>
    );
});
export default PromoteHighlight;
