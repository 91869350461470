import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { BtnRounded, ButtonColor } from "../content/ct.elm";

export const ButtonType = {
    Add: 1,
    Edit: 2,
    Delete: 3,
    Rounded: 4,
    Search: 5,
    View: 6,
};

const Button = (props) => {
    const valueRef = useRef();
    return (
        <React.Fragment>
            {props.btnType == ButtonType.Add ? (
                <ButtonColor
                    bgColor={"#4caf50"}
                    color={"#fff"}
                    fontSize={props.fontSize ? props.fontSize : "12px"}
                    onClick={(e) => {
                        if (props.onClick) props.onClick();
                    }}
                    onChange={props.onChange}
                    className={props.className}
                >
                    <i className='fa fa-plus' aria-hidden='true'></i> <span>{props.label}</span>
                </ButtonColor>
            ) : props.btnType == ButtonType.Edit ? (
                <ButtonColor
                    bgColor={"#357a38"}
                    color={"#fff"}
                    fontSize={props.fontSize ? props.fontSize : "12px"}
                    onClick={(e) => {
                        if (props.onClick) props.onClick();
                    }}
                    onChange={props.onChange}
                    className={props.className}
                >
                    <i className='fa fa-pencil-square-o' aria-hidden='true'></i>
                    <span>Sửa</span>
                </ButtonColor>
            ) : props.btnType == ButtonType.Delete ? (
                <ButtonColor
                    bgColor={"#ff1744"}
                    color={"#fff"}
                    fontSize={props.fontSize ? props.fontSize : "12px"}
                    onClick={(e) => {
                        if (props.onClick) props.onClick();
                    }}
                    onChange={props.onChange}
                    className={props.className}
                >
                    <i className='fa fa-trash-o' aria-hidden='true'></i>
                    <span>Xóa</span>
                </ButtonColor>
            ) : props.btnType == ButtonType.Rounded ? (
                <BtnRounded width={props.width ? props.width : null} color={props.color} onClick={props.onClick} className={props.className}>
                    <span>{props.title}</span>
                </BtnRounded>
            ) : props.btnType == ButtonType.Search ? (
                <ButtonColor
                    bgColor={"#4caf50"}
                    color={"#fff"}
                    fontSize={props.fontSize ? props.fontSize : "12px"}
                    onClick={(e) => {
                        if (props.onClick) props.onClick();
                    }}
                    onChange={props.onChange}
                    className={props.className}
                >
                    <i className='fa fa-search' aria-hidden='true'></i>
                    {props.label ? <span>{props.label}</span> : <span>Tìm kiếm</span>}
                </ButtonColor>
            ) : props.btnType == ButtonType.View ? (
                <ButtonColor
                    bgColor={"#4caf50"}
                    color={"#fff"}
                    fontSize={props.fontSize ? props.fontSize : "12px"}
                    onClick={(e) => {
                        if (props.onClick) props.onClick();
                    }}
                    onChange={props.onChange}
                    className={props.className}
                >
                    <i className='fa fa-eye' aria-hidden='true'></i>
                    {props.label ? <span>{props.label}</span> : <span>Xem</span>}
                </ButtonColor>
            ) : (
                <ButtonColor
                    bgColor={props.bgColor}
                    color={props.color}
                    fontSize={props.fontSize ? props.fontSize : "12px"}
                    onClick={(e) => {
                        if (props.onClick) props.onClick();
                    }}
                    onChange={props.onChange}
                    className={props.className}
                >
                    <span>{props.title}</span>
                    {props.children}
                </ButtonColor>
            )}
        </React.Fragment>
    );
};

Button.propTypes = {
    btnType: PropTypes.number,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

export default Button;
