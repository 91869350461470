import React, { Component } from "react";
import PropTypes from "prop-types";
import InputValidation from "../inputs-refactor/InputValidation";

class TextArea extends InputValidation {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        required: false,
        isValid: true,
        className: "form-control",
        minLength: 0,
        maxLength: 524288,
        value: "",
        readOnly: false,
    };

    static propTypes = {
        id: PropTypes.string,
        required: PropTypes.bool,
        requiredMsg: PropTypes.string,
        isValid: PropTypes.bool,
        invalidMessage: PropTypes.string,
        maxLength: PropTypes.number,
        maxLengthMsg: PropTypes.string,
        minLength: PropTypes.number,
        minLengthMsg: PropTypes.string,
        onChanged: PropTypes.func,
        value: PropTypes.string,
        readOnly: PropTypes.bool,
    };

    render() {
        let classInput = this.props.className;
        if (!this.state.isValid) {
            classInput += " alert-danger";
        }
        return (
            <React.Fragment>
                <textarea name={this.props.name} className={classInput} onChange={this.handleChanged} value={this.props.value} readOnly={this.props.readOnly} rows={this.props.rows} placeholder={this.props.placeholder} />
                {this.state.isValid ? null : (
                    <label className="text-danger" htmlFor={this.props.id}>
                        {this.state.msgInvalid}
                    </label>
                )}
            </React.Fragment>
        );
    }
}
export default TextArea;
