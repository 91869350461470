import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY, StockFolderType } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import DragAndDropIndex from "../based/DragAndDropIndex";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import ModalAddOrUpdateFolder from "./ModalAddOrUpdateFolder";
import ModalAddProduct from "./ModalAddProduct";
import StockFolderServices from "../based/services/StockFolderServices";
import TFUSelect from "../based/inputs/TFUSelect";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    folderType: StockFolderType.AppProduct,
};

let modelUpdateIndex = {
    id: 0,
    parentFolderId: 0,
    folderType: 9,
    index: 0,
};

function FolderProductPrototype() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModalFolder, setIsShowModalFolder] = useState(false);
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [product, setProduct] = useState([]);
    const [pagingProduct, setPagingProduct] = useState(initPage);
    const tableProductRef = useRef(null);
    const [folderId, setFolderId] = useState();
    const [brandCaseOptions, setBrandCaseOptions] = useState([]);

    useEffect(() => {
        getData(paging);
        getBrandCaseOptions({ ...INITPAGING, pageNumber: 1, pageSize: 1000 });
    }, []);

    useEffect(() => {
        getProduct(initPage);
    }, [folderId]);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await FolderProductPrototypeServices.GetFolderAppProductByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    async function getBrandCaseOptions(newPaging) {
        setBusy(true);
        const [err, data] = await FolderProductPrototypeServices.GetFolderProductBrandPrototypeByPage(newPaging);
        if (!err && data) {
            let lstObjects = data.listObjects;
            let lstItem = lstObjects.map((x) => {
                return {
                    value: x.id,
                    label: x.name,
                };
            });
            setBrandCaseOptions(lstItem);
        }
        setBusy(false);
    }

    async function getProduct(paging) {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.GetProductByFolderId(paging, folderId);
        if (!err && data) {
            setProduct(data.listObjects);
            setPagingProduct(data.paging);
            setBusy(false);
        } else {
            setProduct([]);
            setBusy(false);
        }
    }

    //table bên trái
    async function _handleDelete(id) {
        var ids = data.filter((x) => x.id == folderId).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await FolderProductPrototypeServices.DeleteFolderAppProduct(ids[0]);
                    if (!error && data) {
                        setBusy(false);
                        setMsg(null);
                        getData(paging);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                    } else {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Chưa chọn folder!");
    }

    async function updateIndexFolder(id, index) {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.UpdateIndexStockFolder({
            ...modelUpdateIndex,
            id,
            index,
        });
        if (!err && data) {
            getData(paging);
            setFolderId(0);
            setBusy(false);
        } else {
            setBusy(false);
        }
    }

    async function getChildDatas(newPaging) {
        const [err, data] = await StockFolderServices.GetListStockFolderPagings(newPaging);
        return [err, data];
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.id == folderId);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom mb-3"
                    onClick={() => {
                        setFolderId(0);
                        setIsShowModalFolder(true);
                    }}
                >
                    Thêm mới
                </button>
                <button
                    onClick={() => {
                        setIsShowModalFolder(true);
                    }}
                    className={folderId > 0 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom ml-3 mb-3 disable"}
                >
                    Sửa
                </button>
                <button
                    className={folderId > 0 ? "btn btn-danger mb-3 ml-3" : "btn btn-danger ml-3 mb-3 disable"}
                    onClick={() => {
                        _handleDelete();
                    }}
                >
                    Xóa
                </button>
            </React.Fragment>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center"></th>
                    <th className="cursor-pointer">Folder</th>
                </tr>
            </thead>
        );
    }

    function _renderRow(item, idx) {
        return (
            <React.Fragment>
                <td>
                    <CheckBox
                        id={idx.toString()}
                        name={idx.toString()}
                        checked={item.id == folderId}
                        label=" "
                        onCheckedChange={(name, value) => {
                            if (value) {
                                setFolderId(item.id);
                            } else {
                                setFolderId(0);
                            }
                        }}
                    />
                </td>
                <td className="d-flex align-items-center">
                    <i
                        className={`fa ${item.isDown ? "fa-angle-down" : "fa-angle-right"} mr-3 cursor-pointer`}
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            let dt = [...data];
                            let ele = dt.find((x) => x.id == item.id);
                            ele.isDown = !ele.isDown;
                            let [err, datas] = await getChildDatas({ ...INITPAGING, pageNumber: 1, pageSize: 1000, stockFolderId: item.id });
                            if (!err && datas && datas.listObjects) ele.child = datas.listObjects;
                            setData(dt);
                        }}
                    />
                    <span className="d-flex align-items-center cursor-pointer" onClick={(e) => {}}>
                        <img src={item.thumbnail} alt={item.name} className="mr-3 square" />
                        {item.name}
                    </span>
                </td>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return <DragAndDropIndex data={data} renderRow={_renderRow} renderIsDown={renderIsDown} updateIndexFolder={updateIndexFolder} />;
    }

    function renderIsDown(item, i) {
        return (
            item.isDown && (
                <React.Fragment>
                    {item.child &&
                        item.child.map((c, idx) => {
                            return (
                                <React.Fragment>
                                    <tr key={idx}>
                                        <td>
                                            <CheckBox
                                                checked={c.id == folderId}
                                                name={`child-${c.id}`}
                                                onCheckedChange={(name, value) => {
                                                    if (value) setFolderId(c.id);
                                                    else setFolderId(0);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {c.haveChild && (
                                                <i
                                                    className={`fa ${c.isDown ? "fa-angle-down" : "fa-angle-right"} mr-3 cursor-pointer`}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        let dt = [...data];
                                                        let ele = dt.find((x) => x.id == item.id);
                                                        if (!ele) return;
                                                        let childEle = ele.child;
                                                        let child = childEle.find((x) => x.id == c.id);
                                                        if (!child) return;
                                                        child.isDown = !child.isDown;
                                                        let [err, datas] = await getChildDatas({ ...INITPAGING, pageNumber: 1, pageSize: 1000, stockFolderId: c.id });
                                                        if (!err && datas && datas.listObjects) child.child = datas.listObjects;
                                                        setData(dt);
                                                    }}
                                                />
                                            )}

                                            <img src={c.thumbnail} alt={c.name} className="mx-3 sm-square" />
                                            <span>{c.name}</span>
                                        </td>
                                    </tr>
                                    {c.isDown && c.child && c.child.length > 0 ? renderIsDown(c, idx) : ""}
                                </React.Fragment>
                            );
                        })}
                </React.Fragment>
            )
        );
    }

    //table bên phải
    async function updateIndexProductInFolder(idProduct, newIndex) {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.UpdateIndexProductInFolder({
            idProduct,
            newIndex,
            folderId: folderId,
        });
        if (!err && data) {
            getProduct(initPage);
            setBusy(false);
        } else {
            setBusy(false);
        }
    }

    async function handleRemoveProduct(id) {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.DeleteFolderConAppProduct(folderId, id);
        if (!err && data) {
            getProduct(initPage);
            setBusy(false);
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa lỗi");
        }
    }

    function _renderActionProduct() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.id == folderId);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className={`btn btn-custom mb-3`}
                    onClick={() => {
                        setIsShowModalAddProduct(true);
                    }}
                >
                    Thêm sản phẩm +
                </button>
            </React.Fragment>
        );
    }

    function _renderHeaderProduct() {
        return (
            <thead>
                <tr className="text-center">
                    <th>Hình</th>
                    <th>Tên sản phẩm</th>
                    <th>Tên hiển thị</th>
                    <th>Giá</th>
                    <th>Hình thực tế</th>
                    <th>Folder thương hiệu</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }

    const handleUpdateProductFolder = async (ids, folderId) => {
        setBusy(true);

        if (ids && ids.length > 0) {
            let [err, data] = await FolderProductPrototypeServices.CreateFolderConnectionAppProduct(ids, folderId);
            if (!err) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                getProduct({ ...pagingProduct });
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
        } else {
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Chưa chọn sản phẩm nào");
        }
        setBusy(false);
    };

    function _renderRowProduct(item, idx) {
        return (
            <React.Fragment key={idx}>
                <td style={{ width: 100, textAlign: "center" }}>{item.productAvatar ? <img src={item.productAvatar} /> : <img />}</td>
                <td>{item.name}</td>
                <td style={{ textAlign: "center" }}>{item.nameShow}</td>
                <td style={{ textAlign: "center" }}>{item.price}</td>
                <td style={{ textAlign: "center" }}>{item.quantityImage} hình</td>
                <td>
                    <TFUSelect
                        options={brandCaseOptions}
                        name={`brandCaseId-${item.id}`}
                        id={`brandCaseId-${item.id}`}
                        value={item.brandCaseId}
                        onChanged={(newVal) => {
                            handleUpdateProductFolder([item.id], +newVal);
                        }}
                    />
                </td>

                <td style={{ width: 200, textAlign: "center" }}>
                    <button
                        className="btn btn-custom"
                        onClick={async () => {
                            window.open("/prototype-add/" + item.id, "_blank");
                        }}
                    >
                        Sửa
                    </button>
                    <button
                        className="btn ml-3 btn-danger"
                        onClick={() => {
                            handleRemoveProduct(item.id);
                        }}
                    >
                        Xóa
                    </button>
                </td>
            </React.Fragment>
        );
    }

    function _renderBodyProduct() {
        return <DragAndDropIndex data={product} renderRow={_renderRowProduct} updateIndexFolder={updateIndexProductInFolder} />;
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý folder sản phẩm phôi tự thiết kế" />
            <Loading show={isBusy} msg={msg} />
            <div className="body-page">
                <div className="folder-list">
                    <CommonTable data={data} paging={paging} ref={tableRef} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={(paging) => getData(paging)} />
                </div>
                <div className="product-list">
                    <CommonTable
                        data={product}
                        paging={pagingProduct}
                        onFilter={(pagingProduct) => getProduct(pagingProduct)}
                        ref={tableProductRef}
                        hideSearchBasicButton
                        renderAction={_renderActionProduct}
                        renderHeader={_renderHeaderProduct}
                        renderBody={_renderBodyProduct}
                    />
                </div>
            </div>
            {isShowModalFolder && (
                <ModalAddOrUpdateFolder
                    isShowModal={isShowModalFolder}
                    onClose={() => {
                        setIsShowModalFolder(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalFolder(false);
                        getData(initPage);
                    }}
                    id={folderId}
                />
            )}
            {isShowModalAddProduct && (
                <ModalAddProduct
                    isShowModal={isShowModalAddProduct}
                    onClose={() => {
                        setIsShowModalAddProduct(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalAddProduct(false);
                        getProduct(initPage);
                    }}
                    folderId={folderId}
                />
            )}
        </Wrapper>
    );
}
const Wrapper = styled.div`
    .cursor-move {
        cursor: move;
    }
    .body-page {
        display: flex;
        .folder-list {
            width: 30%;
        }
        .product-list {
            width: 70%;
            padding-left: 10px;

            table {
                tr {
                    td {
                        img {
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
    img.square {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }

    img.sm-square {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
`;
export default FolderProductPrototype;
