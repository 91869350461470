import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { INITPAGING } from '../../../based/Constants';
import CheckBox from '../../../based/inputs/CheckBox';
import Textbox from '../../../based/inputs/Textbox';
import Loading from '../../../based/Loading';
import BaseServices from '../../../based/services/BaseServices';
import useFormValidate from '../../../hooks/useFormValidate'
const AddOrEditGroup = ({ group, show, onClose, onSave }) => {
    const [isBusy, setBusy] = useState(false);
    const { addRef, isValid, } = useFormValidate();
    const [dataModel, setDataModel] = useState(group);
    const [affiliates, setAffiliates] = useState([]);
    useEffect(() => {
        if (show) {
            _fetchAffiliates();
        }
    }, [show])
    const _fetchAffiliates = async () => {
        setBusy(true);
        let paging = INITPAGING;
        paging.pageSize = 1000;
        const [err, data] = await BaseServices.Post('/api/affiliate/get-vip-affiliators-by-group/' + group.id);
        if (!err) {
            setAffiliates(data)
        }
        setBusy(false);
    }
    function _handleInputChanged(name, value) {
        let model = { ...dataModel };
        model[name] = value;
        setDataModel(model);
    }
    const _handleSave = async () => {
        if (isValid()) {
            setBusy(true);
            let postObject = { ...dataModel };
            postObject.thirdParties = affiliates.filter(m => m.selected);
            const [err, data] = await BaseServices.Post('/api/affiliate/update-group-affiliate', postObject)
            if (!err) {
                onSave(true);
            }
            setBusy(false);
        }
    }
    const _handleSelectAffiliate = (idx, value) => {
        var affs = [...affiliates];
        affs[idx].selected = value;
        setAffiliates(affs);
    }
    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Loading show={isBusy} msg={'Đang lưu...'} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>{dataModel.id > 0 ? 'Cập nhật thông tin nhóm Affiliate' : 'Thêm mới nhóm Affiliate'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <div className='form-group'>
                    <label className='required'>Tên Nhóm:</label>
                    <Textbox ref={addRef} name='name' id='name' value={dataModel.name} onChanged={_handleInputChanged} />
                </div>
                <div className='form-group'>
                    <label className='required'>Danh sách Affiliate:</label>
                    {
                        affiliates && affiliates.map((af, i) => {
                            return <div key={i}>
                                <div>
                                    <CheckBox id={'affiliate_' + af.thirdPartyId} checked={af.selected} label={af.thirdPartyName + '(' + af.thirdPartyEmail + ')'}
                                        onCheckedChange={(name, value) => _handleSelectAffiliate(i, value)}
                                    />
                                </div>
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={_handleSave}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddOrEditGroup;