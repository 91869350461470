import React from "react";
import Autosuggest from "react-autosuggest";
import { ListGroupItem } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import Common from "./Common";

class Autocomplete extends React.Component {
    constructor() {
        super();
        this.state = { tags: [] };
        this.handleChange = this.handleChange.bind(this);
        this.handleResetSuggestions = this.handleResetSuggestions.bind(this);
    }

    componentDidUpdate(prevProps) {
        const stringTags = this.props.tags.map((tag) => tag).join(",");
        const stringPrevTags = prevProps.tags.map((tag) => tag).join(",");

        if (stringTags != stringPrevTags) {
            let data = this.props.tags;
            this.setState({ tags: data });
        }
    }
    componentDidMount() {
        let data = this.props.tags;
        this.setState({ tags: data });
        document.addEventListener("mousedown", this.handleResetSuggestions);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleResetSuggestions);
    }
    handleResetSuggestions(event) {
        if (this.props.currRef && !this.props.currRef.current.contains(event.target)) {
            this.props.resetSuggestion();
        }
    }
    handleChange(tags) {
        let current = this.props.tags;
        // remove
        if (current.length > tags.length) {
            this.props.onChange(tags);
        }
        //add
        else {
            if (this.props.suggestion) {
                let suggestionText = this.props.suggestion.map((item) => item.text);
                let lastItem = tags && tags.length > 0 ? tags[tags.length - 1] : "";

                let exactIndex = suggestionText.indexOf(lastItem);
                let index = suggestionText.findIndex((item) => {
                    return item.toLowerCase().includes(lastItem.toLowerCase());
                });
                let currentItem = exactIndex <= 0 ? suggestionText[index] : suggestionText[exactIndex];
                tags.splice(tags.length - 1, 1, currentItem);
                if (index >= 0) this.props.onChange(tags);
            }
        }
    }
    render() {
        var newSuggestion = this.props.suggestion;
        var onFetch = (value) => {
            if (value) this.props.onFetch(value);
        };
        var resetSuggestion = () => this.props.resetSuggestion();
        function autocompleteRenderInput({ addTag, ...props }) {
            const handleOnChanged = (e, { newValue, method }) => {
                if (method === "enter") {
                    e.preventDefault();
                } else {
                    if (e.target.value !== "") {
                        onFetch(e.target.value);
                    }
                    props.onChange(e);
                }
            };

            return (
                <Autosuggest
                    suggestions={newSuggestion && newSuggestion.length > 0 ? newSuggestion.map((item) => item.text) : []}
                    shouldRenderSuggestions={(value) => {
                        value && value.trim().length > 0;
                    }}
                    alwaysRenderSuggestions={true}
                    getSuggestionValue={(suggestion) => suggestion}
                    renderSuggestion={(suggestion) => (
                        <ListGroupItem className='py-2' style={{ cursor: "pointer" }}>
                            {Common.isHexColorValue(suggestion) ? (
                                <div>
                                    <span className='dot center mr-2' style={{ background: suggestion }}></span>
                                    {suggestion}
                                </div>
                            ) : (
                                suggestion
                            )}
                        </ListGroupItem>
                    )}
                    inputProps={{ ...props, onChange: handleOnChanged }}
                    onSuggestionSelected={(e, { suggestion }) => {
                        addTag(suggestion);
                        resetSuggestion();
                    }}
                    onSuggestionsClearRequested={() => {}}
                    onSuggestionsFetchRequested={() => {}}
                />
            );
        }

        return <TagsInput disabled={this.props.readOnly} renderInput={autocompleteRenderInput} value={this.state.tags} onChange={this.handleChange} />;
    }
}

export default Autocomplete;
