import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY, PAYMENT_METHOD, SHIPPING_SERVICE_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import OrderServices from "../based/services/OrderServices";

function LogisticMultiCreatePage(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const listOrderId = queryParams.get("listOrderId");
    const [deliveryModel, setDeliveryModel] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (listOrderId) {
            console.log(listOrderId);
            let lstOrderId = listOrderId.split(",");
            fetchData(lstOrderId);
        }
    }, []);

    const fetchData = async (listOrderId) => {
        const [err, data] = await OrderServices.GetOrderGrabDeliveryInfoByOrderId(listOrderId);
        if (!err && data) {
            setDeliveryModel(data);
        } else {
            setDeliveryModel({});
        }
    };

    const createOrder = async (listOrderId, shippingCompanyId, shippingServiceType) => {
        const [err, data] = await OrderServices.CreateMultiOrder(listOrderId, shippingCompanyId, shippingServiceType);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Tạo đơn hàng thành công");
            history.push("/logistic-multi-detail/" + data);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Tạo đơn hàng thất bại");
        }
    };

    const totalCOD = useMemo(() => {
        let total = 0;

        if (deliveryModel && deliveryModel.transactionDeliveries) {
            for (const iterator of deliveryModel.transactionDeliveries) {
                let redundantPrice = iterator.order.finallyPrice - (iterator.order.transferPartial || 0);
                if (iterator.order.paymentMethod == PAYMENT_METHOD.COD || iterator.order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) {
                } else {
                    redundantPrice = 0;
                }

                total += redundantPrice;
            }
        }

        return total;
    }, [deliveryModel]);

    const totalShippingFee = useMemo(() => {
        let total = 0;
        if (deliveryModel && deliveryModel.quoteModels) {
            let activeQuote = deliveryModel.quoteModels.find((x) => x.isActive);
            if (activeQuote) total = activeQuote.shippingFee;
        }
        return total;
    });

    if (!deliveryModel || !deliveryModel.quoteModels || !deliveryModel.transactionDeliveries) return "";
    return (
        <Wrapper>
            <div className="section-info">
                <div className="sender">
                    <div className="sender-header">
                        <div className="sender-header-left">Điểm lấy hàng</div>
                        <div className="sender-header-right">
                            <div className="icon">
                                <GoRightIcon />
                            </div>
                        </div>
                    </div>
                    <div className="sender-body">
                        <p>Ranus - 0898000004</p>
                        <p>75 đường số 2 KDC Parkhills, Phường10. quận Gò vấp</p>
                    </div>
                </div>
            </div>
            {deliveryModel.quoteModels && (
                <div className="section-booking">
                    <div className="booking-main">Chọn phương thức vận chuyển *</div>
                    <div className="method-type">
                        {deliveryModel.quoteModels.map((item, index) => {
                            let length = deliveryModel.quoteModels.length;
                            let className = "";
                            if (index == 0) className = "method-left";
                            else if (index == length - 1) className = "method-right";
                            else className = "method-middle";
                            return (
                                <div
                                    className={`${className} ${item.isActive ? "active" : ""}`}
                                    style={{
                                        width: `${100 / length}%`,
                                    }}
                                    key={index}
                                    onClick={(e) => {
                                        let newQuoteModels = deliveryModel.quoteModels.map((x) => ({ ...x, isActive: false }));
                                        newQuoteModels[index].isActive = true;
                                        setDeliveryModel({ ...deliveryModel, quoteModels: newQuoteModels });
                                    }}
                                >
                                    <div className="method-type-header">{item.serviceName}</div>
                                    <div className="method-type-body">{Common.formatCurrency(item.shippingFee)}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {deliveryModel.transactionDeliveries && deliveryModel.transactionDeliveries.length > 0
                ? deliveryModel.transactionDeliveries.map((item, index) => {
                      const ship = item.shippingAddress;
                      const totalWeight = item.products.reduce((total, item) => total + item.shippingWeight, 0) / 1000;
                      let redundantPrice = item.order.finallyPrice - (item.order.transferPartial || 0);
                      if (item.order.paymentMethod == PAYMENT_METHOD.COD || item.order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) {
                      } else {
                          redundantPrice = 0;
                      }

                      const totalQty = item.products.reduce((total, item) => total + item.quantity, 0);
                      return (
                          <div className="receive-position" key={index}>
                              <div className="receiver">
                                  <div className="receiver-header">
                                      <div className="receiver-header-left">Điểm giao hàng {index + 1}</div>
                                      <div className="receiver-header-right">
                                          <div className="right-detail"></div>
                                          <div className="icon">
                                              <GoRightIcon />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="receiver-body">
                                      <p>
                                          {ship.userName} - {ship.phoneNumber}
                                      </p>
                                      <p>{ship.fullAddress}</p>
                                  </div>
                              </div>
                              <div className="booking-type">
                                  <div className="booking-left">
                                      <div className="booking-type-header">COD</div>
                                      <div className="booking-type-body">{Common.formatCurrency(redundantPrice)}</div>
                                  </div>
                                  <div className="booking-middle">
                                      <div className="booking-type-header">Size</div>
                                      <div className="booking-type-body">S</div>
                                  </div>
                                  <div className="booking-right">
                                      <div className="booking-type-header">Cân nặng</div>
                                      <div className="booking-type-body">{totalWeight} Kg</div>
                                  </div>
                              </div>
                              <div className="booking-package">
                                  <span>
                                      {totalQty} sản phẩm: {Common.formatCurrency(item.order.finallyPrice)}
                                  </span>
                              </div>
                          </div>
                      );
                  })
                : ""}

            <div className="bottom-bar">
                <div className="overview-grab">
                    <div className="overview-top">Tổng tiền COD: {Common.formatCurrency(totalCOD)}</div>
                    <div className="overview-bottom">
                        Phí giao hàng:
                        <span className="shipping-fee">{Common.formatCurrency(totalShippingFee)}</span>
                    </div>
                </div>
                <div
                    className="create-button"
                    onClick={(e) => {
                        let shippId = deliveryModel.quoteModels.find((x) => x.isActive).id;
                        let lstOrderId = listOrderId.split(",");
                        createOrder(lstOrderId, shippId, SHIPPING_SERVICE_TYPE.MULTI_STOP);
                    }}
                >
                    <span>Đặt xe</span>
                </div>
            </div>
        </Wrapper>
    );
}

export default LogisticMultiCreatePage;

const Wrapper = styled.div`
    height: 100vh;
    padding: 20px 16px;
    overflow: auto;
    span.shipping-fee {
        color: #28b259;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        margin-left: 8px;
    }
    .receive-position {
        background: white;
        margin: 12px 0;
        border-radius: 12px;
    }

    .booking-package {
        padding: 8px 12px;
        border-top: 1px solid #d9d9d9;
    }

    .bottom-bar {
        display: flex;
    }

    .create-button {
        width: 40%;
    }

    .overview-grab {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .overview-top {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .overview-bottom {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .header {
        display: flex;
    }

    .header-right {
        margin-left: auto;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .section-info {
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 12px 0;
    }

    .sender-header {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
    }

    .sender-header-right {
        display: flex;
        margin-left: auto;
    }

    .sender-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    p {
        margin: 0 !important;
    }

    .sender-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .sender {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header {
        display: flex;
        align-items: center;
    }

    .receiver-header-right {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .receiver {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header-right .icon {
        margin-left: 8px;
    }

    .right-detail {
        color: #28b259;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .receiver-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .receiver-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .booking-type {
        display: flex;
    }

    .booking-left {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        background: white;
    }

    .booking-middle {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border-left: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        background: #f6f6f6;
    }

    .booking-right {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        background: #f6f6f6;
    }

    .booking-left-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        display: flex;
    }

    .booking-left-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        display: flex;
    }

    .booking-middle-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-middle-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-right-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-right-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-type-header {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .booking-type-body {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .booking-left .booking-type-body {
        color: #28b259;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .order-product-overview {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 8px;
    }

    thead {
        border-radius: 10px 0px 0px 0px;
        border: 1px solid #ededed;
        background: #878787;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
    }

    td span.main {
        display: block;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    td span.sub {
        display: block;
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    tr td {
        vertical-align: middle !important;
        text-align: center;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    table.table.table-border {
        background: white;
    }

    .bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    }

    .create-button {
        border-radius: 12px;
        background: #28b259;
        text-align: center;
        padding: 14px;
    }

    .create-button span {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .booking-main {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .method-type {
        display: flex;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .method-left {
        width: 33.33%;
        text-align: center;
        padding: 12px;
        border-radius: 12px 0 0 12px;
    }

    .active {
        background: #28b259;
    }

    .method-middle {
        width: 33.33%;
        text-align: center;
        padding: 12px;
        border: 1px solid #d9d9d9;
    }

    .method-right {
        width: 33.33%;
        text-align: center;
        padding: 12px;
        border-radius: 0 12px 12px 0;
    }

    .method-type-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .method-type-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-top: 4px;
    }

    .method-type .active div {
        color: white !important;
    }

    .order-product {
        margin-top: 24px;
    }
`;

const GoRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
            <path d="M1 1L5 5L1 9" stroke="black" strokeWidth="1.5" />
        </svg>
    );
};
