import { OPERATOR_ORDER_STATUS, PRINT_TYPE } from "../based/Constants";

export class OperatorOrderModel {
    constructor() {
        this.orderProductId = 0;
        this.printArtworkHandleBy = "";
        this.printLogoHandleBy = "";
        this.pressureArtworkHandleBy = ""
        this.pressureLogoHandleBy = "";
        this.dTGPrintFile = "";
        this.petPrintFile = "";
        this.isBrandStamp = false;
        this.operatorOrderStatus = OPERATOR_ORDER_STATUS.Preparing;
        this.printingBatch = 0;
        this.printArtworkHandleName = "";
        this.printLogoHandleName = "";
        this.pressureLogoHandleName = "";
        this.pressureArtworkHandleName = "";
        this.printType = PRINT_TYPE.DTG;
        this.printTypeName = "";
        this.operatorOrderStatusName = "";
        this.operatorOrderInlineStatusName = "";
    }
}
