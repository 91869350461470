import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ARTWORK_TYPE } from "../../based/Constants";
import TFUTooltip from "../../based/TFUTooltip";
import { SetModel } from "../../models/SetModel";

const ProductPreview = ({ campaign, setCampaign, readOnly }) => {
    const [allowEdit, setAllowEdit] = useState(true);
    const [endTime, setEndTime] = useState(new Date());
    const selectedProduct = campaign ? campaign.app_Set : new SetModel();
    const artworkFront = selectedProduct && selectedProduct.artworks ? selectedProduct.artworks.find((x) => x.artworkType == ARTWORK_TYPE.Front) : null;
    const artworkBack = selectedProduct && selectedProduct.artworks ? selectedProduct.artworks.find((x) => x.artworkType == ARTWORK_TYPE.Back) : null;

    useEffect(() => {
        if (campaign.startTime) getCampaignEndTime(campaign.startTime);
    }, [campaign.startTime]);

    //config thời gian kết thúc
    const getCampaignEndTime = (startTime) => {
        var result = new Date(startTime);
        result.setDate(result.getDate() + 14);
        var camp = { ...campaign, endTime: result };
        setCampaign(camp);
        setEndTime(result);
    };

    return (
        <div>
            <p>
                <b>Tên Set thiết kế: </b> {selectedProduct ? selectedProduct.title : ""}
            </p>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='m-0 font-weight-bold'>Mockup mặt trước:</p>
                    <img src={artworkFront ? artworkFront.thumbnail : "/images/no-image.png"} className='border mt-1' style={{ width: "auto", height: "10rem", padding: "0.5rem" }} />
                </div>
                <div>
                    <p className='m-0 font-weight-bold'>Mockup mặt sau:</p>
                    <img src={artworkBack ? artworkBack.thumbnail : "/images/no-image.png"} className='border mt-1' style={{ width: "auto", height: "10rem", padding: "0.5rem" }} />
                </div>
            </div>
            <div className='justify-content-between mt-2'>
                <div>
                    <span className='mb-1'>
                        <b>
                            Ngày hiển thị:
                            <TFUTooltip content='Là ngày mà sản phẩm này được hiển thị Campaign Milestone trên trang Profile của Creator và trang chiến dịch của Lotus. Mục đích để thông báo trước thời gian giảm giá cho người mua' target='showTime'>
                                <i className='fa fa-question-circle' id='showTime' />
                            </TFUTooltip>
                        </b>
                    </span>
                    <DatePicker
                        onChange={(value) => {
                            var camp = { ...campaign, showTime: value };
                            setCampaign(camp);
                        }}
                        selected={campaign.showTime}
                        adjustDateOnChange
                        minDate={new Date()}
                        disabled={!allowEdit}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeCaption='time'
                        timeFormat='HH:mm'
                        timeIntervals={15}
                        dateFormat='dd/MM/yyyy HH:mm'
                        dropdownMode='select'
                        popperPlacement='auto'
                        className='form-control'
                    />
                </div>
            </div>
            <div className='d-flex justify-content-between mt-2'>
                <div>
                    <span className='mb-1'>
                        <b>
                            Ngày bắt đầu:
                            <TFUTooltip content='Là ngày giá của chiến dịch MileStone bắt đầu. Người mua có thể mua được với giá được set bởi Creator trong chiến dịch MileStone' target='startTime'>
                                <i className='fa fa-question-circle' id='startTime' />
                            </TFUTooltip>
                        </b>
                    </span>
                    <DatePicker
                        onChange={(value) => {
                            var camp = { ...campaign, startTime: value };
                            setCampaign(camp);
                        }}
                        selected={campaign.startTime}
                        adjustDateOnChange
                        minDate={new Date(campaign.showTime)}
                        disabled={!allowEdit}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeCaption='time'
                        timeFormat='HH:mm'
                        timeIntervals={15}
                        dateFormat='dd/MM/yyyy HH:mm'
                        dropdownMode='select'
                        popperPlacement='auto'
                        className='form-control'
                    />
                </div>
                <div>
                    <span className='mb-1'>
                        <b>
                            Ngày kết thúc:
                            <TFUTooltip content='Là ngày kết thúc của chiến dịch MileStone. Giá sẽ trở về giá được set ở mức cuối cùng.' target='endTime'>
                                <i className='fa fa-question-circle' id='endTime' />
                            </TFUTooltip>
                        </b>
                    </span>
                    <DatePicker
                        selected={endTime}
                        minDate={new Date(campaign.startTime)}
                        adjustDateOnChange
                        disabled
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeCaption='time'
                        timeFormat='HH:mm'
                        timeIntervals={15}
                        dateFormat='dd/MM/yyyy HH:mm'
                        dropdownMode='select'
                        popperPlacement='auto'
                        className='form-control'
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductPreview;
