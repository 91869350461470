export class VoucherModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.code = "";
        this.description = "";
        this.fromDate = new Date();
        this.toDate = new Date();
        this.quantity = 0;
        this.validCollectTime = 0;
    }
}
export class VoucherGiftModel extends VoucherModel {
    constructor() {
        super();
        this.voucherId = 0;
        this.appliedQuantity = 0;
        this.loyaltyAmount = 0;
        this.lotusChargeAmount = 0;
    }
}

export class VoucherPromoteModel extends VoucherModel {
    constructor() {
        super();
        this.voucherId = 0;
        this.registerStartDate = null;
        this.registerEndDate = null;
        this.voucherPromoteHighlights = [];
    }
}

export class VoucherPromoteHighlightModel {
    constructor() {
        this.voucherId = 0;
        this.appProductVariantId = 0;
        this.voucherProductPrice = 0;
        this.mustLoyaltyAmount = 0;
        this.mustLotusCharge = 0;
    }
}

export class VoucherFreeShipModel extends VoucherModel {
    constructor() {
        super();
        this.voucherId = 0;
        this.minimumOrderValue = 0;
        this.maximumDiscount = 0;
    }
}

export class VoucherBuyDirectModel extends VoucherModel {
    constructor() {
        super();
        this.voucherId = 0;
        this.salePrice = 0;
        this.isPrintedProduct = true;
        this.maximumLoyalty = 0;
    }
}
