import TagsInput from "react-tagsinput";
import React, { useState, useEffect } from "react";
import PubSub from "pubsub-js";
import Breadcrumbs from "../based/Breadcrumbs";
import { MAINMENU, CONSTANTS, NOTIFY } from "../based/Constants";
import { Layout } from "../layout/Layout";
import Loading from "../based/Loading";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import { Notify } from "../based/Notify";
import TFUImage from "../based/inputs/Image";
import useFormValidate from "../hooks/useFormValidate";
import TFUSelectSubdivisions from "../based/TFUSelectSubdivisions";
import WebSettingsServices from "../based/services/WebSettingsServices";
import Sticky from "react-stickynode";
import TextNumber from "../based/inputs/TextNumber";
import Common from "../based/Common";
import { useRef } from "react";
import Request from "../based/Request";

const WebSetting = () => {
    const [loading, setLoading] = useState({ isLoading: false, msgLoading: "Đang tải dữ liệu..." });
    const [webSetting, setWebSetting] = useState({});
    const { addRef, isValid } = useFormValidate();
    const uploadRef = useRef(null);

    useEffect(() => {
        getData();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.WebSetting });
    }, []);

    const getData = async () => {
        setLoading({ isLoading: true, msgLoading: "Đang tải dữ liệu..." });
        const [err, data] = await WebSettingsServices.GetWebSettings();
        if (!err && data) {
            setLoading({ isLoading: false });
            setWebSetting(data);
        } else {
            setWebSetting({});
            setLoading({ isLoading: false });
            if (err && err.message) Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi: " + err.message);
        }
    };

    const handleInputChange = (name, value) => {
        webSetting[name] = value;
        setWebSetting({ ...webSetting });
    };

    const handleChangedImage = (name, url) => {
        webSetting[name] = url;
        setWebSetting({ ...webSetting });
    };

    const handleSave = async () => {
        if (isValid()) {
            setLoading({ isLoading: true, msgLoading: "Đang lưu dữ liệu..." });
            const [err, data] = await WebSettingsServices.SaveWebSettings(webSetting);
            if (!err && data) {
                setLoading({ isLoading: false });
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công.");
            } else {
                setLoading({ isLoading: false });
                Notify(NOTIFY.WARNING, "Có lỗi", "Lưu thất bại.");
            }
        }
    };

    const handleUploadChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.Convert(file).then((res) => {
                    if (res && res.data) {
                        let { fileResultUrl } = res.data;
                        if (fileResultUrl) window.location.href = fileResultUrl;
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Cấu hình Website" />
            <Loading show={loading.isLoading} msg={loading.msgLoading} />
            <Sticky enabled={true} top={70} bottomBoundary={4000} innerZ={2}>
                <div className="form-group text-right product-navigate-buttons">
                    <button type="button" className="btn btn-custom waves-effect w-xs waves-light btn-sm" onClick={() => handleSave()}>
                        <i className="fa fa-save m-r-5"></i> Lưu cấu hình
                    </button>
                </div>
            </Sticky>
            <div className="row">
                <div className="col-sm-12 pr-1">
                    <div className="card-box p-3">
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="title">Title:</label>
                                <Textbox ref={addRef} id="title" name="title" className="form-control" value={webSetting.title} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="keywords">Keywords:</label>
                                <Textbox ref={addRef} id="keywords" name="keywords" className="form-control" value={webSetting.keywords} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="ogSiteName">Og SiteName:</label>
                                <Textbox ref={addRef} id="ogSiteName" name="ogSiteName" className="form-control" value={webSetting.ogSiteName} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="ogUrl">Og Url:</label>
                                <Textbox ref={addRef} id="ogUrl" name="ogUrl" className="form-control" value={webSetting.ogUrl} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="ogTitle">Og Title:</label>
                                <Textbox ref={addRef} id="ogTitle" name="ogTitle" className="form-control" value={webSetting.ogTitle} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="description">Description:</label>
                                <TextArea ref={addRef} id="description" name="description" className="form-control" value={webSetting.description} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="ogDescription">Og Description:</label>
                                <TextArea ref={addRef} id="ogDescription" name="ogDescription" className="form-control" value={webSetting.ogDescription} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="facebookUrl">Facebook Url:</label>
                                <Textbox ref={addRef} id="facebookUrl" name="facebookUrl" className="form-control" value={webSetting.facebookUrl} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="twitterUrl">Twitter Url:</label>
                                <Textbox ref={addRef} id="twitterUrl" name="twitterUrl" className="form-control" value={webSetting.twitterUrl} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="googleUrl">Google Url:</label>
                                <Textbox ref={addRef} id="googleUrl" name="googleUrl" className="form-control" value={webSetting.googleUrl} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="instagramUrl">Instagram Url:</label>
                                <Textbox ref={addRef} id="instagramUrl" name="instagramUrl" className="form-control" value={webSetting.instagramUrl} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="rssUrl">Rss Url:</label>
                                <Textbox ref={addRef} id="rssUrl" name="rssUrl" className="form-control" value={webSetting.rssUrl} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phoneNumber">Phone Number:</label>
                                <Textbox ref={addRef} id="phoneNumber" name="phoneNumber" className="form-control" value={webSetting.phoneNumber} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="fax">Fax:</label>
                                <Textbox ref={addRef} id="fax" name="fax" className="form-control" value={webSetting.fax} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email">Email:</label>
                                <Textbox ref={addRef} id="email" name="email" className="form-control" email value={webSetting.email} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <TFUSelectSubdivisions
                            showProvince
                            showDistrict
                            showWard
                            provinceInputName="provinceCode"
                            districtInputName="districtCode"
                            wardInputName="wardCode"
                            provinceCode={webSetting.provinceCode}
                            districtCode={webSetting.districtCode}
                            wardCode={webSetting.wardCode}
                            onChanged={(obj) => setWebSetting({ ...webSetting, provinceCode: obj.provinceCode, districtCode: obj.districtCode, wardCode: obj.wardCode })}
                            addRef={addRef}
                            parentClassName="form-row"
                            childClassName="form-group col-md-6"
                        />
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="address">Address:</label>
                                <Textbox ref={addRef} id="address" name="address" className="form-control" value={webSetting.address} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="campaignBoostingLoyalty">Giá Loyalty Boosting</label>
                                <TextNumber name="campaignBoostingLoyalty" suffix="đồng" className="form-control" value={webSetting.campaignBoostingLoyalty} onChanged={handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="logo">Logo:</label>
                            <div className="bg-white">
                                <TFUImage image={webSetting.logo} onChanged={handleChangedImage} name="logo" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="favicon">Favicon:</label>
                            <div className="bg-white">
                                <TFUImage image={webSetting.favicon} onChanged={handleChangedImage} name="favicon" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="ogImage">Og Image:</label>
                            <div className="bg-white">
                                <TFUImage image={webSetting.ogImage} onChanged={handleChangedImage} name="ogImage" />
                            </div>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-md-6">
                                <label htmlFor="preventKeyword">Danh sách Keyword loại bỏ theo GOV:</label>
                                <TextArea ref={addRef} id="preventKeyword" name="preventKeyword" className="form-control" value={webSetting.preventKeyword} onChanged={handleInputChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="tagTrending">Danh sách Tag Trending</label>
                                <TagsInput
                                    addKeys={[13]}
                                    addOnPaste
                                    maxTags={10}
                                    inputProps={{ placeholder: "" }}
                                    value={webSetting.tagTrending || []}
                                    onChange={(tags) => {
                                        let newTags = tags.reduce((prev, val, idx) => {
                                            let value = Common.removeVietnameseFromString(val);
                                            value = `#${value.replace(/ /g, "")}`;
                                            return prev.indexOf(value) == -1 ? [...prev, value] : [...prev];
                                        }, []);
                                        setWebSetting({ ...webSetting, tagTrending: newTags });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="googleAnalytics">Xử lý PES/CSV:</label>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        uploadRef?.current?.click();
                                    }}
                                >
                                    Upload File
                                </button>

                                <input
                                    type={"file"}
                                    className="form-control d-none"
                                    ref={uploadRef}
                                    onChange={(e) => {
                                        handleUploadChange(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default WebSetting;
