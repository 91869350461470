import { DropdownModel } from "../based/PropsDefined";

export class WarehouseOrdersModel {
    constructor() {
        this.id = 0;
        this.expectedWarehouseId = 0;
        this.expectedSlotIndex = 0;
        this.expectedTimeStockIn = new Date();
        this.stockInTime = new Date();
        this.orderCode = "";
        this.estimatedTime = "";
        this.statusId = 0;
        this.stockOrderType = 0;
        this.thirdPartyId = 0;
        this.shippingServiceId = 0;
        this.products = [];
        this.remark = "";

        /**
         * these properties is utilize display
         */
        this.status = "";
        this.productCodes = "";
        this.totalGoodProducts = 0;
        this.totalBadProducts = 0;
        this.createdDate = new Date();
        this.thirdPartyName = "";
        this.nextStatus = [new DropdownModel()];
        this.statusColor = "";

        this.expectedTimeStockOut = new Date();
        this.stockOutTime = new Date();
    }
}
export class WarehouseModel {
    constructor() {
        this.id = 0;
        this.displayName = "";
        this.location = "";
        this.totalSlots = 0;
        this.isActive = true;
    }
}
