import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import FindingProductSelection from "../../affiliate/config-commission/components/FindingProductSelection";
import Common from "../../based/Common";
import CommonModal from "../../based/Modal";
import BaseServices from "../../based/services/BaseServices";
import useFormValidate from "../../hooks/useFormValidate";

function KeywordCollectionModal(props) {
    const [isBusy, setBusy] = useState(false);
    const [data, setData] = useState({});
    const [keywords, setKeywords] = useState(new Set());
    const [products, setProducts] = useState(new Map());
    const [msgModalLoading, setMsgModalLoading] = useState("");
    const [isValid, setIsValid] = useState(true);
    useEffect(() => {
        if (props.isShow && props.dataId > 0) {
            _fetchData();
        } else {
            setData({});
            setProducts(new Map());
        }
    }, [props.isShow]);

    function _handleSelectProduct(productItem) {
        if (products.size < 1 && !products.has(productItem.id)) {
            let newSet = new Map(products.entries());
            newSet.set(productItem.id, productItem);
            setProducts(newSet);
        }
    }
    async function _handleSubmit() {
        if (keywords.size > 0) {
            setBusy(true);
            setMsgModalLoading("Đang lưu...");
            data.keywordsVIE = Array.from(keywords.values());
            data.suggestSetIds = Array.from(products.keys());
            const [err, resData] = await BaseServices.Post(`/api/searchEngine/save-keyword-collection`, data);
            setBusy(false);
            if (!err) {
                props.onSubmit(true);
            } else {
                props.onSubmit(false);
            }
        } else {
            setIsValid(false);
        }
    }
    async function _fetchData() {
        setBusy(true);
        setMsgModalLoading("Đang tải dữ liệu...");
        const [err, resData] = await BaseServices.Get(`/api/searchEngine/get-keyword-collection/${props.dataId}`);
        setBusy(false);
        if (!err) {
            let products = resData.setProducts;
            let keywords = resData.keywordsVIE;
            setKeywords(new Set(keywords));
            let setPrd = new Map();
            for (let i = 0; i < products.length; i++) {
                const element = products[i];
                setPrd.set(element.id, { id: element.id, name: element.name, productAvatar: element.productAvatar });
            }
            setProducts(setPrd);
            setData(resData);
        } else {
        }
    }

    function _handleSelectProduct(productItem) {
        if (!products.has(productItem.id)) {
            let newSet = new Map(products.entries());
            newSet.set(productItem.id, productItem);
            setProducts(newSet);
        }
    }

    function _handleRemoveProduct(item) {
        let newSet = new Map(products.entries());
        newSet.delete(item.id);
        setProducts(newSet);
    }

    function _renderProducts() {
        let array = Array.from(products.values());
        return array.map((item, index) => (
            <tr key={index} style={{ height: 70, borderBottom: "1px solid #ccc" }}>
                <td width={100}>
                    <img src={item.productAvatar} style={{ width: "100%" }} />
                </td>
                <td>{item.name}</td>
                <td width={50}>
                    <button className="btn btn-danger btn-sm" onClick={() => _handleRemoveProduct(item)}>
                        Xóa
                    </button>
                </td>
            </tr>
        ));
    }

    function _handleKeywordChanged(tags) {
        setIsValid(true);
        for (let i = 0; i < tags.length; i++) {
            const element = tags[i];
            tags[i] = element.substring(0, 50);
        }
        let newSet = new Set(tags);
        setKeywords(newSet);
    }
    return (
        <CommonModal size="lg" show={props.isShow} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msgModalLoading} title="Cấu hình bộ từ khóa tìm kiếm" onSave={_handleSubmit} onClose={() => props.onClose()}>
            <div className="form-row">
                <div className="form-group w-100">
                    <label htmlFor="smpKeyword" className="required">
                        Từ khóa
                    </label>
                    <TagsInput
                        value={Array.from(keywords.values())}
                        onChange={_handleKeywordChanged}
                        focusedClassName={"react-tagsinput--focused-custom"}
                        inputProps={{ className: "react-tagsinput-input-custom", placeholder: "Thêm từ khóa" }}
                        tagProps={{ className: "react-tagsinput-tag-custom" }}
                    />
                    <span style={{ fontStyle: "italic" }}>
                        (<strong>Enter</strong> để thêm từ khóa mới tối đa 50 ký tự)
                    </span>
                    {!isValid ? <p className="text-danger">Dữ liệu không được để trống</p> : null}
                </div>
            </div>
            <div className="form-row">
                <div className="form-group w-100">
                    <label className="required">Các sản phẩm được đề xuất khi tìm kiếm chứa các keyword bên trên:</label>
                    <FindingProductSelection onSelectItem={_handleSelectProduct} isSetProduct />
                    {products && products.size > 0 && (
                        <div>
                            <table className="table table-bordered" style={{ width: "100%" }}>
                                <tbody>{_renderProducts()}</tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </CommonModal>
    );
}

export default KeywordCollectionModal;
