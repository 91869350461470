import React, { useEffect, useState } from "react";
import CommonTable from "../based/CommonTable";
import { INITPAGING } from "../based/Constants";
import Loading from "../based/Loading";
import AnalyticServices from "../based/services/AnalyticServices";

export default function PageCountManagement(props) {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, fromDate: new Date(), toDate: new Date() });

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setBusy(true);
        const [err, data] = await AnalyticServices.GetPageCountPaging(newPaging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setPaging(newPaging);
            setDatas([]);
            setBusy(false);
        }
    }

    function _renderAction() {}

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className='center'>Page URL</th>
                    <th className='center'>Count</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return datas && datas.length > 0 ? (
            <tbody>
                {datas.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.pageUrl}</td>
                            <td>{item.count}</td>
                        </tr>
                    );
                })}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colSpan='8' className='center'>
                        Không có dữ liệu
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <CommonTable data={datas} paging={paging} searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
        </React.Fragment>
    );
}
