import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import Common from "../based/Common";
import { INITPAGING, NOTIFY, SHIPPING_SERVICES_CODE } from "../based/Constants";
import { Notify } from "../based/Notify";
import OrderServices from "../based/services/OrderServices";
import ShippingServices from "../based/services/ShippingServices";
import LogisticContext from "../context/LogisticContext";
import RanusSVG from "./assets/Ranus.svg";
import RanusLogistic from "./assets/RanusLogistic.png";
import SPX from "./assets/SPX.svg";
import VietnamPost from "./assets/VietnamPost.png";
import QRInput from "./QRInput";

export const switchCode = (code) => {
    switch (code) {
        case SHIPPING_SERVICES_CODE.RANUS:
            return RanusSVG;
        case SHIPPING_SERVICES_CODE.SHOPEEXPRESS:
            return SPX;
        case SHIPPING_SERVICES_CODE.VNPOST:
            return VietnamPost;
        default:
            return RanusSVG;
    }
};

function LogisticMainPage(props) {
    const [showQrInput, setShowQrInput] = useState(false);
    const history = useHistory();
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 50, listShippingCompanyId: [] });
    const { listModel, setListModel, setBulkModel } = useContext(LogisticContext);
    const [listShippingCompany, setListShippingCompany] = useState([]);

    useEffect(() => {
        getData(paging);
        getShippingCompany();
    }, []);

    const getData = async (page) => {
        const [err, data] = await OrderServices.GetLogisticBulkBookingModels(page);
        if (!err && data) {
            setListModel(data.listObjects);
            setPaging(data.paging);
        } else {
            setListModel([]);
            setPaging(page);
        }
    };

    const getShippingCompany = async () => {
        const [err, data] = await ShippingServices.GetShippingServices();
        if (!err && data) {
            setListShippingCompany(data);
        }
    };

    const allocateOrder = async (models) => {
        const [err, data] = await OrderServices.AllocateBulkBooking(models);
        if (!err && data) {
            console.log("data", data);
            setBulkModel(data);
            history.push("/logistic-filter");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Cập nhật thất bại");
        }
    };

    const allActive = useMemo(() => {
        return listModel && listModel.length > 0 && listModel.every((x) => x.isActive);
    }, [listModel]);

    return (
        <React.Fragment>
            <GlobalStyle />
            <Wrapper>
                <div className="header">
                    <div className="header-left">
                        <div
                            className="icon"
                            onClick={(e) => {
                                history.push("/logistic");
                            }}
                        >
                            <img src={RanusLogistic} />
                        </div>
                    </div>
                    <div className="header-right">
                        <div
                            className="history-btn"
                            onClick={(e) => {
                                history.push("/logistic-history");
                            }}
                        >
                            <span>Lịch sử tạo</span>
                            <div className="icon">
                                <ClockIcon />
                            </div>
                        </div>
                    </div>
                </div>
                {listShippingCompany && (
                    <div className="filter-section">
                        <label htmlFor="">Lọc theo:</label>
                        <div className="filter-list">
                            {listShippingCompany.map((x, index) => {
                                const active = paging.listShippingCompanyId.includes(x.id);
                                return (
                                    <div
                                        key={index}
                                        className={`filter-item ${active ? "active" : ""}`}
                                        onClick={(e) => {
                                            let pg = { ...paging };
                                            let listCompanyId = pg.listShippingCompanyId;
                                            if (listCompanyId.includes(x.id)) {
                                                listCompanyId = listCompanyId.filter((y) => y !== x.id);
                                            } else {
                                                listCompanyId.push(x.id);
                                            }
                                            getData({ ...paging, listShippingCompanyId: listCompanyId });
                                        }}
                                    >
                                        <p className="title">{x.name}</p>
                                        {active && (
                                            <div className="check-icon">
                                                <CheckIcon />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={allActive}
                                        onChange={(e) => {
                                            let temp = [...listModel];
                                            console.log("e.target.checked", e.target.checked);
                                            temp.forEach((x) => (x.isActive = e.target.checked));
                                            setListModel(temp);
                                        }}
                                    />
                                </th>
                                <th className="title">Đơn sẵn sàng giao</th>
                                <th className="method">ĐVVC</th>
                                <th className="status">Tổng tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listModel && listModel.length > 0 ? (
                                listModel.map((x, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            onClick={(e) => {
                                                let temp = [...listModel];
                                                temp[index].isActive = !temp[index].isActive;
                                                setListModel(temp);
                                            }}
                                        >
                                            <td>
                                                <input type="checkbox" checked={x.isActive} onChange={(e) => {}} />
                                            </td>
                                            <td>
                                                <p className="order-id">{x.id}</p>
                                                <p className="order-number">Mã đơn Ranus: {x.orderNumber}</p>
                                                <p className="customer-info">
                                                    {x.userName} - {x.phoneNumber}
                                                </p>
                                                <p className="customer-address">{x.fullAddress}</p>
                                            </td>
                                            <td>
                                                <div className="icon justify-content-center">
                                                    <img src={switchCode(x.shippingCompanyCode)} />
                                                </div>
                                            </td>
                                            <td>
                                                <p>{Common.formatCurrency(x.finallyPrice)}</p>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={1000}>Không có dữ liệu</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="bottom-bar">
                    <div
                        className="create-button"
                        onClick={(e) => {
                            let models = listModel.filter((x) => x.isActive);
                            allocateOrder(models);
                        }}
                    >
                        <span>Lọc đơn</span>
                    </div>
                </div>
            </Wrapper>
            <FloatButtonWrapper>
                <div
                    className="float-button"
                    onClick={(e) => {
                        setShowQrInput(true);
                    }}
                >
                    <div className="float-top">
                        <div className="icon">
                            <QRICon />
                        </div>
                    </div>
                    <div className="float-bottom">Quét mã</div>
                </div>
            </FloatButtonWrapper>
            <QRInput
                show={showQrInput}
                onClose={() => {
                    setShowQrInput(false);
                }}
            />
        </React.Fragment>
    );
}

export default LogisticMainPage;

const FloatButtonWrapper = styled.div`
    .float-button {
        display: flex;
        position: fixed;
        bottom: 95px;
        right: 10px;
        flex-direction: column;
        background: #28b259;
        width: 62px;
        height: 62px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .float-bottom {
        color: #fff;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 5px;
    }
    .icon {
        display: flex;
    }
`;

const Wrapper = styled.div`
    height: 100vh;
    padding: 23px 16px 60px 16px;
    input,
    textarea,
    select {
        font-size: 16px;
    }

    .filter-section {
        margin-top: 22px;
        display: flex;
        align-items: center;
    }

    .filter-list {
        display: flex;
        margin-left: 8px;
        align-items: center;
    }

    .filter-section label {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 !important;
    }

    .filter-item {
        border-radius: 8px;
        /* border: 1px solid #EDEDED; */
        background: #fff;
        padding: 10px;
        margin: 0 6px;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .filter-item p.title {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .filter-item.active {
        border: 1px solid #28b259 !important;
    }

    .filter-item.active .title {
        color: #28b259;
    }

    .body {
        padding-bottom: 60px;
    }
    .header {
        display: flex;
    }

    .header-right {
        margin-left: auto;
    }

    .history-btn {
        display: flex;
        align-items: center;
    }

    .history-btn span {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .history-btn .icon {
        margin-left: 6px;
    }
    .bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    }

    .create-button {
        border-radius: 12px;
        background: #28b259;
        text-align: center;
        padding: 14px;
        width: 100%;
        cursor: pointer;
    }

    .create-button span {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    table.table.table-bordered {
        margin-top: 16px;
        border-radius: 10px 0px 0px 0px;
    }

    thead {
        border-radius: 10px 0px 0px 0px;
        border: 1px solid #ededed;
        background: #878787;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        line-height: 20px; /* 166.667% */
        text-align: center;
    }

    th.title {
        align-self: center;
    }

    tr th {
        vertical-align: middle !important;
    }

    td p {
        margin: 0;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    p.customer-info {
        color: #a0a0a0;
    }

    p.customer-address {
        color: #a0a0a0;
    }

    .icon {
        display: flex;
    }

    p.shipper-detail {
        display: flex;
    }

    span.d-flex {
        color: #13aaff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-decoration-line: underline;
    }

    .shipper-detail .icon {
        margin-left: 3px;
    }

    .shipper-detail span.d-flex {
        margin-left: 3px;
    }

    tr td {
        vertical-align: middle !important;
    }

    p.method-type {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    p.method-date {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }

    p.method-time {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }

    th.method {
        width: 85px;
    }

    p.status-name {
        color: #28b259;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    th.status {
        width: 80px;
    }

    .status-button {
        display: flex;
        text-align: center;
        justify-content: space-around;
        margin-top: 12px;
    }

    .copy-link {
        display: flex;
    }

    .share-link {
        display: flex;
    }

    .bottom-bar {
        display: flex;
        position: fixed;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
        left: 0;
        bottom: 0;
        right: 0;
        justify-content: space-around;
        padding: 4px 8px 4px 8px;
    }

    .total-cound {
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    p.title {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 !important;
    }

    .bar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 11px 13px;
    }

    .bar-item.active {
        background: #28b259;
        color: white;
        border-radius: 10px 10px 0 0;
    }

    .bar-item.active {
    }

    .bar-item.active p.title {
        color: white;
    }
    .filter-item {
        position: relative;
    }

    .check-icon {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const FontIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_5702_1596)">
                <path
                    d="M14.6669 11.2807V13.2807C14.6677 13.4664 14.6297 13.6502 14.5553 13.8203C14.4809 13.9904 14.3718 14.1431 14.235 14.2686C14.0982 14.3941 13.9367 14.4897 13.7608 14.5492C13.5849 14.6087 13.3985 14.6308 13.2136 14.614C11.1622 14.3911 9.19161 13.6901 7.46028 12.5674C5.8495 11.5438 4.48384 10.1782 3.46028 8.56738C2.3336 6.82819 1.63244 4.84805 1.41361 2.78738C1.39695 2.60303 1.41886 2.41722 1.47795 2.2418C1.53703 2.06637 1.63199 1.90518 1.75679 1.76846C1.88159 1.63175 2.03348 1.52252 2.20281 1.44773C2.37213 1.37294 2.55517 1.33422 2.74028 1.33405H4.74028C5.06382 1.33086 5.37748 1.44543 5.62279 1.6564C5.8681 1.86737 6.02833 2.16035 6.07361 2.48072C6.15803 3.12076 6.31458 3.7492 6.54028 4.35405C6.62998 4.59266 6.64939 4.85199 6.59622 5.1013C6.54305 5.35061 6.41952 5.57946 6.24028 5.76072L5.39361 6.60738C6.34265 8.27641 7.72458 9.65835 9.39361 10.6074L10.2403 9.76072C10.4215 9.58147 10.6504 9.45795 10.8997 9.40478C11.149 9.35161 11.4083 9.37102 11.6469 9.46072C12.2518 9.68642 12.8802 9.84297 13.5203 9.92738C13.8441 9.97307 14.1399 10.1362 14.3513 10.3857C14.5627 10.6352 14.6751 10.9538 14.6669 11.2807Z"
                    stroke="#13AAFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5702_1596">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_5720_3563)">
                <path
                    d="M8.33301 10.834C8.69088 11.3124 9.14747 11.7083 9.6718 11.9947C10.1961 12.2812 10.7759 12.4516 11.3719 12.4942C11.9678 12.5369 12.566 12.4509 13.1258 12.2421C13.6856 12.0333 14.1939 11.7065 14.6163 11.284L17.1163 8.78396C17.8753 7.99811 18.2953 6.9456 18.2858 5.85312C18.2763 4.76063 17.8381 3.71558 17.0656 2.94304C16.2931 2.17051 15.248 1.73231 14.1555 1.72281C13.063 1.71332 12.0105 2.1333 11.2247 2.89229L9.79134 4.31729"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6668 9.16702C11.309 8.68858 10.8524 8.2927 10.328 8.00623C9.80371 7.71977 9.22391 7.54942 8.62796 7.50674C8.03201 7.46406 7.43384 7.55004 6.87405 7.75887C6.31425 7.96769 5.8059 8.29446 5.3835 8.71702L2.8835 11.217C2.12451 12.0029 1.70453 13.0554 1.71402 14.1479C1.72352 15.2403 2.16172 16.2854 2.93426 17.0579C3.70679 17.8305 4.75184 18.2687 5.84433 18.2782C6.93681 18.2877 7.98932 17.8677 8.77517 17.1087L10.2002 15.6837"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5720_3563">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const ShareIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.8323 13.557L17.5556 9.12664C17.9769 8.73141 17.9767 8.0624 17.5551 7.66746L12.8318 3.2431C12.193 2.64475 11.1481 3.09768 11.1481 3.97292V5.84184C11.0735 5.851 11.0082 5.86015 10.9428 5.86015C9.7577 5.94257 8.62855 6.22646 7.54605 6.7118C3.9253 8.351 1.66699 11.8034 1.66699 15.7136V15.8792C1.66699 16.6133 2.69484 16.8292 3.03877 16.1807C4.41988 13.5708 6.60354 11.9316 9.50574 11.2265C10.0377 11.0891 10.5789 11.0433 11.1481 10.9517V12.8277C11.1481 13.7032 12.1937 14.156 12.8323 13.557Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const QRICon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_5698_1242)">
                <path d="M3.52832 9.07435H9.05763V3.51758H3.52832V9.07435ZM5.28332 5.28226H7.30167V7.31063H5.28332V5.28226Z" fill="white" />
                <path d="M16.4717 3.51758H10.9424V9.07435H16.4717V3.51758ZM14.7167 7.30968H12.6983V5.2813H14.7167V7.30968Z" fill="white" />
                <path d="M3.52832 16.4826H9.05763V10.9258H3.52832V16.4826ZM5.28332 12.6905H7.30167V14.7188H5.28332V12.6905Z" fill="white" />
                <path d="M12.801 10.9258H10.9434V12.7927H12.801V10.9258Z" fill="white" />
                <path d="M12.801 14.6113H10.9434V16.4782H12.801V14.6113Z" fill="white" />
                <path d="M14.6052 14.6073V16.4771H16.4629V12.793H14.6062H14.6052H12.8008V14.6073H14.6052Z" fill="white" />
                <path d="M1.755 1.76372H6.38969V0H0V6.42048H1.755V1.76372Z" fill="white" />
                <path d="M13.6104 0V1.76372H18.245V6.42048H20V0H13.6104Z" fill="white" />
                <path d="M18.245 18.2368H13.6104V20.0006H20V13.5801H18.245V18.2368Z" fill="white" />
                <path d="M1.755 13.5801H0V20.0006H6.38969V18.2368H1.755V13.5801Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_5698_1242">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const ClockIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 6.5V12.5L16 14.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

const CheckIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.046875 21.3906V8.10156C0.046875 3.68328 3.6286 0.101562 8.04688 0.101562H21.5605L0.046875 21.3906Z" fill="#28B259" />
            <path d="M3.50684 6.37891L6.50684 9.37891L11.5068 4.37891" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
const GlobalStyle = createGlobalStyle`
div#root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
}
`;
