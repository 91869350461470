import React, { useContext } from "react";
import TFUTooltip from "../../based/TFUTooltip";
import CheckBox from "../../based/inputs/CheckBox";
import TextNumber from "../../based/inputs/TextNumber";
import Textbox from "../../based/inputs/Textbox";
import { ConfigAttributeModalContext } from "../../context/ConfigAttributeModalContext";

const PrintFrameConfig = (props) => {
    const { printFrame, setPrintFrame, attributeId, isReadOnly } = useContext(ConfigAttributeModalContext);
    const { addRef } = props;

    const handleInputChange = (name, value, index) => {
        let data = [...printFrame];
        let current = data[index];
        if (name == "isDefault") data.map((item) => (item.isDefault = false));
        current[name] = value;
        setPrintFrame(data);
    };

    return (
        <React.Fragment>
            <div className="col-md-12 card-box p-3">
                <div className="row">
                    <div className="col-sm-12 pr-1">
                        <div className="card-box p-3">
                            <div className="form-group row">
                                <div className="col-md-2 center row">
                                    <label className="col-md-12 center">Thông số mặc định hệ thống</label>
                                    <label htmlFor="isDefault" className="col-md-7 required">
                                        Mặc định tiêu chuẩn
                                    </label>
                                    <label htmlFor="attributeValueName" className="col-md-4 required">
                                        Tên
                                    </label>
                                </div>
                                <div className="col-md-3 center row">
                                    <label className="col-md-12 center">Thông số áo</label>
                                    <div className="col-md-6 center">
                                        <TFUTooltip content="Chiều ngang của khung Mockup" target="widthFrame">
                                            <label id="widthFrame" className="required" htmlFor="widthFrame">
                                                Kích thước khung in ngang
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                    <div className="col-md-6 center">
                                        <TFUTooltip content="Chiều dọc của khung Mockup" target="heightFrame">
                                            <label id="heightFrame" className="required" htmlFor="heightFrame">
                                                Kích thước khung in dọc
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                </div>
                                <div className="col-md-2 center row">
                                    <label className="col-md-12 center">Thông số Artwork mặt trước</label>
                                    <div className="col-md-6 center">
                                        <TFUTooltip content="Khoảng cách đặt khung thiết kế minimum creator có thể config" target="frontMin">
                                            <label id="frontMin" className="required" htmlFor="frontMin">
                                                Cách cổ Min
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                    <div className="col-md-6 center">
                                        <TFUTooltip content="Khoảng cách đặt khung thiết kế maximum creator có thể config" target="frontMax">
                                            <label id="frontMax" className="required" htmlFor="frontMax">
                                                Cách cổ Max
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                </div>
                                <div className="col-md-2 center row">
                                    <label className="col-md-12 center">Thông số Artwork mặt sau</label>
                                    <div className="col-md-6 center">
                                        <TFUTooltip content="Khoảng cách đặt khung thiết kế minimum creator có thể config" target="backMin">
                                            <label id="backMin" className="required" htmlFor="backMin">
                                                Cách cổ Min
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                    <div className="col-md-6 center">
                                        <TFUTooltip content="Khoảng cách đặt khung thiết kế maximum creator có thể config" target="backMax">
                                            <label id="backMax" className="required" htmlFor="backMax">
                                                Cách cổ Max
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                </div>
                                <div className="col-md-1 center">
                                    <label className="col-md-12 center">Tỉ lệ điều chỉnh Artwork </label>
                                    <TFUTooltip content="Tỉ lệ Scale của ảnh" target="scale">
                                        <label id="scale" className="required" htmlFor="scale">
                                            Tỉ lệ
                                        </label>
                                    </TFUTooltip>
                                </div>
                                <div className="col-md-2 center row">
                                    <label className="col-md-12 center">Thông số Logo (Mặt trước, mặt sau)</label>
                                    <div className="col-md-4 center">
                                        <TFUTooltip content="Khoảng cách từ cổ áo tới trục ngang logo" target="logoHeightCollar">
                                            <label id="logoHeightCollar" className="required" htmlFor="logoHeightCollar">
                                                Chiều dọc Logo (Mặt trước)
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                    <div className="col-md-4 center">
                                        <TFUTooltip content="Khoảng cách từ cổ áo tới trục dọc logo" target="logoWidthCollar">
                                            <label id="logoWidthCollar" className="required" htmlFor="logoWidthCollar">
                                                Chiều ngang Logo (Mặt trước)
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                    <div className="col-md-4 center">
                                        <TFUTooltip content="Khoảng cách từ cổ áo tới trục ngang logo" target="logoHeightBackCollar">
                                            <label id="logoHeightBackCollar" className="required" htmlFor="logoHeightBackCollar">
                                                Chiều dọc Logo (Mặt sau)
                                            </label>
                                        </TFUTooltip>
                                    </div>
                                </div>
                            </div>
                            {printFrame.length > 0
                                ? printFrame.map((item, idx) => {
                                      return (
                                          <div className="form-group row" key={idx}>
                                              <div className="col-md-2 row">
                                                  <div className="col-md-6">
                                                      <CheckBox disabled={isReadOnly} checked={item.isDefault} name="isDefault" label=" " onCheckedChange={(name, value) => handleInputChange(name, value, idx)} classDiv="center" />
                                                  </div>
                                                  <Textbox
                                                      id="attributeValueName"
                                                      ref={addRef}
                                                      readOnly={true}
                                                      name="attributeValueName"
                                                      className="form-control col-md-6"
                                                      value={item.attributeValueName}
                                                      onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                      required
                                                  />
                                              </div>
                                              <div className="col-md-3 row">
                                                  <div className="col-md-6">
                                                      <TextNumber
                                                          id="widthFrame"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="widthFrame"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.widthFrame}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                                  <div className="col-md-6">
                                                      <TextNumber
                                                          id="heightFrame"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="heightFrame"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.heightFrame}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                              </div>
                                              <div className="col-md-2 row">
                                                  <div className="col-md-6">
                                                      <TextNumber
                                                          id="frontMin"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="frontMin"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.frontMin}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                                  <div className="col-md-6">
                                                      <TextNumber
                                                          id="frontMax"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="frontMax"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.frontMax}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                              </div>
                                              <div className="col-md-2 row">
                                                  <div className="col-md-6">
                                                      <TextNumber
                                                          id="backMin"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="backMin"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.backMin}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                                  <div className="col-md-6">
                                                      <TextNumber
                                                          id="backMax"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="backMax"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.backMax}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                              </div>
                                              <div className="col-md-1">
                                                  <TextNumber
                                                      id="scale"
                                                      ref={addRef}
                                                      readOnly={isReadOnly}
                                                      name="scale"
                                                      className="form-control required col-md-12"
                                                      required
                                                      max={1}
                                                      decimal={true}
                                                      value={item.scale}
                                                      onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                  />
                                              </div>
                                              <div className="col-md-2 row">
                                                  <div className="col-md-4">
                                                      <TextNumber
                                                          id="logoHeightCollar"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="logoHeightCollar"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.logoHeightCollar}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                                  <div className="col-md-4">
                                                      <TextNumber
                                                          id="logoWidthCollar"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="logoWidthCollar"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.logoWidthCollar}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                                  <div className="col-md-4">
                                                      <TextNumber
                                                          id="logoHeightBackCollar"
                                                          ref={addRef}
                                                          readOnly={isReadOnly}
                                                          name="logoHeightBackCollar"
                                                          className="form-control required"
                                                          min={0}
                                                          required
                                                          value={item.logoHeightBackCollar}
                                                          onChanged={(name, value) => handleInputChange(name, value, idx)}
                                                          decimal
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PrintFrameConfig;
