import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../based/Breadcrumbs';
import TextNumber from '../based/inputs/TextNumber';
import Loading from '../based/Loading';
import BaseServices from '../based/services/BaseServices';
import { Layout } from '../layout/Layout';
import { INITPAGING } from '../based/Constants';
import UnPaidLoyaltyTable from './components/UnPaidLoyaltyTable';
import PaidLoyaltyTable from './components/PaidLoyaltyTable';
function CreatorLoyaltyPayment(props) {
    const [tabIndex, setTabindex] = useState(0)
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Tiền bản quyền' parentPage='Tài chính' hrefParent='/finance' />
            <div className="row">
                <div className="col-md-12">
                    <div className="detail-tab px-0 text-center">
                        <div className="nav nav-tabs">
                            <div className="nav-item">
                                <a href="javascript:void(0)" className={tabIndex == 0 ? "nav-link active" : 'nav-link'} onClick={() => setTabindex(0)}>
                                    <span className="d-none d-sm-block">Chưa thanh toán</span>
                                </a>
                            </div>
                            <div className="nav-item">
                                <a href="javascript:void(0)" className={tabIndex == 1 ? "nav-link active" : 'nav-link'} onClick={() => setTabindex(1)}>
                                    <span className="d-none d-sm-block">Đã thanh toán</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    {
                        tabIndex == 0 && <UnPaidLoyaltyTable />
                    }
                    {
                        tabIndex == 1 && <PaidLoyaltyTable />
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreatorLoyaltyPayment;