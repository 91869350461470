import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { Layout } from '../layout/Layout';
import { CONSTANTS, MAINMENU, SUBMENU, NOTIFY } from '../based/Constants';
import Breadcrumbs from '../based/Breadcrumbs';
import WarehouseInfo from './WarehouseInfo';
import ProductItem from './components/ProductItem';
import SupplierInfo from './SupplierInfo';
import { WarehouseOrdersModel } from '../models/WarehouseOrderModel';
import { StockInContext, WarehouseInfoModel, WarehouseProductModel, SupplierInfoModel } from '../context/StockInContext';
import ProductServices from '../based/services/ProductServices';
import WarehouseServices from '../based/services/WarehouseServices';
import Common from '../based/Common';
import useFormValidate from '../hooks/useFormValidate';
import { Notify } from '../based/Notify';

export default function StockIn(props) {
  const [isBusy, setIsBusy] = useState(true);
  const [isDefinedSlot, setIsDefinedSlot] = useState(false);
  const [warehouseInfo, setWarehouseInfo] = useState(new WarehouseInfoModel());
  const [supplierInfo, setSupplierInfo] = useState(new SupplierInfoModel());
  const [products, setProducts] = useState([new WarehouseProductModel()]);
  const [productList, setProductList] = useState([]);
  const { addRef, isValid } = useFormValidate();

  useEffect(() => {
    setIsBusy(false);
    let warehouseId = props.match.params.warehouseId;
    let slotId = props.match.params.slotId;
    if (warehouseId > 0 && slotId > 0) setIsDefinedSlot(true);
    _getDropdownProducts();
    PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, {
      mainMenu: MAINMENU.InboundOutboundManage,
      subMenu: SUBMENU.InboundOrders,
    });
  }, []);

  async function _getDropdownProducts() {
    let [err, data] = await ProductServices.GetProductsDropdown();
    // Nhớ sửa lại cái API trên, lấy value là id thôi chứ không cần prefix product type
    const options = data.map((x) => ({
      ...x,
      value: x.value.split('_')[1],
    }));
    if (!err) setProductList(options);
  }

  function _handleAddProduct() {
    products.push(new WarehouseProductModel());
    setProducts([...products]);
  }

  async function _handleSave() {
    let isLocalValid = _isLocalValid();
    if (isValid() && isLocalValid) {
      var model = new WarehouseOrdersModel();
      model.expectedWarehouseId = warehouseInfo.id;
      model.expectedSlotCode = warehouseInfo.slotCode;
      model.expectedTimeStockIn = Common.formatDate(warehouseInfo.expectedTime, 'datetime');
      model.createdDate = new Date();
      model.thirdPartyId = supplierInfo.id;
      model.products = products;
      let [err, data] = await WarehouseServices.StockIn(model);
      if (!err) props.history.push('/stock-in-history');
    }
  }
  function _isLocalValid() {
    let updateProducts = [...products];
    if (updateProducts.length < 1) {
      Notify(NOTIFY.ERROR, 'Lỗi', 'Chưa có sản phẩm trong đơn');
      return false;
    }
    updateProducts.forEach(function (p) {
      if (p.productName === '') {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Bạn chưa chọn sản phẩm');
        return false;
      }
      if (p.quantity === 0) {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Số lượng sản phẩm nhập phải lớn hơn 0');
        return false;
      }
    });
    let filterData = updateProducts.map((item, idx) => {
      return JSON.stringify({ variant: item.productId });
    });
    let isNotValid = filterData.length !== new Set(filterData).size;
    if (isNotValid) {
      Notify(NOTIFY.ERROR, 'Lỗi', 'Sản phẩm trùng, xin kiểm tra lại');
      return false;
    } else return true;
  }
  return (
    <React.Fragment>
      <Breadcrumbs currentPage="Lệnh nhập hàng" />
      <StockInContext.Provider value={{ warehouseInfo, setWarehouseInfo, supplierInfo, setSupplierInfo, products, setProducts }}>
        <div className="container-fluid p-0">
          <div className="card-box margin-bottom-10 padding-10">
            <WarehouseInfo readOnly={isDefinedSlot} addRef={addRef} />
          </div>
          <div className="card-box margin-bottom-10 padding-10">
            <SupplierInfo addRef={addRef} />
          </div>
          <div className="card-box margin-bottom-10">
            <div>
              <table className="table table-bordered m-b-0">
                <thead className="thead-light">
                  <tr>
                    <th className="cursor-pointer center" width="300">
                      Mã sản phẩm<span className="required-field-icon">&nbsp;*</span>
                    </th>
                    <th className="cursor-pointer center" width="400">
                      Tên sản phẩm
                    </th>
                    <th className="cursor-pointer center" width="200">
                      Số lượng nhập<span className="required-field-icon">&nbsp;*</span>
                    </th>
                    <th className="cursor-pointer center" width="200">
                      Giá nhập<span className="required-field-icon">&nbsp;*</span>
                    </th>
                    <th className="cursor-pointer center" width="200"></th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.length > 0 &&
                    products.map((product, index) => {
                      return <ProductItem addRef={addRef} index={index} key={index} productList={productList} productItem={product} />;
                    })}
                  <tr>
                    <td colSpan="8" className="text-right">
                      <button className="btn btn-primary btn-sm" onClick={_handleAddProduct}>
                        Thêm sản phẩm
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12">
              <div className="text-right margin-right-10">
                <button className="btn btn-custom" onClick={_handleSave}>
                  Lưu
                </button>
              </div>
            </div>
          </div>
        </div>
      </StockInContext.Provider>
    </React.Fragment>
  );
}
