import Request from "../Request";

const TransactionServices = {
  GetNextTransactionStatus: async (obj) => {
    try {
      const res = await Request.Get(
        "/api/transactions/get-next-transaction-status/" + obj
      );
      if (res.success) return [null, res.data];
      return [res, null];
    } catch (error) {
      return [error, null];
    }
  },
  GetTransactionDetail: async (obj) => {
    try {
      const res = await Request.Get("/api/transactions/get/" + obj);
      if (res.success) return [null, res.data];
      return [res, null];
    } catch (error) {
      return [error, null];
    }
  },
  GetWarOrderByTransactionId: async (obj) => {
    try {
      const res = await Request.Get(
        "/api/transactions/get-war-order-by-transaction-id/" + obj
      );
      if (res.success) return [null, res.data];
      return [res, null];
    } catch (error) {
      return [error, null];
    }
  },
  GetAgeOrderTransactionByTransactionId: async (obj) => {
    try {
      const res = await Request.Get(
        "/api/transactions/get-age-order-transaction-by-transaction-id/" + obj
      );
      if (res.success) return [null, res.data];
      return [res, null];
    } catch (error) {
      return [error, null];
    }
  },
  SetStatusProcessing: async(obj) =>{
    try {
      const res = await Request.Get("/api/transactions/set-status-processing/" + obj)
      if(res.success) return [null,res.data]
      return [res,null]
    } catch (err) {
      return [err,null]
    }
  },
};

export default TransactionServices;
