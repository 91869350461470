import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import { NOTIFY, PLATFORM, STATUS_ACTIVE } from "../based/Constants";
import PushingServices from "../based/services/PushingServices";
import PlatformServices from "../based/services/PlatformServices";
import { Notify } from "../based/Notify";

function VideoPushModal({ show, onClose, videoId, onSave }) {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        _getStores();
    }, []);

    async function _getStores() {
        const [err, data] = await PlatformServices.PlatformStoresUser([PLATFORM.Shopee], STATUS_ACTIVE.All);
        if (!err) {
            setStores(data);
        } else {
            setStores([]);
        }
    }

    async function initVideoUploadShopee(videoId, shopId) {
        let [err, data] = await PushingServices.InitVideoUploadShopee(videoId, shopId);
        if (!err) {
            onSave();
        } else {
            Notify(NOTIFY.ERROR, "Xóa thất bại", "Xóa thất bại");
        }
    }

    return (
        <CommonModal
            show={show}
            onClose={onClose}
            size="md"
            onSave={() => {
                let store = stores.find((m) => m.isChecked);
                if (!store) {
                    Notify(NOTIFY.ERROR, "Chưa chọn cửa hàng", "Chưa chọn cửa hàng");
                    return;
                }
                initVideoUploadShopee(videoId, store.shopId);
            }}
        >
            <Wrapper>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="col-md-12" htmlFor="">
                            Danh sách cửa hàng Shopee
                        </label>
                        <div className="list-item col-md-12">
                            {stores.map((store, index) => {
                                return (
                                    <div
                                        className="item col-md-6"
                                        key={index}
                                        onClick={(e) => {
                                            let list = [...stores];
                                            list.forEach((m) => (m.isChecked = false));
                                            list[index].isChecked = true;
                                            setStores(list);
                                        }}
                                    >
                                        <input type="radio" checked={store.isChecked} />
                                        <label htmlFor="">{store.shopName}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default VideoPushModal;

const Wrapper = styled.div`
    .item {
        display: flex;
        align-items: center;
    }

    .item label {
        margin: 0 0 0 6px;
    }
`;
