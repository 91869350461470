import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import TFUSelect from "../based/inputs/TFUSelect";
import Common from "../based/Common";
import VoucherWinnerHistoryServices from "../based/services/VoucherWinnerHistoryServices";


const VoucherWinnerHistory = ()=>{
    const [datas,setDatas] = useState([]);
    const [paging,setPaging] = useState(Common.Paging);
    const [groupNameOptPaging,setGroupNameOptPaging] = useState(Common.Paging);
    const [voucherNameOptPaging,setVoucherNameOptPaging] = useState(Common.Paging);
    const [userNameOptPaging,setUserNameOptPaging] = useState(Common.Paging);
    const [groupNameOpt,setGroupNameOpt] = useState([]);
    const [voucherNameOpt,setVoucherNameOpt] = useState([]);
    const [userNameOpt,setUserNameOpt] = useState([]);
    const [userId,setUserId] = useState();

    useEffect(()=>{
        getDatas(paging);
    },[])

    useEffect(()=>{
        getGroupNameDropdown(groupNameOptPaging);
    },[groupNameOptPaging])
    useEffect(()=>{
        getVoucherNameDropdown(voucherNameOptPaging);
    },[voucherNameOptPaging])
    useEffect(()=>{
        getUserNameDropdown(userNameOptPaging);
    },[userNameOptPaging])
    useEffect(()=>{
        if(userId){
            userNameOpt.map(item=>{
                if(item.value==userId){
                    setPaging({...paging,userName:item.label})
                }
            })
        }
    },[userId])
    const getDatas= async(paging)=>{
        let [err,data] = await VoucherWinnerHistoryServices.GetTargetGroupWinnerPage(paging);
        if(!err&&data && data.listObjects){
            setDatas(data.listObjects);
            setPaging(data.paging);
        }else{
            setDatas([]);
            setPaging(Common.Paging);
        }
    }

    const getGroupNameDropdown = async(paging)=>{
        let[err,data] = await VoucherWinnerHistoryServices.GetTargetGroupWinnerDropdownGroupName(paging);
        if(!err && data && data.listObjects){
            setGroupNameOpt(data.listObjects);
        }else{
            setGroupNameOpt([]);
        }
    }
    const getVoucherNameDropdown = async(paging)=>{
        let[err,data] = await VoucherWinnerHistoryServices.GetTargetGroupWinnerDropdownVoucher(paging);
        if(!err && data && data.listObjects){
            setVoucherNameOpt(data.listObjects);
        }else{
            setVoucherNameOpt([]);
        }
    }
    const getUserNameDropdown = async(paging)=>{
        let[err,data] = await VoucherWinnerHistoryServices.GetTargetGroupWinnerDropdownUserName(paging);
        if(!err && data && data.listObjects){
            setUserNameOpt(data.listObjects);
        }else{
            setUserNameOpt([]);
        }
    }
    const _renderAction =()=>{
        return(
            <div className="box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="groupName">Group Name:</label>
                            <TFUSelect options={groupNameOpt}  value={groupNameOptPaging.keyword} name='groupName' onChanged={(value)=>setPaging({...paging, groupId: value})} handleInputChange={(value)=>setGroupNameOptPaging({...groupNameOptPaging, keyword: value})}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="voucherName">Voucher Name</label>
                            <TFUSelect options={voucherNameOpt} value={voucherNameOptPaging.keyword} name='voucherName' onChanged={(value)=>setPaging({...paging, voucherId:value})} handleInputChange={(value)=>setVoucherNameOptPaging({...voucherNameOptPaging,keyword:value})}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="userName">User Name</label>
                            <TFUSelect options={userNameOpt} value={userNameOptPaging.keyword} name='userName' onChanged={(value)=>setUserId(value)} handleInputChange={(value)=>setUserNameOptPaging({...userNameOptPaging,keyword:value})}/>
                        </div>
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className="fa fa-search m-r-5"/>
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const _renderHeader = () => {
        return(
            <thead>
                <tr>
                    <th className="text-center">
                        User Name
                    </th>
                    <th className="text-center">
                        Voucher Name
                    </th>
                    <th className="text-center">
                        Voucher Code
                    </th>
                    <th className="text-center">
                        Group Name
                    </th>
                    <th className="text-center">
                        Spin Time
                    </th>
                </tr>
            </thead>
        );
    }
    const _renderBody = ()=>{
        return(
            <tbody>
                {datas && datas.length >0 ?(
                    datas.map((item,i)=>{
                        return(
                            <tr key={i}>
                                <td className="text-center">
                                    {item.userName}
                                </td>
                                <td className="text-center">
                                    {item.voucherName}
                                </td>
                                <td className="text-center">
                                    {item.voucherCode}
                                </td>
                                <td className="text-center">
                                    {item.groupName}
                                </td>
                                <td className="text-center">
                                    {Common.formatDate(new Date(item.spinTime))}
                                </td>
                            </tr>
                        );
                    })
                ):(
                    <tr>
                        <td colSpan='10' className="text-center">
                        <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
                
            </tbody>
        );
    }
    return(
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý Voucher Winner History"/>
            <CommonTable paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} searchBasic={false} onFilter={getDatas}/>
        </React.Fragment>
    );
}

export default VoucherWinnerHistory;