import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import CommonTable from '../../based/CommonTable';
import { CONSTANTS, NOTIFY } from '../../based/Constants';
import { Box } from '../../based/content/ct.elm';
import { ConfirmDialog } from '../../based/Dialog';
import TextArea from '../../based/inputs/TextArea';
import Textbox from '../../based/inputs/Textbox';
import Loading from '../../based/Loading';
import { Notify } from '../../based/Notify';
import BaseServices from '../../based/services/BaseServices';
const INIT_REQUIREMENT = {
    id: 0,
    content: ''
}
const SharingRequirement = () => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState('');
    const [requirements, setRequirements] = useState([]);
    const [editingRequirement, setEditingRequirement] = useState(null);
    useEffect(() => {
        _fetchData();
    }, [])
    const _fetchData = async () => {
        setBusy(true);
        setBusyMsg('Đang tải dữ liệu...')
        const [err, data] = await BaseServices.Get('/api/affiliate/get-sharing-requirements');
        if (!err && data) {
            setRequirements(data);
        }
        setBusy(false);
    }
    const _handleDelete = async (id) => {
        if (id == 0) {
            setEditingRequirement(null);
            let newArr = [...requirements];
            newArr.splice(requirements.length - 1, 1);
            setRequirements(newArr);
        } else {
            ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true)
                    setBusyMsg('Đang xóa dữ liệu...')
                    const [err, data] = await BaseServices.Post('/api/affiliate/delete-sharing-requirement/' + id);
                    if (err) {
                        setBusy(false)
                        setBusyMsg(null)
                        Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                    }
                    else {
                        resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                        setBusy(false)
                        setBusyMsg(null)
                        _fetchData();
                        setEditingRequirement(null);
                    }
                })
            });
        }
    }
    const _handleEditRequirement = async (req) => {
        if (editingRequirement && req.id == editingRequirement.id) {
            await _handleSaveRequirement();
        }
        else
            setEditingRequirement(req);
    }
    const _handleInputChange = (name, value) => {
        setEditingRequirement({ ...editingRequirement, content: value })
    }
    const _handleSaveRequirement = async () => {
        const [err, data] = await BaseServices.Post('/api/affiliate/add-sharing-requirement/', editingRequirement);
        if (!err && data) {
            setEditingRequirement(null);
            _fetchData();
        }
    }
    const _handleAddNew = () => {
        let reqs = [...requirements];
        reqs.push(INIT_REQUIREMENT);
        setRequirements(reqs);
        setEditingRequirement(INIT_REQUIREMENT);
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th>STT</th>
                <th>Tên yêu cầu</th>
                <th></th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        if (!requirements || requirements.length == 0)
            return <tbody>
                <tr>
                    <td colSpan={3}>
                        <button className='btn btn-custom btn-sm m-r-5' onClick={_handleAddNew}>Thêm mới</button>
                    </td>
                </tr>
            </tbody>
        return <tbody>
            {
                requirements.map((item, idx) => {
                    return <tr>
                        <td width={50}>{idx + 1}</td>
                        <td>
                            {
                                editingRequirement && editingRequirement.id == item.id ?
                                    <TextArea
                                        id="editing_content"
                                        name="editing_content"
                                        value={editingRequirement.content}
                                        className="form-control"
                                        onChanged={_handleInputChange}
                                        handleEnter={_handleSaveRequirement}
                                    />
                                    :
                                    item.content
                            }
                        </td>
                        <td width={150}>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleEditRequirement(item)}>{editingRequirement && editingRequirement.id == item.id ? "Xong" : "Sửa"}</button>
                            <button className='btn btn-danger btn-sm m-r-5' onClick={() => _handleDelete(item.id)}>Xóa</button>
                        </td>
                    </tr>
                })
            }
            {
                !editingRequirement && <tr>
                    <td colSpan={3}>
                        <button className='btn btn-custom btn-sm m-r-5' onClick={_handleAddNew}>Thêm mới</button>
                    </td>
                </tr>
            }
        </tbody>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Yêu cầu bài viết Affiliate VIP' parentPage='Ranus' hrefParent='/' />
            <Loading show={busy} msg={busyMsg} />
            <CommonTable
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                hideFooter
            />
        </React.Fragment>
    );
};

export default SharingRequirement;