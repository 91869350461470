import React, { useEffect, useMemo, useRef, useState } from "react";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import RateCardConfigModal from "../voucher-gift/RateCardConfigModal";
import PrintServices from "../based/services/PrintServices";
import VoucherServices from "../based/services/VoucherServices";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import TFUDatetime from "../based/inputs/TFUDatetime";
import PromoteHighlight from "./PromoteHighlight"; 

const TAB_SCREENS = {
    INFO: 0,
    PRODUCTS: 1,
};

export default function AddOrEdit(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState({});

    const { addRef, displayError, isValid } = useFormValidate();
    const [showRateCard, setShowRateCard] = useState(false);
    const [readRateCard, setReadRateCard] = useState([]);

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const refHighLight = useRef(null);

    useEffect(() => {
        setVoucher(props.voucher);
        getReadRateCard();
    }, [props.isShowModal]);
    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function _handleSave(isContinue) { 
        if (isValid()) {
            let obj = { ...voucher, isActive: true };
            if (refHighLight.current != null) {
                let highLight = refHighLight.current.getPromoteHighlight();
                if (highLight) {
                    obj.voucherPromoteHighlights = highLight;
                }
            }

            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await VoucherServices.SaveVoucherPromote(obj); 
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
                if (isContinue) setVoucher(props.voucher);
                else props.onClose();
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) {
                    let errorsHighLight = err.errors.filter((x) => x.key.indexOf("voucherPromoteHighlight") > -1);
                    if (errorsHighLight != null && errorsHighLight.length == err.errors.length) setCurrentTabIndex(TAB_SCREENS.PRODUCTS);
                    else setCurrentTabIndex(TAB_SCREENS.INFO);

                    displayError(err.errors);
                    if (err.errors.find((x) => x.key === "voucherPromoteHighlight")) {
                        Notify(NOTIFY.WARNING, "Chú ý", "Voucher chưa thiết lập sản phẩm");
                    }
                } else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        } else {
            setCurrentTabIndex(TAB_SCREENS.INFO);
        }
    }
    function _handleChangedTab(tab) {
        setCurrentTabIndex(tab);
        if (tab == TAB_SCREENS.PRODUCTS)
            setTimeout(() => {
                if (document.getElementById("txtSearch")) document.getElementById("txtSearch").focus();
            }, 500);
    }
    const TabHeader = useMemo(() => (
        <div className='p-b-10'>
            <ul className='nav nav-tabs tabs-bordered nav-justified'>
                <li className='nav-item'>
                    <a className={currentTabIndex == TAB_SCREENS.INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.INFO)}>
                        <span className='d-none d-sm-block'>Thông tin Voucher</span>
                    </a>
                </li>
                <li className='nav-item'>
                    <a className={currentTabIndex == TAB_SCREENS.PRODUCTS ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.PRODUCTS)}>
                        <span className='d-none d-sm-block'>Thiết lập sản phẩm Voucher</span>
                    </a>
                </li>
            </ul>
        </div>
    ));
    function _renderInfo() {
        return (
            <div className='col-md-12 card-box p-3'>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='code' className='required'>
                                Mã Voucher:
                            </label>
                            <Textbox name='code' required ref={addRef} maxLength={200} className='form-control font-weight-bold' onChanged={(name, value) => setVoucher({ ...voucher, code: value })} value={voucher.code} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='name' className='required'>
                                Tên Voucher:
                            </label>
                            <Textbox name='name' required ref={addRef} maxLength={200} className='form-control' onChanged={(name, value) => setVoucher({ ...voucher, name: value })} value={voucher.name} />
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='fromDate' className='required'>
                                Ngày bắt đầu sử dụng:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='fromDate'
                                maxDate={voucher.toDate}
                                value={voucher.fromDate}
                                onChanged={(newValue) => setVoucher({ ...voucher, fromDate: newValue })}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='toDate' className='required'>
                                Ngày kết thúc sử dụng:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='toDate'
                                minDate={voucher.fromDate}
                                value={voucher.toDate}
                                onChanged={(newValue) => setVoucher({ ...voucher, toDate: newValue })}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='registerStartDate' className='required'>
                                Ngày bắt đầu đăng ký:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='registerStartDate'
                                maxDate={voucher.registerEndDate}
                                value={voucher.registerStartDate}
                                onChanged={(newValue) => setVoucher({ ...voucher, registerStartDate: newValue })}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='registerEndDate' className='required'>
                                Ngày kết thúc đăng ký:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='registerEndDate'
                                minDate={voucher.registerStartDate}
                                value={voucher.registerEndDate}
                                onChanged={(newValue) => setVoucher({ ...voucher, registerEndDate: newValue })}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='displayStartDate' className='required'>
                                Ngày hiển thị Voucher:
                            </label>
                            <TFUDatetime
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='displayStartDate'
                                minDate={voucher.fromDate} 
                                maxDate={voucher.toDate}
                                value={voucher.displayStartDate}
                                onChanged={(newValue) => setVoucher({ ...voucher, displayStartDate: newValue })}
                            />
                        </div>
                    </div> 
                </div>
                <div className='form-group'>
                    <label htmlFor='description'>Mô tả:</label>
                    <TextArea ref={addRef} name='description' value={voucher.description} onChanged={(name, value) => setVoucher({ ...voucher, description: value })} />
                </div>
            </div>
        );
    } 
    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            size='lg'
            title={voucher.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave(false)}>
                        <i className='fa fa-save m-r-5' /> Lưu
                    </button>
                    {voucher.id > 0 ? null : (
                        <button className='btn btn-outline-primary btn-sm' onClick={() => _handleSave(true)}>
                            <i className='fa fa-save m-r-5' /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            {showRateCard && <RateCardConfigModal showRateCard={showRateCard} readRateCard={readRateCard} onClose={() => setShowRateCard(false)} />}
            {TabHeader} 
            <div className={currentTabIndex == TAB_SCREENS.INFO ? "" : "hide"}>{_renderInfo()}</div>
            <div className={currentTabIndex == TAB_SCREENS.PRODUCTS ? "" : "hide"}>
                <PromoteHighlight ref={refHighLight} addRef={addRef} voucherPromoteHighlights={voucher.voucherPromoteHighlights} readRateCard={readRateCard} onShowRateCard={() => setShowRateCard(true)} />
            </div>
        </CommonModal>
    );
}
