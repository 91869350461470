import BaseServices from "./BaseServices";

const AdsServices = {
    CreateUpdateAdAccount: async (model) => {
        return await BaseServices.Post("/api/advertize/create-update-ad-account", model);
    },
    GetAdAccountById: async (id) => {
        return await BaseServices.Get(`/api/advertize/get-ad-account-by-id/${id}`);
    },
    RemoveAdAccountById: async (id) => {
        return await BaseServices.Get(`/api/advertize/remove-ad-account-by-id/${id}`);
    },
    GetAdAccountByPaging: async (model) => {
        return await BaseServices.Post("/api/advertize/get-ad-account-by-paging", model);
    },
    FetchDataByAdAccountId: async (id) => {
        return await BaseServices.Get(`/api/advertize/fetch-data-by-ad-account-id/${id}`);
    },
    GetAdCampaignByPaging: async (model, adAccountId = "") => {
        return await BaseServices.Post("/api/advertize/get-ad-campaign-by-paging?adAccountId=" + adAccountId, model);
    },
    GetAdSetByPaging: async (model, campaignId = "") => {
        return await BaseServices.Post("/api/advertize/get-ad-set-by-paging?campaignId=" + campaignId, model);
    },
    GetAdByPaging: async (model, adSetId = "") => {
        return await BaseServices.Post("/api/advertize/get-ad-by-paging?adSetId=" + adSetId, model);
    },
};

export default AdsServices;
