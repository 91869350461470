import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { CAMPAIGN_TYPE, CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";
import CampaignServices from "../based/services/CampaignServices";
import Config from "./Config";
import TFUTable from "../based/TFUTable";

const columns = [
    {
        displayName: "Tên chương trình",
        name: "title",
    },
    {
        displayName: "URL",
        name: "shortLink",
    },
    {
        displayName: "Mô tả",
        name: "description",
    },
    {
        displayName: "Trạng thái",
        name: "isActive",
        type: "bool",
        labelActive: "Đang diễn ra",
        labelInActive: "Đã kết thúc",
    },
];

const initialPaging = {
    pageSize: 10,
    pageNumber: 1,
    type: CAMPAIGN_TYPE.MEGA,
};

const CampaignMegaManagement = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [paging, setPaging] = useState(initialPaging);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const [isBusy, setBusy] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [campaignSelected, setCampaignSelected] = useState(null);

    useEffect(() => {
        getCampaigns(paging);
        setBusy(false);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, {
            mainMenu: MAINMENU.Campaign,
        });
    }, []);

    const getCampaigns = async (paging) => {
        const [err, data] = await CampaignServices.GetListCampaign(paging);
        if (!err && data) {
            setCampaigns(data.listObjects);
            setPaging(data.paging);
        }
    };

    const handleAddConfig = () => {
        setIsShowModal(true);
    };

    const handleDelete = (campaigns) => {
        let ids = [];
        campaigns.map((item) => ids.push(item.id));
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                const [err, data] = await CampaignServices.DeleteCampaigns(ids);
                if (!err && data) {
                    resolve({
                        title: "Thành công",
                        msg: "Xóa thành công.",
                    });
                    setBusy(false);
                    setTableGuid(Common.generateGuid());
                    getCampaigns(paging);
                } else {
                    setBusy(false);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                }
            });
        });
    };

    const _handleEditCampaign = (campaign) => {
        setCampaignSelected(campaign);
        setIsShowModal(true);
    };

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Chiến dịch" />
            <TFUTable data={campaigns} tableGuid={tableGuid} isBusy={isBusy} headerCheckbox columns={columns} search onAddItem={() => handleAddConfig()} onEditItem={_handleEditCampaign} onDeleteItem={handleDelete} />
            <Config
                campaignId={campaignSelected ? campaignSelected.id : 0}
                isShowModal={isShowModal}
                getCampaigns={() => getCampaigns(paging)}
                onClose={() => {
                    setIsShowModal(false);
                    setTableGuid(Common.generateGuid());
                    setCampaignSelected(null);
                }}
            />
        </React.Fragment>
    );
};

export default CampaignMegaManagement;
