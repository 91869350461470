import React, { useContext } from 'react';

import TextNumber from '../../based/inputs/TextNumber';
import { StockInContext } from '../../context/StockInContext';
import { ProductItemProps } from '../../based/PropsDefined';
import WarehouseServices from '../../based/services/WarehouseServices';
import TFUSelect from '../../based/inputs/TFUSelect';

/**
 * Product item component
 * @param {ProductItemProps} props property of product item component
 */
export default function ProductItem(props) {
  const { products, setProducts } = useContext(StockInContext);

  async function _handleSelectProduct(value) {
    let index = props.index;
    let product = products[index];
    if (value) {
      if (product) {
        let [err, data] = await WarehouseServices.GetProductDetails(value);
        if (!err && data) {
          product.productId = data.productId;
          product.productName = data.productName;
          product.sellerSku = data.sellerSku;
          product.price = data.price;
          product.barcode = data.barcode;
          product.productType = 2;
        } else {
          product.productId = 0;
          product.productName = '';
          product.sellerSku = '';
          product.price = 0;
          product.barcode = '';
          product.productType = null;
        }
      }
    } else {
      product.productId = 0;
      product.productName = '';
      product.sellerSku = '';
      product.price = 0;
      product.barcode = '';
      product.productType = null;
    }
    setProducts([...products]);
  }

  function _handleRemoveProduct() {
    products.splice(props.index, 1);
    setProducts([...products]);
  }

  function _handleChangeProduct(name, value) {
    let product = products[props.index];
    if (product) {
      product[name] = value;
    }
    setProducts([...products]);
  }

  let product = products[props.index];
  return (
    <tr>
      <td className="text-center vertical-align-middle">
        <TFUSelect id={`products[${props.index}].ProductId`} name={`products[${props.index}].ProductId`} options={props.productList} value={product.productId} placeholder="--Chọn sản phẩm--" onChanged={_handleSelectProduct} ref={props.addRef} />
      </td>
      <td className="text-center vertical-align-middle">{product.productName}</td>
      <td className="text-center vertical-align-middle">
        <TextNumber id={`products[${props.index}].Quantity`} name={`products[${props.index}].Quantity`} onChanged={(name, value) => _handleChangeProduct('quantity', value)} required ref={props.addRef} value={product.quantity ? parseInt(product.quantity) : 0} />
      </td>
      <td className="text-center vertical-align-middle">
        <TextNumber id={`products[${props.index}].Price`} name={`products[${props.index}].Price`} onChanged={(name, value) => _handleChangeProduct('price', value)} required ref={props.addRef} value={+product.price} />
      </td>
      <td className="text-center vertical-align-middle">
        <button className="btn btn-danger" onClick={_handleRemoveProduct}>
          <i className="fa fa-trash-o" />
        </button>
      </td>
    </tr>
  );
}
