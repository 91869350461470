import React from "react";

const InputSelect = (props) => {
    var cls = props.className ? props.className : ''
    return (
        <div className='input__group'>
            {props.label ? <label className='input__label'>{props.label}</label> : ""}
            <select className={`input__item ${cls}`} defaultValue={props.defaultValue ? props.defaultValue : "--*--"} onChange={props.onChange}>
                {props.values
                    ? props.values.map((item, index) => (
                        <option value={item.value} key={index}>
                            {item.label}
                        </option>
                    ))
                    : null}
            </select>
        </div>
    );
};

export default InputSelect;
