import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MarketingServices from "../based/services/MarketingServices";


function MarketingStatistic({campaignId}) {
   const [data , setData] = useState({})

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        
        let [err, data] = await MarketingServices.GetStatictisById(campaignId);
        if (!err && data) {
            setData(data)
        } else {
            setData({})
        }
    }


    
    return (
        <WapperStatisticStyle>
            <div className="d-flex">
                <div className="marketing-url">
                    <h4>Thống kê</h4>
                </div>
            </div>
            <table>
                <tr>
                    <td className="title">Số lượng user truy cập</td>
                    <td className="count">{data && data.countUser}</td>
                </tr>
                <tr>
                    <td className="title">Số view</td>
                    <td className="count">{data && data.countView}</td>
                </tr>
            </table>
        
        
            
        </WapperStatisticStyle>
    )
}
const WapperStatisticStyle = styled.div`
    background-color: #fff;
    padding: 16px;
    margin-bottom: 16px;
    position: relative;

    h4{
        color: #666F7B;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        margin-top: 0;

        i{
            margin-left: 4px;
            color: #444;
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
        }
    }

    td{
        border: 1px solid #42f5d7;
        padding: 10px;
        line-height: 1;
        font-size: 16px;
        
        &.title{
            font-weight: 600;
            color: #666F7B;
        }
        &.count{
            font-weight: bold;
            color: #64c5c2;
            text-align: right;
            width: 100px;
        }
    }
    

      

    .marketing-color{
        display: flex;
        align-items: center;

        input{
            height: 40px;
            width: 100px;
            cursor: pointer;
        }

        .color-input{
            width: 100px;
            height: 40px;
        }
    }
    

`
export default MarketingStatistic;
