import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import HotKeyServices from "../based/services/HotKeyServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import Textbox from "../based/inputs/Textbox";
import styled from "styled-components";

const initHotKey = {
    id:0,
    hotKey: '',
    redirectUrl:""
}

function AddOrUpdateHotKey(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [hotKey , setHotKey] = useState({...initHotKey});
    const [keys, setKeys] = useState([])

    useEffect(() => {
        if(props.id>0){
            getHotKey(props.id)
        }
    },[props.id])

    const getHotKey = async (id) => {
        setIsBusy(true);
        let [err, data] = await HotKeyServices.GetHotKeyById(id);
        if (!err && data) {
            setIsBusy(false);
            setHotKey(data)
            setKeys(data.hotKey.split('+'));
        }else{
            setIsBusy(false);
            setHotKey({...initHotKey})

        }
        
    };

    const _handleSave = async()=>{
        if (isValid() && keys.length > 1 && keys.length < 5) {
            setIsBusy(true);
            let [err, data] = await HotKeyServices.CreateUpdateHotKey({...hotKey, hotKey: keys.join("+")});
            if (!err && data == 1) {
                setIsBusy(false);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại tổ hợp phím");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
        if(keys.length < 2 || keys.length > 4){
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Tối thiểu 2 key và tối đa 4 key");
        }
      
    }
    const handleAddKey = (e)=>{
        if(!keys.some(key => key == e.key.toUpperCase()))
            setKeys([...keys, e.key.toUpperCase()])
        e.target.value = ""
    }
    const handleRemoveKey = (i)=>{
        let data = [...keys]
        data.splice(i, 1)
        setKeys(data)
    }
   
    return ( 
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} 
        onSave={_handleSave} 
        onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='hotkey' className='required'>
                    Tổ hợp phím (2 đến 4 key):
                </label>
                <Wapper>
                    <div className="tab-list">
                    {keys && keys.map((key , idx)=>{
                       return (
                        <div key={idx} className="tab-item">
                            <span className="name-tab">{key}</span>
                            <span className="icon" onClick={()=>{
                                handleRemoveKey(idx)
                            }}>
                                &times;
                            </span>
                        </div>)
                    })}
                                                
                    </div>
                    <input type="text" placeholder="Chọn vào đây và nhấn phím" onKeyUp={(e)=>{
                        handleAddKey(e)
                    }}/>
                </Wapper>


                <label htmlFor='redirect-url' className='required'>
                    Đường dẫn điều hướng (vd: /hotket-management):
                </label>
                <Textbox id='redirect-url' name='redirect-url' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setHotKey({ ...hotKey, redirectUrl: value })} 
                value={hotKey.redirectUrl} />
            </div>
        </CommonModal>
     );
}

export default AddOrUpdateHotKey;

const Wapper = styled.div`
    .tab-list{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .tab-item{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 5px;
            background-color: #64C5B1;
            height: 30px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            .name-tab{
                color: #FFFFFF;

            }
            .icon{
                margin-left: 5px;
                color: #FFFFFF;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;

            }
        }
    }
    input{
        margin-top:10px;
        width: 100%;
        height: 30px;
        border: 1px solid #64C5B1;
        border-radius: 10px;
        padding: 0 10px;

        :placeholder{
            color: #f3f3f3;
        }
    }

`
AddOrUpdateHotKey.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};