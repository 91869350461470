import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Textbox from "../../based/inputs/Textbox";
import useFormValidate from "../../hooks/useFormValidate";
import TFUSelect from "../../based/inputs/TFUSelect";
import TextArea from "../../based/inputs/TextArea";
import TextNumber from "../../based/inputs/TextNumber";
import { CONTENT_TYPE, ARTWORK_TYPE } from "../../based/Constants";

const CONFIG_STEP = {
    LIST_OPTIONS: 0,
    CONFIG_TITLE: 1,
    CONFIG_CONTENT: 2,
    CONFIG_ARTWORK: 3,
    CONFIG_PRODUCT: 4,
};
const CONTENT_DIRECTION = {
    Vertical: 0,
    Horicontal: 1,
};
const CONTENT_ALIGN = {
    Left: 0,
    Center: 1,
    Right: 2,
};
const titleModel = {
    contentType: CONTENT_TYPE.Title,
    content: "",
    color: "",
    fontSize: 0,
    fontId: 0,
    fontName: "",
    height: 0,
    width: 0,
    locationX: 0,
    locationY: 0,
    contentDirection: CONTENT_DIRECTION.Vertical,
    contentDirectionValue: 0,
    contentAlign: CONTENT_ALIGN.Left,
    x: 0,
    y: 0,
};
const contentModel = {
    contentType: CONTENT_TYPE.Content,
    content: "",
    color: "",
    fontSize: 0,
    fontId: 0,
    height: 0,
    width: 0,
    fontName: "",
    locationX: 0,
    locationY: 0,
    contentAlign: CONTENT_ALIGN.Left,
    x: 0,
    y: 0,
};
const artworkModel = {
    x: 0,
    y: 0,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    y1: 0,
    y2: 0,
    y3: 0,
    y4: 0,
    collarX: 0,
    collarY: 0,
    appProductVariantId: 0,
    artworkType: ARTWORK_TYPE.Front,
    sliding: 0,
    productId: 0,
    slipMax: 0,
    slipMin: 0,
};
const productModel = {
    x: 0,
    y: 0,
    locationX: 0,
    locationY: 0,
    appProductVariantId: 0,
    productId: 0,
};
const ModalConfig = (props) => {
    const { X, Y, show, step, setStep, onClose, data, setData, index, update, fontOptions, posterVariant } = props;
    const [contentData, setContentData] = useState(contentModel);
    const [titleData, setTitleData] = useState(titleModel);
    const [artworkData, setArtworkData] = useState(artworkModel);
    const [productData, setProductData] = useState(productModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const [productType, setProductType] = useState();
    const [artworkColor, setArtworkColor] = useState();
    const [artworkSize, setArtworkSize] = useState();
    const [displayColorName, setDisplayColorName] = useState();
    const [showVarProduct, setShowVarProduct] = useState(false);

    

    //Reset data về mặc định nếu như là tạo mới, cập nhật data nếu như là update
    useEffect(() => {
        if (!update) {
            setArtworkData({ ...artworkModel, x: parseInt(X), y: parseInt(Y) });
            setContentData({ ...contentModel, x: parseInt(X), y: parseInt(Y) });
            setTitleData({ ...titleModel, x: parseInt(X), y: parseInt(Y) });
            setProductData({ ...productData, x: parseInt(X), y: parseInt(Y) });
            if (posterVariant.length > 0) {
                setArtworkData({ ...artworkData, x: parseInt(X), y: parseInt(Y), appProductVariantId: posterVariant[0].variants[0].id, productId: posterVariant[0].productId });
                setProductData({ ...productData, x: parseInt(X), y: parseInt(Y), appProductVariantId: posterVariant[0].variants[0].id, productId: posterVariant[0].productId });
                setProductType(posterVariant[0].name);
                setArtworkColor(posterVariant[0].variants[0].color);
                setArtworkSize(posterVariant[0].variants[0].size);
            }
        }
        if (update && step == CONFIG_STEP.CONFIG_TITLE) {
            setTitleData(data[0].coL_PosterContentConfigs[index]);
        }
        if (update && step == CONFIG_STEP.CONFIG_CONTENT) {
            setContentData(data[0].coL_PosterContentConfigs[index]);
        }
        if (update && step == CONFIG_STEP.CONFIG_ARTWORK) {
            setArtworkData(data[0].coL_PosterArtworkConfigs[index]);
        }
        if (update && step == CONFIG_STEP.CONFIG_PRODUCT) {
            setProductData(data[0].coL_PosterAppProductVariants[index]);
        }
    }, [show, step, update]);
    useEffect(() => {
        if (step == CONFIG_STEP.CONFIG_ARTWORK) {
            posterVariant.map((set) => {
                if (artworkData.productId == set.productId) {
                    setArtworkColor(set.variants[0].color);
                    setArtworkSize(set.variants[0].size);
                    setDisplayColorName(set.variants[0].colorDisplayName);
                    setProductType(set.name);
                }
            });
        } else {
            posterVariant.map((set) => {
                if (productData.productId == set.productId) {
                    setArtworkColor(set.variants[0].color);
                    setArtworkSize(set.variants[0].size);
                    setDisplayColorName(set.variants[0].colorDisplayName);
                    setProductType(set.name);
                }
            });
        }
    }, [artworkData.productId, productData.productId]);
    useEffect(() => {
        if (step == CONFIG_STEP.CONFIG_ARTWORK) {
            posterVariant.map((set) => {
                if (artworkData.productId == set.productId) {
                    set.variants.map((item) => {
                        if (item.size == artworkSize && item.color == artworkColor) {
                            setArtworkData({ ...artworkData, appProductVariantId: item.id });
                            setDisplayColorName(item.colorDisplayName);
                        }
                    });
                }
            });
        } else {
            posterVariant.map((set) => {
                if (productData.productId == set.productId) {
                    set.variants.map((item) => {
                        if (item.size == artworkSize && item.color == artworkColor) {
                            setProductData({ ...productData, appProductVariantId: item.id });
                            setDisplayColorName(item.colorDisplayName);
                        }
                    });
                }
            });
        }
    }, [artworkSize, artworkColor, artworkData.productId, productData.productId]);

    const handleSaveTitleConfig = () => {
        if (isValid()) {
            if (update && data[0].coL_PosterContentConfigs.length > 0) {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterContentConfigs.splice(index, 1, titleData);
                }
                setData(dataTemp);
                onClose();
            } else {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterContentConfigs.push(titleData);
                }
                setData(dataTemp);
                onClose();
            }
        }
    };
    //Nếu là update thì không cho click back về
    const handleBack = (step) => {
        if (!update) {
            setStep(step);
        }
    };
    const handleSaveContentConfig = () => {
        if (isValid()) {
            if (update && data[0].coL_PosterContentConfigs.length > 0) {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterContentConfigs.splice(index, 1, contentData);
                }
                setData(dataTemp);
                onClose();
            } else {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterContentConfigs.push(contentData);
                }
                setData(dataTemp);
                onClose();
            }
        }
    };
    const handleSaveArtworkConfig = () => {
        if (isValid()) {
            if (update && data[0].coL_PosterArtworkConfigs.length > 0 && data[0].coL_PosterArtworkConfigs[index].productId) {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterArtworkConfigs.splice(index, 1, artworkData);
                }
                setData(dataTemp);
                onClose();
            } else {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterArtworkConfigs.push(artworkData);
                }
                setData(dataTemp);
                onClose();
            }
        }
    };
    const handleSaveProductConfig = () => {
        if (isValid()) {
            if (update && data[0].coL_PosterAppProductVariants.length > 0 && data[0].coL_PosterAppProductVariants[index].productId) {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterAppProductVariants.splice(index, 1, productData);
                }
                setData(dataTemp);
                onClose();
            } else {
                let dataTemp = [...data];
                for (let i = 0; i < dataTemp.length; i++) {
                    dataTemp[i].coL_PosterAppProductVariants.push(productData);
                }
                setData(dataTemp);
                onClose();
            }
        }
    };
    const handleSetFont = (value) => {
        if (step == 1) {
            if (value > 0) {
                var name = fontOptions.find((item) => item.value == value).text;
            } else {
                var name = "";
            }
            setTitleData({ ...titleData, fontId: parseInt(value), fontName: name });
        } else {
            if (value > 0) {
                var name = fontOptions.find((item) => item.value == value).text;
            } else {
                var name = "";
            }
            setContentData({ ...contentData, fontId: parseInt(value), fontName: name });
        }
    };

    const renderContent = (option) => {
        switch (option) {
            case CONFIG_STEP.LIST_OPTIONS:
                return (
                    <div className="content-container">
                        <p className="title-header">Lựa chọn</p>
                        <img className="cancel" src="/images/Cancel_line_black.svg" onClick={onClose} />
                        <p onClick={() => setStep(CONFIG_STEP.CONFIG_TITLE)}>Mô tả tiêu đề</p>
                        <p onClick={() => setStep(CONFIG_STEP.CONFIG_CONTENT)}>Mô tả ngắn gọn</p>
                        <p onClick={() => setStep(CONFIG_STEP.CONFIG_ARTWORK)}>Artwork</p>
                        <p onClick={() => setStep(CONFIG_STEP.CONFIG_PRODUCT)}>Sản phẩm phôi</p>
                    </div>
                );
            case CONFIG_STEP.CONFIG_TITLE:
                return (
                    <AvatarConfig X={X} Y={Y}>
                        <p className="title-header">Mô tả tiêu đề</p>
                        <img className="cancel" src="/images/Cancel_line_black.svg" onClick={onClose} />
                        <img className="back" src="/images/Back_line_black.svg" onClick={() => handleBack(CONFIG_STEP.LIST_OPTIONS)} />
                        <div className="size-container">
                            <div className="item">
                                <span>Tọa độ X</span>
                                <TextNumber decimal className="text-box" ref={addRef} required name="locationX" id="locationX" value={titleData.locationX} onChanged={(name, value) => setTitleData({ ...titleData, locationX: parseInt(value) })} />
                            </div>
                            <div className="item">
                                <span>Tọa độ Y</span>
                                <TextNumber decimal className="text-box" ref={addRef} required name="locationY" id="locationY" value={titleData.locationY} onChanged={(name, value) => setTitleData({ ...titleData, locationY: parseInt(value) })} />
                            </div>
                        </div>
                        <TFUSelect
                            className="select"
                            options={fontOptions}
                            placeholder="--Chọn font--"
                            value={titleData.fontId}
                            onChanged={(value) => {
                                handleSetFont(value);
                            }}
                        />
                       



                        <div className="font-color">
                            <div className="item">
                                <span>Font size (px)</span>
                                <TextNumber decimal className="text-box" ref={addRef} required name="fontSize" id="fontSize" value={titleData.fontSize} onChanged={(name, value) => setTitleData({ ...titleData, fontSize: parseInt(value) })} />
                            </div>
                            <div className="item">
                                <span>Màu</span>
                                <div className="color-container">
                                    <Textbox className="text-box" id="color" name="color" onChanged={(name, value) => setTitleData({ ...titleData, color: value })} value={titleData.color} />
                                    <input type="color" className="colorPicker" id="color" name="color" value={titleData.color} onChange={(e) => setTitleData({ ...titleData, color: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="printer-container">
                            <span className="pl-0">Căn lề</span>
                            <span className={titleData.contentAlign == CONTENT_ALIGN.Left ? "printer" : "cursor-pointer"} onClick={() => setTitleData({ ...titleData, contentAlign: CONTENT_ALIGN.Left })}>
                                Trái
                            </span>
                            <span className={titleData.contentAlign == CONTENT_ALIGN.Right ? "printer" : "cursor-pointer"} onClick={() => setTitleData({ ...titleData, contentAlign: CONTENT_ALIGN.Right })}>
                                Phải
                            </span>
                            <span className={titleData.contentAlign == CONTENT_ALIGN.Center ? "printer" : "cursor-pointer"} onClick={() => setTitleData({ ...titleData, contentAlign: CONTENT_ALIGN.Center })}>
                                Giữa
                            </span>
                        </div>
                        <div className="printer-container">
                            <span className="pl-0">Chiều chữ</span>
                            <span className={titleData.contentDirection == CONTENT_DIRECTION.Vertical ? "printer" : "cursor-pointer"} onClick={() => setTitleData({ ...titleData, contentDirection: CONTENT_DIRECTION.Vertical })}>
                                Dọc
                            </span>
                            <span className={titleData.contentDirection == CONTENT_DIRECTION.Horicontal ? "printer" : "cursor-pointer"} onClick={() => setTitleData({ ...titleData, contentDirection: CONTENT_DIRECTION.Horicontal })}>
                                Ngang
                            </span>
                        </div>
                        <div className="size-container mt-3 mb-3">
                            <div className="item">
                                <span>{titleData.contentDirection == CONTENT_DIRECTION.Vertical ? "Giới hạn chữ" : "Giới hạn kí tự"}</span>
                                <TextNumber
                                    decimal
                                    className="text-box"
                                    ref={addRef}
                                    required
                                    name="directionValue"
                                    id="directionValue"
                                    value={titleData.contentDirectionValue}
                                    onChanged={(name, value) => setTitleData({ ...titleData, contentDirectionValue: parseInt(value) })}
                                />
                            </div>
                        </div>
                        {titleData.contentDirection == CONTENT_DIRECTION.Horicontal && (
                            <div className="size-container mb-3">
                                <div className="item">
                                    <span>Chiều rộng</span>
                                    <TextNumber decimal className="text-box" ref={addRef} required name="width" id="width" value={titleData.width} onChanged={(name, value) => setTitleData({ ...titleData, width: parseInt(value) })} />
                                </div>
                                <div className="item">
                                    <span>Chiều cao</span>
                                    <TextNumber decimal className="text-box" ref={addRef} required name="height" id="height" value={titleData.height} onChanged={(name, value) => setTitleData({ ...titleData, height: parseInt(value) })} />
                                </div>
                            </div>
                        )}

                        <span>Thông tin thay thế(Tối đa 50 kí tự)</span>
                        <TextArea maxLength={50} id="avartarTitle" name="avartarTitle" ref={addRef} className="text-area" onChanged={(name, value) => setTitleData({ ...titleData, content: value })} value={titleData.content} />
                        <div className="d-flex justify-content-center">
                            <div className="btn-save" onClick={handleSaveTitleConfig}>
                                Xác nhận
                            </div>
                        </div>
                    </AvatarConfig>
                );
            case CONFIG_STEP.CONFIG_CONTENT:
                return (
                    <AvatarConfig X={X} Y={Y}>
                        <p className="title-header">Mô tả ảnh đại diện</p>
                        <img className="cancel" src="/images/Cancel_line_black.svg" onClick={onClose} />
                        <img className="back" src="/images/Back_line_black.svg" onClick={() => handleBack(CONFIG_STEP.LIST_OPTIONS)} />
                        <div className="size-container">
                            <div className="item">
                                <span>Tọa độ X</span>
                                <TextNumber
                                    decimal
                                    className="text-box"
                                    ref={addRef}
                                    required
                                    name="locationX"
                                    id="locationX"
                                    value={contentData.locationX}
                                    onChanged={(name, value) => setContentData({ ...contentData, locationX: parseInt(value) })}
                                />
                            </div>
                            <div className="item">
                                <span>Tọa độ Y</span>
                                <TextNumber
                                    decimal
                                    className="text-box"
                                    ref={addRef}
                                    required
                                    name="locationY"
                                    id="locationY"
                                    value={contentData.locationY}
                                    onChanged={(name, value) => setContentData({ ...contentData, locationY: parseInt(value) })}
                                />
                            </div>
                            <div className="item">
                                <span>Chiều rộng</span>
                                <TextNumber decimal className="text-box" ref={addRef} required name="width" id="width" value={contentData.width} onChanged={(name, value) => setContentData({ ...contentData, width: parseInt(value) })} />
                            </div>
                            <div className="item">
                                <span>Chiều cao</span>
                                <TextNumber decimal className="text-box" ref={addRef} required name="height" id="height" value={contentData.height} onChanged={(name, value) => setContentData({ ...contentData, height: parseInt(value) })} />
                            </div>
                        </div>
                        <TFUSelect
                            className="select"
                            options={fontOptions}
                            placeholder="--Chọn font--"
                            value={contentData.fontId}
                            onChanged={(value) => {
                                handleSetFont(value);
                            }}
                        />
                        <div className="font-color">
                            <div className="item">
                                <span>Font size (px)</span>
                                <TextNumber decimal className="text-box" ref={addRef} required name="fontSize" id="fontSize" value={contentData.fontSize} onChanged={(name, value) => setContentData({ ...contentData, fontSize: parseInt(value) })} />
                            </div>
                            <div className="item">
                                <span>Màu</span>
                                <div className="color-container">
                                    <Textbox className="text-box disabled" id="color" name="color" onChanged={(name, value) => setContentData({ ...contentData, color: value })} value={contentData.color} readOnly />
                                    <input type="color" className="colorPicker" id="color" name="color" value={contentData.color} onChange={(e) => setContentData({ ...contentData, color: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="printer-container mb-3">
                            <span className="pl-0">Căn lề</span>
                            <span className={contentData.contentAlign == CONTENT_ALIGN.Left ? "printer" : "cursor-pointer"} onClick={() => setContentData({ ...contentData, contentAlign: CONTENT_ALIGN.Left })}>
                                Trái
                            </span>
                            <span className={contentData.contentAlign == CONTENT_ALIGN.Right ? "printer" : "cursor-pointer"} onClick={() => setContentData({ ...contentData, contentAlign: CONTENT_ALIGN.Right })}>
                                Phải
                            </span>
                            <span className={contentData.contentAlign == CONTENT_ALIGN.Center ? "printer" : "cursor-pointer"} onClick={() => setContentData({ ...contentData, contentAlign: CONTENT_ALIGN.Center })}>
                                Giữa
                            </span>
                        </div>
                        <span>Thông tin thay thế(Tối đa 250 kí tự)</span>
                        <TextArea maxLength={250} height={100} id="avartarContent" name="avartarContent" ref={addRef} className="text-area" onChanged={(name, value) => setContentData({ ...contentData, content: value })} value={contentData.content} />
                        <div className="d-flex justify-content-center">
                            <div className="btn-save" onClick={handleSaveContentConfig}>
                                Xác nhận
                            </div>
                        </div>
                    </AvatarConfig>
                );
            case CONFIG_STEP.CONFIG_ARTWORK:
                return (
                    <ArtworkConfig X={X} Y={Y}>
                        <p className="title-header">Artwork</p>
                        <img className="cancel" src="/images/Cancel_line_black.svg" onClick={onClose} />
                        <img className="back" src="/images/Back_line_black.svg" onClick={() => handleBack(CONFIG_STEP.LIST_OPTIONS)} />
                        <div className="first-container">
                            <div className="left">
                                <div className="mb-2">
                                    <div className="d-flex mb-1">
                                        <span>X1</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationOneX"
                                            id="locationOneX"
                                            value={artworkData.x1}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, x1: parseInt(value) })}
                                        />
                                    </div>
                                    <div className="d-flex">
                                        <span>Y1</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationOneY"
                                            id="locationOneY"
                                            value={artworkData.y1}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, y1: parseInt(value) })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="d-flex">
                                        <span>X3</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationThreeX"
                                            id="locationThreeX"
                                            value={artworkData.x3}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, x3: parseInt(value) })}
                                        />
                                    </div>
                                    <div className="d-flex mt-1">
                                        <span>Y3</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationThreeY"
                                            id="locationThreeY"
                                            value={artworkData.y3}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, y3: parseInt(value) })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="center"></div>
                            <div className="right">
                                <div className="mb-2">
                                    <div className="d-flex mb-1">
                                        <span>X2</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationTwoX"
                                            id="locationTwoX"
                                            value={artworkData.x2}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, x2: parseInt(value) })}
                                        />
                                    </div>
                                    <div className="d-flex">
                                        <span>Y2</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationTwoY"
                                            id="locationTwoY"
                                            value={artworkData.y2}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, y2: parseInt(value) })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="d-flex">
                                        <span>X4</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationFourX"
                                            id="locationFourX"
                                            value={artworkData.x4}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, x4: parseInt(value) })}
                                        />
                                    </div>
                                    <div className="d-flex mt-1">
                                        <span>Y4</span>
                                        <TextNumber
                                            decimal
                                            className="text-box"
                                            ref={addRef}
                                            required
                                            name="locationFourY"
                                            id="locationFourY"
                                            value={artworkData.y4}
                                            onChanged={(name, value) => setArtworkData({ ...artworkData, y4: parseInt(value) })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="second-container mt-3 mb-3">
                            <div className="item">
                                <span>Tọa độ cổ áo</span>
                                <div className="d-flex mt-1">
                                    <span>X</span>
                                    <TextNumber
                                        decimal
                                        className="text-box"
                                        ref={addRef}
                                        required
                                        name="locationCollarX"
                                        id="locationCollarX"
                                        value={artworkData.collarX}
                                        onChanged={(name, value) => setArtworkData({ ...artworkData, collarX: parseInt(value) })}
                                    />
                                </div>
                                <div className="d-flex mt-1">
                                    <span>Y</span>
                                    <TextNumber
                                        decimal
                                        className="text-box"
                                        ref={addRef}
                                        required
                                        name="locationCollarY"
                                        id="locationCollarY"
                                        value={artworkData.collarY}
                                        onChanged={(name, value) => setArtworkData({ ...artworkData, collarY: parseInt(value) })}
                                    />
                                </div>
                            </div>
                            <div className="item">
                                <span>Góc trượt (độ)</span>
                                <TextNumber
                                    min={-180}
                                    max={180}
                                    decimal
                                    className="text-box mt-1"
                                    ref={addRef}
                                    required
                                    name="sliding"
                                    id="sliding"
                                    value={artworkData.sliding}
                                    onChanged={(name, value) => setArtworkData({ ...artworkData, sliding: value })}
                                />
                            </div>
                        </div>
                        <span className="mt-3">Biến thể sản phẩm phôi</span>
                        <div className="varproduct">
                            <span>
                                {productType}-{displayColorName}-{artworkSize}
                            </span>
                            <img src="./images/Forward_line_black.svg" className="next" onClick={() => setShowVarProduct(true)} />
                            <VarProduct showVarProduct={showVarProduct}>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <p>Biến thể sản phẩm phôi</p>
                                    <img src="./images/Cancel_line_black.svg" className="cancel" onClick={() => setShowVarProduct(false)} />
                                </div>
                                <span>Thể loại</span>
                                <div className="d-flex">
                                    {posterVariant.map((set, idx) => {
                                        return (
                                            <span key={idx} className={set.productId == artworkData.productId ? "btn active" : "btn"} onClick={() => setArtworkData({ ...artworkData, productId: parseInt(set.productId) })}>
                                                {set.name.slice(6)}
                                            </span>
                                        );
                                    })}
                                </div>
                                <span>Màu Sắc</span>
                                <div className="d-flex">
                                    {posterVariant.map((set, idx) => {
                                        if (set.productId == artworkData.productId) {
                                            let dataTemp = [];
                                            set.colors.map((item, idx) => {
                                                dataTemp.push(<div key={idx} className={item == artworkColor ? "color select" : "color"} style={{ background: `${item}` }} onClick={() => setArtworkColor(item)}></div>);
                                            });
                                            return dataTemp.map((x) => x);
                                        }
                                    })}
                                </div>
                                <span>Size</span>
                                <div className="d-flex mb-3">
                                    {posterVariant.map((set, idx) => {
                                        if (set.productId == artworkData.productId) {
                                            let dataTemp = [];
                                            set.sizes.map((item, idx) => {
                                                dataTemp.push(
                                                    <span key={idx} className={item == artworkSize ? "btn active" : "btn"} onClick={() => setArtworkSize(item)}>
                                                        {item}
                                                    </span>
                                                );
                                            });
                                            return dataTemp.map((x) => x);
                                        }
                                    })}
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="btn-save" onClick={() => setShowVarProduct(false)}>
                                        Xác nhận
                                    </div>
                                </div>
                            </VarProduct>
                        </div>
                        <div className="printer-container mt-3 mb-3">
                            <span className="pl-0">Mặt in</span>
                            <span className={artworkData.artworkType == ARTWORK_TYPE.Front ? "printer" : "cursor-pointer"} onClick={() => setArtworkData({ ...artworkData, artworkType: ARTWORK_TYPE.Front })}>
                                Trước
                            </span>
                            <span className={artworkData.artworkType == ARTWORK_TYPE.Back ? "printer" : "cursor-pointer"} onClick={() => setArtworkData({ ...artworkData, artworkType: ARTWORK_TYPE.Back })}>
                                Sau
                            </span>
                        </div>
                        <span style={{ color: "#00c853" }} className="mt-3">
                            Giới hạn trượt (cm)
                        </span>
                        <div className="three-container">
                            <div className="item">
                                <span>{`Cao nhất (<=3)`}</span>
                                <TextNumber
                                    decimal
                                    className="text-box"
                                    ref={addRef}
                                    max={3}
                                    required
                                    name="slipMax"
                                    id="slipMax"
                                    value={artworkData.slipMax}
                                    onChanged={(name, value) => setArtworkData({ ...artworkData, slipMax: parseInt(value) })}
                                />
                            </div>
                            <div className="item">
                                <span>{`Thấp nhất (<=20)`}</span>
                                <TextNumber
                                    decimal
                                    className="text-box"
                                    ref={addRef}
                                    max={20}
                                    required
                                    name="slipMin"
                                    id="slipMin"
                                    value={artworkData.slipMin}
                                    onChanged={(name, value) => setArtworkData({ ...artworkData, slipMin: parseInt(value) })}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="btn-save" onClick={handleSaveArtworkConfig}>
                                Xác nhận
                            </div>
                        </div>
                    </ArtworkConfig>
                );
            case CONFIG_STEP.CONFIG_PRODUCT:
                return (
                    <ProductConfig X={X} Y={Y}>
                        <p className="title-header">Sản phẩm phôi</p>
                        <img className="cancel" src="/images/Cancel_line_black.svg" onClick={onClose} />
                        <img className="back" src="/images/Back_line_black.svg" onClick={() => handleBack(CONFIG_STEP.LIST_OPTIONS)} />
                        <span>Sản phẩm phôi</span>
                        <div className="varproduct">
                            <span>{productType}</span>
                            <img src="./images/Forward_line_black.svg" className="next" onClick={() => setShowVarProduct(true)} />
                            <VarProduct showVarProduct={showVarProduct}>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <p>Biến thể sản phẩm phôi</p>
                                    <img src="./images/Cancel_line_black.svg" className="cancel" onClick={() => setShowVarProduct(false)} />
                                </div>
                                <span>Thể loại</span>
                                <div className="d-flex">
                                    {posterVariant.map((set, idx) => {
                                        return (
                                            <span key={idx} className={set.productId == productData.productId ? "btn active" : "btn"} onClick={() => setProductData({ ...productData, productId: parseInt(set.productId) })}>
                                                {set.name.slice(6)}
                                            </span>
                                        );
                                    })}
                                </div>
                                <span>Màu Sắc</span>
                                <div className="d-flex">
                                    {posterVariant.map((set, idx) => {
                                        if (set.productId == productData.productId) {
                                            let dataTemp = [];
                                            set.colors.map((item, idx) => {
                                                dataTemp.push(<div key={idx} className={item == artworkColor ? "color select" : "color"} style={{ background: `${item}` }} onClick={() => setArtworkColor(item)}></div>);
                                            });
                                            return dataTemp.map((x) => x);
                                        }
                                    })}
                                </div>
                                <span>Size</span>
                                <div className="d-flex mb-3">
                                    {posterVariant.map((set, idx) => {
                                        if (set.productId == productData.productId) {
                                            let dataTemp = [];
                                            set.sizes.map((item, idx) => {
                                                dataTemp.push(
                                                    <span key={idx} className={item == artworkSize ? "btn active" : "btn"} onClick={() => setArtworkSize(item)}>
                                                        {item}
                                                    </span>
                                                );
                                            });
                                            return dataTemp.map((x) => x);
                                        }
                                    })}
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="btn-save" onClick={() => setShowVarProduct(false)}>
                                        Xác nhận
                                    </div>
                                </div>
                            </VarProduct>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="btn-save" onClick={handleSaveProductConfig}>
                                Xác nhận
                            </div>
                        </div>
                    </ProductConfig>
                );
        }
    };
    return (
        <Wrapper X={X} Y={Y} show={show}>
            <div className="square"></div>
            {renderContent(step)}
        </Wrapper>
    );
};

export default ModalConfig;

const Wrapper = styled.div`
    display: ${({ show }) => (show ? "block" : "none")};
    .square {
        position: absolute;
        left: ${({ X }) => (X ? X + "px" : "0")};
        top: ${({ Y }) => (Y ? Y + "px" : "0")};
        height: 40px;
        width: 40px;
        border: 1px solid white;
        box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
        transform: translate(-20px, -20px);
    }
    .content-container {
        position: absolute;
        left: ${({ X }) => (X ? X - 120 + "px" : "0")};
        top: ${({ Y }) => (Y ? Y + 50 + "px" : "0")};
        width: 280px;
        border: 1px solid white;
        background: white;
        padding: 10px;
        box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
        .cancel {
            height: 15px !important;
            width: 15px !important;
            border: none !important;
            position: absolute;
            right: 10px;
            top: 14px !important;
            cursor: pointer;
        }
        .title-header {
            font-weight: 700;
            color: black;
        }
        p {
            color: gray;
            cursor: pointer;
        }
    }
`;
const AvatarConfig = styled.div`
    position: absolute;
    left: ${({ X }) => (X ? X - 120 + "px" : "0")};
    top: ${({ Y }) => (Y ? Y + 50 + "px" : "0")};
    width: 350px;
    border: 1px solid white;
    background: white;
    padding: 10px;
    z-index: 9999;
    box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
    .printer-container {
        display: flex;
        margin-top: 10px;
        span {
            padding: 5px 20px;
        }
        .printer {
            color: white;
            background: #00c853;
        }
    }
    .cancel {
        height: 15px !important;
        width: 15px !important;
        border: none !important;
        position: absolute;
        right: 10px;
        top: 14px !important;
        cursor: pointer;
    }
    .back {
        height: 15px !important;
        width: 15px !important;
        border: none !important;
        position: absolute;
        left: 10px;
        top: 14px !important;
        cursor: pointer;
    }

    .text-area {
        width: 100%;
        height: 50px;
        border: 1px solid;
        margin-bottom: 20px;
    }
    .select {
        margin-top: 20px;
    }
    .size-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            width: 45%;
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
        }
    }
    .btn-save {
        padding: 5px 20px;
        color: white;
        cursor: pointer;
        background: #00c853;
    }
    .font-color {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: space-between;
        .item {
            width: 45%;
            position: relative;
            .color-container {
                display: flex;
                justify-content: space-between;
                .text-box {
                    height: 20px;
                    width: 100%;
                    border: 1px solid;
                    width: 80%;
                }
                .colorPicker {
                    padding: 0 !important;
                    border: 1px solid black !important;
                    height: 20px;
                    width: 15%;
                }
            }
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
            .colorPicker {
                padding: 0 !important;
                border: 1px solid black !important;
            }
            .color-review {
                position: absolute;
                height: 15px;
                width: 15px;
                border: 1px solid;
                right: 0;
                top: 24px;
            }
        }
    }
    .title-header {
        font-weight: 700;
        color: black;
        margin-left: 20px;
    }
    p {
        color: gray;
        cursor: pointer;
    }
`;
const ArtworkConfig = styled.div`
    position: absolute;
    left: ${({ X }) => (X ? X - 120 + "px" : "0")};
    top: ${({ Y }) => (Y ? Y + 50 + "px" : "0")};
    width: 350px;
    border: 1px solid white;
    background: white;
    padding: 10px;
    z-index: 9999;
    box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
    .cancel {
        height: 15px !important;
        width: 15px !important;
        border: none !important;
        position: absolute;
        right: 10px;
        top: 14px !important;
        cursor: pointer;
    }
    .printer-container {
        display: flex;
        margin-top: 10px;
        span {
            padding: 5px 20px;
        }
        .printer {
            color: white;
            background: #00c853;
        }
    }
    .back {
        height: 15px !important;
        width: 15px !important;
        border: none !important;
        position: absolute;
        left: 10px;
        top: 14px !important;
        cursor: pointer;
    }
    .varproduct {
        position: relative;
        display: flex;
        justify-content: space-between;
        border: 1px solid;
        align-items: center;
        .next {
            height: 15px !important;
            width: 15px !important;
            position: relative !important;
            cursor: pointer;
            border: none !important;
        }
    }
    .title-header {
        font-weight: 700;
        color: black;
        margin-left: 20px;
    }
    p {
        color: gray;
        cursor: pointer;
    }
    .three-container {
        display: flex;
        justify-content: space-between;
        .item {
            flex-basis: 45%;
            display: flex;
            flex-direction: column;
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
        }
    }
    .btn-save {
        padding: 5px 20px;
        color: white;
        cursor: pointer;
        background: #00c853;
    }
    .first-container {
        display: flex;
        justify-content: space-between;
        .left {
            flex-basis: 25%;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
        }
        .center {
            flex-basis: 40%;
            border-radius: 10px;
            background: #aaa;
        }
        .right {
            flex-basis: 25%;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
        }
    }
    .second-container {
        display: flex;
        justify-content: space-between;
        .item {
            flex-basis: 45%;
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
        }
    }
`;
const ProductConfig = styled.div`
    position: absolute;
    left: ${({ X }) => (X ? X - 120 + "px" : "0")};
    top: ${({ Y }) => (Y ? Y + 50 + "px" : "0")};
    width: 350px;
    border: 1px solid white;
    background: white;
    padding: 10px;
    z-index: 9999;
    box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
    .cancel {
        height: 15px !important;
        width: 15px !important;
        border: none !important;
        position: absolute;
        right: 10px;
        top: 14px !important;
        cursor: pointer;
    }
    .title-header {
        font-weight: 700;
        color: black;
        margin-left: 20px;
    }
    p {
        color: gray;
        cursor: pointer;
    }
    .back {
        height: 15px !important;
        width: 15px !important;
        border: none !important;
        position: absolute;
        left: 10px;
        top: 14px !important;
        cursor: pointer;
    }
    .size-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            width: 45%;
            .text-box {
                height: 20px;
                width: 100%;
                border: 1px solid;
            }
        }
    }
    .btn-save {
        padding: 5px 20px;
        color: white;
        cursor: pointer;
        background: #00c853;
    }
    .varproduct {
        position: relative;
        display: flex;
        justify-content: space-between;
        border: 1px solid;
        align-items: center;
        .next {
            height: 15px !important;
            width: 15px !important;
            position: relative !important;
            cursor: pointer;
            border: none !important;
        }
    }
`;
const VarProduct = styled.div`
    position: absolute;
    top: 0px;
    display: ${({ showVarProduct }) => (showVarProduct ? "block" : "none")};
    width: 350px;
    z-index: 999;
    padding: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
    p {
        margin: 0;
    }
    .cancel {
        position: relative !important;
        cursor: pointer;
        height: 15px !important;
        width: 15px !important;
        top: 0 !important;
        right: 0 !important;
    }
    .color {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid black;
        margin-right: 10px;
    }
    .select {
        border: 2px solid green;
    }
    .btn {
        padding: 5px 12px;
        border-radius: 20px;
        cursor: pointer;
        color: black;
        font-weight: 500;
        background: white;
    }
    .active {
        background: black;
        color: white;
        border: 1px solid;
    }
    .btn-save {
        padding: 5px 20px;
        color: white;
        cursor: pointer;
        background: #00c853;
    }
`;
