import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Loading from '../../based/Loading';
import useFormValidate from '../../hooks/useFormValidate';
import TFUSelect from '../../based/inputs/TFUSelect';
import ReupServices from '../../based/services/ReupServices';
import styled from "styled-components";
import {  NOTIFY } from "../../based/Constants";
import { Notify } from "../../based/Notify";
import { ConfirmDialog } from "../../based/Dialog";



const INIT_DATA = {
    deviceId: 0,
    folderId: 0,
    mainAccountId: 0,
    x: 0,
    y: 0,
}
const AddOrEditAccountCoordinateModal = ({ show, onClose, onSave, account }) => {
    const [dropDownDevice , setDropDownDevice] = useState([])
    const [dropDownSlot , setDropDownSlot] = useState([])
    const [dropDownApp , setDropDownApp] = useState([])
    const [dropDownMainAccount , setDropDownMainAccount] = useState([])
    const [dropDownAppAccount , setDropDownAppAccount] = useState([])
    const [dropDownScriptParent , setDropDownScriptParent] = useState([])
    const [dataScript , setDataScript] = useState([])
    const [data, setData] = useState({})
    const [busy, setBusy] = useState(false)

    useEffect(()=>{
        getDropDownDevice()
        getDropDownMainAccount()
        getDropDownScriptParent()
    },[])

    const getDropDownScriptParent = async () =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetDropDownScriptParent();
        if (!err && data) {
            setDropDownScriptParent(data);
            setBusy(false);
        } else {
            setDropDownScriptParent([]);
            setBusy(false);
        }
    }
    const getDropDownDevice = async () =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetDeviceDropDown();
        if (!err && data) {
            setDropDownDevice(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    const getDropDownMainAccount = async () =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetMainAccountDropDown();
        if (!err && data) {
            setDropDownMainAccount(data);
            setBusy(false);
        } else {
            setDropDownMainAccount([]);
            setBusy(false);
        }
    }

    useEffect(()=>{
        if(account){
            setData(account)
        }else{
            setData(INIT_DATA)
        }
    },[account])

    useEffect(()=>{
        if(data.deviceId > 0){
            getDropDownSlot(data.deviceId)
        }
    },[data.deviceId])

    const getDropDownSlot = async (deviceId) =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetFolderDropDownByDeviceId(deviceId);
        if (!err && data) {
            setDropDownSlot(data);
            setBusy(false);
        } else {
            setDropDownSlot([]);
            setBusy(false);
        }
    }

    useEffect(()=>{
        if(data.folderId > 0){
            getDropDownApp(data.folderId)
        }
    },[data.folderId])

    const getDropDownApp = async (folderId) =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetAppDropDownByFolderId(folderId);
        if (!err && data) {
            setDropDownApp(data);
            setBusy(false);
        } else {
            setDropDownApp([]);
            setBusy(false);
        }
    }

    useEffect(()=>{
        if(data.mainAccountId > 0){
            getDropDownAppAccount(data.mainAccountId)
            setData({deviceId: 0,
                folderId: 0,
                mainAccountId: 0,
                x: 0,
                y: 0,
             mainAccountId: data.mainAccountId } )
        }
    },[data.mainAccountId])

    const getDropDownAppAccount = async (mainAccountId) =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetAppAccountDropDownByMainAccountId(mainAccountId);
        if (!err && data) {
            setDropDownAppAccount(data);
            setBusy(false);
        } else {
            setDropDownAppAccount([]);
            setBusy(false);
        }
    }


    useEffect(()=>{
        if(data.id > 0){
            getDataScript(data.id)
            getData(data.id)
        }
    },[data.id])

    const getDataScript = async (id) =>{
        setBusy(true);
        let [err, data] = await ReupServices.GetScriptParentByAppAcountId(id);
        if (!err && data) {
            setDataScript(data);
            setBusy(false);
        } else {
            setDataScript([]);
            setBusy(false);
        }
    }

    const getData = async (id) =>{
        setBusy(true);
        let [err, dt] = await ReupServices.GetAppAccountById(id);
        if (!err && dt) {
            setData({...dt});
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }


    async function handleDelete(id,idx) {
        if (id > 0){
            let ids = []
            ids.push(id)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, dt] = await ReupServices.DeleteAppScript(ids);
                    if (!error && dt) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDataScript(data.id)
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        }else{
            let newData = [...dataScript]
            newData.splice(idx, 1)
            setDataScript(newData)
        }

    }
    async function handleCreateUpdate(model) {
        setBusy(true);
        let [err, dt] = await ReupServices.CreateUpdateAppScript(model);
        if (!err && dt) {
            getDataScript(data.id)
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    function IsDisableScriptStep(idx){
        if( !dataScript[idx].scriptId)
            return true
        return false;
    }

    async function handleSave() {
        setBusy(true);
        if(data.x && data.y && data.appId) {
            let [err, dt] = await ReupServices.CreateUpdateAppAccount({
                ...data
            });
            if (!err && dt) {
                onSave()
                setBusy(false);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                setBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
        }
        else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Bổ sung tọa độ , chọn app");

        }
        
    }
    
    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>{account ? 'Cập nhật thông tin tài khoản' : 'Thêm mới tài khoản'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <div className='form-group row'>
                    <div className='col-6'>
                        <div className='form-group'>
                                <label className='required'>Tài khoản chính</label>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <TFUSelect name='mainAccountId' required options={dropDownMainAccount}
                                            placeholder='--Chọn tài khoản--' value={data.mainAccountId}
                                            onChanged={(value) => {
                                                setData({...data, mainAccountId: value})
                                            }} styles={{ flexGrow: 1 }} />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='required'>Tài khoản trang/kênh</label>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <TFUSelect name='id' required options={dropDownAppAccount}
                                        isDisabled={!(data.mainAccountId > 0)}
                                            placeholder='--Chọn trang/kênh--' value={data.id}
                                            onChanged={(value) => {
                                                setData({...data, id: value})
                                            }} styles={{ flexGrow: 1 }} />
                                    </div>
                                </div>
                                <label className='required'>Tọa độ X Y</label>
                                <StyleBody>
                                    <div className="box-coordinate">
                                        <div className="w-50">
                                            <input type="number" disabled={data.id > 0 ? "" : "disabled"} min={0} value={data.x} onChange={(e)=>{
                                                 setData({...data, x: e.target.value})
                                            }} />
                                            <span>X:</span>
                                        </div>
                                        <div className="w-50">
                                            <input type="number" disabled={data.id > 0 ? "" : "disabled"} min={0} value={data.y} onChange={(e)=>{
                                                setData({...data, y:  e.target.value})
                                            }} />
                                            <span>Y:</span>
                                        </div>
                                    </div>
                                </StyleBody>
                            </div>
                        <div className='form-group'>
                            <div className='form-group'>
                                <label className='required'>Thiết bị</label>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <TFUSelect name='deviceId' required options={dropDownDevice} placeholder='--Chọn thiết bị--'
                                            value={data.deviceId} onChanged={(value) => {
                                                setData({...data, deviceId: value})
                                            }} styles={{ flexGrow: 1 }} />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='required'>Slot</label>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <TFUSelect name='folderId' isDisabled={!(data.deviceId > 0)} required options={dropDownSlot}
                                            placeholder='--Chọn slot--' value={data.folderId}
                                            onChanged={(value) =>{
                                                setData({...data, folderId: value})
                                            }} styles={{ flexGrow: 1 }} />
                                    </div>
                                   
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='required'>App</label>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <TFUSelect name='appId' isDisabled={!(data.folderId > 0)} required options={dropDownApp}
                                            placeholder='--Chọn app--' value={data.appId}
                                            onChanged={(value) => {
                                                setData({...data, appId: value})
                                            }} styles={{ flexGrow: 1 }} />
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label className='required'>Scripts:</label>
                            {
                                data.id && <StyleBody>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: 50, textAlign: "center" }}>STT</th>
                                            <th style={{ width: 200, textAlign: "center" }}>Tên kịch bản lớn</th>
                                            <th style={{ width: 200, textAlign: "center" }}>Action</th>
                                        </tr>
                                        {dataScript && dataScript.length > 0
                                        ? dataScript.map((item , idx)=>{
                                            return <tr key={idx}>
                                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                                <td style={{ width: 250, textAlign: "center" }}>
                                                {item.id <= 0 || item.isUpdate ?
                                                    <TFUSelect name='id' required options={dropDownScriptParent}
                                                    placeholder='--Chọn script--' value={item.scriptId}
                                                    onChanged={(value) => {
                                                        let dt = [...dataScript]
                                                        dt[idx].scriptId = value
                                                        setDataScript(dt)
                                                    }} /> : item.scriptName 
                                                    }
                                                    
                                                </td>
                                                <td style={{ width: 200, textAlign: "center" }}>
                                                {( item.isUpdate || item.id <= 0) ? 
                                        
                                                <button
                                                    className={`btn btn-custom  ${IsDisableScriptStep(idx) ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if(!IsDisableScriptStep(idx))
                                                            handleCreateUpdate({
                                                                id: item.id,
                                                                scriptParentId: item.scriptId,
                                                                appId: data.id
                                                            });
                                                    }}
                                                >
                                                    Xong
                                                </button> : 
                                                <button
                                                    className="btn btn-custom"
                                                    onClick={() => {
                                                        let dt = [...dataScript]
                                                        dt[idx].isUpdate = true
                                                        setDataScript(dt)
                                                    }}
                                                        >
                                                    Sửa
                                                </button>
                                            }
                                            
                                                   
                                                    <button className='btn btn-danger ml-3'
                                                    onClick={()=>{
                                                        handleDelete(item.id, idx)
                                                    }}
                                                    >Xóa</button>
                                                </td>
                                            </tr>
                                        })
                                        :""}
                                        <tr>
                                            <td className='add-script' 
                                            style={{
                                                color: '#64C5B1',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                cursor: 'pointer',
                                                marginBottom: '10px',
                                            }} 
                                            colSpan={99}
                                            onClick={()=>{
                                                setDataScript([...dataScript, {
                                                    id: 0,
                                                    scriptId: 0
                                                }])
                                            }}
                                            >Thêm script +</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </StyleBody>
                            }
                            
                        </div>
                    </div>
                   
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={()=>{
                    handleSave()
                }}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};
const StyleBody =  styled.div`
    
    .input-in-table{
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder{
            opacity: 0.3;
        }
    }
    .add-script{

    }
    table{

        tr{
            th{
                padding: 5px;
                border: 1px solid #ccc;
            }
            td{
                padding: 5px;
                border: 1px solid #ccc;
            }
        }
    }
    .box-coordinate{
        display: flex;
        width: 100%;
        align-items: center;

        .w-50{
            width: 50%;
            display: block;
            min-width: 50%;
            max-width: 50%;
            position: relative;
            padding: 5px;

            input{
                width: 100%;
                border: none;
                outline: none;
                height: 40px;
                background: #EBEBEB;
                padding-left: 20px;

            }
            span{
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
            }
        }
    }

`
export default AddOrEditAccountCoordinateModal;