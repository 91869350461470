import React, { useContext } from "react";
import styled from "styled-components";
import { PLATFORM } from "../../based/Constants";
import { ProductContext } from "../../context/ProductContext";

const SelectPlatform = [PLATFORM.Shopee, PLATFORM.Lazada, PLATFORM.Tiktok];

function SectionLowCost(props) {
    const { mainProduct, setMainProduct } = useContext(ProductContext);
    return (
        <Wrapper className="col-sm-12 card-box p-3">
            <div className="header">
                <h4 className="header-title m-t-0">
                    <b>THÔNG TIN CONFIG SÀN (LAZADA/SHOPEE)</b>
                </h4>
            </div>

            <div className="body">
                <div className="form-group">
                    <label htmlFor="">Mẫu trên sàn</label>
                    <div className="sub-instruction">VD: "SET_TITLE" _ Áo thun trẻ em Ranus.....</div>
                    <textarea
                        className="form-control my-3"
                        value={mainProduct.platformNameTemplate}
                        onChange={(e) => {
                            setMainProduct({ ...mainProduct, platformNameTemplate: e.target.value });
                        }}
                        style={{
                            height: "auto",
                        }}
                        rows={4}
                    />
                </div>
            </div>
        </Wrapper>
    );
}

export default SectionLowCost;

const Wrapper = styled.div``;
