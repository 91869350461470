import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CheckBox from "../based/inputs/CheckBox";
import ArtistServices from "../based/services/ArtistServices";
import ModalAddOrUpdate from "./ModalAddOrUpdate";

const ART_FolderType = {
    Artwork: 1,
    Video: 2,
};

export const folderTypeOption = [
    { value: ART_FolderType.Artwork, label: "Họa tiết" },
    { value: ART_FolderType.Video, label: "Video" },
];

const initFolder = {
    id: 0,
    name: "",
    createdBy: "",
    parentFolderId: 0,
    isDelete: false,
    folderType: ART_FolderType.Artwork,
    isFolderTrending: false,
};
const initPage = {
    pageSize: 10,
    pageNumber: 1,
};
const initPageArtwork = {
    pageSize: 10000,
    pageNumber: 1,
    keyword: "",
};
function ArtistDetail(props) {
    const [datas, setDatas] = useState([]);
    const [artist, setArtist] = useState({});
    const [dataArtwork, setDataArtwork] = useState([]);
    const [folder, setFolder] = useState({ ...initFolder });
    const [paging, setPaging] = useState(initPage);
    const [pagingArtwork, setPagingArtwork] = useState(initPageArtwork);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const [msg, setMsg] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateModel, setUpdateModel] = useState({});
    const idArtist = props.match.params.id;
    const [isShowVideo, setIsShowVideo] = useState(false);

    useEffect(() => {
        getArtist(idArtist);
    }, []);

    const getArtist = async (id) => {
        setBusy(true);
        let [err, data] = await ArtistServices.GetArtistById(id);
        if (!err && data) {
            setArtist(data);
            setUpdateModel({
                userId: data.id,
                avatar: data.avatar,
                firstName: data.firstName,
                lastName: data.lastName,
                nickName: data.nickName,
                phoneNumber: data.phoneNumber,
            });
            setDatas(data.folders);
            setDataArtwork([]);
            setBusy(false);
        } else {
            setArtist({});
            setDatas([]);
            setBusy(false);
            setDataArtwork([]);
        }
    };

    const updateUserInfo = async () => {
        setBusy(true);
        let [err, data] = await ArtistServices.UpdateInfoUserArtistAdmin(updateModel);
        if (!err && data == 1) {
            setBusy(false);
            getArtist(idArtist);
            setIsUpdate(!isUpdate);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            if (data == -2) {
                setBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
            } else {
                setBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
        }
    };

    // useEffect(()=>{
    //     let folderId = datas.filter((x) => x.selected).map((x) => x.id)
    //     if(folderId.length == 1){
    //         getDataArkwork(folderId[0])
    //     }
    // },[datas])

    const getDataArkwork = async (id) => {
        setBusy(true);
        let [err, data] = await ArtistServices.GetArtworkByFolderId(id, pagingArtwork);
        if (!err && data) {
            setDataArtwork(data);
            setBusy(false);
        } else {
            setDataArtwork([]);
            setBusy(false);
        }
    };

    const _handleDelete = async () => {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await ArtistServices.DeteleFolderArtist(ids);
                    if (!error && data) {
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getArtist(idArtist);
                    } else {
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có tag nào được chọn. Vui lòng kiểm tra lại!");
    };

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        let labelFolder = item.folderType == ART_FolderType.Artwork ? "Họa tiết" : item.folderType == ART_FolderType.Video ? "Video" : "";
                        return (
                            <tr className={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>{item.name}</td>
                                <td>{labelFolder}</td>
                                <td style={{ width: 100 }}>{item.quantityArtwork}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        newDatas.forEach((m) => (m.selected = false));
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
        if (newDatas[index].selected) {
            getDataArkwork(newDatas[index].id);
            if (newDatas[index].folderType == ART_FolderType.Artwork) {
                setIsShowVideo(false);
            }
            if (newDatas[index].folderType == ART_FolderType.Video) {
                setIsShowVideo(true);
            }
        } else {
            setDataArtwork([]);
        }
    };

    const handleAdd = () => {
        setFolder({ ...initFolder, createdBy: artist.userName });
        setIsShowModal(true);
    };
    const handleEdit = () => {
        let dataChecked = datas.filter((x) => x.selected);
        if (dataChecked && dataChecked.length == 1) {
            setFolder(dataChecked[0]);
        }
    };

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="row flex-row pl-3 box__actions">
                <div className="form-group">
                    <button
                        className="btn btn-custom btn-sm m-r-5"
                        onClick={() => {
                            handleAdd();
                        }}
                    >
                        <i className="fa fa-plus m-r-5" />
                        Thêm folder
                    </button>
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button
                                className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"}
                                onClick={() => {
                                    handleEdit();
                                    setIsShowModal(true);
                                }}
                            >
                                <i className="fa fa-edit m-r-5" />
                                Sửa tên folder
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center"></th>
                    <th className="cursor-pointer">Tên folder</th>
                    <th className="cursor-pointer">Loại folder</th>
                    <th className="cursor-pointer">Số Artwork/Video</th>
                </tr>
            </thead>
        );
    }

    const handleSearchArkwork = async (e) => {
        if (e.charCode === 13) {
            let dataChecked = datas.filter((x) => x.selected);
            getDataArkwork(dataChecked[0].id);
        }
    };

    const handleUploadImg = async (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file, 59) //image type thumbnail folder
                    .then((res) => {
                        if (res && res.data) {
                            setUpdateModel({ ...updateModel, avatar: res.data.thumbnailUrl });
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                          return `${prev}${item.value}<br/>`;
                                      }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                    })
                    .catch((err) => {});
            };

            reader.readAsDataURL(file);
        }
    };

    const _handleDeleteJob = async (id) => {
        if (id > 0)
            ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn muốn xóa arkwork này ra khỏi folder ?", () => {
                return new Promise(async (resolve, reject) => {
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await ArtistServices.DeleteJobLiveStream(id);
                    if (!error && data) {
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getArtist(idArtist);
                    } else {
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
    };

    const _handleDeleteArtwork = (idss) => {
        let dataChecked = datas.filter((x) => x.selected);

        let ids = [];
        ids.push(idss);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn muốn xóa arkwork này ra khỏi folder ?", () => {
                return new Promise(async (resolve, reject) => {
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await ArtistServices.DeteleFolderArtistCon(ids, dataChecked[0].id);
                    if (!error && data) {
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getArtist(idArtist);
                    } else {
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có tag nào được chọn. Vui lòng kiểm tra lại!");
    };
    return (
        <React.Fragment>
            <Wapper>
                <Breadcrumbs currentPage="Quản lý artist" />
                <Loading show={isBusy} msg={msg} />
                <div className="full-w">
                    <div className="avatar">
                        <span>Avatar</span>
                        {artist.avatar ? <img src={updateModel.avatar} /> : <span className="w-125">Chưa có avatar</span>}
                        <input
                            type="file"
                            onChange={(e) => {
                                handleUploadImg(e);
                            }}
                            id="avatar-file"
                            hidden
                        />
                        {isUpdate ? (
                            <label for="avatar-file" className="btn-custom-info">
                                Chọn ảnh
                            </label>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-art">
                        <div className="row-art">
                            <div>
                                <label>Họ</label>
                                <input
                                    disabled={isUpdate ? "" : "disabled"}
                                    type="text"
                                    value={updateModel.firstName}
                                    onChange={(e) => {
                                        setUpdateModel({ ...updateModel, firstName: e.target.value });
                                    }}
                                />
                            </div>
                            <div>
                                <label>Tên</label>
                                <input
                                    disabled={isUpdate ? "" : "disabled"}
                                    type="text"
                                    value={updateModel.lastName}
                                    onChange={(e) => {
                                        setUpdateModel({ ...updateModel, lastName: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row-art">
                            <div>
                                <label>Nickname</label>
                                <input
                                    disabled={isUpdate ? "" : "disabled"}
                                    type="text"
                                    value={updateModel.nickName}
                                    onChange={(e) => {
                                        setUpdateModel({ ...updateModel, nickName: e.target.value });
                                    }}
                                />
                            </div>
                            <div>
                                <label>Số điện thoại</label>
                                <input
                                    disabled={isUpdate ? "" : "disabled"}
                                    type="text"
                                    value={updateModel.phoneNumber}
                                    onChange={(e) => {
                                        setUpdateModel({ ...updateModel, phoneNumber: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-art">
                        <div className="row-art">
                            <div className="btn-row">
                                <button
                                    className="btn-custom-info"
                                    onClick={() => {
                                        if (isUpdate) {
                                            setUpdateModel({
                                                userId: artist.id,
                                                avatar: artist.avatar,
                                                firstName: artist.firstName,
                                                lastName: artist.lastName,
                                                nickName: artist.nickName,
                                                phoneNumber: artist.phoneNumber,
                                            });
                                        }
                                        setIsUpdate(!isUpdate);
                                    }}
                                >
                                    {isUpdate ? "Hủy" : "Chỉnh sửa thông tin"}
                                </button>
                            </div>
                            <div className="btn-row">
                                {isUpdate ? (
                                    <button
                                        className="btn-custom-info"
                                        onClick={() => {
                                            updateUserInfo();
                                        }}
                                    >
                                        Lưu thay đổi
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="w-40pc">
                        <CommonTable data={datas} paging={paging} ref={tableRef} headerCheckbox hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} />
                    </div>
                    <div className="w-60pc">
                        <div>
                            <div className="header-art">
                                <span>Chi tiết</span>
                                <input
                                    type="text"
                                    placeholder="Nhập tên arkwork"
                                    onKeyDown={(e) => {
                                        setPagingArtwork({ ...pagingArtwork, keyword: e.target.value });
                                    }}
                                    onKeyPress={(e) => {
                                        handleSearchArkwork(e);
                                    }}
                                />
                            </div>
                            <div className="list-arkwork">
                                {dataArtwork.length > 0
                                    ? dataArtwork.map((item, idx) => {
                                          if (isShowVideo) {
                                              if (item.url)
                                                  return (
                                                      <div key={idx} className="col">
                                                          <div className="item">
                                                              {item.url ? (
                                                                  <video controls>
                                                                      <source src={item.url} type="video/mp4" />
                                                                  </video>
                                                              ) : (
                                                                  "Không có video"
                                                              )}
                                                              <div
                                                                  className="delete-item"
                                                                  onClick={() => {
                                                                      _handleDeleteJob(item.id);
                                                                  }}
                                                              >
                                                                  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <path d="M10 1.5L1 10.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round" />
                                                                      <path d="M10 10.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round" />
                                                                  </svg>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  );
                                              return null;
                                          }

                                          return (
                                              <div key={idx} className="col">
                                                  <div className="item">
                                                      {item.url ? <img src={item.url} alt={item.name} /> : "Không có ảnh"}
                                                      <div
                                                          className="delete-item"
                                                          onClick={() => {
                                                              _handleDeleteArtwork(item.id);
                                                          }}
                                                      >
                                                          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path d="M10 1.5L1 10.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round" />
                                                              <path d="M10 10.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round" />
                                                          </svg>
                                                      </div>
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : isShowVideo
                                    ? "Chưa có video"
                                    : "Chưa có artwork"}
                            </div>
                        </div>
                    </div>
                </div>
            </Wapper>
            {isShowModal && (
                <ModalAddOrUpdate
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getArtist(idArtist);
                        setDataArtwork([]);
                    }}
                    folder={folder}
                />
            )}
        </React.Fragment>
    );
}
const Wapper = styled.div`
    .full-w {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .btn-custom-info {
            margin-top: 10px;
            border: none;
            outline: none;
            background: #64c5b1;
            color: #fff;
            cursor: pointer;
            padding: 3px 10px;
        }

        .avatar {
            width: 125px;
            margin-right: 40px;
            span {
                display: block;
                font-weight: 500;
            }
            img {
                width: 125px;
                height: 125px;
                object-fit: contain;
            }

            .w-125 {
                width: 100%;
                height: 125px;
            }
        }
        .col-art {
            height: 146px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .row-art {
                display: flex;

                .btn-row {
                    margin-right: 0;
                }
                div {
                    margin: 0 20px;
                }
                label {
                    display: block;
                }

                input {
                    width: 300px;
                }
            }
        }
    }
    .add-folder {
        color: #64c5b1;
        cursor: pointer;
    }
    .w-40pc {
        width: 40%;
    }
    .w-60pc {
        width: 60%;
        padding: 47px 10px 10px;
        background-color: white;

        .header-art {
            width: 100%;
            height: 32.5px;
            background-color: #e4e4e4;
            display: flex;
            align-items: center;
            padding-left: 16px;

            span {
                color: #797979;
                font-size: 14px;
                font-weight: 600;
            }

            input {
                margin-left: 20px;
                width: 300px;
                background: #fff;
                border: none;
                padding: 3px 10px;
                outline: none;
            }
        }
        .list-arkwork {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px;
            margin-top: 10px;
            height: 320px;
            overflow-y: auto;
            ::-webkit-scrollbar-thumb {
                background: #64c5b1;
            }
            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            .col {
                width: 16.66666%;
                min-width: 16.66666%;
                max-width: 16.66666%;
                padding: 0 5px;
                margin-bottom: 10px;
                .item {
                    width: 100%;
                    position: relative;
                    border: 1px solid #ccc;
                    img {
                        width: 100%;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }

                    video {
                        width: 100%;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }

                    .delete-item {
                        position: absolute;
                        top: 5px;
                        right: 7px;
                        cursor: pointer;
                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
    .d-flex {
        display: flex;
    }
    .custom-df {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name-folder {
            font-weight: bold;
        }
    }
    .input-search-audio {
        height: 30px;
        width: 40%;
        border-radius: 5px;
        padding: 0 10px;
    }
    .row.flex-row.p-10.box__actions {
        padding: 20px;
    }
`;
export default ArtistDetail;
