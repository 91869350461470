import React, { Component } from "react";
import PropTypes from "prop-types";
import Common from "../Common";

class Radio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        };
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
    }
    static defaultProps = {
        required: false,
        isDisabled: false,
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onCheckedChange: PropTypes.func,
        checked: PropTypes.bool,
        isDisabled: PropTypes.bool,
    };

    handleCheckedChange(e) {
        let value = e.target.value;
        this.props.onCheckedChange(this.props.name, value);
    }

    validateInput() {
        return true;
    }
    render() {
        let guid = Common.generateGuid();
        var classDiv = this.props.isInline ? "radio radio-success form-check-inline" : "radio radio-success";
        if (this.props.classDiv) classDiv += " " + this.props.classDiv;
        return (
            <div className={classDiv}>
                <input disabled={this.props.isDisabled} id={guid} name={this.props.name} type='radio' onChange={this.handleCheckedChange} checked={this.props.checked} value={this.props.value} />
                <label htmlFor={guid}>{this.props.label}</label>
            </div>
        );
    }
}
export default Radio;
