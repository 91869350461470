import BaseServices from "./BaseServices";

const WarehouseServices = {
    GetProductDetails: async (id) => {
        return await BaseServices.Get(`/api/Warehouse/get-product?id=${id}`);
    },
    StockIn: async (model) => {
        return await BaseServices.Post("/api/Warehouse/stock-in", model);
    },
    GetOrderHistory: async (model) => {
        return await BaseServices.Post("/api/Warehouse/get-orders", model);
    },
    ChangeStatus: async (orderId, status) => {
        let obj = { orderId, status };
        return await BaseServices.Post("/api/Warehouse/change-status", obj);
    },
    DeleteOrder: async (orderId) => {
        return await BaseServices.Post("/api/Warehouse/remove-order/" + orderId);
    },
    GetOrderDetail: async (orderId) => {
        return await BaseServices.Get("/api/Warehouse/get-order-detail/" + orderId);
    },
    GetWarehouseById: async (warehouseId) => {
        return await BaseServices.Get("/api/Warehouse/get-warehouse-by-id/" + warehouseId);
    },
    ReceivedProducts: async (obj) => {
        return await BaseServices.Post("/api/Warehouse/received-products", obj);
    },
    GetWarehouseDropdown: async () => {
        return await BaseServices.Get("/api/Warehouse/get-dropdown-warehouse");
    },
    GetListSlotProductDetail: async (productId, productType) => {
        return await BaseServices.Get(`/api/Warehouse/get-slot-products/${productId}/${productType}`);
    },
    GetWarehouses: async (obj) => {
        return await BaseServices.Post("/api/Warehouse/get-warehouses", obj);
    },
    GetWareslot: async (id, paging) => {
        return await BaseServices.Post("/api/Warehouse/get-warehouse-slots/" + id, paging);
    },
    GetSlotsContainingProduct: async (warehouseId, productId) => {
        return await BaseServices.Get(`/api/Warehouse/get-slots-containing-product?warehouseId=${warehouseId}&productId=${productId}`);
    },
    DeleteSlot: async (slotCode, warehouseId) => {
        return await BaseServices.Post(`/api/Warehouse/delete-slot/${slotCode}/${warehouseId}`);
    },
    DeleteWarehouse: async (ids) => {
        return await BaseServices.Post("/api/Warehouse/delete-warehouses", ids);
    },
    QueryButtonDisplay: async (ids) => {
        return await BaseServices.Get(`/api/Warehouse/query-button-display/${ids}`);
    },
    GetSlotProduct: async (warehouseId, slotCode, productId) => {
        return await BaseServices.Get(`/api/Warehouse/get-slot-product/${warehouseId}/${slotCode}/${productId}`);
    },
    GetApprovedQuantity: async (productId, orderId) => {
        return await BaseServices.Get(`/api/Warehouse/get-approved-quantity/${productId}/${orderId}`);
    },
    SyncOPFWarehouse: async (shopId, platform) => {
        return await BaseServices.Post(`/api/Warehouse/sync-opf-warehouse?shopId=${shopId}&platform=${platform}`);
    },
    GetWarehouseOPFById: async (warehouseId) => {
        return await BaseServices.Get("/api/Warehouse/get-warehouseopf-by-id/" + warehouseId);
    },
    UpdateWarehouseOPF: async (model) => {
        return await BaseServices.Post(`/api/Warehouse/update-warehouseopf`, model);
    },

    //#region  StockIn Logo
    GetStockInLogoPaging: async (model) => {
        return await BaseServices.Post(`/api/Warehouse/get-stockin-logo-paging`, model);
    },
    SaveStockInLogo: async (model) => {
        return await BaseServices.Post(`/api/Warehouse/save-stockin-logo`, model);
    },
    AdjustStockInLogo: async (model) => {
        return await BaseServices.Post(`/api/Warehouse/ajust-stockin-logo`, model);
    },
    GetStockInLogoDetail: async (objectId, objectType) => {
        return await BaseServices.Get(`/api/Warehouse/get-stockin-logo-detail?objectId=${objectId}&objectType=${objectType}`);
    },
    DeleteStockInLogo: async (objectId, objectType) => {
        return await BaseServices.Post(`/api/Warehouse/delete-stockin-logo?objectId=${objectId}&objectType=${objectType}`, null);
    },
    //#endregion
};

export default WarehouseServices;
