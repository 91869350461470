import React, { useState, useEffect, useRef } from "react";
import { Layout } from "../layout/Layout";
import Loading from "../based/Loading";
import Pubsub from "pubsub-js";
import { CONSTANTS, MAINMENU } from "../based/Constants";
// import { NOTIFY } from "../based/Constants";
// import { Notify } from "../based/Notify";
// import { ConfirmDialog } from "../based/Dialog";
import Common from "../based/Common";
import Language from "../based/Language";
import CustomerModal from "./CustomerModal";
import CommonTable from "../based/CommonTable";
// import CheckBox from "../based/inputs/CheckBox";
import CustomerDetailsModal from "./CustomerDetailsModal";
import ThirdPartyServices from "../based/services/ThirdPartyServices";
import Breadcrumbs from "../based/Breadcrumbs";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelectSubdivisions from "../based/TFUSelectSubdivisions";
import TFUSelect from "../based/inputs/TFUSelect";
import NumberFormat from "react-currency-format";
import SpinnerCountInfo from "./components/SpinnerCountInfo";
var moment = require("moment");

const initCustomer = {
    id: 0,
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    street: "",
    provinceCode: "",
    districtCode: "",
    wardCode: "",
    birthDate: new Date(),
    gender: 0,
    thirdPartyType: 1,
    isActive: false,
    storeName: "",
    countPurchasedOfSeller: 0,
    purchasedAmountOfSeller: "",
    countPurchasedOfAllSellers: 0,
    purchasedAmountOfAllSellers: "",
};

const genderOptions = [
    {
        label: "Nam",
        text: "Nam",
        value: "1",
    },
    {
        label: "Nữ",
        text: "Nữ",
        value: "2",
    },
    {
        label: "Khác",
        text: "Khác",
        value: "3",
    },
];

export default function Customer(props) {
    const [isBusy, setIsBusy] = useState(true);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [isShowModal, setIsShowModal] = useState(false);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...Common.Paging, gender: -1 });
    const [customer, setCustomer] = useState(initCustomer);
    const [isShowDetailsModal, setIsShowDetailsModal] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState({});

    const [spinnerModal, setSpinnerModal] = useState(false);

    useEffect(() => {
        getDatas(paging);
        Pubsub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Customer });
    }, []);

    async function getDatas(newPaging) {
        setIsBusy(true);
        // setSelectedAll(false);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await ThirdPartyServices.GetsCustomers(newPaging);
        if (!err && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startPurchaseDate) pagingUp.startPurchaseDate = moment(pagingUp.startPurchaseDate, "DD/MM/yyyy").toDate();
                if (pagingUp.endPurchaseDate) pagingUp.endPurchaseDate = moment(pagingUp.endPurchaseDate, "DD/MM/yyyy").toDate();

                if (pagingUp.startCreatedDate) pagingUp.startCreatedDate = moment(pagingUp.startCreatedDate, "DD/MM/yyyy").toDate();
                if (pagingUp.endCreatedDate) pagingUp.endCreatedDate = moment(pagingUp.endCreatedDate, "DD/MM/yyyy").toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsBusy(false);
            setMsg(null);
        } else {
            setDatas([]);
            setPaging(Common.Paging);
            setIsBusy(false);
            setMsg(null);
        }
    }
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        if (paging.startPurchaseDate) newPaging.startPurchaseDate = moment(paging.startPurchaseDate).format("DD/MM/YYYY");
        if (paging.endPurchaseDate) newPaging.endPurchaseDate = moment(paging.endPurchaseDate).format("DD/MM/YYYY");

        if (paging.startCreatedDate) newPaging.startCreatedDate = moment(paging.startCreatedDate).format("DD/MM/YYYY");
        if (paging.endCreatedDate) newPaging.endPurchaseDate = moment(paging.endCreatedDate).format("DD/MM/YYYY");
        getDatas(newPaging);
    }
    function _renderAction() {
        return (
            <div className='p-x-10 box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='customerName'>Tên User: </label>
                            <Textbox className='form-control' name='customerName' onChanged={(name, value) => setPaging({ ...paging, customerName: value })} value={paging.customerName} />
                        </div>
                        <div className='form-group col-md-4'>
                            <TFUSelectSubdivisions showProvince provinceCode={paging.provinceCode} onChanged={(obj) => setPaging({ ...paging, provinceCode: obj ? obj.provinceCode : "" })} parentClassName='form-group' childClassName='m-b-5' />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='street'>Địa chỉ nhà:</label>
                            <Textbox className='form-control' name='street' onChanged={(name, value) => setPaging({ ...paging, street: value })} value={paging.street} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='gender'>Giới tính:</label>
                            <TFUSelect name='gender' value={paging.gender} options={genderOptions} placeholder='---Tất cả---' onChanged={(value) => setPaging({ ...paging, gender: value != null ? value : 0 })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='purchaseDate'>Ngày mua hàng:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='startPurchaseDate'
                                        maxDate={paging.endPurchaseDate}
                                        value={paging.startPurchaseDate}
                                        placeholder='Từ ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, startPurchaseDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='endPurchaseDate'
                                        minDate={paging.startPurchaseDate}
                                        value={paging.endPurchaseDate}
                                        placeholder='Đến ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, endPurchaseDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='createdDate'>Ngày đăng ký:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='startCreatedDate'
                                        maxDate={paging.endCreatedDate}
                                        value={paging.startCreatedDate}
                                        placeholder='Từ ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, startCreatedDate: newValue })}
                                    />
                                    <TFUDatetime
                                        dateFormat='dd/MM/yyyy'
                                        name='endCreatedDate'
                                        minDate={paging.startCreatedDate}
                                        value={paging.endCreatedDate}
                                        placeholder='Đến ngày'
                                        onChanged={(newValue) => setPaging({ ...paging, endCreatedDate: newValue })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th className='cursor-pointer text-center' width={50}>
                        STT
                    </th>
                    <th className='cursor-pointer text-center'>Tên User</th>
                    <th className='cursor-pointer text-center'>Ngày sinh</th>
                    <th className='cursor-pointer text-center'>Giới tính</th>
                    <th className='cursor-pointer text-center'>Ngày đăng ký</th>
                    <th className='cursor-pointer text-center'>Tổng tiền đơn hàng (đ)</th>
                    <th className='cursor-pointer text-center'>Địa chỉ nhà</th>
                    <th className='cursor-pointer text-center'>Thành phố</th>
                    <th className='cursor-pointer text-center' width='150'>
                        Chấp nhận COD
                    </th>
                    <th className='cursor-pointer text-center' width='150'>
                        Điểm Reputation
                    </th>
                    <th className='cursor-pointer text-center' width='150'>
                        Verified
                    </th>
                    <th className='cursor-pointer text-center' width='50'></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        if (datas && datas.length > 0)
            return (
                <tbody>
                    {datas.map((item, i) => {
                        let genderName = "Khác";
                        let gender = genderOptions.find((x) => x.value == item.gender);
                        if (gender != null) genderName = gender.label;
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td className='text-center'>{no}</td>
                                <td className='text-left'>
                                    <div>{item.fullName}</div>
                                    {item.phoneNumber && <small>{item.phoneNumber}</small>}
                                </td>
                                <td className='text-center'>{item.birthDateStr}</td>
                                <td className='text-center'>{genderName}</td>
                                <td className='text-center'></td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalAmountOrder} />
                                </td>
                                <td className='text-center'>{item.street}</td>
                                <td className='text-center'>{item.provinceName}</td>
                                <td className='text-center'>
                                    {item.isPaidCOD ? (
                                        <span className='status__active'>
                                            <i className='fa fa-check m-r-5'></i>Có
                                        </span>
                                    ) : (
                                        <span className='status__unactive'>
                                            <i className='fa fa-exclamation m-r-5'></i>Không
                                        </span>
                                    )}
                                </td>
                                <td className='text-right'>{item.reputation}</td>
                                <td className='text-center'>
                                    {item.verified ? (
                                        <span className='status__active'>
                                            <i className='fa fa-check m-r-5'></i>Đã Verified
                                        </span>
                                    ) : (
                                        <span className='status__unactive'>
                                            <i className='fa fa-exclamation m-r-5'></i>Chưa Verified
                                        </span>
                                    )}
                                </td>
                                <td className='text-center vertical-align-middle '>
                                    <i title='Xem' className='mdi mdi-eye mdi-24px cursor-pointer' style={{ color: "#159a7f" }} onClick={(e) => _handleViewDetail(e, item)}></i>
                                    <i title='Điều chỉnh lượt quay' className='mdi mdi-table-edit mdi-24px cursor-pointer ml-2' style={{ color: "#159a7f" }} onClick={(e) => _handleEditSpinnerCount(e, item)}></i>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            );
        return (
            <tbody>
                <tr>
                    <td colSpan='100' className='center'>
                        {Language.getValue("common.noRecords")}
                    </td>
                </tr>
            </tbody>
        );
    }

    function _handleViewDetail(e, item) {
        e.preventDefault();
        setIsShowDetailsModal(true);
        setCurrentCustomer(item);
    }

    function _handleEditSpinnerCount(e, item) {
        e.preventDefault();
        setSpinnerModal(true);
        setCurrentCustomer(item);
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý Buyer User' />
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={datas} paging={paging} headerCheckbox renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
            {isShowModal && (
                <CustomerModal
                    isShowModal={isShowModal}
                    customer={customer}
                    onSaveSucceed={() => {
                        getDatas(paging);
                        setIsShowModal(false);
                    }}
                    onClose={() => setIsShowModal(false)}
                />
            )}
            {isShowDetailsModal && (
                <CustomerDetailsModal
                    isShowModal={isShowDetailsModal}
                    customer={currentCustomer}
                    genderOptions={genderOptions}
                    onClose={() => {
                        setIsShowDetailsModal(false);
                    }}
                />
            )}
            {spinnerModal && <SpinnerCountInfo isShowModal={spinnerModal} user={currentCustomer} onClose={() => setSpinnerModal(false)} onSaveSucceed={() => setSpinnerModal(false)} />}
        </React.Fragment>
    );
}
