import BaseServices from "../BaseServices";

const TypeQuestionServices = {
    CreateUpdateTypeQuestion: async (model) => {
        return await BaseServices.Post("/api/TypeQuestion/create-update-help-type-question", model);
    },
    DeleteTypeQuestion: async (ids) => {
        return await BaseServices.Post("/api/TypeQuestion/delete-help-type-question", ids);
    },
    GetTypeQuestionByPage: async (model) => {
        return await BaseServices.Post("/api/TypeQuestion/get-help-type-question", model);
    },
    GetTypeQuestionChildByPage: async (model) => {
        return await BaseServices.Post("/api/TypeQuestion/get-type-question-and-child", model);
    },
    GetTypeQuestionById: async (id) => {
        return await BaseServices.Get(`/api/TypeQuestion/get-help-type-question-byId/${id}`);
    },
    GetTypeQuestionDropdown: async (id) =>{
        return BaseServices.Post(`/api/TypeQuestion/get-type-question-dropdown/${id}`);
    },
};

export default TypeQuestionServices;
