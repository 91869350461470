import React, { useState } from "react";
import { Route } from "react-router-dom";
import Breadcrumbs from "../based/Breadcrumbs";
import Loading from "../based/Loading";
import ProductsListing from "./ProductsListing";
export default function ManageProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Sản phẩm" />
            <Loading show={isLoading} msg="Đang tải dữ liệu..." />
            <Route path="/" component={ProductsListing} />
        </React.Fragment>
    );
}
