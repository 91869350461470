import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, ORDER_TYPE, STOCK_IN_STATUS, STOCK_IN_STATUS_NAME } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import StockOutServices from "../based/services/StockOutServices";
import StockOutModal from "./StockOutModal";

const initPaging = {
    pageSize: 10,
    pageNumber: 1,
    orderTypes: ORDER_TYPE.StockOut,
};

//Dùng để exclude các status không muốn show trên screen
const excludeStatus = [STOCK_IN_STATUS.RejectApproval];
const TAB_INDEX = { ...STOCK_IN_STATUS, ALL: 0 };
const TAB_INDEX_NAME = { ...STOCK_IN_STATUS_NAME, ALL: "Tất cả" };
const selectedTabIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export default function StockOutHistory(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const [readOnlyDetail, setReadOnlyDetail] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const [paging, setPaging] = useState(initPaging);
    const [orders, setOrders] = useState([]);
    const [tabIndex, setTabIndex] = useState(TAB_INDEX.ALL);
    //Status của button
    const [orderBtnStatus, setOrderBtnStatus] = useState(0);

    useEffect(() => {
        _fetchData(paging);
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.StockOutHistory });
        setIsBusy(false);
    }, []);

    async function _fetchData(pagingModel) {
        const [err, data] = await StockOutServices.GetOrderHistory(pagingModel);
        if (!err) {
            setOrders(data.listObjects);
            setPaging(data.paging);
        } else {
            setOrders([]);
        }
    }

    function _renderTabIndex() {
        let resultTabIndex = Object.entries(TAB_INDEX).reduce((prev, val) => (selectedTabIndex.indexOf(val[1]) >= 0 ? [...prev, [val[1], val[0]]] : prev), []);
        let newMap = new Map(resultTabIndex);
        let tempIndex = selectedTabIndex.map((item) => {
            return [item, newMap.get(item)];
        });

        return tempIndex.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a className={tabIndex == item[0] ? "nav-link active" : "nav-link"} onClick={() => _handleChangeTab(item[0])}>
                        <span className="d-none d-sm-block">{TAB_INDEX_NAME[item[1]]}</span>
                    </a>
                </li>
            );
        });
    }

    function _handleChangeTab(index) {
        setTabIndex(index);
        let datas = { ...paging, status: index };
        _fetchData(datas);
        setPaging(datas);
    }

    //Status Button:
    //Duyệt: Show Modal, Readonly, Show 2 Button (Duyệt - Từ chối)
    //Xử lý yêu cầu: Show Modal, Được quyền chỉnh sửa, Show Button (Nhận hàng)
    //Đang chờ xác nhận: Show Modal, Được quyền chỉnh sửa, Show Button (Đang chờ xác nhận)
    function _handleChangeStatus(orderId, nextStatus) {
        switch (parseInt(nextStatus)) {
            case STOCK_IN_STATUS.Approved:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(true);
                break;
            case STOCK_IN_STATUS.InboundingProcess:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(false);
                break;
            case STOCK_IN_STATUS.PendingApproval:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(false);
                break;
            case STOCK_IN_STATUS.Cancelled:
                ConfirmDialog("Xác nhận", "Bạn có chắc chắn muốn hủy đơn đã chọn?", () => {
                    return new Promise((resolve, reject) => {
                        _handleServicesChangeStatus(orderId, nextStatus);
                        resolve({ title: "Thành công", msg: "Hủy thành công." });
                    });
                });
                break;
            case STOCK_IN_STATUS.Completed:
                setOrderId(orderId);
                setOrderBtnStatus(nextStatus);
                setShowDetail(true);
                setReadOnlyDetail(true);
                break;
            default:
                _handleServicesChangeStatus(orderId, nextStatus);
                break;
        }
    }

    async function _handleServicesChangeStatus(orderId, nextStatus) {
        const [err, data] = await StockOutServices.ChangeStatus(orderId, nextStatus);
        if (!err) {
            let updateOrders = [...orders];
            let order = updateOrders.filter((m) => m.id == orderId)[0];
            order.status = data.status;
            order.statusId = data.statusId;
            order.statusColor = data.statusColor;
            order.nextStatus = data.nextStatus;
            setOrders(updateOrders);
            setOrderBtnStatus(0);
            _fetchData(paging);
        }
    }

    function _handleDeleteOrder(orderId) {
        ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn muốn xóa đơn đã chọn", () => {
            return new Promise(async (resolve, reject) => {
                const [err, data] = await StockOutServices.DeleteOrder(orderId);
                if (!err) {
                    await _fetchData(initPaging);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    reject({ title: "Có lỗi", msg: "Xóa gặp lỗi." });
                }
            });
        });
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">Số chứng từ</th>
                    <th className="center">Trạng thái xuất kho</th>
                    <th className="center">Ngày tạo đơn</th>
                    <th className="center">Thời gian dự kiến xuất</th>
                    <th className="center">Thông tin khách hàng</th>
                    <th className="center">Mã sản phẩm</th>
                    <th className="center">Số lượng SP</th>
                    <th className="center">Số lượng SP lỗi</th>
                    <th className="center"></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        var body =
            orders && orders.length > 0 ? (
                orders.map((order, idx) => {
                    return (
                        <tr key={idx}>
                            <td>{order.orderCode}</td>
                            <td style={{ color: order.statusColor }}>{order.status}</td>
                            <td>{order.createdDateString}</td>
                            <td>{order.estimatedTimeString}</td>
                            <td>{order.thirdPartyName}</td>
                            <td>{order.productCodes}</td>
                            <td>{order.totalGoodProducts + order.totalBadProducts}</td>
                            <td>{order.totalBadProducts}</td>
                            <td>{__renderActionButtons(order)}</td>
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td colSpan="9">Không có dữ liệu</td>
                </tr>
            );
        return <tbody>{body}</tbody>;
    }

    function __renderActionButtons(order) {
        if (order.nextStatus && order.nextStatus.length > 0) {
            return (
                <DropdownButton
                    id="dropdown-basic-button"
                    title={
                        <span>
                            <i className="fa fa-cog m-r-5" />
                            Thao tác
                        </span>
                    }
                    variant="custom"
                    size="sm"
                >
                    {order.nextStatus.reduce(
                        (prev, status, index) =>
                            !excludeStatus.includes(parseInt(status.value))
                                ? [
                                      ...prev,
                                      <Dropdown.Item key={index} onClick={() => _handleChangeStatus(order.id, status.value)}>
                                          {status.label}
                                      </Dropdown.Item>,
                                  ]
                                : prev,
                        []
                    )}
                    {order.statusId == STOCK_IN_STATUS.PendingApproval ? (
                        <Dropdown.Item key={-1} onClick={() => _handleDeleteOrder(order.id)}>
                            Xóa
                        </Dropdown.Item>
                    ) : (
                        ""
                    )}
                    {order.statusId == STOCK_IN_STATUS.PartiallyReceived || order.statusId == STOCK_IN_STATUS.CompletelyReceived ? (
                        <Dropdown.Item
                            key={-2}
                            onClick={() => {
                                setOrderId(order.id);
                                setOrderBtnStatus(STOCK_IN_STATUS.Completed);
                                setShowDetail(true);
                                setReadOnlyDetail(true);
                            }}
                        >
                            Chi tiết
                        </Dropdown.Item>
                    ) : (
                        ""
                    )}
                </DropdownButton>
            );
        }
        return (
            <DropdownButton
                id="dropdown-basic-button"
                title={
                    <React.Fragment>
                        <i className="fa fa-cog m-r-5" />
                        Thao tác
                    </React.Fragment>
                }
                variant="custom "
                size="sm"
            >
                <Dropdown.Item
                    key={-2}
                    onClick={() => {
                        setOrderId(order.id);
                        setOrderBtnStatus(-1);
                        setShowDetail(true);
                        setReadOnlyDetail(true);
                    }}
                >
                    Chi tiết
                </Dropdown.Item>
            </DropdownButton>
        );
    }

    function _renderAction() {
        return (
            <button
                className="btn btn-custom btn-sm m-r-5"
                onClick={(event) => {
                    event.preventDefault();
                    let history = props.history;
                    history.push("/stock-out");
                }}
            >
                <i className="fa fa-plus m-r-5" />
                Tạo lệnh xuất hàng
            </button>
        );
    }

    return (
        <React.Fragment>
            {isBusy ? (
                <Loading show={true} msg={"Đang tải dữ liệu..."} fullScreen />
            ) : (
                <React.Fragment>
                    <Breadcrumbs currentPage=" Xuất kho" parentPage="Kho" hrefParent="/warehouse" />
                    <ul className="nav nav-tabs tabs-bordered">{_renderTabIndex()}</ul>
                    <CommonTable onFilter={_fetchData} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} />
                    {orderId > 0 && showDetail ? (
                        <StockOutModal
                            isShowModal={showDetail}
                            readOnly={readOnlyDetail}
                            orderId={orderId}
                            onClose={() => setShowDetail(false)}
                            onSave={() => {
                                _fetchData(paging);
                                setShowDetail(false);
                            }}
                            orderBtnStatus={orderBtnStatus}
                            handleChangeStatus={(orderId, status) => _handleServicesChangeStatus(orderId, status)}
                        />
                    ) : (
                        ""
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
