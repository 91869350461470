import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import GameServices from "./GameServices";
import Common from "../based/Common";
import { useHistory } from "react-router-dom";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Request from "../based/Request";
import ReportModal from "./ReportModal";
import CuttingModal from "./CuttingModal";

export const gameModel = {
    id: 0, // long in C#
    createdDate: new Date(), // DateTime in C#
    note: "", // string in C#
    totalCards: 0, // int in C#
    defaultRatePlayerWithoutTie: 0, // int in C#
    defaultRateBankerWithoutTie: 0, // int in C#
    defaultRateTie: 0, // int in C#
    defaultSmallReturnRate: 0,
    defaultBigReturnRate: 0,
};

function GamePage(props) {
    const { printingBatch } = props.match.params;
    const [listModel, setListModel] = useState([gameModel]);
    const [pagingModel, setPagingModel] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 20 });
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef(null);
    const history = useHistory();
    const [selectGameId, setSelectGameId] = useState(0);
    const [showCutting, setShowCutting] = useState(false);

    useEffect(() => {
        getData(pagingModel);
    }, []);

    //lấy ds game
    const getData = async (page) => {
        setLoading(true);

        const [err, data] = await GameServices.GetGames(page);
        if (!err && data && data.listObjects && data.listObjects.length > 0) {
            if (page.pageNumber == 1) setListModel(data.listObjects);
            else setListModel([...listModel, ...data.listObjects]);
            setPagingModel(data.paging);
            setHasMore(true);
        } else {
            if (page.pageNumber == 1) setListModel([]);
            setHasMore(false);
            setPagingModel(page);
        }

        setLoading(false);
    };

    const setLastCollectionHandler = (element) => {
        if (loading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                let updatePaging = { ...pagingModel };
                updatePaging.pageNumber += 1;
                getData(updatePaging);
            }
        });
        if (element) observer.current.observe(element);
    };

    const createNewGame = async (model) => {
        const [err, data] = await GameServices.CreateUpdateGame(model);
        if (!err && data) {
            setListModel([data, ...listModel]);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Tạo mới thành công");
            history.push(`/game-detail/${data.id}`);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Tạo mới thất bại");
        }
    };

    const deleteGame = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setLoading(true);
                const [err, data] = await GameServices.DeleteGame(id);
                if (!err && data) {
                    let newList = listModel.filter((x) => x.id != id);
                    setListModel(newList);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                }
                setLoading(false);
            });
        });
    };

    const handleFileChange = (e) => {
        let file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
        if (file == null) return;

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.ImportGameExcel(file).then((res) => {
                if (res && res.data) {
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Import thành công");
                    getData(pagingModel);
                }
            });
        };
    };

    return (
        <React.Fragment>
            <GlobalStyle />
            <Wrapper>
                <div className="header">
                    <div className="header-left"></div>
                </div>
                <div className="body">
                    <div className="list-action">
                        <div className="import-exl">
                            <button
                                className="btn btn-primary m-1"
                                onClick={(e) => {
                                    let id = document.getElementById("import-excel");
                                    id.click();
                                }}
                            >
                                Import Excel
                            </button>
                            <input
                                type="file"
                                className="d-none"
                                id="import-excel"
                                onChange={(e) => {
                                    handleFileChange(e);
                                }}
                            />
                        </div>
                        <div className="import-exl">
                            <button
                                className="btn btn-primary m-1"
                                onClick={(e) => {
                                    setShowCutting(true);
                                }}
                            >
                                Giả lập
                            </button>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="center">STT</th>
                                <th className="center title">Ngày tạo</th>
                                <th className="center title">Số ván</th>
                                <th className="center method">Note</th>
                                <th className="center status">Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listModel && listModel.length > 0 ? (
                                listModel.map((x, index) => {
                                    return (
                                        <tr key={index} onClick={(e) => {}} ref={setLastCollectionHandler}>
                                            <td>{index + 1}</td>
                                            <td>{Common.formatDate(new Date(x.createdDate), "fulldate")}</td>
                                            <td>{x.totalGameRound}</td>
                                            <td>{x.note}</td>
                                            <td>
                                                <span
                                                    onClick={(e) => {
                                                        history.push(`/game-detail/${x.id}`);
                                                    }}
                                                    className="badge badge-primary m-1"
                                                >
                                                    Chi tiết
                                                </span>
                                                <span
                                                    onClick={(e) => {
                                                        deleteGame(x.id);
                                                    }}
                                                    className="badge badge-danger m-1"
                                                >
                                                    Xóa
                                                </span>
                                                <span
                                                    className="badge badge-success m-1"
                                                    onClick={(e) => {
                                                        setSelectGameId(x.id);
                                                    }}
                                                >
                                                    Report
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={1000} className="center">
                                        Không có dữ liệu
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div
                    className="float-button"
                    onClick={(e) => {
                        createNewGame({
                            createdDate: new Date(),
                            note: "",
                        });
                    }}
                >
                    <div className="icon">
                        <PlusSVG />
                    </div>
                </div>
            </Wrapper>
            <ReportModal
                gameId={selectGameId}
                show={selectGameId > 0}
                onClose={() => {
                    setSelectGameId(0);
                }}
            />
            <CuttingModal
                show={showCutting}
                onClose={() => {
                    setShowCutting(false);
                }}
            />
        </React.Fragment>
    );
}

export default GamePage;

const Wrapper = styled.div`
    .import-exl button.btn.btn-primary {
        padding: 0 4px !important;
        font-size: 12px;
        border-radius: 6px;
    }

    .list-action {
        display: flex;
        margin: 6px 6px;
    }
    .header {
        margin: 10px 0;
    }
    .body {
        overflow: auto;
    }

    .float-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }

    .icon {
        display: flex;
        border-radius: 24px;
        background: #dfffe0;
        padding: 12px;
    }
`;

const GlobalStyle = createGlobalStyle`
body.enlarged {
    height: 100vh!important;
    min-height: 100vh!important;
}
div#root {
    height: 100%;
}
.content-page {
        height: 100%;
        overflow: auto;
    }
`;

const PlusSVG = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1.5V10.5" stroke="#64C5B1" strokeWidth="3" strokeLinecap="round" />
            <path d="M10.5 6L1.5 6" stroke="#64C5B1" strokeWidth="3" strokeLinecap="round" />
        </svg>
    );
};
