import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import AdsServices from "../based/services/AdsServices";

const AdAccountModel = {
    id: "",
    name: "",
};

const AddOrEditAdAccount = ({ id, onSaveContinue, onClose, isShowModal }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [adAccount, setAdAccount] = useState(AdAccountModel);

    useEffect(() => {
        if (id > 0) _getAdAccount(id);
        return () => setAdAccount({});
    }, [id]);

    const _getAdAccount = async (id) => {
        setIsBusy(true);
        let [err, data] = await AdsServices.GetAdAccountById(id);
        if (!err && data) {
            setAdAccount({ ...data });
        } else setAdAccount(AdAccountModel);
        setIsBusy(false);
    };

    const _handleSave = async () => {
        setIsBusy(true);
        let model = { ...adAccount };

        //them model
        let [err, data] = await AdsServices.CreateUpdateAdAccount(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSaveContinue();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
        setIsBusy(false);
    };

    return (
        <Wrapper>
            <CommonModal
                show={isShowModal}
                size="sm"
                id={Common.generateGuid()}
                isBusy={isBusy}
                busyMsg={"Đang tải dữ liệu"}
                title={id > 0 ? "Cập nhật" : "Thêm mới"}
                onSave={_handleSave}
                onClose={() => onClose()}
                classModalBody={"modal-class-body minHeightZero"}
            >
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">
                            Ad Account Id
                        </label>
                        <input className="form-control" type="text" value={adAccount.id} onChange={(e) => setAdAccount({ ...adAccount, id: e.target.value })} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">
                            Ad Account Name
                        </label>
                        <input className="form-control" type="text" value={adAccount.name} onChange={(e) => setAdAccount({ ...adAccount, name: e.target.value })} />
                    </div>
                </div>
            </CommonModal>
        </Wrapper>
    );
};

export default AddOrEditAdAccount;

const Wrapper = styled.div`
    td img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
