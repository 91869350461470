import React, { useEffect, useMemo, useState } from "react";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY, PLATFORM, TEMPLATE_IMPORT } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import BrandServices from "../based/services/BrandServices";
import TFUSelect from "../based/inputs/TFUSelect";
import LazyLoad from "react-lazyload";
import Barcode from "react-barcode";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UploadFileLogo from "./UploadFileLogo";

const STATUS_PROCESS_OPTIONS = [
    {
        value: 1,
        label: "Đã xử lý",
    },
    {
        value: 2,
        label: "Chưa xử lý",
    },
];

export default function HandleLogo(props) {
    const [isBusy, setBusy] = useState(false);
    const [msgBusy, setMsgBusy] = useState("");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const [current, setCurrent] = useState({});

    useEffect(() => {
        getDatas(paging);
    }, []);
    const getDatas = async (newPaging) => {
        setBusy(true);
        setMsgBusy("Đang tải dữ liệu...");
        let [err, data] = await BrandServices.GetHandleBrands(newPaging, newPaging.status);
        if (!err) {
            setDatas(data ? data.listObjects : []);
            setPaging({ ...data.paging, status: newPaging.status });
            setBusy(false);
            setMsgBusy(null);
        } else {
            setDatas([]);
            setBusy(false);
            setMsgBusy(null);
        }
    };
    const _renderAction = () => {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }

        return (
            <div className="box__actions" style={{ borderBottom: "1px solid #f1f1f1" }}>
                <div className="form-group full-width">
                    <div className="form-row">
                        {Common.IsAdmin() && (
                            <div className="form-group col-md-4">
                                <TFUSelect name="createdBy" value={paging.createdBy} options={props.creatorOptions} placeholder="---Chọn người tạo---" onChanged={(value) => setPaging({ ...paging, createdBy: value })} />
                            </div>
                        )}

                        <div className="form-group col-md-4">
                            <TFUSelect name="status" value={paging.status} options={STATUS_PROCESS_OPTIONS} placeholder="---Trạng thái xử lý---" onChanged={(value) => setPaging({ ...paging, status: value != null ? value : -1 })} />
                        </div>

                        <div className="form-group col-md-3">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const _renderHeader = () => (
        <thead>
            <tr className="text-center">
                <th className="text-center">Tên thương hiệu</th>
                <th className="text-center" width="200">
                    Main Logo
                </th>
                <th className="text-center" width="200">
                    Sub Logo
                </th>
                <th className="text-center" width="200">
                    Xử lý Main Logo
                </th>
                <th className="text-center" width="200">
                    Xử lý Sub Logo
                </th>
            </tr>
        </thead>
    );
    async function _handleShowUpload(file, type) {
        if (Common.IsAdmin()) {
            setShowUpload({ isShow: true, type: type });
            setCurrent(file);
        } else {
            switch (type) {
                case "main":
                    if (file.fileUrl && file.fileUrl.length > 0) Notify(NOTIFY.WARNING, "Chú ý", "File đã được upload trước đó. Chỉ Quản trị mới được phép sửa");
                    else {
                        setShowUpload({ isShow: true, type: type });
                        setCurrent(file);
                    }
                    break;
                default:
                    if (file.subFileUrl && file.subFileUrl.length > 0) Notify(NOTIFY.WARNING, "Chú ý", "File đã được upload trước đó. Chỉ Quản trị mới được phép sửa");
                    else {
                        setShowUpload({ isShow: true, type: type });
                        setCurrent(file);
                    }
                    break;
            }
        }
    }
    function _renderFileItem(item, type) {
        let files = [];
        let barcode = "000000000";
        if (type == "main") barcode = item.mainBarcode;
        else barcode = item.subBarcode;
        let fileUrl = "";
        if (type == "main") fileUrl = item.fileUrl;
        else fileUrl = item.subFileUrl;
        files.push(
            <td className="text-center vertical-align-middle" style={{ width: 200 }}>
                <Barcode value={barcode} height="20" />
                <div className="d-flex mt-1 mb-1 justify-content-center">
                    <button
                        type="button"
                        className="btn btn-secondary waves-effect btn-sm mr-1"
                        onClick={() => {
                            navigator.clipboard.writeText(item.mainBarcode);
                            Notify(NOTIFY.SUCCESS, "Thành công", "Sao chép Barcode thành công");
                        }}
                    >
                        Copy Barcode
                    </button>
                    <button type="button" className={fileUrl && !Common.IsAdmin() ? "btn btn-custom waves-effect btn-sm mr-1 disabled" : "btn btn-custom waves-effect btn-sm mr-1"} onClick={() => _handleShowUpload(item, type)}>
                        Upload file
                    </button>
                    <button type="button" className={fileUrl && fileUrl.length > 0 ? "btn btn-success waves-effect waves-light btn-sm " : "btn btn-warning waves-effect waves-light btn-sm "}>
                        <span>{fileUrl && fileUrl.length > 0 ? "Đã upload" : "Chưa upload"}</span>
                    </button>
                    {fileUrl && fileUrl.length > 0 && (
                        <button type="button" className="btn btn-custom waves-effect btn-sm ml-1" onClick={() => _handleDownload(item, `${type}Handle`)}>
                            Download
                        </button>
                    )}
                </div>
                {fileUrl && fileUrl.length > 0 && (
                    <button type="button" className="btn btn-success waves-effect waves-light btn-sm">
                        <span>Passed</span>
                    </button>
                )}
            </td>
        );
        return files;
    }
    async function _handleDownload(item, type) {
        let fileUrl = "";
        switch (type) {
            case "main":
                fileUrl = item.image;
                break;
            case "sub":
                fileUrl = item.subImage;
                break;
            case "mainHandle":
                fileUrl = item.fileUrl;
                break;
            case "subHandle":
                fileUrl = item.subFileUrl;
                break;
        }

        let fileName = fileUrl;
        if (fileName && fileName.length > 0) {
            setBusy(true);
            setMsgBusy("Đang tải logo thương hiệu..");
            let fileArr = fileName.split("/");
            if (fileArr && fileArr.length > 0) fileName = fileArr[fileArr.length - 1];
            let [error, data] = await BrandServices.DownloadLogo(fileUrl, fileName);
            if (!error && data) {
                setBusy(false);
                setMsgBusy(null);
                Notify(NOTIFY.SUCCESS, "Thành công", "Tải Logo thành công");
            } else {
                setBusy(false);
                setMsgBusy(null);
                Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra trong quá trình tải file.");
            }
        } else Notify(NOTIFY.ERROR, "Lỗi", "Không tìm thấy Logo thương hiệu.");
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center vertical-align-middle position-relative">
                                    <div className="d-flex" style={{ width: 200 }}>
                                        <p className="mb-2">{item.name}</p>
                                        {Common.IsAdmin() && (
                                            <span className="btn btn-outline-primary waves-effect waves-light btn-sm position-absolute" style={{ bottom: 0, left: 0, whiteSpace: "break-spaces", wordBreak: "break-all", margin: 5 }}>
                                                Creator: {item.createdBy}
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <LazyLoad once={true} height={10} placeholder={<img src={item.image ? item.image : "/images/no-image.png"} className="border" style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} />}>
                                        <img src={item.image ? item.image : "/images/no-image.png"} className="border" style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} />
                                    </LazyLoad>
                                    <button type="button" className={item.image && item.image.length > 0 ? "btn btn-custom waves-effect btn-sm mt-1" : "btn btn-custom waves-effect btn-sm disabled  mt-1"} onClick={() => _handleDownload(item, "main")}>
                                        Download
                                    </button>
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <LazyLoad once={true} height={10} placeholder={<img src={item.subImage ? item.subImage : "/images/no-image.png"} className="border" style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} />}>
                                        <img src={item.subImage ? item.subImage : "/images/no-image.png"} className="border" style={{ width: "10rem", height: "10rem", padding: "0.5rem" }} />
                                    </LazyLoad>
                                    <button
                                        type="button"
                                        className={item.subImage && item.subImage.length > 0 ? "btn btn-custom waves-effect btn-sm  mt-1" : "btn btn-custom waves-effect btn-sm disabled  mt-1"}
                                        onClick={() => _handleDownload(item, "sub")}
                                    >
                                        Download
                                    </button>
                                </td>
                                {_renderFileItem(item, "main")}
                                {_renderFileItem(item, "sub")}
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="100" className="center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msgBusy} />
            {showUpload && showUpload.isShow && (
                <UploadFileLogo
                    showUpload={showUpload}
                    brand={current}
                    onClose={() => setShowUpload({ isShow: false })}
                    onSuccess={() => {
                        setShowUpload({ isShow: false });
                        getDatas(paging);
                    }}
                />
            )}
            <CommonTable data={datas} paging={paging} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
        </React.Fragment>
    );
}
