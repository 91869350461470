import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import TFUSelect from "../based/inputs/TFUSelect";
import Textbox from "../based/inputs/Textbox";
import ProductServices from "../based/services/ProductServices";
import useFormValidate from "../hooks/useFormValidate";
import ProductTypeModel from "../models/ProductTypeModel";

const PackageTypeOptions = [
    {
        value: 1,
        label: "Áo",
    },
    {
        value: 2,
        label: "Ốp lưng",
    },
    {
        value: 3,
        label: "Bình nước",
    },
    {
        value: 4,
        label: "Hộp bút",
    },
    {
        value: 5,
        label: "Sticker",
    },
    {
        value: 6,
        label: "Túi vải",
    },
];

const AddEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [data, setData] = useState(new ProductTypeModel());
    const { addRef, isValid } = useFormValidate();

    useEffect(() => {
        if (props.id > 0) getProductType(props.id);
        else setData(new ProductTypeModel());
    }, [props.id]);

    const getProductType = async (id) => {
        setIsBusy(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await ProductServices.GetProductTypeById(id);
        if (!err && data) setData(data);
        else setData(new ProductTypeModel());
        setIsBusy(false);
        setMsg(null);
    };

    const _handleSave = async (model) => {
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang tải dữ liệu...");
            const [err, data] = await ProductServices.AddOrUpdateProductType(model);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công!");
                props.onSaveContinue();
            } else Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
            setIsBusy(false);
            setMsg(null);
        }
    };

    const _handleThumbnail = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            Request.UploadImage(file)
                .then((res) => {
                    if (res && res.data) {
                        setData({ ...data, icon: res.data.imageUrl });
                    }
                })
                .catch((err) => {
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi upload");
                });
        };

        reader.readAsDataURL(file);
    };

    const _handleTinyThumbnail = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            Request.UploadImage(file, 3)
                .then((res) => {
                    if (res && res.data) {
                        let thumbUrl = res.data.imageUrl;
                        let fileName = thumbUrl.replace(".png", "-300.webp");
                        setData({ ...data, tinyThumbnail: fileName });
                    }
                })
                .catch((err) => {
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi upload");
                });
        };

        reader.readAsDataURL(file);
    };

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={data.id == 0 ? "Thêm mới" : "Cập nhật"} onSave={() => _handleSave({ ...data })} onClose={() => props.onClose()}>
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="name" className="required">
                        Tên hiển thị:
                    </label>
                    <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setData({ ...data, name: value })} value={data.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="thumbnail" className="d-block my-2 required">
                        Icon
                    </label>
                    <input type="file" id="thumbnail" className="my-2" accept="image/*" multiple={false} onChange={(e) => _handleThumbnail(e)} />
                    <div className="preview-thumbnail my-2">{data.icon && <img src={data.icon} />}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="tinyThumbnail" className="d-block my-2 required">
                        Tiny Thumbnail
                    </label>
                    <input type="file" id="tinyThumbnail" className="my-2" accept="image/*" multiple={false} onChange={(e) => _handleTinyThumbnail(e)} />
                    <div className="preview-thumbnail my-2">{data.tinyThumbnail && <img src={data.tinyThumbnail} />}</div>
                </div>
                <div className="form-group">
                    <label htmlFor="thumbnail" className="d-block my-2 required">
                        Package Type
                    </label>
                    <TFUSelect value={data.packageType} onChanged={(newValue) => setData({ ...data, packageType: newValue })} name="packageType" placeholder="--Chọn loại--" options={PackageTypeOptions} />
                </div>
            </Wrapper>
        </CommonModal>
    );
};

export default AddEditModal;

const Wrapper = styled.div`
    .preview-thumbnail {
        height: 100px;
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;
