import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import MarketingServices from "../based/services/MarketingServices";
import DragAndDropIndex from "../based/DragAndDropIndex";
import Request from "../based/Request";
import ModalSpinConfigTime from "./modals/ModalSpinConfigTime";
import TFUSelect from '../based/inputs/TFUSelect';


const initModel = {
    id: 0,
    name: "",
    marketingCampaignId: 0,
    index: 0,
    prizeType: -1,
    spinMore: 0,
    partnerCollabId: 0
}

const PRIZE_TYPE_OPTION = [
    {value: 0, label: "Quà tặng của cửa hàng"},
    {value: 1, label: "Quà ranus tại cửa hàng"},
    {value: 2, label: "Quà voucher"},
    {value: 3, label: "Thêm lượt"},
    {value: 4, label: "Không trúng thưởng"},
    {value: 5, label: "Quà tặng partner collab"},
]

const SPIN_MORE_OPTION = [
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
]
const MarketingSpinPrizeManegement = ({creatorId ,campaignId, setBusy}) => {
    const [data , setData] = useState([])
    const [dataCollab , setDataCollab] = useState([])
    const tableRef = useRef(null);
    const [paging , setPaging] = useState({})
    const [spinPrizeItem , setSpinPrizeItem] = useState({})
    const [isShowModalConfig , setIsShowModalConfig] = useState(false);
    useEffect(() =>{
        getData()
        getDataCollab()
    },[campaignId])

    const getData = async() => {
        setBusy(true);
        let [err, data] = await MarketingServices.GetSpinPrizeByCampaignId(campaignId);
        if (!err && data) {
            setData(data)
        } else {
            setData([]);
        }
        setBusy(false);
    }

    const getDataCollab = async() => {
        setBusy(true);
        let [err, data] = await MarketingServices.GetPartnerCollabByCampaign(campaignId);
        if (!err && data && data.length > 0) {
            var dd = data.map((item)=>{
                return {
                    value: item.id,
                    label: item.firstName + " " + item.lastName
                }
            })
            setDataCollab(dd)
        } else {
            setDataCollab([]);
        }
        setBusy(false);
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>
                        
                    </th>
                    <th>
                        STT
                    </th>
                    <th>Loại giải thưởng</th>
                    <th>Tên giải thưởng</th>
                    <th>Số khung giờ đặc biệt</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }
    async function _handleDelete(id, idx) {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeleteSpinPrize(id);
                    if (!error && data) {
                        if(data === 1){
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                            getData();
                        }
                        if(data === -7){
                            Notify(NOTIFY.WARNING,"Lưu ý", "Chiến dịch đã chạy , không thể xóa");
                        }
                    
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    function renderRow(item, idx) {
        return (
            <React.Fragment>
                <td style={{width: 40, verticalAlign: 'middle', textAlign: 'center'}}>
                    <svg width="40" height="45" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="38" height="43" fill="white" stroke="#D9D9D9"/>
                    <path d="M13 18H27" stroke="#666F7B" stroke-width="1.5"/>
                    <path d="M13 23H27" stroke="#666F7B" stroke-width="1.5"/>
                    <path d="M13 28H27" stroke="#666F7B" stroke-width="1.5"/>
                    </svg>
                </td>
                <td style={{width: 100}}>{idx + 1}</td>
                    <td>
                        {item.id <= 0 ? (
                            <TFUSelect name='prize-type' id='prize-type' 
                            value={item.prizeType} options={PRIZE_TYPE_OPTION} 
                            onChanged={(value) => {
                                let dt = [...data];
                                dt[idx].prizeType = value;
                                setData(dt);
                            }} />
                        ) : (
                            PRIZE_TYPE_OPTION[item.prizeType].label
                        )}
                    </td>
                    <td>
                        {(item.id <= 0 || item.isUpdate) ?
                        
                      
                        (item.prizeType === PRIZE_TYPE_OPTION[3].value &&  (
                            <TFUSelect name='spin-more' id='spin-more' 
                            value={item.spinMore} options={SPIN_MORE_OPTION} 
                            onChanged={(value) => {
                                let dt = [...data];
                                dt[idx].spinMore = value;
                                setData(dt);
                            }} />))
                        ||
                        (item.prizeType === PRIZE_TYPE_OPTION[5].value &&  (
                            <TFUSelect name='partner-collab' id='partner-collab' 
                            value={item.partnerCollabId} options={dataCollab} 
                            onChanged={(value) => {
                                let dt = [...data];
                                dt[idx].partnerCollabId = value;
                                setData(dt);
                            }} />)
                            
    

                        ) : (
                            item.prizeType === PRIZE_TYPE_OPTION[0].value && "Partner Config" || 
                            item.prizeType === PRIZE_TYPE_OPTION[1].value && "Ranus Config" || 
                            item.prizeType === PRIZE_TYPE_OPTION[2].value && "Ranus Config Voucher" || 
                            item.prizeType === PRIZE_TYPE_OPTION[3].value && `Thêm ${item.spinMore} lượt` || 
                            item.prizeType === PRIZE_TYPE_OPTION[4].value && "Không trúng thưởng" ||
                            item.prizeType === PRIZE_TYPE_OPTION[5].value && `${item.partnerCollabName} config`
                        )}
                    </td>
                    <td style={{ width: 130 }}>{item.countSpecial}</td>
                    <td style={{ width: 300, textAlign: "center" }}>
                        {item.isUpdate || item.id <= 0 ? (
                            <button
                                className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                onClick={() => {
                                    if (!IsDisable(idx)) {
                                        handleCreateOrUpdate(idx);
                                    }
                                }}
                            >
                                Xong
                            </button>
                        ) : (
                            <button
                                className="btn btn-custom m-1"
                                onClick={() => {
                                    let dt = [...data];
                                    dt[idx].isUpdate = true;
                                    setData(dt);
                                }}
                            >
                                Sửa
                            </button>
                        )}
                        {item.id > 0 && (item.prizeType == PRIZE_TYPE_OPTION[3].value
                            || item.prizeType == PRIZE_TYPE_OPTION[4].value)
                         && <button className={"btn btn-custom m-1"}
                            onClick={()=>{
                                setSpinPrizeItem(item)
                                setIsShowModalConfig(true)
                            }}
                        >Config tỷ lệ</button>}
                        <button
                            className={"btn btn-danger m-1"}
                            onClick={() => {
                                _handleDelete(item.id , idx)
                            }}
                        >
                            Xóa
                        </button>
                    </td>
            </React.Fragment>
        );
    }

    async function UpdateIndex(id, index) {
        setBusy(true);
        let [err, data] = await MarketingServices.UpdateIndexSpin({
            id,
            index,
        });
        if (!err && data) {
            getData();
            setBusy(false);
        } else {
            setBusy(false);
        }
    }


    function _renderBody() {
        return (
            <React.Fragment>
                <DragAndDropIndex data={data} renderRow={renderRow} updateIndexFolder={UpdateIndex} />
                <tbody>
                    <tr>
                        <td colSpan={99}>
                            <div
                                className="add-item"
                                style={{
                                    color: "#64C5B1",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                    marginBottom: "10px",
                                }}
                                onClick={() => {
                                    handleAddItem();
                                }}
                            >
                                Thêm +
                            </div>
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    async function handleAddItem() {
        setData([...data, { ...initModel, marketingCampaignId: campaignId }]);
    }
    function _renderAction() {
       return <div className="title-product">
            Thiết lập vòng quay theo chiều kim đồng hồ
       </div>
    }


    function IsDisable(idx) {
        if (data[idx].prizeType < 0 || (data[idx].prizeType == 3 && data[idx].spinMore <= 0)
        || (data[idx].prizeType == 5 && data[idx].partnerCollabId <= 0)
        ) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx],marketingCampaignId: campaignId };
        let [err, dt] = await MarketingServices.CreateUpdateSpinPrize(dataItem);
        if (!err && dt) {
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            getData()
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    return <div>
            {isShowModalConfig && <ModalSpinConfigTime isShowModal={isShowModalConfig} onClose={()=>{
                setIsShowModalConfig(false)
                setSpinPrizeItem({})
            }} spinPrize={spinPrizeItem} 
            campaignId={campaignId}
            />}
            <CommonTable data={data} paging={paging} hideSearchBasicButton 
                renderAction={_renderAction} 
                renderHeader={_renderHeader} 
                renderBody={_renderBody} 
                ref={tableRef} 
             />
    </div>
}

export default MarketingSpinPrizeManegement