import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { ARTWORK_TYPE, NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import ProductionServices from "../based/services/ProductionServices";
import useDebounce from "../hooks/useDebounce";
// import { Layout } from "../layout/Layout";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
const menuItem = ["Trang chủ", "Kiểm tra và bàn giao ĐVVC"];

export default function ProcessHandoverShipping(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, orderBy: "id", orderDirection: "desc" });
    const [datas, setDatas] = useState([]);
    const [tagId, setTagId] = useState(0);
    const debounceGetTag = useDebounce(tagId, 500);

    useEffect(() => {
        if (debounceGetTag) {
            setIsLoading(true);
            getTagInfo(debounceGetTag);
            setIsLoading(false);
        }
    }, [debounceGetTag]);

    async function getTagInfo(tagId) { 
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ProductionServices.GetOrderTagDTGModel(tagId);
        if (!err) {
            if (data) {
                let dts = [...datas];
                dts.splice(0, 0, data);
                setDatas(dts);
                setTagId(0);
            }
        } else {
            let error = err.errors;
            let msg = error.reduce((prev, item, idx) => {
                if (idx == err.length - 1) return (prev += `${item.value}`);
                return (prev += `${item.value}, `);
            }, "");
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
        setMsg("");
        setIsLoading(false);
    }

    function handleSortColumn(columnName) {}
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width='50' className='text-center cursor-pointer' onClick={() => handleSortColumn("id")}>
                        STT
                    </th>
                    <th width='300'>Tag sản xuất</th>
                    <th width='100' className='text-center'>
                        Batch ID
                    </th>
                    <th width='200' className='text-center'>
                        Order ID
                    </th>
                    <th width='200' className='text-center'>
                        Loại áo
                    </th>
                    <th width='200'>Size áo</th>
                    <th width='200'>Màu áo</th>
                    <th width='100'>Loại in</th>
                    <th width='100' className='text-center'>
                        Số sản phẩm thuộc đơn hàng
                    </th>
                    <th className='text-center'>Mặt trước</th>
                    <th className='text-center'>Mặt sau</th>
                    <th className='text-center'>Logo thương hiệu</th>
                    <th className='text-center'>Trạng thái trước khi quét</th>
                    <th className='text-center'>Trạng thái hiện tại</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        if (datas && datas.length > 0)
            return (
                <tbody>
                    {datas.map((item, idx) => {
                        const { orderTagDetail, orderTagMockup } = item;
                        if (!orderTagDetail) return "";

                        const { artworkMockupModels, logoMockupModel } = orderTagMockup;
                        const size = orderTagDetail.attributeValues.find((x) => x.name == "Size");
                        const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : null;
                        const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : null;

                        return (
                            <tr key={idx}>
                                <td className='vertical-align-middle text-center'>{idx + 1}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.tagId}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.batchName}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.orderId}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.productTypeName}</td>
                                <td className='vertical-align-middle text-center'>{size ? size.value : ""}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.colorDisplayName}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.printTypeName}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.totalProductOrderCount}</td>
                                <td className='vertical-align-middle text-center'>{front ? <img src={front.url} alt='front image' style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""} </td>
                                <td className='vertical-align-middle text-center'>{back ? <img src={back.url} alt='back image' style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                <td className='vertical-align-middle text-center'>{logoMockupModel ? <img src={logoMockupModel.url} alt='logo' style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.previousStatusName}</td>
                                <td className='vertical-align-middle text-center'>{orderTagDetail.inlineStatusName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            );
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan='100' className='vertical-align-middle text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    }
    const _renderFilter = useMemo(() => (
        <div className='p-y-10 box__actions'>
            <div className='form-group full-width'>
                <div className='box__search' style={{ minHeight: 163 }}>
                    <div className=' form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='setName'>Tag sản xuất:</label>
                            <Textbox className='form-control' name='tagId' onChanged={(name, value) => setTagId(+value)} value={tagId} />
                        </div>
                    </div>
                    <div className='form-row ml-1'>
                        <div className='form-group'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getTagInfo(tagId)}>
                                <i className='fa fa-search m-r-5' />
                                Duyệt
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <React.Fragment>
            {Common.IsAdmin() ? <Breadcrumbs currentPage='Kiểm tra và bàn giao ĐVVC' /> : <Breadcum menu={menuItem} title='Kiểm tra và bàn giao ĐVVC' />}

            <Loading show={isLoading} msg={msg} />
            <div className='col-sm-12 card-box p-3'>
                <div className='form-row'>
                    <div className='col-sm-12'>{_renderFilter}</div>
                </div>
                <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={() => {}} onFilter={(paging) => {}} searchBasic={false} />
            </div>
        </React.Fragment>
    );
}
