import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import CheckBox from '../../../based/inputs/CheckBox';

const TableVariantsComp = ({ product, onPickVariant, selectedAllVariant, editable }) => {
    const [tabProducts, setTabProducts] = useState(new Map());
    const [selectedProduct, setSelectedProduct] = useState(-1);
    useEffect(() => {
        if (product && product.variants) {
            debugger;
            setSelectedProduct(selectedProduct >= 0 ? selectedProduct : product.variants[0].appProductId);
            let tabs = new Map();
            for (let i = 0; i < product.variants.length; i++) {
                const variant = product.variants[i];
                if (tabs.has(variant.appProductId)) {
                    tabs.get(variant.appProductId).push(variant);
                } else {
                    tabs.set(variant.appProductId, [variant]);
                }
            }
            setTabProducts(tabs);
        }
    }, [product])
    const _renderTabProducts = () => {
        let ui = [];
        tabProducts.forEach((value, key) => {
            ui.push(<li className="nav-item" key={key}>
                <a className={selectedProduct == key ? "nav-link active" : "nav-link btn-light"} onClick={() => setSelectedProduct(key)} >
                    <span>{value[0].productName}</span>
                </a>
            </li>)
        })
        return ui.map((m, i) => m);
    }
    const _renderCellOfRows = (size, columnMaps) => {
        let variants = []
        columnMaps.forEach((value, key) => {
            let filter = value.filter(m => m.size == size)[0];
            if (filter)
                variants.push(filter)
        })
        return variants.map((v, i) => {
            return <td width={50}>
                <CheckBox name={'checkbox_' + v.variantId} checked={!editable || v.selected} onCheckedChange={(name, value) => onPickVariant(v, value)} />
            </td>
        })
    }
    if (!product || !product.variants || product.variants.length == 0)
        return null;
    let variants = product.variants.filter(m => m.appProductId == selectedProduct);
    variants.map((v, i) => {
        let attributes = v.attributes.filter(m => m.Code == 'Color');
        if (attributes && attributes.length > 0)
            v.color = attributes[0].Value;
        attributes = v.attributes.filter(m => m.Code == 'Size');
        if (attributes && attributes.length > 0)
            v.size = attributes[0].Value;
    });
    let columnMaps = new Map();
    let rowMaps = new Map();
    for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];
        if (!columnMaps.has(variant.color)) {
            columnMaps.set(variant.color, [variant]);
        } else {
            columnMaps.get(variant.color).push(variant);
        }
        if (!rowMaps.has(variant.size)) {
            rowMaps.set(variant.size, [variant]);
        } else {
            rowMaps.get(variant.size).push(variant);
        }
    }
    let columns = [];
    columnMaps.forEach((v, k) => columns.push(k));
    let rows = [];
    rowMaps.forEach((v, k) => rows.push(k));
    return <div>
        <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
            {
                tabProducts && _renderTabProducts()
            }
        </ul>
        <table className='table-bordered'>
            <thead>
                <tr>
                    <th>
                        <CheckBox name={'checkbox_all_variants'} checked={selectedAllVariant} onCheckedChange={(name, value) => onPickVariant(null, value)} />
                    </th>
                    {
                        columns && columns.map((c, i) => <th key={i}><span>{c}</span><span style={{ width: 30, height: 20, backgroundColor: c, display: 'inline-block', marginLeft: 10 }}></span>
                        </th>)
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rows && rows.map((v, i) => {
                        return <tr key={i}>
                            <td>{v}</td>
                            {
                                columnMaps && _renderCellOfRows(v, columnMaps)
                            }
                        </tr>
                    })
                }
                <tr></tr>
            </tbody>
        </table>
    </div>

};

export default TableVariantsComp;