import React, { useEffect, useState } from "react";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import { CAMPAIGN_BOOSTING_STATUS, CAMPAIGN_BOOSTING_STATUS_NAME, ROLES } from "../based/Constants";
// import LayoutLight from "../layout/refactor/Layout/Layout";
import CampaignServices from "../based/services/CampaignServices";
import MarketerCampaignBoosting from "./MarketerCampaignBoosting";
import CreatorCampaignBoosting from "./CreatorCampaignBoosting";
import MarketerMasterCampaignBoosting from "./MarketerMasterCampaignBoosting";
import LoadingR from "../based/LoadingR";
const menuItem = ["Trang chủ", "Đẩy thiết kế"];
const STATUS_OPTIONS = [
    {
        value: CAMPAIGN_BOOSTING_STATUS.DRAFT,
        label: CAMPAIGN_BOOSTING_STATUS_NAME[CAMPAIGN_BOOSTING_STATUS.DRAFT],
    },
    {
        value: CAMPAIGN_BOOSTING_STATUS.APPROVED,
        label: CAMPAIGN_BOOSTING_STATUS_NAME[CAMPAIGN_BOOSTING_STATUS.APPROVED],
    },
    {
        value: CAMPAIGN_BOOSTING_STATUS.RUNNING,
        label: CAMPAIGN_BOOSTING_STATUS_NAME[CAMPAIGN_BOOSTING_STATUS.RUNNING],
    },
    {
        value: CAMPAIGN_BOOSTING_STATUS.FINISH,
        label: CAMPAIGN_BOOSTING_STATUS_NAME[CAMPAIGN_BOOSTING_STATUS.FINISH],
    },
];

export default function CampaignBoosting(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        showCampaignBoostingByRole();
    }, []);
    async function showCampaignBoostingByRole() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.ShowCampaignBoostingByRole();
        if (!error && data) {
            setRoles(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setRoles(null);
            setIsLoading(false);
            setMsg(null);
            window.location.href = "/access-denied";
        }
    }
    function _renderView() {
        if (roles && roles.length > 0) {
            if (roles.find((x) => x.toUpperCase() == ROLES.CREATOR)) {
                return <CreatorCampaignBoosting {...props} statusOptions={STATUS_OPTIONS} />;
            } else if (roles.find((x) => x.toUpperCase() == ROLES.MARKETERMANAGER)) {
                return <MarketerCampaignBoosting {...props} statusOptions={STATUS_OPTIONS} />;
            } else return <MarketerMasterCampaignBoosting {...props} statusOptions={STATUS_OPTIONS} />;
        }
    }
    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title='Đẩy thiết kế' />
            <LoadingR fullScreen isShow={isLoading} height='100vh' />
            {_renderView()}
        </React.Fragment>
    );
}
