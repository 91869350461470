import BaseMTOServices from "./BaseMTOServices";

const TicketServices = {
    GetTicket: async (paging) => {
        return await BaseMTOServices.Post("/api/TK_Ticket/get-tk_ticket", paging);
    },
    AnswerTicket: async (model) => {
        return await BaseMTOServices.Post("/api/TK_Ticket/answer-tk_ticket", model);
    },
    GetTicketById: async (id) => {
        return await BaseMTOServices.Get(`/api/TK_Ticket/get-tk_ticket-by-id/${id}`);
    },
    DeleteTicket: async (ids) => {
        return await BaseMTOServices.Post("/api/TK_Ticket/delete-tk_ticket", ids);
    },
    ChangeTicketStatus: async (model) => {
        return await BaseMTOServices.Post("/api/TK_Ticket/change-tk_ticket-status", model);
    },
};

export default TicketServices;
