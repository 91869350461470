import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import { Box } from "../based/content/ct.elm";
import BlogEditor from "../based/inputs/BlogEditor/BlogEditor";
import TFUSelect from "../based/inputs/TFUSelect";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import BlogServices from "../based/services/BlogServices";
import useFormValidate from "../hooks/useFormValidate";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import { BlogModel } from "./BlogManagement";
import ConfigBlogTags from "./components/ConfigBlogTags";
import ImageUpload from "./components/ImageUpload";

const AddOrEditBlogPage = (props) => {
    const history = useHistory();
    const [msg, setMsg] = useState("Đang tải...");
    const [isBusy, setIsBusy] = useState(false);
    const [blog, setBlog] = useState(BlogModel);
    const [categories, setCategories] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const { addRef, displayError, isValid } = useFormValidate();
    const [isLoading, setIsLoading] = useState(false);
    const [msgAvatar, setMsgAvatar] = useState(false);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [isContinue, setIsContinue] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const isAdmin = Common.IsAdmin();

    useEffect(() => {
        getCategories(paging);
        if (props.match.params.id && props.match.params.id > 0) {
            _getBlog(props.match.params.id);
        } else {
            setBlog({ ...BlogModel });
        }
        return () => setBlog({ ...BlogModel });
    }, [props.match.params.id]);
    const _getBlog = async (id) => {
        setIsLoading(true);
        let [err, data] = await BlogServices.GetBlogByID(id);
        if (!err && data) {
            setBlog(data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setBlog({ ...BlogModel });
        }
    };
    const _handleSave = () => {
        if (isValid() && blog.avatar) {
            console.log("save");
            if (blog.seoUrl === "") {
                _saveBlog({ ...blog, seoUrl: Common.removeVietnameseFromString(blog.title) });
            } else {
                _saveBlog({ ...blog });
            }
        }
        if (!blog.avatar) {
            setMsgAvatar(true);
        }
    };
    const _saveBlog = async (blog) => {
        blog.seoUrl = Common.removeVietnameseFromString(blog.title);
        if (!blog.tags || blog.tags === "") {
            ConfirmDialog("Xác nhận ?", "Bạn chưa thêm từ khóa liên quan cho bài viết này, xác nhận tiếp tục tạo bài ?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    blog.createdDate = null;
                    blog.modifiedDate = null;
                    let [err, data] = await BlogServices.CreateUpdateBlog(blog);
                    if (!err && data) {
                        setIsLoading(false);
                        setIsSaveSuccess(true);
                    } else {
                        setIsLoading(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
                    }
                    resolve({ title: "Thành công", msg: "Thành công." });
                });
            });
        } else {
            setIsLoading(true);
            blog.createdDate = null;
            blog.modifiedDate = null;
            let [err, data] = await BlogServices.CreateUpdateBlog(blog);
            if (!err && data) {
                setIsLoading(false);
                setIsSaveSuccess(true);
            } else {
                setIsLoading(false);
                Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
            }
        }
    };

    const getCategories = async (paging) => {
        setIsLoading(true);
        let [err, data] = await BlogServices.GetCategories(paging);
        if (!err && data) {
            setIsLoading(false);
            const options = data.listObjects.map((category) => ({
                value: category.id,
                label: category.name,
                ...category,
            }));

            setCategories([...options]);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setCategories([]);
            setPaging({ ...INITPAGING });
        }
    };
    const handleResetPage = () => {
        setBlog({ ...BlogModel });
        if (props.match.params.id) window.location.href = "/blog";
        else window.location.reload();
    };
    const renderContent = () => {
        return (
            <Wrapper>
                {isSaveSuccess ? (
                    <Popup>
                        <div className="popup-container">
                            <h2>Lưu thành công!</h2>
                            {!isContinue ? (
                                <div className="d-flex w-100 justify-content-between mt-4">
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            history.replace("/blog-management");
                                        }}
                                    >
                                        {" "}
                                        Trở về trang quản lý
                                    </button>
                                    <button className="btn btn-warning" onClick={() => setIsContinue(true)}>
                                        {" "}
                                        Tiếp tục viết bài
                                    </button>
                                </div>
                            ) : (
                                <div className="d-flex w-100 justify-content-between align-items-center mt-4">
                                    {" "}
                                    <i class="fa fa-arrow-left cursor-pointer" aria-hidden="true" onClick={() => setIsContinue(false)}></i>
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            setIsContinue(false);
                                            setIsSaveSuccess(false);
                                        }}
                                    >
                                        {" "}
                                        Tiếp tục chỉnh sửa
                                    </button>
                                    <button
                                        className="btn btn-warning"
                                        onClick={() => {
                                            setIsContinue(false);
                                            setIsSaveSuccess(false);
                                            handleResetPage();
                                        }}
                                    >
                                        {" "}
                                        Xóa tất cả
                                    </button>
                                </div>
                            )}
                        </div>
                    </Popup>
                ) : (
                    ""
                )}

                {isAdmin ? <Breadcrumbs currentPage={props.match.params.id ? "Cập nhật" : "Tạo bài viết mới"} /> : ""}
                <Loading show={isLoading} msg={msg} />
                <div></div>
                <div className="d-flex w-100">
                    <div className="form-group col-md-6">
                        <label htmlFor="title" className="required">
                            Tiêu đề:
                        </label>
                        <Textbox id="title" name="title" ref={addRef} required className="form-control" value={blog.title} onChanged={(name, value) => setBlog({ ...blog, title: value })} />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="categoryId" className="required">
                            Danh mục:
                        </label>
                        <TFUSelect name="categoryId" ref={addRef} required value={blog.categoryId} options={categories} placeholder="---Chọn danh mục---" onChanged={(value) => setBlog({ ...blog, categoryId: value })} />
                    </div>
                </div>
                <div className="d-flex">
                    <div className="form-group col-md-4">
                        <label htmlFor="flexicomboFrame">Ảnh đại diện:</label>
                        <ImageUpload
                            name="avatar"
                            image={blog.avatar}
                            isRequired={msgAvatar}
                            onChanged={(name, value) => {
                                setBlog({ ...blog, avatar: value });
                            }}
                            maximumSize={5}
                        />
                    </div>
                    <div className="col-md-8">
                        <div className="form-group ">
                            <div className="d-flex align-items-center justify-content-end">
                                {" "}
                                <span className="btn btn-success ml-4 mr-4" onClick={() => setIsShowModal(true)}>
                                    Thiết lập từ khóa liên quan
                                </span>{" "}
                                {blog.tags && blog.tags !== "" ? (
                                    <React.Fragment>
                                        Đã thiết lập:
                                        {blog.tags.split(",").map((item, idx) => (
                                            <span key={idx} className="tag-item">
                                                {item}
                                            </span>
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <label htmlFor="title" className="required">
                            Đoạn mô tả ngắn:
                        </label>
                        <TextArea id="shortContent" name="shortContent" height="60%" ref={addRef} required maxLength={512} className="form-control" value={blog.shortContent} onChanged={(name, value) => setBlog({ ...blog, shortContent: value })} />
                        {/*  */}
                    </div>
                </div>
                <div className="w-100 mb-5 position-relative">
                    <div className="form-group">
                        <label htmlFor="content">Nội dung bài viết:</label>
                        <BlogEditor
                            required
                            ref={addRef}
                            handleReset={handleResetPage}
                            name="content"
                            id="content-blog"
                            className="form-control full-width"
                            html={blog.content ? blog.content : null}
                            onChanged={(name, value) => setBlog({ ...blog, content: value })}
                        />
                    </div>
                </div>
                <hr />
                <div className="w-100 mt-3 d-flex align-items-end">
                    {isValid() ? (
                        <button className="btn btn-success rounded" onClick={_handleSave}>
                            Lưu
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                {isShowModal && (
                    <ConfigBlogTags
                        isShowModal={isShowModal}
                        onClose={() => {
                            setIsShowModal(false);
                        }}
                        setBlog={setBlog}
                        blog={blog}
                        onSuccess={() => {}}
                    />
                )}
            </Wrapper>
        );
    };
    return (
        <React.Fragment>
            {!isAdmin ? (
                <React.Fragment>
                    <Breadcum menu={["Trang chủ", "Viết bài"]} title={props.match.params.id ? "Cập nhật" : "Tạo bài viết mới"} />
                    <Box>{renderContent()}</Box>
                </React.Fragment>
            ) : (
                <React.Fragment>{renderContent()}</React.Fragment>
            )}
        </React.Fragment>
    );
};

const Wrapper = styled.div`
    width: 100%;
    .form-control {
        border-radius: 5px;
        border: 1px solid #aaa;
    }
    .tag-item {
        padding: 3px 10px;
        background: #32c861;
        color: #fff;
        border-radius: 6px;
        margin: 0 10px;
    }
`;
const Popup = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    .popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 1rem 1.5rem;
        width: 350px;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
export default AddOrEditBlogPage;
