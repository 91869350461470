import React from "react";
import { useHistory } from "react-router-dom";

const NotAcceptable = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push("/");
    };
    const handleClickLogin = () => {
        history.push("/login");
    };
    return (
        <section className='bg-accpunt-pages'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='wrapper-page'>
                            <div className='account-pages'>
                                <div className='account-box'>
                                    <div className='account-logo-box text-center'>
                                        <h2 className='text-uppercase text-center'>
                                            <a onClick={handleClick} className='text-success'>
                                                <span>
                                                    <img src='/logoranus.png' alt='' height={34} />
                                                </span>
                                            </a>
                                        </h2>
                                    </div>
                                    <div className='account-content text-center' style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                        <h1 className='text-error' style={{ fontSize: "70px" }}>
                                            406
                                        </h1>
                                        <h3 className='text-danger waves-effect waves-light w-75 mt-0' style={{ fontSize: "12px" }}>
                                            Thông tin chưa được xác thực. Không thể thực hiện chức năng này.
                                        </h3>
                                        <div className='form-group row mt-5'>
                                            <div className='col-12'>
                                                <div className='btn-lotus btn-error waves-effect waves-light' onClick={handleClick}>
                                                    Quay lại trang chủ
                                                </div>
                                                <div className='col-12 m-t-10'>
                                                    <p className='text-center'> 
                                                        <a onClick={handleClickLogin}>
                                                            <strong>Đăng nhập</strong>
                                                        </a> 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NotAcceptable;
