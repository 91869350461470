import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import LogisticContext from "../context/LogisticContext";
import { switchCode } from "./LogisticMainPage";

function LogisticFilterPage(props) {
    const history = useHistory();
    const { bulkModel, setBulkModel } = useContext(LogisticContext);

    useEffect(() => {
        if (!bulkModel || bulkModel.length === 0) {
            history.push("/logistic");
        }
    }, [bulkModel]);

    if (!bulkModel) return "";
    return (
        <Wrapper>
            <div className="header">
                <div className="header-left">Kết quả lọc đơn</div>
            </div>
            <div className="body">
                <div className="list-item">
                    {bulkModel.map((item, index) => {
                        if (!item.logisticBulkBookingModels) return "";
                        return (
                            <div className="item" key={index}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="table-header" colSpan={2}>
                                                <span className="route-number">Chuyến {index + 1}</span>
                                                <span className="route-count">{item.logisticBulkBookingModels.length} điểm giao hàng</span>
                                            </th>
                                            <th className="text-green"></th>
                                            <th
                                                className="scan-btn"
                                                onClick={(e) => {
                                                    let models = [...bulkModel];
                                                    for (var model of models) {
                                                        model.active = false;
                                                    }
                                                    models[index].active = true;
                                                    setBulkModel(models);
                                                    history.push("/logistic-filter-scan");
                                                }}
                                            >
                                                Quét mã
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.logisticBulkBookingModels.map((element, idx) => (
                                            <tr key={idx}>
                                                <td className="item-number">{idx + 1}</td>
                                                <td className="item-info">
                                                    <span className="item-info-top">
                                                        {element.userName} - {element.phoneNumber}
                                                    </span>
                                                    <span className="item-info-bottom">{element.fullAddress}</span>
                                                </td>
                                                <td className="item-image">
                                                    <img src={switchCode(element.shippingCompanyCode)} alt="item" />
                                                </td>
                                                <td className="distance">{Common.formatNumber(Math.floor(element.distance * 100) / 100)}km</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Wrapper>
    );
}

export default LogisticFilterPage;

const Wrapper = styled.div`
    padding: 22.5px 16.5px;

    .header {
        display: flex;
    }

    .header-left {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 90% */
    }

    .body {
        margin-top: 16px;
    }

    th.table-header {
        background: #dfffe0;
        vertical-align: middle !important;
    }

    span.route-number {
        color: #28b259;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
    }

    span.route-number {
    }

    span.route-count {
        color: #717171;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        margin-left: 8px;
    }

    th.text-green {
        background: #dfffe0;
    }

    th.scan-btn {
        background: #28b259;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 150% */
    }

    td.item-number {
        vertical-align: middle;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    td.item-info {
        vertical-align: middle;
    }

    span.item-info-top {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        display: inline-block;
    }

    span.item-info-bottom {
        display: inline-block;
        color: #717171;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    td.item-image {
        vertical-align: middle;
    }

    td.distance {
        vertical-align: middle;
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }
`;
