import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import CommonModal from "../based/Modal";
import useFormValidate from "../hooks/useFormValidate";
import { Layout } from "../layout/Layout";
import TopicModel from "../models/TopicModel";
import PropTypes from "prop-types";
import TopicServices from "../based/services/TopicServices";
import { Notify } from "../based/Notify";
import Textbox from "../based/inputs/Textbox";

function TopicManage(props) {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([new TopicModel()]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setBusy(true);
        let [err, data] = await TopicServices.GetTopics(paging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
        }
        setBusy(false);
    }

    function _renderAction() {
        return (
            <button className='btn btn-custom' onClick={() => setIsShowModal(true)}>
                Thêm mới
            </button>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className='center'>Id</th>
                    <th className='center'>Tên hiển thị</th>
                    <th className='center'>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{item.id}</td>
                                <td>{item.topicName}</td>
                                <td>
                                    <button
                                        className='btn btn-custom'
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg='Đang tải dữ liệu...' />
            <Breadcrumbs currentPage='Quản lý chủ đề' />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            <AddEditModal
                isShowModal={isShowModal}
                onClose={() => {
                    setIsShowModal(false);
                }}
                onSaveContinue={() => {
                    setIsShowModal(false);
                    _getData({ ...paging });
                }}
                id={selectId}
            />
        </React.Fragment>
    );
}

TopicManage.propTypes = {};

export default TopicManage;

function AddEditModal(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [data, setData] = useState(new TopicModel());
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if (props.id > 0) {
            _getData(props.id);
        }
    }, [props.id]);

    async function _getData(id) {
        let [err, dt] = await TopicServices.GetTopic(id);
        if (!err) setData(dt);
        else setData(new TopicModel());
    }
    async function _handleSave(data) {
        let [err, dt] = await TopicServices.SaveTopic(data);
        if (!err) Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        else Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        props.onSaveContinue()
    }

    return (
        <CommonModal
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isBusy}
            busyMsg={msg}
            title={data.id == 0 ? "Thêm mới" : "Cập nhật"}
            onSave={() => _handleSave({ ...data })}
            onClose={() => props.onClose()}
        >
            <div className='form-group'>
                <label htmlFor='topicName' className='required'>
                    Tên hiển thị:
                </label>
                <Textbox id='topicName' name='topicName' required ref={addRef} className='form-control' onChanged={(name, value) => setData({ ...data, topicName: value })} value={data.topicName} />
            </div>
        </CommonModal>
    );
}

AddEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
