import React, { useState } from "react";
import styled from "styled-components";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import ModalAddOrUpdateSizeBoard from "./ModalAddOrUpdateSizeBoard";
import CommonTable from "../based/CommonTable";
import { Notify } from "../based/Notify";

export const OPTION_SIZE = [
    {
        value: 0,
        label: "S or K5"
    },
    {
        value: 1,
        label: "M or K6"
    },
    {
        value: 2,
        label: "L or K7"
    },
    {
        value: 3,
        label: "XL or K8"
    },
    {
        value: 4,
        label: "2XL or K9"
    },
    {
        value: 5,
        label: "3XL"
    }
    
]

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};
function SizeBoardItem({data ,title,setData ,getData,paging,tableRef, deleteSize ,setBusy ,dataSizeValue, createOrUpdate , getById , dataDropDown}) {
    const [selectId, setSelectId] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false);
    

    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox id="select_all" name="select_all" checked={data && data.length > 0 && data.filter((x) => x.selected).length === data.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th className="cursor-pointer">Id</th>
                    <th className="cursor-pointer">SizeName</th>
                    <th className="cursor-pointer">Height</th>
                    <th className="cursor-pointer">MinWeight</th>
                    <th className="cursor-pointer">MaxWeight</th>
                    <th className="cursor-pointer">Size</th>
                    <th className="cursor-pointer">SizeValue</th>
                    <th className="cursor-pointer">Product</th>
                </tr>
            </thead>
        );
    }
 

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                   {item.id}
                                </td>
                                <td>
                                   <strong>{item.sizeName}</strong>
                                </td>
                                <td>
                                   {item.height}
                                </td>
                                <td>
                                   {item.minWeight}
                                </td>
                                <td>
                                   {item.maxWeight}
                                </td>
                                <td>
                                   {OPTION_SIZE.map((itm)=>{
                                    if(itm.value === item.size)
                                        return itm.label
                                   })}
                                </td>
                                <td>
                                {dataSizeValue && dataSizeValue.map((itm)=>{
                                    if(itm.value === item.sizeValue && itm.productId == item.productId)
                                        return <div>{itm.label}</div>
                                   })}
                                </td>
                                <td>
                                    {dataDropDown.map((itm)=>{
                                        if(itm.value === item.productId)
                                        return itm.label
                                   })}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }


    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    let [error, data] = await deleteSize(ids);
                    if (!error && data) {
                        setBusy(false);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData({...initPage});
                    } else {
                        setBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
        });
    };

    const _handleEdit = ()=>{
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        setSelectId(ids[0]);
        setIsShowModal(true);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom mb-3"
                    onClick={() => {
                        setSelectId(0);
                        setIsShowModal(true);
                    }}
                >
                    Thêm mới
                </button>
                <button className={dataChecked && dataChecked.length == 1 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom  mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length == 1 ? _handleEdit() : {})}>
                    Sửa
                </button>
                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  mb-3 ml-3" : "btn btn-danger  mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }


    return ( 
        <Wapper>
            <div className="mb-2 font-size-24px"><strong>{title}</strong></div>
            <CommonTable data={data} paging={paging} ref={tableRef} 
            headerCheckbox hideSearchBasicButton 
            renderAction={_renderAction} 
            renderHeader={_renderHeader} 
            renderBody={_renderBody} 
            onFilter={getData} />
            {isShowModal && (
                <ModalAddOrUpdateSizeBoard
                    isShowModal={isShowModal}
                    id={selectId}
                    dataSizeValue={dataSizeValue}
                    dataDropDown={dataDropDown}
                    getById={getById}
                    createOrUpdate={createOrUpdate}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                />
            )}
        </Wapper>
    );
}
const Wapper = styled.div`

`
export default SizeBoardItem;