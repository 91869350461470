import _ from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";
import ProductServices from "../based/services/ProductServices";

const PromoteHighlight = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getPromoteHighlight: () => {
            return campaignBuyMoreProducts;
        },
    }));

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");

    const [products, setProducts] = useState([]);
    const [textSearch, setTextSearch] = useState("");
    const [isFetching, setFetching] = useState(false);

    const [showResult, setShowResult] = useState(false);
    const [quickSetup, setQuickSetup] = useState({});
    const [campaignBuyMoreProducts, setCampaignBuyMoreProducts] = useState([]);

    useEffect(() => {
        setCampaignBuyMoreProducts(props.campaignBuyMoreProducts);
    }, [props.campaignBuyMoreProducts]);

    function _handleRemoveVariant(item) {
        if (item) {
            ConfirmDialog("Xác nhận xoá?", "Bạn chắc chắn muốn loại bỏ sản phẩm đã chọn?", () => {
                return new Promise((resolve, reject) => {
                    let pb = [...campaignBuyMoreProducts];
                    if (pb && pb.length > 0) {
                        let idx = pb.findIndex((x) => x.productId == item.productId);
                        if (idx > -1) pb.splice(idx, 1);
                        setCampaignBuyMoreProducts(pb);
                    }
                    resolve({ title: "Thành công", msg: "Xoá thành công." });
                });
            });
        }
    }

    function _handleChangedHighLight(name, value, idx) {}

    async function _handleSelectItem(item) {
        let pBrand = [...campaignBuyMoreProducts];
        let any = pBrand.some((x) => x.productId == item.id);
        if (any) {
            Notify(NOTIFY.INFO, NOTIFY.INFO, "Sản phẩm đã tồn tại trong danh sách");
        } else {
            pBrand.push({
                campaignId: 0,
                productId: item.id,
                importPrice: item.importPrice,
                availableStock: item.availableStock,
                name: item.name,
                productAvatar: item.productAvatar,
            });
            setShowResult(false);
        }
        setCampaignBuyMoreProducts(pBrand);
    }

    /** render search auto complete */
    function _renderSearchAutoComplete() {
        return (
            <div id='search-autocomplete' className='p-1 bg-search-autocomplete rounded rounded-pill shadow-sm mb-4' onBlur={_handleClickOutside}>
                <div className='input-group'>
                    <div className='input-group-prepend'>
                        <button type='submit' className='btn btn-link text-warning'>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                    <input
                        id='txtSearch'
                        type='search'
                        placeholder='Nhập tên set sản phẩm...'
                        className='form-control border-0 bg-search-autocomplete'
                        onKeyUp={(e) => _handleChangeInputSearch(e)}
                        onChange={(e) => setTextSearch(e.target.value)}
                        onFocus={() => _handleFocusInputSearch()}
                        value={textSearch}
                        autoComplete='off'
                    />
                </div>
                {showResult && _renderResult()}
            </div>
        );
    }
    function _renderResult() {
        return (
            <div id='search-result'>
                <ul className='suggest_search'>
                    {products && products.length > 0 ? (
                        products.map((item, index) => {
                            return (
                                <div className='product-item' key={index} onClick={() => _handleSelectItem(item)}>
                                    <li className='product_suggest'>
                                        <div className='item-img'>
                                            <img src={item.productAvatar} alt={item.name} />
                                        </div>
                                        <div className='item-info'>
                                            <h3>{item.name} </h3>
                                        </div>
                                    </li>
                                </div>
                            );
                        })
                    ) : (
                        <div className='product-item'>
                            <li className='product_suggest'>
                                {isFetching ? (
                                    <span>
                                        <i className='fa fa-spinner m-r-5'></i>Đang tải dữ liệu...
                                    </span>
                                ) : (
                                    <span>Không có dữ liệu</span>
                                )}
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        );
    }
    function _handleFocusInputSearch() {
        if (products && products.length > 0) setShowResult(true);
        else setShowResult(false);
    }
    function _handleClickOutside() {
        setTimeout(function () {
            setShowResult(false);
        }, 200);
    }
    function _handleChangeInputSearch(e) {
        var text = e.target.value;
        setFetching(true);
        setShowResult(true);
        debounce(text);
    }
    const debounce = useCallback(
        _.debounce((_searchVal) => {
            _fetchingSearch(_searchVal);
        }, 1000),
        []
    );
    async function _fetchingSearch(text) {
        if (text && text.length > 0) {
            setFetching(true);
            var modelSearch = { ...Common.Paging, keyword: text };
            const [err, data] = await ProductServices.GetProducts(modelSearch);
            if (!err && data && data.listObjects && data.listObjects.length > 0) {
                setProducts(data.listObjects);
                setFetching(false);
                setShowResult(true);
            } else {
                setShowResult(true);
                setFetching(false);
                setProducts([]);
            }
        } else {
            setProducts([]);
            setShowResult(false);
        }
    }

    /** render action */
    function _renderAction() {}

    /** render header */
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th>Tên sản phẩm</th>
                    <th width='200' className='text-center'>
                        Avatar
                    </th>
                    <th width='200' className='text-center'>
                        Giá nhập
                    </th>
                    <th width='200' className='text-center'>
                        Tồn kho
                    </th>
                    <th width='50'></th>
                </tr>
            </thead>
        );
    }

    /** render body */
    function _renderBody() {
        return (
            <tbody>
                {campaignBuyMoreProducts && campaignBuyMoreProducts.length > 0 ? (
                    campaignBuyMoreProducts.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className='vertical-align-middle'>
                                    <strong> {item.name}</strong>
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <img alt='product avatar' src={item.productAvatar} style={{ width: "200px" }} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={+item.importPrice} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={+item.availableStock} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <i className='mdi mdi-24px mdi-delete text-danger cursor-pointer' onClick={() => _handleRemoveVariant(item)}></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Chưa có sản phẩm nào được chọn</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <div className='col-md-12 card-box p-3'>
            {_renderSearchAutoComplete()}
            <div style={{ maxHeight: 500, overflow: "auto" }}>
                <CommonTable title='Danh sách sản phẩm' data={campaignBuyMoreProducts} paging={Common.Paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} freeSize='tbl_setproducts' />
            </div>
        </div>
    );
});
export default PromoteHighlight;
