export default class GTXPrintModel {
    constructor() {
        //Valid without conditions
        this.szFileName = null;
        this.uiCopies = 1;
        this.byMachineMode = 0;
        this.byPlatenSize = 2;
        this.byInk = 2;
        this.byResolution = 1;
        this.bySaturation = 0;
        this.byBrightness = 0;
        this.byContrast = 0;
        this.bUniPrint = false;

        //Valid only when [Color Ink Only] is specified for [Ink Combination]
        this.byInkVolume = 10;
        this.byDoublePrint = 1;

        //Valid only when [Color+White Ink] is specified for [Ink Combination]
        this.bEcoMode = false;
        this.bMaterialBlack = false;
        this.byMinWhite = 1;
        this.byChoke = 0;
        this.bPause = false;

        //Valid only when [White Ink Only] or [Color+White Ink] is specified for [Ink Combination]
        this.byHighlight = 1;
        this.byMask = 1;
        this.bTransColor = false;
        this.bDivide = false;

        //Valid only when [Color Ink Only] or [Color+White Ink] is specified for [Ink Combination]
        this.bMultiple = false;
        this.iCyanBalance = 0;
        this.iMagentaBalance = 0;
        this.iYellowBalance = 0;
        this.iBlackBalance = 0;

        //Valid only when true is specified for [Transparent Color]
        this.colorTrans = 0;
        this.byTolerance = 0;

        //Valid only when true is specified for [2 Layer White]
        this.byDivideSpan = 0;

        //Valid only when true is specified for [White/Color Pause]
        this.byPauseSpan = 0;

        //Red Config
        this.redHighlight = 0;
        this.redMask = 0;
        this.redMagenta = 0;
        this.redYellow = 0;
    }
}
