import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import TagComboServices from "../../based/services/TagComboServices";
import useFormValidate from "../../hooks/useFormValidate";
import Common from "../../based/Common";
import Request from "../../based/Request";
import { INITPAGING , CONSTANTS} from "../../based/Constants";
import styled from "styled-components";

const initTag = {
    id:0,
    name: ''
}
const initTagCB = {
    id:0,
    listTag:[]
}
const initModelSave = {
    model: {...initTagCB},
    tags:[
        {

        }
    ]
}
function AddOrUpdateTagCBModal(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [tagCB , setTagCB] = useState({...initTagCB});
    const [tags , setTags] = useState([]);
    

    useEffect(() => {
        if(props.id>0){
            getTabCB(props.id)
        }
    },[props.id])

    const getTabCB = async (id) => {
        setIsBusy(true);
        let [err, data] = await TagComboServices.GetTagComboById(id);
        if (!err && data) {
            setIsBusy(false);
            setTagCB({
                id: data.id,
            })
            setTags(data.listTag)
        }else{
            setIsBusy(false);
            setTagCB({...initTagCB})
            setTags([])

        }
        
    };

    const _handleSave = async()=>{
        if (isValid()&& tags.length > 0) {
            setIsBusy(true);
            let [err, data] = await TagComboServices.CreateOrUpdateTagCombo({model: tagCB, tags: tags});
            if (!err && data == 1) {
                setIsBusy(false);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
        if(tags.length==0){
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Chưa thêm tag");

        }
    }
    const handleAddTag = (e)=>{
        if (e.charCode === 13 && e.target.value != "" && tags.length < 100){
            if(!tags.some(tag => tag.name == e.target.value))
                setTags([...tags, {
                    id: 0,
                    name: e.target.value
                }])
            e.target.value = ""
        }
        if(tags.length == 100){
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Tối đa 100 tag");
        }
    }
    const handleRemoveTag = (name) =>{
        setTags(tags.filter(tag => tag.name != name))
    }
    return ( 
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} 
        onSave={_handleSave} 
        onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Tag name
                </label>
                <span style={{float: 'right'}}>{tags.length}/100</span>
                <Wapper>
                    <div className="tab-list">
                    {tags && tags.map((tag , idx)=>{
                       return (
                        <div key={idx} className="tab-item">
                            <span className="name-tab">#{tag.name}</span>
                            <span className="icon" onClick={()=>{
                                handleRemoveTag(tag.name)
                            }}>
                                &times;
                            </span>
                        </div>)
                    })}
                                                
                    </div>
                    <input type="text" placeholder="Nhập tag name và enter" onKeyPress={(e)=>{
                        handleAddTag(e)
                    }}/>
                </Wapper>
               
                
            </div>
        </CommonModal>
     );
}

export default AddOrUpdateTagCBModal;


const Wapper = styled.div`
    .tab-list{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .tab-item{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 5px;
            background-color: #64C5B1;
            height: 30px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            .name-tab{
                color: #FFFFFF;

            }
            .icon{
                margin-left: 5px;
                color: #FFFFFF;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;

            }
        }
    }
    input{
        margin-top:10px;
        width: 100%;
        height: 30px;
        border: 1px solid #64C5B1;
        border-radius: 10px;
        padding: 0 10px;

        :placeholder{
            color: #f3f3f3;
        }
    }

`

AddOrUpdateTagCBModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};