import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import AdsServices from "../based/services/AdsServices";
import AddOrEditAdAccount from "./AddOrEditAdAccount";
import Common from "../based/Common";
import TFUSelect from "../based/refactor/TFUSelect";

const AdAccountModel = {
    id: "",
    name: "",
};

const Step = {
    AdAccount: 0,
    Campaign: 1,
    Adset: 2,
    Ad: 3,
};

const ListStep = [
    {
        id: Step.AdAccount,
        name: "Ad Account",
    },
    {
        id: Step.Campaign,
        name: "Campaign",
    },
    {
        id: Step.Adset,
        name: "Adset",
    },
    {
        id: Step.Ad,
        name: "Ad",
    },
];

function AdsManagement(props) {
    const [currentStep, setCurrentStep] = useState(Step.AdAccount);

    const renderHeader = () => {
        return (
            <ul className="nav nav-tabs tabs-bordered">
                {ListStep.map((item, idx) => {
                    let isActive = item.id == currentStep;
                    return (
                        <li className="nav-item" key={idx}>
                            <a
                                className={`nav-link ${isActive ? "active" : ""}`}
                                data-toggle="tab"
                                onClick={() => {
                                    setCurrentStep(item.id);
                                }}
                            >
                                <span>{item.name}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý Facebook Ads" />
            {renderHeader()}
            {currentStep == Step.AdAccount && <AdAccountManagement />}
            {currentStep == Step.Campaign && <AdCampaignManagement />}
            {currentStep == Step.Adset && <AdSetManagement />}
            {currentStep == Step.Ad && <AdManagement />}
        </Wrapper>
    );
}

function AdAccountManagement() {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [datas, setDatas] = useState([AdAccountModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await AdsServices.GetAdAccountByPaging(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
        setIsBusy(false);
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (value) setSelectId(newDatas[index].id);
        else setSelectId(0);
    };

    const _handleDelete = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await AdsServices.RemoveAdAccountById(id);
                if (!error && data) {
                    _getData(paging);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    reject({ title: "Thất bại", msg: "Vui lòng thử lại sau." });
                }
                setMsg(null);
                setIsLoading(false);
            });
        });
    };

    const _handleFetchInform = async (id) => {
        setIsBusy(true);
        let [err, data] = await AdsServices.FetchDataByAdAccountId(id);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lấy thông tin thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lấy thông tin thất bại");
        }
        setIsBusy(false);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center" width={`15%`}></th>
                    <th className="center" width={`10%`}>
                        Ad Account Id
                    </th>
                    <th className="center" width={`15%`}>
                        Account Name
                    </th>
                    <th className="center" width={`15%`}>
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>
                <button className={selectId > 0 ? "btn btn-danger ml-3" : "btn btn-danger ml-3 disable"} onClick={() => (selectId > 0 ? _handleDelete(selectId) : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.id ? item.id == selectId : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                    <button
                                        className="btn btn-custom ml-2"
                                        onClick={() => {
                                            _handleFetchInform(item.id);
                                        }}
                                    >
                                        Fetch thông tin theo Account Id
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            {isShowModal && (
                <AddOrEditAdAccount
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        _getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </>
    );
}

const CampaignModel = {
    id: "",
    name: "",
    objective: "",
    accountId: "",
};

function AdCampaignManagement() {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [datas, setDatas] = useState([CampaignModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [selectId, setSelectId] = useState(0);

    const [adAccountLabel, setAdAccountLabel] = useState([]);
    const [adAccountId, setAdAccountId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
        _getAdAccount();
    }, []);

    async function _getData(paging, adAccountId = "") {
        setIsBusy(true);
        let [err, data] = await AdsServices.GetAdCampaignByPaging(paging, adAccountId);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
        setIsBusy(false);
    }

    async function _getAdAccount() {
        let [err, data] = await AdsServices.GetAdAccountByPaging({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
        if (!err && data) {
            let list = data.listObjects.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
            setAdAccountLabel(list);
        } else {
            setAdAccountLabel([]);
        }
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (value) setSelectId(newDatas[index].id);
        else setSelectId(0);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center" width={`15%`}></th>
                    <th className="center" width={`10%`}>
                        Id
                    </th>
                    <th className="center" width={`15%`}>
                        Name
                    </th>
                    <th className="center" width={`15%`}>
                        Objective
                    </th>
                    <th className="center" width={`15%`}>
                        Account Id
                    </th>
                    <th className="center" width={`15%`}>
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-md-3">
                        <TFUSelect
                            name="adAccountId"
                            label="Chọn Ad Account"
                            options={adAccountLabel}
                            onChanged={(value) => {
                                _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 }, value || "");
                                setAdAccountId(+value);
                            }}
                            value={adAccountId}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.id ? item.id == selectId : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.objective}</td>
                                <td>{item.accountId}</td>
                                <td></td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
        </>
    );
}

const SetModel = {
    id: "",
    name: "",
    startTime: new Date(),
    endTime: new Date(),
    dailyBudget: 0,
    lifetimeBudget: 0,
    campaignId: "",
};

function AdSetManagement() {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [datas, setDatas] = useState([SetModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [selectId, setSelectId] = useState(0);

    const [campaignLabels, setCampaignLabels] = useState([]);
    const [campaignId, setCampaignId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
        _getCampaign();
    }, []);

    async function _getData(paging, campaignId = "") {
        setIsBusy(true);
        let [err, data] = await AdsServices.GetAdSetByPaging(paging, campaignId);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
        setIsBusy(false);
    }

    async function _getCampaign() {
        let [err, data] = await AdsServices.GetAdCampaignByPaging({ ...INITPAGING, pageNumber: 1, pageSize: 500 }, "");
        if (!err && data) {
            let list = data.listObjects.map((item) => {
                return {
                    value: item.id,
                    label: `${item.name} (${item.accountName})`,
                };
            });
            setCampaignLabels(list);
        } else {
            setCampaignLabels([]);
        }
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (value) setSelectId(newDatas[index].id);
        else setSelectId(0);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center" width={`15%`}></th>
                    <th className="center" width={`10%`}>
                        Id
                    </th>
                    <th className="center" width={`15%`}>
                        Name
                    </th>
                    <th className="center" width={`15%`}>
                        Start
                    </th>
                    <th className="center" width={`15%`}>
                        End
                    </th>
                    <th className="center" width={`15%`}>
                        Daily Budget
                    </th>
                    <th className="center" width={`15%`}>
                        Lifetime Budget
                    </th>
                    <th className="center" width={`15%`}>
                        CampaignId
                    </th>
                    <th className="center" width={`15%`}>
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-md-3">
                        <TFUSelect
                            name="campaignId"
                            label="Chọn Campaign"
                            options={campaignLabels}
                            onChanged={(value) => {
                                _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 }, value || "");
                                setCampaignId(value);
                            }}
                            value={campaignId}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.id ? item.id == selectId : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{Common.formatDate(new Date(item.startTime))}</td>
                                <td>{Common.formatDate(new Date(item.endTime))}</td>
                                <td>{item.dailyBudget}</td>
                                <td>{item.lifetimeBudget}</td>
                                <td>{item.campaignId}</td>
                                <td></td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
        </>
    );
}

const AdModel = {
    id: "",
    name: "",
    adsetId: "",
    effectiveStatus: "",
    status: "",
    adActiveTime: "",
    previewShareableLink: "",
    bidAmount: 0,
};

function AdManagement() {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [datas, setDatas] = useState([AdModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [selectId, setSelectId] = useState(0);

    const [adSetLabels, setAdSetLabels] = useState([]);
    const [adSetId, setAdSetId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
        _getAdSet();
    }, []);

    async function _getData(paging, adsetId = "") {
        setIsBusy(true);
        let [err, data] = await AdsServices.GetAdByPaging(paging, adsetId);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
        setIsBusy(false);
    }

    async function _getAdSet() {
        let [err, data] = await AdsServices.GetAdSetByPaging({ ...INITPAGING, pageNumber: 1, pageSize: 1000 }, "");
        if (!err && data) {
            let list = data.listObjects.map((item) => {
                return {
                    value: item.id,
                    label: `${item.name} (${item.campaignName})`,
                };
            });
            setAdSetLabels(list);
        } else {
            setAdSetLabels([]);
        }
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (value) setSelectId(newDatas[index].id);
        else setSelectId(0);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center" width={`15%`}></th>
                    <th className="center" width={`10%`}>
                        Id
                    </th>
                    <th className="center" width={`15%`}>
                        Name
                    </th>
                    <th className="center" width={`15%`}>
                        AdsetId
                    </th>
                    <th className="center" width={`15%`}>
                        Effective Status
                    </th>
                    <th className="center" width={`15%`}>
                        Status
                    </th>
                    <th className="center" width={`15%`}>
                        Ad Active ime
                    </th>
                    <th className="center" width={`15%`}>
                        Preview Shareable Link
                    </th>
                    <th className="center" width={`15%`}>
                        Bid Amount
                    </th>
                    <th className="center" width={`15%`}>
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-md-3">
                        <TFUSelect
                            name="adSetId"
                            label="Chọn Ad Set"
                            options={adSetLabels}
                            onChanged={(value) => {
                                _getData({ ...INITPAGING, pageNumber: 1, pageSize: 100 }, value || "");
                                setAdSetId(value);
                            }}
                            value={adSetId}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.id ? item.id == selectId : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.adsetId}</td>
                                <td>{item.effectiveStatus}</td>
                                <td>{item.status}</td>
                                <td>{item.adActiveTime}</td>
                                <td>{item.previewShareableLink}</td>
                                <td>{item.bidAmount}</td>
                                <td>
                                    <button
                                        className="btn btn-custom"
                                        onClick={(e) => {
                                            let content = `ads_id=${item.id}`;
                                            navigator.clipboard.writeText(content);
                                            Notify(Notify.SUCCESS, "Thông báo", "Copy thông tin Url thành công");
                                        }}
                                    >
                                        Copy thông tin Url
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
        </>
    );
}

export default AdsManagement;

const Wrapper = styled.div`
    .modal-class-body {
        min-height: 50vh;
    }
    td img {
        width: 80px;
        height: 80px;
        object-fit: contain;
    }
`;
