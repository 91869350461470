import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../based/Breadcrumbs";
import { NOTIFY } from "../../../based/Constants";
import Textbox from "../../../based/inputs/Textbox";
import Loading from "../../../based/Loading";
import { Notify } from "../../../based/Notify";
import BaseServices from "../../../based/services/BaseServices";
import useFormValidate from "../../../hooks/useFormValidate";

const UVPrintingTableProductCalibrateModel = {
    appProductId: 0,
    appProductName: "",
    calX: 0,
    calY: 0,
};

const UVPrintingTableCal = ({ match }) => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState("");
    const [calibrateModels, setCalibrateModels] = useState([UVPrintingTableProductCalibrateModel]);
    const [printingTableName, setprintingTableName] = useState([""]);
    const { addRef, isValid, displayError } = useFormValidate();
    const detailId = match.params.printingTableId;
    const history = useHistory();

    useEffect(() => {
        _fetchData(detailId);
    }, [detailId]);

    const _fetchData = async (printingTableId) => {
        setBusy(true);
        const [err, data] = await BaseServices.Get(`/api/uvprint/get-product-calibrate/${printingTableId}`);
        if (!err && data) {
            let { printingTableName, calibrateModels } = data;
            setprintingTableName(printingTableName);
            setCalibrateModels(calibrateModels);
        } else {
            setprintingTableName("");
            setCalibrateModels([]);
        }
        setBusy(false);
    };

    const _handleSave = async (models) => {
        setBusy(true);

        const [err, data] = await BaseServices.Post(`/api/uvprint/create-update-calibrate-models`, models);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Lưu thành công");
            history.push("/uv-printing-table-management");
        } else {
            Notify(NOTIFY.ERROR, "Lưu thất bại");
        }

        setBusy(false);
    };

    return (
        <React.Fragment>
            <Breadcrumbs currentPage={detailId == "new" ? "Thêm mới bàn gá" : "Chỉnh sửa bàn gá"} parentPage="Ranus" hrefParent="/" />
            <Loading show={busy} msg={busyMsg} />
            <div className="card-box padding-10">
                <div className="row">
                    <div className="col-4">
                        <label className="required">Tên bàn gá</label>
                        <Textbox name="name" ref={addRef} required value={printingTableName} onChanged={(name, value) => setprintingTableName(value)} />
                    </div>
                </div>
                <div className="row">
                    <h5 className="col-12">Danh sách sản phẩm của bàn gá cần hiệu chỉnh</h5>
                    <div className="col-12">
                        <thead>
                            <tr>
                                <th>Sản phẩm</th>
                                <th>Cal X (0.1 mm)</th>
                                <th>Cal Y (0.1 mm)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calibrateModels.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.appProductName}</td>
                                        <td>
                                            <Textbox
                                                name="calX"
                                                ref={addRef}
                                                required
                                                value={item.calX}
                                                onChanged={(name, value) => {
                                                    let dt = [...calibrateModels];
                                                    dt[index].calX = value;
                                                    setCalibrateModels(dt);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Textbox
                                                name="calY"
                                                ref={addRef}
                                                required
                                                value={item.calY}
                                                onChanged={(name, value) => {
                                                    let dt = [...calibrateModels];
                                                    dt[index].calY = value;
                                                    setCalibrateModels(dt);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </div>
                </div>
                <div className="row text-right m-t-10">
                    <div className="col-12">
                        <button
                            className="btn btn-custom"
                            onClick={(e) => {
                                _handleSave(calibrateModels);
                            }}
                        >
                            Hoàn thành
                        </button>
                        <button className="btn btn-dark m-l-10" onClick={() => history.push("/uv-printing-table-management")}>
                            Trở về danh sách
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UVPrintingTableCal;
