import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/inputs/TFUSelect";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";

const initSizeOption = {
    id: 0,
    size:"",
    length: 0,
    width:0,
    weight:"",
    height:"",
    isDelete:false,
    productId: 0
}

const ModalAddOrUpdateSizeBoardDetail = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [size, setSize] = useState({})
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if(props.id>0 )
            getSize(props.id)
        else{
            setSize({...initSizeOption})
        }
    }, [props.id]);
    

    const getSize = async (id) => {
        setIsBusy(true);
        let [err, data] = await props.getById(id);
        if (!err && data) {
            setIsBusy(false);
            setSize(data)
        } else {
            setIsBusy(false);
            setSize({...initSizeOption})
        }
    };
    const _handleSave = async () => {

        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await props.createOrUpdate(size);
            if (!err && data) {
                setIsBusy(false);
                setSize({...initSizeOption})
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                
            }
            props.onSaveContinue();
        }
      
    };


   
   
    
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='Size' className='required'>
                    Size:
                </label>
                <Textbox id='Size' name='Size' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, size: value })} 
                value={size.size} />
                <label htmlFor='Length' className='required'>
                    Length:
                </label>
                <Textbox id='Length' name='Length' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, length: value })} 
                value={size['length']} />

                <label htmlFor='width' className='required'>
                Width:
                </label>
                <Textbox id='width' name='width' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, width: value })} 
                value={size.width} />

                <label htmlFor='weight' className='required'>
                    Weight:
                </label>
                <Textbox id='weight' name='weight' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, weight: value })} 
                value={size.weight} />

                <label htmlFor='height' className='required'>
                    Height:
                </label>
                <Textbox id='height' name='height' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, height: value })} 
                value={size.height} />

                <label htmlFor='product' className='required'>
                    Product:
                </label>
                <TFUSelect name='product' className='' id='product' required
                options={props.dataDropDown} value={size.productId} 
                onChanged={(value) => setSize({ ...size, productId: value})} />
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdateSizeBoardDetail;

ModalAddOrUpdateSizeBoardDetail.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
