import VoucherLightModel from "./VoucherLightModel";

export default class VoucherBrandModel {
    constructor() {
        this.voucherId = 0;
        this.maximumLoyaltyLevel1 = 0;
        this.discountValueLevel1 = 0;
        this.maximumLoyaltyLevel2 = 0;
        this.discountValueLevel2 = 0;
        this.maximumLoyaltyLevel3 = 0;
        this.discountValueLevel3 = 0;
        this.maximumLoyaltyLevel4 = 0;
        this.discountValueLevel4 = 0;
        this.maximumLoyaltyLevel5 = 0;
        this.discountValueLevel5 = 0;
        this.discountValueDefault = 0;
        this.voucher = new VoucherLightModel();
    }
}
