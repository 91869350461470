import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../../based/inputs/Textbox";
import TextArea from "../../based/inputs/TextArea";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import FolderAudioServices from "../../based/services/FolderAudioServices";
import useFormValidate from "../../hooks/useFormValidate";
import Common from "../../based/Common";
import Request from "../../based/Request";
import { INITPAGING , CONSTANTS} from "../../based/Constants";
import styled from "styled-components";
import { initFolderAudio } from "./FolderAudioManagement";

const AddOrUpdateFolderAudio = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [folderAudio, setFolderAudio] = useState({})
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if(props.id>0 )
            getFolderAudio(props.id)
        else{
            setFolderAudio({initFolderAudio})
        }
    }, [props.id]);
    

    const getFolderAudio = async (id) => {
        setIsBusy(true);
        let [err, data] = await FolderAudioServices.GetFolderAudioById(id);
        if (!err && data) {
            setIsBusy(false);
            setFolderAudio(data)
            console.log(data)
        } else {
            setIsBusy(false);
            setFolderAudio({initFolderAudio})
        }
    };
    const _handleSave = async () => {

        if (isValid()) {
            setIsBusy(true);
            let option = { ...folderAudio}
            if(props.id == 0)
                option = { ...folderAudio , parentId: props.parentId}
            let [err, data] = await FolderAudioServices.CreateUpdateFolderAudio(option);
            if (!err && data == 1) {
                setIsBusy(false);
                setFolderAudio({});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
      
    };


   
   
    
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Name:
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => setFolderAudio({ ...folderAudio, name: value })} value={folderAudio.name} />
            </div>
        </CommonModal>
    );
};

export default AddOrUpdateFolderAudio;
const Wapper = styled.div`

    .custom-btn-choose{
        display: inline-block;
        background: #eee;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        margin-right: 10px;
    }

    input[type="file"]{
        visibility: hidden;
    }
    
`
AddOrUpdateFolderAudio.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
