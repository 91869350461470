export class SetCensorshipModel {
    constructor() {
        this.setId = 0;
        this.manualReviewStatus = 0;
        this.remark = "";
        this.artworkCensorShips = [new ArtworkCensorShipModel()];
    }
}

class ArtworkCensorShipModel {
    constructor() {
        this.artworkId = 0;
        this.transparentColors = "";
    }
}
