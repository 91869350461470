import BaseServices from "./BaseServices";

const TemplateTypeServices = {
    GetTemplateTypes: async (paging) => {
        return await BaseServices.Post("/api/Collection/get-list-template-type", paging);
    },
    CreateUpdateTemplateType: async (model) => {
        return await BaseServices.Post("/api/Collection/create-update-template-type", model);
    },
    GetTemplateTypeById: async (id) => {
        return await BaseServices.Get(`/api/Collection/get-template-type/${id}`);
    },
    DeleteTemplateTypeById: async (id) => {
        return await BaseServices.Get(`/api/Collection/delete-template-type/${id}`);
    },
    GetListTopic: async (paging) => {
        return await BaseServices.Post("/api/Collection/get-list-topic", paging);
    },
    GetListBrand: async (paging) => {
        return await BaseServices.Post("/api/Collection/get-list-brand", paging);
    },
};

export default TemplateTypeServices;
