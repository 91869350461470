import React, { useState, useEffect } from 'react';

import Request from '../based/Request';
import { NOTIFY, CONSTANTS } from '../based/Constants';
import { Notify } from '../based/Notify';
import Common from '../based/Common';
import CommonModal from '../based/Modal';
import Textbox from '../based/inputs/Textbox';
import useFormValidate from '../hooks/useFormValidate';
import TFUTooltip from '../based/TFUTooltip';

export default function AddOrEditUser(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [user, setUser] = useState(props.user);
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setUser(props.user);
    }, [props.isShowModal])

    function _handleSave() {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading('Đang lưu dữ liệu..');
            Request.Post('/api/users/save-user', user)
                .then(res => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, 'Thành công', res.message);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                        props.onSaveSucceed();
                    }
                    else {
                        Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                    }
                })
                .catch(err => {
                    if (err.errors) displayError(err.errors);
                    else Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                });
        }
    }

    function _handleChangedUserName(value) {
        if (value) {
            let userOld = { ...user };
            if (!Common.isValidCode(value)) value = userOld.userName;
        }
        setUser({ ...user, userName: value });
    }

    return (
        <CommonModal
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={user.id == 0 ? 'Thêm mới' : 'Cập nhật'}
            onSave={() => _handleSave()}
            onClose={() => props.onClose()}
        >
            <div className='form-group'>
                <label htmlFor="userName" className='required'>Tên đăng nhập: </label>
                <TFUTooltip content="Bao gồm các chữ số a-zA-Z0-9 và dấu _" target="quesuserName">
                    <i className="fa fa-question-circle" id='quesuserName' />
                </TFUTooltip>
                <Textbox id='userName' name='userName' readOnly={user.id ? true : false} required
                    ref={addRef} maxLength={30}
                    className='form-control'
                    onChanged={(name, value) => _handleChangedUserName(value)}
                    value={user.userName}
                />
            </div>
            {
                user.id > 0
                    ? null
                    : <div className="form-group">
                        <label htmlFor="password" className='required'>Mật khẩu:</label>
                        <Textbox id='password' name='password' password required
                            ref={ref => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })} maxLength={255} minLength={6}
                            className='form-control' onChanged={(name, value) => setUser({ ...user, password: value })}
                            value={user.password ? user.password : ''} />
                    </div>
            }
            <div className="form-group">
                <label htmlFor="firstName" className='required'>Họ:</label>
                <Textbox required id='firstName' name='firstName'
                    ref={addRef} maxLength={100}
                    className='form-control' onChanged={(name, value) => setUser({ ...user, firstName: value })}
                    value={user.firstName}
                />
            </div>
            <div className="form-group">
                <label htmlFor="lastName" className='required'>Tên:</label>
                <Textbox required id='lastName' name='lastName'
                    ref={addRef} maxLength={100}
                    className='form-control' onChanged={(name, value) => setUser({ ...user, lastName: value })}
                    value={user.lastName}
                />
            </div>
            <div className="form-group">
                <label htmlFor="email" className='required'>Email:</label>
                <Textbox id='email' name='email' readOnly={user.id ? true : false} required email
                    ref={addRef} maxLength={255}
                    className='form-control' onChanged={(name, value) => setUser({ ...user, email: value })}
                    value={user.email}
                />
            </div>
        </CommonModal>
    )
}