import React, { useEffect, useState } from "react";
import { INITPAGING } from "../based/Constants";
import AnalyticServices from "../based/services/AnalyticServices";
import Common from "../based/Common";
import Loading from "../based/Loading";
import CommonTable from "../based/CommonTable";

function SummaryBanTraffic(props) {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING });

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setBusy(true);

        const [err, data] = await AnalyticServices.GetBannedIPPaging(newPaging);
        if (!err && data) {
            const { listObjects, paging } = data;
            setDatas(listObjects);
            setPaging(paging);
        } else {
            if (newPaging.pageNumber == 1) {
                setDatas([]);
            }
            setPaging({ ...INITPAGING });
        }
        setBusy(false);
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">STT</th>
                    <th className="center">IP</th>
                    <th className="center">Location</th>
                    <th className="center">Ban Time</th>
                    <th className="center">Traffic Source</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return datas && datas.length > 0 ? (
            <tbody>
                {datas.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.ip}</td>
                            <td>{item.location}</td>
                            <td>{item.createdDate ? Common.formatDate(new Date(item.createdDate)) : ""}</td>
                            <td>{item.trafficSource}</td>
                        </tr>
                    );
                })}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colSpan="888" className="center">
                        Không có dữ liệu
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {}

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <CommonTable data={datas} paging={paging} searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
        </React.Fragment>
    );
}

export default SummaryBanTraffic;
