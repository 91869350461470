import React, { useState } from "react";
import CommonModal from "../based/Modal";
import styled from "styled-components";
import OrderServices from "../based/services/OrderServices";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";

function CancelModal({ show, onClose, orderId }) {
    const [busy, setBusy] = useState(false);
    const [remark, setRemark] = useState("");

    const CancelOrder = async () => {
        if (remark === "") {
            Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng nhập lý do hủy đơn hàng");
            return;
        }

        const [err, data] = await OrderServices.CancelOrderByAdmin({
            orderId: orderId,
            orderCancelDetail: remark,
        });
        if (err) {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra");
            return;
        } else {
            Notify(NOTIFY.SUCCESS, "Thành công", "Hủy đơn hàng thành công");
            onClose();
            window.close();
        }
    };

    return (
        <CommonModal
            show={show}
            isBusy={busy}
            busyMsg={"Đang tải"}
            title={`Hủy đơn hàng`}
            onClose={() => onClose()}
            size="md"
            onSave={() => {
                CancelOrder();
            }}
        >
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Lý do hủy đơn
                    </label>
                    <input
                        type="text"
                        value={remark}
                        className="form-control"
                        onChange={(e) => {
                            setRemark(e.target.value);
                        }}
                    />
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default CancelModal;

const Wrapper = styled.div``;
