import React, { useEffect, useState, useCallback } from "react";
import Common from "../../based/Common";
import { CONSTANTS, NOTIFY } from "../../based/Constants";
import Textbox from "../../based/inputs/Textbox";
import TFUSelect from "../../based/inputs/TFUSelect";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import useFormValidate from "../../hooks/useFormValidate";
import BlogServices from "../../based/services/BlogServices";

export default function KeywordModal(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [keyword, setKeyword] = useState(props.keyword);
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        //category
        setKeyword(props.keyword);
    }, [props.isShowModal]);
    async function _handleSave(isContinue) {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu...");
            let [err, data] = await BlogServices.CreateUpdateBlogKeyword(keyword);
            if (!err && data == 1) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                setIsModalLoading(false);
                setMsgModalLoading(null);
                props.onSaveSucceed(isContinue);
                if (isContinue) setKeyword(props.keyword);
            } else {
                if (data == -2) {
                    Notify(NOTIFY.ERROR, "Lỗi", "Keyword đã tồn tại");
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                } else {
                    Notify(NOTIFY.ERROR, "Lỗi", "Lưu thất bại");
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                }
            }
        }
    }

    return (
        <CommonModal
            size='md'
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={keyword.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave(false)}>
                        <i className='fa fa-save m-r-5' /> Lưu
                    </button>
                    {keyword.id > 0 ? null : (
                        <button className='btn btn-outline-primary btn-sm' onClick={() => _handleSave(true)}>
                            <i className='fa fa-save m-r-5' /> Lưu &amp; tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            <div className='form-row'>
                <div className='card card-body' style={{ paddingTop: 5 }}>
                    <h5 className='card-title'>THÔNG TIN KEYWORD</h5>
                    <div className='form-group'>
                        <label htmlFor='parentId'>Keyword cha:</label>
                        <TFUSelect
                            name='parentId'
                            ref={addRef}
                            options={props.keywordOptions}
                            text={keyword.name}
                            value={keyword.parentId}
                            placeholder='-- Keyword cha --'
                            onChangedObject={(selected) => {
                                if (selected) {
                                    var newKeyword = {
                                        ...keyword,
                                        parentId: selected.value ? selected.value : 0,
                                    };
                                    setKeyword(newKeyword);
                                } else {
                                    setKeyword({
                                        ...keyword,
                                        parentId: 0,
                                    });
                                }
                            }}
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='name' className='required'>
                            Keyword:
                        </label>
                        <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => setKeyword({ ...keyword, name: value })} value={keyword.name} />
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
