import React, { useState, useEffect } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

class TFUOverlay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let popover = (
      <Popover id="popover-basic">
        <Popover.Title>{this.props.title}</Popover.Title>
        <Popover.Content>{this.props.content}</Popover.Content>
      </Popover>
    );
    return (
      <Overlay show={this.props.show} target={this.props.target} placement={this.props.placement} container={this.props.container} containerPadding={20}>
        {popover}
      </Overlay>
    );
  }
}

export default TFUOverlay;
