import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import MarketingServices from "../based/services/MarketingServices";
import DragAndDropIndex from "../based/DragAndDropIndex";
import Request from "../based/Request";
import ModalAddProduct from "./modals/ModalAddProduct";
import ModalUpdateProduct from "./modals/ModalUpdateProduct";

const initPage = {
    pageSize: 50,
    pageNumber: 1,
};

const TYPE_PRODUCT = {
    CREATOR: 1,
    DESIGN : 2
}
const modelFolder = {
    id: 0,
    isDelete: 0,
    thumbnail: "",
    name: "",
    index: 0 ,
};

function MarketingProductManagement({campaignId, creatorId}) {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    const [isShowModalUpdateProduct, setIsShowModalUpdateProduct] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");

    const [product, setProduct] = useState([]);
    const [pagingProduct, setPagingProduct] = useState(initPage);
    const tableProductRef = useRef(null);
    const [folderId, setFolderId] = useState();
    const [itemUpdate, setItemUpdate] = useState({})


    useEffect(() => {
        setBusy(false);
        getData();
    }, []);

    async function getData() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetFolderMarketingByCampaign(campaignId);
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
   
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                    </th>
                    <th>
                        Hình ảnh
                    </th>
                    <th>Tên danh mục</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }
    const _handleDelete = async (id , idx) => {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await MarketingServices.DeleteFolder(id);
                    if (!error && data) {
                        setBusy(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData();
                    } else {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        }else{
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
        
    };

    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...data];
                            dt[index].thumbnail = res.data.imageUrl;
                            setData(dt);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    function IsDisable(idx) {
        if (!data[idx].name || !data[idx].thumbnail) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] , marketingCampaignId: campaignId };
        let [err, dt] = await MarketingServices.CreateUpdateFolder(dataItem);
        if (!err && dt) {
            getData();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => {
                                        let dt = [...data];
                                        dt.map((x) => (x.selected = false));
                                        dt[idx].selected = value;
                                        setData(dt);
                                        if(value){
                                            setFolderId(item.id)
                                        }else{
                                            setFolderId(0);
                                        }
                                    }} />
                                </td>
                                <td style={{ width:100, textAlign: "center",position: 'relative' }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <div>
                                            {item.thumbnail ? (
                                                <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                    <img style={{ maxWidth: 100 , maxHeight: 100 }} src={item.thumbnail} />
                                                    <span style={{ position: 'absolute', top: 10 , right: 10 , fontSize: 30 , lineHeight: 0.5}} onClick={()=>{
                                                        let dt = [...data]
                                                        dt[idx].thumbnail = ""
                                                        setData(dt)
                                                    }}>
                                                        &times;
                                                    </span>

                                                </label>
                                            ) : (
                                                ""
                                            )}
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                hidden={item.thumbnail ? true : false}
                                                type="file"
                                                id={`upload-${idx}`}
                                                onChange={(e) => {
                                                    handleUploadFile(e, idx, data);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <img style={{ maxWidth: 100, maxHeight: 100 }} src={item.thumbnail} />
                                    )}
                                </td>
                                <td style={{ width: 200 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Tên danh mục"
                                            value={item.name}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].name = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.name
                                    )}
                                </td>
                                
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) {
                                                    handleCreateOrUpdate(idx);
                                                }
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            _handleDelete(item.id , idx)
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm danh mục +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    async function handleAddItem() {
        setData([...data, { ...modelFolder }]);
    }
    function _renderAction() {
       return <div className="title-product">
            Quản lý danh mục
       </div>
    }




    //Product


    useEffect(() => {
       getProduct()
    },[folderId])

    async function updateIndexProductInFolder(id , index) {
        setBusy(true);
        let [err, data] = await MarketingServices.UpdateIndexProductMarketingInFolder({
            id,
            index,
        });
        if (!err && data) {
            getProduct(initPage)
            setBusy(false);
        } else {
            setBusy(false);
        }
    }
   

    const getProduct = async() =>{
        setBusy(true);
        let [err, data] = await MarketingServices.GetProductMarketingByFolderId(folderId);
        if (!err && data) {
            setProduct(data);
            setBusy(false);
        } else {
            setProduct([]);
            setBusy(false);
        }
    }

    function _renderHeaderProduct() {
        return (
            <thead>
                <tr className="text-center">
                    <th>Hình</th>
                    <th>Kiểu sản phẩm</th>
                    <th>Tên hiển thị</th>
                    <th>Giá hiển thị </th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }
    const handleRemoveProduct = async (item) =>{
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                setMsg("Đang xóa dữ liệu...");
                let [err, data] = await MarketingServices.DeleteMarketingProduct(item.id);
                if (!err && data) {
                    getProduct()
                    setBusy(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    setBusy(false);
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");

                }
            });
        });
        
    }
   
    

    function renderRowProduct(item, idx){
        return (
            <React.Fragment>
                <td style={{width: 100, textAlign: "center" }}>
                    {item.product.isShowThumbnail ? <img src={item.product.thumbnail} /> 
                    : 
                    item.product.productType === TYPE_PRODUCT.CREATOR ?
                        <img src={item.product.productAvatar} /> : <img src={item.product.productImageUrl} />
                    }
                </td>
                <td style={{}}>
                    {item.objectType === TYPE_PRODUCT.CREATOR ? "Creator" : "Tự thiết kế"}
                </td>
                <td style={{ textAlign: "center" }}>
                    {item.product.name}
                </td>
                <td style={{ textAlign: "center" }}>
                    {item.product.price}
                </td>
                <td style={{ width: 200, textAlign: "center" }}>
                    <button className="btn btn-custom" onClick={async()=>{
                        await setItemUpdate(item.product)
                        setIsShowModalUpdateProduct(true);
                    }}>
                        Sửa
                    </button>
                    <button className="btn ml-3 btn-danger" onClick={()=>{
                        handleRemoveProduct(item)
                    }}>
                        Xóa
                    </button>
                </td>
            </React.Fragment>
        )
    }

    function _renderBodyProduct() {
        return (
            <DragAndDropIndex data={product} renderRow={renderRowProduct} updateIndexFolder={updateIndexProductInFolder} />

        );
    }
    function _renderActionProduct() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className={`btn btn-custom mb-2 ${dataChecked && dataChecked.length > 0 ? "" : "disabled"}`}
                    onClick={() => {
                        setIsShowModalAddProduct(true);
                    }}
                >
                    Thêm sản phẩm +
                </button>
               
            </React.Fragment>
        );
    }


    return ( <MarketingProductManagementWapper>
        <Loading show={isBusy} msg={msg} />
        <div className="body-page">
            <div className="folder-list">
                <CommonTable data={data}
                    paging={paging} ref={tableRef}  hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody}
                 />
            </div>
            <div className="product-list">
                <CommonTable data={product} paging={pagingProduct}
                ref={tableProductRef}  hideSearchBasicButton renderAction={_renderActionProduct} renderHeader={_renderHeaderProduct} renderBody={_renderBodyProduct} />

            </div>
        </div>
        {isShowModalAddProduct && <ModalAddProduct 
            isShowModal={isShowModalAddProduct}
            onClose={() => {
                setIsShowModalAddProduct(false);
            }}
            onSaveContinue={() => {
                setIsShowModalAddProduct(false);
                setIsShowModalUpdateProduct(true)
            }}
            setItemUpdate={setItemUpdate}
            product={product}
        />}
        {isShowModalUpdateProduct && <ModalUpdateProduct
            isShowModal={isShowModalUpdateProduct}
            onClose={() => {
                setIsShowModalUpdateProduct(false);
            }}
            onSaveContinue={() => {
                setIsShowModalUpdateProduct(false);
                getProduct()
            }}
            item={itemUpdate}
            folderId={folderId}
            creatorId={creatorId}
        />}
    </MarketingProductManagementWapper> );
}
const MarketingProductManagementWapper = styled.div`
    .cursor-move{
        cursor: move;
    }

    .title-product{
        font-size: 14px;
        font-weight: bold;
        color: #676767;
        margin-bottom: 24px;
    }
    .body-page{
        display: flex;
        .folder-list{
            width: 30%;

           
        }
        .product-list{
            width: 70%;
            padding-left: 10px;

            table{
                tr{
                    td{
                        img{
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }
                }
            }

        }
    }
`
export default MarketingProductManagement;