import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import { APPLIED_OPTION_TYPE, APPLIED_OPTION_TYPE_NAME, FLEXI_COMBO_TYPE, FLEXI_COMBO_TYPE_NAME, INITPAGING, NOTIFY } from "../../based/Constants";
import CheckBox from "../../based/inputs/CheckBox";
import Image from "../../based/inputs/Image";
import Textbox from "../../based/inputs/Textbox";
import TextNumber from "../../based/inputs/TextNumber";
import TFUDatetime from "../../based/inputs/TFUDatetime";
import TFUSelect from "../../based/inputs/TFUSelect";
import Loading from "../../based/Loading";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import FlexiComboServices from "../../based/services/FlexiComboServices";
import useFormValidate from "../../hooks/useFormValidate";
import FlexiComboModel from "../../models/FlexiComboModel";
import FlexiComboProductGiftModel from "../../models/FlexiComboProductGiftModel";
import FlexiSuggestModel from "../../models/FlexiSuggestModel";

const flexiComboTypeOption = Object.keys(FLEXI_COMBO_TYPE).map((key, idx) => {
    return {
        value: FLEXI_COMBO_TYPE[key],
        text: FLEXI_COMBO_TYPE_NAME[key],
        label: FLEXI_COMBO_TYPE_NAME[key],
    };
});

function FlexiComboModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [model, setModel] = useState(new FlexiComboModel());
    const { isShowModal, onSave, onClose, idFlexi } = props;
    const { addRef, displayError, isValid } = useFormValidate();

    const [appliedProduct, setAppliedProduct] = useState([new FlexiSuggestModel()]);
    const [appliedPaging, setAppliedPaging] = useState({ ...INITPAGING });

    const [giftedProduct, setGiftedProduct] = useState([new FlexiSuggestModel()]);
    const [giftedPaging, setGiftedPaging] = useState({ ...INITPAGING });
    const [selectGiftedProduct, setSelectGiftedProduct] = useState([new FlexiComboProductGiftModel()]);

    useEffect(() => {
        getAppliedProduct({ ...appliedPaging, status: model.appliedOption });
        getGiftedProduct({ ...giftedPaging });
        setSelectGiftedProduct([]);
    }, []);

    useEffect(() => {
        if (idFlexi > 0) getId(idFlexi);
        else setModel(new FlexiComboModel());
    }, [isShowModal, idFlexi]);

    /** Lấy sản phẩm được suggest để apply flexi combo */
    const getAppliedProduct = async (paging) => {
        let [err, data] = await FlexiComboServices.GetSuggestAppliedProduct(paging);
        if (!err && data) {
            setAppliedProduct(data.listObjects);
            setAppliedPaging(data.paging);
        } else {
            setAppliedProduct([]);
            setAppliedPaging({ ...INITPAGING });
        }
    };

    /** Handle select loại hình chọn sản phẩm */
    const handleSelectAppliedProduct = (appliedOption) => {
        setModel({ ...model, appliedOption: appliedOption, idsOption: [] });
        getAppliedProduct({ ...INITPAGING, status: appliedOption });
    };

    /** Lấy sản phẩm được suggest để làm quà tặng */
    const getGiftedProduct = async (paging) => {
        let [err, data] = await FlexiComboServices.GetSuggestGiftedProduct(paging);
        if (!err && data) {
            setGiftedProduct(data.listObjects);
            setGiftedPaging(data.paging);
        } else {
            setGiftedProduct([]);
            setGiftedPaging({ ...INITPAGING });
        }
    };

    /** Save model to database */
    const handleSave = async () => {
        setIsLoading(true);
        let dto = { ...model };
        let selectedGift = [...selectGiftedProduct];
        dto.flexiComboProductGifts = selectedGift;

        let [err, data] = await FlexiComboServices.CreateUpdateFlexiCombo(dto);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSave();
        } else {
            let msg = "Lưu thất bại";
            if (err.errors) {
                msg = err.errors.reduce((prev, item, idx) => (prev += `${item.value} <br/>`), "");
            }
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
        setIsLoading(false);
    };

    /** Lấy data theo Id */
    const getId = async (id) => {
        let [err, data] = await FlexiComboServices.GetPageById(id);
        if (!err && data) {
            setModel(data);
            setSelectGiftedProduct(data.flexiComboProductGifts);
        } else {
            setModel(new FlexiComboModel());
        }
    };

    const _renderBody = () => {
        return (
            <React.Fragment>
                <div className='col-md-12 card-box p-3'>
                    <label className='font-weight-bold'>Thông tin cơ bản</label>
                    <div className='form-row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='name' className='required'>
                                    Tên FlexiCombo
                                </label>
                                <Textbox name='name' required className='form-control' value={model.name} onChanged={(name, value) => setModel({ ...model, name: value })} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='displayTime' className='required'>
                                    Thời gian hiển thị
                                </label>
                                <TFUDatetime
                                    dateFormat='dd/MM/yyyy HH:mm'
                                    showTimeSelect
                                    required
                                    ref={addRef}
                                    name='displayTime'
                                    minDate={new Date()}
                                    value={new Date(model.displayTime)}
                                    onChanged={(newValue) => setModel({ ...model, displayTime: newValue })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='startTime' className='required'>
                                    Thời gian bắt đầu chương trình
                                </label>
                                <TFUDatetime
                                    dateFormat='dd/MM/yyyy HH:mm'
                                    showTimeSelect
                                    required
                                    ref={addRef}
                                    name='startTime'
                                    minDate={new Date(model.displayTime)}
                                    value={new Date(model.startTime)}
                                    onChanged={(newValue) => setModel({ ...model, startTime: newValue })}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='endTime' className='required'>
                                    Thời gian kết thúc chương trình
                                </label>
                                <TFUDatetime
                                    dateFormat='dd/MM/yyyy HH:mm'
                                    showTimeSelect
                                    required
                                    ref={addRef}
                                    name='endTime'
                                    minDate={new Date(model.startTime)}
                                    value={new Date(model.endTime)}
                                    onChanged={(newValue) => setModel({ ...model, endTime: newValue })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='flexiComboType' className='required'>
                                    Loại FlexiCombo
                                </label>
                                <TFUSelect name='flexiComboType' value={model.flexiComboType} options={flexiComboTypeOption} placeholder='---Chọn loại FlexiCombo---' onChanged={(value) => setModel({ ...model, flexiComboType: value })} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='flexiComboValueType'>Giá trị FlexiCombo</label>
                                <TextNumber
                                    name='flexiComboValueType'
                                    required
                                    className='form-control'
                                    value={model.flexiComboValueType}
                                    onChanged={(name, value) => setModel({ ...model, flexiComboValueType: value })}
                                    suffix={model.flexiComboType == FLEXI_COMBO_TYPE.MinMoney ? "đồng" : "sản phẩm"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='flexicomboFrame'>Banner FlexiCombo</label>
                                <Image
                                    name='flexicomboFrame'
                                    image={model.flexicomboFrame}
                                    onChanged={(name, value) => {
                                        setModel({ ...model, flexicomboFrame: value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <CheckBox
                                    ref={addRef}
                                    id='isAffiliateOnly'
                                    name='isAffiliateOnly'
                                    label='Sử dụng mã để kích hoạt voucher'
                                    checked={model.isAffiliateOnly}
                                    onCheckedChange={(name, value) => {
                                        setModel({ ...model, isAffiliateOnly: value });
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='voucherCode'>Mã Voucher</label>
                                <Textbox
                                    ref={addRef}
                                    id='voucherCode'
                                    name='voucherCode'
                                    readOnly={!model.isAffiliateOnly}
                                    value={model.isAffiliateOnly ? model.voucherCode : ""}
                                    onChanged={(name, value) => {
                                        setModel({ ...model, voucherCode: value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 card-box p-3'>
                    <label className='font-weight-bold'>Chọn sản phẩm áp dụng FlexiCombo</label>
                    <div className={"flex-applied-tags-input"}>
                        <TagsInput
                            disabled
                            addKeys={[13]}
                            addOnPaste
                            inputProps={{ placeholder: "" }}
                            value={
                                appliedProduct.reduce((prev, item, idx) => {
                                    return model.idsOption.includes(item.id) ? [...prev, item.name] : [...prev];
                                }, []) || []
                            }
                        />
                    </div>
                    <ul className='nav nav-tabs tabs-bordered my-3'>
                        {Object.keys(APPLIED_OPTION_TYPE).map((key, idx) => {
                            return (
                                <li className='nav-item' key={idx}>
                                    <a
                                        className={`nav-link ${model.appliedOption == APPLIED_OPTION_TYPE[key] ? "active" : "btn-light"}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSelectAppliedProduct(APPLIED_OPTION_TYPE[key]);
                                        }}
                                    >
                                        {APPLIED_OPTION_TYPE_NAME[key]}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    <div className='flexi-applied-product'>
                        {appliedProduct && appliedProduct.length > 0 ? (
                            appliedProduct.map((item, idx) => {
                                return (
                                    <div className='flex-applied-item'>
                                        <div>
                                            <img src={item.avatar || "/images/no-image.png"} alt={item.name} />
                                        </div>
                                        <div className='flex-applied-item-name'>{item.name}</div>
                                        <button
                                            className='btn btn-custom btn-sm'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                let ids = model.idsOption;
                                                if (ids.includes(item.id)) {
                                                    let indexOf = ids.indexOf(item.id);
                                                    ids.splice(indexOf, 1);
                                                } else {
                                                    ids.push(item.id);
                                                }
                                                setModel({ ...model, idsOption: ids });
                                            }}
                                        >
                                            {model.idsOption.includes(item.id) ? "Bỏ chọn" : "Chọn"}
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <span>Không có dữ liệu</span>
                        )}
                        <RenderTableFoot paging={appliedPaging} onFilter={(paging) => getAppliedProduct(paging)} />
                    </div>
                </div>

                <div className='col-md-12 card-box p-3'>
                    <label className='font-weight-bold'>Chọn sản phẩm làm quà tặng</label>
                    <div className={"flex-applied-tags-input"}>
                        <TagsInput disabled addKeys={[13]} addOnPaste inputProps={{ placeholder: "" }} value={selectGiftedProduct && selectGiftedProduct.length > 0 ? selectGiftedProduct.map((item) => item.productVariantName) : []} />
                    </div>
                    <ul className='nav nav-tabs tabs-bordered my-3'>
                        <li className='nav-item'>
                            <a
                                className={`nav-link active `}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                Quà tặng
                            </a>
                        </li>
                    </ul>
                    <div className='flexi-applied-product'>
                        {giftedProduct && giftedProduct.length > 0 ? (
                            giftedProduct.map((item, idx) => {
                                return (
                                    <div className='flex-applied-item'>
                                        <div>
                                            <img src={item.avatar || "/images/no-image.png"} alt={item.name} />
                                        </div>
                                        <div className='flex-applied-item-name'>{item.name}</div>
                                        <button
                                            className='btn btn-custom btn-sm'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                let array = [...selectGiftedProduct];
                                                let index = array.findIndex((ele) => ele.productVariantId == item.id);
                                                if (index >= 0) {
                                                    array.splice(index, 1);
                                                    setSelectGiftedProduct(array);
                                                } else {
                                                    let addItem = new FlexiComboProductGiftModel();
                                                    addItem.productVariantId = item.id;
                                                    addItem.productVariantName = item.name;
                                                    setSelectGiftedProduct([...array, addItem]);
                                                }
                                            }}
                                        >
                                            {selectGiftedProduct.some((el) => el.productVariantId == item.id) ? "Bỏ chọn" : "Chọn"}
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <span>Không có dữ liệu</span>
                        )}
                        <RenderTableFoot paging={giftedPaging} onFilter={(paging) => getGiftedProduct(paging)} />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Loading show={isLoading} />
            <CommonModal show={isShowModal} isBusy={isLoading} busyMsg={msg} size='lg' title={model.id == 0 ? "Thêm mới" : "Cập nhập"} onSave={() => handleSave()} onClose={() => onClose()}>
                {_renderBody()}
            </CommonModal>
        </React.Fragment>
    );
}

FlexiComboModal.propTypes = {};

export default FlexiComboModal;

function RenderTableFoot(props) {
    function handleChangePage(page) {
        let newPaging = {
            ...props.paging,
            pageNumber: page,
        };
        props.onFilter(newPaging);
    }

    function handleChangePageSize(pageSize) {
        let newPaging = {
            ...props.paging,
            pageNumber: 1,
            pageSize: pageSize,
        };
        props.onFilter(newPaging);
    }

    let totalRecord = props.paging.totalRecord,
        pageTotal = totalRecord % props.paging.pageSize === 0 ? totalRecord / props.paging.pageSize : (totalRecord - (totalRecord % props.paging.pageSize)) / props.paging.pageSize + 1;

    let from = (props.paging.pageNumber - 1) * props.paging.pageSize + 1;
    let to = props.paging.pageSize * props.paging.pageNumber;

    if (totalRecord == 0) {
        from = 0;
        to = 0;
    } else if (totalRecord < props.paging.pageSize * props.paging.pageNumber) {
        to = totalRecord;
    }
    return (
        <table className='table table-bordered m-b-0'>
            <tfoot>
                <tr>
                    <td>
                        <div className='form-inline'>
                            <ul className='pagination pagination-split footable-pagination m-b-0'>
                                {props.paging.pageNumber > 1 &&
                                    (props.paging.pageNumber === 2 ? (
                                        <li onClick={() => handleChangePage(1)}>
                                            <a className='cursor-pointer'>1</a>
                                        </li>
                                    ) : (
                                        [
                                            <li onClick={() => handleChangePage(1)} title={1} key={0}>
                                                <a className='cursor-pointer'>«</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(props.paging.pageNumber - 1)} title={props.paging.pageNumber - 1} key={1}>
                                                <a className='cursor-pointer'>‹</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(props.paging.pageNumber - 1)} key={2}>
                                                <a className='cursor-pointer'>{props.paging.pageNumber - 1}</a>
                                            </li>,
                                        ]
                                    ))}
                                <li className='active'>
                                    <a className='cursor-pointer'>{props.paging.pageNumber}</a>
                                </li>
                                {pageTotal > props.paging.pageNumber &&
                                    (pageTotal === props.paging.pageNumber + 1 ? (
                                        <li onClick={() => handleChangePage(props.paging.pageNumber + 1)}>
                                            <a className='cursor-pointer'>{props.paging.pageNumber + 1}</a>
                                        </li>
                                    ) : (
                                        [
                                            <li onClick={() => handleChangePage(props.paging.pageNumber + 1)} key={0}>
                                                <a className='cursor-pointer'>{props.paging.pageNumber + 1}</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(props.paging.pageNumber + 1)} title={props.paging.pageNumber + 1} key={1}>
                                                <a className='cursor-pointer'>›</a>
                                            </li>,
                                            <li onClick={() => handleChangePage(pageTotal)} title={pageTotal} key={2}>
                                                <a className='cursor-pointer'>»</a>
                                            </li>,
                                        ]
                                    ))}
                            </ul>
                            <span className='table-record'>
                                Hiển thị từ {from} đến {to} của {totalRecord} bản ghi
                            </span>
                            <label className={"form-inline " + props.footerClassName}>
                                Hiển thị&nbsp;
                                <select className={props.footerClassName ? props.footerClassName : "form-control h-30px"} value={props.paging.pageSize} onChange={(event) => handleChangePageSize(event.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                                &nbsp;bản ghi
                            </label>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}
