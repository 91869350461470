import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import BaseServices from "../../based/services/BaseServices";
import { ConfirmDialog } from "../../based/Dialog";
import { Notify } from "../../based/Notify";
import { CONSTANTS, NOTIFY } from "../../based/Constants";
import Breadcrumbs from "../../based/Breadcrumbs";
import Loading from "../../based/Loading";
import CommonTable from "../../based/CommonTable";
import { useHistory } from "react-router-dom";
import useFormValidate from "../../hooks/useFormValidate";
const INIT_PAGING = {
    pageSize: 50,
    pageNumber: 1,
};
const UVPrintingTableManagement = ({ match }) => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState("");
    const [printingTables, setPrintingTables] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [paging, setPaging] = useState(INIT_PAGING);
    const { addRef, isValid, displayError } = useFormValidate();
    const history = useHistory();
    useEffect(() => {
        _fetchData();
    }, []);
    const _fetchData = async () => {
        setBusy(true);
        setBusyMsg("Loading...");
        const [err, data] = await BaseServices.Post("/api/uvprint/get-printing-tables", paging);
        if (!err) {
            setPrintingTables(data.listObjects);
            setPaging(data.paging);
        } else {
            setPrintingTables([]);
        }
        setBusy(false);
        setBusyMsg("");
    };
    const _handleAdd = async () => {
        history.push("/uv-printing-table-detail/new");
    };
    const _handleEdit = (gr) => {
        history.push("/uv-printing-table-detail/" + gr.id);
    };
    const _handleDelete = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                setBusyMsg("Đang xóa dữ liệu...");
                const [err, data] = await BaseServices.Post("/api/uvprint/delete-printing-table/" + id);
                if (err) {
                    setBusy(false);
                    setBusyMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                } else {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    setBusy(false);
                    setBusyMsg(null);
                    _fetchData();
                }
            });
        });
    };

    const _renderAction = () => {
        return (
            <div className="flex-row m-b-10">
                <button className="btn btn-custom btn-sm" onClick={_handleAdd}>
                    Thêm mới
                </button>
            </div>
        );
    };
    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Tên bàn gá</th>
                    <th>Tên máy in</th>
                    <th>Sản phẩm có thể in</th>
                    <th>Số vị trí có thể in</th>
                    <th>In phẳng</th>
                    <th>In xoay</th>
                    <th></th>
                </tr>
            </thead>
        );
    };
    const _renderBody = () => {
        return (
            <tbody>
                {printingTables && printingTables.length > 0 ? (
                    printingTables.map((g, i) => {
                        let products = [];
                        if (g.slots) {
                            g.slots.map((m) => {
                                if (m.products) {
                                    let prds = m.products.map((n) => n.productName);
                                    products = products.concat(prds);
                                }
                            });
                            products = [...new Set(products)];
                        }
                        return (
                            <tr key={i}>
                                <td width={50}>{g.id}</td>
                                <td>{g.name}</td>
                                <td>{g.printer ? g.printer.name : ""}</td>
                                <td>{products.map((m) => m)}</td>
                                <td>{g.slots ? g.slots.length : 0}</td>
                                <td>{g.printer ? `${g.printer.width}mm x ${g.printer.height}mm` : ""}</td>
                                <td>{g.printer ? `${g.printer.length}mm` : ""}</td>
                                <td width={130}>
                                    <button
                                        className="btn btn-sm btn-custom m-1"
                                        onClick={() => {
                                            history.push("/uv-printing-table-cal/" + g.id);
                                        }}
                                    >
                                        Cấu hình Cal
                                    </button>
                                    <button className="btn btn-sm btn-custom m-1" onClick={() => _handleEdit(g)}>
                                        Sửa
                                    </button>
                                    <button className="btn btn-sm btn-danger m-1" onClick={() => _handleDelete(g.id)}>
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={8}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    };
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý bàn gá" parentPage="Ranus" hrefParent="/" />
            <Loading show={busy} msg={busyMsg} />
            {printingTables && <CommonTable data={printingTables} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} searchBasic onFilter={(paging) => _fetchData(paging)} />}
        </React.Fragment>
    );
};

export default UVPrintingTableManagement;
