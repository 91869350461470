import BaseServices from "./BaseServices";

const AudioServices = {
    CreateUpdateAudio: async (model) => {
        return await BaseServices.Post("/api/Audio/create-update-audio", model);
    },
    DeleteAudio: async (ids) => {
        return await BaseServices.Post("/api/Audio/delete-audio", ids);
    },
    GetAudioByPage: async (model) => {
        return await BaseServices.Post("/api/Audio/get-audio-by-page", model);
    },
    GetAudioById: async (id) => {
        return await BaseServices.Get(`/api/Audio/get-audio-byId/${id}`);
    },
    GetAudioByPageNotConnection: async (model) => {
        return await BaseServices.Post("/api/Audio/get-audio-by-page-not-connection", model);
    },
};

export default AudioServices;

