import BaseServices from "./BaseServices";

const SortServices = {
    CreateUpdate: async (model) => {
        return await BaseServices.Post("/api/Sort/create-update-area-shelf-slot", model);
    },
    GetArea: async () => {
        return await BaseServices.Get(`/api/Sort/get-area-create-update-model`);
    },
    DeleteArea: async (ids) => {
        return await BaseServices.Post(`/api/Sort/delete-area`,ids);
    },
    GetShelvesByArea: async (areaId) => {
        return await BaseServices.Get(`/api/Sort/get-shelves/${areaId}`);
    },
    GetSlotsByShelf: async (shelfId) => {
        return await BaseServices.Get(`/api/Sort/get-slots/${shelfId}`);
    },
    GetOrderProducts: async (orderId) => {
        return await BaseServices.Get(`/api/Sort/get-operator-product-by-order/${orderId}`);
    },
    CancelSlot: async (slotId) => {
        return await BaseServices.Post(`/api/Sort/"cancel-slot/${slotId}`);
    },
    
};

export default SortServices;