import BaseServices from "./BaseServices";

const ShippingServices = {
    GetOrderShippingDetail: async (agencyOrderId, shippingServiceId) => {
        return await BaseServices.Get(`/api/shipping/get-order-shipping-detail-by-id/${agencyOrderId}/${shippingServiceId}`);
    },
    GetStoresShipping: async (offSet, limit, shippingServiceId) => {
        return await BaseServices.Get(`/api/shipping/get-stores-shipping-dropdown/${offSet}/${limit}/${shippingServiceId}`);
    },
    GetProvinceGHN: async () => {
        return await BaseServices.Get(`/api/shipping/get-province-ghn`);
    },
    GetDistrictsGHN: async (provinceId) => {
        return await BaseServices.Get(`/api/shipping/get-district-ghn/${provinceId}`);
    },
    GetWardsGHN: async (districtId) => {
        return await BaseServices.Get(`/api/shipping/get-ward-ghn/${districtId}`);
    },
    CreateOrderShipping: async (shippingServiceId, orderParams) => {
        return await BaseServices.Post(`/api/shipping/create-order-shipping/${shippingServiceId}`, orderParams);
    },
    GetStores: async (offSet, limit, shippingServiceId) => {
        return await BaseServices.Get(`/api/shipping/get-stores/${offSet}/${limit}/${shippingServiceId}`);
    },
    GetServicesShipping: async (shippingServiceId, shopId, fromDistrict, toDistrict) => {
        return await BaseServices.Get(`/api/shipping/get-services-shipping/${shippingServiceId}/${shopId}/${fromDistrict}/${toDistrict}`);
    },
    CalculateFeeShipping: async (shippingServiceId, shopId, order) => {
        return await BaseServices.Post(`/api/shipping/calculate-fee-shipping/${shippingServiceId}/${shopId}`, order);
    },
    GetServicesWithFeeShipping: async (shippingServiceId, shopId, order) => {
        return await BaseServices.Post(`/api/shipping/get-service-with-fee-shipping/${shippingServiceId}/${shopId}`, order);
    },
    GetLocationsGHTK: async (shippingServiceId, parentId) => {
        return await BaseServices.Get(`/api/shipping/get-locations-ghtk/${shippingServiceId}/${parentId}`);
    },
    GetShippingServices: async () => {
        return await BaseServices.Get(`/api/shippingService/get-shipping-services`);
    },
    GetShippingFee: async (model) => {
        return await BaseServices.Post("/api/shipping/get-shipping-fee", model);
    },
    ConnectToVNPost: async () => {
        return await BaseServices.Get("/api/shippingService/connect-to-vnpost");
    },
    IsConnectedVNPost: async () => {
        return await BaseServices.Get("/api/shippingService/is-connect-to-vnpost");
    },
    ConnectToGrab4H: async () => {
        return await BaseServices.Get("/api/shippingService/connect-to-grab4h");
    },
    IsConnectedGrab4H: async () => {
        return await BaseServices.Get("/api/shippingService/is-connect-to-grab4h");
    },
    ConnectToGrab2H: async () => {
        return await BaseServices.Get("/api/shippingService/connect-to-grab2h");
    },
    IsConnectedGrab2H: async () => {
        return await BaseServices.Get("/api/shippingService/is-connect-to-grab2h");
    },
};

export default ShippingServices;
