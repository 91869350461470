import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY, USER_TYPE } from "../based/Constants";
import PhoneNumber from "../based/inputs/PhoneNumber";
import Textbox from "../based/inputs/Textbox";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import { RequestDomain } from "../based/RequestDomain";
import useFormValidate from "../hooks/useFormValidate";

const SUB_TAB = {
    MARKETER_PERSONAL: 1,
    MARKETER_ENTERPRISE: 2,
};

export default function Register(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [isConfirmEmail, setIsConfirmEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const isDesktop = Common.isDesktop();
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
        userType: USER_TYPE.CREATOR,
        makerterType: SUB_TAB.MARKETER_PERSONAL,
    });

    useEffect(() => {
        let paramUrl = window.location.search;
        if (paramUrl && paramUrl.length > 0 && paramUrl.indexOf("ref=")) {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var sellerId = url.searchParams.get("ref");
            var today = new Date();
            var expiresRef = new Date();
            expiresRef.setDate(today.getDate() + 30);
            cookie.save(CONSTANTS.COOKIE_USER_REFERENCE, sellerId, { expires: expiresRef });
        }
    }, []);

    function _handleSignUp() {
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang lưu dữ liệu...");
            let sellerId = cookie.load(CONSTANTS.COOKIE_USER_REFERENCE);
            Request.Post("/api/accounts/register", { ...user, UserReferencesId: sellerId ? sellerId : 0 })
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                        setMsg(null);
                        setIsConfirmEmail(true);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }

    function renderConfirmContent() {
        return (
            <section className="bg-account">
                <div className="page-header mt-5 ml-5">
                    <img alt="Lotus" src="/images/logo-lotus.png" />
                </div>
                <div className="account-content">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="col-left">
                            <img atl="lotus" src="/images/background-image.png" style={{ height: 650 }} />
                        </div>
                        <div className="col-right">
                            <div className="main-account">
                                <h2 className="text-uppercase text-center">
                                    <a href="/" className="text-success">
                                        <span>
                                            <img src="/logo.png" alt="" height="100" />
                                        </span>
                                    </a>
                                </h2>
                                <h4 className="font-weight-bold text-center text-dark mb-20">Xác thực Email</h4>

                                <div className="account-content">
                                    <p>Một email xác thực đã được gửi đến email của bạn. Vui lòng kiểm tra email và nhấp vào liên kết đi kèm để thực hiện việc xác thực!</p>
                                    <div className="form-group row text-center m-t-10">
                                        <div className="col-12">
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    props.history.push("/");
                                                }}
                                                className="btn-lotus btn-md btn-block btn-dark ml-0 waves-effect waves-light"
                                            >
                                                Trang chủ
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    function _signUpCreator() {
        return (
            <div className="form-group  row mt-5">
                <div className="col-12 col-md-6">
                    <div className="form-group  row">
                        <div className="col-6">
                            <Textbox
                                placeholder="Họ"
                                id="firstName"
                                name="firstName"
                                required
                                value={user.firstName}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                maxLength={255}
                                className="form-control"
                                onChanged={(name, value) => setUser({ ...user, firstName: value })}
                            />
                        </div>
                        <div className="col-6">
                            <Textbox
                                placeholder="Tên"
                                id="lastName"
                                name="lastName"
                                required
                                value={user.lastName}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                maxLength={255}
                                className="form-control mb-10"
                                onChanged={(name, value) => setUser({ ...user, lastName: value })}
                            />
                        </div>
                    </div>
                    <div className="form-group  row">
                        <div className="col-12">
                            <PhoneNumber placeholder="Số điện thoại" id="phoneNumber" name="phoneNumber" addRef={addRef} required value={user.phoneNumber} onChanged={(name, value) => setUser({ ...user, phoneNumber: value })} />
                        </div>
                    </div>
                    <div className="form-group  row">
                        <div className="col-12">
                            <Textbox placeholder="Email" id="email" name="email" value={user.email} required email ref={addRef} maxLength={255} className="form-control mb-10 mt-10" onChanged={(name, value) => setUser({ ...user, email: value })} />
                        </div>
                    </div>
                    <div className="form-group row ">
                        <div className="col-12 position-relative">
                            <Textbox
                                placeholder="Mật khẩu"
                                id="password"
                                name="password"
                                value={user.password}
                                required
                                password={!showPassword}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                maxLength={255}
                                minLength={6}
                                className="form-control mb-10"
                                onChanged={(name, value) => setUser({ ...user, password: value })}
                            />
                            <i onClick={() => setShowPassword(!showPassword)} className={`fa icon mr-3 ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <div className="col-12 position-relative">
                            <Textbox
                                placeholder="Nhập lại mật khẩu"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={user.confirmPassword}
                                required
                                password={!showConfirmPass}
                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                maxLength={255}
                                minLength={6}
                                className="form-control mb-10"
                                onChanged={(name, value) => setUser({ ...user, confirmPassword: value })}
                            />
                            <i onClick={() => setShowConfirmPass(!showConfirmPass)} className={`fa icon mr-3 ${showConfirmPass ? "fa-eye-slash" : "fa-eye"}`}></i>
                        </div>
                    </div>
                    <div className="form-group row ">
                        <span className="btn-lotus btn-dark ml-2" onClick={(e) => _handleSignUp()}>
                            Đăng kí
                        </span>
                    </div>
                    <div className="form-group">
                        Bằng việc đăng ký, bạn đã đồng ý với Ranus về{" "}
                        <a
                            className="text-danger cursor-pointer"
                            onClick={(e) => {
                                window.open(`${RequestDomain.HomeUrl()}/html/TermOfService.html`, "_blank");
                            }}
                        >
                            Điều khoản dịch vụ
                        </a>{" "}
                        &{" "}
                        <a
                            className="text-danger cursor-pointer"
                            onClick={(e) => {
                                window.open(`${RequestDomain.HomeUrl()}/html/PrivacyPolicy.html`, "_blank");
                            }}
                        >
                            Chính sách bảo mật
                        </a>
                    </div>
                    <div className="form-group row ml-1">
                        <span className="color_gray mr-3"> Bạn đã có tài khoản?</span>
                        <span
                            className="font-weight-bold text-dark cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                props.history.push("/login");
                            }}
                        >
                            Đăng nhập
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    function _renderSignUp() {
        return (
            <section className="bg-account">
                <div className="signup container align-items-center justify-content-center d-flex">
                    <div className="main-account full-width">
                        <h4 className="font-weight-bold text-dark mb-20">Đăng ký tài khoản mới</h4>
                        <div className="d-flex align-items-center">
                            <span className="color_gray mr-3"> Bạn là</span>
                            <div onClick={() => setUser({ ...user, userType: USER_TYPE.CREATOR })} className={`${isDesktop ? "btn-lotus" : "btn-lotus-mobile"} ${user.userType == USER_TYPE.CREATOR ? "btn-dark" : "text-dark hover_gray"}`}>
                                Nhà sáng tạo
                            </div>
                        </div>
                        <div className="">{user.userType == USER_TYPE.CREATOR && _signUpCreator()}</div>
                    </div>
                </div>
            </section>
        );
    }
    return isConfirmEmail ? renderConfirmContent() : _renderSignUp();
}
