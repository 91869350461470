import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { INITPAGING } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import CommonModal from "../based/Modal";
import StockFolderServices from "../based/services/StockFolderServices";

function SelectImageModal({ isShowModal, onClose, onSave }) {
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, notExist: false });
    const [keyword, setKeyword] = useState("");
    const [timer, setTimer] = useState();
    const [selectedIds, setSelectedIds] = useState([]);
    const observer = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const [isModalLoading, setIsModalLoading] = useState(false);

    useEffect(() => {
        _getData({ ...paging });
    }, []);

    const setLastCollectionHandler = (element) => {
        if (isModalLoading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                let updatePaging = { ...paging };
                updatePaging.pageNumber += 1;
                _getData(updatePaging);
            }
        });
        if (element) observer.current.observe(element);
    };

    const _getData = async (paging) => {
        setIsModalLoading(true);
        let [err, data] = await StockFolderServices.GetListStockSetImagePagings(paging);
        if (!err && data && data.listObjects) {
            setHasMore(true);
            if (paging.pageNumber == 1) setDatas(data.listObjects);
            else setDatas([...datas, ...data.listObjects]);
            setPaging(data.paging);
        } else {
            setHasMore(false);
            setPaging(paging);
        }
        setIsModalLoading(false);
    };

    const _handleKeyword = (keyword) => {
        setKeyword(keyword);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                _getData({ ...INITPAGING, pageNumber: 1, pageSize: 20, keyword: keyword });
            }, 300)
        );
    };

    return (
        <CommonModal
            show={isShowModal}
            size="lg"
            id={Common.generateGuid()}
            isBusy={false}
            busyMsg={"Đang xử lý"}
            title={"Thêm họa tiết"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => onSave(selectedIds)}>
                        <i className="fa fa-save m-r-5" /> Chọn họa tiết
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <Wrapper>
                <div className="stock-body">
                    <div className="body-header">
                        <div className="header-left">Đã chọn {selectedIds && selectedIds.length}</div>
                        <header className="right">
                            <CheckBox
                                checked={paging.notExist}
                                name="notExist"
                                id="notExist"
                                label={"Chi tiết chưa có trong folder"}
                                className="mr-4"
                                onCheckedChange={(name, value) => {
                                    _getData({ ...INITPAGING, pageNumber: 1, pageSize: 10, notExist: !paging.notExist });
                                }}
                            />
                            <input
                                type="text"
                                value={keyword}
                                onChange={(e) => {
                                    _handleKeyword(e.target.value);
                                }}
                            />
                        </header>
                    </div>
                    <div className="body-list">
                        {datas &&
                            datas.map((item, idx) => {
                                let idChecked = selectedIds.includes(item.id);
                                return (
                                    <div
                                        className={`body-item ${idChecked ? "active" : ""}`}
                                        key={idx}
                                        onClick={(e) => {
                                            let ids = [...selectedIds];
                                            let idx = ids.findIndex((x) => x == item.id);
                                            if (idx >= 0) ids.splice(idx, 1);
                                            else ids.push(item.id);
                                            setSelectedIds(ids);
                                        }}
                                        ref={setLastCollectionHandler}
                                    >
                                        <img src={item.thumbUrl} />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

SelectImageModal.propTypes = {};

export default SelectImageModal;

const Wrapper = styled.div`
    .body-list {
        display: flex;
        flex-wrap: wrap;
    }

    .body-item {
        height: 100px;
        width: 100px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        margin: 10px;
        padding: 5px;
    }

    .body-item img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .body-item.active {
        background: #dff8f3;
        border: 1.5px solid #64c5b1;
    }

    .body-header {
        display: flex;
        justify-content: space-between;
    }

    header.right {
        display: flex;
        align-items: center;
    }
`;
