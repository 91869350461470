import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { INITPAGING, NOTIFY } from "../based/Constants";
import CrawlServices from "../based/services/CrawlServices";
import TableFooter from "../based/content/TableFooter";
import Common from "../based/Common";
import { Notify } from "../based/Notify";
import ListShopModal from "./ListShopModal";
import TagsInput from "react-tagsinput";

const CrawlKeywordModel = {
    id: 0, // long
    searchKeyword: "", // string
    compareKeyword: "", // string
    listCompareKeyword: [],
    latestOrganicCount: 0, // int
    latestNonOrganicCount: 0, // int
    lastCrawlDate: new Date(), // DateTime
    status: 0, // CrawlStatus
    isDelete: false, // boolean
    listShop: [],
    isConfigCrawl: false,
};

const CrawlStatus = {
    Prepare: 0,
    Crawling: 1,
    Completed: 2,
};

const CrawlProductInfoDTO = {
    id: 0, // long
    imageUrl: "", // string
    productName: "", // string
    salePrice: 0.0, // decimal
    sellPrice: 0.0, // decimal
    totalSell: 0, // int
    rating: 0.0, // decimal
    shopId: "", // string
    itemId: "", // string
    adsId: "", // string
    createdDate: new Date(), // DateTime
    linkId: "", // string
    linkObject: "Shop", // LinkObject (enum, initialized to one of the values)
};

// Enum representation in JavaScript
const LinkObject = {
    Shop: 0,
    Keyword: 1,
};

function CrawlKeyword(props) {
    const [keywordId, setKeywordId] = useState(0);
    return (
        <Wrapper>
            <div className="body-page">
                <KeywordPage
                    keywordId={keywordId}
                    setKeywordId={(value) => {
                        setKeywordId(value);
                    }}
                />
                <ProductPage keywordId={keywordId} />
            </div>
        </Wrapper>
    );
}

export default CrawlKeyword;

function KeywordPage({ keywordId, setKeywordId }) {
    const [listModel, setListModel] = useState([CrawlKeywordModel]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
    const [show, setShow] = useState(false);
    const [listShopId, setListShopId] = useState([]);
    const [modelIndex, setModelIndex] = useState(-1);

    console.log("show", show);

    useLayoutEffect(() => {
        setListModel([]);
    }, []);

    useEffect(() => {
        getData(paging);
    }, []);

    const getData = async (page) => {
        const [err, data] = await CrawlServices.GetCrawlKeywords({ ...paging });
        if (!err && data) {
            setListModel(data.listObjects);
            setPaging(data.paging);
        } else {
            setListModel([]);
            setPaging(page);
        }
    };

    const removeRow = async (id) => {
        const [err, data] = await CrawlServices.RemoveKeyword(id);
        if (!err) {
            getData(paging);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    };

    const createUpdateRow = async (model) => {
        const [err, data] = await CrawlServices.CreateUpdateKeyword(model);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            getData(paging);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    return (
        <div className="keyword-page">
            <div className="list-action">
                <button
                    className="btn btn-custom m-1"
                    onClick={(e) => {
                        let lst = [...listModel];
                        lst.push({ ...CrawlKeywordModel });
                        setListModel(lst);
                    }}
                >
                    Thêm dòng
                </button>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Config Crawl</th>
                        <th>STT</th>
                        <th>Từ khóa tìm kiếm</th>
                        <th>Từ khóa kiểm tra</th>
                        <th>Shop</th>
                        <th>Vị trí cuối cùng của từ khóa chính xác</th>
                        <th>Ngày crawl gần nhất</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {listModel && listModel.length > 0 ? (
                        listModel.map((item, idx) => {
                            return (
                                <tr
                                    key={idx}
                                    className={item.id == keywordId ? "active" : ""}
                                    onClick={(e) => {
                                        setKeywordId(item.id);
                                    }}
                                >
                                    <td className="center">
                                        <input
                                            type="checkbox"
                                            checked={item.isConfigCrawl}
                                            onChange={(e) => {
                                                let lstModel = [...listModel];
                                                let item = lstModel[idx];
                                                item.isConfigCrawl = e.target.checked;
                                                setListModel(lstModel);
                                            }}
                                        />
                                    </td>
                                    <td className="center">{idx + 1}</td>
                                    <td className="center">
                                        <TagsInput
                                            addKeys={[13]}
                                            addOnPaste
                                            maxTags={10}
                                            inputProps={{ placeholder: "" }}
                                            value={item.searchKeyword ? [item.searchKeyword] : []}
                                            onChange={(tags) => {
                                                let lstModel = [...listModel];
                                                let item = lstModel[idx];
                                                item.searchKeyword = tags[tags.length - 1];
                                                setListModel(lstModel);
                                            }}
                                        />
                                    </td>
                                    <td className="center">
                                        <TagsInput
                                            addKeys={[13]}
                                            addOnPaste
                                            maxTags={10}
                                            inputProps={{ placeholder: "" }}
                                            value={item.listCompareKeyword ? item.listCompareKeyword : []}
                                            onChange={(tags) => {
                                                let lstModel = [...listModel];
                                                let item = lstModel[idx];
                                                item.listCompareKeyword = tags;
                                                setListModel(lstModel);
                                            }}
                                        />
                                    </td>
                                    <td className="center">
                                        <div className="list-shop">
                                            {item.listShop &&
                                                item.listShop.map((shop, index) => {
                                                    return <span key={index}>{shop.shopName}</span>;
                                                })}
                                            <span
                                                className="add-shop cursor-pointer"
                                                onClick={(e) => {
                                                    setShow(true);
                                                    setListShopId(item.listShop || []);
                                                    setModelIndex(idx);
                                                }}
                                            >
                                                + Thêm shop
                                            </span>
                                        </div>
                                    </td>
                                    <td className="center">
                                        <div className="">Tính cả organic: {item.latestOrganicCount}</div>
                                        <div className="">Không tính organic: {item.latestNonOrganicCount}</div>
                                    </td>
                                    <td className="center">{item.lastCrawlDate && Common.formatDate(new Date(item.lastCrawlDate))}</td>
                                    <td className="center">
                                        <div className="list-action-row">
                                            <button
                                                className="btn btn-secondary btn-sm m-1"
                                                onClick={(e) => {
                                                    createUpdateRow(item);
                                                }}
                                            >
                                                Lưu
                                            </button>
                                            <button
                                                className="btn btn-danger btn-sm m-1"
                                                onClick={(e) => {
                                                    removeRow(item.id);
                                                }}
                                            >
                                                Xóa
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={999}>Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <TableFooter paging={paging} onFilter={(page) => getData(page)} />
            <ListShopModal
                show={show}
                onClose={() => setShow(false)}
                listShopId={listShopId}
                setListShopId={setListShopId}
                onSave={() => {
                    let lstModel = [...listModel];
                    lstModel[modelIndex].listShop = listShopId;
                    setListModel(lstModel);
                    setShow(false);
                    setListShopId([]);
                    setModelIndex(-1);
                }}
            />
        </div>
    );
}

function ProductPage({ keywordId }) {
    const [listProduct, setListProduct] = useState([CrawlProductInfoDTO]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 100 });

    useLayoutEffect(() => {
        setListProduct([]);
    }, []);

    useEffect(() => {
        if (keywordId > 0) {
            getData(paging);
        } else {
            setListProduct([]);
        }
    }, [keywordId]);

    const getData = async (page) => {
        const [err, data] = await CrawlServices.GetCrawlProductInfosByKeyword({ ...page, linkShopId: keywordId, createdDate: new Date() });
        if (!err && data) {
            setListProduct(data.listObjects);
            setPaging(data.paging);
        } else {
            setListProduct([]);
            setPaging(page);
        }
    };

    return (
        <div className="product-page">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Hình</th>
                        <th>Tên SP</th>
                        <th>Shop</th>
                        <th>Vị trí</th>
                    </tr>
                </thead>
                <tbody>
                    {listProduct && listProduct.length > 0 ? (
                        listProduct.map((item, idx) => {
                            return (
                                <tr className={`center ${item.isActive ? "active" : ""}`}>
                                    <td
                                        className={`center`}
                                        style={{
                                            width: 50,
                                        }}
                                    >
                                        {idx + 1}
                                    </td>
                                    <td className="center">{item.imageUrl && <img src={item.imageUrl} />}</td>
                                    <td className="center">{item.productName}</td>
                                    <td className="center">
                                        {item.shopName} ({item.shopId}){" "}
                                    </td>
                                    <td
                                        className="center"
                                        style={{
                                            width: 150,
                                        }}
                                    >
                                        <div>Tính cả ad: {item.adCount}</div>
                                        <div>Non ad: {item.notAdCount}</div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="center" colSpan={999}>
                                Không có dữ liệu
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <TableFooter paging={paging} onFilter={(page) => getData(page)} />
        </div>
    );
}

const Wrapper = styled.div`
    .product-page img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    tr.active {
        background: #dfffe0;
    }
    td.active {
        background: #dfffe0;
    }
    span.add-shop.cursor-pointer {
        color: #64c5b1;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .body-page {
        display: flex;
    }

    .keyword-page {
        width: 60%;
        background: white;
        padding: 18px;
    }

    .product-page {
        flex: 1;
        background: white;
        margin-left: 20px;
        padding: 18px;
    }

    .keyword-page thead {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
    }

    .keyword-page thead th {
        text-align: center;
        vertical-align: middle;
    }

    .product-page thead {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
    }

    .product-page thead th {
        text-align: center;
        vertical-align: middle;
    }

    .list-shop {
        display: flex;
        flex-direction: column;
    }
`;
