import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import {BANNER_POSITION , BANNER_PAGE , BANNER_TYPE} from "../banner/BannerManagement";
import { Wapper} from "../banner/BannerTrademarkManagement";
import Loading from "../based/Loading";
import AppBannerServices from "../based/services/AppBannerServices";
import MarketingServices from "../based/services/MarketingServices";
import ModalChooseImage from "./modals/ModalChooseImage";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";




function MarketingBannerManagement({campaignId}) {
    const [isLoading, setIsLoading] = useState(false);
    const [dataBanner, setDataBanner]= useState([])

    useEffect(() =>{
        getData()
    },[])

    async function getData() {
        setIsLoading(true);
        let [err, data] = await MarketingServices.GetBannerByCampaign(campaignId, false);
        if (!err && data) {
            setDataBanner(data);
            setIsLoading(false);
        } else {
            setDataBanner([]);
            setIsLoading(false);
        }
    }

    const createBanner = async(position, type)=>{
        setIsLoading(true)
        let [err, data] = await MarketingServices.CreateUpdateBanner({ 
            id: 0,
            redirectUrl: "",
            imageUrl: "",
            bannerType: type,
            position: position,
            bannerIndex: 0,
            marketingCampaignId: campaignId
        });
        if (!err && data) {
            getData()
            setIsLoading(false)
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsLoading(false)
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    const renderBanner = (position , type) =>{
        return dataBanner && dataBanner.length > 0 && dataBanner.map((item, idx)=>{
            if(item.position == position && item.bannerType == type){
                return (
                    <div key={idx} className="banner-col col-33">
                        <BannerItem campaignId={campaignId} item={item} idx={idx} getData={getData} setIsLoading={setIsLoading} isShowText={true} />
                    </div>
                )
            }
        })
    }


    return (
        <Wapper>
            <Loading show={isLoading} />
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">BANNER DESKTOP</div>
                    <div className="mb-2">Size: chưa có</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.HEADER_BANNER, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-33">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.HEADER_BANNER, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-banner-item">
                    <div className="title">BANNER MOBILE</div>
                    <div className="mb-2">Size: chưa có</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.HEADER_BANNER, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-33">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.HEADER_BANNER, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wapper>
    )
}

export default MarketingBannerManagement;


function BannerItem({item, idx, getData , setIsLoading , isShowText, campaignId}){
    const [dataItem, setDataItem] = useState({...item})
    const [isModal , setIsModal] = useState(false);
    const [image , setImage] = useState("");
    const BANNER = 0

    useEffect(()=>{
        setDataItem({...item})
    },[item])
    function CheckDate(){
        if(item.startDate && item.endDate){
            return true
        }else
            return false
    }

    const removeBanner = async () =>{
        ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chán muốn xóa banner này?", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                let [error, data] = await MarketingServices.DeleteBanner(item.id);
                if (!error && data) {
                    setIsLoading(false);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getData();
                } else {
                    setIsLoading(false);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }


    const handleEdit = async(banner) => {
        setIsLoading(true)
        let [err, data] = await MarketingServices.CreateUpdateBanner({ 
            ...banner,
        });
        if (!err && data) {
            getData()
            setIsLoading(false)
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsLoading(false)
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    
    const handleBanner = async (image) => {
        setIsModal(false)
        let [err, dt] = await MarketingServices.DrawImage({
            imageUrl: image , imageType: BANNER, campaignId: campaignId, bannerId: dataItem.id
        });
        if (!err && dt && dt.imageUrl) {
            handleEdit({...item, imageUrl: dt.imageUrl})
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại xử lý ảnh")
        }
       
    }


   

    const isDate = ()=>{
        if(dataItem.startDate && dataItem.endDate ){
            let start = new Date(dataItem.startDate);
            let end = new Date(dataItem.endDate);
            let now = new Date()
            now.setHours(0)
            now.setMinutes(0)
            now.setSeconds(0)
            now.setMilliseconds(0)
            if( start >= now && start <= end)
                return true
            else{
                Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Ngày bắt đầu kể từ hôm nay và trước hoặc bằng ngày kết thúc");
                return false
            }
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Vui lòng chọn ngày");
            return false
        }
    }

    const formatDate = (time) =>{
        if(time){
            var newDate = new Date(time);
            var date = newDate.getDate();
            var month = newDate.getMonth() + 1; 
            var year = newDate.getFullYear();
            if (date < 10) {
            date = '0' + date;
            }
            if (month < 10) {
            month = '0' + month;
            }
            return year + '-' + month + '-' + date;
        }
        return null
        
    }
    return (
        <div className="banner-item">
            {isModal && <ModalChooseImage  tabImage={BANNER} onContinue={handleBanner} setImage={setImage} isShow={isModal} onClose={()=>{setIsModal(false)}} />}
            <div className="remove" onClick={()=>{
                removeBanner()
            }}>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1L1 12" stroke="#F96A74" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M12 12L1 0.999999" stroke="#F96A74" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
            </div>
            <label htmlFor={`choose-img-${idx}`} className="box-image">
                {item.imageUrl && <img src={item.imageUrl}/>}
                <button id={`choose-img-${idx}`} 
                    className={item.imageUrl ? "input-img hidden" : "btn btn-custom input-img"} 
                    onClick={()=>{
                        setIsModal(true)
                    }}
                 >Chọn ảnh</button>
            </label>
            
            {isShowText &&
            <div className="box-text">
            <h4>Thời gian xuất hiện </h4>
            <div className="box-time">
                <div className="wbox20">
                    <CheckBox id={`is-infinity-${idx}`} name={`is-infinity-${idx}`} checked={!CheckDate()} label=' ' onCheckedChange={(name, value) => {
                        setDataItem({...dataItem, startDate: null, endDate: null})
                        handleEdit({...item,
                             startDate: null,
                             endDate: null,
                        })
                    }} />
                </div>
                <span className="wbox80"><strong>Không giới hạn</strong></span>
            </div>
            <div className="box-time">
                <div className="wbox20">
                    <CheckBox id={`is-no-infinity-${idx}`} name={`is-no-infinity-${idx}`} checked={CheckDate()} label=' ' onCheckedChange={(name, value) => {
                        if(isDate()){
                            handleEdit({...item, startDate: dataItem.startDate, endDate: dataItem.endDate})
                        }
                    }} />
                </div>
                <div className="wbox80">
                    <span className="d-flex">
                        <label>Từ </label>
                        <input type="date" name="start-date" value={dataItem.startDate ? formatDate(dataItem.startDate): ""} onChange={(e)=>{
                            setDataItem({...dataItem, startDate: e.target.value})
                        }} />
                    </span>
                    <span className="d-flex">
                        <label>Đến</label>
                        <input type="date" name="end-date" value={dataItem.endDate ?formatDate(dataItem.endDate) : ''} 
                        onChange={(e)=>{
                            setDataItem({...dataItem, endDate: e.target.value})
                        }}/>
                    </span>
                </div>
            </div>
            <div>
                <h4>Link {"(enter để thay đổi)"}</h4>
                <input type="text" value={dataItem.redirectUrl} onChange={(e)=>{
                    setDataItem({...dataItem, redirectUrl: e.target.value})
                }} onKeyPress={(e)=>{
                    if(e.charCode === 13){
                        handleEdit({...item, redirectUrl: e.target.value})
                    }
                }} />
            </div>
        </div>
            }
        </div> 
    )
}