import React from "react";
import ReactDOM from "react-dom";
import { Notification } from "react-pnotify";
/**
 * Notify to screen
 * @param {number} type The Type of notify (see NOTIFY Constants to get more detail)
 * @param {string} title The caption message.
 * @param {string} message The message.
 * @param {number} delay The second of visible time. Default value is 2s
 */
var Notify = function (type, title, message, delay = 1) {
    let modelElement = document.getElementById("notify-dialog");
    if (!modelElement) {
        modelElement = document.createElement("div");
        modelElement.setAttribute("id", "notify-dialog");
    }
    ReactDOM.render(<Notification type={type} title={title} text={message} animateIn="fadeInDown" animateOut="fadeOutUp" delay={delay * 1000} shadow={false} hide={true} nonblock={false} desktop={false} />, modelElement);
};
export { Notify };
