import BaseServices from "./BaseServices";

const VoucherServices = {
    // Voucher Brand
    CreateUpdateVoucherBrandProduct: async (model) => {
        return await BaseServices.Post("/api/Voucher/create-update-voucher-brand-product", model);
    },
    CreateUpdateVoucherLightBrand: async (model) => {
        return await BaseServices.Post("/api/Voucher/create-update-voucher-light-brand", model);
    },
    GetVoucherBrandProductById: async (voucherId, productId) => {
        return await BaseServices.Get(`/api/Voucher/get-voucher-brand-product-by-id/${voucherId}/${productId}`);
    },
    GetVoucherBrandDetailById: async (voucherId) => {
        return await BaseServices.Get(`/api/Voucher/get-voucher-brand-detail-by-id/${voucherId}`);
    },
    GetVoucherBrandByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Voucher/get-voucher-brand-by-paging`, paging);
    },
    GetVoucherBrandProductByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Voucher/get-voucher-brand-product-by-paging`, paging);
    },
    DeleteVoucherBrandById: async (voucherId) => {
        return await BaseServices.Get(`/api/Voucher/delete-voucher-brand-by-id/${voucherId}`);
    },
    DeleteVoucherBrandProductById: async (voucherId, productId) => {
        return await BaseServices.Get(`/api/Voucher/delete-voucher-brand-product-by-id/${voucherId}/${productId}`);
    },

    // Voucher
    GetVoucherById: async (voucherId) => {
        return await BaseServices.Get(`/api/Voucher/get-voucher-by-id/${voucherId}`);
    },
    AddOrUpdateVoucher: async (voucherData) => {
        return await BaseServices.Post("/api/Voucher/add-or-update-voucher/", voucherData);
    },
    RemoveVoucher: async (listId) => {
        return await BaseServices.Post("/api/Voucher/remove-voucher/", listId);
    },
    GetList: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-list", paging);
    },
    GetSuggestGiftedProduct: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-suggest-gifted-product", paging);
    },
    GetListVoucher: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-list-voucher", paging);
    },

    // Voucher Gift
    GetVoucherGiftPaging: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-voucher-gift-paging", paging);
    },
    GetVoucherGiftDashboard: async () => {
        return await BaseServices.Get("/api/Voucher/get-voucher-gift-dashboard");
    },
    GetVoucherGiftById: async (id) => {
        return await BaseServices.Get("/api/Voucher/get-voucher-gift-byid?id=" + id);
    },
    SaveVoucherGift: async (voucher) => {
        return await BaseServices.Post("/api/Voucher/save-voucher-gift", voucher);
    },
    RemoveVoucherGift: async (ids) => {
        return await BaseServices.Post("/api/Voucher/remove-voucher-gift", ids);
    },
    QuickSaveVoucherGift: async (voucher) => {
        return await BaseServices.Post("/api/Voucher/quick-save-voucher-gift", voucher);
    },

    // Voucher Promote
    GetVoucherPromotePaging: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-voucher-promote-paging", paging);
    },
    SaveVoucherPromote: async (voucher) => {
        return await BaseServices.Post("/api/Voucher/save-voucher-promote", voucher);
    },
    GetVoucherPromoteById: async (id) => {
        return await BaseServices.Get("/api/Voucher/get-voucher-promote-byid?id=" + id);
    },
    RemoveVoucherPromote: async (ids) => {
        return await BaseServices.Post("/api/Voucher/remove-voucher-promote", ids);
    },
    GetVoucherPromotesPagingToRegister: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-voucher-promote-paging-to-register", paging);
    },
    GetVoucherPromoteProductsPagingToRegister: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-voucher-promote-products-paging-to-register", paging);
    },
    GetIdsVoucherPromoteProductsToRegister: async (voucherId, type) => {
        return await BaseServices.Get(`/api/Voucher/get-ids-voucher-promote-products-to-register?voucherId=${voucherId}&type=${type}`);
    },
    SaveJoinVoucherPromote: async (model) => {
        return await BaseServices.Post(`/api/Voucher/save-join-voucher-promote`, model);
    },
    VoucherPromoteUnJoined: async (voucherId) => {
        return await BaseServices.Post(`/api/Voucher/unjoined-voucher-promote?voucherId=${voucherId}`, null);
    },

    // Voucher Freeship
    GetVoucherFreeShipPaging: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-voucher-freeship-paging", paging);
    },
    GetVoucherFreeShipById: async (id) => {
        return await BaseServices.Get("/api/Voucher/get-voucher-freeship-byid?id=" + id);
    },
    SaveVoucherFreeShip: async (voucher) => {
        return await BaseServices.Post("/api/Voucher/save-voucher-freeship", voucher);
    },
    RemoveVoucherFreeShip: async (ids) => {
        return await BaseServices.Post("/api/Voucher/remove-voucher-freeship", ids);
    },

    //Target Group
    CreateUpdateTargetGroup: async (model) => {
        return await BaseServices.Post(`/api/Voucher/create-update-target-group`, model);
    },
    GetTargetGroupByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Voucher/get-target-group-by-page`, paging);
    },
    GetTargetGroupDTO: async (groupId) => {
        return await BaseServices.Get(`/api/Voucher/get-target-group/${groupId}`);
    },
    DeleteTargetGroup: async (groupId) => {
        return await BaseServices.Get(`/api/Voucher/delete-target-group/${groupId}`);
    },

    //Voucher tự thiết kế
    CreateUpdateVoucherCustomize: async (model) => {
        return await BaseServices.Post(`/api/VoucherCustomize/create-update-voucher`, model);
    },
    GetVoucherCustomizeByPage: async (model) => {
        return await BaseServices.Post(`/api/VoucherCustomize/get-voucher-by-page`, model);
    },
    GetVoucherCustomizeById: async (id) => {
        return await BaseServices.Get(`/api/VoucherCustomize/get-voucher-by-id/${id}`);
    },
    DeleteVoucherCustomize: async (id) => {
        return await BaseServices.Get(`/api/VoucherCustomize/delete-voucher/${id}`);
    },
    DeleteVoucherProduct: async (id, productId) => {
        return await BaseServices.Get(`/api/VoucherCustomize/delete-voucher-product/${id}/${productId}`);
    },
};

export default VoucherServices;
