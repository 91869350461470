import React, { useState, useEffect } from 'react'
import { NOTIFY, CONSTANTS } from '../based/Constants';
import { Notify } from '../based/Notify';
import Common from '../based/Common';
import CommonModal from '../based/Modal';
import CheckBox from '../based/inputs/CheckBox';
import RoleServices from '../based/services/RoleServices';

export default function RoleMenus(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [msg, setMsg] = useState(null)
    const [menus, setMenus] = useState([])
    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        if (props.role.id > 0 && props.isShowModal)
            getMenus(props.role.id)
    }, [props.isShowModal, props.role.id])
    async function getMenus(roleId) {
        setIsLoading(true)
        setMsg('Đang tải dữ liệu...')
        const [err, data] = await RoleServices.GetMenusByRole(roleId);
        if (!err) {
            setMenus(data)
            setIsLoading(false)
            setMsg(null)
            let totalChilds = data.length - data.filter(x => x.parentId == 0).length;
            setSelectedAll(data.filter(x => x.parentId > 0 && x.checked).length == totalChilds);
        } else {
            setMenus([]);
            setSelectedAll(false)
            setIsLoading(false)
            setMsg(null)
        }
    }
    function handleInputChange(key, value) {
        let menusUp = [...menus];
        menusUp.map((item) => {
            if (item.id == key)
                item.checked = value;
        })
        setMenus(menusUp)
    }
    async function _handleSave() {
        setIsLoading(true)
        setMsg('Đang lưu dữ liệu..')
        let menuChecked = menus.filter(m => m.checked);
        let datas = [];
        menuChecked.map((menu) => {
            datas.push(menu.functionCode)
        })
        const [err, data] = await RoleServices.SaveRoleMenus({ roleId: props.role.id, menuCodes: datas });
        if (!err) {
            Notify(NOTIFY.SUCCESS, 'Thành công', 'Lưu thành công');
            setIsLoading(false)
            setMsg(null)
            props.onSaveSucceed()
        } else {
            setIsLoading(false)
            setMsg(null)
            Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
        }
    }
    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let menuUp = menus.map(item => {
            if (item.parentId == 0) item.checked = false
            else item.checked = value;
            return item;
        })
        setMenus(menuUp);
    } 
    function _renderMenu() {
        if (menus && menus.length > 0) {
            let arrMnu = [];
            let parents = menus.filter(x => x.parentId == 0)
            if (parents && parents.length > 0) {
                parents.sort(function (a, b) {
                    return menus.filter(x => x.parentId == a.id).length - menus.filter(x => x.parentId == b.id).length
                });
                parents.map((parent, p) => {
                    let menuChilds = [];
                    let childs = menus.filter(x => x.parentId == parent.id);
                    if (childs && childs.length > 0) {
                        childs.map((menu, m) => {
                            menuChilds.push(
                                <div key={m} className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <CheckBox id={menu.id.toString()} name={menu.id.toString()} label={menu.name}
                                        className='form-control'
                                        onCheckedChange={handleInputChange}
                                        checked={menu.checked} />
                                </div>
                            );
                        })
                        arrMnu.push(
                            <div className="form-group col-md-4 col-sm-4 col-xs-12">
                                <div style={{ border: '1px solid #9facb3', borderRadius: 5 }}>
                                    <span className="menu-title" key={p} style={{ marginLeft: '0px !important' }}>{parent.name}</span>
                                    {menuChilds.map((mnu, k) => <React.Fragment key={k}> {mnu}  </React.Fragment>)}
                                </div>
                            </div>
                        );
                    }
                })
            }
            return arrMnu.map((mnu, m) => <React.Fragment key={m}> {mnu}  </React.Fragment>);
        }
    }
    return (
        <CommonModal
            show={props.isShowModal}
            size='lg'
            id={Common.generateGuid()}
            isBusy={isLoading}
            busyMsg={msg}
            title={'Phân quyền cho: ' + props.role.name}
            onSave={() => _handleSave()}
            onClose={() => props.onClose()}
        >
            <div className="form-group">
                <CheckBox
                    checked={selectedAll}
                    name='select_all'
                    id='select_all'
                    label='Chọn tất cả'
                    onCheckedChange={(name, value) => _handleCheckedAll(value)}
                />
            </div>
            <div className="form-group form-row">
                {_renderMenu()}
            </div>

        </CommonModal>
    )
}