import * as signalR from '@aspnet/signalr';
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Common from '../based/Common';
import Request from '../based/Request';
import { ApplicationContext } from '../context/ApplicationContext';


export default class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownIndex: 0,
      fullName: '',
      avatar: '',
      balance: 0,
      isShowModal: false,
      notificationCount: 0,
      notifications: [],
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.userRef = React.createRef();
    this.notificationRef = React.createRef();
    this.connection = null;
  }

  componentDidMount() {
    //event
    document.addEventListener('click', this.handleDropdown.bind(this, 0));
    if (Common.isMobile()) document.body.classList.toggle('enlarged');

    let token = Common.GetToken();
    this.connection = new signalR.HubConnectionBuilder().withUrl(`${Request.API_URL}/hubs/notification?access_token=${token}`).build();
    this.connection
      .start()
      .then(() => {})
      .catch((err) => {});

    Request.Get('/api/accounts/get-user-info')
      .then((result) => {
        this.context.setApplicationInfo({ ...this.context.applicationInfo, userInfo: result.data });
        this.connection.send('subscribeRoles', result.data.roles);
      })
      .catch((err) => {})
      .catch((err) => {});

    this.connection.on('notify', (content, user) => {
      let notifications = this.state.notifications;
      if (notifications.length >= 5) notifications = notifications.slice(0, 4);
      notifications.push(<div dangerouslySetInnerHTML={{ __html: content }} />);
      this.setState({
        notificationCount: this.state.notificationCount + 1,
        notifications: notifications,
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDropdown.bind(this, 0));
    this.connection.off('notify');
  }

  handleToggleSideBar() {
    document.body.classList.toggle('enlarged');
  }

  handleDropdown(index, event) {
    if (index > 0) {
      if (index == this.state.dropdownIndex)
        this.setState({
          dropdownIndex: 0,
        });
      else
        this.setState({
          dropdownIndex: index,
        });
    } else {
      const { target } = event;
      switch (this.state.dropdownIndex) {
        case 1: //notification
          if (this.notificationRef.current && !this.notificationRef.current.contains(target)) {
            this.setState({
              dropdownIndex: 0,
            });
          }
          break;
        case 2: //user
          if (this.userRef.current && !this.userRef.current.contains(target)) {
            this.setState({
              dropdownIndex: 0,
            });
          }
          break;
      }
    }
  }

  handleLogout() {
    Common.RemoveToken();
    cookie.remove('responseAPI');
    window.location.href = '/login';
  }

  handleRenderLinks() {
    return (
      <React.Fragment>
        <Link to={`/profile`} className="dropdown-item notify-item">
          <i className="mdi mdi-account-circle" />
          <span>Thông tin cá nhân</span>
        </Link>
        <Link to={`/security`} className="dropdown-item notify-item">
          <i className="mdi mdi-lock-open" />
          <span>Đổi mật khẩu</span>
        </Link>
        <a className="dropdown-item notify-item" onClick={this.handleLogout}>
          <i className="mdi mdi-power" />
          <span>Đăng xuất</span>
        </a>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="topbar">
        <div className="topbar-left">
          <Link to={'/'} className="logo">
            <span>
              <img src="/logowhite.png" alt="" height={40} />
            </span>
            <i>
              <img src="/images/logo-r.png" alt="" height={28} />
            </i>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="list-inline float-right mb-0">
            {/* <li className="list-inline-item">
              <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect">
                <TextNumber displayType="text" price={true} className="d-inline-block badge badge-pink noti-icon-badge" isSetOnlyInputClassName value={+this.context.applicationInfo.userInfo.balance || 0} />
              </a>
            </li>
            <li className="list-inline-item dropdown notification-list">
              <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect"></a>
            </li> */}
            {/* <li className="list-inline-item dropdown notification-list">
              <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect" onClick={this.handleDropdown.bind(this, 1)} ref={this.notificationRef} data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                <i className="dripicons-bell noti-icon" />
                <span className="badge badge-pink noti-icon-badge">{this.state.notificationCount}</span>
              </a>
              <div className={`dropdown-menu dropdown-menu-right dropdown-arrow dropdown-lg ${this.state.dropdownIndex == 1 ? 'show' : ''}`} aria-labelledby="Preview">
                <div className="dropdown-item noti-title">
                  <h5>
                    <span className="badge badge-danger float-right">{this.state.notificationCount}</span>Thông báo
                  </h5>
                </div>
                {this.state.notifications && this.state.notifications.map((item, i) => <div key={i}>{item}</div>)}
                <a className="dropdown-item notify-item notify-all">View All</a>
              </div>
            </li> */}

            <li className="list-inline-item dropdown notification-list">
              <a className="nav-link dropdown-toggle waves-effect waves-light nav-user" onClick={this.handleDropdown.bind(this, 2)} ref={this.userRef} data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                <img src={this.context.applicationInfo.userInfo.avatar ? this.context.applicationInfo.userInfo.avatar : '/images/users/no-avatar.png'} id="user-avatar" alt="user" className="rounded-circle obj-fit-contain" />
              </a>
              <div className={`dropdown-menu dropdown-menu-right profile-dropdown ${this.state.dropdownIndex == 2 ? 'show' : ''}`} aria-labelledby="Preview">
                <div className="dropdown-item noti-title">
                  <h5 className="text-overflow" title={'Xin chào! ' + this.context.applicationInfo.userInfo.fullName}>
                    Xin chào! {this.context.applicationInfo.userInfo.fullName}
                  </h5>
                </div>

                {/* Links */}
                {this.handleRenderLinks()}
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-light waves-effect">
                <i className="dripicons-menu" onClick={this.handleToggleSideBar.bind(this)} />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

TopBar.contextType = ApplicationContext;
