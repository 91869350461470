import React from "react";
import { AgencyArtWorkModel } from "../models/AgencyArtWorkModel";
import { MainProductsModel, ProductItemModel, ProductVariantModel } from "../models/BaseProductModel";

export class ProductContextModel {
    constructor() {
        this.mainProduct = new MainProductsModel();
        this.setMainProduct = _setMainProduct;
        this.products = [new ProductItemModel()];
        this.setProducts = _setProducts;
        this.variants = [new ProductVariantModel()];
        this.setVariants = _setVariants;
        this.attributes = new Map();
        this.setAttributes = () => {};
        this.listArtwork = [new AgencyArtWorkModel()];
        this.setListArtwork = () => {};
        this.addRef = (ref) => {};
    }
}

/**
 * set paroduct value
 * @param {MainProductsModel} mainProduct
 */
function _setMainProduct(mainProduct) {}

/**
 * Set products of package
 * @param {ProductItemModel[]} products
 */
function _setProducts(products) {}

/**
 * Set product variants
 * @param {ProductVariantModel[]} variants
 */
function _setVariants(variants) {}

export const ProductContext = React.createContext(new ProductContextModel());
