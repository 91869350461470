import React, { useState, useEffect } from "react";
import { NOTIFY, ARTWORK_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import OperatorServices from "../based/services/OperatorServices";
import Textbox from "../based/inputs/Textbox";

export default function BatchModal(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...Common.Paging });
    const [tags, setTags] = useState([]);
    const [currentBatch, setCurrentBatch] = useState({});
    const [detail, setDetail] = useState(null);
    const { printType } = props;

    useEffect(() => {
        getDatas(paging);
    }, [props.isShowModal]);

    async function getDatas(newPaging) {
        setIsBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OperatorServices.GetBatchPaging({ ...newPaging, printType: printType });
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsBusy(false);
            setMsg("");
        } else {
            setDatas([]);
            setIsBusy(false);
            setMsg("");
            Notify(NOTIFY.WARNING, "Chú ý", error.message ? error.message : "Có lỗi trong quá trình lấy thông tin");
        }
    }
    function _handleSave() {
        if (tags && tags.length > 0) {
            props.onSave(tags);
        } else Notify(NOTIFY.WARNING, "Chú ý", "Chưa chọn Tag");
    }
    function _renderBatchs() {
        return (
            <React.Fragment>
                <div className="position-relative mb-20">
                    <Textbox placeholder="Nhập tên Batch..." id="keyword" name="keyword" className="form-control" onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} />
                    <i className="fa fa-search position-absolute cursor-pointer" style={{ top: 7, right: 10 }} onClick={() => getDatas({ ...paging, pageNumber: 1 })}></i>
                </div>
                <div className="content-table table-rep-plugin full-width" id="tbl_items">
                    <table className="table table-bordered m-b-0 focus-on packing">
                        <thead>
                            <tr>
                                <th className="text-center" width="200">
                                    Batch SX
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.length > 0 ? (
                                datas.map((item, idx) => {
                                    let selected = false;
                                    if (currentBatch && item.printingBatch == currentBatch.printingBatch) selected = true;
                                    return (
                                        <tr key={idx}>
                                            <td style={{ backgroundColor: `${selected ? "#dee2e6" : ""} ` }} className="vertical-align-middle text-center cursor-pointer" onClick={() => _handleSelected(item)}>
                                                {item.printingBatch}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="100" className="text-center">
                                        <span>Không có dữ liệu</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <table className="table table-bordered m-b-0">
                        <tfoot>
                            <tr>
                                <td>
                                    <div className="form-inline">
                                        <span className="table-record"> Hiển thị&nbsp;</span>
                                        <label className="form-inline ">
                                            <select className="form-control h-30px" value={paging.pageSize} onChange={(event) => getDatas({ ...paging, pageNumber: 1, pageSize: event.target.value })}>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                            &nbsp;bản ghi
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        );
    }
    async function _handleSelected(item) {
        if (item && item.printingBatch && item.printingBatch != currentBatch.printingBatch) {
            setCurrentBatch(item);
            setIsBusy(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await OperatorServices.PrintTagPetPaging(1, 9999999, 0, 0, item.printingBatch);
            if (!error && data) {
                let tagsUp = tags && tags.length ? [...tags] : [];
                if (data.listObjects && data.listObjects.length > 0) {
                    data.listObjects.map((x) => tagsUp.push(x));
                }
                setTags(tagsUp);
                setIsBusy(false);
                setMsg("");
            } else {
                setDatas([]);
                setIsBusy(false);
                setMsg("");
                Notify(NOTIFY.WARNING, "Chú ý", error.message ? error.message : "Có lỗi trong quá trình lấy thông tin");
            }
        }
    }
    function _renderTableTagSelected() {
        return (
            <React.Fragment>
                <div className="form-group col-md-12">
                    <button className="btn btn-default  btn-sm m-r-5">Số SP: {tags.length}</button>
                </div>
                <div className="content-table table-rep-plugin full-width" id="tbl_items">
                    <table className="table table-bordered m-b-0 focus-on packing">
                        <thead>
                            <tr>
                                <th width="50" className="text-center">
                                    No.
                                </th>
                                <th width="200" className="text-center">
                                    Tag SX
                                </th>
                                <th width="200" className="text-center">
                                    Mã đơn
                                </th>
                                <th width="100" className="text-center">
                                    Mã mặt trước
                                </th>
                                <th width="100 " className="text-center">
                                    Mã mặt sau
                                </th>
                                <th width="100 " className="text-center">
                                    Mã Logo
                                </th>
                                <th width="250" className="text-center">
                                    Tình trạng
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tags && tags.length > 0 ? (
                                tags.map((item, idx) => {
                                    const { orderTagDetail, orderTagMockup } = item;
                                    if (!orderTagDetail) return "";
                                    const { artworkMockupModels, logoMockupModel } = orderTagMockup;
                                    const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : null;
                                    const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : null;

                                    return (
                                        <tr className="cursor-pointer" key={idx} onClick={() => setDetail(item)}>
                                            <td className="vertical-align-middle text-center">{idx + 1}</td>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.tagId}</td>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.orderId}</td>
                                            <td className="vertical-align-middle text-center">{front ? front.artworkId : ""} </td>
                                            <td className="vertical-align-middle text-center">{back ? back.artworkId : ""}</td>
                                            <td className="vertical-align-middle text-center">{logoMockupModel ? logoMockupModel.brandId : ""}</td>
                                            <td className="vertical-align-middle text-center">{orderTagDetail.inlineStatusName}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="100" className="text-center">
                                        <span>Không có dữ liệu</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
    function _renderDetail() {
        if (detail) {
            const { orderTagDetail, orderTagMockup } = detail;
            if (!orderTagDetail) return "";
            const { artworkMockupModels, logoMockupModel } = orderTagMockup;
            const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : null;
            const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : null;
            return (
                <div className="content-table table-rep-plugin full-width mt-10" id="tbl_items">
                    <table className="table table-bordered m-b-0 focus-on packing">
                        <thead>
                            <tr>
                                <th width="250" className="text-center">
                                    Mặt trước
                                </th>
                                <th width="250 " className="text-center">
                                    Mặt sau
                                </th>
                                <th width="250 " className="text-center">
                                    Logo
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="vertical-align-middle text-center">{front ? front.artworkId : ""} </td>
                                <td className="vertical-align-middle text-center">{back ? back.artworkId : ""}</td>
                                <td className="vertical-align-middle text-center">{logoMockupModel ? logoMockupModel.brandId : ""}</td>
                            </tr>
                            <tr>
                                <td className="vertical-align-middle text-center">{front ? <img src={front.url} alt="front image" style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""} </td>
                                <td className="vertical-align-middle text-center">{back ? <img src={back.url} alt="back image" style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                <td className="vertical-align-middle text-center">{logoMockupModel ? <img src={logoMockupModel.url} alt="logo" style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }
    return (
        <CommonModal size="lg" show={props.isShowModal} isBusy={isBusy} busyMsg={msg} title="Chọn Batch" onSave={() => _handleSave()} onClose={() => props.onClose()}>
            <div className="col-sm-12 card-box">
                <div className="form-row m-t-20">
                    <div className="col-md-3">{_renderBatchs()}</div>
                    <div className="col-md-9">
                        {_renderTableTagSelected()}
                        {_renderDetail()}
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
