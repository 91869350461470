import React, {  useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import ModalAddOrUpdateSizeBoardDetail from "./ModalAddOrUpdateSizeBoardDetail";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};
function SizeBoardDetailItem({data ,title,setData ,getData,paging,tableRef, deleteSize,dataSizeValue ,setBusy , createOrUpdate , getById, dataDropDown}) {
    const [selectId, setSelectId] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false);

   

    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox id="select_all" name="select_all" checked={data && data.length > 0 && data.filter((x) => x.selected).length === data.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th className="cursor-pointer">Id</th>
                    <th className="cursor-pointer">Size</th>
                    <th className="cursor-pointer">Length</th>
                    <th className="cursor-pointer">Width</th>
                    <th className="cursor-pointer">Weight</th>
                    <th className="cursor-pointer">Height</th>
                    <th className="cursor-pointer">Product</th>
                </tr>
            </thead>
        );
    }
 

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                   {item.id}
                                </td>
                                <td>
                                   {item.size}
                                </td>
                                <td>
                                   {item['length']}
                                </td>
                                <td>
                                   {item.width}
                                </td>
                                <td>
                                   {item.weight}
                                </td>
                                <td>
                                  {item.height}
                                </td>
                                <td>
                                   {dataDropDown.map((itm)=>{
                                        if(itm.value === item.productId)
                                        return itm.label
                                   })}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }


    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    let [error, data] = await deleteSize(ids);
                    if (!error && data) {
                        setBusy(false);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData({...initPage});
                    } else {
                        setBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
        });
    };

    const _handleEdit = ()=>{
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        setSelectId(ids[0]);
        setIsShowModal(true);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom mb-3"
                    onClick={() => {
                        setSelectId(0);
                        setIsShowModal(true);
                    }}
                >
                    Thêm mới
                </button>
                <button className={dataChecked && dataChecked.length == 1 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom  mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length == 1 ? _handleEdit() : {})}>
                    Sửa
                </button>
                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  mb-3 ml-3" : "btn btn-danger  mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }


    return ( 
        <Wapper>
            <div className="mb-2 font-size-24px"><strong>{title}</strong></div>
            <CommonTable data={data} paging={paging} ref={tableRef} 
            headerCheckbox hideSearchBasicButton 
            renderAction={_renderAction} 
            renderHeader={_renderHeader} 
            renderBody={_renderBody} 
            onFilter={getData} />
            {isShowModal && (
                <ModalAddOrUpdateSizeBoardDetail
                    isShowModal={isShowModal}
                    id={selectId}
                    getById={getById}
                    dataSizeValue={dataSizeValue}
                    dataDropDown={dataDropDown}
                    createOrUpdate={createOrUpdate}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                />
            )}
        </Wapper>
    );
}
const Wapper = styled.div`

`
export default SizeBoardDetailItem;