import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { INITPAGING, NOTIFY } from "../based/Constants";
import CheckBox from "../based/inputs/CheckBox";
import TextArea from "../based/inputs/TextArea";
import TextEditor from "../based/inputs/TextEditor";
import TextNumber from "../based/inputs/TextNumber";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import ComboServices from "../based/services/ComboServices";
import ComboModel from "./Model/ComboModel";
import ComboSectionModel from "./Model/ComboSectionModel";
import { useHistory } from "react-router-dom";
import ComboSectionProductModel from "./Model/ComboSectionProductModel";

export default function ComboEdit(props) {
    const [model, setModel] = useState(new ComboModel());
    const [comboProductSelect, setComboProductSelect] = useState([new ComboSectionProductModel()]);
    const history = useHistory();
    const { id } = props.match.params;

    useEffect(() => {
        _handleGetData({ ...INITPAGING });
    }, []);

    useEffect(() => {
        if (id > 0) _getComboById(id);
        else setModel(new ComboModel());
    }, []);

    const _getComboById = async (id) => {
        let [err, data] = await ComboServices.GetComboById(id);
        if (!err && data) setModel(data);
        else setModel(new ComboModel());
    };

    const _handleAddSection = () => {
        let section = new ComboSectionModel();
        section.checked = true;
        let combo = { ...model };
        combo.comboSections.map((item) => (item.checked = false));
        combo.comboSections.push(section);
        setModel(combo);
        _handleGetData({ ...INITPAGING });
    };

    const _handleUpdateComboSection = (name, value, index) => {
        let combo = { ...model };
        let section = combo.comboSections[index];
        section[name] = value;
        setModel(combo);
    };

    const _handleGetData = async (paging) => {
        let [err, data] = await ComboServices.GetComboProductSelectByPage(paging);
        if (!err && data) setComboProductSelect(data.listObjects);
        else setComboProductSelect([]);
    };

    const _handleRemoveSectionProduct = (sectionIdx, productIdx) => {
        let combo = { ...model };
        let section = combo.comboSections[sectionIdx];
        let product = section.comboSectionProducts;
        product.splice(productIdx, 1);
        setModel(combo);
    };

    const _handleRemoveSection = (sectionIdx) => {
        let combo = { ...model };
        let section = combo.comboSections;
        section.splice(sectionIdx, 1);
        setModel(combo);
    };

    const _handleAddProductToSection = (item) => {
        let combo = { ...model };
        let section = combo.comboSections.find((x) => x.checked);
        if (!section || !section.comboSectionProducts) return;
        let some = section.comboSectionProducts.some((ele) => ele.agencyProductId == item.id);
        if (some) return;

        section.comboSectionProducts.push({ sectionId: section.id, agencyProductId: item.id, productName: item.productName, productAvatar: item.productAvatar });
        setModel(combo);
    };

    const _handleIsActive = (item) => {
        let combo = { ...model };
        let section = combo.comboSections.find((x) => x.checked);
        let sectionProducts = section ? section.comboSectionProducts : null;
        if (!sectionProducts) return false;
        return sectionProducts.some((ele) => ele.agencyProductId == item.id);
    };

    const _handleSave = async () => {
        let [err, data] = await ComboServices.CreateUpdateCombo(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            history.push("/combo-management");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const _handleCheckSection = (idx) => {
        let combo = { ...model };
        combo.comboSections.map((item) => (item.checked = false));
        combo.comboSections[idx].checked = true;
        setModel(combo);
    };

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Combo" />
            <div className="p-10" style={{ marginBottom: "70px" }}>
                <div className="row card-box p-10">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="col-form-label required">Thumbnail (1000x1000px, 300ppi)</label>
                            <UploadImage imgSrc={model.thumbnail} onChanged={(value) => setModel({ ...model, thumbnail: value })} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <div className="label-with-count">
                                <label htmlFor="name" className="col-form-label required">
                                    Tên Combo
                                </label>
                                <sub className="float-left">{model.name.length}/120</sub>
                            </div>
                            <TextArea
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder="Tên Combo"
                                onChanged={(name, value) => {
                                    if (name.length <= 120) setModel({ ...model, name: value });
                                }}
                                rows={4}
                                value={model.name}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sellPrice required">Giá bán</label>
                            <TextNumber id="sellPrice" name="sellPrice" className="form-control" suffix="đ" min={1} required value={model.sellPrice} onChanged={(name, value) => setModel({ ...model, sellPrice: value })} />
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label required">Thời gian bắt đầu</label>
                                    <DatePicker
                                        onChange={(value) => {
                                            setModel({ ...model, fromDate: new Date(value) });
                                        }}
                                        selected={new Date(model.fromDate)}
                                        adjustDateOnChange
                                        minDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        timeCaption="time"
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dropdownMode="select"
                                        popperPlacement="auto"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label required">Thời gian kết thúc</label>
                                    <DatePicker
                                        onChange={(value) => {
                                            console.log("object :>> ", value);
                                            setModel({ ...model, toDate: new Date(value) });
                                        }}
                                        selected={new Date(model.toDate)}
                                        adjustDateOnChange
                                        minDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        timeCaption="time"
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dropdownMode="select"
                                        popperPlacement="auto"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="content">Thông tin Combo</label>
                        <TextEditor
                            id="content"
                            name="content"
                            className="form-control"
                            onChanged={(name, value) => {
                                setModel({ ...model, content: value });
                            }}
                            html={model.content}
                        />
                    </div>
                </div>
                <label htmlFor="">Chọn sản phẩm trong Combo</label>
                <div className="form-row">
                    <div className="col-md-8">
                        {model &&
                            model.comboSections &&
                            model.comboSections.map((item, idx) => {
                                return (
                                    <div className="section-item row card-box p-10">
                                        <div
                                            className="col-md-1 p-10 cursor-pointer"
                                            onClick={(e) => {
                                                _handleCheckSection(idx);
                                            }}
                                        >
                                            <CheckBox
                                                checked={item.checked}
                                                onCheckedChange={(value) => {
                                                    _handleCheckSection(idx);
                                                }}
                                                className="form-control cursor-pointer"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="label-with-count">
                                                <label className="col-form-label">Tên nhóm sản phẩm</label>
                                                <span>{item.name.length}/80</span>
                                            </div>
                                            <TextArea
                                                className="form-control"
                                                name="section-name"
                                                value={item.name}
                                                onChanged={(name, value) => {
                                                    if (value.length <= 80) _handleUpdateComboSection("name", value, idx);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="col-form-label">Số lượng sản phẩm tối đa</label>
                                            <TextNumber
                                                className="form-control"
                                                name="section-quantity"
                                                value={item.quantity}
                                                onChanged={(name, value) => {
                                                    _handleUpdateComboSection("quantity", +value, idx);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label">Sản phẩm áp dụng {item.comboSectionProducts ? `(${item.comboSectionProducts.length})` : ""}</label>
                                            {item.comboSectionProducts &&
                                                item.comboSectionProducts.map((element, index) => {
                                                    return (
                                                        <div className="section_item" key={index}>
                                                            <div className="section_item-thumbnail">{element.productAvatar && <img src={element.productAvatar} alt="thumbnail" className="img-thumbnail" />}</div>
                                                            <div className="section_item-title">{element.productName}</div>
                                                            <div
                                                                className="section_item-remove cursor-pointer"
                                                                onClick={(e) => {
                                                                    _handleRemoveSectionProduct(idx, index);
                                                                }}
                                                            >
                                                                <i className="fa fa-times" />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className="col-md-1">
                                            <a className="float-right cursor-pointer" onClick={(e) => _handleRemoveSection(idx)}>
                                                <i className="fa fa-times " />
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}

                        <div className="section-item row">
                            <a className="btn btn-primary cursor-pointer" onClick={(e) => _handleAddSection()}>
                                Thêm sản phẩm
                            </a>
                        </div>
                    </div>
                    <div className="card-box search-item">
                        <div className="search-item-sticky">
                            <div className="section-search-header"></div>
                            {comboProductSelect &&
                                comboProductSelect.map((item, idx) => {
                                    let isActive = _handleIsActive(item);
                                    return (
                                        <div
                                            className={`section-search-item cursor-pointer ${isActive ? "active" : ""}`}
                                            key={idx}
                                            onClick={(e) => {
                                                _handleAddProductToSection(item);
                                            }}
                                        >
                                            <div className="section-search-thumbnail">
                                                <img src={item.productAvatar} alt="thumbnail" className="img-thumbnail" />
                                            </div>
                                            <div className="section-search-detail">
                                                <div className="section-search-detail-main">{item.productName}</div>
                                                <div className="section-search-detail-sub">Còn {item.productAvailable} sản phẩm</div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-bar">
                <div className="bottom-bar-container container">
                    <span>Vui lòng kiểm tra kỹ thông tin trong combo trước khi nhấn Hoàn thành</span>
                    <div
                        className="btn btn-primary cursor-pointer"
                        onClick={(e) => {
                            _handleSave();
                        }}
                    >
                        Hoàn thành Combo
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .label-with-count {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .section_item {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
    .section_item-thumbnail {
        width: 30%;
        height: auto;
        object-fit: cover;
    }
    .section_item-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #444444;
        margin-left: 10px;
    }
    .section_item-remove {
        margin-left: auto;
    }
    .section-search-header {
    }
    .section-search-item {
        display: flex;
        margin: 10px;
    }
    .section-search-item.active {
        border: 1px solid #64c5b1;
    }
    .section-search-thumbnail {
        width: 30%;
    }
    .section-search-detail {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #444444;
        margin-left: 10px;
    }
    .section-search-detail-sub {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #64c5b1;
    }
    .search-item {
        flex: 1;
        margin-left: 10px;
        overflow-y: auto;
        max-height: 80vh;
    }
    .img-thumbnail {
        border: none !important;
    }
    .bottom-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .bottom-bar-container {
        background: #343c49;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding: 20px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const WrapperUploadImage = styled.div`
    .image-container {
        width: 100%;
        aspect-ratio: 1;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    input[type="file"] {
        opacity: 0;
        position: absolute;
        z-index: -1;
        display: none;
    }
    .image-preview {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const UploadImage = ({ imgSrc, onChanged }) => {
    const handleUploadImage = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.UploadImage(file)
                .then((res) => {
                    if (res && res.data) {
                        let { imageUrl } = res.data;
                        onChanged(imageUrl);
                    }
                })
                .catch((err) => {});
        };
    };
    return (
        <React.Fragment>
            <WrapperUploadImage>
                <div className="image-container">
                    {imgSrc && (
                        <label htmlFor="upload-image-input" className="image-preview">
                            <img src={imgSrc} alt="image" className="image-preview" />
                        </label>
                    )}
                    <input
                        id={"upload-image-input"}
                        className="upload-image-input"
                        type={"file"}
                        accept="image/*"
                        onChange={(e) => {
                            handleUploadImage(e);
                        }}
                    />
                    {!imgSrc && (
                        <div>
                            <label className="btn btn-primary" htmlFor="upload-image-input">
                                Tải lên
                            </label>
                        </div>
                    )}
                </div>
            </WrapperUploadImage>
        </React.Fragment>
    );
};
