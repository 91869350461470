import React, { useState, useEffect } from "react";
import Language from "./Language";
import TFUSelect from "./inputs/TFUSelect";
import CommonServices from "./services/CommonServices";
import { TFUSubdivisionProps, TFUSubdivisionValue } from "./PropsDefined";

/**
 * tfu sub divisions
 * @param {TFUSubdivisionProps} props the props
 */
const TFUSelectSubdivisions = (props) => {
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);

    useEffect(() => {
        if (props.showProvince) GetProvinces();
    }, []);

    useEffect(() => {
        if (props.showDistrict && props.provinceCode) GetDistricts(props.provinceCode);
    }, [props.provinceCode]);

    useEffect(() => {
        if (props.showWard && props.districtCode) GetWards(props.districtCode);
    }, [props.districtCode]);

    async function GetProvinces() {
        const [err, data] = await CommonServices.GetProvinces();
        const formatedList = data && data.length > 0 ? data.map((i) => ({ value: i.id, label: i.name })) : [];
        if (!err) setProvinces(formatedList);
    }

    async function GetDistricts(provinceCode) {
        if (provinceCode) {
            const [err, data] = await CommonServices.GetDistricts(provinceCode);
            const formatedList = data && data.length > 0 ? data.map((i) => ({ value: i.id, label: i.name })) : [];
            if (!err) setDistricts(formatedList);
            else setDistricts([]);
        } else setDistricts([]);
    }

    async function GetWards(districtCode) {
        const [err, data] = await CommonServices.GetWards(districtCode);
        const formatedList = data && data.length > 0 ? data.map((i) => ({ value: i.id, label: i.name })) : [];
        if (!err) setWards(formatedList);
        else setWards([]);
    }

    function handleChangedProvince(val) {
        GetDistricts(val);
        setWards([]);
        props.onChanged(!props.returnSubdivisionsName ? new TFUSubdivisionValue(val, "", "") : new TFUSubdivisionValue(val, "", "", provinces.find((x) => x.value == val) ? provinces.find((x) => x.value == val).text : "", "", ""));
    }

    function handleChangedDistrict(val) {
        GetWards(val);
        props.onChanged(
            !props.returnSubdivisionsName
                ? new TFUSubdivisionValue(props.provinceCode, val, "")
                : new TFUSubdivisionValue(props.provinceCode, val, "", provinces.find((x) => x.value == props.provinceCode).text, districts.find((x) => x.value == val) ? districts.find((x) => x.value == val).text : "", "")
        );
    }

    function handleChangedWard(val) {
        props.onChanged(
            !props.returnSubdivisionsName
                ? new TFUSubdivisionValue(props.provinceCode, props.districtCode, val)
                : new TFUSubdivisionValue(
                      props.provinceCode,
                      props.districtCode,
                      val,
                      provinces.find((x) => x.value == props.provinceCode).text,
                      districts.find((x) => x.value == props.districtCode).text,
                      wards.find((x) => x.value == val) ? wards.find((x) => x.value == val).text : ""
                  )
        );
    }

    return (
        <div className={props.parentClassName}>
            {props.showProvince ? (
                <React.Fragment>
                    <div className={props.childClassName}>
                        <label htmlFor={props.provinceInputName ? props.provinceInputName : "provinceCode"} className={props.isRequiredProvince ? "required full-width" : ""}>
                            {Language.getValue("subdivisions.province")}
                        </label>
                        <TFUSelect
                            id={props.provinceInputName ? props.provinceInputName : "provinceCode"}
                            name={props.provinceInputName ? props.provinceInputName : "provinceCode"}
                            ref={props.addRef}
                            value={props.provinceCode}
                            required={props.isRequiredProvince}
                            options={provinces}
                            onChanged={handleChangedProvince}
                            isDisabled={props.isDisabledProvince}
                        />
                    </div>
                </React.Fragment>
            ) : null}
            {props.showDistrict ? (
                <React.Fragment>
                    <div className={props.childClassName}>
                        <label htmlFor={props.districtInputName ? props.districtInputName : "districtCode"} className={props.isRequiredDistrict ? "required full-width" : ""}>
                            {Language.getValue("subdivisions.district")}
                        </label>
                        <TFUSelect
                            id={props.districtInputName ? props.districtInputName : "districtCode"}
                            name={props.districtInputName ? props.districtInputName : "districtCode"}
                            ref={props.addRef}
                            value={props.districtCode}
                            required={props.isRequiredDistrict}
                            options={districts}
                            onChanged={handleChangedDistrict}
                            isDisabled={props.isDisabledDistrict ? true : props.provinceCode ? false : true}
                        />
                    </div>
                </React.Fragment>
            ) : null}
            {props.showWard ? (
                <React.Fragment>
                    <div className={props.childClassName}>
                        <label htmlFor={props.wardInputName ? props.wardInputName : "wardCode"} className={props.isRequiredWard ? "required full-width" : ""}>
                            {Language.getValue("subdivisions.ward")}
                        </label>
                        <TFUSelect
                            id={props.wardInputName ? props.wardInputName : "wardCode"}
                            name={props.wardInputName ? props.wardInputName : "wardCode"}
                            ref={props.addRef}
                            value={props.wardCode}
                            required={props.isRequiredWard}
                            options={wards}
                            onChanged={handleChangedWard}
                            isDisabled={props.isDisabledWard ? true : props.districtCode ? false : true}
                        />
                    </div>
                </React.Fragment>
            ) : null}
        </div>
    );
};

export default TFUSelectSubdivisions;
