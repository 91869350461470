import React, { useState, useEffect } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import CheckBox from "../based/inputs/CheckBox";
import { Notify } from "../based/Notify";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import TicketServices from "../based/services/TicketServices";
import EditTicket from "./components/EditTicket";
import SettingModal from "./components/SettingModal";


export const TICKET_STATUS = {
    OPEN:0,
    PROCESSING: 1,
    CLOSE:2
};

export const TicketModel = {
    id: 0,
    ticketName: "",
    ticketMessage: "",
    email: "",
    answer:"",
    phone:"",
    status:0,
    isActive: 0,
    isDelete: false,
    createdBy: "",
    createdDate: "",
    modifiedBy: "",
    modifiedDate: "",
};

const Ticket =()=>{
    const [isShowModal, setIsShowModal] = useState(false);
    //init ticket model
    const [ticket, setTicket] = useState({ ...TicketModel });
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    //init ticket paging
    const [paging, setPaging] = useState({ ...Common.Paging });
    const [datas, setDatas] = useState([]);
    const [settingModal, setSettingModal] = useState(false);
    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        getDatas({ ...paging });
    }, []);

    //lay data ticket
    async function getDatas(paging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TicketServices.GetTicket(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function _handleEdit() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length == 1) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await TicketServices.GetTicketById(dataChecked[0].id);
            if (!error && data) {
                setTicket(data);
                setIsShowModal(true);
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình lấy thông tin.");
            }
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn một thông tin trước khi sửa");
    }

    //table ticket
    function _renderHeader() {
        let icon = <i className='fa fa-sort fa-sort-thead'></i>;
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.isChecked).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th onClick={() => handleSortColumn("title")}>
                        Ticket Name{" "}
                        {paging.orderBy === "title" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "title" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                    </th>
                    <th className='text-center'>Email</th>
                    <th className='text-center'>Phone</th>
                    <th width='80' className='text-center'>
                        Người tạo
                    </th>
                    <th width='80' className='text-center'>
                        Thiết lập
                    </th>
                    <th width='80' className='text-center' onClick={() => handleSortColumn("status")}>
                        Trạng thái
                        {paging.orderBy === "status" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "status" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td align='center'>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.isChecked ? item.isChecked : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td width=''>
                                    <h5 className='text-ellipsis-4'>{item.ticketName} </h5>
                                </td>
                                <td>
                                    {" "}
                                    <p className='text-ellipsis-4' style={{ maxWidth: "300px" }}>
                                        {" "}
                                        {item.email}
                                    </p>
                                </td>
                                <td>
                                    {" "}
                                    <p className='text-ellipsis-4' style={{ maxWidth: "300px" }}>
                                        {" "}
                                        {item.phone}
                                    </p>
                                </td>
                                <td align='center'> {item.createdBy ? item.createdBy : "Chưa rõ"}</td>
                                <td align='center'>
                                    <i
                                        className='fa fa-cog cursor-pointer'
                                        aria-hidden='true'
                                        style={{ color: "#159a7f" }}
                                        onClick={() => {
                                            setSettingModal(true);
                                            setTicket(item);
                                        }}
                                    ></i>
                                </td>
                                <td align='center'>
                                    {item.status == TICKET_STATUS.OPEN ? <p className='text-success'>Đang Mở</p> : item.status === TICKET_STATUS.PROCESSING ? <p className='text-warning'>Đang xử lý</p> : <p className='text-danger'>Đã Đóng</p>}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='vertical-align-middle text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.isChecked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className='fa fa-remove m-r-5' />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    const handleSortColumn = (columnName) => {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        getDatas(newPaging);
    };
    async function _handleDelete() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((x) => ids.push(x.id));
                    let [err, data] = await TicketServices.DeleteTicket(ids);
                    if (!err && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn thông tin trước khi xóa");
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.isChecked = value));
        else newDatas[index].isChecked = value;
        setDatas(newDatas);
    };
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Ticket' />
            <Loading show={isLoading} msg={msg} />
            {isShowModal && (
                <EditTicket
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSuccess={() => {
                        setIsShowModal(false);
                        getDatas(paging);
                    }}
                    id={ticket.id}
                />
            )}
            {settingModal && (
                <SettingModal
                    isShowModal={settingModal}
                    ticket={ticket}
                    onClose={() => {
                        setSettingModal(false);
                    }}
                    onSuccess={() => {
                        setSettingModal(false);
                        getDatas(paging);
                    }}
                />
            )}

            <CommonTable data={datas} paging={paging} placeholderSearch='Ticket Name' renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic />
        </React.Fragment>
    );
}

export default Ticket;