import BaseServices from "./BaseServices";

const PlatenSizeServices = {
    DeleteAttributePlatenSize: async (attributeValueId) => {
        return await BaseServices.Get(`/api/AttributePlatenSize/delete-attribute-platen-size/${attributeValueId}`);
    },
    CreateUpdateAttributePlatenSize: async (model) => {
        return await BaseServices.Post("/api/AttributePlatenSize/create-update-attribute-platen-size", model);
    },
    GetAttributePlatenSizeByPage: async (model) => {
        return await BaseServices.Post(`/api/AttributePlatenSize/get-attribute-platen-size-by-page`, model);
    },
};

export default PlatenSizeServices;
