import BaseServices from "./BaseServices";

const SocialAccountServices = {
    CreateUpdateSocialAccount: async (model) => {
        return await BaseServices.Post("/api/SocialAccount/create-update-social-account", model);
    },
    DeleteSocialAccount: async (ids) => {
        return await BaseServices.Post("/api/SocialAccount/delete-social-account", ids);
    },
    GetSocialAccountByPage: async (model) => {
        return await BaseServices.Post("/api/SocialAccount/get-social-account-by-page", model);
    },
    GetSocialAccountById: async (id) => {
        return await BaseServices.Get(`/api/SocialAccount/get-social-account-byId/${id}`);
    },
};

export default SocialAccountServices;
