import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import GiftServices from "../based/services/GiftServices";
import AddOrEditModalGift from "./AddOrEditModalGift";
import AddOrEditModalGiftType from "./AddOrEditModalGiftType";

const ActiveFolderTab = {
    GiftType: "GiftType",
    Gift: "Gift",
    // GiftFolder: "GiftFolder",
};

const FOLDER_TAB = [
    { value: ActiveFolderTab.GiftType, label: "Loại quà tặng" },
    { value: ActiveFolderTab.Gift, label: "Quà tặng" },
    // { value: ActiveFolderTab.GiftFolder, label: "Thư mục quà tặng" },
];

function GiftManageTotal(props) {
    const [activeFolder, setActiveFolder] = useState(ActiveFolderTab.GiftType);

    function _renderFolderTab() {
        return FOLDER_TAB.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a className={activeFolder == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => setActiveFolder(item.value)}>
                        <span>{item.label}</span>
                    </a>
                </li>
            );
        });
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý Quà tặng" />
            <ul className="nav nav-tabs tabs-bordered">{_renderFolderTab()}</ul>
            {activeFolder == ActiveFolderTab.GiftType && <GiftTypeManage />}
            {activeFolder == ActiveFolderTab.Gift && <GiftManage />}
        </React.Fragment>
    );
}

export default GiftManageTotal;

function GiftTypeManage() {
    const [isBusy, setIsBusy] = useState(false);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 50 });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await GiftServices.GetGiftTypeByPaging(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
        }
        setIsBusy(false);
    }

    const _handleDelete = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [error, data] = await GiftServices.DeleteGiftType(id);
                if (!error && data) {
                    _getData(paging);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    reject({ title: "Thất bại", msg: "Vui lòng thử lại sau." });
                }
            });
        });
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">STT</th>
                    <th className="center">Icon</th>
                    <th className="center">Loại quà</th>
                    <th className="center">Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{item.id}</td>
                                <td>
                                    <img
                                        src={item.icon}
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                        }}
                                    />
                                </td>
                                <td>
                                    <span>{item.name}</span>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-custom m-3"
                                        onClick={(e) => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                    <button
                                        className="btn btn-danger m-3"
                                        onClick={(e) => {
                                            _handleDelete(item.id);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={300}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            {isShowModal && (
                <AddOrEditModalGiftType
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        _getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </React.Fragment>
    );
}

const GiftManage = () => {
    const [isBusy, setIsBusy] = useState(false);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 50 });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await GiftServices.GetGiftByPaging(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
        }
        setIsBusy(false);
    }

    const _handleDelete = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [error, data] = await GiftServices.DeleteGift(id);
                if (!error && data) {
                    _getData(paging);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    reject({ title: "Thất bại", msg: "Vui lòng thử lại sau." });
                }
            });
        });
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">STT</th>
                    <th className="center">Tên</th>
                    <th className="center">Icon</th>
                    <th className="center">Loại quà</th>
                    <th className="center">Số lượng</th>
                    <th className="center"></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>
                                    <img
                                        src={item.url}
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                        }}
                                    />
                                </td>
                                <td>
                                    <span>{item.giftTypeName}</span>
                                </td>
                                <td>{item.quantity}</td>
                                <td>
                                    <button
                                        className="btn btn-custom m-3"
                                        onClick={(e) => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                    <button
                                        className="btn btn-danger m-3"
                                        onClick={(e) => {
                                            _handleDelete(item.id);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={300}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            {isShowModal && (
                <AddOrEditModalGift
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        _getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </React.Fragment>
    );
};
