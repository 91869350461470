import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import ChatUserService from "../based/services/ChatUserService";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import ModalAddOrUpdate from "./ModalAddOrUpdate";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: "",
};

function ChatUserManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [user,setUser] = useState({})
    const [isBusy, setBusy] = useState(false);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const history = useHistory()
    const [isShowModal, setIsShowModal] = useState(false);
    console.log(data)

    useEffect(() => {
        getData(paging);
    }, []);


    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ChatUserService.GetChatUserDetailByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }
    function _renderBody(){
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{idx + 1}</td>
                                <td>{item.firstName}</td>
                                <td>{item.lastName}</td>
                                <td>{item.createdDate ? Common.formatDate(new Date(item.createdDate), 'fulldate'): "Chưa cập nhật"}</td>
                                <td style={{textAlign:'center'}}>
                                {!item.isActive ? <button
                                        className='btn btn-custom mr-3'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            _handleConfirm(item.id,true, "duyệt")
                                        }}
                                    >
                                        Duyệt
                                    </button>: ''}
                                {item.isActive ? <button
                                        className='btn btn-danger mr-3'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            _handleConfirm(item.id,false,"từ chối")
                                        }}
                                    >
                                        Từ chối
                                    </button>: ''}
                                </td>                               
                                <td style={{ width: 200, textAlign:'center'}}>
                                    
                                     <button
                                        className='btn btn-custom mr-3'
                                        onClick={() => {
                                            setUser(item)
                                            setIsShowModal(true)
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                    
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }
    

    const _handleConfirm = async (id,status,message) => {
        ConfirmDialog("Xác nhận?", `Bạn có chắc chắn muốn ${message} tài khoản này?`, () => {
            return new Promise(async (resolve, reject) => {
                setMsg("Đang duyệt...");
                let [error, data] = await ChatUserService.ConfirmUser(id,status);
                if (!error && data) {
                    setMsg(null);
                    resolve({ title: "Thành công", msg: `${message} thành công.` });
                    getData(paging);
                } else {
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
       
           
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th>STT</th>
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>Ngày tạo</th>
                    <th>Duyệt</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        
    }
   
    return (
        <React.Fragment>
        <Breadcrumbs currentPage='Quản lý chat bot telegram' />
        <Loading show={isBusy} msg={msg} />
        <CommonTable 
            data={data} 
            paging={paging}
            ref={tableRef}
            hideSearchBasicButton
            renderAction={_renderAction}
            renderHeader={_renderHeader}
            renderBody={_renderBody}
            />
            {isShowModal && (
                <ModalAddOrUpdate
                    isShowModal={isShowModal}
                    user={user} 
                    onClose={() => {
                        setIsShowModal(false);
                        setUser({});
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                />
            )}
    </React.Fragment>
    )
}

export default ChatUserManagement;