import React from "react";
import { useState } from "react";
import Common from "../../based/Common";
import CommonModal from "../../based/Modal";
import { TicketModel } from "../Ticket";
import TextArea from "../../based/inputs/TextArea";
import Textbox from "../../based/inputs/Textbox";
import useFormValidate from "../../hooks/useFormValidate";
import TicketServices from "../../based/services/TicketServices";
import { useEffect } from "react";
import { INITPAGING, NOTIFY } from "../../based/Constants";
import { Notify } from "../../based/Notify";

const EditTicket = (props) => {
    const [msg, setMsg] = useState("Đang tải...");
    const [isBusy, setIsBusy] = useState(false);
    const [ticket, setTicket] = useState(TicketModel);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if (props.id > 0) {
            _getTicket(props.id);
        } else {
            setTicket({ ...TicketModel });
        }
        return () => setTicket({ ...TicketModel });
    }, []);

    const _getTicket = async (id) => {
        setIsBusy(true);
        let [err, data] = await TicketServices.GetTicketById(id);
        if (!err && data) {
            setTicket(data);
            setIsBusy(false);
        } else {
            setIsBusy(false);
            setTicket({ ...TicketModel });
        }
    };
    const _handleSave = () => {
        if (isValid()) {
            _saveTicket({ ...ticket });
        }
    };
    const _saveTicket = async (ticket) => {
        let [err, data] = await TicketServices.AnswerTicket(ticket);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Thành công!.");
            props.onSuccess();
            props.onClose();
        } else {
            Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
        }
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title="Cập nhật" onSave={_handleSave} onClose={props.onClose}>
            <div className='form-group'>
                <label htmlFor='ticketName' className='required'>
                    Ticket Name:
                </label>
                <Textbox id='ticketName' name='ticketName' ref={addRef} required className='form-control' value={ticket.ticketName} onChanged={(name, value) => setTicket({ ...ticket, ticketName: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='email' className='required'>
                    Ticket Email:
                </label>
                <Textbox id='email' name='email' ref={addRef} required className='form-control' value={ticket.email} onChanged={(name, value) => setTicket({ ...ticket, email: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='phone' className='required'>
                    Ticket Phone:
                </label>
                <Textbox id='phone' name='phone' ref={addRef} required className='form-control' value={ticket.phone} onChanged={(name, value) => setTicket({ ...ticket, phone: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='title' className='required'>
                    Ticket Message:
                </label>
                <TextArea id='ticketMessage' name='ticketMessage' ref={addRef} required maxLength={512} className='form-control' value={ticket.ticketMessage} onChanged={(name, value) => setTicket({ ...ticket, ticketMessage: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='title' className='required'>
                    Answer:
                </label>
                <TextArea id='answer' name='answer' ref={addRef} required maxLength={512} className='form-control' value={ticket.answer} onChanged={(name, value) => setTicket({ ...ticket, answer: value })} />
            </div>
        </CommonModal>
    );
};

export default EditTicket;
