import BaseServices from "./BaseServices";

const PosterServices = {
    GetListPoster: async (paging) => {
        return await BaseServices.Post("/api/Collection/get-list-poster", paging);
    },
    CreateUpdatePoster: async (model) => {
        return await BaseServices.Post("/api/Collection/create-update-poster", model);
    },
    GetPosterByGroupId: async (id) => {
        return await BaseServices.Get(`/api/Collection/get-poster-by-groupId/${id}`);
    },
    GetPosterById: async (id) => {
        return await BaseServices.Get(`/api/Collection/delete-poster-by-posterId/${id}`);
    },
    DeletePosterById: async (id) => {
        return await BaseServices.Get(`/api/Collection/delete-poster-by-posterId/${id}`);
    },
    GetListPosterVariant: async (paging) => {
        return await BaseServices.Post("/api/Collection/get-list-poster-variant", paging);
    },
    GetListPosterVariantByProductId: async(id) =>{
        return await BaseServices.Get(`/api/Collection/get-list-poster-variant-by-productId/${id}`);
    },
    GetCategory: async()=>{
        return await BaseServices.Post("/api/Collection/get-poster-category");
    },
    GetMainColor: async(paging)=>{
        return await BaseServices.Post("/api/Collection/get-main-color",paging);
    },
    GetPreviewMockup: async(model)=>{
        return await BaseServices.Post("/api/Collection/preview-mockup",model);
    },
};

export default PosterServices;
