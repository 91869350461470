import ImportExcel from "../Import";
import BaseServices from "./BaseServices";

const EnhanceAccountServices = {
    CreateUpdateEnhanceAccount: async (model) => {
        return await BaseServices.Post("/api/EnhanceAccount/create-update-enhance-account", model);
    },
    GetEnhanceAccountByPage: async (model) => {
        return await BaseServices.Post("/api/EnhanceAccount/get-enhance-account-by-page", model);
    },
    DeteleEnhanceAccount: async (ids) => {
        return await BaseServices.Post("/api/EnhanceAccount/delete-enhance-account", ids);
    },
    GetEnhanceAccountById: async (id) => {
        return await BaseServices.Get(`/api/EnhanceAccount/get-enhance-account-byId/${id}`);
    },
    ImportExcel: async (data) => {
        return await BaseServices.Post(`/api/EnhanceAccount/import-excel`,data,{
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        })
    },
 
};

export default EnhanceAccountServices;

