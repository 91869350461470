import React from "react";
import { POSITION_LEVEL } from "../based/Constants";
import { PrintFrameConfigModel } from "../models/PrintFrameConfigModel";

export class PrintBrandStampModel {
    constructor() {
        let init = Object.keys({ ...POSITION_LEVEL }).map((key, index) => {
            return {
                name: key,
                value: POSITION_LEVEL[key],
                X: 0,
                Y: 0,
                Degree: 0,
            };
        });

        this.attributeValueId = 0;
        this.attributeValueName = "";
        this.positionWidth = 0;
        this.positionHeight = 0;
        this.positionData = init;

        this.imageId = 0;
        this.positionLevel = POSITION_LEVEL.A;
        this.heightCollar = 0;
        this.widthCollar = 0;
        this.axisX = 0;
        this.axisY1 = 0;
        this.axisY2 = 0;
        this.digitalScale = 50;
        this.allowRenderPDP = false;
        this.allowRenderUpload = false;
    }
}

export class SubPrintBrandStampModel {
    constructor() {
        this.attributeValueId = 0;
        this.attributeValueName = "";
        this.positionWidth = 0;
        this.positionHeight = 0;
        this.imageType = 0;
        this.logoWidth = 0;
        this.logoHeight = 0;
        this.positionData = [
            {
                name: "",
                value: 0,
                X: 0,
                Y: 0,
                Degree: 0,
            },
        ];
    }
}

export class ConfigAttributeModalModel {
    constructor() {
        this.attributeId = 0;
        this.setAttributeId = () => {};
        this.printFrame = [new PrintFrameConfigModel()];
        this.setPrintFrame = () => {};
        this.subPrintBrandStamp = [new SubPrintBrandStampModel()];
        this.setSubPrintBrandStamp = () => {};
        this.isReadOnly = false;
    }
}
export const ConfigAttributeModalContext = React.createContext(new ConfigAttributeModalModel());
