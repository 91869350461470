import React, { useEffect, useState } from 'react';
import Common from '../../based/Common';
import CommonTable from '../../based/CommonTable';
import { INITPAGING } from '../../based/Constants';
import TextNumber from '../../based/inputs/TextNumber';
import Loading from '../../based/Loading';
import BaseServices from '../../based/services/BaseServices';
function PaidLoyaltyTable(props) {
    const [isBusy, setIsBusy] = useState(false)
    const [data, setData] = useState([])
    const [paging, setPaging] = useState(INITPAGING)
    useEffect(() => {
        _fetchData();
    }, [])

    const _fetchData = async () => {
        setIsBusy(true);
        let [err, data] = await BaseServices.Post('/api/finance/get-creator-loyalty-payment/1', paging);
        setIsBusy(false);
        if (!err) {
            setData(data.listObjects);
            setPaging(data.paging)
        }
    }
    const _renderHeaderTable = () => {
        return (
            <thead>
                <tr>
                    <th></th>
                    <th>Kỳ thanh toán</th>
                    <th>Tổng tiền</th>
                    <th>Hình thức CK</th>
                    <th>Tên ngân hàng</th>
                    <th>Số tài khoản</th>
                    <th>SĐT MOMO</th>
                    <th>Nội dung CK</th>
                    <th>Mã giao dịch</th>
                    <th>Thời gian giao dịch</th>
                    {
                        !props.creator && <th>Ghi chú</th>
                    }
                </tr>
            </thead>
        )
    }
    const _renderBodyTable = () => {
        return (
            <tbody>
                {
                    data ? data.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{item.creator}</td>
                                <td>{item.recycleTime}</td>
                                <td><TextNumber value={item.totalLoyaltyPrice} displayType='text' price /></td>
                                <td>{item.paymentType == 1 ? 'CK Ngân hàng' : 'Momo'}</td>
                                <td>{item.bankName}</td>
                                <td>{item.bankAccountNumber}</td>
                                <td>{item.phoneNumber ? item.phoneNumber : 'N/A'}</td>
                                <td>{item.transferDescription}</td>
                                <td>{item.transferReferenceCode}</td>
                                <td>{item.transferDate}</td>
                                {
                                    !props.creator && <td>{item.note}</td>
                                }
                            </tr>
                        )
                    })
                        :
                        <tr>
                            <td colSpan={10}>Không có dữ liệu</td>
                        </tr>
                }
            </tbody>
        )
    }
    return (
        <div className="table-responsive h-100vh">
            <Loading show={isBusy} msg='Đang tải dữ liệu' />
            <CommonTable
                paging={paging}
                onFilter={_fetchData}
                renderHeader={_renderHeaderTable}
                renderBody={_renderBodyTable}
                searchBasic
                tableGuid={Common.generateGuid()}
            />
        </div>

    );
}

export default PaidLoyaltyTable;