import BaseServices from "./BaseServices";

const OperatorServices = {
    GetOperators: async (paging) => {
        return await BaseServices.Post("/api/operator/get-operators", paging);
    },
    GetOperator: async (id) => {
        return await BaseServices.Get(`/api/operator/get-operator/${id}`);
    },
    SaveOperator: async (model) => {
        return await BaseServices.Post("/api/operator/save-operator", model);
    },
    GetOperatorOrderPackingItem: async (tagId) => {
        return await BaseServices.Get(`/api/operator/get-tag/${tagId}`);
    },
    GetOperatorWithRole: async () => {
        return await BaseServices.Get("/api/operator/get-operators-with-role");
    },
    GetOrderPackages: async (paging) => {
        return await BaseServices.Post("/api/operator/get-order-package", paging);
    },
    UpdatePackageOrderStatus: async (status, orderId) => {
        return await BaseServices.Post(`/api/operator/update-package-order-status?orderId=${orderId}&status=${status}`, null);
    },
    UpdatePackageFullPack: async (status, orderId) => {
        return await BaseServices.Post(`/api/operator/update-package-full-pack?orderId=${orderId}`, null);
    },
    GetUserPackageHandle: async () => {
        return await BaseServices.Get(`/api/operator/get-users-package-handle`);
    },
    CheckSavePackageHandle: async (orderId) => {
        return await BaseServices.Post(`/api/operator/check-save-package-handle?orderId=${orderId}`, null);
    },
    GetOperatorOrders: async (paging) => {
        return await BaseServices.Post(`/api/operator/get-operator-orders`, paging);
    },
    GetOperatorOrdersTotal: async (paging) => {
        return await BaseServices.Post(`/api/operator/get-operator-orders-total`, paging);
    },
    GetUserPrintHandle: async () => {
        return await BaseServices.Get(`/api/operator/get-users-print-handle`);
    },

    ProcessOperatorFilesPaging: async (paging, tab) => {
        return await BaseServices.Post(`/api/operator/process-operator-files-paging?tab=${tab}`, paging);
    },
    DownloadArtwork: async (artworkId, url, fileName) => {
        return await BaseServices.DownloadFile(`/api/operator/download-artwork?artworkId=${artworkId}&url=${url}`, fileName);
    },
    OperatorFilesSaveUpload: async (model) => {
        return await BaseServices.Post(`/api/operator/operator-file-save-upload`, model);
    },
    GetOperatorFileByArtworkScale: async (artworkId, scale) => {
        return await BaseServices.Get(`/api/operator/get-operator-file-by-artwork-scale?artworkId=${artworkId}&scale=${scale}`);
    },
    GetHandleOverToLotus: async (tagId) => {
        return await BaseServices.Get(`/api/operator/get-handleover-to-lotus/${tagId}`);
    },
    UpdateHandleOverToLotus: async (tagIds) => {
        return await BaseServices.Post(`/api/operator/update-handleover-to-lotus`, tagIds);
    },
    PrintTagPetPaging: async (pageIndex, pageSize, tagId, status, keyword) => {
        return await BaseServices.Get(`/api/operator/get-print-tag-pet-paging?pageIndex=${pageIndex}&pageSize=${pageSize}&tagId=${tagId}&status=${status}&keyword=${keyword}`);
    },
    GetBatchPaging: async (paging) => {
        return await BaseServices.Post(`/api/operator/get-batch-paging`, paging);
    },
};

export default OperatorServices;
