import BaseServices from "./BaseServices";

const HotKeyServices = {  
    CreateUpdateHotKey: async (model) => {
        return await BaseServices.Post("/api/HotKey/create-update-hot-key", model);
    },
    DeleteHotKey: async (ids) => {
        return await BaseServices.Post("/api/HotKey/delete-hot-key", ids);
    },
    GetHotKeyByPage: async (model) => {
        return await BaseServices.Post("/api/HotKey/get-hot-key-by-page", model);
    },
    GetHotKeyById: async (id) => {
        return await BaseServices.Get(`/api/HotKey/get-hot-key-byId/${id}`);
    },
};

export default HotKeyServices;
