import Request from "../../based/Request";
import AccountServices from "../../based/services/AccountServices";
import { actionTypes } from "./types";

// Actions
const agencyFetchBegin = () => ({
    type: actionTypes.CREATOR_INFO_AGENCY_FETCH_BEGIN,
    payload: null,
});

const agencyFetchSuccess = (agency) => ({
    type: actionTypes.CREATOR_INFO_AGENCY_FETCH_SUCCESS,
    payload: agency,
});

const agencyFetchFail = (err) => ({
    type: actionTypes.CREATOR_INFO_AGENCY_FETCH_FAIL,
    payload: err,
});

//Action Creators
const getCreatorInfoData = () => {
    return (_, getState) => {
        const creatorInfoData = getState().creatorInfo.data;
        return creatorInfoData;
    };
};

export const fetchAgencyUser = () => {
    return async (dispatch) => {
        dispatch(agencyFetchBegin());

        const [err, data] = await AccountServices.GetAgencyUserByUserId();
        if (data) {
            dispatch(agencyFetchSuccess(data));
            console.log("data :>> ", data);
        } else dispatch(agencyFetchFail(err));
    };
};

export const uploadBannerImage = (imgFile) => {
    return async (dispatch) => {
        dispatch(agencyFetchBegin());

        Request.UploadImage(imgFile)
            .then((res) => {
                const updatedCreatorInfoData = { ...dispatch(getCreatorInfoData()) };
                updatedCreatorInfoData.bannerUrl = res.data.imageUrl;
                console.log(updatedCreatorInfoData);
                dispatch(agencyFetchSuccess(updatedCreatorInfoData));
            })
            .catch((err) => {
                dispatch(agencyFetchFail(err));
            });
    };
};

export const uploadAvatarImage = (imgFile) => {
    return async (dispatch) => {
        dispatch(agencyFetchBegin());

        Request.UploadImage(imgFile)
            .then((res) => {
                const updatedCreatorInfoData = { ...dispatch(getCreatorInfoData()) };
                updatedCreatorInfoData.user.avatar = res.data.imageUrl;
                dispatch(agencyFetchSuccess(updatedCreatorInfoData));
            })
            .catch((err) => {
                dispatch(agencyFetchFail(err));
            });
    };
};

export const updateAgencyUser = (agencyModel) => {
    return async (dispatch) => {
        dispatch(agencyFetchBegin());

        const [err, data] = await AccountServices.UpdateAgencyUser(agencyModel);
        if (data) {
            dispatch(agencyFetchSuccess(agencyModel));
            return true;
        } else {
            dispatch(agencyFetchFail(err));
            return err;
        }
    };
};

export const removeIdentificationImage = (name) => {
    return async (dispatch) => {
        dispatch(agencyFetchBegin());

        const [err, data] = await AccountServices.UpdateIdentificationImage(name, "");
        if (data) {
            dispatch(getCreatorInfoData());
            return true;
        } else {
            dispatch(agencyFetchFail(err));
            return false;
        }
    };
};
