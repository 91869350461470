import PropTypes from "prop-types";
import React, { useState } from "react";
import { CONSTANTS } from "../Constants";
import Textbox from "./Textbox";

const PhoneNumber = (props) => {
    const [msgInValid, setMsgInValid] = useState("");
    const _handleMsgInvalid = (msg) => {
        if (msg) setMsgInValid(msg);
        else setMsgInValid("");
    };
    return (
        <div className="input-phone-number">
            <div className={`input-group-phone ${msgInValid ? "invalid" : "valid"} `}>
                <div className="input-group-prepend">
                    <span className="input-group-text">+84</span>
                </div>
                <Textbox
                    isPhoneNumber={true}
                    msgInvalid={_handleMsgInvalid}
                    id={props.id}
                    name={props.name}
                    className="form-control input-phone w-100"
                    number
                    ref={props.addRef}
                    required={props.required ? props.required : false}
                    minLength={9}
                    maxLength={11}
                    value={props.value}
                    onChanged={(name, value) => {
                        if (value === "" || (value && value.length <= 11 && CONSTANTS.PHONE_REGEX.test(value))) props.onChanged(name, value);
                    }}
                    readOnly={props.readOnly || false}
                    placeholder={props.placeholder ? props.placeholder : null}
                />
            </div>
            <label className="text-danger full-width" htmlFor={props.id}>
                {msgInValid}
            </label>
        </div>
    );
};

PhoneNumber.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    addRef: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChanged: PropTypes.func.isRequired,
};

export default PhoneNumber;
