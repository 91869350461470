import React, { useEffect, useState } from 'react';
import BaseServices from '../../based/services/BaseServices';
import TextNumber from '../../based/inputs/TextNumber';
import CommonTable from '../../based/CommonTable';
import MonthDetail from './MonthDetail';
import { Notify } from '../../based/Notify';
import { NOTIFY } from '../../based/Constants';
const DetailAffiliator = ({ thirdPartyId, onBack }) => {
    const [affiliator, setAffiliator] = useState(null);
    const [viewMonthDetail, setViewMonthDetail] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(null);
    useEffect(() => {
        _fetchData();
    }, [thirdPartyId])
    const _fetchData = async () => {
        const [err, data] = await BaseServices.Get('/api/affiliate/get-vip-affiliator/' + thirdPartyId)
        if (!err) {
            setAffiliator(data);
        }
    }

    const _handleUpdateQuota = async () => {
        let model = {
            currentQuota: affiliator.quota,
            nextQuota: affiliator.nextQuota
        }
        const [err, data] = await BaseServices.Post('/api/affiliate/update-quota/' + thirdPartyId, model)
        if (!err) {
            Notify(NOTIFY.SUCCESS, 'Thành công', 'Cập nhật thành công');
        }
    }
    const _handleViewMonthDetail = async (month) => {
        setViewMonthDetail(true);
        setSelectedMonth(month);
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th>STT</th>
                <th>Tháng</th>
                <th>Lượng tương tác chung</th>
                <th>SP đã mua</th>
                <th>Nhiệm vụ hoàn thành</th>
                <th>Trạng thái</th>
                <th></th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        if (affiliator && affiliator.top12MonthInteractions) {
            return <tbody>
                {
                    affiliator.top12MonthInteractions.map((item, idx) => {
                        return <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.month}/{item.year}</td>
                            <td>
                                <div><i className='fa fa-eye'></i> {item.totalView ? item.totalView : 0} lượt xem</div>
                                <div><i className='fa fa-thumbs-o-up'></i> {item.totalLike ? item.totalLike : 0} lượt yêu thích</div>
                                <div><i className='fa fa-comment-o'></i> {item.totalComment ? item.totalComment : 0} lượt bình luận</div>
                                <div><i className='fa fa-share-alt'></i> {item.totalShare ? item.totalShare : 0} lượt chia sẻ</div>
                            </td>
                            <td>{item.boughtProductCount}/{affiliator.quota}</td>
                            <td>{item.completedRequirementsCount}/{item.totalRequirementsCount}</td>
                            <td><span className='text-danger'>Đang thống kê</span></td>
                            <td><button className='btn btn-custom btn-sm m-b-5' onClick={() => _handleViewMonthDetail(item)}>Chi tiết</button></td>
                        </tr>
                    })
                }
            </tbody>
        }
        else {
            return <tbody>
                <tr>
                    <td colSpan={7}>Không có dữ liệu</td>
                </tr>
            </tbody>
        }
    }
    return (
        <div>
            {
                affiliator ?
                    viewMonthDetail ? <MonthDetail monthData={selectedMonth} onBack={() => setViewMonthDetail(false)} thirdPartyId={thirdPartyId}/> :
                        <div>
                            <div className='card-box p-10'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <strong className='col-4'>Affiliator:</strong>
                                            <div className='col-4'>{affiliator.fullName}</div>
                                        </div>
                                        <div className='row'>
                                            <strong className='col-4'>Email:</strong>
                                            <div className='col-4'>{affiliator.email}</div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <strong className='col-4'>Quota hiện tại:</strong>
                                            <div className='col-4'>
                                                <TextNumber
                                                    value={affiliator.quota}
                                                    onChanged={(name, value) => setAffiliator({ ...affiliator, quota: value })}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <strong className='col-4'>Quota tháng sau:</strong>
                                            <div className='col-4'>
                                                <TextNumber
                                                    value={affiliator.nextQuota}
                                                    onChanged={(name, value) => setAffiliator({ ...affiliator, nextQuota: value })}
                                                />
                                            </div>
                                            <div><button className='btn btn-custom btn-sm' onClick={_handleUpdateQuota}>Update</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5>Lịch sử tương tác</h5>
                            <CommonTable
                                renderHeader={_renderHeader}
                                renderBody={_renderBody}
                            />
                        </div>
                    :
                    null
            }
        </div>
    );
};

export default DetailAffiliator;