import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import TagsInput from "react-tagsinput";
import { SetConfigModel } from "../artwork-manage/model/PreAddArtworkModel";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, IMAGE_TYPE, IMAGE_TYPE_NAME, NOTIFY, PRINT_TYPE } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import TFUImage from "../based/inputs/Image";
import Textbox from "../based/inputs/Textbox";
import TextEditor from "../based/inputs/TextEditor";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import TFUSelect from "../based/refactor/TFUSelect";
import SetProductServices from "../based/services/SetProductServices";
import useFormValidate from "../hooks/useFormValidate";

export default function ConfigSetProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [config, setConfig] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [products, setProducts] = useState([]);
    const [textSearch, setTextSearch] = useState("");
    const [isFetching, setFetching] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [configModel, setConfigModel] = useState(new SetConfigModel());

    useEffect(() => {
        let model = props.config;
        let modelConfig = model.setConfigModel || new SetConfigModel();
        setConfig(model);
        setConfigModel(modelConfig);
    }, [props.isShowModal]);

    async function _handleSave() {
        if (isValid()) {
            var hasVariants = config.productVariants && config.productVariants.length > 0;
            if (hasVariants) {
                setIsLoading(true);
                setMsg("Đang lưu dữ liệu..");
                let cfModel = { ...configModel };
                cfModel.printType = cfModel.listPrintType.reduce((prev, item, idx) => (prev |= item));
                config.setConfigModel = cfModel;
                const [err, data] = await SetProductServices.Save(config);
                if (!err && data) {
                    Notify(NOTIFY.SUCCESS, "Thành công", "Thiết lập thành công");
                    props.onSuccess();
                } else {
                    if (err.errors) displayError(err.errors);
                    else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                }
                setIsLoading(false);
                setMsg(null);
            } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng thiết lập sản phẩm cho Set!");
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width="120" className="text-center">
                        Ảnh
                    </th>
                    <th width="350">Tên sản phẩm</th>
                    <th width="150" className="text-center">
                        Giá bán (đ)
                    </th>
                    <th className="text-center">Thông tin in</th>
                    <th width="50"></th>
                </tr>
            </thead>
        );
    }
    function _renderFrameConfig(item) {
        if (item.frameConfigs && item.frameConfigs.length > 0) {
            return (
                <table className="table table-bordered m-b-0 focus-on">
                    <thead>
                        <tr className="text-center">
                            <th className="cursor-pointer">Ngang áo (tay)</th>
                            <th className="cursor-pointer">Dài áo (trước)</th>
                            <th className="cursor-pointer">Khung ngang</th>
                            <th className="cursor-pointer">Khung dọc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {item.frameConfigs.map((config, i) => {
                            return (
                                <tr key={i}>
                                    <td>{config.widthMockup}</td>
                                    <td>{config.heightMockup}</td>
                                    <td>{config.widthFrame}</td>
                                    <td>{config.heightFrame}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
    }
    function _renderBody() {
        return (
            <tbody>
                {config && config.productVariants && config.productVariants.length > 0 ? (
                    config.productVariants.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    <a className="iconImageDefault full-width">
                                        <img src={item.avatar} className="variant-avatar m-r-5" alt={item.name} />
                                    </a>
                                </td>
                                <td className="text-left vertical-align-middle">
                                    <strong> {item.name}</strong>
                                    {item.sellerSKU && <p className="margin-bottom-0 font-italic">SKU: {item.sellerSKU ? item.sellerSKU : "----"}</p>}
                                    {item.attributeValuesDetail &&
                                        item.attributeValuesDetail.length > 0 &&
                                        item.attributeValuesDetail.map((attribute, at) => {
                                            return (
                                                <p key={at} className="margin-bottom-0 font-italic">
                                                    {attribute.name} : <span className="badge badge-custom">{attribute.displayName}</span>
                                                </p>
                                            );
                                        })}
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.sellPrice} />
                                </td>
                                <td className="text-center vertical-align-middle">{item.frameConfigs && item.frameConfigs.length > 0 ? _renderFrameConfig(item) : <span>Chưa có thông tin</span>}</td>
                                <td className="text-center vertical-align-middle">
                                    <i className="mdi mdi-24px mdi-delete text-danger cursor-pointer" onClick={() => _handleRemoveVariant(item)}></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="5" className="vertical-align-middle text-center">
                            Chưa có sản phẩm nào được chọn
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {}
    function updateVariantsAfterDel(item) {
        if (item) {
            let configUp = { ...config };
            if (configUp.productVariants && configUp.productVariants.length > 0) {
                let idx = -1;
                configUp.productVariants.map((variant, v) => {
                    if (variant.variantId == item.variantId) idx = v;
                });
                if (idx > -1) configUp.productVariants.splice(idx, 1);
                setConfig(configUp);
            }
        }
    }
    async function _handleRemoveVariant(item) {
        if (item) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    updateVariantsAfterDel(item);
                    setConfig({ ...config, isRunServices: true });
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn sản phẩm trước khi xóa!");
    }
    function _renderSearchAutoComplete() {
        return (
            <div id="search-autocomplete" className="p-1 bg-light rounded rounded-pill shadow-sm mb-4" onBlur={_handleClickOutside}>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <button type="submit" className="btn btn-link text-warning">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                    <input
                        type="search"
                        placeholder="Nhập tên sản phẩm..."
                        className="form-control border-0 bg-light"
                        onKeyUp={(e) => _handleChangeInputSearch(e)}
                        onChange={(e) => setTextSearch(e.target.value)}
                        onFocus={() => _handleFocusInputSearch()}
                        value={textSearch}
                        autoComplete="off"
                    />
                </div>
                {showResult && _renderResult()}
            </div>
        );
    }
    function _handleClickOutside() {
        setTimeout(function () {
            setShowResult(false);
        }, 200);
    }
    function _handleFocusInputSearch() {
        if (products && products.length > 0) setShowResult(true);
        else setShowResult(false);
    }
    const debounce = useCallback(
        _.debounce((_searchVal) => {
            _fetchingSearch(_searchVal);
        }, 1000),
        []
    );
    function _handleChangeInputSearch(e) {
        var text = e.target.value;
        setFetching(true);
        setShowResult(true);
        debounce(text);
    }
    async function _fetchingSearch(text) {
        if (text && text.length > 0) {
            setFetching(true);
            const [err, data] = await SetProductServices.SuggestSearch(text);
            if (!err && data) {
                setProducts(data);
                setFetching(false);
                setShowResult(true);
            } else {
                setShowResult(true);
                setFetching(false);
                setProducts([]);
            }
        } else {
            setProducts([]);
            setShowResult(false);
        }
    }
    function _renderResult() {
        return (
            <div id="search-result">
                <ul className="suggest_search">
                    {products && products.length > 0 ? (
                        products.map((item, index) => {
                            let rankPrice = 0;
                            if (item.minPrice == item.maxPrice) rankPrice = <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.minPrice} suffix=" đ" />;
                            else
                                rankPrice = (
                                    <React.Fragment>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.minPrice} suffix=" đ" /> -
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.maxPrice} suffix=" đ" />
                                    </React.Fragment>
                                );
                            return (
                                <div className="product-item" key={index} onClick={() => _handleSelectItem(item)}>
                                    <li className="product_suggest">
                                        <div className="item-img">
                                            <img src={item.avatar} alt={item.name} />
                                        </div>
                                        <div className="item-info">
                                            <h3>{item.name} </h3>
                                            <p className="item-txt-sku">Bao gồm: {item.totalVariant} biến thể</p>
                                            <strong className="price"> {rankPrice} </strong>
                                        </div>
                                    </li>
                                </div>
                            );
                        })
                    ) : (
                        <div className="product-item">
                            <li className="product_suggest">
                                {isFetching ? (
                                    <span>
                                        <i className="fa fa-spinner m-r-5"></i>Đang tải dữ liệu...
                                    </span>
                                ) : (
                                    <span>Không có dữ liệu</span>
                                )}
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        );
    }
    async function _handleSelectItem(item) {
        if (item && item.id > 0) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu..");
            const [err, data] = await SetProductServices.SuggestProductVariants(item.id);
            if (!err && data) {
                setIsLoading(false);
                setMsg(null);
                //reset config
                let productVariants = data;
                if (productVariants && productVariants.length > 0) {
                    let configUp = { ...config };
                    if (!configUp.productVariants || configUp.productVariants.length == 0) configUp.productVariants = [];
                    productVariants.map((variant) => {
                        let oldVariant = null;
                        if (configUp.productVariants && configUp.productVariants.length > 0) oldVariant = configUp.productVariants.find((x) => x.variantId == variant.variantId);
                        if (!oldVariant) configUp.productVariants.push(variant);
                    });
                    configUp.isRunServices = true;
                    setConfig(configUp);
                    setShowResult(false);
                }
            } else {
                setIsLoading(false);
                setMsg(null);
            }
        }
    }
    function _handleInputChange(key, value) {
        if (value) {
            setConfig({ ...config, setAvatar: value });
            document.getElementById("uploadImages").value = null;
        }
    }
    return (
        <CommonModal title={config.id == 0 ? "Thêm mới" : "Cập nhật"} show={props.isShowModal} size="tfulg" isBusy={isLoading} busyMsg={msg} onClose={() => props.onClose()} onSave={() => _handleSave()}>
            <div className="form-group">
                <div className="form-row">
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="title" className="required">
                            Tên set sản phẩm:
                        </label>
                        <Textbox required ref={addRef} name="title" maxLength={500} className="form-control" onChanged={(name, value) => setConfig({ ...config, title: value })} value={config.title} />
                    </div>
                    <div className="col-md-6">
                        <div className="ui-photo">
                            <TFUImage className="hide" id="uploadImages" name="uploadImages" images={config.setAvatar} onChanged={_handleInputChange} />
                            <ul className="ui-photo-grid clearfix">
                                <li className="ui-photo-item col-md-3">
                                    {config.setAvatar && config.setAvatar.length > 0 ? (
                                        <div className="ui-photo-box">
                                            <img className="ui-photo-item__image" src={config.setAvatar} />
                                            <div className="photo-hover-overlay">
                                                <ul className="photo-overlay-actions">
                                                    <li>
                                                        <button className="btn btn-link photo-overlay-actions__link" onClick={() => setConfig({ ...config, setAvatar: "" })}>
                                                            <i className="fa fa-trash-o" style={{ color: "red" }}></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="file-upload position-relative"
                                            onClick={() => {
                                                document.getElementById("uploadImages").click();
                                            }}
                                        >
                                            <div className="fileupload-text text-center">
                                                <i className="fa fa-camera font-size-30px"></i>
                                                <p className="mb-0 mt-2 text-secondary">Ảnh đại diện</p>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-3">
                        <label className="col-form-label">Giới hạn chiều ngang Artwork (cm)</label>
                        <input type={"number"} value={configModel.widthArtwork} name="widthArtwork" className="form-control" onChange={(e) => setConfigModel({ ...configModel, widthArtwork: +e.target.value })} />
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Giới hạn chiều dọc Artwork (cm)</label>
                        <input type={"number"} value={configModel.heightArtwork} name="heightArtwork" className="form-control" onChange={(e) => setConfigModel({ ...configModel, heightArtwork: +e.target.value })} />
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Giới hạn độ phân giải DPI Artwork</label>
                        <TextNumber required ref={addRef} value={configModel.resolution} name="resolution" suffix={"PPI"} onChanged={(name, value) => setConfigModel({ ...configModel, resolution: +value })} />
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Giới hạn kích thước Mb Artwork</label>
                        <TextNumber required ref={addRef} value={configModel.maxMb} name="maxMb" suffix={"Mb"} onChanged={(name, value) => setConfigModel({ ...configModel, maxMb: +value })} />
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Định dạng</label>
                        <TagsInput
                            value={configModel.format}
                            onChange={(tags) => {
                                for (let i = 0; i < tags.length; i++) {
                                    const element = tags[i];
                                    tags[i] = element.substring(0, 50);
                                }
                                setConfigModel({ ...configModel, format: tags });
                            }}
                            focusedClassName={"react-tagsinput--focused-custom"}
                            inputProps={{ className: "react-tagsinput-input-custom", placeholder: "Thêm giới hạn định dạng hình ảnh" }}
                            tagProps={{ className: "react-tagsinput-tag-custom" }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="col-form-label">
                            Kiểu in khả dụng
                        </label>
                        <CheckBox
                            name="printType"
                            checked={configModel.listPrintType.includes(PRINT_TYPE.DTG)}
                            label="DTG"
                            onCheckedChange={(name, value) => {
                                let list = { ...configModel }.listPrintType;
                                if (value) list.push(PRINT_TYPE.DTG);
                                else {
                                    let idx = list.indexOf(PRINT_TYPE.DTG);
                                    if (idx >= 0) list.splice(idx, 1);
                                }
                                setConfigModel({ ...configModel, listPrintType: list });
                            }}
                        />
                        <CheckBox
                            name="printType"
                            checked={configModel.listPrintType.includes(PRINT_TYPE.PET)}
                            label="PET"
                            onCheckedChange={(name, value) => {
                                let list = { ...configModel }.listPrintType;
                                if (value) list.push(PRINT_TYPE.PET);
                                else {
                                    let idx = list.indexOf(PRINT_TYPE.PET);
                                    if (idx >= 0) list.splice(idx, 1);
                                }
                                setConfigModel({ ...configModel, listPrintType: list });
                            }}
                        />
                        <CheckBox
                            name="printType"
                            checked={configModel.listPrintType.includes(PRINT_TYPE.HEAT)}
                            label="Chuyển nhiệt"
                            onCheckedChange={(name, value) => {
                                let list = { ...configModel }.listPrintType;
                                if (value) list.push(PRINT_TYPE.HEAT);
                                else {
                                    let idx = list.indexOf(PRINT_TYPE.HEAT);
                                    if (idx >= 0) list.splice(idx, 1);
                                }
                                setConfigModel({ ...configModel, listPrintType: list });
                            }}
                        />
                        <CheckBox
                            name="printType"
                            checked={configModel.listPrintType.includes(PRINT_TYPE.UV)}
                            label="UV"
                            onCheckedChange={(name, value) => {
                                let list = { ...configModel }.listPrintType;
                                if (value) list.push(PRINT_TYPE.UV);
                                else {
                                    let idx = list.indexOf(PRINT_TYPE.UV);
                                    if (idx >= 0) list.splice(idx, 1);
                                }
                                setConfigModel({ ...configModel, listPrintType: list });
                            }}
                        />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="" className="col-form-label">
                            Hiển thị ở mẫu Creator
                        </label>
                        <CheckBox
                            name="printType"
                            checked={configModel.isShowCreator}
                            label="Hiển thị ở mẫu Creator"
                            onCheckedChange={(name, value) => {
                                setConfigModel({ ...configModel, isShowCreator: value });
                            }}
                        />
                    </div>

                    <div className="col-md-3">
                        <label>Kiểu Set</label>
                        <TFUSelect
                            options={[
                                {
                                    value: IMAGE_TYPE.ArtWork,
                                    text: IMAGE_TYPE_NAME.ArtWork,
                                    label: IMAGE_TYPE_NAME.ArtWork,
                                },
                                {
                                    value: IMAGE_TYPE.ArtworkTote,
                                    text: IMAGE_TYPE_NAME.ArtworkTote,
                                    label: IMAGE_TYPE_NAME.ArtworkTote,
                                },
                                {
                                    value: IMAGE_TYPE.ArtworkCase,
                                    text: IMAGE_TYPE_NAME.ArtworkCase,
                                    label: IMAGE_TYPE_NAME.ArtworkCase,
                                },
                            ]}
                            value={configModel.imageTypeValidate}
                            onChanged={(newvalue) => {
                                setConfigModel({ ...configModel, imageTypeValidate: newvalue });
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>Nội dung</label>
                        <TextEditor
                            id="content"
                            name="content"
                            className="form-control"
                            onChanged={(name, value) => {
                                setConfig({ ...config, content: value });
                            }}
                            html={config.content}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Thiết lập sản phẩm của Set</b>
                </h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group col-md-12">
                            {_renderSearchAutoComplete()}
                            <CommonTable data={config.setProductVariants} paging={Common.Paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} freeSize="tbl_setproducts" />
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
