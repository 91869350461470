import React, { useEffect, useState, useContext } from 'react';

import Textbox from '../based/inputs/Textbox';
import SupplierModal from '../third-party/SupplierModal';
import { StockInContext, SupplierInfoModel } from '../context/StockInContext';
import Selection from '../based/inputs/Selection';
import ThirdPartyServices from '../based/services/ThirdPartyServices';

export default function SupplierInfo(props) {
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [isShowSupplierModal, setIsShowSupplierModal] = useState(false);
  const { supplierInfo, setSupplierInfo } = useContext(StockInContext);

  useEffect(() => {
    _getSuppliers();
  }, []);

  async function _getSuppliers() {
    const [err, data] = await ThirdPartyServices.GetSuppliersDropdown();
    if (!err) {
      setSupplierOptions(data);
    }
  }

  async function _handleChangeSupplier(id) {
    if (id > 0) {
      const [err, data] = await ThirdPartyServices.GetSupplierDetail(id);
      if (!err) setSupplierInfo(data);
      else setSupplierInfo(new SupplierInfoModel());
    } else setSupplierInfo(new SupplierInfoModel());
  }

  function _handleAddSupplierModal() {
    setIsShowSupplierModal(true);
  }

  function _handleSaveSucceed() {
    _getSuppliers();
  }

  function _renderBody() {
    return (
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="name" className="col-form-label required">
            Tên nhà cung cấp
          </label>
          <div className="row">
            <div className="col-md-10">
              <Selection id="supplierInfoId" name="supplierInfoId" value={supplierInfo.id} required ref={props.addRef} placeholder="---Chọn nhà cung cấp---" onChanged={_handleChangeSupplier} options={supplierOptions} />
            </div>
            <div className="col-md-2">
              <button className="btn btn-custom btn-sm" onClick={_handleAddSupplierModal} title="Thêm nhà cung cấp">
                <i className="fa fa-lg fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="email" className="col-form-label">
                Email
              </label>
              <Textbox className="form-control" id="email" name="email" ref={props.addRef} value={supplierInfo.email} readOnly />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phoneNumber" className="col-form-label">
                Số điện thoại
              </label>
              <Textbox className="form-control" id="phoneNumber" name="phoneNumber" value={supplierInfo.phoneNumber} readOnly />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="address" className="col-form-label">
                Địa chỉ nhà cung cấp
              </label>
              <Textbox className="form-control" id="address" name="address" value={supplierInfo.address} readOnly />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function _renderBodyReadOnly() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <label className="col-md-4 label-normal">Tên nhà cung cấp:</label>
            <strong className="col-md-8">{supplierInfo.fullName}</strong>
          </div>
          <div className="row">
            <label className="col-md-4 label-normal">Email:</label>
            <strong className="col-md-8">{supplierInfo.email}</strong>
          </div>
          <div className="row">
            <label className="col-md-4 label-normal">Số điện thoại:</label>
            <strong className="col-md-8">{supplierInfo.phoneNumber}</strong>
          </div>
          <div className="row">
            <label className="col-md-4 label-normal">Địa chỉ:</label>
            <strong className="col-md-8">{supplierInfo.address}</strong>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="header-title" style={{ margin: '0 0 10px 0', borderBottom: '1px solid #ccc' }}>
          <b>Thông tin nhà cung cấp</b>
        </h4>
        {props.readOnly ? _renderBodyReadOnly() : _renderBody()}
      </div>
      {!props.readOnly && (
        <SupplierModal
          isShowModal={isShowSupplierModal}
          provinces={props.provinces}
          onClose={() => setIsShowSupplierModal(false)}
          onSaveSucceed={(data) => {
            setIsShowSupplierModal(false);
            _handleSaveSucceed(data);
          }}
        />
      )}
    </div>
  );
}
