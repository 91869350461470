export default class VariantFrameConfigModel {
    constructor() {
        this.imageId = 0;
        this.width = 0;
        this.height = 0;
        this.axisX = 0;
        this.collarY = 0;
        this.frameMinY = 0;
        this.lengthFrameMin = 0;
        this.allowRenderPDP = false;
        this.allowRenderUpload = false;
        this.digitalScale = 350;
    }
}
