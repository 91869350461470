import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import VoucherServices from "../based/services/VoucherServices";
import AddOrUpdate from "./AddOrUpdate";

export default function VoucherCustomize(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({...INITPAGING});
    const [datas, setDatas] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [currentVoucher, setCurrentVoucher] = useState(null);

    useEffect(() => {
        getDatas(paging)
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await VoucherServices.GetVoucherCustomizeByPage(newPaging);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width='100' className='text-center'>
                        STT
                    </th>
                    <th width='150' className='text-center'>
                        Tên Voucher
                    </th>
                    <th width='150' className='text-center'>
                        Mã Voucher
                    </th>
                    <th  className='text-center'>
                        Mô tả
                    </th>
                    <th width='200' className='text-center'>
                        Ngày bắt đầu
                    </th>
                    <th width='200' className='text-center'>
                        Ngày kết thúc
                    </th>
                    <th width='200' className='text-center'>
                        Số lượng mã
                    </th>
                    <th width='200' className='text-center'>
                        Action
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                 <td>{idx + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.code}</td>
                                <td>{item.description}</td>
                                <td>{Common.formatDate(new Date(item.fromDate))}</td>
                                <td>{Common.formatDate(new Date(item.toDate))}</td>
                                <td>{item.quantity}</td>
                                <td>
                                    <button className="btn btn-custom"
                                        onClick={()=>{
                                            setCurrentVoucher(item.voucherId);
                                            setIsShowModal(true);
                                        }}
                                    >Sửa</button>
                                    <button className="btn btn-danger ml-2"
                                    onClick={()=>{
                                        _handleDelete(item.voucherId)
                                    }}
                                    
                                    >Xóa</button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }


    function _handleDelete(id) {

            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await VoucherServices.DeleteVoucherCustomize(id);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
    }

    

    function _renderAction() {
        return (
            <div style={{
                color: "#64C5B1",
                fontFamily: "Roboto",
                fontSize: '14px',
                fontWeight: 700,
                marginBottom: 10,
                cursor: "pointer"
            }} 
                onClick={()=>{
                    setCurrentVoucher(0)
                    setIsShowModal(true);
                }}
            >Thêm +</div>
        );
    }
    return (
        <React.Fragment>
            {isShowModal && <AddOrUpdate isShowModal={isShowModal} onClose={()=>{
                setIsShowModal(false);
            }} 
                id={currentVoucher}
                onSaveContinue={()=>{
                    getDatas(paging)
                    setIsShowModal(false);
                }}
            />}
            <Breadcrumbs currentPage='Quản lý Vourcher tự thiết kế' />
            <Loading show={isLoading} msg={msg} />
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction}  searchBasic={false} />
        </React.Fragment>
    );
}
