import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import Loading from "../based/Loading";
import MarketingServices from "../based/services/MarketingServices";
import QRCode from 'react-qr-code';
import Request from "../based/Request";
import ModalChooseImage from "./modals/ModalChooseImage";
import MarketingPartnerCollab from "./MarketingPartnerCollab";
import Common from "../based/Common";



const POSTER = 1
const FIRST_DOMAIN = "https://ranus.vn/co/"
function MarketingColorManagement({campaignId , creatorId}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data , setData] = useState({})
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const [isModal , setIsModal] = useState(false);
    const [image , setImage] = useState("");

    useEffect(() => {
        setBusy(false);
        getData();
    }, [campaignId]);

    async function getData() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetCampaignById(campaignId);
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData({});
            setBusy(false);
        }
    }




    const handleDownloadPoster = () => {
        if (data.poster) {
            // Sử dụng Fetch API để tải hình ảnh từ URL
            fetch(data.poster)
              .then((response) => response.blob())
              .then((blob) => {
                // Tạo một đường dẫn URL cho Blob
                const url = window.URL.createObjectURL(blob);
      
                // Tạo một thẻ a để tạo một liên kết tải về
                const a = document.createElement('a');
                a.href = url;
                a.download = `poster-${data.brandName}.png`; // Tên tệp tải về (có thể thay đổi tùy ý)
                document.body.appendChild(a);
                a.click();
      
                // Xóa thẻ a sau khi tải về hoàn thành
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              })
              .catch((error) => {
                console.error('Lỗi khi tải hình ảnh:', error);
              });
          }
      
    };

    const handleUpdate =  async (e) =>{
        if (e.key === 'Enter'){
            let [err, dt] = await MarketingServices.CreateOrUpdateCampaign({
                ...data
            });
            if (!err && dt != 0) {
                if(dt == 1){
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
                }
                if(dt == -7){
                    Notify(NOTIFY.WARNING, NOTIFY.WARNING,"Ngày trùng với chiến dịch khác")
                }
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
            }
        }
    }


    const handleUpdateDate =  async (data) =>{
            let [err, dt] = await MarketingServices.CreateOrUpdateCampaign({
                ...data
            });
            if (!err && dt != 0) {
                if(dt == 1){
                    setData(data)
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
                }
                if(dt == -7){
                    Notify(NOTIFY.WARNING, NOTIFY.WARNING,"Ngày trùng với chiến dịch khác")
                }
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
            }
        
    }



    const handleUpdateLogo = async (url) => {
        let [err, dt] = await MarketingServices.CreateOrUpdateCampaign({
            ...data, logo: url 
        });
        if (!err && dt) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
        }
    }

    const handlePoster = async (image) => {
        setIsModal(false)
        let [err, dt] = await MarketingServices.DrawImage({
            imageUrl: image , imageType: POSTER, campaignId: campaignId
        });
        if (!err && dt && dt.imageUrl) {
            let [err2, dt2] = await MarketingServices.CreateOrUpdateCampaign({
                ...data, poster: dt.imageUrl 
            });
            if (!err2 && dt2) {
                getData()
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS,"Thành công")
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
            }

        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR,"Thất bại")
        }
       
    }

    async function handleUploadFile(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            setData({...data , logo: res.data.imageUrl})
                            handleUpdateLogo(res.data.imageUrl)
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }
    
    return (
        <WapperColorStyle>
            {isModal && <ModalChooseImage  tabImage={POSTER} onContinue={handlePoster} setImage={setImage} isShow={isModal} onClose={()=>{setIsModal(false)}} />}
            
            <div className="d-flex">
                <div className="marketing-url">
                    <h4>Màu chủ đạo<i>(enter để thay đổi)</i></h4>
                    <div className="marketing-color">
                        <input type="text" value={data.colorCode} onKeyPress={handleUpdate}
                        onChange={(e)=>{setData({...data , colorCode: e.target.value})}} />
                        <input type="color" value={data.colorCode} className="color-input"
                        onChange={(e)=>{setData({...data , colorCode: e.target.value})}} />
                    </div>
                </div>
                <div className="marketing-url">
                    <h4>Tên chiến dịch<i>(enter để thay đổi)</i></h4>
                    <div className="">
                        <input type="text" value={data.name} onKeyPress={handleUpdate}
                        onChange={(e)=>{setData({...data , name: e.target.value})}} />
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className="marketing-url">
                    <h4>Ngày bắt đầu</h4>
                    <div className="wheel-col">
                        <input type="date" value={data.startDate} 
                        onChange={(e)=>{
                            handleUpdateDate({...data , startDate: e.target.value})
                            
                        }}
                        />
                        <div className="absolutedate">{data.startDate && Common.formatDate(new Date(data.startDate))}</div>
                    </div>
                    
                </div>
                <div className="marketing-url">
                    <h4>Ngày kết thúc</h4>
                    <div className="wheel-col">
                        <input type="date" value={data.endDate} 
                        onChange={(e)=>{
                            handleUpdateDate({...data , endDate: e.target.value})
                        }} 
                        />
                        <div className="absolutedate">{data.endDate && Common.formatDate(new Date(data.endDate))}</div>
                    </div>
                </div>
            </div>
            <div className="box-logo">
                <label>Logo:</label>
                {data.logo ? <img src={data.logo} /> : <img/>}
                 <input
                    style={{maxWidth: 90}}
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                        handleUploadFile(e);
                    }}
                />
            </div>
            <div className="box-poster">
                <label>Poster:</label>
                {data.poster && <img src={data.poster} />}
                 <div className="d-flex"><button className="btn mt-2 btn-custom"
                    onClick={()=>{
                        setIsModal(true)
                    }}
                 >Xuất poster</button>
                 {data.poster && <button className="btn btn-custom mt-2 ml-3"
                    onClick={()=>{
                        handleDownloadPoster()
                    }}
                 >Tải về</button>}</div>
            </div>
            

            <MarketingPartnerCollab campaignId={campaignId} creatorId={creatorId} />
            
        </WapperColorStyle>
    )
}
const WapperColorStyle = styled.div`
    background-color: #fff;
    padding: 16px;
    margin-bottom: 16px;
    position: relative;
    min-height: 300px;


    .wheel-col{
        position: relative;


        .absolutedate{
            position: absolute;
            background-color: #fff;
            top: 10px;
            left: 4px;
            width: 90px;
        }



        span{
            display: block;
            width: 100%;
            margin-bottom: 5px;
            color: #666F7B;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        input{
            width: 100%;
            border: 1.5px solid #D9D9D9;
            background: #FFF;
            outline: none;
            height: 30px;
        }
        

    }

    .marketing-qr{
        position: absolute;
        top: 15px;
        left: 1000px;
        display: flex;

        .qr-code{
            width: 120px;
            height: 120px;
        }

        span{
            margin-left: 6px;
            color: #64C5B1;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            cursor: pointer;
        }
    }

    h4{
        color: #666F7B;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        margin-top: 0;

        i{
            margin-left: 4px;
            color: #444;
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
        }
    }
    .box-logo{
        position: absolute;
        top: 15px;
        left: 710px;
        display: flex;
        flex-direction: column;

        img{
            width: 120px;
            height: 120px;
            object-fit: contain;
        }

        input{
            margin-top: 10px;
        }

    }

    .box-poster{
        position: absolute;
        top: 15px;
        left: 840px;
        display: flex;
        flex-direction: column;

        img{
            width: 120px;
            height: 120px;
            object-fit: contain;
        }

        

    }
    .marketing-url{
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 20px;
        min-width: 300px;

        .box-domain{
            width: 350px;
            position: relative;

            input{
                width: 100%;
                padding-left: 118px;
                height: 40px;
            }

            h6{
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-115%);
                font-size: 13px;
                font-weight: 400;
            }
        }

        input{
            width: 250px;
            height: 40px;
        }
        span{
            color: #64C5B1;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            margin-top: 10px;
            cursor: pointer;
        }
    }

    .marketing-color{
        display: flex;
        align-items: center;

        input{
            height: 40px;
            width: 100px;
            cursor: pointer;
        }

        .color-input{
            width: 100px;
            height: 40px;
        }
    }
    

`
export default MarketingColorManagement;
