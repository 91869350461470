import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import MarketingServices from "../based/services/MarketingServices";
import CommonTable from "../based/CommonTable";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";


const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};


function MarketingPartnerCollab({campaignId , creatorId}) {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [result , setResult] = useState([])



    useEffect(() => {
            setBusy(false);
            getData(campaignId);
        
    }, []);

    async function getData(campaignId) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetPartnerCollabByCampaign(campaignId);
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Avatar</th>
                    <th>Họ tên</th>
                    <th>Logo</th>
                    <th>Tên thương hiệu</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }



    const handleFindCreator = async (e)=> {
        if(e.target.value.trim() !== ''){
            let [err, data] = await MarketingServices.GetFindPartner({
                pageNumber: 1,
                pageSize: 5000,
                keyword: e.target.value
            });
            if (!err && data) {
                setResult(data.listObjects);
            } else {
                setResult([]);
            }
        }else{
            setResult([])
        }
        
    }

    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...data];
                            dt[index].logo = res.data.imageUrl;
                            setData(dt);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 150,  textAlign: "center" }}>
                                    <img src={item.avatar} width={100} height={100} />
                                </td>
                                <td style={{ width: 200 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <div className="input-creator">
                                            <input
                                                className="input-in-table"
                                                type="text"
                                                placeholder="Nhập tên đối tác"
                                                onChange={(e) => {
                                                    handleFindCreator(e)
                                                }}
                                            />
                                            <span>{item.firstName && item.lastName ? "Đã chọn: " + item.firstName + " " + item.lastName : ""}</span>
                                            {result && result.length > 0 &&
                                                <div onClick={(e)=> e.stopPropagation()} className="result-search">
                                                    <div key={idx} className="result-item header">
                                                        <div style={{width: 40}}>Ảnh</div>
                                                        <span>Họ tên</span>
                                                        <span>Email</span>
                                                    </div>
                                                    {result.map((itm, i) => {
                                                        return <div key={i}
                                                            onClick={()=>{
                                                                if(itm.userId == creatorId){
                                                                    Notify(NOTIFY.WARNING , NOTIFY.WARNING , "Trùng với creator chính")
                                                                }else{
                                                                    if(!data.some(i=> i.creatorId === itm.userId)){
                                                                        let dt = [...data]
                                                                        dt[idx].creatorId = itm.userId
                                                                        dt[idx].avatar = itm.avatar
                                                                        dt[idx].firstName = itm.firstName
                                                                        dt[idx].lastName = itm.lastName
                                                                        setData(dt)
                                                                        setResult([])
                                                                    }
                                                                    else{
                                                                        Notify(NOTIFY.WARNING , NOTIFY.WARNING , "Đã có creator này")
                                                                    }
                                                                }
                                                                
                                                            }}
                                                        className="result-item">
                                                            <img src={itm.avatar} />
                                                            <span>{itm.firstName + " " + itm.lastName}</span>
                                                            <span>{itm.email}</span>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    ) : (
                                        item.firstName + ' ' + item.lastName
                                    )}
                                </td>
                                <td>
                                {item.id <= 0 || item.isUpdate ? (
                                        <div>
                                            {item.logo ? (
                                                <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                    <img style={{ maxWidth: 100 , maxHeight: 100 }} src={item.logo} />
                                                    <span style={{ position: 'absolute', top: 10 , right: 10 , fontSize: 30 , lineHeight: 0.5}} onClick={()=>{
                                                        let dt = [...data]
                                                        dt[idx].logo = ""
                                                        setData(dt)
                                                    }}>
                                                        &times;
                                                    </span>

                                                </label>
                                            ) : (
                                                ""
                                            )}
                                            <input
                                                style={{maxWidth: 90}}
                                                accept="image/*"
                                                hidden={item.logo ? true : false}
                                                type="file"
                                                id={`upload-${idx}`}
                                                onChange={(e) => {
                                                    handleUploadFile(e, idx, data);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <img style={{ maxWidth: 100, maxHeight: 100 }} src={item.logo} />
                                    )}
                                </td>
                                <td>
                                {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Tên thương hiệu"
                                            value={item.brandName}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].brandName = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.brandName
                                    )}
                                </td>

                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data]
                                                dt[idx].isUpdate = true
                                                setData(dt)
                                            }}
                                        >
                                           Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    {data && data.length < 1 && <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm đối tác +
                        </div>
                    </td>}
                </tr>
            </tbody>
        );
    }

    function _renderAction() {
        return <h6>Partner Collab</h6>
    }

    async function handleAddItem() {
            setData([ { 
                id: 0,
                creatorId: 0,
                marketingCampaignId: campaignId,
                isUpdate: true
            }, ...data]);
        
       
    }

    function IsDisable(idx) {
        if ( data[idx].creatorId <= 0  || !data[idx].logo || !data[idx].brandName ) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx]};
        let [err, dt] = await MarketingServices.CreateUpdatePartnerCollab(dataItem);
        if (!err && dt) {
            getData(campaignId);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Trùng url");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeletePartnerCollab(id);
                    if (!error && data != -1) {
                        if(data == 1){
                            resolve({ title: "Thành công", msg: "Xóa thành công." });
                            getData(campaignId);
                        }
                        if(data == -7){
                            Notify(NOTIFY.WARNING, "Có lỗi", "Chiến dịch đã chạy , không thể xóa");
                        }
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    return (
        <MarketingWapper onClick={()=>{setResult([])}}>
            <Loading show={isBusy} msg={msg} />
            <div className="width-50">
                <CommonTable data={data} paging={paging} hideSearchBasicButton 
                    renderAction={_renderAction} 
                    renderHeader={_renderHeader} 
                    renderBody={_renderBody} 
                    ref={tableRef} 
                />
            </div>
            
        </MarketingWapper>
    )
}


const MarketingWapper = styled.div`
    .width-50{
        max-width: 60%;
        margin-top: 20px;

        .content-table {
            min-height: 500px;
        }
    }
    .container-config{
        display: flex;
        align-items: center;
        flex-wrap: wrap;



        .config-col-50{
            width: 50%;
            max-width: 50%;
            background-color: #fff;
            &.p-15{
                padding: 15px;
            }
            h5{
                color: #666F7B;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
            .box-button{
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: end;
            }

            &.flex-box{
                display: flex;
                align-items: center;
                margin-top: 10px;
                span{
                    display: block;
                    width: 80px;

                }
                input{
                    width: 77px;
                    height: 31px;
                    border: 1.5px solid #D9D9D9;
                    background: #FFF;
                    padding: 0 10px;
                }
            }


        }
    }


    
    .input-creator{
        width: 100%;
        position: relative;

        input{
            width: 100%;
            border: none;
            outline: none;
            height: 40px;
            padding: 0;
            ::placeholder{
                opacity: 0.5;
            }
        }
        span{
            display: block;
            width: 100%;
        }

        .result-search{
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 400px;
            overflow-y: auto;
            height: 200px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            padding: 5px;

           

            .result-item{
                width: 100%;
                display: flex;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                align-items: center;

                &.header{
                    font-weight: 600;
                    cursor: default;

                    :hover{
                        background-color: transparent;
                    }
                    
                }

                :hover{
                    background-color: rgba(0, 0, 0, 0.1);
                }

                img{
                    height: 40px;
                    width: 40px;
                    object-fit: contain;

                }
                
                span{
                    display: block;
                    width: 150px;
                    padding: 0 10px;
                }

            }
        }
    }

    .box-banner{
        display: flex;


        .box-banner-item{
            width: 50%;

        }
    }
`;
export default MarketingPartnerCollab;
