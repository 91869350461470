import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Rating from 'react-rating';
import Common from '../../based/Common';
import { CONSTANTS, NOTIFY, PLATFORM } from '../../based/Constants';
import { ConfirmDialog } from '../../based/Dialog';
import Checkbox from '../../based/inputs/CheckBox';
import TextArea from '../../based/inputs/TextArea';
import { Notify } from '../../based/Notify';
import Request from '../../based/Request';
import BaseServices from '../../based/services/BaseServices';
import ProductReviewModal from './components/ProductReviewModal';
import ResourceModal from './components/ResourceModal';
const REFUND_STATUS = {
    APPROVED: 1,
    DECLINED: 3
}
const MonthDetail = ({ monthData, onBack, thirdPartyId }) => {
    const [busy, setBusy] = useState(false);
    const [editingData, setEditingData] = useState(null);
    const [declineReason, setDeclineReason] = useState(null);
    const [showDeclineForm, setShowDeclineForm] = useState(false);
    const [showResourceModal, setShowResourceModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [sharingId, setSharingId] = useState(0);
    const [selectedResources, setSelectedResources] = useState([]);
    const _handleReject = (sharingResultId, pId, urlId) => {
        setShowDeclineForm(true);
        setDeclineReason({
            sharingResultId: sharingResultId,
            pId: pId,
            urlId: urlId,
            marketingUrl: monthData.sharingProductModels[pId].sharingResultModels[urlId].marketingUrl
        })
    }
    const _handleSubmitDecline = async () => {
        const [err, data] = await BaseServices.Post('/api/affiliate/reject-sharing-result/' + declineReason.sharingResultId, declineReason);
        if (!err) {
            var obj = { ...monthData.sharingProductModels[declineReason.pId].sharingResultModels[declineReason.urlId] };
            obj.isRejected = true;
            monthData.sharingProductModels[declineReason.pId].sharingResultModels[declineReason.urlId] = obj;
            setShowDeclineForm(false);
            setDeclineReason(null);
        }
    }
    const _handleEdit = async (shareModel, pId, urlId) => {
        if (editingData) {
            //submit:
            console.log('submit data: ', editingData);
            monthData.sharingProductModels[pId].sharingResultModels[urlId] = editingData;
            let [err, data] = await BaseServices.Post('/api/affiliate/update-sharing-result', editingData);
            if (!err) {
                setEditingData(null);
            }
        }
        else {
            setEditingData({ ...shareModel })
        }
    }

    const _handleCrawlUrl = async (sharingResultId, pId, urlId) => {
        setBusy(true);
        let submitData = monthData.sharingProductModels[pId].sharingResultModels[urlId];
        console.log('submit data: ', submitData);
        let [err, data] = await BaseServices.Post('/api/affiliate/crawl-marketing-url/' + sharingResultId);
        if (!err) {
            let oldData = monthData.sharingProductModels[pId].sharingResultModels[urlId];
            oldData.viewCount = data.viewCount;
            oldData.likeCount = data.likeCount;
            oldData.commentCount = data.commentCount;
            oldData.shareCount = data.shareCount;
            oldData.thumbnail = data.thumbnail;
            oldData.title = data.title;
            Notify(NOTIFY.SUCCESS, "Thành công", "Đã cập nhật số lượng tương tác");
        }
        setBusy(false);
    }
    const _handleInputChanged = (index, value) => {
        let arr = [...editingData.reviewResults];
        arr[index].requirementResult = value;
        let newState = { ...editingData };
        newState.reviewResults = arr;
        setEditingData(newState)
    }
    const _handleChangeRefundStatus = (product, status) => {
        let msg = status == REFUND_STATUS.APPROVED ? "Bạn có chắc sẽ hoàn tiền cho sản phẩm này?" : "Bạn có chắc sản phẩm này KHÔNG được hoàn tiền?"
        ConfirmDialog('Xác nhận?', msg, () => {
            return new Promise((resolve, reject) => {
                setBusy(true)
                Request.Post(`/api/affiliate/update-cashback-status/${product.sharingId}/${status}`)
                    .then(res => {
                        if (res.success && res.data) {
                            resolve({ title: 'Thành công', msg: 'Cập nhật thành công.' })
                            product.refundStatus = status;
                            setBusy(false)
                        }
                        else {
                            setBusy(false)
                            Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                        }
                    })
                    .catch(err => {
                        setBusy(false)
                    });
            })
        });
    }
    const _renderPlatform = (platform) => {
        switch (platform) {
            case PLATFORM.Internal:
                return "RANUS"
            case PLATFORM.Facebook:
                return "Facebook"
            case PLATFORM.Tiktok:
                return "Tiktok"
            case PLATFORM.Lazada:
                return "Lazada"
            case PLATFORM.Shopee:
                return "Shopee"
            default:
                return '';
        }
    }
    const _renderRefundStatus = (status) => {
        switch (status) {
            case REFUND_STATUS.APPROVED:
                return <strong className='text-custom'>Chấp nhận hoàn tiền</strong>
            case REFUND_STATUS.DECLINED:
                return <strong className='text-danger'>Không hoàn tiền</strong>

            default:
                return <strong className='text-custom'>Đang review</strong>
        }
    }
    const _handleShowResources = (resources) => {
        setSelectedResources(resources);
        setShowResourceModal(true);
    }

    const _handleShowProductReview = async (sharingId) => {
        setSharingId(sharingId);
        setShowReviewModal(true);
    }
    return (
        <div>
            <div>
                <button className='btn btn-custom btn-sm m-b-5' onClick={onBack}>Quay lại</button>
            </div>
            <div className='card-box p-10'>
                <div className='row'>
                    <div className='col-4'>
                        <div>
                            <strong>Ngày gửi sản phẩm:</strong> <span>{monthData.registedDate}</span>
                        </div>
                        <div>
                            <strong>Sản phẩm đã mua:</strong> <span>{monthData.boughtProductCount}/{monthData.quota}</span>
                        </div>
                        <div>
                            <strong>Nhiệm vụ hoàn thành:</strong> <span>{monthData.completedRequirementsCount}/{monthData.totalRequirementsCount}</span>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div><i className='fa fa-thumbs-o-up'></i> {monthData.totalLike ? monthData.totalLike : 0} lượt yêu thích</div>
                        <div><i className='fa fa-comment-o'></i> {monthData.totalComment ? monthData.totalComment : 0} lượt bình luận</div>
                        <div><i className='fa fa-share-alt'></i> {monthData.totalShare ? monthData.totalShare : 0} lượt chia sẻ</div>
                        <div>Đánh giá tổng lượng tương tác: <strong className='text-custom'>Bình thường</strong></div>
                    </div>
                </div>
            </div>
            <h4>Thống kê chi tiết</h4>
            {
                monthData.sharingProductModels && monthData.sharingProductModels.map((product, idx) => {
                    return <div className='card-box p-10' key={idx}>
                        <div className='row'>
                            <div className='col-4'>
                                <label>Sản phẩm {idx + 1}</label>
                                {
                                    product.isBought && <div className='row'>
                                        <div className='col-4'>
                                            <div><strong>Tên SP:</strong></div>
                                            <div><strong>Mua tại:</strong></div>
                                            <div><strong>Mã đơn:</strong></div>
                                            <div><strong>Ngày mua:</strong></div>
                                            <div><strong>Ngày nhận:</strong></div>
                                            <div><strong>Hoàn tiền:</strong></div>
                                            <div><strong>Đánh giá SP:</strong></div>
                                            <div><strong>Link Ảnh/Video gốc:</strong></div>
                                            <div><strong>Trạng thái:</strong></div>
                                        </div>
                                        <div className='col-8'>
                                            <div><strong>{product.productName}</strong></div>
                                            <div><strong>{_renderPlatform(product.boughtSource)}</strong></div>
                                            <div><strong>{product.boughtOrderNumber}</strong></div>
                                            <div><strong>{product.boughtDateStr}</strong></div>
                                            <div><strong>{product.shippedDateStr ? product.shippedDateStr : 'Chưa nhận hàng'}</strong></div>
                                            <div><strong>{Common.formatCurrency(product.productPrice)}</strong></div>
                                            {
                                                !product.isReviewed ? <div>
                                                    <a className='text-link' onClick={() => _handleShowProductReview(product.sharingId)}>Xem review</a>
                                                </div>
                                                    :
                                                    <div><strong>Chưa review SP</strong></div>
                                            }
                                            <div><a className='text-link' onClick={() => _handleShowResources(product.resourceUrls)}>Xem</a></div>
                                            <div><strong> {_renderRefundStatus(product.refundStatus)}</strong></div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='col-8'>
                                {
                                    product.sharingResultModels && product.sharingResultModels.map((share, i) => {
                                        return <div className='border p-10 m-b-10' key={i}>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <a href={share.marketingUrl}>{share.title ? share.title : share.marketingUrl}</a>
                                                    {
                                                        share.thumbnail && <img src={share.thumbnail} alt={share.title} style={{ width: '100%' }} />
                                                    }
                                                    {
                                                        editingData && editingData.sharingResultId == share.sharingResultId ?
                                                            <div>
                                                                <div style={{ display: 'inline-flex' }}>
                                                                    <div className='m-r-10' style={{ width: 100, display: 'inline-flex' }}>
                                                                        <i className='fa fa-thumbs-o-up'></i>
                                                                        <input type={'number'} style={{ width: '100%' }}
                                                                            id={editingData.sharingResultId}
                                                                            name={editingData.sharingResultId}
                                                                            value={editingData.likeCount}
                                                                            onChange={(e) => setEditingData({ ...editingData, likeCount: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className='m-r-10' style={{ width: 100, display: 'inline-flex' }}>
                                                                        <i className='fa fa-comment-o'></i>
                                                                        <input type={'number'} style={{ width: '100%' }}
                                                                            id={editingData.sharingResultId}
                                                                            name={editingData.sharingResultId}
                                                                            value={editingData.commentCount}
                                                                            onChange={(e) => setEditingData({ ...editingData, commentCount: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className='m-r-10' style={{ width: 100, display: 'inline-flex' }}>
                                                                        <i className='fa fa-share-alt'></i>
                                                                        <input type={'number'} style={{ width: '100%' }}
                                                                            id={editingData.sharingResultId}
                                                                            name={editingData.sharingResultId}
                                                                            value={editingData.shareCount}
                                                                            onChange={(e) => setEditingData({ ...editingData, shareCount: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <span className='m-r-10'>
                                                                    <i className='fa fa-eye'></i>
                                                                    {share.viewCount}
                                                                </span>
                                                                <span className='m-r-10'>
                                                                    <i className='fa fa-thumbs-o-up'></i>
                                                                    {share.likeCount}
                                                                </span>
                                                                <span className='m-r-10'>
                                                                    <i className='fa fa-comment-o'></i>
                                                                    {share.commentCount}
                                                                </span>
                                                                <span className='m-r-10'>
                                                                    <i className='fa fa-share-alt'></i>
                                                                    {share.shareCount}
                                                                </span>
                                                            </div>
                                                    }
                                                    {
                                                        share.rejectReason && <div>
                                                            <strong className='text-danger'>{share.rejectReason}</strong>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='col-8'>
                                                    {
                                                        share.reviewResults && share.reviewResults.map((result, ii) => {
                                                            return <Checkbox key={ii}
                                                                id={share.sharingResultId + '.' + result.requirementId}
                                                                name={share.sharingResultId + '.' + result.requirementId}
                                                                checked={editingData && editingData.sharingResultId == share.sharingResultId ? editingData.reviewResults[ii].requirementResult : result.requirementResult}
                                                                onCheckedChange={(name, value) => _handleInputChanged(ii, value)}
                                                                label={result.requirementContent}
                                                                readOnly={!(editingData && editingData.sharingResultId == share.sharingResultId)} />
                                                        })
                                                    }
                                                    <div className='text-right'>
                                                        <button className={share.isRejected ? 'btn btn-custom btn-sm m-r-5 disabled' : 'btn btn-custom btn-sm m-r-5'}
                                                            onClick={() => _handleCrawlUrl(share.sharingResultId, idx, i)}
                                                            disabled={share.isRejected}>
                                                            Crawl data
                                                        </button>
                                                        <button className={share.isRejected ? 'btn btn-custom btn-sm m-r-5 disabled' : 'btn btn-custom btn-sm m-r-5'}
                                                            onClick={() => _handleEdit(share, idx, i)}
                                                            disabled={share.isRejected}>
                                                            {editingData && editingData.sharingResultId == share.sharingResultId ? 'Hoàn thành' : 'Cập nhật'}
                                                        </button>
                                                        <button className={share.isRejected ? 'btn btn-danger btn-sm m-r-5 disabled' : 'btn btn-danger btn-sm m-r-5'}
                                                            onClick={() => _handleReject(share.sharingResultId, idx, i)}
                                                            disabled={share.isRejected}>Từ chối</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                        <div className='text-right'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={(e) => _handleChangeRefundStatus(product, REFUND_STATUS.APPROVED)}>Đồng ý hoàn tiền</button>
                            <button className='btn btn-danger btn-sm m-r-5' onClick={(e) => _handleChangeRefundStatus(product, REFUND_STATUS.DECLINED)}>Từ chối hoàn tiền</button>
                        </div>
                    </div>
                })
            }
            <Modal show={showDeclineForm} size={'lg'} enforceFocus={false}>
                <Modal.Header>
                    <button type='button' className='close' onClick={() => setShowDeclineForm(false)}>
                        <span aria-hidden='true'>×</span>
                        <span className='sr-only'>Close</span>
                    </button>
                    <Modal.Title>Từ chối duyệt, yêu cầu cung cấp lại thông tin</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-20'>
                    <div className='form-group'>
                        <label className='color_gray'>Lý do từ chối:</label>
                        <TextArea name='declinedReason' required value={declineReason ? declineReason.rejectReason : ''} minLength={6} className='form-control'
                            onChanged={(name, value) => setDeclineReason({ ...declineReason, rejectReason: value })} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-custom waves-light' onClick={_handleSubmitDecline}>
                        Lưu
                    </button>
                    <button className='btn btn-inverse waves-light' onClick={() => setShowDeclineForm(false)}>
                        Hủy
                    </button>
                </Modal.Footer>
            </Modal>
            <ResourceModal show={showResourceModal} onClose={() => setShowResourceModal(false)} resources={selectedResources} />

            {/* Modal product review */}
            <ProductReviewModal show={showReviewModal} onClose={() => setShowReviewModal(false)} sharingId={sharingId} thirdPartyId={thirdPartyId} />
        </div>
    );
};

export default MonthDetail;