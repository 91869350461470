import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../../based/Loading";
import CheckBox from "../../../based/inputs/CheckBox";
import TFUSelect from "../../../based/inputs/TFUSelect";
import TextNumber from "../../../based/inputs/TextNumber";
import Textbox from "../../../based/inputs/Textbox";
import BaseServices from "../../../based/services/BaseServices";
import useFormValidate from "../../../hooks/useFormValidate";

const AddOrEditSlotModal = ({ slot, show, onSave, onClose, slots }) => {
    const [isBusy, setBusy] = useState(false);
    const { addRef, isValid, displayError } = useFormValidate();
    const [dataModel, setDataModel] = useState(slot);
    const [directions, setDirections] = useState([]);
    const [products, setProducts] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [noSelectedProducts, setNoSelectedProducts] = useState(false);
    useEffect(() => {
        let directions = [
            {
                value: 0,
                label: "--Chọn hướng quay--",
            },
        ];
        for (let i = 1; i <= 8; i++) {
            directions.push({
                value: i,
                label: `Hướng ${i}`,
            });
        }
        setDirections(directions);
        if (show) {
            _fetchProducts();
            setDataModel(slot);
        }
    }, [show]);

    const _fetchProducts = async () => {
        setBusy(true);
        const [err, data] = await BaseServices.Get("/api/uvprint/get-print-product-models?slotId=" + slot.id);
        if (!err) {
            if (slot.products) {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (slot.products.filter((m) => m.productId == element.productId).length > 0) element.selected = true;
                }
            }
            setProducts(data);
        }
        setBusy(false);
    };

    function _handleInputChanged(name, value) {
        let model = { ...dataModel };
        model[name] = value;
        setDataModel(model);
    }
    const _handleSave = async () => {
        if (isValid()) {
            let index = dataModel.index;
            let existed = false;
            for (let i = 0; i < slots.length; i++) {
                const element = slots[i];
                if (i != index && element.positionIndex == dataModel.positionIndex - 1) {
                    existed = true;
                    break;
                }
            }
            if (existed) {
                let err = new Map();
                err.set("positionIndex", "Vị trí này đã được thiết lập.");
                displayError(err);
                return;
            }
            let data = { ...dataModel };
            data.products = products.filter((m) => m.selected);
            data.positionIndex -= 1;
            if (data.products.length == 0) {
                setNoSelectedProducts(true);
                return;
            }
            onSave(data);
        }
    };
    const _handleSelectProduct = (name, value) => {
        let idx = name.split("_")[1];
        let prds = [...products];
        prds[idx] = { ...prds[idx], selected: value };
        setProducts(prds);
        if (value) {
            setNoSelectedProducts(false);
        }
    };
    let filterProducts = keyword && products ? products.filter((m) => m.productName.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) : products;
    return (
        <Modal show={show} size={"lg"} enforceFocus={false}>
            <Loading show={isBusy} msg={"Đang lưu..."} />
            <Modal.Header>
                <button type="button" className="close" onClick={onClose}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
                <Modal.Title>{dataModel.id > 0 ? "Cập nhật thông tin máy in UV" : "Thêm mới máy in UV"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-20">
                <div className="row">
                    <div className="col-12">
                        <div className="sub-header">
                            - Gốc tọa độ của bàn in là góc ngoài cùng bên phải. <br />
                            - X dương hình đi sang trái <br />
                            - Y dương hình đi lên <br />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Thứ tự trên bàn in</label>
                                    <TextNumber name="positionIndex" min={1} required ref={addRef} onChanged={_handleInputChanged} value={dataModel.positionIndex} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Hướng quay</label>
                                    {/* <select value={dataModel.direction} name='direction' ref={addRef}>
                                        <option>--Chọn hướng quay--</option>
                                        {
                                            directions && directions.map((m, i) => {
                                                return <option key={m.value} value={m.value}>{m.label}</option>
                                            })
                                        }
                                    </select> */}
                                    <TFUSelect name="direction" min={1} required ref={addRef} options={directions} onChanged={(value) => _handleInputChanged("direction", value)} value={dataModel.direction} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label>Tọa độ điểm O (Gốc tọa độ của vị trí gá) so với gốc tọa độ của bàn in </label>
                            </div>
                            <div className="col-12">
                                <div className="row form-group">
                                    <div className="col-6">
                                        <div className="d-flex align-items-baseline">
                                            <label className="mr-1">X</label>
                                            <div>
                                                <Textbox name="x" required ref={addRef} value={dataModel.x} onChanged={_handleInputChanged} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex align-items-baseline">
                                            <label className="mr-1">Y</label>
                                            <div>
                                                <Textbox name="y" required ref={addRef} value={dataModel.y} onChanged={_handleInputChanged} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <label>Tọa độ hiệu chỉnh (Calibrate) X Y của vị trí (Đơn vị: 1mm)</label>
                            </div>
                            <div className="col-12">
                                <div className="row form-group">
                                    <div className="col-6">
                                        <div className="d-flex align-items-baseline">
                                            <label className="mr-1">Calibrate X</label>
                                            <div>
                                                <Textbox name="calX" required ref={addRef} value={dataModel.calX} onChanged={_handleInputChanged} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex align-items-baseline">
                                            <label className="mr-1">Calibrate Y</label>
                                            <div>
                                                <Textbox name="calY" required ref={addRef} value={dataModel.calY} onChanged={_handleInputChanged} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <label>Chú thích</label>
                            </div>
                            <div className="col-12">
                                <div className="box-border p-10 my-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <img src="/images/uv-guideline.jpg" width={"100%"} />
                                        </div>
                                        <div className="col-6">
                                            <img src="/images/direction.png" width={"100%"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <label>Chọn sản phẩm có thể in</label>
                        <div className="form-inline">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={keyword}
                                    onChange={(e) => {
                                        setKeyword(e.target.value);
                                    }}
                                />
                                <button className="btn btn-default btn-sm ml-3" onClick={() => {}}>
                                    <i className="fa fa-search" />
                                </button>
                            </div>
                        </div>
                        <div className="box-border p-10">
                            {filterProducts &&
                                filterProducts.length > 0 &&
                                filterProducts.map((p, i) => {
                                    return <CheckBox name={`product_${i}`} checked={p.selected} label={p.productName} onCheckedChange={_handleSelectProduct} key={i} />;
                                })}
                        </div>
                        {noSelectedProducts && <label className="text-danger full-width">Vui lòng chọn ít nhất một sản phẩm.</label>}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-custom waves-light btn-sm" onClick={_handleSave}>
                    Lưu
                </button>
                <button className="btn btn-inverse waves-light btn-sm" onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddOrEditSlotModal;
