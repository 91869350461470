import React, { Component } from "react";
import PropTypes from "prop-types";
import InputValidation from "./InputValidation";
class Textbox extends InputValidation {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        required: false,
        valid: true,
        email: false,
        className: "form-control",
        minLength: 0,
        maxLength: 524288,
        value: "",
        readOnly: false,
        allowCopy: false,
    };

    static propTypes = {
        id: PropTypes.string,
        required: PropTypes.bool,
        requiredMsg: PropTypes.string,
        valid: PropTypes.bool,
        invalidMessage: PropTypes.string,
        maxLength: PropTypes.number,
        maxLengthMsg: PropTypes.string,
        minLength: PropTypes.number,
        minLengthMsg: PropTypes.string,
        email: PropTypes.bool,
        onChanged: PropTypes.func,
        onBlur: PropTypes.func,
        onKeyUp: PropTypes.func,
        value: PropTypes.string,
        readOnly: PropTypes.bool,
        allowCopy: PropTypes.bool,
    };
    handleBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    handleCopy() {
        var copyText = document.getElementById(this.props.id);
        copyText.select();
        document.execCommand("copy");
    }

    render() {
        let classInput = this.props.className +' input__rounded';
        if (!this.state.isValid) {
            classInput += " alert-danger";
        }
        return (
            <React.Fragment>
                {this.props.prependText ? (
                    <div className='input-group'>
                        <div className='input-group-prepend'>
                            <span className={`input-group-text ${this.props.classNameInputText}`}>{this.props.prependText}</span>
                        </div>
                        <input
                            autoComplete='off'
                            id={this.props.id}
                            name={this.props.name}
                            className={classInput}
                            type={this.props.number ? "number" : this.props.password ? "password" : "text"}
                            placeholder={this.props.placeholder ? this.props.placeholder : null}
                            onChange={this.handleChanged}
                            value={this.props.value ? this.props.value : ""}
                            readOnly={this.props.readOnly}
                            onFocus={this.handleFocus}
                            onKeyUp={this.handleKeyUp}
                            onBlur={this.handleBlur.bind(this)}
                            maxLength={this.props.maxLength ? this.props.maxLength : null}
                        />
                    </div>
                ) : (
                    <input
                        autoComplete='off'
                        id={this.props.id}
                        name={this.props.name}
                        className={classInput}
                        type={this.props.number ? "number" : this.props.password ? "password" : "text"}
                        placeholder={this.props.placeholder ? this.props.placeholder : null}
                        onChange={this.handleChanged}
                        value={this.props.value ? this.props.value : ""}
                        readOnly={this.props.readOnly}
                        onFocus={this.handleFocus}
                        onKeyUp={this.handleKeyUp}
                        onBlur={this.handleBlur.bind(this)}
                        maxLength={this.props.maxLength ? this.props.maxLength : null}
                    />
                )}

                {this.props.allowCopy ? (
                    <div className='input-group-append cursor-pointer' onClick={this.handleCopy.bind(this)}>
                        <span className='input-group-text'>
                            <i class='fa fa-clipboard'></i>
                        </span>
                    </div>
                ) : (
                    ""
                )}
                {this.state.isValid ? null : (
                    <label className='text-danger full-width' htmlFor={this.props.id}>
                        {this.state.msgInvalid}
                    </label>
                )}
            </React.Fragment>
        );
    }
}
export default Textbox;
