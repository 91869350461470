import React, { useEffect, useState } from 'react';
import { CONSTANTS, NOTIFY } from '../based/Constants';
import Textbox from '../based/inputs/Textbox';
import Loading from '../based/Loading';
import { Notify } from '../based/Notify';
import Request from '../based/Request';
import useFormValidate from '../hooks/useFormValidate';

export default function ForgetPasswod(props) {
    const [isBusy, setIsBusy] = useState(false)
    const [msg, setMsg] = useState('')
    const { addRef, displayError, isValid } = useFormValidate()

    const [email, setEmail] = useState('')
    const [isShowFlashNote, setIsShowFlashNote] = useState(false)

    useEffect(() => {

    }, [])

    function _handleForgetPass(e) {
        e.preventDefault()
        if (isValid()) {
            setIsBusy(true)
            setMsg('Đang gửi dữ liệu...')
            Request.Post('/api/accounts/forgot-password', { email })
                .then(res => {
                    if (res.success) {
                        setIsBusy(false)
                        setMsg(null)
                        setIsShowFlashNote(true)
                    } else {
                        Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
                        setIsBusy(false)
                        setMsg(null)
                    }
                })
                .catch(err => {
                    setIsBusy(false)
                    setMsg(null)
                    if (err.errors) displayError(err.errors)
                });
        }
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={msg} />
            <section className='bg-account'>
                <div className='page-header mt-5 ml-5'>
                    <img alt='Lotus' src='/images/logo-lotus.png' />
                </div>
                <div className='account-content'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='col-left'>
                            <img atl='lotus' src='/images/background-image.png' style={{ height: 650 }} />
                        </div>
                        <div className='col-right'>
                            <div className='main-account'>
                                <h4 className='font-weight-bold text-center text-dark mb-20'>Quên mật khẩu</h4>
                                <p className="text-dark ml-3 text-center">Sử dụng email đăng ký tài khoản để thực hiện</p>
                                <div className='form-group position-relative'>
                                    {
                                        isShowFlashNote ?
                                            <div className="form-group  row">
                                                <div className="col-12">
                                                    <div className="alert alert-icon text-info alert-dismissible flash-notice">
                                                        <button type="button" className="close" onClick={() => setIsShowFlashNote(false)}>
                                                            <span>×</span>
                                                        </button>
                                                        <i className="mdi mdi-information mr-2"></i>
                                                        Nếu địa chỉ email của bạn tồn tại trong hệ thống của chúng tôi, bạn sẽ nhận được liên kết khôi phục mật khẩu tại địa chỉ email của bạn sau vài phút!
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <div className="form-group  row">
                                        <div className="col-12">
                                            <label htmlFor="email" className='required'>Email</label>
                                            <Textbox id='email' name='email' value={email} required email
                                                ref={addRef} maxLength={255}
                                                className='form-control' onChanged={(name, value) => setEmail(value)} />
                                        </div>
                                    </div>
                                    <div className="form-group  row">
                                        <div className="col-12">
                                            <a className="btn-lotus btn-md btn-block btn-dark ml-0 waves-effect waves-light" href='#' onClick={(e) => _handleForgetPass(e)}>Đặt lại mật khẩu</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-sm-12 text-center">
                                        <p className="text-muted mb-0">Bạn đã có tài khoản? <a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/login'); }} className="text-dark ml-1"><b>Đăng nhập</b></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}