import BaseServices from "./BaseServices";

const CreatorServices = {
    GetCreators: async (paging) => {
        return await BaseServices.Post("/api/Creator/get-creators", paging);
    },
    SaveCreator: async (obj) => {
        return await BaseServices.Post("/api/Creator/save-creator", obj);
    },
    DeleteCreators: async (ids) => {
        return await BaseServices.Post("/api/Creator/delete-creators", ids);
    },
    UpdateCreatorsLevel: async (ids, level) => {
        return await BaseServices.Post("/api/Creator/update-creators-level?level=" + level, ids);
    },
    // Collections
    GetCollectionsByCreatorId: async (paging, creatorId) => {
        return await BaseServices.Post(`/api/Creator/get-collections-by-creator-id/${creatorId}`, paging);
    },
    GetAutoCollectionById: async (creatorCollectionId) => {
        return await BaseServices.Get(`/api/Collection/get-collection-by-id/${creatorCollectionId}`);
    },
    GetListCollection: async (paging) => {
        return await BaseServices.Post(`/api/Collection/get-list-collection`, paging);
    },
    GetCollectionById: async (id) => {
        return await BaseServices.Get(`/api/Creator/get-collection-by-id/${id}`);
    },
    CreateCollection: async (model) => {
        return await BaseServices.Post("/api/Creator/create-collection", model);
    },
    DeleteCollection: async (id) => {
        return await BaseServices.Get(`/api/Creator/delete-collection/${id}`);
    },
    CreateUpdateCollection: async (model) => {
        return await BaseServices.Post(`/api/Collection/create-update-collection`, model);
    },
    DeleteAutoCollection: async (creatorCollectionId) => {
        return await BaseServices.Get(`/api/Collection/delete-collection/${creatorCollectionId}`);
    },
    // Image
    GetMockupImagesByArtworkId: async (paging, artworkId) => {
        return await BaseServices.Post(`/api/Creator/get-mockup-images-by-artwork-id/${artworkId}`, paging);
    },
    GetArtworksByCreator: async (paging) => {
        return await BaseServices.Post(`/api/Creator/get-artworks-by-user-name`, paging);
    },
    GetConfigByLevel: async (creator) => {
        return await BaseServices.Get(`/api/Creator/get-config-by-level?creator=${creator}`);
    },
};

export default CreatorServices;
