import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Request from '../based/Request';
import { NOTIFY, CONSTANTS } from '../based/Constants';
import { Notify } from '../based/Notify';
import Common from '../based/Common';
import CommonModal from '../based/Modal';
import Textbox from '../based/inputs/Textbox';
import TextNumber from '../based/inputs/TextNumber';
import CheckBox from '../based/inputs/CheckBox';
import TextArea from '../based/inputs/TextArea';
import CommonServices from '../based/services/CommonServices';
import useFormValidate from '../hooks/useFormValidate';
import TFUSelectSubdivisions from '../based/TFUSelectSubdivisions';
import { WareslotsModel } from '../models/WarehousesModel';
import PhoneNumber from '../based/inputs/PhoneNumber';

export default function WarehouseModal(props) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [msgModalLoading, setMsgModalLoading] = useState(null);
  const [warehouse, setWarehouse] = useState({});

  const [Districts, setDistricts] = useState([]);
  const [Wards, setWards] = useState([]);

  const { addRef, displayError, isValid } = useFormValidate();

  useEffect(() => {
    let warehouseUp = props.warehouse;
    setWarehouse(warehouseUp);
    if (warehouseUp.id > 0) {
      if (!warehouseUp.warehouseRegions) warehouseUp.warehouseRegions = [];
      if (warehouseUp.provinceCode) _handleChangedTFUSelectProvince(warehouseUp.provinceCode, warehouseUp);
      if (warehouseUp.districtCode) _handleChangedTFUSelectDistrict(warehouseUp.districtCode, warehouseUp);
    } else {
      setDistricts([]);
      setWards([]);
    }
  }, [props.isShowModal]);

  function _handleSave() {
    if (warehouse && isValid()) {
      Request.Post('/api/Warehouse/create-or-update-warehouse', warehouse)
        .then((res) => {
          if (res.success) {
            Notify(NOTIFY.SUCCESS, 'Thành công', 'Lưu thành công');
            setIsModalLoading(false);
            setMsgModalLoading(null);
            props.onSaveSucceed();
          } else {
            Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
            setIsModalLoading(false);
            setMsgModalLoading(null);
          }
        })
        .catch((err) => {
          if (err.errors) displayError(err.errors);
          setIsModalLoading(false);
          setMsgModalLoading(null);
        });
    }
  }
  async function _handleChangedTFUSelectProvince(val, whUp) {
    setIsModalLoading(true);
    setMsgModalLoading('Đang tải dữ liệu...');
    let warehouseUp = { ...(whUp ? whUp : warehouse), provinceCode: val };
    const [err, dataDist] = await CommonServices.GetDistricts(val);
    if (!err) {
      warehouseUp.districtCode = 0;
      warehouseUp.wardCode = 0;
      setWarehouse(warehouseUp);
      setDistricts(dataDist);
      setIsModalLoading(false);
      setMsgModalLoading(null);
    } else {
      setWarehouse(warehouseUp);
      setDistricts([]);
      setIsModalLoading(false);
      setMsgModalLoading(null);
    }
  }
  async function _handleChangedTFUSelectDistrict(val, whUp) {
    setIsModalLoading(true);
    setMsgModalLoading('Đang tải dữ liệu...');
    let warehouseUp = { ...(whUp ? whUp : warehouse), districtCode: val };
    const [err, dataWard] = await CommonServices.GetWards(val);
    if (!err) {
      setWarehouse(warehouseUp);
      setWards(dataWard);
      setIsModalLoading(false);
      setMsgModalLoading(null);
    } else {
      setWarehouse(warehouseUp);
      setWards([]);
      setIsModalLoading(false);
      setMsgModalLoading(null);
    }
  }
  function _handleUpdateRegions(index, regionUp) {
    let warehouseUp = { ...warehouse };
    if (!warehouseUp.warehouseRegions) warehouseUp.warehouseRegions = [];
    warehouseUp.warehouseRegions[index] = regionUp;
    setWarehouse(warehouseUp);
  }

  function handleAddRegion() {
    let warehouseUp = { ...warehouse };
    if (!warehouseUp.warehouseRegions) warehouseUp.warehouseRegions = [];
    warehouseUp.warehouseRegions.unshift({
      regionCode: '',
      slots: [new WareslotsModel()],
    });
    setWarehouse(warehouseUp);
  }

  function _handleRemoveRegion(index) {
    let warehouseUp = { ...warehouse };
    warehouseUp.warehouseRegions.splice(index, 1);
    setWarehouse(warehouseUp);
  }

  return (
    <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isModalLoading} busyMsg={msgModalLoading} size="lg" title={warehouse.id == 0 ? 'Thêm mới' : 'Cập nhật'} onSave={() => _handleSave()} onClose={() => props.onClose()}>
      <div className="form-row div-guide">
        <div className="form-group col-md-12">
          <small>
            <i className="fa fa-info-circle p-r-5"></i>
            Yêu cầu nhập đúng và đầy đủ thông tin. Thông tin kho được sử dụng để tạo shop trên Giao hàng nhanh
          </small>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-8">
          <label htmlFor="name" className="required">
            {' '}
            Tên kho:{' '}
          </label>
          <Textbox id="name" name="name" className="form-control" ref={addRef} required maxLength={255} value={warehouse.name} onChanged={(name, value) => setWarehouse({ ...warehouse, name: value })} />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="phoneNumber" className="required">
            Điện thoại
          </label>
          <PhoneNumber id="phoneNumber" name="phoneNumber" addRef={addRef} required value={warehouse.phoneNumber ? warehouse.phoneNumber : ''} onChanged={(name, value) => setWarehouse({ ...warehouse, phoneNumber: value })} />
        </div>
      </div>
      <TFUSelectSubdivisions
        showProvince
        showDistrict
        showWard
        isRequiredProvince
        isRequiredDistrict
        isRequiredWard
        provinceCode={warehouse.provinceCode}
        districtCode={warehouse.districtCode}
        wardCode={warehouse.wardCode}
        onChanged={(obj) => {
          setWarehouse({ ...warehouse, ...obj });
        }}
        addRef={addRef}
        parentClassName="form-row"
        childClassName="form-group col-md-4"
      />
      <div className="form-row">
        <div className="form-group col-md-12">
          <label htmlFor="location" className="required">
            Địa chỉ kho:
          </label>
          <TextArea id="location" name="location" className="form-control" ref={addRef} required value={warehouse.location} onChanged={(name, value) => setWarehouse({ ...warehouse, location: value })} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-3">
          <label htmlFor="ghnShopId" className="required">
            ShopID (Giao Hàng Nhanh)
          </label>
          <Textbox id="ghnShopId" name="ghnShopId" className="form-control" ref={addRef} required value={warehouse.ghnShopId} onChanged={(name, value) => setWarehouse({ ...warehouse, ghnShopId: value })} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label htmlFor="warehouseArea">Khu vực:</label>
          <button type="button" className="btn btn-xs btn-custom" onClick={handleAddRegion} style={{ marginLeft: 5 }}>
            <i className="mdi mdi-plus" />
          </button>
        </div>
      </div>
      {warehouse && warehouse.warehouseRegions && warehouse.warehouseRegions.length > 0 ? (
        <div className="row">
          {warehouse.warehouseRegions.map((region, r) => {
            return (
              <React.Fragment key={r}>
                <WarehouseSlot addRefParent={addRef} index={r} region={region} onChanged={(regionUp) => _handleUpdateRegions(r, regionUp)} onRemove={() => _handleRemoveRegion(r)} />
              </React.Fragment>
            );
          })}
        </div>
      ) : null}

      <div className="form-group">
        <CheckBox id="isActive" name="isActive" checked={warehouse.isActive ? warehouse.isActive : false} onCheckedChange={(name, value) => setWarehouse({ ...warehouse, isActive: value })} label="Kích hoạt" />
      </div>
    </CommonModal>
  );
}

const WarehouseSlot = (props) => {
  const [region, setRegion] = useState(null);

  useEffect(() => {
    setRegion(props.region);
  }, [props.region]);

  const handleAdd = () => {
    if (!region.slots) region.slots = [];
    region.slots.push(new WareslotsModel());
    props.onChanged({ ...region });
  };

  const handleChange = (index, key, value) => {
    value = RemoveDots(value);
    var newSlots = region.slots.map((s, i) => {
      if (i === index) s[key] = value;
      return s;
    });
    props.onChanged({ ...region, slots: newSlots });
  };

  function RemoveDots(value) {
    if (value && value.length > 0) {
      value = value.replace(/\./g, '');
    }
    return value;
  }
  const handleRemove = (index) => {
    var newSlots = [];
    region.slots.map((s, i) => {
      if (i !== index) newSlots.push(s);
    });
    props.onChanged({ ...region, slots: newSlots });
  };
  return region ? (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card-box p-l-10 p-r-10 p-b-10 p-t-10">
          <h4 className="header-title mb-3">
            <div className="form-row col-md-12">
              <label className="col-md-1 required">Khu vực</label>
              <div className="col-md-4">
                <Textbox name={`warehouseRegions[${props.index}].RegionCode`} className="form-control mb-2" required ref={props.addRefParent} maxLength={255} value={region.regionCode} onChanged={(name, value) => props.onChanged({ ...region, regionCode: RemoveDots(value) })} />
              </div>
              <i className="mdi mdi-24px mdi-delete text-danger cursor-pointer" onClick={() => props.onRemove()} />
            </div>
          </h4>
          <label className="col-md-12">Vị trí lưu kho:</label>
          <div className="d-flex">
            <label className="col text-left required">Mã vị trí</label>
            <label className="col text-left">Chiều dài (cm)</label>
            <label className="col text-left">Chiều rộng (cm)</label>
            <label className="col text-left">Chiều cao (cm)</label>
            <div className="text-right w-24px"></div>
            <div className="text-right w-24px">
              <button type="button" className="btn btn-xs btn-custom" onClick={handleAdd}>
                <i className="mdi mdi-plus" />
              </button>
            </div>
          </div>
          <div className="scroll-slots ">
            {region.slots && region.slots.length > 0
              ? region.slots.map((slot, index) => {
                  return (
                    <div className="d-flex" key={index}>
                      <div className="d-flex flex-wrap w-100 mr-1">
                        <Textbox
                          id={`warehouseRegions[${props.index}].Slots[${index}].Code`}
                          name={`warehouseRegions[${props.index}].Slots[${index}].Code`}
                          className="form-control mb-2"
                          required
                          ref={props.addRefParent}
                          maxLength={255}
                          value={slot.code}
                          onChanged={(name, value) => handleChange(index, 'code', value)}
                        />
                      </div>
                      <div className="d-flex flex-wrap w-100 mx-1">
                        <TextNumber
                          id={`warehouseRegions[${props.index}].Slots[${index}].Length`}
                          name={`warehouseRegions[${props.index}].Slots[${index}].Length`}
                          className=" mb-2"
                          suffix=" (cm)"
                          ref={props.addRefParent}
                          value={slot.length}
                          onChanged={(name, value) => handleChange(index, 'length', value)}
                        />
                      </div>
                      <div className="d-flex flex-wrap w-100 mx-1">
                        <TextNumber
                          id={`warehouseRegions[${props.index}].Slots[${index}].Width`}
                          name={`warehouseRegions[${props.index}].Slots[${index}].Width`}
                          className=" mb-2"
                          suffix=" (cm)"
                          ref={props.addRefParent}
                          value={slot.width}
                          onChanged={(name, value) => handleChange(index, 'width', value)}
                        />
                      </div>
                      <div className="d-flex flex-wrap w-100 ml-1">
                        <TextNumber
                          id={`warehouseRegions[${props.index}].Slots[${index}].Height`}
                          name={`warehouseRegions[${props.index}].Slots[${index}].Height`}
                          className=" mb-2"
                          suffix=" (cm)"
                          ref={props.addRefParent}
                          value={slot.height}
                          onChanged={(name, value) => handleChange(index, 'height', value)}
                        />
                      </div>
                      <div className="cursor-pointer m-l-10" style={{ paddingTop: '5px' }}>
                        <CheckBox id={`warehouseRegions[${props.index}].Slots[${index}.IsActive]`} name={`warehouseRegions[${props.index}].Slots[${index}.IsActive]`} className=" mb-2" checked={slot.isActive} onCheckedChange={(name, value) => handleChange(index, 'isActive', value)} />
                      </div>
                      <i className="mdi mdi-24px mdi-delete text-danger cursor-pointer" onClick={handleRemove.bind(this, index)} />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

WarehouseSlot.propTypes = {
  region: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired,
  addRefParent: PropTypes.func.isRequired,
};
