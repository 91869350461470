import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import { OPTION_SIZE } from "./SizeBoardItem";
import { HeightAndMaxMinWeight } from "./HeightAndMaxMinWeight";

const initSizeOption = {
    id: 0,
    sizeName:"",
    sizeValue: 0,
    height:0,
    minWeight:0,
    maxWeight:0,
    size:0,
    productId: 0,
    isDelete:false,
}



const ModalAddOrUpdateSizeBoard = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [size, setSize] = useState({})
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [sizeValue , setSizeValue] = useState([])

    useEffect(() => {
        if(props.id>0 )
            getSize(props.id)
        else{
            setSize({...initSizeOption})
        }
    }, [props.id]);


    useEffect(()=>{
        let dtOption = props.dataSizeValue.filter(x => x.productId === size.productId)
        setSizeValue(dtOption)
    },[size.productId])

    

    const getSize = async (id) => {
        setIsBusy(true);
        let [err, data] = await props.getById(id);
        if (!err && data) {
            setIsBusy(false);
            setSize(data)
        } else {
            setIsBusy(false);
            setSize({...initSizeOption})
        }
    };
    const _handleSave = async () => {

        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await props.createOrUpdate(size);
            if (!err && data) {
                setIsBusy(false);
                setSize({...initSizeOption})
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                
            }
            props.onSaveContinue();
        }
      
    };


   
   
    
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='product' className='required'>
                    Product:
                </label>
                <TFUSelect name='product' className='' id='product' required
                options={props.dataDropDown} value={size.productId} 
                onChanged={(value) => {
                    setSize({ ...size, productId: value})
                    
                    }
                    
                    } />
                <label htmlFor='size-value' className='required'>
                    SizeValue:
                </label>
                <TFUSelect name='size-value' className='' id='size-value' required
                options={sizeValue} value={size.sizeValue} 
                onChanged={(value) => setSize({ ...size, sizeValue: value})} />
                <label htmlFor='height' className='required'>
                    Height:
                </label>
                <TFUSelect name='height' className='' id='height' required
                options={HeightAndMaxMinWeight} value={size.height} 
                onChanged={(value) => {
                    let sizeItem = HeightAndMaxMinWeight.find(x => x.value == value)
                    setSize({ ...size, height: value, minWeight: sizeItem.minWeight, maxWeight: sizeItem.maxWeight , sizeName: sizeItem.sizeName})}}

                 />
                <label htmlFor='name' className='required'>
                    Size name:
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, sizeName: value })} 
                value={size.sizeName} />
                

                <label htmlFor='min-weight' className='required'>
                    MinWeight:
                </label>
                <TextNumber id='min-weight' name='min-weight' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, minWeight: value })} 
                value={size.minWeight} />

                <label htmlFor='max-weight' className='required'>
                    MaxWeight:
                </label>
                <TextNumber id='max-weight' name='max-weight' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, maxWeight: value })} 
                value={size.maxWeight} />

                <label htmlFor='size' className='required'>
                    Size:
                </label>
                <TFUSelect name='size' className='' id='size' required
                options={OPTION_SIZE} value={size.size} 
                onChanged={(value) => setSize({ ...size, size: value})} />


                


                
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdateSizeBoard;

ModalAddOrUpdateSizeBoard.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
