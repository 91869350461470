import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Loading from "../based/Loading";
import ModalDetail from "./ModalDetail";
import SortServices from "../based/services/SortServices";


const INIT_SHELF = {
    totalOrderNumber: 0,
    errorOrderCount: 0,
    emptySlotCount: 0,
    slots: []
}

const PositionAreaManagement = ({ areaId }) => {
    const [data, setData] = useState(INIT_SHELF);
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [isModal, setIsModal] = useState(false);
    const [columnCount, setColumnCount] = useState(0)
    const [rowCount, setRowCount] = useState(0);
    const [shelves, setShelves] = useState([]);
    const [activeShelf, setActiveShelf] = useState(0);
    const [activeSlot, setActiveSlot] = useState(null);
    useEffect(() => {
        fetchShelves(areaId)
    }, [areaId])

    const fetchShelves = async (areaId) => {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await SortServices.GetShelvesByArea(areaId);
        if (!err && data) {
            setShelves(data);
            if (data.length > 0) {
                let shelfId = data[0].id;
                await fetchSlots(shelfId);
            }
            else {
                setData(INIT_SHELF);
                setRowCount(0);
                setColumnCount(0);
            }
            setBusy(false);
        } else {
            setShelves([]);
            setBusy(false);
        }
    }

    const fetchSlots = async (shelfId) => {
        const [err2, data2] = await SortServices.GetSlotsByShelf(shelfId);
        if (!err2 && data2) {
            setData(data2);
            let slots = data2.slots;
            let rowCount = 0;
            let columnCount = 0;
            if (slots && slots.length > 0) {
                for (let i = 0; i < slots.length; i++) {
                    const element = slots[i];
                    rowCount = Math.max(rowCount, element.row);
                    columnCount = Math.max(columnCount, element.column);
                }
            }
            setRowCount(rowCount);
            setColumnCount(columnCount);
        }
    }

    const _handleActiveShelf = async (shelfId) => {
        setActiveShelf(shelfId);
        fetchSlots(shelfId);
    }
    const _handleShowDetailSlot = async (slot) => {
        setIsModal(true);
        setActiveSlot(slot);
    }
    const _renderRows = () => {
        let slots = data.slots;
        if (!slots)
            return null;
        let doms = [];
        for (let id = rowCount - 1; id >= 0; id--) {
            let rows = slots.filter(m => m.row == id + 1);
            doms.push(<tr key={id}>
                <td style={{ backgroundColor: '#666F7B', color: '#fff', minWidth: 50, maxWidth: 50, }} className='text-center'>
                    {id + 1}
                </td>
                {rows.length > 0 && rows.map((itm, i) => {
                    return <td key={i} onClick={() => _handleShowDetailSlot(itm)}>
                        {
                            itm.order ? __renderTransferedOrder(itm.order, itm.isEmpty) : "Khay trống"
                        }
                    </td>
                })}
            </tr>)
        }
        return doms.map(m => m);
    }

    const __renderTransferedOrder = (order, transfered) => {
        let enough = transfered || order.productInSlotCount == order.totalProductCount;
        return enough ?
            <div className="item-box enough">
                <div>
                    <span>
                        Thời gian
                    </span>
                    <strong>
                        {new Date(order.orderDate).toLocaleDateString()}
                    </strong>
                </div>
                <div>
                    <span>
                        Mã ĐH:
                    </span>
                    <strong>
                        {order.orderId}
                    </strong>
                </div>
                <div>
                    <span>
                        Đã SX:
                    </span>
                    <strong className="count enough">
                        {order.printedCount}/{order.totalProductCount}
                    </strong>
                </div>
                <div>
                    <span>
                        Trong khay:
                    </span>
                    <strong className="count enough">
                        {order.totalProductCount}/{order.totalProductCount}
                    </strong>
                </div>
                {
                    transfered && <div className="text-center">
                        <strong className="count enough center">
                            Đã chuyển
                        </strong>
                    </div>
                }
            </div>
            :
            <div className="item-box not_enough">
                <div>
                    <span>
                        Thời gian
                    </span>
                    <strong>
                        {new Date(order.orderDate).toLocaleDateString()}
                    </strong>
                </div>
                <div>
                    <span>
                        Mã ĐH:
                    </span>
                    <strong>
                        {order.orderId}
                    </strong>
                </div>
                <div>
                    <span>
                        Đã SX:
                    </span>
                    <strong className={`count ${ order.printedCount === order.totalProductCount ? "enough" : "not_enough"} `}>
                        {order.printedCount}/{order.totalProductCount}
                    </strong>
                </div>
                <div>
                    <span>
                        Trong khay:
                    </span>
                    <strong className={`count ${order.productInSlotCount === order.totalProductCount ? "enough" : "not_enough"} `}>
                        {order.productInSlotCount}/{order.totalProductCount}
                    </strong>
                </div>
            </div>
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            {
                isModal && <ModalDetail
                    isShowModal={isModal}
                    onClose={() => { setIsModal(false) }}
                    slot={activeSlot}
                    onSaveContinue={()=>{
                        setIsModal(false) 
                        fetchShelves(areaId)
                    }}
                />
            }
            <div className="main">
                <div className="action">
                    <span>Có {data.totalOrderNumber} đơn hàng trong khay</span>
                    <span className="error">{data.errorOrderCount} đơn hàng bị lỗi</span>
                    <span>{data.emptySlotCount} khay trống</span>
                </div>

                <table>
                    <thead>
                        <tr className='text-center'>
                            <th style={{ minWidth: 50, backgroundColor: '#fff' }}></th>
                            {
                                [...Array(columnCount)].map((m, id) => {
                                    return <th key={id}>{id + 1}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rowCount > 0 && _renderRows()
                        }

                    </tbody>
                </table>
            </div>

        </StyleBody>
    );
};
const StyleBody = styled.div`
    .main{
        background-color: #fff;
        overflow-x: auto;
        padding: 15px;

        ::-webkit-scrollbar {
            width: 10px;
        }
        
        ::-webkit-scrollbar-track {
            background: #D9D9D9;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #64C5B1;

        }
          

        .action{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
    
            span{
                margin-right: 20px;
                color: #666F7B;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                
                &.error{
                    color: #D70000;
                }
    
    
            }
        }
    
        table{
            thead{
                th{
                    border: 1px solid #D9D9D9;
                    background: #666F7B;
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 600;
                    min-width: 230px;
                    max-width: 230px;
                }
            }
            tbody{
                td{
                    border: 1px solid #D9D9D9;
                    background: #fff;
                    height: 140px;
                    cursor: pointer;
                    min-width: 230px;
                    max-width: 230px;
                    cursor: pointer;

                    .item-box{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        padding: 0 12px;

                        div{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            span{
                                color: #828282;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                font-weight: 600;

                            }
                            strong{
                                color: #444;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                font-weight: 700;
                                &.enough{
                                    color: #28B259;
                                }
                                &.not_enough{
                                    color: #D70000;
                                }
                            }
                        }


                        &.enough{
                            background: #DFFFE0;
                        }
                        &.not_enough{
                            background: #FFDFDF;
                        }

                    }
                }
            }
        }
    }
`
export default PositionAreaManagement;
