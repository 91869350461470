import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CommonTable from '../../../based/CommonTable';
import { CONSTANTS, INITPAGING, NOTIFY } from '../../../based/Constants';
import { ConfirmDialog } from '../../../based/Dialog';
import Loading from '../../../based/Loading';
import { Notify } from '../../../based/Notify';
import BaseServices from '../../../based/services/BaseServices';
import AddEditMarketingProduct from './AddEditMarketingProduct';
import TableVariantsComp from './TableVariantsComp';
const INIT_PRODUCT = {
    setId: 0,
    image: '',
    title: '',
    ranusUrl: '',
    lazadaUrl: '',
    shopeeUrl: '',
    tiktokShopUrl: ''
}
const MarketingProducts = () => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState('');
    const [products, setProducts] = useState([]);
    const [paging, setPaging] = useState(INITPAGING);
    const [editingProduct, setEditingProduct] = useState(null);
    useEffect(() => {
        _fetchData();
    }, [])
    const _fetchData = async () => {
        setBusy(true);
        setBusyMsg('Đang tải dữ liệu...')
        const [err, data] = await BaseServices.Post('/api/affiliate/get-marketing-products', paging);
        if (!err && data) {
            let products = data.listObjects;
            if (products && products.length > 0) {
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    if (product.variants && product.variants.length > 0) {
                        product.variants.forEach(v => v.attributes = JSON.parse(v.attributeJsonValues))
                    }
                }
            }
            setProducts(products)
            setPaging(data.paging)
        }
        setBusy(false);

    }
    const _handleAdd = () => {
        setEditingProduct(INIT_PRODUCT);
    }
    const _handleEdit = async (item) => {
        let editingProduct = { ...item, otherRequirements: [] };
        if (item.otherRequirements) {
            editingProduct.otherRequirements = item.otherRequirements.map(m => m.content);
        }
        const [err, data] = await BaseServices.Post('/api/affiliate/get-marketing-product/' + item.setId);
        if (!err && data) {
            if (data.variants) {
                data.variants.forEach(m => {
                    m.attributes = JSON.parse(m.attributeJsonValues)
                })
            }
            editingProduct.variants = data.variants;
            editingProduct.campaigns = data.campaigns;
            setEditingProduct(editingProduct);
        }
    }
    const _handleDelete = async (id) => {
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true)
                setBusyMsg('Đang xóa dữ liệu...')
                const [err, data] = await BaseServices.Post('/api/affiliate/delete-marketing-product/' + id);
                if (err) {
                    setBusy(false)
                    setBusyMsg(null)
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
                else {
                    resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                    setBusy(false)
                    setBusyMsg(null)
                    _fetchData();
                }
            })
        });
    }
    const _handleSaveProduct = async () => {
        setBusy(true)
        setBusyMsg('Đang lưu dữ liệu...')
        let submitObject = { ...editingProduct };
        let otherRequirements = editingProduct.otherRequirements;
        if (otherRequirements && otherRequirements.length > 0) {
            submitObject.otherRequirements = [];
            for (let i = 0; i < otherRequirements.length; i++) {
                const element = otherRequirements[i];
                submitObject.otherRequirements.push({
                    id: 0,
                    content: element,
                    setId: editingProduct.setId
                })
            }
        }
        const [err, data] = await BaseServices.Post('/api/affiliate/add-marketing-product', submitObject);
        if (err) {
            setBusy(false)
            setBusyMsg(null)
            Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
        }
        else {
            setBusy(false)
            setBusyMsg(null)
            _fetchData();
            setEditingProduct(null);
        }
    }
    const _renderAction = () => {
        return <button className='btn btn-custom m-b-10' onClick={_handleAdd}>Thêm sản phẩm</button>
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <td>STT</td>
                <td>Hình ảnh</td>
                <td>Tên SP</td>
                <td>Các biến thể</td>
                <td>Các chiến dịch</td>
                <td>Link mua SP</td>
                <td>Yêu cầu cho hình ảnh / Video</td>
                <td>Chỉnh sửa</td>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        if (products && products.length > 0) {
            return <tbody>
                {
                    products.map((item, idx) => {
                        return <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                                <img src={item.image} style={{ width: 50 }} />
                            </td>
                            <td>{item.title}</td>
                            <td>
                                {
                                    <TableVariantsComp product={item} onPickVariant={() => { }} selectedAllVariant={false} />
                                }
                            </td>
                            <td>
                                {
                                    item.campaigns && <ul>
                                        {
                                            item.campaigns.map((g, i) => {
                                                return <li key={i} style={{ display: 'flex', gap: 10 }}>
                                                    {g.title}
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </td>
                            <td>
                                <ul>
                                    {item.ranusUrl && <li>Ranus: <a href={item.ranusUrl}>{item.ranusUrl}</a></li>}
                                    {item.shopeeUrl && <li>Shopee: <a href={item.shopeeUrl}>{item.shopeeUrl}</a></li>}
                                    {item.lazadaUrl && <li>Lazada: <a href={item.lazadaUrl}>{item.lazadaUrl}</a></li>}
                                    {item.tiktokShopUrl && <li>Tiktok: <a href={item.tiktokShopUrl}>{item.tiktokShopUrl}</a></li>}
                                </ul>
                            </td>
                            <td>
                                {
                                    item.otherRequirements && item.otherRequirements.length > 0 &&
                                    <ul>
                                        {
                                            item.otherRequirements.map((req, i) => {
                                                return <li key={i}>{req.content}</li>
                                            })
                                        }
                                    </ul>
                                }
                            </td>
                            <td>
                                <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleEdit(item)}>Sửa</button>
                                <button className='btn btn-danger btn-sm m-r-5' onClick={() => _handleDelete(item.setId)}>Xóa</button>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        }
        else
            return <tbody>
                <tr>
                    <td colSpan={6}>Không có sản phẩm</td>
                </tr>
            </tbody>
    }
    return (
        editingProduct ? <AddEditMarketingProduct
            product={editingProduct}
            setProduct={setEditingProduct}
            onCancel={() => setEditingProduct(null)}
            onSave={_handleSaveProduct}
        /> :
            <div>
                <Loading show={busy} msg={busyMsg} />
                <CommonTable
                    renderAction={_renderAction}
                    renderHeader={_renderHeader}
                    renderBody={_renderBody}
                    paging={paging}
                />
            </div>
    );
};

export default MarketingProducts;