import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TFUTooltipProps } from './PropsDefined';

/**
 * Tfu tooltip
 * @param {TFUTooltipProps} props the tfu tooltip props 
 */
export default function TFUTooltip(props) {
    const [isShow, setIsShow] = useState(false)
    const [left, setLeft] = useState('50%')
    function _handleMouseOver(e) {     
        let x = document.getElementById(props.target).offsetLeft
        setLeft(x + '%')
        setIsShow(true)
    }
    return (
        <div className='tfu-tooltip'
            onMouseOver={(e) => _handleMouseOver(e)}
            onMouseLeave={() => setIsShow(false)}
            >
            {
                isShow ?
                    <div className={`tfu-tooltip-content ${props.position} `}
                        dangerouslySetInnerHTML={{ __html: props.content }}>
                    </div>
                    : null
            }
            {props.children}
        </div >
    );
}

TFUTooltip.propTypes = {
    content: PropTypes.string.isRequired,
    position: PropTypes.string
};
TFUTooltip.defaultProps = {
    position: 'top'
};