import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import Common from "../based/Common";
import { IMAGEABLE_TYPE, INITPAGING, NOTIFY } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CustomizeServices from "../based/services/CustomizeServices";
import PosterServices from "../based/services/PosterServices";
import StockFolderServices from "../based/services/StockFolderServices";
import useFormValidate from "../hooks/useFormValidate";
import MainColorTypeServices from "../based/services/MainColorServices";

const StockPhotoModel = {
    id: 0,
    title: "",
    description: "",
    thumbUrl: "",
    url: "",
    width: 0,
    height: 0,
    tagConnections: [],
    collectionConnections: [],
    colorConnections: [],
    collectionTypeId: 0,
    folderConnections: [],
    isActive: true,
};

class CollectionModel {
    constructor() {
        this.id = 0;
        this.name = "";
    }
}

class CollectionTypeModel {
    constructor() {
        this.id = 0;
        this.name = "";
    }
}

export class LabelModel {
    constructor() {
        this.text = "";
        this.value = "";
        this.label = "";
    }
}

const AddOrEditStockModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [stockPhoto, setStockPhoto] = useState(StockPhotoModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const { id, onSaveContinue, onClose, isShowModal } = props;
    const [mainColorOptions, setMainColorOptions] = useState([new LabelModel()]);
    const [collectionContentOptions, setCollectionContentOptions] = useState([new CollectionModel()]);
    const [collectionTypeOptions, setCollectionTypeOptions] = useState([new CollectionTypeModel()]);
    const [pagingCollectionType, setpagingCollectionType] = useState({ ...INITPAGING });
    const [backgroundColor, setBackgroundColor] = useState("white");
    const [keyword, setKeyword] = useState("");
    const [folderOptions, setFolderOptions] = useState([]);
    const [listImage, setListImage] = useState([]);

    useEffect(() => {
        _getMainColor();
        _getCollectionContent();
        _getCollectionType({ ...pagingCollectionType });
    }, []);

    useEffect(() => {
        if (id > 0) _getStockPhoto(id);
        return () => setStockPhoto({});
    }, [id]);

    const _getStockPhoto = async (id) => {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetStockPhotoById(id);
        if (!err && data) {
            setStockPhoto({ ...data });
            let folders = data.folders || [];
            setFolderOptions(folders);
            setListImage([{ url: data.url, thumbUrl: data.thumbUrl }]);
        } else setStockPhoto(StockPhotoModel);
        setIsBusy(false);
    };

    const _getCollectionContent = async () => {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetListCollectionContentPagings({ ...INITPAGING, pageSize: 100, pageNumber: 1 });
        if (!err && data && data.listObjects) {
            setCollectionContentOptions(data.listObjects);
        } else setCollectionContentOptions([]);
        setIsBusy(false);
    };

    const _getCollectionType = async (paging) => {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetListCollectionTypePagings(paging);
        if (!err && data && data.listObjects) {
            setCollectionTypeOptions(data.listObjects);
            setpagingCollectionType(data.paging);
        } else {
            setCollectionTypeOptions([]);
            setpagingCollectionType({});
        }

        setIsBusy(false);
    };

    const _getMainColor = async () => {
        let [err, data] = await MainColorTypeServices.GetMainColorByPage({ ...INITPAGING, pageNumber: 1, pageSize: 20 });
        if (!err && data && data.listObjects) {
            let datas = [];
            data.listObjects.map((set) => {
                let modelTemp = {
                    text: set.colorDisplayName,
                    value: set.code,
                    label: set.colorDisplayName,
                };
                datas.push(modelTemp);
            });
            setMainColorOptions(datas);
        }
    };

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let model = { ...stockPhoto };
            let folderFilter = folderOptions && folderOptions.length > 0 ? folderOptions.filter((x) => x >= 0) : [];
            let folderConnection = folderFilter ? folderFilter.map((item) => ({ folderId: item })) : [];
            let imageUploads = listImage && listImage.map((item) => ({ url: item.url, thumbUrl: item.thumbUrl }));

            //them model
            model.folderConnections = folderConnection;
            model.imageUploads = imageUploads;
            let [err, data] = id > 0 ? await CustomizeServices.UpdateStockPhoto(model) : await CustomizeServices.CreateStockPhoto(model);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                onSaveContinue();
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            setIsBusy(false);
        }
    };

    const _handleMultipleImage = (e) => {
        e.preventDefault();
        let files = e.target.files;
        let fileNameWithoutExtension = "";
        let fileName = files && files.length > 0 ? files[0].name : "";
        fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
        setStockPhoto({ ...stockPhoto, title: fileNameWithoutExtension });
        Request.UploadMultipleStockPhoto(files, IMAGEABLE_TYPE.StockPhoto).then((res) => {
            if (res && res.data) {
                let listImage = res.data.listImage;
                let lstImage =
                    listImage &&
                    listImage.map((item) => ({
                        url: item.imageUrl,
                        thumbUrl: item.thumbnailUrl,
                    }));
                setListImage(lstImage);
            }
        });
    };

    return (
        <CommonModal show={isShowModal} size="lg" id={Common.generateGuid()} isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()} classModalBody={"modal-class-body"}>
            <Wrapper>
                <form>
                    <div className="group-item">
                        <label className="required mr-3" htmlFor="stockPhoto">
                            Stock Photo
                        </label>
                        <div className="sub-header">Yêu cầu: ảnh SVG dưới 500kb</div>
                        <input
                            className=""
                            type="file"
                            name="stockPhoto"
                            id="stockPhoto"
                            multiple={id > 0 ? false : true}
                            accept="image/*"
                            onChange={(e) => {
                                _handleMultipleImage(e);
                            }}
                        />
                    </div>
                    <div className="group-item">
                        <div className="thumbnail" style={{ backgroundColor: backgroundColor }}>
                            {listImage &&
                                listImage.map((item, idx) => {
                                    return <img src={item.thumbUrl} key={idx} />;
                                })}
                        </div>
                        <div className="sub-thumbnail">
                            <label htmlFor="">Màu nền</label>
                            <div className={`square ${backgroundColor == "white" ? "active" : ""}`} style={{ backgroundColor: "white" }} onClick={(e) => setBackgroundColor("white")}></div>
                            <div className={`square ${backgroundColor == "black" ? "active" : ""}`} style={{ backgroundColor: "black" }} onClick={(e) => setBackgroundColor("black")}></div>
                        </div>
                    </div>

                    <div className="group-item">
                        <div className="label-text">
                            <label className="required">Tên họa tiết</label>
                            <span className="sub-header">(Tông màu + Thành phần có trong thiết kế)</span>
                        </div>
                        <div className="item-input">
                            <input type="text" className="title" placeholder="Xanh dương đậm nhạt thành phố" value={stockPhoto.title} onChange={(e) => setStockPhoto({ ...stockPhoto, title: e.target.value })} />
                        </div>
                    </div>

                    <div className="group-item">
                        <div className="label-text">
                            <label className="required">Tag</label>
                            <span className="sub-header">(Các keywork liên quan đến thiết kế, VIE + ENG)</span>
                        </div>
                        <div className="item-input">
                            <TagsInput
                                addKeys={[13]}
                                addOnPaste
                                maxTags={10}
                                inputProps={{ placeholder: "" }}
                                value={stockPhoto.tagConnections || []}
                                onChange={(tags) => {
                                    let newTags = tags.reduce((prev, val, idx) => {
                                        return prev.indexOf(val) == -1 ? [...prev, val] : [...prev];
                                    }, []);
                                    setStockPhoto({ ...stockPhoto, tagConnections: newTags });
                                }}
                            />
                        </div>
                    </div>

                    <div className="group-item border">
                        <div className="hearder-group">
                            <label className="required">Nội dung họa tiết</label>
                            <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                            <span className="sub-header">(Có thể chọn nhiều)</span>
                        </div>
                        <div className="group-body">
                            {collectionContentOptions &&
                                collectionContentOptions.map((item, idx) => {
                                    return (
                                        <div
                                            className="body-item"
                                            key={idx}
                                            onClick={(e) => {
                                                let dt = stockPhoto.collectionConnections || [];
                                                let index = dt.findIndex((x) => x == item.id);
                                                if (index >= 0) dt.splice(index, 1);
                                                else dt.push(item.id);
                                                setStockPhoto({ ...stockPhoto, collectionConnections: dt });
                                            }}
                                        >
                                            <input type="checkbox" checked={stockPhoto.collectionConnections ? stockPhoto.collectionConnections.includes(item.id) : false} />
                                            <span>{item.name}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="group-item border">
                        <div className="hearder-group">
                            <label className="required">Kiểu họa tiết</label>
                            <span className="sub-header">(Chọn 1)</span>
                        </div>
                        <div className="group-body">
                            {collectionTypeOptions &&
                                collectionTypeOptions.map((item, idx) => {
                                    return (
                                        <div className="body-item" key={idx} onClick={(e) => setStockPhoto({ ...stockPhoto, collectionTypeId: item.id })}>
                                            <input type="checkbox" name="" id="" checked={stockPhoto.collectionTypeId == item.id} />
                                            <span>{item.name}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="group-item border">
                        <div className="hearder-group">
                            <label className="required">Màu chủ đạo</label>
                            <span className="sub-header">(Tối đa 2)</span>
                        </div>
                        <div className="group-body">
                            {mainColorOptions &&
                                mainColorOptions.map((item, idx) => {
                                    let isActive = stockPhoto.colorConnections ? stockPhoto.colorConnections.includes(item.value) : false;
                                    return (
                                        <div
                                            className={`body-item ${isActive ? "active" : ""}`}
                                            key={idx}
                                            onClick={(e) => {
                                                let list = stockPhoto.colorConnections || [];
                                                if (isActive) {
                                                    let index = list.findIndex((x) => x == item.value);
                                                    list.splice(index, 1);
                                                } else {
                                                    let length = list.length;
                                                    if (length >= 2) Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 2 màu");
                                                    else list.push(item.value);
                                                }
                                                setStockPhoto({ ...stockPhoto, colorConnections: list });
                                            }}
                                        >
                                            <div className="square" style={{ background: item.value }} />
                                            <span>{item.label}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="group-item">
                        <div className="label-text">
                            <label className="">Cho vào folder</label>
                        </div>
                        <div className="folder-body">
                            {folderOptions &&
                                folderOptions.map((item, idx) => {
                                    return (
                                        <div className="folder-option" key={idx}>
                                            <FolderDropdownOption
                                                folderId={item}
                                                setFolderId={(id) => {
                                                    let fo = [...folderOptions];
                                                    fo[idx] = id;
                                                    setFolderOptions(fo);
                                                }}
                                            />
                                        </div>
                                    );
                                })}

                            <div
                                className="folder-add cursor-pointer"
                                onClick={(e) => {
                                    let fo = [...folderOptions];
                                    fo.push(-1);
                                    setFolderOptions(fo);
                                }}
                            >
                                Thêm folder +
                            </div>
                        </div>
                    </div>

                    <div className="group-item">
                        <div className="label-text">
                            <label className="">Ẩn hiện</label>
                        </div>
                        <div className="item-input">
                            <input type="checkbox" className="isActive" checked={stockPhoto.isActive} onChange={(e) => setStockPhoto({ ...stockPhoto, isActive: !stockPhoto.isActive })} />
                        </div>
                    </div>
                </form>
            </Wrapper>
        </CommonModal>
    );
};

export default AddOrEditStockModal;

const FolderDropdownOption = ({ folderId, setFolderId }) => {
    const [dropdown, setDropDown] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 1000 });

    useEffect(() => {
        _getDropdown(paging);
    }, []);

    const _getDropdown = async (paging) => {
        let [err, data] = await StockFolderServices.GetDropdownFolderPagings(paging);
        if (!err && data && data.listObjects) {
            setDropDown(data.listObjects);
            setPaging(data.paging);
        } else {
            setDropDown([]);
            setPaging(paging);
        }
    };

    return <TFUSelect options={dropdown} placeholder="--Chọn folder--" value={folderId} onChanged={(value) => setFolderId(+value)} />;
};

AddOrEditStockModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};

const Wrapper = styled.div`
    label {
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        color: #444444;
        margin-bottom: 0;
    }
    .thumbnail {
        width: 100%;
        height: auto;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        display: flex;
        flex-wrap: wrap;

        img {
            width: 33.3333%;
            aspect-ratio: 1;
            height: auto;
            object-fit: contain;
        }
    }
    .modal-class-body {
        min-height: 50vh;
    }
    .sub-thumbnail {
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 10px 10px;
    }

    span.sub-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #5d5d5d;
    }

    .group-item {
        margin: 20px 0;
    }

    .item-input .title {
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;
    }

    .label-text {
        margin: 5px 0;
    }

    .group-item.border {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        padding: 10px;
    }

    .hearder-group {
        margin: 10px 0;
    }

    .header-group input[type="text"] {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
    }

    .hearder-group input[type="text"] {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
    }

    .group-body {
        display: flex;
        flex-wrap: wrap;
    }

    .body-item {
        flex-basis: 33.33%;
        margin: 10px 0;
        display: flex;
        align-items: center;
    }

    .body-item input[type="checkbox"] {
        margin-right: 5px;
    }

    .square {
        width: 15px;
        height: 15px;
        margin: 5px;
        border-radius: 20%;
        border: 1px solid #d9d9d9;
    }
    .square.active {
        border: 2px solid #64c5b1;
        border-radius: 3px;
    }
    .body-item.active {
        border: 2px solid #64c5b1;
        border-radius: 3px;
    }

    .folder-add {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #64c5b1;
    }

    .folder-body {
        display: flex;
        align-items: center;
    }

    .folder-option {
        width: 15%;
        margin: 0 10px;
    }
`;
