import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcumWrapper, Title } from './bc.elm'

export default function Breadcum(props) {
    return (
        <BreadcumWrapper>
            <div className='breadcum'>
                {
                props.title ?
                <React.Fragment>
                    <span className='breadcum-item' >
                        <Link to={'/'} style={{ color: '#797979' }} >
                            &nbsp;Trang chủ&nbsp;
                        </Link>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                    <span className='breadcum-item' >
                        <Link to={props.hrefParent ? props.hrefParent : '#'} style={{ color: '#797979' }} >
                            &nbsp;{props.title}&nbsp;
                        </Link>
                        {/* <i className="fa fa-chevron-right" aria-hidden="true"></i> */}
                    </span>
                </React.Fragment>
                :
                <span className='breadcum-item' >
                    <Link to={'/'} style={{ color: '#797979' }} >
                        &nbsp;Trang chủ&nbsp;
                    </Link>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </span>
                }
            </div>
            <Title>
                {props.title ? <h4>{props.title}</h4> : ''}
            </Title>
        </BreadcumWrapper>
    )
}

Breadcum.propTypes = {
    menu: PropTypes.any,
    title: PropTypes.string,
    hrefParent: PropTypes.string
}

