import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import { RequestDomain } from "../based/RequestDomain";
import VoucherServices from "../based/services/VoucherServices";
import { App_TargetGroupModel } from "../models/TargetGroupModel";
import AddOrEdit from "./AddOrEdit";
var moment = require("moment");

export default function TargetGroup(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentGroup, setCurrentGroup] = useState(null);

    useEffect(() => {
        getDatas(paging);
    }, []);

    const getLink = (code) => {
        return `${RequestDomain.HomeUrl()}/spinner/index.html?code=${code}`;
    };

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await VoucherServices.GetTargetGroupByPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width='150' className='text-center'>
                        Tên nhóm
                    </th>
                    <th width='150' className='text-center'>
                        Mô tả
                    </th>
                    <th width='200' className='text-center'>
                        Link vòng quay
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center'>
                                    <CheckBox id={i.toString()} name={i.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(i, value)} />
                                </td>
                                <td className='text-center'>
                                    <strong>{item.name}</strong>
                                </td>
                                <td className='text-center'> {item.description}</td>
                                <td
                                    className='text-center cursor-pointer'
                                    onClick={async () => {
                                        let link = getLink(item.spinnerLink)
                                        await navigator.clipboard.writeText(link);
                                        Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Sao chép thành công");
                                    }}
                                >
                                    {item.spinnerLink}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _handleAdd() {
        setCurrentGroup(new App_TargetGroupModel());
        setShowModal(true);
    }
    async function _handleEdit() {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            if (selected != null) {
                setIsLoading(true);
                setMsg("Đang tải dữ liệu...");
                const [err, data] = await VoucherServices.GetTargetGroupDTO(selected.id);
                if (!err && data) {
                    setIsLoading(false);
                    setMsg(null);
                    setCurrentGroup(data);
                    setShowModal(true);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.INFO, "Thông báo", CONSTANTS.MSG_ERROR);
                }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn nhóm để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleDelete() {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa nhóm này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await VoucherServices.DeleteTargetGroup(ids[0]);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    }

    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='name'>Tên nhóm:</label>
                            <Textbox className='form-control' name='name' onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} />
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleAdd()}>
                                <i className='fa fa-plus m-r-5' /> Thêm mới
                            </button>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnabled ? _handleEdit() : {})}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                            <button
                                className={dataChecked && dataChecked.length > 0 && dataChecked.length == 1 ? "btn btn-danger btn-sm m-r-5" : "btn btn-danger btn-sm m-r-5 disable"}
                                onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}
                            >
                                <i className='fa fa-remove m-r-5' /> Xóa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý nhóm' />
            <Loading show={isLoading} msg={msg} />
            {showModal && <AddOrEdit isShowModal={showModal} voucher={currentGroup} onClose={() => setShowModal(false)} onSaveSucceed={() => getDatas(paging)} />}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
