import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Logo from "./assets/logo-r.png";
import { gameModel } from "./GamePage";
import GameServices from "./GameServices";
import ReportModal from "./ReportModal";

const GameRoundDTO = {
    id: 0, // long in C#
    gameId: 0, // long in C#
    turn: 1, // int in C#
    first: null, // int in C#
    second: null, // int in C#
    third: null, // int in C#
    fourth: null, // int in C#
    fifth: null, // int? in C# (nullable, so using null)
    sixth: null, // int? in C# (nullable, so using null)
    rate: 0.0, // float in C#
    winner: 0, // Winner (custom object/class in C#)
    wintype: 0, // Wintype (custom object/class in C#)
    ratePlayer: 0.0, // float in C#
    rateBanker: 0.0, // float in C#
    rateTie: 0.0, // float in C#
    totalRatePlayerWithoutTie: 0.0, // float in C#
    totalRateBankerWithoutTie: 0.0, // float in C#
    smallRate: 0.0, // float in C#
    bigRate: 0.0, // float in C#
    smallReturnRate: 0.0, // float in C#
    bigReturnRate: 0.0, // float in C#
    playerScore: null,
    bankerScore: null,
    playerEdge: 0,
    bankerEdge: 0,
    tieEdge: 0,
    isChecked: false,
};

const ActiveEnum = {
    undefined: 0,
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
};

export const Card = {
    One: 1,
    Two: 2,
    Three: 3,
    Four: 4,
    Five: 5,
    Six: 6,
    Seven: 7,
    Eight: 8,
    Nine: 9,
    Ten: 10,
    Jack: 11,
    Queen: 12,
    King: 13,
};

const CardDisplayName = (value) => {
    switch (value) {
        case Card.One:
            return "1";
        case Card.Two:
            return "2";
        case Card.Three:
            return "3";
        case Card.Four:
            return "4";
        case Card.Five:
            return "5";
        case Card.Six:
            return "6";
        case Card.Seven:
            return "7";
        case Card.Eight:
            return "8";
        case Card.Nine:
            return "9";
        case Card.Ten:
            return "10";
        case Card.Jack:
            return "J";
        case Card.Queen:
            return "Q";
        case Card.King:
            return "K";
        default:
            return "";
    }
};

function GameDetail(props) {
    const { gameId } = props.match.params;
    const [listModel, setListModel] = useState([GameRoundDTO]);
    const [game, setGame] = useState({ ...gameModel });
    const [note, setNote] = useState("");
    const [burnCard, setBurnCard] = useState("");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showReport, setShowReport] = useState(false);

    useEffect(() => {
        if (gameId) {
            getGameRoud(gameId);
            getGameById(gameId);
        }
    }, [gameId]);

    const getGameById = async (gameId) => {
        const [err, data] = await GameServices.GetGameById(+gameId);
        if (!err && data) {
            setGame(data);
            setNote(data.note);
            setBurnCard(CardDisplayName(data.burnCard));
        } else {
            setGame({});
        }
    };

    const getGameRoud = async (gameId) => {
        const [err, data] = await GameServices.GetGameRoundByGameId(+gameId);
        if (!err && data && data.length > 0) {
            //thêm item cuối và checked
            let lastItem = data[data.length - 1];
            let item = { ...GameRoundDTO };
            item.turn = lastItem.turn + 1;
            item.isChecked = true;
            data.push(item);

            setListModel(data);
        } else {
            //tạo ra 105 turn
            let item = { ...GameRoundDTO };
            item.turn = 1;
            item.isChecked = true;
            setListModel([item]);
        }
    };

    const getCurInput = (temp) => {
        //kiểm tra xem item nào nhập chưa có giá trị thì lần lượt
        let lstValue = [temp.first, temp.second, temp.third, temp.fourth, temp.fifth, temp.sixth];

        //lấy ra index cuối dãy có giá trị
        let lastNullValue = lstValue.findIndex((x) => x == null);
        if (lastNullValue == -1) return ActiveEnum.undefined;

        //tính ra score player/banker
        let scorePlayer = Common.calculateCardValue([temp.first, temp.third]) % 10;
        let scoreBanker = Common.calculateCardValue([temp.second, temp.fourth]) % 10;
        let isScore89 = scorePlayer == 8 || scorePlayer == 9 || scoreBanker == 8 || scoreBanker == 9;
        let isScore67 = (scorePlayer == 6 || scorePlayer == 7) && (scoreBanker == 6 || scoreBanker == 7);
        let isScore0507 = scorePlayer >= 0 && scorePlayer <= 5 && scoreBanker >= 0 && scoreBanker <= 7;
        let first4Card = [temp.first, temp.second, temp.third, temp.fourth];
        let is4Card = first4Card.every((x) => x != null);

        if (!isScore89 && !isScore67 && !isScore0507 && is4Card) {
            return ActiveEnum.sixth;
        }

        //return vị trí đang được active để input
        let res = ActiveEnum.undefined;
        switch (lastNullValue) {
            case 0:
                res = ActiveEnum.first;
                break;
            case 1:
                res = ActiveEnum.second;
                break;
            case 2:
                res = ActiveEnum.third;
                break;
            case 3:
                res = ActiveEnum.fourth;
                break;
            case 4:
                res = ActiveEnum.fifth;
                break;
            case 5:
                res = ActiveEnum.sixth;
                break;
            default:
                res = ActiveEnum.undefined;
                break;
        }
        return res;
    };

    //lấy ra vị trí được input tiếp theo
    const activeInput = useMemo(() => {
        //lấy ra active item
        let activeItem = listModel.find((x) => x.isChecked);
        if (!activeItem) return ActiveEnum.undefined;
        let res = getCurInput(activeItem);
        return res;
    }, [listModel]);

    //lấy ra tổng số lá đã ra
    const totalLeaveOut = useMemo(() => {
        let total = 0;

        if (listModel && listModel.length > 0) {
            listModel.map((x) => {
                if (x.first) total++;
                if (x.second) total++;
                if (x.third) total++;
                if (x.fourth) total++;
                if (x.fifth) total++;
                if (x.sixth) total++;
            });
        }

        if (game && game.burnCard != null) total++;

        return total;
    }, [listModel, game]);

    const handleKeyboard = async (value) => {
        let queenCard = [Card.Ten, Card.Jack, Card.Queen, Card.King];
        let lstCard = [Card.One, Card.Two, Card.Three, Card.Four, Card.Five, Card.Six, Card.Seven, Card.Eight, Card.Nine, ...queenCard];

        //nếu nằm trong ds card thì input theo thứ tự
        if (lstCard.includes(value)) {
            //lấy ra item đang được active
            let temp = [...listModel];
            let item = temp.find((x) => x.isChecked);
            if (!item) return;

            //lấy ra vị trí cần được input
            let curInput = getCurInput(item);

            //tùy vào vị trí để thay đổi giá trị
            switch (curInput) {
                case ActiveEnum.first:
                    item.first = value;
                    break;
                case ActiveEnum.second:
                    item.second = value;
                    break;
                case ActiveEnum.third:
                    item.third = value;
                    break;
                case ActiveEnum.fourth:
                    item.fourth = value;
                    break;
                case ActiveEnum.fifth:
                    item.fifth = value;
                    break;
                case ActiveEnum.sixth:
                    item.sixth = value;
                    break;
                default:
                    break;
            }

            //tính lại điểm player/banker
            let playerScore = Common.calculateCardValue([item.first, item.third, item.fifth]) % 10;
            item.playerScore = playerScore;
            let bankerScore = Common.calculateCardValue([item.second, item.fourth, item.sixth]) % 10;
            item.bankerScore = bankerScore;
            setListModel(temp);
        }

        //nếu value "Enter" thì tính dòng cũ và thêm dòng mới
        if (value == "Enter") {
            let temp = [...listModel];

            //thêm dòng mới nếu chưa có dòng nào
            if (!temp || temp.length == 0) {
                let item = { ...GameRoundDTO };
                item.turn = 1;
                item.isChecked = true;
                temp.push(item);
                setListModel(temp);
                return;
            }

            //kiểm tra lượt trước đó input chưa đủ 4 lá thì không thêm dòng mới
            let lastItem = temp[temp.length - 1];
            let first4Card = [lastItem.first, lastItem.second, lastItem.third, lastItem.fourth];
            let is4Card = first4Card.every((x) => x != null);
            if (!is4Card) {
                Notify(NOTIFY.INFO, NOTIFY.INFO, "Lượt trước chưa đủ 4 lá");
                return;
            }

            //lưu và tính dòng cũ
            lastItem.gameId = +gameId;
            var resGameRound = await createUpdateGameRound(lastItem);
            if (resGameRound) {
                //update lại các thông số dùng cũ
                lastItem.id = resGameRound.id;
                lastItem.ratePlayer = resGameRound.ratePlayer;
                lastItem.rateBanker = resGameRound.rateBanker;
                lastItem.rateTie = resGameRound.rateTie;
                lastItem.totalRatePlayerWithoutTie = resGameRound.totalRatePlayerWithoutTie;
                lastItem.totalRateBankerWithoutTie = resGameRound.totalRateBankerWithoutTie;
                lastItem.smallRate = resGameRound.smallRate;
                lastItem.bigRate = resGameRound.bigRate;
                lastItem.smallReturnRate = resGameRound.smallReturnRate;
                lastItem.bigReturnRate = resGameRound.bigReturnRate;

                //thêm dòng mới
                temp.map((x) => (x.isChecked = false));
                let newItem = { ...GameRoundDTO };
                newItem.turn = lastItem.turn + 1;
                newItem.isChecked = true;
                temp.push(newItem);
                setListModel(temp);

                //scroll to bottom
                let table = document.querySelector(".body");
                table.scrollTop = table.scrollHeight;
            }
        }

        //nếu value là "Reset" thì reset lại giá trị của item đang được active
        if (value == "Reset") {
            let temp = [...listModel];
            let item = temp.find((x) => x.isChecked);
            if (!item) return;
            item.first = null;
            item.second = null;
            item.third = null;
            item.fourth = null;
            item.fifth = null;
            item.sixth = null;
            item.playerScore = null;
            item.bankerScore = null;
            setListModel(temp);
        }

        //nếu value là "Delete" thì xóa dòng đang được active
        if (value == "Delete") {
            let temp = [...listModel];
            let index = temp.findIndex((x) => x.isChecked);
            if (index == -1) return;

            //lấy ra ds giá trị
            let item = temp[index];
            let lstValue = [item.first, item.second, item.third, item.fourth, item.fifth, item.sixth];

            //duyệt từ cuối ra đầu, lấy ra item cuối cùng có giá trị
            let lastIndex = Common.findLastIndexWithValue(lstValue);
            if (lastIndex == -1) return;
            switch (lastIndex) {
                case 0:
                    item.first = null;
                    break;
                case 1:
                    item.second = null;
                    break;
                case 2:
                    item.third = null;
                    break;
                case 3:
                    item.fourth = null;
                    break;
                case 4:
                    item.fifth = null;
                    break;
                case 5:
                    item.sixth = null;
                    break;
                default:
                    break;
            }
            setListModel(temp);
        }
    };

    const createUpdateGameRound = async (gameRound) => {
        const [err, data] = await GameServices.CreateUpdateGameRound(gameRound);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Tạo mới thành công");
            return data;
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Tạo mới thất bại");
            return null;
        }
    };

    const removeGameRound = async (gameRoundId) => {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setLoading(true);
                const [err, data] = await GameServices.RemoveGameRoundById(gameRoundId);
                if (!err && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getGameRoud(gameId);
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                }
                setLoading(false);
            });
        });
    };

    const takeNote = async (model) => {
        const [err, data] = await GameServices.TakeNote(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu note thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu note thất bại");
        }
    };

    const updateBurnCard = async (model) => {
        const [err, data] = await GameServices.BurnCard(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu burn card thành công");
            setGame({ ...game, burnCard: model.burnCard });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu burn card thất bại");
        }
    };

    const smallEdege = useMemo(() => {
        if (!listModel || listModel.length == 0 || listModel.length == 1) return 0;

        //lấy ra item cuối cùng có giá trị
        let lastItem = listModel[listModel.length - 2];
        if (lastItem.smallReturnRate > 0) return parseFloat((lastItem.smallReturnRate - game.defaultSmallReturnRate) * 100).toFixed(2);
        return 0;
    }, [listModel, game]);

    const bigEdege = useMemo(() => {
        if (!listModel || listModel.length == 0 || listModel.length == 1) return 0;

        //lấy ra item cuối cùng có giá trị
        let lastItem = listModel[listModel.length - 2];
        if (lastItem.bigReturnRate > 0) return parseFloat((lastItem.bigReturnRate - game.defaultBigReturnRate) * 100).toFixed(2);
        return 0;
    }, [listModel, game]);

    if (!game || !game.id) return <div>Không có dữ liệu</div>;
    return (
        <React.Fragment>
            <Loading show={loading} msg="Đang xử lý..." />
            <Wrapper>
                <div className="top-page">
                    <div className="logo">
                        <div
                            className="header-left"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div
                                className="logo-image"
                                onClick={(e) => {
                                    history.push("/game-management");
                                }}
                            >
                                <img src={Logo} />
                            </div>
                            <div
                                className="badge badge-success m-1"
                                onClick={(e) => {
                                    setShowReport(true);
                                }}
                            >
                                Report
                            </div>
                        </div>

                        <div className="header-right">
                            <div className="date-area">
                                <span> {Common.formatDate(new Date(game.createdDate), "fulldate")}</span>
                            </div>
                            <div
                                className="header-row"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div htmlFor="">Small: </div>
                                <div> {smallEdege}</div>
                            </div>
                            <div
                                className="header-row"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div htmlFor="">Big: </div>
                                <div> {bigEdege}</div>
                            </div>
                        </div>
                    </div>
                    <div className="top-body">
                        <div className="header-left">
                            <div className="list-header-row">
                                <div className="header-row">
                                    <label htmlFor="">Ghi chú: </label>
                                    <div className="input-area">
                                        <input
                                            type="text"
                                            value={note}
                                            onChange={(e) => {
                                                setNote(e.target.value);
                                            }}
                                        />
                                        <button
                                            onClick={(e) => {
                                                takeNote({
                                                    id: +gameId,
                                                    note: note,
                                                });
                                            }}
                                        >
                                            Lưu note
                                        </button>
                                    </div>
                                </div>
                                <div className="header-row">
                                    <label htmlFor="">Burn Card: </label>
                                    <div className="input-area">
                                        <input
                                            type="text"
                                            value={burnCard}
                                            onChange={(e) => {
                                                setBurnCard(e.target.value);
                                            }}
                                        />
                                        <button
                                            onClick={(e) => {
                                                //kiểm tra burn card không nằm trong list card thì báo
                                                let lstCard = [Card.One, Card.Two, Card.Three, Card.Four, Card.Five, Card.Six, Card.Seven, Card.Eight, Card.Nine, Card.Ten];
                                                let jqk = ["j", "q", "k"];
                                                if (!lstCard.includes(+burnCard) && !jqk.includes(burnCard.toLowerCase())) {
                                                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Burn card không hợp lệ");
                                                    return;
                                                }

                                                let burnItem = 0;
                                                if (burnCard.toLowerCase() == "j") burnItem = Card.Jack;
                                                else if (burnCard.toLowerCase() == "q") burnItem = Card.Queen;
                                                else if (burnCard.toLowerCase() == "k") burnItem = Card.King;
                                                else burnItem = +burnCard;

                                                updateBurnCard({
                                                    id: +gameId,
                                                    burnCard: burnItem,
                                                });
                                            }}
                                        >
                                            Lưu card
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="center">R</th>
                                <th className="center" colSpan={4}>
                                    Player
                                </th>
                                <th className="center" colSpan={4}>
                                    Banker
                                </th>
                                <th className="center">Player E</th>
                                <th className="center">Banker E</th>
                                <th className="center">Tie</th>
                                <th className="center">Small E</th>
                                <th className="center">Big E</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listModel && listModel.length > 0 ? (
                                listModel.map((x, index) => {
                                    const playerEdge = x.totalRatePlayerWithoutTie > 0 ? parseFloat((x.totalRatePlayerWithoutTie - game.defaultRatePlayerWithoutTie) * 100).toFixed(2) : null;
                                    const bankerEdge = x.totalRateBankerWithoutTie > 0 ? parseFloat((x.totalRateBankerWithoutTie - game.defaultRateBankerWithoutTie) * 100).toFixed(2) : null;
                                    const tieEdge = x.rateTie > 0 ? parseFloat((x.rateTie - game.defaultRateTie) * 100).toFixed(2) : null;
                                    const scorePlayer = Common.calculateCardValue([x.first, x.third, x.fifth]) % 10;
                                    const scoreBanker = Common.calculateCardValue([x.second, x.fourth, x.sixth]) % 10;
                                    const smallEdge = x.smallReturnRate > 0 ? parseFloat((x.smallReturnRate - game.defaultSmallReturnRate) * 100).toFixed(2) : null;
                                    const bigEdge = x.bigReturnRate > 0 ? parseFloat((x.bigReturnRate - game.defaultBigReturnRate) * 100).toFixed(2) : null;
                                    return (
                                        <tr key={index} onClick={(e) => {}} className={`${x.isChecked ? "active" : ""}`}>
                                            <td className={`center turn`}>
                                                <span>{x.turn}</span>
                                                <span
                                                    className="badge badge-danger"
                                                    onClick={(e) => {
                                                        removeGameRound(x.id);
                                                    }}
                                                >
                                                    Xóa
                                                </span>
                                            </td>
                                            <td className={`center numb ${x.isChecked && activeInput == ActiveEnum.first ? "active" : ""}`}>{CardDisplayName(x.first)}</td>
                                            <td className={`center numb ${x.isChecked && activeInput == ActiveEnum.third ? "active" : ""}`}>{CardDisplayName(x.third)}</td>
                                            <td className={`center numb ${x.isChecked && activeInput == ActiveEnum.fifth ? "active" : ""}`}>{CardDisplayName(x.fifth)}</td>
                                            <td className={`center p-0 ${scorePlayer != scoreBanker && (scorePlayer > scoreBanker ? "player-score" : "banker-score")}  `}>{x.playerScore}</td>
                                            <td className={`center numb ${x.isChecked && activeInput == ActiveEnum.second ? "active" : ""}`}>{CardDisplayName(x.second)}</td>
                                            <td className={`center numb ${x.isChecked && activeInput == ActiveEnum.fourth ? "active" : ""}`}>{CardDisplayName(x.fourth)}</td>
                                            <td className={`center numb ${x.isChecked && activeInput == ActiveEnum.sixth ? "active" : ""}`}>{CardDisplayName(x.sixth)}</td>
                                            <td className={`center p-0 ${scorePlayer != scoreBanker && (scoreBanker > scorePlayer ? "player-score" : "banker-score")}`}>{x.bankerScore}</td>
                                            <td className={`center p-0`}>{playerEdge}</td>
                                            <td className={`center p-0`}>{bankerEdge}</td>
                                            <td className={`center p-0`}>{tieEdge}</td>
                                            <td className={`center p-0`}>{smallEdge}</td>
                                            <td className={`center p-0`}>{bigEdge}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={1000} className="center">
                                        Không có dữ liệu
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="bottom-bar">
                    <div className="keyboard">
                        <div className="list-item">
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.One);
                                }}
                            >
                                1
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Two);
                                }}
                            >
                                2
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Three);
                                }}
                            >
                                3
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Four);
                                }}
                            >
                                4
                            </div>
                        </div>
                        <div className="list-item">
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Five);
                                }}
                            >
                                5
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Six);
                                }}
                            >
                                6
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Seven);
                                }}
                            >
                                7
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Eight);
                                }}
                            >
                                8
                            </div>
                        </div>
                        <div className="list-item">
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Nine);
                                }}
                            >
                                9
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Ten);
                                }}
                            >
                                10
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Jack);
                                }}
                            >
                                J
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.Queen);
                                }}
                            >
                                Q
                            </div>
                        </div>
                        <div className="list-item">
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard(Card.King);
                                }}
                            >
                                K
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard("Reset");
                                }}
                            >
                                Reset
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard("Delete");
                                }}
                            >
                                Delete
                            </div>
                            <div
                                className="item"
                                onClick={(e) => {
                                    handleKeyboard("Enter");
                                }}
                            >
                                Enter
                            </div>
                        </div>
                    </div>
                    <div className="over-view">
                        <div className="total-leave-out">
                            <span>Tổng số lá đã ra: {totalLeaveOut}</span>
                        </div>
                        <div className="total-leave-remain">
                            <span>Tổng số lá còn lại {416 - totalLeaveOut}</span>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <ReportModal
                gameId={+gameId}
                show={showReport}
                onClose={() => {
                    setShowReport(false);
                }}
            />
        </React.Fragment>
    );
}

export default GameDetail;

const Wrapper = styled.div`
    width: 100vw;
    overflow-x: hidden;
    .body {
        height: calc(90vh - 125px - 275px);
        overflow: auto;
        position: relative;
    }

    thead {
        position: sticky;
        top: 0px;
        background: #f3f3f3;
    }
    td.center.p-0 {
        padding: 1px !important;
    }
    span.badge.badge-danger {
        font-size: 8px;
        display: block;
        margin: 2px 0;
        padding: 1 !important;
    }

    .input-area input[type="text"] {
        width: 100% !important;
    }

    .input-area {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .top-page {
        background-color: rgb(241, 241, 241);
    }
    .header-right {
        font-size: 10px !important;
    }

    .header-right .header-row {
        font-size: 10px;
    }

    .header-right label {
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .header-right span {
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .header-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .list-header-row .header-row {
        width: calc(50% - 6px);
        margin: 0 3px !important;
    }

    .list-header-row .header-row button {
        font-size: 10px !important;
    }
    .top-body {
        width: 100%;
    }

    .list-header-row {
        flex-wrap: wrap;
    }
    .top-body {
        display: flex;
    }

    .header-right {
        margin-left: auto;
        text-align: right;
    }
    .logo-image {
        width: 50px;
        height: 50px;
        background-color: #313a46 !important;
        padding: 10px;
    }

    .logo-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .logo {
        display: flex;
        padding: 6px;
    }
    .list-header-row {
        display: flex;
    }

    .list-header-row .header-row {
        margin-right: 12px;
    }
    .input-area button {
        border: none;
        background: #28b259;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 100%;
    }

    .input-area {
        display: flex;
        height: 30px;
    }

    .input-area input[type="text"] {
        padding: 0 !important;
        margin: 0 !important;
        border: none;
    }
    td.center.player-score {
        background-color: lightgreen;
        color: black;
        font-weight: 600;
    }

    td.center.banker-score {
        background-color: red;
        color: black;
        font-weight: 600;
    }

    .row-active {
        background: deepskyblue;
        color: black;
    }

    td.center.turn {
        background-color: deepskyblue;
        color: black;
        font-weight: 500;
    }

    td.center.numb {
        padding: 1px !important;
    }
    tr th {
        font-size: 12px;
    }
    .top-body {
        position: sticky;
        top: 0;
        background: #f1f1f1;
    }
    tr td {
        width: calc(100vw / 14);
        max-width: calc(100vw / 14);
    }
    td.center.turn {
        padding: 0 !important;
    }
    td.center.active {
        border: 1px solid #ededed;
        background: #ffdfdf;
    }

    tr.active {
        border: 1px solid #ededed;
        background: #dfffe0;
    }
    .bottom-bar {
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .keyboard {
        display: flex;
        flex-direction: column;
    }

    .list-item {
        display: flex;
    }

    .item {
        width: calc(25%);
        text-align: center;

        /* G-Board/Key Box Shadow */
        height: 50px;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f3f3f3;
        font-weight: 600;
    }

    .total-leave-out {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 6px 0;
    }

    .total-leave-remain {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 6px 0;
    }

    .over-view {
        padding: 12px 16px;
    }
    tr td {
        font-size: 0.8em;
    }

    .header-row label {
        margin: 0;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
    }

    .header-row span {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .input-area {
        margin: 6px 0;
    }
`;
