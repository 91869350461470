import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { PACKAGE_ORDER_STATUS, PACKAGE_ORDER_STATUS_NAME, PRINT_TYPE } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import Loading from "../based/Loading";
import OperatorServices from "../based/services/OperatorServices";
import { Layout } from "../layout/Layout";
var moment = require("moment");

const PACKAGE_STATUS_OPTIONS = [
    { value: PACKAGE_ORDER_STATUS.NOT_PACK, label: PACKAGE_ORDER_STATUS_NAME.NOT_PACK },
    { value: PACKAGE_ORDER_STATUS.PACKING, label: PACKAGE_ORDER_STATUS_NAME.PACKING },
    { value: PACKAGE_ORDER_STATUS.PARTIAL_PACK, label: PACKAGE_ORDER_STATUS_NAME.PARTIAL_PACK },
    { value: PACKAGE_ORDER_STATUS.FULL_PACK, label: PACKAGE_ORDER_STATUS_NAME.FULL_PACK },
];

const PRINT_TYPE_OPTIONS = [
    { value: PRINT_TYPE.DTG, label: "DTG" },
    { value: PRINT_TYPE.PET, label: "PET" },
];

export default function OperatorOrderPacking(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...Common.Paging, packageOrderStatus: -1, printType: 0 });
    const [userHandleOptions, setUserPackageHandle] = useState([]);

    useEffect(() => {
        getUserPackageHandle();
        getDatas({ ...Common.Paging, packageOrderStatus: -1 });
    }, []);
    async function getUserPackageHandle() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OperatorServices.GetUserPackageHandle();
        if (!error && data) {
            setUserPackageHandle(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setUserPackageHandle([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OperatorServices.GetOrderPackages(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.fromDate) pagingUp.fromDate = moment(pagingUp.fromDate, "DD/MM/yyyy").toDate();
                if (pagingUp.toDate) pagingUp.toDate = moment(pagingUp.toDate, "DD/MM/yyyy").toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg("");
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg("");
        }
    }
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        if (paging.fromDate) newPaging.fromDate = moment(paging.fromDate).format("DD/MM/YYYY");
        if (paging.toDate) newPaging.toDate = moment(paging.toDate).format("DD/MM/YYYY");
        getDatas(newPaging);
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th width='50' className='text-center'>
                        STT
                    </th>
                    <th width='200' className='text-center'>
                        Order ID
                    </th>
                    <th width='100' className='text-center'>
                        Tổng số lượng SP
                    </th>
                    <th>Danh sách Tag sản xuất</th>
                    <th width='150' className='text-center'>
                        Ngày đặt hàng
                    </th>
                    <th width='150' className='text-center'>
                        Kênh bán
                    </th>
                    <th width='150' className='text-center'>
                        Tình trạng
                    </th>
                    <th width='150' className='text-center'>
                        Loại đóng gói
                    </th>
                    <th width='200' className='text-center'>
                        User đóng gói
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderProductionTags(item) {
        if (item.productionTags && item.productionTags.length > 0) {
            let tags = [];
            item.productionTags.map((tag, idx) => {
                let cls = "cursor-pointer";
                if (tag.isBold) cls += " font-weight-bold";
                if (tag.isHighLight) cls += " mark-yellow";
                if (tag.isUnderLine) cls += " text-decoration-underline";
                tags.push(
                    <span key={idx} className={cls}>
                        {tag.tagId}
                    </span>
                );
                if (idx < item.productionTags.length - 1) tags.push(<span className='m-r-5'>,</span>);
            });
            return tags;
        }
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, k) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;
                        return (
                            <tr key={k}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='text-center vertical-align-middle'>{item.orderNumber ? item.orderNumber : item.id}</td>
                                <td className='text-center vertical-align-middle'>{item.itemCounts}</td>
                                <td className='text-center vertical-align-middle'>{_renderProductionTags(item)}</td>
                                <td className='text-center vertical-align-middle'>{item.createdDateDisplay}</td>
                                <td className='text-center vertical-align-middle'>{item.platformName}</td>
                                <td className='text-center vertical-align-middle'>{item.packageOrderStatusName}</td>
                                <td className='text-center vertical-align-middle'>{item.productPackageTypeName}</td>
                                <td className='text-center vertical-align-middle'>{item.packageHandleByName}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-4'>
                            <label htmlFor='keyword'>Mã đơn hàng:</label>
                            <Textbox className='form-control' name='keyword' onChanged={(name, value) => setPaging({ ...paging, keyword: value })} value={paging.keyword} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='packageOrderStatus'>Tình trạng đơn hàng:</label>
                            <TFUSelect name='packageOrderStatus' value={paging.packageOrderStatus} options={PACKAGE_STATUS_OPTIONS} onChanged={(value) => setPaging({ ...paging, packageOrderStatus: value != null ? value : -1 })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='printType'>Kiểu in:</label>
                            <TFUSelect name='printType' value={paging.printType} options={PRINT_TYPE_OPTIONS} onChanged={(value) => setPaging({ ...paging, printType: value != null ? value : 0 })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='packageHandleBy'>User đóng gói: </label>
                            <TFUSelect name='packageHandleBy' value={paging.packageHandleBy} options={userHandleOptions} placeholder='---User đóng gói---' onChanged={(value) => setPaging({ ...paging, packageHandleBy: value })} />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='time'>Ngày đặt hàng:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='fromDate' maxDate={paging.toDate} value={paging.fromDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, fromDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='toDate' minDate={paging.fromDate} value={paging.toDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, toDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            <button className='btn btn-custom btn-sm' onClick={() => props.history.push("/order-packing")}>
                                <i className='fa fa-cube m-r-5' />
                                Tạo đóng gói
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs currentPage='Quản lý đóng gói' />
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(newPaging) => getDatas(newPaging)} />
        </React.Fragment>
    );
}
