import BaseServices from './BaseServices';

const WebSettingsServices = {
  GetWebSettings: async () => {
    return await BaseServices.Get('/api/WebSettings/get-web-settings');
  },
  SaveWebSettings: async (model) => {
    return await BaseServices.Post('/api/WebSettings/save-web-settings', model);
  },
};

export default WebSettingsServices;
