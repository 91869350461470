import { UserModel } from './UserModel';

export class AgencyModel {
  constructor() {
    this.userId = 0;
    this.agencyLevel = '';
    this.balance = '';
    this.maximumCreatedBrand = '';
    this.bannerUrl = '';
    this.websiteUrl = '';
    this.facebookUrl = '';
    this.youtubeUrl = '';
    this.instagramUrl = '';
    this.tikTokUrl = '';
    this.description = '';

    // Display Fields
    this.user = new UserModel();
  }
}
