export class PrintFrameConfigModel {
    constructor() {
        this.attributeValueId = 0;
        this.widthFrame = 0;
        this.heightFrame = 0;
        this.widthMockup = 0;
        this.heightMockup = 0;


        this.isDefault = false;
        this.scale = 0;
        this.attributeValueName = "";

        this.frontMin = 0;
        this.frontMax = 0;
        this.backMin = 0;
        this.backMax = 0;

        this.logoHeightCollar = 0;
        this.logoWidthCollar = 0;
        this.logoHeightBackCollar = 0;
    }
}
