import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import StockFolderServices from "../based/services/StockFolderServices";

class StockSetModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.content = "";
    }
}

function AddEditModal({ isShowModal, onClose, onSave, currentId }) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [model, setModel] = useState(new StockSetModel());

    useEffect(() => {
        if (currentId > 0) _getData(currentId);
    }, [currentId]);

    const _handleSave = async () => {
        let [err, data] = await StockFolderServices.CreateUpdateStockSet(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const _getData = async (id) => {
        let [err, data] = await StockFolderServices.GetStockSetById(id);
        if (!err && data) setModel(data);
        else setModel(new StockSetModel());
    };

    return (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={model.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <Wrapper>
                <form>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="name" className="d-block my-2 required">
                                Tên Set
                            </label>
                            <input type="text" value={model.name} id="name" required className="form-control" onChange={(e) => setModel({ ...model, name: e.target.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="name" className="d-block my-2 required">
                                Nội dung Set
                            </label>
                            <textarea type="text" value={model.content} id="content" required className="form-control" onChange={(e) => setModel({ ...model, content: e.target.value })} />
                        </div>
                    </div>
                </form>
            </Wrapper>
        </CommonModal>
    );
}

AddEditModal.propTypes = {};

export default AddEditModal;

const Wrapper = styled.div`
    .preview-thumbnail {
        height: 100px;
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;
