import RequestMTO from "../RequestMTO";

const BaseMTOServices = {
    Get: async (url) => {
        try {
            const res = await RequestMTO.Get(url);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    Post: async (url, params) => {
        try {
            const res = await RequestMTO.Post(url, params);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
    Delete: async (url) => {
        try {
            const res = await RequestMTO.Delete(url);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
};

export default BaseMTOServices;
