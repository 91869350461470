import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
export class SwichToogle extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckChanged = this.handleCheckChanged.bind(this);
  }

  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.bool,
  };

  handleCheckChanged(e) {
    this.props.onCheckChanged(this.props.name, e.target.checked);
  }

  render() {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={this.props.name}
          checked={this.props.value}
          onChange={(e) => {
            this.handleCheckChanged(e);
          }}
          onClick={(e) => {
            this.handleCheckChanged(e);
          }}
        />
        <label className="custom-control-label" htmlFor={this.props.name}>
          {this.props.title}
        </label>
      </div>
    );
  }
}