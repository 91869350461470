import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import MessPhotoServices from "../based/services/MessPhotoServices";
import TFUDatetime from "../based/inputs/TFUDatetime";

class StockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.startDate = new Date();
        this.endDate = new Date();
        this.isDefault = false;
        this.limit = 0;
    }
}

function AddEditModal({ isShowModal, onClose, onSave, currentId }) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [folder, setFolder] = useState(new StockFolderModel());

    useEffect(() => {
        if (currentId > 0) _getData(currentId);
    }, [currentId]);

    const _handleSave = async () => {
        let [err, data] = folder.id > 0 ? await MessPhotoServices.UpdateFolderMess(folder) : await MessPhotoServices.CreateFolderMess(folder);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const _getData = async (id) => {
        let [err, data] = await MessPhotoServices.GetFolderMessById(id);
        if (!err && data) setFolder(data);
        else setFolder(new StockFolderModel());
    };

    return (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={folder.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <Wrapper>
                <form>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="name" className="d-block my-2 required">
                                Tên folder
                            </label>
                            <input type="text" value={folder.name} id="name" required className="form-control" onChange={(e) => setFolder({ ...folder, name: e.target.value })} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="isDefault">Mặc định</label>
                            <input type="checkbox" checked={folder.isDefault} id="isDefault" className="form-control" onChange={(e) => setFolder({ ...folder, isDefault: e.target.checked })} />
                        </div>
                    </div>
                    {!folder.isDefault && (
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="startDate">Ngày bắt đầu</label>
                                <TFUDatetime
                                    value={new Date(folder.startDate)}
                                    name="startDate"
                                    onChanged={(value, name) => {
                                        setFolder({ ...folder, startDate: value });
                                    }}
                                    required
                                    minDate={new Date()}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="endDate">Ngày kết thúc</label>
                                <TFUDatetime
                                    value={new Date(folder.endDate)}
                                    name="endDate"
                                    onChanged={(value, name) => {
                                        setFolder({ ...folder, endDate: value });
                                    }}
                                    required
                                    minDate={new Date()}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="limit">Giới hạn</label>
                            <input type="number" value={folder.limit} id="limit" className="form-control" onChange={(e) => setFolder({ ...folder, limit: e.target.value })} />
                        </div>
                    </div>
                </form>
            </Wrapper>
        </CommonModal>
    );
}

export default AddEditModal;

const Wrapper = styled.div`
    .preview-thumbnail {
        height: 100px;
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;
