import React, { useContext, useState } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { ApplicationContext } from '../../../context/ApplicationContext'
import { NavbarLeftWrapper, NavBarToggle, NavItem } from './nbl.elm'


export const NavITem = ({ id, handleClickMenu, currMenuSelect, toggle, item }) => {
    var parent = item.filter((item) => item.parentId === 0);
    var children = item.filter((item) => item.parentId !== 0);
    var navId = localStorage.getItem("navItem");
    const [isDropDown, setIsDropDown] = useState(true);
    const handleDropdown = () => {
        handleClickMenu(id);
        setIsDropDown(!isDropDown);
    };
    const setActiveNavId = (id) => {
        localStorage.setItem("navItem", id);
    };
    const location = useLocation()
    return (
        <NavItem toggle={toggle}>
            <div className='main__nav__item'>
                <span className='item__icon'>
                    <i className='fa fa-id-card-o' aria-hidden='true'></i>
                </span>
                <div className='item__name'>{parent[0].name}</div>
                <span className='toggle__icon' onClick={handleDropdown}>
                    {currMenuSelect.includes(id) ? <i className='fa fa-chevron-right' aria-hidden='true'></i> : <i className='fa fa-chevron-down' aria-hidden='true'></i>}
                </span>
            </div>
            <div className={`${toggle && isDropDown ? "dropdown active" : "dropdown"}`}>
                <ul>
                    {children.length &&
                        children.map((item, index) => (
                            <li className={`${location.pathname === item.navigateLink ? "menu__item active" : "menu__item"}`} onClick={() => setActiveNavId(item.id)} key={index}>
                                <Link to={item.navigateLink}>{item.name}</Link>
                            </li>
                        ))}
                </ul>
            </div>
        </NavItem>
    );
};
const NavbarLeft = ({ toggle }) => {
    const [isToggle, setIsToggle] = useState(true);
    const [currMenuSelect, setCurrMenuSelect] = useState([]);
    var context = useContext(ApplicationContext);
    var menus = context.applicationInfo.menus;
    const _handleRedirectHome = () => {
        window.location.replace('/')
    }
    const handleToggleNav = () => {
        setIsToggle(!isToggle);
        toggle();
    };
    const handleClickMenu = (key) => {
        if (!currMenuSelect.includes(key)) {
            setCurrMenuSelect([...currMenuSelect, key]);
        } else {
            var menuSelect = currMenuSelect.filter((value, index) => {
                return value !== key;
            });
            setCurrMenuSelect(menuSelect);
        }
    };
    const _handleMenuItem = (menus) => {
        if (menus && menus.length) {
            var parentItems = menus.filter((item, index) => item.parentId === 0);
            var menulist = [];
            if (parentItems.length) {
                parentItems.map((item) => {
                    var children = menus.filter((childItem) => childItem.parentId === item.id);
                    menulist.push([item, ...children]);
                });
            }
            return menulist.map((item, index) => <NavITem key={index} item={item} id={index} handleClickMenu={handleClickMenu} currMenuSelect={[...currMenuSelect]} toggle={isToggle} />);
        }
    };
    return (
        <NavbarLeftWrapper toggle={isToggle}>
            <div className={`${isToggle ? "navbar__left__container toggle" : "navbar__left__container"}`}>
                <NavBarToggle onClick={handleToggleNav}>{isToggle ? <i className='fa fa-chevron-left' aria-hidden='true'></i> : <i className='fa fa-chevron-right' aria-hidden='true'></i>}</NavBarToggle>
                <Link to='/' className='nav__logo' > {isToggle ? <img className='logo__full' src='/images/Logo-01.png' /> : <img className='logo__sub' src='/images/Logo-04.png' />}</Link>
                <div className='nav__content__wrapper'>{_handleMenuItem(menus)}</div>
            </div>
        </NavbarLeftWrapper>
    );
};

export default NavbarLeft;
