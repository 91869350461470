import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import BaseServices from '../based/services/BaseServices';

function FindingCollectionSelection({isSetCol, onSelectItem, id, name, disabled, sm}) {
	const [keyword, setKeyword] = useState('');
	const [products, setProducts] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const inputRef = useRef(null);

	async function _fetchData(keyword) {
		let	url = `/api/CreatorCollectionShow/get-col-by-key/${keyword}`;
		let [error, data] = await BaseServices.Get(url);
		if (data) {
			setProducts(data);
		} else {
			setProducts([]);
		}
	}

	function _handleShowDropdown() {
		if (!showDropdown)
			inputRef.current.focus();
		setShowDropdown(!showDropdown);
	}

	function _handleClickOutside() {
		setTimeout(function () {
			setShowDropdown(false);
		}, 200);
	}

	function _renderDropdown() {
		if (!products || products.length == 0)
			return <div className="tfu-autocomplete-items">
				<table>
					<tbody>
						<tr>
							<td colSpan={2}>Không tìm thấy dữ liệu, gõ để tìm kiếm</td>
						</tr>
					</tbody>
				</table>
			</div>
		return (
			<div className="tfu-autocomplete-items table table-responsive" style={{maxHeight: 500}}>
				<table>
					<tbody>
						{products.map((item, index) => (
							<tr key={index} onClick={() => _handleSelectItem(item)} style={{padding: 10}}>
								<td width={50}>
									<img src={item.thumbnail} style={{ width: '100%' }} />
								</td>
								<td>{item.name}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}

	/**
	 * handle select item
	 * @param {DropdownModel} item dropdown model item
	 */
	function _handleSelectItem(item) {
		setShowDropdown(false);
		if (onSelectItem)
			onSelectItem(item);
	}
	const delayCallFetchData = useCallback(_.debounce(function (txt) {
		_fetchData(txt)
	}, 500), []);

	async function _handleTextChange(e) {
		let txt = e.target.value;
		setKeyword(txt);
		delayCallFetchData(txt);
	}

	return (
		<div
			className={
				"tfu-autocomplete " + (sm && "tfu-autocomplete-sm")
			}
			onBlur={_handleClickOutside}
		>
			<input
				aria-autocomplete={false}
				className="tfu-autocomplete-input"
				id={id}
				name={name}
				ref={inputRef}
				value={keyword}
				onChange={_handleTextChange}
				onFocus={() => {
					setShowDropdown(true);
				}}
				onBlur={_handleClickOutside}
				disabled={disabled}
				autoComplete={"off"}
			/>
			{!disabled && <span
				className={`tfu-autocomplete-chevron fa fa-chevron-${showDropdown ? "up" : "down"}`}
				onClick={_handleShowDropdown}
			/>}
			{showDropdown && _renderDropdown()}
		</div>
	);
}

export default FindingCollectionSelection;