import { v4 as uuid } from "uuid";
import { PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE, PROJECT_EDITOR_MODAL_TYPE as MODAL_TYPE } from "../../../based/Constants";

// Mockup Modal
const openAddMockupModal = (state) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    updatedMockupModal.isShow = true;
    updatedMockupModal.type = MODAL_TYPE.ADD;
    updatedMockupModal.mainImage.url = "";
    updatedMockupModal.subImages = [{ id: uuid(), url: "" }];
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const openEditMockupModal = (state, itemId) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    const updatedItems = { ...updatedState.items };
    const updatedData = [...updatedItems.data];

    const itemIdx = updatedData.findIndex((item) => item.id === itemId);
    if (itemIdx < 0) return updatedState;

    updatedMockupModal.isShow = true;
    updatedMockupModal.mockupId = itemId;
    updatedMockupModal.type = MODAL_TYPE.EDIT;
    updatedMockupModal.mainImage = updatedData[itemIdx].data.mainImage;
    updatedMockupModal.subImages = updatedData[itemIdx].data.subImages;
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const openInsertMockupModal = (state, insertItemId) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    const updatedItems = { ...updatedState.items };
    const updatedData = [...updatedItems.data];

    const itemIdx = updatedData.findIndex((item) => item.id === insertItemId);
    if (itemIdx < 0) return updatedState;

    updatedMockupModal.isShow = true;
    updatedMockupModal.mockupId = insertItemId;
    updatedMockupModal.type = MODAL_TYPE.INSERT;
    updatedMockupModal.mainImage.url = "";
    updatedMockupModal.subImages = [{ id: uuid(), url: "" }];
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const openMockupModal = (state, { itemId, type }) => {
    switch (type) {
        case MODAL_TYPE.ADD:
            return openAddMockupModal(state);
        case MODAL_TYPE.EDIT:
            return openEditMockupModal(state, itemId);
        case MODAL_TYPE.INSERT:
            return openInsertMockupModal(state, itemId);
        default:
            return state;
    }
};

const closeMockupModal = (state) => {
    console.log("close:", state);
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };

    updatedMockupModal.isShow = false;
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const addSubImageMockup = (state) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    const updatedSubImages = [...updatedMockupModal.subImages];

    const newSubImage = {
        id: uuid(),
        url: "",
    };

    updatedSubImages.push(newSubImage);
    updatedMockupModal.subImages = updatedSubImages;
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

// Mockup Item
const addMockup = (state) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    const updatedItems = { ...updatedState.items };
    const updatedData = [...updatedItems.data];

    const newItem = {
        id: uuid(),
        type: ITEM_TYPE.MOCKUP,
        data: {
            isFullWidth: false,
            mainImage: updatedMockupModal.mainImage,
            subImages: updatedMockupModal.subImages,
        },
    };

    updatedData.push(newItem);
    updatedMockupModal.isShow = false;
    updatedItems.data = updatedData;
    updatedState.items = updatedItems;
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const editMockup = (state, itemId) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    const updatedItems = { ...updatedState.items };
    const updatedData = [...updatedItems.data];
    const itemIdx = updatedData.findIndex((item) => item.id === itemId);
    if (itemIdx < 0) return updatedState;
    updatedMockupModal.isShow = false;
    updatedData[itemIdx].data.mainImage = updatedMockupModal.mainImage;
    updatedData[itemIdx].data.subImages = updatedMockupModal.subImages;
    updatedItems.data = updatedData;
    updatedState.items = updatedItems;
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const insertMockup = (state, itemId) => {
    const updatedState = { ...state };
    const updatedMockupModal = { ...updatedState.mockupModal };
    const updatedItems = { ...updatedState.items };
    const updatedData = [...updatedItems.data];

    const itemIdx = updatedData.findIndex((item) => item.id === itemId);
    if (itemIdx < 0) return updatedState;

    const newItem = {
        id: uuid(),
        type: ITEM_TYPE.MOCKUP,
        data: {
            isFullWidth: false,
            mainImage: updatedMockupModal.mainImage,
            subImages: updatedMockupModal.subImages,
        },
    };

    updatedMockupModal.isShow = false;
    updatedData.splice(itemIdx, 0, newItem);
    updatedItems.data = updatedData;
    updatedState.items = updatedItems;
    updatedState.mockupModal = updatedMockupModal;

    return updatedState;
};

const removeMockup = (state, itemId) => {
    const updatedState = { ...state };
    const updatedItems = { ...updatedState.items };
    const updatedData = [...updatedItems.data];

    const itemIdx = updatedData.findIndex((item) => item.id === itemId);
    if (itemIdx < 0) return updatedState;

    updatedData.splice(itemIdx, 1);
    updatedItems.data = updatedData;
    updatedState.items = updatedItems;

    return updatedState;
};

export default {
    openMockupModal,
    closeMockupModal,
    addSubImageMockup,
    addMockup,
    editMockup,
    insertMockup,
    removeMockup,
};
