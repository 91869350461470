import React from 'react';
import { PRODUCT_EDITOR_TAB } from '../../based/Constants';

export default function TabHeader(props) {
    function _handleClickTab(idx) {
        if (props.tabIndex != idx)
            props.onTabChanged(idx);
    }
    return (
        <div className="col-12 col-lg-8 offset-md-2 p-b-10">
            <ul className="nav nav-tabs tabs-bordered nav-justified">
                <li className="nav-item">
                    <a
                        className={props.tabIndex == PRODUCT_EDITOR_TAB.PRODUCT_INFO ? "nav-link h-100 active" : "nav-link h-100"}
                        onClick={() => _handleClickTab(PRODUCT_EDITOR_TAB.PRODUCT_INFO)}
                    >
                        <span className="d-none d-sm-block">Thông tin sản phẩm</span>
                    </a>
                </li>
                {/* <li className="nav-item">
                    <a
                        className={props.tabIndex == PRODUCT_EDITOR_TAB.PRODUCT_WARRANTY ? "nav-link h-100 active" : "nav-link h-100"}
                        onClick={() => _handleClickTab(PRODUCT_EDITOR_TAB.PRODUCT_WARRANTY)}
                    >
                        <span className="d-none d-sm-block">Bảo hành</span>
                    </a>
                </li> */}
            </ul>
        </div>
    );
}
