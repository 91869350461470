import React, { useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import { ShopCatDTO } from "./GeneralInformation";
import PlatformServices from "../based/services/PlatformServices";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";

function ShopCatModal({ show, onClose, id, parentId, shopId, platform, onSave, index }) {
    const [shopCatName, setShopCatName] = useState("");

    const createUpdateShopCat = async () => {
        let md = { ...ShopCatDTO };
        md.id = id;
        md.parentId = parentId;
        md.name = shopCatName;
        md.shopId = shopId;
        md.platform = platform;
        md.isActive = true;
        md.index = index;

        const [err, data] = await PlatformServices.CreateUpdateShopCat(md);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật danh mục thành công");
            onSave();
            setShopCatName("");
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra, vui lòng thử lại sau");
        }
    };

    return (
        <Wrapper>
            <CommonModal
                title={id > 0 ? "Chỉnh sửa danh mục" : parentId > 0 ? "Thêm danh mục con" : "Thêm danh mục cha"}
                show={show}
                onClose={onClose}
                onSave={() => {
                    createUpdateShopCat();
                }}
            >
                <div className="fomr-row">
                    <label htmlFor="parent-name" className="required">
                        Đặt tên danh mục
                    </label>
                    <input type="text" className="form-control" value={shopCatName} onChange={(e) => setShopCatName(e.target.value)} />
                </div>
            </CommonModal>
        </Wrapper>
    );
}

export default ShopCatModal;

const Wrapper = styled.div``;
