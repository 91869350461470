import BaseServices from "./BaseServices";

const TagTrendingServices = {
    CreateUpdateTagTrending: async (model) => {
        return await BaseServices.Post("/api/WebSettings/create-update-tag", model);
    },
    GetTagTrendingById: async (id) => {
        return await BaseServices.Get(`/api/WebSettings/get-tag-by-id/${id}`);
    },
    DeleteTagTrendingById: async (id) => {
        return await BaseServices.Get(`/api/WebSettings/delete-tag-by-id/${id}`);
    },
    GetTagTrendingPage: async (paging) => {
        return await BaseServices.Post("/api/WebSettings/get-update-tag-page", paging);
    },
};

export default TagTrendingServices;
