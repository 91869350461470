import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import SizeServices from "../based/services/SizeServices";
import ModalAddOrUpdateSize from "./ModalAddOrUpdateSize";
import ModalShowSizeBoardAndSizeBoardDetail from "./ModalShowSizeBoardAndSizeBoardDetail";
import TemSizeServices from "../based/services/TemSizeServices";
import TFUSelect from "../based/inputs/TFUSelect";
import SizeOptionItem from "./SizeOptionItem";
import TFUImage from "../based/inputs/Image";
const initPage = {
    pageSize: 10,
    pageNumber: 1,
};
const modelSize = {
    productId: 0,
    minHeight: 0,
    maxHeight: 0,
    minWeight: 0,
    maxWeight: 0,
    productName: "",
    isDelete: false,
    handDoors: [],
    shoulders: [],
    longSleeves: [],
    longs: [],
    necks: [],
    horizontals: [],
};

function Size() {
    const [selectId, setSelectId] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowModalShow, setIsShowModalShow] = useState(false);
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState({ ...initPage });
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [sizeBoard, setSizeBoard] = useState([]);
    const [sizeBoardDetail, setSizeBoardDetail] = useState([]);
    const [dataDropDown, setDataDropDown] = useState([]);
    const [dataProduct, setDataProduct] = useState([]);
    const [dataSizeValue, setDataSizeValue] = useState([]);

    useEffect(() => {
        getData({ ...initPage });
        getDataDropDown();
        getDataProduct();
        getDataSizeValue();
    }, []);
    const getDataSizeValue = async () => {
        setBusy(true);
        let [err, data] = await SizeServices.GetSizeValueDropDown();
        if (!err && data) {
            setDataSizeValue(data);
            setBusy(false);
        } else {
            setDataSizeValue([]);
            setBusy(false);
        }
    };
    const getData = async (paging) => {
        setBusy(true);
        let [err, data] = await SizeServices.GetSizeByPage(paging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setPaging({ ...initPage });
            setBusy(false);
        }
    };

    const getDataDropDown = async () => {
        setBusy(true);
        let [err, data] = await SizeServices.GetSizeDropDown();
        if (!err && data) {
            setDataDropDown(data);
            setBusy(false);
        } else {
            setDataDropDown([]);
            setBusy(false);
        }
    };
    const getDataProduct = async () => {
        setBusy(true);
        let [err, data] = await TemSizeServices.GetProductDropDown();
        if (!err && data) {
            setDataProduct(data);
            setBusy(false);
        } else {
            setDataProduct([]);
            setBusy(false);
        }
    };

    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>Tên sản phẩm</th>
                    <th>Tên hiển thị</th>
                    <th>Min - Max (Height)</th>
                    <th>Min - Max (Weight)</th>
                    <th>Ảnh mô tả</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    {item.isAdd ? (
                                        <TFUSelect
                                            id={`product-${idx}`}
                                            options={dataProduct}
                                            value={item.productId}
                                            onChanged={(value) => {
                                                let dt = [...data];
                                                dt[idx].productId = value;
                                                dt[idx].productName = dataProduct.find((x) => x.value == value).label;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.productName
                                    )}
                                </td>
                                <td>
                                    {item.isUpdate || item.isAdd ? (
                                        <input
                                            type="text"
                                            className="input-in-table"
                                            placeholder="Tên hiển thị"
                                            value={item.displayName}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].displayName = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.displayName
                                    )}
                                </td>
                                <td>
                                    {item.isUpdate || item.isAdd ? (
                                        <input
                                            type="number"
                                            className="input-in-table"
                                            placeholder="min"
                                            value={item.minHeight}
                                            maxLength={1}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].minHeight = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.minHeight + " - "
                                    )}
                                    {item.isUpdate || item.isAdd ? (
                                        <input
                                            type="number"
                                            className="input-in-table"
                                            placeholder="max"
                                            value={item.maxHeight}
                                            maxLength={1}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].maxHeight = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.maxHeight
                                    )}
                                </td>
                                <td>
                                    {item.isUpdate || item.isAdd ? (
                                        <input
                                            type="number"
                                            className="input-in-table"
                                            placeholder="min"
                                            value={item.minWeight}
                                            maxLength={1}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].minWeight = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.minWeight + " - "
                                    )}

                                    {item.isUpdate || item.isAdd ? (
                                        <input
                                            type="number"
                                            className="input-in-table"
                                            placeholder="max"
                                            value={item.maxWeight}
                                            maxLength={1}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].maxWeight = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.maxWeight
                                    )}
                                </td>
                                <td>
                                    {item.isUpdate || item.isAdd ? (
                                        <div className="icon">
                                            <TFUImage
                                                id={`image-${idx}`}
                                                name={`image-${idx}`}
                                                onChanged={(name, value) => {
                                                    let dt = [...data];
                                                    dt[idx].thumbnailUrl = value;
                                                    setData(dt);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div className="icon">
                                            <div className="imgPreview" style={{ position: "relative" }}>
                                                <div className="preview">
                                                    <img src={item.thumbnailUrl} style={{ maxWidth: "60%" }} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </td>

                                <td>
                                    {item.isUpdate || item.isAdd ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : (
                                        <button
                                            className={`btn btn-custom m-1`}
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.productId, idx, item.isAdd);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }
    async function handleAddItem() {
        setData([...data, { ...modelSize, isAdd: true }]);
    }
    function IsDisable(idx) {
        if (data[idx].minHeight <= 0 || data[idx].minWeight <= 0 || data[idx].maxHeight <= 0 || data[idx].maxWeight <= 0 || data[idx].productId <= 0 || !data[idx].productName) return true;
        return false;
    }

    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await SizeServices.CreateUpdateSize(dataItem);
        if (!err && dt) {
            getData(paging);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx, idAdd) {
        if (!idAdd) {
            var ids = [];
            ids.push(id);
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await SizeServices.DeleteSize(ids);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    const getSizeHorizontal = async (productId, idx) => {
        let [err, dt] = await SizeServices.GetSizeHorizontalByProductId(productId);
        if (!err && dt) {
            let newDT = [...data];
            newDT[idx].horizontals = dt;
            setData(newDT);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi lấy dữ liệu");
        }
    };

    const getSizeHandDoor = async (productId, idx) => {
        let [err, dt] = await SizeServices.GetSizeHandDoorByProductId(productId);
        if (!err && dt) {
            let newDT = [...data];
            newDT[idx].handDoors = dt;
            setData(newDT);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi lấy dữ liệu");
        }
    };

    const getSizeLong = async (productId, idx) => {
        let [err, dt] = await SizeServices.GetSizeLongByProductId(productId);
        if (!err && dt) {
            let newDT = [...data];
            newDT[idx].longs = dt;
            setData(newDT);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi lấy dữ liệu");
        }
    };

    const getSizeLongSleeve = async (productId, idx) => {
        let [err, dt] = await SizeServices.GetSizeLongSleeveByProductId(productId);
        if (!err && dt) {
            let newDT = [...data];
            newDT[idx].longSleeves = dt;
            setData(newDT);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi lấy dữ liệu");
        }
    };

    const getSizeNeck = async (productId, idx) => {
        let [err, dt] = await SizeServices.GetSizeNeckByProductId(productId);
        if (!err && dt) {
            let newDT = [...data];
            newDT[idx].necks = dt;
            setData(newDT);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi lấy dữ liệu");
        }
    };

    const getSizeShoulder = async (productId, idx) => {
        let [err, dt] = await SizeServices.GetSizeShoulderByProductId(productId);
        if (!err && dt) {
            let newDT = [...data];
            newDT[idx].shoulders = dt;
            setData(newDT);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi lấy dữ liệu");
        }
    };

    const _handleShowSizeBoard = () => {
        var sizeBoard = data.filter((x) => x.selected).map((x) => x.sizeBoards);
        setSizeBoard(sizeBoard[0]);
        setSizeBoardDetail([]);
        setIsShowModalShow(true);
    };

    const _handleShowSizeBoardDetail = () => {
        var sizeBoardDetail = data.filter((x) => x.selected).map((x) => x.sizeBoardDetails);
        setSizeBoardDetail(sizeBoardDetail[0]);
        setSizeBoard([]);
        setIsShowModalShow(true);
    };

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button className={dataChecked && dataChecked.length == 1 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom  mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length == 1 ? _handleShowSizeBoard() : {})}>
                    Size Board
                </button>
                <button className={dataChecked && dataChecked.length == 1 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom  mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length == 1 ? _handleShowSizeBoardDetail() : {})}>
                    Size Board Detail
                </button>
            </React.Fragment>
        );
    }

    return (
        <Wapper>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage={"Quản lý size"} />
            <CommonTable data={data} paging={paging} ref={tableRef} headerCheckbox hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getData} />
            {isShowModal && (
                <ModalAddOrUpdateSize
                    isShowModal={isShowModal}
                    id={selectId}
                    getById={SizeServices.GetSizeByProductId}
                    createOrUpdate={SizeServices.CreateUpdateSize}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                />
            )}
            {isShowModalShow && (
                <ModalShowSizeBoardAndSizeBoardDetail
                    isShowModal={isShowModalShow}
                    sizeBoard={sizeBoard}
                    dataSizeValue={dataSizeValue}
                    sizeBoardDetail={sizeBoardDetail}
                    dataDropDown={dataDropDown}
                    onClose={() => {
                        setIsShowModalShow(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalShow(false);
                    }}
                />
            )}
        </Wapper>
    );
}
const Wapper = styled.div`
    .input-in-table {
        width: 56px;
        height: 32px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
export default Size;
