import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import MarketingBannerManagement from "./MarketingBannerManagement";
import MarketingColorManagement from "./MarketingColorManagement";
import MarketingProductManagement from "./MarketingProductManagement";
import MarketingStatistic from "./MarketingStatistic";
import MarketingWheelManagement from "./MarketingWheelManagement";





function MarketingDetailManagement({campaignId, creatorId}) {
    
    return (
        <MarketingWapper>
            <MarketingColorManagement campaignId={campaignId} creatorId={creatorId} />
            <MarketingStatistic campaignId={campaignId} />
            <MarketingBannerManagement campaignId={campaignId} />
            <MarketingProductManagement campaignId={campaignId} creatorId={creatorId} />
            <MarketingWheelManagement campaignId={campaignId} />
        </MarketingWapper>
    )
}
const MarketingWapper = styled.div`
    


    .box-banner{
        display: flex;


        .box-banner-item{
            width: 50%;

        }
    }
`;
export default MarketingDetailManagement;
