import * as signalR from "@aspnet/signalr";
import Moment from "moment";
import PubSub from "pubsub-js";
import React, { Fragment, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY, REFUND_REASONS, RETURN_ORDER_STATUS, RETURN_ORDER_STATUS_NAME, RETURN_ORDER_STATUS_NAME_ARR, TicketHistoryStatus } from "../based/Constants";
import MultipleImages from "../based/inputs/MultipleImages";
import TextArea from "../based/inputs/TextArea";
import Language from "../based/Language";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import ReturnRequestService from "../based/services/ReturnRequestService";
import useFormValidate from "../hooks/useFormValidate";
import TicketReturnOrderChangeStatusModal from "./TicketReturnOrderChangeStatusModal";
const RefundType = {
    MoMo: 1,
    Bank: 2,
};

const TicketReturnOrderDetail = (props) => {
    const columns = [
            { name: "STT", displayName: "STT" },
            { name: "name", displayName: "Tên sản phẩm" },
            { name: "sellerSKU", displayName: "Seller Sku" },
            { name: "price", displayName: "Giá" },
            { name: "quantity", displayName: "Số lượng đổi trả" },
            { name: "reason", displayName: "Lý do" },
        ],
        currentUserName = Common.GetUserName(),
        [busy, setBusy] = useState({ isBusy: true, busyMsg: "" }),
        [returnOrderTicket, setReturnOrderTicket] = useState({}),
        [nextStatus, setNextStatus] = useState(0),
        [isShowChangeStatusModal, setIsShowChangeStatusModal] = useState(false),
        [ticketComments, setTicketComments] = useState([]),
        [ticketComment, setTicketComment] = useState({}),
        tableRef = useRef(null),
        { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setBusy({ isBusy: false, busyMsg: "" });
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.TicketReturnOrder });
        getTicketDetail();
    }, []);

    useEffect(() => {
        var connection = new signalR.HubConnectionBuilder().withUrl(`${Request.API_URL}/hubs/notification?access_token=${Common.GetToken()}`).build();
        connection.start().then(() => connection.send("subscribeTopic", "ticket/" + props.match.params.ticketId));
        connection.on("ReceiveTicketComment", (data) => {
            var newTicketComments = [...ticketComments];
            newTicketComments.unshift(data);
            setTicketComments(newTicketComments);
        });
        connection.on("ReceiveTicketTicketStatus", (data) => {
            setReturnOrderTicket({ ...returnOrderTicket, status: data });
        });
        return () => {
            connection.off("ReceiveTicketComment");
            connection.off("ReceiveTicketTicketStatus");
        };
    }, [ticketComments]);

    const getTicketDetail = async () => {
        setBusy({ isBusy: true, busyMsg: "Đang tải dữ liệu..." });
        var ticketId = parseFloat(props.match.params.ticketId);
        if (ticketId) {
            const [err, data] = await ReturnRequestService.GetTicketReturnOrderDetail(ticketId);
            if (!err && data) {
                for (var op of data.returnOrderProducts) {
                    var reason = REFUND_REASONS.find((x) => x.value == op.reason);
                    op.reason = reason.label;
                }
                setReturnOrderTicket(data);
                setTicketComments(data.ticketComments ? data.ticketComments : []);
            } else {
                Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
            }
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };

    const renderTableBody = (products) => {
        if (products && products.length > 0)
            return (
                <tbody>
                    {products.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <tr>
                                    {columns.map((column, j) => {
                                        switch (column.name) {
                                            case "STT":
                                                return (
                                                    <td className="center" key={j}>
                                                        {index + 1}
                                                    </td>
                                                );
                                            case "price":
                                                return (
                                                    <td className="center" key={j}>
                                                        <CurrencyFormat displayType="text" thousandSeparator="," decimalSeparator="." decimalScale={2} value={item[column.name]} />
                                                    </td>
                                                );
                                            default:
                                                return (
                                                    <td className="center" key={j}>
                                                        {item[column.name]}
                                                    </td>
                                                );
                                        }
                                    })}
                                </tr>
                                {item.attachments && item.attachments.length > 0 ? (
                                    <tr>
                                        <td />
                                        <td colSpan={5}>
                                            {item.attachments.map((attac, i) => (
                                                <a key={i} href={attac} target="_blank" className="mr-3">
                                                    tệp đính kèm {i + 1} <i className="mdi mdi-open-in-new" />
                                                </a>
                                            ))}
                                        </td>
                                    </tr>
                                ) : (
                                    ""
                                )}
                                {(item.hasImei || item.hasSerialNumber) && item.extendDatas && item.extendDatas.length > 0 ? (
                                    <tr>
                                        <td />
                                        <td colSpan={4}>
                                            <table className="w-100">
                                                <tr key="headerinfo">
                                                    <th className="center p-1" width="60">
                                                        STT
                                                    </th>
                                                    {item.hasImei ? <th className="center p-1">Số imei</th> : ""}
                                                    {item.hasSerialNumber ? <th className="center p-1">Số serial</th> : ""}
                                                </tr>
                                                {item.extendDatas.map((extendData, i) => (
                                                    <tr key={i}>
                                                        <td className="center p-1" width="60">
                                                            {i + 1}
                                                        </td>
                                                        {item.hasImei ? <td className="center p-1">{extendData.imei}</td> : ""}
                                                        {item.hasSerialNumber ? <td className="center p-1">{extendData.serialNumber}</td> : ""}
                                                    </tr>
                                                ))}
                                            </table>
                                        </td>
                                    </tr>
                                ) : (
                                    ""
                                )}
                            </Fragment>
                        );
                    })}
                </tbody>
            );
        var columnCount = columns ? columns.length + 1 : 10000000;
        return (
            <tbody>
                <tr>
                    <td colSpan={columnCount} className="center">
                        {Language.getValue("common.noRecords")}
                    </td>
                </tr>
            </tbody>
        );
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr className="text-center">
                    {columns.map((column, i) => {
                        return (
                            <th key={i} className="cursor-pointer" width={column.width ? column.width : "auto"}>
                                <span>{column.displayName}</span>
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    };

    const handleCloseTicket = async () => {
        setBusy({ isBusy: true, busyMsg: "Đang lưu dữ liệu..." });
        const [err, data] = await ReturnRequestService.CloseTicket(returnOrderTicket.id);

        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", CONSTANTS.MSG_SUCCESS);
            props.history.push("/ticket-return-order");
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };

    const handleChangeTicketStatus = async (status) => {
        setBusy({ isBusy: true, busyMsg: "Đang lưu dữ liệu..." });
        const [err, data] = await ReturnRequestService.ChangeTicketStatus(returnOrderTicket.id, status);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", CONSTANTS.MSG_SUCCESS);
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };

    const handleChangeTicketHistoryStatus = async (status) => {
        setBusy({ isBusy: true, busyMsg: "Đang lưu dữ liệu..." });
        const [err, data] = await ReturnRequestService.UpdateTicketHistoryStatus(returnOrderTicket.id, status);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", CONSTANTS.MSG_SUCCESS);
            await getTicketDetail();
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };

    const updateTicketHistoryStatus = async (status) => {
        setBusy({ isBusy: true, busyMsg: "Đang lưu dữ liệu..." });
        const [err, data] = await ReturnRequestService.UpdateTicketHistoryStatus(returnOrderTicket.id, status);
        if (!err && data) {
            await getTicketDetail();
            Notify(NOTIFY.SUCCESS, "Thành công", CONSTANTS.MSG_SUCCESS);
        } else {
            Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
        }
        setBusy({ isBusy: false, busyMsg: "" });
    };

    const handleSucceedModal = () => {
        setReturnOrderTicket({ ...returnOrderTicket, status: nextStatus });
        setIsShowChangeStatusModal(false);
    };

    const renderButton = () => {
        switch (returnOrderTicket.status) {
            case RETURN_ORDER_STATUS.Processing:
                return (
                    <Fragment>
                        <button className="btn btn-danger btn-sm float-right" onClick={handleCloseTicket}>
                            <i className="fa fa-close m-r-5" /> Đóng yêu cầu
                        </button>
                        <button className="btn btn-warning btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.DeclineReceivingOff)}>
                            {RETURN_ORDER_STATUS_NAME.DeclineReceivingOff}
                        </button>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.AllowReceivingOff)}>
                            <i className="fa fa-check m-r-5" /> {RETURN_ORDER_STATUS_NAME.AllowReceivingOff}
                        </button>
                        <button className="btn btn-primary btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.UpdateInformation)}>
                            {RETURN_ORDER_STATUS_NAME.UpdateInformation}
                        </button>
                    </Fragment>
                );
            case RETURN_ORDER_STATUS.UpdateInformation:
                return (
                    <Fragment>
                        <button className="btn btn-warning btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.DeclineReceivingOff)}>
                            {RETURN_ORDER_STATUS_NAME.DeclineReceivingOff}
                        </button>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.AllowReceivingOff)}>
                            <i className="fa fa-check m-r-5" /> {RETURN_ORDER_STATUS_NAME.AllowReceivingOff}
                        </button>
                    </Fragment>
                );
            case RETURN_ORDER_STATUS.AllowReceivingOff:
                return (
                    <Fragment>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.Refund)}>
                            {RETURN_ORDER_STATUS_NAME.Refund}
                        </button>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.SendMoreProducts)}>
                            {RETURN_ORDER_STATUS_NAME.SendMoreProducts}
                        </button>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => handleChangeTicketStatus(RETURN_ORDER_STATUS.ReplaceProducts)}>
                            {RETURN_ORDER_STATUS_NAME.ReplaceProducts}
                        </button>
                    </Fragment>
                );
            case RETURN_ORDER_STATUS.DeclineReceivingOff:
                return (
                    <React.Fragment>
                        <button className="btn btn-danger btn-sm float-right" onClick={handleCloseTicket}>
                            <i className="fa fa-close m-r-5" /> Đóng yêu cầu
                        </button>
                        {returnOrderTicket.status == RETURN_ORDER_STATUS.Refund && returnOrderTicket.ticketHistoryStatus == TicketHistoryStatus.AdminRefundSucceed ? (
                            ""
                        ) : (
                            <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => handleChangeTicketHistoryStatus(TicketHistoryStatus.AdminRefundSucceed)}>
                                Hoàn tiền {returnOrderTicket.refundTypeName} thành công
                            </button>
                        )}
                    </React.Fragment>
                );
            case RETURN_ORDER_STATUS.ReplaceProducts:
            case RETURN_ORDER_STATUS.SendMoreProducts:
                return (
                    <React.Fragment>
                        <button className="btn btn-danger btn-sm float-right" onClick={handleCloseTicket}>
                            <i className="fa fa-close m-r-5" /> Đóng yêu cầu
                        </button>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => updateTicketHistoryStatus(TicketHistoryStatus.AdminSendProduct)}>
                            {`Admin gửi hàng thay thế thành công`}
                        </button>
                    </React.Fragment>
                );
            case RETURN_ORDER_STATUS.Refund:
                return (
                    <React.Fragment>
                        <button className="btn btn-danger btn-sm float-right" onClick={handleCloseTicket}>
                            <i className="fa fa-close m-r-5" /> Đóng yêu cầu
                        </button>
                        <button className="btn btn-custom btn-sm float-right mr-2" onClick={() => updateTicketHistoryStatus(TicketHistoryStatus.AdminRefundSucceed)}>
                            {`Admin hoàn tiền thành công`}
                        </button>
                    </React.Fragment>
                );
            default:
                return "";
        }
    };

    const handleComment = async () => {
        if (isValid()) {
            setBusy({ isBusy: true, busyMsg: "Đang lưu dữ liệu..." });
            ticketComment.ticketId = returnOrderTicket.id;
            const [err, data] = await ReturnRequestService.AddTicketComment(ticketComment);

            if (!err && data) {
                setTicketComment("");
            } else {
                Notify(NOTIFY.WARNING, "Lỗi", CONSTANTS.MSG_ERROR);
            }
            setBusy({ isBusy: false, busyMsg: "" });
        }
    };

    return (
        <React.Fragment>
            <Loading show={busy.isBusy} msg={busy.busyMsg} />
            <Breadcrumbs currentPage="Yêu cầu đổi trả đơn hàng" parentPage="Quản lý đổi trả hàng" hrefParent="/ticket-return-order" />
            <div className="ticket-return-order-detail">
                <div className="card-box margin-bottom-10 padding-10">
                    <h4 className="header-title">Thông tin đơn hàng</h4>
                    <div className="row">
                        <label className="col-md-3 label-normal">Số đơn hàng:</label>
                        <strong className="col-md-9">{returnOrderTicket.orderNumber}</strong>
                    </div>
                    <div className="row">
                        <label className="col-md-3 label-normal">Tiêu đề:</label>
                        <strong className="col-md-9">{returnOrderTicket.title}</strong>
                    </div>
                    <div className="row">
                        <label className="col-md-3 label-normal">Nội dung chi tiết:</label>
                        <strong className="col-md-9">{returnOrderTicket.description}</strong>
                    </div>
                    <div className="row">
                        <label className="col-md-3 label-normal">Trạng thái:</label>
                        <strong className="col-md-9">{RETURN_ORDER_STATUS_NAME_ARR[returnOrderTicket.status]}</strong>
                    </div>
                    <div className="row">
                        <label className="col-md-3 label-normal">Danh sách sản phẩm:</label>
                        <div className="col-md-9">
                            <CommonTable
                                classDivTable="min-h-70px"
                                data={returnOrderTicket.returnOrderProducts}
                                ref={tableRef}
                                tableGuid={Common.generateGuid()}
                                renderHeader={renderTableHeader}
                                renderBody={() => renderTableBody(returnOrderTicket.returnOrderProducts)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-3 label-normal">Thông tin địa chỉ hoàn hàng:</label>
                        <div className="col-md-9">
                            Tên: {returnOrderTicket.userName}, Số điện thoại: {returnOrderTicket.phoneNumber}, Địa chỉ: {returnOrderTicket.streetAddress}, {returnOrderTicket.wardName}, {returnOrderTicket.districtName},{" "}
                            {returnOrderTicket.provinceName}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-3 label-normal">Thông tin địa chỉ hoàn tiền:</label>
                        <div className="col-md-9">{returnOrderTicket.refundType == RefundType.MoMo ? "Refund bằng Ví MoMo" : `Ngân hàng: ${returnOrderTicket.bankAccountName}, Tên tài khoản: ${returnOrderTicket.refundAddress}`}</div>
                    </div>

                    {returnOrderTicket.status === RETURN_ORDER_STATUS.Closed ? (
                        ""
                    ) : (
                        <div className="row">
                            <div className="col-md-9 offset-md-3">
                                {renderButton()}
                                {isShowChangeStatusModal && (
                                    <TicketReturnOrderChangeStatusModal
                                        isShowModal={isShowChangeStatusModal}
                                        returnOrderTicket={returnOrderTicket}
                                        nextStatus={nextStatus}
                                        onClose={() => setIsShowChangeStatusModal(false)}
                                        onDone={handleSucceedModal}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="card-box margin-bottom-10 padding-10">
                    <h4 className="header-title">Ticket comments</h4>
                    <TextArea id="content" name="content" className="form-control" rows="2" setTicketComment value={ticketComment.content} required ref={addRef} onChanged={(name, value) => setTicketComment({ ...ticketComment, content: value })} />
                    <MultipleImages
                        className="hide"
                        id="attachments"
                        name="attachments"
                        images={ticketComment.attachments}
                        isPreview
                        limit={5}
                        classNamePreview="p-2 col-md-2"
                        onChanged={(name, newImages) => setTicketComment({ ...ticketComment, attachments: newImages })}
                    />
                    <button className="btn btn-custom btn-sm my-2" onClick={handleComment}>
                        Cập nhật
                        <i className="mdi mdi-send ml-2" />
                    </button>
                    <button
                        className="btn btn-sm mx-2"
                        onClick={() => {
                            document.getElementById("attachments").click();
                        }}
                    >
                        <i className="mdi mdi-image mdi-18px" />
                    </button>
                    <hr />
                    <div className="timeline timeline-left">
                        {ticketComments && ticketComments.length > 0
                            ? ticketComments.map((item, index) => {
                                  var userColor = currentUserName == item.userName ? "custom" : "danger";
                                  return (
                                      <article key={index} className="timeline-item">
                                          <div className="timeline-desk">
                                              <div className="panel timeline-box">
                                                  <span className="arrow" />
                                                  <span className={`timeline-icon bg-${userColor}`}>
                                                      <i className="mdi mdi-checkbox-blank-circle-outline" />
                                                  </span>
                                                  <h4 className={`text-${userColor}`}>{item.userName}</h4>
                                                  <p className="timeline-date text-muted">
                                                      <small>{Moment(item.createdDate).format("HH:mm DD/MM/YYYY")}</small>
                                                  </p>
                                                  <p>{item.content}</p>
                                                  {item.attachments && item.attachments.length > 0 ? (
                                                      <MultipleImages className="hide" images={item.attachments} isPreview disableRemoveImages classNamePreview="p-2 col-md-2" onChanged={(name, newImages) => {}} />
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                          </div>
                                      </article>
                                  );
                              })
                            : ""}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TicketReturnOrderDetail;
