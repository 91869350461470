import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import { Modal } from "react-bootstrap";
import Loading from "../../based/Loading";
import Language from "../Language";

function CommonModal(props) {
    const { show, size, id, isBusy, busyMsg, classModalBody, hiddenFooter, customButton, error, title, onClose, children, onSave, isHideClose } = props;
    return (
        <Modal show={show} size={size} id={id} enforceFocus={false}>
            <Loading show={isBusy ? isBusy : false} msg={busyMsg ? busyMsg : "Đang tải dữ liệu..."} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classModalBody}>{children}</Modal.Body>
            {hiddenFooter ? null : (
                <Modal.Footer>
                    {customButton ? customButton : null}
                    {onSave != undefined ? (
                        <Button
                            color='#4caf50'
                            onClick={() => {
                                if (onSave) onSave();
                            }}
                            title='Lưu'
                        />
                    ) : null}
                    {error ? (
                        <Button className='btn btn-danger w-md waves-light btn-sm' onClick={onClose}>
                            {Language.getValue("common.btnClose")}
                        </Button>
                    ) : onClose && !isHideClose ? (
                        <Button
                            color='#ff1744'
                            onClick={() => {
                                if (onClose) onClose();
                            }}
                            title='Đóng'
                        />
                    ) : null}
                </Modal.Footer>
            )}
        </Modal>
    );
}

CommonModal.propTypes = {
    isModal: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    width: PropTypes.number,
};

export default CommonModal;
