import React, { useState , useEffect, useRef} from "react";
import styled from "styled-components"
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import BaseServices from "../based/services/BaseServices";
import Common from "../based/Common";
import { Notify } from "../based/Notify";
import Loading from "../based/Loading";
import PetErrorModal from "./PetErrorModal";
import { ConfirmDialog } from "../based/Dialog";

const INIT_DATA = {
    size: "",
    productName: "",
    colorCode: "",
    orderId: 0,
    productId: 0,
    printedStatus: false,
    operatorOrderId: 0,
    orderDate: new Date(),
    totalPrintPet: 0,
    countPrintedPet: 0,
    printingBatchs: [],
    barcodes: [],
    sellerSKU: ""
}
const PetManagement = () => {
    const [data, setData] = useState({...INIT_DATA})
    const [product , setProduct] = useState([])
    const [paging, setPaging] = useState({...INITPAGING})
    const [isBusy, setBusy] = useState(false);
    const tableRef = useRef(null)
    const inputRef = useRef(null)
    const [code, setCode] = useState("")
    const [isAction , setIsAction] = useState(false)
    const [isModalError , setIsModalError] = useState(false)
    const [changeOperatorOrderId , setChangeOperatorOrderId] = useState(0)

    useEffect(()=>{
        if(!isModalError){
            inputRef.current.focus()
        }
    },[isModalError])

    const handleGetProduct = async (code) => {
        setBusy(true);
       
        const [err, dt] = await BaseServices.Get(`/api/PetPressed/get-pet-by-code/${code}`)
        if (!err) {
            ///nếu có dt trả về 
            if(dt){
                if(data.operatorOrderId && data.sellerSKU && data.operatorOrderId != dt.operatorOrderId){
                    if(dt.operatorOrderId == changeOperatorOrderId){
                        setData(dt);
                        setProduct(dt.products)
                        setIsAction(false)
                        window.scrollTo(0,0)
                        Notify(NOTIFY.SUCCESS , NOTIFY.SUCCESS,"Đổi arkwork thành công");
                    }else{
                        setChangeOperatorOrderId(dt.operatorOrderId)
                        Notify(NOTIFY.WARNING , "Yêu cầu đổi arkwork","Quét lại lần 2 để thay đổi arkwork");
                    }
                }else{
                    setData(dt);
                    setProduct(dt.products)
                    setIsAction(false)
                    window.scrollTo(0,0)
                }
            }else{
                //check sku
                if(data.operatorOrderId && data.sellerSKU && !data.printedStatus){
                    //bỏ số lô của tem áo
                    let sku = code.substring(0, code.length - 1)
                    if(data.sellerSKU && data.sellerSKU === sku){
                        setIsAction(true);
                        Notify(NOTIFY.SUCCESS , NOTIFY.SUCCESS,"Tiến hành ép");
                    }else{
                        setIsAction(false);
                        Notify(NOTIFY.WARNING , NOTIFY.WARNING,"Sai áo");
                    }
                }else{
                    Notify(NOTIFY.WARNING , NOTIFY.WARNING,"Không tim thấy arkwork");
                }
            }
            
        } else {
            setData({...INIT_DATA})
            setProduct([])
            Notify(NOTIFY.ERROR , NOTIFY.ERROR,"Lỗi api");
        }
        


        setCode("")
        setBusy(false);

    } 

    const handlePressSuccess = async (operatorOrderId) => {
        if(operatorOrderId > 0){
            setBusy(true);
            const [err, data] = await BaseServices.Get(`/api/PetPressed/change-status/${operatorOrderId}`);
            if (!err && data) {
                handleGetProduct(operatorOrderId)
                Notify(NOTIFY.SUCCESS , NOTIFY.SUCCESS,"Thành công");
            } else {
                Notify(NOTIFY.ERROR , NOTIFY.ERROR,"Thất bại");
            }
            setBusy(false);
        }
    }

    const handleError = async () => {
        if(data.operatorOrderId > 0){
            setIsModalError(true)
        }
    }


    const _renderAction = () =>{
        return <h6 className="mb-2">
            Danh sách đã ép
        </h6>
    }

    const _renderBody = () => {
        return (
            <tbody>
                {product && product.length > 0 && product.map((item,idx)=>{
                    return <tr key={idx} className={`${item.printedStatus ? "success" : "error"}`}>
                        <td>{item.printDate 
                        && Common.formatDate(new Date(item.printDate), "datetime")}</td>
                        <td>{item.barcodes.length > 0 && 
                                item.barcodes.map((item , i)=>{
                                    return <div key={i}>{item.barcode}</div>
                                })
                            }</td>
                        <td>{item.orderId}</td>
                        <td>{item.productName}</td>
                        <td>{item.size}</td>
                        <td>                                
                            <div style={{width: '100%', height: 40,background: data.colorCode}}></div>
                        </td>
                        <td>{item.productId}</td>
                        <td>{item.operatorOrderId}</td>
                        <td>{Common.formatDate(new Date(item.orderDate), "datetime")}</td>
                        <td className="status">{
                            item.printedStatus ? "Đã xong" : "Chưa xong"
                        }</td>
                        <td style={{textAlign: 'center'}}>
                            <button className="btn btn-custom"
                                onClick={ async ()=>{
                                    setCode(item.operatorOrderId)
                                    handleGetProduct(item.operatorOrderId)
                                }}
                            >Chi tiết</button>
                        </td>
                    </tr>
                })}
            </tbody>)
    }

    const _renderHeader = () => {
        return (
            <thead>
                <tr className="text-center">
                    <th>Thời gian ép</th>
                    <th>Mã SX</th>
                    <th>Mã đơn hàng</th>
                    <th>Dòng sản phẩm</th>
                    <th>Size</th>
                    <th>Màu</th>
                    <th>Mã SP</th>
                    <th>Tag ID</th>
                    <th>Ngày đặt</th>
                    <th>Trạng thái</th>
                    <th></th>
                </tr>
            </thead>
        );
    }
    
    return (
        <StylePet>
            {isModalError &&  <PetErrorModal operatorOrderId={data.operatorOrderId} onClose={()=>{setIsModalError(false)}} />}
            <Loading show={isBusy}  />
            <Breadcrumbs currentPage="Quản lý xử lý ép PET (DTF)"  />
            <div className="box-flex">
                <div className="box-item w-35 mh">
                    <div className="item">
                        <div className="box-input">
                            <input type="text" placeholder="Mã sản xuất/Tag ID" value={code} ref={inputRef} onBlur={(e)=>{
                                if(!isModalError){
                                    setTimeout(()=>{
                                        inputRef.current.focus()
                                    },2000)
                                }
                                
                            }} autoFocus onKeyPress={(e)=>{
                                if(e.charCode == 13){
                                    handleGetProduct(code)
                                }
                            }} onChange={(e)=>{
                                setCode(e.target.value)
                            }}/>
                            <button className="btn btn-custom" onClick={()=>{handleGetProduct(code)}}>Nhập</button>
                        </div>
                        <div className={`box-name box-custom ${data.operatorOrderId ? isAction || data.printedStatus ? "success" : "error" : ""}`}>
                            <span>Sản phẩm</span>
                            <strong>{data.productName}</strong>
                        </div>
                        <div className={`box-flex box-custom ${data.operatorOrderId ? isAction || data.printedStatus ? "success" : "error" : ""}`}>
                            <div className="w-33">
                                <span>Size</span>
                                <strong className="size">{data.size}</strong>
                            </div>
                            <div className="w-33">
                                <span>Màu</span>
                                <div className="color" style={{background: data.colorCode}}></div>
                            </div>
                            <div className="w-33">
                                <span>Mã sản phẩm</span>
                                <strong className="name">{data.sellerSKU}</strong>
                            </div>
                        </div>
                        <div className="box-info">
                            <div>
                                <strong>Mã đơn hàng</strong>
                                <span>{data.orderId}</span>
                            </div>
                            <div>
                                <strong>Tag ID</strong>
                                <span>{data.operatorOrderId}</span>
                            </div>
                            <div>
                                <strong>Batch SX</strong>
                                <span>{data.printingBatchs.length > 0 && 
                                data.printingBatchs.map((item , i)=>{
                                    return <div key={i}>{item}</div>
                                })}</span>
                            </div>
                            <div>
                                <strong>Ngày đặt hàng</strong>
                                <span>{Common.formatDate(new Date(data.orderDate))}</span>
                            </div>
                        </div>
                        <div className={`box-statictis ${data.countPrintedPet === data.totalPrintPet ? "success" : "error"}`}>
                            <span>Đã ép</span>
                            <strong>{data.countPrintedPet}/{data.totalPrintPet}</strong>
                        </div>
                        {data.printedStatus ?
                            <div className="box-button">
                                <button className="btn btn-success">Đã ép</button>
                            </div> : 
                            <div className="box-button">
                                <button className={`btn btn-danger ${data.operatorOrderId ? "" : "disabled"}`}
                                    onClick={()=>{
                                        if(data.operatorOrderId)
                                            handleError()
                                    }}
                                >Hình lỗi</button>
                                <button className={`btn btn-custom ${isAction && data.operatorOrderId ? "" : "disabled"}`}
                                    onClick={()=>{
                                        if(isAction && data.operatorOrderId){
                                            handlePressSuccess(data.operatorOrderId)
                                        }
                                    }}
                                >Đã ép xong</button>
                            </div>
                        }
                        
                    </div>
                </div>
                <div className="box-item w-65 box-flex">
                    {data.barcodes.length > 0 && data.barcodes.map((item , idx)=>{
                        return  <div key={idx} className="box-item w-50 mb">
                                    <div className="item">
                                        <div className="title">
                                            {item.artworkType == 1 && "Mặt trước"}
                                            {item.artworkType == 2 && "Mặt sau"}

                                        </div>
                                        <div className="box-img">
                                            <img src={item.productImageUrl} />

                                        </div>
                                        <div>
                                            <span className="title">Mã sản xuất: </span>
                                            <span className="code">{item.barcode}</span>
                                        </div>
                                        <div className="box-flex">
                                            <div className="w-33">
                                                <span>Kích thước</span>
                                                <strong>{item.width}x{item.height}cm</strong>
                                            </div>
                                            <div className="w-33">
                                                <span>Top - Cổ</span>
                                                <strong>{item.collarToImage}cm</strong>
                                            </div>
                                            <div className="w-33">
                                                <span>Giữa - Trái</span>
                                                <strong>{item.leftToMid}cm</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    })}
                    
                </div>
                
                
                
            </div>
            <div className="table mt-3">
                <CommonTable data={data} paging={paging} 
                hideSearchBasicButton renderAction={_renderAction} 
                renderHeader={_renderHeader} renderBody={_renderBody} 
                ref={tableRef}/>
            </div>

        </StylePet>
    );
};
const StylePet =  styled.div`

    .table{
        tr{
            &.success{
                background-color: #DFFFE0;
                
                td{
                    &.status{
                        color: #28B259;
                    }
                }
                
            }
            &.error{
                background-color: #FFDFDF;
                
                td{
                    &.status{
                        color: #F96A74;
                    }
                }
                
            }
        }
        
    }
    .box-flex {
        width: 100%;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        .box-custom{

            &.error{
                background-color: #FFDFDF;
                
                strong{
                    color: #F96A74 !important;
                }
            }

            &.success{
                background-color: #DFFFE0;
                
                strong{
                    color: #64C5B1 !important;
                }
            }
        }

        
        

        .box-item{
            width: 25%;
            min-width: 25%;
            max-width: 25%;
            padding: 0 8px;

            
            &.mb{
                margin-bottom: 5px;
            }

            &.w-35{
                width: 35%;
                min-width: 35%;
                max-width: 35%;
            }

            &.w-33{
                width: 33.33333%;
                min-width: 33.33333%;
                max-width: 33.33333%;
            }
            

            &.w-65{
                width: 65%;
                min-width: 65%;
                max-width: 65%;
            }

            &.w-50{
                width: 50%;
                min-width: 50%;
                max-width: 50%;
            }
            
            .item{
                width: 100%;
                padding: 12px;
                background: #fff;
                height: 100%;
                position: relative;
                margin-bottom: 5px;

                .box-input{
                    display: flex;
                    margin-bottom: 12px;

                    input{
                        width: 65%;
                        height: 38px;

                        ::placeholder{
                            color: #444;
                            font-size: 14px;
                            font-weight: 500;
                            opacity: 0.2;
                        }
                    }
                    button{
                        width: 35% !important;
                        height: 38px;
                    }
                }

                .box-name{
                    border: 1px solid #ccc;
                    display: flex;
                    flex-direction: column;
                    padding: 6px;
                    strong{
                        margin-top: 8px;
                        color: #444;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 1.2;
                    }
                }

                .box-info{
                    margin-top: 8px;
                    padding: 6px;
                    border: 1px solid #ccc;

                    div{
                        margin-bottom: 10px;
                        display: flex;

                        :last-child{
                            margin-bottom: 0;
                        }

                        strong{
                            display: block;
                            width: 110px;
                        }
                        span{
                            color: #817979;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                    
                }
                .box-statictis{
                    display: flex;
                    border: 1px solid #ccc;
                    align-items: center;
                    height: 39px;
                    padding: 6px;
                    span{
                        display: block;
                        width: 110px;
                    }

                    strong{
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 1
                    }

                    &.error{
                        background-color: #FFDFDF;
                        span{
                            color: #F96A74;
                        }
                        strong{
                            color: #F96A74;
                        }
                    }

                    &.success{
                        background-color: #DFFFE0;
                        span{
                            color: #64C5B1;
                        }
                        strong{
                            color: #64C5B1;
                        }
                    }
                    
                }

                .title{
                    color: #444;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1;
                }

                .code{
                    margin-left: 8px;
                    color: #444;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1;
                }

                .box-img{
                    width: 100%;
                    text-align: center;
                    margin: 6px auto;

                    img{
                        width: 95%;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }
                }
                

                .w-33{
                    width: 33.33333%;
                    min-width: 33.33333%;
                    max-width: 33.33333%;
                    border: 1px solid #ccc;
                    padding: 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 65px;

                    span{
                        line-height: 1;
                        color: #817979;
                        font-size: 14px;
                        font-weight: 400;
                        white-space: nowrap;

                    }

                    .size{
                        color: #444;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 1;
                    }

                    .name{
                        color: #444;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 1;
                    }

                    .color{
                        width: 100%;
                        height: 20px;
                    }

                    strong{
                        color: #444;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 1;
                    }
                }

                .box-button{
                    width: 100%;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    button{
                        width: 45% !important;
                    }
                }
            }


            
        }
    }
`
export default PetManagement;
