import BaseServices from "./BaseServices";

const CrawlServices = {
    GetCrawlLinkShops: async (page) => {
        return await BaseServices.Post("/api/crawl/get-crawl-link-shops", page);
    },
    RemoveLinkShop: async (id) => {
        return await BaseServices.Get("/api/crawl/remove-link-shop/" + id);
    },
    GetLinkShopById: async (id) => {
        return await BaseServices.Get("/api/crawl/get-link-shop-by-id/" + id);
    },
    CreateUpdateLinkShop: async (model) => {
        return await BaseServices.Post("/api/crawl/create-update-link-shop", model);
    },
    GetCrawlProductInfos: async (page) => {
        return await BaseServices.Post("/api/crawl/get-crawl-product-infos", page);
    },
    GetCrawlKeywords: async (page) => {
        return await BaseServices.Post("/api/crawl/get-crawl-keywords", page);
    },
    RemoveKeyword: async (id) => {
        return await BaseServices.Get("/api/crawl/remove-keyword/" + id);
    },
    CreateUpdateKeyword: async (model) => {
        return await BaseServices.Post("/api/crawl/create-update-keyword", model);
    },
    GetCrawlProductInfosByKeyword: async (page) => {
        return await BaseServices.Post("/api/crawl/get-crawl-product-infos-by-keyword", page);
    },
};

export default CrawlServices;
