import React from "react";
import { ARTWORK_TYPE, OPTION_POSITION } from "../based/Constants";
import { AgencyArtWorkModel } from "../models/AgencyArtWorkModel";
import { PrintFrameConfigModel } from "../models/PrintFrameConfigModel";
import { SetBrandStampModel } from "../models/SetBrandStampModel";
import { SetModel } from "../models/SetModel";

class PrintDetailConfig {
    constructor() {
        this.artworkType = ARTWORK_TYPE.None;
        this.optionTop = 0;
        this.optionPosition = OPTION_POSITION.PositionA;
    }
}

export class SetProductContextModel {
    constructor() {
        this.setModel = new SetModel();
        this.setSetModel = () => { };
        this.printDetail = new PrintDetailConfig();
        this.setPrintDetail = () => { };
        this.config = new PrintFrameConfigModel();
        this.setConfig = () => { };
        this.listArtwork = [new AgencyArtWorkModel()];
        this.setListArtwork = () => { };
        this.listBrandStamp = [];
        this.setListBrandStamp = () => { };
        this.ageSetModel = new SetModel();
        this.setAgeSetModel = () => { };
        this.addRef = (ref) => { };

    }
}

export const SetProductContext = React.createContext(new SetProductContextModel());
