import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { INITPAGING, NOTIFY } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import StockFolderServices from "../based/services/StockFolderServices";

class StockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.parentFolderId = 0;
        this.thumbnail = "";
    }
}

function MoveModal({ isShowModal, onClose, currentId, onSave }) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [datas, setDatas] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);

    useEffect(() => {
        _getData();
    }, []);

    const _getData = async () => {
        let [err, data] = await StockFolderServices.GetListStockFolderPagings({ ...INITPAGING, pageNumber: 1, pageSize: 100 });
        if (!err && data && data.listObjects) {
            let labels = data.listObjects.map((item) => ({
                value: item.id,
                text: item.name,
                label: item.name,
            }));
            setDatas(labels);
        } else setDatas([]);
    };

    const _handleSave = async () => {
        let [err, data] = await StockFolderServices.UpdateStockFolderParent(currentId, +selectedValue);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thất bại");
        }
    };

    return (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={datas.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <Wrapper>
                <form>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="name" className="d-block my-2 required">
                                Chọn folder muốn di chuyển đến
                            </label>
                            <TFUSelect
                                name="province"
                                value={selectedValue}
                                options={datas}
                                required
                                placeholder="--Di chuyển--"
                                className="pb-3"
                                onChanged={(value) => {
                                    setSelectedValue(value);
                                }}
                            />
                        </div>
                    </div>
                </form>
            </Wrapper>
        </CommonModal>
    );
}

MoveModal.propTypes = {};

export default MoveModal;

const Wrapper = styled.div`
    .preview-thumbnail {
        height: 100px;
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;
