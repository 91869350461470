import React, { useState, useEffect } from "react";
import CommonModal from "../based/Modal";
import TextNumber from "../based/inputs/TextNumber";
import { CONSTANTS, NOTIFY, IMAGE_TYPE } from "../based/Constants";
import useFormValidate from "../hooks/useFormValidate";
import { Notify } from "../based/Notify";
import TFUImage from "../based/inputs/Image";
import TFUTooltip from "../based/TFUTooltip";
import BrandServices from "../based/services/BrandServices";

export default function UploadFileLogo(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [config, setConfig] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(() => {
        setConfig(props.brand);
    }, props.showUpload);
    function _handleInputChange(key, value) {
        if (value) {
            if (props.showUpload.type == "main") setConfig({ ...config, fileUrl: value });
            else setConfig({ ...config, subFileUrl: value });
            document.getElementById("uploadImages").value = null;
        }
    }
    async function _handleSave() {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let post = { ...config, type: props.showUpload.type == "main" ? 1 : 2 };
            const [err, data] = await BrandServices.HandleLogo(post);
            if (!err && data) {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.SUCCESS, "Thành công", data);
                props.onSuccess();
            } else {
                setIsLoading(false);
                setMsg(null);
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
            }
        }
    }
    function removeLogoFile() {
        if (props.showUpload.type == "main") setConfig({ ...config, fileUrl: "" });
        else setConfig({ ...config, subFileUrl: "" });
    }
    function _renderUploadLogo() {
        let fileUrl = "";
        if (props.showUpload.type == "main") fileUrl = config.fileUrl;
        else fileUrl = config.subFileUrl;

        return (
            <div className='ui-photo'>
                <TFUImage className='hide' id='uploadImages' name='uploadImages' images={fileUrl} onChanged={_handleInputChange} maximumSize='5242880' imageType={IMAGE_TYPE.Brand} />
                <ul className='ui-photo-grid clearfix'>
                    <li className='ui-photo-item col-md-12'>
                        {fileUrl && fileUrl.length > 0 ? (
                            <div className='ui-photo-box'>
                                <img className='ui-photo-item__image' src={fileUrl} />
                                <div className='photo-hover-overlay'>
                                    <ul className='photo-overlay-actions'>
                                        <li>
                                            <button className='btn btn-link photo-overlay-actions__link' onClick={() => removeLogoFile()}>
                                                <i className='fa fa-trash-o' style={{ color: "red" }}></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div
                                className='file-upload position-relative'
                                onClick={() => {
                                    document.getElementById("uploadImages").click();
                                }}
                            >
                                <div className='fileupload-text text-center'>
                                    <i className='fa fa-camera font-size-30px'></i>
                                    <p className='mb-0 mt-2 text-secondary'>(*.png | 5MB)</p>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
            </div>
        );
    } 
    return (
        <CommonModal title={`Upload file Logo: ${props.showUpload.type == "main" ? config.mainBarcode : config.subBarcode}`} show={props.showUpload.isShow} isBusy={isLoading} busyMsg={msg} onClose={() => props.onClose()} onSave={() => _handleSave()}>
            {_renderUploadLogo()}
            <div className='form-group form-row col-md-12'>
                <div className='col-md-6'>
                    <label htmlFor='width' className='required'>
                        Chiều rộng&nbsp;
                        <TFUTooltip content='Chiều ngang barcode' target='infoWidth'>
                            <i className='fa fa-question-circle' id='infoWidth' />
                        </TFUTooltip>
                    </label>
                    <TextNumber required name='width' ref={addRef} className='form-control' onChanged={(name, value) => setConfig({ ...config, width: value })} value={config.width} />
                </div>
                <div className='col-md-6'>
                    <label htmlFor='height' className='required'>
                        Chiều cao&nbsp;
                        <TFUTooltip content='Chiều cao barcode' target='infoheight'>
                            <i className='fa fa-question-circle' id='infoheight' />
                        </TFUTooltip>
                    </label>
                    <TextNumber required name='height' ref={addRef} className='form-control' onChanged={(name, value) => setConfig({ ...config, height: value })} value={config.height} />
                </div>
            </div>
            <div className='form-group form-row col-md-12'>
                <label className='full-width'>
                    Tọa độ (X,Y)&nbsp;
                    <TFUTooltip content='Toạ độ trên cùng bên trái của barcode' target='infoheight'>
                        <i className='fa fa-question-circle' id='infoheight' />
                    </TFUTooltip>
                </label>
                <div className='col-md-6'>
                    <label htmlFor='X' className='required'>
                        X
                    </label>
                    <TextNumber required name='X' ref={addRef} className='form-control' onChanged={(name, value) => setConfig({ ...config, X: value })} value={config.X} />
                </div>
                <div className='col-md-6'>
                    <label htmlFor='Y' className='required'>
                        Y
                    </label>
                    <TextNumber required name='Y' ref={addRef} className='form-control' onChanged={(name, value) => setConfig({ ...config, Y: value })} value={config.Y} />
                </div>
            </div>
        </CommonModal>
    );
}
