import React, { useContext } from "react";
import TextNumber from "../based/inputs/TextNumber";
import Textbox from "../based/inputs/Textbox";
import { ProductVariantContext } from "../context/ProductVariantContext";

export default function SectionPrices(props) {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);

    return (
        <React.Fragment>
            <div className="section-productVariants-pricing col-md-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Giá của biến thể</b>
                </h4>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="variantImportPrice" className="col-form-label">
                            Giá nhập
                        </label>
                        <TextNumber
                            onChanged={(name, value) => setEditingVariant({ ...editingVariant, importPrice: value, isModified: true })}
                            price={true}
                            key={`variantImportPrice[${editingVariant.id}]`}
                            id="variantImportPrice"
                            name="variantImportPrice"
                            value={editingVariant.importPrice}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="variantSellPrice" className="col-form-label">
                            Giá bán
                        </label>
                        <TextNumber
                            onChanged={(name, value) => setEditingVariant({ ...editingVariant, sellPrice: value, isModified: true })}
                            price={true}
                            ref={addRef}
                            key={`variantSellPrice[${editingVariant.id}]`}
                            id="variantSellPrice"
                            name="variantSellPrice"
                            value={editingVariant.sellPrice}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="variantStandardPrice" className="col-form-label">
                            Giá sàn (Giá thấp nhất có thể bán)
                        </label>
                        <TextNumber
                            onChanged={(name, value) => setEditingVariant({ ...editingVariant, standardPrice: value, isModified: true })}
                            price={true}
                            key={`variantStandardPrice[${editingVariant.id}]`}
                            id="variantStandardPrice"
                            name="variantStandardPrice"
                            value={editingVariant.standardPrice}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="variantVoucherPrice" className="col-form-label">
                            Giá Voucher <small>(Giá được sử dụng cho Voucher thưởng)</small>
                        </label>
                        <TextNumber
                            onChanged={(name, value) => setEditingVariant({ ...editingVariant, voucherPrice: value, isModified: true })}
                            price={true}
                            key={`variantVoucherPrice[${editingVariant.id}]`}
                            id="variantVoucherPrice"
                            name="variantVoucherPrice"
                            value={editingVariant.voucherPrice}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-3">
                        <label htmlFor="varaintSellerSku" className="col-form-label required">
                            SKU
                        </label>
                        <Textbox
                            key={`varaintSellerSku[${editingVariant.id}]`}
                            id="varaintSellerSku"
                            name="varaintSellerSku"
                            value={editingVariant.sellerSKU}
                            ref={addRef}
                            required
                            onChanged={(name, value) => setEditingVariant({ ...editingVariant, sellerSKU: value, isModified: true })}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="variantBarcode" className="col-form-label required">
                            Barcode
                        </label>
                        <Textbox
                            key={`variantBarcode[${editingVariant.id}]`}
                            id="variantBarcode"
                            name="variantBarcode"
                            value={editingVariant.barcode}
                            ref={addRef}
                            required
                            onChanged={(name, value) => setEditingVariant({ ...editingVariant, barcode: value, isModified: true })}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
