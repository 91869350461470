import React, { useEffect, useState } from "react";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import VoucherServices from "../based/services/VoucherServices";
import TextArea from "../based/inputs/TextArea";

export default function AddOrEdit(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [codeValid, setCodeValid] = useState({ status: 0, message: "err" });
    useEffect(() => {
        setVoucher(props.voucher);
        setCodeValid({ status: 1, message: "" });
    }, [props.isShowModal]);

    async function _handleSave(isContinue) {
        if (isValid() && codeValid.status === 1) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await VoucherServices.SaveVoucherFreeShip(voucher);
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
                if (isContinue) setVoucher(props.voucher);
                else props.onClose();
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        }
    }

    const handleRegexCodeVoucher = (value) => {
        setVoucher({ ...voucher, code: value });
        let regex = new RegExp(/[!@# $%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
        if (regex.test(value)) setCodeValid({ status: 2, message: CONSTANTS.INVALID_VOUCHER_NAME });
        else if (value.length && value.length < 6) setCodeValid({ status: 2, message: CONSTANTS.MSG_MIN_LENGTH_INVALID.replace("##", "06") });
        else if (value.length && value.length > 15) setCodeValid({ status: 2, message: CONSTANTS.MSG_MAX_LENGTH_INVALID.replace("##", "15") });
        else setCodeValid({ status: 1, message: "" });
    };

    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            title={voucher.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave(false)}>
                        <i className='fa fa-save m-r-5' /> Lưu
                    </button>
                    {voucher.id > 0 ? null : (
                        <button className='btn btn-outline-primary btn-sm' onClick={() => _handleSave(true)}>
                            <i className='fa fa-save m-r-5' /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            <div className='form-group'>
                <div className='label__group'>
                    <label htmlFor='code' className='required'>
                        Mã Voucher:
                    </label>
                    <div className='suggestions .ext-info'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>&nbsp;
                    </div>
                </div>
                <Textbox name='code' required ref={addRef} maxLength={200} className={`form-control font-weight-bold ${codeValid.status === 2 ? "alert-danger" : ""}`} onChanged={(name, value) => handleRegexCodeVoucher(value.trim())} value={voucher.code} />
                {codeValid.status === 2 ? <span className='text-danger'>{codeValid.message}</span> : null}
            </div>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Tên Voucher:
                </label>
                <Textbox name='name' required ref={addRef} maxLength={200} className='form-control' onChanged={(name, value) => setVoucher({ ...voucher, name: value })} value={voucher.name} />
            </div>
            <div className='form-group'>
                <label htmlFor='minimumOrderValue' className='required'>
                    Giá trị đơn hàng tối thiểu:
                </label>
                <TextNumber
                    required
                    ref={addRef}
                    id='minimumOrderValue'
                    suffix='đồng'
                    name='minimumOrderValue'
                    className='form-control'
                    value={voucher.minimumOrderValue}
                    onChanged={(name, value) => setVoucher({ ...voucher, minimumOrderValue: value })}
                />
            </div>
            <div className='form-group'>
                <label htmlFor='maximumDiscount' className='required'>
                    Giảm giá tối đa:
                </label>
                <TextNumber required ref={addRef} id='maximumDiscount' suffix='đồng' name='maximumDiscount' className='form-control' value={voucher.maximumDiscount} onChanged={(name, value) => setVoucher({ ...voucher, maximumDiscount: value })} />
            </div>
            <div className='form-group'>
                <label htmlFor='description'>Mô tả:</label>
                <TextArea ref={addRef} name='description' value={voucher.description} onChanged={(name, value) => setVoucher({ ...voucher, description: value })} />
            </div>
        </CommonModal>
    );
}
