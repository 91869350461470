import BaseServices from "./BaseServices";

const AnalyticServices = {
    GetTrackingByPaging: async (paging) => {
        return BaseServices.Post("/api/analytic/get-tracking-bypaging", paging);
    },
    ExportExcel: async (model) => {
        return BaseServices.Post("/api/analytic/export-excel-tracking", model);
    },
    GetSearchByPaging: async (paging) => {
        return BaseServices.Post("/api/analytic/get-keyword-bypaging", paging);
    },
    ExportSearchExcel: async (model) => {
        return BaseServices.Post("/api/analytic/export-excel-search", model);
    },
    ExportSearchTrafficExcel: async (model) => {
        return BaseServices.Post("/api/analytic/export-excel-search-traffic", model);
    },
    GetPageCountPaging: async (paging) => {
        return BaseServices.Post("/api/analytic/get-pagecount-bypaging", paging);
    },
    GetBannedIPPaging: async (paging) => {
        return BaseServices.Post("/api/analytic/get-banned-ip-paging", paging);
    },
};

export default AnalyticServices;
