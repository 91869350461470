import React, { useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../based/Breadcrumbs";
import ComboServices from "../based/services/ComboServices";
import { useEffect } from "react";
import Common from "../based/Common";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";

function ComboManagement(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [datas, setDatas] = useState([]);
    const history = useHistory();

    useEffect(() => {
        _getData({ ...paging });
    }, []);

    const _getData = async (paging) => {
        let [err, data] = await ComboServices.GetComboByPage(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
    };

    const _deleteData = async (id) => {
        ConfirmDialog("Xác nhận xoá", "Bạn có muốn xoá Combo hiện tại", () => {
            return new Promise(async (resolve, reject) => {
                let [err, data] = await ComboServices.DeleteCombo(id);
                if (!err && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    _getData({ ...paging });
                } else Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
            });
        });
    };

    const _renderAction = () => {
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group">
                    <button
                        className="btn btn-custom btn-sm m-r-5"
                        onClick={() => {
                            history.push("/combo-edit");
                        }}
                    >
                        <i className="fa fa-plus m-r-5" />
                        Thêm
                    </button>
                </div>
            </div>
        );
    };
    const _renderHeader = () => {
        return (
            <thead>
                <tr className="text-center">
                    <th className="center">STT</th>
                    <th width={"20%"} className="center">
                        Thumbnail
                    </th>
                    <th className="center">Tên Combo</th>
                    <th className="center">Ngày hiệu lực</th>
                    <th className="center">Giá bán</th>
                    <th className="center">Sản phẩm</th>
                    <th className="center">Chỉnh sửa</th>
                </tr>
            </thead>
        );
    };
    const _renderBody = () => {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className="text-center" key={idx}>
                                <td>{idx + 1}</td>
                                <td>
                                    <img src={item.thumbnail} alt="thumbnail" className="img-thumbnail" />
                                </td>
                                <td>{item.name}</td>
                                <td>
                                    Từ {Common.formatDate(new Date(item.fromDate))}
                                    <br />
                                    Đến {Common.formatDate(new Date(item.toDate))}
                                </td>
                                <td>{Common.formatCurrency(item.sellPrice)}</td>
                                <td>
                                    <div>{item.productContentApply}</div>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            history.push(`/combo-edit/${item.id}`);
                                        }}
                                    >
                                        <i className="fa fa-edit" />
                                    </button>
                                    <button className="btn btn-danger ml-2" onClick={(e) => _deleteData(item.id)}>
                                        <i className="fa fa-remove" />
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    };
    const _handleFilter = () => {};

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Combo" />
            <Loading show={isLoading} msg={"Đang tải dữ liệu"} />
            <CommonTable renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} paging={paging} data={datas} onFilter={_handleFilter} />
        </Wrapper>
    );
}

export default ComboManagement;

const Wrapper = styled.div`
    .img-thumbnail {
        width: 100%;
        height: auto;
    }
`;
