import React, { Component } from "react";
import { CONSTANTS, NOTIFY } from "../../based/Constants";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import Request from "../../based/Request";
import TFUSelect from "../../based/inputs/TFUSelect";
import ImageServices from "../../based/services/ImageServices";
import { ImageModel } from "../../models/BaseProductModel";

class MultipleImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            isValid: true,
            msgInvalid: "",
            selectedIndex: -1,
            isUploading: false,
        };
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }

    handleImageChange(event) {
        event.preventDefault();
        if (!this.state.isValid)
            this.setState({
                isValid: true,
                msgInvalid: "",
            });
        let files = event.target.files;
        let images = [];
        if (this.props.images && this.props.images.length > 0) {
            this.props.images.map((image) => {
                images.push(image);
            });
        }
        if (this.props.limit && files && files.length + images.length > this.props.limit) {
            Notify(NOTIFY.WARNING, "Lỗi", `Chỉ được tải lên tối đa ${this.props.limit} ảnh.`);
            return;
        }
        for (let index = 0; index < files.length; index++) {
            let reader = new FileReader();
            const pic = files[index];
            let size = pic.size;
            if (size <= (this.props.size ? this.props.size : 2) * 1024 * 1000) {
                this.setState({ isUploading: true });
                reader.onloadend = () => {
                    Request.UploadImage(pic, this.props.imageType || 0)
                        .then((res) => {
                            let newImage = new ImageModel();
                            newImage.url = res.data.imageUrl;
                            images.push(newImage);
                            if (this.props.onChanged) {
                                this.props.onChanged(this.props.name, images);
                            }
                            this.setState({
                                isUploading: false,
                                selectedIndex: this.state.selectedIndex === -1 ? 0 : this.state.selectedIndex,
                            });
                            document.querySelector(`#${this.props.id ? this.props.id : "btn-upload-input"}`).value = "";
                        })
                        .catch((err) => {
                            this.setState({
                                isValid: false,
                                msgInvalid: CONSTANTS.MSG_IMAGE_INVALID,
                            });
                            this.file.value = "";
                            this.setState({
                                isUploading: false,
                            });
                            document.querySelector(`#${this.props.id ? this.props.id : "btn-upload-input"}`).value = "";
                        });
                };
            } else {
                this.setState({
                    isValid: false,
                    msgInvalid: CONSTANTS.MSG_IMAGE_INVALID,
                });
            }
            reader.readAsDataURL(pic);
        }
        if (this.props.replace && this.props.images) {
            for (let i = 0; i < this.props.images.length; i++) {
                let img = this.props.images[i];
                if (img) {
                    img = img.split("/")[img.split("/").length - 1];
                    Request.DeleteImage(img)
                        .then((res) => {})
                        .catch((err) => {});
                }
            }
        }
    }

    async handleRemoveImage(idx) {
        if (!this.state.isValid)
            this.setState({
                isValid: true,
                msgInvalid: "",
            });
        let images = this.props.images;
        let img = images[idx];
        img = img.url.split("/")[img.url.split("/").length - 1];
        // Request.DeleteImage(img)
        //     .then((res) => {})
        //     .catch((err) => {});

        //xóa image trên table
        let _ = await ImageServices.DeleteImage(img);

        images.splice(idx, 1);
        this.props.onChanged(this.props.name, images);
        var selectedIndex = this.state.selectedIndex;
        if (selectedIndex + 1 > images.length) {
            this.setState({ selectedIndex: images.length - 1 });
        }
    }

    displayError(msg) {
        this.setState({
            isValid: false,
            msgInvalid: msg,
        });
    }

    handleSelectImage(index) {
        this.setState({
            selectedIndex: index,
        });
    }

    handleSwap(isUp) {
        if ((isUp && this.state.selectedIndex == 0) || (!isUp && this.state.selectedIndex == this.props.images.length - 1) || this.state.selectedIndex < 0 || this.state.selectedIndex > this.props.images.length - 1) return;

        if (this.props.onSwap) {
            if (isUp) {
                let idx = this.state.selectedIndex - 1;
                this.props.onSwap(idx, this.state.selectedIndex);
                this.setState({ selectedIndex: idx });
            } else {
                let idx = this.state.selectedIndex + 1;
                this.props.onSwap(this.state.selectedIndex, idx);
                this.setState({ selectedIndex: idx });
            }
        }
    }

    render() {
        let { images } = this.props;
        let $imagePreview = null;
        if (images) {
            $imagePreview = images.map((image, i) => {
                if (image)
                    return (
                        <div className={this.state.selectedIndex == i ? this.props.classNamePreview + " preview selected" : this.props.classNamePreview + " preview"} key={i}>
                            {this.props.imageTypeOptions && (
                                <div className="w-100 p-b-10">
                                    <TFUSelect
                                        options={this.props.imageTypeOptions}
                                        value={image.imageType}
                                        onChanged={(value, name) => {
                                            let currentImages = this.props.images;
                                            let curr = currentImages[i];
                                            curr.imageType = value;
                                            this.props.onChanged(this.props.name, currentImages);
                                        }}
                                    />
                                </div>
                            )}
                            <img src={image.url} style={{ maxWidth: "100%" }} onClick={this.props.sortable ? this.handleSelectImage.bind(this, i) : null} />
                            {!this.props.disableRemoveImages && (
                                <a className="remove-image" onClick={this.handleRemoveImage.bind(this, i)} style={{ position: "absolute" }}>
                                    <span className="fa fa-times"></span>
                                </a>
                            )}
                        </div>
                    );
            });
        }
        return (
            <div className="previewComponent">
                <input
                    id={this.props.id ? this.props.id : "btn-upload-input"}
                    className={this.props.className ? "fileInput " + this.props.className : "fileInput"}
                    ref={(ins) => (this.file = ins)}
                    type="file"
                    multiple={true}
                    accept="image/x-png, image/gif, image/jpeg, image/bmp"
                    onChange={this.handleImageChange.bind(this)}
                />
                {this.props.sortable && this.props.images && this.props.images.length > 0 ? (
                    <div className="sortable">
                        <a onClick={this.handleSwap.bind(this, true)} alt="Move Up">
                            <span className="move-up fa fa-arrow-left"></span>
                        </a>
                        <a onClick={this.handleSwap.bind(this, false)} alt="Move Down">
                            <span className="move-down fa fa-arrow-right"></span>
                        </a>
                    </div>
                ) : null}
                {this.props.isPreview ? (
                    <div className="imgPreview position-relative row" style={this.state.isUploading ? { height: "140px", marginBottom: "10px" } : {}}>
                        <Loading show={this.state.isUploading} msg={"Đang tải ảnh lên"} contentClassName={"mt-3"} />
                        {$imagePreview}
                    </div>
                ) : null}
                {this.state.isValid ? null : <label className="text-danger">{this.state.msgInvalid}</label>}
            </div>
        );
    }
}

export default MultipleImages;
