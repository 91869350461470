import React, { Component } from "react";
import ReactDOM from "react-dom";
import Modal from "./Modal";
import Loading from "./Loading";
import { Notify } from "./Notify.js";
import { NOTIFY } from "./Constants.js";

class ConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            busyMsg: "Deleting...",
        };
        this.handleConfirm = this.handleConfirm.bind(this);
    }
    handleConfirm() {
        this.setState({
            isBusy: true,
        });
        this.props.onConfirm();
    }
    render() {
        return (
            <Modal show={this.props.show} bsSize='sm' id='modalConfirm' onClose={this.props.onClose} busy={this.state.isBusy} busyMsg={this.state.busyMsg} title={this.props.title ? this.props.title : "Confirm Delete"} onConfirm={this.handleConfirm}>
                <p>{this.props.message ? this.props.message : "Do you really want to DELETE this record?"}</p>
            </Modal>
        );
    }
}

class ErrorModal extends Component {
    render() {
        return (
            <Modal show={this.props.show} bsSize='sm' id='modalConfirm' onClose={this.props.onClose} title={this.props.title ? this.props.title : "Error"} error>
                <p>{this.props.message ? this.props.message : "Has an error from server. Please contact admin to get support."}</p>
            </Modal>
        );
    }
}
/**
 * Open dialog to show IMPORTANT ERROR that have sent by Server.
 * @param {string} title The title of this Dialog
 * @param {string} message The content of this Dialog
 */
var ErrorDialog = function (title, message) {
    let modelElement = document.getElementById("err-dialog");
    if (!modelElement) {
        modelElement = document.createElement("div");
        modelElement.setAttribute("id", "err-dialog");
    }
    var closeDialog = function () {
        ReactDOM.render(<ErrorModal show={false} />, modelElement);
    };
    ReactDOM.render(<ErrorModal show={true} title={title} message={message} onClose={closeDialog} />, modelElement);
};

/**
 * Open dialog to confirm an issue.
 * @param {string} title The title of this Dialog.
 * @param {string} message The content of this Dialog.
 * @param {Promise} processConfirm The function was executed after accept this confirmation.
 */
var ConfirmDialog = function (title, message, processConfirm, isCloseImmediately = false, processClose = null) {
    let modelElement = document.getElementById("confirm-dialog");
    if (!modelElement) {
        modelElement = document.createElement("div");
        modelElement.setAttribute("id", "confirm-dialog");
    }
    var confirm = function (callback) {
        processConfirm()
            .then((res) => {
                if (!isCloseImmediately) Notify(NOTIFY.SUCCESS, res.title, res.msg);
                closeDialog();
            })
            .catch((err) => {
                if (!isCloseImmediately) Notify(NOTIFY.ERROR, err.title, err.msg);
                if (processClose) processClose();
                closeDialog();
            });
    };
    var closeDialog = function () {
        ReactDOM.render(<ConfirmModal show={false} />, modelElement);
    };
    ReactDOM.render(<ConfirmModal show={true} title={title} message={message} onConfirm={confirm} onClose={closeDialog} />, modelElement);
};
export { ConfirmDialog, ErrorDialog };
