import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-currency-format";
import { CARD_COLOR, NOTIFY } from "../../based/Constants";
import { Block } from "../../based/content/ct.elm";
import TextNumber from "../../based/inputs/TextNumber";
import { Notify } from "../../based/Notify";
import Button, { ButtonType } from "../../based/refactor/Button";
import CommonTable from "../../based/refactor/CommonTable";
import CampaignServices from "../../based/services/CampaignServices";
import PrintServices from "../../based/services/PrintServices";
import { MilestoneModel } from "../../models/MilestoneModel";
const defaultMilestones = [new MilestoneModel(1, 10000)];

const ProductMilestones = ({ campaign, setCampaign }) => {
    const [readRateCard, setReadRateCard] = useState([]);
    const [milestones, setMilestones] = useState(defaultMilestones);
    const [lastMilestone, setLastMilestone] = useState(new MilestoneModel(2, 20000));
    const [allowEdit, setAllowEdit] = useState(true);
    const ageSet = campaign.app_Set;

    useEffect(() => {
        getReadRateCard();
    }, []);

    useEffect(() => {
        if (ageSet) {
            if (ageSet.productMilestoneCount) setAllowEdit(ageSet.productMilestoneCount.count > 0 ? false : true);
            //save
            if (!ageSet.milestones) {
                if (ageSet.id > 0 && campaign.id > 0) getMilestones(ageSet);
                else {
                    setMilestones(defaultMilestones);
                    setLastMilestone({
                        level: defaultMilestones.length + 1,
                        quantity: 1,
                        loyaltyAmount: ageSet.loyaltyAmount,
                        lotusChargeAmount: ageSet.lotusChargeAmount,
                        isLast: true,
                    });
                }
            }
            //update
            else {
                var formatedData = formatData(ageSet.milestones);
                setMilestones(formatedData);
                setLastMilestone({
                    level: formatedData.length + 1,
                    quantity: 1,
                    loyaltyAmount: ageSet.loyaltyAmount,
                    lotusChargeAmount: ageSet.lotusChargeAmount,
                    isLast: true,
                });
                saveTempMilestones(formatedData);
            }
        }
    }, [ageSet]);

    //lấy thông tin rate card config
    const getReadRateCard = async () => {
        const [err, data] = await PrintServices.ReadRateCard();
        if (!err && data) {
            setReadRateCard(data);
        } else {
            setReadRateCard([]);
        }
    };

    //lấy danh sách milestone theo set id, và campaign id
    const getMilestones = async (set) => {
        const [err, data] = await CampaignServices.GetProductMilestoneSetId(set.id, campaign.id);
        if (!err &&  data && data.length > 0) {
            var formatedData = formatData(data)
            setMilestones(formatedData);
            setLastMilestone({
                level: formatedData.length + 1,
                quantity: 0,
                loyaltyAmount: set.loyaltyAmount,
                lotusChargeAmount: set.lotusChargeAmount,
                isLast: true,
            });
            saveTempMilestones(formatedData);
        } else {
            setMilestones(defaultMilestones);
        }
    };

    //format lại data
    const formatData = (milestones) => {
        var formatedData = milestones.map((x, idx) => ({
            ...x,
            level: x.level,
            toQty: x.toQty,
            quantity: x.level == 1 ? x.toQty - x.fromQty + 1 : x.toQty - x.fromQty,
            loyaltyAmount: x.loyaltyAmount,
            lotusChargeAmount: x.lotusChargeAmount,
            setId: campaign.ageSetId,
        }));
        return formatedData;
    };

    //thay đổi thông tin milestone
    const handleChangeMilestoneValue = (name, value, idx) => {
        let milestonesUpdate = [];
        switch (name) {
            case "loyaltyAmount":
                // Calculate Ranus Fee amount
                let lotusChargeAmount = 0;
                if (value > 0 && readRateCard && readRateCard.length > 0) {
                    let total = 0;
                    let tmp = 0;
                    for (let index = 0; index < readRateCard.length; index++) {
                        if (value >= readRateCard[index].loyaltyAmount) {
                            total += (readRateCard[index].loyaltyAmount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                            tmp = readRateCard[index].loyaltyAmount;
                        } else {
                            total += (value - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                            break;
                        }
                    }
                    lotusChargeAmount = value - total;
                }
                milestonesUpdate = milestones.map((x) =>
                    x.level - 1 === idx
                        ? {
                              ...x,
                              [name]: value,
                              lotusChargeAmount: lotusChargeAmount,
                          }
                        : x
                );
                break;
            default:
                milestonesUpdate = milestones.map((x) => (x.level - 1 === idx ? { ...x, [name]: value } : x));
                break;
        }
        setMilestones(milestonesUpdate);
        saveTempMilestones(milestonesUpdate);
    };

    //lưu thông tin milestone
    const saveTempMilestones = (milestones) => {
        let campaignUpdate = { ...campaign };
        if (!campaignUpdate.milestones) campaignUpdate.milestones = [];
        campaignUpdate.milestones = milestones;
        setCampaign(campaignUpdate);
    };

    //thay đổi mức độ
    const handleChangeLevel = (type) => {
        switch (type) {
            case "increase":
                if (milestones.length < 3) {
                    var milestonesUpdate = [...milestones];
                    var lastMilestone = milestonesUpdate[milestonesUpdate.length - 1];
                    milestonesUpdate.push({
                        level: lastMilestone.level + 1,
                        quantity: 1,
                        loyaltyAmount: lastMilestone.loyaltyAmount + 10000,
                        lotusChargeAmount: 0,
                        productId: ageSet.id,
                    });
                    setMilestones(milestonesUpdate);
                    setLastMilestone({
                        level: milestonesUpdate.length + 1,
                        quantity: 0,
                        loyaltyAmount: ageSet.loyaltyAmount,
                        lotusChargeAmount: ageSet.lotusChargeAmount,
                        isLast: true,
                    });
                    saveTempMilestones(milestonesUpdate);
                } else {
                    Notify(NOTIFY.WARNING, "Cảnh báo", "Tối đa 4 mốc!");
                }
                break;
            case "decrease":
                if (milestones.length > 1) {
                    var milestonesUpdate = [...milestones];
                    milestonesUpdate.pop();
                    setMilestones(milestonesUpdate);
                    setLastMilestone({
                        level: milestonesUpdate.length + 1,
                        quantity: 0,
                        loyaltyAmount: ageSet.loyaltyAmount,
                        lotusChargeAmount: ageSet.lotusChargeAmount,
                        isLast: true,
                    });
                    saveTempMilestones(milestonesUpdate);
                } else {
                    Notify(NOTIFY.WARNING, "Cảnh báo", "Tối thiểu 2 mốc!");
                }
                break;
        }
    };

    //render table get data
    const renderHeaderTable = () => {
        return (
            <tr style={{ background: "#e4e4e4" }}>
                <th className='text-center' width='100'>
                    Mức Charge
                </th>
                <th className='text-center vertical-align-middle'>Mức tiền bản quyền (đ)</th>
                <th className='text-center vertical-align-middle'>Ranus Operation Fee (%)</th>
            </tr>
        );
    };

    //render table get data
    const renderBodyTable = () => {
        return (
            <React.Fragment>
                {readRateCard && readRateCard.length > 0 ? (
                    readRateCard.map((item, k) => {
                        return (
                            <tr key={k}>
                                <td className='text-center'>{k + 1}</td>
                                <td className='text-center'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                </td>
                                <td className='text-center'>{item.lotusChargePercent}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='3'>
                            <span>Chưa có cấu hình</span>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    //render table get data
    const RateCardConfig = useMemo(() => <CommonTable renderHeader={renderHeaderTable} renderBody={renderBodyTable} />);

    //render table mức độ
    const renderCreatePriceTableHead = () => {
        return (
            <tr>
                <th className='center'>Mức</th>
                <th className='center'>
                    <p className='center m-0 position-relative has-tooltip'>
                        Số lượng
                        <i className='cursor-pointer fa fa-info-circle ml-3'></i>
                        <span className='tooltip-text position-absolute'>Số lượng sản phẩm tham gia chiến dịch</span>
                    </p>
                </th>
                <th className='center'>Tiền bản quyền</th>
            </tr>
        );
    };

    //render table mức độ
    const renderCreatePriceTableBody = () => {
        return (
            <React.Fragment>
                {[...milestones, lastMilestone].map((item, idx) => (
                    <tr key={idx}>
                        <td className='center'>Mức {item.level}</td>
                        <td className='center'>
                            <TextNumber required name='quantity' className='form-control' readOnly={item.isLast || !allowEdit} value={item.quantity} min={1} max={999999999} onChanged={(name, value) => handleChangeMilestoneValue(name, +value, idx)} />
                        </td>
                        <td className='center'>
                            <TextNumber
                                required
                                name='loyaltyAmount'
                                className='form-control'
                                readOnly={item.isLast || !allowEdit}
                                suffix='đ'
                                value={item.loyaltyAmount}
                                min={1}
                                max={999999999}
                                onChanged={(name, value) => handleChangeMilestoneValue(name, +value, idx)}
                            />
                        </td>
                    </tr>
                ))}
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Block flexBasis='50%' boxShadow='none'>
                {RateCardConfig}
                <p className='text-danger mb-0 mt-20'>
                    <b>* </b>Tiền bản quyền thấp nhất là 0đ, bước giá thay đổi thấp nhất là 10k. Số lượng mốc tối thiểu là 2, tối đa là 4.
                </p>
                <p className='text-danger'>
                    <b>* </b>Ví dụ giá cost sản phẩm (120k), tiền bản quyền (64k): Giá bán (190k)
                </p>
            </Block>
            {ageSet ? (
                <Block flexBasis='50%' boxShadow='none'>
                    <CommonTable renderHeader={renderCreatePriceTableHead} renderBody={renderCreatePriceTableBody} />
                    {allowEdit ? (
                        <div className='d-flex mt-10'>
                            <Button btnType={ButtonType.Rounded} color={CARD_COLOR.Green} onClick={() => handleChangeLevel("increase")} title='Thêm mức' /> &nbsp;
                            <Button btnType={ButtonType.Rounded} color={CARD_COLOR.Red} onClick={() => handleChangeLevel("decrease")} title='Giảm mức' />
                        </div>
                    ) : null}
                </Block>
            ) : null}
        </React.Fragment>
    );
};

export default ProductMilestones;
