import styled from 'styled-components';

export const ContentWrapper = styled.div`
position: absolute;
top: 70px;
right: 300px;
width: ${({ toggle }) => toggle ? 'calc(100% - 300px - 240px)' : 'calc(100% - 300px - 59px)'} ;
height: calc(100vh - 70px);
transition: width .6s ease;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;

`
export const Box = styled.div`
width: 99%;
margin-top: 15px;
background: ${({ bgColor }) => bgColor ? bgColor : 'white'};
border-radius: 6px;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
padding: 10px 15px;
.box__header{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding: 10px ;
}
.box__content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 100px;
}
`
export const Block = styled.div`
padding:7px 10px;
background: ${({ bgColor }) => bgColor ? bgColor : 'white'};
flex-basis: 30%;
border-radius: 7px;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
margin-right: 15px;
cursor: pointer;

.header{
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    i{
        font-size: 25px;
    }
}
.content{
    min-height: 70px;
    color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    .info{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
`
