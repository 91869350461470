import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import TFUSelect from "../based/inputs/TFUSelect";
import PlatenSizeServices from "../based/services/PlatenSizeServices";
import SizeServices from "../based/services/SizeServices";
import Common from "../based/Common";
import Breadcrumbs from "../based/Breadcrumbs";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ''
};

const modelPlatenSize = {
    attributeValueId: 1,
    platenSize: 1,
    isAdd: true
};

const platenSizeEnum = [
    {value: 1, label: "P10x12"},
    {value: 2, label: "P14X16"},
    {value: 4, label: "P16X21"}
]

const PlatenSizeManage = () => {
    const [data, setData] = useState([]);
    const [dataSizeValue, setDataSizeValue] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);

    useEffect(() => {
        getData(paging);
        getSizeValue()
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await PlatenSizeServices.GetAttributePlatenSizeByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }


    
    async function getSizeValue() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await SizeServices.GetSizeValueDropDown();
        if (!err && data) {
            setDataSizeValue(data);
        } else {
            setDataSizeValue([]);
        }
        setBusy(false);
    }


    const handleChangeEnum = (value, idx)=>{
        let dt = [...data];
        if(Common.hasFlag(dt[idx].platenSize, value)){
            dt[idx].platenSize = dt[idx].platenSize & (~value);
        }else{
            dt[idx].platenSize = dt[idx].platenSize | value;
        }
        console.log('object :>> ', dt[idx].platenSize);
        setData(dt);
    }
    
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>AttributeValueId</th>
                    <th>AttributeValueName</th>
                    <th>PlatenSize</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 100 }}>
                                    {
                                        item.attributeValueId
                                    }
                                </td>
                                <td style={{  }}>
                                {item.isAdd || item.isUpdate ? (
                                         <TFUSelect
                                         className="select"
                                         options={dataSizeValue}
                                         placeholder="--Chọn--"
                                         value={item.attributeValueId}
                                         onChanged={(value) => {
                                             let dt = [...data];
                                             dt[idx].attributeValueId = value;
                                             setData(dt);
                                         }}/>
                                    ) : (
                                        item.attributeValueName
                                    )}
                                </td>
                                <td>
                                 {item.isAdd || item.isUpdate ? (
                                    platenSizeEnum.map((itm, i)=>{
                                            return <div key={i}>
                                                <input type="checkbox" checked={Common.hasFlag(item.platenSize, itm.value)} 
                                                    onChange={()=>{
                                                        handleChangeEnum(itm.value, idx)
                                                    }}
                                                />
                                                <span>{itm.label}</span>
                                            </div>
                                    })
                                    ) : (
                                        platenSizeEnum.map((itm, i)=>{
                                            if(Common.hasFlag(item.platenSize, itm.value))
                                            return <div key={i}>{itm.label}</div>
                                        })
                                    )}
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    {item.isAdd || item.isUpdate ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : <button
                                            className={`btn btn-custom m-1`}
                                            onClick={() => {
                                                let dt = [...data]
                                                dt[idx].isUpdate = true
                                                setData(dt)
                                            }}
                                        >
                                            Sửa
                                        </button>}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.attributeValueId, idx, item.isAdd);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                   
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {
        return <div className="mb-3 text-title"></div>;
    }

    async function handleAddItem() {
        setData([ ...data, { ...modelPlatenSize }]);
    }

    function IsDisable(idx) {
        if (!data[idx].attributeValueId || !data[idx].platenSize) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await PlatenSizeServices.CreateUpdateAttributePlatenSize(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại, kiểm tra lại tên lô");
        }
    }

    async function handleDelete(id, idx, isAdd) {
        if (!isAdd) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await PlatenSizeServices.DeleteAttributePlatenSize(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    
    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage='Quản lý khung in DTG' parentPage='Ranus' hrefParent='/' />
            <CommonTable data={data} paging={paging} searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />

            
        </StyleBody>
    );
};
const StyleBody = styled.div`

    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }

    
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    input[type="checkbox"]{
        accent-color: green;
        margin-right: 16px;

    }

   
    
`;
export default PlatenSizeManage;
