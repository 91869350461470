import BaseServices from "./BaseServices";

const PrintServices = {
    ExtractInkData: async (model) => {
        return await BaseServices.Post("/api/printer/extract-print-data", model);
    },
    ExtractListInkData: async (list) => {
        return await BaseServices.Post("/api/printer/extract-list-print-data", list);
    },
    CreateUpdate: async (model) => {
        return await BaseServices.Post("/api/printer/create-update", model);
    },
    ReadFirst: async () => {
        return await BaseServices.Get("/api/printer/read-first");
    },
    CreateUpdateStepConfig: async (model) => {
        return await BaseServices.Post("/api/printer/create-update-step-config", model);
    },
    ReadFirstStepConfig: async (id) => {
        return await BaseServices.Get(`/api/printer/read-first-step-config/${id}`);
    },
    CreateUpdateRateCard: async (models) => {
        return await BaseServices.Post("/api/printer/create-update-rate-card", models);
    },
    ReadRateCard: async () => {
        return await BaseServices.Get("/api/printer/read-rate-card");
    },
    CreateUpdateFrameConfig: async (model, attributeId) => {
        return await BaseServices.Post(`/api/printer/create-update-frame-config/${attributeId}`, model);
    },
    GetFrameConfigsByAttributeId: async (attributeId) => {
        return await BaseServices.Get(`/api/printer/get-config-by-attributeId/${attributeId}`);
    },
    RemoveFrameConfigByAttributeId: async (attributeId) => {
        return await BaseServices.Get(`/api/printer/remove-config-by-attributeId/${attributeId}`);
    },
    TryGetFrameByListAttributeId: async (ids) => {
        return await BaseServices.Post("/api/printer/try-get-frame-by-list-attId", ids);
    },
    SaveBrandStampConfig: async (model) => {
        return await BaseServices.Post("/api/printer/save-brand-stamp-config", model);
    },
    SaveGTXConfig: async (models) => {
        return await BaseServices.Post(`/api/printer/save-gtx-config`, models);
    },
    RemoveGTXConfig: async (id) => {
        return await BaseServices.Get(`/api/printer/remove-gtx-config/${id}`);
    },
    GetGTXConfig: async (id) => {
        return await BaseServices.Get(`/api/printer/get-gtx-config/${id}`);
    },
    GetPageGTXConfig: async (paging) => {
        return await BaseServices.Post(`/api/printer/get-page-gtx-config`, paging);
    },
    GetBrandSetModel: async (setId) => {
        return await BaseServices.Get(`/api/printer/get-brand-set-model/${setId}`);
    },
    GetBrandStampConfigBySetId: async (setId) => {
        return await BaseServices.Get(`/api/printer/get-brand-stamp-config-by-set/${setId}`);
    },
};

export default PrintServices;
