import BaseMTOServices from "./BaseMTOServices";

const MTOServices = {
    CreateUpdateDesign: async (model) => {
        return await BaseMTOServices.Post("/api/MakeToOrder/create-update-mto-design", model);
    },
    CreateUpdateCategory: async (model) => {
        return await BaseMTOServices.Post("/api/MakeToOrder/create-update-mto-category", model);
    },
    GetMTOCategoryById: async (id) => {
        return await BaseMTOServices.Get(`/api/MakeToOrder/get-mto-category/${id}`);
    },
    GetCategories: async (paging) => {
        return await BaseMTOServices.Post("/api/MakeToOrder/get-mto-categories", paging);
    },
    GetDesignByCategoryId: async (paging, id) => {
        return await BaseMTOServices.Post(`/api/MakeToOrder/get-mto-design-by-category-id?CategoryId=${id}`, paging);
    },
    DeleteCategory: async (ids) => {
        return await BaseMTOServices.Post(`/api/MakeToOrder/delete-mto-category`, ids);
    },
    DeleteDesign: async (ids) => {
        return await BaseMTOServices.Post(`/api/MakeToOrder/delete-mto-design`, ids);
    },
    FilterCategoryByKeyword: async (paging) => {
        return await BaseMTOServices.Post(`/api/MakeToOrder/mto-filter-categoies-by-keyword`, paging);
    },
    FilterDesignByKeyword: async (paging, id) => {
        return await BaseMTOServices.Post(`/api/MakeToOrder/mto-filter-design-by-keyword?CategoryId=${id}`, paging);
    },
};

export default MTOServices;
