import React, { useContext, useState } from "react";
import TagsInput from "react-tagsinput";
import CheckBox from "../based/inputs/CheckBox";
import TFUSelect from "../based/inputs/TFUSelect";
import TextNumber from "../based/inputs/TextNumber";
import Textbox from "../based/inputs/Textbox";
import { ProductVariantContext } from "../context/ProductVariantContext";
import { ProductVariantModel } from "../models/BaseProductModel";

export default function SectionVariants(props) {
    const { productVariants, setProductVariants, attributes, setAttributes } = useContext(ProductVariantContext);
    const [multiVariant, setMultiVariant] = useState(false);

    function _addVariant() {
        let newVariants = new Map(attributes.entries());
        newVariants.set("", []);
        setAttributes(newVariants);
    }
    /**
     * get product productVariants from attributes values
     * @param {Map} attributes the attributes
     */
    function _handleSetProductVariants(attributes) {
        if (attributes && attributes.size > 0) {
            let array = []; //array of map:
            attributes.forEach((value, key) => {
                array.push(
                    value.map((v, i) => ({
                        id: key,
                        value: v,
                        name: props.attributeOptions.find((m) => m.value == key).text,
                    }))
                );
            });
            let result = [];
            if (array.length >= 2) {
                result = __reduce(array[0], array[1]);
                for (let i = 2; i < array.length; i++) {
                    const element = array[i];
                    result = __reduce(result, element);
                }
            } else {
                result = array[0];
            }
            if (result.length != 0) {
                let _variants = [];
                for (let i = 0; i < result.length; i++) {
                    let attributes = Array.isArray(result[i]) ? result[i] : [result[i]];
                    let variant = new ProductVariantModel();
                    variant.attributes = attributes;
                    variant.productId = productVariants[0].productId;
                    _variants.push(variant);
                }
                if (_variants.length > 0) {
                    _variants[0].mainProductImage = productVariants[0].mainProductImage;
                    _variants[0].productName = productVariants[0].productName;
                    setProductVariants(_variants);
                }
            }
        } else {
            props.getProductVariants();
        }
    }
    function __reduce(array1, array2) {
        let result = [];
        for (var i = 0; i < array1.length; i++) {
            let arr = Array.isArray(array1[i]) ? Array.from(array1[i]) : [array1[i]];
            for (var j = 0; j < array2.length; j++) {
                let ar = arr.concat(array2[j]);
                result.push(ar);
            }
        }
        return result;
    }

    function _changeAttribute(oldKey, newKey) {
        let newVariants = new Map(attributes.entries());
        newVariants.delete(oldKey);
        if (newKey) {
            let variant = newVariants.has(newKey);
            if (!variant) {
                newVariants.set(newKey, []);
            }
        }
        setAttributes(newVariants);
        _handleSetProductVariants(newVariants);
    }
    function _changeAttributeValue(key, newValue) {
        let newVariants = new Map(attributes.entries());
        newVariants.set(key, newValue);
        setAttributes(newVariants);
        _handleSetProductVariants(newVariants);
    }

    function _renderAttributes() {
        let content = [];
        if (attributes && attributes.size > 0) {
            attributes.forEach((value, key) => {
                content.push(
                    <div className="form-row m-t-10" key={key}>
                        <div className="col-sm-6">
                            <TFUSelect options={props.attributeOptions} value={key} noOptionsMessage={() => "Không có dữ liệu"} onChanged={(v) => _changeAttribute(key, v)} isDisabled={false} />
                        </div>
                        <div className="col-sm-6">
                            {key && key.trim().length != 0 ? (
                                <TagsInput
                                    addKeys={[186]}
                                    value={value}
                                    onChange={(tag) => _changeAttributeValue(key, tag)}
                                    inputProps={{
                                        placeholder: "",
                                    }}
                                    disabled={false}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            });
        }
        return (
            <div>
                {content.length > 0 && content.map((m) => m)}
                <div className="my-2">
                    <button type="button" className="btn btn-custom waves-effect w-xs waves-light btn-sm" onClick={_addVariant}>
                        <i className="fa fa-plus"></i> Thêm biến thể
                    </button>
                    <span style={{ marginLeft: 20, fontStyle: "italic" }}>
                        (Các giá trị phân tách nhau bởi dấu <strong>;</strong>)
                    </span>
                </div>
            </div>
        );
    }
    function _handleChangeVariant(idx, name, value, attId) {
        let variant = productVariants[idx];
        if (attId > 0) variant.attributes[attId].value = value;
        else variant[name] = value;
        setProductVariants([...productVariants]);
    }
    /**
     * Render productVariants
     */
    function _renderAddingVariants() {
        let columns = [];
        attributes.forEach((v, k) => {
            let attribute = props.attributeOptions.find((m) => m.value == k);
            if (attribute) columns.push(attribute.label);
        });
        columns = columns.concat(["Giá", "SKU", "Barcode"]);
        return (
            <div>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            {columns.map((c, i) => (
                                <th key={i}>{c}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {productVariants &&
                            productVariants.map((variant, idx) => {
                                if (variant.attributes.length <= 0) return <tr></tr>;
                                return (
                                    <tr key={idx}>
                                        {variant.attributes &&
                                            variant.attributes.map((att, i) => (
                                                <td key={i}>
                                                    <span>{att.value}</span>
                                                </td>
                                            ))}
                                        {__renderVariant(variant, idx)}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        );
    }

    function __renderVariant(variant, idx) {
        return (
            <React.Fragment>
                <td>
                    <TextNumber
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, name, value);
                        }}
                        price={true}
                        name="sellPrice"
                        value={variant.sellPrice}
                    />
                </td>
                <td>
                    <Textbox
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, name, value);
                        }}
                        price={true}
                        name="sellerSKU"
                        value={variant.sellerSKU}
                    />
                </td>
                <td>
                    <Textbox
                        onChanged={(name, value) => {
                            _handleChangeVariant(idx, name, value);
                        }}
                        price={true}
                        name="barcode"
                        value={variant.barcode}
                    />
                </td>
            </React.Fragment>
        );
    }
    return (
        <div className="section-variant">
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Biến thể của sản phẩm</b>
                </h4>
                <div className="row">
                    <div className="col-sm-12">
                        {
                            <CheckBox
                                id="multiVariant"
                                name="multiVariant"
                                checked={multiVariant}
                                onCheckedChange={(name, value) => {
                                    setMultiVariant(value);
                                    if (!value) {
                                        setAttributes(new Map());
                                    }
                                }}
                                label="Sản phẩm có nhiều biến thể. Ví dụ khác nhau về màu sắc, kích thước..."
                            />
                        }
                        {multiVariant && _renderAttributes()}
                        {multiVariant && productVariants[0] && attributes && _renderAddingVariants()}
                    </div>
                </div>
            </div>
        </div>
    );
}
