
import BaseServices from "./BaseServices";

const VoucherWinnerHistoryServices = {
    // Voucher Brand
    GetTargetGroupWinnerPage: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-target-group-winner-page", paging);
    },
    GetTargetGroupWinnerDropdownUserName: async (paging) => {
        return await BaseServices.Post("/api/Voucher/get-target-group-winner-dropdown-username",paging);
    },
    GetTargetGroupWinnerDropdownGroupName: async (paging) => {
        return await BaseServices.Post(`/api/Voucher/get-target-group-winner-dropdown-groupname`, paging);
    },
    GetTargetGroupWinnerDropdownVoucher: async (paging) => {
        return await BaseServices.Post(`/api/Voucher/get-target-group-winner-dropdown-voucher`, paging);
    },
};

export default VoucherWinnerHistoryServices;
