import BaseServices from "../../based/services/BaseServices";
const VideoServices = {
    InsertJobVideo: async (model) => {
        return BaseServices.Post("/api/video/insert-job-video", model);
    },
    GetJobVideoByPage: async (paging) => {
        return BaseServices.Post("/api/video/get-job-video-by-page", paging);
    },
    CreateUpdateAfterEffect: async (model) => {
        return BaseServices.Post("/api/video/create-update-after-effect", model);
    },
    GetAfterEffectByPage: async (paging) => {
        return BaseServices.Post("/api/video/get-after-effect-by-page", paging);
    },
    DeleteAfterEffectById: async (id) => {
        return BaseServices.Get("/api/video/delete-after-effect-by-id/" + id);
    },
    GetAfterEffectById: async (id) => {
        return BaseServices.Get("/api/video/get-after-effect-by-id/" + id);
    },
    GetAfterEffectConfigModelById: async (id) => {
        return BaseServices.Get("/api/video/get-after-effect-config-model-by-id/" + id);
    },
    UpdateAfterEffectConfigModel: async (model) => {
        return BaseServices.Post("/api/video/update-after-effect-config-model", model);
    },
    CreateUpdatePresetConfigModel: async (model) => {
        return BaseServices.Post("/api/video/create-update-preset-config-model", model);
    },
    GetPresetConfigModelById: async (id) => {
        return BaseServices.Get("/api/video/get-preset-config-model-by-id/" + id);
    },
    GetProductAndColor: async () => {
        return BaseServices.Get("/api/video/get-product-and-color-dropdown");
    },
};


export default VideoServices;
