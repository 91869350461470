export class SetBrandStampModel {
    constructor() {
        this.ageSetId = 0;
        this.brandUrl = "";
        this.brandPosition = 0;
        this.brandDegree = 0;
        this.brandAppVariants = [];
        this.checked = 0;
        this.brandId = 0
    }
}
