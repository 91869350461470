import BaseServices from "./BaseServices";

const CommonServices = {
    GetProvinces: async (dropdown = 0) => {
        return await BaseServices.Get(`/api/Location/get-list-province?dropdown=${dropdown}`);
    },
    GetDistricts: async (provinceId, dropdown = 0) => {
        return await BaseServices.Get(`/api/Location/get-list-district-by-province-code/${provinceId}?dropdown=${dropdown}`);
    },
    GetWards: async (districtCode, dropdown = 0) => {
        return await BaseServices.Get(`/api/Location/get-list-ward-by-district-code/${districtCode}?dropdown=${dropdown}`);
    },
    GetListVNPostProvince: async () => {
        return await BaseServices.Get(`/api/Location/get-list-province-vnpost`);
    },
    GetListVNPostDistrict: async (provinceCode) => {
        return await BaseServices.Get(`/api/Location/get-list-district-vnpost/${provinceCode}`);
    },
    GetListVNPostWard: async (districtCode) => {
        return await BaseServices.Get(`/api/Location/get-list-ward-vnpost/${districtCode}`);
    },
    GetMenus: async () => {
        return await BaseServices.Get("/api/menu/get-menus");
    },
    GetBanks: async () => {
        return await BaseServices.Get("/api/Bank/get-banks");
    },
    GetAgencyLevel: async () => {
        return await BaseServices.Get("/api/systemConfig/get-agencyLevel");
    },
    GetWarranties: async () => {
        return await BaseServices.Get("/api/SystemWarranties/get-dropdown-model");
    },
    GetShippingServices: async () => {
        return await BaseServices.Get("/api/ShippingService/get-shipping-services");
    },
    GetDropdownOPFWarranties: async () => {
        return await BaseServices.Get("/api/systemWarranties/get-dropdown-opfwarranties");
    },
    GetDropdownsCreator: async () => {
        return await BaseServices.Get("/api/creator/get-dropdown");
    },
    GetOPFWarehousesByShop: async (shopId) => {
        return await BaseServices.Get(`/api/warehouse/get-opfwarehouse-byshop?shopId=${shopId}`);
    },
    GetMarketerUsers: async () => {
        return await BaseServices.Get("/api/Campaign/get-marketer-users");
    },
    GetListShopeeXpressProvince: async () => {
        return await BaseServices.Get(`/api/Location/get-list-province-shopeexpress`);
    },
    GetListShopeeXpressDistrict: async (provinceName) => {
        return await BaseServices.Get(`/api/Location/get-list-district-shopeexpress/${provinceName}`);
    },
    GetListShopeeXpressWard: async (districtName) => {
        return await BaseServices.Get(`/api/Location/get-list-ward-shopeexpress/${districtName}`);
    },
};

export default CommonServices;
