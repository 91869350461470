import React, { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY, VOUCHER_DISCOUNT_TYPE, VOUCHER_PRODUCT_SCOPE, VOUCHER_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import Textbox from "../based/inputs-refactor/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TextNumber from "../based/inputs/TextNumber";
import CheckBox from "../based/refactor/CheckBox";
import TFUSelect from "../based/refactor/TFUSelect";
import VoucherServices from "../based/services/VoucherServices";
import { useHistory } from "react-router-dom";

const Tab = {
    VoucherDetail: 1,
    ProductApply: 2,
};

const MethodPayment = {
    COD: 1,
    MoMo: 2,
    ZaloPay: 4,
};

const VoucherTypeOptions = [
    { value: VOUCHER_TYPE.Normal, label: "Giảm giá cho đơn hàng tối thiểu" },
    { value: VOUCHER_TYPE.Direct, label: "Mua với giá cố định" },
    { value: VOUCHER_TYPE.FreeProduct, label: "Tặng trực tiếp" },
    { value: VOUCHER_TYPE.Affiliate, label: "Affiliate" },
];

const VoucherDiscountTypeOptions = [
    { value: VOUCHER_DISCOUNT_TYPE.PERCENTAGE, label: "Phần trăm" },
    { value: VOUCHER_DISCOUNT_TYPE.FIXEDPRICE, label: "Giá cố định" },
];

const VoucherShowOptions = [
    { value: false, label: "Hiển thị trên trang voucher để thu thập" },
    { value: true, label: "Không hiển thị trên trang voucher để thu thập" },
];

const intitialPaging = {
    pageNumber: 1,
    pageSize: 10,
    totalRecord: 0,
};

class VoucherModel {
    constructor() {
        let now = new Date();
        let next2weeks = new Date();
        next2weeks.setDate(next2weeks.getDate() + 14);
        this.id = 0;
        this.code = "";
        this.name = "";
        this.description = "";
        this.type = VOUCHER_DISCOUNT_TYPE.PERCENTAGE;
        this.fromDate = now;
        this.toDate = next2weeks;
        this.quantity = 0;
        this.voucherType = VOUCHER_TYPE.Normal;
        this.colorCode = "#000000";
        this.voucherThumbnail = "";
        this.validCollectTime = 0;
        this.value = 0;
        this.lotusMaximumDiscountValue = 0;
        this.isCombined = false;
        this.isHideCollect = false;
        this.isActive = true;
        this.isApplyVoucherInput = false;
        this.voucherProductScope = 0;
        this.isApplyAll = true;
        this.voucherProducts = [];
        this.validPriceOrder = 0;
        this.maxUsage = 0;
        //voucher mua với giá
        this.buyWithPrice = 0;
        //voucher tặng quà trực tiếp
        this.voucherFreeProducts = [];
    }
}

function VoucherOverallEditor(props) {
    const voucherId = props.match.params.voucherId;
    const [tabId, setTabId] = useState(Tab.VoucherDetail);
    const [voucherModel, setVoucherModel] = useState(new VoucherModel());
    const [voucherProducts, setVoucherProducts] = useState([]);
    const [giftProducts, setGiftProducts] = useState([]);
    const voucherThumbnailRef = useRef(null);
    const [paging, setPaging] = useState({ ...intitialPaging });
    const [isFetching, setIsFetching] = useState(false);
    const [listProductOption, setListProductOption] = useState([]);
    const [listGiftOption, setListGiftOption] = useState([]);
    const [giftPaging, setGiftPaging] = useState({ ...intitialPaging });
    const [voucherScopeActive, setVoucherScopeActive] = useState(VOUCHER_PRODUCT_SCOPE.Brand);
    const history = useHistory();

    useEffect(() => {
        if (voucherId > 0) {
            //call api lấy thông tin edit
            getVoucherId(voucherId);
        } else {
            setVoucherModel(new VoucherModel());
        }

        return () => {
            setVoucherModel(null);
        };
    }, [voucherId]);

    useEffect(() => {
        getListProduct();
    }, [paging.keyword]);

    useEffect(() => {
        getListGift();
    }, [giftPaging.keyword]);

    const getListProduct = async () => {
        setIsFetching(true);
        const [err, data] = await VoucherServices.GetList(paging);
        if (!err && data) {
            const options = data.listObjects.map((product) => ({
                value: product.id,
                label: product.name,
                thumbnail: product.productAvatar,
                ...product,
            }));
            setListProductOption(options);
        } else {
            setIsFetching(false);
        }
    };

    const getListGift = async () => {
        setIsFetching(true);
        const [err, data] = await VoucherServices.GetSuggestGiftedProduct(paging);
        if (!err && data) {
            const options = data.listObjects.map((product) => ({
                value: product.id,
                label: product.name,
                thumbnail: product.avatar,
                ...product,
            }));
            setListGiftOption(options);
        } else {
            setIsFetching(false);
        }
    };

    const getVoucherId = async (id) => {
        const [err, data] = await VoucherServices.GetVoucherById(id);
        if (!err && data) {
            setVoucherModel(data);
            if (data.voucherProducts) setVoucherProducts(data.voucherProducts);
            if (data.voucherFreeProducts) setGiftProducts(data.voucherFreeProducts);
        } else {
            setVoucherModel(new VoucherModel());
        }
    };

    function renderBody() {
        switch (tabId) {
            case Tab.VoucherDetail:
                return renderVoucherDetail();
            case Tab.ProductApply:
                return renderProductApply();
            default:
                return null;
        }
    }

    function handleImage(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.UploadImage(file)
                .then((res) => {
                    setVoucherModel({ ...voucherModel, voucherThumbnail: res.data.imageUrl });
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }

    async function handleSave() {
        //tùy vào voucher product scope lấy ra danh sách voucher product, nếu là isApply all thì ignore
        let model = { ...voucherModel };
        if (model.isApplyAll) {
            model.voucherProducts = [];
        } else {
            let listFilterScope = [];
            if ((model.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Brand) > 0) listFilterScope.push(VOUCHER_PRODUCT_SCOPE.Brand);
            if ((model.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Store) > 0) listFilterScope.push(VOUCHER_PRODUCT_SCOPE.Store);
            if ((model.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Customize) > 0) listFilterScope.push(VOUCHER_PRODUCT_SCOPE.Customize);
            model.voucherProducts = [...voucherProducts].filter((x) => listFilterScope.includes(x.voucherProductScope));
        }

        //nếu là voucher free product thì add thêm danh sách quà tặng vào
        if (model.voucherType == VOUCHER_TYPE.FreeProduct) {
            model.voucherFreeProducts = [...giftProducts];
        }

        let [err, data] = await VoucherServices.AddOrUpdateVoucher(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Lưu voucher thành công!");
            history.push("/voucher-overall-management");
        } else {
            //read from error
            let msgError = err && err.errors && err.errors.reduce((prev, item, idx) => (prev += item.value + "\n"), "");

            Notify(NOTIFY.ERROR, "Lỗi", msgError || "Có lỗi xảy ra, vui lòng thử lại sau!");
        }
    }

    function handleScheme(voucherType) {
        switch (voucherType) {
            case VOUCHER_TYPE.Normal:
            case VOUCHER_TYPE.Affiliate:
                return (
                    <section className="scheme">
                        <div className="scheme-row">
                            <label className="scheme-row__label">Kiểu giảm giá</label>
                            <div className="scheme-row__input">
                                <TFUSelect
                                    options={VoucherDiscountTypeOptions}
                                    value={voucherModel.type}
                                    onChanged={(newVal) => {
                                        //phần trăm
                                        if (newVal == VOUCHER_DISCOUNT_TYPE.PERCENTAGE) {
                                            setVoucherModel({
                                                ...voucherModel,
                                                type: newVal,
                                            });
                                        }
                                        //giá cố định
                                        else {
                                            setVoucherModel({
                                                ...voucherModel,
                                                type: newVal,
                                                lotusMaximumDiscountValue: 0,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="scheme-row">
                            <div className="scheme-column">
                                <label className="scheme-row__label">Giá trị đơn tối thiểu (VNĐ)</label>
                                <div className="scheme-row__input">
                                    <TextNumber
                                        className="form-control"
                                        value={voucherModel.validPriceOrder}
                                        onChanged={(name, newVal) => {
                                            setVoucherModel({
                                                ...voucherModel,
                                                validPriceOrder: +newVal,
                                            });
                                        }}
                                        suffix="VNĐ"
                                    />
                                </div>
                            </div>
                            <div className="scheme-column">
                                <label className="scheme-row__label">Giảm giá {voucherModel.type == VOUCHER_DISCOUNT_TYPE.FIXEDPRICE ? "(VNĐ)" : "(%)"}</label>
                                <div className="scheme-row__input">
                                    <TextNumber
                                        className="form-control"
                                        value={voucherModel.value}
                                        onChanged={(name, newVal) => {
                                            setVoucherModel({
                                                ...voucherModel,
                                                value: +newVal,
                                            });
                                        }}
                                        min={1}
                                        max={voucherModel.type == VOUCHER_DISCOUNT_TYPE.FIXEDPRICE ? voucherModel.validPriceOrder : 100}
                                        suffix={voucherModel.type == VOUCHER_DISCOUNT_TYPE.FIXEDPRICE ? "VNĐ" : "%"}
                                    />
                                </div>
                            </div>
                            <div className="scheme-column">
                                <label className="scheme-row__label">Số tiền tối đa được giảm</label>
                                <div className="scheme-row__input">
                                    <TextNumber
                                        className="form-control"
                                        value={voucherModel.lotusMaximumDiscountValue}
                                        onChanged={(name, newVal) => {
                                            setVoucherModel({
                                                ...voucherModel,
                                                lotusMaximumDiscountValue: +newVal,
                                            });
                                        }}
                                        suffix="VNĐ"
                                        readOnly={voucherModel.type === VOUCHER_DISCOUNT_TYPE.FIXEDPRICE}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                );
            case VOUCHER_TYPE.Direct:
                return (
                    <section className="scheme">
                        <div className="scheme-row">
                            <div className="scheme-column">
                                <label className="scheme-row__label">Mua với giá (VNĐ)</label>
                                <div className="scheme-row__input">
                                    <TextNumber
                                        className="form-control"
                                        value={voucherModel.buyWithPrice}
                                        onChanged={(name, newVal) => {
                                            setVoucherModel({
                                                ...voucherModel,
                                                buyWithPrice: +newVal,
                                            });
                                        }}
                                        suffix="VNĐ"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                );
            default:
                return "";
        }
    }

    const addVoucherProductHandler = (product) => {
        const { id, name, avatar, sellPrice, voucherProductScope, sellerSKU } = product;
        const updatedProducts = [...voucherProducts];
        var oldProduct = updatedProducts.find((x) => x.productId == id && x.voucherProductScope == voucherProductScope);
        if (oldProduct) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn đã thêm sản phẩm này rồi!");
        } else {
            updatedProducts.push({
                voucherId: voucherModel.id,
                productId: id,
                productName: name,
                productAvatar: avatar,
                sellPrice: sellPrice,
                sellerSKU: sellerSKU,
                voucherProductScope: voucherProductScope,
            });
            setVoucherProducts(updatedProducts);
        }
    };

    const addGiftProductHandler = (product) => {
        const { id, name, avatar, sellerSKU, sellPrice } = product;
        const updatedProducts = [...giftProducts];
        var oldProduct = updatedProducts.find((x) => x.appProductVariantId == id);
        if (oldProduct) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn đã thêm sản phẩm này rồi!");
        } else {
            updatedProducts.push({
                appProductVariantId: id,
                productName: name,
                productAvatar: avatar,
                sellerSKU: sellerSKU,
                sellPrice: sellPrice,
            });
            setGiftProducts(updatedProducts);
        }
    };

    function renderVoucherDetail() {
        return (
            <React.Fragment>
                <section className="detail">
                    <div className="detail-content">
                        <div className="detail-content__item">
                            <div className="detail-content__item__image">
                                <label htmlFor="">Icon và màu nền</label>
                                <div className="detail-content__item__image__upload">
                                    <div
                                        className="detail-content__item__image__upload__image"
                                        onClick={(e) => {
                                            voucherThumbnailRef.current.click();
                                        }}
                                    >
                                        {voucherModel.voucherThumbnail ? <img src={voucherModel.voucherThumbnail} alt="image" /> : <img src="https://via.placeholder.com/150" alt="" />}
                                        <input
                                            type={"file"}
                                            accept={"image/*"}
                                            onChange={(e) => {
                                                handleImage(e);
                                            }}
                                            onClick={(e) => {
                                                e.target.value = null;
                                            }}
                                            ref={voucherThumbnailRef}
                                            className="hide"
                                        />
                                    </div>
                                    <div className="detail-content__item__image__upload__button">
                                        <div className="input-code">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={voucherModel.colorCode}
                                                onChange={(e) => {
                                                    let val = e.target.value;
                                                    setVoucherModel({
                                                        ...voucherModel,
                                                        colorCode: val.toUpperCase(),
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="input-color">
                                            <input
                                                type={"color"}
                                                value={voucherModel.colorCode}
                                                onChange={(e) => {
                                                    let val = e.target.value;
                                                    setVoucherModel({
                                                        ...voucherModel,
                                                        colorCode: val.toUpperCase(),
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="detail-content__item__info">
                                <div className="detail-content__item__info__row">
                                    <label className="detail-content__item__info__row__label">Tên voucher</label>
                                    <div className="detail-content__item__info__row__input">
                                        <Textbox
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            value={voucherModel.name}
                                            onChanged={(name, newVal) => {
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    name: newVal,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="detail-content__item__info__row">
                                    <label className="detail-content__item__info__row__label">Hạn sử dụng</label>
                                    <div className="detail-content__item__info__row__input">
                                        <TFUDatetime
                                            type="date"
                                            className="form-control"
                                            minDate={new Date()}
                                            value={new Date(voucherModel.fromDate)}
                                            onChanged={(newVal) => {
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    fromDate: newVal,
                                                });
                                            }}
                                            readOnly={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                        />
                                        <TFUDatetime
                                            type="date"
                                            className="form-control"
                                            minDate={new Date()}
                                            value={new Date(voucherModel.toDate)}
                                            onChanged={(newVal) => {
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    toDate: newVal,
                                                });
                                            }}
                                            readOnly={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                        />
                                    </div>
                                </div>
                                <div className="detail-content__item__info__row">
                                    <label className="detail-content__item__info__row__label">Số lượng mã</label>
                                    <div className="detail-content__item__info__row__input">
                                        <TextNumber
                                            className="form-control"
                                            value={voucherModel.quantity}
                                            onChanged={(name, newVal) => {
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    quantity: +newVal,
                                                });
                                            }}
                                            readOnly={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="detail-content__item__info">
                                <div className="detail-content__item__info__row">
                                    <label className="detail-content__item__info__row__label">Mã voucher</label>
                                    <div className="detail-content__item__info__row__input">
                                        <Textbox
                                            id="code"
                                            name="code"
                                            className="form-control"
                                            value={voucherModel.code}
                                            onChanged={(name, newVal) => {
                                                //trim value
                                                let value = newVal;
                                                value = value.replace(/\s/g, "");
                                                value = Common.removeVietnameseFromString(value);
                                                value = value.toUpperCase();
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    code: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="detail-content__item__info__row">
                                    <label className="detail-content__item__info__row__label">Thời gian dùng sau khi thu thập</label>
                                    <div className="detail-content__item__info__row__input">
                                        <TextNumber
                                            className="form-control"
                                            value={voucherModel.validCollectTime}
                                            onChanged={(name, newVal) => {
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    validCollectTime: +newVal,
                                                });
                                            }}
                                            suffix="Giờ"
                                            readOnly={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                        />
                                    </div>
                                </div>
                                <div className="detail-content__item__info__row">
                                    <label className="detail-content__item__info__row__label">Số voucher tối đa có thể thu thập</label>
                                    <div className="detail-content__item__info__row__input">
                                        <TextNumber
                                            className="form-control"
                                            value={voucherModel.maxUsage}
                                            onChanged={(name, newVal) => {
                                                setVoucherModel({
                                                    ...voucherModel,
                                                    maxUsage: +newVal,
                                                });
                                            }}
                                            readOnly={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="detail-description">
                            <textarea
                                value={voucherModel.description}
                                onChange={(e) => {
                                    setVoucherModel({
                                        ...voucherModel,
                                        description: e.target.value,
                                    });
                                }}
                                placeholder="Mô tả"
                                rows={5}
                            />
                        </div>
                    </div>
                </section>

                {handleScheme(voucherModel.voucherType)}

                <section className="payment">
                    <div className="payment-row">
                        <label className="payment-row__label">Hình thức thanh toán</label>
                        <div className="row-checkbox-list">
                            <div className="row-checkbox">
                                {/* bitwise checked */}
                                <CheckBox
                                    label="COD"
                                    checked={voucherModel.methodPayment & MethodPayment.COD}
                                    onCheckedChange={(name, newVal) => {
                                        if (newVal) {
                                            setVoucherModel({
                                                ...voucherModel,
                                                methodPayment: voucherModel.methodPayment | MethodPayment.COD,
                                            });
                                        } else {
                                            setVoucherModel({
                                                ...voucherModel,
                                                methodPayment: voucherModel.methodPayment & ~MethodPayment.COD,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="row-checkbox">
                                <CheckBox
                                    label="MOMO"
                                    checked={voucherModel.methodPayment & MethodPayment.MoMo}
                                    onCheckedChange={(name, newVal) => {
                                        if (newVal) {
                                            setVoucherModel({
                                                ...voucherModel,
                                                methodPayment: voucherModel.methodPayment | MethodPayment.MoMo,
                                            });
                                        } else {
                                            setVoucherModel({
                                                ...voucherModel,
                                                methodPayment: voucherModel.methodPayment & ~MethodPayment.MoMo,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="row-checkbox">
                                <CheckBox
                                    label="ZaloPay"
                                    checked={voucherModel.methodPayment & MethodPayment.ZaloPay}
                                    onCheckedChange={(name, newVal) => {
                                        if (newVal) {
                                            setVoucherModel({
                                                ...voucherModel,
                                                methodPayment: voucherModel.methodPayment | MethodPayment.ZaloPay,
                                            });
                                        } else {
                                            setVoucherModel({
                                                ...voucherModel,
                                                methodPayment: voucherModel.methodPayment & ~MethodPayment.ZaloPay,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="scope">
                    <div className="scope-row">
                        <label htmlFor="">Phạm vi hoạt động voucher</label>
                        <div className="scope-column">
                            <div className="row-checkbox">
                                <CheckBox
                                    label="Áp dụng với voucher khác"
                                    checked={voucherModel.isCombined}
                                    onCheckedChange={(name, newVal) => {
                                        setVoucherModel({
                                            ...voucherModel,
                                            isCombined: newVal,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="scope-column">
                            <div className="scope-show-voucher">
                                <TFUSelect
                                    options={VoucherShowOptions}
                                    value={voucherModel.isHideCollect}
                                    onChanged={(newVal) => {
                                        setVoucherModel({
                                            ...voucherModel,
                                            isHideCollect: newVal,
                                        });
                                    }}
                                    isDisabled={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                />
                            </div>
                            {voucherModel.isHideCollect && (
                                <div className="scope-check-voucher">
                                    <CheckBox
                                        label="Áp được mã tại ô voucher thanh toán"
                                        checked={voucherModel.isApplyVoucherInput}
                                        onCheckedChange={(name, newVal) => {
                                            setVoucherModel({
                                                ...voucherModel,
                                                isApplyVoucherInput: newVal,
                                            });
                                        }}
                                        readOnly={voucherModel.voucherType == VOUCHER_TYPE.Affiliate}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

    function renderProductApply() {
        return (
            <React.Fragment>
                {voucherModel.voucherType == VOUCHER_TYPE.FreeProduct && (
                    <section className="product-gift">
                        <label htmlFor="">Chọn quà tặng</label>
                        <ReactSelect
                            options={listGiftOption}
                            formatOptionLabel={(product) => (
                                <div className="custom-option">
                                    <img src={product.thumbnail} alt={product.label} />
                                    <span>{product.label}</span>
                                </div>
                            )}
                            id="giftKeyword"
                            name="giftKeyword"
                            className="react-select-up"
                            classNamePrefix="react-select"
                            placeholder="Nhập tên, mã sản phẩm ..."
                            onInputChange={(value) => setGiftPaging({ ...giftPaging, keyword: value })}
                            value={giftPaging.keyword}
                            onChange={(selected) => {
                                addGiftProductHandler(selected);
                            }}
                            noOptionsMessage={() => (isFetching ? "Đang tải dữ liệu" : "Không có dữ liệu")}
                        />

                        {/* create table with table tag */}
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th width={"20%"}>Hình</th>
                                    <th width={"20%"}>Mã sản phẩm</th>
                                    <th width={"30%"}>Tên sản phẩm</th>
                                    <th width={"20%"}>Giá</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {giftProducts ? (
                                    giftProducts.map((product, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="td-thumbnail">
                                                    <img src={product.productAvatar} alt={product.productName} />
                                                </td>
                                                <td>{product.sellerSKU}</td>
                                                <td>{product.productName}</td>
                                                <td>{product.sellPrice}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={(e) => {
                                                            let updatedProducts = [...giftProducts];
                                                            updatedProducts.splice(index, 1);
                                                            setGiftProducts(updatedProducts);
                                                        }}
                                                    >
                                                        Xóa
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            Không có dữ liệu
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </section>
                )}

                <section className="product-apply">
                    <label htmlFor="" className="main-label">
                        Chọn sản phẩm áp dụng voucher
                    </label>
                    <div className="apply-all">
                        <CheckBox
                            label="Áp dụng cho tất cả sản phẩm"
                            checked={voucherModel.isApplyAll}
                            onCheckedChange={(name, newVal) => {
                                if (newVal) {
                                    setVoucherModel({
                                        ...voucherModel,
                                        isApplyAll: newVal,
                                        voucherProductScope: 0,
                                    });
                                } else {
                                    setVoucherModel({
                                        ...voucherModel,
                                        isApplyAll: newVal,
                                    });
                                }
                            }}
                        />
                    </div>
                    <div className="apply-check">
                        <div className="apply-check-item">
                            <CheckBox
                                label="Thương hiệu"
                                checked={voucherModel.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Brand}
                                onCheckedChange={(name, newVal) => {
                                    if (newVal) {
                                        setVoucherModel({
                                            ...voucherModel,
                                            voucherProductScope: voucherModel.voucherProductScope | VOUCHER_PRODUCT_SCOPE.Brand,
                                            isApplyAll: false,
                                        });
                                    } else {
                                        setVoucherModel({
                                            ...voucherModel,
                                            voucherProductScope: voucherModel.voucherProductScope & ~VOUCHER_PRODUCT_SCOPE.Brand,
                                            isApplyAll: false,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className="apply-check-item">
                            <CheckBox
                                label="Cửa hàng"
                                checked={voucherModel.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Store}
                                onCheckedChange={(name, newVal) => {
                                    if (newVal) {
                                        setVoucherModel({
                                            ...voucherModel,
                                            voucherProductScope: voucherModel.voucherProductScope | VOUCHER_PRODUCT_SCOPE.Store,
                                            isApplyAll: false,
                                        });
                                    } else {
                                        setVoucherModel({
                                            ...voucherModel,
                                            voucherProductScope: voucherModel.voucherProductScope & ~VOUCHER_PRODUCT_SCOPE.Store,
                                            isApplyAll: false,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className="apply-check-item">
                            <CheckBox
                                label="Tự thiết kế"
                                checked={voucherModel.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Customize}
                                onCheckedChange={(name, newVal) => {
                                    if (newVal) {
                                        setVoucherModel({
                                            ...voucherModel,
                                            voucherProductScope: voucherModel.voucherProductScope | VOUCHER_PRODUCT_SCOPE.Customize,
                                            isApplyAll: false,
                                        });
                                    } else {
                                        setVoucherModel({
                                            ...voucherModel,
                                            voucherProductScope: voucherModel.voucherProductScope & ~VOUCHER_PRODUCT_SCOPE.Customize,
                                            isApplyAll: false,
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="product-select">
                        <div className="product-select-left">
                            <label htmlFor="">Loại sản phẩm</label>
                            <div className="left-content">
                                {(voucherModel.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Brand) > 0 && (
                                    <div
                                        className={`product-select-left-row cursor-pointer ${voucherScopeActive == VOUCHER_PRODUCT_SCOPE.Brand ? "active" : ""}`}
                                        onClick={() => {
                                            setVoucherScopeActive(VOUCHER_PRODUCT_SCOPE.Brand);
                                        }}
                                    >
                                        Thương hiệu
                                    </div>
                                )}
                                {(voucherModel.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Store) > 0 && (
                                    <div
                                        className={`product-select-left-row cursor-pointer ${voucherScopeActive == VOUCHER_PRODUCT_SCOPE.Store ? "active" : ""}`}
                                        onClick={() => {
                                            setVoucherScopeActive(VOUCHER_PRODUCT_SCOPE.Store);
                                        }}
                                    >
                                        Cửa hàng
                                    </div>
                                )}
                                {(voucherModel.voucherProductScope & VOUCHER_PRODUCT_SCOPE.Customize) > 0 && (
                                    <div
                                        className={`product-select-left-row cursor-pointer ${voucherScopeActive == VOUCHER_PRODUCT_SCOPE.Customize ? "active" : ""}`}
                                        onClick={() => {
                                            setVoucherScopeActive(VOUCHER_PRODUCT_SCOPE.Customize);
                                        }}
                                    >
                                        Tự thiết kế
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="product-select-right">
                            <div className="right-label">
                                <label htmlFor="">Danh sách sản phẩm</label>
                            </div>
                            <div className="right-content">
                                {/* create table with table tag */}
                                <div className="header-button">
                                    <div className="header-select">
                                        <TFUSelect placeholder="Kiểu in" />
                                    </div>
                                    <div className="header-select">
                                        <TFUSelect placeholder="Loại sản phẩm" />
                                    </div>
                                    <div className="header-select product">
                                        <ReactSelect
                                            options={listProductOption}
                                            formatOptionLabel={(product) => (
                                                <div className="custom-option">
                                                    <img src={product.thumbnail} alt={product.label} />
                                                    <span>{product.label}</span>
                                                </div>
                                            )}
                                            id="keyword"
                                            name="keyword"
                                            className="react-select-up"
                                            classNamePrefix="react-select"
                                            placeholder="Nhập tên, mã sản phẩm ..."
                                            onInputChange={(value) => setPaging({ ...paging, keyword: value })}
                                            value={paging.keyword}
                                            onChange={(selected) => {
                                                let productSelected = {
                                                    id: selected.id,
                                                    name: selected.name,
                                                    sellPrice: selected.sellPrice,
                                                    avatar: selected.productAvatar,
                                                    importPrice: selected.importPrice,
                                                    sellerSKU: selected.sellerSKU,
                                                    voucherProductScope: voucherScopeActive,
                                                };
                                                addVoucherProductHandler(productSelected);
                                            }}
                                            noOptionsMessage={() => (isFetching ? "Đang tải dữ liệu" : "Không có dữ liệu")}
                                        />
                                    </div>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width={"20%"}>Hình</th>
                                            <th width={"20%"}>Mã sản phẩm</th>
                                            <th width={"30%"}>Tên sản phẩm</th>
                                            <th width={"20%"}>Giá</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {voucherProducts.filter((x) => x.voucherProductScope == voucherScopeActive).length > 0 ? (
                                            voucherProducts
                                                .filter((x) => x.voucherProductScope == voucherScopeActive)
                                                .map((product, index) => (
                                                    <tr key={index}>
                                                        <td className="td-thumbnail">{product.productAvatar && <img src={product.productAvatar} alt="" />}</td>
                                                        <td>{product.sellerSKU}</td>
                                                        <td>{product.productName}</td>
                                                        <td>{Common.formatCurrency(product.sellPrice)}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-danger cursor-pointer"
                                                                onClick={(e) => {
                                                                    let updatedProducts = [...voucherProducts];
                                                                    let index = updatedProducts.findIndex((x) => x.productId == product.productId && x.voucherProductScope == product.voucherProductScope);
                                                                    updatedProducts.splice(index, 1);
                                                                    setVoucherProducts(updatedProducts);
                                                                }}
                                                            >
                                                                Xóa
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    Không có dữ liệu
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

    function switchVoucherInstruction(voucherType) {
        switch (voucherType) {
            case VOUCHER_TYPE.Normal:
                return "(Ví dụ: Giảm 100K cho đơn hàng từ 500K hoặc Giảm 20% cho đơn hàng từ 400K)";
            case VOUCHER_TYPE.Direct:
                return "(Ví dụ: Mua áo thun bất kì với giá 100K)";
            case VOUCHER_TYPE.FreeProduct:
                return "(Ví dụ: Tặng áo thun Ranus Classic khi mua bất cứ sản phẩm nào)";
            case VOUCHER_TYPE.Affiliate:
                return "(Ví dụ: Giảm 100K cho đơn hàng từ 500K hoặc Giảm 20% cho đơn hàng từ 400K)";
            default:
                break;
        }
    }

    return (
        <Wrapper>
            <section className="type">
                <div className="type-select">
                    <label htmlFor="voucherType">Loại voucher</label>
                    <div className="voucherType-select">
                        <TFUSelect
                            name="voucherType"
                            options={VoucherTypeOptions}
                            value={+voucherModel.voucherType}
                            placeholder="Chọn loại voucher"
                            required
                            onChanged={(value) => {
                                if (voucherId > 0) {
                                    let newModel = new VoucherModel();
                                    if (value == VOUCHER_TYPE.Affiliate) {
                                        setVoucherModel({
                                            ...newModel,
                                            voucherType: value,
                                            quantity: 0,
                                            validCollectTime: 0,
                                            maxUsage: 0,
                                            isHideCollect: true,
                                            isApplyVoucherInput: true,
                                        });
                                    } else {
                                        setVoucherModel({
                                            ...newModel,
                                            voucherType: value,
                                        });
                                    }
                                } else {
                                    setVoucherModel({
                                        ...voucherModel,
                                        voucherType: value,
                                    });
                                }
                                setTabId(Tab.VoucherDetail);
                            }}
                        />
                    </div>
                    <div className="sub-instruction">{switchVoucherInstruction(voucherModel.voucherType)}</div>
                </div>
            </section>
            <div className="detail-tab">
                <div className={`detail-tab__item ${tabId == Tab.VoucherDetail ? "active" : ""}`} onClick={() => setTabId(Tab.VoucherDetail)}>
                    Thông tin voucher
                </div>
                <div className={`detail-tab__item ${tabId == Tab.ProductApply ? "active" : ""}`} onClick={() => setTabId(Tab.ProductApply)}>
                    Sản phẩm áp dụng
                </div>
            </div>
            {renderBody()}
            <div className="bottom-save">
                <div className="bottom-button">
                    <button
                        className="btn btn-danger btn-sm m-r-5"
                        onClick={() => {
                            //close tab
                            history.back();
                        }}
                    >
                        Đóng
                    </button>
                    {tabId == Tab.VoucherDetail && (
                        <button
                            className="btn btn-custom btn-sm m-r-5"
                            onClick={() => {
                                setTabId(Tab.ProductApply);
                            }}
                        >
                            Tiếp theo
                        </button>
                    )}
                    {tabId == Tab.ProductApply && (
                        <button
                            className="btn btn-custom btn-sm m-r-5"
                            onClick={(e) => {
                                handleSave();
                            }}
                        >
                            Lưu
                        </button>
                    )}
                </div>
            </div>
        </Wrapper>
    );
}

export default VoucherOverallEditor;

const Wrapper = styled.div`
    margin: 20px 0;

    .bottom-save {
        margin: 20px 0;
    }

    .bottom-button {
        display: flex;
        justify-content: center;
    }

    .custom-option {
        display: flex;
        align-items: center;
        img {
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-right: 10px;
        }
        span {
            flex: 1;
        }
    }

    .td-thumbnail img {
        width: 100%;
        object-fit: contain;
        height: 100%;
    }

    //gift
    .right-label label {
        margin: 5px 0 !important;
    }

    section.product-gift {
        background: white;
        padding: 10px 15px;
        margin: 0 0 20px 0;
    }

    .product-gift label {
        margin: 5px 0 !important;
    }

    .product-gift thead {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
    }

    //product
    .header-button {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
        display: flex;
        padding: 5px 10px;
        border-bottom: 0;
    }

    .right-content .table thead {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
        border-top: 0;
    }

    .right-content-table-body__item-img > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    section.product-apply {
        background: white;
        padding: 10px 15px;
    }

    .product-select-left-row.active {
        background: #ceeae4;
    }

    .apply-check {
        display: flex;
        margin: 5px 0;
    }

    .apply-check-item {
        width: calc(100% / 3);
    }

    .apply-all {
        margin: 5px 0;
    }

    .product-select {
        display: flex;
        margin: 5px 0;
    }

    .product-select-left {
        width: 30%;
    }

    .product-select-right {
        flex: 1;
    }

    .product-select-left-row {
        width: 160px;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 6px 0;
        padding: 12px 16px;
    }

    .right-content-header {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
        padding: 6px 12px;
    }

    .header-select {
        width: 20%;
        margin: 0 10px;
    }

    .header-select.product {
        flex: 1 !important;
    }

    .right-content-table-header {
        width: 100%;
        display: flex;
        margin: 10px 0px;
    }

    .right-content-table-header__item {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .body-item {
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
    }

    .right-content-table-body__item {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    //scope
    section.scope {
        margin: 18px 0;
        background: white;
        padding: 10px 15px;
    }

    .scope-row .row-checkbox {
        margin: 5px 0 0 5px;
    }

    .scope-row {
        display: flex;
        flex-wrap: wrap;
    }

    .scope-row label {
        flex-basis: 100%;
    }

    .scope-column {
        margin: 0 20px 0px 0;
        width: 30%;
    }

    .scope-check-voucher {
        margin: 10px 0 0 5px;
    }

    //payment
    section.payment {
        margin: 18px 0;
        background: white;
        padding: 10px 15px;
    }

    .payment-row {
        display: flex;
        flex-wrap: wrap;
    }

    .row-checkbox-list {
        width: 100%;
        display: flex;
        margin: 10px 0;
    }

    .row-checkbox {
        margin: 0 20px;
    }

    .row-checkbox:first-child {
        margin-left: 5px;
    }

    .location-row {
        flex-basis: 30%;
        width: 30%;
        margin: 10px 0;
    }

    label.payment-row__label {
        flex-basis: 100%;
    }

    //scheme
    section.scheme {
        background: white;
        padding: 10px 15px;
        margin: 18px 0;
    }

    .scheme-row {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    .scheme-row__input {
        width: 190px;
    }

    .scheme-column {
        width: 20%;
    }

    label.scheme-row__label {
        margin: 0 20px 0px 0;
    }

    .scheme-row__input > input.form-control {
        margin-top: 5px;
    }

    //detail
    section.detail {
        background: white;
        margin: 0 0 18px 0;
    }

    .detail-content__item__image__upload__image {
        width: 150px;
        height: 150px;
    }

    .detail-content__item__image__upload__image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .detail-tab {
        display: flex;
        margin: 18px 0 0 0;
    }

    .detail-tab__item.active {
        border-bottom: 3px solid #64c5b1;
        background: #ffffff;
    }

    .detail-tab__item {
        width: 50%;
        color: #666f7b;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 16px 20px 16px 20px;
        cursor: pointer;
    }

    .detail-content {
        padding: 10px 15px;
    }

    .detail-content__item {
        display: flex;
    }

    .detail-content__item__image {
        width: calc(100% / 3);
    }

    .detail-content__item__info {
        width: calc(100% / 3);
        padding: 0 35px;
    }

    .detail-content__item__image__upload {
        display: flex;
        flex-wrap: wrap;
    }

    .detail-content__item__image__upload__button {
        width: 100%;
        display: flex;
        margin-top: 19px;
    }

    .input-code {
        width: 75px;
    }

    .input-color {
        width: 75px;
    }

    .input-code input[type="text"] {
        width: 100%;
    }

    .input-color input[type="color"] {
        width: 100%;
        height: 100%;
    }

    input[type="date"].form-control:first-child {
        margin-left: 0px;
    }

    input.form-control {
        border-radius: 0 !important;
        border: 1px solid #d9d9d9;
        padding: 5px 12px;
    }

    label {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        margin-bottom: 0px !important;
    }

    .detail-content__item__info__row__input {
        display: flex;
        margin: 5px 0;
    }

    input[type="date"].form-control {
        margin: 0 0 0 10px;
    }

    .detail-description {
    }

    .detail-description > textarea {
        width: 100%;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 30px 0;
    }

    //voucher type
    section.type {
        padding: 0 15px;
    }
    .type-select {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .type-select label {
        flex-basis: 100%;
        width: 100%;
    }

    .voucherType-select {
        width: 20%;
    }

    .sub-instruction {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 20px;
    }
`;
