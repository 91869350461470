import React, { useState, useEffect, useRef, forwardRef } from "react";
import Textbox from "../../based/inputs/Textbox";

function ScannedProductInfo(props, ref) {
  const [currentValue, setCurrentValue] = useState("");
  const testRef = useRef(null);
  useEffect(() => {
    if (props.isClearInput) setCurrentValue("");
    props.setClearInput();
  }, [props.isClearInput]);

  function handleChanged(name, value) {
    setCurrentValue(value);
    props.onChanged(name, value);
  }
  return (
    <div className="col-md-12">
      <div className="form-group">
        <label className="col-form-label">Barcode Scan</label>
        <Textbox
          ref={testRef}
          name="barcode"
          className="form-control"
          value={currentValue ? currentValue : ""}
          onChanged={(name, value) => handleChanged(name, value)}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
}

export default forwardRef(ScannedProductInfo);
