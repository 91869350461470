import { VOUCHER_TYPE } from "../../based/Constants";

export default class VoucherLightModel {
    constructor() {
        this.id = 0;
        this.code = "";
        this.name = "";
        this.description = "";
        this.fromDate = new Date();
        this.toDate = new Date();
        this.displayStartDate = new Date();
        this.maxUsage = 0;
        this.quantity = 0;
        this.voucherType = VOUCHER_TYPE.Brand;
    }
}
