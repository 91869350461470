import React, { useState } from "react";
import PropTypes from "prop-types";
import CommonModal from "../based/Modal";
import Common from "../based/Common";
import Request from "../based/Request";
import { Notify } from "../based/Notify";
import { NOTIFY, StockFolderType } from "../based/Constants";
import styled from "styled-components";
import StockFolderServices from "../based/services/StockFolderServices";
import { useEffect } from "react";

class StockFolderModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.parentFolderId = 0;
        this.thumbnail = "";
        this.subThumbnail = "";
        this.folderType = StockFolderType.StockPhoto;
    }
}

const ThumbnailTypeImage = 59;

function AddEditModal({ isShowModal, onClose, onSave, currentParentId, currentId }) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [folder, setFolder] = useState(new StockFolderModel());

    useEffect(() => {
        setFolder({ ...folder, parentFolderId: currentParentId });
    }, [currentParentId]);

    useEffect(() => {
        if (currentId > 0) _getData(currentId);
    }, [currentId]);

    const _handleSave = async () => {
        let [err, data] = await StockFolderServices.CreateUpdateStockFolder(folder);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const _getData = async (id) => {
        let [err, data] = await StockFolderServices.GetStockFolderById(id);
        if (!err && data) setFolder(data);
        else setFolder(new StockFolderModel());
    };

    const _handleThumbnail = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            Request.UploadImage(file, ThumbnailTypeImage)
                .then((res) => {
                    if (res && res.data) {
                        setFolder({ ...folder, thumbnail: res.data.thumbnailUrl });
                    }
                })
                .catch((err) => {
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi upload");
                });
        };

        reader.readAsDataURL(file);
    };

    const _handleSubThumbnail = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            Request.UploadImage(file, ThumbnailTypeImage)
                .then((res) => {
                    if (res && res.data) {
                        setFolder({ ...folder, subThumbnail: res.data.thumbnailUrl });
                    }
                })
                .catch((err) => {
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi upload");
                });
        };

        reader.readAsDataURL(file);
    };

    return (
        <CommonModal
            show={isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={folder.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                </React.Fragment>
            }
            onClose={() => onClose()}
        >
            <Wrapper>
                <form>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="name" className="d-block my-2 required">
                                {currentId > 0 ? "Tên folder" : currentParentId == 0 ? "Tên folder cha" : "Tên folder con"}
                            </label>
                            <input type="text" value={folder.name} id="name" required className="form-control" onChange={(e) => setFolder({ ...folder, name: e.target.value })} />
                        </div>
                    </div>
                    {currentParentId == 0 && (
                        <div className="row">
                            <div className="form-group">
                                <label htmlFor="thumbnail" className="d-block my-2 required">
                                    Icon
                                </label>
                                <input type="file" id="thumbnail" className="my-2" accept="image/*" multiple={false} onChange={(e) => _handleThumbnail(e)} />
                                <div className="preview-thumbnail my-2">{folder.thumbnail && <img src={folder.thumbnail} />}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="subThumbnail" className="d-block my-2 required">
                                    Icon Sub
                                </label>
                                <input type="file" id="subThumbnail" className="my-2" accept="image/*" multiple={false} onChange={(e) => _handleSubThumbnail(e)} />
                                <div className="preview-thumbnail my-2">{folder.subThumbnail && <img src={folder.subThumbnail} />}</div>
                            </div>
                        </div>
                    )}
                </form>
            </Wrapper>
        </CommonModal>
    );
}

AddEditModal.propTypes = {};

export default AddEditModal;

const Wrapper = styled.div`
    .preview-thumbnail {
        height: 100px;
        width: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #ffffff;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;
