import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../../based/inputs/Textbox";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import FontServices from "../../based/services/FontServices";
import useFormValidate from "../../hooks/useFormValidate";
import Common from "../../based/Common";
import TextNumber from "../../based/inputs/TextNumber";


const FontModel = {
    id:0,
    name:""
}
const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState(null);
    const [font, setFont] = useState(FontModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const [check, setCheck] = useState(true);

    useEffect(() => {
        if (props.id > 0) getFont(props.id);
        else setFont({ ...FontModel });
        return () => setFont({ ...FontModel });
    }, [props.id]);

    const getFont = async (id) => {
        setIsBusy(true);
        let [err, data] = await FontServices.GetFontById(id);
        if (!err && data) {
            setIsBusy(false);
            setFont({ ...data });
        } else {
            setIsBusy(false);
            setFont(FontModel);
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await FontServices.CreateUpdateFont({ ...font });
            if (!err && data == 1) {
                setIsBusy(false);
                setFont(FontModel);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Ngành hàng đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Tên hiển thị:
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => setFont({ ...font, name: value })} value={font.name} />
            </div>
        </CommonModal>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
