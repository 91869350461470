import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import InstructServices from "../based/services/InstructServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import CheckBox from "../based/inputs/CheckBox";


const initStep = {
    id: 0,
    isDelete: 0,
    title: '',
    xPath:"",
    isAction: false,
    showIfExistElement: '',
    instructLinkId: 0,
    index: 0
}

const ModalAddOrUpdateStep = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [step, setStep] = useState({...initStep});
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if (props.id > 0) getStep(props.id);
        else setStep({...initStep,instructLinkId: props.idLink});
    }, [props.id]);
    

    const getStep = async (id) => {
        setIsBusy(true);
        let [err, data] = await InstructServices.GetStepById(id);
        if (!err && data) {
            setIsBusy(false);
            setStep(data);
        } else {
            setIsBusy(false);
            setStep({});
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await InstructServices.CreateUpdateStep({...step});
            if (!err && data) {
                setIsBusy(false);
                setStep({...initStep});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
               
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                
            }
            props.onSaveContinue();
        }
        
    };


   
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                {/* <label htmlFor='step-index' className='required'>
                Bước:
                </label>
                <TextNumber id='step-index' name='step-index' required ref={addRef} className='form-control' onChanged={(name, value) => {
                    setStep({ ...step, stepIndex: value })
                }} value={step.stepIndex} /> */}
                <label htmlFor='title' className='required'>
                Tên bước:
                </label>
                <Textbox id='title' name='title' required ref={addRef} className='form-control' onChanged={(name, value) => {
                    setStep({ ...step, title: value })
                }} value={step.title} />
                <label htmlFor='xPath' className='required'>
                Selector (nơi xuất hiện hướng dẫn):
                </label>
                <Textbox id='xPath' name='xPath' required ref={addRef} className='form-control' onChanged={(name, value) => {
                    setStep({ ...step, xPath: value })
                }} value={step.xPath} />
                <label htmlFor='isAction' className='required'>
                Hành động click:
                </label>
                <CheckBox id="isAction" name="isAction" checked={step.isAction} label=" " onCheckedChange={(name, value) => {
                    setStep({ ...step, isAction: value })
                }} />
                <label htmlFor='showIfExistElement' className=''>
                Selector show (show step nếu element này hiển thị ):
                </label>
                <Textbox id='showIfExistElement' name='showIfExistElement'  ref={addRef} className='form-control' onChanged={(name, value) => {
                    setStep({ ...step, showIfExistElement: value })
                }} value={step.showIfExistElement} />
                
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdateStep;

ModalAddOrUpdateStep.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
