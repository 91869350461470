import React, { useContext, useEffect, useState } from "react";
import TextNumber from "../../based/inputs/TextNumber";
import PrintServices from "../../based/services/PrintServices";
import { ProductContext } from "../../context/ProductContext";
import { PrintStepConfigModel } from "../../models/PrintStepConfigModel";
import PrintStepModal from "../../print/PrintStepModal";

export default function SectionStepConfig(props) {
    const [isShowBox, setIsShowBox] = useState(true);
    const { mainProduct, setMainProduct, addRef } = useContext(ProductContext);
    const [printStepConfig, setPrintStepConfig] = useState(new PrintStepConfigModel());
    const [isShowStepConfig, setIsShowStepConfig] = useState(false);

    useEffect(() => {
        let categoryId = mainProduct.categoryId;
        if (+categoryId > 0) getConfig(categoryId);
    }, [mainProduct.categoryId]);

    const getConfig = async (id) => {
        let [err, data] = await PrintServices.ReadFirstStepConfig(id);
        if (!err) setPrintStepConfig(data);
        else setPrintStepConfig(new PrintStepConfigModel());
    };

    return (
        <div className="section-step-config">
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>In ấn - Chi phí bước nhảy</b>
                </h4>
                <div className="card-widgets cursor-pointer">
                    <span onClick={() => setIsShowBox(!isShowBox)}>
                        <i className={isShowBox ? "fa fa-angle-double-up" : "fa fa-angle-double-down"}></i>
                    </span>
                </div>
                <p className="sub-header">Thiết lập bước nhảy của chi phí in ấn theo cấp độ</p>
                <a className="btn btn-custom waves-effect w-xs waves-light btn-sm mb-2 mr-2" onClick={() => setIsShowStepConfig(true)}>
                    <i className="fa fa-plus" /> Chỉnh sửa bước nhảy
                </a>
                {isShowBox ? (
                    <div className="row">
                        <div className="col-md-12 row">
                            <div className="col-md-3">
                                <label htmlFor="costLevel1" className="col-form-label">
                                    Chi phí in ấn level 1
                                </label>
                                <TextNumber id="costLevel1" readOnly suffix="đồng" name="costLevel1" value={printStepConfig.costLevel1} onChanged={(name, value) => setPrintStepConfig({ ...printStepConfig, costLevel1: value })} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="costLevel2" className="col-form-label">
                                    Chi phí in ấn level 2
                                </label>
                                <TextNumber id="costLevel2" readOnly suffix="đồng" name="costLevel2" value={printStepConfig.costLevel2} onChanged={(name, value) => setPrintStepConfig({ ...printStepConfig, costLevel2: value })} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="costLevel3" className="col-form-label">
                                    Chi phí in ấn level 3
                                </label>
                                <TextNumber id="costLevel3" readOnly suffix="đồng" name="costLevel3" value={printStepConfig.costLevel3} onChanged={(name, value) => setPrintStepConfig({ ...printStepConfig, costLevel3: value })} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="costLevel4" className="col-form-label">
                                    Chi phí in ấn level 4
                                </label>
                                <TextNumber id="costLevel4" readOnly suffix="đồng" name="costLevel4" value={printStepConfig.costLevel4} onChanged={(name, value) => setPrintStepConfig({ ...printStepConfig, costLevel4: value })} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="costLevel5" className="col-form-label">
                                    Chi phí in ấn level 5
                                </label>
                                <TextNumber id="costLevel5" readOnly suffix="đồng" name="costLevel5" value={printStepConfig.costLevel5} onChanged={(name, value) => setPrintStepConfig({ ...printStepConfig, costLevel5: value })} />
                            </div>
                        </div>
                    </div>
                ) : null}
                {isShowStepConfig && (
                    <PrintStepModal
                        isShowModal={isShowStepConfig}
                        onClose={() => {
                            setIsShowStepConfig(false);
                        }}
                        onSaveSucceed={() => {
                            setIsShowStepConfig(false);
                            getConfig(mainProduct.categoryId);
                        }}
                        categoryId={mainProduct.categoryId}
                    />
                )}
            </div>
        </div>
    );
}
