import React, { useEffect, useState } from "react";
import { CONSTANTS, NOTIFY, PLATFORM, STATUS_ACTIVE } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import Request from "../../based/Request";
import PlatformServices from "../../based/services/PlatformServices";
const urlRegisterShop = process.env.NODE_ENV === "production" ? "https://shopee.vn/buyer/login/signup" : "https://banhang.test-stable.shopee.vn/account/signin"; //'https://uat.shopee.vn/buyer/signup';
import { useHistory, useLocation } from "react-router-dom";
import CheckBox from "../../based/inputs/CheckBox";

export default function ShopeeConfig(props) {
    const [isBusy, setBusy] = useState(true);
    const [message, setMessage] = useState("Đang tải dữ liệu...");
    const [stores, setStores] = useState([]);
    const [storeSelected, setStorSelected] = useState(null);
    const history = useHistory();

    useEffect(() => {
        setBusy(false);
        connnect();
    }, []);

    async function connnect() {
        let fullParamUrl = window.location.search;
        if (fullParamUrl && fullParamUrl.indexOf("shop_id") > -1) {
            setBusy(true);
            setMessage("Đang tải dữ liệu..");
            const [err, data] = await PlatformServices.ConnectToPlatform(fullParamUrl);
            if (!err) {
                setBusy(false);
                setMessage("");
                props.history.push("/connect-platform-config/shopee");
            } else {
                setBusy(false);
                setMessage("");
            }
            _getStores();
        } else {
            _getStores();
        }
    }

    async function _getStores() {
        setBusy(true);
        setMessage("Đang tải dữ liệu..");
        const [err, data] = await PlatformServices.PlatformStoresUser([PLATFORM.Shopee], STATUS_ACTIVE.All);
        if (!err) {
            setStores(data);
            setBusy(false);
            setMessage("");
        } else {
            setBusy(false);
            setMessage("");
        }
    }

    async function _handleRemoveStore(id) {
        ConfirmDialog("Xác nhận", "Bạn chắc chắn muốn xóa kết nối đến gian hàng đã chọn? ", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                setMessage("Đang xóa dữ liệu..");
                const [err, data] = await PlatformServices.DeleteConnectStore(id);
                if (!err) {
                    if (data) {
                        resolve({ title: "Thành công", msg: "Xóa kết nối gian hàng thành công" });
                        let storesUpdate = [...stores];
                        let idx = -1;
                        storesUpdate.map((item, i) => {
                            if (item.id == id) idx = i;
                        });
                        if (idx > -1) storesUpdate.splice(idx, 1);
                        setStores(storesUpdate);
                    } else {
                        reject(err);
                    }
                    setBusy(false);
                    setMessage("");
                } else {
                    Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                    setBusy(false);
                    setMessage("");
                }
            });
        });
    }

    async function _handleConnect() {
        setBusy(true);
        setMessage("Đang kết nối...");
        const [err, data] = await PlatformServices.ConnectToPlatform(`?platform=${PLATFORM.Shopee}&returnUrl=/connect-platform-config/shopee`);
        if (!err) {
            window.location.href = data;
            setBusy(false);
            setMessage("");
        } else {
            setBusy(false);
            setMessage("");
        }
    }

    async function _handleRefreshToken(store) {
        ConfirmDialog("Xác nhận", "Bạn chắc chắn muốn kết nối lại gian hàng đã chọn? ", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                setMessage("Đang kết nối gian hàng..");
                const [err, data] = await PlatformServices.RefreshAccessToken({ id: store.id, shopId: store.shopId, platform: PLATFORM.Shopee });
                if (!err) {
                    resolve({ title: "Thành công", msg: "Kết nối gian hàng thành công" });
                    _getStores();
                    setBusy(false);
                    setMessage("");
                } else {
                    reject({ title: "Có lỗi", msg: err.data ? err.data : CONSTANTS.MSG_ERROR });
                    setBusy(false);
                    setMessage("");
                }
            });
        });
    }

    async function _syncCat(model) {
        const [err, data] = await PlatformServices.SyncCategories(model);
        if (!err) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Đồng bộ danh mục thành công");
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Đồng bộ danh mục thất bại");
        }
    }

    async function _handleActiveStore(platform, shop, isActive) {
        const [err, data] = await PlatformServices.UpdateSetIsActiveShop(platform, shop, isActive);
        if (!err) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật trạng thái gian hàng thành công");
            _getStores();
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Cập nhật trạng thái gian hàng thất bại");
        }
    }

    function _renderTable() {
        return stores && stores.length > 0 ? (
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h4 className="header-title">
                        <b>Gian hàng Shopee của bạn</b>
                    </h4>
                    <table className="tablesaw table mb-0 tablesaw-stack">
                        <thead>
                            <tr>
                                <th>Mã gian hàng</th>
                                <th>Gian hàng</th>
                                <th>Trạng thái</th>
                                <th>Ngày kết nối</th>
                                <th>Ngày hết hạn</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stores.map((store, k) => {
                                return (
                                    <tr key={k} className={storeSelected && storeSelected.shopId == store.shopId ? "tr_selected" : ""}>
                                        <td>
                                            <strong className="store-name" onClick={() => setStorSelected(store)}>
                                                {store.shopId}
                                            </strong>
                                        </td>
                                        <td>
                                            <strong className="store-name" onClick={() => setStorSelected(store)}>
                                                {store.shopName}
                                            </strong>
                                        </td>
                                        <td>{!store.isExpired ? <span className="label label-table label-success"> Đang hoạt động </span> : <span className="label label-table label-danger">Hết hạn</span>}</td>
                                        <td>
                                            <span>{store.connectDate}</span>
                                        </td>
                                        <td>{store.connectDateExpires ? <span>{store.connectDateExpires}</span> : null}</td>
                                        <td>
                                            <CheckBox
                                                name={`isActive_${store.id}`}
                                                checked={store.isActive}
                                                onCheckedChange={(name, value) => {
                                                    _handleActiveStore(PLATFORM.Shopee, store.shopId, value);
                                                }}
                                                label="Kích hoạt"
                                            />
                                        </td>
                                        <td>
                                            {store.isExpired ? (
                                                <button className="btn btn-custom btn-sm m-1" onClick={() => _handleRefreshToken(store)}>
                                                    <i className="fa fa-refresh" /> Kết nối lại
                                                </button>
                                            ) : null}
                                            <button
                                                className="btn btn-custom btn-sm m-1"
                                                onClick={(e) => {
                                                    history.push(`/platform-shop-management/${store.shopId}/${PLATFORM.Shopee}`);
                                                }}
                                            >
                                                Chi tiết
                                            </button>
                                            <button className="btn btn-danger btn-sm m-1" onClick={() => _handleRemoveStore(store.id)}>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                            <button
                                                className="btn btn-custom btn-sm m-1"
                                                onClick={(e) => {
                                                    _syncCat({ platform: PLATFORM.Shopee, shopId: store.shopId });
                                                }}
                                            >
                                                Đồng bộ ngành hàng
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : null;
    }
    return (
        <React.Fragment>
            <Loading show={isBusy} msg={message} />
            <div className="row ml-2">
                <div className="col-sm-12 form-row card-box p-3">
                    <div className="col-12 col-lg-8">
                        <div className="lazada-channel--text">
                            <p className="mb-3 font-size-24px header-title">
                                Kết nối kênh bán hàng Shopee
                                {stores.length > 0 ? <span> ({stores.length})</span> : null}
                            </p>
                            <p>
                                Kết nối với hệ thống bán hàng của Shopee, giúp đồng bộ tồn kho, giá sản phẩm, đơn hàng... giữa hai hệ thống <strong>RANUS-SHOPEE</strong>.
                            </p>
                            {stores && stores.length > 0 ? (
                                <p className="sub-header text-success">
                                    {" "}
                                    <b>Hãy bắt đầu sử dụng các kênh bán hàng của Shopee để bán được nhiều hàng và có nhiều doanh thu hơn.</b>
                                </p>
                            ) : (
                                <p className="sub-header text-danger">Kênh bán hàng Shopee của bạn chưa được kết nối. Vui lòng kết nối để bắt đầu sử dụng.</p>
                            )}
                            <div className="text-left">
                                <button className="btn btn-custom mt-3 mb-3" onClick={() => _handleConnect()}>
                                    <span>
                                        <i className="fa fa-share-alt"></i> Kết nối kênh bán hàng
                                    </span>
                                </button>
                                <p>
                                    Bạn chưa có gian hàng?{" "}
                                    <a href={urlRegisterShop} target="_blank">
                                        Đăng ký tại đây
                                    </a>
                                </p>
                            </div>
                        </div>
                        <hr />
                        {_renderTable()}
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="salechannel--image">
                            <img src={Request.CdnURL() + "/images_system/sale-chanel.jpg"} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
