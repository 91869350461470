import React, { useState, useEffect } from "react";
import PubSub from "pubsub-js";
import { Button } from "react-bootstrap";
import Breadcrumbs from "../based/Breadcrumbs";
import { MAINMENU, CONSTANTS, NOTIFY } from "../based/Constants";
import { Layout } from "../layout/Layout";
import Loading from "../based/Loading";
import TextNumber from "../based/inputs/TextNumber";
import Language from "../based/Language";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import PrintServices from "../based/services/PrintServices";

const PrintSetting = () => {
    const [loading, setLoading] = useState({ isLoading: false, msgLoading: "Đang tải dữ liệu..." });
    const [printSetting, setPrintSetting] = useState({});
    const { addRef, isValid } = useFormValidate();

    useEffect(() => {
        getData();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.WebSetting });
    }, []);

    const getData = async () => {
        setLoading({ isLoading: true, msgLoading: "Đang tải dữ liệu..." });
        const [err, data] = await PrintServices.ReadFirst();
        if (!err && data) {
            setLoading({ isLoading: false });
            setPrintSetting(data);
        } else {
            setLoading({ isLoading: false });
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi: " + err.message);
        }
    };

    const handleInputChange = (name, value) => {
        printSetting[name] = value;
        setPrintSetting({ ...printSetting });
    };

    const handleSave = async () => {
        if (isValid()) {
            setLoading({ isLoading: true, msgLoading: "Đang lưu dữ liệu..." });
            const [err, data] = await PrintServices.CreateUpdate(printSetting);
            if (!err && data) {
                setLoading({ isLoading: false });
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công.");
            } else {
                setLoading({ isLoading: false });
                Notify(NOTIFY.WARNING, "Có lỗi", "Lưu thất bại.");
            }
        }
    };

    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Cấu hình chi phí in" />
            <Loading show={loading.isLoading} msg={loading.msgLoading} />
            <div className="row">
                <div className="col-sm-6 pr-1">
                    <div className="card-box p-3">
                        <h6>In DTG</h6>
                        <div className="form-group">
                            <label htmlFor="dtgColorCost">Chi phí mực in (Màu):</label>
                            <TextNumber ref={addRef} id="dtgColorCost" suffix="đồng/cc" name="dtgColorCost" className="form-control" value={printSetting.dtgColorCost} onChanged={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dtgWhiteCost">Chi phí mực in (Trắng):</label>
                            <TextNumber ref={addRef} id="dtgWhiteCost" suffix="đồng/cc" name="dtgWhiteCost" className="form-control" value={printSetting.dtgWhiteCost} onChanged={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dtgCoverColorCost">Chi phí nước phủ áo màu (Pretreatment):</label>
                            <TextNumber ref={addRef} id="dtgCoverColorCost" suffix="đồng/1ml" name="dtgCoverColorCost" className="form-control" value={printSetting.dtgCoverColorCost} onChanged={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dtgCoverWhiteCost">Chi phí nước phủ áo trắng (Pretreatment):</label>
                            <TextNumber ref={addRef} id="dtgCoverWhiteCost" suffix="đồng/1ml" name="dtgCoverWhiteCost" className="form-control" value={printSetting.dtgCoverWhiteCost} onChanged={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dtgCoverVolumeRate">Tỉ lệ mực phun (VD: phun 0.279ml/1cm) :</label>
                            <TextNumber ref={addRef} id="dtgCoverVolumeRate" suffix="ml/1cm" name="dtgCoverVolumeRate" className="form-control" value={printSetting.dtgCoverVolumeRate} onChanged={handleInputChange}  decimal/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="dtgHumanCost">Chi phí nhân công:</label>
                            <TextNumber ref={addRef} id="dtgHumanCost" suffix="đồng/thiết kế" name="dtgHumanCost" className="form-control" value={printSetting.dtgHumanCost} onChanged={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dtgMachineCost">Chi phí khấu hao máy móc:</label>
                            <TextNumber ref={addRef} id="dtgMachineCost" suffix="đồng/thiết kế" name="dtgMachineCost" className="form-control" value={printSetting.dtgMachineCost} onChanged={handleInputChange} />
                        </div>
                        <h6>In PET</h6>
                        <div className="form-group">
                            <label htmlFor="petHumanCost">Chi phí nhân công:</label>
                            <TextNumber ref={addRef} id="petHumanCost" name="petHumanCost" className="form-control" value={printSetting.petHumanCost} onChanged={handleInputChange} suffix="đồng/thiết kế" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="petMaterialCost">Chi phí in (VD: 0.26đ/mm<sup>2</sup>):</label>
                            <TextNumber ref={addRef} id="petMaterialCost" name="petMaterialCost" className="form-control" value={printSetting.petMaterialCost} onChanged={handleInputChange} decimal suffix="đồng/mm2" />
                        </div>
                        <div className="footer-form-question">
                            <Button variant="primary" onClick={handleSave}>
                                <i className="fa fa-save m-r-5" />
                                {Language.getValue("common.btnSave")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PrintSetting;
