import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CARD_COLOR, NOTIFY } from './Constants';
import { Box, BtnRounded, ButtonBlackRounded, ButtonColor } from './content/ct.elm';
import { Notify } from './Notify';
import BaseModal from './refactor/BaseModal';
import StepNavigation from './StepNavigation';
const initSteps = [{
    value: 1,
    text: "Tạo Thương hiệu",
    isActive: false,
    isPass: false,
    content: {
        header: 'Tạo Thương hiệu',
        videoEmbed: 'https://www.youtube.com/embed/ECojLfGChE8',
        videoThumbnail: '/images/default-step.png',
        actionPage: { link: '/brand', label: 'Quản lý thương hiệu' },
        content: [
            <p><span>Lưu ý:</span> Khi upload logo cần: </p>,
            <p>Chọn mục Định dạng file là * <span>.png</span>, </p>,
            <p>Dung lượng tối đa: <span>5 MB</span>, </p>,
            <p>Kích thước tối đa: <span>5x5 cm</span>, </p>,
            <p>DPI/PPI: <span>300</span>. </p>,
            <br />,
            <p>Upload chứng minh quyền sử dụng cần: </p>,
            <p>Định dạng file là *<span>.pdf, .jpg, .jpeg, .png</span>, </p>,
            <p>Dung lượng tối đa: <span>3 MB</span>, </p>,
            <p>Số lượng tập tin tối đa: <span>5</span>. </p>,
        ]
    }
}, {
    value: 2,
    text: "Kiểm duyệt thương hiệu",
    isActive: false,
    isPass: false,
    content: {
        header: 'Xét duyệt thương hiệu',
        videoEmbed: 'https://www.youtube.com/embed/Kqsd6Wk2JRk',
        videoThumbnail: '/images/default-step.png',
        content: [
            <p>Ranus sẽ tiến hành kiểm duyệt thương hiệu của Creator trước khi tiến hành tạo sản phẩm, thương hiệu sau khi duyệt sẽ có trạng thái đã duyệt. </p>,
        ]
    }
}, {
    value: 3,
    text: "Tạo sản phẩm",
    isActive: false,
    isPass: false,
    content: {
        header: 'Tạo sản phẩm – Shopping and Detail',
        videoEmbed: 'https://www.youtube.com/embed/NPkBtHGXuqI',
        videoThumbnail: '/images/default-step.png',
        actionPage: { link: '/artworks', label: 'Quản lý thiết kế' },
        content: [
            <p>Creator chọn <span>QUẢN LÍ THIẾT KẾ</span> để tạo sản phẩm, chọn <span>Tải Thiết Kế Lên</span> vào giao diện <span>Đăng Thiết Kế</span> tiến hành Quy trình gồm 4 bước <span>Shopping – Detail – Upload Artwork – Artwork Info</span>.</p>,
            <p><span>Lưu ý:</span> Số lượng thiết kế được phép tạo cho Creator mới là 10 thiết kế không quá 25 MB/ 5 thiết kế không quá 50 MB / 2 thiết kế không quá 100 MB.</p>,
            <p>Quy trình 4 bước thực hiện như sau:</p>,
            <ul>
                <li><span>Bước  1  Shopping:</span> Nơi Creator chọn áo phôi cho sản phẩm
                    <p>Creator chọn <span>COMBO</span> được Ranus tạo sẵn phù hợp với thiết kế để làm áo phôi, mỗi <span>COMBO</span> có thông tin đầy đủ về <span>Tên sản phẩm phôi, size (kích thước), color (màu sắc).</span></p>
                </li>
                <li><span>Bước 2 Detail:</span> Lựa chọn <span>vị trí in thiết kế (mặt trước/sau)</span>.</li>
                <li><span>Bước 3 Upload Artwork:</span> Chọn <span>TẢI THIẾT KẾ LÊN</span> để đăng thiết kế, sau đó chọn vị trí của thiết kế trên áo (có thể chọn <span>Preview Mockup</span> để xem vị trí thực tế của thiết kế trên áo.)</li>
                <p><span>Lưu ý:</span> Định dạng file bắt buộc là *<span>.png</span>, dung lượng tối đa: <span>100 mb</span>, kích thước bắt buộc: <span>40x53 cm</span>, DPI/PPI: <span>300 600</span>.</p>
                <p>Khoảng cách được tính như sau: Vị trí khung thiết kế cách cổ áo Min (ít nhất): <span>35 mm</span> và Max (cao nhất): <span>200 mm</span>. </p>
                <li><span>Bước 4 Detail:</span> Creator điền các thông tin bắt buộc (*) theo yêu cầu của Ranus Và số <span>Tiền bản quyền</span> mong muốn.</li>
                <p><span>Lưu ý:</span> Càng nhiều thông tin xác thực, tỉ lệ kiểm duyệt thành công càng cao.</p>
                <p><span>Tiền bản quyền thực lĩnh</span> là số tiền mà Creator nhận được với mỗi sản phẩm được Ranus bán ra. Được tính bằng cách lấy <span>Tiền bản quyền</span> trừ mức <span>Charge</span> (phí hoạt động) tương ứng.</p>
                <p><span>Ví dụ:</span><br/> Creator chọn mức tiền bản quyền là 150,000 đồng. <br/> 
                Phí hoạt động sẽ được tính như sau: <br/> 
                (30,0000)+(40,000*15%)+(40,000*18%)+(40,000*21%)=21,600  đồng <br/> 
                Tiền bản quyền creator thực lĩnh sẽ là: <br/> 
                150,000 – 21,600= 128,400 đồng</p>
              
            </ul>
        ]
    }
}, {
    value: 4,
    text: "Kiểm duyệt sản phẩm",
    isActive: false,
    isPass: false
    , content: {
        header: 'Tạo sản phẩm – Upload Artwork',
        videoEmbed: 'https://www.youtube.com/embed/nTk1EePPWcI',
        videoThumbnail: '/images/default-step.png',
        content: [
            <p>Sau khi điền hết toàn bộ thông tin bắt buộc mà Ranus yêu cầu, Creator chọn <span>HOÀN TẤT</span> và chờ Ranus kiểm duyệt. Thông tin sản phẩm được duyệt thành công hay không được hiển thị ở ô <span>TRẠNG THÁI</span> mục <span>QUẢN LÍ THIẾT KẾ</span>.</p>
        ]
    }
},]
const SupportControl = (props) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [stepsArray, setStepsArray] = useState(initSteps)
    const [isModal, setIsModal] = useState(false)
    const history = useHistory()
    const updateCurrentStep = (step) => {
        setCurrentStep(step)
    }
    const renderContent = (tab) => {
        let renderStep = stepsArray.filter((item) => item.value === tab)
        renderStep = { ...renderStep[0] }
        if (renderStep) return (<React.Fragment>
            <div className='box__content' id='content-support'>
                <ContentLeft>
                    <VideoContent>
                        <div className='image-thumbnail'>
                            <img src={renderStep.content.videoEmbed ? renderStep.content.videoThumbnail || '/images/default-step.png' : '/images/default-step.png'} alt="default image" />
                            {renderStep.content.videoEmbed ? <div className='translucent'><i className="fa fa-play-circle" aria-hidden="true" onClick={() => setIsModal(true)}></i></div> : ''}
                        </div>

                    </VideoContent>
                </ContentLeft>
                <ContentRight>
                    <div className='step-pan w-100'>
                        <StepNavigation labelArray={stepsArray} currentStep={currentStep} updateCurrentStep={updateCurrentStep} />
                    </div>
                    <div className='tab-content'>
                        {renderStep.content.header ? <span className='tab-title'>{renderStep.content.header || ''}</span> : ''}
                        <div className='short-text '>
                            {renderStep.content.content ? <p className='text-ellipsis'>{renderStep.content.content[0].props.children}</p> :
                                <React.Fragment>{renderStep.content.content.map((item, idx) => <React.Fragment key={idx}>{item}</React.Fragment>)}</React.Fragment>}
                            <span onClick={() => setIsModal(true)} className='view-more'>[Xem thêm]</span>
                        </div>
                        {renderStep.content.actionPage ? <div className='w-30'>
                            <ButtonColor color='#fff' bgColor='#2C9B46'
                                onClick={() => {
                                    history.push(renderStep.content.actionPage.link)
                                }}>{renderStep.content.actionPage.label}</ButtonColor>
                        </div> : ''}
                    </div>
                </ContentRight>
            </div>
            <BaseModal isShow={isModal} onClose={() => setIsModal(false)} className='modal-width-40' customButton={renderStep.content.actionPage ? <div className='w-40'>
                <BtnRounded className="mr-3" color={CARD_COLOR.Green}
                    onClick={() => {
                        history.push(renderStep.content.actionPage.link)
                    }}>{renderStep.content.actionPage.label}</BtnRounded>
            </div> : ''}>
                <ModalVideo>
                    <iframe width="100%" height="300px" src={renderStep.content.videoEmbed} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                </ModalVideo>
                <div className='content w-100'>
                    <Title>{renderStep.content.header}</Title>
                    <div className='demo-text '>
                        {renderStep.content.content.map((item, idx) => <React.Fragment key={idx}>{item}</React.Fragment>)}
                    </div>

                </div>
            </BaseModal>
        </React.Fragment>)
        else return;
    }
    useEffect(() => {
        if (props.required) {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('body').style.height = '100vh';
        }
        if (!props.required) {
            document.querySelector('body').style.overflow = 'visible';
            document.querySelector('body').style.height = 'auto';
        }

    }, [props.required])
    return (
        <Box >
            <Title>Hoàn thành các bước để trải nghiệm.</Title>
            <div className='box__content' id='content-support'>
                {renderContent(currentStep)}
            </div>

            {/* các page require thực hiện mesage thì hiện */}
            {props.required ? <Filter onClick={() => Notify(NOTIFY.WARNING, NOTIFY.WARNING, 'Hoàn thành các bước để được trải nghiệm')}></Filter> : ''}

        </Box>
    )
}

const VideoContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
.image-thumbnail{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img{
        width: 100%;
        max-height: 100%;
    }
    .translucent{
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,.5);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: none;
        i{
            font-size: 5rem;
            color: #000;
            cursor: pointer;
        }
    } 
    &:hover .translucent{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
`
const ContentRight = styled.div`
flex-basis:70%;
.tab-content{
    padding:10px;
    .short-text {
        position: relative;
        width: 90%;
        span.view-more{
        color:#2C9B46;
        cursor: pointer;
        width: 100%;
        float: right;
        text-align: right;
        } 
    }
}
.text-ellipsis{
    display: -webkit-box;
    height: 45.2px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.625;
}
`
const ContentLeft = styled.div`
flex-basis:30%;
`
const Title = styled.span`

        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
`
const ModalVideo = styled.div`
width: 100%;
min-height:300px;
`
const Filter = styled.div`
position: absolute;
    bottom: -100vh;
    height: 100vh;
    right: 0;
    background: rgba(0,0,0,.2);
    width: 100%;
    z-index: 10;
`
export default SupportControl
