import styled from 'styled-components';

export const NotifyWrapper = styled.div`
width: 300px;
position: fixed;
top: 70px;
right: 0;
background: white;
height: calc(100vh - 70px);
box-shadow: 2px 0px 10px 1px #aaaaaa;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
.txt__green{
    color: #2e7d32;
    font-size: 14px;
}
.header__notify{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    padding: 15px 0;
    span.title{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        color: #000;
        font-size: 18px;
        font-weight: 600;
        i{
            margin-right: 5px;
        }
    }
    p{
        margin: 0;
        cursor: pointer;
        &:hover{
            text-decoration: 2px solid #2e7d32;
        }
    }
}
.content__notify{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 80vh;
    overflow-y: scroll;
}
`
export const NotifyItemWrapper = styled.div`
width: 100%;
padding: 5px 10px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
.time{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    span{
        margin-right: 6px;
    }
}
.title{
display: flex;
font-size: 18px;
font-weight: bold;
color: #000;
cursor: pointer;
}
p.description{
    font-size: 12px;
}
`
