import BaseServices from './BaseServices';

const BankServices = {
  GetBankAccounts: async (paging) => {
    return await BaseServices.Post('/api/Bank/get-bank-accounts', paging);
  },
  ChangeBankAccountStatus: async (id) => {
    return await BaseServices.Post(`/api/Bank/change-bank-account-status/${id}`);
  },
  VerifyBankAccount: async (id) => {
    return await BaseServices.Post(`/api/Bank/verify-bank-account/${id}`);
  },
  DeleteBankAccounts: async (ids) => {
    return await BaseServices.Post('/api/Bank/delete-bank-accounts', ids);
  },
  ////
  GetBankAccountInfo: async (id) => {
    return await BaseServices.Post(`/api/Bank/get-bank-account/${id}`);
  },
  GetInfoBankAccounts: async () => {
    return await BaseServices.Get(`/api/Bank/get-info-bank-accounts/`);
  },
  ////
  GetCreatorBankAccounts: async (username) => {
    return await BaseServices.Get(`/api/Bank/get-creator-bank-accounts?username=${username}`);
  },
  UpdateCreatorBankAccounts: async (bankAccounts) => {
    return await BaseServices.Post(`/api/Bank/update-creator-bank-accounts/`, bankAccounts);
  },
};

export default BankServices;
