import BaseServices from "./BaseServices";

const FontServices = {
    GetFonts: async (paging) => {
        return await BaseServices.Post("/api/Collection/get-list-font", paging);
    },
    CreateUpdateFont: async (model) => {
        return await BaseServices.Post("/api/Collection/create-update-font", model);
    },
    GetFontById: async (id) => {
        return await BaseServices.Get(`/api/Collection/get-font/${id}`);
    },
    DeleteFontById: async (id) => {
        return await BaseServices.Get(`/api/Collection/delete-font/${id}`);
    },
};

export default FontServices;
