import React from 'react'
import Common from './Common';
import cookie from 'react-cookies';

export default function AccessDeniedPage(props) {
    function _handleLogout() {
        Common.RemoveToken();
        cookie.remove('responseAPI')
        window.location.href = '/login'
    }
    return (
        <section className='bg-accpunt-pages'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="wrapper-page">
                            <div className="account-pages">
                                <div className="account-box">
                                    <div className="account-logo-box text-center">
                                        <h2 className="text-uppercase text-center">
                                            <a href="/" className="text-success">
                                                <span><img src="/logo.png" alt="" height="100" /></span>
                                            </a>
                                        </h2>

                                    </div>
                                    <div className="account-content text-center" style={{ paddingTop: 0 }}>
                                        <h1 className="text-error">403</h1>
                                        <h3 className="text-danger mt-3" style={{ fontSize: 20 }}>Bạn không có quyền truy cập vào trang này</h3>
                                        <div className="form-group row mt-4">
                                            <div className="col-12">
                                                <a className="btn btn-md btn-block btn-primary waves-effect waves-light" href='/'>Trang chủ</a>
                                            </div>
                                            <div className='col-12 m-t-10'>
                                                <p className='text-center'>
                                                    Thử <a onClick={_handleLogout}><strong>đăng nhập</strong></a> bằng tài khoản khác.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}