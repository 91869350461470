import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING } from "../based/Constants";
import Loading from "../based/Loading";
import OrderServices from "../based/services/OrderServices";
import { OrderAffiliateModel } from "../models/OrderAffiliateModel";

const ManageOrderAffiliate = () => {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([new OrderAffiliateModel()]);
    const [paging, setPaging] = useState(INITPAGING);

    useEffect(() => {
        getProductAffiliate(paging);
    }, []);

    const getProductAffiliate = async (paging) => {
        setBusy(true);
        const [err, data] = await OrderServices.GetListOrderAffiliateInfo(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(INITPAGING);
        }
        setBusy(false);
    };

    const _renderAction = () => {};

    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th className="center">Order Id</th>
                    <th className="center">Trạng thái</th>
                    <th className="center">Email người mua</th>
                    <th className="center">Tên người mua</th>
                    <th className="center">Địa chỉ người mua</th>
                    <th className="center">Số điện thoại người mua</th>
                    <th className="center">Ngày tạo đơn hàng</th>
                    <th className="center">Sản phẩm</th>
                    <th className="center">Số lượng</th>
                    <th className="center">Thành tiền</th>
                    <th className="center">Tên Creator</th>
                    <th className="center">Tên Affiliate</th>
                    <th className="center">Reference Code</th>
                    <th className="center">Hành động</th>
                </tr>
            </thead>
        );
    };

    const _renderBody = () => {
        if (datas && datas.length > 0) return <tbody>{_renderContentBody()}</tbody>;
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan="100" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    };

    const _renderContentBody = () => {
        let content = [];
        if (datas && datas.length > 0)
            datas.map((item, idx) => {
                let rowSpan = item.orD_OrderProductAffiliates ? item.orD_OrderProductAffiliates.length : 0;
                let count = 0;
                let orderProduct = item.orD_OrderProductAffiliates;
                orderProduct && orderProduct.length > 0
                    ? orderProduct.map((element, index) => {
                          count = count + 1;
                          let arr = [];
                          if (count <= 1) {
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {item.id}
                                  </td>
                              );
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {item.statusName}
                                  </td>
                              );
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {item.createdBy}
                                  </td>
                              );
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {item.customerName}
                                  </td>
                              );
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {item.shippingAddressName}
                                  </td>
                              );
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {item.phoneNumber}
                                  </td>
                              );
                              arr.push(
                                  <td className="center" rowSpan={rowSpan}>
                                      {Common.formatDate(new Date(item.createdDate), "fulldate")}
                                  </td>
                              );
                          }
                          arr.push(<td className="center">{element.productName}</td>);
                          arr.push(<td className="center">{element.quantity}</td>);
                          arr.push(<td className="center">{Common.formatCurrency(element.finallyPrice)}</td>);
                          arr.push(<td className="center">{element.creatorName}</td>);
                          arr.push(<td className="center">{element.affiliateName}</td>);
                          arr.push(<td className="center">{element.referenceCode}</td>);
                          if (count <= 1) {
                              arr.push(<td className="center" rowSpan={rowSpan}></td>);
                          }
                          content.push(
                              <tr key={idx}>
                                  {arr.map((ar, iii) => {
                                      return <React.Fragment key={iii}>{ar}</React.Fragment>;
                                  })}
                              </tr>
                          );
                      })
                    : null;
            });
        return content;
    };

    return (
        <React.Fragment>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Quản lý dòng sản phẩm" />
            <CommonTable datas={datas} paging={paging} onFilter={(newPage) => getProductAffiliate(newPage)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
        </React.Fragment>
    );
};

export default ManageOrderAffiliate;
