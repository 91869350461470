import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import Common from "../based/Common";
import FormValidate from "../based/FormValidate";
import Request from "../based/Request";
import Textbox from "../based/inputs/Textbox";
import { ApplicationContext } from "../context/ApplicationContext";
class Login extends FormValidate {
    constructor(props) {
        super(props);
        this.state = {
            isBusy: true,
            data: {
                errorMsg: "",
                username: "",
                password: "",
                isRemember: false,
            },
            errorMsg: "",
            username: "",
            password: "",
            isRemember: false,
            showPassword: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }
    componentDidMount() {
        this.setState({
            isBusy: false,
        });
    }
    handleInputChange(key, value) {
        let obj = this.state.data;
        obj.errorMsg = "";
        obj[key] = value;
        this.setState(obj);
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isBusy: true });
            let redirect = this.props.location.search;
            Request.Post("/api/accounts/login" + redirect, { ...this.state.data, rUUID: Common.GetRUUID() })
                .then((res) => {
                    // Save token to cookie
                    let today = new Date();
                    let expireDay = new Date();
                    expireDay.setDate(today.getDate() + 365);

                    //save token và redirect về địa chỉ
                    cookie.save("token", res.data.token, { path: "/", expires: expireDay });
                    this.getMenus(res.data.token);
                    window.location.href = res.data.redirect;
                })
                .catch((err) => {
                    this.setState({ isBusy: false });
                    var oldNotify = document.querySelectorAll(".ui-pnotify-closer");
                    for (var i = 0; i < oldNotify.length; i++) oldNotify[i].click();
                    this.displayError(err);
                });
        }
    }

    getMenus(token) {
        const instance = axios.create({
            baseURL: Request.API_URL,
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        instance.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
        instance
            .get("/api/menu/get-menus")
            .then((res) => {
                let data = res.data;
                if (data.success) {
                    let { applicationInfo, setApplicationInfo } = this.context;
                    applicationInfo.menus = data.data;
                    setApplicationInfo(applicationInfo);
                }
            })
            .catch((err) => {});
    }

    render() {
        const isDesktop = Common.isDesktop();
        const bannerDesktop = 650;
        const bannerMobile = 330;
        return (
            <section className="bg-account">
                <div className="page-header mt-5 ml-5 cursor-pointer">
                    <img
                        alt="Lotus"
                        src="/images/logo-lotus.png"
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    />
                </div>
                <div className="account-content">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="col-left">
                            <img atl="lotus" src="/images/background-image.png" style={{ height: isDesktop ? bannerDesktop : bannerMobile }} />
                        </div>
                        <div className="col-right">
                            <div className={isDesktop ? "main-account" : "main-account-mobile"}>
                                <h4 className="font-weight-bold text-dark mb-20">Đăng nhập</h4>
                                <div className="form-group mb-20">
                                    <Textbox
                                        placeholder="Tên đăng nhập"
                                        ref={(ins) => this.refSets.set("username", ins)}
                                        id="username"
                                        name="username"
                                        value={this.state.username}
                                        required
                                        email
                                        className="form-control"
                                        onChanged={this.handleInputChange}
                                        handleEnter={this.handleLogin}
                                    />
                                </div>
                                <div className="form-group position-relative">
                                    <Textbox
                                        placeholder="Mật khẩu"
                                        id="password"
                                        name="password"
                                        value={this.state.password}
                                        required
                                        ref={(ins) => this.refSets.set("password", ins)}
                                        password={!this.state.showPassword}
                                        className="form-control mb-10"
                                        onChanged={this.handleInputChange}
                                        handleEnter={this.handleLogin}
                                    />
                                    <i onClick={() => this.setState({ showPassword: !this.state.showPassword })} className={`fa icon ${this.state.showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                                <p
                                    className="text-dark ml-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push("/forgot-password");
                                    }}
                                >
                                    Quên mật khẩu?
                                </p>
                                <div className="d-flex mb-20">
                                    <div className="btn-lotus btn-dark ml-0" onClick={this.handleLogin}>
                                        Đăng nhập
                                    </div>
                                    <div
                                        className="btn-lotus btn-light-outline-dark hover_gray text-dark"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push("/signup");
                                        }}
                                    >
                                        Đăng ký
                                    </div>
                                </div>
                                {/* <div className='signin-other d-flex align-items-center'>
                                    <span className='mr-4 text-dark'>Đăng nhập bằng cách khác</span>
                                    <FacebookButton />
                                    <GoogleButton />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
Login.contextType = ApplicationContext;
export default Login;
