import React, { useState } from "react";
import styled from "styled-components";
import CrawlKeyword from "./CrawlKeyword";
import Breadcrumbs from "../based/Breadcrumbs";

const FOLDER_TAB = [
    { value: 0, label: "Crawl Shop" },
    { value: 1, label: "Crawl Từ Khóa" },
];

function ManageCrawl(props) {
    const [activeFolder, setActiveFolder] = useState(1);

    function _renderFolderTab() {
        return FOLDER_TAB.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a className={activeFolder == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => setActiveFolder(item.value)}>
                        <span>{item.label}</span>
                    </a>
                </li>
            );
        });
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý Crawl" />
            <ul className="nav nav-tabs tabs-bordered">{_renderFolderTab()}</ul>
            {activeFolder == 1 && <CrawlKeyword />}
        </Wrapper>
    );
}

export default ManageCrawl;

const Wrapper = styled.div``;
