import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import BaseServices from "../based/services/BaseServices";
import KeywordCollectionModal from "./components/KeywordCollectionModal";

function KeywordCollection(props) {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, fromDate: new Date(), toDate: new Date() });
    const [selectedItem, setSelectedItem] = useState(null);
    const [editId, setEditId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        setBusy(false);
        _fetchData(paging);
    }, []);

    async function _fetchData(newPaging) {
        // setBusy(true)
        const [err, data] = await BaseServices.Post("/api/searchEngine/get-page-keyword-collections", newPaging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setPaging(newPaging);
            setDatas([]);
            setBusy(false);
        }
    }

    async function onFileChange(event) {
        let selectedFile = event.target.files[0];
        var formData = new FormData();
        formData.append("file", selectedFile);
        const [err, data] = await BaseServices.Post("/api/searchEngine/upload-keyword-collection", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        var imagefile = document.getElementById("file");
        imagefile.value = null;
        if (!err && data) {
            _fetchData(INITPAGING);
        }
    }

    function _handleImport() {
        inputRef.current.click();
    }

    function _handleAdd() {
        setEditId(0);
        setShowModal(true);
    }

    function _handleEdit() {
        setShowModal(true);
        setEditId(selectedItem.collectionId);
    }

    function _handleDelete() {
        ConfirmDialog("Xác nhận xóa?", "Bạn có muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                const [err, data] = await BaseServices.Post("/api/searchEngine/delete-keyword-collection/" + selectedItem.collectionId);
                if (!err && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    setBusy(false);
                    _fetchData(paging);
                } else {
                    setBusy(false);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                }
            });
        });
    }

    function _renderAction() {
        return (
            <div className="row flex-row p-10 box__actions align-items-end">
                <div className="form-group">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleImport}>
                        <i className="fa fa-plus m-r-5" /> Import Excel
                    </button>
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" /> Thêm mới
                    </button>
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleEdit} disabled={!selectedItem}>
                        <i className="fa fa-edit m-r-5" /> Chỉnh sửa
                    </button>
                    <button className="btn btn-danger btn-sm m-r-5" onClick={_handleDelete} disabled={!selectedItem}>
                        <i className="fa fa-remove m-r-5" /> Xóa
                    </button>
                    <input ref={inputRef} type="file" hidden id="file" onChange={onFileChange} />
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center"></th>
                    <th className="center" width="150">
                        Collection Id
                    </th>
                    <th className="center">Các từ khóa</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return datas && datas.length > 0 ? (
            <tbody>
                {datas.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td width={40} className="center">
                                <CheckBox label=" " checked={selectedItem == item} onCheckedChange={(name, value) => (value ? setSelectedItem(item) : setSelectedItem(null))} />
                            </td>
                            <td className="center">{item.collectionId}</td>
                            <td>
                                <span>
                                    <p>{item.keywords}</p>
                                </span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colSpan="3" className="center">
                        Không có dữ liệu
                    </td>
                </tr>
            </tbody>
        );
    }

    function _handleSubmitForm(isSuccess) {
        if (isSuccess) {
            setShowModal(false);
            _fetchData(paging);
            setSelectedItem(null);
        }
    }
    function _handleCloseModal() {
        setShowModal(false);
    }
    return (
        <React.Fragment>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <CommonTable data={datas} paging={paging} searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={_fetchData} />
            <KeywordCollectionModal dataId={editId} isShow={showModal} onClose={_handleCloseModal} onSubmit={_handleSubmitForm} />
        </React.Fragment>
    );
}

export default KeywordCollection;
