import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import BannerHomeShopManagement from "./BannerHomeShopManagement";
import BannerSeftDesignManagement from "./BannerSeftDesignManagement";
import BannerTrademarkManagement from "./BannerTrademarkManagement";

const SCREEN = {
    SHOP: "SHOP",
    DESIGN: "DESIGN",
    TRADEMARK: "TRADEMARK",
};

export const BANNER_TYPE = {
    DESKTOP: 1,
    MOBILE: 2,
};
export const BANNER_PAGE = {
    TRADEMARK: 1,
    SHOP: 2,
    CUSTOMIZE: 3,
    CREATOR: 4,
    MARKETING_LANDING: 5,
};
export const BANNER_POSITION = {
    HOMEPAGE: 1,
    //TRADEMARK
    TOP_PROMOTION: 2,
    MIDDLE_PROMOTION: 3,
    VOUCHER: 4,
    BEST_DEAL: 5,
    NEW_DESIGN: 6,
    BEST_SELLER: 7,
    HOT_COLLECTION: 8,
    DESIGN_STYLE: 9,
    HOT_CREATOR: 10,
    //SHOP
    NEW_PRODUCT: 11,
    COMBO: 12,
    COLLECTION: 13,
    MALE: 14,
    FEMALE: 15,
    CHILD: 16,
    //CUSTOMIZE
    PRINT_DIGITAL: 17,
    COM_EMBROIDERY: 18,
    //CREATOR
    //19 20 21


    //MARKETING
    HEADER_BANNER: 22,

};
const PX_IMAGE = {
    "190PX": 190,
    "230PX": 230,
    "270PX": 270,
    "285PX": 285,
    "300PX": 300,
    "350PX": 350,
    "390PX": 390,
    "407PX": 407,
    "410PX": 410,
    "460PX": 460,
    "480PX": 480,
    "600PX": 600,
    "704PX": 704,
    "705PX": 705,
    "710PX": 710,
    "720PX": 720,
    "820PX": 820,
    "970PX": 970,
    "1440PX": 1440,
    "1500PX": 1500,
    "1920PX": 1920,
    "5000PX": 5000,
};

const SIZE_IMAGE = {
    "250KB": 250,
    "500KB": 500,
    "1024KB": 1024,
    "2048KB": 2048,
};

export const isValidImage = (image, page, position, type) => {
    return new Promise((resolve, reject) => {
        let width;
        let height;
        let size;
        let dpi = 72;
        if (page === BANNER_PAGE.TRADEMARK) {
            if (position === BANNER_POSITION.HOMEPAGE) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["970PX"] : PX_IMAGE["710PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["407PX"] : PX_IMAGE["285PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["500KB"];
            }
            if (position === BANNER_POSITION.TOP_PROMOTION) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["480PX"] : PX_IMAGE["480PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["190PX"] : PX_IMAGE["190PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["1024KB"];
            }
            if (position === BANNER_POSITION.MIDDLE_PROMOTION) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["705PX"] : PX_IMAGE["705PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["300PX"] : PX_IMAGE["300PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["1024KB"];
            }
            if (position === BANNER_POSITION.VOUCHER) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["710PX"] : PX_IMAGE["710PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["285PX"] : PX_IMAGE["285PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["1024KB"];
            }
            if(position === BANNER_POSITION.BEST_DEAL){
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['1440PX'] : PX_IMAGE['820PX']
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['300PX'] : PX_IMAGE['410PX']
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE['1024KB'] : SIZE_IMAGE['1024KB']
            }
            if(position === BANNER_POSITION.NEW_DESIGN){
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['1440PX'] : PX_IMAGE['820PX']
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['300PX'] : PX_IMAGE['410PX']
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE['1024KB'] : SIZE_IMAGE['1024KB']
            }
            if(position === BANNER_POSITION.BEST_SELLER){
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['1440PX'] : PX_IMAGE['820PX']
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['300PX'] : PX_IMAGE['410PX']
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE['1024KB'] : SIZE_IMAGE['1024KB']
            }
            if(position === BANNER_POSITION.HOT_COLLECTION){
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['1440PX'] : PX_IMAGE['820PX']
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['300PX'] : PX_IMAGE['410PX']
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE['1024KB'] : SIZE_IMAGE['1024KB']
            }
            if(position === BANNER_POSITION.DESIGN_STYLE){
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['1440PX'] : PX_IMAGE['820PX']
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['300PX'] : PX_IMAGE['704PX']
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE['1024KB'] : SIZE_IMAGE['1024KB']
            }
            if(position === BANNER_POSITION.HOT_CREATOR){
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['1440PX'] : PX_IMAGE['820PX']
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE['300PX'] : PX_IMAGE['410PX']
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE['1024KB'] : SIZE_IMAGE['1024KB'] 
            }
        } else if (page === BANNER_PAGE.SHOP) {
            if (position === BANNER_POSITION.HOMEPAGE) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1920PX"] : PX_IMAGE["720PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["720PX"] : PX_IMAGE["460PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["2048KB"] : SIZE_IMAGE["500KB"];
            }
            if (position === BANNER_POSITION.NEW_PRODUCT) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1500PX"] : PX_IMAGE["390PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["270PX"] : PX_IMAGE["230PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["1024KB"];
            }
            if (position === BANNER_POSITION.COMBO) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1500PX"] : PX_IMAGE["390PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["270PX"] : PX_IMAGE["230PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["1024KB"];
            }
            if (position === BANNER_POSITION.COLLECTION) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1500PX"] : PX_IMAGE["390PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["270PX"] : PX_IMAGE["230PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["1024KB"];
            }
            if (position === BANNER_POSITION.MALE) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1500PX"] : PX_IMAGE["600PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["270PX"] : PX_IMAGE["350PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["500KB"];
            }
            if (position === BANNER_POSITION.FEMALE) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1500PX"] : PX_IMAGE["600PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["270PX"] : PX_IMAGE["350PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["500KB"];
            }
            if (position === BANNER_POSITION.CHILD) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1500PX"] : PX_IMAGE["600PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["270PX"] : PX_IMAGE["350PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["1024KB"] : SIZE_IMAGE["500KB"];
            }
        } else if (page === BANNER_PAGE.CUSTOMIZE)  {
            if (position === BANNER_POSITION.HOMEPAGE) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["1920PX"] : PX_IMAGE["1920PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["5000PX"] : PX_IMAGE["5000PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["2048KB"] : SIZE_IMAGE["2048KB"];
            }
            if (position === BANNER_POSITION.PRINT_DIGITAL) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["300PX"] : PX_IMAGE["300PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["190PX"] : PX_IMAGE["190PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["250KB"] : SIZE_IMAGE["250KB"];
            }
            if (position === BANNER_POSITION.COM_EMBROIDERY) {
                width = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["300PX"] : PX_IMAGE["300PX"];
                height = type === BANNER_TYPE.DESKTOP ? PX_IMAGE["190PX"] : PX_IMAGE["190PX"];
                size = type === BANNER_TYPE.DESKTOP ? SIZE_IMAGE["250KB"] : SIZE_IMAGE["250KB"];
            }
        }
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function (e) {
            var img = new Image();
            img.src = e.target.result;
            img.onload = function () {
                var h = this.height;
                var w = this.width;
                if(!width || !height || !size) {
                    resolve(true);
                }
                else if (page === BANNER_PAGE.CUSTOMIZE && position === BANNER_POSITION.HOMEPAGE) {
                    if (width - 10 <= w && w <= width + 10 && h <= height + 10 && Math.round(image.size / 1024) <= size) {
                        resolve(true);
                    } else {
                        Notify(NOTIFY.WARNING, NOTIFY.WARNING, `Ảnh sai kích thước quy định`);
                        resolve(false);
                    }
                } else {
                    if ((w / h == width / height || (width - 10 <= w && w <= width + 10 && height - 10 <= h && h <= height + 10)) && Math.round(image.size / 1024) <= size) {
                        resolve(true);
                    } else {
                        Notify(NOTIFY.WARNING, NOTIFY.WARNING, `Ảnh sai kích thước quy định (chỉ cho phép lệch kích thước gốc trên dưới 10px hoặc đúng tỷ lệ w/h với kích thước gốc)`);
                        resolve(false);
                    }
                }
            };
        };
    });
};

function BannerManagement() {
    const [tab, setTab] = useState("");

    useEffect(() => {
        setTab(SCREEN.TRADEMARK);
    }, []);

    return (
        <Wapper>
            <Breadcrumbs currentPage="Quản lý banner" />
            <div className="tab-banner">
                <div
                    onClick={() => {
                        setTab(SCREEN.TRADEMARK);
                    }}
                    className={`item ${tab === SCREEN.TRADEMARK ? "active" : ""}`}
                >
                    Thương hiệu
                </div>
                <div
                    onClick={() => {
                        setTab(SCREEN.SHOP);
                    }}
                    className={`item ${tab === SCREEN.SHOP ? "active" : ""}`}
                >
                    Cửa hàng
                </div>
                <div
                    onClick={() => {
                        setTab(SCREEN.DESIGN);
                    }}
                    className={`item ${tab === SCREEN.DESIGN ? "active" : ""}`}
                >
                    Tự thiết kế
                </div>
            </div>
            <div>
                {tab === SCREEN.TRADEMARK && <BannerTrademarkManagement />}
                {tab === SCREEN.DESIGN && <BannerSeftDesignManagement />}
                {tab === SCREEN.SHOP && <BannerHomeShopManagement />}
            </div>
        </Wapper>
    );
}
const Wapper = styled.div`
    .tab-banner {
        display: flex;

        .item {
            width: 185px;
            height: 44px;
            display: grid;
            place-items: center;
            background-color: #f8f9fa;
            color: #666f7b;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;

            &.active {
                background-color: #fff;
                border-bottom: 5px solid #64c5b1;
                cursor: default;
            }
        }
    }
`;
export default BannerManagement;
