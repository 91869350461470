import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import TFUImage from "../../based/inputs/Image";
import { ProductVariantContext } from "../../context/ProductVariantContext";
import { VariantWarningZoneModel } from "../../models/BaseProductModel";

const MockupConfig = (props) => {
    const { editingVariant, addRef, listMockup, setListMockup } = useContext(ProductVariantContext);

    useEffect(() => {
        let variant = { ...editingVariant };
        let listZone = variant ? variant.productVariantMockups : [];

        if (listZone && listZone.length > 0) setListMockup(listZone);
        else setListMockup([]);
    }, [editingVariant]);

    return (
        <React.Fragment>
            <div className="col-md-12 card-box p-3">
                <h5 className="header-title m-t-0">
                    <b>CONFIG ẢNH MOCKUP ỐP LƯNG (TRÊN/DƯỚI)</b>
                </h5>
                <p className="sub-header">
                    Note:
                    <br />
                    - Config mặt trên/dưới để apply render ảnh ốp lưng SHOPEE/LAZADA
                    <br />
                </p>
            </div>
            <div className="card-box margin-bottom-10">
                <div>
                    <table className="table table-bordered m-b-0">
                        <thead className="thead-light">
                            <tr>
                                <th className="cursor-pointer center" width="200">
                                    Lớp ảnh bên dưới<span className="required-field-icon">&nbsp;*</span>
                                </th>
                                <th className="cursor-pointer center" width="200">
                                    Lớp ảnh trên<span className="required-field-icon">&nbsp;*</span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listMockup &&
                                listMockup.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <label className="btn btn-rounded btn-primary" htmlFor={`bottomUrl-${index}`}>
                                                    Tải mặt dưới lên
                                                </label>
                                                <TFUImage
                                                    image={item.bottomUrl}
                                                    ref={addRef}
                                                    onChanged={(name, value) => {
                                                        let lst = [...listMockup];
                                                        lst[index].bottomUrl = value;
                                                        setListMockup(lst);
                                                    }}
                                                    name={`bottomUrl-${index}`}
                                                    className={"custom-file-input d-none"}
                                                    id={`bottomUrl-${index}`}
                                                />
                                            </td>
                                            <td>
                                                <label className="btn btn-rounded btn-primary" htmlFor={`topUrl-${index}`}>
                                                    Tải mặt trên lên
                                                </label>
                                                <TFUImage
                                                    image={item.topUrl}
                                                    ref={addRef}
                                                    onChanged={(name, value) => {
                                                        let lst = [...listMockup];
                                                        lst[index].topUrl = value;
                                                        setListMockup(lst);
                                                    }}
                                                    name={`topUrl-${index}`}
                                                    className={"custom-file-input d-none"}
                                                    id={`topUrl-${index}`}
                                                />
                                            </td>
                                            <button
                                                className="btn btn-danger btn-sm mx-1"
                                                onClick={(e) => {
                                                    let lst = [...listMockup];
                                                    lst.splice(index, 1);
                                                    setListMockup(lst);
                                                }}
                                            >
                                                Xóa cấu hình
                                            </button>
                                        </tr>
                                    );
                                })}
                            <tr>
                                <td colSpan="999" className="text-right">
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            let newItem = { bottomUrl: "", topUrl: "" };
                                            let lst = [...listMockup];
                                            lst.push(newItem);
                                            setListMockup(lst);
                                        }}
                                    >
                                        Thêm cấu hình
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MockupConfig;

const WarningZoneItem = ({ item = new VariantWarningZoneModel(), setItem = () => {}, index }) => {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);
    const [options, setOptions] = useState([]);
    const [testUrl, setTestUrl] = useState("");

    useEffect(() => {
        getOptions();
    }, [editingVariant]);

    const getOptions = () => {
        let listImage =
            editingVariant && editingVariant.listImage
                ? editingVariant.listImage.map((item) => ({
                      value: item.url,
                      label: item.url,
                      image: item.url,
                      imageTypeName: item.imageType == 27 ? "Mặt trước" : item.imageType == 28 ? "Mặt sau" : "",
                  }))
                : [];
        setOptions(listImage);
    };

    return (
        <Wrapper>
            <td className="text-center vertical-align-middle">
                <ReactSelect
                    value={item.url}
                    options={options}
                    formatOptionLabel={(country) => (
                        <div className="country-option">
                            <img src={country.image} alt="country-image" />
                            <span>{country.imageTypeName}</span>
                        </div>
                    )}
                    onChange={(selected) => {
                        setItem({ ...item, url: selected.value });
                    }}
                />
                {item.url && <img src={item.url} className="thumbnail-image" />}
            </td>
            <td className="text-center vertical-align-middle">
                <label className="btn btn-rounded btn-primary" htmlFor={`bottomLayer-${index}`}>
                    Tải mặt dưới lên
                </label>
                <TFUImage image={item.bottomLayer} ref={addRef} onChanged={(name, value) => setItem({ ...item, bottomLayer: value })} name={`bottomLayer-${index}`} className={"custom-file-input d-none"} id={`bottomLayer-${index}`} />
            </td>
            <td className="text-center vertical-align-middle">
                <label className="btn btn-rounded btn-primary" htmlFor={`topLayer-${index}`}>
                    Tải mặt trên lên
                </label>
                <TFUImage image={item.topLayer} ref={addRef} onChanged={(name, value) => setItem({ ...item, topLayer: value })} name={`topLayer-${index}`} className={"custom-file-input d-none"} id={`topLayer-${index}`} />
            </td>
            <td className="text-center vertical-align-middle">
                <input
                    placeholder="--Tên hiển thị--"
                    value={item.displayName}
                    onChange={(e) => {
                        let val = e.target.value;
                        setItem({ ...item, displayName: val });
                    }}
                    className="form-control"
                />
            </td>
        </Wrapper>
    );
};

const Wrapper = styled.tr`
    .image-preview {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }

    .image-preview img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .country-option {
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }

    .thumbnail-image {
        width: 60%;
        height: auto;
        object-fit: contain;
    }
`;
