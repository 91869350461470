export class BankAccountConfigModel {
    constructor() {
        this.id = 0;
        this.bankAccountName = '';
        this.bankAccountNumber = 0;
        this.bankName = '';
        this.bankCode = 0;
        this.verified = false;
        this.isActive = true;
    }
}
