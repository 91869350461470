import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import { CONSTANTS, INITPAGING, NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import CheckBox from "../based/inputs/CheckBox";
import HotKeyServices from "../based/services/HotKeyServices";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import AddOrUpdateHotKey from "./AddOrUpdateHotKey";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

function HotKeyManagement() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        getData(paging);
    }, []);


    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await HotKeyServices.GetHotKeyByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderBody(){
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    {" "}
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td >{item.hotKey}</td>                                
                                <td >{item.redirectUrl}</td>                                
                                <td style={{width: 300 , textAlign:'center'}}>
                                    <button
                                        className='btn btn-custom'
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th width={40} className='center'>
                        <CheckBox id='select_all' name='select_all' checked={data && data.length > 0 && data.filter((x) => x.selected).length === data.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th className='cursor-pointer'>Tổ hợp phím</th>
                    <th className='cursor-pointer'>Đường dẫn điều hướng</th>
                    <th className='cursor-pointer'>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className='btn btn-custom mb-3'
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>

                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger mb-3 ml-3" : "btn btn-danger mb-3 ml-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }
    const _handleChecked = (index, value) => {
        let newDatas = [...data];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setData(newDatas);
    };
    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await HotKeyServices.DeleteHotKey(ids);
                    if (!error && data) {
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có key nào được chọn. Vui lòng kiểm tra lại!");
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý tổ hợp phím' />
            <Loading show={isBusy} msg={msg} />
            <CommonTable 
                data={data} 
                paging={paging}
                ref={tableRef} 
                headerCheckbox 
                hideSearchBasicButton
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={getData} 
                />



                {isShowModal && (
                <AddOrUpdateHotKey
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </React.Fragment>
    )
}




const Wrapper = styled.div`
   
`
HotKeyManagement.propTypes = {};


export default HotKeyManagement;
