import BaseServices from "./BaseServices";

const ComboServices = {
    GetComboProductSelectByPage: async (paging) => {
        return await BaseServices.Post(`/api/combo/get-combo-product-select-by-page`, paging);
    },
    GetComboByPage: async (paging) => {
        return await BaseServices.Post(`/api/combo/get-combo-by-page`, paging);
    },
    DeleteCombo: async (comboId) => {
        return await BaseServices.Get(`/api/combo/delete-combo/${comboId}`);
    },
    GetComboById: async (comboId) => {
        return await BaseServices.Get(`/api/combo/get-combo/${comboId}`);
    },
    CreateUpdateCombo: async (model) => {
        return await BaseServices.Post(`/api/combo/create-update-combo`, model);
    },
};

export default ComboServices;
