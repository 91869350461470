import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import TextNumber from "../based/inputs/TextNumber";

const initSizeOption = {
    productId: 0,
    productName: "",
    maxHeight:0 , 
    maxWeight: 0 , 
    minHeight: 0 , 
    minWeight: 0,
    isDelete: false,
}

const ModalAddOrUpdateSize = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [size, setSize] = useState({})
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if(props.id>0 )
            getSize(props.id)
        else{
            setSize({...initSizeOption})
        }
    }, [props.id]);
    

    const getSize = async (id) => {
        setIsBusy(true);
        let [err, data] = await props.getById(id);
        if (!err && data) {
            setIsBusy(false);
            setSize(data)
        } else {
            setIsBusy(false);
            setSize({...initSizeOption})
        }
    };
    const _handleSave = async () => {

        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await props.createOrUpdate(size);
            if (!err && data) {
                setIsBusy(false);
                setSize({...initSizeOption})
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                
            }
            props.onSaveContinue();
        }
      
    };


   
   
    
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                {props.id>0 ? <div>ProductId: {size.productId}</div> :
                <div>
                    <label htmlFor='productId' className='required'>
                        ProductId:
                    </label>
                    <TextNumber id='productId' name='productId' required ref={addRef} className='form-control' 
                    onChanged={(name, value) => setSize({ ...size, productId: value })} 
                    value={size.productId} />    
                </div>}
                <label htmlFor='product-name' className='required'>
                    Product Name:
                </label>
                <Textbox id='product-name' name='product-name' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, productName: value })} 
                value={size.productName} />
                <label htmlFor='min-weight' className='required'>
                    Min-Weight:
                </label>
                <TextNumber id='min-weight' name='min-weight' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, minWeight: value })} 
                value={size.minWeight} />
                <label htmlFor='max-weight' className='required'>
                    Max-Weight:
                </label>
                <TextNumber id='max-weight' name='max-weight' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, maxWeight: value })} 
                value={size.maxWeight} />
                
                
                <label htmlFor='min-height' className='required'>
                    Min-Height:
                </label>
                <TextNumber id='min-height' name='min-height' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, minHeight: value })} 
                value={size.minHeight} />
                <label htmlFor='max-height' className='required'>
                    Max-Height:
                </label>
                <TextNumber id='max-height' name='max-height' required ref={addRef} className='form-control' 
                onChanged={(name, value) => setSize({ ...size, maxHeight: value })} 
                value={size.maxHeight} />
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdateSize;

ModalAddOrUpdateSize.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
