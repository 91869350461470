import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TFUImage from "../../based/inputs/Image";
import ColorPicker from "../../based/ColorPicker";
import cloneDeep from "lodash.clonedeep";
import PosterServices from "../../based/services/PosterServices";
import TFUSelect from "../../based/inputs/TFUSelect";
import { Notify } from "../../based/Notify";
import { NOTIFY } from "../../based/Constants";
import useFormValidate from "../../hooks/useFormValidate";

const VariantModal = (props) => {
    const { datas, setDatas, showVariant, setShowVariant, mainColorOptions } = props;
    const [dataModel, setDataModel] = useState();
    const { addRef, displayError, isValid } = useFormValidate();
    const [variantArtwork, setVariantArtwork] = useState([]);
    useEffect(() => {
        getVariantByProductId();
        getDataModel();
    }, [showVariant]);
    const getDataModel = () => {
        let dataTemp = {
            name: datas[0].name,
            templateTypeName: datas[0].templateTypeName,
            templateTypeId: datas[0].templateTypeId,
            posterCategory: datas[0].posterCategory,
            mainColor: "",
            bottomLayer: "",
            topLayer: "",
            posterGroupId: 0,
            isDefault: false,
            width: 0,
            height: 0,
            coL_PosterArtworkConfigs: cloneDeep(datas[0].coL_PosterArtworkConfigs),
            coL_PosterContentConfigs: datas[0].coL_PosterContentConfigs,
        };
        setDataModel(dataTemp);
    };

    const getVariantByProductId = () => {
        let dataOptions = [];
        datas[0].coL_PosterArtworkConfigs.map(async (set) => {
            let [err, data] = await PosterServices.GetListPosterVariantByProductId(set.productId);
            if (!err && data) {
                let atributeTemp = data.find((item) => item.id == set.appProductVariantId).attributeValueLightModels;
                let size = atributeTemp.find((item) => item.code == "Size").value;
                let dataTemp = [];
                data.map((item) => {
                    if (item.attributeValueLightModels.find((x) => x.code == "Size").value == size) {
                        let color = item.attributeValueLightModels.find((x) => x.code == "Color").value;
                        if (!dataTemp.find((x) => x.text == color)) {
                            let VariantOptionsModel = {
                                value: item.id,
                                text: color,
                                label: item.colorDisplayName,
                            };
                            dataTemp.push(VariantOptionsModel);
                        }
                    }
                });
                dataOptions.push(dataTemp);
                setVariantArtwork(dataOptions);
            }
        });
    };
    const handleAddVariant = () => {
        let listDataTemp = [...datas];
        if (isValid() && listDataTemp[listDataTemp.length - 1].bottomLayer != "") {
            let dataTemp = cloneDeep(dataModel);
            listDataTemp.push(dataTemp);
            setDatas(listDataTemp);
        } else {
            Notify(NOTIFY.WARNING, "Thông báo", "Vui lòng cung cấp đủ thông tin");
        }
    };
    const handleSetTopLayer = (value, idx) => {
        let listDataTemp = [...datas];
        listDataTemp[idx].topLayer = value;
        setDatas(listDataTemp);
    };
    const handleSetBottomLayer = (value, idx) => {
        let listDataTemp = [...datas];
        listDataTemp[idx].bottomLayer = value;
        setDatas(listDataTemp);
    };
    const handleSetColorCode = (value, idx, index) => {
        let listDataTemp = [...datas];
        listDataTemp[idx].coL_PosterArtworkConfigs[index].appProductVariantId = value;
        setDatas(listDataTemp);
    };
    const setMainColor = (value, idx) => {
        let listDataTemp = [...datas];
        listDataTemp[idx].mainColor = value;
        setDatas(listDataTemp);
    };
    const createOrUpdate = async () => {
        if (isValid() && datas[datas.length - 1].bottomLayer != "") {
            let [err, data] = await PosterServices.CreateUpdatePoster(datas);
            if (!err && data) {
                setDatas(data);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                setShowVariant(false);
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng thử lại sau");
            }
        } else {
            Notify(NOTIFY.WARNING, "Thông báo", "Vui lòng cung cấp đủ thông tin");
        }
    };
    const handleClose = ()=>{
        let listDataTemp = [...datas];
        if (isValid() && listDataTemp[listDataTemp.length - 1].bottomLayer == "") {
            listDataTemp.pop();
            setDatas(listDataTemp);
            setShowVariant(false)
        } else {
            setShowVariant(false)
        }
    }
    return (
        <Wrapper showVariant={showVariant}>
            {showVariant && (
                <div className='dialog-container'>
                    <img src='./images/Cancel_line_black.svg' className='image' onClick={() => handleClose()} />
                    <h3>
                        <b>Biến thể</b>
                    </h3>
                    <div className='table-container'>
                        <table className='table'>
                            <thead className='w-100'>
                                <tr className='tr-head w-100'>
                                    <th className='text-center w-17'>Tên biến thể</th>
                                    <th className='text-center w-17'>Lớp dưới</th>
                                    <th className='text-center w-17'>Lớp trên</th>
                                    <th className='text-center w-28'>Màu sắc</th>
                                    <th className='text-center w-21'>Tông màu</th>
                                </tr>
                            </thead>
                            <tbody className='w-100'>
                                {datas.map((set, idx) => {
                                    return (
                                        <tr>
                                            <th className='text-center align-middle'>Biến thể {idx + 1}</th>
                                            <th className='text-center align-middle'>
                                                {set.bottomLayer ? (
                                                    <img src={set.bottomLayer} style={{ height: 50 }} />
                                                ) : (
                                                    <React.Fragment>
                                                        <label htmlFor='bottomLayer'>Tải ảnh</label>
                                                        <TFUImage image={set.bottomLayer} className={"custom-file-input d-none"} imageType='poster' onChanged={(name, value) => handleSetBottomLayer(value, idx)} name='subImage' id={`bottomLayer`} />
                                                    </React.Fragment>
                                                )}
                                            </th>
                                            <th className='text-center align-middle'>
                                                {set.topLayer ? (
                                                    <img src={set.topLayer} style={{ height: 50 }} />
                                                ) : (
                                                    <React.Fragment>
                                                        <label htmlFor='topLayer'>Tải ảnh</label>
                                                        <TFUImage image={set.topLayer} imageType='poster' className={"custom-file-input d-none"} onChanged={(name, value) => handleSetTopLayer(value, idx)} name='topLayer' id={`topLayer`} />
                                                    </React.Fragment>
                                                )}
                                            </th>
                                            <th className='text-center align-middle'>
                                                {set.coL_PosterArtworkConfigs.map((item, index) => {
                                                    return (
                                                        <div className='color-container'>
                                                            <span>Vị trí {index + 1}</span>
                                                            <TFUSelect
                                                                className='select'
                                                                options={variantArtwork[index]}
                                                                name='mainColor'
                                                                id='mainColor'
                                                                ref={addRef}
                                                                required
                                                                value={item.appProductVariantId}
                                                                onChanged={(value) => handleSetColorCode(value, idx, index)}
                                                            />
                                                            {item.appProductVariantId > 0 ? (
                                                                <div
                                                                    className='color-review'
                                                                    style={{ background: `${variantArtwork.length > 0 ? variantArtwork[index].find((x) => x.value == item.appProductVariantId).text : ""}` }}
                                                                ></div>
                                                            ) : (
                                                                <div className='color-review'></div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </th>
                                            <th className='text-center align-middle'>
                                                <div className='tones-container'>
                                                    <TFUSelect className='select' options={mainColorOptions} name='mainColor' id='mainColor' value={datas[idx].mainColor} onChanged={(value) => setMainColor(value, idx)} />
                                                    <div className='color-review' style={{ background: `${datas[idx].mainColor}` }}></div>
                                                </div>
                                            </th>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <span style={{ color: "#00c853" }} onClick={handleAddVariant}>
                        Thêm biến thể +
                    </span>
                    <div className='btn-variant-container'>
                        <span className='btn-close' onClick={() => handleClose()}>
                            Đóng
                        </span>
                        <span className='btn-done' onClick={()=>createOrUpdate()}>Hoàn thành</span>
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default VariantModal;

const Wrapper = styled.div`
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: ${({ showVariant }) => (showVariant ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    z-index: 999;

    .dialog-container {
        padding: 20px;
        width: 800px !important;
        max-height: 800px !important;
        min-height: 400px !important;
        height: 400px !important;
        background: white;
        position: relative;
        display: flex;
        flex-direction: column;
        .image {
            position: absolute;
            height: 20px;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
        .btn-variant-container {
            position: absolute;
            right: 20px !important;
            bottom: 20px !important;
            display: flex;
            .btn-close {
                margin-right: 20px;
                padding: 5px 25px !important;
                color: white;
                background: #00c853;
                cursor: pointer;
            }
            .btn-done {
                padding: 5px 25px !important;
                color: white;
                background: #00c853;
                cursor: pointer;
            }
        }
        .table-container {
            height: 200px;
            display: block;
            overflow-y: scroll;
            .table {
                width: 100%;
                border: 1px solid #dee2e6;
                background-color: transparent;
                border-collapse: collapse;
                .tr-head {
                    background: #e4e4e4;
                    color: black;
                }
                tbody {
                    tr {
                        th {
                            .tones-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .select {
                                    width: 80%;
                                }
                                .color-review {
                                    height: 20px;
                                    width: 20px;
                                    border: 1px solid black;
                                }
                            }
                            .color-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                span {
                                    width: 40%;
                                }
                                .select {
                                    width: 50%;
                                }
                                .color-review {
                                    height: 20px;
                                    width: 20px;
                                    border: 1px solid black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
