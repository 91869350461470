import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import Common from "../based/Common";
import { IMAGEABLE_TYPE, NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CustomizeServices from "../based/services/CustomizeServices";
import useFormValidate from "../hooks/useFormValidate";
import { HandleFileName, HandleNewFileName } from "./AddOrEditMultipleModal";
const PlatformType = {
    Undefined: 0,
    ShutterStock: 1,
    Adobe: 2,
};

const StockImagePlatformModel = {
    platformId: "",
    platformType: PlatformType.Undefined,
};

const PlatformTypeOptions = [
    {
        value: PlatformType.ShutterStock,
        label: "ShutterStock",
    },
    {
        value: PlatformType.Adobe,
        label: "Adobe",
    },
];
const StockImageModel = {
    id: 0,
    title: "",
    thumbUrl: "",
    url: "",
    width: 0,
    height: 0,
    tagConnections: [],
    stockImagePlatform: { ...StockImagePlatformModel },
};

const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [stockImage, setStockImage] = useState(StockImageModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const { id, onSaveContinue, onClose, isShowModal } = props;

    useEffect(() => {
        if (id > 0) _getStockPhoto(id);
        return () => setStockImage({});
    }, [id]);

    const _getStockPhoto = async (id) => {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetStockImageById(id);
        if (!err && data) {
            let dt = { ...data };
            if (!dt.stockImagePlatform) dt.stockImagePlatform = { ...StockImagePlatformModel };
            setStockImage(dt);
        } else setStockImage(StockImageModel);
        setIsBusy(false);
    };

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let model = { ...stockImage };
            let [err, data] = await CustomizeServices.CreateUpdateStockImage(model);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                onSaveContinue();
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            setIsBusy(false);
        }
    };

    const _handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadStockPhoto(file, IMAGEABLE_TYPE.StockPhoto).then((res) => {
                    if (res && res.data) {
                        let { imageUrl, thumbnailUrl } = res.data;
                        let fileName = file.name;
                        let handleFileName = HandleNewFileName(fileName);
                        let si = { ...stockImage };
                        si.url = imageUrl;
                        si.thumbUrl = thumbnailUrl;

                        if (handleFileName) {
                            si.title = handleFileName.title;
                            si.tagConnections = handleFileName.tags;
                            si.stockImagePlatform = { ...StockImagePlatformModel, platformId: handleFileName.platformId, platformType: handleFileName.platformType };
                        }
                        setStockImage(si);
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Wrapper>
            <CommonModal show={isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()} classModalBody={"modal-class-body"}>
                <div className="form-group">
                    <label htmlFor="image" className="required">
                        Stock Image:
                    </label>
                    <div className="sub-header">
                        Tên file:
                        <br />
                        Dành cho Shutter Stock: Doremon ngồi trước cửa#doremon đang ngồi,nobita#_Id_st
                        <br />
                        Dành cho Adobe: Doremon ngồi trước cửa#doremon đang ngồi,nobita#_Id_ad
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            _handleImageChange(e);
                        }}
                    />
                    <div className="variant-image">
                        <img className="image-cover" src={stockImage.thumbUrl} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="title" className="required">
                        Tên hiển thị:
                    </label>
                    <Textbox id="title" name="title" required ref={addRef} className="form-control" onChanged={(name, value) => setStockImage({ ...stockImage, title: value })} value={stockImage.title} />
                </div>
                <div className="form-group">
                    <label htmlFor="platformId">Platform Id (Adobe/ShutterStock Id)</label>
                    <Textbox
                        id="platformId"
                        name="platformId"
                        className="form-control"
                        onChanged={(name, value) => {
                            let si = { ...stockImage };
                            let platform = si.stockImagePlatform;
                            platform.platformId = value;
                            setStockImage(si);
                        }}
                        value={stockImage.stockImagePlatform.platformId}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="platformType">Platform Type (Adobe/ShutterStock)</label>
                    <TFUSelect
                        name="platformType"
                        value={stockImage.stockImagePlatform.platformType}
                        options={PlatformTypeOptions}
                        onChanged={(newValue) => {
                            let si = { ...stockImage };
                            let platform = si.stockImagePlatform;
                            platform.platformType = !newValue ? PlatformType.Undefined : newValue;
                            setStockImage(si);
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description" className="required">
                        Tag:
                    </label>
                    <TagsInput
                        addKeys={[13]}
                        addOnPaste
                        maxTags={10}
                        inputProps={{ placeholder: "" }}
                        value={stockImage.tagConnections || []}
                        onChange={(tags) => {
                            let newTags = tags.reduce((prev, val, idx) => {
                                return prev.indexOf(val) == -1 ? [...prev, val] : [...prev];
                            }, []);
                            setStockImage({ ...stockImage, tagConnections: newTags });
                        }}
                    />
                </div>
            </CommonModal>
        </Wrapper>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};

const Wrapper = styled.div`
    .modal-class-body {
        min-height: 50vh;
    }
`;
