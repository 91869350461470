import React from 'react';
import Common from './Common';

const DisplayVariantAttributeValue = ({ key, keyName, value }) => {
    return Common.isHexColorValue(value)
        ?   <div className="mr-4 d-flex align-items-center" key={key}>
                <b>{keyName}:</b> 
                <div className="ml-1"style={{ background: value, width: "1rem", height: "1rem" }}></div>
            </div>
        :   <span className="mr-4" key={key}><b>{keyName}</b>: {value}</span>
}

export default DisplayVariantAttributeValue;