import React, { useState, useEffect, useRef } from 'react';
import PubSub from 'pubsub-js';
import Moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { CONSTANTS, MAINMENU, NOTIFY, BANK_TRANSFER_STATUS, BANK_TRANSFER_STATUS_NAME } from '../based/Constants';
import Common from '../based/Common';
import Loading from '../based/Loading';
import { Layout } from '../layout/Layout';
import Breadcrumbs from '../based/Breadcrumbs';
import CommonTable from '../based/CommonTable';
import CheckBox from '../based/inputs/CheckBox';
import Language from '../based/Language';
import CreditFormModal from './CreditFormModal';
import { AGE_AgencyCreditModel } from '../models/AgencyCreditModel';
import { Notify } from '../based/Notify';
import { ConfirmDialog } from '../based/Dialog';
import CreditServices from '../based/services/CreditServices';
import BankServices from '../based/services/BankServices';

const TAB_INDEX = { ...BANK_TRANSFER_STATUS, ALL: 0 };
const TAB_INDEX_NAME = { ...BANK_TRANSFER_STATUS_NAME, ALL: 'Tất cả' };
const selectedTabIndex = [0, 1, 2, 3, 4];

const CreditManagement = (props) => {
  const columns = [
      { name: 'bankAccountName', displayName: 'Tên tài khoản nhận' },
      { name: 'bankAccountNumber', displayName: 'Số tài khoản nhận' },
      { name: 'bankName', displayName: 'Tên ngân hàng nhận' },
      { name: 'amount', displayName: 'Số tiền rút', type: 'currency' },
      { name: 'balance', displayName: 'Số tiền khả dụng', type: 'currency' },
      { name: 'modifiedDate', displayName: 'Lần cập nhật gần nhất', type: 'date' },
      { name: 'transferStatusName', displayName: 'Trạng thái', type: 'status' },
      { name: 'remark', displayName: 'Ghi chú', type: 'status' },
    ],
    initPage = { pageSize: 10, pageNumber: 1, orderBy: 'id', orderDirection: 'asc', transferStatus: 0 },
    [busy, setBusy] = useState({ isBusy: true, busyMsg: '' }),
    [paging, setPaging] = useState(initPage),
    [datas, setDatas] = useState([]),
    [tableGuid, setTableGuid] = useState(Common.generateGuid()),
    [bankAccounts, setBankAccounts] = useState([]),
    tableRef = useRef(null),
    [bankTransfer, setBankTransfer] = useState(new AGE_AgencyCreditModel()),
    [isShowModal, setIsShowModal] = useState(false),
    [selectAll, setSelectAll] = useState(false),
    [tabIndex, setTabIndex] = useState(TAB_INDEX.ALL);

  useEffect(() => {
    setBusy({ isBusy: false, busyMsg: '' });
    getDatas(paging);

    getDataBankAccount();
    PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.CreditManage });
  }, []);

  function _renderTabIndex() {
    let resultTabIndex = Object.entries(TAB_INDEX).reduce((prev, val) => (selectedTabIndex.indexOf(val[1]) >= 0 ? [...prev, [val[1], val[0]]] : prev), []);
    let newMap = new Map(resultTabIndex);
    let tempIndex = selectedTabIndex.map((item) => {
      return [item, newMap.get(item)];
    });

    return tempIndex.map((item, index) => {
      return (
        <li key={index} className="nav-item">
          <a className={tabIndex == item[0] ? 'nav-link active' : 'nav-link'} onClick={() => _handleChangeTab(item[0])}>
            <span className="d-none d-sm-block">{TAB_INDEX_NAME[item[1]]}</span>
          </a>
        </li>
      );
    });
  }

  function _handleChangeTab(index) {
    setTabIndex(index);
    let datas = { ...paging, transferStatus: index };
    getDatas(datas);
    setPaging(datas);
  }

  const getDatas = async (newPaging) => {
      setBusy({ isBusy: true, busyMsg: 'Đang tải dữ liệu...' });
      const [err, data] = await CreditServices.GetBankTransfers(newPaging);
      if (!err && data) {
        setPaging(data.paging);
        setDatas(data.listObjects);
        setTableGuid(Common.generateGuid());
      } else {
        Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
        setPaging(initPage);
        setDatas([]);
      }
      setBusy({ isBusy: false, busyMsg: '' });
    },
    getDataBankAccount = async () => {
      const [err, data] = await BankServices.GetInfoBankAccounts();
      if (!err) {
        if (data && data.length > 0) {
          var datas = data.map((item) => {
            item.label = `Ngân hàng: ${item.bankName} - Số tài khoản: ${item.bankAccountNumber} - Tên tài khoản: ${item.bankAccountName}`;
            item.value = item.id;
            return item;
          });
          setBankAccounts(datas);
        }
      }
    };

  const renderTableAction = () => (
      <div className="row">
        <div className="col-sm-12">
          <div className="row flex-row p-y-10">
            {props.managementType == 'seller' ? (
              <div className="w-110px">
                <div className="form-group">
                  <button className="btn btn-custom btn-sm w-100px" onClick={handleAddBankTransfer}>
                    <i className="fa fa-plus m-r-5" />
                    {Language.getValue('tableAction.add')}
                  </button>
                </div>
              </div>
            ) : null}
            <div className="w-110px">
              <div className="form-group">
                <button className="btn btn-custom btn-sm w-100px" disabled={datas && datas.filter((x) => x.checked).length != 1} onClick={handleSeeBankTransferDetail}>
                  <i className="fa fa-info m-r-5" />
                  {Language.getValue('tableAction.detail')}
                </button>
              </div>
            </div>
            {props.managementType == 'seller' ? (
              <div className="w-110px">
                <div className="form-group">
                  <button className="btn btn-danger btn-sm w-100px" disabled={!datas.some((x) => x.checked)} onClick={handleDeleteBankTransfer}>
                    <i className="fa fa-remove m-r-5" />
                    {Language.getValue('tableAction.delete')}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ),
    handleAddBankTransfer = () => {
      setBankTransfer(new AGE_AgencyCreditModel());
      setIsShowModal(true);
    },
    handleSeeBankTransferDetail = () => {
      var datasSelected = datas.filter((x) => x.checked);
      if (datasSelected && datasSelected.length > 1) {
        Notify(NOTIFY.INFO, 'Thông báo', 'Chỉ được chọn 1 dòng để xem mỗi lần!');
      } else {
        setBankTransfer({ ...datasSelected[0], created: true });
        setIsShowModal(true);
      }
    },
    handleDeleteBankTransfer = () => {
      var datasDelete = datas.filter((x) => x.checked);
      if (datasDelete && datasDelete.length > 0) {
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
          return new Promise(async (resolve, reject) => {
            var ids = datasDelete.map((x) => x.id);
            setBusy({ isBusy: true, busyMsg: 'Đang xóa dữ liệu...' });
            const [err, data] = await CreditServices.RemoveBankTransfers(ids);
            if (!err) {
              setBusy({ isBusy: false, busyMsg: '' });
              resolve({ title: 'Thành công', msg: 'Xóa thành công' });
              getDatas(paging);
            } else {
              Notify(NOTIFY.ERROR, 'Lỗi', CONSTANTS.MSG_ERROR);
              setBusy({ isBusy: false, busyMsg: '' });
            }
          });
        });
      } else Notify(NOTIFY.INFO, 'Thông báo', 'Không có bản ghi nào được chọn. Vui lòng kiểm tra lại!');
    };

  const renderTableHeader = () => (
      <thead>
        <tr className="text-center">
          <th width={40} className="center">
            <CheckBox name="select_all" label="" checked={selectAll} onCheckedChange={(name, value) => handleCheckedAll(value)} />
          </th>
          {columns.map((column, i) => {
            var icon = <i className="fa fa-sort fa-sort-thead"></i>;
            if (column.name && column.name.toLowerCase() === paging.orderBy.toLowerCase()) icon = paging.orderDirection.toUpperCase() === 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead" /> : <i className="fa fa-sort-asc fa-sort-thead" />;
            return (
              <th key={i} className="cursor-pointer" width={column.width ? column.width : 'auto'} onClick={() => handleSortColumn(column)}>
                {column.isSort ? icon : ''}
                <span>{column.displayName}</span>
              </th>
            );
          })}
        </tr>
      </thead>
    ),
    handleCheckedAll = (value) => {
      setSelectAll(value);
      var newDatas = datas.map((item) => {
        item.checked = value;
        return item;
      });
      setDatas(newDatas);
    },
    handleSortColumn = (column) => {
      var dir = column.name == paging.orderBy ? 'desc' : 'asc',
        newPaging = { ...paging, orderBy: column.name, orderDirection: dir };
      getDatas(newPaging);
    };

  const renderTableBody = () => {
      if (datas && datas.length > 0)
        return (
          <tbody>
            {datas.map((item, i) => {
              return (
                <tr key={i} onClick={() => handleClickRow(i)}>
                  {
                    <td width={40} className={'center'}>
                      <CheckBox id={item.id.toString()} name={i.toString()} label="" checked={!!item.checked} onCheckedChange={(name, value) => handleCheckedChange(item, i, value)} />
                    </td>
                  }
                  <td className={'center'}>
                    <span>{item.bankAccountName}</span>
                  </td>
                  <td className={'center'}>
                    <span>{item.bankAccountNumber}</span>
                  </td>
                  <td className={'center'}>
                    <span>{item.bankName}</span>
                  </td>
                  <td className={'center'}>
                    <CurrencyFormat displayType="text" thousandSeparator="," decimalSeparator="." decimalScale={2} value={item.amount} />
                  </td>
                  <td className={'center'}>
                    <CurrencyFormat displayType="text" thousandSeparator="," decimalSeparator="." decimalScale={2} value={item.balance} />
                  </td>
                  <td className={'center'}>
                    <span>{Moment(item.modifiedDate).format('HH:mm DD/MM/YYYY')}</span>
                  </td>
                  <td className={'center'}>
                    <span dangerouslySetInnerHTML={{ __html: item.transferStatusName && BANK_TRANSFER_STATUS_NAME[item.transferStatusName] ? BANK_TRANSFER_STATUS_NAME[item.transferStatusName] : '' }} />
                  </td>
                  <td className={'center'}>
                    <span>{item.remark}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        );
      let columnCount = columns ? columns.length + 1 : 10000000;
      return (
        <tbody>
          <tr>
            <td colSpan={columnCount} className="center">
              {Language.getValue('common.noRecords')}
            </td>
          </tr>
        </tbody>
      );
    },
    handleClickRow = (rowIndex) => {
      var newDatas = [...datas];
      newDatas[rowIndex].checked = !newDatas[rowIndex].checked;
      setDatas(newDatas);
    },
    handleCheckedChange = (item, idx, value) => {
      var newDatas = [...datas];
      newDatas[idx].checked = value;
      setDatas(newDatas);
      setSelectAll(newDatas.filter((x) => x.checked).length == newDatas.length);
    };

  const handleSaveSucceed = () => {
    setIsShowModal(false);
    setTableGuid(Common.generateGuid());
    getDatas(paging);
  };

  return (
    <React.Fragment>
      <Loading show={busy.isBusy} msg={busy.busyMsg} />
      <Breadcrumbs currentPage="Lịch sử hoàn tiền" parentPage="Rút tiền" hrefParent="/settings" />
      <ul className="nav nav-tabs tabs-bordered">{_renderTabIndex()}</ul>
      <CommonTable data={datas} paging={paging} ref={tableRef} tableGuid={tableGuid} searchBasic onFilter={getDatas} renderAction={renderTableAction} renderHeader={renderTableHeader} renderBody={renderTableBody} />
      <CreditFormModal
        isShowModal={isShowModal}
        bankTransferHistory={bankTransfer}
        dataBankAccounts={bankAccounts}
        managementType={props.managementType}
        onSaveSucceed={handleSaveSucceed}
        onClose={() => {
          setIsShowModal(false), setBankTransfer(new AGE_AgencyCreditModel());
        }}
      />
    </React.Fragment>
  );
};

export default CreditManagement;
