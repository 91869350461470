import React, { useEffect, useState } from "react";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import FinanceServices from "../based/services/FinanceServices";
import NumberFormat from "react-currency-format";
import Textbox from "../based/inputs/Textbox";
import { ConfirmDialog } from "../based/Dialog";

export default function FinanceTranscipts(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [report, setReport] = useState({});
    const [transcripts, setTranscripts] = useState("");

    useEffect(() => {
        document.getElementById("transcripts").focus();
    }, [props.isShowModal]);
    async function _handleSave() {
        if (transcripts && transcripts.length > 0) {
            ConfirmDialog("Xác nhận ", "Bạn chắc chắn xác nhận đối soát này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang lưu dữ liệu...");
                    let [error, data] = await FinanceServices.SaveFinanceMomoTranscripts(transcripts);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xác nhận đối soát thành công." });
                        props.onClose();
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra xác nhận đối soát.");
                    }
                });
            });
        } else {
            document.getElementById("transcripts").focus();
            Notify(NOTIFY.WARNING, "Chú ý", "Không tìm thấy thông tin đối soát");
        }
    }
    async function _handleFilter() {
        if (transcripts && transcripts.length > 0) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, result] = await FinanceServices.FinanceMomoTranscripts(transcripts);
            if (!error) {
                if (result && result.totalOrders > 0) setReport(result);
                else {
                    setReport({});
                    Notify(NOTIFY.WARNING, "Chú ý", "Không tìm thấy thông tin đối soát");
                }
                setIsLoading(false);
                setMsg(null);
            } else {
                setReport({});
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.ERROR, "Lỗi", error.data);
            }
        } else {
            document.getElementById("transcripts").focus();
            Notify(NOTIFY.WARNING, "Chú ý", "Nhập danh sách transcript id cần đối soát");
        }
    }

    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            size='lg'
            title='Đối soát MoMo theo Transcript '
            customButton={
                <React.Fragment>
                    <button className={report.totalOrders > 0 ? "btn btn-custom btn-sm" : "btn btn-custom btn-sm disable"} onClick={() => (report.totalOrders > 0 ? _handleSave() : {})}>
                        <i className='fa fa-save m-r-5' /> Đối soát thành công
                    </button>
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            <div className='form-row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <Textbox name='transcripts' id='transcripts' placeholder='Nhập transcript id (Ví dụ: 1,2,3)' className='form-control' onChanged={(name, value) => setTranscripts(value)} value={transcripts} />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleFilter()}>
                            <i className='fa fa-search m-r-5' /> Tìm kiếm
                        </button>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-xl-4 col-sm-6'>
                    <div className='card-box widget-box-four overview'>
                        <div className='float-left'>
                            <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng số đơn hàng</h4>
                            <h3 className='mb-0 mt-2'>
                                <span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalOrders ? report.totalOrders : 0} />
                                </span>
                            </h3>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
                <div className='col-xl-4 col-sm-6'>
                    <div className='card-box widget-box-four overview'>
                        <div className='float-left'>
                            <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng tiền MoMo Charge</h4>
                            <h3 className='mb-0 mt-2'>
                                <span className='m-r-10'>đ</span>
                                <span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalTransferCharge ? report.totalTransferCharge : 0} />
                                </span>
                            </h3>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
                <div className='col-xl-4 col-sm-6'>
                    <div className='card-box widget-box-four overview'>
                        <div className='float-left'>
                            <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng tiền MoMo thanh toán</h4>
                            <h3 className='mb-0 mt-2'>
                                <span className='m-r-10'>đ</span>
                                <span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalTransferAmount ? report.totalTransferAmount : 0} />
                                </span>
                            </h3>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
