import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { SelectionProps } from '../PropsDefined';
import { CONSTANTS } from '../Constants';

/**
 * selection common input
 * @param {SelectionProps} props selection props
 */
const Selection = (props, ref) => {
  useImperativeHandle(ref, () => ({ displayError, validateInput, props }));

  const [valid, setValid] = useState({ isValid: true, msgInvalid: '' });

  function _handleChange(e) {
    let newValue = e.target.value,
      msgError = validateInput(newValue);
    setValid({ isValid: msgError === '', msgInvalid: msgError });
    props.onChanged(newValue);
  }

  function displayError(msg) {
    setValid({ isValid: false, msgInvalid: msg });
  }

  function validateInput(value) {
    if ((!value || value == -1) && props.required)
      return CONSTANTS.MSG_REQUIRED;
    return '';
  }

  return (
    <React.Fragment>
      <select
        id={props.id}
        name={props.name}
        value={props.value}
        required={props.required}
        onChange={_handleChange}
        className={`form-control${props.className ? ` ${props.className}` : ''}${valid.isValid ? '' : ' alert-danger'}`}
        disabled={props.disabled ? props.disabled : false}
      >
        <option value={-1}>{props.placeholder}</option>
        {
          props.options ?
            props.options.map((item, i) => (
              <option value={item.value} key={i}>
                {item.label}
              </option>
            ))
            : ''
        }
      </select>
      {valid.isValid ? '' : <label className="text-danger full-width" htmlFor={props.id}>{valid.msgInvalid}</label>}
    </React.Fragment>
  );
}
export default forwardRef(Selection);