import React, { useState, useRef, useEffect } from "react";
import Loading from "../../based/Loading";
import Request from "../../based/Request";
import { Notify } from "../../based/Notify";
import Textbox from "../../based/refactor/Textbox";
import TextArea from "../../based/refactor/TextArea";
import { NOTIFY, CONSTANTS } from "../../based/Constants";
import useFormValidate from "../../hooks/useFormValidate";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import LayoutLight from "../../layout/refactor/Layout/Layout";
import { Box, BoxUser, BtnRounded } from "../../based/content/ct.elm";
const menuItem = ["Trang chủ", "UserReferences"];
export default function UserReferencesRefactor(props) {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const { addRef, displayError, isValid } = useFormValidate();
    const [data, setData] = useState(null);
    const [email, setEmail] = useState(null);
    useEffect(() => {
        getUserReferencesInfo();
    }, []);
    function getUserReferencesInfo() {
        setBusy(true);
        setMsg("Đang lấy dữ liệu...");
        Request.Get("/api/accounts/user-references")
            .then((res) => {
                if (res.success) {
                    let data = res.data;
                    setData(data);
                    setBusy(false);
                    setMsg(null);
                } else {
                    setBusy(false);
                    setMsg(null);
                }
            })
            .catch((err) => {
                setBusy(false);
                setMsg(null);
            });
    }
    function _handleSave() {
        if (isValid()) {
            setBusy(true);
            setMsg("Đang lưu dữ liệu...");
            Request.Post(`/api/accounts/save-user-reference/${email}`, null)
                .then((res) => {
                    if (res.success) {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật thành công");
                        getUserReferencesInfo();
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                    else Notify(NOTIFY.ERROR, "Lỗi", err.message);
                });
        }
    }
    function _handleCopyLink(e) {
        var copyText = document.getElementsByName("linkShared");
        if (copyText) {
            copyText[0].select();
            copyText[0].setSelectionRange(0, 99999);
            document.execCommand("copy");
        }
    }
    return (
        <React.Fragment>
             <Breadcum menu={menuItem} title='UserReferences' /> 
            <Loading show={isBusy} msg={msg} />
             <Box style={{'height':'85%'}}>
                 <BoxUser>
                 <div className='card-box col-12 col-lg-8 offset-md-0 p-3' style={{'maxHeight':'125px'}}>
                <h4 className='header-title m-t-0'>
                    <b>Link giới thiệu</b>
                </h4>
                <TextArea className='linkShared form-control h-60' name='linkShared' readOnly value={data ? data.linkShared : null} />
                <button className='btn btn-custom btn-sm' title='Copy' onClick={_handleCopyLink} style={{ position: "absolute", right: 16, top: 41 }}>
                    <i className='fa fa-clipboard'></i>
                </button>
            </div>
            <div className='card-box col-12 col-lg-8 offset-md-0 p-3' style={{'maxHeight':'100px'}} >
                <h4 className='header-title m-t-0'>
                    <b>được giới thiệu bởi</b>
                </h4>
                {data && data.userReferences ? (
                    <div className='member-card-alt'>
                        <div className='avatar-xxl member-thumb float-left'>
                            <img src={data.userReferences.avatar ? data.userReferences.avatar : Request.CdnURL() + "/images_system/no-image.png"} className='img-thumbnail' alt='profile-image' />
                        </div>
                        <div className='member-card-alt-info'>
                            <h4 className='mb-2 mt-0'>{data.userReferences.fullName}</h4>
                            <div className='text-left'>
                                <p className='text-muted font-13'>
                                    <strong>Điện thoại :</strong> <span className='ml-3'>{data.userReferences.phoneNumber}</span>
                                </p>
                                <p className='text-muted font-13'>
                                    <strong>Email :</strong> <span className='ml-3'>{data.userReferences.email}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className='form-row m-l-5'>
                            <div className='row col-10'>
                                <Textbox value={email} name='email' required ref={addRef} placeholder='Nhập email người giới thiệu' email maxLength={255} className='form-control' onChanged={(name, value) => setEmail(value)} />
                            </div>
                            <div className='col-2'>
                            <BtnRounded color='#34d3eb' style={{width:'100%'}} onClick={() => _handleSave()}>
                                &nbsp; Lưu &nbsp;
                            </BtnRounded>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
            {data && data.introducedPerson && data.introducedPerson.length > 0 ? (
                <div className='card-box col-12 col-lg-8 offset-md-2 p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>đã giới thiệu cho</b>
                    </h4>
                    <div className='col-sm-12 p-0'>
                        <div className='row'>
                            {data.introducedPerson.map((item, i) => {
                                return (
                                    <div className='col-lg-4' key={i}>
                                        <div className='card-box p-3 member-card'>
                                            <div className='member-card-alt'>
                                                <div className='avatar-xxl member-thumb float-left'>
                                                    <img src={item.avatar ? item.avatar : Request.CdnURL() + "/images_system/no-image.png"} className='img-thumbnail' alt='profile-image' />
                                                </div>
                                                <div className='member-card-alt-info'>
                                                    <h4 className='mb-2 mt-0'>{item.fullName}</h4>
                                                    <p className='text-muted'>{item.confirmedEmail ? <span className='badge badge-success'>Đang hoạt động</span> : <span className='badge badge-warning'>Chưa kích hoạt</span>}</p>
                                                    <div className='text-left'>
                                                        <p className='text-muted font-13'>
                                                            <strong>Điện thoại :</strong> <span className='ml-3'>{item.phoneNumber}</span>
                                                        </p>
                                                        <p className='text-muted font-13'>
                                                            <strong>Email :</strong> <span className='ml-3'>{item.email}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : null}
                 </BoxUser>
             </Box>
        </React.Fragment>
    );
}
