import PropTypes from 'prop-types';
import React from 'react';

export default function Breadcrumbs(props) {
    return (
        <div className='row'>
            <div className='col-12 h-65px'>
                <div className='page-title-box p-x-25'>
                    <h4 className='page-title float-left'>{props.currentPage}</h4>
                    <ol className='breadcrumb float-right'>
                        {
                            props.parentPage ?
                                <React.Fragment>
                                    <li className='breadcrumb-item'><a href='/'>RANUS</a></li>
                                    <li className='breadcrumb-item'><a href={props.hrefParent ? props.hrefParent : '#'}>{props.parentPage}</a></li>
                                </React.Fragment>
                                : <li className='breadcrumb-item'><a href='/'>RANUS</a></li>
                        }
                        <li className='breadcrumb-item'><a>{props.currentPage}</a></li>
                    </ol>
                    <div className='clearfix' />
                </div>
            </div>
        </div>
    )
}

Breadcrumbs.propTypes = {
    currentPage :  PropTypes.string,
    parentPage : PropTypes.string,
    hrefParent : PropTypes.string
}
