import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ArtistServices from "../based/services/ArtistServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import Request from "../based/Request";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import styled from "styled-components";
import TFUSelect from "../based/refactor/TFUSelect";
import { folderTypeOption } from "./ArtistDetail";


function ModalAddOrUpdate(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [folder, setFolder] = useState({})
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(()=>{
        setFolder({...props.folder})
    },[])

    
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await ArtistServices.CreateUpdateArtist({ ...folder});
            if (!err) {
                setIsBusy(false);
                setFolder({});
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                
            }
            props.onSaveContinue();
        }
       
    };

    return ( 
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Nhập tên folder
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control'
                 onChanged={(name, value) => setFolder({ ...folder, name: value })} 
                 value={folder.name} />
                <label htmlFor='type' className='required'>
                    Loại folder
                </label>
                <TFUSelect
                    className={"select"}
                    id="packageType"
                    name="packageType"
                    placeholder="Chọn loại folder"
                    options={folderTypeOption}
                    value={folder.folderType}
                    isClearable={false}
                    onChanged={(value) => {
                        setFolder({ ...folder, folderType: value })
                    }}
                />
            </div>
        </CommonModal>
    );
}

export default ModalAddOrUpdate;
ModalAddOrUpdate.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};