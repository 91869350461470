import React from "react";
import Language from "../../based/Language";
import NumberFormat from "react-currency-format";
import { PLATFORM } from "../../based/Constants";
function StoreItem(props) {
  return (
    <div className="text-left-wrap col-md-3" style={{ marginBottom: 10 }}>
      <div className="row border m-l-5 m-r-5 m-b-10 h-100">
        <div className="text-center col border-bottom" style={{ whiteSpace: "normal" }}>
          <div className="row">
            <a className="col-12" onClick={() => {
              // if (props.history) props.history.push(`/inventory-manage?id=${props.data.id}`)
              // else window.location.href = `/inventory-manage?id=${props.data.id}`
            }} >
              <i className="fa fa-database"></i> {props.data.name}
              {
                props.data.platform == PLATFORM.Lazada && props.data.warehouseCode && props.data.warehouseCode.length > 0 &&
                <strong> ({props.data.warehouseCode})</strong>
              }
              {
                props.data.phoneNumber && props.data.phoneNumber.length > 0 ?
                  <span> (  {props.data.phoneNumber} )</span>
                  : null
              }
              {
                props.data.formatAddress ?
                  <p><i className="fa fa-map-marker"></i> {props.data.formatAddress}</p>
                  : null
              }
            </a>
            <span style={{ position: "absolute", right: 10, bottom: 0 }}>
              {
                props.isMulPlatform ?
                  <strong>
                    {props.data.platformName} {props.data.shopName ? <span>- {props.data.shopName}</span> : null}
                  </strong>
                  : null
              }
              {props.data.ghnShopId ?
                <strong>
                  | GHN: {props.data.ghnShopId}
                </strong> : null
              }
            </span>
          </div>
        </div>
        <div className="w-100"></div>
        <div className="col m-b-5">
          <div className="row">
            <div className="col-12">
              <span>Tổng số sản phẩm trong kho: </span>
              <b>
                <NumberFormat
                  thousandSeparator=','
                  displayType={'text'}
                  decimalSeparator='.'
                  decimalScale={2}
                  value={props.data.totalQuantity}
                />
              </b>

            </div>
            <div className="col-12 m-b-5" style={{ position: "absolute", right: 0, bottom: -5 }}>
              <button className="btn btn-xs btn-custom float-right" onClick={() => { props.onEdit(); }}  >
                <i className="fa fa-edit m-r-5" />
                {Language.getValue("tableAction.edit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StoreItem;
