import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { NOTIFY ,CONSTANTS} from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import {BANNER_POSITION , BANNER_PAGE , BANNER_TYPE , isValidImage} from "./BannerManagement";
import Request from "../based/Request";
import AppBannerServices from "../based/services/AppBannerServices";

function BannerTrademarkManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData]= useState([])


    useEffect(() =>{
        getData()
    },[])

    async function getData() {
        setIsLoading(true);
        let [err, data] = await AppBannerServices.GetBanner(BANNER_PAGE.TRADEMARK);
        if (!err && data) {
            setData(data);
            setIsLoading(false);
        } else {
            setData([]);
            setIsLoading(false);
        }
    }


    const createBanner = async(position, type)=>{
        setIsLoading(true)
        let [err, data] = await AppBannerServices.CreateUpdateBanner({ 
            id: 0,
            redirectUrl: "",
            imageUrl: "",
            bannerType: type,
            pageType: BANNER_PAGE.TRADEMARK,
            position: position,
            bannerIndex: 0
        });
        if (!err && data) {
            getData()
            setIsLoading(false)
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsLoading(false)
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    const renderBanner = (position , type) =>{
        return data && data.length > 0 && data.map((item, idx)=>{
            if(item.position == position && item.bannerType == type){
                return (
                    <div key={idx} className="banner-col col-6">
                        <BannerItem item={item} idx={idx} getData={getData} setIsLoading={setIsLoading} isShowText={true} />
                    </div>
                )
            }
        })
    }
   
    

    const renderBannerJustImage = (position , type) =>{
        let dataBanner
        let idx
        data.forEach((item, index)=>{
            if(item.position == position && item.bannerType == type){
               dataBanner = {...item}
               idx = index
            }
        })
        if(dataBanner && idx){
            return <BannerItem item={dataBanner} idx={idx} getData={getData} setIsLoading={setIsLoading} isShowText={false} />
        }else{
            return <ChooseImage position={position} type={type} page={BANNER_PAGE.TRADEMARK} getData={getData} setIsLoading={setIsLoading}/>
        }
    }



    return ( 
        <Wapper>
            <Loading show={isLoading} />
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Home page</div>
                    <div className="mb-2">Desktop (970x407, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (710x285, 72ppi, {"<"} 500mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Homepage Promotions</div>
                    <div className="mb-2">Desktop (480x190, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.TOP_PROMOTION, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.TOP_PROMOTION, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (480x190, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.TOP_PROMOTION, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.TOP_PROMOTION, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Middle Promotions</div>
                    <div className="mb-2">Desktop (705x300, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.MIDDLE_PROMOTION, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.MIDDLE_PROMOTION, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (705x300, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.MIDDLE_PROMOTION, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.MIDDLE_PROMOTION, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Voucher</div>
                    <div className="mb-2">Desktop (710x285, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.VOUCHER, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.VOUCHER, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (710x285, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.VOUCHER, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.VOUCHER, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Best Deal</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1440x300, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.BEST_DEAL, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (820x410, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.BEST_DEAL, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">New Design</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1440x300, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.NEW_DESIGN, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (820x410, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.NEW_DESIGN, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Best Seller</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1440x300, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.BEST_SELLER, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (820x410, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.BEST_SELLER, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Hot Collection</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1440x300, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.HOT_COLLECTION, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (820x410, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.HOT_COLLECTION, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Design Style</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1440x300, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.DESIGN_STYLE, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (820x704, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.DESIGN_STYLE, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Hot Creator</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1440x300, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.HOT_CREATOR, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (820x410, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.HOT_CREATOR, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
        </Wapper>
    );
}
export function BannerItem({item, idx, getData , setIsLoading , isShowText}){
    const [dataItem, setDataItem] = useState({...item})

    useEffect(()=>{
        setDataItem({...item})
    },[item])
    function CheckDate(){
        if(item.startDate && item.endDate){
            return true
        }else
            return false
    }

    const removeBanner = async () =>{
        ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chán muốn xóa banner này?", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                let [error, data] = await AppBannerServices.DeteleBanner(item.id);
                if (!error && data) {
                    setIsLoading(false);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getData();
                } else {
                    setIsLoading(false);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }


    const handleEdit = async(banner) => {
        setIsLoading(true)
        let [err, data] = await AppBannerServices.CreateUpdateBanner({ 
            ...banner,
        });
        if (!err && data) {
            getData()
            setIsLoading(false)
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsLoading(false)
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    
    

    const updateBanner = async (e) =>{
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            const isValid = await isValidImage(file, item.pageType , item.position , item.bannerType);
            if(isValid){
                reader.onloadend = () => {
                    Request.UploadImage(file)
                        .then((res) => {
                            if (res && res.data) {
                                handleEdit({...item, imageUrl: res.data.imageUrl})
                            } else {
                                let errMsg =
                                    res.errors && res.errors.length > 0
                                        ? res.errors.reduce((prev, item, idx) => {
                                                return `${prev}${item.value}<br/>`;
                                            }, "")
                                        : "" || CONSTANTS.MSG_ERROR;
                                Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                            }
    
    
                        })
                        .catch((err) => {
    
    
                        });
                };
    
                reader.readAsDataURL(file);
            }
        }

    }

    const isDate = ()=>{
        if(dataItem.startDate && dataItem.endDate ){
            let start = new Date(dataItem.startDate);
            let end = new Date(dataItem.endDate);
            let now = new Date()
            now.setHours(0)
            now.setMinutes(0)
            now.setSeconds(0)
            now.setMilliseconds(0)
            if( start >= now && start <= end)
                return true
            else{
                Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Ngày bắt đầu kể từ hôm nay và trước hoặc bằng ngày kết thúc");
                return false
            }
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Vui lòng chọn ngày");
            return false
        }
    }

    const formatDate = (time) =>{
        if(time){
            var newDate = new Date(time);
            var date = newDate.getDate();
            var month = newDate.getMonth() + 1; 
            var year = newDate.getFullYear();
            if (date < 10) {
            date = '0' + date;
            }
            if (month < 10) {
            month = '0' + month;
            }
            return year + '-' + month + '-' + date;
        }
        return null
        
    }
    return (
        <div className="banner-item">
            <div className="remove" onClick={()=>{
                removeBanner()
            }}>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1L1 12" stroke="#F96A74" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M12 12L1 0.999999" stroke="#F96A74" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
            </div>
            <label htmlFor={`choose-img-${idx}`} className="box-image">
                {item.imageUrl && <img src={item.imageUrl}/>}
                <input type="file" accept="image/*" className={item.imageUrl ? "input-img hidden" : "input-img"} id={`choose-img-${idx}`} onChange={(e)=>{
                    updateBanner(e)
                }}/>
            </label>
            
            {isShowText &&
            <div className="box-text">
            <h4>Thời gian xuất hiện </h4>
            <div className="box-time">
                <div className="wbox20">
                    <CheckBox id={`is-infinity-${idx}`} name={`is-infinity-${idx}`} checked={!CheckDate()} label=' ' onCheckedChange={(name, value) => {
                        setDataItem({...dataItem, startDate: null, endDate: null})
                        handleEdit({...item,
                             startDate: null,
                             endDate: null,
                        })
                    }} />
                </div>
                <span className="wbox80"><strong>Không giới hạn</strong></span>
            </div>
            <div className="box-time">
                <div className="wbox20">
                    <CheckBox id={`is-no-infinity-${idx}`} name={`is-no-infinity-${idx}`} checked={CheckDate()} label=' ' onCheckedChange={(name, value) => {
                        if(isDate()){
                            handleEdit({...item, startDate: dataItem.startDate, endDate: dataItem.endDate})
                        }
                    }} />
                </div>
                <div className="wbox80">
                    <span className="d-flex">
                        <label>Từ </label>
                        <input type="date" name="start-date" value={dataItem.startDate ? formatDate(dataItem.startDate): ""} onChange={(e)=>{
                            setDataItem({...dataItem, startDate: e.target.value})
                        }} />
                    </span>
                    <span className="d-flex">
                        <label>Đến</label>
                        <input type="date" name="end-date" value={dataItem.endDate ?formatDate(dataItem.endDate) : ''} 
                        onChange={(e)=>{
                            setDataItem({...dataItem, endDate: e.target.value})
                        }}/>
                    </span>
                </div>
            </div>
            <div>
                <h4>Link {"(enter để thay đổi)"}</h4>
                <input type="text" value={dataItem.redirectUrl} onChange={(e)=>{
                    setDataItem({...dataItem, redirectUrl: e.target.value})
                }} onKeyPress={(e)=>{
                    if(e.charCode === 13){
                        handleEdit({...item, redirectUrl: e.target.value})
                    }
                }} />
            </div>
        </div>
            }
        </div> 
    )
}

export function ChooseImage({position , type , page, getData, setIsLoading}){

    const AddBanner = async(img)=>{
        setIsLoading(true)
        let [err, data] = await AppBannerServices.CreateUpdateBanner({ 
            id: 0,
            redirectUrl: "",
            imageUrl: img,
            bannerType: type,
            pageType: page,
            position: position,
            bannerIndex: 0
        });
        if (!err && data) {
            getData()
            setIsLoading(false)
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsLoading(false)
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    const changeImageButton = async(e)=>{
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            const isValid = await isValidImage(file, page , position , type);
            if(isValid){
                reader.onloadend = () => {
                    Request.UploadImage(file)
                        .then((res) => {
                            if (res && res.data) {
                                AddBanner(res.data.imageUrl)
                            } else {
                                let errMsg =
                                    res.errors && res.errors.length > 0
                                        ? res.errors.reduce((prev, item, idx) => {
                                                return `${prev}${item.value}<br/>`;
                                            }, "")
                                        : "" || CONSTANTS.MSG_ERROR;
                                Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                            }


                        })
                        .catch((err) => {


                        });
                };

                reader.readAsDataURL(file);
            }
        }
    }

    return (
        <div className="banner-item">
            <div className="box-image">
                <input type="file" id="choose-img-add" accept="image/*" className="" onChange={(e)=>{
                    changeImageButton(e)
                }}/>
            </div>
        </div> 
    )
}



export const Wapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    .input-img{
        width: 75px;
    }
    .hidden{
        display: none;
    }

    .box-banner{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 0 5px;
        margin-bottom: 15px;

        &.box3{
            width: 33.3333%;
            min-width: 33.3333%;
            max-width: 33.3333%;
        }

        .box-banner-item{
            padding: 15px;
            width: 100%;
            background-color: #fff;
            
            .title{
                font-size: 14px;
                font-weight: bold;
                color: #676767;
                margin-bottom: 10px;
            }
            .banner-list{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -13px;

                .banner-col{   
                    padding: 0 13px;       
                    &.col-6{
                        width: 16.666666%;
                        min-width: 16.666666%;
                        max-width: 16.666666%;
                    }
                    &.col50{
                        width: 50%;
                        min-width: 50%;
                        max-width: 50%;
                    }

                    &.col-33{
                        width: 33.333333%;
                        min-width: 33.333333%;
                        max-width: 33.333333%;
                    }

                    .box-add{
                        margin-bottom: 20px;
                        width: 100%;
                        height: 200px;

                        .btn-add{
                            border: none;
                            outline: none;
                            font-weight: bold;
                            color: #64C5B1;
                            cursor: pointer;
                            background: transparent;
                        }
                    }
                    
                    .banner-item{
                        margin-bottom: 20px;
                        width: 100%;
                        border: 1px solid #ccc;
                        position: relative;
                        padding: 0 !important;


                        .box-image{
                            display: grid;
                            place-items: center;
                            width: 100%;
                            aspect-ratio: 1/1;
                            background: #D9D9D9;
                            cursor: pointer;
                            
                            img{
                                width: 100%;
                                aspect-ratio: 1/1;
                                object-fit: contain;
                            }

                            input[type="file"]{
                                width: 75px;
                            }
                        }
                        .box-text{
                            padding: 0 10px 10px;
                            h4{
                                font-size: 12px;
                                font-weight: bold;
                                color: #676767;
                            }

                            .box-time{
                                display: flex;
                                margin-bottom: 10px;
                                .wbox20{
                                    width: 20%;
                                    min-width: 20%;
                                }
                                .wbox80{
                                    width: 80%;
                                    min-width: 80%;


                                    label{
                                        width: 35px;
                                    }

                                    input{
                                        width: calc(100% - 40px);
                                        margin-bottom: 5px;
                                    }

                                }
                            }

                            input{
                                width: 100%;
                                
                            }

                        }

                        .remove{
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            cursor: pointer;
                        }

                    }


                
                }
            }
        }


        
    }

    @media (max-width: 1440px){
        .box-banner{
           
            .banner-list{
               
    
                .banner-col{
                  
                    
                   
                }
            }
        }
    }

`

export default BannerTrademarkManagement;