import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ConfirmDialog } from "../based/Dialog";
import TFUSelect from "../based/inputs/TFUSelect";
import TextNumber from "../based/inputs/TextNumber";

const initPaging = {
    pageNumber: 1,
    pageSize: 20,
    keyword: "",
};

const SaleType = {
    Undefined: 0,
    FixedPrice: 1,
    Percentage: 2,
};

const SaleTypeOptions = [
    {
        value: SaleType.FixedPrice,
        label: "Giá cố định",
    },
    {
        value: SaleType.Percentage,
        label: "Phần trăm",
    },
];

const DealHighLight = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getCampaignHighLight: () => {
            return campaignHighLight;
        },
    }));

    const [campaignHighLight, setCampaignHighLight] = useState(props.dealHighLight);
    const [isFetching, setFetching] = useState(false);
    const [products, setProducts] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [paging, setPaging] = useState(initPaging);

    useEffect(() => {
        setCampaignHighLight(props.dealHighLight);
    }, [props.dealHighLight]);

    const _handleClickCardHeader = (idx) => {
        let idCard = "deal" + idx;
        document.getElementById(idCard).classList.toggle("hide");
    };

    const _handleRemoveDeal = (deal) => {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa Deal đã chọn?", () => {
            return new Promise((resolve, reject) => {
                let dealUpdate = [...campaignHighLight];
                if (dealUpdate && dealUpdate.length > 0) {
                    dealUpdate.map((item, i) => {
                        if (item.id == deal.id) dealUpdate.splice(i, 1);
                    });
                }
                setCampaignHighLight(dealUpdate);
                resolve({ title: "Thành công", msg: "Xóa thành công." });
            });
        });
    };

    const _handleChangeData = (idx, key, value) => {
        let dealUpdate = [...campaignHighLight];
        if (dealUpdate && dealUpdate.length > 0) {
            let item = dealUpdate[idx];
            item[key] = value;
        }
        setCampaignHighLight(dealUpdate);
    };

    const _renderDealItem = (deal, idx) => {
        idx = idx ? idx : 0;
        return (
            <div
                key={idx}
                className="form-group"
                style={{
                    marginBottom: "50px",
                }}
            >
                <div className="card bg-transparent">
                    <div className="card-header" onClick={() => _handleClickCardHeader(idx)}>
                        <h6 className="m-0">
                            Deal {idx + 1}
                            {campaignHighLight && campaignHighLight.length > 1 ? <i className="fa fa-times pull-right" onClick={() => _handleRemoveDeal(deal)}></i> : null}
                        </h6>
                    </div>
                    <div className="card-body" id={"deal" + idx}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="title" className="col-form-label">
                                    Giá trị đơn hàng tối thiểu
                                </label>
                                <TextNumber name="minimumOrderValue" value={deal.minimumOrderValue} onChanged={(name, value) => _handleChangeData(idx, name, +value)} suffix="VNĐ" />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="index" className="col-form-label">
                                    Loại giảm giá
                                </label>
                                <TFUSelect
                                    options={SaleTypeOptions}
                                    placeholder="--Chọn loại giảm giá--"
                                    value={deal.saleType}
                                    onChanged={(value) => _handleChangeData(idx, "saleType", value)}
                                    styles={{
                                        zIndex: 9999,
                                    }}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                {deal.saleType == SaleType.FixedPrice ? (
                                    <React.Fragment>
                                        <label htmlFor="saleValue" className="col-form-label">
                                            Giá trị giảm giá
                                        </label>
                                        <TextNumber name="saleValue" value={deal.saleValue} onChanged={(name, value) => _handleChangeData(idx, name, +value)} suffix="VNĐ" />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <label htmlFor="saleValue" className="col-form-label">
                                            Phần trăm giảm giá
                                        </label>
                                        <TextNumber name="saleValue" value={deal.saleValue} onChanged={(name, value) => _handleChangeData(idx, name, +value)} min={1} max={100} suffix={"%"} />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const _handleAddDeal = () => {
        let dealHighLightUpdate = [...campaignHighLight];
        let dealItem = {
            id: 0,
            campaignId: 0,
            saleType: SaleType.Undefined,
            saleValue: 0,
            minimumOrderValue: 0,
        };
        dealHighLightUpdate.push(dealItem);
        setCampaignHighLight(dealHighLightUpdate);
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <button className="btn btn-success " onClick={_handleAddDeal}>
                    <i className="fa fa-plus"></i>
                    <span> Thêm deal nổi bật</span>
                </button>
            </div>
            {campaignHighLight && campaignHighLight.length > 0
                ? campaignHighLight.map((deal, k) => {
                      return _renderDealItem(deal, k);
                  })
                : _renderDealItem()}
        </React.Fragment>
    );
});

export default DealHighLight;
