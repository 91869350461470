import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../../based/Common";
import CommonTable from "../../based/CommonTable";
import { INITPAGING, NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import CheckBox from "../../based/inputs/CheckBox";
import Loading from "../../based/Loading";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import BlogServices from "../../based/services/BlogServices";
import ProductServices from "../../based/services/ProductServices";
import useFormValidate from "../../hooks/useFormValidate";

const ConfigBlogTags = (props) => {
    const [msg, setMsg] = useState("Đang tải...");
    const [isBusy, setIsBusy] = useState(false);
    const [categories, setCategories] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [keywords, setKeywords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [listBackLink, setListBackLink] = useState([]);
    const { addRef, displayError, isValid } = useFormValidate();
    const [listProductReference, setListProductReference] = useState([]);
    const [getProductLoading, setGetProductLoading] = useState(false);
    const [keywordSelected, setKeywordSelected] = useState("");
    const [keywordPaging, setKeywordPaging] = useState({ ...INITPAGING, keyword: "" });
    const [tags, setTags] = useState([]);

    useEffect(() => {
        getKeywords(paging);
    }, []);
    useEffect(() => {
        getListReferenceProduct({ ...keywordPaging, keyword: keywordSelected });
    }, []);
    useEffect(() => {
        if (props.blog.tags && props.blog.tags != "") setTags(props.blog.tags.split(","));
        return () => {
            setTags([]);
        };
    }, [props.blog.tags]);

    const getKeywords = async (paging) => {
        setIsLoading(true);
        let [err, data] = await BlogServices.GetBlogKeywords(paging);
        if (!err && data) {
            setIsLoading(false);
            setKeywords(data.listObjects);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setKeywords([]);
            setPaging({ ...INITPAGING });
        }
    };
    const getListReferenceProduct = async (paging) => {
        setGetProductLoading(true);
        let [err, data] = await ProductServices.GetProductByKeyWord(paging);
        if (!err && data) {
            setGetProductLoading(false);
            setListProductReference([...data.listObjects]);
            setKeywordPaging(data.paging);
            setKeywordSelected(data.paging.keyword);
        } else {
            setGetProductLoading(false);
            setListProductReference([]);
            setKeywordPaging({ ...INITPAGING, keyword: "" });
        }
    };
    function search(keyword) {
        let textToSearch = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        let pattern = new RegExp(`${textToSearch}`, "gi");
        let paragraph = document.getElementById("paragraph");
        paragraph.innerHTML = paragraph.innerHTML.replace(pattern, (match) => `<mark>${match}</mark>`);
    }

    function decodeEntity(inputStr) {
        var textarea = document.createElement("textarea");
        textarea.innerHTML = inputStr;
        return textarea.value;
    }

    function findLocationCharacter(substring, string) {
        var a = [],
            i = -1;
        while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
        return a;
    }
    const handleCountKeywords = (keywords) => {
        var result = [];
        if (keywords && keywords.length > 0) {
            keywords.map((item) => {
                let temp = findLocationCharacter(item.name, decodeEntity(props.blog.content));
                if (temp && temp.length) {
                    result.push({ keyword: item.name, count: temp.length, level: item.level });
                }
            });
        }
        return result;
    };
    const keywordIsTag = (keyword) => {
        let result = false;
        if (tags.length > 0) {
            tags.map((item) => {
                if (item == keyword) result = true;
            });
        }
        return result;
    };
    const _handleChecked = (keyword) => {
        if (keyword) {
            if (keywordIsTag(keyword)) {
                ConfirmDialog("Xác nhận?", "Từ khóa đã được thêm vào tag, xác nhận xóa ?", () => {
                    return new Promise(async (resolve, reject) => {
                        let newTags = [];
                        tags.map((item) => {
                            if (item != keyword) {
                                newTags.push(item);
                            }
                        });
                        setTags(newTags);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                    });
                });
            } else {
                getListReferenceProduct({ ...keywordPaging, keyword: keyword });
                if (tags && tags.length >= 3) {
                    Notify(NOTIFY.WARNING, "Cảnh báo", "Tối đa 3 tag");
                } else {
                    setTags([...tags, keyword]);
                }
            }
        } else {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Nhập từ khóa");
        }
    };
    const handleOnSave = () => {
        props.setBlog({ ...props.blog, tags: tags.toString() });
        props.onClose();
    };
    const renderHeaderKeyword = () => {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        Chọn
                    </th>
                    <th width='' className='text-center'>
                        Từ khóa
                    </th>
                    <th>Level</th>
                    <th>Số lượng</th>
                </tr>
            </thead>
        );
    };

    const renderBodyKeyword = () => {
        return (
            <tbody>
                {handleCountKeywords(keywords).length > 0 ? (
                    handleCountKeywords(keywords).map((item, idx) => (
                        <tr
                            key={idx}
                            onClick={() => {
                                setKeywordSelected(item.keyword);
                                getListReferenceProduct({ ...keywordPaging, keyword: item.keyword });
                            }}
                        >
                            <td>
                                {" "}
                                <CheckBox id={idx.toString()} name={idx.toString()} checked={keywordIsTag(item.keyword)} label=' ' onCheckedChange={() => _handleChecked(item.keyword)} />
                            </td>
                            <td>{item.keyword}</td>
                            <td>{item.level}</td>
                            <td>{item.count}</td>
                        </tr>
                    ))
                ) : (
                    <tr key=''>
                        <td colSpan={4}> Không có keyword</td>
                    </tr>
                )}
            </tbody>
        );
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} size='lg' isBusy={isBusy} busyMsg={msg} title='Thiết lập từ khóa liên quan' onSave={handleOnSave} onClose={props.onClose}>
            <Wrapper>
                <Loading show={isLoading} msg='Đang tải' />
                <div className='seo-information d-flex'>
                    {/* <div className='w-50'>
                        <CommonTable renderHeader={renderHeader} renderBody={renderBody} classDivTable='table__fix__height' />
                    </div> */}

                    <div className='w-50'>
                        <div className='title'>Danh sách từ khóa</div>
                        <CommonTable renderHeader={renderHeaderKeyword} renderBody={renderBodyKeyword} classDivTable='table__fix__height ' />
                        <div className='list-tag'>
                            <div className='title'>Danh sách tag:</div>
                            <div className='d-flex'>
                                {tags && tags.length > 0
                                    ? tags.map((item, idx) => (
                                          <div key={idx} className='d-flex justify-content-between align-items-center p-1  m-2'>
                                              <span className='d-inline-block text-truncate mr-2 pt-1' style={{ maxWidth: "120px" }}>
                                                  {item}
                                              </span>{" "}
                                              <i className='fa fa-times-circle cursor-pointer text-danger pb-2' aria-hidden='true' onClick={() => _handleChecked(item)}></i>
                                          </div>
                                      ))
                                    : "Chưa có tag cho bài viết "}
                            </div>
                        </div>
                        {/* <div className='list-tag'>
                            <div className='title'>SEO Url:</div>
                            <div className='d-flex'>
                                
                            </div>
                        </div> */}
                    </div>
                    <div className='d-flex flex-column w-50 ml-3'>
                        <div className='title'>Từ khóa:</div>
                        <div className='filter-product-wrapper d-flex flex-column card-box p-2'>
                            {" "}
                            <div className='d-flex justify-content-between'>
                                <span className='btn btn-success' onClick={() => _handleChecked(keywordSelected)}>
                                    Thêm tag <i className='fa fa-plus' aria-hidden='true'></i>
                                </span>{" "}
                                <div className='form-inline'>
                                    <div className='form-group'>
                                        <input type='text' placeholder='Từ khóa' className='form-control' value={keywordSelected} onChange={(e) => setKeywordSelected(e.target.value)} />
                                        <button className='btn btn-default btn-sm ml-3' onClick={() => getListReferenceProduct({ ...keywordPaging, keyword: keywordSelected })}>
                                            <i className='fa fa-search m-r-5' /> Tìm kiếm
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='search-result-container d-flex flex-column'>
                                {getProductLoading ? (
                                    <Loading show={getProductLoading} msg='Đang tìm kiếm...' />
                                ) : (
                                    <React.Fragment>
                                        {listProductReference && listProductReference.length > 0
                                            ? listProductReference.map((product, index) => (
                                                  <div key={index} className='d-flex justify-content-between p-3'>
                                                      <div className='product-avatar' style={{ width: 100 }}>
                                                          {" "}
                                                          <img src={product.suggestedVariantAvatar} alt={product.suggestedVariantName} />
                                                      </div>
                                                      <div className='product-name'>{product.suggestedVariantName}</div>
                                                      <div className='total-sell'> đã bán: {product.totalSell}</div>
                                                  </div>
                                              ))
                                            : "Chưa có sản phẩm phù hợp"}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='link-infomation '></div>
            </Wrapper>
        </CommonModal>
    );
};

const Wrapper = styled.div`
    .filter-product-wrapper {
        min-height: 450px;
        position: relative;
    }
    .product-avatar {
        width: 100px;
        img {
            width: 100%;
        }
    }
    .search-result-container {
        max-height: 400px;
        overflow-y: auto;
        margin-top: 10px;
    }
`;
export default ConfigBlogTags;
