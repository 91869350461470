import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CheckBox from "../based/inputs/CheckBox";
import { Wapper, BannerItem , ChooseImage } from "./BannerTrademarkManagement";
import {BANNER_POSITION , BANNER_PAGE , BANNER_TYPE} from "./BannerManagement";
import AppBannerServices from "../based/services/AppBannerServices";

function BannerHomeShopManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData]= useState([])


    useEffect(() =>{
        getData()
    },[])

    async function getData() {
        setIsLoading(true);
        let [err, data] = await AppBannerServices.GetBanner(BANNER_PAGE.SHOP);
        if (!err && data) {
            setData(data);
            setIsLoading(false);
        } else {
            setData([]);
            setIsLoading(false);
        }
    }
    const createBanner = async(position, type)=>{
        setIsLoading(true)
        let [err, data] = await AppBannerServices.CreateUpdateBanner({ 
            id: 0,
            redirectUrl: "",
            imageUrl: "",
            bannerType: type,
            pageType: BANNER_PAGE.SHOP,
            position: position,
            bannerIndex: 0
        });
        if (!err && data) {
            getData()
            setIsLoading(false)
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsLoading(false)
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    const renderBanner = (position , type) =>{
        return data && data.length > 0 && data.map((item, idx)=>{
            if(item.position == position && item.bannerType == type){
                return (
                    <div key={idx} className="banner-col col-6">
                        <BannerItem item={item} idx={idx} getData={getData} setIsLoading={setIsLoading} isShowText={true} />
                    </div>
                )
            }
        })
    }
   
    

    const renderBannerJustImage = (position , type) =>{
        let dataBanner
        let idx
        data.forEach((item, index)=>{
            if(item.position == position && item.bannerType == type){
               dataBanner = {...item}
               idx = index
            }
        })
        if(dataBanner && idx){
            return <BannerItem item={dataBanner} idx={idx} getData={getData} setIsLoading={setIsLoading} isShowText={false} />
        }else{
            return <ChooseImage position={position} type={type} page={BANNER_PAGE.SHOP} getData={getData} setIsLoading={setIsLoading}/>
        }
    }

    return ( 
        <Wapper>
            <Loading show={isLoading} />
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Home page</div>
                    <div className="mb-2">Desktop (1920x720, 72dpi, {"<"} 2Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (720x460, 72ppi, {"<"} 500mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.HOMEPAGE, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">New Product</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1500x270, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.NEW_PRODUCT, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (390x230, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.NEW_PRODUCT, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Combo</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1500x270, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.COMBO, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (390x230, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.COMBO, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner box3">
                <div className="box-banner-item">
                    <div className="title">Bộ sưu tập</div>
                    <div className="banner-list">
                        <div className="banner-col col50">
                            <div className="mb-2">Desktop (1500x270, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.COLLECTION, BANNER_TYPE.DESKTOP)}
                        </div>
                        <div className="banner-col col50">
                            <div className="mb-2">Mobile (390x230, 72ppi, {"<"} 1Mb)</div>
                            {renderBannerJustImage(BANNER_POSITION.COLLECTION, BANNER_TYPE.MOBILE)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Sản phẩm cho nam</div>
                    <div className="mb-2">Desktop (1500x270, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.MALE, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.MALE, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (600x350, 72ppi, {"<"} 500mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.MALE, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.MALE, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Sản phẩm cho nữ</div>
                    <div className="mb-2">Desktop (1500x270, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.FEMALE, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.FEMALE, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (600x350, 72ppi, {"<"} 500mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.FEMALE, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.FEMALE, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-banner">
                <div className="box-banner-item">
                    <div className="title">Sản phẩm cho trẻ em</div>
                    <div className="mb-2">Desktop (1500x270, 72dpi, {"<"} 1Mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.CHILD, BANNER_TYPE.DESKTOP)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.CHILD, BANNER_TYPE.DESKTOP)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">Mobile (600x350, 72ppi, {"<"} 500mb)</div>
                    <div className="banner-list">
                        {renderBanner(BANNER_POSITION.CHILD, BANNER_TYPE.MOBILE)}
                        <div className="banner-col col-6">
                            <div className="box-add">
                                <button className="btn-add" onClick={()=>{
                                    createBanner(BANNER_POSITION.CHILD, BANNER_TYPE.MOBILE)
                                }}>Thêm + </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wapper>
    );
}


export default BannerHomeShopManagement;