import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../../based/inputs/Textbox";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import BlogServices from "../../based/services/BlogServices";
import useFormValidate from "../../hooks/useFormValidate";
import BlogCategoryModel from "../../models/BlogCategoryModel";
import Common from "../../based/Common";
import TextNumber from "../../based/inputs/TextNumber";
import { INITPAGING } from "../../based/Constants";

const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState(null);
    const [category, setCategory] = useState(new BlogCategoryModel());
    const { addRef, displayError, isValid } = useFormValidate();
    const [check, setCheck] = useState(true);

    useEffect(() => {
        if (props.id > 0) getCategory(props.id);
        else setCategory({ ...BlogCategoryModel });
        return () => setCategory({ ...BlogCategoryModel });
    }, [props.id]);

    const getCategory = async (id) => {
        setIsBusy(true);
        let [err, data] = await BlogServices.GetCategory(id);
        if (!err && data) {
            setIsBusy(false);
            setCategory({ ...data });
        } else {
            setIsBusy(false);
            setCategory(new BlogCategoryModel());
        }
    };
    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await BlogServices.CreateUpdateCategory({ ...category });
            if (!err && data == 1) {
                setIsBusy(false);
                setCategory(new BlogCategoryModel());
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                if (data == -2) {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Ngành hàng đã tồn tại");
                } else {
                    setIsBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            }
            props.onSaveContinue();
        }
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={props.id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='name' className='required'>
                    Tên hiển thị:
                </label>
                <Textbox id='name' name='name' required ref={addRef} className='form-control' onChanged={(name, value) => setCategory({ ...category, name: value })} value={category.name} />
                <label htmlFor='indexPos' className='required mt-3'>
                    Thứ tự hiển thị:
                </label>
                <TextNumber id='indexPos' name='indexPos' min={0} required ref={addRef} className='form-control' onChanged={(name, value) => setCategory({ ...category, indexPos: value })} value={category.indexPos} />
            </div>
        </CommonModal>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
