import React, { useState, useEffect, useRef } from "react";
import Request from "../based/Request";
import { NOTIFY, CONSTANTS } from "../based/Constants";
import { Notify } from "../based/Notify";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import useFormValidate from "../hooks/useFormValidate";

export default function AddOrEdit(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [action, setAction] = useState(props.action);
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setAction(props.action);
    }, [props.isShowModal]);

    function Save(isContinue) {
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang lưu dữ liệu..");
            Request.Post("/api/action/save", action)
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                        setIsBusy(false);
                        setMsg(null);
                        props.onSaveSucceed(isContinue);
                        if (isContinue) setAction(props.action);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }
    function _handleSave() {
        Save(false);
    }
    function handleSaveContinue() {
        Save(true);
    }
    return (
        <CommonModal
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isBusy}
            busyMsg={msg}
            title={action.id == 0 ? "Thêm mới" : "Cập nhật"}
            onSave={() => _handleSave()}
            onSaveContinue={() => handleSaveContinue()}
            onClose={() => props.onClose()}
        >
            <div className="form-group">
                <label htmlFor="actionCode" className="required">
                    Code:
                </label>
                <Textbox id="actionCode" name="actionCode" required readOnly={action.id ? true : false} ref={addRef} className="form-control" onChanged={(name, value) => setAction({ ...action, actionCode: value })} value={action.actionCode} />
            </div>
            <div className="form-group">
                <label htmlFor="name" className="required">
                    Tên:
                </label>
                <Textbox id="name" name="name" required ref={addRef} className="form-control" onChanged={(name, value) => setAction({ ...action, name: value })} value={action.name} />
            </div>
            <div className="form-group">
                <label htmlFor="apiUrl" className="required">
                    ApiUrl:
                </label>
                <Textbox id="apiUrl" name="apiUrl" required ref={addRef} className="form-control" onChanged={(name, value) => setAction({ ...action, apiUrl: value })} value={action.apiUrl} />
            </div>
            <div className="form-group">
                <label htmlFor="methodType" className="required">
                    MethodType:
                </label>
                <Textbox id="methodType" name="methodType" required ref={addRef} className="form-control" onChanged={(name, value) => setAction({ ...action, methodType: value })} value={action.methodType} />
            </div>
            <div className="form-group">
                <label htmlFor="description">Mô tả:</label>
                <TextArea id="description" name="description" ref={addRef} className="form-control" onChanged={(name, value) => setAction({ ...action, description: value })} value={action.description} />
            </div>
        </CommonModal>
    );
}
