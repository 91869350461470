import React, { useEffect, useState } from "react";
import { INITPAGING } from "../../based/Constants";
import TextNumber from "../../based/inputs/TextNumber";
import Loading from "../../based/Loading";
import BaseServices from "../../based/services/BaseServices";
import PaymentModal from "./PaymentModal";

function UnPaidLoyaltyTable(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(INITPAGING);
    const [isShowModalPayment, setShowModalPayment] = useState(false);
    const [processItemIndex, setProcessItemIndex] = useState(-1);
    useEffect(() => {
        _fetchData();
    }, []);

    const _fetchData = async () => {
        setIsBusy(true);
        let [err, data] = await BaseServices.Post("/api/finance/get-creator-loyalty-payment/0", paging);
        setIsBusy(false);
        if (!err) {
            setData(data);
        }
    };
    const _handleShowPaymentModal = (index) => {
        setShowModalPayment(true);
        setProcessItemIndex(index);
    };

    return (
        <div className="table-responsive h-100vh">
            <Loading show={isBusy} msg="Đang tải dữ liệu" />
            <div className="card-box padding-10">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Kỳ thanh toán</th>
                            <th>Tổng tiền</th>
                            <th>Tên ngân hàng</th>
                            <th>Tên người nhận</th>
                            <th>Số tài khoản</th>
                            <th>SĐT MOMO</th>
                            <th>Tên MOMO</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 ? (
                            data.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.creator}</td>
                                        <td>{item.recycleTime}</td>
                                        <td>
                                            <TextNumber displayType="text" price value={item.totalLoyaltyPrice} />
                                        </td>
                                        <td>{item.bankName}</td>
                                        <td>{item.bankAccountName}</td>
                                        <td>{item.bankAccountNumber}</td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.momoName}</td>
                                        <td width="50">
                                            <button className="btn btn-primary btn-sm" onClick={() => _handleShowPaymentModal(i)}>
                                                Thanh toán
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={8}>Không có dữ liệu</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {isShowModalPayment && (
                <PaymentModal
                    show={isShowModalPayment}
                    data={data[processItemIndex]}
                    onClose={() => setShowModalPayment(false)}
                    onSaveSucceeded={() => {
                        setShowModalPayment(false);
                        _fetchData();
                    }}
                />
            )}
        </div>
    );
}

export default UnPaidLoyaltyTable;
