import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import DisplayVariantAttributeValue from "../based/DisplayVariantAttributeValue";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import SetProductServices from "../based/services/SetProductServices";
import ConfigSetProduct from "./ConfigSetProduct";

const AppAgencyType = {
    App: 1,
    Agency: 2,
    DirectSell: 3,
};
const SetProductModel = {
    id: 0,
    title: "",
    setAvatar: "",
    productVariants: [],
    setType: AppAgencyType.DirectSell,
};

const initPaging = {
    pageNumber: 1,
    pageSize: 10,
    totalRecord: 0,
    appAgencyType: AppAgencyType.DirectSell,
};

const DirectSell = () => {
    const [isShowModal, setIsShowModal] = useState(false);
    const [config, setConfig] = useState(SetProductModel);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, appAgencyType: AppAgencyType.DirectSell });
    const [datas, setDatas] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        getDatas({ ...Common.Paging, appAgencyType: AppAgencyType.DirectSell });
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await SetProductServices.GetDirectSellPaging(newPaging);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    const _handleAdd = () => {
        setConfig(SetProductModel);
        setIsShowModal(true);
    };

    async function _handleEdit() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length == 1) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await SetProductServices.GetByIdForUpdate(dataChecked[0].id);
            if (!error && data) {
                setConfig(data);
                setIsShowModal(true);
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình lấy thông tin.");
            }
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn một thông tin trước khi sửa");
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className="text-center">
                        <CheckBox checked={selectedAll} name="select_all" id="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th width="120" className="text-center">
                        Ảnh
                    </th>
                    <th>Tên Set</th>
                    <th className="text-center">Tên sản phẩm</th>
                    <th width="150" className="text-center">
                        Giá bán (đ)
                    </th>
                    <th width="200" className="text-center">
                        Người tạo
                    </th>
                    <th width="200" className="text-center">
                        Ngày cập nhật gần nhất
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    _renderContentBody()
                ) : (
                    <tr>
                        <td colSpan="100" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAttributes(item) {
        const attrArr = Common.GroupArray(item.attributeValuesDetail, "name");
        for (const key of attrArr.keys()) {
            let value = attrArr.get(key);
            const upgradeListAtt = [...new Map(value.map((item) => [item["value"], item])).values()];
            attrArr.set(key, upgradeListAtt);
        }
        const attrObj = {};
        for (const key of attrArr.keys()) {
            attrObj[key] = attrArr.get(key);
            for (const attr of attrObj[key]) {
                for (const ageProVarAtt of item.attributeValuesDetail) {
                    if (attr.value === ageProVarAtt.value) {
                        attr.checked = true;
                        break;
                    } else attr.checked = false;
                }
            }
        }
        return attrObj && <div className="d-flex flex-row flex-wrap">{Object.keys(attrObj).map((key) => attrObj[key] && attrObj[key].map((item, idx) => <DisplayVariantAttributeValue key={idx} keyName={key} value={item.value} />))}</div>;
    }
    function _renderContentBody() {
        let content = [];
        if (datas && datas.length > 0) {
            datas.map((item, i) => {
                let avatar = item.setAvatar ? item.setAvatar : "/images/no-image.png";
                let rowSpan = (item.productVariants && item.productVariants.length) > 0 ? item.productVariants.length : 1;
                let count = 0;
                if (item.productVariants && item.productVariants.length > 0) {
                    item.productVariants.map((product, p) => {
                        count = count + 1;
                        let arr = [];
                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle">
                                    <CheckBox checked={item.isChecked} name={i.toString()} id={i.toString()} label=" " onCheckedChange={(name, value) => _handleChecked(name, value)} />
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle">
                                    <a className="iconImageDefault full-width">
                                        <img src={avatar} className="variant-avatar" />
                                    </a>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className="vertical-align-middle">
                                    <span>{item.title}</span>
                                </td>
                            );
                        }
                        arr.push(
                            <td className="vertical-align-middle">
                                <strong> {product.name}</strong>
                                <p className="mb-0 white-space-nowrap">
                                    SKU:
                                    <span className="badge badge-custom" style={{ fontSize: 13 }}>
                                        {product.sellerSKU}
                                    </span>
                                </p>
                                {product.attributeValuesDetail && product.attributeValuesDetail.length > 0 && _renderAttributes(product)}
                            </td>
                        );
                        arr.push(
                            <td className="vertical-align-middle text-right">
                                <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.sellPrice} />
                            </td>
                        );
                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className="text-center vertical-align-middle">
                                    <span>{item.createdBy}</span>
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className="text-center vertical-align-middle">
                                    <span>{item.modifiedDateDisplay}</span>
                                </td>
                            );
                        }
                        content.push(
                            <tr key={i + "_" + p}>
                                {arr.map((a, iiii) => {
                                    return <React.Fragment key={iiii}>{a}</React.Fragment>;
                                })}
                            </tr>
                        );
                    });
                }
            });
        }
        return content;
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.isChecked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group full-width">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" />
                        Tạo mới
                    </button>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    async function _handleDelete() {
        var dataChecked = datas.filter((x) => x.isChecked);
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((x) => ids.push(x.id));
                    let [error, data] = await SetProductServices.DeleteSets(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn thông tin trước khi xóa");
    }
    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.isChecked = value));
        setDatas(datasUpdate);
    }
    function _handleChecked(index, value) {
        let updateData = [...datas];
        let data = updateData[index];
        data.isChecked = !data.isChecked;
        setDatas(updateData);
        setSelectedAll(updateData.filter((x) => x.isChecked).length == datas.length);
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Bán trực tiếp" />
            <Loading show={isLoading} msg={msg} />
            {isShowModal && (
                <ConfigSetProduct
                    isShowModal={isShowModal}
                    onClose={() => setIsShowModal(false)}
                    onSuccess={() => {
                        setIsShowModal(false);
                        getDatas(paging);
                    }}
                    config={config}
                />
            )}
            <CommonTable data={datas} paging={paging} placeholderSearch="Tên set" renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={getDatas} searchBasic />
        </React.Fragment>
    );
};

export default DirectSell;
