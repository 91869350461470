import BaseServices from "../../based/services/BaseServices";

const TopicServices = {
    GetTopics: async (paging) => {
        return BaseServices.Post("/api/topic/get-topics", paging);
    },
    SaveTopic: async (model) => {
        return BaseServices.Post("/api/topic/save-topic", model);
    },
    GetTopic: async (id) => {
        return BaseServices.Get(`/api/topic/get-topic/${id}`);
    },
    GetDropdownTopic: async (paging) => {
        return BaseServices.Post("/api/topic/get-dropdown-topics", paging);
    },
    GetTop10Topic: async () => {
        return BaseServices.Get("/api/topic/get-top-topic");
    },
};

export default TopicServices;
