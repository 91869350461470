import React, { forwardRef, useImperativeHandle, useState } from "react";
import { CONSTANTS } from "../Constants";
import Loading from "../Loading";
import Request from "../Request";
import Common from "../Common";
import { Notify } from "../Notify";
import { NOTIFY } from "../Constants";
import { LotusUploadProps } from "../PropsDefined";

const IMAGES = [".jpg", ".jpeg", ".png"];
const OFFICES = [".pdf,.docx,.xlsx"];

/**
 * Lotus Upload
 * @param {LotusUploadProps} props the lotus upload props
 * @param {Ref} ref reference
 */
const LotusUpload = (props, ref) => {
    useImperativeHandle(ref, () => ({ displayError, validateInput, props }));

    const [valid, setValid] = useState({ isValid: true, msgInvalid: "" });
    const [isUploading, setIsUploading] = useState(false);

    const displayError = (msg) => {
        setValid({ isValid: false, msgInvalid: msg });
    };

    const validateInput = (value) => {
        if (!value && props.required) return CONSTANTS.MSG_REQUIRED;
        return "";
    };

    function _handleChangeUpload(e) {
        e.preventDefault();
        if (props.required)
            setValid({
                isValid: false,
                msgInvalid: props.requiredMsg ? props.requiredMsg : CONSTANTS.MSG_REQUIRED,
            });
        else setValid({ isValid: true, msgInvalid: "" });
        let files = e.target.files;
        if (files && files.length > 0) {
            let documentType = props.documentType || 0;
            let sizeLimit = props.sizeLimit || 5; //default nếu không truyền size thì lấy 5MB
            if (props.multiple) {
                //nếu upload nhiều file
                let documents = [];
                if (props.documents && props.documents.length > 0) {
                    props.documents.map((doc) => {
                        documents.push(doc);
                    });
                }
                if (props.fileLimit && files && files.length + documents.length > props.fileLimit) {
                    setValid({
                        isValid: false,
                        msgInvalid: `Chỉ được tải lên tối đa ${props.fileLimit} tệp tin.`,
                    });
                    return;
                }
                let countFileInValid = 0;
                for (let index = 0; index < files.length; index++) {
                    let file = files[index];
                    if (file.size / 1048576 <= sizeLimit) {
                        let reader = new FileReader();
                        setIsUploading(true);
                        reader.onloadend = () => {
                            Request.UploadDocument(file, documentType)
                                .then((res) => {
                                    if (res && res.data) {
                                        documents.push(res.data.url);
                                        if (props.onChanged) {
                                            props.onChanged(props.name, documents);
                                        }
                                        document.querySelector(`#${props.id ? props.id : "btn-upload-document"}`).value = "";
                                        setIsUploading(false);
                                    } else {
                                        let errMsg =
                                            res.errors && res.errors.length > 0
                                                ? res.errors.reduce((prev, item, idx) => {
                                                      return `${prev}${item.value}`;
                                                  }, "")
                                                : "";
                                        setValid({ isValid: false, msgInvalid: errMsg });

                                        countFileInValid++;
                                        document.querySelector(`#${props.id ? props.id : "btn-upload-document"}`).value = "";
                                        setIsUploading(false);
                                    }
                                })
                                .catch((err) => {
                                    setValid({ isValid: false, msgInvalid: CONSTANTS.MSG_IMAGE_INVALID });
                                    setIsUploading(false);
                                    document.querySelector(`#${props.id ? props.id : "btn-upload-document"}`).value = "";
                                });
                        };
                        reader.readAsDataURL(file);
                    } else {
                        countFileInValid++;
                    }
                }
                if (countFileInValid > 0)
                    setValid({
                        isValid: false,
                        msgInvalid: `Tồn tại ${countFileInValid} tệp tin không đúng yêu cầu.`,
                    });
            } else {
                //chỉ upload 1 file
                let reader = new FileReader();
                let file = files[0];
                if (file.size / 1048576 > sizeLimit) {
                    setValid({
                        isValid: false,
                        msgInvalid: CONSTANTS.MSG_IMAGE_INVALID,
                    });
                    if (props.onChanged) {
                        props.onChanged(props.name, null);
                    }
                } else {
                    setIsUploading(true);
                    reader.onloadend = () => {
                        Request.UploadDocument(file, documentType)
                            .then((res) => {
                                if (res && res.data) {
                                    var document = props.documents;
                                    if (document) {
                                        document = document.split("/")[document.split("/").length - 1];
                                        Request.DeleteDocument(document)
                                            .then((deleteRes) => {})
                                            .catch((err) => {});
                                    }
                                    if (props.onChanged) {
                                        props.onChanged(props.name, res.data.url);
                                    }
                                } else {
                                    let errMsg =
                                        res.errors && res.errors.length > 0
                                            ? res.errors.reduce((prev, item, idx) => {
                                                  return `${prev}${item.value}`;
                                              }, "")
                                            : "";
                                    setValid({
                                        isValid: false,
                                        msgInvalid: errMsg,
                                    });
                                }
                                setIsUploading(false);
                            })
                            .catch((err) => {
                                setValid({
                                    isValid: false,
                                    msgInvalid: CONSTANTS.MSG_IMAGE_INVALID,
                                });
                                setIsUploading(false);
                            });
                    };
                    reader.readAsDataURL(file);
                }
            }
        } else {
            setValid({
                isValid: false,
                msgInvalid: "Không có tệp tin nào được chọn",
            });
        }
    }
    function handleRemove(idx) {
        setValid({ isValid: true, msgInvalid: "" });
        let documents = props.documents;
        if (documents) {
            if (props.multiple) {
                let document = documents[idx];
                document = document.split("/")[document.split("/").length - 1];
                setIsUploading(true);
                Request.DeleteDocument(document)
                    .then((res) => {
                        documents.splice(idx, 1);
                        props.onChanged(props.name, documents);
                        setIsUploading(false);
                    })
                    .catch((err) => {
                        setIsUploading(false);
                    });
            } else {
                let arr = documents.split("/");
                documents = arr[arr.length - 1];
                setIsUploading(true);
                Request.DeleteDocument(documents)
                    .then((res) => {
                        setIsUploading(false);
                        if (props.onChanged) props.onChanged(props.name, "");
                    })
                    .catch((err) => {
                        setIsUploading(false);
                    });
            }
        }
    }
    function detectOfficeMIME(extension) {
        if (extension) {
            switch (extension) {
                case ".pdf":
                    return "application/pdf";
                case ".docx":
                    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                case ".xlsx":
                    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                case ".ppt":
                    return "application/vnd.ms-powerpoint";
                default:
                    return "";
            }
        }
    }
    async function _handleView(fileName) {
        if (fileName && fileName.length > 0) {
            let arr = fileName.split("/");
            fileName = arr[arr.length - 1];
            let extension = fileName.substring(fileName.lastIndexOf("."), fileName.length);
            setIsUploading(true);
            Request.PreviewDocument(fileName, props.createdBy)
                .then((res) => {
                    if (res.success) {
                        setIsUploading(false);
                        let mime = detectOfficeMIME(extension);
                        const blob = Common.base64toBlob(res.data, mime);
                        const fileURL = URL.createObjectURL(blob);
                        var win = window.open(fileURL);
                    } else {
                        setIsUploading(false);
                        Notify(NOTIFY.ERROR, "Lỗi", res.data);
                    }
                })
                .catch((err) => {
                    setIsUploading(false);
                });
        }
    }
    function detectOfficeIcon(extension) {
        if (extension) {
            switch (extension) {
                case ".pdf":
                    return `${Request.CdnURL()}/images_system/icon_pdf.jpg`;
                case ".docx":
                    return `${Request.CdnURL()}/images_system/icon_word.png`;
                case ".xlsx":
                    return `${Request.CdnURL()}/images_system/icon_excel.jpg`;
                case ".ppt":
                    return `${Request.CdnURL()}/images_system/icon_powerpoint.png`;
                default:
                    return `${Request.CdnURL()}/images_system/icon-file.png`;
            }
        } else return `${Request.CdnURL()}/images_system/icon-file.png`;
    }
    function _renderDocument(doc, k) {
        if (doc) {
            let extension = doc.substring(doc.lastIndexOf("."), doc.length);
            let isImage = IMAGES.find((x) => x == extension) != null;
            let arr = doc.split("/");
            let fileName = arr[arr.length - 1];
            return (
                <div className='document-item'>
                    {isImage ? <img alt='Lotus' src={doc} /> : <img alt='Lotus' src={detectOfficeIcon(extension)} />}
                    {props.isAction && (
                        <React.Fragment>
                            <div className='document-overlay'></div>
                            <div className='document-action'>
                                <div className='action'>
                                    {/* <i title='Tải về' className='mdi mdi-24px mdi-download text-white cursor-pointer'></i> */}
                                    {!isImage && (props.isPreviewFile != false || props.isPreviewFile == undefined) && <i title='Xem' className='mdi mdi-24px mdi-eye text-white cursor-pointer' onClick={() => _handleView(doc)}></i>}
                                    {(props.isDelete != false || props.isDelete == undefined) && <i title='Xóa' className='mdi mdi-24px mdi-delete text-danger cursor-pointer' onClick={() => handleRemove(k)}></i>}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {props.isShowFileName && (
                        <div className='document-name'>
                            <span>{fileName}</span>
                        </div>
                    )}
                </div>
            );
        }
    }
    function _renderPreview() {
        if (props.documents) {
            if (props.multiple) {
                return (
                    <div id='preview-documents'>
                        {props.documents.map((doc, k) => {
                            return <React.Fragment key={k}>{_renderDocument(doc, k)}</React.Fragment>;
                        })}
                    </div>
                );
            } else {
                return <div id='preview-documents'>{_renderDocument(props.documents)}</div>;
            }
        }
    }
    return (
        <React.Fragment>
            <Loading show={isUploading} msg='Đang tải lên...' />
            {(props.isShowUpload != false || props.isShowUpload == undefined) && (
                <div className='legacy-form-item'>
                    <div className='legacy-form-item-control-wrapper'>
                        <div className='legacy-form-item-control'>
                            <span className='full-width'>
                                <input
                                    className={props.className ? "fileInputDocument hide " + props.className : "fileInputDocument hide"}
                                    type='file'
                                    multiple={props.multiple}
                                    id={props.id ? props.id : "btn-upload-document"}
                                    accept={props.accept}
                                    onChange={(e) => _handleChangeUpload(e)}
                                />
                                <button
                                    type='button'
                                    className='lotus-btn-dashed lotus-btn-upload full-width'
                                    onClick={() => {
                                        document.getElementById(props.id ? props.id : "btn-upload-document").click();
                                    }}
                                >
                                    <i className='fa fa-upload m-r-5' /> <span> {props.buttonName ? props.buttonName : "Nhấn vào để thêm tài liệu"} </span>
                                    {props.description && <p>{props.description}</p>}
                                </button>
                                {valid.isValid ? null : <label className='text-danger full-width'>{valid.msgInvalid}</label>}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {_renderPreview()}
        </React.Fragment>
    );
};
export default forwardRef(LotusUpload);
