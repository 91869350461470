import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import MidjourneyService from "../based/services/MidjourneyService";
import Common from "../based/Common";
const initPage = {
    pageSize: 100,
    pageNumber: 1,
    keyword: "",
};
const modelUserSender = {
    id: 0,
    senderId: "",
    name: "",
    isActiveGPT: false,
    isActiveMidjourney: false,
    isActiveGPTReset: false,
    isResetCapcut: false,
    isGetPassMidjourney: false,
    ipLocation: "",
};

const initTime = {
    id: 0,
    userName: "",
    minute: 0,
    minuteAutoReset: 0,
    passwordApp: "",
};

const RESET_TYPE = {
    Midjourney: 0,
    ChatGPT: 1,
    CapcutPro: 2,
};

const PASSWORD_TYPE = {
    Midjourney: 0,
    ChatGPT: 1,
    CapcutPro: 2,
};

function MidjourneyPassReset() {
    const [passCurrent, setPassCurrent] = useState({});
    const [pass, setPass] = useState({});
    const [time, setTime] = useState({});
    const [passCurrentGPT, setPassCurrentGPT] = useState({});
    const [passGPT, setPassGPT] = useState({});
    const [timeGPT, setTimeGPT] = useState({});
    const [passCurrentCapcut, setPassCurrentCapcut] = useState({});
    const [passCapcut, setPassCapcut] = useState({});
    const [timeCapcut, setTimeCapcut] = useState({});
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState({ ...initPage });

    useEffect(() => {
        getMidjourneyPassByPage();
        getTimeReset();
        getData({ ...initPage });
    }, []);
    const getMidjourneyPassByPage = async () => {
        setBusy(true);
        let [err, data] = await MidjourneyService.GetMidjourneyPass();
        if (!err && data && data.length > 0) {
            let current = data.find((x) => x.isUsing == true && x.passwordType == PASSWORD_TYPE.Midjourney);
            let pass = data.find((x) => x.isUsing == false && x.passwordType == PASSWORD_TYPE.Midjourney);
            if (current) {
                setPassCurrent(current);
            }
            if (pass) {
                setPass(pass);
            }

            let currentGPT = data.find((x) => x.isUsing == true && x.passwordType == PASSWORD_TYPE.ChatGPT);
            let passGPT = data.find((x) => x.isUsing == false && x.passwordType == PASSWORD_TYPE.ChatGPT);
            if (currentGPT) {
                setPassCurrentGPT(currentGPT);
            }
            if (passGPT) {
                setPassGPT(passGPT);
            }

            let currentCapcut = data.find((x) => x.isUsing == true && x.passwordType == PASSWORD_TYPE.CapcutPro);
            let passCapcut = data.find((x) => x.isUsing == false && x.passwordType == PASSWORD_TYPE.CapcutPro);
            if (currentCapcut) {
                setPassCurrentCapcut(currentCapcut);
            }
            if (passCapcut) {
                setPassCapcut(passCapcut);
            }
        } else {
            setPassCurrentCapcut(null);
            setPassCapcut(null);
            setPassCurrentGPT(null);
            setPassGPT(null);
            setPassCurrent(null);
            setPass(null);
        }
        setBusy(false);
    };

    const getTimeReset = async () => {
        setBusy(true);
        let [err, data] = await MidjourneyService.GetTimeReset();
        if (!err && data && data.length > 0) {
            let midjourney = data.find((x) => x.resetType == RESET_TYPE.Midjourney);
            if (midjourney) setTime(midjourney);

            let chatGPT = data.find((x) => x.resetType == RESET_TYPE.ChatGPT);
            if (chatGPT) setTimeGPT(chatGPT);

            let capcut = data.find((x) => x.resetType == RESET_TYPE.CapcutPro);
            if (capcut) setTimeCapcut(capcut);
        } else {
            setTime({ ...initTime, resetType: RESET_TYPE.Midjourney, minuteAutoReset: 0 });
            setTimeGPT({ ...initTime, resetType: RESET_TYPE.ChatGPT, minuteAutoReset: 0 });
            setTimeCapcut({ ...initTime, resetType: RESET_TYPE.CapcutPro, minuteAutoReset: 0 });
        }
        setBusy(false);
    };

    const getData = async (paging) => {
        setBusy(true);
        let [err, data] = await MidjourneyService.GetUserSender(paging);
        if (!err && data && data.listObjects) {
            setData(data.listObjects);
            setPaging(data.paging);
        } else {
            setData([]);
            setPaging(initPage);
        }
        setBusy(false);
    };

    async function handleCreateOrUpdateTime(time) {
        if ((time.minuteAutoReset != 0 && time.minuteAutoReset <= 59) || (time.minuteAutoReset > 59 && time.minuteAutoReset <= time.minute)) {
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Thời gian auto >= 60 và lớn hơn thời gian yêu cầu");

            return;
        }
        if (time.userName && time.minute >= 0 && time.passwordApp && (time.minuteAutoReset == 0 || (time.minuteAutoReset > 59 && time.minuteAutoReset > time.minute))) {
            setBusy(true);
            let data = { ...time };
            let [err, dt] = await MidjourneyService.CreateUpdateTimeReset(data);
            if (!err && dt) {
                getTimeReset();
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            setBusy(false);
        } else {
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Nhập đủ thông tin");
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>SenderId</th>
                    <th>Name</th>
                    <th>IP</th>
                    <th>CapcutPro Reset Pass</th>
                    <th>Midjourney Reset Pass</th>
                    <th>Midjourney Get Pass</th>
                    <th>GPT+ Reset Pass</th>
                    <th>GPT+ Get Pass</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="SenderId"
                                            value={item.senderId}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].senderId = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.senderId
                                    )}
                                </td>
                                <td>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="Name"
                                            value={item.name}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].name = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.name
                                    )}
                                </td>
                                <td>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="text"
                                            placeholder="IP"
                                            value={item.ipLocation}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].ipLocation = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.ipLocation
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        ""
                                    ) : (
                                        <input
                                            type="checkbox"
                                            className="style-checkbox"
                                            checked={item.isResetCapcut}
                                            onClick={() => {
                                                updateUser({ ...item, isResetCapcut: !item.isResetCapcut });
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        ""
                                    ) : (
                                        <input
                                            type="checkbox"
                                            className="style-checkbox"
                                            checked={item.isActiveMidjourney}
                                            onClick={() => {
                                                updateUser({ ...item, isActiveMidjourney: !item.isActiveMidjourney });
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        ""
                                    ) : (
                                        <input
                                            type="checkbox"
                                            className="style-checkbox"
                                            checked={item.isGetPassMidjourney}
                                            onClick={() => {
                                                updateUser({ ...item, isGetPassMidjourney: !item.isGetPassMidjourney });
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        ""
                                    ) : (
                                        <input
                                            type="checkbox"
                                            className="style-checkbox"
                                            checked={item.isActiveGPTReset}
                                            onClick={() => {
                                                updateUser({ ...item, isActiveGPTReset: !item.isActiveGPTReset });
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        ""
                                    ) : (
                                        <input
                                            type="checkbox"
                                            className="style-checkbox"
                                            checked={item.isActiveGPT}
                                            onClick={() => {
                                                updateUser({ ...item, isActiveGPT: !item.isActiveGPT });
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 250, textAlign: "center" }}>
                                    {item.isUpdate || item.id <= 0 ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm user +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {
        return <h5 className="mb-2">Danh sách senderId (PAGE CHIA SẺ TÀI KHOẢN MIDJOURNEY)</h5>;
    }

    async function handleAddItem() {
        setData([{ ...modelUserSender }, ...data]);
    }

    function IsDisable(idx) {
        if (!data[idx].senderId) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await MidjourneyService.CreateUpdateUserSender(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function updateUser(item) {
        setBusy(true);
        let [err, dt] = await MidjourneyService.CreateUpdateUserSender(item);
        if (!err && dt) {
            getData(paging);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MidjourneyService.DeleteUserSender(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    return (
        <Wapper>
            <Loading show={isBusy} msg={msg} />
            <Breadcrumbs currentPage={"Quản lý pass"} />

            <div className="flex-box">
                <div className="box-flex-child">
                    <h4>Midjourney Option</h4>
                    <div className="box-input">
                        <div>
                            <span>Username:</span>
                            <input
                                type="text"
                                className="input-in-table"
                                placeholder="username"
                                value={time.userName}
                                onChange={(e) => {
                                    setTime({ ...time, userName: e.target.value });
                                }}
                            />
                        </div>
                        <div>
                            <span>Password App:</span>
                            <input
                                type="text"
                                className="input-in-table"
                                placeholder="Password App"
                                value={time.passwordApp}
                                onChange={(e) => {
                                    setTime({ ...time, passwordApp: e.target.value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="box-input number mt-2">
                        <div>
                            <span>Số phút yêu cầu reset: </span>
                            <input
                                type="number"
                                className="input-in-table"
                                placeholder="phút"
                                value={time.minute}
                                onChange={(e) => {
                                    setTime({ ...time, minute: e.target.value });
                                }}
                            />
                        </div>
                        <div>
                            <span className="flex-box">
                                <span>Số phút auto reset: </span>
                                <div className="box-right-check">
                                    Dùng
                                    <input
                                        type="checkbox"
                                        checked={time.minuteAutoReset >= 60 && time.minuteAutoReset > time.minute ? true : false}
                                        onClick={() => {
                                            if (time.minuteAutoReset >= 60 && time.minuteAutoReset > time.minute) setTime({ ...time, minuteAutoReset: 0 });
                                            else setTime({ ...time, minuteAutoReset: 1440 });
                                        }}
                                    />
                                </div>
                            </span>

                            <input
                                type="number"
                                className="input-in-table"
                                disabled={time.minuteAutoReset === 0 ? true : false}
                                placeholder="phút"
                                value={time.minuteAutoReset}
                                onChange={(e) => {
                                    setTime({ ...time, minuteAutoReset: e.target.value });
                                }}
                            />
                        </div>
                        <button
                            className="btn btn-custom"
                            onClick={() => {
                                handleCreateOrUpdateTime({ ...time, resetType: RESET_TYPE.Midjourney });
                            }}
                        >
                            Lưu
                        </button>
                    </div>

                    <div className="password">
                        <span></span>
                        <span>Password</span>
                        <span>Thời gian reset</span>
                    </div>
                    <div className="password">
                        <span>Password hiện tại</span>
                        <div>{passCurrent && passCurrent.password}</div>
                        <div>{passCurrent && passCurrent.timeReset && Common.formatDate(new Date(passCurrent.timeReset), "fulldate")}</div>
                    </div>
                    <div className="password">
                        <span>Password yêu cầu reset kế tiếp</span>
                        <div>{pass && pass.password}</div>
                        <div>{passCurrent && passCurrent.timeReset && Common.formatDate(new Date(new Date(passCurrent.timeReset).getTime() + time.minute * 60 * 1000), "fulldate")}</div>
                    </div>
                    <div className="password">
                        <span>Thời gian auto reset kế tiếp</span>
                        <div></div>
                        <div>{passCurrent && passCurrent.timeReset && time.minuteAutoReset > 0 ? Common.formatDate(new Date(new Date(passCurrent.timeReset).getTime() + time.minuteAutoReset * 60 * 1000), "fulldate") : "Không auto"}</div>
                    </div>
                </div>

                <div className="box-flex-child">
                    <h4>ChatGPT+ Option</h4>
                    <div className="box-input">
                        <div>
                            <span>Username:</span>
                            <input
                                type="text"
                                className="input-in-table"
                                placeholder="username"
                                value={timeGPT.userName}
                                onChange={(e) => {
                                    setTimeGPT({ ...timeGPT, userName: e.target.value });
                                }}
                            />
                        </div>
                        <div>
                            <span>Password App:</span>
                            <input
                                type="text"
                                className="input-in-table"
                                placeholder="Password App"
                                value={timeGPT.passwordApp}
                                onChange={(e) => {
                                    setTimeGPT({ ...timeGPT, passwordApp: e.target.value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="box-input number mt-2">
                        <div>
                            <span>Số phút yêu cầu reset: </span>
                            <input
                                type="number"
                                className="input-in-table"
                                placeholder="phút"
                                value={timeGPT.minute}
                                onChange={(e) => {
                                    setTimeGPT({ ...timeGPT, minute: e.target.value });
                                }}
                            />
                        </div>
                        <div>
                            <span className="flex-box">
                                <span>Số phút auto reset: </span>
                                <div className="box-right-check">
                                    Dùng
                                    <input
                                        type="checkbox"
                                        checked={timeGPT.minuteAutoReset >= 60 && timeGPT.minuteAutoReset > timeGPT.minute ? true : false}
                                        onClick={() => {
                                            if (timeGPT.minuteAutoReset >= 60 && timeGPT.minuteAutoReset > timeGPT.minute) setTimeGPT({ ...timeGPT, minuteAutoReset: 0 });
                                            else setTimeGPT({ ...timeGPT, minuteAutoReset: 1440 });
                                        }}
                                    />
                                </div>
                            </span>
                            <input
                                type="number"
                                className="input-in-table"
                                placeholder="phút"
                                disabled={timeGPT.minuteAutoReset === 0 ? true : false}
                                value={timeGPT.minuteAutoReset}
                                onChange={(e) => {
                                    setTimeGPT({ ...timeGPT, minuteAutoReset: e.target.value });
                                }}
                            />
                        </div>
                        <button
                            className="btn btn-custom"
                            onClick={() => {
                                handleCreateOrUpdateTime({ ...timeGPT, resetType: RESET_TYPE.ChatGPT });
                            }}
                        >
                            Lưu
                        </button>
                    </div>

                    <div className="password">
                        <span></span>
                        <span>Password</span>
                        <span>Thời gian reset</span>
                    </div>
                    <div className="password">
                        <span>Password hiện tại</span>
                        <div>{passCurrentGPT && passCurrentGPT.password}</div>
                        <div>{passCurrentGPT && passCurrentGPT.timeReset && Common.formatDate(new Date(passCurrentGPT.timeReset), "fulldate")}</div>
                    </div>
                    <div className="password">
                        <span>Password yêu cầu reset kế tiếp</span>
                        <div>{passGPT && passGPT.password}</div>
                        <div>{passCurrentGPT && passCurrentGPT.timeReset && Common.formatDate(new Date(new Date(passCurrentGPT.timeReset).getTime() + timeGPT.minute * 60 * 1000), "fulldate")}</div>
                    </div>
                    <div className="password">
                        <span>Thời gian auto reset kế tiếp</span>
                        <div></div>
                        <div>
                            {passCurrentGPT && passCurrentGPT.timeReset && timeGPT.minuteAutoReset > 0 ? Common.formatDate(new Date(new Date(passCurrentGPT.timeReset).getTime() + timeGPT.minuteAutoReset * 60 * 1000), "fulldate") : "Không auto"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-box mt-3">
                <div className="box-flex-child">
                    <h4>CapcutPro Option</h4>
                    <div className="box-input">
                        <div>
                            <span>Username:</span>
                            <input
                                type="text"
                                className="input-in-table"
                                placeholder="username"
                                value={timeCapcut.userName}
                                onChange={(e) => {
                                    setTimeCapcut({ ...timeCapcut, userName: e.target.value });
                                }}
                            />
                        </div>
                        <div>
                            <span>Password App:</span>
                            <input
                                type="text"
                                className="input-in-table"
                                placeholder="Password App"
                                value={timeCapcut.passwordApp}
                                onChange={(e) => {
                                    setTimeCapcut({ ...timeCapcut, passwordApp: e.target.value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="box-input number mt-2">
                        <div>
                            <span>Số phút yêu cầu reset: </span>
                            <input
                                type="number"
                                className="input-in-table"
                                placeholder="phút"
                                value={timeCapcut.minute}
                                onChange={(e) => {
                                    setTimeCapcut({ ...timeCapcut, minute: e.target.value });
                                }}
                            />
                        </div>
                        <div>
                            <span className="flex-box">
                                <span>Số phút auto reset: </span>
                                <div className="box-right-check">
                                    Dùng
                                    <input
                                        className=""
                                        type="checkbox"
                                        checked={timeCapcut.minuteAutoReset >= 60 && timeCapcut.minuteAutoReset > timeCapcut.minute ? true : false}
                                        onClick={() => {
                                            if (timeCapcut.minuteAutoReset >= 60 && timeCapcut.minuteAutoReset > timeCapcut.minute) setTimeCapcut({ ...timeCapcut, minuteAutoReset: 0 });
                                            else setTimeCapcut({ ...timeCapcut, minuteAutoReset: 1440 });
                                        }}
                                    />
                                </div>
                            </span>
                            <input
                                type="number"
                                className="input-in-table"
                                placeholder="phút"
                                value={timeCapcut.minuteAutoReset}
                                disabled={timeCapcut.minuteAutoReset === 0 ? true : false}
                                onChange={(e) => {
                                    setTimeCapcut({ ...timeCapcut, minuteAutoReset: e.target.value });
                                }}
                            />
                        </div>
                        <button
                            className="btn btn-custom"
                            onClick={() => {
                                handleCreateOrUpdateTime({ ...timeCapcut, resetType: RESET_TYPE.CapcutPro });
                            }}
                        >
                            Lưu
                        </button>
                    </div>

                    <div className="password">
                        <span></span>
                        <span>Password</span>
                        <span>Thời gian reset</span>
                    </div>
                    <div className="password">
                        <span>Password hiện tại</span>
                        <div>{passCurrentCapcut && passCurrentCapcut.password}</div>
                        <div>{passCurrentCapcut && passCurrentCapcut.timeReset && Common.formatDate(new Date(passCurrentCapcut.timeReset), "fulldate")}</div>
                    </div>
                    <div className="password">
                        <span>Password yêu cầu reset kế tiếp</span>
                        <div>{passCapcut && passCapcut.password}</div>
                        <div>{passCurrentCapcut && passCurrentCapcut.timeReset && Common.formatDate(new Date(new Date(passCurrentCapcut.timeReset).getTime() + timeCapcut.minute * 60 * 1000), "fulldate")}</div>
                    </div>
                    <div className="password">
                        <span>Thời gian auto reset kế tiếp</span>
                        <div></div>
                        <div>
                            {passCurrentCapcut && passCurrentCapcut.timeReset && timeCapcut.minuteAutoReset > 0
                                ? Common.formatDate(new Date(new Date(passCurrentCapcut.timeReset).getTime() + timeCapcut.minuteAutoReset * 60 * 1000), "fulldate")
                                : "Không auto"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3"></div>
            <CommonTable data={data} paging={paging} searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
        </Wapper>
    );
}
const Wapper = styled.div`
    table.table.table-bordered.m-b-0.focus-on {
        position: relative;
    }

    thead {
        position: sticky;
        top: 0;
    }
    .style-checkbox {
        width: 20px;
        height: 20px;
        accent-color: green;
    }

    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    .flex-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .box-flex-child {
            width: calc(50% - 8px);
        }

        .box-right-check {
            display: flex;
            align-items: center;

            input[type="checkbox"] {
                margin-left: 12px;
                margin-right: 0;
                width: 14px;
                height: 14px;
                accent-color: green;
                cursor: pointer;
            }
        }
    }

    .password {
        margin-top: 12px;
        display: flex;

        span {
            display: block;
            width: 200px;
        }

        div {
            width: 200px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .box-input {
        display: flex;
        align-items: end;

        span {
            display: block;
            width: 150px;
        }
        input {
            width: 250px;
            height: 40px;
            margin-right: 12px;
            padding: 16px;
        }

        button {
            height: 40px;
        }

        &.number {
        }

        .flex-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 250px;
        }
    }

    .input-in-table {
        width: 100%;
        height: 100%;

        :placeholder {
            opacity: 0.2;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
export default MidjourneyPassReset;
