import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import BaseServices from "../based/services/BaseServices";

const ModalUploadBanner = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [image, setImage] = useState({ desktop: "", mobile: "" });
    const [listBanner, setListBanner] = useState([]);
    const [msg, setMsg] = useState("");
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        setImage({
            desktop: props.item.bannerDesktopUrl,
            mobile: props.item.bannerMobileUrl,
        });
        setKeyword(props.item.description);
        setListBanner(props.item.listImage);
    }, [props.item]);

    async function _handleSaveProduct() {
        let model = {
            id: 0,
            idCol: props.item.id,
            showType: props.item.showType,
            bannerMobileUrl: image.mobile,
            bannerDesktopUrl: image.desktop,
            listImage: listBanner,
            description: keyword,
        };
        let [err, data] = await BaseServices.Post("/api/CreatorCollectionShow/create-col-show", model);
        if (!err && data) {
            props.onClose();
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleUploadFileMobile(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            setImage({ ...image, mobile: res.data.imageUrl });
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    const handleUploadImages = (e) => {
        let files = e.target.files;
        if (!files || files.length === 0) return;

        Request.UploadMultipleImage(files).then((res) => {
            if (res && res.data && !res.errors) {
                let { listImageUrl } = res.data;
                const listImageDTO = listImageUrl.map((item) => ({
                    url: item,
                    imageType: 61,
                }));

                setListBanner(listImageDTO);
            } else {
                let errMsg =
                    res.errors && res.errors.length > 0
                        ? res.errors.reduce((prev, item, idx) => {
                              return `${prev}${item.value}<br/>`;
                          }, "")
                        : "";
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
            }
        });
    };

    async function handleUploadFileDesktop(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            setImage({ ...image, desktop: res.data.imageUrl });
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <CommonModal
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isBusy}
            busyMsg={msg}
            title={"Cập nhật banner"}
            onSave={() => {
                _handleSaveProduct();
            }}
            onClose={() => props.onClose()}
        >
            <Wrapper>
                <div className="form-group">
                    <label className="form-label d-block" htmlFor="">
                        Nội dung
                    </label>
                    <textarea
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value);
                        }}
                        style={{ width: "100%" }}
                    />
                </div>
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <div>
                        <div className="box-image">
                            {image && image.mobile ? <img src={image.mobile} /> : <img />}
                            {image && image.mobile && (
                                <span
                                    onClick={() => {
                                        setImage({ ...image, mobile: "" });
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13 1L1 13" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 13L1 0.999999" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            )}
                        </div>
                        <input
                            className="mt-2"
                            style={{ maxWidth: 90 }}
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                                handleUploadFileMobile(e);
                            }}
                        />
                        Mobile
                    </div>
                    <div>
                        <div className="box-image">
                            {image && image.desktop ? <img src={image.desktop} /> : <img />}
                            {image && image.desktop && (
                                <span
                                    onClick={() => {
                                        setImage({ ...image, desktop: "" });
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13 1L1 13" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 13L1 0.999999" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            )}
                        </div>
                        <input
                            className="mt-2"
                            style={{ maxWidth: 90 }}
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                                handleUploadFileDesktop(e);
                            }}
                        />
                        Desktop
                    </div>
                </div>
                <div className="list-image-section mt-3">
                    <label className="form-label d-block" htmlFor="">
                        Danh sách Banner
                    </label>
                    <div className="upload-multiple-banner">
                        <input
                            type="file"
                            onChange={(e) => {
                                handleUploadImages(e);
                            }}
                            multiple
                        />
                    </div>
                    <div className="list-image">
                        {listBanner &&
                            listBanner.length > 0 &&
                            listBanner.map((item, idx) => {
                                return (
                                    <div key={idx} className="box-image">
                                        <img src={item.url} />

                                        <div className="position">
                                            <input
                                                value={item.position}
                                                onChange={(e) => {
                                                    let value = +e.target.value;
                                                    let newList = [...listBanner];
                                                    newList[idx].position = value;
                                                    setListBanner(newList);
                                                }}
                                                type={"number"}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
};

export default ModalUploadBanner;
const Wrapper = styled.div`
    .box-image {
        width: 180px;
        height: 180px;
        position: relative;

        span {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        img {
            height: 100%;
            aspect-ratio: 1;
            object-fit: contain;
        }
    }

    .box-image {
        display: flex;
        align-items: center;
    }
`;
