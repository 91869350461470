import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CheckBox from "../based/inputs/CheckBox";
import TFUSelect from "../based/inputs/TFUSelect";
import TemSizeServices from "../based/services/TemSizeServices";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};

const modelProduct = {
    productId: 0,
    isUpdate: true,
};
const modelStamp = {
    productId: 0,
    isAdd: true,
    stampAvatar: "",
    productVariantId: "",
    sizeStampBarcodeColorId: 0
}
const StampManagement = () => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [optionProduct , setOptionProduct] = useState([])
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [selectId, setSelectId] = useState(0);
    const [dataDropDownTypeColor, setDataDropDownTypeColor] = useState([]);
    const [typeColor, setTypeColor] = useState([]);

    useEffect(() => {
        setBusy(false);
        getData(paging);
        getDropdownProduct();
        getStampBarcodeColor()
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetProductStampByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    async function getDropdownProduct() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetProductDropDown();
        if (!err && data) {
            setOptionProduct(data);
            setBusy(false);
        } else {
            setOptionProduct([]);
            setBusy(false);
        }
    }
    async function getStampBarcodeColor() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetStampBarcodeColor();
        if (!err && data) {
            setTypeColor(data)
            let dd = data.map((item)=>{
                return {
                    value: item.id,
                    label: item.name
                }
            })
            setDataDropDownTypeColor(dd);
            setBusy(false);
        } else {
            setOptionProduct([]);
            setBusy(false);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>Ảnh</th>
                    <th>Dòng sản phẩm</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 40, textAlign: "center" }}>
                                    {item.productId > 0 && (
                                        <CheckBox
                                            checked={item.checked}
                                            name={`${item.id}`}
                                            label=" "
                                            onCheckedChange={(name, value) => {
                                                let dt = [...data];
                                                dt.map((x) => (x.checked = false));
                                                dt[idx].checked = value;
                                                setData(dt);
                                                if (value) {
                                                    setSelectId(item.productId);
                                                } else {
                                                    setSelectId(0);
                                                }
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 100, textAlign: "center" }}>
                                   {item.productAvatar ? <img  style={{width: 80, height: 80}} src={item.productAvatar}/> : <img style={{width: 80, height: 80}} />} 
                                </td>
                                <td style={{ width: 250 }}>
                                    {item.isUpdate ? (
                                         <TFUSelect
                                            className="select"
                                            options={optionProduct}
                                            placeholder="--Chọn--"
                                            value={item.productId}
                                            onChanged={(value) => {
                                                let dt = [...data];
                                                dt[idx].productId = value;
                                                setData(dt);
                                            }}
                                     />
                                    ) : (
                                        item.name
                                    )}
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    {item.isUpdate ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : ""}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.productId, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                   
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {
        return <div className="mb-3 text-title">Dòng sản phẩm</div>;
    }

    async function handleAddItem() {
        setData([ ...data, { ...modelProduct }]);
    }

    function IsDisable(idx) {
        if (data[idx].productId <= 0) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await TemSizeServices.CreateProductStamp(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {

            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await TemSizeServices.DeleteProductStampSize(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }


    const [stampSize, setStampSize] = useState([]);
    const [pagingStampSize, setPagingStampSize] = useState(initPage);
    const tableScriptStampSize = useRef(null);
    const [dataDropDownStamp, setDataDropDownStamp] = useState([]);
    
    
    useEffect(() => {
        if (selectId > 0) {
            getStampSize();
        } else {
            setStampSize([]);
            setDataDropDownStamp([])
        }
    }, [selectId]);

    async function getStampSize() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetStampSizeByProductId(selectId);
        if (!err && data) {
            setStampSize(data);
            getDataDropDownStamp(data);
            setBusy(false);
        } else {
            setStampSize([]);
            setBusy(false);
        }
    }
    async function getDataDropDownStamp(dataConflix) {
        setBusy(true);
        let [err, data] = await TemSizeServices.GetProductVarriantByProductId(selectId);
        if (!err && data && data.length > 0) {
            let newdata = data.filter((item) => !dataConflix.some(i=> i.productVariantId == item.productVariantId))
            const option = newdata.map((item , idx)=>{
                return {
                    value: item.productVariantId,
                    label: item.sizeName + ' - ' + item.colorName + ' - ' + item.sellerSKU
                }
            })
            setDataDropDownStamp(option);
            setBusy(false);
        } else {
            setDataDropDownStamp([]);
            setBusy(false);
        }
    }

    function _renderHeaderStampSize() {
        return (
            <thead>
                <tr className="text-center">
                    <th>Tem</th>
                    <th>Size</th>
                    <th>Màu</th>
                    <th>Mã sản phẩm</th>
                    <th>Màu barcode</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }

    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...stampSize];
                            dt[index].stampAvatar = res.data.imageUrl;
                            setStampSize(dt);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    

   

    function _renderBodyStampSize() {
        return (
            <React.Fragment>
                <tbody>
                    {stampSize.length > 0 && stampSize.map((item , idx)=>{
                        return <tr className={idx}>

                            <td style={{ width: 150, textAlign: "center",backgroundColor: '#8A8A8A', position: 'relative' }}>
                                {item.isUpdate || item.isAdd ? 
                                 (
                                    <div>
                                        {item.stampAvatar ? (
                                            <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                <img style={{ maxWidth: 150, maxHeight: 150 }} src={item.stampAvatar} />
                                                <span
                                                    style={{ position: "absolute", top: 10, right: 10, fontSize: 30, lineHeight: 0.5 }}
                                                    onClick={() => {
                                                        let dt = [...stampSize];
                                                        dt[idx].stampAvatar = "";
                                                        setStampSize(dt);
                                                    }}
                                                >
                                                    &times;
                                                </span>
                                            </label>
                                        ) : (
                                            ""
                                        )}
                                        <input
                                            style={{ width: 90 }}
                                            accept="image/*"
                                            hidden={item.stampAvatar ? true : false}
                                            type="file"
                                            id={`upload-${idx}`}
                                            onChange={(e) => {
                                                handleUploadFile(e, idx, stampSize);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <img style={{ maxWidth: 150, maxHeight: 150 }} src={item.stampAvatar} />
                                )}
                            </td>
                            <td>{item.sizeName}</td>
                            <td>{item.colorName}</td>
                            <td style={{ width: 400 }}>
                                {item.isAdd ? (
                                    <TFUSelect
                                        className="select"
                                        options={dataDropDownStamp}
                                        placeholder="--Chọn--"
                                        value={item.productVariantId}
                                        onChanged={(value) => {
                                            let dt = [...stampSize];
                                            dt[idx].productVariantId = value;
                                            setStampSize(dt);
                                        }}
                                />
                                ) : (
                                    item.sellerSKU
                                )}
                            </td>
                            <td style={{ width: 150 }}>
                                {item.isUpdate || item.isAdd ? (
                                    <input
                                        type="text"
                                        className="input-in-table"
                                        placeholder="Nhập mã màu"
                                        value={item.barcodeColor}
                                        onChange={(e) => {
                                            let dt = [...stampSize];
                                            dt[idx].barcodeColor = e.target.value;
                                            setStampSize(dt);
                                        }}
                                />
                                ) : (
                                    item.barcodeColor
                                )}
                            </td>
                            <td style={{ width: 200, textAlign: "center" }}>
                                {item.isUpdate || item.isAdd ? (
                                    <button
                                        className={`btn btn-custom m-1  ${IsDisableStampSize(idx) ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!IsDisableStampSize(idx)) handleCreateOrUpdateStampSize(idx);
                                        }}
                                    >
                                        Lưu
                                    </button>
                                ) :  <button
                                        className={`btn btn-custom }`}
                                        onClick={() => {
                                            let dt = [...stampSize];
                                            dt[idx].isUpdate = true;
                                            setStampSize(dt);
                                        }}
                                    >
                                        Sửa
                                    </button>}
                                <button
                                    className={"btn btn-danger m-1"}
                                    onClick={() => {
                                        handleDeleteStampSize(item.productVariantId, idx, item.isAdd);
                                    }}
                                >
                                    Xóa
                                </button>
                            
                            </td>
                        </tr>
                    })}
                </tbody>
                <tbody>
                    <tr>
                        {selectId > 0 && (
                            <td colSpan={99}>
                                <div
                                    className="add-item"
                                    style={{
                                        color: "#64C5B1",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                    }}
                                    onClick={() => {
                                        handleAddItemStamp();
                                    }}
                                >
                                    Thêm tem +
                                </div>
                            </td>
                        )}
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }
    function _renderActionScriptStep() {
        return <div className="mb-3 text-title">Quản lý tem</div>;
    }
    async function handleAddItemStamp() {
        const newScriptStep = JSON.parse(JSON.stringify(modelStamp));
        newScriptStep.productId = selectId;
        setStampSize([...stampSize, newScriptStep]);
    }
    function IsDisableStampSize(idx) {
        if (stampSize[idx].productId <= 0 || stampSize[idx].productVariantId <= 0 || !stampSize[idx].stampAvatar
            || !stampSize[idx].barcodeColor ) {
            return true;
        }

        return false;
    }
    async function handleCreateOrUpdateStampSize(idx) {
        setBusy(true);
        let dataItem = { ...stampSize[idx] };
        let [err, dt] = await TemSizeServices.CreateUpdateStampSize(dataItem);
        if (!err && dt) {
            getStampSize();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    async function handleDeleteStampSize(id, idx, add) {
        if (!add) {
           
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await TemSizeServices.DeleteStampSize(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getStampSize();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...stampSize];
            newData.splice(idx, 1);
            setStampSize(newData);
        }
    }


    //Type color
    const addTypeColor = () => {
        setTypeColor([...typeColor, {
            id: 0,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        }])
    }

    const handleAddUpdateTypeColor = async (item) =>{
        if( item.width <= 0 || item.height <= 0 
        ||item.x < 0 || item.y < 0)
        {
            Notify(NOTIFY.WARNING, NOTIFY.WARNING , "Kiểm tra lại dữ liệu");
        }else{
            setBusy(true);
            let [err, dt] = await TemSizeServices.CreateUpdateSizeStampBarcodeColor(item);
            if (!err && dt) {
                getStampBarcodeColor();
                setBusy(false);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                setBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
        }
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <div className="w-100">
                <h4>Tùy chỉnh barcode</h4>
                <div className="type_color_list">
                    {typeColor && typeColor.length > 0 && typeColor.map((item, idx)=>{
                        return <div key={idx} className="type_color_item">
                            <div className="row">
                                <span>Tọa độ X</span>
                                <input type="number" value={item.x} 
                                onChange={(e)=>{
                                    let dt = [...typeColor];
                                    dt[idx].x = e.target.value;
                                    setTypeColor(dt);
                                }} />
                                <span>Chiều dài (mm)</span>
                                <input type="number" value={item.width} 
                                onChange={(e)=>{
                                    let dt = [...typeColor];
                                    dt[idx].width = e.target.value;
                                    setTypeColor(dt);
                                }} />

                            </div>
                            <div className="row">
                                <span>Tọa độ Y</span>
                                <input type="number" value={item.y} 
                                onChange={(e)=>{
                                    let dt = [...typeColor];
                                    dt[idx].y = e.target.value;
                                    setTypeColor(dt);
                                }} />
                                <span>Chiều rộng (mm)</span>
                                <input type="number" value={item.height} 
                                onChange={(e)=>{
                                    let dt = [...typeColor];
                                    dt[idx].height = e.target.value;
                                    setTypeColor(dt);
                                }} />
                            </div>
                            <button className="btn btn-custom" onClick={()=>{
                                handleAddUpdateTypeColor(item)
                            }}>Lưu</button>
                        </div>
                    })}
                </div>
                {typeColor && typeColor.length < 1 && <span
                    style={{
                        color: "#64C5B1",
                        fontSize: "16px",
                        fontWeight: 600,
                        cursor: "pointer",
                        marginBottom: "10px",
                    }}
                onClick={()=>{
                    addTypeColor()
                }}>Thêm +</span>}
            </div>
            <div className="w-30">
                <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
            </div>

            <div className="w-70">
                <CommonTable data={stampSize} paging={pagingStampSize} hideSearchBasicButton renderAction={_renderActionScriptStep} renderHeader={_renderHeaderStampSize} renderBody={_renderBodyStampSize} ref={tableScriptStampSize} />
            </div>
        </StyleBody>
    );
};
const StyleBody = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }
    .w-100 {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #fff;

        h4{
            color: #444;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
        }

        .type_color_list{
            display: flex;

            .type_color_item{
                margin-right: 40px;

                input{
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    height: 26px;
                    width: 100px;
                    &[type="number"]{
                        width: 50px;
                    }

                    ::placeholder {
                        opacity: 0.3;
                    }
                }

               
                .row{
                    margin-bottom: 10px;

                    input{
                        width: 150px;
                    }

                    span{
                        margin-left: 10px;
                        width: 120px;
                        display: block;
                    }
                }
            }
        }
    }


    .w-30 {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
    .w-70 {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    
`;
export default StampManagement;
