import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../../based/CommonTable";
import { NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import Request from "../../based/Request";
import CheckBox from "../../based/inputs/CheckBox";
import TFUSelect from "../../based/inputs/TFUSelect";
import MarketingServices from "../../based/services/MarketingServices";
import { Modal } from "react-bootstrap";
import Common from "../../based/Common";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};
const IMAGE_POSITION = {
    BANNER: 0,
    POSTER: 1,
    WHEEL: 2
}

const modelImage = {
    id: 0,
    imageUrl: '',
    name: "",
    imageType: 0,
};
const modelImageVariant = {
    id: 0,
    imageUrl: '',
    name: "",
    marketingImageId: 0,
    isAdd: true
}
const ModalAddUpdateImage = ({tabImage, isShow , onClose}) => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [selectId, setSelectId] = useState(0);
    const [tab , setTab] = useState()

    useEffect(() => {
        setBusy(false);
        getData(tabImage);
        setTab(tabImage)
    }, []);

    

    async function getData(type) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetImageByType(type);
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

   
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th></th>
                    <th>Ảnh</th>
                    <th>Tên</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 40, textAlign: "center" }}>
                                    {item.id > 0 && (
                                        <CheckBox
                                            checked={item.checked}
                                            name={`${item.id}`}
                                            label=" "
                                            onCheckedChange={(name, value) => {
                                                let dt = [...data];
                                                dt.map((x) => (x.checked = false));
                                                dt[idx].checked = value;
                                                setData(dt);
                                                if (value) {
                                                    setSelectId(item.id);
                                                } else {
                                                    setSelectId(0);
                                                }
                                            }}
                                        />
                                    )}
                                </td>
                                <td style={{ width: 100, textAlign: "center" }}>
                               
                                    <div>
                                        {item.imageUrl ? (
                                            <label for={`upload-${idx}`} style={{ display: "block", width: "100%", height: "100%" }}>
                                                <img style={{ maxWidth: 100, maxHeight: 100 }} src={item.imageUrl} />
                                                
                                            </label>
                                        ) : (
                                            ""
                                        )}
                                        <input
                                            style={{ width: 90 }}
                                            accept="image/*"
                                            hidden={item.imageUrl ? true : false}
                                            type="file"
                                            id={`upload-${idx}`}
                                            onChange={(e) => {
                                                handleUploadFile(e, idx, data);
                                            }}
                                        />
                                    </div>
                                
                                </td>
                                <td style={{ width: 250 }}>
                                    {
                                        item.name
                                    }
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                   
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    async function handleUploadFile(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...data];
                            dt[index].imageUrl = res.data.imageUrl;
                            dt[index].name = file.name;
                            setData(dt);

                            handleCreateOrUpdate(index)
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    function _renderAction() {
        return <div className="mb-3 text-title">Hình ảnh</div>;
    }

    async function handleAddItem() {
        setData([ ...data, { ...modelImage }]);
    }

    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx], imageType: tab };
        let [err, dt] = await MarketingServices.CreateUpdateImage(dataItem);
        if (!err && dt) {
            getData(tab);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {

            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeleteImage(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(tab);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    const [imageVariant, setImageVariant] = useState([]);
    useEffect(() => {
        if (selectId > 0) {
            getImageVariant();
        } else {
            setImageVariant([]);
        }
    }, [selectId]);

    async function getImageVariant() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await MarketingServices.GetImageVariantByMarketingImageId(selectId);
        if (!err && data) {
            setImageVariant(data);
            setBusy(false);
        } else {
            setImageVariant([]);
            setBusy(false);
        }
    }
    async function handleUploadFileVariant(e, index, data) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            let dt = [...data];
                            dt[index].imageUrl = res.data.imageUrl;
                            dt[index].name = file.name;
                            setImageVariant(dt);

                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }
  

    

    

   

   
    
    async function handleAddItemStamp() {
        
        const obj = JSON.parse(JSON.stringify(modelImageVariant));
        if(imageVariant.length <= 0){
            obj.imageUrl = data.find(x => x.id == selectId).imageUrl;
            obj.name = data.find(x => x.id == selectId).name;
        }
        obj.marketingImageId = selectId;
        setImageVariant([...imageVariant, obj]);
    }
  
    async function handleCreateOrUpdateImageVariant(idx) {
        setBusy(true);
        let dataItem = { ...imageVariant[idx] , marketingImageId: selectId };
        let [err, dt] = await MarketingServices.CreateUpdateImageVariant(dataItem);
        if (!err && dt) {
            getImageVariant();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    async function handleDeleteImageVariant(id, idx, add) {
        if (!add) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeleteImageVariant(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getImageVariant();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...imageVariant];
            newData.splice(idx, 1);
            setImageVariant(newData);
        }
    }




    return (
         <Modal show={isShow} onHide={onClose} size={'lg'} id={Common.generateGuid()} enforceFocus={false}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>
                    Quản lý hình ảnh
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor:'#f3f3f3'}}>
                <StyleBody>
                    <Loading show={isBusy} msg={msg} />
                    <ul className="nav nav-tabs tabs-bordered w-100" style={{ alignSelf: "flex-start" }}>
                        <li className="nav-item">
                            <a className={tab === IMAGE_POSITION.BANNER ? "nav-link active" : "nav-link btn-light"} onClick={() => {
                                setTab(IMAGE_POSITION.BANNER)
                                getData(IMAGE_POSITION.BANNER);
                                setSelectId(0)
                            }}>
                                <span>Banner</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={tab === IMAGE_POSITION.POSTER  ? "nav-link active" : "nav-link btn-light"} onClick={() =>{
                                setTab(IMAGE_POSITION.POSTER)
                                getData(IMAGE_POSITION.POSTER);
                                setSelectId(0)
                            }}>
                                <span>Poster</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={tab === IMAGE_POSITION.WHEEL ? "nav-link active" : "nav-link btn-light"} onClick={() =>{
                                setTab(IMAGE_POSITION.WHEEL)
                                getData(IMAGE_POSITION.WHEEL);
                                setSelectId(0)
                            }}>
                                <span>Vòng quay</span>
                            </a>
                        </li>
                    </ul>
                    <div className="w-30">
                        <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
                    </div>

                    <div className="w-70">
                        <h5 className="text-title">Biến thể</h5>
                        <div className="list-item">
                            {imageVariant && imageVariant.length > 0 &&
                            imageVariant.map((item, idx)=>{
                                return <div key={idx} className="item-image-variant">
                                    <div className="w40per">
                                        {item.isAdd ? 
                                            !item.imageUrl ? 
                                                <input
                                                style={{ width: 90 }}
                                                accept="image/*"
                                                type="file"
                                                id={`upload-${idx}`}
                                                onChange={(e) => {
                                                    handleUploadFileVariant(e, idx, imageVariant);
                                                }}
                                            />: 
                                            <div>
                                                <img src={item.imageUrl}/>
                                                <div className="remove"
                                                onClick={()=>{
                                                    let data = [...imageVariant]
                                                    data[idx].imageUrl = ""
                                                    setImageVariant(data)
                                                }}
                                                >&times;</div>
                                            </div>
                                        :
                                        item.imageUrl ? <img src={item.imageUrl}/> : <img />}
                                        
                                    </div>
                                    <div className="w60per">
                                        <textarea value={item.name} onChange={(e)=>{
                                            let data = [...imageVariant]
                                            data[idx].name = e.target.value
                                            setImageVariant(data)
                                        }}  />

                                        {item.isAdd && <button style={{color: '#64c5b1'}} onClick={()=>{
                                            handleCreateOrUpdateImageVariant(idx)
                                        }}>Lưu</button>}
                                        <button onClick={()=>{
                                            handleDeleteImageVariant(item.id , idx , item.isAdd)
                                        }}>Xóa</button>
                                    </div>
                                </div>
                            })}
                            
                            {selectId > 0 && (
                                <div
                                    className="add-item"
                                    style={{
                                        color: "#64C5B1",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                    }}
                                    onClick={() => {
                                        handleAddItemStamp();
                                    }}
                                >
                                    Thêm tem +
                                </div>
                        )}
                        </div>
                    </div>
                </StyleBody>
            </Modal.Body>
        </Modal>
    );
};
const StyleBody = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;


    .list-item{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        .item-image-variant{
            width: calc(50% - 5px);
            min-width: calc(50% - 5px);
            max-width: calc(50% - 5px);
            margin-bottom: 10px;
            border: 1.5px solid #D9D9D9;
            background: #FFF;
            display: flex;

            .w40per{
                width: 40%;
                position: relative;
                background: #D9D9D9;


                img{
                    width: 100%;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                }
                .remove{
                    cursor: pointer;
                    top: 5px;
                    right: 5px;
                    font-size: 30px;
                    position: absolute;
                    line-height: 1;
                    color: red;
                }
            }

            .w60per{
                width: 60%;
                text-align: end;
                textarea{
                    width: 100%;
                    height: calc(100% - 32px);
                }

                button{
                    position: relative;
                    right: 0;
                    height: 20px;
                    border: none; 
                    outline: none;
                    background: transparent;
                    color: red;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }
    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }
    .w-100 {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #fff;

        h4{
            color: #444;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
        }

        .type_color_list{
            display: flex;

            .type_color_item{
                margin-right: 40px;

                input{
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    height: 26px;
                    width: 100px;
                    &[type="number"]{
                        width: 50px;
                    }

                    ::placeholder {
                        opacity: 0.3;
                    }
                }

               
                .row{
                    margin-bottom: 10px;

                    input{
                        width: 150px;
                    }

                    span{
                        margin-left: 10px;
                        width: 120px;
                        display: block;
                    }
                }
            }
        }
    }


    .w-30 {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
    .w-70 {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
        padding: 15px;
        background-color: #fff;

    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    
`;
export default ModalAddUpdateImage;
