import moment from "moment";
import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../../based/Common";
import { CARD_COLOR, CONSTANTS, MAINMENU, NOTIFY, VOUCHER_STATUS, VOUCHER_STATUS_NAME } from "../../based/Constants";
import { Block, Box } from "../../based/content/ct.elm";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import Button, { ButtonType } from "../../based/refactor/Button";
import CommonTable from "../../based/refactor/CommonTable";
import InputDatePicker from "../../based/refactor/InputDatePicker";
import TFUSelect from "../../based/refactor/TFUSelect";
import Request from "../../based/Request";
import CampaignServices from "../../based/services/CampaignServices";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import AttributeValue from "../components/AttributeValue";
import Config from "../Config";
const STATUS_OPTIONS = [
    {
        value: VOUCHER_STATUS.UPCOMING,
        label: VOUCHER_STATUS_NAME[VOUCHER_STATUS.UPCOMING],
    },
    {
        value: VOUCHER_STATUS.GOING_ON,
        label: VOUCHER_STATUS_NAME[VOUCHER_STATUS.GOING_ON],
    },
    {
        value: VOUCHER_STATUS.FINISHED,
        label: VOUCHER_STATUS_NAME[VOUCHER_STATUS.FINISHED],
    },
];
const CampaignMilestoneManagement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [datas, setDatas] = useState([]);
    const [dashboard, setDashboard] = useState({});
    const [paging, setPaging] = useState({ ...Common.Paging, status: -1 });
    const [currentMilestone, setCurrentMilestone] = useState({});
    const [isShowModal, setIsShowModal] = useState(false);

    useEffect(() => {
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, {
            mainMenu: MAINMENU.Campaign,
        });
        getDatas(paging);
        getDashboard();
    }, []);

    /** lấy thông tin dashboard */
    const getDashboard = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetDashboardCampaignMilestone();
        if (!error && data) {
            setDashboard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDashboard({});
            setIsLoading(false);
            setMsg(null);
        }
    };
    console.log(datas);
    /** lấy thông tin table */
    const getDatas = async (newPaging) => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetCampaignProductMilestonePaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    };

    /** handle sort column */
    const handleSortColumn = (columnName) => {
        let dir = "asc";
        if (columnName == paging.orderBy) dir = paging.orderDirection == "desc" ? "asc" : "desc";
        let newPaging = {
            ...paging,
            orderBy: columnName,
            orderDirection: dir,
        };
        getDatas(newPaging);
    };

    /** handle delete */
    const handleDelete = (campaign) => {
        if (campaign) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    const [err, data] = await CampaignServices.DeleteCampaignMilestone(campaign.id);
                    if (!err && data) {
                        resolve({
                            title: "Thành công",
                            msg: "Xóa thành công.",
                        });
                        setIsLoading(false);
                        setMsg("");
                        getDatas(paging);
                        getDashboard();
                    } else {
                        setIsLoading(false);
                        setMsg("");
                        Notify(NOTIFY.WARNING, "Có lỗi", err.data ? err.data : "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                    }
                });
            });
        } else {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Không thể xóa chiến dịch đã có đơn mua!");
        }
    };

    /** thêm config */
    const handleAddConfig = () => {
        setIsShowModal(true);
    };

    /** render trạng thái */
    const _renderStatus = (item) => {
        let cls = "rounded text-white status";
        let statusName = "Sắp diễn ra";
        switch (item.status) {
            case 3:
                cls += " label-success";
                statusName = "Đang diễn ra";
                break;
            case 2:
                cls += " label-danger";
                statusName = "Đã kết thúc";
                break;
            default:
                cls += " label-warning";
                break;
        }
        return (
            <div className={cls}>
                <span>{statusName}</span>
            </div>
        );
    };

    /** render header */
    const renderHeader = () => {
        let icon = <i className='fa fa-sort fa-sort-thead'></i>;
        return (
            <tr>
                <th className='text-center' width='50'>
                    STT
                </th>
                <th className='text-center'>Tên sản phẩm</th>
                <th className='text-center' width='150'>
                    Mock-up mặt trước
                </th>
                <th className='text-center' width='150'>
                    Mock-up mặt sau
                </th>
                <th className='text-center' width='100'>
                    Loại in
                </th>
                <th className='text-center' width='150'>
                    Ngày hiển thị
                </th>
                <th width='150' className='text-center' onClick={() => handleSortColumn("startTime")}>
                    Ngày bắt đầu &nbsp;
                    {paging.orderBy === "startTime" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "startTime" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                </th>
                <th width='150' className='text-center' onClick={() => handleSortColumn("endTime")}>
                    Ngày kết thúc &nbsp;
                    {paging.orderBy === "endTime" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "endTime" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                </th>
                <th className='text-center' width='150' onClick={() => handleSortColumn("status")}>
                    Trạng thái &nbsp;
                    {paging.orderBy === "status" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "status" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon}
                </th>
                <th className='text-center' width='100'></th>
            </tr>
        );
    };

    /** render thuộc tính */
    const _renderAttributes = (item) => {
        const attrArr = Common.GroupArray(item.attributes, "name");
        for (const key of attrArr.keys()) {
            let value = attrArr.get(key);
            const upgradeListAtt = [...new Map(value.map((item) => [item["value"], item])).values()];
            attrArr.set(key, upgradeListAtt);
        }
        const attrObj = {};
        for (const key of attrArr.keys()) {
            attrObj[key] = attrArr.get(key);
            for (const attr of attrObj[key]) {
                for (const ageProVarAtt of item.attributes) {
                    if (attr.value === ageProVarAtt.value) {
                        attr.checked = true;
                        break;
                    } else attr.checked = false;
                }
            }
        }
        return attrObj && <div className='d-flex flex-row flex-wrap justify-content-center'>{Object.keys(attrObj).map((key) => attrObj[key] && attrObj[key].map((item, idx) => <AttributeValue key={idx} keyName={key} value={item.value} />))}</div>;
    };

    /** render body */
    const renderBody = () => {
        return (
            <React.Fragment>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='vertical-align-middle'>
                                    {item.name}
                                    <p className='mb-0 white-space-nowrap'>
                                        SKU: <span className='badge badge-custom'>{item.sellerSKU}</span>
                                    </p>
                                    {item.attributes && item.attributes.length > 0 && _renderAttributes(item)}
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <img
                                        src={item.mockupFront}
                                        alt={item.name}
                                        className='variant-avatar cursor-pointer'
                                        onClick={(e) => {
                                            let setId = item.setId;
                                            let productId = item.agencyProductVariantId;
                                            window.open(`${Request.HOME_URL}/product-detail-page/${setId}/${productId}`, "_blank");
                                        }}
                                    />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <img
                                        src={item.mockupBack}
                                        alt={item.name}
                                        className='variant-avatar cursor-pointer'
                                        onClick={(e) => {
                                            let setId = item.setId;
                                            let productId = item.agencyProductVariantId;
                                            window.open(`${Request.HOME_URL}/product-detail-page/${setId}/${productId}`, "_blank");
                                        }}
                                    />
                                </td>
                                <td className='text-center vertical-align-middle'>{item.printTypeName}</td>
                                <td className='text-center vertical-align-middle'>{item.showTimeDisplay}</td>
                                <td className='text-center vertical-align-middle'>{item.startTimeDisplay}</td>
                                <td className='text-center vertical-align-middle'>{item.endTimeDisplay}</td>
                                <td className='text-center vertical-align-middle'>{_renderStatus(item)}</td>
                                <td className='text-center vertical-align-middle'>
                                    {item.status != 2 && (
                                        <React.Fragment>
                                            <i
                                                className='fa fa-pencil cursor-poiter text-primary'
                                                title='Cập nhật'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentMilestone(item);
                                                    setIsShowModal(true);
                                                }}
                                            ></i>
                                            &nbsp;|
                                        </React.Fragment>
                                    )}
                                    &nbsp;
                                    <React.Fragment>
                                        <i className='fa fa-trash cursor-poiter text-danger' title='Xóa' onClick={() => handleDelete(item)}></i>
                                    </React.Fragment>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td className='text-center' colSpan='100'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcum menu={["Trang chủ", "Giá tốt mua ngay"]} title='Giá tốt mua ngay' />
            <Box>
                <div className='box__content'>
                    <Block flexBasis='33%' bgColor={CARD_COLOR.Green}>
                        <div className='header'>
                            <h6 style={{ color: "#fff" }}>Cấp bậc hiện tại</h6>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                {dashboard.level ? (
                                    <h2>
                                        Level {dashboard.level.agencyLevel} || {dashboard.level.tierName}{" "}
                                    </h2>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </Block>
                    <Block flexBasis='33%' bgColor={CARD_COLOR.Blue}>
                        <div className='header'>
                            <h6 style={{ color: "#fff" }}>Hạn mức ản phẩm được đăng ký tham gia</h6>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                <h2>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.level ? dashboard.level.milestoneRegisteredProductLimit : 0} />
                                </h2>
                            </div>
                        </div>
                    </Block>
                    <Block flexBasis='33%' bgColor={CARD_COLOR.Yellow}>
                        <div className='header'>
                            <h6 style={{ color: "#fff" }}>Số sản phẩm đã đăng ký</h6>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                <h2>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.totalProductsRegistered ? dashboard.totalProductsRegistered : 0} />
                                </h2>
                            </div>
                        </div>
                    </Block>
                </div>
            </Box>
            <Box minHeight='600px'>
                <div className='box__content'>
                    <Block flexBasis='100%' style={{ marginTop: "20px" }}>
                        <div className='flex__space-between mb-10' style={{ width: "90%" }}>
                            <div className='flex__column flex__basis-20'>
                                <label>Trạng thái:</label>
                                <TFUSelect name='status' className='item__input__select' options={STATUS_OPTIONS} value={paging.status} placeholder='--Tất cả---' onChanged={(value) => setPaging({ ...paging, status: value != null ? value : -1 })} />
                            </div>
                            <div className='flex__column flex__basis-30'>
                                <label>Thời gian áp dụng:</label>
                                <div className='flex__row'>
                                    <InputDatePicker dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} /> &nbsp;
                                    <InputDatePicker dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                            <div className='flex__column flex__basis-30'></div>
                        </div>
                        <div className='box__header'>
                            <Button btnType={ButtonType.Search} className='mx-1' label='Tìm kiếm' onClick={() => getDatas(paging)} />
                            <Button btnType={ButtonType.Add} className='mx-1' label='Thêm mới' onClick={() => handleAddConfig()} />
                        </div>
                    </Block>
                </div>
                <CommonTable renderHeader={renderHeader} renderBody={renderBody} paging={paging} onFilter={(paging) => getDatas(paging)} />
            </Box>
            {isShowModal && (
                <Config
                    campaignId={currentMilestone ? currentMilestone.id : 0}
                    currentMilestone={currentMilestone}
                    isShowModal={isShowModal}
                    registeredProductLimit={dashboard.totalProductsRegistered}
                    onClose={() => {
                        setIsShowModal(false);
                        setCurrentMilestone(null);
                    }}
                    onSuccess={() => {
                        getDatas(paging);
                        setIsShowModal(false);
                        setCurrentMilestone(null);
                        getDashboard();
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default CampaignMilestoneManagement;
