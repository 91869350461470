import React, { useEffect, useState } from 'react';
import Loading from '../../based/Loading';
import Request from '../../based/Request';

function SoldProducts(props) {
    const [busy, setBusy] = useState(false)
    const [data, setData] = useState(new Map())
    const [headerDates, setHeaderDates] = useState([])
    useEffect(() => {
        _fetchData()
    }, [])

    const _fetchData = () => {
        setBusy(true)
        Request.Get('/api/finance/last-sold-products?mock=true')
            .then(res => {
                setBusy(false)
                if (res.success) {
                    __populateData(res.data);
                }
            })
            .catch(err => setBusy(false))
    }
    const __populateData = (listProducts) => {
        let mapSkus = new Map();
        let header = ["-1"];
        listProducts.map(item => {
            if (header.indexOf(item.soldShortDate) < 0) {
                header.push(item.soldShortDate);
            }

            if (mapSkus.has(item.id)) {
                let productSet = mapSkus.get(item.id);
                if (productSet.has(item.soldShortDate)) {
                    productSet.get(item.soldShortDate).push(item)
                } else {
                    productSet.set(item.soldShortDate, [item]);
                }
            } else {
                let value = new Map();
                value.set(item.soldShortDate, [item]);
                mapSkus.set(item.id, value)
            }
        })
        setHeaderDates(header);
        setData(mapSkus);
    }
    let prods = Array.from(data.keys());
    return (
        <div className="row">
            <div className="col-12 bg-white">
                <h5>Sản phẩm đã bán trong tháng</h5>
                <div className='table-responsive'>
                    <Loading show={busy} />
                    <table className='table table-bordered mb-0'>
                        <thead>
                            <tr>
                                <th className='text-center' >Mockup</th> {/* avatar */}
                                <th className='text-center' >SKU</th> {/* SKU */}
                                {
                                    headerDates.map((h, i) => {
                                        if (i == 0)
                                            return <th className='text-center' key={i}>Tổng cộng</th>
                                        return <th className='text-center' key={i}>{h}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                prods.map((p) => {
                                    let mapDates = data.get(p).values();
                                    let dateItemsArray = Array.from(mapDates);
                                    let firstArray = dateItemsArray[0];
                                    return <tr key={p}>
                                        <td className='text-center' height='60'> <img src={firstArray[0].avatar}
                                            alt={firstArray[0].avatar} style={{ height: '100%' }} /></td>
                                        <td>{firstArray[0].sku}</td>
                                        {
                                            headerDates.map((h, i) => {
                                                let qty = 0;
                                                if (i == 0) {
                                                    for (let i = 0; i < dateItemsArray.length; i++) {
                                                        const element = dateItemsArray[i];
                                                        for (let j = 0; j < element.length; j++) {
                                                            qty += element[j].quantity;
                                                        }
                                                    }
                                                } else {
                                                    qty = data.get(p).has(h) ? data.get(p).get(h).map(m => m.quantity).reduce((v, c) => v + c, 0) : 0;
                                                }
                                                return <td className='text-right' key={h}>{qty}</td>
                                            })
                                        }
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default SoldProducts;