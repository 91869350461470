import React, { useState, useEffect } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import PrintServices from "../based/services/PrintServices";
import { Layout } from "../layout/Layout";
import GTXPrinterModal from "./components/GTXPrinterModal";

export default function MangeGTXPrinter() {
    const [isBusy, setBusy] = useState(true);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        getDatas({ ...INITPAGING });
    }, []);

    const getDatas = async (paging) => {
        setBusy(true);
        let [err, data] = await PrintServices.GetPageGTXConfig(paging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setPaging({ ...INITPAGING });
            setDatas([]);
        }
        setBusy(false);
    };

    const _renderAction = () => {
        return (
            <button
                className='btn btn-custom'
                onClick={(e) => {
                    setSelectId(0);
                    setIsShowModal(true);
                }}
            >
                <i className='fa fa-edit mr-3'></i>
                Thêm mới
            </button>
        );
    };

    const _removeConfig = async (id) => {
        setBusy(true);
        let [err, data] = await PrintServices.RemoveGTXConfig(id);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xoá thành công");
            getDatas({ ...paging });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xoá thất bại");
        }

        setBusy(false);
    };

    const _renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th className='center'>Loại in</th>
                    <th className='center'>Platen Size</th>
                    <th className='center'>Transparent Color</th>
                    <th className='center'>Brightness</th>
                    <th className='center'>Contrast</th>
                    <th className='center'>Sản phẩm</th>
                    <th className='center'>Hành động</th>
                </tr>
            </thead>
        );
    };

    const _renderBody = () => {
        return datas && datas.length > 0 ? (
            <tbody>
                {datas.map((item, idx) => {
                    return (
                        <tr key={idx}>
                            <td>{item.byInkName}</td>
                            <td>{item.byPlatenSizeName}</td>
                            <td>{item.bTransColor ? "Bật" : "Tắt"}</td>
                            <td>{item.byBrightness}</td>
                            <td>{item.byContrast}</td>
                            <td>{item.productId == 0 ? "Mặc định" : item.productName}</td>
                            <td className='center'>
                                <button
                                    className='btn btn-custom'
                                    onClick={(e) => {
                                        setSelectId(item.id);
                                        setIsShowModal(true);
                                    }}
                                >
                                    <i className='fa fa-edit mr-3'></i>
                                    Chỉnh sửa
                                </button>

                                <button
                                    className='btn btn-danger ml-2'
                                    onClick={(e) => {
                                        _removeConfig(item.id);
                                    }}
                                >
                                    Xoá
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colSpan='5' className='center'>
                        Không có dữ liệu
                    </td>
                </tr>
            </tbody>
        );
    };

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <Breadcrumbs currentPage='GTX Printer' />
            <CommonTable data={datas} paging={paging} searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
            {isShowModal && (
                <GTXPrinterModal
                    id={selectId}
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    saveSucceed={() => {
                        setIsShowModal(false);
                        getDatas({ ...paging });
                    }}
                />
            )}
        </React.Fragment>
    );
}
