import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY, PLATFORM, PLATFORM_NAME } from "../based/Constants";
import { Notify } from "../based/Notify";
import PushingServices from "../based/services/PushingServices";
import Shopee from "./assets/Shopee.png";
import Lazada from "./assets/Lazada.png";
import Tiktok from "./assets/Tiktok.png";
import TFUSelect from "../based/inputs/TFUSelect";

const PLATFORM_PUSH = [
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Tiktok, label: PLATFORM_NAME.Tiktok },
];

const Artwork_Type = {
    Front: 1,
    Back: 2,
};

const PushingProductMultiPlatformModel = {
    appCategoryId: 0,
    opfCategoryId: 0,
    platform: PLATFORM.Shopee,
    platformName: "",
    photoshopId: 0,
    platformBreadcrumb: "",
    photoshopFiles: [],
    photoshopProducts: [],
    shopOptions: [],
    shopId: "",
    listOPFCategory: [],
};

const PushingProductMultiModel = {
    id: 0,
    pushingProductId: 0,
    name: "",
    categoryId: 0,
    platformCategoryId: 0,
    platformBreadcrumb: "",
    brand: "Ranus",
    content: "",
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    printType: 0,
    photoshopId: 0,
    colorCode: "",
    colorName: "",
    appProductVariantId: "",
    platform: PLATFORM.Undefined,
    shopId: "",
    pushingProduct: {},
    pushingPhotoshopProducts: [],
    artworkType: Artwork_Type.Front,
    artworkCount: 1,
    multiPlatformModels: [PushingProductMultiPlatformModel],
    designId: 0,
    avatar: "",
};

function PushingManageMultiDetail(props) {
    const { designId } = props.match.params;
    const [model, setModel] = useState(PushingProductMultiModel);

    useEffect(() => {
        if (designId) {
            _getProductCustomizeMultiById(designId);
        }
    }, [designId]);

    async function _getProductCustomizeMultiById(designId) {
        let [err, data] = await PushingServices.GetProductCustomizesMultiById(designId);
        if (!err && data) {
            setModel(data);
        } else {
            setModel(null);
            Notify(NOTIFY.ERROR, "Lỗi", err.message);
        }
    }

    //lấy thông tin sản phẩm theo photoshopId
    async function _getPhotoshopProduct(md) {
        let [err, data] = await PushingServices.GetPushingPhotoshopProductDetail(md);
        if (!err && data) {
            data = data.map((item) => {
                item.checked = true;
                return item;
            });
            return data;
        } else {
            return [];
        }
    }

    async function _createUpdatePushProduct() {
        let md = { ...model };
        md.designId = +designId;
        md.multiPlatformModels = md.multiPlatformModels.filter((x) => x.checked);
        if (md.multiPlatformModels && md.multiPlatformModels.length > 0) {
            for (var multi of md.multiPlatformModels) {
                // multi.photoshopProducts = multi.photoshopProducts.filter((x) => x.checked);

                multi.shopOptions = multi.shopOptions.filter((x) => x.isChecked);
                if (multi.shopOptions && multi.shopOptions.length > 0) {
                    for (var shop of multi.shopOptions) {
                        shop.photoshopProducts = shop.photoshopProducts.filter((x) => x.checked);
                    }
                }
            }
        }

        console.log("md", md);
        // let [err, data] = await PushingServices.CreateUpdatePushProductMulti(md);
        // if (!err && data) {
        //     Notify(NOTIFY.SUCCESS, "Thành công", "Đẩy sản phẩm thành công, hệ thống sẽ tự động cập nhật sản phẩm sau ít phút");
        //     setTimeout(() => {
        //         window.close();
        //     }, 1000);
        // } else {
        //     Notify(NOTIFY.ERROR, "Lỗi", err.message);
        // }
    }

    function getImage(platform) {
        switch (platform) {
            case PLATFORM.Shopee:
                return <img src={Shopee} />;
            case PLATFORM.Lazada:
                return <img src={Lazada} />;
            case PLATFORM.Tiktok:
                return <img src={Tiktok} />;
            default:
                return "";
        }
    }

    if (!model) return <div>Hiện tại không có dữ liệu</div>;
    return (
        <Wrapper>
            <div className="form-total">
                <section className="product-overview">
                    <div className="product-avatar">
                        <img src={model.avatar} />
                    </div>
                    <div className="product-info">
                        <div className="label-product">Sản phẩm muốn đẩy</div>
                        <div className="product-name">{model.name}</div>
                        <div className="product-name">Màu {model.colorName}</div>
                        <div className="badge-customize">Tự thiết kế</div>
                    </div>
                </section>

                <section className="platform-section">
                    <div className="label-form">
                        <label htmlFor="" className="required">
                            CHỌN SÀN
                        </label>
                        <div className="sub-instruction">(Có thể chọn nhiều)</div>
                    </div>

                    <div className="platform">
                        {model.multiPlatformModels &&
                            model.multiPlatformModels.map((item, index) => {
                                const listOptions = item.listOPFCategory.map((x) => {
                                    return {
                                        value: x.opfCategoryId,
                                        label: x.platformBreadcrumb,
                                    };
                                });

                                return (
                                    <div className="platform-element" key={index}>
                                        <div className="platform-left">
                                            <div className="checkbox-area">
                                                <input
                                                    type="checkbox"
                                                    checked={item.checked}
                                                    onChange={(e) => {
                                                        let md = { ...model };
                                                        let multi = md.multiPlatformModels[index];
                                                        multi.checked = e.target.checked;
                                                        setModel(md);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="platform-right">
                                            <div
                                                className="image-icon"
                                                onClick={(e) => {
                                                    let md = { ...model };
                                                    let multi = md.multiPlatformModels[index];
                                                    multi.checked = !multi.checked;
                                                    setModel(md);
                                                }}
                                            >
                                                {getImage(item.platform)}
                                            </div>
                                            {item.checked && (
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Chọn</th>
                                                            <th>Gian hàng</th>
                                                            <th>Ngành hàng</th>
                                                            <th>Danh mục</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.shopOptions &&
                                                            item.shopOptions.length > 0 &&
                                                            item.shopOptions.map((shop, idx) => {
                                                                const lstShopCat = shop.listShopCatOption.map((x) => {
                                                                    return {
                                                                        value: x.id,
                                                                        label: x.name,
                                                                    };
                                                                });

                                                                return (
                                                                    <tr key={idx}>
                                                                        <td className="select">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={shop.isChecked}
                                                                                onChange={(e) => {
                                                                                    let md = { ...model };
                                                                                    let multi = md.multiPlatformModels[index];
                                                                                    let sh = multi.shopOptions;
                                                                                    sh[idx].isChecked = e.target.checked;
                                                                                    setModel(md);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="shop">
                                                                            <span
                                                                                className="shop-name"
                                                                                onClick={(e) => {
                                                                                    let md = { ...model };
                                                                                    let multi = md.multiPlatformModels[index];
                                                                                    let sh = multi.shopOptions;
                                                                                    sh[idx].isChecked = !sh[idx].isChecked;
                                                                                    setModel(md);
                                                                                }}
                                                                            >
                                                                                {shop.label}
                                                                            </span>
                                                                            {/* <span className="badge badge-warning">Đã đẩy</span> */}
                                                                        </td>
                                                                        <td>
                                                                            <TFUSelect
                                                                                placeholder="Chọn ngành hàng"
                                                                                options={listOptions}
                                                                                value={shop.opfCategoryId}
                                                                                onChanged={(newValue) => {
                                                                                    let md = { ...model };
                                                                                    let multi = md.multiPlatformModels[index];
                                                                                    let sh = multi.shopOptions;
                                                                                    sh[idx].opfCategoryId = newValue;
                                                                                    setModel(md);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <TFUSelect
                                                                                placeholder="Chọn danh mục"
                                                                                options={lstShopCat}
                                                                                value={shop.shopCatId}
                                                                                onChanged={(newValue) => {
                                                                                    let md = { ...model };
                                                                                    let multi = md.multiPlatformModels[index];
                                                                                    let sh = multi.shopOptions;
                                                                                    sh[idx].shopCatId = newValue;
                                                                                    setModel(md);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </section>

                <section className="product-info">
                    <div className="label-form">
                        <label htmlFor="" className="required">
                            THÔNG TIN SẢN PHẨM
                        </label>
                    </div>

                    <div className="product-detail">
                        <div className="form-product">
                            <label htmlFor="" className="required">
                                Tên sản phẩm
                            </label>
                            <input
                                type="text"
                                placeholder="Nhập tên sản phẩm"
                                value={model.name}
                                onChange={(e) => {
                                    setModel({ ...model, name: e.target.value });
                                }}
                            />
                        </div>

                        <div className="form-product">
                            <label htmlFor="" className="required">
                                Thương hiệu
                            </label>
                            <input type="text" className="disabled" placeholder="Nhập tên thương hiệu" value={"Ranus"} readOnly />
                        </div>
                        <div className="form-product">
                            <label htmlFor="" className="required">
                                Mô tả sản phẩm
                            </label>
                            <textarea
                                type="text"
                                placeholder="Mô tả sản phẩm"
                                rows="5"
                                value={model.content}
                                onChange={(e) => {
                                    setModel({ ...model, content: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-product">
                            <label htmlFor="" className="required">
                                Kích thước
                            </label>
                            <div className="form-package">
                                <input
                                    type="number"
                                    placeholder="Dài"
                                    value={model.length}
                                    onChange={(e) => {
                                        setModel({ ...model, length: +e.target.value });
                                    }}
                                    className="mx-2"
                                />
                                <SVGX />
                                <input
                                    type="number"
                                    placeholder="Rộng"
                                    value={model.width}
                                    onChange={(e) => {
                                        setModel({ ...model, width: +e.target.value });
                                    }}
                                    className="mx-2"
                                />
                                <SVGX />
                                <input
                                    type="number"
                                    placeholder="Cao"
                                    value={model.height}
                                    onChange={(e) => {
                                        setModel({ ...model, height: +e.target.value });
                                    }}
                                    className="mx-2"
                                />
                            </div>
                        </div>

                        <div className="form-product">
                            <label htmlFor="" className="required">
                                Cân nặng
                            </label>
                            <div className="form-package">
                                <input
                                    type="number"
                                    placeholder="Dài"
                                    value={model.weight}
                                    onChange={(e) => {
                                        setModel({ ...model, weight: +e.target.value });
                                    }}
                                />
                            </div>
                        </div>

                        {model.artworkCount > 1 && (
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Mặt hiển thị mặc định
                                </label>
                                <div className="form-radio-box">
                                    <input
                                        type="radio"
                                        id="front"
                                        name="front"
                                        checked={model.artworkType === Artwork_Type.Front}
                                        onClick={() => {
                                            setModel({ ...model, artworkType: Artwork_Type.Front });
                                        }}
                                    />
                                    <label htmlFor="front">Mặt trước</label>
                                    <input
                                        type="radio"
                                        id="back"
                                        name="back"
                                        checked={model.artworkType === Artwork_Type.Back}
                                        onClick={() => {
                                            setModel({ ...model, artworkType: Artwork_Type.Back });
                                        }}
                                    />
                                    <label htmlFor="back">Mặt sau</label>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                <section className="product-push">
                    <div className="label-form">
                        <label htmlFor="" className="required">
                            TÙY CHỌN RENDER HÌNH VÀ GIÁ
                        </label>
                    </div>
                    {model.multiPlatformModels &&
                        model.multiPlatformModels.map((item, index) => {
                            if (!item.checked) return <div></div>;

                            //nếu không có shop nào được check thì ignore
                            const checkedShopOptions = item.shopOptions.filter((x) => x.isChecked);
                            if (!checkedShopOptions || checkedShopOptions.length == 0) return "";

                            return checkedShopOptions.map((shop, i) => {
                                let checkedItem = item.photoshopFiles ? item.photoshopFiles.find((x) => x.id === shop.photoshopId) : null;
                                let photoshopFileName = checkedItem ? checkedItem.name : "";

                                return (
                                    <div className="form-card row" key={i}>
                                        <div className="image-icon">
                                            {getImage(item.platform)} - {shop.label}
                                        </div>
                                        <div className="form-render col-md-12">
                                            <div className="render-item-body">
                                                <div className="render-item-detail">{photoshopFileName}</div>
                                                <div className="render-list">
                                                    {item.photoshopFiles &&
                                                        item.photoshopFiles.map((ele, idx) => {
                                                            return (
                                                                <div
                                                                    className={`render-item ${ele.id === shop.photoshopId ? "active" : ""}`}
                                                                    key={idx}
                                                                    onClick={async () => {
                                                                        let md = { ...model };
                                                                        let platform = md.multiPlatformModels[index];
                                                                        let listShop = platform.shopOptions;
                                                                        let shop = listShop[i];
                                                                        shop.photoshopId = ele.id;

                                                                        //lấy ra thông tin sản phẩm theo photoshopId
                                                                        let pushModel = {
                                                                            designId: designId,
                                                                            photoshopId: ele.id,
                                                                            platform: item.platform,
                                                                            shopId: shop.value,
                                                                        };
                                                                        let psProducts = await _getPhotoshopProduct(pushModel);
                                                                        shop.photoshopProducts = psProducts;
                                                                        setModel(md);
                                                                    }}
                                                                >
                                                                    <img src={ele.thumbnail} />
                                                                    {ele.id == shop.photoshopId && (
                                                                        <div className="background">
                                                                            <div className="icon">
                                                                                <SVGCheck />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-avatar col-md-6 row">
                                            <label htmlFor="" className="required col-md-12">
                                                Avatar (Chọn 1)
                                            </label>
                                            <div className="product-list">
                                                {shop.photoshopProducts &&
                                                    shop.photoshopProducts.map((ele, idx) => {
                                                        return (
                                                            <div className="product-item" key={idx}>
                                                                <input
                                                                    type="radio"
                                                                    checked={ele.avatarChecked}
                                                                    onChange={(e) => {
                                                                        let newPhotoshopProducts = [...shop.photoshopProducts];
                                                                        newPhotoshopProducts.map((x) => {
                                                                            x.avatarChecked = false;
                                                                        });
                                                                        newPhotoshopProducts[idx].avatarChecked = e.target.checked;
                                                                        let md = { ...model };
                                                                        let platform = md.multiPlatformModels[index];
                                                                        let sh = platform.shopOptions[i];
                                                                        sh.photoshopProducts = newPhotoshopProducts;
                                                                        setModel(md);
                                                                    }}
                                                                />
                                                                <label htmlFor={ele.id}>{ele.appProductName}</label>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>

                                        <div className="form-product col-md-6 row">
                                            <label htmlFor="" className="required col-md-12">
                                                Sản phẩm hiển thị (Được chọn nhiều)
                                            </label>
                                            <div className="product-list">
                                                {shop.photoshopProducts &&
                                                    shop.photoshopProducts.map((ele, idx) => {
                                                        return (
                                                            <div className="product-item" key={idx}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={ele.checked}
                                                                    onChange={(e) => {
                                                                        let newPhotoshopProducts = [...shop.photoshopProducts];
                                                                        newPhotoshopProducts[idx].checked = e.target.checked;
                                                                        let md = { ...model };
                                                                        let platform = md.multiPlatformModels[index];
                                                                        let sh = platform.shopOptions[i];
                                                                        sh.photoshopProducts = newPhotoshopProducts;
                                                                        setModel(md);
                                                                    }}
                                                                />
                                                                <label htmlFor={ele.id}>{ele.appProductName}</label>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className="form-price col-md-12">
                                            <label htmlFor="">Giá và tồn kho theo dòng sản phẩm</label>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Thumbnail</th>
                                                        <th>Dòng sản phẩm</th>
                                                        <th>Size</th>
                                                        <th>Scale</th>
                                                        <th>Giá sản phẩm</th>
                                                        <th>Chi phí in</th>
                                                        <th>Giá gạch (Sell Price)</th>
                                                        <th>Giảm giá (Discount)</th>
                                                        <th>Giảm giá SIÊU RẺ (Lowcost Discount)</th>
                                                        <th>Giá bán (Sale Price)</th>
                                                        <th>Số lượng</th>
                                                        <th>Mã SKU</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shop.photoshopProducts &&
                                                        shop.photoshopProducts.map((ele, idx) => {
                                                            if (!ele.pushingPhotoshopVariants || !ele.checked) return;
                                                            var rowSpan = ele.pushingPhotoshopVariants.length;
                                                            return (
                                                                <React.Fragment>
                                                                    {ele.pushingPhotoshopVariants &&
                                                                        ele.pushingPhotoshopVariants.map((variant, i) => {
                                                                            var sellPrice = variant.sellPrice + variant.printCost;
                                                                            var salePrice = sellPrice - variant.discountBrandVoucher - variant.lowCostDiscount;
                                                                            return (
                                                                                <tr key={i}>
                                                                                    {i === 0 && (
                                                                                        <React.Fragment>
                                                                                            <td rowSpan={rowSpan} className="thumbnail-table">
                                                                                                <img src={ele.appProductAvatar} />
                                                                                            </td>
                                                                                            <td rowSpan={rowSpan}>{ele.appProductName}</td>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <td>{variant.sizeValue}</td>
                                                                                    <td>{variant.scale}</td>
                                                                                    <td>{Common.formatCurrency(variant.sellPrice)}</td>
                                                                                    <td>{Common.formatCurrency(variant.printCost)}</td>
                                                                                    <td>{Common.formatCurrency(sellPrice)}</td>
                                                                                    <td>{Common.formatCurrency(variant.discountBrandVoucher)}</td>
                                                                                    <td>{Common.formatCurrency(variant.lowCostDiscount)}</td>
                                                                                    <td>{Common.formatCurrency(salePrice)}</td>
                                                                                    <td>{variant.quantity}</td>
                                                                                    <td>{variant.sku}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                );
                            });
                        })}
                </section>

                <div className="form-button">
                    <button
                        className="btn btn-danger"
                        onClick={(e) => {
                            e.preventDefault();
                            window.close();
                        }}
                    >
                        Trở về
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            _createUpdatePushProduct();
                        }}
                    >
                        Đẩy sản phẩm
                    </button>
                </div>

                {/* <div className="form-card">
                    <h6>Thông tin muốn đẩy sản phẩm</h6>
                    {model.multiPlatformModels &&
                        model.multiPlatformModels.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="form-platform">
                                        <label htmlFor="" className="required">
                                            Chọn sàn ({item.platformName})
                                        </label>
                                        <div className="platform-list">
                                            <div className="platform-item">
                                                <input
                                                    type="checkbox"
                                                    id={item.platformName}
                                                    name={item.platformName}
                                                    checked={item.checked}
                                                    onClick={() => {
                                                        let md = { ...model };
                                                        let multiPlatformModels = [...md.multiPlatformModels];
                                                        multiPlatformModels[index].checked = !multiPlatformModels[index].checked;
                                                        if (!multiPlatformModels[index].checked) {
                                                            multiPlatformModels[index].shopId = "";
                                                        } else {
                                                            let firstCheck = item.shopOptions && item.shopOptions.length > 0 ? item.shopOptions[0] : null;
                                                            multiPlatformModels[index].shopId = firstCheck ? firstCheck.value : "";
                                                        }
                                                        setModel(md);
                                                    }}
                                                />
                                                <label htmlFor={item.platformName}>{item.platformName}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {item.checked && (
                                        <React.Fragment>
                                            <div className="form-shop">
                                                <label htmlFor="" className="required">
                                                    Chọn gian hàng ({item.platformName})
                                                </label>
                                                <div className="shop-list">
                                                    {item.shopOptions &&
                                                        item.shopOptions.map((ele, idx) => {
                                                            return (
                                                                <div className="shop-item" key={idx}>
                                                                    <input
                                                                        type="radio"
                                                                        id={ele.label}
                                                                        name={ele.label}
                                                                        checked={item.shopId == ele.value}
                                                                        onClick={(e) => {
                                                                            let md = { ...model };
                                                                            md.multiPlatformModels[index].shopId = ele.value;
                                                                            setModel(md);
                                                                        }}
                                                                    />
                                                                    <label htmlFor={ele.label}>{ele.label}</label>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                            <div className="form-product">
                                                <label htmlFor="" className="required">
                                                    Ngành hàng
                                                </label>
                                                <div className="list-platform">
                                                    {item.listOPFCategory.map((x, i) => {
                                                        return (
                                                            <div className="platform-row" key={i}>
                                                                <input
                                                                    type="radio"
                                                                    onClick={(e) => {
                                                                        let md = { ...model };
                                                                        let multi = md.multiPlatformModels[index];
                                                                        multi.appCategoryId = item.appCategoryId;
                                                                        multi.opfCategoryId = x.opfCategoryId;
                                                                        setModel(md);
                                                                    }}
                                                                    checked={x.opfCategoryId == item.opfCategoryId}
                                                                />
                                                                <input type="text" placeholder="Nhập tên ngành hàng" value={x.platformBreadcrumb} readOnly />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <hr />
                                </React.Fragment>
                            );
                        })}
                </div> */}
                {/* 
                <div className="form-card">
                    <h6>Thông tin sản phẩm</h6>
                    <div className="form-product">
                        <label htmlFor="" className="required">
                            Tên sản phẩm
                        </label>
                        <input
                            type="text"
                            placeholder="Nhập tên sản phẩm"
                            value={model.name}
                            onChange={(e) => {
                                setModel({ ...model, name: e.target.value });
                            }}
                        />
                    </div>
                    <div className="form-product">
                        <label htmlFor="" className="required">
                            Thương hiệu
                        </label>
                        <input type="text" placeholder="Nhập tên thương hiệu" value={"Ranus"} readOnly />
                    </div>
                    <div className="form-product">
                        <label htmlFor="" className="required">
                            Mô tả sản phẩm
                        </label>
                        <textarea
                            type="text"
                            placeholder="Mô tả sản phẩm"
                            rows="5"
                            value={model.content}
                            onChange={(e) => {
                                setModel({ ...model, content: e.target.value });
                            }}
                        />
                    </div>
                    <div className="form-product">
                        <label htmlFor="" className="required">
                            Kích thước
                        </label>
                        <div className="form-package">
                            <input
                                type="number"
                                placeholder="Dài"
                                value={model.length}
                                onChange={(e) => {
                                    setModel({ ...model, length: +e.target.value });
                                }}
                                className="mx-2"
                            />
                            <SVGX />
                            <input
                                type="number"
                                placeholder="Rộng"
                                value={model.width}
                                onChange={(e) => {
                                    setModel({ ...model, width: +e.target.value });
                                }}
                                className="mx-2"
                            />
                            <SVGX />
                            <input
                                type="number"
                                placeholder="Cao"
                                value={model.height}
                                onChange={(e) => {
                                    setModel({ ...model, height: +e.target.value });
                                }}
                                className="mx-2"
                            />
                        </div>
                    </div>

                    <div className="form-product">
                        <label htmlFor="" className="required">
                            Cân nặng
                        </label>
                        <div className="form-package">
                            <input
                                type="number"
                                placeholder="Dài"
                                value={model.weight}
                                onChange={(e) => {
                                    setModel({ ...model, weight: +e.target.value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-product">
                        <label htmlFor="" className="required">
                            Màu sắc:
                        </label>
                        <div className="form-package">
                            <input type="color" value={model.colorCode} readOnly />
                            <span className="mx-2">{model.colorName}</span>
                        </div>
                    </div>

                    {model.artworkCount > 1 && (
                        <div className="form-product">
                            <label htmlFor="" className="required">
                                Mặt hiển thị mặc định
                            </label>
                            <div className="form-radio-box">
                                <input
                                    type="radio"
                                    id="front"
                                    name="front"
                                    checked={model.artworkType === Artwork_Type.Front}
                                    onClick={() => {
                                        setModel({ ...model, artworkType: Artwork_Type.Front });
                                    }}
                                />
                                <label htmlFor="front">Mặt trước</label>
                                <input
                                    type="radio"
                                    id="back"
                                    name="back"
                                    checked={model.artworkType === Artwork_Type.Back}
                                    onClick={() => {
                                        setModel({ ...model, artworkType: Artwork_Type.Back });
                                    }}
                                />
                                <label htmlFor="back">Mặt sau</label>
                            </div>
                        </div>
                    )}
                </div> */}
            </div>
        </Wrapper>
    );
}

export default PushingManageMultiDetail;

const Wrapper = styled.div`
    .form-render {
        padding: 0px;
        margin: 6px 0;
    }

    .form-avatar {
        margin: 6px 0;
        padding: 10px;
    }

    .product-list {
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .form-avatar label.required {
        margin-bottom: 8px !important;
    }

    .form-product {
        margin: 6px 0;
        padding: 10px;
    }

    .form-product label.required {
        margin-bottom: 8px !important;
    }

    .form-product input[type="checkbox"] {
        height: auto;
    }
    .product-detail {
        background: white;
        padding: 12px;
        margin: 16px 0;
    }
    .platform-element {
        margin: 12px 0;
    }

    .platform {
        margin: 12px 0;
    }

    .label-form {
        display: flex;
        align-items: center;
    }

    .label-form label.required {
        margin: 0 !important;
    }

    .sub-instruction {
        margin-left: 8px;
    }

    section.platform-section {
        margin: 16px 0;
    }

    .platform-element {
        background: white;
        padding: 20px 17px;
        display: flex;
        align-items: flex-start;
    }

    .platform-top {
        display: flex;
        align-items: center;
    }

    .checkbox-area {
        display: flex;
        margin-top: 8px;
    }

    .image-icon {
        height: 20px;
        width: auto;
        margin-bottom: 8px;
    }

    .image-icon img {
        height: 100%;
        width: auto;
        object-fit: contain;
    }

    .platform-right {
        margin-left: 23px;
        flex: 1;
    }

    td.select {
        width: 36px;
        vertical-align: middle;
    }

    td.shop {
        width: 385px;
        vertical-align: middle;
    }

    span.shop-name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    span.badge.badge-warning {
        margin-left: 4px;
    }

    /* product */
    .product-avatar {
        width: 120px;
        height: 120px;
    }

    .product-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    section.product-overview {
        display: flex;
        background: white;
        padding: 6px;
        width: 30%;
    }

    .label-product {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 4px;
    }

    .product-name {
        color: #444;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .badge-customize {
        margin-top: auto;
        border-radius: 40px;
        background: #f20e45;
        width: fit-content;
        padding: 2px 10px;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .product-info {
        display: flex;
        flex-direction: column;
    }
    .platform-row {
        display: flex;
        align-items: center;
    }

    .list-platform {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .list-platform input[type="text"] {
        margin-left: 20px;
    }

    .platform-row input[type="radio"] {
        max-width: 16px;
        max-height: 16px;
    }
    .form-package {
        display: flex;
        align-items: center;
    }
    .form-radio-box {
        display: flex;
        align-items: center;
    }

    .form-radio-box label {
        margin-bottom: 0;
        margin-left: 10px;
    }

    .form-radio-box input {
        margin-left: 10px;
        display: flex;
    }

    .render-item.active {
        position: relative;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #64c5b1;
        background: linear-gradient(0deg, rgba(100, 197, 177, 0.5) 0%, rgba(100, 197, 177, 0.5) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
        opacity: 0.5;
    }
    .form-total {
        padding: 10px;
    }

    .form-card {
        background: white;
        padding: 18px;
        margin: 18px 0;
    }

    .form-card h6 {
        color: #666f7b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;
    }

    label.required {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .platform-list {
        display: flex;
    }

    .platform-item {
        width: calc(20% - 20px);
        margin: 10px;
        display: flex;
        align-items: center;
    }

    .platform-item label {
        margin: 0 0 0 10px;
    }

    .form-shop {
        margin-top: 10px;
    }

    .shop-list {
        display: flex;
        align-items: center;
    }

    .shop-item {
        display: flex;
        align-items: center;
    }

    .shop-item label {
        margin: 0 0 0 10px;
    }

    .form-product {
        display: flex;
        align-items: center;
        margin: 8px 0;
    }

    .form-product label.required {
        margin-bottom: 0;
        width: 10%;
    }

    .product-detail input[type="text"] {
        padding: 12px;
    }

    .product-detail textarea {
        padding: 12px;
    }

    .product-detail input {
        padding: 12px;
    }

    .form-product input {
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
        height: 32px;
    }

    .form-product textarea {
        flex: 1;
    }

    .form-package input[type="text"] {
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 0 12px;
    }

    .form-package span {
    }

    .form-package input[type="text"]:first-child {
        margin-left: 0px;
    }

    .form-print-type {
        display: flex;
    }

    .form-print-type-item {
        display: flex;
        align-items: center;
    }

    .form-print-type-item label {
        margin: 0 0 0 10px;
    }

    .form-render {
        display: flex;
    }

    .render-list {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 10px;
    }

    .form-render label.required {
        width: 10%;
    }

    .render-item {
        width: 110px;
        height: 110px;
    }

    .render-item img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .render-item.active {
        border: 1px solid #64c5b1;
        background: linear-gradient(0deg, rgba(100, 197, 177, 0.5) 0%, rgba(100, 197, 177, 0.5) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
    }

    .product-list {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .product-item {
        width: calc(25% - 20px);
        margin: 10px;
        display: flex;
        align-items: center;
    }

    .product-item input[type="checkbox"] {
        flex: 0 !important;
    }

    .product-item label {
        margin: 0 0 0 10px;
    }

    thead {
        background: #d9d9d9;
    }

    .thumbnail-table img {
        width: 110px;
        height: 110px;
        margin: auto;
        display: block;
    }

    td.thumbnail-table {
        text-align: center;
        vertical-align: middle;
    }

    .render-item-body {
        flex: 1;
    }

    .render-item-detail {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 0;
    }

    .form-button {
        display: flex;
        justify-content: flex-end;
    }

    .form-button button.btn {
        margin: 10px;
    }

    .form-package input:first-child {
        margin-left: 0 !important;
    }
`;

const SVGX = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M1 1L11 11" stroke="black" />
            <path d="M11 1L0.999999 11" stroke="black" />
        </svg>
    );
};

const SVGCheck = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#64C5B1" />
            <path d="M4 7.9987L6.66667 10.6654L12 5.33203" stroke="white" />
        </svg>
    );
};
