import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogEditor from "../../../based/inputs/BlogEditor/BlogEditor";
import TextArea from "../../../based/inputs/TextArea";
import { INITPAGING, NOTIFY } from "../../../based/Constants";
import useFormValidate from "../../../hooks/useFormValidate";
import Textbox from "../../../based/inputs/Textbox";
import Breadcrumbs from "../../../based/Breadcrumbs";
import Loading from "../../../based/Loading";
import { Notify } from "../../../based/Notify";
import AnswerContentService from "../../../based/services/Help/AnswerContentService";
import TypeQuestionService from "../../../based/services/Help/TypeQuestionService";
import { useHistory } from "react-router-dom";
import Common from "../../../based/Common";
import { ConfirmDialog } from "../../../based/Dialog";
import Breadcum from "../../../layout/refactor/Breadcum/Breadcum";
import { Box } from "../../../based/content/ct.elm";
import { AnswerContentModel } from "../AnswerContentManagement";
import ImageUpload from "./ImageUpload";
import TFUSelect from "../../../based/inputs/TFUSelect";

const initTypeQuestion = {
    id: 0,
    parentId: 0,
    name: "",
};


const AddOrEditAnswerContent = (props) => {
    const history = useHistory();
    const [msg, setMsg] = useState("Đang tải...");
    const [isBusy, setIsBusy] = useState(false);
    const [answerContent, setAnswerContent] = useState(AnswerContentModel);
    const [typeQuestion, setTypeQuestion] = useState(initTypeQuestion);
    const [typeQuestionOption, setTypeQuestionOption] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const { addRef, displayError, isValid } = useFormValidate();
    const [isLoading, setIsLoading] = useState(false);
    const [msgAvatar, setMsgAvatar] = useState(false);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [isContinue, setIsContinue] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const isAdmin = Common.IsAdmin();

    useEffect(() => {
        GetTypeQuestionOption();
        if (props.match.params.id && props.match.params.id > 0) {
            _getAnswerContent(props.match.params.id);
        } else {
            setAnswerContent({ ...AnswerContentModel });
        }
        return () =>  setAnswerContent({ ...AnswerContentModel });
    }, [props.match.params.id]);
    
    const _handleSave = () => {
        if (isValid()) {
            console.log("save");
            if (answerContent.seoUrl === "") {
                _saveAnswerContent({ ...answerContent, seoUrl: Common.removeVietnameseFromString(answerContent.title) });
            } else {
                _saveAnswerContent({ ...answerContent });
            }
        }
    };
    const _saveAnswerContent = async (answerContent) => {
        answerContent.seoUrl= Common.removeVietnameseFromString(answerContent.title);
        // if (!answerContent.tags || answerContent.tags === "") {
        //     ConfirmDialog("Xác nhận ?", "Bạn chưa thêm từ khóa liên quan cho bài viết này, xác nhận tiếp tục tạo bài ?", () => {
        //         return new Promise(async (resolve, reject) => {
        //             setIsLoading(true);
        //             let [err, data] = await BlogServices.CreateUpdateBlog(blog);
        //             if (!err && data) {
        //                 setIsLoading(false);
        //                 setIsSaveSuccess(true);
        //             } else {
        //                 setIsLoading(false);
        //                 Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
        //             }
        //             resolve({ title: "Thành công", msg: "Thành công." });
        //         });
        //     });
        // } else {
            setIsLoading(true);
            console.log(answerContent.video)
            let [err, data] = await AnswerContentService.CreateUpdateAnswerContent(answerContent);
            if (!err && data) {
                setIsLoading(false);
                setIsSaveSuccess(true);
            } else {
                setIsLoading(false);
                Notify(NOTIFY.WARNING, "Có lỗi", "Xảy ra lỗi!.");
            }
        //}
    };
    const _getAnswerContent = async (id) => {
        setIsLoading(true);
        let [err, data] = await AnswerContentService.GetAnswerContentById(id);
        if (!err && data) {
            setAnswerContent(data);
            GetTypeQuestion(data.typeQuestionId)
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setAnswerContent({ ...AnswerContentModel });
        }
    };
    const GetTypeQuestion = async (id) => {
        let [err, data] = await TypeQuestionService.GetTypeQuestionById(id);
        if (!err && data) {
            setTypeQuestion(data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setTypeQuestion({ ...initTypeQuestion });
        }
    };
    const GetTypeQuestionOption = async () => {
        setIsLoading(true);
        let [err, data] = await TypeQuestionService.GetTypeQuestionDropdown(0);
        if (!err && data) {
            setIsLoading(false);
            setTypeQuestionOption(data);
        } else {
            setIsLoading(false);
            setTypeQuestionOption([]);
        }
    };
    // const handleResetPage = () => {
    //     setAnswerContent({ ...AnswerContentModel });
    //     if (props.match.params.id) window.location.href = "/answer-content";
    //     else window.location.reload();
    // };

    const renderContent = () => {
        return (
            <Wrapper>
                {isSaveSuccess ? (
                    <Popup>
                        <div className='popup-container'>
                            <h2>Lưu thành công!</h2>
                            {!isContinue ? (
                                <div className='d-flex w-100 justify-content-between mt-4'>
                                    <button
                                        className='btn btn-success'
                                        onClick={() => {
                                            history.replace("");
                                        }}
                                    >
                                        {" "}
                                        Trở về trang quản lý
                                    </button>
                                    <button className='btn btn-warning' onClick={() => setIsContinue(true)}>
                                        {" "}
                                        Tiếp tục viết bài
                                    </button>
                                </div>
                            ) : (
                                <div className='d-flex w-100 justify-content-between align-items-center mt-4'>
                                    {" "}
                                    <i class='fa fa-arrow-left cursor-pointer' aria-hidden='true' onClick={() => setIsContinue(false)}></i>
                                    <button
                                        className='btn btn-success'
                                        onClick={() => {
                                            setIsContinue(false);
                                            setIsSaveSuccess(false);
                                        }}
                                    >
                                        {" "}
                                        Tiếp tục chỉnh sửa
                                    </button>
                                    <button
                                        className='btn btn-warning'
                                        onClick={() => {
                                            setIsContinue(false);
                                            setIsSaveSuccess(false);
                                            //handleResetPage();
                                        }}
                                    >
                                        {" "}
                                        Xóa tất cả
                                    </button>
                                </div>
                            )}
                        </div>
                    </Popup>
                ) : (
                    ""
                )}

                {isAdmin ? <Breadcrumbs currentPage={props.match.params.id ? "Cập nhật" : "Tạo bài viết mới"} /> : ""}
                <Loading show={isLoading} msg={msg} />
                <div></div>
                <div className='d-flex w-100'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='title' className='required'>
                            Tiêu đề:
                        </label>
                        <Textbox id='title' name='title' ref={addRef} required  className='form-control' value={answerContent.title} onChanged={(name, value) => setAnswerContent({ ...answerContent, title: value })} />
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='typeQuestionId' className='required'>
                            Bài viết thuộc loại câu hỏi:
                        </label>
                        <TFUSelect
                            name='typeQuestionId'
                            ref={addRef}
                            value={typeQuestion.id}
                            required
                            text={typeQuestion.name}
                            options={typeQuestionOption}
                            placeholder='-- Chọn loại câu hỏi --'
                            onChangedObject={(selected) => {
                                if (selected) {
                                    GetTypeQuestion(selected.value)
                                    setAnswerContent({ ...answerContent, typeQuestionId: selected.value })
                                } else {
                                    setTypeQuestion({
                                        ...typeQuestion,
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="w-100 d-flex">
                    <div className='form-group col-md-12'>
                        <label htmlFor='flexicomboFrame'>Link video đầu trang dạng mã nhúng(nếu có):</label>
                        <TextArea id='video' name='video'  className='form-control' value={answerContent.video} 
                        onChanged={(name, value) => {
                            setAnswerContent({ ...answerContent, video: value })
                        }
                        }
                         />
                    </div>
                    {/* <div className='form-group '>
                            <div className='d-flex align-items-center justify-content-end'>
                                {" "}
                                <span className='btn btn-success ml-4 mr-4' onClick={() => setIsShowModal(true)}>
                                    Thiết lập từ khóa liên quan
                                </span>{" "}
                                {answerContent.tags && answerContent.tags !== "" ? (
                                    <React.Fragment>
                                        Đã thiết lập:
                                        {answerContent.tags.split(",").map((item, idx) => (
                                            <span key={idx} className='tag-item'>
                                                {item}
                                            </span>
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div> */}
                </div>
                <div className='d-flex'>
                   
                    <div className='col-md-12'>
                        
                        <label htmlFor='shortContent' className='required'>
                            Đoạn mô tả ngắn:
                        </label>
                        <TextArea id='shortContent' name='shortContent' height='60%'
                          maxLength={512} className='form-control'
                          value={answerContent.shortContent} 
                          onChanged={(name, value) => 
                            setAnswerContent({ ...answerContent, shortContent: value }
                          )} />
                        {/*  */}
                    </div>
                </div>
                <div className='w-100 mb-5 position-relative'>
                    <div className='form-group'>
                        <label htmlFor='content'>Nội dung bài viết:</label>
                        <BlogEditor
                            required
                            ref={addRef}
                            // handleReset={handleResetPage}
                            name='content'
                            id='content'
                            className='form-control full-width'
                            html={answerContent.content ? answerContent.content : null}
                            onChanged={(name, value) => setAnswerContent({ ...answerContent, content: value })}
                        />
                    </div>
                </div>
                <hr />
                <div className='w-100 mt-3 d-flex align-items-end'>
                    {isValid() ? (
                        <button className='btn btn-success rounded' onClick={_handleSave}>
                            Lưu
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                {/* {isShowModal && (
                    <ConfigBlogTags
                        isShowModal={isShowModal}
                        onClose={() => {
                            setIsShowModal(false);
                        }}
                        setAnswerContent={setAnswerContent}
                        answerContent={answerContent}
                        onSuccess={() => {}}
                    />
                )} */}
            </Wrapper>
        );
    };
    return (
        <React.Fragment>
            {!isAdmin ? (
                <React.Fragment>
                    <Breadcum menu={["Trang chủ", "Viết bài"]} title={props.match.params.id ? "Cập nhật" : "Tạo bài viết mới"} />
                    <Box>{renderContent()}</Box>
                </React.Fragment>
            ) : (
                <React.Fragment>{renderContent()}</React.Fragment>
            )}
        </React.Fragment>
    );
};

const Wrapper = styled.div`
    width: 100%;
    .form-control {
        border-radius: 5px;
        border: 1px solid #aaa;
    }
    .tag-item {
        padding: 3px 10px;
        background: #32c861;
        color: #fff;
        border-radius: 6px;
        margin: 0 10px;
    }
`;
const Popup = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    .popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 1rem 1.5rem;
        width: 350px;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
export default AddOrEditAnswerContent;
