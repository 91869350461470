import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../../based/Breadcrumbs";
import Common from "../../based/Common";
import CommonTable from "../../based/CommonTable";
import { NOTIFY, PLATFORM, PLATFORM_NAME, STATUS_ACTIVE } from "../../based/Constants";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import PlatformServices from "../../based/services/PlatformServices";
import WarehouseServices from "../../based/services/WarehouseServices";
import { WarehousesModel } from "../../models/WarehousesModel";
import OPFWarehouseModal from "./OPFWarehouseModal";
import StoreItem from "./StoreItem";

const PLATFORM_TAB = [
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
    { value: PLATFORM.Tiktok, label: PLATFORM_NAME.Tiktok },
];

export default function OPFWarehouseManagement(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [datas, setDatas] = useState(null);
    const [paging, setPaging] = useState({ ...Common.Paging });
    const [shopOptions, setShopOptions] = useState([]);
    const [warehouse, setWarehouse] = useState(new WarehousesModel());
    const [isShowModal, setIsShowModal] = useState(false);

    useEffect(() => {
        _handleChangeNavTab(PLATFORM.Lazada);
    }, []);

    async function _handleChangeNavTab(platform) {
        if (platform != paging.platform) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu..");
            const [err, data] = await PlatformServices.PlatformStoresUser([platform], STATUS_ACTIVE.All);
            if (!err) {
                let shops = [];
                if (data && data.length > 0) data.map((shop) => shops.push({ value: shop.shopId, label: shop.shopName }));
                setShopOptions(shops);
                setIsLoading(false);
                setMsg("");
                let pageUp = { ...paging, platform: platform, shopId: shops.length > 0 ? shops[0].value : "", shopName: shops.length > 0 ? shops[0].label : "" };
                setPaging(pageUp);
                _getDatas(pageUp);
            } else {
                setShopOptions([]);
                setIsLoading(false);
                setMsg("");
                setPaging({ ...paging, platform: platform, shopId: "", shopName: "" });
            }
        } else if (platform == PLATFORM_TAB[0].value) {
            setShopOptions([]);
            setPaging({ ...paging, platform: platform, shopId: "", shopName: "" });
        }
    }
    async function _handleChangeShop(shop) {
        let pageUpdate = { ...paging, shopId: shop.value, shopName: shop.label };
        setPaging(pageUpdate);
        _getDatas(pageUpdate);
    }
    async function _getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu..");
        let [err, res] = await WarehouseServices.GetWarehouses(newPaging);
        if (!err) {
            setDatas(res.listObjects);
            setPaging(res.paging);
            setIsLoading(false);
            setMsg("");
        } else {
            setIsLoading(false);
            setMsg("");
        }
    }
    const NavWarehouses = useMemo(() => (
        <ul className="nav nav-tabs tabs-bordered ">
            {PLATFORM_TAB.map((item, k) => {
                return (
                    <li className="nav-item">
                        <a className={paging.platform == item.value ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleChangeNavTab(item.value)}>
                            <span className="d-none d-sm-block">{item.label}</span>
                        </a>
                    </li>
                );
            })}
        </ul>
    ));
    function _renderShops() {
        if (shopOptions && shopOptions.length > 0) {
            return (
                <ul className="nav nav-tabs tabs-bordered">
                    {shopOptions.map((shop, index) => {
                        return (
                            <li key={index} className="nav-item">
                                <a className={paging.shopId == shop.value ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeShop(shop)}>
                                    <span>{shop.label}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            );
        }
    }
    async function _handleSyncWarehouse() {
        setIsLoading(true);
        setMsg("Đang đồng bộ dữ liệu..");
        const [err, data] = await WarehouseServices.SyncOPFWarehouse(paging.shopId, paging.platform);
        if (!err) {
            _getDatas(paging);
            setIsLoading(false);
            setMsg("");
            Notify(NOTIFY.SUCCESS, "Thành công", data);
        } else {
            setIsLoading(false);
            setMsg("");
            Notify(NOTIFY.ERROR, "Lỗi", err.data);
        }
    }
    async function _handleEdit(idx) {
        if (datas[idx]) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu..");
            const [err, data] = await WarehouseServices.GetWarehouseOPFById(datas[idx].id);
            if (!err) {
                setWarehouse(data);
                setIsShowModal(true);
                setIsLoading(false);
                setMsg("");
            } else {
                setIsLoading(false);
                setMsg("");
            }
        }
    }
    function _renderAction() {
        return (
            <div className="col-sm-12">
                <div className="row flex-row p-y-10">
                    <div className="m-r-5">
                        <div className="form-group">
                            <button className="btn btn-custom btn-sm" onClick={() => _handleSyncWarehouse()}>
                                <i className="fa fa-download m-r-5" /> Đồng bộ
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _renderHeader() {}
    function _renderBody() {
        return (
            <tbody className="tbody-warehouse">
                <tr>
                    <td>
                        <div className="padding-10">
                            <div className={datas && datas.length > 0 ? "row" : "text-center"}>
                                {datas && datas.length > 0 ? (
                                    datas.map((item, i) => {
                                        return <StoreItem data={item} onEdit={() => _handleEdit(i)} readOnly={true} />;
                                    })
                                ) : (
                                    <span className="p-y-10">Không có dữ liệu kho</span>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý kho Open Platform" />
            <Loading show={isLoading} msg={msg} />
            {isShowModal && (
                <OPFWarehouseModal
                    isShowModal={isShowModal}
                    warehouse={warehouse}
                    onSuccess={() => {
                        setIsShowModal(false);
                        _getDatas(paging);
                    }}
                    onClose={() => setIsShowModal(false)}
                />
            )}
            {NavWarehouses}
            {_renderShops()}
            <CommonTable
                data={datas}
                paging={paging}
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                // searchBasic
                onFilter={() => _getDatas(paging)}
                freeSize
            />
        </React.Fragment>
    );
}
