import { BankAccountConfigModel } from './BankAccountConfigModel';

export class AGE_AgencyCreditModel {
  constructor() {
    this.id = 0;
    this.agencyId = 0;
    this.bankAccountId = 0;
    this.bankAccountNumber = 0;
    this.bankAccountName = '';
    this.bankName = '';
    this.remark = '';
    this.amount = 0;
    this.balance = 0;
    this.transferStatus = 0;
    this.sYS_BankAccountConfig = new BankAccountConfigModel();
  }
}
