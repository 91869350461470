import React, { useEffect, useState, useMemo, Fragment } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import CommonTable from "../based/refactor/CommonTable";
import { NOTIFY, CARD_COLOR, CAMPAIGN_BOOSTING_STATUS, CAMPAIGN_BOOSTING_STATUS_NAME, CAMPAIGN_BOOSTING_STATUS_OPTIONS } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import SetupModal from "./SetupModal";
import CampaignServices from "../based/services/CampaignServices";
import Button, { ButtonType } from "../based/refactor/Button";
import InputSelect from '../based/refactor/InputSelect'
import InputDatePicker from "../based/refactor/InputDatePicker";
import { Block, Box } from '../based/content/ct.elm'
var moment = require("moment");

export default function CreatorCampaignBoosting(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: -1 });
    const [datas, setDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [dashboard, setDashboard] = useState({});
    const [currentBoosting, setCurrentBoosting] = useState({});
    const [isView, setIsView] = useState(false);

    useEffect(() => {
        getDatas(paging);
        getDashboard();
    }, []);
    async function getDashboard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetDashboardCampaignBoosting();
        if (!error && data) {
            setDashboard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDashboard({});
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await CampaignServices.GetCampaignBoostingPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function _handleDelete(item) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa Chiến dịch Boosting", () => {
            return new Promise(async (resolve, reject) => {
                let ids = [];
                ids.push(item.id);
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await CampaignServices.DeleteCampaignBoostings(ids);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: data });
                    getDatas(paging);
                    getDashboard();
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.ERROR, "Có lỗi", error.data ? error.data : "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    }

    function _renderHeader() {
        return (
            <tr>
                <th className='text-center' width='3%'>
                    STT
                </th>
                <th className='text-center' width='25%'>Tên Set sản phẩm</th>
                <th className='text-center' width='10%'>
                    Tiền bản quyền (đ)
                </th>
                <th className='text-center' width='10%'>
                    HashTag
                </th>
                <th className='text-center' width='10%'>
                    Reference Link
                </th>
                <th className='text-center' width='15%'>
                    Lý do bán được hàng
                </th>
                <th className='text-center' width='10%'>
                    Ngày tạo
                </th>
                <th className='text-center' width='10%'>
                    Trạng thái
                </th>
                <th className='text-center' width='10%'></th>
            </tr>
        );
    }
    function _renderStatus(item) {
        let cls = "rounded text-white status";
        switch (item.status) {
            case CAMPAIGN_BOOSTING_STATUS.APPROVED:
                cls += " label-info";
                break;
            case CAMPAIGN_BOOSTING_STATUS.RUNNING:
                cls += " label-success";
                break;
            case CAMPAIGN_BOOSTING_STATUS.FINISH:
                cls += " label-danger";
                break;
            default:
                cls += " label-secondary";
                break;
        }
        return (

            <div className={cls}>
                <span>{CAMPAIGN_BOOSTING_STATUS_NAME[item.status]}</span>
            </div>
        );
    }
    function _renderHashTags(item) {
        if (item.hashTag && item.hashTag.length > 0) {
            let arr = [];
            let tags = item.hashTag;
            if (tags && tags.length > 0) {
                tags.map((tag, t) => {
                    arr.push(
                        <p key={t} className=' m-b-0' style={{ color: "#287cff" }}>
                            {tag}
                        </p>
                    );
                });
            }
            return arr;
        }
    }

    function _renderBody() {
        return (
            <React.Fragment>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='text-left vertical-align-middle'>{item.setName}</td>
                                <td className='text-right vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                </td>
                                <td className='text-left vertical-align-middle'>{_renderHashTags(item)}</td>
                                <td className='text-left vertical-align-middle'>
                                    <a href={item.referenceLink} target='_blank'>
                                        {item.referenceLink}
                                    </a>
                                </td>
                                <td className='text-left vertical-align-middle'>{item.reason}</td>
                                <td className='text-center vertical-align-middle'> {item.createdDateDisplay}</td>
                                <td className='text-center vertical-align-middle'>{_renderStatus(item)}</td>
                                <td className='text-center vertical-align-middle'>
                                    {(item.status == CAMPAIGN_BOOSTING_STATUS.DRAFT || item.status == CAMPAIGN_BOOSTING_STATUS.APPROVED) && (
                                        <React.Fragment>
                                            <i
                                                className='fa fa-pencil cursor-poiter text-primary'
                                                title='Cập nhật'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentBoosting(item);
                                                    setShowModal(true);
                                                    setIsView(false);
                                                }}
                                            ></i>
                                            &nbsp;|
                                        </React.Fragment>
                                    )}
                                    &nbsp;
                                    <i
                                        className='fa fa-eye cursor-poiter text-info'
                                        title='Chi tiết'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentBoosting(item);
                                            setShowModal(true);
                                            setIsView(true);
                                        }}
                                    ></i>
                                    &nbsp;
                                    {(item.status == CAMPAIGN_BOOSTING_STATUS.DRAFT || item.status == CAMPAIGN_BOOSTING_STATUS.FINISH) && (
                                        <React.Fragment>
                                            |&nbsp;
                                            <i className='fa fa-trash cursor-poiter text-danger' title='Xóa' onClick={() => _handleDelete(item)}></i>
                                        </React.Fragment>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (

                    <tr>
                        <td className='text-center' colSpan={100}>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>

                )}
            </React.Fragment>
        );
    }
    function _renderAction() {
        return (
            <div className='box__actions' style={{ width: '100%' }}>
                <div className='form-group full-width'>
                    <div className='box__searchRefactor form-row'>
                        <div className="flex__column in flex__basis-50">
                            <label>Trạng thái:</label>
                            <InputSelect className='flex__basis-40' values={[...CAMPAIGN_BOOSTING_STATUS_OPTIONS]} defaultValue={paging.status} placeholder='--Tất cả---' onChanged={(value) => setPaging({ ...paging, status: value != null ? value : -1 })} />
                        </div>
                        <div className='flex__column flex__basis-30'>

                            <label>Thời gian áp dụng:</label>

                            <div className='flex__row'>
                                <InputDatePicker dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                <InputDatePicker dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                            </div>
                        </div>
                        <div className='form-group col-md-12 d-flex pt-2' >
                            <Button btnType={ButtonType.Search} onClick={() => getDatas(paging)} className='mx-1' label='Tìm kiếm' />
                            <Button btnType={ButtonType.Add} label='Thêm mới' className='mx-1' onClick={() => {
                                setShowModal(true);
                                setIsView(false);
                                setCurrentBoosting(null);
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const _renderDashboard = useMemo(() => (
        <Box>
            <div className='box__content'>
                <Block flexBasis='25%' bgColor={CARD_COLOR.Green}>
                    <h6 style={{ color: '#fff' }}>Cấp bậc hiện tại</h6>

                    <div className='content'>
                        <div className='info'>
                            <h3 className='mb-0 mt-2'>
                                <span className='m-r-10'> {dashboard.level ? <span>Level {dashboard.level.agencyLevel}</span> : ""}</span>
                                <span>&nbsp;|&nbsp;{dashboard.level ? dashboard.level.tierName : ""}</span>
                            </h3>
                        </div>
                    </div>
                </Block>
                <Block flexBasis='25%' bgColor={CARD_COLOR.Orange}>
                    <div className='header'>
                        <h6 style={{ color: '#fff' }}>Số sản phẩm được boosting trong 1 tháng</h6>
                    </div>
                    <div className='content'>
                        <div className='info'>
                            <h3 className='mb-0 mt-2'>
                                <span className='m-r-10'>sp</span>
                                <span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.level ? dashboard.level.totalSetBoosting : 0} />
                                </span>
                            </h3>
                        </div>
                    </div>
                </Block>
                <Block flexBasis='25%' bgColor={CARD_COLOR.Blue}>
                    <div className='header'>
                        <h6 style={{ color: '#fff' }}>Số sản phẩm đã đăng ký trong tháng</h6>
                    </div>
                    <div className='content'>
                        <div className='info'>
                            <h3 className='mb-0 mt-2'>
                                <span className='m-r-10'>sp</span>
                                <span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.totalSetRegistered ? dashboard.totalSetRegistered : 0} />
                                </span>
                            </h3>
                        </div>
                    </div>
                </Block>
                <Block flexBasis='25%' bgColor={CARD_COLOR.Yellow}>
                    <div className='header'>
                        <h6 style={{ color: '#fff' }}>Tiền bản quyền tối đa</h6>
                    </div>
                    <div className='content'>
                        <div className='info'>
                            <h3 className='mb-0 mt-2'>
                                <span className='m-r-10'>đ</span>
                                <span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.campaignBoostingLoyalty ? dashboard.campaignBoostingLoyalty : 0} />
                                </span>
                            </h3>
                        </div>
                    </div>
                </Block>
            </div>
        </Box>
    ));
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            {showModal && (
                <SetupModal
                    isModal={showModal}
                    isView={isView}
                    currentBoosting={currentBoosting}
                    onClose={() => {
                        setShowModal(false);
                        setIsView(false);
                    }}
                    onSuccess={() => {
                        setShowModal(false);
                        setIsView(false);
                        getDatas(paging);
                    }}
                    onReloadDashboard={() => { () => getDashboard() }}
                    handleModalBoosting={() => setShowModal(false)}
                />
            )}
            {_renderDashboard}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} />
        </React.Fragment>
    );
}
