import Pubsub from "pubsub-js";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import VoucherServices from "../based/services/VoucherServices";
import VoucherLightModel from "../models/Promotion/VoucherLightModel";
import AddOrEditBrandProduct from "./AddOrEditBrandProduct";

export default function VoucherBrandProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([new VoucherLightModel()]);
    const [showModal, setShowModal] = useState(false);
    const [voucherId, setVoucherId] = useState(0);
    const [productId, setProductId] = useState(0);

    useEffect(() => {
        Pubsub.publish(CONSTANTS.VOUCHER, { mainMenu: MAINMENU.VoucherBrand });
        let pagingNew = { ...Common.Paging };
        getDatas(pagingNew);
    }, []);

    /** lấy thông tin voucher brand */
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");

        let [err, data] = await VoucherServices.GetVoucherBrandProductByPaging(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(newPaging);
        }
        console.log(`data`, data);
        setIsLoading(false);
        setMsg(null);
    }

    /** handle check box */
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };

    /** handle add */
    function _handleAdd() {
        setVoucherId(0);
        setProductId(0);
        setShowModal(true);
    }

    /** handle edit */
    async function _handleEdit() {
        let dataChecked = datas.find((x) => x.selected);
        if (dataChecked) {
            setVoucherId(dataChecked.voucherId);
            setProductId(dataChecked.productId);
            setShowModal(true);
        }
    }

    /** handle delete */
    function _handleDelete() {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");

                    let dataChecked = datas.find((x) => x.selected);
                    let voucherId = dataChecked.voucherId;
                    let productId = dataChecked.productId;
                    let [err, data] = await VoucherServices.DeleteVoucherBrandProductById(voucherId, productId);
                    if (!err && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas({ ...paging });
                    } else {
                        let errors = err.errors;
                        let msg = errors && errors.length > 0 ? errors.reduce((prev, item, idx) => (prev += idx + 1 == errors.length ? item.value : item.value + ", "), "") : "Xóa thất bại";
                        Notify(NOTIFY.ERROR, "Xóa thất bại", msg);
                    }
                    setIsLoading(false);
                    setMsg(null);
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    }

    /** handle filter */
    function _handleFilter() {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
    }

    /** render */
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className="text-center">
                        <CheckBox id="select_all" name="select_all" checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label="" onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width="150" className="text-center">
                        Mã Voucher
                    </th>
                    <th width="150" className="text-center">
                        Tên Voucher
                    </th>
                    <th width="150" className="text-center">
                        Tên sản phẩm
                    </th>
                    <th width="150" className="text-center">
                        Thời gian bắt đầu
                    </th>
                    <th width="100" className="text-center">
                        Thời gian kết thúc
                    </th>
                    <th width="100" className="text-center">
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderButton(item) {
        let btn = (
            <a
                className="font-weight-bold"
                style={{ color: "#64c5b1", cursor: "pointer" }}
                onClick={() => {
                    setVoucherId(item.voucherId);
                    setProductId(item.productId);
                    setShowModal(true);
                }}
            >
                Chi tiết
            </a>
        );
        return btn;
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td width={40} className="text-center vertical-align-middle">
                                    {Common.IsAdmin() ? <CheckBox id={i.toString()} name={i.toString()} checked={item.selected ? item.selected : false} label=" " onCheckedChange={(name, value) => _handleChecked(i, value)} /> : <span> {no}</span>}
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <strong>{item.voucherCode}</strong>
                                </td>
                                <td className="text-center vertical-align-middle"> {item.voucherName}</td>
                                <td className="text-center vertical-align-middle"> {item.productName}</td>
                                <td className="text-center vertical-align-middle"> {Common.formatDate(new Date(item.fromDate))}</td>
                                <td className="text-center vertical-align-middle"> {Common.formatDate(new Date(item.toDate))}</td>
                                <td className="text-center vertical-align-middle">{_renderButton(item)}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="20" className="text-center">
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="box__actions">
                <div className="form-group full-width">
                    <div className="box__search form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="code">Mã Voucher:</label>
                            <Textbox className="form-control" id="code" name="code" onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="name">Tên Voucher:</label>
                            <Textbox className="form-control" name="name" onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="time">Thời gian áp dụng:</label>
                            <div className="input-group form-row">
                                <div className="input-daterange input-group" id="date-range">
                                    <TFUDatetime dateFormat="dd/MM/yyyy" name="startDate" maxDate={paging.endDate} value={paging.startDate} placeholder="Từ ngày" onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat="dd/MM/yyyy" name="endDate" minDate={paging.startDate} value={paging.endDate} placeholder="Đến ngày" onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleFilter()}>
                                <i className="fa fa-search m-r-5" />
                                Tìm kiếm
                            </button>
                            {Common.IsAdmin() && (
                                <React.Fragment>
                                    <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleAdd()}>
                                        <i className="fa fa-plus m-r-5" /> Thêm mới
                                    </button>
                                    <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnabled ? _handleEdit() : {})}>
                                        <i className="fa fa-edit m-r-5" /> Sửa
                                    </button>
                                    <button className={isEnabled ? "btn btn-danger btn-sm m-r-5" : "btn btn-danger btn-sm m-r-5 disable"} onClick={() => (isEnabled ? _handleDelete() : {})}>
                                        <i className="fa fa-remove m-r-5" /> Xóa
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý Scheme Vourcher Daily" />
            <Loading show={isLoading} msg={msg} />
            {showModal && (
                <AddOrEditBrandProduct
                    isShowModal={showModal}
                    voucherId={voucherId}
                    productId={productId}
                    onClose={() => setShowModal(false)}
                    onSaveSucceed={(value) => {
                        setShowModal(value);
                        getDatas(paging);
                    }}
                />
            )}
            <CommonTable classDivTable="mh-100vh" data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
