import React, { useEffect, useMemo, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import NumberFormat from "react-currency-format";
import Sticky from "react-stickynode";
import Breadcrumbs from "../based/Breadcrumbs";
import { ATTRIBUTE_INPUT_TYPE, NOTIFY, PLATFORM, PLATFORM_NAME, STATUS_ACTIVE } from "../based/Constants";
import { ErrorDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Radio from "../based/inputs/Radio";
import TextArea from "../based/inputs/TextArea";
import Textbox from "../based/inputs/Textbox";
import TextEditor from "../based/inputs/TextEditor";
import TextNumber from "../based/inputs/TextNumber";
import TFUSelect from "../based/inputs/TFUSelect";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CommonServices from "../based/services/CommonServices";
import PlatformServices from "../based/services/PlatformServices";
import ProductServices from "../based/services/ProductServices";
import useFormValidate from "../hooks/useFormValidate";
import TFUCategories from "./TFUCategories";

const PLATFORM_PUSH = [
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
    { value: PLATFORM.Sendo, label: PLATFORM_NAME.Sendo },
    { value: PLATFORM.Tiki, label: PLATFORM_NAME.Tiki },
    { value: PLATFORM.Tiktok, label: PLATFORM_NAME.Tiktok },
];

export default function PushProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [shopOptions, setShopOptions] = useState([]);
    const [showCatOptions, setShowCatOptions] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const [data, setData] = useState({ shopInfo: { platform: PLATFORM.Lazada, shopId: "" }, storeInfo: {}, productInfo: {} });
    const [target, setTarget] = useState(null);
    const [overlayShow, setOverlayShow] = useState(false);
    const [overlayDatas, setOverlayDatas] = useState([]);
    const ref = useRef(null);
    const { categoryId, productId } = props.match.params;

    useEffect(() => {
        let productId = props.match.params.productId;
        if (productId) {
            getShops([PLATFORM.Lazada, PLATFORM.Shopee, PLATFORM.Sendo, PLATFORM.Tiki, PLATFORM.Tiktok]);
        }
    }, []);
    async function mappingAgencyProductInfo(productId, platform, shopId, categoryId, breadCrumbs) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu..");
        const [err, info] = await ProductServices.GetAgencyProductToPush(productId, platform, shopId, categoryId);
        if (!err) {
            let dataUp = { ...data };
            dataUp.productInfo = info.productInfo;
            if (platform == PLATFORM.Tiktok) {
                dataUp.productInfo.oPFCategoryId = categoryId;
                dataUp.productInfo.oPFCategoryName = breadCrumbs;
            }
            if (!dataUp.productInfo.categoryBreadcrumb) dataUp.productInfo.categoryBreadcrumb = breadCrumbs;
            dataUp.storeInfo = info.storeInfo;
            setData(dataUp);
            setIsLoading(false);
            setMsg("");
        } else {
            let dataUp = { ...data };
            dataUp.productInfo = {};
            dataUp.storeInfo = {};
            setData(dataUp);
            setIsLoading(false);
            setMsg("");
            Notify(NOTIFY.ERROR, "Lỗi", err.message ? err.message : "Đã có lỗi xảy ra trong quá trình xử lý dữ liệu");
        }
    }
    async function handlePush() {
        if (isValid()) {
            let isValidQuantity = true;
            if (isValidQuantity) {
                setIsLoading(true);
                setMsg("Đang đẩy sản phẩm...");
                const [err, result] = await ProductServices.PushProductToStore(data);
                if (!err) {
                    setIsLoading(false);
                    setMsg("");
                    Notify(NOTIFY.SUCCESS, "Thành công", "Đẩy sản phẩm thành công");
                } else {
                    ErrorDialog("Lỗi", <span dangerouslySetInnerHTML={{ __html: err ? err.data : "" }} />);
                    setIsLoading(false);
                    setMsg("");
                }
            } else Notify(NOTIFY.WARNING, "Thông báo", "Yêu cầu nhập đủ số lượng cần đẩy lên sàn");
        }
    }
    const NavigateButtons = useMemo(() => (
        <Sticky enabled={true} top={70} bottomBoundary={4000} innerZ={2}>
            <div className="form-group text-right product-navigate-buttons">
                <button type="button" className="btn btn-custom waves-effect w-xs waves-light margin-right-10 btn-sm" onClick={handlePush}>
                    <i className="fa fa-upload m-r-5"></i>Đẩy sản phẩm
                </button>
                <button type="button" className="btn btn-inverse waves-effect w-xs waves-light btn-sm" onClick={() => props.history.push("/manage-product-design")}>
                    <i className="fa fa-long-arrow-left m-r-5"></i>Trở lại
                </button>
            </div>
        </Sticky>
    ));

    async function getShops(platforms) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu..");
        const [err, stores] = await PlatformServices.PlatformStoresUser(platforms, STATUS_ACTIVE.All, productId);
        if (!err) {
            let currentShopOptions = [];
            if (stores && stores.length > 0) currentShopOptions = stores.filter((x) => x.platform == PLATFORM.Lazada);
            else Notify(NOTIFY.WARNING, "Thông báo", "Không tìm thấy gian hàng hoặc chưa được kết nối đến sàn.");
            if (currentShopOptions.length > 0) {
                let dataUp = { ...data };
                dataUp.shopInfo.shopId = currentShopOptions[0].shopId;
                dataUp.shopInfo.platform = PLATFORM.Lazada;
                mappingAgencyProductInfo(props.match.params.productId, dataUp.shopInfo.platform, dataUp.shopInfo.shopId, +categoryId);
            }
            setShopOptions(stores);
            setIsLoading(false);
            setMsg("");
        } else {
            setShopOptions([]);
            setIsLoading(false);
            setMsg("");
        }
    }
    async function setShopInfo(name, value) {
        let dataUp = { ...data };
        dataUp.shopInfo[name] = value;
        if (name == "platform") {
            let currentShopOptions = [];
            let catId = +categoryId;
            if (shopOptions && shopOptions.length > 0) currentShopOptions = shopOptions.filter((x) => x.platform == value);
            if (currentShopOptions.length > 0) {
                dataUp.shopInfo.shopId = currentShopOptions[0].shopId;
                catId = currentShopOptions[0].opfCategoryId;
            }
            mappingAgencyProductInfo(props.match.params.productId, value, dataUp.shopInfo.shopId, +catId);
        } else {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu..");
            const [err, warehouses] = await CommonServices.GetOPFWarehousesByShop(value);
            if (!err) {
                if (dataUp.productInfo && dataUp.productInfo.variants && dataUp.productInfo.variants.length > 0) {
                    dataUp.productInfo.variants.map((variant) => {
                        let warehousesUp = warehouses && warehouses.length > 0 ? warehouses.map((a) => Object.assign({}, a)) : null;
                        variant.opfWarehouse = warehousesUp;
                    });
                }
                setIsLoading(false);
                setMsg("");
            } else {
                setIsLoading(false);
                setMsg("");
            }
        }
        setOverlayDatas(null);
        setOverlayShow(false);
        setData(dataUp);
    }
    function _renderBoxContent() {
        let currentShopOptions = [];
        if (shopOptions && shopOptions.length > 0) currentShopOptions = shopOptions.filter((x) => x.platform == data.shopInfo.platform);
        let breadcrumbsCat = "Chưa chọn ngành hàng";

        return (
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Thông tin sàn</b>
                </h4>
                <p className="sub-header">Thiết lập thông tin sàn thương mại điện tử để đẩy sản phẩm lên</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group ">
                            <label htmlFor="platform" className="col-form-label required">
                                Đẩy lên sàn
                            </label>
                            <div className="form-row">
                                {PLATFORM_PUSH.map((item, k) => {
                                    return (
                                        <div className="col-md-3" key={k}>
                                            <Radio id={k} name="platform" isInline checked={data.shopInfo.platform == item.value} value={item.value} onCheckedChange={(name, value) => setShopInfo(name, value)} label={item.label} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {currentShopOptions && currentShopOptions.length > 0 ? (
                            <div className="form-group ">
                                <label htmlFor="shopId" className="col-form-label required">
                                    Đẩy lên gian hàng
                                </label>
                                <div className="form-row">
                                    {currentShopOptions.map((shop, s) => {
                                        return (
                                            <div className="col-md-4" key={s}>
                                                <Radio name="shopId" id={s} isInline checked={data.shopInfo.shopId == shop.shopId} value={shop.shopId} onCheckedChange={(name, value) => setShopInfo(name, value)} label={shop.shopName} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                        <div className="form-group ">
                            <div className="form-row">
                                <label htmlFor="categoryId" className="col-form-label required">
                                    Ngành hàng
                                </label>
                                <label className="col-form-label">
                                    <strong className="m-r-5">{data.productInfo && data.productInfo.categoryBreadcrumb ? data.productInfo.categoryBreadcrumb : breadcrumbsCat}</strong>
                                    <i className="fa fa-pencil text-info" onClick={() => setShowCatOptions(true)}></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _handleChangeProductName(value) {
        let dataUp = { ...data };
        dataUp.productInfo.name = value;
        setData(dataUp);
    }
    function _renderProductInfo() {
        let product = data.productInfo;
        let attributes = [];
        if (product && product.opfAttributes && product.opfAttributes.length > 0) attributes = product.opfAttributes.filter((x) => x.code != "name");
        let ctProductName = product && (
            <div className="form-group col-md-12">
                <label htmlFor="name" className="required full-width">
                    Tên sản phẩm
                </label>
                <Textbox name="name" required ref={addRef} value={product.name} onChanged={(name, value) => _handleChangeProductName(value)} />
            </div>
        );
        return (
            product && (
                <React.Fragment>
                    {attributes && attributes.length > 0 && (
                        <React.Fragment>
                            <div className="col-sm-12 card-box p-3">
                                <h4 className="header-title m-t-0">
                                    <b>Thông tin sản phẩm</b>
                                </h4>
                                <div className="row">
                                    <div className="col-md-12 form-row">
                                        {ctProductName}
                                        {attributes && attributes.length > 0
                                            ? attributes.map((attr, k) => {
                                                  return <React.Fragment key={k}>{__renderControl(attr)}</React.Fragment>;
                                              })
                                            : null}
                                    </div>
                                    <div className="col-md-12 form-row">
                                        <label htmlFor="printtype" className="required full-width">
                                            Kiểu in
                                        </label>
                                        {product &&
                                            product.agencyPrintTypeModels &&
                                            product.agencyPrintTypeModels.map((item, idx) => {
                                                return (
                                                    <div className="col-md-4">
                                                        <CheckBox
                                                            name="printTypeName"
                                                            checked={item.isActive}
                                                            label={item.printTypeName}
                                                            onCheckedChange={(name, value) => {
                                                                const dt = { ...data };
                                                                let shop = dt.productInfo;
                                                                let printTypes = shop.agencyPrintTypeModels;
                                                                printTypes.map((x, y) => {
                                                                    if (x.printType == item.printType) x.isActive = true;
                                                                    else x.isActive = false;
                                                                });
                                                                setData(dt);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 card-box p-3">
                                <h4 className="header-title m-t-0">
                                    <b>Hình ảnh sản phẩm</b>
                                </h4>
                                <p className="sub-header">Hình ảnh được thiết lập của sản phẩm và biến thể sản phẩm. Click vào ảnh muốn đặt làm ảnh đại diện của sản phẩm</p>
                                {_renderImages()}
                            </div>
                            {_renderVariants()}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )
        );
    }
    function _handleClickEdit(e, variant) {
        setTarget(e.target);
        setOverlayShow(!overlayShow);
        setOverlayDatas(variant);
    }
    function _handleQuantity(name, value, variant) {
        let dataUp = { ...data };
        dataUp.productInfo.variants.map((item) => {
            if (item.id == variant.id) {
                item.quantity = value;
                if (item.opfWarehouse) {
                    item.opfWarehouse[0].quantity = value;
                }
            }
        });
        setData(dataUp);
    }
    function _handleQuantityMulti(inv, value) {
        if (overlayDatas && inv) {
            let dataUp = { ...data };
            dataUp.productInfo.variants.map((item) => {
                if (item.id == overlayDatas.id)
                    item.opfWarehouse.map((wh) => {
                        if (wh.id == inv.id) wh.quantity = value;
                    });
            });
            setData(dataUp);
        }
    }
    function _handleSetVariantAvatar(variant, image) {
        let dataUp = { ...data };
        dataUp.productInfo.variants.map((item) => {
            if (item.id == variant.id) item.productAvatar = image.url;
        });
        setData(dataUp);
    }
    function _renderImages() {
        let product = data.productInfo;
        let printTypeActive = product.agencyPrintTypeModels.find((x) => x.isActive);
        let printType = printTypeActive.printType;
        var hasImage =
            (product.listImage && product.listImage.length > 0) ||
            (product.variants && product.variants.length > 0 && product.variants.filter((x) => x.listImage && x.listImage.length > 0) && product.variants.filter((x) => x.listImage && x.listImage.length > 0).length > 0);
        if (hasImage)
            return (
                <React.Fragment>
                    {product.listImage && product.listImage.length > 0 && (
                        <div className="col-sm-12 card-box p-3">
                            <h4 className="header-title m-t-0">
                                <b>Ảnh sản phẩm</b>
                            </h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-row">
                                        <div className="ui-product-photo">
                                            <ul className="ui-product-photo-grid clearfix">
                                                {product.listImage.map((image, idx) => {
                                                    return (
                                                        <li key={idx} className="ui-product-photo-item col-md-2">
                                                            <div className="file-upload position-relative">
                                                                <div className="fileupload-text text-center">
                                                                    <img src={image.url} style={{ height: "100%", display: "block", objectFit: "contain" }} className="full-width" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {product.variants &&
                        product.variants.length > 0 &&
                        product.variants
                            .filter((x) => x.printType == printType)
                            .map((variant, idx) => {
                                return (
                                    variant.listImage &&
                                    variant.listImage.length > 0 && (
                                        <div key={idx} className="col-sm-12 card-box p-3">
                                            <h4 className="header-title m-t-0">
                                                <b>biến thể: {variant.sellerSKU}</b>
                                            </h4>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-row">
                                                        <div className="ui-product-photo">
                                                            <ul className="ui-product-photo-grid clearfix">
                                                                {variant.listImage.map((image, idx) => {
                                                                    let isAvatar = image.url == variant.productAvatar;
                                                                    return (
                                                                        <li key={idx} className="ui-product-photo-item col-md-2" onClick={() => _handleSetVariantAvatar(variant, image)}>
                                                                            <div className={isAvatar ? "file-upload position-relative avatar" : "file-upload position-relative"}>
                                                                                <div className="fileupload-text text-center">
                                                                                    <img src={image.url} style={{ height: "100%", display: "block", objectFit: "contain" }} className="full-width" />
                                                                                </div>
                                                                                {isAvatar && (
                                                                                    <span className="active__item">
                                                                                        <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                </React.Fragment>
            );
        else
            return (
                <div className="full-width text-center" style={{ background: "#e9ecef", opacity: 1, padding: 100, fontSize: 20 }}>
                    <strong>Sản phẩm chưa có ảnh</strong>
                </div>
            );
    }
    function _renderVariants() {
        let product = data.productInfo;
        if (product.variants && product.variants.length > 0) {
            let columns = product.variants[0].opfAttributes;
            let printTypeActive = product.agencyPrintTypeModels.find((x) => x.isActive);
            let printType = printTypeActive.printType;
            return (
                <div className="col-sm-12 card-box p-3">
                    <h4 className="header-title m-t-0">
                        <b>Thông tin biến thể</b>
                    </h4>
                    <p className="sub-header">Thiết lập đầy đủ thông tin của biến thể</p>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-add-variants">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="center vertical-align-middle" width="65"></th>
                                        {data && data.shopInfo && data.shopInfo.platform == PLATFORM.Shopee && <th width="200">Tên biến thể</th>}
                                        <th width="250">SKU</th>
                                        {columns &&
                                            columns.length > 0 &&
                                            columns.map((column, i) => (
                                                <th key={i} width="250">
                                                    {column.name}
                                                </th>
                                            ))}
                                        <th className="center vertical-align-middle" width="250">
                                            Số lượng
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {product.variants
                                        .filter((x) => x.printType == printType)
                                        .map((variant, idx) => {
                                            let totalQuantity = variant.opfWarehouse && variant.opfWarehouse.length > 0 ? variant.opfWarehouse.reduce((a, b) => a + Number(b.quantity), 0) : 0;
                                            return (
                                                variant.opfAttributes &&
                                                variant.opfAttributes.length > 0 && (
                                                    <tr key={idx}>
                                                        <td>
                                                            <img
                                                                src={variant.productAvatar && variant.productAvatar.length > 0 ? variant.productAvatar : "https://localhost:6001/images_system/no-image.png"}
                                                                className="variant-avatar"
                                                                id="tfutooltip_123456"
                                                            />
                                                        </td>
                                                        {data && data.shopInfo && data.shopInfo.platform == PLATFORM.Shopee && <td className="vertical-align-middle">{variant.name}</td>}
                                                        <td className="vertical-align-middle">
                                                            {variant.sellerSKU}
                                                            <div>
                                                                {variant.attributes &&
                                                                    variant.attributes.map((item, idx) => {
                                                                        return (
                                                                            <span className="badge ml-1" key={idx}>
                                                                                {item.value}
                                                                            </span>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </td>
                                                        {variant.opfAttributes.map((attr, i) => (
                                                            <td key={i} className="vertical-align-middle">
                                                                {__renderControl(attr, variant)}
                                                            </td>
                                                        ))}
                                                        <td className="center">
                                                            {variant.opfWarehouse && variant.opfWarehouse.length > 1 ? (
                                                                <React.Fragment>
                                                                    <strong className="m-r-5">
                                                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={totalQuantity} />
                                                                    </strong>
                                                                    <a
                                                                        onClick={(e) => {
                                                                            _handleClickEdit(e, variant);
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-edit" />
                                                                    </a>
                                                                </React.Fragment>
                                                            ) : (
                                                                <div className="form-group col-md-12">
                                                                    <TextNumber name="quantity" value={variant.quantity} onChanged={(name, value) => _handleQuantity(name, value, variant)} />
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }
    function _handleChangedSelected(code, value, variant, isMulti) {
        let dataUp = { ...data };
        if (variant) {
            dataUp.productInfo.variants.map((item) => {
                if (item.id == variant.id)
                    item.opfAttributes.map((attr) => {
                        if (attr.code == code)
                            if (isMulti) attr.value = value ? value : null;
                            else attr.value = value ? value : "";
                    });
            });
        } else {
            dataUp.productInfo.opfAttributes.map((attr) => {
                if (attr.code == code)
                    if (isMulti) attr.value = value ? value : null;
                    else attr.value = value ? value : "";
            });
        }
        setData(dataUp);
    }
    function _handleUpdateAttribute(code, value, variant) {
        let dataUp = { ...data };
        if (variant) {
            dataUp.productInfo.variants.map((item) => {
                if (item.id == variant.id)
                    item.opfAttributes.map((attr) => {
                        if (attr.code == code) attr.value = value;
                    });
            });
        } else {
            dataUp.productInfo.opfAttributes.map((attr) => {
                if (attr.code == code) {
                    if (code == "name") dataUp.productInfo.name = value;
                    attr.value = value;
                }
            });
        }
        setData(dataUp);
    }
    function __renderControl(attr, variant) {
        if (attr && attr.code != "name") {
            let name = attr.code;
            let placeHolder = attr.code.toLowerCase() == "brand" ? "Ranus" : "--Chọn";
            attr.isRequired = attr.code.toLowerCase() == "brand" ? false : true;

            let isVariant = variant != null && variant != undefined;
            let lblTitle = !isVariant && (
                <label htmlFor={name} className={attr.isRequired ? "required full-width" : "full-width"}>
                    {attr.name}{" "}
                </label>
            );
            let options = [];
            if (attr.options && attr.options.length > 0) {
                attr.options.map((op) => options.push({ value: op.id, label: op.name }));
            }
            let classControl = isVariant ? "form-group col-md-12" : "form-group col-md-4";
            switch (attr.dataType) {
                case ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT:
                    return (
                        <div className={classControl}>
                            {lblTitle}
                            <TFUSelect name={name} required={attr.isRequired} ref={addRef} options={options} isClearable value={attr.value} placeholder={placeHolder} onChanged={(value) => _handleChangedSelected(name, value, variant)} />
                        </div>
                    );
                case ATTRIBUTE_INPUT_TYPE.MULTI_SELECT:
                    return (
                        <div className={classControl}>
                            {lblTitle}
                            {options && options.length > 0 ? (
                                <TFUSelect name={name} required={attr.isRequired} ref={addRef} options={options} isClearable value={attr.value} placeholder={placeHolder} onChanged={(value) => _handleChangedSelected(name, value, variant, true)} />
                            ) : (
                                <Textbox name={name} required={attr.isRequired} ref={addRef} value={attr.value} readOnly={attr.isDisable} onChanged={(name, value) => _handleUpdateAttribute(name, value, variant)} />
                            )}
                        </div>
                    );
                case ATTRIBUTE_INPUT_TYPE.TEXT:
                    return (
                        <div className={classControl}>
                            {lblTitle}
                            <Textbox name={name} required={attr.isRequired} ref={addRef} value={attr.value} readOnly={attr.isDisable} onChanged={(name, value) => _handleUpdateAttribute(name, value, variant)} />
                        </div>
                    );
                case ATTRIBUTE_INPUT_TYPE.TEXT_AREA:
                    return (
                        <div className="form-group col-md-12">
                            {lblTitle}
                            <TextArea
                                name={name}
                                required={attr.isRequired}
                                height="300px"
                                ref={addRef}
                                className="form-control full-width"
                                value={attr.value}
                                readOnly={attr.isDisable}
                                onChanged={(name, value) => _handleUpdateAttribute(name, value, variant)}
                            />
                        </div>
                    );
                case ATTRIBUTE_INPUT_TYPE.RICH_TEXT:
                    return (
                        <div className="form-group col-md-12">
                            {lblTitle}
                            <TextEditor
                                id={name}
                                name={name}
                                required={attr.isRequired}
                                ref={addRef}
                                className="form-control full-width"
                                html={attr.value ? attr.value : null}
                                onChanged={(name, value) => _handleUpdateAttribute(name, value, variant)}
                            />
                        </div>
                    );
                case ATTRIBUTE_INPUT_TYPE.NUMBER:
                    return (
                        <div className={classControl}>
                            {lblTitle}
                            <TextNumber id={name} decimal name={name} required={attr.isRequired} ref={addRef} value={attr.value ? +attr.value : 0} onChanged={(name, value) => _handleUpdateAttribute(name, value, variant)} />
                        </div>
                    );
                case ATTRIBUTE_INPUT_TYPE.DATE:
                    return <div className={classControl}>{lblTitle}</div>;
            }
        }
    }
    async function _handleSelectedCat(breadcrumbs, opfCategory) {
        // if (data.shopInfo.platform == PLATFORM.Tiktok) {
        //     let [err, res] = await BaseServices.Get(`/api/connectPlatform/get-attributes?platform=${PLATFORM.Tiktok}&seller=${data.shopInfo.shopId}&shopId=${data.shopInfo.shopId}&categoryId=${opfCategory}`)
        //     let dataUp = { ...data };
        //     dataUp.productInfo.category = opfCategory;
        //     dataUp.productInfo.categoryBreadcrumb = breadcrumbs;
        //     setData(dataUp);
        // }
        // else { }
        let dataUp = { ...data };
        dataUp.productInfo.categoryBreadcrumb = breadcrumbs;
        setData(dataUp);
        mappingAgencyProductInfo(props.match.params.productId, data.shopInfo.platform, data.shopInfo.shopId, opfCategory.id, breadcrumbs);
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Đẩy sản phẩm lên sàn" />
            <Loading show={isLoading} msg={msg} />
            {showCatOptions && <TFUCategories title="Chọn ngành hàng" isShowModal={showCatOptions} onClose={() => setShowCatOptions(false)} shopId={data.shopInfo.shopId} platform={data.shopInfo.platform} onSelected={_handleSelectedCat} />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {NavigateButtons}
                        {_renderBoxContent()}
                        {_renderProductInfo()}
                    </div>
                </div>
            </div>
            {overlayShow && <OverlayElement show={overlayShow} target={target} onClose={() => setOverlayShow(false)} container={ref.current} data={overlayDatas} handleQuantity={_handleQuantityMulti} />}
        </React.Fragment>
    );
}

const OverlayElement = (props) => {
    let popover = (
        <Popover id={"popover-basic"}>
            <Popover.Title as="h6">Kho hàng</Popover.Title>
            <Popover.Content>
                {props.data && props.data.opfWarehouse && props.data.opfWarehouse.length > 0
                    ? props.data.opfWarehouse.map((inve, idx) => {
                          return (
                              <div className="row" key={idx}>
                                  <div className="col-md-12 form-row">
                                      <label className="col-form-label col-md-8">{inve.warehouseName}</label>
                                      <div className="col-md-4">
                                          <TextNumber
                                              className="form-control"
                                              value={inve.quantity}
                                              onChanged={(name, value) => {
                                                  props.handleQuantity(inve, value);
                                              }}
                                          />
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : null}
            </Popover.Content>
        </Popover>
    );

    return (
        <Overlay rootClose={true} onHide={() => props.onClose()} show={props.show} target={props.target} placement="bottom" container={props.container} containerPadding={20}>
            {popover}
        </Overlay>
    );
};
