import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import Common from '../../based/Common';
import CommonTable from '../../based/CommonTable';
import { CONSTANTS, NOTIFY } from '../../based/Constants';
import { ConfirmDialog } from '../../based/Dialog';
import Loading from '../../based/Loading';
import { Notify } from '../../based/Notify';
import BaseServices from '../../based/services/BaseServices';
import AddOrEditGroup from './components/AddOrEditGroup';
import AddOrEditVIPCampaign from './components/AddOrEditVIPCampaign';
const INIT_PAGING = {
    pageSize: 50,
    pageNumber: 1,
    type: 6
}
const INIT_CAMPAIGN = {
    id: 0,
    title: '',
    description: '',
    startTime: new Date(),
    endTime: new Date(),
    type: 6
}
const VIPCampaign = () => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [editingCamp, setEditingCamp] = useState(INIT_CAMPAIGN);
    const [showModalEditing, setShowModalEditing] = useState(false);
    useEffect(() => {
        _fetchData();
    }, [])
    const _fetchData = async () => {
        setBusy(true);
        setBusyMsg("Loading...")
        const [err, data] = await BaseServices.Post('/api/campaign/get-list-campaign', paging);
        if (!err) {
            setCampaigns(data.listObjects);
            setPaging(data.paging);
        } else {
            setCampaigns([]);
        }
        setBusy(false);
        setBusyMsg('')
    }
    const _handleAddCamp = async () => {
        setShowModalEditing(true);
        setEditingCamp(INIT_CAMPAIGN)
    }
    const _handleEdit = (gr) => {
        setShowModalEditing(true);
        setEditingCamp({ ...gr, startTime: new Date(gr.startTime), endTime: new Date(gr.endTime) });
    }
    const _handleDelete = async (id) => {
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true)
                setBusyMsg('Đang xóa dữ liệu...')
                const [err, data] = await BaseServices.Post('/api/campaign/delete-campaigns', [id]);
                if (err) {
                    setBusy(false)
                    setBusyMsg(null)
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
                else {
                    resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                    setBusy(false)
                    setBusyMsg(null)
                    _fetchData();
                }
            })
        });
    }
    const _handleCloseModal = () => {
        setEditingCamp(INIT_CAMPAIGN);
        setShowModalEditing(false);
    }
    const _handleSave = (success) => {
        if (success) {
            setShowModalEditing(false);
            setEditingCamp(INIT_CAMPAIGN);
            _fetchData();
        }
    }
    const _renderAction = () => {
        return <div className='flex-row m-b-10'>
            <button className='btn btn-custom btn-sm' onClick={_handleAddCamp}>
                Thêm mới
            </button>
        </div>
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th>Id</th>
                <th>Tên chiến dịch</th>
                <th>Mô tả</th>
                <th width='200'>Thời gian bắt đầu</th>
                <th width='200'>Thời gian kết thúc</th>
                <th></th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        return <tbody>
            {
                campaigns && campaigns.length > 0 ?
                    campaigns.map((g, i) => {
                        return <tr key={i}>
                            <td width={50}>{g.id}</td>
                            <td>{g.title}</td>
                            <td>{g.description}</td>
                            <td>{Common.formatDate(new Date(g.startTime))}</td>
                            <td>{Common.formatDate(new Date(g.endTime))}</td>
                            <td width={130}>
                                <button className='btn btn-sm btn-custom m-r-10' onClick={() => _handleEdit(g)}>Sửa</button>
                                <button className='btn btn-sm btn-danger' onClick={() => _handleDelete(g.id)}>Xóa</button>
                            </td>
                        </tr>
                    })
                    :
                    <tr><td colSpan={6}>Không có dữ liệu</td></tr>
            }
        </tbody>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý chiến dịch VIP Affiliate' parentPage='Ranus' hrefParent='/' />
            <Loading show={busy} msg={busyMsg} />
            {
                campaigns && <CommonTable
                    data={campaigns}
                    paging={paging}
                    renderHeader={_renderHeader}
                    renderBody={_renderBody}
                    renderAction={_renderAction}
                    searchBasic
                    onFilter={(paging) => _fetchData(paging)}
                />
            }
            {
                showModalEditing && <AddOrEditVIPCampaign
                    campaign={editingCamp}
                    onClose={_handleCloseModal}
                    onSave={_handleSave}
                    show={showModalEditing}
                />
            }
        </React.Fragment>
    );
};

export default VIPCampaign;