import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import CustomizeServices from "../based/services/CustomizeServices";
import useFormValidate from "../hooks/useFormValidate";

const StockFontModel = {
    id: 0,
    fontFamily: "",
    fontSize: 24,
    fontWeight: 500,
    text: "",
    color: "#000000",
    textInfos: [],
    languageId: 0,
    isActive: true,
    lineHeight: 1,
};

const TextInfo = {
    id: 0,
    fontFamily: "",
    name: "",
    url: "",
    fontWeight: 0,
    fontStyle: 0,
};

const FontStyle = {
    Normal: 0,
    Italic: 1,
};

const FontStyleOptions = [
    { value: 0, label: "Normal" },
    { value: 1, label: "Italic" },
];

const FontWeightOptions = [
    { value: 1, label: "Light", fontWeight: 400 },
    { value: 2, label: "Normal", fontWeight: 500 },
    { value: 3, label: "Bold", fontWeight: 600 },
];

const AddOrEditModal = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [stockFont, setStockFont] = useState(StockFontModel);
    const { addRef, displayError, isValid } = useFormValidate();
    const { id, onSaveContinue, onClose, isShowModal } = props;
    const [language, setLanguage] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        _getLanguage();
    }, []);

    useEffect(() => {
        if (id > 0) _getStockFont(id);
        return () => setStockFont({});
    }, [id]);

    const _getStockFont = async (id) => {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetText(id);
        if (!err && data) {
            setStockFont({ ...data });
        } else setStockFont(StockFontModel);
        setIsBusy(false);
    };

    const _handleFontChange = (e) => {
        e.preventDefault();
        let files = e.target.files;
        if (files) {
            Request.UploadStockFont(files).then((res) => {
                if (res && res.data) {
                    let { listUrl, fontFamily, lineHeight } = res.data;
                    var textInfos = [];
                    if (listUrl) {
                        textInfos = listUrl.map((item, idx) => {
                            let fontAttribute = _handleFileName(item.fileName);
                            return {
                                id: 0,
                                fontFamily: item.familyName,
                                name: item.name,
                                url: item.url,
                                lineHeight: 1,
                                fontWeight: fontAttribute && fontAttribute.fontWeight,
                                fontStyle: fontAttribute && fontAttribute.fontStyle,
                            };
                        });
                    }
                    setStockFont({ ...stockFont, fontFamily: fontFamily, text: fontFamily, textInfos: textInfos, lineHeight: 1 });
                    setErrMsg("");
                } else {
                    if (res) setErrMsg(res.data);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
            });
        }
    };

    const _handleFileName = (fileName) => {
        let arrFileName = fileName.split("-");
        let fontFamily = arrFileName && arrFileName.length > 0 ? arrFileName[0] : "";
        let fontStyle = arrFileName && arrFileName.length > 1 ? (arrFileName[1] == "Normal" ? 0 : arrFileName[1] == "Italic" ? 1 : 0) : 0;
        let fontWeight = arrFileName && arrFileName.length > 2 ? +arrFileName[2] : 500;
        return {
            fontFamily,
            fontStyle,
            fontWeight,
        };
    };

    const _getLanguage = async () => {
        let [err, data] = await CustomizeServices.GetLanguage();
        if (!err && data) {
            let options = data.map((item) => ({ value: item.id, label: item.name }));
            setLanguage(options);
        } else setLanguage([]);
    };

    const _handleSave = async () => {
        if (isValid()) {
            setIsBusy(true);
            let model = { ...stockFont };
            let [err, data] = id > 0 ? await CustomizeServices.UpdateText(model) : await CustomizeServices.CreateText(model);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                onSaveContinue();
            } else {
                let erMs = err ? err.data : "Lưu thất bại";
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, erMs);
            }
            setIsBusy(false);
        }
    };

    const handleItem = (name, value, index) => {
        let st = { ...stockFont };
        let models = st.textInfos;
        let model = models[index];
        model[name] = value;
        setStockFont(st);
    };

    return (
        <Wrapper>
            <CommonModal show={isShowModal} id={Common.generateGuid()} size="lg" isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()} classModalBody={"modal-class-body"}>
                <div className="form-group">
                    <label htmlFor="font" className="required">
                        Stock Font:
                    </label>
                    <div className="sub-header">
                        Note:
                        <br />
                        -Link font chữ không được có khoản trắng. Bao gồm (woff2, woff, ttf) nhằm tối đa khả năng hiển thị trên các thiết bị
                        <br />
                        -Tên file theo format giúp việc input nhanh hơn
                        <br />
                        -Tên file: TenFont-FontStyle-FontWeight
                        <br />
                        -Các font .woff, .woff2 convert từ .ttf cần đặt giống tên File nhau, để hệ thống có thể xác định. VD: Arial-Light.tff, Arial-Light.woff, Arial-Light.woff2
                    </div>
                    <input
                        id="font"
                        type="file"
                        required
                        accept="file/*"
                        multiple
                        onChange={(e) => {
                            _handleFontChange(e);
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="font" className="required">
                        Danh sách font chữ:
                    </label>
                    {stockFont && (
                        <div className="table-responsive">
                            <table className="table table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Font Url</th>
                                        <th>Font Family Name</th>
                                        <th>Font Name</th>
                                        <th>Font Weight</th>
                                        <th>Font Type</th>
                                        <th className="tabledit-toolbar-column"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stockFont.textInfos &&
                                        stockFont.textInfos.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>
                                                        <span className="tabledit-span tabledit-identifier">{idx + 1}</span>
                                                    </td>
                                                    <td className="w-20">
                                                        <a href={item.url}>{item.url}</a>
                                                    </td>
                                                    <td className="w-20">{item.fontFamily}</td>
                                                    <td className="w-20">{item.name}</td>
                                                    <td>
                                                        <span
                                                            className="tabledit-span tabledit-identifier"
                                                            style={{
                                                                display: editIndex == idx ? "none" : "unset",
                                                            }}
                                                        >
                                                            {FontWeightOptions.find((x) => x.fontWeight == item.fontWeight) && FontWeightOptions.find((x) => x.fontWeight == item.fontWeight).label}
                                                        </span>
                                                        {editIndex == idx && (
                                                            <TFUSelect
                                                                options={FontWeightOptions}
                                                                value={FontWeightOptions.find((x) => x.fontWeight == item.fontWeight) && FontWeightOptions.find((x) => x.fontWeight == item.fontWeight).value}
                                                                onChanged={(value) => {
                                                                    let selected = FontWeightOptions.find((x) => x.value == value);
                                                                    handleItem("fontWeight", selected.fontWeight, idx);
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="tabledit-span tabledit-identifier"
                                                            style={{
                                                                display: editIndex == idx ? "none" : "unset",
                                                            }}
                                                        >
                                                            {item.fontStyle == 0 ? "Normal" : "Italic"}
                                                        </span>
                                                        {editIndex == idx && (
                                                            <TFUSelect
                                                                options={FontStyleOptions}
                                                                value={item.fontStyle}
                                                                onChanged={(value) => {
                                                                    handleItem("fontStyle", value, idx);
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="tabledit-edit-button btn btn-primary"
                                                            onClick={(e) => {
                                                                if (editIndex == idx) setEditIndex(-1);
                                                                else setEditIndex(idx);
                                                            }}
                                                        >
                                                            <span className="mdi mdi-pencil"></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <div className="form-group">
                    <label className="required" htmlFor="fontFamily">
                        Font Family
                    </label>
                    <input type="text" value={stockFont.fontFamily} required className="form-control" onChange={(e) => setStockFont({ ...stockFont, fontFamily: e.target.value })} readOnly />
                </div>
                <div className="form-group">
                    <label className="required" htmlFor="fontSize">
                        Text
                    </label>
                    <input
                        type="text"
                        id="text"
                        value={stockFont.text}
                        required
                        className="form-control"
                        onChange={(e) => {
                            setStockFont({ ...stockFont, text: e.target.value });
                        }}
                    />
                </div>
                <div className="form-group">
                    <label className="required" htmlFor="fontSize">
                        Font Size
                    </label>
                    <input
                        type="number"
                        id="fontSize"
                        required
                        value={stockFont.fontSize}
                        className="form-control"
                        onChange={(e) => {
                            setStockFont({ ...stockFont, fontSize: e.target.value });
                        }}
                    />
                </div>
                <div className="form-group">
                    <label className="required" htmlFor="fontWeight">
                        Font Weight
                    </label>
                    <input
                        type="number"
                        id="fontWeight"
                        required
                        value={stockFont.fontWeight}
                        className="form-control"
                        onChange={(e) => {
                            setStockFont({ ...stockFont, fontWeight: e.target.value });
                        }}
                    />
                </div>
                <div className="form-group">
                    <label className="required" htmlFor="color">
                        Font Color
                    </label>
                    <input
                        type="color"
                        id="color"
                        required
                        value={stockFont.color}
                        className="form-control"
                        onChange={(e) => {
                            setStockFont({ ...stockFont, color: e.target.value });
                        }}
                    />
                </div>
                <div className="form-group">
                    <label className="required" htmlFor="lineHeight">
                        Line Height
                    </label>
                    <input
                        type="number"
                        id="lineHeight"
                        required
                        value={stockFont.lineHeight}
                        className="form-control"
                        onChange={(e) => {
                            setStockFont({ ...stockFont, lineHeight: e.target.value });
                        }}
                    />
                </div>

                <div className="form-group">
                    <label className="required" htmlFor="language">
                        Ngôn ngữ
                    </label>
                    <TFUSelect
                        name="language"
                        options={language}
                        onChanged={(value) => {
                            setStockFont({ ...stockFont, languageId: +value });
                        }}
                        value={stockFont.languageId}
                    />
                </div>

                <div className="form-group">
                    <label className="required" htmlFor="language">
                        Hoạt động
                    </label>
                    <div className="sub-header">Hiển thị font chữ trên trang chủ</div>
                    <input
                        type={"checkbox"}
                        checked={stockFont.isActive}
                        className=""
                        onChange={(e) => {
                            setStockFont({ ...stockFont, isActive: e.target.checked });
                        }}
                    />
                </div>

                {errMsg && (
                    <div className="form-group">
                        <div className="text-danger">{errMsg}</div>
                    </div>
                )}
            </CommonModal>
        </Wrapper>
    );
};

export default AddOrEditModal;

AddOrEditModal.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};

const Wrapper = styled.div`
    .modal-class-body {
        min-height: 50vh;
    }
`;
