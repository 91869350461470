import React, { useState, useEffect } from "react";
import { NOTIFY, BANK_TRANSFER_STATUS } from "../based/Constants";
import { Notify } from "../based/Notify";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import TextArea from "../based/inputs/TextArea";
import useFormValidate from "../hooks/useFormValidate";
import TFUSelect from "../based/inputs/TFUSelect";
import CreditServices from "../based/services/CreditServices";
import BankServices from "../based/services/BankServices";

const BANK_NUMBER_REGEX = /^\d+$/;
const INVALID_BANK_NUMBER_REGEX_MSG = "Số tài khoản không đúng định dạng.";

export default function CreditFormModal(props) {
    const { addRef, displayError, isValid } = useFormValidate();

    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [bankTransferHistory, setBankTransferHistory] = useState(props.bankTransferHistory);
    const [dataBankAccounts, setDataBankAccounts] = useState(props.dataBankAccounts);

    useEffect(() => {
        setBankTransferHistory(props.bankTransferHistory);
        setDataBankAccounts(props.dataBankAccounts);
    }, [props.isShowModal]);

    async function _handleSave() {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu..");
            bankTransferHistory.sYS_BankAccountConfig = dataBankAccounts.find((x) => x.value == bankTransferHistory.bankAccountId);
            const [err, data] = await CreditServices.CreateBankTransfer(bankTransferHistory);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Tạo mới/Cập nhập thành công");
                setIsModalLoading(false);
                setMsgModalLoading(null);
                props.onSaveSucceed();
            } else {
                if (err.errors) displayError(err.errors);
                if (err.data) Notify(NOTIFY.ERROR, "Lỗi", err.data);
                else Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                setIsModalLoading(false);
                setMsgModalLoading(null);
            }
        }
    }

    async function _handleConfirm() {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu..");
            bankTransferHistory.sYS_BankAccountConfig = dataBankAccounts.find((x) => x.value == bankTransferHistory.bankAccountId);
            const [err, data] = await CreditServices.ApprovedBankTransfer(bankTransferHistory);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", bankTransferHistory.transferStatus == BANK_TRANSFER_STATUS.ApprovedTransaction ? "Đã chuyển toàn bộ tiền" : "Xác nhận thành công");
                setIsModalLoading(false);
                setMsgModalLoading(null);
                props.onSaveSucceed();
            } else {
                if (err.errors) displayError(err.errors);
                if (err.data) Notify(NOTIFY.ERROR, "Lỗi", err.data);
                else Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                setIsModalLoading(false);
                setMsgModalLoading(null);
            }
        }
    }

    async function _handleReject() {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu..");
            bankTransferHistory.sYS_BankAccountConfig = dataBankAccounts.find((x) => x.value == bankTransferHistory.bankAccountId);
            const [err, data] = await CreditServices.RejectBankTransfer(bankTransferHistory.id, bankTransferHistory.remark);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Đã từ chối đơn!");
                setIsModalLoading(false);
                setMsgModalLoading(null);
                props.onSaveSucceed();
            } else {
                if (err.errors) displayError(err.errors);
                if (err.data) Notify(NOTIFY.ERROR, "Lỗi", err.data);
                else Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                setIsModalLoading(false);
                setMsgModalLoading(null);
            }
        }
    }

    async function getBankAccountInfo(id) {
        const [err, data] = await BankServices.GetBankAccountInfo(id);
        if (!err && data) {
            var newbankTransferHistory = { ...bankTransferHistory };
            newbankTransferHistory.bankAccountId = id;
            newbankTransferHistory.bankAccountName = data.bankAccountName;
            newbankTransferHistory.bankAccountNumber = data.bankAccountNumber;
            newbankTransferHistory.bankName = data.bankName;
            setBankTransferHistory(newbankTransferHistory);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
        }
    }

    return (
        <CommonModal
            show={props.isShowModal}
            id={Common.generateGuid()}
            isBusy={isModalLoading}
            busyMsg={msgModalLoading}
            title={bankTransferHistory.id == 0 ? "Thêm mới" : "Chi tiết"}
            onSave={!bankTransferHistory.created ? () => _handleSave() : null}
            onTransferSuccessful={Common.IsAdmin() && bankTransferHistory.transferStatus == BANK_TRANSFER_STATUS.ApprovedTransaction ? () => _handleConfirm() : null}
            onConfirm={Common.IsAdmin() && bankTransferHistory.transferStatus == BANK_TRANSFER_STATUS.PendingTransaction ? () => _handleConfirm() : null}
            onReject={Common.IsAdmin() && bankTransferHistory.transferStatus != BANK_TRANSFER_STATUS.RejectedTransaction && bankTransferHistory.transferStatus != BANK_TRANSFER_STATUS.SuccessfulTransaction ? () => _handleReject() : null}
            onClose={() => props.onClose()}
        >
            <div className="form-group">
                <label htmlFor="receivedBankAccountId" className="col-form-label required">
                    Tài khoản nhận tiền:
                </label>
                <TFUSelect
                    name="receivedBankAccountId"
                    required
                    ref={addRef}
                    options={dataBankAccounts}
                    value={bankTransferHistory.bankAccountId}
                    placeholder="--Tài khoản nhận tiền--"
                    onChanged={(value) => {
                        getBankAccountInfo(value);
                    }}
                    isDisabled={bankTransferHistory.created}
                />
            </div>
            {bankTransferHistory.bankAccountId ? (
                <div>
                    <div className="form-group">
                        <label htmlFor="bankName" className="col-form-label required">
                            Tên ngân hàng nhận:
                        </label>
                        <Textbox
                            required
                            ref={addRef}
                            id="bankName"
                            name="bankName"
                            minLength={3}
                            maxLength={50}
                            allowCopy
                            className="form-control"
                            readOnly
                            onChanged={(name, value) => setBankTransferHistory({ ...bankTransferHistory, bankName: value })}
                            value={bankTransferHistory.bankName}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bankAccountNumber" className="col-form-label required">
                            Số tài khoản nhận:
                        </label>
                        <Textbox
                            required
                            ref={(ref) => addRef(ref, { pattern: BANK_NUMBER_REGEX, invalidMsg: INVALID_BANK_NUMBER_REGEX_MSG })}
                            minLength={4}
                            maxLength={20}
                            id="bankAccountNumber"
                            name="bankAccountNumber"
                            className="form-control"
                            readOnly
                            allowCopy
                            onChanged={(name, value) => setBankTransferHistory({ ...bankTransferHistory, bankAccountNumber: value })}
                            value={bankTransferHistory && bankTransferHistory.bankAccountNumber && bankTransferHistory.bankAccountNumber != 0 ? bankTransferHistory.bankAccountNumber.toString() : ""}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bankAccountName" className="col-form-label required">
                            Tên tài khoản nhận:
                        </label>
                        <Textbox
                            id="bankAccountName"
                            name="bankAccountName"
                            required
                            minLength={3}
                            maxLength={50}
                            allowCopy
                            ref={addRef}
                            className="form-control"
                            readOnly
                            onChanged={(name, value) => setBankTransferHistory({ ...bankTransferHistory, bankAccountName: value })}
                            value={bankTransferHistory.bankAccountName}
                        />
                    </div>
                </div>
            ) : null}
            <div className="form-group">
                <label htmlFor="amount" className="col-form-label required">
                    Số tiền:
                </label>
                <TextNumber
                    required
                    ref={addRef}
                    id="amount"
                    name="amount"
                    allowCopy
                    suffix="đ"
                    max={Number.MAX_SAFE_INTEGER}
                    readOnly={bankTransferHistory.created}
                    className="form-control"
                    onChanged={(name, value) => setBankTransferHistory({ ...bankTransferHistory, amount: value })}
                    value={bankTransferHistory && bankTransferHistory.amount != 0 ? +bankTransferHistory.amount : 0}
                />
            </div>
            {bankTransferHistory.transferStatus != 0 ? (
                <div className="form-group">
                    <label className="col-form-label font-weight-bold">Ghi chú</label>
                    <TextArea
                        name="remark"
                        className="form-control"
                        value={bankTransferHistory.remark}
                        onChanged={(name, value) => setBankTransferHistory({ ...bankTransferHistory, remark: value })}
                        readOnly={!Common.IsAdmin() || bankTransferHistory.transferStatus == BANK_TRANSFER_STATUS.RejectedTransaction}
                    />
                </div>
            ) : null}
        </CommonModal>
    );
}
