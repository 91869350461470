import React, { useEffect, useState } from 'react';
import CommonTable from '../based/CommonTable';
import LoadingR from '../based/LoadingR';
import BaseServices from '../based/services/BaseServices';
import Breadcum from '../layout/refactor/Breadcum/Breadcum';
import DownloadResourceConfigModal from './components/DownloadResourceConfigModal';
import RESOURCE_TYPE from './components/ResourceType';
import THIRD_PARTY_PLANS from './components/ThirdPartyPlan';

const menu = ['Trang chủ', 'Giới hạn số lần download trong ngày']
function DownloadResourceConfig(props) {
    const [data, setData] = useState(null);
    const [busy, setBusy] = useState(false);
    const [showConfigModal, setShowConfigModal] = useState(false);

    useEffect(() => {
        _fetchData();
    }, [])

    async function _fetchData() {
        setBusy(true);
        const [err, resData] = await BaseServices.Get('/api/downloadresource/get-download-resource-configs');
        setBusy(false);
        if (!err && resData) {
            setData(resData);
        }
    }
    function _renderHeader() {
        return <thead>
            <tr>
                <th>#</th>
                <th className='text-center cursor-pointer'>Loại tài khoản</th>
                <th className='text-center cursor-pointer'>Nguồn download</th>
                <th className='text-center cursor-pointer'>Giới hạn lượt download hàng ngày</th>
            </tr>
        </thead>
    }
    function _renderBody() {
        return <tbody>
            {data && data.length > 0 ? data.map((item, i) => {
                return <tr key={i}>
                    <td className='text-center'>
                        {i + 1}
                    </td>
                    <td>
                        {THIRD_PARTY_PLANS.filter(m => m.id == item.thirdPartyPlan)[0].name}
                    </td>
                    <td>
                        {RESOURCE_TYPE.filter(m => m.resourceType == item.resourceType)[0].name}
                    </td>
                    <td className='text-right'>
                        {
                            item.limitDownloadCount
                        }
                    </td>
                </tr>
            })
                :
                <tr>
                    <td colSpan={4}>Không có dữ liệu</td>
                </tr>
            }
        </tbody>
    }
    function _renderAction() {
        return <div className='flex-row'>
            <div className='form-group'>
                <button className='btn btn-custom btn-sm m-r-5' onClick={_handleEdit}>
                    <i className='fa fa-plus m-r-5' /> Cấu hình
                </button>
            </div>
        </div>
    }

    function _handleEdit() {
        setShowConfigModal(true);
    }
    function _handleSubmitConfig(isSuccess) {
        if (isSuccess) {
            setShowConfigModal(false);
            _fetchData();
        }
    }
    return (
        <React.Fragment>
            <Breadcum menu={menu} title='Giới hạn số lần download trong ngày' />
            <LoadingR fullScreen isShow={busy} />
            <CommonTable
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                renderAction={_renderAction}
            />
            <DownloadResourceConfigModal
                isShow={showConfigModal}
                onClose={() => setShowConfigModal(false)}
                onSubmit={_handleSubmitConfig}
                data={data}
            />
        </React.Fragment>
    );
}

export default DownloadResourceConfig;