import React, { useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import AppAccountCoordinateManagement from "./AppAccountCoordinateManagement";
import DeviceManagement from "./DeviceManagement";
import AppManagement from "./AppManagement";
import MainAccountManagement from "./MainAccountManagement";
import FolderManagement from "./FolderManagement";
import AppInFolderMangement from "./AppInFolderMangement";
import AppAccountNewFileManagement from "./AppAccountNewFileManagement";
import SubScriptManagement from "./SubScriptManagement";
import ScriptManagement from "./ScriptManagement";

const ReupManagement = () => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý Reup" parentPage="Ranus" hrefParent="/" />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                <li className="nav-item">
                    <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)}>
                        <span>Thiết bị</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 1 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(1)}>
                        <span>Ứng dụng</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 2 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(2)}>
                        <span>Tài khoản chính</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 3 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(3)}>
                        <span>Tọa độ Slot (Folder)</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 4 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(4)}>
                        <span>Tọa độ App bên trong Slot</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 5 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(5)}>
                        <span>Tọa độ tài khoản</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 6 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(6)}>
                        <span>Kịch bản con (Sub_Script)</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 7 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(7)}>
                        <span>Kịch bản lớn (Script)</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == 8 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(8)}>
                        <span>Tài khoản/Trang/Kênh</span>
                    </a>
                </li>
            </ul>
            {tabIndex == 0 && <DeviceManagement />}
            {tabIndex == 1 && <AppManagement />}
            {tabIndex == 2 && <MainAccountManagement />}
            {tabIndex == 3 && <FolderManagement />}
            {tabIndex == 4 && <AppInFolderMangement />}
            {tabIndex == 5 && <AppAccountNewFileManagement />}
            {tabIndex == 6 && <SubScriptManagement />}
            {tabIndex == 7 && <ScriptManagement />}
            {tabIndex == 8 && <AppAccountCoordinateManagement />}
        </React.Fragment>
    );
};

export default ReupManagement;
