import { WareslotProductsModel } from "./WareslotProductsModel";
export class ProductVariantModel {
  constructor() {
    this.id = 0;
    this.isActive = false;
    this.name = "";
    this.images = [];
    this.inventory = [new WareslotProductsModel()];
    this.sellerSku = "";

    //field get từ xử lý data
    this.totalSold = 0;
    this.totalGood = 0;
    this.totalDefective = 0;
    this.totalBroken = 0;
    this.totalPending = 0;
    this.isCollapse = false;
  }
}
