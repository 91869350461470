import React from "react";
import PropTypes from "prop-types";
import { Box, Table } from "../content/ct.elm";

function TableWidget(props) {
    return (
        <Box>
            <div className='box__header'>{props.title}</div>
            <div className='box__content'>
                <Table>
                    <thead>{props.renderHeader && props.renderHeader()}</thead>
                    <tbody>{props.renderBody && props.renderBody()}</tbody>
                </Table>
            </div>
        </Box>
    );
}

TableWidget.propTypes = {
    title: PropTypes.string,
    renderHeader: PropTypes.func,
    renderBody: PropTypes.func,
};

export default TableWidget;
