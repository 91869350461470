import React from "react";
import {useState, useEffect, useRef } from "react";
import { NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import CommonModal from "../../based/Modal";
import { Notify } from "../../based/Notify";
import MarketingServices from "../../based/services/MarketingServices";
import Common from "../../based/Common";
import { INITPAGING , CONSTANTS} from "../../based/Constants";
import styled from "styled-components";
import { ConfirmDialog } from "../../based/Dialog";
import { Modal } from "react-bootstrap";
import Loading from "../../based/Loading";
import ModalChangeSpecialDay from "./ModalChangeSpecialDay";


const TAB_CONFIG = {
    OPTION: 0,
    DATE: 1
}

const ModalConfigTimeReview = ({isShowModal, gift, onClose, campaignId}) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [tab , setTab] = useState(TAB_CONFIG.OPTION)
    const [isModal , setIsModal] = useState({
        isShow: false,
        date: null,
        specialApply: {},
    })
    const [configDefault , setConfigDefault] = useState([])
    const [specialDay , setSpecialDay] = useState([])
    const [giftId , setGiftId] = useState([])


    useEffect(()=>{
        if(gift && gift.id > 0){
            getGiftConfigByGiftId(gift.id)
            getSpecialDayByGiftId(gift.id)
        }else{
            setConfigDefault([])
            setSpecialDay([])
        }
    },[gift])

    const getGiftConfigByGiftId = async (giftId) => {
        let [err , data ] = await MarketingServices.GetGiftConfigByGiftId(giftId);
        if(!err && data){
            setConfigDefault(data)
        }else{
            setConfigDefault([])
        }
    }

    const getSpecialDayByGiftId = async (giftId) => {
        let [err , data ] = await MarketingServices.GetSpecialDayByGiftId(giftId);
        if(!err && data){
            setSpecialDay(data)
        }else{
            setSpecialDay([])
        }
    }




    return (
        <Modal show={isShowModal} onHide={onClose} size={'lg'} id={Common.generateGuid()} enforceFocus={false}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>
                    {tab === TAB_CONFIG.DATE && "Ngày áp dụng"}
                    {tab === TAB_CONFIG.OPTION && "Chi tiết giải thưởng"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor:'#f3f3f3'}}>
                <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                    <li className="nav-item">
                        <a className={tab === TAB_CONFIG.OPTION ? "nav-link active" : "nav-link btn-light"} onClick={() => {
                            setTab(TAB_CONFIG.OPTION)
                        }}>
                            <span>Giải thưởng</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={tab === TAB_CONFIG.DATE ? "nav-link active" : "nav-link btn-light"} onClick={() =>{
                            setGiftId(gift.id)
                            setTab(TAB_CONFIG.DATE)
                        }}>
                            <span>Ngày áp dụng</span>
                        </a>
                    </li>
                </ul>
                <ModalCreateUpdateGiftStyle 
                    className={`partner_modal `} >
                    
                    {tab === TAB_CONFIG.OPTION &&
                    <div className="partner_modal_body">
                        <div className="partner_modal_body_gift">
                        <span>
                            <label>Số lượng quà tối đa 1 user nhận</label>
                            <input type="number" min={0} max={59}  placeholder="00" value={gift.maxReceive} 
                                readOnly
                            />
                        </span>
                       <span>
                        <label>Trong khoảng thời gian</label>
                        <input type="text" min={0} max={59}  placeholder="00" value={gift.effectiveTime == -1 ? "Cả chiến dịch" : `${gift.effectiveTime} ngày`} 
                                readOnly
                            />
                       </span>
                        </div>
                        <div className="partner_modal_body-config">
                            <h5>Ngày mặc định ({configDefault.length})</h5>
                            <div className="partner_modal_body-config-list">
                                {
                                    configDefault && configDefault.length > 0 ? configDefault.map((item, idx)=>{
                                        return  <GiftConfigItem 
                                            idx={idx} 
                                            item={item}
                                            
                                        />
                                    }) : 
                                    ""
                                }
                            </div>
                        </div>
                        <div className="partner_modal_body-config">
                            <div className="d-flex" style={{alignItems: 'center'}}>
                                <h5>Ngày đặc biệt ({specialDay.length})</h5>
                               
                            </div>
                            {specialDay.length > 0 && specialDay.map((itm , i)=>{
                                return <div key={i} className="partner_modal_body-config-special">
                                    <div className="partner_modal_body-config-special-title">
                                        <div className="partner_modal_body-config-special-count">
                                            {specialDay.length - i}
                                        </div>
                                        <div className="partner_modal_body-config-special-name">
                                            {
                                            itm.name 
                                        } ({itm.configTimes.length})
                                        </div>
                                       
                                    </div>
                                    <div className="partner_modal_body-config-list">
                                        {
                                            itm.configTimes.length > 0 ? itm.configTimes.map((item, idx)=>{
                                                return  <ConfigTimeSpecialDayItem 
                                                    idx={idx} 
                                                    item={item}
                                                    
                                                />
                                            }) : 
                                           ""
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>}
                    {tab === TAB_CONFIG.DATE &&
                        <DateConfig campaignId={campaignId} isModal={isModal} specialDay={specialDay} giftId={giftId} />
                    }
                </ModalCreateUpdateGiftStyle>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <div className="box-button" style={{ display: "flex", justifyContent: 'center' }}>
                        <button className="btn btn-danger" onClick={()=>{onClose()}}>Đóng</button>
                        <button className="btn btn-custom"
                            onClick={()=>{
                                if(tab === TAB_CONFIG.OPTION){
                                    setGiftId(gift.id)
                                    setTab(TAB_CONFIG.DATE)
                                    
                                }
                                if(tab === TAB_CONFIG.DATE){
                                    onClose()
                                }
                            }}
                        >{tab === TAB_CONFIG.DATE ? "Xong" : "Tiếp theo"}</button> 
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
       

    );
};

export default ModalConfigTimeReview;
const DateConfig = ({giftId , isModal , specialDay, campaignId}) => {
    const [day, setDay] = useState([])
    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [year, setYear] = useState(new Date().getFullYear())
    const [campaign, setCampaign] = useState({})
    const [isModalApply, setIsModalApply] = useState({
        isShow: false,
        date: null,
        specialApply: {},
    })

    useEffect(()=>{
        if(giftId > 0)
            getMonthAndSpecialDayByGiftId(month , year)
    },[])

    useEffect(()=>{
        if(!isModal.isShow){
            getMonthAndSpecialDayByGiftId(month , year)
        }
    },[isModal])
    
    useEffect(()=>{
        getCampaign()
    },[])

    const getCampaign = async () => {
        let [err, data] = await MarketingServices.GetCampaignById(campaignId)
        if(!err && data){
            setCampaign(data)
        }else{
            setCampaign({})
        }
    }


    const getMonthAndSpecialDayByGiftId = async (month , year)  => {
        let [err , dt ] = await MarketingServices.GetMonthAndSpecialDayByGiftId(month , year , giftId);
        if(!err && dt){
            setDay(dt)
        }else{
            setDay([])
        }
        
    }
   

    

    return <div className="partner_modal_body">

        <div className="box-month-year">
            <div className="month-show">
                <span onClick={()=>{
                    let m = month - 1 > 0 ? month - 1 : 12
                    let y = month - 1 > 0 ? year : year - 1
                    setMonth(m)
                    setYear(y)
                    getMonthAndSpecialDayByGiftId(m, y)
                }}>
                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 2L2 10L9 18" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
                <span>{month}/{year}</span>
                <span onClick={()=>{
                    let m = month + 1 <= 12 ? month + 1 : 1
                    let y = month + 1 <= 12 ? year : year + 1
                    setMonth(m)
                    setYear(y)
                    getMonthAndSpecialDayByGiftId(m,y)
                }}>
                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 18L9 10L2 2" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
        </div>
        <div className="table-date">
            <div className="row-date-header">
                <div className="item-date-header">T2</div>
                <div className="item-date-header">T3</div>
                <div className="item-date-header">T4</div>
                <div className="item-date-header">T5</div>
                <div className="item-date-header">T6</div>
                <div className="item-date-header">T7</div>
                <div className="item-date-header">CN</div>
            </div>
            <div className="row-date-body">
                {day && day.length > 0 && day.map((item , idx)=>{
                    return <div key={idx}
                     className={`item-date ${item.month != month ? "faded" :  
                     new Date(campaign.startDate) <= new Date(item.year+"-"+item.month+"-"+item.day) && new Date(item.year+"-"+item.month+"-"+item.day) <= new Date(campaign.endDate) ? "active" : ""}`}
                        
                    >
                        <span>{item.day}</span>
                        {item.specialApply && <strong>{specialDay.length - specialDay.findIndex(x => x.id == item.specialApply.specialDayId)}</strong>}
                    </div>
                })}
            </div> 
        </div>
    </div>
}

const ConfigTimeSpecialDayItem = ({idx , item }) => {


    return <div key={idx} className="partner_modal_body-config-col">
        <div className="partner_modal_body-config-item">
            <div className="partner_modal_body-config-item-title">
                <h6>Khung giờ {idx + 1}</h6>
                
            </div>
            <div className="partner_modal_body-config-item-container">
                <div className="partner_modal_body-config-item-box time">
                    <div className="time-item">
                        <input type="number" min={0} max={23} placeholder="00" value={item.startTime.split(":")[0]} 
                                disabled
                                />
                        <span>:</span>
                        <input type="number" min={0} max={59} placeholder="00" value={item.startTime.split(":")[1]} 
                            disabled
                        />
                    </div>
                    <span>-</span>
                    <div className="time-item">
                        <input  type="number" min={0} max={23} placeholder="00" value={item.endTime.split(":")[0]}
                        disabled
                        
                        />
                        <span>:</span>
                        <input  type="number" min={0} max={59} placeholder="00" value={item.endTime.split(":")[1]} 
                            disabled
                        />
                    </div>
                </div>
                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Tỷ lệ trúng</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} max={100} placeholder="00" value={item.rateWin} 
                                disabled
                                />
                            <span>%</span>
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Lượng quà</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} placeholder="00" value={item.quantity} 
disabled
                                />
                            <span>cái</span>
                        </div>
                    </div>
                </div>  

                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Hạn sử dụng</span>
                    </div>
                    <div className="partner_modal_body-config-item-box-item"
                        
                    >
                        <span>
                            <i></i>
                            <svg 
                                
                            
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M11.1816 1L13.7276 3.54592L11.1816 6.09183" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.27148 7.36184V6.08889C2.27148 5.41367 2.53971 4.7661 3.01717 4.28865C3.49462 3.8112 4.14218 3.54297 4.8174 3.54297H13.7281" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.8174 15.002L2.27148 12.4561L4.8174 9.91016" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.7281 8.63867V9.91163C13.7281 10.5868 13.4599 11.2344 12.9824 11.7119C12.505 12.1893 11.8574 12.4575 11.1822 12.4575H2.27148" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                </div>  
                <div className="partner_modal_body-config-item-box">
                    {item.minuteExpiry != null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Hạn dùng</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.minuteExpiry} 
                                    disabled
                                    
                                    />
                                <span>Phút</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Dùng sau</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0}  placeholder="0" value={item.timeStartUsing} 
                                   disabled
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>
                                <div>Hạn dùng</div>
                                <div className="modal-button-detail">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_8397_2569)">
                                        <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_8397_2569">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.timeEndUsing} 
                                    disabled
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>
                    }
                </div>  
            </div>

        </div>
        
    </div>
}


const GiftConfigItem = ({idx , item
    }) => {
    
 
    return <div key={idx} className="partner_modal_body-config-col">
        <div className="partner_modal_body-config-item">
            <div className="partner_modal_body-config-item-title">
                <h6>Khung giờ {idx + 1}</h6>
                
            </div>
            <div className="partner_modal_body-config-item-container">
                <div className="partner_modal_body-config-item-box time">
                    <div className="time-item">
                        <input type="number" min={0} max={23} placeholder="00" value={item.startTime.split(":")[0]} 
                                disabled
                                />
                        <span>:</span>
                        <input type="number" min={0} max={59} placeholder="00" value={item.startTime.split(":")[1]} 
                            disabled
                        />
                    </div>
                    <span>-</span>
                    <div className="time-item">
                        <input  type="number" min={0} max={23} placeholder="00" value={item.endTime.split(":")[0]}
                        disabled
                        
                        />
                        <span>:</span>
                        <input  type="number" min={0} max={59} placeholder="00" value={item.endTime.split(":")[1]} 
                            disabled
                        />
                    </div>
                </div>
                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Tỷ lệ trúng</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} max={100} placeholder="00" value={item.rateWin} 
                                disabled
                                />
                            <span>%</span>
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Lượng quà</span>
                        <div className="config-item-box-item-input">
                            <input type="number" min={0} placeholder="00" value={item.quantity} 
                                disabled
                                />
                            <span>cái</span>
                        </div>
                    </div>
                </div>  

                <div className="partner_modal_body-config-item-box">
                    <div className="partner_modal_body-config-item-box-item">
                        <span>Hạn sử dụng</span>
                    </div>
                    <div className="partner_modal_body-config-item-box-item"
                        
                    >
                        <span>
                            <i></i>
                            <svg 
                                
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M11.1816 1L13.7276 3.54592L11.1816 6.09183" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.27148 7.36184V6.08889C2.27148 5.41367 2.53971 4.7661 3.01717 4.28865C3.49462 3.8112 4.14218 3.54297 4.8174 3.54297H13.7281" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.8174 15.002L2.27148 12.4561L4.8174 9.91016" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.7281 8.63867V9.91163C13.7281 10.5868 13.4599 11.2344 12.9824 11.7119C12.505 12.1893 11.8574 12.4575 11.1822 12.4575H2.27148" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                            
                        </span>
                    </div>
                </div>  
                <div className="partner_modal_body-config-item-box">
                    {item.minuteExpiry != null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Hạn dùng</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.minuteExpiry} 
                                    disabled
                                    
                                    />
                                <span>Phút</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Dùng sau</span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0}  placeholder="0" value={item.timeStartUsing} 
                                    disabled
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>}
                    {item.minuteExpiry == null &&
                        <div className="partner_modal_body-config-item-box-item">
                            <span>
                                <div>Hạn dùng</div>
                                <div className="modal-button-detail">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_8397_2569)">
                                        <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_8397_2569">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </span>
                            <div className="config-item-box-item-input">
                                <input type="number" min={0} placeholder="0" value={item.timeEndUsing} 
                                    disabled
                                    
                                    />
                                <span>Ngày</span>
                            </div>
                        </div>
                    }
                </div>  
            </div>
        </div>
        
    </div>
}

const ModalCreateUpdateGiftStyle = styled.div`
    width: 100%;
    bottom: 0;
    border-radius: 16px 16px 0 0 !important;
    position: relative;
    background-color: #f3f3f3 !important;
    font-family: 'Lexend';


    .modal-gift-config-add{
        color: #64C5B1;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 1; /* 142.857% */
        margin-left: 20px;
        cursor: pointer;
    }

    .partner_modal_body{
        

        .box-month-year{
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            .month-show{
                display: flex;
                width: 160px;
                margin-left: 10px;
                padding: 20px;
                align-items: center;
                justify-content: space-between;
                height: 38px;
                border-radius: 10px;
                background: #fff;
    
                span{
                    color: #444;
                    font-family: Lexend;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    cursor: pointer;
                    line-height: normal;
                }
            }
        }

        .table-date{
            margin-top: 15px;
            border-radius: 10px;
            overflow: hidden;

            .row-date-header{
                display: flex;
                
                .item-date-header{
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    height: 45px;
                    color: #FFF;
                    text-align: center;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 45px;
                    background:  #7A7A7A;
                    
                }
            }

            .row-date-body{
                display: flex;
                flex-wrap: wrap;

                .item-date{
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    cursor: pointer;
                    height: 80px;
                    padding: 12px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #EDEDED;
                    background: #FFF;

                    &.active{
                        border: 1px solid #EDEDED;
                        background: #C2F2D3;
                    }

                    &.faded{
                        span{
                            color: #D8D8D8;
                        }
                        strong{
                            display: none;
                        }
                    }
                    span{
                        color: #666;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    strong{
                        display: block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: #64C5B1;
                        color: #FFF;
                        text-align: center;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }
        }



        .partner_modal_body_gift{
            display: flex;
            margin-top: 20px;

            span{
                width: 30%;
                label{
                    display: block;
                }

                input[type="number"]{
                    width: calc(100% - 20px);
                    border-radius: 10px;
                    border: 1px solid #FFF;
                    background: #EDEDED;
                    height: 38px;
                    padding: 8px;

                    ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
        }


        .partner_modal_body-config{
            margin-top: 12px;


            .partner_modal_body-config-special{
                margin-top: 20px;

                .partner_modal_body-config-special-title{
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    
                    .partner_modal_body-config-special-count{
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #64C5B1;
                        color: #FFF;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 15px;
                    }
                    .partner_modal_body-config-special-name{
                        margin: 0 10px;
                        color: #64C5B1;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                        height: 32px;


                        input{
                            border-radius: 5px;
                            background: #FFF;
                            height: 32px;
                            border: none;
                            outline: none;
                            width: 250px;
                            padding: 0 5px;

                            ::placeholder{
                                color: #505050;
                                font-family: Lexend;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                    .partner_modal_body-config-special-icon{
                        color: #64C5B1;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                        margin-right: 10px;
                    }
                }
            }

            h5{
                color: #505050;
                font-family: Lexend;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 12px;
            }


            .partner_modal_body-config-list{
                display: flex;
                flex-wrap: wrap;

                .add-config{
                    margin: 50px 0;
                }

                .partner_modal_body-config-col{
                    width: 205px;
                    min-width: 205px;
                    max-width: 205px;
                    text-align: center;
                    display: flex;
                    position: relative;
                    justify-content: end;
                    margin-bottom: 10px;


                    input[type="number"]{
                        ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                  

                    .partner_modal_body-config-item{
                        width: 180px;
                        position: relative;

                        .add-first{
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translate(-100%, -50%);
                            cursor: pointer;
                        }
                        .add-last{
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(100%, -50%);
                            cursor: pointer;
                        }
                        
                        
                        
                        .partner_modal_body-config-item-title{
                            height: 38px;
                            border-radius: 10px 10px 0px 0px;
                            background: #7A7A7A;
                            padding: 0 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            h6{
                                color: #FFF;
                                font-family: Lexend;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1;
                                margin: 0;
                            }
    
                        }

                        .partner_modal_body-config-item-container{
                            background: #fff;
                            border-radius: 0 0 10px 10px;
                            padding: 8px;

                            .partner_modal_body-config-item-box{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 10px;

                                input{
                                    ::placeholder{
                                        opacity: 0.2;
                                    }
                                }

                                &.time{
                                    border-radius: 10px;
                                    border: 1px solid #FFF;
                                    background: #EDEDED;
                                    height: 38px;
                                    padding: 8px;

                                    span{
                                        color: #BDBDBD;
                                        font-size: 20px;
                                    }

                                    .time-item{
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 56px;

                                        
                                        input{
                                            width: 48%;
                                            border: none;
                                            outline: none;
                                            background-color: #EDEDED;
                                            text-align: center;
                                            

                                            ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                                                -webkit-appearance: none;
                                                margin: 0;
                                            }
                                        }
                                    }

                                }


                                .partner_modal_body-config-item-box-item{
                                    width: calc(50% - 5px);

                                    span{
                                        display: flex;
                                        justify-content: space-between;
                                        color: #505050;
                                        font-family: Lexend;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 1;
                                        margin-bottom: 5px;
                                    }
                                    .config-item-box-item-input{
                                        width: 100%;
                                        position: relative;

                                        input{
                                            width: 100%;
                                            height: 32px;
                                            border-radius: 10px;
                                            border: 1px solid #FFF;
                                            background: #EDEDED;
                                            color: #505050;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 1;
                                            padding: 0 37px 0 8px;
                                            border: none;
                                            outline: none;
                                        }
                                        span{
                                            position: absolute;
                                            top: 50%;
                                            right: 6px;
                                            transform: translateY(-50%);
                                        }
                                    }
                                }
                            }

                        }

                        
                    }
                }

                

            }
        }

        .modal-button-detail{
            position: relative;

            .customise-tooltip{
                width: 232px;
                text-align: left;
                .customise-tooltip-content{
                    line-height: 1.2;
                }
            }

            :hover{
                .customise-tooltip{
                    display: block;
                }
            }
        }
        .partner_modal_body-box{
            margin-top: 12px;
            border-radius: 10px;
            background: #FFF;
            padding: 12px;

            .partner_modal_body-box-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    color: #505050;
                    font-family: Lexend;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1;
                }

                h5{
                    color: #505050;
                    font-size: 12px;
                    font-style: normal;
                    line-height: normal;
                }
    
            }

           
            .input-name-gift{
                width: 100%;
                border-radius: 10px;
                border: 1px solid #E1E1E1;
                background: #FFF;
                color: #505050;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                padding: 0 12px;
                height: 42px;

            }

        }
    }

    .box-footer-modal{
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;
        height: 100px;

        

        .partner_modal_tab{
            height: 40px;
            display: flex;


            .partner_modal_tab-item{
                height: 40px;
                width: 50%;
                background: #F8F8F8;
                color: #7A7A7A;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                text-align: center;
                position: relative;

                :first-child{
                    border-radius: 0 0 10px 0;
                }
                :last-child{
                    border-radius: 0 0 0 10px;
                }

                .partner_modal_tab-item-line{
                    display: none;
                }

                &.active{
                    background-color: #fff;
                    color: #64C5B1;

                    .partner_modal_tab-item-line{
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 4px;
                        background: #64C5B1;
                        left: 0;
                        z-index: 5;
                    }
                    
                }
            }
        }

        .partner_modal_body-comfirm-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            padding: 0 16px;
            z-index: 1;
            background-color: #fff;
    
            button{
                border: none;
                outline: none;
                border-radius: 10px;
                width: 125px;
                height: 38px;
                color: #FFF;
                font-size: 14px;
                font-weight: 600;
                line-height: 38px;
                text-align: center;
                border: 2px solid #f4f4f4;
                color: #64C5B1;
                background-color: #fff;
                line-height: 1;
                margin: 0 5px;
               
    
                &.accept{
                    background: #64C5B1;
                    color: #fff;
                }
            }
    
        }
    }
    
    


    

    
`
ModalConfigTimeReview.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
