import React, { useEffect, useMemo, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import ProductionServices from "../based/services/ProductionServices";

function PrintingMainPage(props) {
    const { printingBatch } = props.match.params;
    const [listModel, setListModel] = useState([]);

    useEffect(() => {
        getData(printingBatch);
    }, [printingBatch]);

    const getData = async (printingBatch) => {
        const [err, data] = await ProductionServices.GetProductPickBatchesAsync(printingBatch);
        if (!err && data) {
            setListModel(data);
        } else {
            setListModel([]);
        }
    };

    const totalQuantity = useMemo(() => {
        return listModel.reduce((total, item) => total + item.totalQuantity, 0);
    }, [listModel]);

    return (
        <React.Fragment>
            <GlobalStyle />
            <Wrapper>
                <div className="header">
                    <div className="header-left">
                        <span>Tổng số lượng áo {totalQuantity}</span>
                    </div>
                </div>

                <div className="body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="center">STT</th>
                                <th className="center title">Tên sản phẩm</th>
                                <th className="center method">Màu</th>
                                <th className="center status">Size</th>
                                <th className="center status">Số lượng</th>
                                <th>Checkbox</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listModel && listModel.length > 0 ? (
                                listModel.map((x, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            onClick={(e) => {
                                                let temp = [...listModel];
                                                temp[index].isActive = !temp[index].isActive;
                                                setListModel(temp);
                                            }}
                                        >
                                            <td>{index + 1}</td>
                                            <td>{x.productName}</td>
                                            <td>{x.color}</td>
                                            <td>{x.size}</td>
                                            <td>{x.totalQuantity}</td>
                                            <td>
                                                <input type="checkbox" checked={x.isActive} onChange={(e) => {}} />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={1000}>Không có dữ liệu</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Wrapper>
        </React.Fragment>
    );
}

export default PrintingMainPage;

const Wrapper = styled.div`
    .header {
        margin: 10px 0;
    }
    .body {
        overflow: auto;
    }
`;

const GlobalStyle = createGlobalStyle`
body.enlarged {
    height: 100vh!important;
    min-height: 100vh!important;
}
div#root {
    height: 100%;
}
.content-page {
        height: 100%;
        overflow: auto;
    }
`;
