import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Breadcrumbs from '../../based/Breadcrumbs';
import Common from '../../based/Common';
import CommonTable from '../../based/CommonTable';
import { CONSTANTS, NOTIFY } from '../../based/Constants';
import { ConfirmDialog } from '../../based/Dialog';
import Loading from '../../based/Loading';
import { Notify } from '../../based/Notify';
import TextNumber from '../../based/refactor/TextNumber';
import BaseServices from '../../based/services/BaseServices';
const REFUND_STATUS = {
    APPROVED: 1,
    REFUNDED: 2,
    DECLINED: 3
}
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 50,
    keyword: ''
}
const VIPRefunds = () => {
    const [busy, setBusy] = useState(false);
    const [busyMsg, setBusyMsg] = useState('');
    const [data, setData] = useState([]);//sharing list
    const [paging, setPaging] = useState(INIT_PAGING);
    useEffect(() => {
        _fetchData(paging);
    }, [])
    const _fetchData = async (filter) => {
        setBusy(true);
        setBusyMsg("Loading...")
        const [err, data] = await BaseServices.Post('/api/affiliate/get-list-refunds', filter);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
        } else {
            setData([]);
        }
        setBusy(false);
        setBusyMsg('')
    }
    const _handleRefund = async (sharingId) => {
        ConfirmDialog('Xác nhận hoàn tiền?', 'Bạn chắc chắn đã hoàn tiền cho user này?', () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true)
                setBusyMsg('Đang xử lý...')
                const [err, data] = await BaseServices.Post(`/api/affiliate/update-cashback-status/${sharingId}/${REFUND_STATUS.REFUNDED}`);
                if (err) {
                    setBusy(false)
                    setBusyMsg(null)
                    Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                }
                else {
                    resolve({ title: 'Thành công', msg: 'Cập nhật thành công.' })
                    setBusy(false)
                    setBusyMsg(null)
                    _fetchData();
                }
            })
        });
    }
    const _renderHeader = () => {
        return <thead>
            <tr>
                <th>STT</th>
                <th>Người mua</th>
                <th>Mã đơn hàng</th>
                <th>Hình ảnh SP</th>
                <th>Tên SP</th>
                <th>Số lượng</th>
                <th>Số tiền được hoàn</th>
                <th>Trạng thái</th>
                <th></th>
            </tr>
        </thead>
    }
    const _renderBody = () => {
        return <tbody>
            {
                data && data.length > 0 ?
                    data.map((g, i) => {
                        return <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{g.buyerName}</td>
                            <td>{g.orderNumber}</td>
                            <td>
                                <img src={g.productAvatar} style={{ width: 50 }} />
                            </td>
                            <td>{g.productName}</td>
                            <td>{g.quantity}</td>
                            <td>
                                {/* <TextNumber text price value={g.productPrice} name='refund_price' /> */}
                                {Common.formatCurrency(g.productPrice)}
                            </td>
                            <td>{g.refundStatus == REFUND_STATUS.APPROVED ? 'Đang chờ hoàn tiền' : 'Đã hoàn tiền'}</td>
                            <td width={130}>
                                {g.refundStatus == REFUND_STATUS.APPROVED && <button className='btn btn-sm btn-custom m-r-10' onClick={() => _handleRefund(g.sharingId)}>Xác nhận đã hoàn tiền</button>}
                            </td>
                        </tr>
                    })
                    :
                    <tr><td colSpan={8}>Không có dữ liệu</td></tr>
            }
        </tbody>
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Hoàn tiền VIP Affiliate' parentPage='Ranus' hrefParent='/' />
            <Loading show={busy} msg={busyMsg} />
            {
                data && <CommonTable
                    data={data}
                    paging={paging}
                    renderHeader={_renderHeader}
                    renderBody={_renderBody}
                    searchBasic
                    onFilter={(paging) => _fetchData(paging)}
                />
            }
        </React.Fragment>
    );
};

export default VIPRefunds;