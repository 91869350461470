import React, { useEffect, useState } from "react";
import { CAMPAIGN_TYPE, NOTIFY } from "../based/Constants";
import { Block, Box } from "../based/content/ct.elm";
import { Notify } from "../based/Notify";
import BaseModal from "../based/refactor/BaseModal";
import CampaignServices from "../based/services/CampaignServices";
import { SetModel } from "../models/SetModel";
import ProductImages from "./components/ProductImages";
import ProductMilestones from "./components/ProductMilestones";
import ProductPreview from "./components/ProductPreview";

const initCampaign = {
    id: 0,
    title: "",
    shortLink: "",
    showTime: new Date(new Date() - 1),
    startTime: new Date(),
    endTime: new Date(),
    description: null,
    isActive: true,
    index: 1,
    type: CAMPAIGN_TYPE.MILESTONE,
    headerDesktopImages: [],
    headerMobileImages: [],
    app_Set: new SetModel(),
    milestones: [],
    campaignSetMileStones: [],
};

const Config = (props) => {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [campaign, setCampaign] = useState(initCampaign);

    useEffect(() => {
        if (props.campaignId > 0) getCampaign(props.campaignId);
    }, [props.isShowModal]);

    //lấy thông tin chiến dịch theo id
    const getCampaign = async (id) => {
        setIsModalLoading(true);
        setMsgModalLoading("Đang tải dữ liệu...");
        const [err, data] = await CampaignServices.GetCampaignById(id, CAMPAIGN_TYPE.MILESTONE);
        if (!err && data) {
            var camp = data;
            camp.showTime = new Date(camp.showTime);
            camp.startTime = new Date(camp.startTime);
            camp.endTime = new Date(camp.endTime);
            setCampaign(camp);
        } else {
            setCampaign(initCampaign);
        }
        setIsModalLoading(false);
        setMsgModalLoading(null);
    };

    //lưu/cập nhập thông tin chiến dịch
    const _handleSave = async () => {
        let cp = { ...campaign };
        const valid = checkValid(cp);
        console.log("cp", cp);
        if (valid) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu...");
            let ms = cp.milestones;
            ms.map((item,idx) => {
                if(idx == 0) item.toQty = item.quantity
                else item.toQty = item.quantity + ms[idx-1].toQty
            })
            const [err, data] = await CampaignServices.AddOrUpdateCampaign(campaign);
            if (!err && data) {
                setIsModalLoading(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công!");
                setMsgModalLoading(null);
                props.onSuccess();
                setCampaign(initCampaign);
            } else {
                let errMsg = "Có lỗi xảy ra. Vui lòng kiểm tra lại thông tin và thử lại sau!";
                if (err) {
                    if (err.errors && err.errors[0].value) errMsg = err.errors[0].value;
                    if (err.data) errMsg = err.data;
                }
                Notify(NOTIFY.ERROR, "Lỗi", errMsg);
                setIsModalLoading(false);
                setMsgModalLoading(null);
            }
        }
    };

    //kiểm tra giá trị input
    const checkValid = (campaign) => {
        if (campaign.showTime >= campaign.startTime) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Ngày hiển thị phải nhỏ hơn ngày bắt đầu!");
            return false;
        }
        return true;
    };

    //chọn từ danh sách các set artwork
    const handleSelectProduct = (set) => {
        if (set) {
            //lấy ra thông tin artworks
            let artworks = set.artworks;
            if (artworks && artworks.length > 0) {
                artworks = artworks.map((item, idx) => ({ thumbnail: item.thumbnail, artworkType: item.artworkType }));
            }

            //inject dữ liệu vào model
            let st = new SetModel();
            st.id = set.id;
            st.title = set.title;
            st.artworks = artworks;
            st.lotusChargeAmount = set.lotusChargeAmount;
            st.loyaltyAmount = set.loyaltyAmount;
            setCampaign({ ...campaign, title: set.title, app_Set: st, ageSetId: set.id });
        } else {
            Notify(NOTIFY.WARNING, "Lưu ý", "Sản phẩm đang tham gia chiến dịch khác!");
        }
    };

    return (
        <BaseModal
            isShow={props.isShowModal}
            title={`${props.campaignId ? "Chỉnh sửa" : "Đăng ký"} chiến dịch`}
            onSave={() => _handleSave()}
            onClose={() => {
                props.onClose();
                setCampaign(initCampaign);
            }}
        >
            <Box>
                <div className='box__header'>
                    <h5 className='m-0'>
                        <b className='d-flex align-items-center'>
                            <div className='round-number mr-2'>1</div> Chọn sản phẩm - Thời gian
                        </b>
                    </h5>
                </div>
                <div className='box__content'>
                    <Block flexBasis='60%' boxShadow='none'>
                        <ProductImages handleSelectProduct={handleSelectProduct} readOnly={props.campaignId > 0} />
                    </Block>
                    <Block flexBasis='40%' boxShadow='none' style={{ marginTop: "57px", padding: "17px 22px", minHeight: "565px" }}>
                        <ProductPreview campaign={campaign} setCampaign={setCampaign} readOnly={props.campaignId > 0} />
                    </Block>
                </div>
            </Box>
            <Box>
                <div className='box__header'>
                    <h5 className='m-0'>
                        <b className='d-flex align-items-center'>
                            <div className='round-number  mr-2'>2</div> Tạo bước giá
                        </b>
                    </h5>
                </div>
                <div className='box__content'>
                    <ProductMilestones campaign={campaign} setCampaign={setCampaign} />
                </div>
            </Box>
        </BaseModal>
    );
};

export default Config;
