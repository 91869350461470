import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../based/Modal";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import TFUDatetime from "../based/inputs/TFUDatetime";
import NumberFormat from "react-currency-format";
import CommonTable from "../based/CommonTable"; 
import Common from "../based/Common";

const TAB_SCREENS = {
    INFO: 0,
    PRODUCTS: 1,
};

export default function VoucherDetail(props) {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState({});

    useEffect(() => {
        setVoucher(props.voucher);
        setMsg("");
    }, [props.isShowModal]);

    const TabHeader = useMemo(() => (
        <div className='p-b-10'>
            <ul className='nav nav-tabs tabs-bordered nav-justified'>
                <li className='nav-item'>
                    <a className={currentTabIndex == TAB_SCREENS.INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setCurrentTabIndex(TAB_SCREENS.INFO)}>
                        <span className='d-none d-sm-block'>Thông tin Voucher</span>
                    </a>
                </li>
                <li className='nav-item'>
                    <a className={currentTabIndex == TAB_SCREENS.PRODUCTS ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setCurrentTabIndex(TAB_SCREENS.PRODUCTS)}>
                        <span className='d-none d-sm-block'>Thiết lập sản phẩm Voucher</span>
                    </a>
                </li>
            </ul>
        </div>
    ));

    function _renderInfo() {
        return (
            <div className='col-md-12 card-box p-3'>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='code'>Mã Voucher:</label>
                            <Textbox name='code' readOnly className='form-control font-weight-bold' onChanged={(name, value) => {}} value={voucher.code} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='name'>Tên Voucher:</label>
                            <Textbox name='name' readOnly className='form-control' onChanged={(name, value) => {}} value={voucher.name} />
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='fromDate'>Ngày bắt đầu sử dụng:</label>
                            <TFUDatetime dateFormat='dd/MM/yyyy  HH:mm' readOnly name='fromDate' value={voucher.fromDate} onChanged={(newValue) => {}} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='toDate'>Ngày kết thúc sử dụng:</label>
                            <TFUDatetime dateFormat='dd/MM/yyyy  HH:mm' readOnly name='toDate' value={voucher.toDate} onChanged={(newValue) => {}} />
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='registerStartDate'>Ngày bắt đầu đăng ký:</label>
                            <TFUDatetime dateFormat='dd/MM/yyyy  HH:mm' readOnly name='registerStartDate' value={voucher.registerStartDate} onChanged={(newValue) => {}} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='registerEndDate'>Ngày kết thúc đăng ký:</label>
                            <TFUDatetime dateFormat='dd/MM/yyyy  HH:mm' readOnly name='registerEndDate' value={voucher.registerEndDate} onChanged={(newValue) => {}} />
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor='displayStartDate'>Ngày hiển thị Voucher:</label>
                            <TFUDatetime dateFormat='dd/MM/yyyy  HH:mm' readOnly name='displayStartDate' value={voucher.displayStartDate} onChanged={(newValue) => {}} />
                        </div>
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='description'>Mô tả:</label>
                    <TextArea readOnly name='description' value={voucher.description} onChanged={(name, value) => {}} />
                </div>
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th>Tên sản phẩm</th>
                    <th width='200' className='text-center'>
                        Giá thường ngày (đ)
                    </th>
                    <th width='200' className='text-center'>
                        Giá Promote Brand (đ)
                    </th>
                    <th width='200' className='text-center'>
                        Giá Loyalty (đ)
                    </th>
                    <th width='150' className='text-center'>
                        Ranus Operation Fee (đ)
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {voucher.voucherPromoteHighlights && voucher.voucherPromoteHighlights.length > 0 ? (
                    voucher.voucherPromoteHighlights.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    <strong> {item.productName}</strong>
                                    {item.sellerSKU && <p className='margin-bottom-0 font-italic'>SKU: {item.sellerSKU ? item.sellerSKU : "----"}</p>}
                                    {item.attributeValuesDetail &&
                                        item.attributeValuesDetail.length > 0 &&
                                        item.attributeValuesDetail.map((attribute, at) => {
                                            return (
                                                <p key={at} className='margin-bottom-0 font-italic'>
                                                    {attribute.name} : <span className='badge badge-custom'>{attribute.displayName}</span>
                                                </p>
                                            );
                                        })}
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.sellPrice} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.voucherProductPrice} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.mustLoyaltyAmount} />
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.mustLotusCharge} />
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Chưa có sản phẩm nào được chọn</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {}
    function _renderPromoteHighlight() {
        return (
            <div className='col-md-12 card-box p-3'>
                <div style={{ maxHeight: 500, overflow: "auto" }}>
                    <CommonTable title='Danh sách sản phẩm' data={voucher.voucherPromoteHighlights} paging={Common.Paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} freeSize='tbl_setproducts' />
                </div>
            </div>
        );
    } 
    return (
        <CommonModal show={props.isShowModal} isBusy={isLoading} busyMsg={msg} size='lg' title='Chi tiết Voucher' onClose={() => props.onClose()}>
            {TabHeader}
            {currentTabIndex == TAB_SCREENS.INFO && _renderInfo()}
            {currentTabIndex == TAB_SCREENS.PRODUCTS && _renderPromoteHighlight()}
        </CommonModal>
    );
}
