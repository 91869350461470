import React, { useEffect, useMemo, useState } from "react";
import Common from "../based/Common";
import { CARD_COLOR, NOTIFY } from "../based/Constants";
import { Block, Box, BtnRounded } from "../based/content/ct.elm";
import CheckBox from "../based/refactor/CheckBox";
import TFUImage from "../based/inputs/Image";
import LotusUpload from "../based/inputs/LotusUpload";
import TextArea from "../based/refactor/TextArea";
import Textbox from "../based/refactor/Textbox";
import TextNumber from "../based/refactor/TextNumber";
import { Notify } from "../based/Notify";
import BaseModal from "../based/refactor/BaseModal";
import ButtonTab from "../based/refactor/ButtonTab";
import BrandServices from "../based/services/BrandServices";
import useFormValidate from "../hooks/useFormValidate";

const TAB_SCREEN = {
    INFO: "INFO",
    DOCUMENTS: "DOCUMENTS",
};

export default function AddOrEdit(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [brand, setBrand] = useState(props.brand);
    const { addRef, displayError, isValid } = useFormValidate();
    const [tabIndex, setTabIndex] = useState();

    useEffect(() => {
        setBrand(props.brand);
        setTabIndex(TAB_SCREEN.INFO);
    }, [props.isShowModal]);

    async function _handleSave(isContinue) {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu..");
            let [err, data] = await BrandServices.SaveBrand(brand);
            if (!err) {
                setMsgModalLoading(null);
                setIsModalLoading(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
                if (isContinue) setBrand(props.brand);
            } else {
                setMsgModalLoading(null);
                setIsModalLoading(false);
                if (err.errors) {
                    let outOfConfig = err.errors.find((x) => x.key == "outOfConfig");
                    if (outOfConfig) {
                        Notify(NOTIFY.ERROR, "Lỗi", outOfConfig.value);
                    }
                    displayError(err.errors);
                } else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        }
    }

    function _handleChangedOPFBrand(item, platform) {
        let brandUp = { ...brand };
        if (!brandUp.opfBrands || brandUp.opfBrands.length == 0) brandUp.opfBrands = [];
        let currentOPF = brandUp.opfBrands.filter((x) => x.platform == platform.value);
        if (currentOPF && currentOPF.length > 0) {
            if (item.id) currentOPF[0].id = item.value;
            else brandUp.opfBrands = brandUp.opfBrands.filter((x) => x.platform != platform.value);
        } else {
            let obj = {
                id: item.value,
                platform: platform.value,
            };
            brandUp.opfBrands.push(obj);
        }
        setBrand(brandUp);
    }
    const TabHeader = useMemo(() => (
        <div className='p-b-10 w-100'>
            <ul className='nav nav-tabs tabs-bordered'>
                <li className='nav-item'>
                    <ButtonTab className={`${tabIndex == TAB_SCREEN.INFO ? "active" : ""} mx-1`} title='Thông tin thương hiệu' onClick={() => setTabIndex(TAB_SCREEN.INFO)} />
                </li>
                <li className='nav-item'>
                    <ButtonTab className={`${tabIndex == TAB_SCREEN.DOCUMENTS ? "active" : ""} mx-1`} title='Tài liệu thương hiệu' onClick={() => setTabIndex(TAB_SCREEN.DOCUMENTS)} />
                </li>
            </ul>
        </div>
    ));
    function _renderInfoTab() {
        return (
            <div className='form-row' style={{ width: '100%' }}>
                <div className='col-md-12'>
                    <div className='card-body' style={{ paddingTop: 5 }}>
                        <h4 className='header-title m-t-0'>
                            <b>Thông tin thương hiệu</b>
                        </h4>
                        <div className='form-row'>
                            <div className='form-group col-md-8'>
                                <label htmlFor='name' className='required'>
                                    Tên thương hiệu:
                                </label>
                                <Textbox
                                    id='name'
                                    name='name'
                                    required
                                    ref={addRef}
                                    maxLength={255}
                                    className='form-control'
                                    onChanged={(name, value) => setBrand({ ...brand, name: value })}
                                    onBlur={(event) => setBrand({ ...brand, name: event.target.value.trim() })}
                                    value={brand.name}
                                />
                            </div>
                            <div className='form-group col-md-4'>
                                <label htmlFor='index'>Thứ tự hiển thị:</label>
                                <TextNumber id='index' name='index' ref={addRef} min={0} max={999999999} className='form-control' onChanged={(name, value) => setBrand({ ...brand, index: value ? value : 0 })} value={brand.index} />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className="url" htmlFor='linkWebsite'>Link Website:</label>
                            <Textbox
                                name='linkWebsite'
                                ref={addRef}
                                maxLength={500}
                                url
                                className='form-control'
                                onChanged={(name, value) => setBrand({ ...brand, linkWebsite: value })}
                                onBlur={(event) => setBrand({ ...brand, linkWebsite: event.target.value.trim() })}
                                value={brand.linkWebsite}
                            />
                        </div>
                        <div className='form-group'>
                            <label className="url" htmlFor='facebookPage'>Facebook Page:</label>
                            <Textbox
                                name='facebookPage'
                                ref={addRef}
                                url
                                maxLength={500}
                                className='form-control'
                                onChanged={(name, value) => setBrand({ ...brand, facebookPage: value })}
                                onBlur={(event) => setBrand({ ...brand, facebookPage: event.target.value.trim() })}
                                value={brand.facebookPage}
                            />
                        </div>
                        <div className='form-group'>
                            <label className="url" htmlFor='instagram'>Instagram:</label>
                            <Textbox
                                name='instagram'
                                ref={addRef}
                                url
                                maxLength={500}
                                className='form-control'
                                onChanged={(name, value) => setBrand({ ...brand, instagram: value })}
                                onBlur={(event) => setBrand({ ...brand, instagram: event.target.value.trim() })}
                                value={brand.instagram}
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='description'>Mô tả:</label>
                            <TextArea className='form-control' rows='3' value={brand.description} onChanged={(name, value) => setBrand({ ...brand, description: value })} />
                        </div>
                        <div className='form-group'>
                            <div className='custom-file d-block form-row left h-100'>
                                <label className='btn btn-rounded btn-primary' htmlFor='image'>
                                    Tải Logo lên
                                </label>
                                <div className='full-width'>
                                    <small>
                                        (Định dạng ảnh cho phép: <strong>*.png</strong>, Dung lượng tối đa: <strong>5MB</strong>, Kích thước tối đa: <strong>5x5 cm</strong>, DPI/PPI: <strong>300</strong>)
                                    </small>
                                </div>
                                <TFUImage
                                    image={brand.image} ref={addRef} imageType='brand' onChanged={(name, value) => setBrand({ ...brand, image: value })} name='image'
                                    className={"custom-file-input d-none"}
                                    id={"image"}
                                />
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className='custom-file d-block form-row left h-100'>
                                <label className='btn btn-rounded btn-primary' htmlFor='subImage'>
                                    Tải Sub Logo lên
                                </label>
                                <div className='full-width'>
                                    <small>
                                        (Định dạng ảnh cho phép: <strong>*.png</strong>, Dung lượng tối đa: <strong>5MB</strong>, Kích thước tối đa: <strong>5x5 cm</strong>, DPI/PPI: <strong>300</strong>)
                                    </small>
                                </div>
                                <TFUImage
                                    image={brand.subImage} imageType='brand' onChanged={(name, value) => setBrand({ ...brand, subImage: value })} name='subImage'
                                    className={"custom-file-input d-none"}
                                    id={"subImage"}
                                />
                            </div>
                        </div>
                        <div className='form-group m-1'>
                            <CheckBox id='isActive' name='isActive' checked={brand.isActive} onCheckedChange={(name, value) => setBrand({ ...brand, isActive: value })} label='Kích hoạt' />
                        </div>
                    </div>
                </div>
                <div className='col-md-4  hide'>
                    <div className='card-body' style={{ paddingTop: 5 }}>
                        <h5 className='card-title'>THÔNG TIN SEO</h5>
                        <div className='form-group'>
                            <label htmlFor='metaTitle'>MetaTitle:</label>
                            <Textbox id='metaTitle' className='form-control' onChanged={(name, value) => setBrand({ ...brand, metaTitle: value })} value={brand.metaTitle} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='metaKeywords'>MetaKeywords:</label>
                            <TextArea id='metaKeywords' className='form-control' onChanged={(name, value) => setBrand({ ...brand, metaKeywords: value })} value={brand.metaKeywords} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='metaDescription'>MetaDescription:</label>
                            <TextArea className='form-control' rows='3' value={brand.metaDescription} onChanged={(name, value) => setBrand({ ...brand, metaDescription: value })} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _handleChangeDocument(key, value) {
        setBrand({ ...brand, certificatesBrand: value });
    }
    function _renderDocumentTab() {
        return (
            tabIndex == TAB_SCREEN.DOCUMENTS && (
                <div className='form-row' style={{ width: '100%' }}>
                    <div className='col-md-12'>
                        <div className='card card-body' style={{ paddingTop: 5 }}>
                            <h4 className='header-title m-t-0'>
                                <b>Tài liệu yêu cầu cho thương hiệu</b>
                            </h4>
                            <div className='form-group'>
                                <div className='alert alert-icon alert-info text-info lotus-alert-info'>
                                    <i className='mdi mdi-information mr-2'></i>
                                    <span className='text-black'>Giấy tờ chứng minh quyền sở hữu thương hiệu</span>
                                </div>
                            </div>
                            <div className='form-group'>
                                <LotusUpload
                                    // isShowUpload={false}
                                    name='certificateBrand'
                                    buttonName='Nhấn vào để tải lên tài liệu'
                                    description={<span>(.PDF, .JPG, .JPEG, .PNG) | Tối đa 3 MB | Tối đa 5 tệp tin</span>}
                                    accept='.pdf,.jpg,.jpeg,.png'
                                    fileLimit={5}
                                    sizeLimit={3}
                                    documentType='CertificateBrandOwnership'
                                    multiple
                                    isAction
                                    // isDelete={false}
                                    // isPreviewFile={false}
                                    // isShowFileName
                                    documents={brand.certificatesBrand}
                                    onChanged={_handleChangeDocument}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
    return (
        <BaseModal className='modal-width-40' title={brand.id == 0 ? "Thêm mới" : "Cập nhật"} isShow={props.isShowModal}
            onClose={props.onClose}
            customButton={
                    <React.Fragment>
                        <BtnRounded className="mr-3" width='130px' color={CARD_COLOR.Green} onClick={() => _handleSave(false)}>Lưu</BtnRounded>
                        <BtnRounded className="mr-3" width='130px' color={CARD_COLOR.Blue} onClick={() => _handleSave(true)}>Lưu và tiếp tục</BtnRounded>
                    </React.Fragment>
            }
            id={Common.generateGuid()}>
            <Box>
                {!Common.IsAdmin() && TabHeader}
                {tabIndex == TAB_SCREEN.INFO ? _renderInfoTab() : _renderDocumentTab()}
            </Box>
        </BaseModal>
    );
}