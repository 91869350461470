export class MilestoneModel {
    constructor(level, loyaltyAmount) {
        this.level = level || 0;
        this.quantity = 1;
        this.loyaltyAmount = loyaltyAmount || 0;
        this.lotusChargeAmount = 0;
        this.sellPrice = 0;
    }
}

