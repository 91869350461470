import { INITPAGING } from "../../based/Constants";
import { CollectionModel } from "../../models/CollectionModel";
import { CommonState } from "../../models/CommonState";
import { actionTypes } from "./types";

import imageReducer from "./reducer/image";
import textReducer from "./reducer/text";
import embedReducer from "./reducer/embed";
import mockupReducer from "./reducer/mockup";
import mockupImageReducer from "./reducer/mockupImage";
import projectReducer from "./reducer/project";

////// Initial Data
const COMMON_STATE = new CommonState();
const INITIAL_STATE = {
  items: {
    ...COMMON_STATE,
    data: [],
  },
  embedModal: {
    isShow: false,
    isError: false,
    content: "",
    embedId: "",
    type: "",
  },
  mockupModal: {
    isShow: false,
    type: "",
    mockupId: "",
    mainImage: {
      url: "",
      objectId: "",
    },
    subImages: [],
  },
  mockupImageModal: {
    ...COMMON_STATE,
    data: {
      isShow: false,
      hasMore: true,
      pickedImageId: null,
      paging: { ...INITPAGING },
      items: [],
    },
  },
  project: {
    ...COMMON_STATE,
    data: new CollectionModel(),
  },
};
const INITIAL_ACTION = {
  type: "",
  payload: null,
};

const toggleFullWidth = (state, itemId) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  const isFullWidth = updatedData[itemIdx].data.isFullWidth;
  updatedData[itemIdx].data.isFullWidth = !isFullWidth;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const reducer = (state = INITIAL_STATE, action = INITIAL_ACTION) => {
  switch (action.type) {
    ////// Image
    // Add
    case actionTypes.PROJECT_EDITOR_ADD_IMAGE_BEGIN:
      return imageReducer.fetchImageBegin(state);
    case actionTypes.PROJECT_EDITOR_ADD_IMAGE_SUCCESS:
      return imageReducer.addImageSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_ADD_IMAGE_FAIL:
      return imageReducer.fetchImageFail(state, action.payload);
    // Remove
    case actionTypes.PROJECT_EDITOR_REMOVE_IMAGE_BEGIN:
      return imageReducer.fetchImageBegin(state);
    case actionTypes.PROJECT_EDITOR_REMOVE_IMAGE_SUCCESS:
      return imageReducer.removeImageSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_REMOVE_IMAGE_FAIL:
      return imageReducer.fetchImageFail(state, action.payload);
    // Replace
    case actionTypes.PROJECT_EDITOR_REPLACE_IMAGE_BEGIN:
      return imageReducer.fetchImageBegin(state);
    case actionTypes.PROJECT_EDITOR_REPLACE_IMAGE_SUCCESS:
      return imageReducer.replaceImageSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_REPLACE_IMAGE_FAIL:
      return imageReducer.fetchImageFail(state, action.payload);
    // Insert
    case actionTypes.PROJECT_EDITOR_INSERT_IMAGE_BEGIN:
      return imageReducer.fetchImageBegin(state);
    case actionTypes.PROJECT_EDITOR_INSERT_IMAGE_SUCCESS:
      return imageReducer.insertImageSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_INSERT_IMAGE_FAIL:
      return imageReducer.fetchImageFail(state, action.payload);

    ////// Text
    case actionTypes.PROJECT_EDITOR_ADD_TEXT:
      return textReducer.addText(state);
    case actionTypes.PROJECT_EDITOR_REMOVE_TEXT:
      return textReducer.removeText(state, action.payload);
    case actionTypes.PROJECT_EDITOR_INSERT_TEXT:
      return textReducer.insertText(state, action.payload);
    case actionTypes.PROJECT_EDITOR_SET_TEXT:
      return textReducer.setText(state, action.payload);

    ////// Embed
    // Item
    case actionTypes.PROJECT_EDITOR_ADD_EMBED:
      return embedReducer.addEmbed(state, action.payload);
    case actionTypes.PROJECT_EDITOR_REMOVE_EMBED:
      return embedReducer.removeEmbed(state, action.payload);
    case actionTypes.PROJECT_EDITOR_EDIT_EMBED:
      return embedReducer.editEmbed(state, action.payload);
    case actionTypes.PROJECT_EDITOR_INSERT_EMBED:
      return embedReducer.insertEmbed(state, action.payload);
    // Modal
    case actionTypes.PROJECT_EDITOR_OPEN_EMBED_MODAL:
      return embedReducer.openEmbedModal(state, action.payload);
    case actionTypes.PROJECT_EDITOR_CLOSE_EMBED_MODAL:
      return embedReducer.closeEmbedModal(state);

    /////// Mockup
    // Item
    case actionTypes.PROJECT_EDITOR_ADD_MOCKUP:
      return mockupReducer.addMockup(state);
    case actionTypes.PROJECT_EDITOR_EDIT_MOCKUP:
      return mockupReducer.editMockup(state, action.payload);
    case actionTypes.PROJECT_EDITOR_INSERT_MOCKUP:
      return mockupReducer.insertMockup(state, action.payload);
    case actionTypes.PROJECT_EDITOR_REMOVE_MOCKUP:
      return mockupReducer.removeMockup(state, action.payload);
    // Modal
    case actionTypes.PROJECT_EDITOR_OPEN_MOCKUP_MODAL:
      return mockupReducer.openMockupModal(state, action.payload);
    case actionTypes.PROJECT_EDITOR_CLOSE_MOCKUP_MODAL:
      return mockupReducer.closeMockupModal(state);
    case actionTypes.PROJECT_EDITOR_ADD_SUB_IMAGE_MOCKUP:
      return mockupReducer.addSubImageMockup(state);

    // Image
    // Modal
    case actionTypes.PROJECT_EDITOR_OPEN_IMAGE_MOCKUP_MODAL:
      return mockupImageReducer.openMockupImageModal(state, action.payload);
    case actionTypes.PROJECT_EDITOR_CLOSE_IMAGE_MOCKUP_MODAL:
      return mockupImageReducer.closeMockupImageModal(state);
    case actionTypes.PROJECT_EDITOR_CLEAR_IMAGES_MOCKUP:
      return mockupImageReducer.clearImages(state);
    // Fetch Mockups
    case actionTypes.PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_BEGIN:
      return mockupImageReducer.fetchImagesBegin(state);
    case actionTypes.PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_SUCCESS:
      return mockupImageReducer.fetchImagesSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_FAIL:
      return mockupImageReducer.fetchImagesFail(state, action.payload);
    case actionTypes.PROJECT_EDITOR_SELECT_IMAGE_MOCKUP:
      return mockupImageReducer.selectMockupImage(state, action.payload);
    // Fetch Artworks
    case actionTypes.PROJECT_EDITOR_FETCH_ARTWORKS_BEGIN:
      return mockupImageReducer.fetchImagesBegin(state);
    case actionTypes.PROJECT_EDITOR_FETCH_ARTWORKS_SUCCESS:
      return mockupImageReducer.fetchImagesSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_FETCH_ARTWORKS_FAIL:
      return mockupImageReducer.fetchImagesFail(state, action.payload);

    ////// Project
    // Upload Cover Image
    case actionTypes.PROJECT_EDITOR_UPLOAD_COVER_IMAGE_BEGIN:
      return projectReducer.fetchBegin(state);
    case actionTypes.PROJECT_EDITOR_UPLOAD_COVER_IMAGE_SUCCESS:
      return projectReducer.uploadCoverImageSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_UPLOAD_COVER_IMAGE_FAIL:
      return projectReducer.fetchFail(state, action.payload);
    // Create Project
    case actionTypes.PROJECT_EDITOR_CREATE_BEGIN:
      return projectReducer.fetchBegin(state);
    case actionTypes.PROJECT_EDITOR_CREATE_SUCCESS:
      return state;
    case actionTypes.PROJECT_EDITOR_CREATE_FAIL:
      return projectReducer.fetchFail(state, action.payload);
    // Fetch Project
    case actionTypes.PROJECT_EDITOR_FETCH_BEGIN:
      return projectReducer.fetchBegin(state);
    case actionTypes.PROJECT_EDITOR_FETCH_SUCCESS:
      return projectReducer.fetchProjectSuccess(state, action.payload);
    case actionTypes.PROJECT_EDITOR_FETCH_FAIL:
      return projectReducer.fetchFail(state, action.payload);
    // Clear Project
    case actionTypes.PROJECT_EDITOR_CLEAR:
      return projectReducer.clearProject(state);

    ////// Toggle Full Width (Image, Embed, Mockup)
    case actionTypes.PROJECT_EDITOR_TOGGLE_FULL_WIDTH:
      return toggleFullWidth(state, action.payload);

    ////// Default
    default:
      return state;
  }
};

export default reducer;
