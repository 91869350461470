import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { STATUS_TRANSACTION_NAME, STATUS_TRANSACTION } from "../based/Constants";
import Loading from "../based/Loading";
import Textbox from "../based/inputs/Textbox";
import { Layout } from "../layout/Layout";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TFUSelect from "../based/inputs/TFUSelect";
import FinanceServices from "../based/services/FinanceServices";
import NumberFormat from "react-currency-format";
import FinanceTranscipts from "./FinanceTranscipts";
var moment = require("moment");

const ORDER_STATUS = [
    {
        value: STATUS_TRANSACTION.WAITING_CONFIRM,
        label: STATUS_TRANSACTION_NAME.WAITING_CONFIRM,
    },
    {
        value: STATUS_TRANSACTION.PROCESSING,
        label: STATUS_TRANSACTION_NAME.PROCESSING,
    },
    {
        value: STATUS_TRANSACTION.PRODUCTION,
        label: STATUS_TRANSACTION_NAME.PRODUCTION,
    },
    {
        value: STATUS_TRANSACTION.PACKING,
        label: STATUS_TRANSACTION_NAME.PACKING,
    },
    {
        value: STATUS_TRANSACTION.READYTOSHIP,
        label: STATUS_TRANSACTION_NAME.READYTOSHIP,
    },
    {
        value: STATUS_TRANSACTION.SHIPPING,
        label: STATUS_TRANSACTION_NAME.SHIPPING,
    },
    {
        value: STATUS_TRANSACTION.SHIPPED,
        label: STATUS_TRANSACTION_NAME.SHIPPED,
    },
    {
        value: STATUS_TRANSACTION.ERROR,
        label: STATUS_TRANSACTION_NAME.ERROR,
    },
    {
        value: STATUS_TRANSACTION.RETURNED,
        label: STATUS_TRANSACTION_NAME.RETURNED,
    },
    {
        value: STATUS_TRANSACTION.CANCELED,
        label: STATUS_TRANSACTION_NAME.CANCELED,
    },
    {
        value: STATUS_TRANSACTION.SUCCEED,
        label: STATUS_TRANSACTION_NAME.SUCCEED,
    },
];

export default function FinanceMomo(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [report, setReport] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        document.getElementById("orderNumber").focus();
        getDatas(paging);
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await FinanceServices.FinanceMomoGetPage(newPaging);
        if (!error && result) {
            let pagingUp = result.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setPaging(pagingUp);
            let financeMomo = result.financeMomo;
            if (financeMomo) {
                setDatas(financeMomo.orders);
                setReport(financeMomo.report);
            } else {
                setDatas([]);
                setReport({});
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setReport({});
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <span>STT</span>
                    </th>
                    <th width='200' className='text-center'>
                        Mã đơn hàng
                    </th>
                    <th>Tên khách hàng</th>
                    <th width='150' className='text-center'>
                        Số điện thoại
                    </th>
                    <th width='200' className='text-center'>
                        Buyer thanh toán (đ)
                    </th>
                    <th width='200' className='text-center'>
                        Tiền Momo Charge (đ)
                    </th>
                    <th width='150' className='text-center'>
                        Mã giao dịch Momo
                    </th>
                    <th width='150' className='text-center'>
                        Trạng thái
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((order, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center vertical-align-middle'>
                                    {no}
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <a target='_blank' href={`/order-detail/${order.id}`}>
                                        <span className='m-r-5'>{order.orderNumber}</span>
                                        <i className='mdi mdi-open-in-new'></i>
                                    </a>
                                    <br></br>
                                    <small>{order.createdDateDisplay}</small>
                                </td>
                                <td className='vertical-align-middle'>
                                    <strong>{order.customerName}</strong>
                                    {order.shippingAddressName && order.shippingAddressName.length > 0 && (
                                        <React.Fragment>
                                            <br></br>
                                            <small className='font-italic'>
                                                <i className='fa fa-map-marker m-r-5'></i>
                                                {order.shippingAddressName}
                                            </small>
                                        </React.Fragment>
                                    )}
                                </td>
                                <td className='text-center vertical-align-middle'> {order.phoneNumber}</td>
                                <td className='text-center vertical-align-middle'>
                                    <strong>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={order.finallyPrice} />
                                    </strong>
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <strong>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={order.transferCharge} />
                                    </strong>
                                </td>
                                <td className='text-center vertical-align-middle'>{order.transferId}</td>
                                <td className='vertical-align-middle text-center'>
                                    <span className={" rounded text-white status " + order.statusLabelColor}>{order.statusName}</span>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            <span>Không có đơn hàng</span>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-3'>
                            <label htmlFor='orderNumber'>Mã đơn hàng:</label>
                            <Textbox className='form-control' id='orderNumber' name='orderNumber' onChanged={(name, value) => setPaging({ ...paging, orderNumber: value })} value={paging.orderNumber} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='transferId'>Mã giao dịch Momo:</label>
                            <Textbox className='form-control' name='transferId' onChanged={(name, value) => setPaging({ ...paging, transferId: value })} value={paging.transferId} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='orderStatus'>Trạng thái đơn hàng: </label>
                            <TFUSelect name='orderStatus' value={paging.orderStatus} options={ORDER_STATUS} placeholder='--Tất cả---' onChanged={(value) => setPaging({ ...paging, orderStatus: value != null ? value : 0 })} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='time'>Thời gian giao dịch:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => getDatas({ ...paging, pageNumber: 1 })}>
                                <i className='fa fa-search m-r-5' /> Tìm kiếm
                            </button>
                            <button className='btn btn-custom btn-sm m-r-5' onClick={() => setShowModal(true)}>
                                <i className='fa fa-file-text-o m-r-5' /> Đối soát theo Transcripts
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function _renderReport() {
        if (report) {
            return (
                <div className='form-row'>
                    <div className='col-xl-3 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng số đơn hàng</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalOrders ? report.totalOrders : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng tiền khách hàng thanh toán</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span className='m-r-10'>đ</span>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalFinalPrice ? report.totalFinalPrice : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng tiền MoMo Charge</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span className='m-r-10'>đ</span>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalTransferCharge ? report.totalTransferCharge : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-sm-6'>
                        <div className='card-box widget-box-four overview'>
                            <div className='float-left'>
                                <h4 className='mt-0 font-15 font-weight-medium mb-1 text-overflow'>Tổng tiền MoMo thanh toán</h4>
                                <h3 className='mb-0 mt-2'>
                                    <span className='m-r-10'>đ</span>
                                    <span>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={report.totalTransferAmount ? report.totalTransferAmount : 0} />
                                    </span>
                                </h3>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Đối soát MoMo' />
            <Loading show={isLoading} msg={msg} />
            {showModal && <FinanceTranscipts isShowModal={showModal} onClose={() => setShowModal(false)} />}
            {_renderReport()}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} searchBasic={false} />
        </React.Fragment>
    );
}
