import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import ManyShareServices from "../based/services/ManyShareServices";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};



const ManyShareLink = (props) => {
    const tableRef = useRef(null);
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");


    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ManyShareServices.GetManyShareByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Tài khoản</th>
                    <th>Link</th>
                    <th>Ngày tạo</th>
                    <th>Số user ghé thăm</th>
                    <th>Lượt view</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }


    async function handleDelete(id) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                let [error, data] = await ManyShareServices.DeleteManyShare(id);
                if (!error && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getData(paging);
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
       
    }


  
    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{textAlign: "center" }}>{item.fullName}</td>
                                <td style={{textAlign: "center" }}>{'https://ranus.vn/share-product/' + item.id}</td>
                                <td style={{textAlign: "center" }}>{item.createdDate && Common.formatDate(new Date(item.createdDate))}</td>
                                <td style={{textAlign: "center" }}>{item.countUser}</td>
                                <td style={{textAlign: "center" }}>{item.countView}</td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                
            </tbody>
        );
    }

    function _renderAction() {}

    return <ManyShareLinkWapper>
         <CommonTable data={data} paging={paging} 
                renderAction={_renderAction} 
                searchBasic
                renderHeader={_renderHeader} 
                renderBody={_renderBody} 
                ref={tableRef} 
                onFilter={(paging) =>{getData(paging)}}
             />
       
    </ManyShareLinkWapper>
}

const ManyShareLinkWapper = styled.div`

    
    


    
`;


export default ManyShareLink;
