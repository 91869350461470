import React, { useState, useEffect } from 'react';
import CommonTable from '../../based/CommonTable';

const columns = [
  {
    displayName: 'Tên kho',
  },
  {
    displayName: 'Tên vị trí',
  },
  {
    displayName: 'Số lượng đã bán',
  },
  {
    displayName: 'Số lượng sản phẩm (Tốt)',
  },
  {
    displayName: 'Số lượng sản phẩm (Lỗi)',
  },
  {
    displayName: 'Số lượng sản phẩm (Bị hỏng)',
  },
  {
    displayName: 'Đang chờ nhập hàng',
  },
];

const initPaging = {
  pageNumber: 1,
  pageSize: 10,
  orderBy: 'id',
  orderDirection: 'asc',
};

export default function InventoryWarehouse(props) {
  const [datas, setDatas] = useState(props.datas);
  const [paging, setPaging] = useState(initPaging);

  useEffect(() => {
    if (props.datas) setDatas(props.datas);
  }, [props.datas]);

  function _handleSortColumn(column) {}
  function _renderAction() {}
  function _renderHeader() {
    return (
      <tr className="text-center">
        <th></th>
        {columns.map((column, index) => {
          let cls = 'cursor-pointer';
          let width = column.width ? column.width : 'auto';
          let icon = <i className="fa fa-sort-desc fa-sort-thead"></i>;
          if (column.name && column.name.toLowerCase() == paging.orderBy.toLowerCase()) {
            if (paging.orderDirection.toUpperCase() === 'DESC') icon = <i className="fa fa-sort-desc fa-sort-thead"></i>;
            else icon = <i className="fa fa-sort-asc fa-sort-thead"></i>;
          }

          return (
            <th className={cls} key={index} width={width} onClick={() => _handleSortColumn(column)}>
              {column.isSort ? icon : null}
              <span>{column.displayName}</span>
            </th>
          );
        })}
      </tr>
    );
  }
  function _renderBody() {
    let cls = 'vertical-align-middle text-center';
    return (
      <React.Fragment>
        {datas && datas.length > 0 ? (
          datas.map((item, idx) => {
            return (
              <tr key={idx} className={cls}>
                <td>{idx + 1}</td>
                <td>{item.warehouseName}</td>
                <td>{item.slotCode}</td>
                <td>{item.soldQuantity}</td>
                <td>{item.goodQuantity}</td>
                <td>{item.defectiveQuantity}</td>
                <td>{item.brokenQuantity}</td>
                <td>{item.pendingQuantity}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={9} className="text-center">
              Không có dữ liệu
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {_renderHeader()}
      {_renderBody()}
    </React.Fragment>
  );
}
