import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CusCollectionTypeServices from "../based/services/CusCollectionTypeServices";
import styled from "styled-components";

const CollectionTypeModel = {
    id: 0,
    name: "",
    keyCode: ""
};
function CustomizeCollectionType(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [data, setDatas] = useState([CollectionTypeModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });


    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await CusCollectionTypeServices.GetCUSCollectionTypeByPage(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
        }
        setIsBusy(false);
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Chủ đề</th>
                    <th>Code</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }



  

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 150,  textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                            <input
                                                className="input-in-table"
                                                type="text"
                                                placeholder="Tên chủ đề"
                                                value={item.name}
                                                onChange={(e) => {
                                                    let dt = [...data];
                                                    dt[idx].name = e.target.value;
                                                    setDatas(dt);
                                                }}
                                            />
                                        ) : (
                                            item.name
                                        )}
                                </td>
                                <td style={{ width: 200 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                        className="input-in-table"
                                        type="text"
                                        placeholder="Code"
                                        value={item.keyCode}
                                        onChange={(e) => {
                                            let dt = [...data];
                                            dt[idx].keyCode = e.target.value;
                                            setDatas(dt);
                                        }}
                                    />
                                    ) : (
                                        item.keyCode
                                    )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setDatas(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {}

    async function handleAddItem() {
        setDatas([{ 
            id: 0,
            name: "",
            keyCode: ''
         },...data]);
       
    }

    function IsDisable(idx) {
        if ( !data[idx].name || !data[idx].keyCode) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setIsBusy(true);
        let dataItem = { ...data[idx]};
        let [err, dt] = await CusCollectionTypeServices.CreateUpdateCUSCollectionType(dataItem);
        if (!err && dt ) {
            _getData(paging);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            setIsBusy(false);
        } else {
            setIsBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Trùng code");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await CusCollectionTypeServices.DeleteCUSCollectionType(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        _getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setDatas(newData);
        }
    }


    return (
        <Wapper>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Quản lý Kiểu họa tiết" />
            <CommonTable datas={data} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
           
        </Wapper>
    );
}
const Wapper = styled.div`
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }
`
export default CustomizeCollectionType;
