import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import Shopee from "./assets/Shopee.png";
import Lazada from "./assets/Lazada.png";
import PlatformServices from "../based/services/PlatformServices";
import { Notify } from "../based/Notify";
import { NOTIFY, PLATFORM, SEX } from "../based/Constants";
import TFUCategories from "../based/TFUCategories";
import ShopCatModal from "./ShopCatModal";
import EditShopCatModal from "./EditShopCatModal";
import Request from "../based/Request";
import PushingServices from "../based/services/PushingServices";
import { ConfirmDialog } from "../based/Dialog";
import TFUSelect from "../based/inputs/TFUSelect";

const PlatformCatStoreDTO = {
    id: 0, // default value, can be set dynamically
    shopId: "", // string value, can be set dynamically
    platform: 0, // Platform object, to be defined based on structure of Platform class in C#
    platformCategoryId: 0, // default value, can be set dynamically
    breadcrumb: "", // this property is for front-end use only, not mapped in database
};

export const ShopCatDTO = {
    id: 0, // Assuming 0 as a placeholder for `Id`
    name: "", // Empty string as placeholder for `Name`
    parentId: 0, // Assuming 0 as a placeholder for `ParentId`
    shopId: "", // Empty string as placeholder for `ShopId`
    platform: 0, // Assuming an empty object for `Platform`, adjust as needed
    isActive: false, // Defaulting to false for `IsActive`
    index: 0,
    thumbnail: "",
};

const SexOption = [
    { value: SEX.Man, label: "Nam" },
    { value: SEX.Woman, label: "Nữ" },
    { value: SEX.Other, label: "Khác" },
];

function GeneralInformation({ shopId, platform }) {
    return (
        <React.Fragment>
            <Wrapper>
                <section className="general-information">
                    <HeaderInfoSection shopId={shopId} platform={platform} />
                    <ShopImageSection shopId={shopId} platform={platform} />
                    <CategorySection shopId={shopId} platform={platform} />
                    <ShopCatSection shopId={shopId} platform={platform} />
                </section>
            </Wrapper>
        </React.Fragment>
    );
}

export default GeneralInformation;

const HeaderInfoSection = ({ shopId, platform }) => {
    const [shopInfo, setShopInfo] = useState({});

    useEffect(() => {
        getShopInfo(shopId, platform);
    }, []);

    const getShopInfo = async (shopId, platform) => {
        const [err, data] = await PlatformServices.GetStoreInfo({ shopId, platform });
        if (!err && data) {
            setShopInfo(data);
        } else {
            setShopInfo({});
        }
    };

    const renderPlatform = (platform) => {
        switch (platform) {
            case PLATFORM.Shopee:
                return <img src={Shopee} alt="Shopee" />;
            case PLATFORM.Lazada:
                return <img src={Lazada} alt="Lazada" />;
            case PLATFORM.Tiktok:
                return <div>Tiktok</div>;
            default:
                return <img src={Shopee} alt="Shopee" />;
        }
    };

    const saveFileUrl = async (url) => {
        const [err, data] = await PlatformServices.UpdateSizeBoardUrl({ shopId, platform, sizeBoardUrl: url });
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            getShopInfo(shopId, platform);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const removeFileUrl = async (model) => {
        const [err, data] = await PlatformServices.RemoveSizeBoardUrl(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
            getShopInfo(shopId, platform);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    };

    async function handleUploadFile(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            saveFileUrl(res.data.imageUrl);
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    }

    async function updateAllShop(shopId, platform) {
        const [err, data] = await PushingServices.UpdateAllJobPlatformQueue(shopId, platform);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Update thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Update thất bại");
        }
    }

    function DialogUpdateAll() {
        ConfirmDialog("Xác nhận cập nhật?", "Bạn chắc chắn muốn cập nhật tất cả shop?", () => {
            return new Promise((resolve, reject) => {
                updateAllShop(shopId, platform);
                resolve({ title: "Thành công", msg: "Cập nhật thành công." });
            });
        });
    }

    if (!shopInfo) return <div className="no-data">Không tồn tại shop</div>;
    return (
        <section className="header-info">
            {renderPlatform(platform)}
            <h6 className="shop-name">
                {shopInfo.shopName}
                <div className="icon">
                    <OpenLink />
                </div>
            </h6>
            <hr />
            <div className="sizeboard-section">
                <div className="list-action">
                    <button
                        className="btn btn-sm btn-custom m-2"
                        onClick={(e) => {
                            document.getElementById("sizeboard-id").click();
                        }}
                    >
                        Upload SizeBoard
                    </button>
                    <button
                        className="btn btn-sm btn-custom m-2"
                        onClick={(e) => {
                            removeFileUrl({ shopId, platform });
                        }}
                    >
                        Xóa
                    </button>
                    <input
                        id="sizeboard-id"
                        type="file"
                        onChange={(e) => {
                            handleUploadFile(e);
                        }}
                        className="d-none"
                    />
                </div>
                <div className="size-board-url">
                    <a href={shopInfo.sizeBoardUrl} target="_blank">
                        {shopInfo.sizeBoardUrl}
                    </a>
                </div>
            </div>
            <hr />
            <div className="button-section">
                <div className="list-action">
                    <button
                        className="btn btn-custom btn-sm m-1"
                        onClick={(e) => {
                            DialogUpdateAll();
                        }}
                    >
                        Update Giá/Tồn kho (Toàn Shop)
                    </button>
                </div>
            </div>
        </section>
    );
};

const ShopImageSection = ({ shopId, platform }) => {
    const [listImage, setListImage] = useState([]);

    useEffect(() => {
        getListPushImage({ shopId: shopId, platform: platform });
    }, [shopId, platform]);

    const getListPushImage = async (model) => {
        const [err, data] = await PlatformServices.GetListPushShop(model);
        if (!err && data) {
            setListImage(data);
        } else {
            setListImage([]);
        }
    };

    const removePushImage = async (id) => {
        const [err, data] = await PlatformServices.RemovePushShop(id);
        if (!err && data) {
            getListPushImage({ shopId: shopId, platform: platform });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    };

    const createImageShop = async (model) => {
        const [err, data] = await PlatformServices.CreateImageShop(model);
        if (!err && data) {
            getListPushImage({ shopId: shopId, platform: platform });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const updateImageShop = async (model) => {
        const [err, data] = await PlatformServices.UpdateImageShop(model);
        if (!err && data) {
            getListPushImage({ shopId: shopId, platform: platform });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const handleChangeImage = async (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            createImageShop({
                                shopId: shopId,
                                platform: platform,
                                url: res.data.imageUrl,
                                index: 0,
                            });
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                        }
                    })
                    .catch((err) => {
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Upload failed");
                    });
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <section className="shop-image">
            <div className="list-action">
                <button
                    className="btn btn-custom btn-sm"
                    onClick={(e) => {
                        document.getElementById("upload-image-id").click();
                    }}
                >
                    Upload ảnh
                </button>
                <input
                    id="upload-image-id"
                    type="file"
                    className="d-none"
                    onChange={(e) => {
                        handleChangeImage(e);
                    }}
                />
            </div>
            {listImage && listImage.length > 0 ? (
                <div className="list-item">
                    {listImage.map((item, index) => {
                        return (
                            <div className="item" key={index}>
                                <div className="product-avatar">
                                    <div className="avatar">
                                        <img src={item.url} alt="" />
                                    </div>
                                    <div className="index-input">
                                        <input
                                            type="number"
                                            placeholder="Nhập thứ tự hiển thị"
                                            value={item.index}
                                            onChange={(e) => {
                                                let lm = [...listImage];
                                                lm[index].index = e.target.value;
                                                setListImage(lm);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="item-action">
                                    <button
                                        className="btn btn-danger btn-sm m-1"
                                        onClick={(e) => {
                                            removePushImage(item.id);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                    <button
                                        className="btn btn-custom btn-sm m-1"
                                        onClick={(e) => {
                                            updateImageShop(item);
                                        }}
                                    >
                                        Cập nhập
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="no-data">
                    <p>Chưa có ảnh nào</p>
                </div>
            )}
        </section>
    );
};

const CategorySection = ({ shopId, platform }) => {
    const [listModel, setListModel] = useState([PlatformCatStoreDTO]);
    const [showCat, setShowCat] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    useEffect(() => {
        getData({ shopId, platform });
    }, [shopId, platform]);

    //lấy danh sách ngành hàng
    const getData = async (paging) => {
        const [err, data] = await PlatformServices.GetPlatformCatStore(paging);
        if (!err && data) {
            setListModel(data);
        } else {
            setListModel([]);
        }
    };

    const updateSexCatStore = async (id, sex) => {
        const [err, data] = await PlatformServices.UpdateSexCatStore(id, sex);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Cập nhật thành công");
            getData({ shopId, platform });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Cập nhật thất bại");
        }
    };

    const createUpdatePlatformCatStore = async (model) => {
        const [err, data] = await PlatformServices.CreateUpdatePlatformCatStore(model);
        if (!err && data) {
            getData({ shopId, platform });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    };

    const removeData = async (id) => {
        const [err, data] = await PlatformServices.RemovePlatformCatStore(id);
        if (!err && data) {
            getData({ shopId, platform });
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    };

    return (
        <React.Fragment>
            <section className="category">
                <div className="category-header">
                    <h6>Ngành hàng</h6>
                    <button
                        className="btn btn-custom"
                        onClick={(e) => {
                            setShowCat(true);
                            setSelectedId(0);
                        }}
                    >
                        Thêm ngành hàng +
                    </button>
                </div>
                <div className="category-body">
                    <div className="list-bread-crumb">
                        {listModel && listModel.length > 0 ? (
                            listModel.map((item, index) => {
                                return (
                                    <div className="breadcrumb-cat" key={index}>
                                        <div className="breadcrumb-left">{item.breadcrumb}</div>
                                        <div className="breadcrumb-right">
                                            <div className="lst-action">
                                                <TFUSelect
                                                    options={SexOption}
                                                    value={item.sex}
                                                    onChanged={(value) => {
                                                        updateSexCatStore(item.id, +value);
                                                    }}
                                                />

                                                <button
                                                    className="btn"
                                                    onClick={(e) => {
                                                        setShowCat(true);
                                                        setSelectedId(item.id);
                                                    }}
                                                >
                                                    <div className="icon">
                                                        <EditIcon />
                                                    </div>
                                                </button>
                                                <button
                                                    className="btn"
                                                    onClick={(e) => {
                                                        removeData(item.id);
                                                    }}
                                                >
                                                    <div className="icon">
                                                        <RemoveIcon />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-dataa">
                                <p>Chưa có ngành hàng nào</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <TFUCategories
                title="Config ngành hàng"
                isShowModal={showCat}
                onClose={() => {
                    setShowCat(false);
                }}
                platform={platform}
                onSelected={(breadcrumb, platformCatId) => {
                    let md = { ...PlatformCatStoreDTO };
                    md.id = selectedId;
                    md.shopId = shopId;
                    md.platform = platform;
                    md.platformCategoryId = platformCatId.id;
                    createUpdatePlatformCatStore(md);
                }}
            />
        </React.Fragment>
    );
};

const ShopCatSection = ({ shopId, platform }) => {
    const [listModel, setListModel] = useState([ShopCatDTO]);
    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState(0);
    const [currentParentId, setCurrentParentId] = useState(0);
    const [index, setIndex] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);

    useLayoutEffect(() => {
        setListModel([]);
    }, []);

    useEffect(() => {
        getShopCats({ shopId, platform });
    }, [shopId, platform]);

    const getShopCats = async (model) => {
        const [err, data] = await PlatformServices.GetShopCats(model);
        if (!err && data) {
            if (listModel && listModel.length > 0) {
                for (var iterator of data) {
                    let item = listModel.find((x) => x.id == iterator.id);
                    if (item && item.isExpand) {
                        iterator.isExpand = true;
                    }
                }
            }
            setListModel(data);
        } else {
            setListModel([]);
        }
    };

    const syncShopCat = async (model) => {
        const [err, data] = await PlatformServices.SyncShopCat(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Đồng bộ thành công");
            getShopCats({ shopId, platform });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đồng bộ thất bại");
        }
    };

    const handleEdit = async () => {
        let checkedItem = [];
        //get checkedItem
        let ld = [...listModel];
        for (var model of ld) {
            if (model.isChecked) {
                checkedItem.push(model);
            }

            let child = model.children;
            if (child != null || child.length > 0) {
                for (var x of child) {
                    if (x.isChecked) {
                        checkedItem.push(x);
                    }
                }
            }
        }

        //nếu length == 1 thì ok, còn không thì thông báo
        if (checkedItem.length != 1) {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Vui lòng chọn 1 danh mục để chỉnh sửa");
            return;
        }

        setCurrentId(checkedItem[0].id);
        setCurrentParentId(checkedItem[0].parentId);
        setShowEditModal(true);
    };

    const handleRemove = async () => {
        let checkedItem = [];
        //get checkedItem
        let ld = [...listModel];
        for (var model of ld) {
            if (model.isChecked) {
                checkedItem.push(model);
            }

            let child = model.children;
            if (child != null || child.length > 0) {
                for (var x of child) {
                    if (x.isChecked) {
                        checkedItem.push(x);
                    }
                }
            }
        }

        //nếu length == 1 thì ok, còn không thì thông báo
        if (checkedItem.length != 1) {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Vui lòng chọn 1 danh mục để chỉnh sửa");
            return;
        }
        const [err, data] = await PlatformServices.RemoveShopCat(checkedItem[0].id, shopId, platform);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
            getShopCats({ shopId, platform });
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa thất bại");
        }
    };

    const handleToogle = async (id, isActive) => {
        const [err, data] = await PlatformServices.ToggleShopCat(id, isActive, shopId, platform);
        if (!err && data) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <section className="shop-cat">
                <div className="shop-cat-header">
                    <h6>Danh mục</h6>
                    <div className="list-cat-btn">
                        <button
                            className="btn btn-custom"
                            onClick={(e) => {
                                handleEdit();
                            }}
                        >
                            Chỉnh sửa
                        </button>
                        <button
                            className="btn btn-custom"
                            onClick={(e) => {
                                syncShopCat({ shopId, platform });
                            }}
                        >
                            Đồng bộ từ sàn
                        </button>

                        {/* <button className="btn btn-custom">Di chuyển</button> */}
                        {/* <button className="btn btn-custom">Refresh</button> */}
                        {/* <button className="btn btn-custom">Clone</button> */}
                        <button
                            className="btn btn-danger"
                            onClick={(e) => {
                                handleRemove();
                            }}
                        >
                            Xóa
                        </button>
                    </div>
                </div>
                <div className="shop-cat-body">
                    <div className="list-cat">
                        {listModel && listModel.length > 0 ? (
                            listModel.map((item, idx) => {
                                return (
                                    <React.Fragment>
                                        <div className="cat parent" key={idx}>
                                            <div className="check-box">
                                                <input
                                                    type="checkbox"
                                                    checked={item.isChecked}
                                                    onChange={(e) => {
                                                        let lm = [...listModel];
                                                        lm[idx].isChecked = e.target.checked;
                                                        setListModel(lm);
                                                    }}
                                                />
                                                <div
                                                    className="height-line"
                                                    onMouseEnter={() => {
                                                        let lm = [...listModel];
                                                        lm.map((item) => {
                                                            item.isHover = false;
                                                        });
                                                        lm[idx].isHover = true;
                                                        setListModel(lm);
                                                    }}
                                                    onMouseLeave={() => {
                                                        let lm = [...listModel];
                                                        lm.map((item) => {
                                                            item.isHover = false;
                                                        });

                                                        setListModel(lm);
                                                    }}
                                                />
                                            </div>
                                            <div className="cat-detail">
                                                {(!item.children || item.children.length == 0) && (
                                                    <div
                                                        className="height-line"
                                                        onMouseEnter={() => {
                                                            let lm = [...listModel];
                                                            lm.map((item) => {
                                                                item.isHover = false;
                                                                item.isChildHover = false;
                                                            });
                                                            lm[idx].isChildHover = true;
                                                            setListModel(lm);
                                                        }}
                                                        onMouseLeave={() => {
                                                            let lm = [...listModel];
                                                            lm.map((item) => {
                                                                item.isHover = false;
                                                                item.isChildHover = false;
                                                            });

                                                            setListModel(lm);
                                                        }}
                                                    />
                                                )}

                                                <div className="cat-name">
                                                    <div
                                                        className="expand-icon cursor-pointer"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let lm = [...listModel];
                                                            lm[idx].isExpand = !lm[idx].isExpand;
                                                            setListModel(lm);
                                                        }}
                                                    >
                                                        {item.isExpand ? <DownExpandIcon /> : <RightExpandIcon />}
                                                    </div>
                                                    {item.name} ({item.count})
                                                </div>
                                                <div className="cat-btn">
                                                    <div
                                                        className="jsless-switchery"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleToogle(item.id, !item.isActive).then((res) => {
                                                                let lm = [...listModel];
                                                                lm[idx].isActive = !lm[idx].isActive;
                                                                setListModel(lm);
                                                            });
                                                        }}
                                                    >
                                                        <input type="checkbox" name="switch1" id="switch1" checked={item.isActive} />
                                                        <label for="switch1"></label>
                                                    </div>
                                                </div>

                                                {item.isChildHover && (
                                                    <div
                                                        className="open-icon-hover"
                                                        onMouseEnter={() => {
                                                            let lm = [...listModel];
                                                            lm.map((item) => {
                                                                item.isHover = false;
                                                                item.isChildHover = false;
                                                            });
                                                            lm[idx].isChildHover = true;
                                                            setListModel(lm);
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setCurrentId(0);
                                                            setCurrentParentId(item.id);
                                                            setIndex(0);
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        <div className="open-icon">
                                                            <OpenCatModalIcon />
                                                        </div>
                                                        <div className="separate-line"></div>
                                                    </div>
                                                )}
                                            </div>
                                            {item.isHover && (
                                                <div
                                                    className="open-icon-hover"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setCurrentId(0);
                                                        setCurrentParentId(0);
                                                        setIndex(item.index);
                                                        setShowModal(true);
                                                    }}
                                                    onMouseEnter={() => {
                                                        let lm = [...listModel];
                                                        lm[idx].isHover = true;
                                                        setListModel(lm);
                                                    }}
                                                >
                                                    <div className="open-icon">
                                                        <OpenCatModalIcon />
                                                    </div>
                                                    <div className="separate-line"></div>
                                                </div>
                                            )}
                                        </div>

                                        {item.isExpand &&
                                            item.children &&
                                            item.children.length > 0 &&
                                            item.children.map((child, index) => {
                                                return (
                                                    <div className="cat child" key={index}>
                                                        <div className="check-box">
                                                            <input
                                                                type="checkbox"
                                                                checked={child.isChecked}
                                                                onChange={(e) => {
                                                                    let lm = [...listModel];
                                                                    lm[idx].children[index].isChecked = e.target.checked;
                                                                    setListModel(lm);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="cat-detail">
                                                            <div
                                                                className="height-line"
                                                                onMouseEnter={() => {
                                                                    let lm = [...listModel];
                                                                    lm.map((x) => {
                                                                        x.isHover = false;
                                                                        x.isChildHover = false;
                                                                    });
                                                                    let child = lm[idx].children;
                                                                    child.map((x) => {
                                                                        x.isHover = false;
                                                                    });
                                                                    child[index].isHover = true;
                                                                    setListModel(lm);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    let lm = [...listModel];
                                                                    lm.map((x) => {
                                                                        x.isHover = false;
                                                                        x.isChildHover = false;
                                                                    });
                                                                    let child = lm[idx].children;
                                                                    child.map((x) => {
                                                                        x.isHover = false;
                                                                    });
                                                                    setListModel(lm);
                                                                }}
                                                            />
                                                            <div className="cat-name">{child.name}</div>
                                                            <div className="cat-btn">
                                                                <div className="jsless-switchery">
                                                                    <input type="checkbox" name="switch3" id="switch3" />
                                                                    <label for="switch3"></label>
                                                                </div>
                                                            </div>

                                                            {child.isHover && (
                                                                <div
                                                                    className="open-icon-hover"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        setCurrentId(0);
                                                                        setCurrentParentId(child.parentId);
                                                                        setIndex(child.index);
                                                                        setShowModal(true);
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        let lm = [...listModel];
                                                                        lm.map((x) => {
                                                                            x.isHover = false;
                                                                            x.isChildHover = false;
                                                                        });
                                                                        let child = lm[idx].children;
                                                                        child.map((x) => {
                                                                            x.isHover = false;
                                                                        });
                                                                        child[index].isHover = true;
                                                                        setListModel(lm);
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        let lm = [...listModel];
                                                                        lm.map((x) => {
                                                                            x.isHover = false;
                                                                            x.isChildHover = false;
                                                                        });
                                                                        let child = lm[idx].children;
                                                                        child.map((x) => {
                                                                            x.isHover = false;
                                                                        });
                                                                        setListModel(lm);
                                                                    }}
                                                                >
                                                                    <div className="open-icon">
                                                                        <OpenCatModalIcon />
                                                                    </div>
                                                                    <div className="separate-line"></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <div className="no-data">
                                <a
                                    href=""
                                    className="text-custom"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setCurrentId(0);
                                        setCurrentParentId(0);
                                        setShowModal(true);
                                    }}
                                >
                                    Thêm danh mục cha
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <ShopCatModal
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                id={currentId}
                parentId={currentParentId}
                shopId={shopId}
                platform={platform}
                onSave={() => {
                    getShopCats({ shopId, platform });
                    setShowModal(false);
                }}
                index={index}
            />
            <EditShopCatModal
                show={showEditModal}
                onClose={() => {
                    setShowEditModal(false);
                }}
                onSave={() => {
                    getShopCats({ shopId, platform });
                    setShowEditModal(false);
                }}
                shopCatId={currentId}
                shopId={shopId}
                platform={platform}
            />
        </React.Fragment>
    );
};

// icon
const OpenLink = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3333 9.88885V15.2222C14.3333 15.6937 14.146 16.1459 13.8126 16.4793C13.4792 16.8127 13.0271 17 12.5556 17H2.77778C2.30628 17 1.8541 16.8127 1.5207 16.4793C1.1873 16.1459 1 15.6937 1 15.2222V5.4444C1 4.97291 1.1873 4.52072 1.5207 4.18732C1.8541 3.85393 2.30628 3.66663 2.77778 3.66663H8.11111"
                stroke="#64C5B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M11.6667 1H17.0001V6.33333" stroke="#64C5B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.22217 10.7778L16.9999 1" stroke="#64C5B1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const EditIcon = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18H19" stroke="#64C5B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M14.5 1.50023C14.8978 1.1024 15.4374 0.878906 16 0.878906C16.2786 0.878906 16.5544 0.933776 16.8118 1.04038C17.0692 1.14699 17.303 1.30324 17.5 1.50023C17.697 1.69721 17.8532 1.93106 17.9598 2.18843C18.0665 2.4458 18.1213 2.72165 18.1213 3.00023C18.1213 3.2788 18.0665 3.55465 17.9598 3.81202C17.8532 4.06939 17.697 4.30324 17.5 4.50023L5 17.0002L1 18.0002L2 14.0002L14.5 1.50023Z"
                stroke="#64C5B1"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const RemoveIcon = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5H3H19" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5"
                stroke="#D70000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M8 10V16" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 10V16" stroke="#D70000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const OpenCatModalIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#64C5B1" />
            <path d="M9 6V12" stroke="white" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 9L6 9" stroke="white" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const RightExpandIcon = () => {
    return (
        <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 8.25L4.25 4.75L0.75 1.25" stroke="black" />
        </svg>
    );
};

const DownExpandIcon = () => {
    return (
        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L4.5 4.5L8 1" stroke="black" />
        </svg>
    );
};

//wrapper
const Wrapper = styled.div`
    section.shop-image {
        width: calc(50% - 10px);
        background: white;
        margin-left: 10px;
        padding: 10px 12px;
    }

    .avatar {
        width: 100px;
        height: 100px;
    }

    .avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .list-item {
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        margin: 10px;
        border: 1px solid #d9d9d9;
        padding: 10px;
    }

    .product-avatar {
        display: flex;
        align-items: center;
    }

    .index-input {
        width: 80px;
    }

    .index-input input[type="number"] {
        width: 100%;
    }

    .item-action {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .cat-name {
        display: flex;
        align-items: center;
    }

    .expand-icon {
        display: flex;
        margin-right: 8px;
    }

    .cat-detail {
        position: relative;
    }
    .cat.parent {
        position: relative;
    }

    .cat.parent .open-icon-hover {
        position: absolute;
        display: flex;
        width: calc(100% + 9px);
        justify-content: center;
        align-items: center;
        bottom: -9px;
        left: -9px;
        z-index: 1;
        .open-icon {
            display: flex;
        }

        .separate-line {
            height: 1.5px;
            background: #64c5b1;
            width: 100%;
        }
    }

    .cat.child .cat-detail {
        position: relative;
    }

    .cat.child .open-icon-hover {
        position: absolute;
        display: flex;
        width: calc(100% + 9px);
        justify-content: center;
        align-items: center;
        bottom: -9px;
        left: -9px;
        z-index: 1;
        .open-icon {
            display: flex;
        }

        .separate-line {
            height: 1.5px;
            background: #64c5b1;
            width: 100%;
        }
    }

    /* Switchery */
    .jsless-switchery {
        input {
            display: none;
        }
        label {
            background-color: #fff;
            border: 1px solid #dfdfdf;
            border-radius: 20px;
            cursor: pointer;
            display: inline-block;
            height: 30px;
            position: relative;
            vertical-align: middle;
            width: 50px;
            user-select: none;
            box-sizing: content-box;
            background-clip: content-box;
            border-color: rgb(223, 223, 223);
            box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
            transition: border 0.4s, box-shadow 0.4s;
            background-color: rgb(255, 255, 255);
            margin: 0;
            &:after {
                content: "";
                background: #fff;
                border-radius: 100%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
                height: 30px;
                position: absolute;
                top: 0;
                width: 30px;
                left: 0px;
                transition: background-color 0.4s, left 0.2s;
            }
        }
        input:checked {
            ~ label {
                border-color: rgb(100, 189, 99);
                box-shadow: rgb(100, 189, 99) 0px 0px 0px 16px inset;
                transition: border 0.4s, box-shadow 0.4s, background-color 1.2s;
                background-color: rgb(100, 189, 99);
                &:after {
                    left: 20px;
                    transition: background-color 0.4s, left 0.2s;
                    background-color: rgb(255, 255, 255);
                }
            }
        }
    }

    //positional
    .jsless-switchery {
    }

    /* Danh mục */
    section.shop-cat {
        padding: 16px;
    }

    .shop-cat-header {
        display: flex;
        align-items: center;
    }

    .list-cat-btn {
        margin-left: auto;
    }

    .list-cat-btn {
    }

    .list-cat-btn .btn {
        margin: 0 6px;
    }

    .cat {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .cat.child .cat-detail {
        padding-left: 40px;
    }

    .cat-detail {
        flex: 1;
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 10px 16px;
        height: 100%;
    }

    .cat-btn {
        margin-left: auto;
    }

    .check-box {
        padding: 10px 16px;
        display: flex;
        height: 100%;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .shop-cat-body {
        margin-top: 16px;
    }

    .cat-name {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    /* Ngành hàng */
    section.header-info {
        background: white;
        padding: 13px 17px;
        width: 50%;
    }

    h6.shop-name {
        display: flex;
        align-items: center;
        color: #64c5b1;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .icon {
        display: flex;
    }

    h6.shop-name .icon {
        margin-left: 6px;
    }

    section.general-information {
        display: flex;
        flex-wrap: wrap;
    }

    section.category {
        width: 50%;
        background: white;
        padding: 16px;
        margin-top: 13px;
    }

    section.shop-cat {
        width: calc(50% - 13px);
        background: white;
        margin-left: 13px;
        margin-top: 13px;
    }

    .category-header {
        display: flex;
        align-items: center;
    }

    .category-header button.btn.btn-custom {
        margin-left: auto;
    }

    .breadcrumb-cat {
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 9px 12px;
        margin: 10px 0;
    }

    .breadcrumb-right {
        margin-left: auto;
    }

    .breadcrumb-left {
        width: 80%;
    }

    .lst-action {
    }

    .lst-action button.btn {
        background: none;
        margin: 0 6px;
    }

    .list-bread-crumb {
        margin-top: 24px;
    }

    .check-box {
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
    }

    .height-line {
        height: 50px;
        width: 10px;
        background: #d9d9d9;
        position: absolute;
        left: -5px;
        top: 0;
    }
`;
