import React, { useState, useEffect } from 'react'
import CommonModal from '../based/Modal'
import CategoryServices from '../based/services/CategoryServices';
import PrintServices from '../based/services/PrintServices';
import useFormValidate from '../hooks/useFormValidate';
import { PrintStepConfigModel } from '../models/PrintStepConfigModel';
import TextNumber from '../based/inputs/TextNumber';
import Textbox from '../based/inputs/Textbox';
import { ImageModel } from '../models/BaseProductModel';

export default function PrintStepModal(props) {
    const { addRef, displayError, isValid } = useFormValidate();
    const [printStepConfig, setPrintStepConfig] = useState({})
    const [category, setCategory] = useState({})

    useEffect(() => {
        let id = props.categoryId
        if (id > 0) {
            getConfig(id)
            getCategoryById(id)
        }
    }, [props.categoryId])

    const handleInputChange = (name, value) => {
        let config = { ...printStepConfig }
        config[name] = value
        setPrintStepConfig(config)
    }

    const handleSelect = (newValue) => {
        let config = { ...printStepConfig }
        config.categoryId = newValue
        setPrintStepConfig(config)
    }

    const getConfig = async (id) => {
        let [err, data] = await PrintServices.ReadFirstStepConfig(id)
        if (!err) setPrintStepConfig(data)
        else {
            let data = new PrintStepConfigModel()
            data.categoryId = id
            setPrintStepConfig(data)
        }
    }

    const getCategoryById = async (id) => {
        let [err, data] = await CategoryServices.GetCategoryById(id)
        if (!err) setCategory(data)
        else setCategory({})
    }

    const handleSave = async (model) => {
        let [err, data] = await PrintServices.CreateUpdateStepConfig(model)
        if (!err) props.onSaveSucceed()
        else props.onClose()
    }

    return <CommonModal
        size='md'
        show={props.isShowModal}
        title={category.id == 0 ? 'Thêm mới' : 'Cập nhật'}
        onSave={() => handleSave({ ...printStepConfig })}
        onClose={() => props.onClose()}
    >
        <div className="form-group">
            <label htmlFor="name">Ngành hàng:</label>
            <Textbox ref={addRef} id="name" name="name" className="form-control" value={category.name} readOnly />
        </div>
        <div className="form-group">
            <label htmlFor="costLevel1">Chi phí in ấn Level 1:</label>
            <TextNumber ref={addRef} id="costLevel1" suffix="đồng" name="costLevel1" className="form-control" value={printStepConfig.costLevel1} onChanged={handleInputChange} />
        </div>
        <div className="form-group">
            <label htmlFor="costLevel2">Chi phí in ấn Level 2:</label>
            <TextNumber ref={addRef} id="costLevel2" suffix="đồng" name="costLevel2" className="form-control" value={printStepConfig.costLevel2} onChanged={handleInputChange} />
        </div>
        <div className="form-group">
            <label htmlFor="costLevel3">Chi phí in ấn Level 3:</label>
            <TextNumber ref={addRef} id="costLevel3" suffix="đồng" name="costLevel3" className="form-control" value={printStepConfig.costLevel3} onChanged={handleInputChange} />
        </div>
        <div className="form-group">
            <label htmlFor="costLevel4">Chi phí in ấn Level 4:</label>
            <TextNumber ref={addRef} id="costLevel4" suffix="đồng" name="costLevel4" className="form-control" value={printStepConfig.costLevel4} onChanged={handleInputChange} />
        </div>
        <div className="form-group">
            <label htmlFor="costLevel5">Chi phí in ấn Level 5:</label>
            <TextNumber ref={addRef} id="costLevel5" suffix="đồng" name="costLevel5" className="form-control" value={printStepConfig.costLevel5} onChanged={handleInputChange} />
        </div>
    </CommonModal>
}
