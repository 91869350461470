import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import MarketingServices from "../based/services/MarketingServices";
import CommonTable from "../based/CommonTable";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import MarketingDetailManagement from "./MarketingDetailManagement";
import Common from "../based/Common";


const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: ""
};


function MarketingCampaign({landingPageId, creatorId}) {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const history = useHistory()
    const [campaign , setCampaign] = useState({id: 0, name: ""})


    useEffect(() => {
        setBusy(false);
        getData();
        setCampaign({id: 0, name: ""})
    }, [landingPageId]);

    useEffect(() => {
        if(campaign.id == 0){
            getData();
        }
    }, [campaign]);

    async function getData() {
        setBusy(true);
        let [err, data] = await MarketingServices.GetCampaignByLPId(landingPageId);
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Tên chiến dịch</th>
                    <th>Ngày bắt đầu</th>
                    <th>Ngày kết thúc</th>
                    <th>Số lượt quay mỗi ngày</th>
                    <th>Màu chủ đạo</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }



  

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td style={{ width: 50, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ width: 150,  textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                            <input
                                                className="input-in-table"
                                                type="text"
                                                placeholder="Tên chiến dịch"
                                                value={item.name}
                                                onChange={(e) => {
                                                    let dt = [...data];
                                                    dt[idx].name = e.target.value;
                                                    setData(dt);
                                                }}
                                            />
                                        ) : (
                                            item.name
                                        )}
                                </td>
                                <td style={{ width: 200 }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="date"
                                            value={item.startDate}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].startDate = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        Common.formatDate(new Date(item.startDate))
                                    )}
                                </td>
                                <td style={{ width: 200 }}>
                                {item.id <= 0 || item.isUpdate ? (
                                        <input
                                            className="input-in-table"
                                            type="date"
                                            value={item.endDate}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].endDate = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        Common.formatDate(new Date(item.endDate))
                                    )}
                                </td>
                                <td style={{ width: 100,  textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                            <input
                                                className="input-in-table"
                                                type="number"
                                                value={item.spinCount}
                                                onChange={(e) => {
                                                    let dt = [...data];
                                                    dt[idx].spinCount = e.target.value;
                                                    setData(dt);
                                                }}
                                            />
                                        ) : (
                                            item.spinCount
                                        )}
                                </td>
                                <td style={{ width: 150,  textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                            <input
                                                className="input-in-table"
                                                type="text"
                                                placeholder="Màu chủ đạo (#000000)"
                                                value={item.colorCode}
                                                onChange={(e) => {
                                                    let dt = [...data];
                                                    dt[idx].colorCode = e.target.value;
                                                    setData(dt);
                                                }}
                                            />
                                        ) : (
                                            item.colorCode
                                        )}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    {item.id <= 0 || item.isUpdate ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom m-1"
                                            onClick={() => {
                                                setCampaign(item)
                                            }}
                                        >
                                            Chi tiết
                                        </button>
                                    )}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm chiến dịch +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {}

    async function handleAddItem() {
        setData([ ...data, { 
            id: 0,
            name: "",
            startDate: "",
            endDate: "",
            marketingLandingPageId: landingPageId,
            wheelImage: '',
            spinCount: 0,
            pin: 0,
            logo: "",
            poster: "",
            isDelete: false,
            colorCode: "",
            isUpdate: true
        }]);
       
    }

    function IsDisable(idx) {
        var today = new Date();
        var year = today.getFullYear();
        var mon = today.getMonth();
        var day = today.getDate();
        var onlyDate = new Date(year , mon , day)

        if ( !data[idx].name || (new Date(data[idx].startDate) < onlyDate) ||
        (new Date(data[idx].startDate) > new Date(data[idx].endDate)) ) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx]};
        let [err, dt] = await MarketingServices.CreateUpdateCampaign(dataItem);
        if (!err && dt != 0 ) {
            if(dt == 1){
                getData();
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            }
            if(dt == -7){
                Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Trùng ngày mới chiến dịch khác");
            }
            setBusy(false);
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thêm lỗi");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await MarketingServices.DeleteCampaign(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData();
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    return (
        <MarketingWapper>
            <Loading show={isBusy} msg={msg} />
            {
                campaign.id > 0 ? 
                <React.Fragment>
                    <div className="header-cam">
                        <h4><svg onClick={()=>{
                            setCampaign({id: 0, name:""})
                        }} width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 1L2 7L8 13" stroke="#666F7B" stroke-width="2"/>
                            </svg>
                
                        {campaign.name}</h4>

                        <button className="btn btn-danger" onClick={()=>{
                            setCampaign({id: 0, name:""})
                        }}>Quay lại</button>
                    </div>
                    <MarketingDetailManagement campaignId={campaign.id} creatorId={creatorId} />
                </React.Fragment>
                 : <CommonTable data={data} paging={paging} hideSearchBasicButton 
                        renderAction={_renderAction} 
                        renderHeader={_renderHeader} 
                        renderBody={_renderBody} 
                        ref={tableRef} 
                    />
            }
            
        </MarketingWapper>
    )
}


const MarketingWapper = styled.div`
    .header-cam{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        

        h4{
            display: flex;
            align-items: center;
            color: #666F7B;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            svg{
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    .container-config{
        display: flex;
        align-items: center;
        flex-wrap: wrap;



        .config-col-50{
            width: 50%;
            max-width: 50%;
            background-color: #fff;
            &.p-15{
                padding: 15px;
            }
            h5{
                color: #666F7B;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
            .box-button{
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: end;
            }

            &.flex-box{
                display: flex;
                align-items: center;
                margin-top: 10px;
                span{
                    display: block;
                    width: 80px;

                }
                input{
                    width: 77px;
                    height: 31px;
                    border: 1.5px solid #D9D9D9;
                    background: #FFF;
                    padding: 0 10px;
                }
            }


        }
    }


    
    .input-creator{
        width: 100%;
        position: relative;

        input{
            width: 100%;
            border: none;
            outline: none;
            height: 40px;
            padding: 0;
            ::placeholder{
                opacity: 0.5;
            }
        }
        span{
            display: block;
            width: 100%;
        }

        .result-search{
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 400px;
            overflow-y: auto;
            height: 200px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            padding: 5px;

           

            .result-item{
                width: 100%;
                display: flex;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                align-items: center;

                &.header{
                    font-weight: 600;
                    cursor: default;

                    :hover{
                        background-color: transparent;
                    }
                    
                }

                :hover{
                    background-color: rgba(0, 0, 0, 0.1);
                }

                img{
                    height: 40px;
                    width: 40px;
                    object-fit: contain;

                }
                
                span{
                    display: block;
                    width: 150px;
                    padding: 0 10px;
                }

            }
        }
    }

    .box-banner{
        display: flex;


        .box-banner-item{
            width: 50%;

        }
    }
`;
export default MarketingCampaign;
