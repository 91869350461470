import React, { useState , useEffect} from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import PositionAreaManagement from "./PositionAreaManagement";
import AreaManagement from "./AreaManagement";
import SortServices from "../based/services/SortServices";


const ShelfManagement = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState([])
    const [isBusy, setBusy] = useState(true);



    useEffect(() => {
        setBusy(false);
        getData();
    }, []);

    async function getData() {
        setBusy(true);
        let [err, data] = await SortServices.GetArea();
        if (!err && data) {
            setData(data);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Quản lý khay kệ"  />
            <ul className="nav nav-tabs tabs-bordered" style={{ alignSelf: "flex-start" }}>
                <li className="nav-item">
                    <a className={tabIndex == 0 ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(0)}>
                        <span>Số lượng Khu - Kệ - Tầng</span>
                    </a>
                </li>

                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <li key={idx} className="nav-item">
                                <a className={tabIndex == item.id ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(item.id)}>
                                    <span>Khu {item.nameArea}</span>
                                </a>
                            </li>
                )})): ""}
            </ul>
            {tabIndex == 0 && <AreaManagement />}
            {tabIndex > 0 && <PositionAreaManagement areaId={tabIndex} />}



        </React.Fragment>
    );
};

export default ShelfManagement;
