import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CustomizeServices from "../based/services/CustomizeServices";
import AddOrEditModal from "./AddOrEditModal";

const StockFontModel = {
    id: 0,
    fontFamily: "",
    fontSize: 0,
    fontWeight: 0,
    text: "",
    color: "",
    url: "",
};

function StockFont(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải...");
    const [datas, setDatas] = useState([StockFontModel]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await CustomizeServices.GetTextsPaging(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
        }
        setIsBusy(false);
    }

    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (value) setSelectId(newDatas[index].id);
        else setSelectId(0);
    };

    const _handleDelete = async (id) => {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await CustomizeServices.RemoveText(id);
                if (!error && data) {
                    _getData(paging);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    reject({ title: "Thất bại", msg: "Vui lòng thử lại sau." });
                }
                setMsg(null);
                setIsLoading(false);
            });
        });
    };

    const _handleRenderFontFaceCss = async () => {
        setIsLoading(true);
        let [err, data] = await await CustomizeServices.RenderFontFace();
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Tạo font face css thành công.");
        } else {
            Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình tạo font.");
        }
        setIsLoading(false);
    };

    const _handleClearFontFaceCss = async () => {
        setIsLoading(true);
        let [err, data] = await await CustomizeServices.ClearFontFace();
        Notify(NOTIFY.SUCCESS, "Thành công", "Clear font face css thành công.");
        setIsLoading(false);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center"></th>
                    <th className="center">Font Family</th>
                    <th className="center">Font Size</th>
                    <th className="center">Font Weight</th>
                    <th className="center">Text</th>
                    <th className="center">Color</th>
                    <th className="center">Trạng thái hoạt động</th>
                    <th className="center">Font Url</th>
                    <th className="center"></th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        setIsShowModal(true);
                        setSelectId(0);
                    }}
                >
                    Thêm mới
                </button>
                <button className={selectId > 0 ? "btn btn-danger ml-3" : "btn btn-danger ml-3 disable"} onClick={() => (selectId > 0 ? _handleDelete(selectId) : {})}>
                    Xóa
                </button>
                <button className="btn btn-custom mx-2" onClick={() => _handleRenderFontFaceCss()}>
                    Tạo Font Face CSS ranus.vn
                </button>
                <button className="btn btn-custom mx-2" onClick={() => _handleClearFontFaceCss()}>
                    Clear Font Face CSS ranus.vn
                </button>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    <CheckBox id={idx.toString()} name={idx.toString()} checked={item.id ? item.id == selectId : false} label=" " onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                                </td>
                                <td className="text-center">{item.fontFamily}</td>
                                <td className="text-center">{item.fontSize}</td>
                                <td className="text-center">{item.fontWeight}</td>
                                <td className="text-center">{item.text}</td>
                                <td className="text-center">
                                    <div style={{ background: item.color, width: "20px", height: "20px" }} />
                                </td>
                                <td className="text-center">
                                    <span className={`badge ${item.isActive ? "badge-success" : "badge-danger"}`}>{item.isActive ? "active" : "deactive"}</span>
                                </td>
                                <td>
                                    {item.textInfos &&
                                        item.textInfos.map((ele, i) => {
                                            return (
                                                <div>
                                                    <span>
                                                        <a href={ele.url}>{ele.url}</a> - {ele.fontStyleName} - {ele.fontWeight}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                </td>
                                <td>
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setSelectId(item.id);
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <Wrapper>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Quản lý Stock Photo" />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            {isShowModal && (
                <AddOrEditModal
                    isShowModal={isShowModal}
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        _getData({ ...paging });
                    }}
                    id={selectId}
                />
            )}
        </Wrapper>
    );
}

export default StockFont;

const Wrapper = styled.div`
    .modal-class-body {
        min-height: 50vh;
    }
`;
