import BaseServices from "./BaseServices";

const MarketingServices = {
    CreateUpdateCreatorMarketing: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-partner`, model);
    },
    DeleteCreatorMarketing: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-partner/${id}`);
    },
    GetCreatorMarketingByPage: async (model) => {
        return await BaseServices.Post(`/api/Marketing/get-partner-by-page`, model);
    },
    GetAllCreatorMarketing: async (model) => {
        return await BaseServices.Post(`/api/Marketing/get-all-creator-marketing`, model);
    },
    GetFindPartner: async (model) => {
        return await BaseServices.Post(`/api/Marketing/get-find-partner`, model);
    },
    CreateUpdateLandingPage: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-landing-page`, model);
    },
    DeleteLandingPage: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-landing-page/${id}`);
    },
    GetLandingPageByPartnerId: async (id) => {
        return await BaseServices.Post(`/api/Marketing/get-landing-page-by-partner/${id}`);
    },
    GetLandingPageByPageById: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-langding-page-byId/${id}`);
    },

    CreateUpdateCampaign: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-campaign`, model);
    },
    DeleteCampaign: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-campaign/${id}`);
    },
    GetCampaignByLPId: async (landingPageId) => {
        return await BaseServices.Get(`/api/Marketing/get-partner-campaign/${landingPageId}`);
    },
    GetCampaignById: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-campaign-byId/${id}`);
    },


    CreateUpdatePartnerCollab: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-partner-collab`, model);
    },
    DeletePartnerCollab: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-partner-collab/${id}`);
    },
    GetPartnerCollabByCampaign: async (campaignId) => {
        return await BaseServices.Get(`/api/Marketing/get-partner-collab-by-campaign/${campaignId}`);
    },

    
    GetStatictisById: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-statistic-marketing-byId/${id}`);
    },
    



    CreateUpdateFolder: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-folder-marketing`, model);
    },
    DeleteFolder: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-folder-marketing/${id}`);
    },
    GetFolderMarketingByCampaign: async (campaignId) => {
        return await BaseServices.Get(`/api/Marketing/get-folder-marketing-by-campaignId/${campaignId}`);
    },





    CreateFolderConnectionMarketingProduct: async (model, folderId , folderConnectionType) => {
        return await BaseServices.Post(`/api/Marketing/create-update-product-marketing/${folderId}/${folderConnectionType}`, model);
    },


    GetProductMarketingByFolderId: async (folderId) => {
        return await BaseServices.Get(`/api/Marketing/get-product-marketing-by-folderId/${folderId}`);
    },
    DeleteMarketingProduct: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-product-in-folder/${id}`);
    },
    UpdateIndexProductMarketingInFolder: async (model) => {
        return await BaseServices.Post(`/api/Marketing/update-index-product-marketing`, model);
    },


    GetDesignByKeyword: async (model) => {
        return await BaseServices.Post(`/api/Marketing/get-design-by-keyword`, model);
    },

    GetProductCreatorByKeyword: async (model) => {
        return await BaseServices.Post(`/api/Marketing/get-product-creator-by-keyword`, model);
    },

    GetProductCreatorById: async (id,userId) => {
        return await BaseServices.Get(`/api/Marketing/get-product-creator-by-id/${id}/${userId}`);
    },
    GetProductDesignById: async (id,userId) => {
        return await BaseServices.Get(`/api/Marketing/get-product-design-by-id/${id}/${userId}`);
    },

    GetBannerByCampaign: async (campaignId, show) => {
        return await BaseServices.Get(`/api/Marketing/get-banner-by-campaign/${campaignId}/${show}`);
    },

    CreateUpdateBanner: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-banner`, model);
    },

    DeleteBanner: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-banner/${id}`);
    },


    
    GetGiftRanusByCampaignId: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-gift-ranus-by-campaignId/${id}`);
    },
    CreateUpdateGift: async (model) => {
        return await BaseServices.Post(`/api/Partner/create-update-gift`, model);
    },
    CreateUpdateSpecialDay: async (model) => {
        return await BaseServices.Post(`/api/Partner/create-update-special-day`, model);
    },
    CreateUpdateMarketingGiftConfig: async (model) => {
        return await BaseServices.Post(`/api/Partner/create-update-gift-config`, model);
    },
    CreateUpdateMarketingGiftSpecialDayConfig: async (model) => {
        return await BaseServices.Post(`/api/Partner/create-update-gift-special-day-config`, model);
    },
    CreateOrDeleteSpecialDayApply: async (model) => {
        return await BaseServices.Post(`/api/Partner/create-or-delete-special-day-apply`, model);
    },
    GetSpecialDayByGiftId: async (id) => {
        return await BaseServices.Get(`/api/Partner/get-special-day-by-giftId/${id}`);
    },
    GetSpecialDayConfigBySpecialDayId: async (id) => {
        return await BaseServices.Get(`/api/Partner/get-special-day-config-by-specialDayId/${id}`);
    },
    GetGiftConfigByGiftId: async (id) => {
        return await BaseServices.Get(`/api/Partner/get-gift-config-by-giftId/${id}`);
    },
    GetMonthAndSpecialDayByGiftId: async (month, year ,id) => {
        return await BaseServices.Get(`/api/Partner/get-full-month-by-giftId/${month}/${year}/${id}`);
    },
    


    DeleteGiftConfigById: async (id) => {
        return await BaseServices.Get(`/api/Partner/delete-gift-config-by-id/${id}`);
    },
    DeleteSpecialDayGifyConfigById: async (id) => {
        return await BaseServices.Get(`/api/Partner/delete-special-day-gift-config-by-id/${id}`);
    },
    DeleteSpecialDayById: async (id) => {
        return await BaseServices.Get(`/api/Partner/delete-special-day-by-id/${id}`);
    },
    DeleteGift: async (id) => {
        return await BaseServices.Get(`/api/Partner/delete-gift/${id}`);
    },


    CreateOrUpdateCampaign: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-campaign`, model);
    },
    GetCampaign: async (creatorId) => {
        return await BaseServices.Get(`/api/Marketing/get-partner-campaign/${creatorId}`);
    },
    ChangePIN: async (id,pin) => {
        return await BaseServices.Get(`/api/Marketing/change-pin/${id}/${pin}`);
    },

    GetGiftByCampaignId: async (id, type) => {
        return await BaseServices.Get(`/api/Marketing/get-gift-by-campaignId/${id}/${type}`);
    },
    GetUserWin: async (model) => {
        return await BaseServices.Post(`/api/Marketing/get-partner-user-win`, model);
    },
    GetGiftCollabByCampaignId: async (id, collabId) => {
        return await BaseServices.Get(`/api/Marketing/get-gift-collab-by-campaignId/${id}/${collabId}`);
    },


    //Vòng quay
    CreateUpdateSpinPrize: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-spin-prize`, model);
    },
    CreateUpdateSpinConfig: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-spin-config`, model);
    },
    CreatUpdateSpinConfigMore: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-spin-config-more`, model);
    },
    CreateUpdateSpinSpecialDay: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-spin-special-day`, model);
    },
    CreateSpinSpecialDayApply: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-spin-special-apply-day`, model);
    },
    DeleteSpinPrize: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-spin-prize/${id}`);
    },
    DeleteSpinConfig: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-spin-config/${id}`);
    },
    DeleteSpinSpecialDay: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-spin-specical-day/${id}`);
    },
    DeleteSpinSpecialDayApply: async (model) => {
        return await BaseServices.Post(`/api/Marketing/delete-spin-specical-apply-day`,model);
    },
    UpdateIndexSpin: async (model) => {
        return await BaseServices.Post(`/api/Marketing/update-index-spin`, model);
    },
    GetSpinPrizeByCampaignId: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-spin-prize-by-campaignId/${id}`);
    },
    GetSpinConfig: async (id, type) => {
        return await BaseServices.Get(`/api/Marketing/get-spin-config/${id}/${type}`);
    },
    GetSpecialDayBySpinPrizeId: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-spin-special-day-by-spinprize/${id}`);
    },
    GetMonthAndSpecialDayBySpinPrizeId: async (month, year, id) => {
        return await BaseServices.Get(`/api/Marketing/get-spin-month-apply-day/${month}/${year}/${id}`);
    },
    GetVoucherDropDown: async () => {
        return await BaseServices.Post(`/api/Marketing/get-voucher-dropdown`);
    },




    //config postion image
    CreateUpdateImage: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-image`, model);
    },
    CreateUpdateImageVariant: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-image-variant`, model);
    },
    CreateUpdateImagePosition: async (model) => {
        return await BaseServices.Post(`/api/Marketing/create-update-image-position`, model);
    },
    DeleteImage: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-image/${id}`);
    },
    DeleteImageVariant: async (id) => {
        return await BaseServices.Get(`/api/Marketing/delete-image-variant/${id}`);
    },
    GetImageVariantByMarketingImageId: async (id) => {
        return await BaseServices.Get(`/api/Marketing/get-image-variant-by-image-id/${id}`);
    },
    GetImageByType: async (type) => {
        return await BaseServices.Get(`/api/Marketing/get-image-by-type/${type}`);
    },
    GetImagePositionByType: async (type) => {
        return await BaseServices.Get(`/api/Marketing/get-image-position-by-type/${type}`);
    },
    DrawImage: async (model) => {
        return await BaseServices.Post(`/api/Marketing/draw-image`, model);
    },
   
};

export default MarketingServices;

