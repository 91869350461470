import React, { useEffect, useMemo, useState } from "react";
import { CARD_COLOR, CONSTANTS, NOTIFY } from "../based/Constants";
import { Box, BtnRounded } from "../based/content/ct.elm";
import LotusUpload from "../based/inputs/LotusUpload";
import Radio from "../based/inputs/Radio";
import TextArea from "../based/refactor/TextArea";
import Textbox from "../based/refactor/Textbox";
import { Notify } from "../based/Notify";
import BaseModal from "../based/refactor/BaseModal";
import ButtonTab from "../based/refactor/ButtonTab";
import BrandServices from "../based/services/BrandServices";
import useFormValidate from "../hooks/useFormValidate";

const TAB_SCREEN = {
    INFO: "INFO",
    DOCUMENTS: "DOCUMENTS",
    APPROVED: "APPROVED",
};

export default function BrandApproved(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [brand, setBrand] = useState({});
    const [dataSubmit, setDataSubmit] = useState({});
    const [tabIndex, setTabIndex] = useState();
    const { addRef, displayError, isValid } = useFormValidate();
    useEffect(() => {
        if (props.isShow) {
            getDatas(props.brand.id);
            setTabIndex(TAB_SCREEN.INFO);
        }
    }, [props.isShow]);

    async function getDatas(id) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await BrandServices.GetById(id);
        if (!error && data) {
            setBrand(data);
            setDataSubmit({ id: data.id, name: data.name, status: data.status > 0 ? data.status : 1 });
            setIsLoading(false);
            setMsg(null);
        } else {
            setBrand({});
            setIsLoading(false);
            setMsg(null);
        }
    }
    const TabHeader = useMemo(() => (
        <div className='p-b-10 w-100'>
            <ul className='nav nav-tabs tabs-bordered'>
                <li className='nav-item'>
                    <ButtonTab className={`${tabIndex == TAB_SCREEN.INFO ? "active" : ""} mx-1`} title='Thông tin thương hiệu' onClick={() => setTabIndex(TAB_SCREEN.INFO)} />
                </li>
                <li className='nav-item'>
                    <ButtonTab className={`${tabIndex == TAB_SCREEN.DOCUMENTS ? "active" : ""} mx-1`} title='Tài liệu thương hiệu' onClick={() => setTabIndex(TAB_SCREEN.DOCUMENTS)} />
                </li>
                <li className='nav-item'>
                    <ButtonTab className={`${tabIndex == TAB_SCREEN.APPROVED ? "active" : ""} mx-1`} title='Kiểm duyệt thương hiệu' onClick={() => setTabIndex(TAB_SCREEN.APPROVED)} />
                </li>
            </ul>
        </div>
    ));
    async function _handleSave() {
        if (dataSubmit.status == 2) {
            if (isValid()) {
                _save(2);
            }
        } else {
            _save(1);
        }
    }
    async function _save(status) {
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");
        let [error, data] = await BrandServices.ApprovedBrand(dataSubmit);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            props.onSaveSucceed();
            Notify(NOTIFY.SUCCESS, "Thành công", data ? data : "Kiểm duyệt thành công");
        } else {
            setIsLoading(false);
            setMsg(null);
            if (error.errors) displayError(error.errors);
            else Notify(NOTIFY.ERROR, "Lỗi", error.data ? error.data : CONSTANTS.MSG_ERROR);
        }
    }
    function _renderInfo() {
        if (tabIndex == TAB_SCREEN.INFO) {
            let status = props.statusOptions.find((x) => x.value == brand.status);
            let statusName = "Chưa duyệt";
            if (status) {
                statusName = status.label;
            }
            return (
                <div className='col-sm-12 p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>Thông tin thương hiệu</b>
                    </h4>
                    <div className='form-row'>
                        <div className='form-group col-md-8'>
                            <label htmlFor='name'>Tên thương hiệu:</label>
                            <Textbox name='name' className='form-control' onChanged={(name, value) => { }} value={brand.name} readOnly />
                        </div>
                        <div className='form-group col-md-4'>
                            <label htmlFor='index'>Thứ tự hiển thị:</label>
                            <Textbox name='index' className='form-control' onChanged={(name, value) => { }} value={brand.index} readOnly />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='description'>Mô tả:</label>
                        <div className='full-width' style={{ background: "#e9ecef", opacity: 1, padding: 10, height: brand.description ? "auto" : 100 }}>
                            <span dangerouslySetInnerHTML={{ __html: brand.description }} />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-3'>
                            <label htmlFor='image' className='col-form-label'>
                                Logo
                            </label>
                            <div className='full-width' style={{ border: "1px solid #e9ecef", height: 150 }}>
                                <img src={brand.image} alt={brand.name} className='full-width' style={{ maxHeight: 150, objectFit: "contain" }} />
                            </div>
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='subImage' className='col-form-label'>
                                Sub Logo
                            </label>
                            <div className='full-width' style={{ border: "1px solid #e9ecef", height: 150 }}>
                                <img src={brand.subImage} alt={brand.name} className='full-width' style={{ maxHeight: 150, objectFit: "contain" }} />
                            </div>
                        </div>
                        <div className='form-group col-md-3'>
                            <label className='col-form-label' htmlFor='isActive'>
                                Trạng thái kích hoạt
                            </label>
                            <div className={brand.isActive ? "rounded text-white status label-success text-center" : "rounded text-white status label-warning text-center"}>{brand.isActive ? "Đã kích hoạt" : "Chưa kích hoạt"}</div>
                        </div>
                        <div className='form-group col-md-3'>
                            <label className='col-form-label' htmlFor='statusName'>
                                Trạng thái duyệt
                            </label>
                            <div className={brand.status == 1 ? "rounded text-white status label-success text-center" : brand.status == 2 ? "rounded text-white status label-danger text-center" : "rounded text-white status label-warning text-center"}>
                                {statusName}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    function _renderApproved() {
        return (
            tabIndex == TAB_SCREEN.APPROVED && (
                <div className='col-sm-12 p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>Kiểm duyệt thương hiệu</b>
                    </h4>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className=' form-row'>
                                <div className='col-md-3 form-group'>
                                    <Radio name='status' isInline checked={dataSubmit.status == 1} value={1} onCheckedChange={(name, value) => setDataSubmit({ ...dataSubmit, status: value })} label='Duyệt thương hiệu' />
                                </div>
                                <div className='col-md-3 form-group'>
                                    <Radio name='status' isInline checked={dataSubmit.status == 2} value={2} onCheckedChange={(name, value) => setDataSubmit({ ...dataSubmit, status: value })} label='Từ chối duyệt thương hiệu' />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <label className={dataSubmit.status == 1 ? "col-form-label" : "col-form-label required"} htmlFor='remark'>
                                        {dataSubmit.status == 1 ? "Ghi chú" : "Lý do từ chối duyệt"}
                                    </label>
                                    <TextArea name='remark' className='form-control' ref={addRef} required={dataSubmit.status == 2} value={dataSubmit.remark} onChanged={(name, value) => setDataSubmit({ ...dataSubmit, remark: value })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
    function _renderDocuments() {
        return (
            tabIndex == TAB_SCREEN.DOCUMENTS && (
                <div className='col-sm-12 p-3'>
                    <h4 className='header-title m-t-0'>
                        <b>Tài liệu thương hiệu</b>
                    </h4>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <div className='alert alert-icon alert-info text-info lotus-alert-info'>
                                    <i className='mdi mdi-information mr-2'></i>
                                    <span className='text-black'>Giấy tờ chứng minh quyền sở hữu thương hiệu</span>
                                </div>
                            </div>
                            <div className='form-group'>
                                {brand.certificatesBrand ? (
                                    <LotusUpload
                                        isShowUpload={false}
                                        name='certificateBrand'
                                        multiple
                                        isAction
                                        isDelete={false}
                                        isShowFileName
                                        documents={brand.certificatesBrand}
                                        createdBy={brand.createdBy}
                                    // onChanged={() => {}}
                                    />
                                ) : (
                                    <div className='full-width text-center' style={{ background: "#e9ecef", opacity: 1, padding: 100, fontSize: 20 }}>
                                        <strong>Thương hiệu chưa có giấy tờ sở hữu</strong>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
    return (
        // <CommonModal
        //     title='Kiểm duyệt thương hiệu'
        //     show={props.isShow}
        //     size='lg'
        //     isBusy={isLoading}
        //     busyMsg={msg}
        //     onClose={() => props.onClose()}
        //     customButton={
        //         props.isShow &&
        //         tabIndex == TAB_SCREEN.APPROVED && (
        //             <BtnRounded width='130px' color={CARD_COLOR.Green}  onClick={() => _handleSave()}>
        //                 <i className='fa fa-save m-r-5' /> {dataSubmit.status == 1 ? "Duyệt thương hiệu" : "Từ chối thương hiệu"}
        //                 </BtnRounded> 
        //             // <button className='btn btn-custom btn-sm' onClick={() => _handleSave()}>

        //             // </button>
        //         )
        //     }
        // >
        <BaseModal className='modal-width-50' title='Kiểm duyệt thương hiệu' isShow={props.isShow}
            customButton={
                props.isShow &&
                tabIndex == TAB_SCREEN.APPROVED && (
                    <BtnRounded width='150px' color={CARD_COLOR.Green} onClick={() => _handleSave()}>
                        {dataSubmit.status == 1 ? "Duyệt thương hiệu" : "Từ chối thương hiệu"}
                    </BtnRounded>
                )
            }
            onClose={() => props.onClose()}
        >
            <Box>
                {TabHeader}
                {tabIndex == TAB_SCREEN.INFO ? _renderInfo() : tabIndex == TAB_SCREEN.DOCUMENTS ? _renderDocuments() : _renderApproved()}
            </Box>
        </BaseModal>
    );
}
