import React, { useState } from "react";
import CommonModal from "../based/Modal";
import styled from "styled-components";
import OrderServices from "../based/services/OrderServices";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";

function NoteModal({ show, onClose, orderId, onSave }) {
    const [busy, setBusy] = useState(false);
    const [remark, setRemark] = useState("");

    const CancelOrder = async () => {
        if (remark === "") {
            Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng nhập ghi chú");
            return;
        }
        const [err, data] = await OrderServices.RemarkOrderByAdmin({
            orderId: orderId,
            orderCancelDetail: remark,
        });
        if (err) {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra");
            return;
        } else {
            Notify(NOTIFY.SUCCESS, "Thành công", "Ghi chú thành công");
            onSave();
        }
    };

    return (
        <CommonModal
            show={show}
            isBusy={busy}
            busyMsg={"Đang tải"}
            title={`Note đơn hàng`}
            onClose={() => onClose()}
            size="md"
            onSave={() => {
                CancelOrder();
            }}
        >
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Note
                    </label>
                    <textarea
                        type="text"
                        value={remark}
                        className="form-control"
                        onChange={(e) => {
                            setRemark(e.target.value);
                        }}
                    />
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default NoteModal;

const Wrapper = styled.div``;
