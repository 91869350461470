import React from 'react'
import styled from 'styled-components';
const ToolTip = (props) => {
    return (
        <ToolTipWrapper className={`fa fa-question-circle ${props.className}`} aria-hidden="true">
            <span className="tooltiptext">{props.content}</span>
        </ToolTipWrapper>
    )
}
const ToolTipWrapper = styled.i`
color: #FD9B00;
position: relative;
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
&:hover .tooltiptext {
  visibility: visible;
}
`
export default ToolTip
