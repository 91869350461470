import React, { useEffect, useMemo, useState } from "react";
import Loading from "../based/Loading";
import { Layout } from "../layout/Layout";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY, ARTWORK_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import OperatorServices from "../based/services/OperatorServices";
import Barcode from "react-barcode";
import { ConfirmDialog } from "../based/Dialog";

export default function HandleOverToLotus(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [barcode, setBarcode] = useState("");
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        let content = document.querySelector(".content");
        if (content) {
            var contentHeight = content.offsetHeight;
            let boxBarcode = document.querySelector("#box-barcode");
            let boxBarcodeHeight = boxBarcode ? boxBarcode.offsetHeight : 300;
            var height = contentHeight - 130 - boxBarcodeHeight;
            if (height > 0) {
                document.getElementById("tbl_items").style.overflowY = "auto";
                document.getElementById("tbl_items").style.height = height + "px";
            }
        }
        document.getElementById("barcode").focus();
    }, []);
    async function _handleSubmit() {
        ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn chuyển tất cả sang trạng thái Đã bàn giao cho Ranus?", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang lưu dữ liệu...");
                let ids = [];
                datas.map((item) => ids.push(item.orderTagDetail.tagId));
                let [error, data] = await OperatorServices.UpdateHandleOverToLotus(ids);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Chuyển trạng thái thành công" });
                    window.location.reload();
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình cập nhật trạng thái.");
                }
            });
        });
    }
    function _renderButtons() {
        return (
            <div className="form-group col-md-12">
                <button className={datas && datas.length > 0 ? "btn btn-custom  btn-sm m-r-5" : "btn btn-custom  btn-sm m-r-5 disable"} onClick={() => (datas && datas.length > 0 ? _handleSubmit() : {})}>
                    <i className="fa fa-check m-r-5" />
                    Ranus xác nhận nhận hàng
                </button>
            </div>
        );
    }
    const _renderHeader = useMemo(() => (
        <thead>
            <tr>
                <th width="50" className="text-center cursor-pointer">
                    STT
                </th>
                <th width="200" className="text-center">
                    Barcode
                </th>
                <th width="200" className="text-center">
                    Order ID
                </th>
                <th width="200" className="text-center">
                    Loại áo
                </th>
                <th width="200 " className="text-center">
                    Size áo
                </th>
                <th width="200 " className="text-center">
                    Màu áo
                </th>
                <th width="100" className="text-center">
                    Loại in
                </th>
                <th className="text-center" width="200">
                    Mặt trước
                </th>
                <th className="text-center" width="200">
                    Mặt sau
                </th>
                <th className="text-center" width="200">
                    Logo thương hiệu
                </th>
            </tr>
        </thead>
    ));
    function _renderProducts() {
        return (
            <div className="content-table table-rep-plugin full-width" id="tbl_items">
                <table className="table table-bordered m-b-0 focus-on packing">
                    {_renderHeader}
                    <tbody>
                        {datas && datas.length > 0 ? (
                            datas.map((item, idx) => {
                                const { orderTagDetail, orderTagMockup } = item;
                                if (!orderTagDetail) return "";
                                const { artworkMockupModels, logoMockupModel } = orderTagMockup;
                                const size = orderTagDetail.attributeValues.find((x) => x.code == "Size");
                                const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : "/images/no-image.png";
                                const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : "/images/no-image.png";
                                return (
                                    <tr key={idx}>
                                        <td className="vertical-align-middle text-center">{idx + 1}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.tagId}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.orderId}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.productTypeName}</td>
                                        <td className="vertical-align-middle text-center">{size ? size.value : ""}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.colorDisplayName}</td>
                                        <td className="vertical-align-middle text-center">{orderTagDetail.printTypeName}</td>
                                        <td className="vertical-align-middle text-center">{front ? <img src={front.url} alt="front image" style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""} </td>
                                        <td className="vertical-align-middle text-center">{back ? <img src={back.url} alt="back image" style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                        <td className="vertical-align-middle text-center">{logoMockupModel ? <img src={logoMockupModel.url} alt="logo" style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="100" className="text-center">
                                    <span>Không có dữ liệu</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    async function _handleScanBarcode(barcodeSearch) {
        if (barcodeSearch) {
            let existed = false;
            if (datas && datas.length > 0) {
                for (let index = 0; index < datas.length; index++) {
                    existed = datas[index].orderTagDetail && datas[index].orderTagDetail.tagId == barcodeSearch;
                    if (existed) break;
                }
            }
            if (!existed) {
                setIsLoading(true);
                setMsg("Đang tải dữ liệu...");
                let [error, data] = await OperatorServices.GetHandleOverToLotus(barcodeSearch);
                if (!error && data) {
                    if (data) {
                        let orderTagDetail = data.orderTagDetail;
                        if (orderTagDetail && orderTagDetail.tagId > 0) {
                            let datasUp = [...datas];
                            datasUp.push(data);
                            setDatas(datasUp);
                        }
                    }
                    setIsLoading(false);
                    setMsg("");
                } else {
                    setIsLoading(false);
                    setMsg("");
                    Notify(NOTIFY.WARNING, "Chú ý", error.message ? error.message : "Có lỗi trong quá trình lấy thông tin");
                }
            }
            setBarcode("");
        } else {
            Notify(NOTIFY.WARNING, "Chú ý", "Yêu cầu nhập Barcode");
            document.getElementById("barcode").focus();
        }
    }
    function _renderSectionBarcode() {
        return (
            <div className="form-group col-md-3 order-packing">
                <div className="form-row m-t-20">
                    <div className="col-md-9">
                        <input
                            autocomplete="off"
                            id="barcode"
                            name="barcode"
                            className="form-control text-center font-weight-bold"
                            type="text"
                            placeholder="Nhập barcode"
                            maxlength="200"
                            onChange={(e) => setBarcode(e.target.value)}
                            value={barcode}
                            onKeyPress={(e) => {
                                if (e.charCode === 13) _handleScanBarcode(barcode);
                            }}
                        ></input>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-custom btn-sm full-width" onClick={() => _handleScanBarcode(barcode)}>
                            Nhập
                        </button>
                    </div>
                </div>
                <div className="form-row m-t-10">
                    <div className="col-md-12 text-center">
                        <Barcode value={barcode ? barcode : "000000000"} height="50" />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs currentPage="Verify và Nhận hàng" />
            <div className="col-sm-12 card-box">
                <div className="form-row m-t-20">
                    {_renderButtons()}
                    <div className="form-group col-md-12">{_renderProducts()}</div>
                    <div className="form-group col-md-12 form-row" id="box-barcode">
                        {_renderSectionBarcode()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
