import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../based/Breadcrumbs";
import CommonTable from "../../based/CommonTable";
import { CONSTANTS, NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import CheckBox from "../../based/inputs/CheckBox";
import FolderAudioServices from "../../based/services/FolderAudioServices";
import AddOrUpdateFolderAudio from "./AddOrUpdateFolderAudio";
import ModalAddAudioToFolder from "./ModalAddAudioToFolder";

export const initFolderAudio = {
    id: 0,
    parentId: 0,
    name: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    countAudio: 0,
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
    keyword: "",
};

function FolderAudioManagement() {
    const [datas, setDatas] = useState([[]]);
    const [dataAudio, setDataAudio] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [pagingAudio, setPagingAudio] = useState(initPage);
    const [folderAudio, setFolderAudio] = useState(initFolderAudio);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const tableRefAudio = useRef(null);
    const [msg, setMessage] = useState("");
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowModalAddAudio, setIsShowModalAddAudio] = useState(false);
    const [parentId, setParentId] = useState(-1);
    const [folderId, setFolderId] = useState(0);
    const [nameFolder, setNameFolder] = useState("");

    useEffect(() => {
        setBusy(false);
        getDatas(paging);
    }, []);

    useEffect(() => {
        getDataAudio(folderId, pagingAudio);
    }, [folderId]);

    const getDataAudio = async (folderId, pagingAudio) => {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await FolderAudioServices.GetAudioByFolderId(pagingAudio, folderId);
        if (!err && data) {
            setDataAudio(data.listObjects);
            setPagingAudio(data.paging);
            setBusy(false);
        } else {
            setDataAudio([]);
            setBusy(false);
            setPagingAudio(initPage);
        }
    };

    async function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await FolderAudioServices.GetFolderAudioByPage(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setDatas([]);
            setBusy(false);
        }
    }

    function _handleSaveSucceed(isContinue) {
        getDatas({ ...paging, pageNumber: 1 });
        if (isContinue) setFolderAudio(initFolderAudio);
        else setIsShowModal(false);
    }

    function _handleSaveSucceedAudio(isContinue) {
        getDataAudio(folderId, { ...paging, pageNumber: 1 });
        if (!isContinue) setIsShowModalAddAudio(false);
    }

    function _handleAdd() {
        setFolderAudio(initFolderAudio);
        setParentId(0);
        setIsShowModal(true);
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" />
                        Thêm folder cha
                    </button>
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" />
                                Sửa tên folder
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center"></th>
                    <th className="cursor-pointer">Tên folder</th>
                    <th className="cursor-pointer">Số nhạc</th>
                </tr>
            </thead>
        );
    }

    async function _handleEdit() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setBusy(true);
            setMessage("Đang tải dữ liệu...");
            let [err, data] = await FolderAudioServices.GetFolderAudioById(dataChecked[0].id);
            if (!err && data) {
                setBusy(false);
                setMessage(null);
                setFolderAudio(data);
                setIsShowModal(true);
            } else {
                setBusy(false);
                setMessage(null);
                Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một bản ghi để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr className="text-center">
                                    <td className="center">
                                        <CheckBox
                                            checked={item.checked}
                                            name={`${item.id}`}
                                            label=" "
                                            onCheckedChange={(name, value) => {
                                                let dt = [...datas];
                                                dt.map((x) => (x.checked = false));
                                                dt[index].checked = value;
                                                setDatas(dt);

                                                if (value) {
                                                    setFolderId(item.id);
                                                } else {
                                                    setFolderId(0);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="text-left">{item.name}</td>
                                    <td>{item.countAudio}</td>
                                </tr>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={4} className="text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    async function _handleDelete() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn muốn xóa folder đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMessage("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((d) => ids.push(d.id));
                    let [err, data] = await FolderAudioServices.DeleteFolderAudio(ids);
                    console.log(data);
                    if (!err && data) {
                        setBusy(false);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas({ ...paging });
                        setFolderId(0);
                        setNameFolder("");
                    } else {
                        setBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                    }
                });
            });
        }
    }

    const _handleAddAudio = () => {
        let checked = datas.filter((x) => x.checked);
        if (checked && checked.length > 0 && checked.length == 1) {
            setIsShowModalAddAudio(true);
        } else {
            Notify(NOTIFY.INFO, "Thông báo", "Chưa chọn xem folder nhạc nào");
        }
    };

    function convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedTime = `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
        return formattedTime;
    }

    const hanldeFilterAudio = (e) => {
        //e.preventDefault();
        let key = e.target.value;
        if (folderId) {
            if (e.charCode === 13) {
                const newPaging = {
                    ...pagingAudio,
                    pageNumber: 1,
                    keyword: key,
                };
                getDataAudio(folderId, newPaging);
            }
        }
    };

    function _renderActionAudio() {
        return (
            <div className="row flex-row p-10 box__actions">
                <div className="form-group custom-df">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAddAudio}>
                        <i className="fa fa-plus m-r-5" />
                        Thêm nhạc
                    </button>
                    <div>
                        Folder: <span className="name-folder">{nameFolder ? nameFolder : "Chưa chọn xem folder nào"}</span>
                    </div>
                    <input
                        type="text"
                        onKeyPress={(e) => {
                            hanldeFilterAudio(e);
                        }}
                        className="input-search-audio"
                        placeholder="Nhập tên nhạc tìm trong folder và enter"
                    />
                </div>
            </div>
        );
    }

    function _renderHeaderAudio() {
        return (
            <thead>
                <tr className="text-center">
                    <th className="cursor-pointer">Tên nhạc</th>
                    <th className="cursor-pointer" style={{ width: "30%" }}>
                        Thời lượng
                    </th>
                </tr>
            </thead>
        );
    }

    function _renderBodyAudio() {
        return (
            <tbody>
                {dataAudio && dataAudio.length > 0
                    ? dataAudio.map((item, idx) => {
                          return (
                              <tr className="text-center">
                                  <td className="cursor-pointer">{item.name}</td>
                                  <td className="cursor-pointer">{convertSecondsToMinutes(item.duration)}</td>
                              </tr>
                          );
                      })
                    : "Chưa có nhạc"}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Wapper>
                <Breadcrumbs currentPage="Quản lý loại câu hỏi" />
                <Loading show={isBusy} msg={msg} />
                <div className="d-flex">
                    <div className="w-50pc">
                        <CommonTable data={datas} paging={paging} ref={tableRef} headerCheckbox hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
                    </div>
                    <div className="w-50pc">
                        <CommonTable data={dataAudio} paging={pagingAudio} ref={tableRefAudio} hideSearchBasicButton renderAction={_renderActionAudio} renderHeader={_renderHeaderAudio} renderBody={_renderBodyAudio} />
                    </div>
                </div>
            </Wapper>
            {isShowModalAddAudio && (
                <ModalAddAudioToFolder
                    isShowModal={isShowModalAddAudio}
                    folderId={folderId}
                    folderName={nameFolder}
                    audios={dataAudio}
                    onClose={() => {
                        setIsShowModalAddAudio(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalAddAudio(false);
                        getDataAudio(folderId, pagingAudio);
                        getDatas({ ...paging });
                        _handleSaveSucceedAudio;
                    }}
                />
            )}
            {isShowModal && (
                <AddOrUpdateFolderAudio
                    isShowModal={isShowModal}
                    id={folderAudio.id}
                    parentId={parentId}
                    onClose={() => {
                        setIsShowModal(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getDatas({ ...paging });
                        _handleSaveSucceed;
                    }}
                />
            )}
        </React.Fragment>
    );
}

const Wapper = styled.div`
    .add-folder {
        color: #64c5b1;
        cursor: pointer;
    }
    .w-50pc {
        width: 50%;
    }
    .d-flex {
        display: flex;
    }
    .custom-df {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name-folder {
            font-weight: bold;
        }
    }
    .input-search-audio {
        height: 30px;
        width: 40%;
        border-radius: 5px;
        padding: 0 10px;
    }
    .row.flex-row.p-10.box__actions {
        padding: 20px;
    }
`;

export default FolderAudioManagement;
