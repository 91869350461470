import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import TFUImage from "../based/inputs/Image";
import Textbox from "../based/inputs/Textbox";
import TextEditor from "../based/inputs/TextEditor";
import TextNumber from "../based/inputs/TextNumber";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import BrandServices from "../based/services/BrandServices";
import SetProductServices from "../based/services/SetProductServices";
import TopicServices from "../based/services/TopicServices";
import useFormValidate from "../hooks/useFormValidate";
import CheckBox from "../based/inputs/CheckBox";
const INIT_CONFIG = {
    displayInCategories: [],
};
export default function ConfigSetProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [config, setConfig] = useState(INIT_CONFIG);
    const { addRef, displayError, isValid } = useFormValidate();
    const [products, setProducts] = useState([]);
    const [textSearch, setTextSearch] = useState("");
    const [isFetching, setFetching] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [brandOptions, setBrandOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);
    const [categories, setCategories] = useState([]);

    const [topics, setTopics] = useState([]);

    useEffect(() => {
        let configUp = props.config;
        if (configUp) {
            if (configUp.tag) configUp.tag = handleConvertArrayToString(configUp.tag);
        }
        setConfig(configUp);
        getBrandOptions();
        getTopicOptions(configUp.topic);
    }, [props.isShowModal]);

    async function getBrandOptions() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await BrandServices.GetListBrand(Common.Paging, 1);
        if (!err && data) {
            const options = data.listObjects.map((brand) => ({
                value: brand.id,
                label: brand.name,
                ...brand,
            }));
            setBrandOptions(options);
            setIsLoading(false);
            setMsg("");
        } else {
            setBrandOptions([]);
            setIsLoading(false);
            setMsg("");
        }
    }

    async function getTopicOptions(tops) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await TopicServices.GetTopics(Common.Paging);
        if (!err && data) {
            const options = data.listObjects.map((topic) => ({
                value: topic.id,
                label: topic.topicName,
                ...topic,
            }));
            if (tops && tops.length > 0 && options && options.length > 0) {
                let arrTopics = [];
                tops.map((t) => {
                    var cur = options.find((x) => x.value == t);
                    if (cur) {
                        arrTopics.push({ name: cur.label, id: cur.id });
                    }
                });
                setTopics(arrTopics);
            }
            setTopicOptions(options);
            setIsLoading(false);
            setMsg("");
        } else {
            setTopicOptions([]);
            setIsLoading(false);
            setMsg("");
        }
    }

    const handleChooseTopic = (id) => {
        const topic = topicOptions && topicOptions.length > 0 ? topicOptions.find((x) => x.value == id) : null;
        if (topic) {
            var existed = topics.find((x) => x.id == topic.id);
            if (existed) {
                Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn đã thêm chủ đề này rồi!");
            } else setTopics([...topics, { name: topic.label, id: topic.value }]);
        }
    };

    const handleRemoveTopic = (id) => {
        let topicsUpdate = [...topics];
        topicsUpdate = topicsUpdate.filter((x) => x.id !== id);
        setTopics(topicsUpdate);
    };

    const handleConvertStringToArray = (string) => {
        if (string && string.length > 0 && typeof string == "string") {
            var array = [];
            var items = string.split(",");
            items.forEach((el) => {
                var format = el.replace(/\s/g, "");
                if (format !== "") array.push(format);
            });
            return array;
        }
        return "";
    };

    const handleConvertArrayToString = (array) => {
        if (array) {
            var string = "";
            array.map((x) => (string += x + ","));
            return string;
        }
        return "";
    };

    async function _handleSave() {
        config.tag = handleConvertStringToArray(config.tag);
        config.topic = topics.map((x) => x.name);
        if (isValid()) {
            var hasVariants = config.productVariants && config.productVariants.length > 0;
            if (hasVariants) {
                setIsLoading(true);
                setMsg("Đang lưu dữ liệu..");
                const [err, data] = await SetProductServices.DirectSell(config);
                if (!err && data) {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                    props.onSuccess();
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                    else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                }
            } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn sản phẩm cho Set!");
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width="50" className="text-center">
                        Mặc định hiển thị
                    </th>
                    <th width="120" className="text-center">
                        Ảnh
                    </th>
                    <th width="350">Tên sản phẩm</th>
                    <th width="150" className="text-center">
                        Giá bán
                    </th>
                    <th className="text-center">Thông tin in</th>
                    <th width="50"></th>
                </tr>
            </thead>
        );
    }

    function _renderFrameConfig(item) {
        if (item.frameConfigs && item.frameConfigs.length > 0) {
            return (
                <table className="table table-bordered m-b-0 focus-on">
                    <thead>
                        <tr className="text-center">
                            <th className="cursor-pointer">Ngang áo (tay)</th>
                            <th className="cursor-pointer">Dài áo (trước)</th>
                            <th className="cursor-pointer">Khung ngang</th>
                            <th className="cursor-pointer">Khung dọc</th>
                            <th className="cursor-pointer">Vị trí 1</th>
                            <th className="cursor-pointer">Vị trí 2</th>
                            <th className="cursor-pointer">Vị trí 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {item.frameConfigs.map((config, i) => {
                            return (
                                <tr key={i}>
                                    <td>{config.widthMockup}</td>
                                    <td>{config.heightMockup}</td>
                                    <td>{config.widthFrame}</td>
                                    <td>{config.heightFrame}</td>
                                    <td>{config.optionTop1}</td>
                                    <td>{config.optionTop2}</td>
                                    <td>{config.optionTop3}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
    }
    function _handleChangePrice(item, idx, value) {
        let configUp = { ...config };
        if (configUp.productVariants && configUp.productVariants.length > 0) {
            configUp.productVariants[idx].sellPrice = value;
        }
        setConfig(configUp);
    }
    function _renderBody() {
        return (
            <tbody>
                {config && config.productVariants && config.productVariants.length > 0 ? (
                    config.productVariants.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="center">
                                    <CheckBox
                                        name={`checkbox-${i}`}
                                        onCheckedChange={(name, value) => {
                                            let configUp = { ...config };
                                            if (configUp.productVariants && configUp.productVariants.length > 0) {
                                                for (let j = 0; j < configUp.productVariants.length; j++) {
                                                    configUp.productVariants[j].isDefault = false;
                                                }
                                                configUp.productVariants[i].isDefault = value;
                                            }
                                            setConfig(configUp);
                                        }}
                                        checked={item.isDefault}
                                    />
                                </td>

                                <td className="center">
                                    <a className="iconImageDefault full-width">
                                        <img src={item.avatar} className="variant-avatar m-r-5" alt={item.name} />
                                    </a>
                                </td>
                                <td className="text-left vertical-align-middle">
                                    <strong> {item.name}</strong>
                                    {item.sellerSKU && <p className="margin-bottom-0 font-italic">SKU: {item.sellerSKU ? item.sellerSKU : "----"}</p>}
                                    {item.attributeValuesDetail &&
                                        item.attributeValuesDetail.length > 0 &&
                                        item.attributeValuesDetail.map((attribute, at) => {
                                            return (
                                                <span key={at} className="margin-bottom-0 font-italic mr-4">
                                                    {attribute.name} : <span className="badge badge-custom">{attribute.displayName}</span>
                                                </span>
                                            );
                                        })}
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <TextNumber
                                        required
                                        ref={addRef}
                                        name={`productVariants[${i}].sellPrice`}
                                        className="form-control"
                                        suffix=" đ"
                                        value={item.sellPrice}
                                        min={0}
                                        max={999999999}
                                        onChanged={(name, value) => _handleChangePrice(item, i, value)}
                                    />
                                </td>
                                <td className="text-center vertical-align-middle">{item.frameConfigs && item.frameConfigs.length > 0 ? _renderFrameConfig(item) : <span>Chưa có thông tin</span>}</td>
                                <td className="text-center vertical-align-middle">
                                    <i className="mdi mdi-24px mdi-delete text-danger cursor-pointer" onClick={() => _handleRemoveVariant(item)}></i>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="5" className="vertical-align-middle text-center">
                            Chưa có sản phẩm nào được chọn
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {}

    function updateVariantsAfterDel(item) {
        if (item) {
            let configUp = { ...config };
            if (configUp.productVariants && configUp.productVariants.length > 0) {
                let idx = -1;
                configUp.productVariants.map((variant, v) => {
                    if (variant.variantId == item.variantId) idx = v;
                });
                if (idx > -1) configUp.productVariants.splice(idx, 1);
                setConfig(configUp);
            }
        }
    }

    async function _handleRemoveVariant(item) {
        if (item) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    updateVariantsAfterDel(item);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn sản phẩm trước khi xóa!");
    }

    function _renderSearchAutoComplete() {
        return (
            <div id="search-autocomplete" className="p-1 bg-light rounded rounded-pill shadow-sm mb-4" onBlur={_handleClickOutside}>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <button type="submit" className="btn btn-link text-warning">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                    <input
                        type="search"
                        placeholder="Nhập tên sản phẩm..."
                        className="form-control border-0 bg-light"
                        onKeyUp={(e) => _handleChangeInputSearch(e)}
                        onChange={(e) => setTextSearch(e.target.value)}
                        onFocus={() => _handleFocusInputSearch()}
                        value={textSearch}
                        autoComplete="off"
                    />
                </div>
                {showResult && _renderResult()}
            </div>
        );
    }

    function _handleClickOutside() {
        setTimeout(function () {
            setShowResult(false);
        }, 200);
    }

    function _handleFocusInputSearch() {
        if (products && products.length > 0) setShowResult(true);
        else setShowResult(false);
    }

    const debounce = useCallback(
        _.debounce((_searchVal) => {
            _fetchingSearch(_searchVal);
        }, 1000),
        []
    );

    function _handleChangeInputSearch(e) {
        var text = e.target.value;
        setFetching(true);
        setShowResult(true);
        debounce(text);
    }

    async function _fetchingSearch(text) {
        if (text && text.length > 0) {
            setFetching(true);
            const [err, data] = await SetProductServices.SuggestSearch(text);
            if (!err && data) {
                setProducts(data);
                setFetching(false);
                setShowResult(true);
            } else {
                setShowResult(true);
                setFetching(false);
                setProducts([]);
            }
        } else {
            setProducts([]);
            setShowResult(false);
        }
    }

    function _renderResult() {
        return (
            <div id="search-result">
                <ul className="suggest_search">
                    {products && products.length > 0 ? (
                        products.map((item, index) => {
                            let rankPrice = 0;
                            if (item.minPrice == item.maxPrice) rankPrice = <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.minPrice} suffix=" đ" />;
                            else
                                rankPrice = (
                                    <React.Fragment>
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.minPrice} suffix=" đ" /> -
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.maxPrice} suffix=" đ" />
                                    </React.Fragment>
                                );
                            return (
                                <div className="product-item" key={index} onClick={() => _handleSelectItem(item)}>
                                    <li className="product_suggest">
                                        <div className="item-img">
                                            <img src={item.avatar} alt={item.name} />
                                        </div>
                                        <div className="item-info">
                                            <h3>{item.name} </h3>
                                            <p className="item-txt-sku">Bao gồm: {item.totalVariant} biến thể</p>
                                            <strong className="price"> {rankPrice} </strong>
                                        </div>
                                    </li>
                                </div>
                            );
                        })
                    ) : (
                        <div className="product-item">
                            <li className="product_suggest">
                                {isFetching ? (
                                    <span>
                                        <i className="fa fa-spinner m-r-5"></i>Đang tải dữ liệu...
                                    </span>
                                ) : (
                                    <span>Không có dữ liệu</span>
                                )}
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        );
    }

    async function _handleSelectItem(item) {
        if (item && item.id > 0) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu..");
            const [err, data] = await SetProductServices.SuggestProductVariants(item.id);
            if (!err && data) {
                setIsLoading(false);
                setMsg(null);
                //reset config
                let productVariants = data;
                if (productVariants && productVariants.length > 0) {
                    let configUp = { ...config };
                    if (!configUp.productVariants || configUp.productVariants.length == 0) configUp.productVariants = [];
                    productVariants.map((variant) => {
                        let oldVariant = null;
                        if (configUp.productVariants && configUp.productVariants.length > 0) oldVariant = configUp.productVariants.find((x) => x.variantId == variant.variantId);
                        if (!oldVariant) configUp.productVariants.push(variant);
                    });
                    setConfig(configUp);
                    setShowResult(false);
                }
            } else {
                setIsLoading(false);
                setMsg(null);
            }
        }
    }

    function _handleInputChange(key, value) {
        if (value) {
            setConfig({ ...config, setAvatar: value });
            document.getElementById("uploadImages").value = null;
        }
    }

    return (
        <CommonModal title={config.id == 0 ? "Thêm mới" : "Cập nhật"} show={props.isShowModal} size="tfulg" isBusy={isLoading} busyMsg={msg} onClose={() => props.onClose()} onSave={() => _handleSave()}>
            <div className="form-group">
                <div className="form-row">
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="title" className="required">
                            Tên set sản phẩm:
                        </label>
                        <Textbox required name="title" ref={addRef} maxLength={500} className="form-control" onChanged={(name, value) => setConfig({ ...config, title: value })} value={config.title} />
                    </div>
                    <div className="col-md-6">
                        <div className="ui-photo">
                            <TFUImage className="hide" id="uploadImages" name="uploadImages" images={config.setAvatar} onChanged={_handleInputChange} />
                            <ul className="ui-photo-grid clearfix">
                                <li className="ui-photo-item col-md-3">
                                    {config.setAvatar && config.setAvatar.length > 0 ? (
                                        <div className="ui-photo-box">
                                            <img className="ui-photo-item__image" src={config.setAvatar} />
                                            <div className="photo-hover-overlay">
                                                <ul className="photo-overlay-actions">
                                                    <li>
                                                        <button className="btn btn-link photo-overlay-actions__link" onClick={() => setConfig({ ...config, setAvatar: "" })}>
                                                            <i className="fa fa-trash-o" style={{ color: "red" }}></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="file-upload position-relative"
                                            onClick={() => {
                                                document.getElementById("uploadImages").click();
                                            }}
                                        >
                                            <div className="fileupload-text text-center">
                                                <i className="fa fa-camera font-size-30px"></i>
                                                <p className="mb-0 mt-2 text-secondary">Ảnh đại diện</p>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="title" className="required">
                            Ưu điểm:
                        </label>
                        <TextEditor required ref={addRef} name="advantages" className="form-control full-width" html={config.advantages ? config.advantages : null} onChanged={(name, value) => setConfig({ ...config, advantages: value })} />
                    </div>
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="title" className="required">
                            Nội dung:
                        </label>
                        <TextEditor required ref={addRef} name="content" className="form-control full-width" html={config.content ? config.content : null} onChanged={(name, value) => setConfig({ ...config, content: value })} />
                    </div>
                </div>
                {/* <div className="form-row">
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="title" className="required">
                            SEO Meta Title:
                        </label>
                        <Textbox required name="seoMetaTitle" ref={addRef} maxLength={500} className="form-control" onChanged={(name, value) => setConfig({ ...config, seoMetaTitle: value })} value={config.seoMetaTitle} />
                    </div>
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="title" className="required">
                            SEO Meta Description:
                        </label>
                        <Textbox required name="seoMetaDescription" ref={addRef} maxLength={500} className="form-control" onChanged={(name, value) => setConfig({ ...config, seoMetaDescription: value })} value={config.seoMetaDescription} />
                    </div>
                </div> */}
                <div className="form-row">
                    {/* <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="seoMetaKeywords" className="required">
                            SEO Meta Keywords:
                        </label>
                        <Textbox required name="seoMetaKeywords" ref={addRef} maxLength={500} className="form-control" onChanged={(name, value) => setConfig({ ...config, seoMetaKeywords: value })} value={config.seoMetaKeywords} />
                    </div> */}
                    <div className="col-md-6 vertical-align-middle">
                        <label htmlFor="brandId" className="required">
                            Thương hiệu:
                        </label>
                        <TFUSelect ref={addRef} required name="brandId" value={config.brandId} options={brandOptions} placeholder="Nhập tên thương hiệu ..." onChanged={(value) => setConfig({ ...config, brandId: value })} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 vertical-align-middle">
                        <label htmlFor="tag" className="required">
                            Tags: (Có thể ghi nhiều tag, ngăn cách bởi dấu phẩy)
                        </label>
                        <Textbox required name="tag" ref={addRef} maxLength={500} className="form-control" onChanged={(name, value) => setConfig({ ...config, tag: value })} value={config.tag} />
                    </div>
                    <div className="col-md-6 vertical-align-middle" style={{ margin: "auto" }}>
                        <label htmlFor="topic" className="required">
                            Chủ đề:
                        </label>
                        <TFUSelect name="topic" value="" options={topicOptions} placeholder="Chọn chủ đề..." onChanged={(value) => handleChooseTopic(value)} />
                        {topics && topics.length > 0 && (
                            <div className="d-flex">
                                {topics.map((topic, idx) => (
                                    <div className="d-flex align-items-center location-item w-fit-content" key={idx}>
                                        <span className="location-name">{topic.name}</span>
                                        <span className="ml-2 text-danger location-remove" onClick={() => handleRemoveTopic(topic.id)}>
                                            x
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {/* <div className="form-row">
                    <div className="col-md-6 vertical-align-middle">
                        <label htmlFor="tag" className="required">
                            Ngành hàng hiển thị
                        </label>
                        <div className="d-flex">
                            {config &&
                                config.displayInCategories &&
                                config.displayInCategories.map((item, idx) => {
                                    let option = categories.find((x) => +x.value == item);
                                    if (option)
                                        return (
                                            <div className="d-flex align-items-center location-item w-fit-content ">
                                                <span className="location-name">{option.text}</span>
                                            </div>
                                        );
                                })}
                        </div>
                        <TFUMultiLevelSelection multiple fetchOptions={_fetchCategories} onChanged={_handleChangedCategories} />
                    </div>
                </div> */}
            </div>
            <div className="col-sm-12 card-box p-3">
                <h4 className="header-title m-t-0">
                    <b>Thiết lập sản phẩm của Set</b>
                </h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group col-md-12">
                            {_renderSearchAutoComplete()}
                            <CommonTable noBoxOuter data={config.setProductVariants} paging={Common.Paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} freeSize="tbl_setproducts" />
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}
