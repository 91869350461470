import React, { useEffect, useMemo, useState } from "react";
import { UpdateArtworkInfoInside } from "../artwork-manage/UpdateArtworkInfo";
import Common from "../based/Common";
import { INK_COMBINATION, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Radio from "../based/inputs/Radio";
import TextArea from "../based/inputs/TextArea";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ArtworkServices from "../based/services/ArtworkServices";
import useFormValidate from "../hooks/useFormValidate";
import { AgencyArtWorkModel } from "../models/AgencyArtWorkModel";
import { SetBrandStampModel } from "../models/SetBrandStampModel";
import { SetCensorshipModel } from "../models/SetCensorshipModel";
import { SetModel } from "../models/SetModel";
var cloneDeep = require("lodash.clonedeep");
const REVIEW_TAB = {
    INFO: "INFO",
    CENSORSHIP: "CENSORSHIP",
};

const colorInkOptions = Object.keys({ ...INK_COMBINATION }).map((item) => {
    return {
        value: INK_COMBINATION[item],
        label: item,
    };
});

const MANUAL_RATE = [
    {
        value: 1,
        label: "Rất xấu",
    },
    {
        value: 2,
        label: "Xấu",
    },
    {
        value: 3,
        label: "Bình thường",
    },
    {
        value: 4,
        label: "Đẹp",
    },
    {
        value: 5,
        label: "Rất đẹp",
    },
];

export default function SetCensorship(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [tabIndex, setTabIndex] = useState();
    const { addRef, displayError, isValid } = useFormValidate();
    const [tranColor, setTranColor] = useState([]);

    const [setModel, setSetModel] = useState(new SetModel());
    const [artworkModels, setArtworkModels] = useState([new AgencyArtWorkModel()]);
    const [brandStamp, setBrandStamp] = useState([new SetBrandStampModel()]);

    useEffect(() => {
        if (props.isShow) {
            getDatas(props.setId);
            setTabIndex(REVIEW_TAB.INFO);
        }
    }, [props.isShow]);

    async function getDatas(id) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetPreAddArtwork(id);
        if (!error && data) {
            let setModel = data.setModel;
            let brandStamp = data.brandStampModels;
            let artwork = data.artworkModels;

            artwork.map((item, idx) => {
                if (item.transparentColors) {
                    item.tranColor = cloneDeep([...item.transparentColors]);
                }
            });

            setSetModel(setModel);
            setArtworkModels(artwork);
            setBrandStamp(brandStamp);

            setIsLoading(false);
            setMsg(null);
        } else {
            setSetModel({});
            setArtworkModels([]);
            setBrandStamp([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    const TabHeader = useMemo(() => (
        <div className="p-b-10">
            <ul className="nav nav-tabs tabs-bordered nav-justified">
                <li className="nav-item">
                    <a className={tabIndex == REVIEW_TAB.INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setTabIndex(REVIEW_TAB.INFO)}>
                        <span className="d-none d-sm-block">Thông tin tổng quan</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={tabIndex == REVIEW_TAB.CENSORSHIP ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => setTabIndex(REVIEW_TAB.CENSORSHIP)}>
                        <span className="d-none d-sm-block">Thông tin thiết kế</span>
                    </a>
                </li>
            </ul>
        </div>
    ));

    async function _handleSave() {
        if (setModel.manualReviewStatus == 2) {
            if (isValid()) {
                ConfirmDialog("Xác nhận", "Nếu từ chối duyệt thiết kế, trạng thái của Set sản phẩm trở thành 'Bị hủy'. Vui lòng xác nhận!", () => {
                    return new Promise(async (resolve, reject) => {
                        var result = _save(2);
                        if (result) resolve({ title: "Thành công", msg: "Kiểm duyệt thành công." });
                    });
                });
            }
        } else {
            _save(1);
        }
    }
    async function _save(status) {
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");

        let setCensorshipModel = new SetCensorshipModel();
        setCensorshipModel.setId = setModel.id;
        setCensorshipModel.manualReviewStatus = status;
        setCensorshipModel.manualRate = setModel.manualRate;
        setCensorshipModel.remark = setModel.remark;
        setCensorshipModel.artworkCensorShips = artworkModels.map((item) => {
            return {
                artworkId: item.id,
                transparentColors: JSON.stringify(item.transparentColors),
            };
        });

        let [error, data] = await ArtworkServices.SaveSetCensorship(setCensorshipModel);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            props.onSaveSucceed();
            props.onClose();
            if (status == 2) {
                return true;
            } else {
                Notify(NOTIFY.SUCCESS, "Thành công", "Kiểm duyệt thành công");
            }
        } else {
            setIsLoading(false);
            setMsg(null);
            if (error.errors) displayError(error.errors);
            else Notify(NOTIFY.ERROR, "Lỗi", error.data);
        }
    }
    function _renderInfo() {
        return <UpdateArtworkInfoInside artworkId={props.setId} readOnly />;
    }
    function _renderCensorship() {
        return (
            <React.Fragment>
                <div className="col-sm-12 card-box p-3">
                    <h4 className="header-title m-t-0">
                        <b>Thông tin chi tiết</b>
                    </h4>
                    {artworkModels && artworkModels.length > 0
                        ? artworkModels.map((artwork, idx) => {
                              return (
                                  <React.Fragment>
                                      <div className="card-body border-bottom">
                                          <div className="row">
                                              <div className="col-md-12">
                                                  <div className="form-row">
                                                      <div className="form-group col-md-3">
                                                          <div className="full-width center" style={{ border: "1px solid #e9ecef", backgroundImage: "url(/images/transparent.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                                              <img src={artwork.url} alt={artwork.setName} style={{ maxHeight: 150 }} />
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-md-6">
                                                          <label className="col-form-label" htmlFor="setName">
                                                              Thông tin thiết kế
                                                          </label>
                                                          <div className="full-width" style={{ background: "#e9ecef", opacity: 1, padding: 10 }}>
                                                              <p className="mb-0">
                                                                  <span className="m-r-5">Kích thước in:</span> {Math.ceil(artwork.widthCm)}cm x {Math.ceil(artwork.heightCm)}cm
                                                              </p>
                                                              <p className="mb-0">
                                                                  <span className="m-r-5">Kích thước ảnh:</span> {artwork.width} x {artwork.height} pixel
                                                              </p>
                                                              <p className="mb-0">
                                                                  <span className="m-r-5">{artwork.dpi} DPi</span>
                                                              </p>
                                                              <p className="mb-0">
                                                                  <span className="m-r-5">Dung lượng:</span> {artwork.size}MB
                                                              </p>
                                                              <p className="mb-0">
                                                                  <span className="m-r-5">Ảnh gốc:</span>{" "}
                                                                  <a className="text-primary" onClick={() => window.open(artwork.url, "_blank")}>
                                                                      {artwork.url}
                                                                  </a>
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="form-group col-md-12">
                                                  <label className="col-form-label col-md-12">IN ẤN - Kỹ thuật in DTG</label>
                                                  <TFUSelect name={"inkCombination"} value={artwork.inkCombination} options={colorInkOptions || []} isDisabled={true} />
                                              </div>
                                          </div>
                                          <div className="row">
                                              <label className="col-form-label col-md-12">IN ẤN - Màu áo Transparent</label>
                                              <p className="sub-header">
                                                  Các màu áo hệ thống sẽ lựa chọn để bỏ qua khi in, giúp màu background thiết kế trùng với màu áo hiện tại.
                                                  <br />
                                                  Hệ thống sẽ tiến hành kiểm tra và phản hồi độ khả dụng.
                                              </p>
                                              <div className="col-md-12">
                                                  <div className="d-flex flex-row flex-wrap">
                                                      {artwork.tranColor && artwork.tranColor.length > 0
                                                          ? artwork.tranColor.map((item, index) => {
                                                                let isColor = Common.isHexColorValue(item);
                                                                let valueBtn = isColor ? <span className="dot center mr-1" style={{ background: item }} /> : null;
                                                                let isActive = artwork.transparentColors.includes(item);
                                                                return (
                                                                    <button
                                                                        key={index}
                                                                        className={`btn btn-outline-dark waves-effect width-md waves-light mr-1 ${isActive ? "active" : ""}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            let arts = [...artworkModels];
                                                                            let art = arts[idx];
                                                                            let transparentColors = art.transparentColors;
                                                                            if (isActive) {
                                                                                let findIndex = transparentColors.indexOf(item);
                                                                                transparentColors.splice(findIndex, 1);
                                                                            } else transparentColors.push(item);
                                                                            setArtworkModels(arts);
                                                                        }}
                                                                    >
                                                                        {valueBtn}
                                                                        {item}
                                                                    </button>
                                                                );
                                                            })
                                                          : ""}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </React.Fragment>
                              );
                          })
                        : ""}
                </div>
                {setModel.manualReviewStatus !== 2 ? (
                    <div className="col-sm-12 card-box p-3">
                        <h4 className="header-title m-t-0">
                            <b>KIỂM DUYỆT</b>
                        </h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-row">
                                    <div className="col-md-3 form-group">
                                        <Radio
                                            name="manualReviewStatus"
                                            isInline
                                            checked={setModel.manualReviewStatus == 1}
                                            value={1}
                                            onCheckedChange={(name, value) => setSetModel({ ...setModel, manualReviewStatus: value, remark: "Duyệt thiết kế" })}
                                            label="Duyệt thiết kế"
                                        />
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <Radio
                                            name="manualReviewStatus"
                                            isInline
                                            checked={setModel.manualReviewStatus == 2}
                                            value={2}
                                            onCheckedChange={(name, value) => setSetModel({ ...setModel, manualReviewStatus: value, remark: "Bị hủy do vi phạm quy định của Ranus" })}
                                            label="Từ chối thiết kế"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        <TFUSelect name={"manualRate"} value={setModel.manualRate} options={MANUAL_RATE} onChanged={(newValue) => setSetModel({ ...setModel, manualRate: newValue })} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label className={setModel.manualReviewStatus == 1 ? "col-form-label" : "col-form-label required"} htmlFor="remark">
                                            {setModel.manualReviewStatus == 1 ? "Ghi chú" : "Lý do từ chối duyệt"}
                                        </label>
                                        <TextArea name="remark" className="form-control" ref={addRef} required={setModel.manualReviewStatus == 2} value={setModel.remark} onChanged={(name, value) => setSetModel({ ...setModel, remark: value })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
    return (
        <CommonModal
            title="Kiểm duyệt thiết kế"
            show={props.isShow}
            size="lg"
            isBusy={isLoading}
            busyMsg={msg}
            onClose={() => props.onClose()}
            customButton={
                props.isShow &&
                tabIndex == REVIEW_TAB.CENSORSHIP &&
                (setModel.manualReviewStatus !== 2 ? (
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave()}>
                        <i className="fa fa-save m-r-5" /> {setModel.manualReviewStatus == 1 ? "Duyệt thiết kế" : "Từ chối thiết kế"}
                    </button>
                ) : null)
            }
        >
            {TabHeader}
            {tabIndex == REVIEW_TAB.INFO ? _renderInfo() : _renderCensorship()}
        </CommonModal>
    );
}
