import React, { useContext, useEffect, useState } from "react";
import TFUSelect from "../based/refactor/TFUSelect";
import AttributeServices from "../based/services/AttributeServices";
import { ProductVariantContext } from "../context/ProductVariantContext";

export default function SectionVariantAttributes(props) {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);

    return (
        <div className="section-productVariants-attributes col-md-12 card-box p-3">
            <h4 className="header-title m-t-0">
                <b>Thuộc tính của biến thể</b>
            </h4>
            <div className="row">
                <div className="col-md-12">
                    {editingVariant && editingVariant.attributes && editingVariant.attributes.length > 0 && (!props.isEditing || editingVariant.attributes[0].value)
                        ? editingVariant.attributes.map((variant, index) => {
                              return (
                                  <div className="form-group" key={index}>
                                      <label htmlFor={`attributeValue[${index}]`} className="col-form-label required">
                                          {variant.name}
                                      </label>
                                      <AttributeValueOptions
                                          attributeId={variant.id}
                                          onChanged={(newVal) => {
                                              let updatedAttributes = [...editingVariant.attributes];
                                              updatedAttributes[index].value = newVal;
                                              setEditingVariant({ ...editingVariant, attributes: updatedAttributes, isModified: true });
                                          }}
                                          value={variant.value}
                                      />
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
        </div>
    );
}

const AttributeValueOptions = ({ attributeId, onChanged, value }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getOptions(attributeId);
    }, [attributeId]);

    const getOptions = async (attId) => {
        let [err, data] = await AttributeServices.GetAttributesValue(attId);
        if (!err) setOptions(data);
        else setOptions([]);
    };

    return (
        <TFUSelect
            options={options.map((item) => ({
                value: item.text,
                text: item.text,
                label: item.label,
            }))}
            value={value}
            onChanged={(newVal) => {
                onChanged(newVal);
            }}
            placeholder="--Chọn thuộc tính--"
        />
    );
};
