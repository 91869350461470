import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import OrderServices from "../based/services/OrderServices";
import Common from "../based/Common";
import { Notify } from "../based/Notify";
import { NOTIFY, PAYMENT_METHOD } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";

function LogisticDetailPage(props) {
    const { id } = props.match.params;
    const [deliveryModel, setDeliveryModel] = useState();

    useEffect(() => {
        ZaloSocialSDK.reload();
    });

    useEffect(() => {
        if (id) fetchData(id);
    }, [id]);

    const fetchData = async (orderId) => {
        const [err, data] = await OrderServices.GetRanusShippingLabel(orderId);
        if (!err && data) {
            setDeliveryModel(data);
        } else {
            setDeliveryModel(null);
        }
    };

    const cancelDelivery = async (cancelDeliveryId, shippingCompanyId) => {
        ConfirmDialog("Hủy đơn hàng", "Bạn có chắc chắn muốn hủy đơn hàng này không?", async () => {
            const [err, data] = await OrderServices.CancelDelivery(cancelDeliveryId, shippingCompanyId);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Hủy đơn hàng thành công");
                fetchData(id);
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Hủy đơn hàng thất bại");
            }
        });
    };

    const distance = useMemo(() => {
        if (deliveryModel && deliveryModel.quoteModels) {
            let first = deliveryModel.quoteModels[0];
            return first ? first.distance / 1000 : 0;
        }
        return 0;
    }, [deliveryModel]);

    const totalQuantity = useMemo(() => {
        if (deliveryModel && deliveryModel.products) {
            return deliveryModel.products.reduce((total, item) => total + item.quantity, 0);
        }
        return 0;
    }, [deliveryModel]);

    const CODAmount = useMemo(() => {
        if (deliveryModel && deliveryModel.order) {
            let redundantPrice = deliveryModel.order.finallyPrice - (deliveryModel.order.transferPartial || 0);
            if (redundantPrice < 0) redundantPrice = 0;
            if (deliveryModel.order.paymentMethod == PAYMENT_METHOD.COD || deliveryModel.order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) {
                return redundantPrice;
            }
            return 0;
        }
        return 0;
    }, [deliveryModel]);

    const totalWeight = useMemo(() => {
        if (deliveryModel && deliveryModel.products) {
            var totalWeight = deliveryModel.products.reduce((total, item) => total + item.shippingWeight, 0);
            return totalWeight / 100;
        }
        return 0;
    }, [deliveryModel]);

    if (!deliveryModel) return null;
    return (
        <Wrapper>
            {deliveryModel.order && (
                <div className="header">
                    <div className="header-left">{deliveryModel.order.id}</div>
                    <div className="header-right">Mã đơn Ranus: {deliveryModel.order.orderNumber}</div>
                </div>
            )}

            {deliveryModel.order && (
                <div className="section-shipper-detail">
                    <div className="shipping-detail">
                        <div className="detail-item">
                            <div className="detail-left">Trạng thái</div>
                            <div className="detail-right text-green">{deliveryModel.order.shippingServicesStatus}</div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-left">Mã đơn Grab</div>
                            <div
                                className="detail-right"
                                onClick={(e) => {
                                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Đã copy mã đơn hàng", 0.5);
                                    navigator.clipboard.writeText(deliveryModel.order.shippingServiceOrderId);
                                }}
                            >
                                {deliveryModel.order.shippingServiceOrderId}
                                <div className="icon">
                                    <CopyIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shipping-fee">
                        <div className="fee-left">{deliveryModel.order.shippingServiceTypeName}</div>
                        <div className="fee-right">{Common.formatCurrency(deliveryModel.order.shippingServicesFee)}</div>
                    </div>
                </div>
            )}

            <div className="section-info">
                {deliveryModel.warehousesModel && (
                    <div className="sender">
                        <div className="sender-header">
                            <div className="sender-header-left">Điểm lấy hàng</div>
                            <div className="sender-header-right">
                                <div className="icon">
                                    <GoRightIcon />
                                </div>
                            </div>
                        </div>
                        <div className="sender-body">
                            <p>
                                {deliveryModel.warehousesModel.name} - {deliveryModel.warehousesModel.phoneNumber}
                            </p>
                            <p>{deliveryModel.warehousesModel.fullAddress}</p>
                        </div>
                    </div>
                )}

                {deliveryModel.shippingAddress && (
                    <div className="receiver">
                        <div className="receiver-header">
                            <div className="receiver-header-left">Điểm giao hàng</div>
                            <div className="receiver-header-right">
                                <div className="right-detail">{distance > 0 ? `Cách ${distance} Km` : ""}</div>
                                <div className="icon">
                                    <GoRightIcon />
                                </div>
                            </div>
                        </div>
                        <div className="receiver-body">
                            <p>
                                {deliveryModel.shippingAddress.userName} - {deliveryModel.shippingAddress.phoneNumber}
                            </p>
                            <p>{deliveryModel.shippingAddress.fullAddress}</p>
                        </div>
                    </div>
                )}

                <div className="booking-type">
                    <div className="booking-left">
                        <div className="booking-type-header">COD</div>
                        <div className="booking-type-body">{Common.formatCurrency(CODAmount)}</div>
                    </div>
                    <div className="booking-middle">
                        <div className="booking-type-header">Size</div>
                        <div className="booking-type-body">S</div>
                    </div>
                    <div className="booking-right">
                        <div className="booking-type-header">Cân nặng</div>
                        <div className="booking-type-body">{totalWeight} Kg</div>
                    </div>
                </div>
            </div>

            <div className="order-product">
                <div className="order-product-overview">
                    Chi tiết đơn hàng ({totalQuantity}): {deliveryModel.order ? Common.formatCurrency(deliveryModel.order.finallyPrice) : 0}
                </div>
                <table className="table table-border">
                    <thead>
                        <tr>
                            <th>Sản phẩm</th>
                            <th>Số lượng</th>
                            <th>Tổng tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveryModel.products.map((item, index) => {
                            let sizeItem = item.attributeValueLightModels ? item.attributeValueLightModels.find((x) => x.code == "Size") : null;
                            const size = sizeItem ? sizeItem.value : "";
                            return (
                                <tr key={index}>
                                    <td>
                                        <span className="main">{item.name}</span>
                                        <span className="sub">
                                            Màu {item.colorDisplayName}, Size {size}
                                        </span>
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>{Common.formatCurrency(0)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="bottom-bar">
                <div
                    className={`cancel-button ${deliveryModel.order.shippingServicesStatus == "COMPLETED" ? "disabled" : ""}`}
                    onClick={(e) => {
                        cancelDelivery(deliveryModel.order.shippingServiceOrderId, deliveryModel.order.shippingCompanyId);
                    }}
                >
                    <span>Hủy đơn</span>
                </div>
                <div
                    className="copy-button"
                    onClick={(e) => {
                        if (deliveryModel.order.trackUrl) {
                            navigator.clipboard.writeText(deliveryModel.order.trackUrl);
                            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Copy link thành công");
                        } else {
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Không tìm thấy link");
                        }
                    }}
                >
                    <div className="icon">
                        <CopyLinkIcon />
                    </div>
                    <span>Copy link</span>
                </div>
                <div className="share-button">
                    <div className="zalo-share-button" data-href={deliveryModel.order.trackUrl} data-oaid="4522263210269195160" data-layout="2" data-color="white" data-share-type="4" data-customize="true">
                        <div className="icon">
                            <ShareLinkIcon />
                        </div>
                        <span>Share Zalo</span>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default LogisticDetailPage;

const Wrapper = styled.div`
    height: 100vh;
    padding: 20px 16px 70px 16px;
    overflow: auto;
    .zalo-share-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .shipping-fee {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .fee-right {
        margin-left: auto;
    }
    .section-shipper-detail {
        background: white;
        margin: 12px 0;
        border-radius: 12px;
    }

    .detail-item {
        display: flex;
        margin: 5px 0;
    }

    .detail-right {
        display: flex;
        margin-left: auto;
        align-items: center;
    }

    .detail-right .icon {
        margin-left: 6px;
    }

    .detail-right.text-green {
        color: #28b259;
        font-weight: 700;
    }

    .shipping-detail {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .shipping-fee {
        padding: 8px 12px;
        display: flex;
    }

    .bottom-bar {
        display: flex;
    }

    .copy-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        margin: 0px 5px;
    }

    .share-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        margin: 0 5px;
    }

    .cancel-button {
        border-radius: 12px;
        background: #f96a74;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin: 0 5px;
    }

    .copy-button span {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .share-button span {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    .header {
        display: flex;
    }

    .header-right {
        margin-left: auto;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .section-info {
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 12px 0;
    }

    .sender-header {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
    }

    .sender-header-right {
        display: flex;
        margin-left: auto;
    }

    .sender-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    p {
        margin: 0 !important;
    }

    .sender-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .sender {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header {
        display: flex;
        align-items: center;
    }

    .receiver-header-right {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .receiver {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header-right .icon {
        margin-left: 8px;
    }

    .right-detail {
        color: #28b259;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .receiver-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .receiver-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .booking-type {
        display: flex;
    }

    .booking-left {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border: 1px solid #d9d9d9;
        background: white;
        border-radius: 0 0 0 12px;
    }

    .booking-middle {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border: 1px solid #d9d9d9;
        background: #f6f6f6;
    }

    .booking-right {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border: 1px solid #d9d9d9;
        background: #f6f6f6;
        border-radius: 0 0 12px 0;
    }

    .booking-left-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        display: flex;
    }

    .booking-left-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        display: flex;
    }

    .booking-middle-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-middle-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-right-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-right-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-type-header {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .booking-type-body {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .booking-left .booking-type-body {
        color: #28b259;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .order-product-overview {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 8px;
    }

    thead {
        border-radius: 10px 0px 0px 0px;
        border: 1px solid #ededed;
        background: #878787;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
    }

    td span.main {
        display: block;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    td span.sub {
        display: block;
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    tr td {
        vertical-align: middle !important;
        text-align: center;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    table.table.table-border {
        background: white;
    }

    .bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    }

    .create-button {
        border-radius: 12px;
        background: #28b259;
        text-align: center;
        padding: 14px;
    }

    .create-button span {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .booking-main {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .method-type {
        display: flex;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .method-left {
        width: 33.33%;
        text-align: center;
        padding: 12px 24px;
    }

    .method-middle {
        width: 33.33%;
        text-align: center;
        padding: 12px 24px;
        border: 1px solid #d9d9d9;
    }

    .method-right {
        width: 33.33%;
        text-align: center;
        padding: 12px 24px;
    }

    .method-type-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .method-type-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-top: 4px;
    }

    .method-left.active {
        background: #28b259;
        border-radius: 12px 0 0 12px;
    }

    .method-type .active div {
        color: white !important;
    }

    .order-product {
        margin-top: 24px;
    }
`;

const GoRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
            <path d="M1 1L5 5L1 9" stroke="black" strokeWidth="1.5" />
        </svg>
    );
};

const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M19.1998 9.30078H11.0998C10.1057 9.30078 9.2998 10.1067 9.2998 11.1008V19.2008C9.2998 20.1949 10.1057 21.0008 11.0998 21.0008H19.1998C20.1939 21.0008 20.9998 20.1949 20.9998 19.2008V11.1008C20.9998 10.1067 20.1939 9.30078 19.1998 9.30078Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.7 14.7H4.8C4.32261 14.7 3.86477 14.5104 3.52721 14.1728C3.18964 13.8352 3 13.3774 3 12.9V4.8C3 4.32261 3.18964 3.86477 3.52721 3.52721C3.86477 3.18964 4.32261 3 4.8 3H12.9C13.3774 3 13.8352 3.18964 14.1728 3.52721C14.5104 3.86477 14.7 4.32261 14.7 4.8V5.7"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const CopyLinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.0002 10.9992C13.5707 10.4251 13.0228 9.95007 12.3936 9.60631C11.7645 9.26255 11.0687 9.05813 10.3535 9.00691C9.63841 8.9557 8.92061 9.05888 8.24885 9.30947C7.5771 9.56005 6.96709 9.95218 6.4602 10.4592L3.4602 13.4592C2.54941 14.4023 2.04544 15.6653 2.05683 16.9763C2.06822 18.2872 2.59407 19.5413 3.52111 20.4683C4.44815 21.3954 5.70221 21.9212 7.01319 21.9326C8.32418 21.944 9.58719 21.44 10.5302 20.5292L12.2402 18.8192"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const ShareLinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M15.0615 16.5851L21.2219 10.8068C21.6432 10.4116 21.643 9.74254 21.2214 9.3476L15.061 3.57708C14.4222 2.97874 13.3774 3.43167 13.3774 4.30691V7.01099C13.2878 7.02198 13.2094 7.03297 13.131 7.03297C11.7088 7.13187 10.3539 7.47253 9.05487 8.05494C4.70997 10.022 2 14.1648 2 18.8571V19.0558C2 19.9367 3.23342 20.1958 3.64614 19.4176C5.30347 16.2857 7.92385 14.3187 11.4065 13.4725C12.0448 13.3077 12.6943 13.2527 13.3774 13.1429V15.8558C13.3774 16.7313 14.4229 17.1841 15.0615 16.5851Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
