import React, { useState, useEffect,useRef } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../based/Breadcrumbs";
import CommonTable from "../../based/CommonTable";
import { CONSTANTS, INITPAGING, NOTIFY } from "../../based/Constants";
import PropTypes from "prop-types";
import CheckBox from "../../based/inputs/CheckBox";
import AddOrUpdateModal from "./components/AddOrUpdateModal";
import HelpTypeQuestionModel from "../../models/HelpTypeQuestionModel";
import TypeQuestionService from "../../based/services/Help/TypeQuestionService";
import { Notify } from "../../based/Notify";
import { ConfirmDialog } from "../../based/Dialog";
import Loading from "../../based/Loading";



const initTypeQuestion = {
    id: 0,
    parentId: 0,
    name: "",
    icon:"",
    indexPos:"",
    colorIcon:"",
    child:[],
};

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

function TypeQuestionManagement() {
    const [datas, setDatas] = useState([[]]);
    const [paging, setPaging] = useState(initPage);
    const [typeQuestion, setTypeQuestion] = useState(initTypeQuestion);
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [typeQuestionOptions, setTypeQuestionOptions] = useState([]);
    const [msg, setMessage] = useState("");
    const [selectedAll, setSelectedAll] = useState(false);

    const [isClickCheckbox, setIsClickCheckbox] = useState(false);
    useEffect(() => {
        setBusy(false);
        getDatas(paging);
    }, []);



    async function getDatas(newPaging) {
        setBusy(true);
        setMessage("Đang tải dữ liệu...");
        let [err, data] = await TypeQuestionService.GetTypeQuestionChildByPage(newPaging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setDatas([]);
            setBusy(false);
        }
    }
    
    async function GetTypeQuestionOptions(id) {
        setBusy(true);
        let [err,data] = await TypeQuestionService.GetTypeQuestionDropdown(id);
        if (!err && data) {
            setTypeQuestionOptions(data);
            setBusy(false);
        } else {
            setTypeQuestionOptions([]);
            setBusy(false);
        }
    }
    
    async function _handleDelete() {
        let dataChecked = _getKeywordsChecked();
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Khi xóa câu hỏi, các câu hỏi con (nếu có) sẽ bị xóa theo , bạn có chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMessage("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((d) => ids.push(d.id));
                    let[err,data] = await TypeQuestionService.DeleteTypeQuestion(ids);
                    console.log(data)
                    if (!err && data){
                        setBusy(false);
                        _handleCheckedAll(false);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas({ ...paging });
                        GetTypeQuestionOptions(0);
                    } else {
                        setBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                    }
                });
            });
        }
    }
    
     async function _handleEdit() {
        let dataChecked = _getKeywordsChecked();
        if (dataChecked && dataChecked.length == 1) {
            GetTypeQuestionOptions(dataChecked[0].id);
            setBusy(true);
            setMessage("Đang tải dữ liệu...");
            let [err,data] = await TypeQuestionService.GetTypeQuestionById(dataChecked[0].id);
            if (!err && data) {
                setBusy(false);
                setMessage(null);
                setTypeQuestion(data);
                setIsShowModal(true);
            } else {
                setBusy(false);
                setMessage(null);
                Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một bản ghi để thực hiện. Vui lòng kiểm tra lại!");
    }
    
    function _handleAdd() {
        GetTypeQuestionOptions(0);
        setTypeQuestion(initTypeQuestion);
        setIsShowModal(true);
    }
    
    function _handleSaveSucceed(isContinue) {
        getDatas({ ...paging, pageNumber: 1 });
        GetTypeQuestionOptions(0);
        if (isContinue) setTypeQuestion(initTypeQuestion);
        else setIsShowModal(false);
    }
    
    
    function _getKeywordsChecked() {
        if (isClickCheckbox)
            if (datas && datas.length > 0) {
                let datasUp = [];
                datas.map((parent) => {
                    let keywords = _recursiveGetChecked(parent);
                    if (keywords && keywords.length > 0) keywords.map((x) => datasUp.push(x));
                });
                return datasUp;
            }
        return null;
    }
    
    function _recursiveGetChecked(parent) {
        let checkeds = [];
        if (parent && parent.checked) checkeds.push(parent);
        if (parent && parent.child && parent.child.length > 0) {
            parent.child.map((child) => {
                let results = _recursiveGetChecked(child);
                if (results && results.length > 0) results.map((x) => checkeds.push(x));
            });
        }
        return checkeds;
    }
    
    function _getTotalRecordFlat() {
        if (datas && datas.length > 0) {
            let totalRecord = 0;
            datas.map((parent) => {
                let keywords = _recursiveCountRecord(parent);
                if (keywords && keywords.length > 0) totalRecord = totalRecord + keywords.length;
            });
            return totalRecord;
        }
        return 0;
    }
    
    function _recursiveCountRecord(parent) {
        let arr = [];
        arr.push(parent);
        if (parent && parent.child && parent.child.length > 0) {
            parent.child.map((child) => {
                let results = _recursiveCountRecord(child);
                if (results && results.length > 0) results.map((x) => arr.push(x));
            });
        }
        return arr;
    }
    
    function _handleCheckedAll(value) {
        setSelectedAll(value);
        setIsClickCheckbox(true);
        let datasUp = [];
        datas.map((parent) => {
            let keywords = _recursiveCheckedAllInPage(parent, value);
            datasUp.push(keywords);
        });
        setDatas(datasUp);
    }


    function _recursiveCheckedAllInPage(parent, value) {
        parent.checked = value;
        if (parent && parent.child && parent.child.length > 0) {
            parent.child.map((child) => {
                _recursiveCheckedAllInPage(child, value);
            });
        }
        return parent;
    }


    function _handleCheckedChange(keyword, value) {
        setIsClickCheckbox(true);
        let datasUp = [];
        datas.map((parent) => {
            let keywords = _recursiveSetStateCat(parent, keyword, "checked");
            datasUp.push(keywords);
        });
        let dataChecked = _getKeywordsChecked();
        let totalRecord = _getTotalRecordFlat();
        setSelectedAll((dataChecked && dataChecked.length) == totalRecord);
        setDatas(datasUp);
    }
    function _recursiveSetStateCat(parent, keyword, name) {
        if (parent.id == keyword.id) parent[name] = !parent[name];
        if (parent.child && parent.child.length > 0) {
            parent.child.map((child) => {
                _recursiveSetStateCat(child, keyword, name);
            });
        }
        return parent;
    }
    function _handleCollapse(keyword) {
        setIsClickCheckbox(false);
        let datasUp = [];
        datas.map((parent) => {
            let keywords = _recursiveSetStateCat(parent, keyword, "isUp");
            datasUp.push(keywords);
        });
        setDatas(datasUp);
    }
    function _handleClickRow(keyword, e) {
        // e.preventDefault();
        // _handleCheckedChange(keyword);
    }

     function _recursiveKeywords(keyword, level) {
        let contents = [];
        if (keyword) {
            if (keyword.isUp === undefined) keyword.isUp = true;
            let icon = keyword.child && keyword.child.length > 0 ? <i onClick={() => _handleCollapse(keyword)} className={keyword.isUp ? "fa fa-angle-right collapse__cat" : "fa fa-angle-down collapse__cat"}></i> : "";
            let space = "";
            if (level > 1) {
                if (keyword.child && keyword.child.length > 0)
                    for (var i = 0; i < level; i++) {
                        space = space + `&nbsp;&nbsp;&nbsp;`;
                    }
                else
                    for (var i = 0; i < level - 1; i++) {
                        space = space + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
                    }
            }
            contents.push(
                <tr key={-1}>
                    <td width={40} className={"center"}>
                        <CheckBox checked={!!keyword.checked} name={keyword.id} id={keyword.id} label=' ' onCheckedChange={(name, value) => _handleCheckedChange(keyword, value)} />
                    </td>
                    <td>
                        <span dangerouslySetInnerHTML={{ __html: space ? space : "" }} />
                        {icon}
                        {keyword.name}
                    </td>
                    <td align='center'>
                        {keyword.indexPos}
                    </td>
                    <td align='center'>
                        {keyword.icon ? <img src={keyword.icon} width={37} alt={keyword.name} /> : "Không có icon"}
                    </td>
                    
                    <td align='center'>
                        {keyword.colorIcon}
                    </td>
                </tr>
            );
            console.log(keyword.isUp)
            if (!keyword.isUp && keyword.child && keyword.child.length > 0) {
                level++;
                keyword.child.map((child) => {
                    contents.push(_recursiveKeywords(child, level));
                });
            }
        }
        return contents;
    }

    function _renderBody() {
        if (datas && datas.length > 0) {
            let contents = [];
            datas.map((item) => {
                let keywords = _recursiveKeywords(item, 1);
                contents.push(keywords);
            });
            if (contents && contents.length > 0) {
                return (
                    <tbody>
                        {contents.map((item, i) => {
                            return <React.Fragment key={i}>{item}</React.Fragment>;
                        })}
                    </tbody>
                );
            }
        }
        return (
            <tbody>
                <tr>
                    <td colSpan={4} className='text-center'>
                        Không có dữ liệu!
                    </td>
                </tr>
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = _getKeywordsChecked();
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='row flex-row p-10 box__actions'>
                <div className='form-group'>
                    <button className='btn btn-custom btn-sm m-r-5' onClick={_handleAdd}>
                        <i className='fa fa-plus m-r-5' />
                        Thêm
                    </button>
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className='fa fa-edit m-r-5' />
                                Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className='fa fa-remove m-r-5' />
                                Xóa
                            </button>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    <th width={40} className='center'>
                        <CheckBox checked={!!selectedAll} name='select_all' label=' ' onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th className='cursor-pointer'>Loại câu hỏi</th>
                    <th className='cursor-pointer'>Thứ tự hiển thị</th>
                    <th className='cursor-pointer'>Icon</th>
                    <th className='cursor-pointer'>Mã màu icon (hex)</th>
                </tr>
            </thead>
        );
    }

    return ( 
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý loại câu hỏi' />
            <Loading show={isBusy} msg={msg} />
            <CommonTable 
                data={datas} 
                paging={paging}
                ref={tableRef} 
                headerCheckbox 
                searchBasic
                hideSearchBasicButton
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={getDatas} />
            {isShowModal && (
                <AddOrUpdateModal
                    isShowModal={isShowModal}
                    typeQuestion={typeQuestion} 
                    typeQuestionOptions={typeQuestionOptions} 
                    onClose={() => {
                        setIsShowModal(false);
                        setSelectId(0);
                    }}
                    onSaveContinue={() => {
                        setIsShowModal(false);
                        getDatas({ ...paging });
                        _handleSaveSucceed
                    }}
                />
            )}


        </React.Fragment>   
    );
}




const Wrapper = styled.div`
   
`
TypeQuestionManagement.propTypes = {};


export default TypeQuestionManagement;