import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { INITPAGING, NOTIFY, PLATFORM, PLATFORM_NAME } from "../based/Constants";
import { Notify } from "../based/Notify";
import PushingServices from "../based/services/PushingServices";
import { PushingPhotoshopProduct } from "./PushingFileModal";

const PLATFORM_PUSH = [
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Tiktok, label: PLATFORM_NAME.Tiktok },
];

const Artwork_Type = {
    Front: 1,
    Back: 2,
};

const PushingProductCustomizeModel = {
    id: 0,
    pushingProductId: 0,
    name: "",
    categoryId: 0,
    platformCategoryId: 0,
    platformBreadcrumb: "",
    brand: "Ranus",
    content: "",
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    printType: 0,
    photoshopId: 0,
    colorCode: "",
    colorName: "",
    appProductVariantId: "",
    platform: PLATFORM.Undefined,
    shopId: "",
    pushingProduct: {},
    pushingPhotoshopProducts: [],
    artworkType: Artwork_Type.Front,
    artworkCount: 1,
};

const PushingProductType = {
    Shop: 0,
    Customize: 1,
};

function PushingManageDetail(props) {
    const [shopOptions, setShopOptions] = useState([]);
    const [model, setModel] = useState(PushingProductCustomizeModel);
    const [photoshopFiles, setPhotoshopFiles] = useState([]);
    const [photoshopProducts, setPhotoshopProducts] = useState([PushingPhotoshopProduct]);
    const { designId, platform } = props.match.params;

    useEffect(() => {
        _getProductCustomizesById(+designId, +platform);
    }, []);

    useEffect(() => {
        if (model.platform > 0) {
            _getShopOptions(model.platform);
            if (model.colorCode != "") {
                _getPhotoshopByPage({ ...INITPAGING, pageNumber: 1, pageSize: 100, colorCode: model.colorCode, photoshopFileType: +model.platform }).then((psFiles) => {
                    setPhotoshopFiles(psFiles);
                    //lấy ra photoshop id đầu tiên
                    let psId = psFiles.length > 0 ? psFiles[0].id : null;
                    setModel({ ...model, photoshopId: psId });
                });
            }
        }
    }, [model.platform]);

    useEffect(() => {
        if (designId > 0) {
            if (model.photoshopId > 0) _getPhotoshopProduct(designId, model.photoshopId);
            else setPhotoshopProducts([]);
        }
    }, [model.photoshopId]);

    //lấy thông tin shop theo platform
    async function _getShopOptions(platformId) {
        let [err, data] = await PushingServices.GetShopByPlatform(platformId);
        if (!err && data) {
            if (!model.shopId && data.length > 0) setModel({ ...model, shopId: data[0].value });
            setShopOptions(data);
        } else {
            setShopOptions([]);
        }
    }

    //lấy thông tin cơ bản theo design id
    async function _getProductCustomizesById(designId, platform) {
        let [err, data] = await PushingServices.GetProductCustomizesById(designId, platform);
        if (!err && data) {
            //lấy ra thông tin các file photoshop
            let psFiles = await _getPhotoshopByPage({ ...INITPAGING, pageNumber: 1, pageSize: 100, colorCode: data.colorCode });
            setPhotoshopFiles(psFiles);

            //lấy ra photoshop id đầu tiên
            if (data.photoshopId == 0) {
                let psId = psFiles.length > 0 ? psFiles[0].id : 0;
                data.photoshopId = psId;
            }

            //platform hiện tại
            data.platform = platform;
            setModel(data);
            Notify(NOTIFY.SUCCESS, "Thành công", "Lấy thông tin sản phẩm thành công");
        } else {
            setModel(PushingProductCustomizeModel);
            Notify(NOTIFY.ERROR, "Lỗi", "Không thể lấy thông tin sản phẩm từ server");
        }
    }

    //lấy thông tin các file photoshop được config
    async function _getPhotoshopByPage(page) {
        let [err, data] = await PushingServices.GetPhotoshopFileByPage(page);
        if (!err && data) {
            return data.listObjects;
        } else {
            return [];
        }
    }

    //lấy thông tin sản phẩm theo photoshopId
    async function _getPhotoshopProduct(designId, photoshopId) {
        let [err, data] = await PushingServices.GetPushingPhotoshopProductDetail(designId, photoshopId);
        if (!err && data) {
            data = data.map((item) => {
                item.checked = true;
                return item;
            });
            setPhotoshopProducts(data);
        } else {
            setPhotoshopProducts([]);
        }
    }

    async function _createUpdatePushProduct() {
        let md = { ...model };
        if (!md.shopId) {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Chưa chọn gian hàng");
            return;
        }
        if (!md.photoshopId) {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Chưa chọn file pts");
            return;
        }
        let pushingProduct = {
            productId: +designId,
            productType: PushingProductType.Customize,
            platform: md.platform,
            shopId: md.shopId,
        };
        md.pushingProduct = pushingProduct;
        md.pushingPhotoshopProducts = photoshopProducts.filter((x) => x.checked);
        if (md.artworkType == 0) md.artworkType = Artwork_Type.Front;
        let [err, data] = await PushingServices.CreateUpdatePushProduct(md);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Đẩy sản phẩm thành công");
            window.close();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra");
        }
    }

    const photoshopFileName = useMemo(() => {
        // photoshopFiles
        let checkedItem = model && photoshopFiles ? photoshopFiles.find((x) => x.id === model.photoshopId) : null;
        return checkedItem ? checkedItem.name : "";
    }, [photoshopFiles, model.photoshopId]);

    return (
        <Wrapper>
            <div className="form-total">
                <div className="form-card">
                    <h6>Thông tin muốn đẩy sản phẩm</h6>

                    <div className="form-platform">
                        <label htmlFor="" className="required">
                            Chọn sàn
                        </label>
                        <div className="platform-list">
                            {PLATFORM_PUSH.map((item, index) => {
                                return (
                                    <div className="platform-item" key={index}>
                                        <input
                                            type="radio"
                                            id={item.label}
                                            name={item.label}
                                            checked={model.platform === item.value}
                                            onClick={() => {
                                                _getProductCustomizesById(designId, item.value);
                                            }}
                                        />
                                        <label htmlFor={item.label}>{item.label}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="form-shop">
                        <label htmlFor="" className="required">
                            Chọn gian hàng
                        </label>
                        <div className="shop-list">
                            {shopOptions.map((item, index) => {
                                return (
                                    <div className="shop-item" key={index}>
                                        <input
                                            type="radio"
                                            id={item.label}
                                            name={item.label}
                                            checked={model.shopId === item.value}
                                            onClick={(e) => {
                                                setModel({ ...model, shopId: item.value });
                                            }}
                                        />
                                        <label htmlFor={item.label}>{item.label}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {model && (
                    <React.Fragment>
                        <div className="form-card">
                            <h6>Thông tin sản phẩm</h6>
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Tên sản phẩm
                                </label>
                                <input
                                    type="text"
                                    placeholder="Nhập tên sản phẩm"
                                    value={model.name}
                                    onChange={(e) => {
                                        setModel({ ...model, name: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Ngành hàng
                                </label>
                                <input type="text" placeholder="Nhập tên ngành hàng" value={model.platformBreadcrumb} readOnly />
                            </div>
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Thương hiệu
                                </label>
                                <input type="text" placeholder="Nhập tên thương hiệu" value={"Ranus"} readOnly />
                            </div>
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Mô tả sản phẩm
                                </label>
                                <textarea
                                    type="text"
                                    placeholder="Mô tả sản phẩm"
                                    rows="5"
                                    value={model.content}
                                    onChange={(e) => {
                                        setModel({ ...model, content: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Kích thước
                                </label>
                                <div className="form-package">
                                    <input
                                        type="number"
                                        placeholder="Dài"
                                        value={model.length}
                                        onChange={(e) => {
                                            setModel({ ...model, length: +e.target.value });
                                        }}
                                        className="mx-2"
                                    />
                                    <SVGX />
                                    <input
                                        type="number"
                                        placeholder="Rộng"
                                        value={model.width}
                                        onChange={(e) => {
                                            setModel({ ...model, width: +e.target.value });
                                        }}
                                        className="mx-2"
                                    />
                                    <SVGX />
                                    <input
                                        type="number"
                                        placeholder="Cao"
                                        value={model.height}
                                        onChange={(e) => {
                                            setModel({ ...model, height: +e.target.value });
                                        }}
                                        className="mx-2"
                                    />
                                </div>
                            </div>

                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Cân nặng
                                </label>
                                <div className="form-package">
                                    <input
                                        type="number"
                                        placeholder="Dài"
                                        value={model.weight}
                                        onChange={(e) => {
                                            setModel({ ...model, weight: +e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Màu sắc:
                                </label>
                                <div className="form-package">
                                    <input type="color" value={model.colorCode} readOnly />
                                    <span className="mx-2">{model.colorName}</span>
                                </div>
                            </div>

                            {model.artworkCount > 1 && (
                                <div className="form-product">
                                    <label htmlFor="" className="required">
                                        Mặt hiển thị mặc định
                                    </label>
                                    <div className="form-radio-box">
                                        <input
                                            type="radio"
                                            id="front"
                                            name="front"
                                            checked={model.artworkType === Artwork_Type.Front}
                                            onClick={() => {
                                                setModel({ ...model, artworkType: Artwork_Type.Front });
                                            }}
                                        />
                                        <label htmlFor="front">Mặt trước</label>
                                        <input
                                            type="radio"
                                            id="back"
                                            name="back"
                                            checked={model.artworkType === Artwork_Type.Back}
                                            onClick={() => {
                                                setModel({ ...model, artworkType: Artwork_Type.Back });
                                            }}
                                        />
                                        <label htmlFor="back">Mặt sau</label>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="form-card">
                            <h6>Tùy chọn render hình và giá</h6>
                            <div className="form-render">
                                <label htmlFor="" className="required">
                                    File Render
                                </label>
                                <div className="render-item-body">
                                    <div className="render-item-detail">{photoshopFileName}</div>
                                    <div className="render-list">
                                        {photoshopFiles &&
                                            photoshopFiles.map((item, index) => {
                                                return (
                                                    <div
                                                        className={`render-item ${item.id === model.photoshopId ? "active" : ""}`}
                                                        key={index}
                                                        onClick={() => {
                                                            setModel({ ...model, photoshopId: item.id });
                                                        }}
                                                    >
                                                        <img src={item.thumbnail} />
                                                        {item.id == model.photoshopId && (
                                                            <div className="background">
                                                                <div className="icon">
                                                                    <SVGCheck />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="form-product">
                                <label htmlFor="" className="required">
                                    Sản phẩm
                                </label>
                                <div className="product-list">
                                    {photoshopProducts &&
                                        photoshopProducts.map((item, index) => {
                                            return (
                                                <div className="product-item" key={index}>
                                                    <input
                                                        type="checkbox"
                                                        id={item.id}
                                                        name={item.id}
                                                        checked={item.checked}
                                                        onChange={(e) => {
                                                            let newPhotoshopProducts = [...photoshopProducts];
                                                            newPhotoshopProducts[index].checked = e.target.checked;
                                                            setPhotoshopProducts(newPhotoshopProducts);
                                                        }}
                                                    />
                                                    <label htmlFor={item.id}>{item.appProductName}</label>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="form-price">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Thumbnail</th>
                                            <th>Dòng sản phẩm</th>
                                            <th>Size</th>
                                            <th>Scale</th>
                                            <th>Giá sản phẩm</th>
                                            <th>Chi phí in</th>
                                            <th>Giá gạch (Sell Price)</th>
                                            <th>Giảm giá (Discount)</th>
                                            <th>Giá bán (Sale Price)</th>
                                            <th>Số lượng</th>
                                            <th>Mã SKU</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {photoshopProducts &&
                                            photoshopProducts.map((item, idx) => {
                                                if (!item.pushingPhotoshopVariants || !item.checked) return;
                                                var rowSpan = item.pushingPhotoshopVariants.length;
                                                return (
                                                    <React.Fragment>
                                                        {item.pushingPhotoshopVariants &&
                                                            item.pushingPhotoshopVariants.map((variant, index) => {
                                                                var sellPrice = variant.sellPrice + variant.printCost;
                                                                var salePrice = sellPrice - variant.discountBrandVoucher;
                                                                return (
                                                                    <tr key={index}>
                                                                        {index === 0 && (
                                                                            <React.Fragment>
                                                                                <td rowSpan={rowSpan} className="thumbnail-table">
                                                                                    <img src={item.appProductAvatar} />
                                                                                </td>
                                                                                <td rowSpan={rowSpan}>{item.appProductName}</td>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <td>{variant.sizeValue}</td>
                                                                        <td>{variant.scale}</td>
                                                                        <td>{Common.formatCurrency(variant.sellPrice)}</td>
                                                                        <td>{Common.formatCurrency(variant.printCost)}</td>
                                                                        <td>{Common.formatCurrency(sellPrice)}</td>
                                                                        <td>{Common.formatCurrency(variant.discountBrandVoucher)}</td>
                                                                        <td>{Common.formatCurrency(salePrice)}</td>
                                                                        <td>{variant.quantity}</td>
                                                                        <td>{variant.sku}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="form-button">
                            <button
                                className="btn btn-danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.close();
                                }}
                            >
                                Trở về
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    _createUpdatePushProduct();
                                }}
                            >
                                Đẩy sản phẩm
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Wrapper>
    );
}

export default PushingManageDetail;

const Wrapper = styled.div`
    .form-package {
        display: flex;
        align-items: center;
    }
    .form-radio-box {
        display: flex;
        align-items: center;
    }

    .form-radio-box label {
        margin-bottom: 0;
        margin-left: 10px;
    }

    .form-radio-box input {
        margin-left: 10px;
        display: flex;
    }

    .render-item.active {
        position: relative;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #64c5b1;
        background: linear-gradient(0deg, rgba(100, 197, 177, 0.5) 0%, rgba(100, 197, 177, 0.5) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
        opacity: 0.5;
    }
    .form-total {
        padding: 10px;
    }

    .form-card {
        background: white;
        padding: 18px;
        margin: 18px 0;
    }

    .form-card h6 {
        color: #666f7b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;
    }

    label.required {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .platform-list {
        display: flex;
    }

    .platform-item {
        width: calc(20% - 20px);
        margin: 10px;
        display: flex;
        align-items: center;
    }

    .platform-item label {
        margin: 0 0 0 10px;
    }

    .form-shop {
        margin-top: 10px;
    }

    .shop-list {
        display: flex;
        align-items: center;
    }

    .shop-item {
        display: flex;
        align-items: center;
    }

    .shop-item label {
        margin: 0 0 0 10px;
    }

    .form-product {
        display: flex;
        align-items: center;
        margin: 18px 0;
    }

    .form-product label.required {
        margin-bottom: 0;
        width: 15%;
    }

    .form-product input {
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
        height: 32px;
    }

    .form-product textarea {
        flex: 1;
    }

    .form-package input[type="text"] {
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 0 12px;
    }

    .form-package span {
    }

    .form-package input[type="text"]:first-child {
        margin-left: 0px;
    }

    .form-print-type {
        display: flex;
    }

    .form-print-type-item {
        display: flex;
        align-items: center;
    }

    .form-print-type-item label {
        margin: 0 0 0 10px;
    }

    .form-render {
        display: flex;
    }

    .render-list {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 10px;
    }

    .form-render label.required {
        width: 15%;
    }

    .render-item {
        width: 110px;
        height: 110px;
    }

    .render-item img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .render-item.active {
        border: 1px solid #64c5b1;
        background: linear-gradient(0deg, rgba(100, 197, 177, 0.5) 0%, rgba(100, 197, 177, 0.5) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
    }

    .product-list {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .product-item {
        width: calc(25% - 20px);
        margin: 10px;
        display: flex;
        align-items: center;
    }

    .product-item input[type="checkbox"] {
        flex: 0 !important;
    }

    .product-item label {
        margin: 0 0 0 10px;
    }

    thead {
        background: #d9d9d9;
    }

    .thumbnail-table img {
        width: 110px;
        height: 110px;
        margin: auto;
        display: block;
    }

    td.thumbnail-table {
        text-align: center;
        vertical-align: middle;
    }

    .render-item-body {
        flex: 1;
    }

    .render-item-detail {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 0;
    }

    .form-button {
        display: flex;
        justify-content: flex-end;
    }

    .form-button button.btn {
        margin: 10px;
    }

    .form-package input:first-child {
        margin-left: 0 !important;
    }
`;

const SVGX = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M1 1L11 11" stroke="black" />
            <path d="M11 1L0.999999 11" stroke="black" />
        </svg>
    );
};

const SVGCheck = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#64C5B1" />
            <path d="M4 7.9987L6.66667 10.6654L12 5.33203" stroke="white" />
        </svg>
    );
};
