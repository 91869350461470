import React, { useState, useEffect } from 'react';
import { Layout } from '../layout/Layout';
import Loading from '../based/Loading';
import { CONSTANTS, INITPAGING } from '../based/Constants';
import { NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import CommonTable from '../based/CommonTable';
import AnalyticServices from '../based/services/AnalyticServices';
import Request from '../based/Request';
import TFUDatetime from '../based/inputs/TFUDatetime';


export default function AnyalyticManagement(props) {
    const [isBusy, setBusy] = useState(true)
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, fromDate: new Date(), toDate: new Date() });

    useEffect(() => {
        setBusy(false)
        getDatas(paging)
    }, [])

    async function getDatas(newPaging) {
        setBusy(true)
        const [err, data] = await AnalyticServices.GetTrackingByPaging(newPaging);
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setBusy(false)
        } else {
            setPaging(newPaging);
            setDatas([]);
            setBusy(false)
        }
    }

    async function exportExcel(newPaging) {
        let [err, data] = await AnalyticServices.ExportExcel(newPaging)
        if (!err) {
            let fileName = data.fileName;
            window.open(`${Request.API_URL}/api/analytic/download-file/${fileName}`, '_blank')
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, CONSTANTS.MSG_ERROR)
        }

    }

    function _renderAction() {
        return <div className='row flex-row p-10 box__actions align-items-end'>
            <div className='form-group ml-3'>
                <label htmlFor="fromDate" className="required">Từ ngày:</label>
                <TFUDatetime
                    dateFormat='dd/MM/yyyy'
                    name='fromDate'
                    value={new Date(paging.fromDate)}
                    onChanged={(selectedDate) => setPaging({ ...paging, fromDate: new Date(selectedDate) })}
                />
            </div>
            <div className='form-group ml-3'>
                <label htmlFor="toDate" className="required">Đến ngày:</label>
                <TFUDatetime
                    dateFormat='dd/MM/yyyy'
                    name='toDate'
                    value={new Date(paging.toDate)}
                    onChanged={(selectedDate) => setPaging({ ...paging, toDate: new Date(selectedDate) })}
                />
            </div>
            <div className="form-group ml-3">
                <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); getDatas({ ...paging }) }}>Tìm kiếm</button>
            </div>
            <div className="form-group ml-3">
                <button className={`btn btn-primary ${!datas ? 'disabled' : ''}`} onClick={(e) => { e.preventDefault(); exportExcel({ ...paging }) }}>Xuất file</button>
            </div>
        </div>
    }


    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className='center'>Session Id</th>
                    <th className='center'>Thời gian bắt đầu Link</th>
                    <th className='center'>Link trước</th>
                    <th className='center'>Link hiện tại</th>
                    <th className='center'>Thời gian bắt đầu Session</th>
                    <th className='center'>Địa chỉ IP</th>
                    <th className='center'>Tên người dùng</th>
                    <th className='center'>Thông tin thiết bị</th>
                </tr>
            </thead>
        )
    }

    function _renderBody() {
        return datas && datas.length > 0
            ? <tbody>
                {
                    datas.map((item, i) => {
                        return <tr key={i} >
                            <td>{item.sessionId}</td>
                            <td>{item.startTime}</td>
                            <td>{item.preLink}</td>
                            <td>{item.curLink}</td>
                            <td>{item.sessionStartTime}</td>
                            <td>{item.ipInfo}</td>
                            <td>{item.userName}</td>
                            <td>{item.deviceInfo}</td>
                        </tr>
                    })
                }
            </tbody>
            : <tbody><tr><td colSpan='8' className='center'>Không có dữ liệu</td></tr></tbody>
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={'Đang tải dữ liệu...'} />
            <CommonTable
                data={datas}
                paging={paging}
                searchBasic
                renderAction={_renderAction}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={getDatas}
            />
        </React.Fragment>
    );
}
