import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import Loading from "../based/Loading";
import ReupServices from "../based/services/ReupServices";
import AddOrUpdateAppInFolder from "./comps/AddOrUpdateAppInFolder";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

const AppInFolderMangement = () => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [showModalEditing, setShowModalEditing] = useState(false);
    const [selectId, setSelectId] = useState(0);

    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await ReupServices.GetFolderIfAppExist(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>Tên Slot</th>
                    <th>Tên thiết bị</th>
                    <th>Tên app và tọa độ</th>
                    <th>Vị trí page</th>
                    <th>Note</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {" "}
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{item.folderName}</td>
                                <td>{item.deviceName}</td>
                                <td style={{ width: 350, textAlign: "center" }}>
                                    {item.apps.map((app, index) => {
                                        return (
                                            <div className="row-app" key={index}>
                                                <span className="w-25">{app.appName}</span>
                                                <span className="w-25">X: {app.x}</span>
                                                <span className="w-25">Y: {app.y}</span>
                                                {app.thumbnail ? <img className="w-25" src={app.thumbnail}></img> : ""}
                                            </div>
                                        );
                                    })}
                                </td>
                                <td style={{ width: 150, textAlign: "center" }}>{item.positionPage}</td>
                                <td style={{ width: 250, textAlign: "center" }}>{item.note}</td>
                                <td style={{ width: 150, textAlign: "center" }}>
                                    <button
                                        className="btn btn-custom m-1"
                                        onClick={() => {
                                            setSelectId(item.id);
                                            setShowModalEditing(true);
                                        }}
                                    >
                                        Sửa
                                    </button>
                                    <button className="btn btn-danger m-1" onClick={() => {}}>
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {
        return (
            <button
                className="btn btn-custom mb-3"
                onClick={() => {
                    setSelectId(0);
                    setShowModalEditing(true);
                }}
            >
                Thêm
            </button>
        );
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
            {showModalEditing && (
                <AddOrUpdateAppInFolder
                    isShowModal={showModalEditing}
                    onClose={() => {
                        setShowModalEditing(false);
                        setSelectId(0);
                        getData({ ...paging });
                    }}
                    onSaveContinue={() => {
                        setShowModalEditing(false);
                        getData({ ...paging });
                    }}
                    id={selectId}
                    setSelectId={setSelectId}
                />
            )}
        </StyleBody>
    );
};
const StyleBody = styled.div`
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }
    .row-app {
        display: flex;
        width: 100%;
        .w-50 {
            width: 50%;
            min-width: 50%;
            max-width: 50%;
        }
        .w-25 {
            width: 25%;
            min-width: 25%;
            max-width: 25%;
        }
    }
    .box-coordinate {
        display: flex;
        width: 100%;
        align-items: center;

        .w-50 {
            width: 50%;
            display: block;
            min-width: 50%;
            max-width: 50%;
            position: relative;
            padding: 5px;

            input {
                width: 100%;
                border: none;
                outline: none;
                height: 40px;
                background: #ebebeb;
                padding-left: 20px;
            }
            span {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
            }
        }
    }
`;
export default AppInFolderMangement;
