import React from "react";
import { ProductVariantExtendsModel } from "../models/ProductVariantExtendsModel";
export class WarehouseInfoModel {
  constructor() {
    this.id = 0;
    this.slotIndex = 0;
    this.address = "";
    this.expectedTime = new Date();
    this.orderCode = "";
    this.importTime = new Date();
  }
}

export class CustomerInfoModel {
  constructor() {
    this.id = 0;
    this.email = "";
    this.phoneNumber = "";
    this.address = "";
    this.fullName = "";
  }
}

export class WarehouseProductModel {
  constructor() {
    this.productType = 0;
    this.productId = 0;
    this.productVariantId = 0;
    this.sellerSku = "";
    this.barCode = "";
    this.productName = "";
    this.quantity = 0;
    this.price = 0;
    this.defectiveQuantity = 0;
    this.brokenQuantity = 0;
    this.slotCode = "";
    this.productStatus = 0;
    this.slotOptions = [];
    this.slotLength = 0;

    this.batchCode = "";
    this.productVariantExtends = new ProductVariantExtendsModel();
    this.batchCodeOptions = [];
  }
}

export class StockOutContextModel {
  constructor() {
    this.warehouseInfo = new WarehouseInfoModel();
    this.setWarehouseInfo = setWarehouseInfo;
    this.customerInfo = new CustomerInfoModel();
    this.setCustomerInfo = setCustomerInfo;
    this.products = [new WarehouseProductModel()];
    this.setProducts = setProducts;
    this.platformOrderPackages = [new PlatformOrderPackageModel()];
    this.setPlatformOrderPackages = setPlatformOrderPackages;
    this.transactionInfo = new OrderModel();
    this.setTransactionInfo = setTransactionInfo;
  }
}

export class PlatformOrderPackageModel {
  constructor() {
    this.orderId = 0;
    this.packageId = "";
    this.items = [null];
    this.totalItems = 0;
    this.orderNr = "";
    this.trackingNumber = "";
    this.invoiceNumber = 0;
    this.shipmentProvider = null;
    this.firstMileName = "";
    this.firstMileType = "";
    this.sellerSkuList = [null];
    this.warehouseCode = "";
  }
}

export class OrderModel {
  constructor() {
    this.id = 0;
    this.platformName = "";
    this.customerName = "";
    this.productName = "";
    this.price = 0;
    this.quantity = 0;
    this.discount = "";
    this.finallyPrice = "";
    this.flashSaleId = 0;
    this.shippingCompanyName = "";
    this.totalPrice = "";
    this.totalDiscount = "";
    this.finallyPrice = "";
    this.paymentMethodName = "";
    this.statusName = "";
    this.shipmentInfo = "";
    this.platform = "";
    this.store = "";
    this.promisedShippingTime = "";
    this.statusName = "";
    this.shopName = "";
  }
}

/**
 * Set warehouse info model
 * @param {WarehouseInfoModel} warehouseInfo
 */
function setWarehouseInfo(warehouseInfo) {}

/**
 * Set customer info model
 * @param {CustomerInfoModel} customerInfo
 */
function setCustomerInfo(customerInfo) {}

/**
 * Set list products
 *
 * @param {WarehouseProductModel[]} products
 */
function setProducts(products) {}

/**
 * Set platform order package model
 *
 * @param {PlatformOrderPackageModel[]} platformOrderPackages
 */
function setPlatformOrderPackages(platformOrderPackages) {}

function setTransactionInfo(transactionInfo) {}

export const StockOutContext = React.createContext(new StockOutContextModel());
