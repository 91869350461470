import { v4 as uuid } from 'uuid';
import {
  PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE,
  PROJECT_EDITOR_TEXT_ALIGN_TYPE as TEXT_ALIGN_TYPE,
} from '../../../based/Constants';

const addText = (state) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const newItem = {
    id: uuid(),
    type: ITEM_TYPE.TEXT,
    data: {
      value: '',
      align: TEXT_ALIGN_TYPE.CENTER,
    },
  };

  updatedData.push(newItem);
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const removeText = (state, itemId) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  updatedData.splice(itemIdx, 1);
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const insertText = (state, itemId) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === itemId);
  if (itemIdx < 0) return updatedState;

  const insertItem = {
    id: uuid(),
    type: ITEM_TYPE.TEXT,
    data: {
      value: '',
      align: TEXT_ALIGN_TYPE.CENTER,
    },
  };

  updatedData.splice(itemIdx, 0, insertItem);
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

const setText = (state, { id, value }) => {
  const updatedState = { ...state };
  const updatedItems = { ...updatedState.items };
  const updatedData = [...updatedItems.data];

  const itemIdx = updatedData.findIndex((item) => item.id === id);
  if (itemIdx < 0) return updatedState;

  updatedData[itemIdx].data.value = value;
  updatedItems.data = updatedData;
  updatedState.items = updatedItems;

  return updatedState;
};

export default {
  addText,
  removeText,
  insertText,
  setText,
};
