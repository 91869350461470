import BaseServices from "./BaseServices";

const AppBannerServices = {
    CreateUpdateBanner: async (model) => {
        return await BaseServices.Post("/api/Banner/create-update-banner", model);
    },
    DeteleBanner: async (id) => {
        return await BaseServices.Get(`/api/Banner/delete-banner/${id}`);
    },
    GetBanner: async (page) => {
        return await BaseServices.Get(`/api/Banner/get-banner-by-type/${page}`);
    },

    
};

export default AppBannerServices;

