import React, { useEffect, useState, useMemo } from "react";
import { NOTIFY, CONSTANTS } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import Textbox from "../based/inputs/Textbox";
import TextArea from "../based/inputs/TextArea";
import TextNumber from "../based/inputs/TextNumber";
import CampaignServices from "../based/services/CampaignServices";
import TagsInput from "react-tagsinput";
import TFUDatetime from "../based/inputs/TFUDatetime";
import useFormValidate from "../hooks/useFormValidate";
var moment = require("moment");

export default function MarketerRegisterModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [config, setConfig] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        getBoosting(props.currentBoosting.id);
    }, [props.isShowModal]);

    async function getBoosting(id) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, result] = await CampaignServices.GetCampaignBoostingById(id);
        if (!err && result) {
            if (result.fromDate) result.fromDate = moment(result.fromDate).toDate();
            else result.fromDate = new Date(new Date().setDate(new Date().getDate() + 1));
            if (result.toDate) result.toDate = moment(result.toDate).toDate();
            else result.toDate = new Date(new Date().setDate(new Date().getDate() + 3));
            setConfig(result);
            setIsLoading(false);
            setMsg("");
        } else {
            setConfig({});
            setIsLoading(false);
            setMsg("");
        }
    }
    async function _handleSave() {
        var valid = props.isRegister ? true : isValid();
        if (valid) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu...");
            let [error, data] = props.isRegister ? await CampaignServices.MarketerUnRegisterBoosting(config.id) : await CampaignServices.MarketerRegisterBoosting(config);
            if (!error && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", data);
                props.onSaveSucceed();
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                if (error.errors) displayError(error.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", error.data ? error.data : CONSTANTS.MSG_ERROR);
            }
        }
    }
    function _renderConfigRunBoosting() {
        return (
            <div className='col-sm-12 card-box p-3'>
                <h4 className='header-title m-t-0'>
                    <b>Cấu hình chạy Campain Boosting</b>
                </h4>
                <div className='form-row'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor='fromDate' className='required'>
                                Ngày bắt đầu chạy:
                            </label>
                            <TFUDatetime
                                disabled={props.isRegister || props.isPreview}
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='fromDate'
                                maxDate={config.toDate}
                                value={config.fromDate}
                                onChanged={(newValue) => setConfig({ ...config, fromDate: newValue })}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor='toDate' className='required'>
                                Ngày kết thúc chạy:
                            </label>
                            <TFUDatetime
                                disabled={props.isRegister || props.isPreview}
                                dateFormat='dd/MM/yyyy HH:mm'
                                showTimeSelect
                                required
                                ref={addRef}
                                name='toDate'
                                minDate={config.fromDate}
                                value={config.toDate}
                                onChanged={(newValue) => setConfig({ ...config, toDate: newValue })}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor='budge' className='required'>
                                Ngân sách dự kiến:
                            </label>
                            <TextNumber
                                readOnly={props.isRegister || props.isPreview}
                                required
                                ref={addRef}
                                name='budge'
                                suffix='đồng'
                                className='form-control'
                                value={config.budge}
                                onChanged={(name, value) => setConfig({ ...config, budge: value })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } 
    const _renderCampaignInfo = useMemo(() => (
        <div className='col-sm-12 card-box p-3'>
            <h4 className='header-title m-t-0'>
                <b>Thông tin Campain Boosting</b>
            </h4>
            <div className='form-row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label htmlFor='setName'>Set sản phẩm</label>
                        <Textbox readOnly name='setName' className='form-control font-weight-bold' onChanged={(name, value) => {}} value={config.setName} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='loyaltyAmount'>Tiền bản quyền</label>
                        <TextNumber readOnly name='loyaltyAmount' suffix='đồng' className='form-control' value={config.loyaltyAmount} onChanged={(name, value) => {}} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='referenceLink'>Reference Link</label>
                        <Textbox readOnly name='referenceLink' className='form-control' onChanged={(name, value) => {}} value={config.referenceLink} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='hashTag'>HashTag</label>
                        <TagsInput
                            disabled
                            value={config && config.hashTag && config.hashTag.length > 0 ? config.hashTag : []}
                            focusedClassName={"react-tagsinput--focused-custom"}
                            inputProps={{ className: "react-tagsinput-input-custom", placeholder: "" }}
                            tagProps={{ className: "react-tagsinput-tag-custom" }}
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group form-row'>
                        <div className='col-md-6'>
                            <p className='m-0'>Mockup mặt trước:</p>
                            <img
                                src={props.currentBoosting && props.currentBoosting.mockupFront ? props.currentBoosting.mockupFront : "/images/no-image.png"}
                                alt={config.setName}
                                className='border mt-1'
                                style={{ width: "10rem", height: "10rem", padding: "0.5rem" }}
                            />
                        </div>
                        <div className='col-md-6'>
                            <p className='m-0'>Mockup mặt sau:</p>
                            <img
                                src={props.currentBoosting && props.currentBoosting.mockupBack ? props.currentBoosting.mockupBack : "/images/no-image.png"}
                                alt={config.setName}
                                className='border mt-1'
                                style={{ width: "10rem", height: "10rem", padding: "0.5rem" }}
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='toltalTraffics'>Tổng lượng traffic</label>
                        <TextNumber readOnly name='toltalTraffics' className='form-control' value={props.currentBoosting.toltalTraffics} onChanged={(name, value) => {}} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='toltalTraffics30days'> Tổng traffic trong 30 ngày gần nhất</label>
                        <TextNumber readOnly name='toltalTraffics30days' className='form-control' value={props.currentBoosting.toltalTraffics30days} onChanged={(name, value) => {}} />
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='form-group'>
                        <label htmlFor='reason'>Lý do sẽ bán được mặt hàng này</label>
                        <TextArea readOnly name='reason' className='form-control' onChanged={(name, value) => {}} value={config.reason} />
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <CommonModal
            show={props.isShowModal}
            size='lg'
            isBusy={isLoading}
            busyMsg={msg}
            title='Thông tin Boosting'
            customButton={
                !props.isPreview && (
                    <button className='btn btn-custom btn-sm' onClick={() => _handleSave()}>
                        <i className='fa fa-save m-r-5' /> {props.isRegister ? "Hủy đăng ký" : "Đăng ký"}
                    </button>
                )
            }
            onClose={() => props.onClose()}
        >
            {_renderCampaignInfo}
            {_renderConfigRunBoosting()}
        </CommonModal>
    );
}
