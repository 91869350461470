import React, {useState, useEffect, useRef, useContext} from "react";
import {OrderModel} from "../models/OrderModel";
import TransactionServices from "../based/services/TransactionServices";
import {STATUS_TRANSACTION_NAME} from "../based/Constants";
import LazadaPackageInfo from "./components/LazadaPackageInfo";
import {StockOutContext} from "../context/StockOutContext";
export default function TransactionInfo(props) {
  // const [transactionInfo, setTransactionInfo] = useState(new OrderModel());
  const {transactionInfo,setTransactionInfo} = useContext(StockOutContext)
  const [platformOrderId, setPlatformOrderId] = useState(0);
  const {platformOrderPackages, setPlatformOrderPackages} = useContext(StockOutContext);

  useEffect(() => {
    if (props.transactionId) {
      _getTransactionDetail(props.transactionId.toString());
      _getPlatformId(props.transactionId.toString());
    }
  }, [props.transactionId]);

  async function _getTransactionDetail(transactionId) {
    const [err, data] = await TransactionServices.GetTransactionDetail(transactionId);
    if (!err) {
      setTransactionInfo(data);
    }
  }

  async function _getPlatformId(transactionId) {
    let [error, ageorderTransaction] = await TransactionServices.GetAgeOrderTransactionByTransactionId(transactionId);
    if (!error) {
      setPlatformOrderId(parseInt(ageorderTransaction[0].agencyOrder.orderId));
    }
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12 text-center">
          <h4 className="header-title" style={{margin: "0 0 10px 0"}}>
            <b>Thông tin đơn hàng</b>
          </h4>
        </div>
      </div>
      {transactionInfo != null ? (
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <label className="col-md-4 text-right label-normal">Mã đơn (FIFO)</label>
              <strong className="col-md-8">{transactionInfo.id}</strong>
            </div>
            <div className="row">
              <label className="col-md-4 text-right label-normal">Dự kiến giao</label>
              <strong className="col-md-8">{transactionInfo.promisedShippingTime}</strong>
            </div>
            <div className="row">
              <label className="col-md-4 text-right label-normal">Trạng thái</label>
              <strong className="col-md-8">{transactionInfo.statusName}</strong>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <label className="col-md-4 text-right label-normal">Mã đơn (Sàn)</label>
              <strong className="col-md-8 text-truncate">{transactionInfo.externalTransactionId}</strong>
            </div>
            <div className="row">
              <label className="col-md-4 text-right label-normal">Sàn</label>
              <strong className="col-md-8 text-truncate">{transactionInfo.platformName}</strong>
            </div>
            <div className="row">
              <label className="col-md-4 text-right label-normal">Gian hàng</label>
              <strong className="col-md-8 text-truncate">{transactionInfo.shopName}</strong>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-md-12 text-center">
          <h4 className="header-title" style={{margin: "0 0 10px 0"}}>
            <b>Thông tin vận chuyển</b>
          </h4>
        </div>
      </div>
      {platformOrderId != null && transactionInfo && transactionInfo.platform > 0 && transactionInfo.shopName ? (
        <div className="col-md-12">
          <LazadaPackageInfo
            platformOrderId={platformOrderId}
            request={{platform: transactionInfo.platform, shopAccount: transactionInfo.shopName}}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
}
