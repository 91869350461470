import { ARTWORK_TYPE, INK_COMBINATION, OPTION_POSITION } from "../based/Constants";

export class AgencyArtWorkModel {
    constructor() {
        this.id = 0;
        this.url = "";
        this.artworkType = ARTWORK_TYPE.None;
        this.appSetId = 0;
        this.optionTop = 0;
        this.optionTopOpposite = 0;
        this.inkCombination = INK_COMBINATION.Color;
        this.transparentColors = [];
        this.optionPosition = OPTION_POSITION.PositionA;
        this.thumbnail = "";
        this.isDefaultAvatar = false;
    }
}
