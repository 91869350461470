import React, { useState, useEffect } from 'react';
import { NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import Common from '../based/Common';
import CommonModal from '../based/Modal';
import Textbox from '../based/inputs/Textbox';
import TextArea from '../based/inputs/TextArea';
import CheckBox from '../based/inputs/CheckBox';
import TFUSelect from '../based/inputs/TFUSelect';
import useFormValidate from '../hooks/useFormValidate';
import BankServices from '../based/services/BankServices';

export default function AddOrEditUser(props) {
  const { addRef, isValid } = useFormValidate();

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [msgModalLoading, setMsgModalLoading] = useState(null);
  const [bankAccount, setBankAccount] = useState(props.bankAccount);

  useEffect(() => {
    setBankAccount(props.bankAccount);
  }, [props.isShowModal]);

  const _handleSave = async () => {
    if (isValid()) {
      setIsModalLoading(true);
      setMsgModalLoading('Đang lưu dữ liệu..');
      const [err, data] = await BankServices.UpdateCreatorBankAccounts([bankAccount]);
      if (!err && data) {
        Notify(NOTIFY.SUCCESS, 'Thành công', 'Tạo mới/Cập nhập thành công');
        setIsModalLoading(false);
        setMsgModalLoading(null);
        props.onSaveSucceed();
      }
      else {
        Notify(NOTIFY.ERROR, 'Lỗi', 'Có lỗi xảy ra. Vui lòng thử lại sau ít phút.');
        setIsModalLoading(false);
        setMsgModalLoading(null);
      }
    }
  }

  return (
    <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isModalLoading} busyMsg={msgModalLoading} title={bankAccount.id == 0 ? 'Thêm mới' : 'Cập nhật'} onSave={() => _handleSave()} onClose={() => props.onClose()}>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label htmlFor="bankAccountName" className="required">
            Tên tài khoản:
          </label>
          <Textbox id="bankAccountName" name="bankAccountName" required ref={addRef} minLength={3} maxLength={50} className="form-control" onChanged={(name, value) => setBankAccount({ ...bankAccount, bankAccountName: value })} value={bankAccount.bankAccountName} />
        </div>
        <div className="form-group col-md-12">
          <label htmlFor="bankAccountNumber" className="required">
            Số tài khoản:
          </label>
          <Textbox
            required
            ref={addRef}
            minLength={4}
            maxLength={20}
            id="bankAccountNumber"
            name="bankAccountNumber"
            className="form-control"
            onChanged={(name, value) => setBankAccount({ ...bankAccount, bankAccountNumber: value })}
            value={bankAccount && bankAccount.bankAccountNumber && bankAccount.bankAccountNumber != 0 ? bankAccount.bankAccountNumber.toString() : ''}
          />
        </div>
        <div className="form-group col-md-12">
          <label htmlFor="bankName" className="required">
            Ngân hàng:
          </label>
          <TFUSelect
            name="bankCode"
            noOptionsMessage={() => 'Không có dữ liệu'}
            options={props.banks}
            ref={addRef}
            required
            value={bankAccount.bankCode}
            onChanged={(value) => {
              if (value) setBankAccount({ ...bankAccount, bankCode: value });
              else setBankAccount({ ...bankAccount, bankCode: value, branchCode: null });
            }}
          />
        </div>
        <div className="form-group col-md-12">
          <label htmlFor="remark">
            Ghi chú:
          </label>
          <TextArea
            name="remark"
            ref={addRef}
            value={bankAccount.remark}
            onChanged={(name, value) => setBankAccount({ ...bankAccount, remark: value })}
          />
        </div>
        <div className="form-group col-md-12">
          <div className="form-row">
            <div className="form-group col-md-6 d-flex">
              <label htmlFor="isActive" className="required mr-2">Kích hoạt:</label>
              <CheckBox id="isActive" className="form-control" checked={bankAccount.isActive} onCheckedChange={(name, value) => setBankAccount({ ...bankAccount, isActive: value })} />
            </div>
            <div className="form-group col-md-6 d-flex">
              <label htmlFor="verified" className="required mr-2">Xác thực:</label>
              <CheckBox id="verified" className="form-control" checked={bankAccount.verified} onCheckedChange={(name, value) => setBankAccount({ ...bankAccount, verified: value })} />
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
}
