import BaseServices from "./BaseServices";

const ReupServices = {
    //Devices
    CreateUpdateDevice: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-device", model);
    },
    DeleteDevice: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-device", ids);
    },
    GetDeviceByPage: async (model) => {
        return await BaseServices.Post("/api/Reup/get-device-by-page", model);
    },
    GetDeviceById: async (id) => {
        return await BaseServices.Get(`/api/Reup/get-device-byId/${id}`);
    },
    GetDeviceDropDown: async () => {
        return await BaseServices.Get(`/api/Reup/get-device-drop-down`);
    },

    //Applications
    CreateUpdateApplication: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-application", model);
    },
    DeleteApplication: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-application", ids);
    },
    GetApplicationByPage: async (model) => {
        return await BaseServices.Post("/api/Reup/get-application-by-page", model);
    },
    GetApplicationById: async (id) => {
        return await BaseServices.Get(`/api/Reup/get-application-byId/${id}`);
    },
    GetApplicationDropDown: async () => {
        return await BaseServices.Get(`/api/Reup/get-application-drop-down`);
    },

    //MainAccount
    CreateUpdateMainAccount: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-main-account", model);
    },
    DeleteMainAccount: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-main-account", ids);
    },
    GetMainAccountByPage: async (model) => {
        return await BaseServices.Post("/api/Reup/get-main-account-by-page", model);
    },
    GetMainAccountById: async (id) => {
        return await BaseServices.Get(`/api/Reup/get-main-account-byId/${id}`);
    },
    GetMainAccountDropDown: async () => {
        return await BaseServices.Get(`/api/Reup/get-main-account-drop-down`);
    },

    //Folder
    CreateUpdateFolder: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-folder", model);
    },
    DeleteFolder: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-folder", ids);
    },
    GetFolderByPage: async (model) => {
        return await BaseServices.Post("/api/Reup/get-folder-by-page", model);
    },
    GetFolderById: async (id) => {
        return await BaseServices.Get(`/api/Reup/get-folder-byId/${id}`);
    },
    GetFolderIfAppExist: async (model) => {
        return await BaseServices.Post("/api/Reup/get-folder-if-app-exist", model);
    },
    GetFolderDropDown: async () => {
        return await BaseServices.Get(`/api/Reup/get-folder-drop-down`);
    },
    GetFolderDropDownByDeviceId: async (deviceId) => {
        return await BaseServices.Get(`/api/Reup/get-folder-drop-down-by-device/${deviceId}`);
    },

    //App
    CreateUpdateApp: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-app", model);
    },
    DeleteApp: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-app", ids);
    },
    DeleteAppByFolderId: async (folderId) => {
        return await BaseServices.Get(`/api/Reup/delete-app-byFolderId/${folderId}`);
    },
    GetAppByFolderId: async (folderId) => {
        return await BaseServices.Get(`/api/Reup/get-app-byFolderId/${folderId}`);
    },
    GetAppDropDownByFolderId: async (folderId) => {
        return await BaseServices.Get(`/api/Reup/get-app-drop-down-by-folderId/${folderId}`);
    },

    //AppAccounts
    CreateUpdateAppAccount: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-app-account", model);
    },
    DeleteAppAccount: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-app-account", ids);
    },
    GetAppAccountByPage: async (model) => {
        return await BaseServices.Post("/api/Reup/get-app-account-by-page", model);
    },
    GetAppAccountDropDownByMainAccountId: async (mainAccountId) => {
        return await BaseServices.Get(`/api/Reup/get-app-account-drop-down-by-mainAccountId/${mainAccountId}`);
    },
    GetAppAccountById: async (id) => {
        return await BaseServices.Get(`/api/Reup/get-app-account-byId/${id}`);
    },

    //Script , SubScript
    CreateUpdateScript: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-script", model);
    },
    DeleteScript: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-script", ids);
    },
    GetScriptByPage: async (model, isParent) => {
        return await BaseServices.Post(`/api/Reup/get-script-by-page/${isParent}`, model);
    },
    GetSubScriptDropDown: async () => {
        return await BaseServices.Get(`/api/Reup/get-sub-script-drop-down`);
    },
    GetDropDownScriptParent: async () => {
        return await BaseServices.Get(`/api/Reup/get-script-parent-drop-down`);
    },
    GetScriptParentByAppAcountId: async (appAcountId) => {
        return await BaseServices.Get(`/api/Reup/get-script-parent-by-app-account-id/${appAcountId}`);
    },
    ExportScriptTotalByScriptId: async (scriptId) => {
        return await BaseServices.Get(`/api/Reup/export-script-total-by-scriptId/${scriptId}`);
    },

    //ScriptSteps
    CreateUpdateScriptStep: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-script-step", model);
    },
    DeleteScriptStep: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-script-step", ids);
    },
    GetScriptStepByScriptId: async (scriptId) => {
        return await BaseServices.Get(`/api/Reup/get-script-step-by-scriptId/${scriptId}`);
    },

    //Script Total
    GetScriptTotalByScriptId: async (scriptId) => {
        return await BaseServices.Get(`/api/Reup/get-script-total-by-scriptId/${scriptId}`);
    },
    CreateUpdateScriptTotal: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-script-total", model);
    },
    DeleteScriptTotal: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-script-total", ids);
    },

    //Script Total
    CreateUpdateAppScript: async (model) => {
        return await BaseServices.Post("/api/Reup/create-update-app-script", model);
    },
    DeleteAppScript: async (ids) => {
        return await BaseServices.Post("/api/Reup/delete-app-script", ids);
    },
    DeleteAppScriptByAppId: async (appIds) => {
        return await BaseServices.Post("/api/Reup/delete-app-script-by-appId", appIds);
    },
    GetAppAccountIfXYEXIST: async (model) => {
        return await BaseServices.Post("/api/Reup/get-app-account-if-xy-exist", model);
    },

    UpdateIndexScriptStep: async (model) => {
        return await BaseServices.Post("/api/Reup/update-index-script-step", model);
    },

    UpdateIndexScriptTotal: async (model) => {
        return await BaseServices.Post("/api/Reup/update-index-script-total", model);
    },
};

export default ReupServices;
