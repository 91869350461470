import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import { INITPAGING, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import TFUSelect from "../based/inputs/TFUSelect";
import ProductServices from "../based/services/ProductServices";
import VideoServices from "../based/services/VideoServices";
import { LayerProductSide, LayerType, LayerTypeName, AfterEffectVideoType , JobVideoSex} from "../preset/PresetModel";

const LayerOptions = [
    { value: LayerType.Undefined, label: "--None--" },
    { value: LayerType.Artwork, label: LayerTypeName[LayerType.Artwork] },
    { value: LayerType.ProductImage, label: LayerTypeName[LayerType.ProductImage] },
    { value: LayerType.ProductPrice, label: LayerTypeName[LayerType.ProductPrice] },
    { value: LayerType.ProductName, label: LayerTypeName[LayerType.ProductName] },
    { value: LayerType.AudioAI, label: LayerTypeName[LayerType.AudioAI] },
    { value: LayerType.AffiliateCode, label: LayerTypeName[LayerType.AffiliateCode] },
];

const ProductLayer = [LayerType.Artwork, LayerType.ProductImage, LayerType.ProductPrice, LayerType.ProductName];

const PrintSideOptions = [
    { value: LayerProductSide.Undefined, label: "--None--" },
    { value: LayerProductSide.Front, label: "Mặt trước" },
    { value: LayerProductSide.Back, label: "Mặt sau" },
];

const SexOptions = [
    { value: JobVideoSex.Undefined, label: "--None--" },
    { value: JobVideoSex.Man, label: "Nam" },
    { value: JobVideoSex.Woman, label: "Nữ" },
];

const VideoOptions = [
    { value: AfterEffectVideoType.Reup, label: "Reup" },
    { value: AfterEffectVideoType.LiveStream, label: "LiveStream" },
];

function AfterEffectConfig(props) {
    const id = props.match.params.id;
    const [isBusy, setIsBusy] = useState(false);
    const [model, setModel] = useState({});
    const { afterEffectModel, afterEffectLayers } = model;
    const [productOptions, setProductOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);

    useEffect(() => {
        if (id > 0) loadData();
    }, [id]);


    useEffect(() => {
        loadProduct();
    }, []);

    useEffect(()=>{
        let product = productOptions.find(x => x.value == afterEffectModel.appProductId)
        if(product && product.colors){
            setColorOptions(product.colors)
        }
    },[afterEffectModel, productOptions])

    const loadData = async () => {
        setIsBusy(true);
        let [err, data] = await VideoServices.GetAfterEffectConfigModelById(id);
        if (!err && data) {
            setModel(data);
        }
        setIsBusy(false);
    };

    const loadProduct = async () => {
        setIsBusy(true);
        let [err, data] = await VideoServices.GetProductAndColor();
        if (!err && data) {
            setProductOptions(data);
        }
        setIsBusy(false);
    };

    const handleValue = (name, value, index) => {
        let newModel = { ...model };
        newModel.afterEffectLayers[index][name] = value;
        setModel(newModel);
    };

    const handleSave = async () => {

        if(model.afterEffectModel.videoType >= 0 &&
            (model.afterEffectModel.videoType == AfterEffectVideoType.Reup ||
            (model.afterEffectModel.videoType == AfterEffectVideoType.LiveStream &&
            model.afterEffectModel.width > 0 && model.afterEffectModel.height > 0
            && model.afterEffectModel.appProductId > 0 && model.afterEffectModel.appProductColor &&
            model.afterEffectModel.sex > 0 && model.afterEffectModel.printSide > 0))
            ){

            setIsBusy(true);
            let [err, data] = await VideoServices.UpdateAfterEffectConfigModel(model);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Cập nhật thành công");
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Cập nhật thất bại");
            }
            setIsBusy(false);
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Vui lòng cập nhật đủ thông tin");

        }

      
    };

    return (
        <Wrapper>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Config layer After Effect" />

            <div className="card-box">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name" className="required">
                                Tên video quảng cáo
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={afterEffectModel ? afterEffectModel.name : ""}
                                onChange={(e) => {
                                    setModel({
                                        ...model,
                                        afterEffectModel: {
                                            ...afterEffectModel,
                                            name: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label htmlFor="name" className="required">
                                Loại video
                            </label>
                            <TFUSelect
                                options={VideoOptions}
                                value={afterEffectModel ? afterEffectModel.videoType : AfterEffectVideoType.Undefined}
                                onChanged={(value) => {
                                        setModel({
                                            ...model,
                                            afterEffectModel: {
                                                ...afterEffectModel,
                                                videoType: value,

                                            },
                                        });
                                    
                                    
                                }}
                            />
                        </div>
                    </div>
                </div>
                {afterEffectModel && afterEffectModel.videoType == AfterEffectVideoType.LiveStream ? (
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="type" className="required">
                                    Loại áo
                                </label>
                                <TFUSelect
                                    options={productOptions}
                                    value={afterEffectModel ? afterEffectModel.appProductId : 0}
                                    onChanged={(value) => {
                                        setModel({
                                            ...model,
                                            afterEffectModel: {
                                                ...afterEffectModel,
                                                appProductId: value,
                                                appProductColor: ""
                                            },
                                        });

                                       
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="color" className="required">
                                    Màu sản phẩm
                                </label>
                                <TFUSelect
                                    options={colorOptions}
                                    value={afterEffectModel ? afterEffectModel.appProductColor : ""}
                                    onChanged={(value) => {
                                        setModel({
                                            ...model,
                                            afterEffectModel: {
                                                ...afterEffectModel,
                                                appProductColor: value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="sex" className="required">
                                    Giới tính
                                </label>
                                <TFUSelect
                                    options={SexOptions}
                                    value={afterEffectModel ? afterEffectModel.sex : JobVideoSex.Undefined}
                                    onChanged={(value) => {
                                        setModel({
                                            ...model,
                                            afterEffectModel: {
                                                ...afterEffectModel,
                                                sex: value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="printSide" className="required">
                                    Mặt in
                                </label>
                                <TFUSelect
                                options={PrintSideOptions}
                                value={afterEffectModel ? afterEffectModel.printSide : PrintSideOptions.Undefined}
                                onChanged={(value) => {
                                    setModel({
                                        ...model,
                                        afterEffectModel: {
                                            ...afterEffectModel,
                                            printSide: value,
                                        },
                                    });
                                }}
                            />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="width" className="required">
                                    Width (mm)
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    value={afterEffectModel ? afterEffectModel.width : ""}
                                    onChange={(e) => {
                                        setModel({
                                            ...model,
                                            afterEffectModel: {
                                                ...afterEffectModel,
                                                width: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="height" className="required">
                                    Height (mm)
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    value={afterEffectModel ? afterEffectModel.height : ""}
                                    onChange={(e) => {
                                        setModel({
                                            ...model,
                                            afterEffectModel: {
                                                ...afterEffectModel,
                                                height: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            
                <div className="row">
                    <div className="col-md-10">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Layer</th>
                                    <th>Loại Layer</th>
                                    <th>Sản phẩm</th>
                                    <th>Mặt in</th>
                                </tr>
                            </thead>
                            <tbody>
                                {afterEffectLayers &&
                                    afterEffectLayers.map((item, i) => {
                                        return (
                                            <tr>
                                                <td className="center">{item.layerName}</td>
                                                <td>
                                                    <TFUSelect
                                                        options={LayerOptions}
                                                        value={item.layerType}
                                                        onChanged={(e) => {
                                                            handleValue("layerType", e, i);
                                                        }}
                                                        className="mx-2"
                                                    />
                                                </td>
                                                <td>
                                                    {ProductLayer.indexOf(item.layerType) > -1 && (
                                                        <TFUSelect
                                                            options={productOptions}
                                                            value={item.productId}
                                                            onChanged={(e) => {
                                                                handleValue("productId", e, i);
                                                            }}
                                                            className="mx-2"
                                                        />
                                                    )}
                                                </td>
                                                <td>
                                                    {ProductLayer.indexOf(item.layerType) > -1 && (
                                                        <TFUSelect
                                                            options={PrintSideOptions}
                                                            value={item.printSide}
                                                            onChanged={(e) => {
                                                                handleValue("printSide", e, i);
                                                            }}
                                                            className="mx-2"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                handleSave();
                            }}
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default AfterEffectConfig;

const Wrapper = styled.div`
    .card-box {
        padding: 20px !important;
    }
    .table > tbody > tr > td {
        padding: 0px !important;
        select {
            width: 100%;
            height: 100%;
        }
    }
`;
