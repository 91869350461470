import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Textbox from '../../../based/inputs/Textbox';
import Loading from '../../../based/Loading';
import BaseServices from '../../../based/services/BaseServices';

const PickupProductModal = ({ show, onSelectProduct, onClose }) => {
    const [isBusy, setBusy] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [products, setProducts] = useState([]);
    useEffect(() => {
        if (show)
            _fetchProduct();
    }, [show])

    const _fetchProduct = async () => {
        setBusy(true);
        let url = `/api/setProduct/find-suggest-products?keyword=${keyword}`;
        let [error, data] = await BaseServices.Get(url);
        if (data) {
            setProducts(data);
        } else {
            setProducts([]);
        }
        setBusy(false);
    }
    return (
        <Modal show={show} size={'lg'} enforceFocus={false}>
            <Loading show={isBusy} msg={'Đang tải...'} />
            <Modal.Header>
                <button type='button' className='close' onClick={onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Product pickup</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                <Textbox
                    placeholder='Nhập để tìm kiếm...'
                    id='keyword'
                    name='keyword'
                    onChanged={(name, value) => setKeyword(value)}
                    handleEnter={() => _fetchProduct()}
                    value={keyword}
                />
                <div className='row m-t-10'>
                    {
                        products && products.length > 0 && products.map((item, idx) => {
                            return <div className='col-3 pointer' style={{ border: '1px solid #ccc', padding: 10 }} onClick={() => { setBusy(true); onSelectProduct(item) }}>
                                <img src={item.productAvatar} style={{ width: '100%' }} />
                                <strong>{item.title}</strong>
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PickupProductModal;