import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NOTIFY, PRINT_TYPE_NAME } from "../based/Constants";
import { Notify } from "../based/Notify";
import TFUImage from "../based/inputs/Image";
import CommonModal from "../based/refactor/CommonModal";
import OrderServices from "../based/services/OrderServices";
import { PrintOrderProductModel } from "./element/PrintOrderModel";
import { DesignExtend, ElementableType, PrintProductModel } from "./element/PrintProductModel";

export class DesignModel {
    constructor() {
        this.id = 0;
        this.creatorId = 0;
        this.folderId = 0;
        this.title = "Untitle";
        this.description = "";
        this.public = false;
        this.width = 0;
        this.height = 0;
        this.color = "";
        this.printOrderProducts = [];
        this.designExtends = [];
        this.printType = PRINT_TYPE_NAME.DTG;
        this.isDraft = 1;
        this.tempId = "";
    }
    printOrderProducts = [new PrintOrderProductModel()];
    designExtends = [new DesignExtend()];
}

function CustomizeDetailModal({ show, onClose, orderProductId }) {
    const [busy, setBusy] = useState(false);
    const [designExtends, setDesignExtends] = useState([new DesignExtend()]);
    const [operatorOrderId, setOperatorOrderId] = useState(0);
    const [designOrderId, setDesignOrderId] = useState(0);

    useEffect(() => {
        if (show && orderProductId > 0) {
            _getPrintEditor(orderProductId);
        }
    }, [show]);

    const _getPrintEditor = async (orderProductId) => {
        setBusy(true);
        let [err, data] = await OrderServices.GetDesignExtendByOrderProductId(orderProductId);
        if (!err && data) {
            var { designExtends, operatorOrderId, designOrderId } = data;
            setDesignExtends(designExtends);
            setOperatorOrderId(operatorOrderId);
            setDesignOrderId(designOrderId);
        }
        setBusy(false);
    };

    const _handleEnhance = async () => {
        setBusy(true);
        let listEnhance = [];
        designExtends.forEach((de) => {
            de.elements.forEach((element) => {
                if (element.url && (element.enhanceOrNot || element.enhanceUrl))
                    listEnhance.push({
                        elementId: element.id,
                        sourceUrl: element.url,
                        enhanceUrl: element.enhanceUrl,
                    });
            });
        });
        let [err, data] = await OrderServices.InsertRangeImageEnhance(listEnhance, designOrderId);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu Job thành công");
            onClose(true);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu Job thất bại");
        }
        setBusy(false);
    };

    return (
        <CommonModal
            show={show}
            isBusy={busy}
            busyMsg={"Đang tải"}
            title={`Chi tiết thiết kế`}
            onClose={() => onClose()}
            size="lg"
            customButton={
                <React.Fragment>
                    {operatorOrderId > 0 ? (
                        ""
                    ) : (
                        <React.Fragment>
                            <button className="btn btn-primary" onClick={() => _handleEnhance()} disabled={busy}>
                                Lưu thông tin Enhance vào Job Enhance
                            </button>
                        </React.Fragment>
                    )}
                </React.Fragment>
            }
        >
            <Wrapper>
                <div className="modal-body">
                    <div className="col-12 card-box">
                        <h4 className="header-title">Thông tin thiết kế</h4>
                        <div className="sub-header">
                            - Chọn các sản phẩm muốn enhance chất lượng <br />
                            - Sau khi chọn các sản phẩm muốn enhance chất lượng, click nút "Enhance" để bắt đầu enhance chất lượng <br />
                            - Sau khi enhance xong, hệ thống sẽ tiến hành render lại artwork <br />
                            - Trong quá trình enhance, bạn không thể chuyển trạng thái sản xuất của đơn hàng <br />
                            - Bạn có thể upload hình ảnh enhance bằng tay <br />
                        </div>
                        {designExtends &&
                            designExtends.length > 0 &&
                            designExtends.map((designExtend, index) => {
                                return (
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row align-items-center">
                                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                                                        Artwork
                                                    </label>
                                                    <div className="col-md-10">
                                                        <a href={designExtend.artworkUrl} target="_blank" rel="noopener noreferrer">
                                                            {designExtend.artworkUrl}
                                                        </a>
                                                    </div>
                                                </div>
                                                <table className="table table-bordered m-b-0 focus-on">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Element Id</th>
                                                            <th className="text-center">Source Name</th>
                                                            <th className="text-center">Source Url</th>
                                                            <th className="text-center">EnhanceUrl Url</th>
                                                            <th className="text-center">Enhance bằng hệ thống</th>
                                                            <th className="text-center">Enhance tay</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {designExtend.elements &&
                                                            designExtend.elements.length > 0 &&
                                                            designExtend.elements.map((element, idx) => {
                                                                if (element.elementableType == ElementableType.Image && element.url) {
                                                                    return (
                                                                        <tr>
                                                                            <td className="vertical-align-middle text-center">{element.id}</td>
                                                                            <td className="vertical-align-middle text-center">
                                                                                <p>{element.thumbName}</p>
                                                                                <p>
                                                                                    Đường dẫn:
                                                                                    <a href={element.url} target="_blank" rel="noopener noreferrer">
                                                                                        {element.url}
                                                                                    </a>
                                                                                </p>
                                                                            </td>
                                                                            <td className="vertical-align-middle text-center">
                                                                                <img
                                                                                    src={element.thumbUrl}
                                                                                    alt=""
                                                                                    width="100"
                                                                                    onClick={() => {
                                                                                        window.open(element.url, "_blank");
                                                                                    }}
                                                                                    className="cursor-pointer"
                                                                                />
                                                                            </td>
                                                                            <td className="vertical-align-middle text-center">
                                                                                <img
                                                                                    src={element.enhanceUrl}
                                                                                    alt=""
                                                                                    width="100"
                                                                                    onClick={() => {
                                                                                        window.open(element.enhanceUrl, "_blank");
                                                                                    }}
                                                                                    className="cursor-pointer"
                                                                                />
                                                                            </td>
                                                                            <td className="vertical-align-middle text-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={element.enhanceOrNot}
                                                                                    onChange={(e) => {
                                                                                        let val = e.target.checked;
                                                                                        let de = [...designExtends];
                                                                                        let selectedDe = de[index];
                                                                                        let element = selectedDe.elements[idx];
                                                                                        element.enhanceOrNot = val;
                                                                                        if (val) element.enhanceUrl = "";
                                                                                        setDesignExtends(de);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td className="vertical-align-middle text-center">
                                                                                <TFUImage
                                                                                    maximumSize={50}
                                                                                    image={element.enhanceUrl}
                                                                                    onChanged={(name, value) => {
                                                                                        let de = [...designExtends];
                                                                                        let selectedDe = de[index];
                                                                                        let element = selectedDe.elements[idx];
                                                                                        element.enhanceUrl = value;
                                                                                        if (value) element.enhanceOrNot = false;
                                                                                        setDesignExtends(de);
                                                                                    }}
                                                                                    name="preview-image"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default CustomizeDetailModal;

const Wrapper = styled.div`
    .preview {
        display: none;
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
        }
    }
    .d-none {
        display: none;
    }
`;
