import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Breadcrumbs from '../based/Breadcrumbs';
import { CONSTANTS, MAINMENU, NOTIFY } from '../based/Constants';
import TextNumber from '../based/inputs/TextNumber';
import Language from '../based/Language';
import Loading from '../based/Loading';
import { Notify } from '../based/Notify';
import PrintServices from '../based/services/PrintServices';
import useFormValidate from '../hooks/useFormValidate';
import { Layout } from '../layout/Layout';
import { PrintRateCardConfigModel } from '../models/PrintRateCardConfigModel';
import PubSub from 'pubsub-js'

export default function PrintRateCardSetting(props) {
  const [loading, setLoading] = useState({ isLoading: false, msgLoading: 'Đang tải dữ liệu...' });
  const [printRateCard, setPrintRateCard] = useState([new PrintRateCardConfigModel()]);
  const { addRef, isValid } = useFormValidate();

  useEffect(() => {
    getData();
    PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.WebSetting });
  }, []);

  const getData = async () => {
    setLoading({ isLoading: true, msgLoading: 'Đang tải dữ liệu...' });
    const [err, data] = await PrintServices.ReadRateCard();
    if (!err && data) {
      setLoading({ isLoading: false });
      setPrintRateCard(data);
    } else {
      setLoading({ isLoading: false });
      Notify(NOTIFY.ERROR, 'Lỗi', 'Có lỗi: ' + err.message);
    }
  };

  const handleInputChange = (name, value, idx) => {
    let rateCard = [...printRateCard];
    let current = rateCard[idx];
    current[name] = value;
    setPrintRateCard(rateCard);
  };

  const handleSave = async () => {
    if (isValid()) {
      setLoading({ isLoading: true, msgLoading: 'Đang lưu dữ liệu...' });
      const [err, data] = await PrintServices.CreateUpdateRateCard([...printRateCard]);
      if (!err && data) {
        setLoading({ isLoading: false });
        Notify(NOTIFY.SUCCESS, 'Thành công', 'Lưu thành công.');
      } else {
        setLoading({ isLoading: false });
        Notify(NOTIFY.WARNING, 'Có lỗi', 'Lưu thất bại.');
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumbs currentPage=" Cấu hình mức chiết khấu (Loyalty RateCard)" />
      <Loading show={loading.isLoading} msg={loading.msgLoading} />
      <div className="row">
        <div className="col-sm-6 pr-1">
          <div className="card-box p-3">
            <div className="form-group row">
              <div className="col-md-6">
                <label htmlFor="dtgColorCost">Loyalty Amount (đồng):</label>
              </div>
              <div className="col-md-6">
                <label htmlFor="dtgWhiteCost">Ranus Operation Fee (%):</label>
              </div>
            </div>

            {printRateCard.length > 0
              ? printRateCard.map((item, idx) => {
                  return (
                    <div className="form-group row">
                      <div className="col-md-6">
                        <TextNumber id="loyaltyAmount" suffix="đồng" name="loyaltyAmount" className="form-control" value={item.loyaltyAmount} onChanged={(name, value) => handleInputChange(name, value, idx)} />
                      </div>
                      <div className="col-md-6">
                        <TextNumber id="lotusChargePercent" suffix="%" name="lotusChargePercent" className="form-control" value={item.lotusChargePercent} onChanged={(name, value) => handleInputChange(name, value, idx)} />
                      </div>
                    </div>
                  );
                })
              : ''}

            <div className="footer-form-question">
              <Button variant="primary" onClick={handleSave}>
                <i className="fa fa-save m-r-6" />
                {Language.getValue('common.btnSave')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
