import {PRODUCT_STATUS, PRODUCT_STATUS_TYPE} from "../based/Constants";
export class WareslotProductsModel {
  constructor() {
    this.id = 0;
    this.warehouseId = 0;
    this.slotCode = "";
    this.productVariantId = 0;
    this.productStatus = PRODUCT_STATUS_TYPE.Good;
    this.sellableQuantity = 0;
  }
}
