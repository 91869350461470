import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from 'react';
import ReactSelect from 'react-select';
import Moment from 'moment';
import TFUSelect from '../../based/inputs/TFUSelect';
import TextNumber from '../../based/inputs/TextNumber';
import WarehouseServices from '../../based/services/WarehouseServices';
import StockOutServices from '../../based/services/StockOutServices';
import { StockOutContext } from '../../context/StockOutContext';
import { PRODUCT_STATUS_TYPE, STOCK_IN_STATUS, PRODUCT_STATUS, PRODUCT_TYPE, NOTIFY } from '../../based/Constants';
import ProductServices from '../../based/services/ProductServices';
import { Notify } from '../../based/Notify';
import TFUTooltip from '../../based/TFUTooltip';

const initMinMax = { min: 0, max: 0 };
const statusOptions = Object.entries(PRODUCT_STATUS).map(([key, values]) => {
  return {
    value: PRODUCT_STATUS_TYPE[key],
    label: values,
    text: values,
  };
});

const StockOutModalItem = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({ displayError, validateInput, props }));

  const { index, readOnly, handlePropertyChanged, orderStatusId } = props;
  const [product, setProduct] = useState(props.product);
  const [wareslots, setWareslots] = useState([]);
  const [batchCodeOptions, setBatchCodeOptions] = useState([]);
  const [availableQuantity, setAvailbleQuantity] = useState(0);
  const [extendDataOptions, setExtendDataOptions] = useState([]);
  const [minMax, setMinMax] = useState(initMinMax);
  const [valid, setValid] = useState({ isValid: true, msgInvalid: '' });
  const [isValidExtendData, setIsValidExtendData] = useState(true);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    let product = props.product;
    let productId = product ? product.productId : 0;
    let warehouseId = product ? product.warehouseId : 0;
    if (productId > 0) {
      setProduct(product);
      _getListSlotProduct(warehouseId, product);

      if (orderStatusId == STOCK_IN_STATUS.Approved) _getApprovedQuantity(productId, product.productStatus, props.orderId, product.batchCode);

      if (orderStatusId != STOCK_IN_STATUS.PendingApproval) _renderOptionsBatchCode(product);
    }
    if (warehouseId > 0) _renderOptionsTFUSelect(warehouseId);
  }, [props.product]);

  useEffect(() => {
    if (orderStatusId == STOCK_IN_STATUS.Approved) _getExtendDataOptions(product.batchCode);
  }, [product.batchCode]);

  const displayError = (msg) => {
    setValid({ isValid: false, msgInvalid: msg });
    setIsValidExtendData(false);
    handlePropertyChanged(index, 'isExpandExtendData', true);
  };

  const validateInput = () => {
    if (product.hasImei || product.hasSerialNumber) {
      if (product.extendData.length !== product.quantity) return 'Cần bổ sung thông tin sản phẩm.';
    } else if (product.hasExpDate) {
      if (product.quantity > 0 && product.extendData.length === 0) return 'Cần bổ sung thông tin sản phẩm.';
    }
    return '';
  };

  const _getApprovedQuantity = async (productId, productStatus, orderId, batchCode) => {
    const [error, res] = await StockOutServices.GetApprovedQuantity(productId, productStatus, orderId, batchCode);
    if (!error && res) {
      setAvailbleQuantity(res);
    } else {
      setAvailbleQuantity(0);
    }
  };

  const _getExtendDataOptions = async (batchCode) => {
    if (batchCode && product.hasExtendData) {
      const [err, resExtendDataOptions] = await StockOutServices.GetExtendDataOptions(batchCode);
      if (!err && resExtendDataOptions && resExtendDataOptions.length > 0) {
        setExtendDataOptions(resExtendDataOptions);
      }
    } else setExtendDataOptions([]);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  // Lấy danh sách những slot có chứa sản phẩm và sellable quantity > 0
  async function _getListSlotProduct(warehouseId, product) {
    const [err, data] = await WarehouseServices.GetSlotsContainingProduct(warehouseId, product.productId);
    if (!err && data && data.length > 0) {
      // Số lượng sản phẩm có thể xuất trong kho hiện tại (max)
      let slot = data.find((x) => x.batchCode == product.batchCode);

      // Nếu số lượng sản phẩm còn trong kho bằng 0 thì số lượng có thể xuất chính là số lượng sản phẩm hiện tại trong đơn
      setAvailbleQuantity(slot && slot.sellable > 0 ? (orderStatusId == STOCK_IN_STATUS.Approved ? product.quantity : slot.sellable) : product.quantity);

      // Lọc danh sách slot chứa sản phẩm hiện tại vào dropdown (có thể trùng)
      let slotOptions = data.map((s) => ({ value: s.slotCode, text: s.slotCode, label: s.slotCode }));

      // Lọc để lấy danh sách các slot không trùng nhau
      let uniqueOptions = [...new Set(slotOptions.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
      setWareslots(uniqueOptions);
    } else {
      setWareslots([]);
    }
    return true;
  }

  async function _renderOptionsTFUSelect(warehouseId) {
    let [err, data] = await ProductServices.GetAvaiDropProductsByWhId(warehouseId);
    let options = [...new Set(data.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
    //Nếu đơn đã xác nhận thì dropdown lúc này chỉ gồm những sản phẩm lúc xác nhận
    if (orderStatusId == STOCK_IN_STATUS.Approved) {
      let productIds = products.map((i) => i.productId);
      let productList = options.filter((i) => productIds.includes(+i.value.split('_')[1]));
      const formatedOptions = productList.map((x) => ({
        ...x,
        value: x.value.split('_')[1],
      }));
      setProductOptions(formatedOptions);
    } else {
      const formatedOptions = options.map((x) => ({
        ...x,
        value: x.value.split('_')[1],
      }));
      setProductOptions(formatedOptions);
    }
  }

  async function _renderOptionsBatchCode(product) {
    let obj = {
      warehouseId: product.warehouseId,
      productId: product.productId,
      slotCode: product.slotCode,
      productStatus: product.productStatus,
    };
    let [err, data] = await StockOutServices.GetBatchCodeList(obj);
    if (!err && data) {
      setBatchCodeOptions(data);
    } else {
      setBatchCodeOptions([]);
    }
  }

  const { products, setProducts } = useContext(StockOutContext);

  async function _handleSelectProduct(value) {
    let index = props.index;
    let product = products[index];
    if (value) {
      if (product) {
        let [err, data] = await WarehouseServices.GetProductDetails(value);
        if (!err && data) {
          product.productId = data.productId;
          product.productName = data.productName;
          product.sellerSku = data.sellerSku;
          product.price = data.price;
          product.barcode = data.barcode;
          product.orderId = props.orderId;
          product.warehouseId = props.warehouseId;
          product.productType = PRODUCT_TYPE.PRODUCT_VARIANT;
        } else {
          product.productId = 0;
          product.productName = '';
          product.sellerSku = '';
          product.price = 0;
          product.barcode = '';
          product.productType = null;
        }
      }
    } else {
      product.productId = 0;
      product.productName = '';
      product.sellerSku = '';
      product.price = 0;
      product.barcode = '';
      product.productType = null;
    }
    _getListSlotProduct(props.warehouseId, product);
    _renderOptionsBatchCode(product);
    setProducts([...products]);
  }

  const renderComplementExtendData = () => {
    if (product.isExpandExtendData && product.hasExtendData && product.quantity > 0 && props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess) {
      var complementExtendDataElement = [];
      var placeholderArr = [];
      if (product.hasImei) placeholderArr.push('Imei');
      if (product.hasSerialNumber) placeholderArr.push('SerialNumber');
      if (product.hasExpDate) placeholderArr.push('ExpiredDate');
      var placeholder = `--Chọn ${placeholderArr.join('/')}--`;

      if (!product.hasImei && !product.hasSerialNumber) {
        var listOption = [];
        extendDataOptions.forEach((x) => {
          for (var i = 0; i < x.quantity; i++) {
            listOption.push({ ...x, quantity: 1, value: i, label: `[${Moment(x.manufacturingDate).format('DD.MM.yyyy')}-${Moment(x.expiredDate).format('DD.MM.yyyy')}]` });
          }
        });

        complementExtendDataElement.push(
          <tr key="all">
            <td />
            <td colSpan="5">
              <ReactSelect
                classNamePrefix={`${isValidExtendData ? '' : 'alert-danger '}react-select`}
                value={product.extendData}
                onChange={(selected) => {
                  setIsValidExtendData(true);
                  if (selected.length <= product.quantity) handlePropertyChanged(index, 'extendData', selected);
                }}
                options={listOption}
                noOptionsMessage={() => 'Không có dữ liệu'}
                isClearable={true}
                isMulti={true}
                placeholder={placeholder}
              />
              {isValidExtendData ? '' : <label className="text-danger full-width">Cần chọn bổ sung thông tin sản phẩm</label>}
            </td>
          </tr>
        );
      } else {
        var listOption = extendDataOptions.map((x) => {
          var labelArr = [];
          if (product.hasImei) labelArr.push(x.imei);
          if (product.hasSerialNumber) labelArr.push(x.serialNumber);
          if (product.hasExpDate) labelArr.push(`[${Moment(x.manufacturingDate).format('DD.MM.yyyy')}-${Moment(x.expiredDate).format('DD.MM.yyyy')}]`);
          return { ...x, quantity: 1, value: x.id, label: labelArr.join(' / ') };
        });

        complementExtendDataElement.push(
          <tr key="all">
            <td />
            <td colSpan="5">
              <ReactSelect
                classNamePrefix={`${isValidExtendData ? '' : 'alert-danger '}react-select`}
                value={product.extendData}
                onChange={(selected) => {
                  setIsValidExtendData(true);
                  if (selected != null) {
                    if (selected.length <= product.quantity) {
                      handlePropertyChanged(index, 'extendData', selected);
                    } else {
                      selected.length = product.quantity;
                      handlePropertyChanged(index, 'extendData', selected);
                      Notify(NOTIFY.WARNING, 'Cảnh báo', `Đã chọn đủ ${product.quantity} sản phẩm!`);
                    }
                  } else {
                    handlePropertyChanged(index, 'extendData', []);
                  }
                }}
                options={listOption}
                noOptionsMessage={() => 'Không có dữ liệu'}
                isClearable={true}
                isMulti={true}
                placeholder={placeholder}
              />
              {isValidExtendData ? '' : <label className="text-danger full-width">Cần chọn bổ sung thông tin sản phẩm</label>}
            </td>
          </tr>
        );
      }
      return complementExtendDataElement;
    } else return '';
  };

  return (
    <React.Fragment>
      <tr id={`products[${index}]`}>
        {orderStatusId != STOCK_IN_STATUS.PendingApproval ? (
          <td className="text-left">
            <TFUSelect
              id={`batchCode_${index}`}
              name={`batchCode_${index}`}
              value={product.batchCode}
              onChanged={(value) => {
                setExtendDataOptions([]);
                handlePropertyChanged(index, 'extendData', []);
                handlePropertyChanged(index, 'batchCode', value);
                handlePropertyChanged(index, 'quantity', 0);
                _getListSlotProduct(props.warehouseId, product);
              }}
              options={batchCodeOptions}
              isDisabled={readOnly}
              required
              isClearable={false}
            />
          </td>
        ) : (
          <td className="text-center">{product.batchCode}</td>
        )}
        <td className="text-center">
          <div className="form-row">
            <span className="status__active col-md-2">{product.isMatchingScan ? <i className="fa fa-check"></i> : null}</span>
            <span className="col-md-10">{product.barcode}</span>
          </div>
        </td>
        {/* <td className="text-center">{product.isAdded && product.isAdded == true ? <TFUSelect id="sellerSku" name="sellerSku" value={product.productId} options={productOptions} onChanged={(value) => props.handleSelectProduct(value, index)} /> : product.sellerSku}</td> */}
        {product.isAdded ? (
          <td>
            <TFUSelect id={`products[${props.index}].ProductId`} name={`products[${props.index}].ProductId`} options={productOptions} value={`${product.productType}_${product.productId}`} placeholder="--Chọn sản phẩm--" onChanged={_handleSelectProduct} ref={props.addRef} />
          </td>
        ) : (
          <td className="text-center">{product.sellerSku}</td>
        )}
        <td className="text-center">{product.productName}</td>
        <td className="text-right d-flex flex-column align-items-center">
          <TextNumber id={`quantity_${index}`} name="quantity" value={product.quantity ? product.quantity : 0} onChanged={(name, value) => handlePropertyChanged(index, name, value)} displayType={props.readOnly ? 'text' : 'input'} min={availableQuantity ? 1 : 0} max={availableQuantity} />
          {orderStatusId == STOCK_IN_STATUS.Approved || orderStatusId == STOCK_IN_STATUS.RejectApproval ? (
            availableQuantity > 0 ? (
              <em>
                số lượng {orderStatusId == STOCK_IN_STATUS.Approved ? 'tối đa' : 'tồn kho'} là {availableQuantity}
              </em>
            ) : (
              <em>không có sản phẩm</em>
            )
          ) : null}
        </td>
        <td className="text-right">
          <TextNumber id={`price_${index}`} displayType={readOnly ? 'text' : 'input'} price name="price" value={product.price ? +product.price : 0} onChanged={(name, value) => handlePropertyChanged(index, name, value)} />
        </td>
        <td className="text-center ">
          <TFUSelect
            value={product.slotCode != '' ? product.slotCode : -1}
            placeholder="--Vị trí--"
            onChanged={(id) => {
              setExtendDataOptions([]);
              handlePropertyChanged(index, 'extendData', []);
              handlePropertyChanged(index, 'slotCode', id);
              _getListSlotProduct(props.product.warehouseId, props.product);
              let newProduct = { ...product };
              newProduct.slotCode = id;
              _renderOptionsBatchCode(newProduct);
            }}
            options={wareslots}
            isDisabled={readOnly}
          />
        </td>
        <td className="text-center">
          <TFUSelect
            name="productStatus"
            value={product.productStatus}
            placeholder="--Trạng thái--"
            onChanged={(productStatus) => {
              setExtendDataOptions([]);
              handlePropertyChanged(index, 'extendData', []);
              handlePropertyChanged(index, 'productStatus', productStatus);
              let newProduct = { ...product };
              newProduct.productStatus = productStatus;
              _renderOptionsBatchCode(newProduct);
            }}
            options={statusOptions}
            isDisabled={readOnly}
          />
        </td>
        <td className="text-center">
          <button className={props.readOnly ? 'btn btn-sm mx-1 btn-danger disable' : 'btn btn-sm mx-1 btn-danger'} onClick={() => props.handleRemoveProduct(props.index)}>
            <i className="fa fa-trash-o" />
          </button>
          {product.hasExtendData && props.orderBtnStatus == STOCK_IN_STATUS.InboundingProcess ? (
            <button type="button" className="btn btn-sm mx-1 btn-info" onClick={() => handlePropertyChanged(index, 'isExpandExtendData', !product.isExpandExtendData)}>
              <i className={product.isExpandExtendData ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'} />
            </button>
          ) : (
            ''
          )}
          {valid.isValid ? (
            ''
          ) : (
            <TFUTooltip position="top" content={valid.msgInvalid} target={`products[${index}]`}>
              <i className="fa fa-info-circle text-danger m-l-5" />
            </TFUTooltip>
          )}
        </td>
      </tr>
      {renderComplementExtendData()}
    </React.Fragment>
  );
});

export default StockOutModalItem;
