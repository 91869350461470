import React, { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import WarehouseModal from '../warehouse/WarehouseModal';
import { WarehouseModel } from '../models/WarehouseOrderModel';
import { WarehouseInfoProps } from '../based/PropsDefined';
import { StockInContext, WarehouseInfoModel } from '../context/StockInContext';
import Selection from '../based/inputs/Selection';
import WarehouseServices from '../based/services/WarehouseServices';

/**
 * Warehouse basic info
 * @param {WarehouseInfoProps} props The warehouse info props
 */
export default function WarehouseInfo(props) {
  const [warehouses, setWarehouses] = useState([]);
  const [slots, setSlots] = useState([]);
  const [isShowWarehouseModal, setIsShowWarehouseModal] = useState(false);
  const { warehouseInfo, setWarehouseInfo } = useContext(StockInContext);

  useEffect(() => {
    _getWarehouses();
  }, []);

  async function _getWarehouses() {
    let [err, data] = await WarehouseServices.GetWarehouseDropdown();
    if (!err) setWarehouses(data);
    else setWarehouses([]);
  }

  async function _handleSelectedWarehouse(id) {
    if (id > 0) {
      const [err, data] = await WarehouseServices.GetWarehouseById(id);
      if (!err) {
        let address = data.location;
        setWarehouseInfo({ ...warehouseInfo, id: id, address: address });
        if (data.wareslot) {
          let slotOptions = data.wareslot
            .filter((slot) => slot.isActive)
            .map((slot) => {
              return { value: slot.code, label: slot.code };
            });
          setSlots(slotOptions);
        }
      } else setWarehouseInfo(new WarehouseInfoModel());
    } else setWarehouseInfo(new WarehouseInfoModel());
  }

  function _handleChangeDate(date, name) {
    if (date != null) {
      setWarehouseInfo({ ...warehouseInfo, expectedTime: date });
    }
  }

  function _handleAddWarehouseModal() {
    setIsShowWarehouseModal(true);
  }

  function _handleSaveWarehouseSucceeded(warehouse) {
    setIsShowWarehouseModal(false);
    _getWarehouses();
  }

  function _handleSelectedSlot(slot) {
    setWarehouseInfo({ ...warehouseInfo, slotCode: slot });
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="header-title font-weight-bold">Thông tin cơ bản</h4>
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="name" className="col-form-label required">
              Kho
            </label>
            <div className="row">
              <div className="col-md-10">
                <Selection id="warehouseInfoId" name="warehouseInfoId" value={warehouseInfo.id} required ref={props.addRef} placeholder="---Chọn kho---" onChanged={_handleSelectedWarehouse} options={warehouses} />
              </div>
              <div className="col-md-2">
                <button className="btn btn-custom btn-sm" onClick={_handleAddWarehouseModal} title="Thêm kho">
                  <i className="fa fa-lg fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="name" className="col-form-label required">
              Vị trí lưu kho
            </label>
            <Selection id="warehouseInfoSlotCode" name="warehouseInfoSlotCode" value={warehouseInfo.slotCode} required ref={props.addRef} placeholder="---Chọn Vị trí---" onChanged={_handleSelectedSlot} options={slots} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="warehouseAddress" className="col-form-label">
              Địa chỉ kho
            </label>
            <input className="form-control" id="warehouseAddress" name="warehouseAddress" value={warehouseInfo.address} readOnly />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="startTime" className="col-form-label">
              Thời gian dự kiến nhập hàng
            </label>
            <div className="row">
              <div className="col-md-10">
                <DatePicker
                  onChange={(date) => _handleChangeDate(date, 'startTime')}
                  selected={warehouseInfo.expectedTime}
                  adjustDateOnChange
                  locale={vi}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  showTimeSelect
                  timeCaption="time"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy HH:mm"
                  dropdownMode="select"
                  popperPlacement="auto"
                  className="form-control"
                />
              </div>
            </div>
            <WarehouseModal warehouse={new WarehouseModel()} isShowModal={isShowWarehouseModal} onClose={() => setIsShowWarehouseModal(false)} onSaveSucceed={(data) => _handleSaveWarehouseSucceeded(data)} />
          </div>
        </div>
      </div>
    </div>
  );
}
