import BaseServices from './BaseServices';

const CreditServices = {
  GetBankTransfers: async (paging) => {
    return await BaseServices.Post('/api/Credit/get-bank-transfers', paging);
  },
  CreateBankTransfer: async (obj) => {
    return await BaseServices.Post(`/api/Credit/create-bank-transfer`, obj);
  },
  RemoveBankTransfers: async (ids) => {
    return await BaseServices.Post(`/api/Credit/remove-bank-transfer`, ids);
  },
  ApprovedBankTransfer: async (obj) => {
    return await BaseServices.Post(`/api/Credit/approved-bank-transfer`, obj);
  },
  RejectBankTransfer: async (id, remark) => {
    return await BaseServices.Get(`/api/Credit/approved-bank-transfer/${id}/${remark}`);
  },
};

export default CreditServices;
