import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Language from '../../based/Language';
import Loading from '../../based/Loading';
import BaseServices from '../../based/services/BaseServices';
import useFormValidate from '../../hooks/useFormValidate';
import TextNumber from '../../based/inputs/TextNumber';
import TFUSelect from '../../based/inputs/TFUSelect';
import RESOURCE_TYPE from './ResourceType';
import THIRD_PARTY_PLANS from './ThirdPartyPlan';
const INIT_MODEL = [{
    thirdPartyPlan: THIRD_PARTY_PLANS[0].id,
    resources: RESOURCE_TYPE.map(m => ({ resourceType: m.resourceType, limitDownloadCount: 99 }))
}]
function DownloadResourceConfigModal(props) {
    const [isBusy, setBusy] = useState(false);
    const [msg, setMsg] = useState('');
    const [model, setModel] = useState(INIT_MODEL);
    const { addRef, isValid, displayError } = useFormValidate();
    useEffect(() => {
        if (props.isShow) {
            _parseData();
        }
    }, [props.isShow])
    async function _parseData() {
        setBusy(true);
        setMsg('Đang lấy dữ liệu...')
        let data = props.data;
        let setPlan = new Map();
        if (data) {
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                let resources = setPlan.get(element.thirdPartyPlan);
                let resource = { resourceType: element.resourceType, limitDownloadCount: element.limitDownloadCount };
                if (resources) {
                    resources.add(resource);
                }
                else {
                    resources = new Set();
                    resources.add(resource)
                    setPlan.set(element.thirdPartyPlan, resources);
                }
            }
            let array = []
            setPlan.forEach((value, key) => {
                array.push({
                    thirdPartyPlan: key,
                    resources: Array.from(value.values())
                })
            })
            setModel(array);
        }
        setBusy(false);
    }
    async function _handleSubmit() {
        if (isValid()) {
            setBusy(true);
            setMsg('Đang lưu dữ liệu...')
            const [err, resData] = await BaseServices.Post(`/api/downloadresource/config-download-resource`, { model: model });
            setBusy(false);
            if (!err) {
                props.onSubmit(true);
            } else {
                props.onSubmit(false);
            }
        }
    }

    function _handleInputChanged(planIndex, index, value) {
        let plan = { ...model[planIndex] }
        let config = plan.resources[index];
        config.limitDownloadCount = Number(value);
        plan.resources = _.cloneDeep(plan.resources);
        model[planIndex] = plan;
        setModel([...model]);
    }
    function _handleChangedPlan(index, planValue) {
        let plan = { ...model[index], thirdPartyPlan: planValue }
        model[index] = plan;
        setModel([...model]);
    }
    return (
        <Modal show={props.isShow} size={'lg'} enforceFocus={false}>
            <Loading show={isBusy} msg={msg} />
            <Modal.Header>
                <button type='button' className='close' onClick={props.onClose}>
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                </button>
                <Modal.Title>Giới hạn số lượt download hàng ngày</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-20'>
                {
                    model.map((config, i) => {
                        return <div key={i}>
                            <div className='form-group'>
                                <label className='required'>Loại tài khoản</label>
                                <TFUSelect required id='thirdPartyPlan' name='thirdPartyPlan' ref={addRef}
                                    options={THIRD_PARTY_PLANS.filter(m => m.id == config.thirdPartyPlan).map(m => ({ value: m.id, label: m.name }))}
                                    value={config.thirdPartyPlan}
                                    onChanged={(value) => _handleChangedPlan(i, value)}
                                />
                            </div>
                            <div className='form-group'>
                                {
                                    config.resources.map((item, idx) => {
                                        let resource = RESOURCE_TYPE.find(m => m.resourceType == item.resourceType)
                                        return <div key={idx} className='row align-items-center'>
                                            <div className='col-3'>
                                                <img src={resource.logo} alt={resource.name} style={{ width: 100 }} />
                                            </div>
                                            <div className='col-3'>
                                                <span>{resource.name}</span>
                                            </div>
                                            <div className='col-6'>
                                                <TextNumber ref={addRef} required min={1} max={99}
                                                    id={`limitDownloadCount[${i}][${idx}]`}
                                                    name={`limitDownloadCount[${i}][${idx}]`} value={item.limitDownloadCount}
                                                    onChanged={(name, value) => _handleInputChanged(i, idx, value)} />
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-custom waves-light btn-sm' onClick={_handleSubmit}>
                    Lưu
                </button>
                <button className='btn btn-inverse waves-light btn-sm' onClick={props.onClose}>
                    {Language.getValue("common.btnClose")}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default DownloadResourceConfigModal;