export class OrderAffiliateModel {
    constructor() {
        this.id = 0;
        this.createdBy = "";
        this.customerName = "";
        this.shippingAddressName = "";
        this.phoneNumber = "";
        this.createdDate = new Date();
        this.statusName = "";
        this.orD_OrderProductAffiliates = [new OrderProductAffiliateModel()];
    }
}

export class OrderProductAffiliateModel {
    constructor() {
        this.id = 0;
        this.orderId = 0;
        this.creatorName = "";
        this.affiliateName = "";
        this.referenceCode = "";
        this.count = 0;
        this.productName = "";
        this.quantity = 0;
        this.finallyPrice = 0;
    }
}
