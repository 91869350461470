import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import InstructServices from "../based/services/InstructServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import styled from "styled-components";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import Request from "../based/Request";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import DragAndDropIndex from "../based/DragAndDropIndex";

const ModalAddOrUpdateContent = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [content , setContent] = useState([])

    useEffect(() => {
        getContent()
    }, []);
    
    const getContent = async () => {
        setIsBusy(true);
        let [err, data] = await InstructServices.GetContentByStepId(props.stepId);
        if (!err && data) {
            setContent(data);
            setIsBusy(false);
        } else {
            setContent([]);
            setIsBusy(false);
        }
        
    }
    async function UpdateIndexContentInStep(id, newIndex) {
        setIsBusy(true);
        let [err, data] = await InstructServices.UpdateIndexContentInStep({
            instructStepId: props.stepId,
            id, 
            newIndex
        });
        if (!err && data) {
            getContent()
            setIsBusy(false);
        } else {
            setIsBusy(false);
        }
    }


    const handleAddUpdateContent = async (model) => {
        setIsBusy(true);
        let [err, data] = await InstructServices.CreateUpdateContent(model);
        if (!err && data) {
            getContent();
            setIsBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setIsBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    }
    
    const hanleDeleteContent = async (id) =>{
            let ids= []
            ids.push(id)
            ConfirmDialog("Xác nhận xóa?", "Bạn có chắc chắn xóa nội dung này ", () => {
                return new Promise(async (resolve, reject) => {
                    setIsBusy(true);
                    let [err, data] = await InstructServices.DeleteContent(ids);
                    if (!err && data) {
                        getContent()
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        setIsBusy(false);
                    } else {
                        setIsBusy(false);
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa lỗi");
                    }  
                });
            });
        
    }

    const handleChangeImg = (e, item) =>{
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then((res) => {
                        if (res && res.data) {
                            handleAddUpdateContent({
                                ...item,
                                icon : res.data.imageUrl,
                            })
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                            return `${prev}${item.value}<br/>`;
                                        }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }


                    })
                    .catch((err) => {


                    });
            };

            reader.readAsDataURL(file);
        }
    }
   
    function renderRow(item, index){
        return (
            <React.Fragment>
                <td style={{width: 40,textAlign: 'center', background:'#fff' , cursor: 'move', border: '1px solid #767676'}}>
                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H12.5" stroke="#565656"/>
                        <path d="M0 5.5H12.5" stroke="#565656"/>
                        <path d="M0 10H12.5" stroke="#565656"/>
                    </svg>

                </td>
                <td style={{width: 40, height: 40}}>
                    {item.icon ? <div>
                        <span className="remove" onClick={()=>{
                            handleAddUpdateContent({
                                ...item,
                                icon : "",
                            })
                        }}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L1 11" stroke="#F96A74"/>
                                <path d="M11 11L1 1" stroke="#F96A74"/>
                            </svg>
                        </span>
                        <img src={item.icon} />
                    </div>
                    : <label for={`input-img-${index}`}>Chọn</label>
                    }
                    <input type="file" id={`input-img-${index}`} hidden onChange={(e)=>{
                        handleChangeImg(e, item)
                    }}  />
                </td>
                <td>
                    <textarea style={{width: '100%', height: 40, color: '#fff', background: '#767676' , border: 'none'}} cols={5} value={item.content} onKeyPress={(e)=>{
                        if(e.charCode === 13){
                            handleAddUpdateContent({
                                ...item,
                                content : e.target.value,
                            })
                        }
                    }} onChange={(e)=>{
                        let data = [...content]
                        data[index] = {...data[index], content: e.target.value}
                        setContent(data)
                    }} />
                </td>
                <td style={{width: 50,textAlign: 'center',cursor: 'pointer', color: 'red' , fontWeight: 500}} onClick={()=>{
                    hanleDeleteContent(item.id)
                }}>
                    Xóa
                </td>
        </React.Fragment>
        )
    }


    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Chỉnh sửa nội dung"} onClose={() => props.onClose()}>
            <Wapper>
                <Loading show={isBusy} msg={msg} />
                <table>
                    <tbody>
                        <tr>
                            <th style={{width: 40}}></th>
                            <th style={{width: 40}}>Ảnh</th>
                            <th>Nội dung (nhập và enter để lưu)</th>
                            <th style={{width: 50}}></th>
                        </tr>
                    </tbody>
                    <DragAndDropIndex data={content} renderRow={renderRow} updateIndexFolder={UpdateIndexContentInStep} />
                    <tbody>
                     <tr>
                        <th colSpan={999} className="add-content" onClick={()=>{
                            handleAddUpdateContent({
                                id: 0,
                                instructStepId: props.stepId,
                                icon : "",
                                index: 0,
                                content: ""
                            })
                        }}>Thêm nội dung +</th>
                     </tr>
                    </tbody>
                </table>
            </Wapper>
        </CommonModal>
    );
};

export default ModalAddOrUpdateContent;
const Wapper = styled.div`
    .add-content{
        color: #64C5B1;
        cursor: pointer;
    }

    table{
        width: 100%;
        tr{

            td{
                position: relative;
                border: 1px solid #fff;
                background-color: #767676;

                .remove{
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    cursor: pointer;
                    
                }

                label{
                    width: 40px;
                    display: block;
                    background: #fff;
                    border-radius: 5px;
                    text-align: center;
                    margin: 0 auto;
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }
            
        }
    }

`
ModalAddOrUpdateContent.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
