import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../based/Constants";
import Request from "../../../based/Request";
import styled from "styled-components";
// import FolderIcon from "./folder_icon.png";
// import CloseIcon from "./CloseIcon.svg";
import Common from "../../../based/Common";
import { ConfirmDialog } from "../../../based/Dialog";

function ImageUpload(props) {
    const [isUploaded, setIsUploaded] = useState(false);
    const [typeFile, setTypeFile] = useState("");
    const [progress, setProgress] = useState();
    const [isValid, setIsValid] = useState(true);
    const [msgInvalid, setMsgInvalid] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isRequired, setIsRequired] = useState(false);

    useEffect(() => {
        setIsRequired(props.isRequired);
    }, [props.isRequired]);

    function handleImageChange(e) {
        let imageType = props.imageType || 0;
        let reader = new FileReader();
        let file = e.target.files[0];
        let maximumSize = props.maximumSize || 2;

        if (e.target.files && file) {
            if (file.size / 1024 / 1024 > maximumSize) {
                setIsValid(false);
                setMsgInvalid(`Kích thước tối đa ${props.maximumSize ? props.maximumSize : 2} (${Math.floor(file.size / 1024)}kb)`);
                if (props.onChanged) {
                    props.onChanged(props.name, null);
                }
            } else {
                reader.onloadend = (e) => {
                    var img = new Image();
                    img.src = e.target.result;
                    img.onload = function () {
                        var width = this.width;
                        var height = this.height;
                        // if (width >  height) {
                        //     setIsValid(false);
                        //     setMsgInvalid(`Tỉ lệ cho phép: Rộng >2x dài, hiện tại ${width}-${height}`);
                        //     if (props.onChanged) {
                        //         props.onChanged(props.name, null);
                        //     }
                        // } else {
                            setIsValid(true);
                            setIsUploading(true);
                            Request.UploadImage(file, imageType)
                                .then((res) => {
                                    console.log("res:", res);
                                    if (res && res.data) {
                                        setIsUploaded(true);
                                        var img = props.image;
                                        setTypeFile(file.type);
                                        if (img) {
                                            img = img.split("/")[img.split("/").length - 1];
                                            Request.DeleteImage(img)
                                                .then((deleteRes) => {})
                                                .catch((err) => {});
                                        }
                                        if (props.onChanged) {
                                            props.onChanged(props.name, res.data.imageUrl, res.data.thumbnailUrl);
                                            if (props.onFileName) {
                                                let fileName = Common.GetFileName(file.name);
                                                let fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
                                                props.onFileName(fileNameWithoutExtension);
                                            }
                                        }
                                    } else {
                                        setIsValid(false);
                                        setMsgInvalid(res.message ? res.message : "Có lỗi");
                                    }
                                    setIsUploading(false);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setIsValid(false);
                                    setMsgInvalid(CONSTANTS.MSG_IMAGE_INVALID);
                                    file.value = "";
                                    setIsUploading(false);
                                });
                        // }
                    };
                };
            }
        }
        reader.readAsDataURL(file);
    }
    const handleRemoveImage = () => {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa ảnh đã chọn?", () => {
            return new Promise((resolve, reject) => {
                if (!isValid) setIsValid(true);
                setMsgInvalid("");
                let img = props.image;
                let arr = img.split("/");
                img = arr[arr.length - 1];
                Request.DeleteImage(img)
                    .then((res) => {
                        if (props.onChanged) props.onChanged(props.name, "");
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                    })
                    .catch((err) => {});
            });
        });
    };
    return (
        <Container>
            <BoxUpload>
                <div className='image-upload'>
                    {!isUploaded ? (
                        <React.Fragment>
                            <label htmlFor='upload-input'>
                                <img src={typeof props.image === "string" && props.image != "" ? `${props.image}` : "./images/folder_icon.png"} draggable={"false"} alt='placeholder' />
                                {props.image ? null : <p style={{ color: "#444" }}>Tải ảnh lên</p>}
                            </label>

                            <input id='upload-input' type='file' accept='image/*' onChange={handleImageChange} />
                        </React.Fragment>
                    ) : (
                        <ImagePreview>
                            <img
                                className='close-icon'
                                src='./images/CloseIcon.svg'
                                alt='CloseIcon'
                                onClick={() => {
                                    setIsUploaded(false);
                                    handleRemoveImage();
                                }}
                            />
                            <img id='uploaded-image' src={props.image} draggable={false} alt='uploaded-img' />
                        </ImagePreview>
                    )}
                </div>
                {isUploading && (
                    <div className='overlay-loading'>
                        {" "}
                        <div className='sk-circle'>
                            <div className='sk-circle1 sk-child'></div>
                            <div className='sk-circle2 sk-child'></div>
                            <div className='sk-circle3 sk-child'></div>
                            <div className='sk-circle4 sk-child'></div>
                            <div className='sk-circle5 sk-child'></div>
                            <div className='sk-circle6 sk-child'></div>
                            <div className='sk-circle7 sk-child'></div>
                            <div className='sk-circle8 sk-child'></div>
                            <div className='sk-circle9 sk-child'></div>
                            <div className='sk-circle10 sk-child'></div>
                            <div className='sk-circle11 sk-child'></div>
                            <div className='sk-circle12 sk-child'></div>
                        </div>
                    </div>
                )}
            </BoxUpload>

            {isValid ? null : <label className='text-danger full-width' dangerouslySetInnerHTML={{ __html: msgInvalid }}></label>}
            {!isRequired ? null : <label className='text-danger full-width'>Không được để trống</label>}
        </Container>
    );
}

export default ImageUpload;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    p {
        margin-top: -10px;
        color: #777;
    }
`;

const BoxUpload = styled.div`
    border: 1px dashed #799cd9;
    position: relative;
    background: #fbfbff;
    border-radius: 20px;
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    .image-upload {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        label {
            cursor: pointer;

            :hover {
                opacity: 0.8;
            }
        }
        > input {
            display: none;
        }
    }
    .overlay-loading {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        border-radius: 16px;
    }
`;

const ImagePreview = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    #uploaded-image {
        /* width: 180px; */
        width: 90%;
        height: 90%;
        object-fit: cover;
        /* height: 100%; */
        border-radius: 20px;
    }
    .close-icon {
        background: #000;
        border-radius: 5px;
        opacity: 0.8;
        position: absolute;
        z-index: 10;
        right: 15px;
        top: 20px;
        cursor: pointer;
        :hover {
            opacity: 1;
        }
    }
`;
