import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import { CONSTANTS, MAINMENU } from "../../based/Constants";
import Loading from "../../based/Loading";
import ShippingServices from "../../based/services/ShippingServices";

export default function VNPost(props) {
    const [isBusy, setBusy] = useState(true);
    const [message, setMessage] = useState("Đang tải dữ liệu...");
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        _isConnectToVNPost();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ConnectPlatform });
    }, []);

    async function _handleConnectToVNPost() {
        setBusy(true);
        setMessage("Đang kết nối...");
        const [err, data] = await ShippingServices.ConnectToVNPost();
        if (!err) {
            setBusy(false);
            setMessage("");
            _isConnectToVNPost();
        } else {
            setBusy(false);
            setMessage("");
        }
    }

    async function _isConnectToVNPost() {
        setBusy(true);
        setMessage("Đang kết nối...");
        const [err, data] = await ShippingServices.IsConnectedVNPost();
        if (!err && data) setConnected(true);
        else setConnected(false);
        setBusy(false);
        setMessage("");
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg={message} />
            <div className="row">
                <div className="col-sm-12 form-row card-box p-3">
                    <div className="col-12 col-lg-6">
                        <div className="lazada-channel--text">
                            <p className="mb-3 font-size-24px header-title">Kết nối VNPost</p>
                            {connected ? (
                                <p className="sub-header text-success">
                                    <b>Hãy bắt đầu sử dụng các kênh bán hàng của VNPOST.</b>
                                </p>
                            ) : (
                                <p className="sub-header text-danger">VNPost chưa được kết nối. Vui lòng kết nối để bắt đầu sử dụng.</p>
                            )}
                        </div>
                        <div className="text-left">
                            <button className="btn btn-custom mt-3 mb-3" onClick={() => _handleConnectToVNPost()}>
                                <span>
                                    <i className="fa fa-share-alt"></i> Kết nối kênh bán hàng
                                </span>
                            </button>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="salechannel--image">
                            <img src={"/images/sale-chanel.jpg"} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
