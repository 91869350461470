import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import CommonModal from "../based/Modal";
import useFormValidate from "../hooks/useFormValidate";
import Request from "../based/Request";
import TFUSelect from "../based/inputs/TFUSelect";
import { IMAGEABLE_TYPE, INITPAGING, NOTIFY } from "../based/Constants";
import CommonTable from "../based/CommonTable";
import CusStockphotoServices from "../based/services/CusStockphotoServices";
import CusCollectionTypeServices from "../based/services/CusCollectionTypeServices";
import MainColorTypeServices from "../based/services/MainColorServices";
import { Notify } from "../based/Notify";

const ManyAddStockModal = (props) => {
    const { onSaveContinue, onClose, isShowModal } = props;
    const [isBusy, setIsBusy] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const [data, setData] = useState([]);
    const [mainColor, setMainColor] = useState([]);
    const [colType, setColType] = useState([]);
    const [colTypeDropDown, setColTypeDropDown] = useState([]);

    useEffect(() => {
        getMainColor({ ...INITPAGING, pageSize: 100 });
        getColType({ ...INITPAGING, pageSize: 100 });
    }, []);

    async function getColType(paging) {
        setIsBusy(true);
        let [err, data] = await CusCollectionTypeServices.GetCUSCollectionTypeByPage(paging);
        if (!err && data) {
            setColType(data.listObjects);
            if (data.listObjects.length > 0) {
                setColTypeDropDown(
                    data.listObjects.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    })
                );
            }
        } else {
            setColType([]);
        }
        setIsBusy(false);
    }

    async function getMainColor(paging) {
        setIsBusy(true);
        let [err, data] = await MainColorTypeServices.GetMainColorByPage(paging);
        if (!err && data) {
            setMainColor(data.listObjects);
        } else {
            setMainColor([]);
        }
        setIsBusy(false);
    }

    const _handleSave = async () => {
        setIsBusy(true);
        let [err, dt] = await CusStockphotoServices.CreateManyStockPhoto({
            listDatas: data,
        });
        if (!err && dt) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            setIsBusy(false);
            onSaveContinue();
        } else {
            setIsBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Thất bại");
        }
    };

    const handleUpload = async (e) => {
        setIsBusy(true);
        e.preventDefault();
        let files = e.target.files;
        let uploadPromises = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let nameBefore = file.name;

            let name = nameBefore.substring(0, nameBefore.lastIndexOf("."))
            
            let index1 = name.indexOf("(");
            let index2 = name.indexOf(")");

            let title = name;
            if (index1 >= 0) {
                title = name.substring(0, index1).trim();
            }

            let arrColor = [];
            if (index1 >= 0 && index2 >= 0) {
                let arrColorStr = name.substring(index1 + 1, index2);
                arrColor = arrColorStr.split(",");
            }
            let arrColorValue = [];
            let collectionTypeId = 0;
            if (arrColor.length > 0) {
                if (colType && colType.length > 0) {
                    let dt = colType.find((x) => x.keyCode == arrColor[0].trim());
                    if (dt && dt.id) {
                        collectionTypeId = dt.id;
                    }
                }
                if (arrColor.length > 1 && mainColor && mainColor.length > 0) {
                    for (var j = 1; j < arrColor.length; j++) {
                        if (j <= 2) {
                            let color = mainColor.find((x) => x.keyCode == arrColor[j].trim());
                            if (color && color.code) {
                                arrColorValue.push(color.code);
                            }
                        }
                    }
                }
            }

            let index3 = name.indexOf("#");
            let index4 = name.lastIndexOf("#");
            let arrTag = [];
            if (index3 >= 0 && index4 >= 0 && index3 != index4) {
                let arrTagStr = name.substring(index3 + 1, index4);
                arrTag = arrTagStr.split(",");
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);

            const uploadPromise = new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;

                        Request.UploadImage(file, IMAGEABLE_TYPE.StockPhoto)
                            .then((res) => {
                                let obj = {
                                    id: 0,
                                    title: title,
                                    description: "",
                                    thumbUrl: res.data.imageUrl,
                                    url: res.data.imageUrl,
                                    collectionTypeId: collectionTypeId,
                                    width: width,
                                    height: height,
                                    tagConnections: arrTag,
                                    colorConnections: arrColorValue,
                                };
                                resolve(obj);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    };
                };
            });

            uploadPromises.push(uploadPromise);
        }

        Promise.all(uploadPromises)
            .then((uploadedData) => {
                setData([...data, ...uploadedData]);
                setIsBusy(false);
            })
            .catch((error) => {
                setIsBusy(false);
                // Handle errors if any of the promises reject
                console.error("Error uploading images:", error);
            });
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center" style={{ width: 50 }}>
                        STT
                    </th>
                    <th className="center">Tên</th>
                    <th className="center">Thumbnail</th>
                    <th className="center">Kiểu họa tiết</th>
                    <th className="center">Tông màu(2)</th>
                    <th className="center">Tags</th>
                    <th className="center">Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {}

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="center">{idx + 1}</td>
                                <td>
                                    {item.isUpdate ? (
                                        <textarea
                                            style={{height: '100%'}}
                                            rows={5}
                                            className="input-in-table"
                                            value={item.title}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].title = e.target.value;
                                                setData(dt);
                                            }}
                                        />
                                    ) : (
                                        item.title
                                    )}
                                </td>
                                <td>
                                    <img style={{ width: 100, height: 100, objectFit: "contain" }} src={item.thumbUrl} />
                                </td>
                                <td style={{width: 200}}>
                                    {item.isUpdate ? (
                                        <TFUSelect
                                            value={item.collectionTypeId}
                                            onChanged={(newValue) => {
                                                let dt = [...data];
                                                dt[idx].collectionTypeId = newValue;
                                                setData(dt);
                                            }}
                                            name="col-type"
                                            placeholder="--Chọn loại--"
                                            options={colTypeDropDown}
                                        />
                                    ) : (
                                        colType.find((x) => x.id == item.collectionTypeId) && colType.find((x) => x.id == item.collectionTypeId).name
                                    )}
                                </td>
                                <td style={{width: 200}}>
                                    {item.isUpdate ? (
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {mainColor &&
                                                mainColor.length > 0 &&
                                                mainColor.map((ele, i) => {
                                                    let iColor = item.colorConnections.findIndex((x) => x == ele.code);
                                                    return (
                                                        <div
                                                            className={iColor != -1 ? "active" : ""}
                                                            style={{ display: "flex", alignItems: "center", marginBottom: 8,  padding: '4px'}}
                                                            key={i}
                                                            onClick={() => {
                                                                if (iColor != -1) {
                                                                    let dt = [...data];
                                                                    dt[idx].colorConnections.splice(iColor, 1);
                                                                    setData(dt);
                                                                } else {
                                                                    if (item.colorConnections.length < 2) {
                                                                        let dt = [...data];
                                                                        dt[idx].colorConnections.push(ele.code);
                                                                        setData(dt);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {ele.name}
                                                            <span style={{ width: 25, height: 25, background: ele.code, display: "block" }}></span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    ) : (
                                        item.colorConnections &&
                                        item.colorConnections.map((ele, i) => {
                                            return (
                                                <div className="" style={{ display: "flex", alignItems: "center", marginBottom: 8 }} key={i}>
                                                    {ele}
                                                    <span style={{ width: 50, height: 30, background: ele, display: "block", marginLeft: 8 }}></span>
                                                </div>
                                            );
                                        })
                                    )}
                                </td>
                                <td style={{width: 200}}>
                                    {item.isUpdate ? (
                                        <div>
                                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                {item.tagConnections &&
                                                    item.tagConnections.map((ele, i) => {
                                                        return (
                                                            <span className="text-center badge badge-secondary m-2 cursor-pointer" key={i}>
                                                                #{ele}
                                                                {"  "}
                                                                <span
                                                                    onClick={() => {
                                                                        let dt = [...data];
                                                                        dt[idx].tagConnections.splice(i, 1);
                                                                        setData(dt);
                                                                    }}
                                                                >
                                                                    x
                                                                </span>
                                                            </span>
                                                        );
                                                    })}
                                            </div>
                                            <div>
                                                {" "}
                                                <input
                                                    type="text"
                                                    placehoder="Nhập tag"
                                                    onKeyPress={(e) => {
                                                        if (e.charCode === 13) {
                                                            let dt = [...data];
                                                            dt[idx].tagConnections.push(e.target.value);
                                                            setData(dt);
                                                            e.target.value = ''
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        item.tagConnections &&
                                        item.tagConnections.map((ele, i) => {
                                            return (
                                                <div className="text-center badge badge-secondary m-2" key={i}>
                                                    #{ele}
                                                </div>
                                            );
                                        })
                                    )}
                                </td>
                                <td style={{width: 85}}>
                                    {item.isUpdate ? (
                                        <button
                                            className="btn btn-custom"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = false;
                                                setData(dt);
                                            }}
                                        >
                                            Xong
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-custom"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt[idx].isUpdate = true;
                                                setData(dt);
                                            }}
                                        >
                                            Sửa
                                        </button>
                                    )}

                                        <button
                                            className="btn btn-danger mt-2"
                                            onClick={() => {
                                                let dt = [...data];
                                                dt.splice(idx , 1)
                                                setData(dt);
                                            }}
                                        >
                                            Xóa
                                        </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <CommonModal show={isShowModal} size="lg" id={Common.generateGuid()} isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={"Thêm mới nhiều photo"} onSave={_handleSave} onClose={() => onClose()} classModalBody={"modal-class-body"}>
            <Wrapper>
                <div>
                    <h6>Hướng dẫn upload:</h6>
                    <p>Chọn các hình ảnh họa tiết (chỉ chứa hình ảnh (svg , png , jpg))</p>
                    <p>Tên hình ảnh phải đúng với cấu trúc <b>Tên (Kiểu họa tiết, tông màu) #Tag#</b></p>
                    <p>Tông màu và họa tiết là những ký tự viết tắc (code) được định nghĩa từ trước</p>
                    <p>Tham khảo viết tắt: <a href="https://admin.ranus.vn/main-color-management" target="blank">Tông màu</a>, <a href="https://admin.ranus.vn/collection-type-management" target="blank">họa tiết</a> </p>
                    <p>Ví dụ: Cái cây hình người đi bộ (C,Hong,Trang) #cây,người,đi bộ#</p>

                </div>
                <label className="btn-upload" htmlFor="stock-photo-upload">
                    <input
                        type="file"
                        id="stock-photo-upload"
                        multiple
                        hidden
                        accept="image/*"
                        onChange={(e) => {
                            handleUpload(e);
                        }}
                    />
                    Chọn ảnh họa tiết
                </label>
                <div className="list-stock">
                    <CommonTable datas={data} paging={{}} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
                </div>
            </Wrapper>
        </CommonModal>
    );
};

export default ManyAddStockModal;

const Wrapper = styled.div`
    .btn-upload {
        background-color: #64c5b1;
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
    }

    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    p{
        margin-bottom: 8px;
        line-height: 1;
    }

    .active {
        border: 1px solid #64c5b1;
    }
`;
