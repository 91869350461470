import ComboSectionModel from "./ComboSectionModel";

export default class ComboModel {
    constructor() {
        this.id = 0;
        this.thumbnail = "";
        this.name = "";
        this.fromDate = new Date();
        this.toDate = new Date();
        this.sellPrice = 0;
        this.comboSections = [];
        this.content = "";
    }
    comboSections = [new ComboSectionModel()];
}
