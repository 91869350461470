import React, { Component } from "react";
import Common from "../Common";
import { CONSTANTS } from "../Constants";
import Loading from "../Loading";
import Request from "../Request";
import { ConfirmDialog } from "../Dialog";

class TFUImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            isValid: true,
            msgInvalid: "",
            isUploading: false,
            fileName: "",
        };
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }

    handleImageChange(e) {
        e.preventDefault();
        if (!this.state.isValid)
            this.setState({
                isValid: true,
                msgInvalid: "",
            });
        let reader = new FileReader();
        let file = e.target.files[0];
        let imageType = this.props.imageType || 0;
        if (file) {
            let maximumSize = this.props.maximumSize || 2;
            if (file.size / 1024 / 1024 > maximumSize) {
                this.setState({
                    isValid: false,
                    msgInvalid: CONSTANTS.MSG_IMAGE_INVALID_SIZE,
                });
                if (this.props.onChanged) {
                    this.props.onChanged(this.props.name, null);
                }
            } else {
                this.setState({
                    isUploading: true,
                });
                reader.onloadend = () => {
                    Request.UploadImage(file, imageType)
                        .then((res) => {
                            if (res && res.data) {
                                var img = this.props.image;
                                if (img) {
                                    img = img.split("/")[img.split("/").length - 1];
                                    Request.DeleteImage(img)
                                        .then((deleteRes) => { })
                                        .catch((err) => { });
                                }
                                if (this.props.onChanged) {
                                    this.props.onChanged(this.props.name, res.data.imageUrl, res.data.thumbnailUrl);
                                    if (this.props.onFileName) {
                                        let fileName = Common.GetFileName(file.name);
                                        let fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
                                        this.props.onFileName(fileNameWithoutExtension);
                                    }
                                }
                            } else {
                                let errMsg =
                                    res.errors && res.errors.length > 0
                                        ? res.errors.reduce((prev, item, idx) => {
                                            return `${prev}${item.value}<br/>`;
                                        }, "")
                                        : "";
                                this.setState({
                                    isValid: false,
                                    msgInvalid: errMsg,
                                });
                            }
                            this.setState({
                                isUploading: false,
                            });
                        })
                        .catch((err) => {
                            this.setState({
                                isValid: false,
                                msgInvalid: CONSTANTS.MSG_IMAGE_INVALID,
                            });
                            this.file.value = "";
                            this.setState({
                                isUploading: false,
                            });
                        });
                };
            }
            reader.readAsDataURL(file);
        }
    }

    handleRemoveImage() {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa ảnh đã chọn?", () => {
            return new Promise((resolve, reject) => {
                if (!this.state.isValid)
                    this.setState({
                        isValid: true,
                        msgInvalid: "",
                    });
                let img = this.props.image;
                let arr = img.split("/");
                img = arr[arr.length - 1];
                Request.DeleteImage(img)
                    .then((res) => {
                        if (this.props.onChanged) this.props.onChanged(this.props.name, "");
                        this.file.value = "";
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                    })
                    .catch((err) => { });
            });
        });
    }

    validateInput(value) {
        let msg = "";
        if (value === undefined) value = this.state.value;
        if (value == null || value === "") {
            if (this.props.required) msg = this.props.requiredMsg ? this.props.requiredMsg : CONSTANTS.MSG_REQUIRED;
        }
        return msg;
    }

    displayError(msg) {
        this.setState({
            isValid: false,
            msgInvalid: msg,
        });
    }
    render() {
        let { image } = this.props;
        let $imagePreview = null;
        if (image) {
            $imagePreview = (
                <div className='preview'>
                    <img src={image} style={{ maxWidth: "60%" }} />
                    <a className='remove-image' onClick={this.handleRemoveImage} style={{ position: "absolute" }}>
                        <span className='btn btn-sm  btn-outline-secondary mx-2'>
                            <i className='fa fa-times' />
                        </span>
                    </a>
                </div>
            );
        }
        return (
            <div className='previewComponent'>
                <input
                    className={this.props.className ? "fileInput " + this.props.className : "fileInput"}
                    ref={(ins) => (this.file = ins)}
                    type='file'
                    multiple={false}
                    id={this.props.id ? this.props.id : "btn-upload-input"}
                    accept='image/*'
                    onChange={(e) => this.handleImageChange(e)}
                />
                {!this.props.isHidePreview && (
                    <div className='imgPreview' style={{ position: "relative" }}>
                        {$imagePreview}
                    </div>
                )}
                {this.state.isValid ? null : <label className='text-danger full-width' dangerouslySetInnerHTML={{ __html: this.state.msgInvalid }}></label>}
                {<Loading show={this.state.isUploading} msg='Đang tải lên...' />}
            </div>
        );
    }
}

export default TFUImage;
