import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";
import LogisticContext from "../context/LogisticContext";

function LogisticFilterScanPage(props) {
    const { bulkModel, setBulkModel } = useContext(LogisticContext);
    const [listShippingOrderNumber, setListShippingOrderNumber] = useState([]);
    const [keyword, setKeyword] = useState("");
    const history = useHistory();

    useEffect(() => {
        let anyActive = bulkModel ? bulkModel.some((item) => item.active) : false;
        if (!bulkModel || !anyActive) history.push("/logistic");
    }, [bulkModel]);

    const activeBulkModel = useMemo(() => {
        return bulkModel.find((item) => item.active);
    }, [bulkModel]);

    const totalDelivery = useMemo(() => {
        return activeBulkModel ? activeBulkModel.logisticBulkBookingModels.length : 0;
    }, [activeBulkModel]);

    function onCreateBulk() {
        let lstBooking = activeBulkModel.logisticBulkBookingModels.filter((item) => listShippingOrderNumber.includes(item.shippingOrderNumber));
        if (lstBooking != null && lstBooking.length > 0) {
            let lstOrderId = lstBooking.map((item) => item.id);
            let stringOrderId = lstOrderId.join(",");

            //nếu 1 đơn thì đặt đơn lẽ
            if (lstOrderId.length === 1) {
                let shipNumber = listShippingOrderNumber[0];
                history.push(`/logistic-create/${shipNumber}`);
            } else history.push(`/logistic-multi-create?listOrderId=${stringOrderId}`);
        }
    }

    function handleEnter(keyword) {
        let list = [...listShippingOrderNumber];
        let anyItem = activeBulkModel.logisticBulkBookingModels.find((item) => item.shippingOrderNumber === keyword);

        if (!anyItem) {
            Notify(NOTIFY.INFO, NOTIFY.INFO, "Không tìm thấy mã đơn hàng");
            return;
        }

        if (!list.includes(keyword) && anyItem) {
            list.push(keyword);
            setListShippingOrderNumber(list);
        }
        setKeyword("");
    }

    if (!activeBulkModel) return "";
    return (
        <React.Fragment>
            <GlobalStyle />
            <Wrapper>
                <div className="header">
                    <div className="header-left">
                        <div className="main">Chuyến {activeBulkModel.index}</div>
                        <span className="sub">{totalDelivery} điểm giao hàng</span>
                    </div>
                    <div className="header-instruct">
                        Quét từng đơn hàng để Đặt xe {listShippingOrderNumber.length}/{totalDelivery}
                    </div>
                </div>
                <div className="body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="header-delivery">Điểm giao hàng</th>
                                <th className="header-distance">Km</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {activeBulkModel.logisticBulkBookingModels.map((item, index) => (
                                <tr key={index}>
                                    <td className="item-number">{index + 1}</td>
                                    <td className="item-info">
                                        <span className="item-info-top">
                                            {item.userName} - {item.phoneNumber}
                                        </span>
                                        <span className="item-info-bottom">{item.fullAddress}</span>
                                    </td>
                                    <td className="distance">{item.distance}km</td>
                                    <td className="scan-btn">
                                        <input type="checkbox" checked={listShippingOrderNumber.includes(item.shippingOrderNumber)} onChange={(e) => {}} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="bottom-bar">
                    <div className="scan-area">
                        <input
                            type="text"
                            placeholder="Nhập hoặc quét mã"
                            value={keyword}
                            onChange={(e) => {
                                let val = e.target.value;
                                setKeyword(val);
                                if (val.length === 6) {
                                    handleEnter(val);
                                }
                            }}
                            onKeyDown={(e) => {
                                //enter
                                if (e.key === "Enter") {
                                    handleEnter(keyword);
                                }
                            }}
                        />
                    </div>
                    <div
                        className="proceed-btn"
                        onClick={(e) => {
                            //nếu scan chưa đủ số đơn hàng thì thông báo
                            if (listShippingOrderNumber.length < activeBulkModel.logisticBulkBookingModels.length) {
                                ConfirmDialog(
                                    "Thông báo",
                                    "Chưa quét đủ số đơn hàng, bạn có muốn tiếp tục đặt xe không?",
                                    () => {
                                        return new Promise((resolve, reject) => {
                                            onCreateBulk();
                                            resolve({ title: "Thành công", msg: "" });
                                        });
                                    },
                                    true
                                );
                            } else {
                                onCreateBulk();
                            }
                        }}
                    >
                        Tiến hành đặt
                    </div>
                </div>
            </Wrapper>
        </React.Fragment>
    );
}

export default LogisticFilterScanPage;

const Wrapper = styled.div`
    padding: 22.5px 16.5px;

    .header {
        display: flex;
        flex-wrap: wrap;
    }

    .header-instruct {
        width: 100%;
        flex-basis: 100%;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        margin: 16px 0;
    }

    .header-left {
        display: flex;
        align-items: baseline;
    }

    .header-left .main {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 90% */
    }

    .header-left span.sub {
        color: #717171;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        margin-left: 6px;
    }

    thead {
        border: 1px solid #ededed;
        background: #878787;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
        text-align: center;
    }

    td.item-number {
        vertical-align: middle;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    td.item-info {
        /* display: flex; */
        /* flex-wrap: wrap; */
    }

    span.item-info-top {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        display: block;
    }

    span.item-info-bottom {
        color: #717171;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    td.distance {
        vertical-align: middle;
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    td.scan-btn {
        vertical-align: middle;
        text-align: center;
    }

    .bottom-bar {
    }

    .bottom-bar {
        background: #fff;
        box-shadow: 0px -1px 0px 0px #ededed;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .proceed-btn {
        border-radius: 12px;
        background: #28b259;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: 12px;
        width: 40%;
        padding: 14px;
    }

    input[type="text"] {
        border: none;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .scan-area {
        border-radius: 12px;
        border: 1px solid #ededed;
        background: #fff;
        padding: 12px 12px;
        flex: 1;
    }
`;

const GlobalStyle = createGlobalStyle`
div#root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
`;
