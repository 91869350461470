import React, { createContext, useState } from "react";

const LogisticContext = createContext({
    listModel: [],
    setListModel: () => {},
    bulkModel: [],
    setBulkModel: () => {},
});

export default LogisticContext;

export const LogisticContextProvider = ({ children }) => {
    const [listModel, setListModel] = useState([]);
    const [bulkModel, setBulkModel] = useState([]);

    return (
        <LogisticContext.Provider
            value={{
                listModel,
                setListModel,
                bulkModel,
                setBulkModel,
            }}
        >
            {children}
        </LogisticContext.Provider>
    );
};
