import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Loading from "../based/Loading";
import styled from "styled-components";
import TemplateServices from "../based/services/TemplateServices";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import CommonTable from "../based/CommonTable";
import AddOrUpdateTagDesign from "./AddOrUpdateTagDesign";
import TFUSelect from "../based/inputs/TFUSelect";
import Common from "../based/Common";
import ProductServices from "../based/services/ProductServices";

var INITPAGING = {
    pageNumber: 1,
    orderDirection: "desc",
    keyword: "",
    pageSize: 16,
    packageType: 0,
    appProductId: 0,
    fromDate: null,
    toDate: null,
};

const packageTypeEnum = [
    { value: 0, label: "Tất cả" },
    { value: 1, label: "Áo" },
    { value: 2, label: "Ốp lưng" },
    { value: 3, label: "Bình nước" },
    { value: 4, label: "Hộp bút" },
];

function TableRanus() {
    const [isBusy, setBusy] = useState(false);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageSize: 50 });
    const [isModal, setIsModal] = useState(false);
    const [item, setItem] = useState({});
    const [productDropDown, setProductDropDown] = useState([]);
    const [productDropDownTotal, setProductDropDownTotal] = useState([]);

    useEffect(() => {
        getDatas(paging);
        getProductDropDown();
    }, []);

    const getDatas = async (paging) => {
        setBusy(true);
        let [err, data] = await TemplateServices.GetTemplate(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging({ ...INITPAGING });
        }
        setBusy(false);
    };

    useEffect(() => {
        if (productDropDownTotal.length > 0) {
            if (paging.packageType == 0) {
                setProductDropDown([{ value: 0, label: "Tất cả" }, ...productDropDownTotal]);
            } else {
                setProductDropDown([{ value: 0, label: "Tất cả" }, ...productDropDownTotal.filter((x) => x.packageType == paging.packageType)]);
            }
        }
    }, [paging.packageType, productDropDownTotal]);

    const getProductDropDown = async () => {
        setBusy(true);
        let [err, data] = await TemplateServices.GetDropDownProduct();
        if (!err && data) {
            setProductDropDownTotal(data);
        } else {
            setProductDropDownTotal([]);
        }
        setBusy(false);
    };

    async function _handleRemoveProduct(id) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                let [err, data] = await TemplateServices.DeleteTemplate(id);
                if (!err && data) {
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getDatas(paging);
                    setBusy(false);
                } else {
                    Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Xóa thất bại");
                    setBusy(false);
                }
            });
        });
    }

    async function pushProductMetaCatalog(templateId) {
        setBusy(true);

        let [error, data] = await ProductServices.PushProductMetaCatalog(0, templateId);
        if (!error && data) {
            console.log("data", data);
            Notify("success", "Thông báo", "Đẩy sản phẩm thành công");
        } else {
            console.log("error", error);
            Notify("error", "Thông báo", "Đẩy sản phẩm thất bại");
        }
        setBusy(false);
    }

    function _renderHeader() {}
    function _renderBody() {
        return (
            <div className="w-100pc">
                <div className="table-category">
                    <div className="body-table">
                        {datas && datas.length > 0
                            ? datas.map((item, idx) => {
                                  return (
                                      <div key={idx} className="col-table">
                                          <div className="item-table">
                                              <div className="box-img">
                                                  <img src={item.imageUrl} alt={item.title} />
                                              </div>
                                              <div className="description">{item.title}</div>
                                              <div className="flex-bottom mt-1">
                                                  <div className="sale-price">{Common.formatCurrency(item.salePrice)}</div>
                                                  <div className="price">{Common.formatCurrency(item.price)}</div>
                                              </div>
                                              <div className="flex-bottom">
                                                  <div
                                                      className="btn-banner"
                                                      onClick={() => {
                                                          setItem(item);
                                                          setIsModal(true);
                                                      }}
                                                  >
                                                      Cập nhật tag
                                                  </div>
                                                  <div className="remove" onClick={() => _handleRemoveProduct(item.id)}>
                                                      Xóa
                                                  </div>
                                              </div>
                                              <div className="flex-bottom">
                                                  <div
                                                      className="btn btn-custom my-2"
                                                      onClick={(e) => {
                                                          pushProductMetaCatalog(item.id);
                                                      }}
                                                  >
                                                      Đẩy Facebook
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : "Không có thiết kế"}
                    </div>
                </div>
            </div>
        );
    }

    function _renderAction() {
        return (
            <div className="mb-3">
                <div className="box-header">
                    <div>
                        <div className="d-flex mb-2" style={{ alignItems: "center" }}>
                            Loại sản phẩm:
                            <TFUSelect
                                className={"select"}
                                id="packageType"
                                name="packageType"
                                placeholder="Chọn loại sản phẩm"
                                options={packageTypeEnum}
                                value={paging.packageType}
                                isClearable={false}
                                onChanged={(value) => {
                                    if (value >= 0) setPaging({ ...paging, pageNumber: 1, packageType: value, appProductId: 0 });
                                }}
                            />
                            Dòng sản phẩm:
                            <TFUSelect
                                className={"select"}
                                id="productId"
                                name="productId"
                                placeholder="Chọn sản phẩm"
                                options={productDropDown}
                                value={paging.appProductId}
                                isClearable={false}
                                onChanged={(value) => {
                                    if (value >= 0) setPaging({ ...paging, pageNumber: 1, appProductId: value });
                                }}
                            />
                        </div>
                        <div>
                            Từ ngày:
                            <input
                                type="date"
                                value={paging.fromDate}
                                onChange={(e) => {
                                    setPaging({ ...paging, pageNumber: 1, fromDate: e.target.value });
                                }}
                            />
                            Đến ngày:
                            <input
                                type="date"
                                value={paging.toDate}
                                onChange={(e) => {
                                    setPaging({ ...paging, pageNumber: 1, toDate: e.target.value });
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Nhập tên thiết kế"
                                value={paging.keyword}
                                onChange={(e) => {
                                    setPaging({ ...paging, pageNumber: 1, keyword: e.target.value });
                                }}
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            getDatas(paging);
                        }}
                    >
                        Tìm
                    </button>
                </div>
                <div className="box-header mt-2">
                    Sắp xếp:
                    <input
                        type="radio"
                        checked={paging.orderDirection == "asc"}
                        onClick={() => {
                            getDatas({ ...paging, pageNumber: 1, orderDirection: "asc" });
                        }}
                    />
                    Tăng dần
                    <input
                        type="radio"
                        checked={paging.orderDirection == "desc"}
                        onClick={() => {
                            getDatas({ ...paging, pageNumber: 1, orderDirection: "desc" });
                        }}
                    />
                    Giảm dần
                </div>
            </div>
        );
    }

    return (
        <TableRanusWrapper>
            {isModal && (
                <AddOrUpdateTagDesign
                    isShowModal={isModal}
                    onClose={() => {
                        setIsModal(false);
                    }}
                    id={item.id}
                    title={item.title}
                    description={item.description}
                    designPoint={item.designPoint}
                    onSaveContinue={() => {
                        getDatas(paging);
                        setIsModal(false);
                    }}
                />
            )}
            <Breadcrumbs currentPage="Quản lý mẫu tự thiết kế" />
            <Loading show={isBusy} />
            <CommonTable
                data={datas}
                paging={paging}
                renderHeader={_renderHeader}
                hideSearchBasicButton
                hideSearch
                onFilter={(paging) => {
                    getDatas(paging);
                }}
                renderBody={_renderBody}
                renderAction={_renderAction}
                searchBasic
            />
        </TableRanusWrapper>
    );
}
const TableRanusWrapper = styled.div`
    .box-header {
        display: flex;
        align-items: center;
        justify-content: end;

        .select {
            max-width: 200px;
            min-width: 200px;
            margin-right: 16px;
        }

        button {
            border: none;
            outline: none;
            padding: 10px 20px;
            cursor: pointer;
        }

        input[type="radio"] {
            margin-left: 16px;
            margin-right: 8px;

            accent-color: green;
            width: 20px;
            height: 20px;
        }

        input {
            margin-right: 16px;
            border: 1px solid #ccc;
            outline: none;
            padding: 5px 10px;
            ::placeholder {
                opacity: 0.3;
            }
        }
    }
    .w-100pc {
        width: 100%;
        background-color: #fff;

        .table-category {
            width: calc(100% - 10px);
            margin-left: 10px;
            background-color: #fff;

            .header-table {
                height: 50px;
                padding: 6px 12px;
                background: #d9d9d9;

                .tfu-autocomplete-input {
                }
            }
            .body-table {
                padding: 12px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -6px;
                overflow-y: auto;

                .col-table {
                    width: 12.5%;
                    max-width: 12.5%;
                    min-width: 12.5%;
                    padding: 0 6px;

                    .item-table {
                        margin-bottom: 12px;
                        width: 100%;
                        padding: 6px;
                        border: 1px solid #d9d9d9;
                        position: relative;

                        .box-img {
                            width: 100%;
                            aspect-ratio: 1/1;

                            img {
                                width: 100%;
                                height: 100%;
                                min-height: 100%;
                                object-fit: contain;
                            }
                        }
                        .flex-bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .btn-banner {
                                cursor: pointer;
                                color: green;
                            }
                            .remove {
                                color: red;
                                cursor: pointer;
                            }

                            .sale-price {
                                color: red;
                                font-weight: 500;
                                font-size: 14px;
                            }

                            .price {
                                font-size: 13px;
                                text-decoration: line-through;
                            }
                        }

                        .box-name {
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            img {
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                                border-radius: 50%;
                            }
                            p {
                                font-size: 12px;
                                font-weight: bold;
                                line-height: 14px;
                                margin-left: 6px;
                                color: #000000;
                            }
                        }
                        .description {
                            width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-size: 14px;
                            color: #676767;
                            margin-top: 6px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
`;
export default TableRanus;
