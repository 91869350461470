import React, { useContext } from "react";
import { ConfirmDialog } from "../based/Dialog";
import Request from "../based/Request";
import { ProductVariantContext } from "../context/ProductVariantContext";

export default function SectionMainProductBasicInfo(props) {
    const { editingVariant, productVariants } = useContext(ProductVariantContext);

    function _handleAddVariant(e) {
        e.preventDefault();
        if (editingVariant.isModified) {
            ConfirmDialog(
                "Chú ý!",
                "Thông tin mới nhập của biến thể hiện tại chưa được lưu. Bạn có muốn bỏ qua và tiếp tục đến thay đổi thông tin của biến thể mới?",
                () => {
                    return new Promise((resolve, reject) => {
                        resolve();
                        props.history.push(`/product-variants-editor/${props.urlPlatform}/${props.productId}/add`);
                    });
                },
                true
            );
        } else {
            props.history.push(`/product-variants-editor/${props.urlPlatform}/${props.productId}/add`);
        }
    }

    function _handleBackToEditMainProduct(e) {
        e.preventDefault();
        if (editingVariant.isModified) {
            ConfirmDialog(
                "Chú ý!",
                "Thông tin mới nhập của biến thể hiện tại chưa được lưu. Bạn có muốn bỏ qua và tiếp tục đến thay đổi thông tin của biến thể mới?",
                () => {
                    return new Promise((resolve, reject) => {
                        resolve();
                        props.history.push(`/product-editor/${props.urlPlatform}/${props.productId}`);
                    });
                },
                true
            );
        } else {
            props.history.push(`/product-editor/${props.urlPlatform}/${props.productId}`);
        }
        e.preventDefault();
    }

    return (
        <div className="section-productVariants-mainProductBasicInfo">
            <div className="col-sm-12 card-box p-3">
                <div className="col-sm-4 d-inline-block">
                    <a className="iconImageDefault full-width">
                        <img src={productVariants[0] ? productVariants[0].productAvatar : Request.CdnURL() + "/images_system/no-image.png"} />
                    </a>
                </div>
                <div className="col-sm-8 d-inline-block">
                    <h6 className="m-0 text-overflow" title={productVariants[0] ? productVariants[0].productName : null}>
                        {productVariants[0] ? productVariants[0].productName : null}
                    </h6>
                    <p className="m-0 text-overflow" title={productVariants[0] ? productVariants[0].variantCount + " biến thể" : null}>
                        {productVariants[0] ? productVariants[0].variantCount + " biến thể" : null}
                    </p>
                    {props.isEditing && (
                        <a className="d-block" href="#" onClick={(e) => _handleAddVariant(e)}>
                            <i className="fa fa-plus-square"></i> Thêm biến thể
                        </a>
                    )}
                    <a className="d-block" href="#" onClick={(e) => _handleBackToEditMainProduct(e)}>
                        <i className="fa fa-arrow-left"></i> Trở lại chi tiết sản phẩm
                    </a>
                </div>
            </div>
        </div>
    );
}
