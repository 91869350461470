import React from 'react'
import styled from 'styled-components';
const initStep = [{
    stepName: '1',
    stepContent: () => <div>abc def</div>
}]
const StepNavigation = (props) => {
    return (
        <StepWrapper>
            {props.labelArray.map((item, idx) => <Step
                updateCurrentStep={props.updateCurrentStep}
                active={props.currentStep === idx + 1}
                key={idx} item={item} />)}
        </StepWrapper>
    )
}

const Step = (props) => {
    return (
        <div className={props.active ? 'step-block active' : 'step-block'}>
            <div className={props.item.isPass ? 'circle-wrapper pass' : 'circle-wrapper'} onClick={() => props.updateCurrentStep(props.item.value)}>
                <div className={props.item.isPass ? 'circle pass' : props.active ? 'circle active' : 'circle'}>{props.item.isPass ? <i className="fa fa-check" aria-hidden="true"></i> : <p>{props.item.value}</p>}</div>
            </div>
            <span>{props.item.text}</span>
        </div>
    )
}
const StepWrapper = styled.div`
display: flex;
margin-bottom: 20px;
width: 100%;
justify-content: flex-start;
flex-wrap: wrap;
.step-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 170px;
    position: relative;
    .circle-wrapper{
        padding: 0 20px;
        display: flex;
        align-items: center;
    }
}
.step-block:not(:first-child) .circle-wrapper::after{
content:'';
width: 100%;
height: 2px;
position: absolute;
top:0;
bottom: 50%;
background-color: #aaa;
margin: auto;
z-index: 0;
right: 80px;
}
.step-block:not(:first-child) .circle-wrapper.pass::after{
content:'';
width: 100%;
height: 2px;
position: absolute;
top:0;
bottom: 50%;

background-color: #2C9B46;
margin: auto;
z-index: 0;
}
.circle{
    width: 29px;
    height: 29px;
    border-radius:50%;
    line-height:25px;
    background-color:#fff;
    border:2px solid #aaa;
    color:#aaa;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index: 1;
        p{
            margin: 0;
        }
}
.circle.active{
    background-color:#000;
    border:2px solid transparent;
    color:#fff;

}
.circle.pass{
    background-color:#2C9B46;
    border:2px solid transparent;
    i{
        color:#fff;
    }
}
`
export default StepNavigation
