import React, { useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import { RequestDomain } from "../based/RequestDomain";

function ReportModal({ show, onClose, gameId }) {
    const [model, setModel] = useState({ benchPlayer: "", benchBanker: "" });

    const handleConfirm = () => {
        let url = `${RequestDomain.APIUrl()}/api/game/report-export?gameId=${gameId}&benchPlayer=${model.benchPlayer}&benchBanker=${model.benchBanker}`;
        window.open(url, "_blank");
    };

    return (
        <Wrapper>
            <CommonModal
                show={show}
                onClose={() => onClose()}
                onConfirm={() => {
                    handleConfirm();
                }}
            >
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Game Id
                    </label>
                    <input type="text" readOnly className="form-control" value={gameId} />
                </div>
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Bench Player
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        value={model.benchPlayer}
                        onChange={(e) => {
                            setModel({ ...model, benchPlayer: e.target.value });
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Bench Banker
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        value={model.benchBanker}
                        onChange={(e) => {
                            setModel({ ...model, benchBanker: e.target.value });
                        }}
                    />
                </div>
            </CommonModal>
        </Wrapper>
    );
}

export default ReportModal;

const Wrapper = styled.div``;
