import React, { useEffect, useState } from 'react';
import BaseServices from '../based/services/BaseServices';
import { Modal } from 'react-bootstrap';
import Loading from '../based/Loading';
import CommonModal from '../based/Modal';
import { Notify } from '../based/Notify';
import { NOTIFY } from '../based/Constants';

const ChangeSlotPositionModal = ({ isShow, onClose, onSave, slotDetail }) => {
    const [isBusy, setBusy] = useState(false);
    const [slots, setSlots] = useState([]);
    const [areas, setAreas] = useState([]);
    const [floors, setFloors] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedArea, setSelectedArea] = useState(0);
    const [selectedRow, setSelectedRow] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState(0);
    const [selectedSlot, setSelectedSlot] = useState(0);
    useEffect(() => {
        if (isShow)
            fetchAreas();
    }, [isShow])

    const fetchAreas = async () => {
        setBusy(true);
        const [err, data] = await BaseServices.Get('/api/Sort/get-empty-slots');
        if (!err && data) {
            setSlots(data);
            let areas = [];
            let floors = [];
            let columns = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (areas.findIndex(m => m.id == element.areaId) < 0)
                    areas.push({ id: element.areaId, name: element.areaName });
                if (floors.indexOf(element.row) < 0)
                    floors.push(element.row)
                if (columns.indexOf(element.column) < 0)
                    columns.push(element.column)
            }
            setAreas(areas);
            setFloors(floors);
            setColumns(columns);
            setSelectedArea(areas[0].id);
            setSelectedRow(floors[0]);
            setSelectedColumn(columns[0]);
        }
        setBusy(false);
    }

    const _handleChangePosition = async () => {
        let slot = slots.find(m => m.row == selectedRow && m.column == selectedColumn && m.areaId == selectedArea);
        if (slot) {
            const [err, data] = await BaseServices.Post(`/api/Sort/move-products?fromSlotId=${slotDetail.id}&toSlotId=${slot.id}`);
            if (!err && data) {
                Notify(NOTIFY.SUCCESS, 'Chuyển thành công');
                onClose(true);
            }
            else {
                Notify(NOTIFY.ERROR, 'Có lỗi xảy ra');
            }
        }else{
            Notify(NOTIFY.ERROR, 'Khay đã có sản phẩm');
            onClose(true);
        }
    }
    return (
        <CommonModal show={isShow} onClose={onClose} size={'sm'} enforceFocus={false}
            onSave={_handleChangePosition}
            scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
            <label>Vị trí hiện tại:</label>
            <div className='d-flex' style={{ justifyContent: 'space-around' }}>
                <div className='box'>
                    <span><strong>Khu: {slotDetail.areaName}</strong></span>
                </div>
                <div className='box'>
                    <span><strong>Tầng: {slotDetail.row}</strong></span>
                </div>
                <div className='box'>
                    <span><strong>Khay: {slotDetail.column}</strong></span>
                </div>
            </div>
            <label>Vị trí chuyển đến:</label>
            <div className='d-flex' style={{ justifyContent: 'space-around' }}>
                <div className='box'>
                    <select value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)}>
                        {
                            areas && areas.map((item, index) => {
                                return <option value={item.id} key={index}>Khu {item.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className='box'>
                    <select value={selectedRow} onChange={(e) => setSelectedRow(e.target.value)}>
                        {
                            floors && floors.map((item, index) => {
                                return <option value={item} key={index}>Tầng {item}</option>
                            })
                        }
                    </select>
                </div>
                <div className='box'>
                    <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
                        {
                            columns && columns.map((item, index) => {
                                return <option value={item} key={index}>Khay {item}</option>
                            })
                        }
                    </select>
                </div>
            </div>
        </CommonModal>
    );
};

export default ChangeSlotPositionModal;