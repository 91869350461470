import React, { useContext } from "react";
import { CONSTANTS } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Common from "../based/Common";
import Request from "../based/Request";
import ProductServices from "../based/services/ProductServices";
import { ProductVariantContext } from "../context/ProductVariantContext";

export default function SectionProductVariants(props) {
    const { editingVariant, setEditingVariant, productVariants, setProductVariants } = useContext(ProductVariantContext);

    function _handleClickVariant(id, index) {
        if (editingVariant.isModified) {
            ConfirmDialog(
                "Chú ý!",
                "Thông tin mới nhập của biến thể hiện tại chưa được lưu. Bạn có muốn bỏ qua và tiếp tục đến thay đổi thông tin của biến thể mới?",
                () => {
                    return new Promise((resolve, reject) => {
                        resolve();
                        handleChangeVariant(index, id);
                    });
                },
                true
            );
        } else {
            handleChangeVariant(index, id);
        }
    }

    async function handleChangeVariant(index, variantId) {
        let data = [...productVariants];
        data.map((item) => (item.isEditing = false));
        data[index].isEditing = true;
        setProductVariants(data);
        const [err, dt] = await ProductServices.GetProductVariantInfo(data[index].id);
        if (!err) {
            dt.isModified = false;
            setEditingVariant(dt);
        }
        let productId = props.productId;
        props.history.push(`/product-variants-editor/${props.urlPlatform}/${productId}/edit/${variantId}`);
    }

    function _handleDeleteVariant(id, idx) {
        ConfirmDialog("Xác nhận xóa?", "Sản phẩm đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa sản phẩm đã chọn? ", () => {
            return new Promise(async (resolve, reject) => {
                const [err, data] = await ProductServices.DeleteVariant(id);
                if (!err) {
                    productVariants.splice(idx, 1);
                    if (productVariants[0]) {
                        productVariants.map((item) => (item.isEditing = false));
                    }
                    setProductVariants(productVariants);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    if (productVariants.length === 0) props.history.push(`/product-editor/${props.urlPlatform}/${props.productId}`);
                    else props.history.push(`/product-variants-editor/${props.urlPlatform}/${props.productId}/edit`);
                } else {
                    if (err.data) reject({ title: "Có lỗi", msg: err.data });
                    else reject({ title: "Có lỗi", msg: CONSTANTS.MSG_ERROR });
                }
                props.scrollToTop();
            });
        });
    }
    return (
        <div className="section-productVariants-variants">
            <div className="col-sm-12 card-box px-0 pt-3">
                <h4 className="header-title m-t-0 mb-3 px-3">
                    <b>Biến thể</b>
                </h4>
                {productVariants.length > 0 &&
                    productVariants.map((item, idx) => {
                        let attributes = item.attributeValues
                            ? JSON.parse(item.attributeValues)
                                  .map((x) => x.Value)
                                  .join("/")
                            : "";
                        let divBaseClass = "col-sm-12 py-2 border-top border-silver";
                        return (
                            <div className={item.isEditing && props.isEditing ? `${divBaseClass} variant-active` : divBaseClass} key={idx}>
                                <div
                                    className="col-sm-4 d-inline-block cursor-pointer"
                                    onClick={() => {
                                        if (!item.isEditing) _handleClickVariant(item.id, idx);
                                    }}
                                >
                                    <a className="py-1 iconImageDefault full-width">
                                        <img src={item.productAvatar ? item.productAvatar : Request.CdnURL() + "/images_system/no-image.png"} />
                                    </a>
                                </div>
                                <div
                                    className="col-sm-6 d-inline-block align-top cursor-pointer"
                                    onClick={() => {
                                        if (!item.isEditing) _handleClickVariant(item.id, idx);
                                    }}
                                >
                                    <p className="py-1 m-0 text-overflow" title={attributes}>
                                        {attributes}
                                    </p>
                                    {item.sellerSKU ? (
                                        <p className="py-1 m-0 text-overflow">
                                            <i>
                                                SKU: <strong>{item.sellerSKU}</strong>
                                            </i>
                                        </p>
                                    ) : null}
                                    {+new Date(item.modifiedDate).getFullYear() != 1970 ? (
                                        <p className="py-1 m-0 text-overflow">
                                            Cập nhập: <strong>{Common.formatDate(new Date(item.modifiedDate), "datetime")}</strong>
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-sm-2 d-inline-block align-top p-0">
                                    <button className="btn btn-sm btn-danger" onClick={() => _handleDeleteVariant(item.id, idx)}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
