import PubSub from "pubsub-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, INITPAGING, MAINMENU, NOTIFY, PLATFORM, YEARCHECK, PRODUCT_MATERIAL_TYPE_OPTION } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Textbox from "../based/inputs/Textbox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import ProductServices from "../based/services/ProductServices";
import TFUAutoComplete from "../based/TFUAutoComplete";
import { ApplicationContext } from "../context/ApplicationContext";
import Selection from "../based/inputs/Selection";

export default function ProductsListing(props) {
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [isShowSearch, setIsShowSearch] = useState(false);
    const [categoryKeyword, setCategoryKeyword] = useState("");
    const { applicationInfo, setApplicationInfo } = useContext(ApplicationContext);
    const [productTypeOptions, setProductTypeOptions] = useState([]);

    useEffect(() => {
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ManageProduct });
        let newPaging = { ...applicationInfo.productListingPaging };
        if (props.tabIndex) newPaging.platform = props.tabIndex;
        getDatas(newPaging);
        _getProductTypes();
    }, [props.tabIndex]);

    const _getProductTypes = async () => {
        let [err, data] = await ProductServices.GetProductTypes({ ...INITPAGING, pageNumber: 1, pageSize: 20 });
        if (!err && data) {
            let options = data.listObjects.map((x) => ({ value: x.id, text: x.name, label: x.name }));
            setProductTypeOptions(options);
        } else setProductTypeOptions([]);
    };

    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        setSelectedAll(false);
        let [error, data] = await ProductServices.GetProducts(newPaging);
        if (!error && data) {
            setDatas(data.listObjects);
            setApplicationInfo({ ...applicationInfo, productListingPaging: data.paging });
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setApplicationInfo({ ...applicationInfo, productListingPaging: { ...INITPAGING } });
            setIsLoading(false);
            setMsg(null);
        }
    }

    function _handleAdd() {
        let url = "/product-editor/new";
        if (props.tabIndex)
            switch (props.tabIndex) {
                case PLATFORM.LOTUS:
                    url = "/product-editor/lotus/new";
                    break;
            }
        if (props && props.history) props.history.push(url);
        else window.location.href = url;
    }
    function _handleEdit() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            let sp = dataChecked[0];
            if (sp != null) {
                if (sp.platforms && sp.platforms.length > 1) {
                    ConfirmDialog(
                        "Xác nhận",
                        "Sản phẩm đang được bán trên " + sp.platforms.length + " sàn. Bạn chắc chắn muốn sửa sản phẩm đã chọn? ",
                        () => {
                            return new Promise(async (resolve, reject) => {
                                reject({ title: "" });
                                let url = "/product-editor/" + sp.id;
                                if (props.tabIndex)
                                    switch (props.tabIndex) {
                                        case PLATFORM.LOTUS:
                                            url = `/product-editor/lotus/${sp.id}`;
                                            break;
                                    }
                                if (props && props.history) props.history.push(url);
                                else window.location.href = url;
                            });
                        },
                        true
                    );
                } else {
                    let url = "/product-editor/" + sp.id;
                    if (props.tabIndex)
                        switch (props.tabIndex) {
                            case PLATFORM.LOTUS:
                                url = `/product-editor/lotus/${sp.id}`;
                                break;
                        }
                    if (props && props.history) props.history.push(url);
                    else window.location.href = url;
                }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một sản phẩm để thực hiện. Vui lòng kiểm tra lại!");
    }
    function _handleRemove() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length > 0) {
            let ids = [];
            dataChecked.map((d) => ids.push(d.id));
            ConfirmDialog("Xác nhận xóa?", "Sản phẩm đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa sản phẩm đã chọn? ", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu....");
                    const [err, data] = await ProductServices.DeleteProducts(ids, props.tabIndex);
                    if (!err) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(applicationInfo.productListingPaging);
                    } else {
                        if (err.data) reject({ title: "Có lỗi", msg: err.data });
                        else reject({ title: "Có lỗi", msg: CONSTANTS.MSG_ERROR });
                    }
                    setIsLoading(false);
                    setMsg("");
                });
            });
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn sản phẩm để xóa. Vui lòng kiểm tra lại!");
    }
    function _handleCheckedAll(value) {
        if (!datas || datas.length == 0) return;
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }
    function _renderHeader() {
        var mulPlatform = false;
        if (datas && datas.length > 0) {
            var productMulPlatform = datas.filter((x) => x.platforms && x.platforms.length > 1);
            if (productMulPlatform && productMulPlatform.length > 0) mulPlatform = true;
        }
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox id={Common.generateGuid()} checked={selectedAll} name="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th width="100" className="cursor-pointer center">
                        <span className="ml-3">Ảnh</span>
                    </th>
                    <th className="cursor-pointer center">
                        <span className="ml-3">Tên sản phẩm</span>
                    </th>
                    <th className="cursor-pointer center">
                        <span className="ml-3">Tồn kho</span>
                    </th>
                    {mulPlatform ? (
                        <th className="cursor-pointer center">
                            <span className="ml-3">Được bán trên</span>
                        </th>
                    ) : null}
                    <th className="cursor-pointer center">
                        <span className="ml-3">Cập nhật gần nhất</span>
                    </th>
                    <th className="cursor-pointer center">
                        <span className="ml-3">Checklist thiết lập vị trí Artwork/Logo</span>
                    </th>
                </tr>
            </thead>
        );
    }
    function _handleCheckdChange(item, idx, value) {
        let datasUpdate = [...datas];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setDatas(datasUpdate);
        setSelectedAll(datasUpdate.filter((x) => x.checked).length == datas.length);
    }
    const TFUSelectCat = useMemo(() => (
        <TFUAutoComplete
            id="categoryId"
            name="categoryId"
            urlFetchData={{
                url: "/api/Category/get-list-children-category",
                params: {
                    ...INITPAGING,
                    keyword: categoryKeyword,
                },
            }}
            onSelectedItemChanged={(item) => {
                setCategoryKeyword(item.text);
                if (!item.isTyping || applicationInfo.productListingPaging.categoryId) {
                    setApplicationInfo({ ...applicationInfo, productListingPaging: { ...applicationInfo.productListingPaging, categoryId: item.value } });
                }
            }}
            placeholder={applicationInfo.productListingPaging.categoryId > 0 ? "" : "--Ngành hàng--"}
            value={applicationInfo.productListingPaging.categoryId}
        />
    ));
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group full-width">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" />
                        Thêm
                    </button>
                    {props.tabIndex && props.tabIndex == PLATFORM.Facebook ? (
                        <button className="btn btn-custom btn-sm m-r-5" onClick={() => props.onChooseProducts()}>
                            <i className="fa fa-cube m-r-5" />
                            Lấy từ nguồn khác
                        </button>
                    ) : null}
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" />
                                Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger btn-sm m-r-5" : "btn btn-danger btn-sm m-r-5 disable"} onClick={_handleRemove}>
                                <i className="fa fa-trash-o m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    ) : null}
                    <button className="btn btn-custom btn-sm m-r-5" onClick={() => setIsShowSearch(!isShowSearch)}>
                        <i className={isShowSearch ? "fa fa-angle-double-up m-r-5" : "fa fa-angle-double-down m-r-5"} />
                        Tìm kiếm nâng cao
                    </button>
                    {isShowSearch ? (
                        <div className="box__search form-row">
                            <div className="form-group col-md-3">
                                <label htmlFor="categoryId">Ngành hàng:</label>
                                {TFUSelectCat}
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="productMaterialType">Loại sản phẩm:</label>
                                <Selection
                                    id="productMaterialType"
                                    name="productMaterialType"
                                    value={applicationInfo.productListingPaging.productMaterialType}
                                    placeholder="---Chọn loại sản phẩm ---"
                                    onChanged={(value) => setApplicationInfo({ ...applicationInfo, productListingPaging: { ...applicationInfo.productListingPaging, productMaterialType: value } })}
                                    options={PRODUCT_MATERIAL_TYPE_OPTION}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="productTypeId"> Dòng sản phẩm:</label>
                                <Selection
                                    id="productTypeId"
                                    name="productTypeId"
                                    value={applicationInfo.productListingPaging.productTypeId}
                                    options={productTypeOptions}
                                    placeholder="---Chọn dòng sản phẩm---"
                                    onChanged={(value) => setApplicationInfo({ ...applicationInfo, productListingPaging: { ...applicationInfo.productListingPaging, productTypeId: +value } })}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="keyword">Tên sản phẩm:</label>
                                <Textbox
                                    className="form-control"
                                    onChanged={(name, value) => setApplicationInfo({ ...applicationInfo, productListingPaging: { ...applicationInfo.productListingPaging, keyword: value } })}
                                    value={applicationInfo.productListingPaging.keyword}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <button className="btn btn-custom btn-sm m-r-5" onClick={() => getDatas(applicationInfo.productListingPaging)}>
                                    <i className="fa fa-search m-r-5" />
                                    Tìm kiếm
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    function _handleClickRow(index) {
        let data = [...datas];
        data[index].checked = !data[index].checked;
        setDatas(data);
        setSelectedAll(data.filter((x) => x.checked).length == datas.length);
    }
    function _renderBody() {
        var mulPlatform = false;
        if (datas && datas.length > 0) {
            var productMulPlatform = datas.filter((x) => x.platforms && x.platforms.length > 1);
            if (productMulPlatform && productMulPlatform.length > 0) mulPlatform = true;
        }
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, k) => {
                        var hideAndshow = false;
                        let avatar = item.productAvatar ? item.productAvatar : Request.CdnURL() + "/images_system/no-image.png";
                        let recently = Common.formatDate(new Date(item.modifiedDate));
                        let recentlyYear = recently.slice(6);
                        YEARCHECK.HIDEYEAR;
                        if (YEARCHECK.HIDEYEAR !== recentlyYear) hideAndshow = true;
                        return (
                            <tr key={k} className="text-center" onClick={() => _handleClickRow(k)}>
                                <td width={40} className="vertical-align-middle">
                                    <CheckBox id={Common.generateGuid()} checked={!!item.checked} name={item.id.toString()} label=" " onCheckedChange={(name, value) => _handleCheckdChange(item, k, value)} />
                                </td>
                                <td>
                                    <a className="iconImageDefault full-width">
                                        <img src={avatar} className="variant-avatar" id={`tfutooltip_${item.id}`} />
                                    </a>
                                </td>
                                <td className="text-left vertical-align-middle">{item.name}</td>
                                <td className="text-left vertical-align-middle">
                                    {item.variantCount > 0 ? (
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix={` sản phẩm của ${item.variantCount} biến thể`} value={item.availableStock} />
                                    ) : (
                                        <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" sản phẩm" value={item.availableStock} />
                                    )}
                                </td>
                                {mulPlatform ? (
                                    <td className="text-left vertical-align-middle">
                                        <span>{item.platforms.join(", ")}</span>
                                    </td>
                                ) : null}
                                {hideAndshow ? <td className="vertical-align-middle">{Common.formatDate(new Date(item.modifiedDate), "datetime")}</td> : <td className="vertical-align-middle"></td>}
                                <td className="text-left vertical-align-middle">
                                    {/* Đã config artwork frame ở mục thuộc tính chưa */}
                                    {item.isConfigArtworkFrame ? (
                                        <span className="status__active">
                                            <i className="fa fa-check m-r-5"></i>Đã thiết lập vị trí khung Artwork tại thuộc tính Kích thước
                                        </span>
                                    ) : (
                                        <span className="status__unactive">
                                            <i className="fa fa-exclamation m-r-5"></i>Chưa thiết lập vị trí khung Artwork tại thuộc tính Kích thước
                                        </span>
                                    )}
                                    <br />
                                    {/* Đã config bộ ảnh của size tiêu chuẩn chưa (trái, phải, trước, sau) */}
                                    {item.isConfigStandardSize ? (
                                        <span className="status__active">
                                            <i className="fa fa-check m-r-5"></i>Đã thiết lập Bộ ảnh của Size Tiêu Chuẩn
                                        </span>
                                    ) : (
                                        <span className="status__unactive">
                                            <i className="fa fa-exclamation m-r-5"></i>Chưa thiết lập Bộ ảnh của Size Tiêu Chuẩn
                                        </span>
                                    )}
                                    <br />
                                    {/* Đã config thông số cho 5 vị trí logo chưa (2 trước, 1 sau, 1 trái, 1 phải) */}
                                    {item.isConfigBrandStamp ? (
                                        <span className="status__active">
                                            <i className="fa fa-check m-r-5"></i>Đã thiết lập Thông số vị trí Logo Tiêu Chuẩn
                                        </span>
                                    ) : (
                                        <span className="status__unactive">
                                            <i className="fa fa-exclamation m-r-5"></i>Chưa thiết lập Thông số vị trí Logo Tiêu Chuẩn
                                        </span>
                                    )}
                                    <br />
                                    {/* Đã config các ảnh tiêu chuẩn nào sẽ được render PDP chưa */}
                                    {item.isConfigRenderPDP ? (
                                        <span className="status__active">
                                            <i className="fa fa-check m-r-5"></i>Đã thiết lập Ảnh chuẩn Mặc định Render PDP
                                        </span>
                                    ) : (
                                        <span className="status__unactive">
                                            <i className="fa fa-exclamation m-r-5"></i>Chưa thiết lập Ảnh chuẩn Mặc định Render PDP
                                        </span>
                                    )}
                                    <br />
                                    {/* Đã config các ảnh tiêu chuẩn nào sẽ được render Upload chưa */}
                                    {item.isConfigRenderUpload ? (
                                        <span className="status__active">
                                            <i className="fa fa-check m-r-5"></i>Đã thiết lập Ảnh chuẩn Mặc định Render Upload Creator
                                        </span>
                                    ) : (
                                        <span className="status__unactive">
                                            <i className="fa fa-exclamation m-r-5"></i>Chưa thiết lập Ảnh chuẩn Mặc định Render Upload Creator
                                        </span>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="7" className="text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _handleFilter(newPaging) {
        getDatas(newPaging);
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <CommonTable renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} paging={applicationInfo.productListingPaging} data={datas} searchBasic={!isShowSearch} onFilter={_handleFilter} />
        </React.Fragment>
    );
}
