import React, { useEffect, useMemo, useState } from "react";
import Loading from "../based/Loading";
import Breadcrumbs from "../based/Breadcrumbs";
import { NOTIFY, ARTWORK_TYPE, STATUS_TRANSACTION } from "../based/Constants";
import { Notify } from "../based/Notify";
import Barcode from "react-barcode";
import { ConfirmDialog } from "../based/Dialog";
import OrderServices from "../based/services/OrderServices";
import Common from "../based/Common";
import DisplayVariantAttributeValue from "../based/DisplayVariantAttributeValue";

export default function HandleOverToShipper(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [barcode, setBarcode] = useState("");
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        let content = document.querySelector(".content");
        if (content) {
            var contentHeight = content.offsetHeight;
            let boxBarcode = document.querySelector("#box-barcode");
            let boxBarcodeHeight = boxBarcode ? boxBarcode.offsetHeight : 300;
            var height = contentHeight - 130 - boxBarcodeHeight;
            if (height > 0) {
                document.getElementById("tbl_items").style.overflowY = "auto";
                document.getElementById("tbl_items").style.height = height + "px";
            }
        }
        document.getElementById("barcode").focus();
    }, []);

    function _handleSetStatusToShipped() {
        if (datas && datas.length > 0) {
            let ids = [];
            datas.map((order) => ids.push(order.id));
            if (ids && ids.length > 0) {
                ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn chuyển trạng thái 'Bàn giao cho Shipper' cho tất cả đơn hàng này?", () => {
                    return new Promise(async (resolve, reject) => {
                        setIsLoading(true);
                        setMsg("Đang lưu dữ liệu...");
                        let [error, data] = await OrderServices.ChangeMultiOrderStatus(ids, STATUS_TRANSACTION.SHIPPED);
                        if (!error) {
                            resolve({ title: "Thành công", msg: "Đơn hàng đã được bàn giao cho Shipper." });
                            setIsLoading(false);
                            setMsg(null);
                            window.location.reload();
                        } else {
                            setIsLoading(false);
                            setMsg(null);
                            Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình cập nhật trạng thái");
                        }
                    });
                });
            }
        } else Notify(NOTIFY.INFO, "Chú ý", "Không tìm thấy đơn hàng để thực hiện");
    }

    function _renderButtons() {
        return (
            <div className='form-group col-md-12'>
                <button className={datas && datas.length > 0 ? "btn btn-custom  btn-sm m-r-5" : "btn btn-custom  btn-sm m-r-5 disable"} onClick={() => (datas && datas.length > 0 ? _handleSetStatusToShipped() : {})}>
                    <i className='fa fa-paper-plane-o m-r-5' />
                    Bàn giao cho Shipper
                </button>
            </div>
        );
    }
    const _renderHeader = useMemo(() => (
        <thead>
            <tr>
                <th width='50' className='text-center cursor-pointer'>
                    STT
                </th>
                <th width='200' className='text-center'>
                    Order ID
                </th>
                <th width='200' className='text-center'>
                    Mã vận chuyển
                </th>
                <th className='text-center'>Loại áo</th>
                <th width='100' className='text-center'>
                    Số lượng
                </th>
                <th className='text-center' width='100'>
                    Tag sản xuất
                </th>
                <th width='100' className='text-center'>
                    Loại in
                </th>
                <th className='text-center' width='200'>
                    Mặt trước
                </th>
                <th className='text-center' width='200'>
                    Mặt sau
                </th>
                <th className='text-center' width='200'>
                    Logo thương hiệu
                </th>
            </tr>
        </thead>
    ));
    function _renderProductionTags(item) {
        if (item.productionTags && item.productionTags.length > 0) {
            let tags = [];
            item.productionTags.map((tag, idx) => {
                tags.push(
                    <span key={idx} className='cursor-pointer'>
                        {tag.tagId}
                    </span>
                );
                if (idx < item.productionTags.length - 1) tags.push(<span className='m-r-5'>,</span>);
            });
            return tags;
        }
    }

    function _renderProducts() {
        return (
            <div className='content-table table-rep-plugin full-width' id='tbl_items'>
                <table className='table table-bordered m-b-0 focus-on packing'>
                    {_renderHeader}
                    {datas && datas.length > 0 && _renderContentBody()}
                </table>
            </div>
        );
    }
    function _renderAttributes(item) {
        const attrArr = Common.GroupArray(item.attributes, "name");
        for (const key of attrArr.keys()) {
            let value = attrArr.get(key);
            const upgradeListAtt = [...new Map(value.map((item) => [item["value"], item])).values()];
            attrArr.set(key, upgradeListAtt);
        }
        const attrObj = {};
        for (const key of attrArr.keys()) {
            attrObj[key] = attrArr.get(key);
            for (const attr of attrObj[key]) {
                for (const ageProVarAtt of item.attributes) {
                    if (attr.value === ageProVarAtt.value) {
                        attr.checked = true;
                        break;
                    } else attr.checked = false;
                }
            }
        }
        return attrObj && <div className='d-flex flex-row flex-wrap'>{Object.keys(attrObj).map((key) => attrObj[key] && attrObj[key].map((item, idx) => <DisplayVariantAttributeValue key={idx} keyName={key} value={item.value} />))}</div>;
    }
    function _renderContentBody() {
        let content = [];
        if (datas && datas.length > 0) {
            datas.map((order, o) => {
                let rowSpan = (order.orderProducts && order.orderProducts.length) > 0 ? order.orderProducts.length : 1;
                let count = 0;
                if (order.orderProducts && order.orderProducts.length > 0) {
                    order.orderProducts.map((product, p) => {
                        count = count + 1;
                        let arr = [];
                        if (!product.isGift) {
                            const { artworkMockupModels, logoMockupModel } = product.tagMockup;
                            const front = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Front) : "/images/no-image.png";
                            const back = artworkMockupModels ? artworkMockupModels.find((x) => x.artworkType == ARTWORK_TYPE.Back) : "/images/no-image.png";
                            if (count <= 1) {
                                arr.push(
                                    <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                        {o + 1}
                                    </td>
                                );
                                arr.push(
                                    <td rowSpan={rowSpan} className='vertical-align-middle'>
                                        <a target='_blank' href={`/order-detail/${order.id}`}>
                                            <span className='m-r-5'>{order.id}</span>
                                            <i className='mdi mdi-open-in-new'></i>
                                        </a>
                                        {order.orderNumber && (
                                            <p className='m-r-5 mb-0'>
                                                Số đơn hàng: <b>{order.orderNumber}</b>
                                            </p>
                                        )}
                                        <p>
                                            <small>{order.createdDateDisplay}</small>
                                        </p>
                                    </td>
                                );
                                arr.push(
                                    <td rowSpan={rowSpan} className='vertical-align-middle'>
                                        {order.shippingOrderNumber}
                                    </td>
                                );
                            }

                            arr.push(
                                <td className='vertical-align-middle'>
                                    <strong> {product.productName}</strong>
                                    <p className='font-italic mb-0 '>Mã SKU: {product.productSku ? product.productSku : "---"}</p>
                                    {product.attributes && product.attributes.length > 0 && _renderAttributes(product)}
                                </td>
                            );
                            arr.push(
                                <td className='vertical-align-middle'>
                                    <span className='float-right'>{product.quantity}</span>
                                </td>
                            );
                            arr.push(<td className='vertical-align-middle text-center'>{_renderProductionTags(product)}</td>);
                            arr.push(<td className='vertical-align-middle text-center'>{product.printTypeName}</td>);
                            arr.push(<td className='vertical-align-middle'>{front ? <img src={front.url} alt='front image' style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>);
                            arr.push(<td className='vertical-align-middle'>{back ? <img src={back.url} alt='back image' style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>);
                            arr.push(<td className='vertical-align-middle'>{logoMockupModel ? <img src={logoMockupModel.url} alt='logo' style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>);
                        } else {
                            arr.push(
                                <td className='vertical-align-middle'>
                                    <strong> {product.productName}</strong>
                                    <p className='font-italic mb-0 '>Mã SKU: {product.productSku ? product.productSku : "---"}</p>
                                    {product.attributes && product.attributes.length > 0 && _renderAttributes(product)}
                                </td>
                            );
                            arr.push(
                                <td className='vertical-align-middle'>
                                    <span className='float-right'>{product.quantity}</span>
                                </td>
                            );
                            arr.push(<td className='vertical-align-middle text-center'></td>);
                            arr.push(<td className='vertical-align-middle text-center'></td>);
                            arr.push(
                                <td className='vertical-align-middle text-center'>
                                    <img src={product.productAvatar} alt={product.productName} style={{ width: "200px", height: "auto", objectFit: "cover" }} />
                                </td>
                            );
                            arr.push(<td className='vertical-align-middle text-center'></td>);
                            arr.push(<td className='vertical-align-middle text-center'></td>);
                        }
                        content.push(
                            <tr key={o + "_" + p}>
                                {arr.map((item, iiii) => {
                                    return item;
                                })}
                            </tr>
                        );
                    });
                }
            });
        } else {
            content.push(
                <tr>
                    <td colSpan='14' className='vertical-align-middle text-center'>
                        <span>Không có đơn hàng</span>
                    </td>
                </tr>
            );
        }
        return content;
    }
    async function _handleScanBarcode(barcodeSearch) {
        if (barcodeSearch) {
            let existed = false;
            if (datas && datas.length > 0) {
                for (let index = 0; index < datas.length; index++) {
                    existed = datas[index].id == barcodeSearch;
                    if (existed) break;
                }
            }
            if (!existed) {
                setIsLoading(true);
                setMsg("Đang tải dữ liệu...");
                let [error, data] = await OrderServices.GetHandleOverToShipper(barcodeSearch);
                if (!error && data) {
                    if (data) {
                        if (data.id > 0) {
                            let datasUp = datas && datas.length > 0 ? [...datas] : [];
                            datasUp.push(data);
                            setDatas(datasUp);
                        }
                    }
                    setIsLoading(false);
                    setMsg("");
                } else {
                    setIsLoading(false);
                    setMsg("");
                    Notify(NOTIFY.WARNING, "Chú ý", error.message ? error.message : "Có lỗi trong quá trình lấy thông tin");
                }
            } else {
                Notify(NOTIFY.WARNING, "Chú ý", "Mã đơn hàng đã được quét trước đó");
                document.getElementById("barcode").focus();
            }
        } else {
            Notify(NOTIFY.WARNING, "Chú ý", "Yêu cầu nhập mã đơn hàng");
            document.getElementById("barcode").focus();
        }
    }
    function _renderSectionBarcode() {
        return (
            <div className='form-group col-md-3 order-packing'>
                <div className='form-row m-t-20'>
                    <div className='col-md-9'>
                        <input
                            autocomplete='off'
                            id='barcode'
                            name='barcode'
                            className='form-control text-center font-weight-bold'
                            type='text'
                            placeholder='Nhập mã đơn hàng'
                            maxlength='200'
                            onChange={(e) => setBarcode(e.target.value)}
                            value={barcode}
                            onKeyPress={(e) => {
                                if (e.charCode === 13) _handleScanBarcode(barcode);
                            }}
                        ></input>
                    </div>
                    <div className='col-md-3'>
                        <button className='btn btn-custom btn-sm full-width' onClick={() => _handleScanBarcode(barcode)}>
                            Nhập
                        </button>
                    </div>
                </div>

                <div className='form-row m-t-10'>
                    <div className='col-md-12 text-center'>
                        <Barcode value={barcode ? barcode : "000000000"} height='50' />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs currentPage='Bàn giao cho Shipper' />
            <div className='col-sm-12 card-box'>
                <div className='form-row m-t-20'>
                    {_renderButtons()}
                    <div className='form-group col-md-12'>{_renderProducts()}</div>
                    <div className='form-group col-md-12 form-row' id='box-barcode'>
                        {_renderSectionBarcode()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
