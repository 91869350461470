import React, { useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import CheckBox from "../based/inputs/CheckBox";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import AddOrUpdateModal from "./components/AddOrUpdateModal";
import MTOServices from "../based/services/MTOServices";
import AddDesignModal from "./components/AddDesignModal";
import Loading from "../based/Loading";
import { useEffect } from "react";

const initCategory = { id: 0, name: "", avatar: "" };
const MTOCategoryManagement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [showDesign, setShowDesign] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [category, setCategory] = useState(initCategory);

    useEffect(() => {
        getCategories({ ...paging });
    }, []);
    const getCategories = async (paging) => {
        setIsLoading(true);
        let [err, data] = await MTOServices.GetCategories(paging);
        if (!err && data) {
            setIsLoading(false);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setDatas([]);
            setPaging(Common.Paging);
        }
    };
    const createUpdateCategory = async (category) => {
        setIsLoading(true);
        let [err, data] = await MTOServices.CreateUpdateCategory(category);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", category.id !== 0 ? "Cập nhật thành công" : " Thêm bộ sưu tập thành công");
            setCategory({ id: 0, name: "", avatar: "" });
            getCategories(paging);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra !");
        }
    };
    const filterCategoryByKeyword = async () => {};
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className='text-center'>
                        <CheckBox checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} name='select_all' id='select_all' label=' ' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width='400' className='text-center'>
                        Ảnh
                    </th>
                    <th className='text-center'>Tên bộ sưu tập</th>
                    <th width='200' className='text-center'>
                        Trạng thái
                    </th>
                    <th width='150' className='text-center'>
                        Hành động
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => (
                        <tr key={idx}>
                            <td align='center'>
                                <CheckBox id={idx.toString()} name={idx.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                            </td>
                            <td align='center'>
                                <img src={item.avatar} alt={item.name} style={{ width: 200 }} />
                            </td>
                            <td align='center'>
                                <h4 className='font-weight-bold'>{item.name}</h4>
                            </td>
                            <td align='center'>Kích hoạt</td>
                            <td align='center'>
                                {" "}
                                <button className='btn btn-success' onClick={() => setSelectedCategory(item.id)}>
                                    Xem thiết kế
                                </button>{" "}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr key='' className='text-center'>
                        <td colSpan={4}>Chưa có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'>
                    <button
                        className='btn btn-custom btn-sm m-r-5'
                        onClick={() => {
                            setIsShowModal(true);
                            setCategory(initCategory);
                        }}
                    >
                        <i className='fa fa-plus m-r-5' />
                        Tạo mới
                    </button>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className='fa fa-edit m-r-5' /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className='fa fa-remove m-r-5' />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    const _handleFilter = async (paging) => {
        setIsLoading(true);
        let [err, data] = await MTOServices.FilterCategoryByKeyword(paging);
        if (!err && data) {
            setIsLoading(false);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setDatas([]);
            setPaging(Common.Paging);
        }
    };
    const _handleEdit = async () => {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            setCategory({ ...selected });
            setIsShowModal(true);
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn bộ sưu tập để thực hiện. Vui lòng kiểm tra lại!");
    };
    const _handleDelete = async () => {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa bộ sưu tập này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [err, data] = await MTOServices.DeleteCategory(ids);
                    if (!err && data) {
                        setIsLoading(false);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        // Notify(NOTIFY.SUCCESS, "Thành công", "Xóa thành công");
                        getCategories(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có thiết kế nào được chọn. Vui lòng kiểm tra lại!");
    };
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Quản lý bộ sưu tập MTO' />

            <Loading show={isLoading} msg={msg} />
            <AddDesignModal isShow={selectedCategory !== 0} onClose={() => setSelectedCategory(0)} categoryId={selectedCategory} />
            <AddOrUpdateModal isShow={isShowModal} onClose={() => setIsShowModal(false)} handleSave={createUpdateCategory} category={category} />
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} searchBasic onFilter={_handleFilter} />
        </React.Fragment>
    );
};

export default MTOCategoryManagement;
