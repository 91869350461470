import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../../based/Common";
import { ARTWORK_STATUS } from "../../based/Constants";
import { BtnRounded } from "../../based/content/ct.elm";
import CommonTable from "../../based/refactor/CommonTable";
import HeaderFilter from "../../based/refactor/HeaderFilter";
import ArtworkServices from "../../based/services/ArtworkServices";
import { SetModel } from "../../models/SetModel";

const ProductImages = ({ handleSelectProduct, readOnly }) => {
    const [paging, setPaging] = useState(Common.Paging);
    const [appSet, setAppSet] = useState([new SetModel()]);

    useEffect(() => {
        getSet(paging);
    }, []);

    //lấy thông tin set theo paging
    async function getSet(newPaging) {
        const [err, data] = await ArtworkServices.GetSetPaging(newPaging, ARTWORK_STATUS.Online, ARTWORK_STATUS.All);
        if (!err && data) {
            setAppSet(data.listObjects);
            setPaging(data.paging);
        } else {
            setAppSet([]);
            setPaging(Common.Paging);
        }
        console.log("data", data);
    }

    //render header
    function _renderHeader() {
        return (
            <tr>
                <th className='text-center' width='50'>
                    STT
                </th>
                <th className='text-center' width='100'>
                    Hình ảnh
                </th>
                <th className='text-center'>Tên Set thiết kế</th>
                <th className='text-center' width='100'>
                    Tiền bản quyền (đ)
                </th>
                {!readOnly && <th className='text-center' width='100'></th>}
            </tr>
        );
    }

    //render body
    function _renderBody() {
        return (
            <React.Fragment>
                {appSet && appSet.length > 0 ? (
                    appSet.map((item, p) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + p + 1;
                        let artworks = item.artworks;
                        let thumbnail = artworks && artworks.length > 0 ? artworks[0].thumbnail : "";
                        return (
                            <tr key={p}>
                                <td className='text-center vertical-align-middle'>{no}</td>
                                <td className='text-center vertical-align-middle'>
                                    <img src={thumbnail} className='variant-avatar' />
                                </td>
                                <td className='vertical-align-middle'>
                                    <strong>{item.title}</strong>
                                </td>
                                <td className='text-center vertical-align-middle'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                </td>
                                {!readOnly && (
                                    <td className='text-center vertical-align-middle'>
                                        {item.setMileStoneCount > 0 ? (
                                            <BtnRounded color='#873e23' className='disabled'>
                                                Đã chọn
                                            </BtnRounded>
                                        ) : (
                                            <BtnRounded color='#4caf50' onClick={() => handleSelectProduct(item)}>
                                                Chọn
                                            </BtnRounded>
                                        )}
                                    </td>
                                )}
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='10' className='text-center'>
                            <span>Không có sản phẩm</span>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className='d-flex justify-content-end mr-10'>
                <HeaderFilter
                    handleBasicSearch={(keyword) =>
                        getSet({
                            ...paging,
                            pageNumber: 1,
                            setName: keyword,
                        })
                    }
                />
            </div>
            <CommonTable renderHeader={_renderHeader} renderBody={_renderBody} paging={paging} onFilter={(paging) => getSet(paging)} fixedTable />
        </React.Fragment>
    );
};

export default ProductImages;
