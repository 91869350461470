import React, { Component } from 'react';
import { Notify } from './Notify';
import { NOTIFY } from './Constants';

class FormValidate extends Component {
    constructor(props) {
        super(props)
        this.refSets = new Map()
    }

    isFormValid() {
        let isValid = true;
        this.refSets.forEach((ref) => {
            if (ref && ref.validateInput) {
                var msg = ref.validateInput(ref.props.value);
                if(msg !== '' && ref.displayError){
                    ref.displayError(msg)
                    isValid = false
                }
            }
        })
        return isValid
    }

    fillData(data) {
        this.refSets.forEach((ref, key) => {
            if (ref && data[key] && ref.setValue) {
                ref.setValue(data[key])
            }
        })
    }

    /**
     * 
     * @param {object} err Contains array errors if has fields are invalid. otherwise contains Message.
     */
    displayError(err) {
        if (err.errors) {
            err.errors.map(e => {
                let ref = this.refSets.get(e.key)
                if (ref && ref.displayError) {
                    ref.displayError(e.value)
                }
            })
        }else{
            Notify(NOTIFY.ERROR, 'Thao tác gặp lỗi.', err.message)
        }
    }
}

export default FormValidate;