import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import Sticky from "react-stickynode";
import PrintFrameConfig from "../attribute/components/PrintFrameConfig";
import VariantImageConfig from "../attribute/components/VariantImageConfig";
import WarningZoneConfig from "../attribute/components/WarningZoneConfig";
import Breadcrumbs from "../based/Breadcrumbs";
import { CONSTANTS, MAINMENU, NOTIFY } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import AttributeServices from "../based/services/AttributeServices";
import ProductServices from "../based/services/ProductServices";
import { ConfigAttributeModalContext } from "../context/ConfigAttributeModalContext";
import { ProductVariantContext } from "../context/ProductVariantContext";
import useFormValidate from "../hooks/useFormValidate";
import NavigateButtons from "../manage-product/ProductEditor/NavigateButtons";
import { PackageType, ProductVariantMockupDTO, ProductVariantModel, VariantImageModel, VariantWarningZoneModel } from "../models/BaseProductModel";
import SectionBasicInfo from "./SectionBasicInfo";
import SectionMainProductBasicInfo from "./SectionMainProductBasicInfo";
import SectionPricing from "./SectionPricing";
import SectionProductVariants from "./SectionProductVariants";
import SectionShipping from "./SectionShipping";
import SectionVariantAttributes from "./SectionVariantAttributes";
import SectionVariants from "./SectionVariants";
import MockupConfig from "../attribute/components/MockupConfig";

export default function ProductVariantsEditor(props) {
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const [productVariants, setProductVariants] = useState([]);
    const [attributes, setAttributes] = useState(new Map());
    const [attributeOptions, setAttributeOptions] = useState([]);
    const [editingVariant, setEditingVariant] = useState(new ProductVariantModel());
    const isEditing = props.match.params.action === "edit";
    const productId = parseFloat(props.match.params.productId);
    const variantId = parseFloat(props.match.params.variantId);
    const urlPlatform = props.match.params.platform;
    const [printFrame, setPrintFrame] = useState([]);
    const [subPrintBrandStamp, setSubPrintBrandStamp] = useState([]);
    const [listWarningZone, setListWarningZone] = useState([new VariantWarningZoneModel()]);
    const [listVariantImage, setListVariantImage] = useState([new VariantImageModel()]);
    const [listMockup, setListMockup] = useState([ProductVariantMockupDTO]);

    useEffect(() => {
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ManageProduct });
        if (productId) getProductVariants(productId, variantId);
        if (!isEditing) {
            getProductAttributes(productId);
            getAttributes();
        }
    }, [variantId]);

    //lấy thuộc tính của biến thể theo sản phẩm
    async function getProductAttributes(productId) {
        let [error, data] = await ProductServices.GetProductAttributes(productId);
        if (!error && data) {
            if (isEditing) setEditingVariant({ ...editingVariant, attributes: data, isModified: false, productId: productId });
            else {
                //lấy thuộc tính được config là print frame
                let attributePrintFrame = data.find((x) => x.isPrintFrameConfig);
                let printFrameId = attributePrintFrame ? attributePrintFrame.id : 0;

                //khởi tạo 1 modal mới
                let newEditingVariant = new ProductVariantModel();
                newEditingVariant.attributes = data;
                newEditingVariant.isModified = false;
                newEditingVariant.productId = productId;
                newEditingVariant.attributePrintFrameConfig = printFrameId;
                setEditingVariant(newEditingVariant);
            }
        }
    }

    //lấy dropdown thuộc tính
    async function getAttributes() {
        let [error, data] = await AttributeServices.GetDropdownAttributes();
        if (!error) setAttributeOptions(data);
    }

    async function getProductVariants(productId, editingVariantId) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu..");
        let commonInfo = {};
        const [err, data] = await ProductServices.GetProductVariants(productId);
        if (!err) {
            if (data && data.length > 0) {
                if (isEditing) {
                    if (editingVariantId) {
                        let variants = data.filter((m) => m.id == editingVariantId);
                        if (variants.length > 0) {
                            let variant = variants[0];
                            variant.isEditing = true;
                        }
                    }
                }
                commonInfo.name = data[0].productName;
                commonInfo.width = data[0].width;
                commonInfo.height = data[0].height;
                commonInfo.length = data[0].length;
                commonInfo.shippingWeight = data[0].shippingWeight;
                commonInfo.volume = data[0].volume;
                setProductVariants(data);
            }
        }
        if (editingVariantId) {
            const [err2, data2] = await ProductServices.GetProductVariantInfo(editingVariantId);
            if (!err2) {
                data2.isModified = false;
                if (commonInfo != null && data2 && data2.length > 0) {
                    if (data2.name == null || data2.name.trim().length == 0) data2.name = commonInfo.name;
                    if (data2.width == null || data2.width == 0) data2.width = commonInfo.width;
                    if (data2.height == null || data2.height == 0) data2.height = commonInfo.height;
                    if (data2.length == null || data2.length == 0) data2.length = commonInfo.length;
                    if (data2.shippingWeight == null || data2.shippingWeight == 0) data2.shippingWeight = commonInfo.shippingWeight;
                    if (data2.volume == null || data2.volume == 0) data2.volume = commonInfo.volume;
                }
                setEditingVariant(data2);
            }
        }
        setIsLoading(false);
        setMsg(null);
    }

    function _handleSave() {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let variant = { ...editingVariant };
            let listImage = variant ? variant.listImage : [];
            if (listImage)
                for (var image of listImage) {
                    if (listWarningZone && listWarningZone.length > 0) {
                        let lstWarning = [...listWarningZone].filter((x) => x.url == image.url);
                        image.listWarningZone = lstWarning;
                    }

                    if (listVariantImage && listVariantImage.length > 0) {
                        let lstVariantImage = [...listVariantImage].filter((x) => x.url == image.url);
                        image.listVariantImage = lstVariantImage;
                    }
                }

            if (listMockup && listMockup.length > 0) {
                variant.productVariantMockups = listMockup;
            }

            Request.Post("/api/product/save-product-variant", variant)
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                        setIsLoading(false);
                        setMsg(null);
                        getProductVariants(productId, variantId);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsLoading(false);
                        setMsg(null);
                    }
                    scrollToTop();
                })
                .catch((err) => {
                    setIsLoading(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }

    function goBack() {
        window.history.back();
    }

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage={isEditing ? "Biên tập biến thể sản phẩm" : "Thêm mới biến thể sản phẩm"} parentPage="Biên tập sản phẩm" hrefParent={`/product-editor/${props.match.params.productId}`} />
            <Loading show={isLoading} msg={msg} />
            <ProductVariantContext.Provider
                value={{
                    editingVariant: editingVariant,
                    setEditingVariant: setEditingVariant,
                    productVariants: productVariants,
                    setProductVariants: setProductVariants,
                    attributes: attributes,
                    setAttributes: setAttributes,
                    addRef,
                    listWarningZone: listWarningZone,
                    setListWarningZone: setListWarningZone,
                    listVariantImage: listVariantImage,
                    setListVariantImage: setListVariantImage,
                    listMockup: listMockup,
                    setListMockup: setListMockup,
                }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {!isEditing || (productVariants[0] && productVariants[0].variantCount > 0) ? (
                                <React.Fragment>
                                    <Sticky enabled={true} top={70} bottomBoundary={4000} innerZ={2}>
                                        <NavigateButtons onSave={_handleSave} onCancel={goBack} />
                                    </Sticky>
                                    <div className="row">
                                        <div className="col-sm-3 d-inline-block">
                                            <SectionMainProductBasicInfo isEditing={isEditing} productId={productId} history={props.history} urlPlatform={urlPlatform} />
                                            {productVariants[0] && productVariants[0].variantCount > 0 && (
                                                <SectionProductVariants history={props.history} urlPlatform={urlPlatform} productId={productId} isEditing={isEditing} scrollToTop={() => scrollToTop()} />
                                            )}
                                        </div>
                                        <div className="col-sm-8 d-inline-block px-3">
                                            {productVariants[0] && productVariants[0].variantCount > 0 ? (
                                                <React.Fragment>
                                                    <ConfigAttributeModalContext.Provider value={{ attributeId: editingVariant.attributePrintFrameConfig, printFrame, setPrintFrame, subPrintBrandStamp, setSubPrintBrandStamp, isReadOnly: true }}>
                                                        <SectionVariantAttributes isEditing={isEditing} />
                                                        <SectionBasicInfo />
                                                        <SectionPricing />
                                                        <SectionShipping />
                                                        <PrintFrameConfig />
                                                        {editingVariant.packageType == PackageType.Case || editingVariant.packageType == PackageType.TShirt || editingVariant.packageType == PackageType.Sticker ? (
                                                            <WarningZoneConfig />
                                                        ) : editingVariant.packageType == PackageType.Bottle ? (
                                                            <VariantImageConfig />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {editingVariant.packageType == PackageType.Case && <MockupConfig />}
                                                    </ConfigAttributeModalContext.Provider>
                                                </React.Fragment>
                                            ) : (
                                                <SectionVariants attributeOptions={attributeOptions} getProductVariants={() => getProductVariants(productId)} />
                                            )}
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="col-sm-12 card-box p-3">
                                    <h5>Sản phẩm chưa có biến thể</h5>
                                    <span>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.history.push(`/product-editor/${urlPlatform}/${productId}`);
                                            }}
                                        >
                                            Trở lại sản phẩm
                                        </a>
                                        hoặc
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.history.push(`/product-variants-editor/${urlPlatform}/${productId}/add`);
                                            }}
                                        >
                                            Thêm biến thể ngay
                                        </a>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ProductVariantContext.Provider>
        </React.Fragment>
    );
}
