import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import { Layout } from "../layout/Layout";
import NumberFormat from "react-currency-format";
import CommonTable from "../based/CommonTable";
import Common from "../based/Common";
import FinanceServices from "../based/services/FinanceServices";
import Loading from "../based/Loading";
import PaymentModal from "./PaymentModal";

const TAB_SCREEN = {
    UNPAID: 0,
    PAID: 1,
};

export default function FinanceOperator(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);

    const [isShowModalPayment, setShowModalPayment] = useState(false);
    const [currentOperator, setCurrentOperator] = useState({});
    const [tabIndex, setTabindex] = useState(TAB_SCREEN.UNPAID);

    useEffect(() => {
        getDatas(paging, TAB_SCREEN.UNPAID);
    }, []);
    async function getDatas(newPaging, tab) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, result] = await FinanceServices.FinanceOperatorGetPage(tab, newPaging);
        if (!error && result) {
            setPaging(result.paging);
            setDatas(result.listObjects);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _handleChangedTab(tab) {
        getDatas(Common.Paging, tab);
        setTabindex(tab);
    }
    const _renderTab = useMemo(() => (
        <ul className='nav nav-tabs tabs-bordered'>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.UNPAID ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.UNPAID)}>
                    <span>Chưa thanh toán</span>
                </a>
            </li>
            <li className='nav-item'>
                <a className={tabIndex == TAB_SCREEN.PAID ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangedTab(TAB_SCREEN.PAID)}>
                    <span>Đã thanh toán</span>
                </a>
            </li>
        </ul>
    ));
    function _handleShowPaymentModal(item) {
        setShowModalPayment(true);
        setCurrentOperator(item);
    }
    function _renderHeader() {
        return tabIndex == TAB_SCREEN.UNPAID ? (
            <thead>
                <tr>
                    <th width='50'>STT</th>
                    {Common.IsAdmin() && <th>Tên Operator</th>}
                    <th className='text-center'>Kỳ thanh toán</th>
                    <th className='text-center'>Tổng tiền (đ)</th>
                    <th>Tên ngân hàng</th>
                    <th>Tên người nhận</th>
                    <th className='text-center'>Số tài khoản</th>
                    <th className='text-center'>SĐT MOMO</th>
                    {Common.IsAdmin() && <th></th>}
                </tr>
            </thead>
        ) : (
            <thead>
                <tr>
                    <th width='50'>STT</th>
                    {Common.IsAdmin() && <th>Tên Operator</th>}
                    <th className='text-center'>Kỳ thanh toán</th>
                    <th className='text-center'>Tổng tiền (đ)</th>
                    <th>Hình thức CK</th>
                    <th>Tên ngân hàng</th>
                    <th className='text-center'>Số tài khoản</th>
                    <th className='text-center'>SĐT MOMO</th>
                    <th>Nội dung CK</th>
                    <th className='text-center'>Mã giao dịch</th>
                    <th>Thời gian giao dịch</th>
                    <th>Ghi chú</th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        let no = paging.pageSize * (paging.pageNumber - 1) + i + 1;
                        return tabIndex == TAB_SCREEN.UNPAID ? (
                            <tr key={i}>
                                <td className='text-center'>{no}</td>
                                {Common.IsAdmin() && <td>{item.operatorName}</td>}
                                <td className='text-center'>{item.recycleTime}</td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalAmount} />
                                </td>
                                <td>{item.bankName}</td>
                                <td>{item.bankAccountName}</td>
                                <td className='text-center'>{item.bankAccountNumber}</td>
                                <td className='text-center'>{item.phoneNumber}</td>
                                {Common.IsAdmin() && (
                                    <td width='50'>
                                        <button className='btn btn-custom btn-sm' onClick={() => _handleShowPaymentModal(item)}>
                                            <i className='fa fa-paper-plane-o m-r-5' />
                                            Thanh toán
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ) : (
                            <tr key={i}>
                                <td className='text-center'>{no}</td>
                                {Common.IsAdmin() && <td>{item.operator}</td>}
                                <td className='text-center'>{item.recycleTime}</td>
                                <td className='text-right'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.totalAmount} />
                                </td>
                                <td className='text-center'>{item.paymentType == 1 ? "CK Ngân hàng" : "Momo"}</td>
                                <td>{item.bankName}</td>
                                <td className='text-center'>{item.bankAccountNumber}</td>
                                <td className='text-center'>{item.phoneNumber ? item.phoneNumber : "N/A"}</td>
                                <td>{item.transferDescription}</td>
                                <td className='text-center'>{item.transferReferenceCode}</td>
                                <td className='text-center'>{item.transferDate}</td>
                                <td>{item.note}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='100' className='text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        return (
            <div className='p-y-10 box__actions'>
                <div className='form-group full-width'></div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage='Thanh toán chi phí sản xuất' />
            <Loading show={isLoading} msg={msg} />
            {_renderTab}
            {isShowModalPayment && (
                <PaymentModal
                    isShowModal={isShowModalPayment}
                    onClose={() => setShowModalPayment(false)}
                    operator={currentOperator}
                    onSuccess={() => {
                        setShowModalPayment(false);
                        getDatas(paging, tabIndex);
                    }}
                />
            )}
            <CommonTable
                data={datas}
                paging={paging}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                renderAction={_renderAction}
                onFilter={(paging) => getDatas(paging, tabIndex)}
                searchBasic={Common.IsAdmin()}
                placeholderSearch='Tên Operator'
            />
        </React.Fragment>
    );
}
