import React, { Component } from "react";
import PropTypes from "prop-types";
import InputValidation from "../inputs-refactor/InputValidation";
class Textbox extends InputValidation {
    constructor(props) {
        super(props);
        this.state = { hidePass: true };
        this.state.isValid = true;
    }
    static defaultProps = {
        required: false,
        valid: true,
        email: false,
        url: false,
        className: "",
        minLength: 0,
        maxLength: 524288,
        value: "",
        readOnly: false,
        allowCopy: false,
    };

    static propTypes = {
        id: PropTypes.string,
        required: PropTypes.bool,
        requiredMsg: PropTypes.string,
        valid: PropTypes.bool,
        invalidMessage: PropTypes.string,
        maxLength: PropTypes.number,
        maxLengthMsg: PropTypes.string,
        minLength: PropTypes.number,
        minLengthMsg: PropTypes.string,
        email: PropTypes.bool,
        onChanged: PropTypes.func,
        onBlur: PropTypes.func,
        onKeyUp: PropTypes.func,
        value: PropTypes.string,
        readOnly: PropTypes.bool,
        allowCopy: PropTypes.bool,
    };
    handleBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    handleCopy() {
        var copyText = document.getElementById(this.props.id);
        copyText.select();
        document.execCommand("copy");
    }
    render() {
        let classInput = this.props.className;
        if (!this.state.isValid) {
            classInput += " alert-danger";
        }
        if (this.props.isPhoneNumber) {
            if (this.state.isValid) this.props.msgInvalid("");
            else this.props.msgInvalid(this.state.msgInvalid);
        }
        return (
            <React.Fragment>
                {this.props.prependText ? (
                    <div className='form__group'>
                        <label>
                            {this.props.prependText} <span className='sub__label'>{this.props.subPrependText}</span>
                        </label>
                        <div className='input__extent'>
                            <input
                                autoComplete='off'
                                id={this.props.id}
                                name={this.props.name}
                                className={`${classInput} input__item`}
                                type={this.props.number ? "number" : this.props.password ? "password" : "text"}
                                placeholder={this.props.placeholder ? this.props.placeholder : null}
                                onChange={this.handleChanged}
                                value={this.props.value ? this.props.value : ""}
                                readOnly={this.props.readOnly}
                                onFocus={this.handleFocus}
                                onKeyUp={this.handleKeyUp}
                                onBlur={this.handleBlur.bind(this)}
                                onKeyDown={this.handleEnter}
                                maxLength={this.props.maxLength ? this.props.maxLength : null}
                            />

                            <span className='sub__input'>đồng</span>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <input
                            autoComplete='off'
                            id={this.props.id}
                            name={this.props.name}
                            className={`${classInput} input__item`}
                            type={this.props.number ? "number" : this.props.password && this.state.hidePass ? "password" : "text"}
                            placeholder={this.props.placeholder ? this.props.placeholder : null}
                            onChange={this.handleChanged}
                            value={this.props.value ? this.props.value : ""}
                            readOnly={this.props.readOnly}
                            onFocus={this.handleFocus}
                            onKeyUp={this.handleKeyUp}
                            onKeyDown={this.handleEnter}
                            onBlur={this.handleBlur.bind(this)}
                        />
                        {this.props.password ? (
                            <span className='show-hide-password'>
                                {this.state.hidePass ? (
                                    <i className='fa fa-eye' aria-hidden='true' onClick={() => this.setState({ hidePass: false })}></i>
                                ) : (
                                    <i className='fa fa-eye-slash' aria-hidden='true' onClick={() => this.setState({ hidePass: true })}></i>
                                )}
                            </span>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {this.props.allowCopy ? (
                    <div className='input-group-append cursor-pointer' onClick={this.handleCopy.bind(this)}>
                        <span className='input-group-text'>
                            <i className='fa fa-clipboard'></i>
                        </span>
                    </div>
                ) : (
                    ""
                )}
                {!this.props.isPhoneNumber ? (
                    <React.Fragment>
                        {this.state.isValid ? null : (
                            <label className='text-danger full-width' htmlFor={this.props.id}>
                                {this.state.msgInvalid}
                            </label>
                        )}
                    </React.Fragment>
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }
}
export default Textbox;
