import React, { useEffect, useLayoutEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { INITPAGING } from "../based/Constants";
import Loading from "../based/Loading";
import CheckBox from "../based/inputs/CheckBox";
import PresetServices from "../based/services/PresetServices";
import { PresetModel } from "./PresetModel";

function PresetManage(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [datas, setDatas] = useState([new PresetModel()]);
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [selectId, setSelectId] = useState(0);

    useLayoutEffect(() => {
        setDatas([]);
    }, []);

    useEffect(() => {
        _getData({ ...INITPAGING });
    }, []);

    async function _getData(paging) {
        setIsBusy(true);
        let [err, data] = await PresetServices.GetListPresetByPage(paging);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
        setIsBusy(false);
    }

    const handleSortColumn = (columnName) => {};

    const _handleDelete = async (id) => {
        setIsBusy(true);
        let [err, data] = await PresetServices.DeletePresetById(id);
        if (!err && data) {
            _getData({ ...INITPAGING });
        }
        setIsBusy(false);
    };

    function _renderHeader() {
        let icon = <i className="fa fa-sort fa-sort-thead"></i>;
        return (
            <thead>
                <tr>
                    <th className="center"></th>
                    <th className="center">Id</th>
                    <th className="center" onClick={() => handleSortColumn("name")}>
                        Tên {paging.orderBy === "name" && paging.orderDirection.toUpperCase() === "DESC" ? <i className="fa fa-sort-desc fa-sort-thead"></i> : paging.orderBy === "name" ? <i className="fa fa-sort-asc fa-sort-thead"></i> : icon}
                    </th>
                    <th className="center">Thumbnail</th>
                    <th className="center">Ngày cập nhập</th>
                    <th className="center">Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={() => {
                        window.open("/preset-config", "_blank");
                    }}
                >
                    Thêm mới
                </button>
                <button className={selectId > 0 ? "btn btn-danger ml-3" : "btn btn-danger ml-3 disable"} onClick={() => (selectId > 0 ? _handleDelete(selectId) : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>
                                    <CheckBox
                                        id={idx.toString()}
                                        name={idx.toString()}
                                        checked={item.id ? item.id == selectId : false}
                                        label=" "
                                        onCheckedChange={(name, value) => {
                                            if (value) setSelectId(item.id);
                                            else setSelectId(0);
                                        }}
                                    />
                                </td>
                                <td className="center">{item.id}</td>
                                <td className="center">{item.name}</td>
                                <td className="center">
                                    <img
                                        src={item.thumbnail}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </td>
                                <td className="center">{item.createdDate ? Common.formatDate(new Date(item.createdDate)) : ""}</td>
                                <td className="center">
                                    <button
                                        className="btn btn-custom"
                                        onClick={() => {
                                            window.open("/preset-config/" + item.id, "_blank");
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <React.Fragment>
            <Loading show={isBusy} msg="Đang tải dữ liệu..." />
            <Breadcrumbs currentPage="Quản lý After Effect" />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
        </React.Fragment>
    );
}

export default PresetManage;
