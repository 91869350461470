import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import ReactSelect from "react-select";
import CheckBox from "../based/inputs/CheckBox";
import TFUSelect from "../based/inputs/TFUSelect";
import TFUDatetime from "../based/inputs/TFUDatetime";
import TemSizeServices from "../based/services/TemSizeServices";
import Common from "../based/Common";
import ShipmentProductManagement from "./ShipmentProductManagement"
import { Alert } from "react-bootstrap";
const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

const modelShipment = {
    id: 0,
    name: "",
    requestDate: '',
    isActive: false,
    isUpdate: true,
};

const ShipmentManagement = () => {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isBusy, setBusy] = useState(true);
    const [msg, setMsg] = useState("");
    const tableRef = useRef(null);
    const [selectId, setSelectId] = useState(0);
    const [shipmentItem, setShipmentItem] = useState({});

    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetShipmentByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    
    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th>STT</th>
                    <th>Lô sản xuất</th>
                    <th>Ngày yêu cầu</th>
                    <th>Active</th>
                    <th>Hành động</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, idx) => {
                        return (
                            <tr className={idx}>
                                <td>{idx + 1}</td>
                                <td style={{ width: 100 }}>
                                    {item.isUpdate ? (
                                         <input type="text"
                                            className="input-in-table"
                                            placeholder="Nhập lô"
                                            value={item.name}
                                            maxLength={1}
                                            onChange={(e) => {
                                                let dt = [...data];
                                                dt[idx].name = e.target.value;
                                                setData(dt);
                                            }}
                                     />
                                    ) : (
                                        item.name
                                    )}
                                </td>
                                <td style={{ width: 250 }}> 
                                {item.isUpdate ?
                                    <TFUDatetime minDate={new Date()} dateFormat="dd/MM/yyyy"  value={item.requestDate ? new Date(item.requestDate) : new Date()} name="fromDate"  className="form-control" 
                                    onChanged={(value, name) => {
                                        let dt = [...data];
                                        dt[idx].requestDate = value;
                                        setData(dt);
                                    }} /> : Common.formatDate(new Date(item.requestDate))}

                                </td>
                                <td>
                                <CheckBox
                                    checked={item.isActive}
                                    name={`${item.id}`}
                                    label=" "
                                    onCheckedChange={ async (name, value) => {
                                       if(item.isUpdate){
                                            let dt = [...data];
                                            dt[idx].isActive = value;
                                            setData(dt);
                                       }else{
                                            let dt = [...data];
                                            dt[idx].isActive = value;
                                            await setData(dt);
                                            handleCreateOrUpdate(idx)
                                       }
                                    }}
                                />
                                </td>
                                <td style={{ width: 200, textAlign: "center" }}>
                                    {item.isUpdate ? (
                                        <button
                                            className={`btn btn-custom m-1  ${IsDisable(idx) ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!IsDisable(idx)) handleCreateOrUpdate(idx);
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    ) : <button
                                            className={`btn btn-custom m-1`}
                                            onClick={() => {
                                                setSelectId(item.id);
                                            }}
                                        >
                                            Xem
                                        </button>}
                                    <button
                                        className={"btn btn-danger m-1"}
                                        onClick={() => {
                                            handleDelete(item.id, idx);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                   
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={99}>Không có dữ liệu</td>
                    </tr>
                )}
                <tr>
                    <td colSpan={99}>
                        <div
                            className="add-item"
                            style={{
                                color: "#64C5B1",
                                fontSize: "16px",
                                fontWeight: 600,
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                handleAddItem();
                            }}
                        >
                            Thêm +
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    function _renderAction() {
        return <div className="mb-3 text-title">Dánh sách lô</div>;
    }

    async function handleAddItem() {
        setData([ ...data, { ...modelShipment }]);
    }

    function IsDisable(idx) {
        if (!data[idx].name || !data[idx].requestDate) return true;
        return false;
    }
    async function handleCreateOrUpdate(idx) {
        setBusy(true);
        let dataItem = { ...data[idx] };
        let [err, dt] = await TemSizeServices.CreateUpdateShipment(dataItem);
        if (!err && dt) {
            getData(initPage);
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại, kiểm tra lại tên lô");
        }
    }

    async function handleDelete(id, idx) {
        if (id > 0) {

            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    let [error, data] = await TemSizeServices.DeleteShipment(id);
                    if (!error && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else {
            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);
        }
    }

    const [listProductOption, setListProductOption] = useState([]);
    const [pagingProduct, setPagingProduct] = useState({ ...initPage });    
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getListProduct();
    }, [pagingProduct.keyword]);

    const getListProduct = async () => {
        setIsFetching(true);
        const [err, data] = await TemSizeServices.GetProductStampByPage(pagingProduct);
        if (!err && data) {
            const options = data.listObjects.map((product) => ({
                value: product.productId,
                label: product.name,
                ...product,
            }));
            setListProductOption(options);
        } else {
            setIsFetching(false);
        }
    };

    const selectProductHandler = (product) => {
        if (product) {
            const { productId, name } = product;
            const updatedProducts = [...shipmentItem.products];
            var oldProduct = updatedProducts.find((x) => x.productId == productId);
            if (oldProduct) {
                Notify(NOTIFY.WARNING, "Cảnh báo", "Bạn đã thêm sản phẩm này rồi!");
            } else {
                handleAddProductShipment(productId)
            }
        }
    };


    useEffect(() => {
        if (selectId > 0) {
            getShipmentItem();
        } else {
            setShipmentItem({});
        }
    }, [selectId]);

    async function getShipmentItem() {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await TemSizeServices.GetShipmentById(selectId);
        if (!err && data) {
            setShipmentItem(data);
            setBusy(false);
        } else {
            setShipmentItem([]);
            setBusy(false);
        }
    }
    

    async function handleUpdate() {
        
        setBusy(true);
        let [err, dt] = await TemSizeServices.CreateUpdateShipment({...shipmentItem});
        if (!err && dt) {
            getShipmentItem();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại , kiểm tra lại tên lô");
        }
    
    }

    const handleChangeName = async (value) => {
        setBusy(true);
        let [err, dt] = await TemSizeServices.CheckName(shipmentItem.id, value);
        if (!err ) {
            if(dt){
                setShipmentItem({...shipmentItem , name: value , isDuplicate: true})
                Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Trùng tên lô");
            }else{
                setShipmentItem({...shipmentItem , name: value , isDuplicate: false})
            }
            
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi");

        }
        setBusy(false);

    }

    
    async function handleAddProductShipment(id) {
        
        setBusy(true);
        let [err, dt] = await TemSizeServices.CreateUpdateShipmentProduct({
            productId: id,
            id: 0,
            shipmentId: shipmentItem.id
        });
        if (!err && dt) {
            getShipmentItem();
            setBusy(false);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
        }
    
    }

    
    async function handleDeleteShipmentProduct(id) {
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
            return new Promise(async (resolve, reject) => {
                        
                setBusy(true);
                let [err, dt] = await TemSizeServices.DeleteShipmentProduct(id);
                if (!err && dt) {
                    getShipmentItem();
                    setBusy(false);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    setBusy(false);
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
                }
                
            });
        });
    
    }

    return (
        <StyleBody>
            <Loading show={isBusy} msg={msg} />
            <div className="w-30">
                <CommonTable data={data} paging={paging} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} ref={tableRef} onFilter={(paging) => getData(paging)} />
            </div>

            <div className="w-70">
                {selectId > 0 && <div className="box-item-shipment">
                    <div className="box-item-shipment-header">
                        <h4>Chi tiết lô</h4>
                        <div className="d-flex">
                            {!shipmentItem.isUpdate ? 
                                <button className="btn btn-custom"
                                onClick={()=>{
                                    setShipmentItem({...shipmentItem, isUpdate: true});
                                }}
                            >Chỉnh sửa</button>
                            : 
                            <button className="btn btn-danger"
                                onClick={()=>{
                                    setShipmentItem({...shipmentItem, isUpdate: false});
                                }}
                            >Hủy</button>
                        }
                        {shipmentItem.isUpdate && 
                                <button className={`btn btn-custom ml-2 ${(!shipmentItem.name || shipmentItem.isDuplicate) ? "disabled" : ""}`}
                                onClick={()=>{
                                    if(!shipmentItem.name || shipmentItem.isDuplicate ){

                                    }else{
                                        handleUpdate()
                                    }
                                }}
                            >Lưu</button>
                        }
                        </div>
                    </div>
                    <div className="box-item-shipment-header">
                        <div className="d-flex">
                            <div className="box-flex-re">
                                <label>Lô sản xuất</label>
                                <input type="text" value={shipmentItem.name} 
                                maxLength={1}
                                onChange={(e)=>{
                                    handleChangeName(e.target.value);
                                }}
                                disabled={shipmentItem.isUpdate ? "" : 'disabled'} />
                            </div>
                            <div className="box-flex-re">
                                <label>Ngày yêu cầu</label>
                                <TFUDatetime minDate={new Date()} disabled={shipmentItem.isUpdate}  dateFormat="dd/MM/yyyy"  value={shipmentItem.requestDate ? new Date(shipmentItem.requestDate) : new Date()} name="fromDate"  className="form-control" 
                                    onChanged={(value, name) => {
                                        setShipmentItem({...shipmentItem , requestDate: value})
                                    }} /> 
                            </div>
                        </div>
                        <div className="box-add-product">
                            <ReactSelect
                                id='keyword'
                                name='keyword'
                                isClearable={true}
                                className='react-select-up'
                                classNamePrefix='react-select'
                                placeholder='Nhập tên sản phẩm ...'
                                onInputChange={(value) => setPagingProduct({ ...pagingProduct, keyword: value })}
                                value={pagingProduct.keyword}
                                options={listProductOption}
                                onChange={(selected) => selectProductHandler(selected)}
                                noOptionsMessage={() => (isFetching ? "Đang tải dữ liệu" : "Không có dữ liệu")}
                            />
                           
                        </div>
                    </div>

                    {shipmentItem.products && shipmentItem.products.length > 0 && shipmentItem.products.map((item, idx)=>{
                        return <div key={idx} className="box-shipment-product">
                            <div className="box-shipment-product-header">
                                <h4>{item.name}</h4>
                                <span onClick={()=>{
                                    handleDeleteShipmentProduct(item.id)
                                }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 1L1 13" stroke="#444444" stroke-width="2"/>
                                    <path d="M13 13L1 0.999999" stroke="#444444" stroke-width="2"/>
                                    </svg>
                                </span>
                            </div>
                            <ShipmentProductManagement shipmentProductId={item.id} productId={item.productId}/>
                        </div>
                    })}
                </div>}
            </div>
        </StyleBody>
    );
};
const StyleBody = styled.div`
    display: flex;
    align-items: flex-start;

    .text-title {
        color: #444;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 600;
        line-height: 20px;
    }

    .w-30 {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
    }
    .w-70 {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
    }
    .input-in-table {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        padding: 0;
        display: block;

        ::placeholder {
            opacity: 0.3;
        }
    }

    .box-item-shipment{
        width: 100%;
        padding: 12px;
        background: #fff;

        .box-item-shipment-header{
            display: flex;
            align-items: center;
            justify-content: space-between;



            .box-flex-re{
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                align-items: start;
                label{
                    color: #444;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                    margin-bottom: 10px;
                }

                input{ 
                    height: 38px;
                    width: 100px;
                }
            }

            .box-add-product{
                input{ 
                    height: 38px;
                    width: 200px !important;

                    ::placeholder {
                        opacity: 0.3;
                    }
                }
            }

        }
    }

    .box-shipment-product{
        width: 100%;
        margin-top: 15px;

        .box-shipment-product-header{
            display:flex;
            align-items: center;
            justify-content: space-between;
            height: 38px;
            padding: 0 20px;
            border: 1px solid #D9D9D9;
            background: #D9D9D9;

            h4{
                color: #444;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
            }
        }
    }

    
`;
export default ShipmentManagement;
