import React, { useState, useEffect } from "react";

import Request from "../../based/Request";
import { NOTIFY, CONSTANTS } from "../../based/Constants";
import { Notify } from "../../based/Notify";
import Common from "../../based/Common";
import CommonModal from "../../based/Modal";
import Textbox from "../../based/inputs/Textbox";
import useFormValidate from "../../hooks/useFormValidate";
import TFUTooltip from "../../based/TFUTooltip";
import TextNumber from "../../based/inputs/TextNumber";
import CheckBox from "../../based/inputs/CheckBox";

export default function SpinnerCountInfo(props) {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [msgModalLoading, setMsgModalLoading] = useState(null);
    const [user, setUser] = useState(props.user);
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        setUser(props.user);
    }, [props.isShowModal]);

    function _handleSave() {
        if (isValid()) {
            setIsModalLoading(true);
            setMsgModalLoading("Đang lưu dữ liệu..");
            let model = {
                thirdPartyId: user.id,
                spinnerCount: user.spinnerCount,
                fakeIPSpinnerCount: user.fakeIPSpinnerCount,
                isLikeRanusPage: user.isLikeRanusPage
            };
            Request.Post("/api/thirdParty/update-spinner-count-ip", model)
                .then((res) => {
                    if (res.success) {
                        Notify(NOTIFY.SUCCESS, "Thành công", res.message);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                        props.onSaveSucceed();
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsModalLoading(false);
                        setMsgModalLoading(null);
                    }
                })
                .catch((err) => {
                    if (err.errors) displayError(err.errors);
                    else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                    setIsModalLoading(false);
                    setMsgModalLoading(null);
                });
        }
    }

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isModalLoading} busyMsg={msgModalLoading} title={user.id == 0 ? "Thêm mới" : "Cập nhật"} onSave={() => _handleSave()} onClose={() => props.onClose()}>
            <div className='form-group'>
                <label htmlFor='userName' className='required'>
                    Tên đầy đủ:{" "}
                </label>
                <TFUTooltip content='Bao gồm các chữ số a-zA-Z0-9 và dấu _' target='quesuserName'>
                    <i className='fa fa-question-circle' id='quesuserName' />
                </TFUTooltip>
                <Textbox id='fullName' name='fullName' readOnly={user.id ? true : false} required ref={addRef} className='form-control' onChanged={(name, value) => {}} value={user.fullName} />
            </div>
            <div className='form-group'>
                <label htmlFor='spinnerCount' className='required'>
                    Số lượt quay
                </label>
                <TextNumber required id='spinnerCount' name='spinnerCount' ref={addRef} maxLength={100} className='form-control' onChanged={(name, value) => setUser({ ...user, spinnerCount: value })} value={+user.spinnerCount} />
            </div>
            <div className='form-group'>
                <CheckBox
                    label='Đã like FanPage Ranus'
                    checked={user.isLikeRanusPage}
                    onCheckedChange={(name, value) => {
                        setUser({ ...user, isLikeRanusPage: value });
                    }}
                />
            </div>
            <div className='form-group'>
                <label htmlFor='fakeIPSpinnerCount' className='required'>
                    Địa chỉ IP
                </label>
                <Textbox required id='fakeIPSpinnerCount' name='fakeIPSpinnerCount' ref={addRef} maxLength={100} className='form-control' onChanged={(name, value) => setUser({ ...user, fakeIPSpinnerCount: value })} value={user.fakeIPSpinnerCount} />
            </div>
        </CommonModal>
    );
}
