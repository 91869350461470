import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CommonModal from "../../based/Modal";
import Common from "../../based/Common";
import { OperatorOrderTicket } from "../../models/OperatorOrderTicket";
import Textbox from "../../based/inputs/Textbox";

function OperatorOrderDetail(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState(null);
    const [data, setData] = useState(new OperatorOrderTicket());

    useEffect(() => {
        _getData();
    }, []);

    const _getData = async () => {
        let data = new OperatorOrderTicket();
        data.brandName = "5th way";
        data.creatorName = "Huy";
        data.productName = "T-Basic";
        data.logoPositionLevel = "MR5";
        setData(data);
    };

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Chi tiết"} onCLose={() => props.onCLose()}>
            <div className='row'>
                <div className='col-md-6'>
                    <label className='col-form-label' htmlFor='creatorName'>
                        Brand
                    </label>
                    <Textbox className='form-control' name='brandName' value={data.brandName} readOnly />
                </div>
                <div className='col-md-6'>
                    <label className='col-form-label' htmlFor='creatorName'>
                        Creator
                    </label>
                    <Textbox className='form-control' name='creatorName' value={data.creatorName} readOnly />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <Textbox className='form-control' name='productName' value={data.productName} readOnly />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 row'></div>
                <div className='col-md-6 row'>
                    <div className='col-md-12'>
                        <label className='col-form-label'>Brand Logo</label>
                        <div>
                            <Textbox value={data.logoPositionLevel} name='logoPositionLevel' readOnly />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label className='col-form-label'>Front Printing</label>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
}

OperatorOrderDetail.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
};

export default OperatorOrderDetail;
