import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import styled from "styled-components";
import BaseServices from '../based/services/BaseServices';

const ModalAddOrUpdate = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [file, setFile] = useState()


   
    

    
    const _handleSave = async () => {
        const selectedFile = file;
        setIsBusy(true)
        const formData = new FormData();
        formData.append('data', selectedFile);
        const [error, data] = await BaseServices.Post('/api/EnhanceAccount/import-excel', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        //let [error, data] = await EnhanceAccountService.ImportExcel(formData);
        if(!error && data){
            Notify(NOTIFY.SUCCESS, "Thành công", "Import data successfully");
            setIsBusy(false)
            props.onSaveContinue();
        }
        else{
            Notify(NOTIFY.ERROR, "Có lỗi", "Có lỗi xảy ra trong quá trình xử lý");
            setIsBusy(false)

        }
        
        
        
    };
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy}  title={"Import Excel"} onSave={_handleSave} onClose={() => props.onClose()}>
            <div className='form-group'>
            <Wapper>
                <p>Dữ liệu trong file excel gồm 3 cột theo đúng thứ tự: </p>
                <p>Username , password , credit</p>
                <p>Dữ liệu trong các cột không được để trống</p>
                <p>Cột credit bắt buộc là số</p>
                <p>Nếu dữ liệu sai sót , quá trình import sẽ lỗi</p>
                <label htmlFor='upload-excel' className='required'>
                    Chọn file excel:
                </label>
                <input id="upload-excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" className="btn btn-custom" onChange={(e)=>{
                    setFile(e.target.files[0])
                }} />
            </Wapper>
                
               
            </div>
        </CommonModal>
    );
};

export default ModalAddOrUpdate;
const Wapper = styled.div`


    
`
ModalAddOrUpdate.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
