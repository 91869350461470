import React, { useContext, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import TFUImage from "../based/inputs/Image";
import PrintServices from "../based/services/PrintServices";
import { ConfigAttributeModalContext } from "../context/ConfigAttributeModalContext";
import { ProductVariantContext } from "../context/ProductVariantContext";
import { FrameConfigIgnorePixel } from "../models/BaseProductModel";

function SectionIgnorePixel(props) {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);
    const { attributeId } = useContext(ConfigAttributeModalContext);

    useEffect(() => {
        if (attributeId > 0 && editingVariant && (!editingVariant.frameConfigIgnorePixel || !editingVariant.frameConfigIgnorePixel.ignorePixelUrl)) getData(attributeId);
    }, [attributeId]);

    //lấy thông tin frameconfig
    const getData = async (attributeId) => {
        let [err, data] = await PrintServices.GetFrameConfigsByAttributeId(attributeId);
        if (!err) {
            let editConfig = { ...editingVariant };
            let attribute = editConfig.attributes;
            let sizeAttribute = attribute ? attribute.find((x) => x.isPrintFrameConfig) : null;
            let frameConfig = data.find((x) => x.attributeValueName == sizeAttribute.value);

            let newIgnore = new FrameConfigIgnorePixel();
            newIgnore.attributeValueId = frameConfig.attributeValueId;
            newIgnore.widthFrame = frameConfig.widthFrame;
            newIgnore.heightFrame = frameConfig.heightFrame;
            editConfig.frameConfigIgnorePixel = newIgnore;
            setEditingVariant(editConfig);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lỗi");
        }
    };

    return (
        <div className="card-box margin-bottom-10">
            <div>
                <table className="table table-bordered m-b-0">
                    <thead className="thead-light">
                        <tr>
                            <th className="cursor-pointer center" width="200">
                                Kích thước ngang thực tế<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Kích thước dọc thực tế<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Hình ảnh<span className="required-field-icon">&nbsp;*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {editingVariant && editingVariant.frameConfigIgnorePixel && (
                            <tr>
                                <td className="cursor-pointer center" width="200">
                                    {editingVariant.frameConfigIgnorePixel.widthFrame}
                                </td>
                                <td className="cursor-pointer center" width="200">
                                    {editingVariant.frameConfigIgnorePixel.heightFrame}
                                </td>
                                <td className="cursor-pointer center" width="200">
                                    <TFUImage
                                        name="ignorePixelUrl"
                                        image={editingVariant.frameConfigIgnorePixel.ignorePixelUrl}
                                        onChanged={(name, value) => {
                                            let editConfig = { ...editingVariant };
                                            editConfig.frameConfigIgnorePixel.ignorePixelUrl = value;
                                            setEditingVariant(editConfig);
                                        }}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SectionIgnorePixel;
