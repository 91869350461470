import BaseServices from "./BaseServices";

const BlogServices = {
    GetCategories: async (paging) => {
        return await BaseServices.Post("/api/Blogs/get-blog-categories", paging);
    },
    CreateUpdateCategory: async (model) => {
        return await BaseServices.Post("/api/Blogs/create-update-blog-category", model);
    },
    GetCategory: async (id) => {
        return await BaseServices.Get(`/api/Blogs/get-category/${id}`);
    },
    DeleteCategories: async (ids) => {
        return await BaseServices.Post("/api/Blogs/delete-blog-category", ids);
    },
    CreateUpdateBlog: async (model) => {
        return await BaseServices.Post("/api/Blogs/create-update-blog", model);
    },
    GetListBlog: async (paging) => {
        return await BaseServices.Post("/api/Blogs/get-blogs", paging);
    },
    DeleteBLogs: async (ids) => {
        return await BaseServices.Post("/api/Blogs/delete-blog", ids);
    },
    GetBlogByID: async (id) => {
        return BaseServices.Get(`/api/Blogs/get-blog-by-id/${id}`);
    },
    ChangeBlogStatus: async (model) => {
        return await BaseServices.Post("/api/Blogs/change-blog-status", model);
    },
    GetBlogKeywords: async (paging) => {
        return await BaseServices.Post("/api/Blogs/get-blog-keywords", paging);
    },
    CreateUpdateBlogKeyword: async (model) => {
        return await BaseServices.Post("/api/Blogs/create-update-blog-keyword", model);
    },
    DeleteBlogKeyword: async (ids) => {
        return await BaseServices.Post("/api/Blogs/delete-blog-keywords", ids);
    },
    GetBlogKeyword: async (id) => {
        return BaseServices.Get(`/api/Blogs/get-blog-keyword/${id}`);
    },
    GetKeywordDropdown: async (id) => {
        return BaseServices.Post(`/api/Blogs/get-keyword-dropdown/${id}`);
    },
};

export default BlogServices;
