import React, { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import { WarehouseInfoProps } from '../based/PropsDefined';
import { StockOutContext, WarehouseInfoModel } from '../context/StockOutContext';
import Selection from '../based/inputs/Selection';
import WarehouseServices from '../based/services/WarehouseServices';

/**
 * Warehouse basic info
 * @param {WarehouseInfoProps} props The warehouse info props
 */
export default function StockOutWarehouseInfo(props) {
  const [warehouses, setWarehouses] = useState([]);

  const { warehouseInfo, setWarehouseInfo } = useContext(StockOutContext);

  useEffect(() => {
    _getWarehouses();
  }, []);

  async function _getWarehouses() {
    let [err, data] = await WarehouseServices.GetWarehouseDropdown();
    if (!err) setWarehouses(data);
    else setWarehouses([]);
  }

  async function _handleSelectedWarehouse(id) {
    if (id > 0) {
      const [err, data] = await WarehouseServices.GetWarehouseById(id);
      if (!err) {
        let address = data.location;
        setWarehouseInfo({ ...warehouseInfo, id: id, address: address });
      } else setWarehouseInfo(new WarehouseInfoModel());
    } else setWarehouseInfo(new WarehouseInfoModel());
  }

  function _handleChangeDate(date, name) {
    if (date != null) {
      setWarehouseInfo({ ...warehouseInfo, expectedTime: date });
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="header-title font-weight-bold">Thông tin cơ bản</h4>
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="name" className="col-form-label required">
              Kho
            </label>
            <div className="row">
              <div className="col-md-12">
                <Selection ref={props.addRefParent ? props.addRefParent : null} id="warehouseInfoId" name="warehouseInfoId" required value={warehouseInfo.id} placeholder="---Chọn kho---" onChanged={_handleSelectedWarehouse} options={warehouses} />
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="warehouseAddress" className="col-form-label">
              Địa chỉ kho
            </label>
            <input className="form-control" id="warehouseAddress" name="warehouseAddress" value={warehouseInfo.address} readOnly />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="startTime" className="col-form-label">
              Thời gian dự kiến xuất hàng
            </label>
            <div className="row">
              <div className="col-md-12">
                <DatePicker
                  onChange={(date) => _handleChangeDate(date, 'startTime')}
                  selected={warehouseInfo.expectedTime}
                  adjustDateOnChange
                  locale={vi}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  showTimeSelect
                  timeCaption="time"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy HH:mm"
                  dropdownMode="select"
                  popperPlacement="auto"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
